import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HazardousImplantsFormSection from './HazardousImplantsFormSection';

class HazardousImplantsFormSectionContainer extends Component {
  static propTypes = {
    fieldData: PropTypes.shape({
      implant: PropTypes.string,
      dateRemoved: PropTypes.string,
    }),
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    fieldData: {},
  }

  handleChange = (key, value) => {
    const { onChange, fieldData } = this.props;

    const data = {
      ...fieldData,
      [key]: value,
    };
    onChange(data);
  };

  render() {
    const { fieldData, disabled } = this.props;
    return (
      <HazardousImplantsFormSection
        onChange={this.handleChange}
        fieldData={fieldData}
        disabled={disabled}
      />
    );
  }
}

export default HazardousImplantsFormSectionContainer;
