import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { productType, serviceType } from 'types/bereavement';
import Dialog from '@material-ui/core/Dialog';
import Slider from 'react-slick';
import IconAdd from '@material-ui/icons/Add';
import CatalogueItemDetails from 'components/catalogue/CatalogueItemDetails';

import Arrow from 'components/common/Arrow';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const classesContainer = {
  root: rcStyles.rc_flow,
};

const settings = detailDisplayIndex => ({
  dots: false,
  infinite: false,
  speed: 500,
  initialSlide: detailDisplayIndex,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: <Arrow size="large" dir="left" />,
  nextArrow: <Arrow size="large" dir="right" />,
  classes: classesContainer,
});

const CatalogueItemsSliderModal = ({
  isOpen,
  detailDisplayIndex,
  allItems,
  itemIdsInPackage,
  disabled,
  onAddSelection,
  onClose,
  focusNewSelection,
}) => (
  <Dialog
    onClose={onClose}
    fullScreen
    open={isOpen}
    TransitionComponent={Transition}
    className={classNames(
      rcStyles.rc_wrap,
      styles.c_page__full_height,
      styles.has_faded_background,
    )}
    disableBackdropClick
    disableEscapeKeyDown
  >
    <Slider {...settings(detailDisplayIndex)}>
      {allItems.map(item => (
        <Fragment key={item.id}>
          <div className={classNames(
            styles.u_fullWidth,
          )}
          >
            <div className={classNames(
              rcStyles.rc_top_bar,
              styles.o_fb,
              styles.u_islet,
            )}
            >
              <div>
                <IconButton
                  className={classNames(styles.o_button__secondary)}
                  color="primary"
                  variant="contained"
                  aria-label="Close"
                  onClick={() => onClose()}
                  data-test-id="close"
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <div>
                <Button
                  color="secondary"
                  variant="contained"
                  aria-label="Add"
                  onClick={() => { onAddSelection(item); onClose(); }}
                  data-test-id="add"
                >
                  <IconAdd />
                  {t('Add Item')}
                </Button>
              </div>
            </div>
            <div className={rcStyles.rc_main}>
              <CatalogueItemDetails
                item={item}
                key={item.id}
                isInPackage={itemIdsInPackage && itemIdsInPackage.includes(item.id)}
                disabled={disabled}
                onAddSelection={() => { onAddSelection(item); onClose(); }}
                onClose={onClose}
                focusNewSelection={focusNewSelection}
                className={rcStyles.flow}
              />
            </div>
          </div>
        </Fragment>
      ))}
    </Slider>
  </Dialog>
);

CatalogueItemsSliderModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  allItems: PropTypes.oneOfType([
    PropTypes.arrayOf(productType).isRequired,
    PropTypes.arrayOf(serviceType).isRequired,
  ]).isRequired,
  detailDisplayIndex: PropTypes.number,
  itemIdsInPackage: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  onAddSelection: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  focusNewSelection: PropTypes.func.isRequired,
};

export default CatalogueItemsSliderModal;
