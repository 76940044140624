import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { generateHexId } from 'services/utils';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { updateClientsBillPayerAndPrimaryContactStatus } from 'services/utils/bereavement';
import { createBereavementAction } from 'actions/bereavement';
import { emptyBereavement, emptyDeceasedPerson } from 'constants/bereavement';
import { historyType } from 'types/reactRouter';
import FirstCallScreen from './FirstCallScreen';

export const FirstCallContext = React.createContext();

class FirstCallScreenContainer extends Component {
  static propTypes = {
    history: historyType.isRequired,
    createBereavement: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      bereavement: {
        ...emptyBereavement,
        id: uuidv4(),
        deceasedPeople: [{
          ...emptyDeceasedPerson,
          id: generateHexId(),
        }],
      },
      isSaving: false,
      isValidationEnabled: false,
      isDeceasedFormValid: false,
      isAutomaticEmailsConfirmationModalOpen: false,
    };
  }

  setDeceasedFormValid = (isValid) => {
    this.setState({ isDeceasedFormValid: isValid });
  }

  setAppointment = (appointment, selectedHome) => {
    const { bereavement } = this.state;
    this.updateBereavementByKey('appointment', appointment);
    if (!bereavement.homeId && !bereavement.ownerId && selectedHome) {
      this.updateBereavementByKey('homeId', selectedHome.id);
      this.updateBereavementByKey('home', selectedHome);
      this.updateBereavementByKey('ownerId', appointment.staffMemberId);
      this.updateBereavementByKey('owner', appointment.staffMember);
    }
  }

  updateBereavementByKey = (key, value) => {
    this.setState(prevState => ({
      bereavement: {
        ...prevState.bereavement,
        [key]: value,
      },
    }));
  }

  updateFullBereavement = bereavement => this.setState({ bereavement });

  addClient = (bereavementId, bereavedPeopleConnection) => {
    const { bereavement } = this.state;
    const bereavedPeopleConnections = updateClientsBillPayerAndPrimaryContactStatus(
      bereavement.bereavedPeopleConnections, bereavedPeopleConnection,
    );

    this.setState(prevState => ({
      bereavement: {
        ...prevState.bereavement,
        bereavedPeopleConnections: [
          ...bereavedPeopleConnections,
          bereavedPeopleConnection,
        ],
      },
    }));
  }

  editClient = (bereavementId, bereavedPeopleConnection) => {
    const { bereavement } = this.state;

    const bereavedPeopleConnections = updateClientsBillPayerAndPrimaryContactStatus(
      bereavement.bereavedPeopleConnections, bereavedPeopleConnection,
    );

    this.setState(prevState => ({
      bereavement: {
        ...prevState.bereavement,
        bereavedPeopleConnections,
      },
    }));
  }

  removeClient = (bereavedPeopleConnectionId) => {
    this.setState(prevState => ({
      bereavement: {
        ...prevState.bereavement,
        bereavedPeopleConnections: prevState.bereavement.bereavedPeopleConnections
          .filter(connection => connection.id !== bereavedPeopleConnectionId),
      },
    }));
  }

  toggleAutomaticEmailsConfirmationModal = () => {
    this.setState(prevState => ({
      isAutomaticEmailsConfirmationModalOpen: !prevState.isAutomaticEmailsConfirmationModalOpen,
    }));
  }

  handleAutomaticEmailConfirmation = (checked) => {
    const { bereavement } = this.state;
    const primaryContact = bereavement.bereavedPeopleConnections
      .find(connection => connection.isPrimaryContact);

    if (primaryContact && primaryContact.bereavedPerson.emails.length) {
      return this.updateBereavementByKey('sendEmailsToBereavedConsent', checked);
    }
    return this.toggleAutomaticEmailsConfirmationModal();
  }

  onSubmit = (isCreateCaseFormValid) => {
    const { history } = this.props;
    const { bereavement, isDeceasedFormValid } = this.state;

    this.setState({ isValidationEnabled: true });

    if (isDeceasedFormValid
      && isCreateCaseFormValid
      && bereavement.bereavedPeopleConnections.length > 0) {
      const { createBereavement } = this.props;
      createBereavement(bereavement);
      history.push(`/case/${bereavement.id}/arrangement`);
    }
  }

  render() {
    const {
      bereavement,
      isSaving,
      isValidationEnabled,
      isDeceasedFormValid,
      isAutomaticEmailsConfirmationModalOpen,
    } = this.state;

    return (
      <FirstCallScreen
        bereavement={bereavement}
        isSaving={isSaving}
        isValidationEnabled={isValidationEnabled}
        isDeceasedFormValid={isDeceasedFormValid}
        isAutomaticEmailsConfirmationModalOpen={isAutomaticEmailsConfirmationModalOpen}
        isBereavedFormValid={bereavement.bereavedPeopleConnections.length > 0}
        setDeceasedFormValid={this.setDeceasedFormValid}
        setAppointment={this.setAppointment}
        updateBereavementByKey={this.updateBereavementByKey}
        updateFullBereavement={this.updateFullBereavement}
        onSubmit={this.onSubmit}
        addClient={this.addClient}
        editClient={this.editClient}
        removeClient={this.removeClient}
        onAutomaticEmailConfirmation={this.handleAutomaticEmailConfirmation}
        toggleAutomaticEmailsConfirmationModal={this.toggleAutomaticEmailsConfirmationModal}
      />
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  createBereavement: createBereavementAction,
}, dispatch);

export default withApollo(
  withRouter(
    connect(null, mapDispatchToProps)(FirstCallScreenContainer),
  ),
);
