import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { appointmentType, homeType } from 'types/bereavement';

import BookAppointmentModal from './BookAppointmentModal';

class BookAppointmentModalContainer extends Component {
  static propTypes = {
    appointment: appointmentType,
    home: homeType,
    disabled: PropTypes.bool,
    setAppointment: PropTypes.func.isRequired,
    buttonColor: PropTypes.string,
    btnWillExpand: PropTypes.bool,
  }

  static defaultProps = {
    buttonColor: 'primary',
  }

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  handleOpen = () => {
    this.setState({
      isOpen: true,
    });
  };

  handleClose = () => {
    this.setState({
      isOpen: false,
    });
  }

  render() {
    const {
      appointment,
      buttonColor,
      home,
      disabled,
      btnWillExpand,
      setAppointment,
    } = this.props;
    const { isOpen } = this.state;

    return (
      <BookAppointmentModal
        appointment={appointment}
        displayAppointment={appointment}
        home={home}
        isOpen={isOpen}
        disabled={disabled}
        buttonColor={buttonColor}
        btnWillExpand={btnWillExpand}
        onOpen={this.handleOpen}
        onCancel={this.handleClose}
        onSave={setAppointment}
      />
    );
  }
}

export default BookAppointmentModalContainer;
