import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { caseStatuses, serviceTypeIds } from 'constants/bereavement';
import { bereavementType, arrangementType } from 'types/bereavement';
import { apolloClientType } from 'types/apollo';
import ArrangementMusicForm from './ArrangementMusicForm';
import { editArrangementMusicInformation } from './mutations.gql';

class ArrangementMusicFormContainer extends Component {
  static propTypes = {
    bereavement: bereavementType.isRequired,
    arrangement: arrangementType.isRequired,
    client: apolloClientType.isRequired,
    onSave: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      categoryInformation: {
        isConfirmed: false,
        serviceMusic: [],
        serviceOrganists: [],
      },
    };
  }

  componentDidMount() {
    const { arrangement } = this.props;

    if (!arrangement.categoryInformation) {
      return;
    }

    const { music } = arrangement.categoryInformation;
    const categoryInformation = {
      serviceMusic: music && music.serviceMusic ? music.serviceMusic : [],
      serviceOrganists: music && music.serviceOrganists ? music.serviceOrganists : [],
      isConfirmed: music && music.isConfirmed,
    };
    this.setState({ categoryInformation });
  }

  handleChange = (key, value) => {
    this.setState(prevState => ({
      categoryInformation: {
        ...prevState.categoryInformation,
        [key]: value,
      },
    }));
  }

  handleSave = () => {
    this.setState({ isSaving: true });
    const {
      client, bereavement, arrangement, onSave,
    } = this.props;
    const { categoryInformation } = this.state;
    onSave(bereavement.id, arrangement.id, 'music', categoryInformation);

    const input = {
      bereavementId: bereavement.id,
      arrangementId: arrangement.id,
      ...categoryInformation,
    };

    client.mutate({
      mutation: editArrangementMusicInformation,
      variables: { input },
    }).finally(() => this.setState({ isSaving: false }));
  }

  render() {
    const { bereavement, arrangement } = this.props;
    const { isSaving, categoryInformation } = this.state;
    const venueCategoryInformation = arrangement.categoryInformation && {
      [serviceTypeIds.CREMATORIUM]: arrangement.categoryInformation.crematorium,
      [serviceTypeIds.SERVICE_VENUE]: arrangement.categoryInformation.serviceVenue,
      [serviceTypeIds.BURIAL]: arrangement.categoryInformation.cemetery,
    };

    return (
      <ArrangementMusicForm
        categoryInformation={categoryInformation}
        venueCategoryInformation={venueCategoryInformation}
        disabled={isSaving || bereavement.caseStatus === caseStatuses.CLOSED}
        isSaving={isSaving}
        onChange={this.handleChange}
        onSave={this.handleSave}
      />
    );
  }
}

export default withApollo(ArrangementMusicFormContainer);
