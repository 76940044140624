import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { bereavementType, arrangementType } from 'types/bereavement';
import PropTypes from 'prop-types';
import { historyType, matchType } from 'types/reactRouter';
import { apolloClientType } from 'types/apollo';
import { removePropertyByName, focusNewSelection } from 'services/utils';
import { selectPackageAction, unselectPackageAction, unconfirmArrangementAction } from 'actions/arrangement';
import { changeConfirmedArrangementAndPackageMessage, changeConfirmedArrangementMessage, changePackageMessage } from 'constants/arrangement';
import { navigationPathType } from 'types/common';
import ArrangementPackagesScreen from './ArrangementPackagesScreen';
import { getPackages } from './queries.gql';
import { selectPackageMutation } from './mutations.gql';

class ArrangementPackagesScreenContainer extends Component {
  static propTypes = {
    bereavement: bereavementType.isRequired,
    arrangement: arrangementType.isRequired,
    disabled: PropTypes.bool.isRequired,
    navigationPaths: PropTypes.arrayOf(navigationPathType),
    client: apolloClientType.isRequired,
    history: historyType.isRequired,
    match: matchType.isRequired,
    selectPackage: PropTypes.func.isRequired,
    unselectPackage: PropTypes.func.isRequired,
    unconfirmArrangement: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.expandBannerRef = React.createRef();
    this.selectedItemRef = React.createRef();

    this.state = {
      isLoading: true,
      confirmCallback: () => { },
      confirmModalMessage: null,
      packages: [],
      pagination: {
        first: 5,
        endCursor: null,
        hasNextPage: true,
      },
    };
  }

  componentDidMount() {
    this.getPackages();
  }

  getPackages = () => {
    const { client, bereavement } = this.props;
    const { packages, pagination } = this.state;

    this.setState({ isLoading: true });

    client.query({
      query: getPackages,
      variables: {
        pagination: {
          first: pagination.first,
          after: pagination.endCursor,
        },
        organisationalUnitIds: [bereavement.homeId],
      },
    }).then(({ data }) => {
      const { packages: { edges, pageInfo } } = data;
      const updatedPackages = packages.concat(
        edges.map(({ node }) => removePropertyByName(node, '__typename')),
      );
      this.setState({
        packages: updatedPackages,
        isLoading: false,
        pagination: pageInfo,
      });
    });
  }

  getConfirmationModalMessage = (arrangement) => {
    if (arrangement.isConfirmed && arrangement.packageSelection) {
      return changeConfirmedArrangementAndPackageMessage;
    }
    if (arrangement.isConfirmed) {
      return changeConfirmedArrangementMessage;
    }
    if (arrangement.packageSelection) {
      return changePackageMessage;
    }
    return '';
  }

  handleSelectBespoke = () => {
    const {
      bereavement,
      arrangement,
      navigationPaths,
      history,
      match,
      unconfirmArrangement,
    } = this.props;
    const currentIndex = navigationPaths.findIndex(route => route.name === 'Packages');
    const nextPath = navigationPaths[currentIndex + 1];

    if (arrangement.packageSelection || arrangement.isConfirmed) {
      const message = this.getConfirmationModalMessage(arrangement);

      this.setState({
        confirmModalMessage: message,
        confirmCallback: () => {
          if (arrangement.isConfirmed) {
            unconfirmArrangement(bereavement.id, arrangement.id);
          }

          this.unselectPackage();
          const url = match.url.split('/');
          url.splice(-1, 1);
          history.push(`${url.join('/')}/${nextPath.path}`);
        },
      });
      return;
    }

    const url = match.url.split('/');
    url.splice(-1, 1);
    history.push(`${url.join('/')}/${nextPath.path}`);
  }

  handleSelect = (packageItem) => {
    const { bereavement, arrangement, unconfirmArrangement } = this.props;

    if (arrangement.packageSelection || arrangement.isConfirmed) {
      const message = this.getConfirmationModalMessage(arrangement);
      this.setState({
        confirmModalMessage: message,
        confirmCallback: () => {
          if (arrangement.isConfirmed) {
            unconfirmArrangement(bereavement.id, arrangement.id);
          }

          this.selectPackage(packageItem);
          this.setState({
            confirmModalMessage: null,
            confirmCallback: () => { },
          });
        },
      });
      return;
    }

    this.selectPackage(packageItem);
  }

  handleUnselect = () => {
    const { bereavement, arrangement, unconfirmArrangement } = this.props;

    let message = changePackageMessage;
    if (arrangement.isConfirmed) {
      message = changeConfirmedArrangementAndPackageMessage;
    }

    this.setState({
      confirmModalMessage: message,
      confirmCallback: () => {
        if (arrangement.isConfirmed) {
          unconfirmArrangement(bereavement.id, arrangement.id);
        }

        this.unselectPackage();
        this.setState({
          confirmModalMessage: null,
          confirmCallback: () => { },
        });
      },
    });
  }

  selectPackage = (packageItem) => {
    const {
      bereavement, arrangement, client, selectPackage,
    } = this.props;

    selectPackage(bereavement.id, arrangement.id, packageItem);

    const input = {
      bereavementId: bereavement.id,
      arrangementId: arrangement.id,
      packageId: packageItem.id,
    };
    client.mutate({
      mutation: selectPackageMutation,
      variables: { input },
    });
  }

  unselectPackage = () => {
    const {
      bereavement, arrangement, client, unselectPackage,
    } = this.props;

    unselectPackage(bereavement.id, arrangement.id);

    const input = {
      bereavementId: bereavement.id,
      arrangementId: arrangement.id,
      packageId: null,
    };
    client.mutate({
      mutation: selectPackageMutation,
      variables: { input },
    });
  }

  render() {
    const { arrangement, disabled } = this.props;
    const {
      packages, isLoading, pagination, confirmModalMessage, confirmCallback,
    } = this.state;
    const selectedPackage = arrangement.packageSelection && arrangement.packageSelection.package;

    return (
      <ArrangementPackagesScreen
        packages={packages}
        selectedPackage={selectedPackage}
        isLoading={isLoading}
        disabled={disabled}
        hasMorePackages={pagination.hasNextPage}
        confirmModalMessage={confirmModalMessage}
        getPackages={this.getPackages}
        onSelectBespoke={this.handleSelectBespoke}
        onSelect={this.handleSelect}
        onUnselect={this.handleUnselect}
        confirmCallback={confirmCallback}
        cancelCallback={() => this.setState({
          confirmModalMessage: null,
          confirmCallback: () => { },
        })}
        expandBannerRef={this.expandBannerRef}
        focusNewSelection={() => focusNewSelection(this.expandBannerRef, this.selectedItemRef)}
        selectedItemRef={this.selectedItemRef}
      />
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  selectPackage: selectPackageAction,
  unselectPackage: unselectPackageAction,
  unconfirmArrangement: unconfirmArrangementAction,
}, dispatch);

export default withApollo(
  withRouter(connect(null, mapDispatchToProps)(ArrangementPackagesScreenContainer)),
);
