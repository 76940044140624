import React, { Component } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { withSnackbar } from 'notistack';
import { generateHexId } from 'services/utils';
import { historyType, locationType } from 'types/reactRouter';
import { apolloClientType } from 'types/apollo';
import { setUserAction } from 'actions/user';
import { setLocationBeforeErrorAction, setProcessingOfRequestInQueueFailedAction } from 'actions/request';
import LoginScreen from './LoginScreen';
import { login } from './queries.gql';
import { validationSchema } from './validation';

class LoginScreenContainer extends Component {
  static redirect(location, redirect, history) {
    if (redirect && !location.pathname.includes('/login')) {
      history.push(redirect);
    } else {
      history.push('/');
    }
  }

  static propTypes = {
    history: historyType.isRequired,
    location: locationType.isRequired,
    redirect: PropTypes.string.isRequired,
    client: apolloClientType.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    setUser: PropTypes.func.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    locationBeforeError: PropTypes.string,
    setLocationBeforeError: PropTypes.func.isRequired,
    setProcessingOfRequestInQueueFailed: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      formData: {
        username: '',
        password: '',
      },
      isLoading: false,
      isValidationEnabled: false,
    };

    this.usernameRef = React.createRef();
  }

  componentDidMount() {
    if (this.usernameRef && this.usernameRef.current) {
      this.usernameRef.current.focus();
    }
  }

  handleChange = (key, value) => {
    const { validate } = this.props;
    const { formData, isValidationEnabled } = this.state;
    const updatedFormData = {
      ...formData,
      [key]: value,
    };

    if (isValidationEnabled) {
      validate(updatedFormData, validationSchema);
    }

    this.setState({ formData: updatedFormData });
  }

  handleLogin = (event) => {
    event.preventDefault();
    const {
      history,
      location,
      redirect,
      client,
      setUser,
      enqueueSnackbar,
      validate,
      locationBeforeError,
      setLocationBeforeError,
      setProcessingOfRequestInQueueFailed,
    } = this.props;
    const { formData } = this.state;

    const isValid = validate(formData, validationSchema);
    this.setState({ isValidationEnabled: true });
    if (!isValid) {
      return;
    }

    this.setState({ isLoading: true });

    setLocationBeforeError(null);
    setProcessingOfRequestInQueueFailed(false);

    client.query({
      query: login,
      variables: {
        public: formData.username,
        secret: formData.password,
      },
    }).then(({ data }) => {
      const token = data.devToken ? data.devToken : data.token;
      if (!token) {
        enqueueSnackbar(
          t('Login failed - please check your username and password'),
          { variant: 'error' },
        );
        return;
      }
      token.xeroStateCode = generateHexId();
      setUser(token);
      LoginScreenContainer.redirect(location, locationBeforeError ?? redirect, history);
    }).finally(() => {
      this.setState({
        isLoading: false,
      });
    });
  }

  render() {
    const { errors } = this.props;
    const {
      formData,
      isLoading,
      isValidationEnabled,
    } = this.state;

    return (
      <LoginScreen
        formData={formData}
        usernameRef={this.usernameRef}
        isLoading={isLoading}
        errors={isValidationEnabled ? errors : {}}
        onChange={this.handleChange}
        onLogin={this.handleLogin}
      />
    );
  }
}

const mapStateToProps = state => ({
  locationBeforeError: state.requestQueueStore.locationBeforeError,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setUser: setUserAction,
  setLocationBeforeError: setLocationBeforeErrorAction,
  setProcessingOfRequestInQueueFailed: setProcessingOfRequestInQueueFailedAction,
}, dispatch);

export default withApollo(
  withRouter(
    withValidation(
      withSnackbar(
        connect(mapStateToProps, mapDispatchToProps)(LoginScreenContainer),
      ),
    ),
  ),
);
