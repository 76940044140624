import TemplateDetails from 'components/letterTemplates/TemplateDetails';
import UploadTemplate from 'components/letterTemplates/UploadTemplate';

export const generateTemplateSteps = [
  {
    title: 'Template details',
    component: TemplateDetails,
  },
  {
    title: 'Upload template',
    component: UploadTemplate,
  },
];
