import React from 'react';
import PropTypes from 'prop-types';
import styles from 'scss/main.scss';

import UnsortableCategoryItem from 'components/catalogue/UnsortableCategoryItem';

const UnsortableCategoryList = ({
  categories,
  onEditCategory,
}) => (
  <div
    className={styles.c_grid__3col}
    data-test-id="unsortableCategoryListContainer"
  >
    {categories.map((category, index) => (
      <UnsortableCategoryItem
        category={category}
        key={index}
        onEditCategory={onEditCategory}
      />
    ))}
  </div>
);

UnsortableCategoryList.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.any),
  onEditCategory: PropTypes.func.isRequired,
};

export default UnsortableCategoryList;
