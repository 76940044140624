import styled from 'styled-components';

const ExpansionLink = styled.a`
  color: #5994D4;
  cursor: pointer;
  display: inline-block;
  margin-left: 3px;
  text-transform: lowercase;
  transition: all 0.4s;
  white-space: nowrap

  &:hover {
    color: darken(#5994D4, 10%);
  }
`;

const Ellipsis = styled.span`
  letter-spacing: 1px;
  margin-left: 0.25rem;
`;

export default {
  Text,
  ExpansionLink,
  Ellipsis,
};
