import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';

import AddressFormSection from 'components/common/AddressFormSection';
import DateTimePicker from 'components/common/DateTimePicker';
import IconLoad from 'icons/IconLoad';
import styles from 'scss/main.scss';
import { getSuggestedArrangementVenueDates } from 'services/utils/arrangement';
import { arrangementType } from 'types/bereavement';

import rcStyles from './styles.scss';

const ArrangementReceptionVenueForm = ({
  arrangement,
  formData,
  disabled,
  isSaving,
  errors,
  formRefs,
  onChange,
  onNumericChange,
  onSave,
}) => {
  const { startDateTime, endDateTime } = formData;
  return (
    <div className={rcStyles.v_spacing}>
      {isSaving && (
        <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
          <IconLoad title={t('Saving')} />
        </div>
      )}
      <div ref={formRefs.startDateTime}>
        <DateTimePicker
          value={startDateTime || null}
          label={t('Start date & time')}
          placeholder={t('Start date & time')}
          isStartDate
          maxDate={endDateTime}
          timeRange={[startDateTime, endDateTime]}
          getSuggestedDates={() => getSuggestedArrangementVenueDates(arrangement)}
          onChange={date => onChange('startDateTime', date)}
          onClearEndDate={() => onChange('endDateTime', null)}
          isClearable
          disabled={disabled}
          errors={errors && !!errors.startDateTime}
          helperText={errors && errors.startDateTime}
          data-test-id="startDateTime"
        />
      </div>
      <div ref={formRefs.endDateTime}>
        <DateTimePicker
          value={endDateTime || null}
          label={t('End date & time')}
          placeholder={t('End date & time')}
          isEndDate
          minDate={startDateTime}
          timeRange={[startDateTime, endDateTime]}
          onChange={date => onChange('endDateTime', date)}
          isClearable
          disabled={disabled}
          errors={errors && !!errors.endDateTime}
          helperText={errors && errors.endDateTime}
          data-test-id="endDateTime"
        />
      </div>
      <div
        className={rcStyles.col1}
        ref={formRefs.arrangedByFamily}
      >
        <FormControlLabel
          control={(
            <Checkbox
              checked={formData.arrangedByFamily || false}
              onChange={event => onChange('arrangedByFamily', event.target.checked)}
              inputProps={{ 'data-test-id': 'arrangedByFamily' }}
              disabled={disabled}
              color="primary"
            />
          )}
          label={t('Arranged by family')}
        />
      </div>
      <div ref={formRefs.catering}>
        <FormControlLabel
          control={(
            <Checkbox
              checked={formData.catering || false}
              onChange={event => onChange('catering', event.target.checked)}
              inputProps={{ 'data-test-id': 'catering' }}
              disabled={disabled}
              color="primary"
            />
          )}
          label={t('Catering')}
        />
      </div>
      <div
        className={rcStyles.col1}
        ref={formRefs.expectedAttendees}
      >
        <TextField
          type="number"
          label={t('Expected number of guests')}
          value={formData.expectedAttendees || ''}
          onChange={event => onNumericChange('expectedAttendees', event.target.value)}
          disabled={disabled}
          error={errors && errors.expectedAttendees}
          helperText={errors && errors.expectedAttendees}
          fullWidth
          inputProps={{
            'data-test-id': 'expectedAttendees',
            'min': 0,
          }}
        />
      </div>
      <div
        className={classNames(
          rcStyles.col1,
          rcStyles.colspan2,
        )}
        ref={formRefs.alternativeLocation}
      >
        <AddressFormSection
          address={formData.alternativeLocation}
          onChange={address => onChange('alternativeLocation', address)}
          data-test-id="alternativeLocation"
          lookupLabel={t('Alternate location')}
          errors={errors.alternativeLocation}
          disabled={disabled}
        />
      </div>
      <div
        className={classNames(
          rcStyles.col1,
          rcStyles.colspan2,
        )}
        ref={formRefs.notes}
      >
        <TextField
          label={t('Notes')}
          value={formData.notes || ''}
          onChange={event => onChange('notes', event.target.value)}
          inputProps={{
            'data-test-id': 'notes',
          }}
          disabled={disabled}
          multiline
          fullWidth
        />
      </div>
      <div
        className={rcStyles.col1}
        ref={formRefs.sequence}
      >
        <RadioGroup
          aria-label={t('sequence')}
          name="sequence"
          value={`${formData.sequence}`}
          onChange={event => onChange('sequence', event.target.value)}
          row
          data-test-id="sequenceRadioGroup"
        >
          <FormControlLabel
            value="PRE_SERVICE"
            control={<Radio color="primary" disabled={disabled} data-test-id="sequencePreService" />}
            label={t('Pre service')}
          />
          <FormControlLabel
            value="POST_SERVICE"
            control={<Radio color="primary" disabled={disabled} data-test-id="sequencePostService" />}
            label={t('Post service')}
          />
        </RadioGroup>
      </div>
      {!formData.arrangedByFamily && (
        <div
          ref={formRefs.isConfirmed}
          className={classNames(
            styles.c_confirmed__wrap,
            rcStyles.col1,
            rcStyles.colspan2,
          )}
        >
          <FormControlLabel
            control={(
              <Checkbox
                className={styles.c_confirmed__checkbox}
                checked={formData.isConfirmed || false}
                onChange={event => onChange('isConfirmed', event.target.checked)}
                inputProps={{ 'data-test-id': 'isConfirmed' }}
                disabled={disabled}
                color="primary"
              />
            )}
            label={t('Booking confirmed with reception venue')}
          />
        </div>
      )}
      <span className={rcStyles.col1}>
        <Button
          onClick={onSave}
          color="primary"
          variant="contained"
          disabled={disabled}
          data-test-id="saveReceptionVenueCategory"
        >
          {t('Save')}
        </Button>
      </span>
    </div>
  );
};

ArrangementReceptionVenueForm.propTypes = {
  arrangement: arrangementType.isRequired,
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
  disabled: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onNumericChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ArrangementReceptionVenueForm;
