import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { updateAgeAtDeath } from 'services/utils/bereavement';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { bereavementType } from 'types/bereavement';
import { validationSchema } from './validation';
import FirstCallDeceasedForm from './FirstCallDeceasedForm';

class FirstCallDeceasedFormContainer extends Component {
  static propTypes = {
    bereavement: bereavementType.isRequired,
    updateFullBereavement: PropTypes.func.isRequired,
    updateBereavementByKey: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    validate: PropTypes.func.isRequired,
    isValidationEnabled: PropTypes.bool,
    setIsValid: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isCaseView: PropTypes.bool,
  }

  componentDidMount() {
    const { bereavement } = this.props;
    this.validate(bereavement.deceasedPeople[0]);
  }

  validate = (deceased) => {
    const { setIsValid, validate } = this.props;
    const isValid = validate(deceased, validationSchema);

    if (setIsValid) {
      setIsValid(isValid);
    }
  }

  handleDeceasedChange = (key, value) => {
    const { updateFullBereavement, bereavement } = this.props;

    const updatedBereavement = {
      ...bereavement,
      deceasedPeople: [{
        ...bereavement.deceasedPeople[0],
        [key]: value,
      }],
    };

    if (key === 'dateOfBirth' || key === 'deathDateTime') {
      updatedBereavement.deceasedPeople[0] = updateAgeAtDeath(updatedBereavement.deceasedPeople[0]);
    }
    if (key === 'isReadyForCollection' || key === 'collectionLocation') {
      const { collectionLocation, transfersIntoCare } = updatedBereavement.deceasedPeople[0];
      const transferIntoCare = transfersIntoCare[0];
      if (!!collectionLocation && !transferIntoCare.fromLocation) {
        updatedBereavement.deceasedPeople[0].transfersIntoCare = [{
          ...transferIntoCare,
          fromLocation: { ...collectionLocation },
        }];
      }
    }

    this.validate(updatedBereavement.deceasedPeople[0], validationSchema);
    updateFullBereavement(updatedBereavement);
  }

  handleDeceasedTransferIntoCareChange = (key, value) => {
    const { bereavement } = this.props;
    const transferIntoCare = bereavement.deceasedPeople[0].transfersIntoCare[0];
    const updatedTransfersIntoCare = [{
      ...transferIntoCare,
      [key]: value,
    }];
    this.handleDeceasedChange('transfersIntoCare', updatedTransfersIntoCare);
  }

  handleCoronerChange = (nameAddress) => {
    const { updateFullBereavement, bereavement } = this.props;
    const { name, directoryListingId, directoryListing } = nameAddress;

    updateFullBereavement({
      ...bereavement,
      coronerName: name,
      coronerDirectoryId: directoryListingId,
      coronerDirectoryListing: directoryListing,
    });
  }

  render() {
    const {
      errors, isValidationEnabled, disabled, isCaseView, bereavement, updateBereavementByKey,
    } = this.props;

    return (
      <FirstCallDeceasedForm
        bereavement={bereavement}
        onDeceasedChange={this.handleDeceasedChange}
        onDeceasedTransferIntoCareChange={this.handleDeceasedTransferIntoCareChange}
        onBereavementChange={updateBereavementByKey}
        onCoronerChange={this.handleCoronerChange}
        errors={isValidationEnabled ? errors : {}}
        disabled={disabled}
        isCaseView={isCaseView}
      />
    );
  }
}

export default withValidation(FirstCallDeceasedFormContainer);
