import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Slide from '@material-ui/core/Slide';
import DateTimePicker from 'components/common/DateTimePicker';
import StaffMemberLookup from 'components/common/lookups/StaffMemberLookup';
import LocationFormSection from 'components/common/LocationFormSection';
import { appointmentType, homeType } from 'types/bereavement';
import { reactRefType } from 'types/common';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const AppointmentModal = ({
  appointment,
  home,
  isOpen,
  dialogRef,
  formRefs,
  errors,
  onChange,
  onCancel,
  onSave,
}) => (
  <Dialog
    onClose={onCancel}
    aria-labelledby="book-appointment-title"
    open={isOpen}
    fullScreen
    scroll="paper"
    TransitionComponent={Transition}
    disableBackdropClick
    disableEscapeKeyDown
  >
    <div
      ref={dialogRef}
      className={classNames(
        styles.c_dialog__body,
        styles.c_dialog__overflow,
      )}
    >
      <DialogTitle
        disableTypography
        className={styles.dialog_title_bar}
      >
        <IconButton
          color="primary"
          variant="contained"
          aria-label="Close"
          onClick={onCancel}
          data-test-id="cancel"
          className={classNames(
            styles.o_button__secondary,
            styles.is_close,
          )}
        >
          <CloseIcon />
        </IconButton>
        <div className={classNames(styles.is_title)}>
          {t('Book Appointment')}
        </div>
        <div className={styles.is_actions}>
          <Button
            onClick={onSave}
            variant="contained"
            color="secondary"
            data-test-id="saveAppointment"
          >
            {t('Save Appointment')}
          </Button>
        </div>
      </DialogTitle>
      <DialogContent className={styles.c_dialog__content}>
        <div
          className={classNames(
            rcStyles.v_spacing,
            styles.o_block_content,
          )}
        >
          <DateTimePicker
            value={appointment.dateTime || null}
            label={t('Date & time *')}
            onChange={date => onChange('dateTime', date)}
            isClearable
            errors={errors && !!errors.dateTime}
            helperText={errors && errors.dateTime}
            data-test-id="dateTime"
          />
          <div ref={formRefs.address}>
            <LocationFormSection
              value={appointment.address}
              valueKey="address"
              errors={errors.address}
              data-test-id="locationFormSection"
              onChange={onChange}
            />
          </div>
          <div ref={formRefs.staffMember}>
            <InputLabel
              classes={{ root: rcStyles.rc_select_label }}
            >
              {t('Select staff member *')}
            </InputLabel>
            <StaffMemberLookup
              value={appointment.staffMember}
              placeholder=""
              organisationalUnits={home && [home.id]}
              onChange={changedValue => onChange('staffMember', changedValue)}
              isSearchable={false}
              data-test-id="staffMemberLookup"
            />
            {errors.staffMember && (
              <FormHelperText error>{errors.staffMember}</FormHelperText>
            )}
          </div>
        </div>
      </DialogContent>
    </div>
  </Dialog>
);

AppointmentModal.propTypes = {
  appointment: appointmentType.isRequired,
  home: homeType,
  isOpen: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  dialogRef: reactRefType.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any),
};

export default AppointmentModal;
