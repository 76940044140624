import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CharityJustGivingForm from 'components/charity/CharityJustGivingForm';
import Checkbox from '@material-ui/core/Checkbox';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const CharityFormSection = ({
  charityInformation,
  disabled,
  errors,
  onAdd,
  onRemove,
  onChange,
}) => {
  const { justGivingCharities, localCharities } = charityInformation;
  const charities = [...justGivingCharities, ...localCharities];

  return (
    <div className={classNames(
      rcStyles.v_spacing,
      rcStyles.charity_block,
    )}
    >
      <div className={rcStyles.charity__buttons}>
        <Button
          color="primary"
          variant="outlined"
          size="medium"
          disabled={disabled || charities.length >= 3}
          onClick={() => onAdd('justGivingCharities')}
          data-test-id="addJustGivingCharity"
        >
          <AddIcon />
          {t('Add Online Charity')}
        </Button>
        <Button
          color="primary"
          variant="outlined"
          size="medium"
          disabled={disabled || charities.length >= 3}
          onClick={() => onAdd('localCharities')}
          data-test-id="addOfflineCharity"
        >
          <AddIcon />
          {t('Add Offline Charity')}
        </Button>
      </div>
      {justGivingCharities.map((charity, index) => (
        <Fragment key={`justGivingCharities-${index}`}>
          <div className={rcStyles.charity_list__row}>
            <div className={rcStyles.charity_list__row_body}>
              <CharityJustGivingForm
                formData={charity}
                disabled={disabled}
                errors={errors && errors.justGivingCharities && errors.justGivingCharities[index]}
                onChange={updatedCharity => onChange('justGivingCharities', index, updatedCharity)}
                data-test-id="justGivingForm"
              />
            </div>
            <div className={rcStyles.charity_list__row_action}>
              <Button
                onClick={() => onRemove('justGivingCharities', index)}
                classes={{ root: styles.o_button__round }}
                data-test-id="removeJustGivingCharity"
                color="primary"
                variant="outlined"
              >
                <RemoveIcon />
              </Button>
            </div>
          </div>
        </Fragment>
      ))}
      {localCharities.map((charity, index) => (
        <Fragment key={`localCharities-${index}`}>
          <div className={rcStyles.charity_list__row}>
            <div className={rcStyles.charity_list__row_body}>
              <div className={rcStyles.v_spacing}>
                <TextField
                  label={t('Offline charity name')}
                  value={charity.name || ''}
                  onChange={event => onChange('localCharities', index, {
                    ...charity,
                    name: event.target.value,
                  })}
                  inputProps={{
                    'data-test-id': 'offlineCharityName',
                  }}
                  disabled={disabled}
                  error={errors && errors.localCharities && errors.localCharities[index]}
                  helperText={
                    errors
                    && errors.localCharities
                    && errors.localCharities[index]
                    && errors.localCharities[index].name
                  }
                  fullWidth
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={charity.sendDonationsToFamily || false}
                      onChange={event => onChange('localCharities', index, {
                        ...charity,
                        sendDonationsToFamily: event.target.checked,
                      })}
                      inputProps={{ 'data-test-id': 'charitySendDonationsToFamily' }}
                      disabled={disabled}
                      color="primary"
                    />
                  )}
                  label={t('Send client donations directly')}
                />
              </div>
            </div>
            <div className={rcStyles.charity_list__row_action}>
              <Button
                onClick={() => onRemove('localCharities', index)}
                classes={{ root: styles.o_button__round }}
                data-test-id="removeIcon"
                color="primary"
                variant="outlined"
              >
                <RemoveIcon />
              </Button>
            </div>
          </div>
        </Fragment>
      ))}
    </div>
  );
};

CharityFormSection.propTypes = {
  charityInformation: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool.isRequired,
  errors: PropTypes.objectOf(PropTypes.any),
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CharityFormSection;
