import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { historyType } from 'types/reactRouter';
import { arrangementSettingsCategories } from 'constants/catalogue';
import { apolloClientType } from 'types/apollo';
import PropTypes from 'prop-types';
import { categories as constantCategories } from 'constants/arrangement';
import { arrayMove } from 'services/utils';
import { categoryOrderMutationTransform } from 'transforms/catalogue';
import { setArrangementCategoryDisplayPreferencesAction } from 'actions/user';
import { setArrangementCategoryDisplayPreferences as setArrangementCategoryDisplayPreferencesMutation } from './mutations.gql';
import { getCategoryOrder } from './queries.gql';
import AdminArrangementSettingsScreen from './AdminArrangementSettingsScreen';

export class AdminArrangementSettingsScreenContainer extends Component {
  static propTypes = {
    tenantId: PropTypes.string,
    history: historyType,
    client: apolloClientType,
    setArrangementCategoryDisplayPreferences: PropTypes.func.isRequired,
    availableFeatures: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    tenantCategories: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      isLoading: true,
      isSaving: false,
      isSortEnabled: false,
      isVisibilityEnabled: false,
    };
  }

  componentDidMount() {
    this.getCategories();
  }

  getCategories() {
    const {
      client,
      tenantId,
      setArrangementCategoryDisplayPreferences,
    } = this.props;

    client.query({
      query: getCategoryOrder,
      variables: { tenantId },
    }).then(({ data }) => {
      const { tenant: { arrangementCategoryDisplayPreferences } } = data;

      this.setCategories(
        arrangementCategoryDisplayPreferences
          .filter(({ category }) => ![constantCategories.OTHER, 'OTHER_SERVICES'].includes(category)),
      );

      setArrangementCategoryDisplayPreferences(arrangementCategoryDisplayPreferences);
    });
  }

  setCategories = (categories = []) => {
    const {
      availableFeatures,
      tenantCategories,
    } = this.props;

    if (categories.length !== arrangementSettingsCategories.length) {
      arrangementSettingsCategories.forEach((defaultCategory) => {
        if (!categories.find(item => item.category === defaultCategory.category)) {
          categories.push({ ...defaultCategory, visible: true });
        }
      });
    }

    const featureCategories = availableFeatures
      .filter(feature => feature.includes('category'))
      .map(feature => feature.replace('category', '').toUpperCase());

    this.setState({
      categories: categories.map(category => ({
        ...category,
        ...arrangementSettingsCategories
          .find(categoryData => (categoryData.category === category.category)),
      })).filter((category) => {
        if (category.requiresFeatureFlag) {
          return tenantCategories.includes(category.category) && featureCategories.includes(category.category);
        }
        return tenantCategories.includes(category.category);
      }),
      isLoading: false,
    });
  }

  handleEditCategory = (category) => {
    const { history } = this.props;
    history.push(`/catalogue/arrangement-settings/${category}`);
  }

  handleCancel = () => {
    const { history } = this.props;
    history.push('/catalogue');
  }

  handleEnableSort = () => {
    this.setState(prevState => ({
      isSortEnabled: !prevState.isSortEnabled,
    }));
  }

  handleSetOrder = () => {
    this.setState({ isSortEnabled: false });
  }

  handleSortCategories = ({ oldIndex, newIndex }) => {
    const { categories } = this.state;
    const reorderedCategories = arrayMove(categories, oldIndex, newIndex);

    this.setState({ categories: reorderedCategories });
  }

  handleSave = () => {
    const { categories } = this.state;
    const {
      client,
      tenantId,
      setArrangementCategoryDisplayPreferences,
    } = this.props;
    const arrangementCategoryDisplayPreferences = categoryOrderMutationTransform(categories);

    this.setState({ isSaving: true });
    client.mutate({
      mutation: setArrangementCategoryDisplayPreferencesMutation,
      variables: {
        input: {
          tenantId,
          arrangementCategoryDisplayPreferences,
        },
      },
    }).finally(() => {
      this.setState({ isSaving: false });
      setArrangementCategoryDisplayPreferences(arrangementCategoryDisplayPreferences);
    });
  }

  render() {
    const {
      categories,
      isLoading,
      isSaving,
      isSortEnabled,
      isVisibilityEnabled,
    } = this.state;

    return (
      <AdminArrangementSettingsScreen
        isSortEnabled={isSortEnabled}
        isVisibilityEnabled={isVisibilityEnabled}
        isLoading={isLoading}
        isSaving={isSaving}
        categories={categories}
        onEditCategory={this.handleEditCategory}
        onCancel={this.handleCancel}
        onEnableSort={this.handleEnableSort}
        onSetOrder={this.handleSetOrder}
        onSortCategories={this.handleSortCategories}
        onSave={this.handleSave}
      />
    );
  }
}

const mapStateToProps = state => ({
  tenantId: state.userStore.user.tenantId,
  availableFeatures: state.userStore.user.tenantFeatureFlags,
  tenantCategories: state.userStore.user.tenantCategories,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setArrangementCategoryDisplayPreferences: setArrangementCategoryDisplayPreferencesAction,
}, dispatch);

export default withApollo(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AdminArrangementSettingsScreenContainer),
  ),
);
