import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconLoad from 'icons/IconLoad';

import styles from 'scss/main.scss';
import rcStyles from './styles.scss';


const ArrangementMemorialisationForm = ({
  categoryInformation,
  disabled,
  isSaving,
  onChange,
  onSave,
}) => (
  <div className={rcStyles.v_spacing}>
    <TextField
      label={t('Note')}
      value={categoryInformation.notes || ''}
      onChange={event => onChange('notes', event.target.value)}
      disabled={disabled}
      inputProps={{
        'data-test-id': 'memorialisation-note',
      }}
      fullWidth
    />
    <div className={styles.c_confirmed__wrap}>
      <FormControlLabel
        control={(
          <Checkbox
            className={styles.c_confirmed__checkbox}
            checked={categoryInformation.isConfirmed || false}
            onChange={event => onChange('isConfirmed', event.target.checked)}
            inputProps={{ 'data-test-id': 'isConfirmed' }}
            disabled={disabled}
            color="primary"
          />
        )}
        label={t('Order placed/Availability confirmed')}
      />
    </div>
    <span>
      <Button
        onClick={onSave}
        color="primary"
        variant="contained"
        disabled={disabled}
        data-test-id="saveMemorialisationInformation"
      >
        {t('Save')}
      </Button>
    </span>
    {isSaving && (
      <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
        <IconLoad title={t('Saving')} />
      </div>
    )}
  </div>
);

ArrangementMemorialisationForm.propTypes = {
  categoryInformation: PropTypes.objectOf(PropTypes.any),
  isSaving: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default ArrangementMemorialisationForm;
