import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { arrangementSettingsCategories } from 'constants/catalogue';
import AdminArrangementPackagesOrderScreen from 'screens/admin/AdminArrangementPackagesOrderScreen';
import AdminArrangementProductsServicesOrderScreen from 'screens/admin/AdminArrangementProductsServicesOrderScreen';
import AdminArrangementSettingsCategoryScreen from 'screens/admin/AdminArrangementSettingsCategoryScreen';
import { matchType } from 'types/reactRouter';

class AdminArrangementSettingsCategoryRouter extends Component {
  static propTypes = {
    match: matchType.isRequired,
  }

  constructor(props) {
    super(props);
    const {
      match: {
        params: {
          category,
        },
      },
    } = props;

    const arrangementSettingsCategory = arrangementSettingsCategories.find(
      catalogueCategory => catalogueCategory.path === category,
    );

    this.state = {
      category: arrangementSettingsCategory,
      subCategories: arrangementSettingsCategory?.resourceCategories || [],
    };
  }

  render() {
    const { category, subCategories } = this.state;

    if (subCategories.length > 1) {
      return (
        <AdminArrangementSettingsCategoryScreen
          category={category}
          subCategories={subCategories}
        />
      );
    }

    if (category.category === 'PACKAGES') {
      return (
        <AdminArrangementPackagesOrderScreen
          category={category}
          subCategories={subCategories}
        />
      );
    }

    return (
      <AdminArrangementProductsServicesOrderScreen
        category={category}
        subCategories={subCategories}
      />
    );
  }
}

export default withRouter(AdminArrangementSettingsCategoryRouter);
