import React from 'react';
import i18next from 'i18next';
import { formatCurrency } from 'services/utils';
import rcStyles from './styles.scss';
import { Props } from './FuneralSafeIndicativeCostsTable.types';

const FuneralSafeIndicativeCostsTable: React.FC<Props> = ({ monthlyPayments }: Props) => (
  <div className={rcStyles.costs_table}>
    <div className={rcStyles.header}>
      {monthlyPayments.map(item => (
        <div className={rcStyles.costs_table__item} key={item.term}>
          <b>{item.term}</b>
          {' '}
          <span className={rcStyles.is_months}>{i18next.t('months')}</span>
        </div>
      ))
      }
    </div>
    <div className={rcStyles.body}>
      {monthlyPayments.map(item => (
        <div className={rcStyles.costs_table__item} key={item.term}>
          {formatCurrency(item.amount)}
        </div>
      ))
      }
    </div>
  </div>
);

export default FuneralSafeIndicativeCostsTable;
