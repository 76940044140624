import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import Slide from '@material-ui/core/Slide';
import OrganisationalUnitLookup from 'components/common/lookups/OrganisationalUnitLookup';
import StaffMemberLookup from 'components/common/lookups/StaffMemberLookup';
import { nameType } from 'types/common';
import { organisationalUnitTypes } from 'constants/organisationalUnits';
import IconDanger from 'icons/IconDanger';
import IconLoadSml from 'icons/IconLoadSml';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const dialogClasses = {
  paper: rcStyles.rc_dialog__paper,
};

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const AssignCaseModal = ({
  title,
  saveButtonText,
  selectedHome,
  selectedOwner,
  isOpen,
  isSaving,
  displayDeceasedError,
  displayBereavedError,
  onHomeChange,
  onOwnerChange,
  onClose,
  onSave,
  errors,
  formRefs,
}) => (
  <Dialog
    onClose={onClose}
    aria-labelledby="assign-case-title"
    open={isOpen}
    fullWidth
    maxWidth="sm"
    classes={dialogClasses}
    TransitionComponent={Transition}
    disableBackdropClick
    disableEscapeKeyDown
  >
    <DialogTitle disableTypography className={styles.c_dialog__header}>
      <div className={styles.c_dialog__title}>
        {title}
      </div>
    </DialogTitle>
    <DialogContent className={styles.c_dialog__content}>
      <div className={classNames(styles.c_errors)}>
        {displayBereavedError && (
          <div className={classNames(styles.c_error)}>
            <IconDanger className={classNames(styles.c_error__icon)} />
            <span className={classNames(styles.c_error__msg)}>
              <Typography color="error">{t('At least one client is required')}</Typography>
            </span>
          </div>
        )}
        {displayDeceasedError && (
          <div className={classNames(styles.c_error)}>
            <IconDanger className={classNames(styles.c_error__icon)} />
            <span className={classNames(styles.c_error__msg)}>
              <Typography color="error">{t('There are errors in the Deceased form')}</Typography>
            </span>
          </div>
        )}
      </div>
      <div
        ref={formRefs.homeId}
        className={styles.u_push__bottom_double}
      >
        {errors.homeId && (<FormHelperText error>{errors.homeId}</FormHelperText>)}
        <InputLabel
          classes={{ root: rcStyles.rc_select_label }}
        >
          {t('Select home *')}
        </InputLabel>
        <OrganisationalUnitLookup
          value={selectedHome}
          placeholder=""
          onChange={onHomeChange}
          type={organisationalUnitTypes.HOME}
          data-test-id="homeLookup"
        />
      </div>
      <div ref={formRefs.ownerId}>
        {errors.ownerId && (<FormHelperText error>{errors.ownerId}</FormHelperText>)}
        <InputLabel
          classes={{ root: rcStyles.rc_select_label }}
        >
          {t('Assigned to *')}
        </InputLabel>
        <StaffMemberLookup
          value={selectedOwner}
          placeholder=""
          organisationalUnits={selectedHome && [selectedHome.id]}
          onChange={onOwnerChange}
          disabled={!selectedHome}
          cacheUniq={selectedHome && selectedHome.id}
          data-test-id="staffMemberLookup"
        />
      </div>
    </DialogContent>
    <DialogActions className={styles.c_dialog__actions}>
      <Button
        onClick={onClose}
        variant="outlined"
        color="primary"
        data-test-id="cancelCase"
        fullWidth
      >
        {t('Cancel')}
      </Button>
      <Button
        onClick={onSave}
        variant="contained"
        color="primary"
        data-test-id="saveCase"
        fullWidth
      >
        {isSaving ? (
          <div className={classNames(
            styles.c_svg_loader_sml,
            styles.c_svg_loader_sml_white,
            styles.c_svg_loader_sml_btn,
          )}
          >
            <IconLoadSml title={t('Saving')} />
          </div>
        ) : t(saveButtonText)}
      </Button>
    </DialogActions>
  </Dialog>
);

AssignCaseModal.propTypes = {
  title: PropTypes.string.isRequired,
  saveButtonText: PropTypes.string,
  selectedHome: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  selectedOwner: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: nameType.isRequired,
  }),
  isOpen: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool,
  displayDeceasedError: PropTypes.bool.isRequired,
  displayBereavedError: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onHomeChange: PropTypes.func.isRequired,
  onOwnerChange: PropTypes.func.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any),
};

AssignCaseModal.defaultProps = {
  saveButtonText: 'Save',
};

export default AssignCaseModal;
