import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { buildNameString, getUriForMediaByVariant } from 'services/utils';
import { mediaVariants } from 'constants/media';
import { possessionTypes, possessionActionTaken, possessionsFamilyWishes } from 'constants/possession';
import { possessionType } from 'types/bereavement';
import PossessionItem from './PossessionItem';

class PossessionItemContainer extends Component {
  static propTypes = {
    possession: possessionType.isRequired,
    disabled: PropTypes.bool.isRequired,
    onEdit: PropTypes.func,
  }

  getImageVariant = item => (
    item && getUriForMediaByVariant(item, mediaVariants.FIT_200_X_200)
  );

  getType = (typeName) => {
    const selectedType = possessionTypes.find(type => type.value === typeName);
    return selectedType.placeholder;
  }

  getActionTaken = (actionTaken) => {
    const selectedType = possessionActionTaken.find(type => type.value === actionTaken.action);
    return selectedType.placeholder;
  }

  getLastAction = (actionHistory) => {
    const orderedActions = actionHistory
      .slice()
      .sort((action1, action2) => (action1.timeCreated > action2.timeCreated ? -1 : 1));

    const action = orderedActions[0];
    const staffName = action.createdBy ? `${buildNameString(action.createdBy.name)},` : '';
    const actionDescription = this.getActionTaken(action);
    return `${actionDescription}: ${staffName} ${moment(action.timeCreated).format('DD MMM - HH:mm')}`;
  }

  getPastActions = (actionHistory) => {
    const orderedActions = actionHistory
      .slice()
      .sort((action1, action2) => (action1.timeCreated > action2.timeCreated ? -1 : 1));
    orderedActions.splice(0, 1);

    return orderedActions.map((action) => {
      const staffName = action.createdBy ? `${buildNameString(action.createdBy.name)},` : '';
      return `${this.getActionTaken(action)}: ${staffName} ${moment(action.timeCreated).format('DD MMM - HH:mm')}`;
    });
  }

  getFamilyWishesPlaceholder = (value) => {
    const familyWish = possessionsFamilyWishes.find(familyWishes => familyWishes.value === value);
    return familyWish ? familyWish.placeholder : '';
  }

  render() {
    const { possession, disabled, onEdit } = this.props;

    return (
      <PossessionItem
        possession={possession}
        disabled={disabled}
        onEdit={onEdit}
        getImageVariant={this.getImageVariant}
        getType={this.getType}
        getLastAction={this.getLastAction}
        getPastActions={this.getPastActions}
        getFamilyWishesPlaceholder={this.getFamilyWishesPlaceholder}
      />
    );
  }
}

export default PossessionItemContainer;
