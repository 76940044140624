import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const IconCelebrant = (props) => {
  const { className } = props;
  const classes = className ? `${className} ${styles.rc_icon}` : styles.rc_icon;

  return (
    <svg viewBox="3 5.2 28 28" className={classes}>
      <path d="M25.7,22.5a11.43,11.43,0,0,1,.7,6.5H23V21.4l-5.8,2.1-5.7-2.1V29H7.7a10.57,10.57,0,0,1,.6-6.5C10,18.4,17,18.4,17,18.4S24,18.5,25.7,22.5ZM21.3,24h-.1v5h-8V24l3.7,1.4.3.1.3-.1ZM17,16.9a4.4,4.4,0,1,1,4.4-4.4A4.4,4.4,0,0,1,17,16.9Z" />
    </svg>
  );
};

IconCelebrant.propTypes = {
  className: PropTypes.string,
};

export default IconCelebrant;
