import React from 'react';
import { withRouter } from 'react-router-dom';
import { locationType } from 'types/reactRouter';

class ScrollToTop extends React.Component {
  static propTypes = {
    location: locationType,
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(ScrollToTop);
