import React from 'react';
import i18next from 'i18next';
import { Link } from 'react-router-dom';
import DoneIcon from '@material-ui/icons/Done';

import rcStyles from './styles.scss';
import { Props } from './index.types';

const ArrangementCategoryLink: React.FC<Props> = ({
  arrangementId, label, path, isConfirmed, title, description,
}: Props) => (
  <Link
    to={`arrangement/${arrangementId}/${path}`}
    className={rcStyles.arrangement__category}
  >
    <div className={rcStyles.is_confirmed}>
      {isConfirmed && <DoneIcon className={rcStyles.is_icon} />}
    </div>
    <div>
      <h3 className={rcStyles.is_label}>
        {i18next.t(label)}
      </h3>
      <span>{title}</span>
      <span>{description}</span>
    </div>
  </Link>
);

export default ArrangementCategoryLink;
