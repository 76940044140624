import { generateHexId } from 'services/utils';
import { BEREAVEMENTS_PER_PAGE } from 'constants/bereavement';
import {
  getBereavementResult,
  getBereavementsResult,
  editBereavementTransform,
  createLiteBereavementTransform,
  createLiteBereavementMutationInputTransform,
  editLiteBereavementMutationInputTransform,
  createBereavementTransform,
  addBereavementTransform,
  deceasedPersonTransferIntoCareTransform,
  saveNotesInputTransform,
  saveAshesRecordInputTransform,
  saveAshesRecordActionsTransform,
  saveDeedInputTransform,
  saveDeedActionsTransform,
  saveDeedDocumentsTransform,
  createBereavedPersonResult,
  editBereavedPersonTransform,
  bereavedPersonConnectionTransform,
  saveDocumentsInputTransform,
} from 'transforms/bereavement';
import { getBereavementNoteCategories } from 'services/utils/bereavement';
import {
  getBereavement,
  getBereavements,
  getLiteBereavement,
  getConciseBereavements,
} from './bereavement.queries.gql';
import {
  editBereavementMutation,
  createBereavementMutation,
  createBereavementForLiteMutation,
  editLiteArrangementMutation,
  saveBereavementNotes,
  saveDocumentsMutation,
  createBereavedPersonMutation,
  addBereavedPersonConnectionMutation,
  editBereavedPersonMutation,
  editBereavedPersonConnectionMutation,
  editCaseStatusMutation,
  addDeceasedAshesRecord,
  editDeceasedAshesRecord,
  addDeceasedPersonDeed,
  editDeceasedPersonDeed,
  sendUserToFuneralSafeSoftCheckMutation,
  sendUserToFuneralSafeReferralMutation,
} from './bereavement.mutations.gql';

export const bereavementActions = {
  CLEAR_ALL: 'BEREAVEMENT_CLEAR_ALL',
  CLEAR_BEREAVEMENTS: 'BEREAVEMENT_CLEAR_BEREAVEMENTS',
  CLEAR_BEREAVEMENT_FILTERS: 'BEREAVEMENT_CLEAR_BEREAVEMENT_FILTERS',
  SET_BEREAVEMENTS: 'BEREAVEMENT_SET_BEREAVEMENTS',
  SET_BEREAVEMENT: 'BEREAVEMENT_SET_BEREAVEMENT',
  ADD_BEREAVEMENTS: 'BEREAVEMENT_ADD_BEREAVEMENTS',
  REMOVE_BEREAVEMENT: 'BEREAVEMENT_REMOVE_BEREAVEMENT',
  UPDATE_BEREAVEMENT: 'BEREAVEMENT_UPDATE_BEREAVEMENT',
  SET_DECEASED_PERSON: 'BEREAVEMENT_SET_DECEASED_PERSON',
  UPDATE_APPOINTMENT: 'BEREAVEMENT_UPDATE_APPOINTMENT',
  ADD_CLIENT: 'BEREAVEMENT_ADD_CLIENT',
  EDIT_CLIENT: 'BEREAVEMENT_EDIT_CLIENT',
  REMOVE_CLIENT: 'BEREAVEMENT_REMOVE_CLIENT',
  ADD_ASHES_RECORD: 'BEREAVEMENT_ADD_ASHES_RECORD',
  UPDATE_ASHES_RECORD: 'BEREAVEMENT_UPDATE_ASHES_RECORD',
  ADD_POSSESSION: 'BEREAVEMENT_ADD_POSSESSION',
  REMOVE_POSSESSION: 'BEREAVEMENT_REMOVE_POSSESSION',
  UPDATE_POSSESSION: 'BEREAVEMENT_UPDATE_POSSESSION',
  ADD_DEED: 'BEREAVEMENT_ADD_DEED',
  UPDATE_DEED: 'BEREAVEMENT_UPDATE_DEED',
  ADD_ARRANGEMENT: 'BEREAVEMENT_ADD_ARRANGEMENT',
  EDIT_ARRANGEMENT: 'BEREAVEMENT_EDIT_ARRANGEMENT',
  SET_ARRANGEMENTS: 'BEREAVEMENT_SET_ARRANGEMENTS',
  SET_NOTES: 'BEREAVEMENT_SET_NOTES',
  UPDATE_FILTERS: 'BEREAVEMENT_UPDATE_FILTERS',
  SET_LOADING: 'BEREAVEMENT_SET_LOADING',
  SET_LIST_LOADED: 'BEREAVEMENT_SET_LIST_LOADED',
  SET_ACTIVE_REQUEST: 'BEREAVEMENT_SET_ACTIVE_REQUEST',
  SET_TOTALS: 'BEREAVEMENT_SET_TOTALS',
};

export const clearAllAction = () => (
  { type: bereavementActions.CLEAR_ALL }
);

export const clearBereavementsAction = () => (
  { type: bereavementActions.CLEAR_BEREAVEMENTS }
);

export const clearBereavementFiltersAction = () => (
  { type: bereavementActions.CLEAR_BEREAVEMENT_FILTERS }
);

export const setBereavementsAction = (bereavements, pagination, filters) => ({
  type: bereavementActions.SET_BEREAVEMENTS,
  payload: { bereavements, pagination, filters },
});

export const setBereavementAction = bereavement => ({
  type: bereavementActions.SET_BEREAVEMENT,
  payload: { bereavement },
});

export const addBereavementsAction = (bereavements, pagination, filters) => ({
  type: bereavementActions.ADD_BEREAVEMENTS,
  payload: { bereavements, pagination, filters },
});

export const setTotalsAction = totals => ({
  type: bereavementActions.SET_TOTALS,
  payload: { totals },
});

export const removeBereavementAction = bereavementId => (
  { type: bereavementActions.REMOVE_BEREAVEMENT, payload: bereavementId }
);

export const updateBereavementAction = (bereavementId, key, value) => ({
  type: bereavementActions.UPDATE_BEREAVEMENT,
  payload: { bereavementId, key, value },
});

export const setDeceasedPersonAction = (bereavementId, deceasedPerson) => ({
  type: bereavementActions.SET_DECEASED_PERSON,
  payload: { bereavementId, deceasedPerson },
});

export const updateAppointmentAction = (bereavementId, appointment) => ({
  type: bereavementActions.UPDATE_APPOINTMENT,
  payload: { bereavementId, appointment },
});

export const addClientAction = (bereavementId, bereavedPeopleConnection) => ({
  type: bereavementActions.ADD_CLIENT,
  payload: { bereavementId, bereavedPeopleConnection },
});

export const editClientAction = (bereavementId, bereavedPeopleConnection) => ({
  type: bereavementActions.EDIT_CLIENT,
  payload: { bereavementId, bereavedPeopleConnection },
});

export const removeClientAction = (bereavementId, id) => ({
  type: bereavementActions.REMOVE_CLIENT,
  payload: { bereavementId, id },
});

export const addPossessionAction = (bereavementId, possession) => ({
  type: bereavementActions.ADD_POSSESSION,
  payload: { bereavementId, possession },
});

export const updatePossessionAction = possessionData => ({
  type: bereavementActions.UPDATE_POSSESSION,
  payload: possessionData,
});

export const addAshesRecordAction = (bereavementId, ashesRecord) => ({
  type: bereavementActions.ADD_ASHES_RECORD,
  payload: { bereavementId, ashesRecord },
});

export const updateAshesRecordAction = (bereavementId, ashesRecord) => ({
  type: bereavementActions.UPDATE_ASHES_RECORD,
  payload: { bereavementId, ashesRecord },
});

export const addDeedAction = (bereavementId, deed) => ({
  type: bereavementActions.ADD_DEED,
  payload: { bereavementId, deed },
});

export const updateDeedAction = (bereavementId, deed) => ({
  type: bereavementActions.UPDATE_DEED,
  payload: { bereavementId, deed },
});

export const addArrangementAction = (bereavementId, arrangement) => ({
  type: bereavementActions.ADD_ARRANGEMENT,
  payload: {
    bereavementId,
    arrangement: {
      ...arrangement,
      isConfirmed: false,
      calculation: {
        discounts: { amount: 0, currency: 'GBP' },
        finalTotal: { amount: 0, currency: 'GBP' },
        totalDisbursements: { amount: 0, currency: 'GBP' },
        totalWithoutDiscounts: { amount: 0, currency: 'GBP' },
      },
    },
  },
});

export const editArrangementAction = (bereavementId, arrangementId, updates) => ({
  type: bereavementActions.EDIT_ARRANGEMENT,
  payload: {
    bereavementId,
    arrangementId,
    updates,
  },
});

export const setNotesAction = (bereavementId, notes) => ({
  type: bereavementActions.UPDATE_BEREAVEMENT,
  payload: { bereavementId, key: 'notes', value: notes },
});

export const setDocumentsAction = (bereavementId, documents) => ({
  type: bereavementActions.UPDATE_BEREAVEMENT,
  payload: { bereavementId, key: 'documents', value: documents },
});

export const updateFiltersAction = (key, value) => (
  { type: bereavementActions.UPDATE_FILTERS, payload: { key, value } }
);

export const setLoadingAction = isLoading => (
  { type: bereavementActions.SET_LOADING, payload: isLoading }
);

export const setListLoadedAction = isListLoaded => (
  { type: bereavementActions.SET_LIST_LOADED, payload: isListLoaded }
);

export const setActiveRequest = activeRequest => (
  { type: bereavementActions.SET_ACTIVE_REQUEST, payload: activeRequest }
);

const buildQueryInput = (getState, newFilters, customPagination) => {
  const pagination = customPagination || getState().bereavementStore.pagination;
  const filters = newFilters || getState().bereavementStore.filters;
  const { identityTypeId } = getState().userStore.user;
  const { user: { policy: { scopes } } } = getState().userStore;
  const noteCategories = getBereavementNoteCategories(scopes);

  return {
    caseStatus: filters.caseStatus,
    accountStatus: filters.accountStatus,
    organisationalUnitIds: (filters.organisationalUnitId === 'all') ? null : [filters.organisationalUnitId],
    ownerIds: (filters.ownerId === 'mine') ? [identityTypeId] : null,
    term: filters.searchTerm || null,
    pagination: {
      first: pagination.first,
      after: pagination.after,
    },
    sortOrders: filters.sortOrders || [],
    hasUnInvoicedAmount: !!filters.hasUnInvoicedAmount,
    hasBalanceOutstanding: !!filters.hasBalanceOutstanding,
    noteCategories,
  };
};

export const fetchBereavementsAction = (filters, fetchConcise = false) => (dispatch, getState, client) => {
  dispatch(setLoadingAction(true));
  dispatch(setListLoadedAction(true));
  const { activeRequest } = getState().bereavementStore;
  if (activeRequest) {
    client.queryManager.stopQuery(activeRequest);
  }
  dispatch(setActiveRequest(client.queryManager.idCounter));
  if (filters) {
    dispatch(clearBereavementsAction());
  }

  const query = client.query({
    query: fetchConcise ? getConciseBereavements : getBereavements,
    variables: buildQueryInput(getState, filters),
  }).then(({ data }) => {
    const {
      bereavements,
      uninvoicedBereavements,
      hasBalanceOutstandingBereavements,
      totalInvoiceTemplatesUninvoiced,
    } = data;
    const { edges, pageInfo } = bereavements;
    const totals = {
      uninvoiced: {
        count: uninvoicedBereavements?.totalCount,
        total: {
          amount: uninvoicedBereavements
            ?.edges
            ?.reduce((acc, { node }) => acc + node.account.totals.totalUnInvoiced.amount, 0),
          currency: uninvoicedBereavements?.edges?.[0]?.node?.account?.totals?.totalUnInvoiced?.currency ?? 'GBP',
        },
      },
      withOutstandingBalance: {
        count: hasBalanceOutstandingBereavements?.totalCount,
        total: {
          amount: hasBalanceOutstandingBereavements
            ?.edges
            ?.reduce((acc, { node }) => acc + node.account.totals.balance.amount, 0),
          currency: hasBalanceOutstandingBereavements?.edges?.[0]?.node?.account?.totals?.balance?.currency ?? 'GBP',
        },
      },
      totalInvoiceTemplatesUninvoiced: {
        count: totalInvoiceTemplatesUninvoiced?.totalCount,
        total: {
          amount: totalInvoiceTemplatesUninvoiced
            ?.edges
            ?.reduce((acc, { node }) => acc + node.account.totals.totalInvoiceTemplatesUninvoiced.amount, 0),
          currency: totalInvoiceTemplatesUninvoiced
            ?.edges?.[0]?.node?.account?.totals?.totalInvoiceTemplatesUninvoiced?.currency ?? 'GBP',
        },
      },
    };
    const updatedBereavements = getBereavementsResult(edges, fetchConcise);

    dispatch(addBereavementsAction(updatedBereavements, {
      first: getState().bereavementStore.pagination.first,
      after: pageInfo.endCursor,
      hasNextPage: pageInfo.hasNextPage,
    }, filters));
    dispatch(setTotalsAction(totals));
  }).finally(() => {
    dispatch(setLoadingAction(false));
    dispatch(setActiveRequest(null));
  });

  return query;
};


export const backgroundFetchBereavementsAction = (lastId, endCursor = null, fetchConcise = false) => (
  dispatch,
  getState,
  client,
) => (
  client.query({
    query: fetchConcise ? getConciseBereavements : getBereavements,
    variables: buildQueryInput(getState, null, { first: BEREAVEMENTS_PER_PAGE, after: endCursor }),
  }).then(({ data }) => {
    const { edges, pageInfo } = data.bereavements;
    const updatedBereavements = getBereavementsResult(edges, fetchConcise);

    if (!updatedBereavements.find(bereavement => bereavement.id === lastId)) {
      dispatch(addBereavementsAction(updatedBereavements));
      dispatch(backgroundFetchBereavementsAction(lastId, pageInfo.endCursor));
    } else {
      dispatch(addBereavementsAction(updatedBereavements, pageInfo));
    }
  })
);

export const fetchBereavementAction = id => (dispatch, getState, client) => {
  const { user: { policy: { scopes } } } = getState().userStore;
  const noteCategories = getBereavementNoteCategories(scopes);

  return client.query({
    query: getBereavement,
    variables: {
      id,
      noteCategories,
    },
  }).then(({ data }) => {
    if (data && data.bereavement) {
      dispatch(addBereavementsAction([getBereavementResult(data)]));
    }
  });
};

export const fetchLiteBereavementAction = id => (dispatch, getState, client) => {
  client.query({
    query: getLiteBereavement,
    variables: { id },
  }).then(({ data }) => {
    if (data && data.bereavement) {
      dispatch(addBereavementsAction([getBereavementResult(data)]));
    }
  });
};

export const editBereavementAction = bereavement => (dispatch, getState, client) => {
  dispatch(setBereavementAction(bereavement));
  client.mutate({
    mutation: editBereavementMutation,
    variables: { input: editBereavementTransform(bereavement) },
  });
};

export const createBereavementAction = bereavement => (dispatch, getState, client) => {
  const updatedBereavement = {
    ...bereavement,
    deceasedPeople: bereavement.deceasedPeople
      .map(deceasedPerson => deceasedPersonTransferIntoCareTransform(deceasedPerson)),
  };
  dispatch(addBereavementsAction([addBereavementTransform(updatedBereavement)]));
  client.mutate({
    mutation: createBereavementMutation,
    variables: { input: createBereavementTransform(updatedBereavement) },
  });
};

export const createBereavementForLiteAction = (data, id) => (dispatch, getState, client) => {
  const bereavement = createLiteBereavementTransform(data, id);
  const input = createLiteBereavementMutationInputTransform(bereavement);

  dispatch(addBereavementsAction([bereavement]));

  client.mutate({
    mutation: createBereavementForLiteMutation,
    variables: { input },
  });
};

export const editBereavementForLiteAction = ({
  bereavement,
}) => (dispatch, getState, client) => {
  const input = editLiteBereavementMutationInputTransform(bereavement);

  dispatch(addBereavementsAction([bereavement]));

  client.mutate({
    mutation: editLiteArrangementMutation,
    variables: { input },
  });
};

export const saveNotesAction = (bereavementId, notes) => (dispatch, getState, client) => {
  const input = {
    bereavementId,
    notes: saveNotesInputTransform(notes),
  };

  dispatch(setNotesAction(bereavementId, notes));

  client.mutate({
    mutation: saveBereavementNotes,
    variables: { input },
  });
};

export const saveDocumentsAction = (bereavementId, documents) => (dispatch, getState, client) => {
  const documentsListInput = documents.map(document => ({
    id: document.id || generateHexId(),
    title: document.title,
    mediaId: document.media.id,
    media: document.media,
  }));

  dispatch(setDocumentsAction(bereavementId, documentsListInput));

  client.mutate({
    mutation: saveDocumentsMutation,
    variables: {
      input: {
        bereavementId,
        documents: saveDocumentsInputTransform(documentsListInput),
      },
    },
  });
};

export const createAshesRecordAction = (
  bereavementId, deceasedPersonId, ashesRecord,
) => (dispatch, getState, client) => {
  const id = generateHexId();
  const newAshesRecord = {
    ...ashesRecord,
    actions: saveAshesRecordActionsTransform(ashesRecord.actions),
    id,
  };
  const input = {
    bereavementId,
    deceasedPersonId,
    ...saveAshesRecordInputTransform(ashesRecord),
    id,
  };

  dispatch(addAshesRecordAction(bereavementId, newAshesRecord));

  client.mutate({
    mutation: addDeceasedAshesRecord,
    variables: { input },
  });
};

export const editAshesRecordAction = (
  bereavementId, deceasedPersonId, ashesRecord,
) => (dispatch, getState, client) => {
  const editedAshesRecord = {
    ...ashesRecord,
    actions: saveAshesRecordActionsTransform(ashesRecord.actions),
  };
  const input = {
    bereavementId,
    deceasedPersonId,
    ...saveAshesRecordInputTransform(ashesRecord),
  };

  dispatch(updateAshesRecordAction(bereavementId, editedAshesRecord));

  client.mutate({
    mutation: editDeceasedAshesRecord,
    variables: { input },
  });
};

export const createDeedAction = (bereavementId, deceasedPersonId, deed) => (dispatch, getState, client) => {
  const newDeed = {
    ...deed,
    actions: saveDeedActionsTransform(deed.actions),
    documents: saveDeedDocumentsTransform(deed.documents),
    id: generateHexId(),
  };
  const input = {
    bereavementId,
    deceasedPersonId,
    ...saveDeedInputTransform(newDeed),
  };

  dispatch(addDeedAction(bereavementId, newDeed));

  client.mutate({
    mutation: addDeceasedPersonDeed,
    variables: { input },
  });
};

export const editDeedAction = (bereavementId, deceasedPersonId, deed) => (dispatch, getState, client) => {
  const editedDeed = {
    ...deed,
    actions: saveDeedActionsTransform(deed.actions),
    documents: saveDeedDocumentsTransform(deed.documents),
  };
  const input = {
    bereavementId,
    deceasedPersonId,
    ...saveDeedInputTransform(editedDeed),
  };

  dispatch(updateDeedAction(bereavementId, editedDeed));

  client.mutate({
    mutation: editDeceasedPersonDeed,
    variables: { input },
  });
};

export const createBereavedPersonAction = ({
  newBereavedPersonConnection,
  bereavementId,
  onCreateBereavedPersonCallback,
}) => (dispatch, getState, client) => {
  client
    .mutate({
      mutation: createBereavedPersonMutation,
      variables: { input: newBereavedPersonConnection.bereavedPerson },
    })
    .then((data) => {
      const bereaved = {
        ...newBereavedPersonConnection,
        isBillPayer: false,
        bereavedPerson: createBereavedPersonResult(data),
      };

      onCreateBereavedPersonCallback(bereaved);

      client.mutate({
        mutation: addBereavedPersonConnectionMutation,
        variables: { input: bereavedPersonConnectionTransform(bereaved, bereavementId) },
      });
    });
};

export const editBereavedPersonAction = (
  bereavementId,
  bereavedPersonConnection,
) => (dispatch, getState, client) => {
  client
    .mutate({
      mutation: editBereavedPersonMutation,
      variables: { input: editBereavedPersonTransform(bereavedPersonConnection.bereavedPerson) },
    })
    .then(() => {
      client.mutate({
        mutation: editBereavedPersonConnectionMutation,
        variables: { input: bereavedPersonConnectionTransform(bereavedPersonConnection, bereavementId) },
      });
    });
};

export const editCaseStatusAction = (
  bereavementId, updateBereavementKey, status,
) => (dispatch, getState, client) => {
  dispatch(updateBereavementAction(bereavementId, updateBereavementKey, status));

  client.mutate({
    mutation: editCaseStatusMutation,
    variables: {
      input: {
        id: bereavementId,
        caseStatus: status,
      },
    },
  });
};

export const sendUserToFuneralSafeSoftCheckAction = (
  bereavementId,
  arrangementId,
  GDPRApproval,
) => (dispatch, getState, client) => {
  const input = { bereavementId, arrangementId, GDPRApproval };

  dispatch(editArrangementAction(
    bereavementId,
    arrangementId,
    {
      funeralSafeSoftCheckDate: new Date().toISOString(),
      funeralSafeSoftCheckGDPRApproval: GDPRApproval,
    },
  ));

  client.mutate({
    mutation: sendUserToFuneralSafeSoftCheckMutation,
    variables: { input },
  });
};

export const sendUserToFuneralSafeReferralAction = (
  bereavementId,
  arrangementId,
  GDPRApproval,
  loanAmount,
) => (dispatch, getState, client) => {
  const input = {
    bereavementId,
    arrangementId,
    GDPRApproval,
    loanAmount,
  };

  dispatch(editArrangementAction(
    bereavementId,
    arrangementId,
    {
      funeralSafeReferralDate: new Date().toISOString(),
      funeralSafeReferralGDPRApproval: GDPRApproval,
      loanAmount,
    },
  ));

  client.mutate({
    mutation: sendUserToFuneralSafeReferralMutation,
    variables: { input },
  });
};
