import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { createDirectoryListingAction, editDirectoryListingAction } from 'actions/directoryListings';
import { categoriesWithNames, emptyDirectoryListing } from 'constants/directoryListing';
import { historyType, locationType, matchType } from 'types/reactRouter';
import { directoryListingType } from 'types/directoryListing';
import { apolloClientType } from 'types/apollo';

import { getDirectoryListing } from './queries.gql';
import AdminDirectoryListingScreen from './AdminDirectoryListingScreen';
import { validationSchema } from './validation';

class AdminDirectoryListingScreenContainer extends Component {
  static propTypes = {
    directoryListings: PropTypes.arrayOf(directoryListingType).isRequired,
    parentUrl: PropTypes.string.isRequired,
    client: apolloClientType.isRequired,
    location: locationType.isRequired,
    match: matchType.isRequired,
    history: historyType,
    createDirectoryListing: PropTypes.func.isRequired,
    editDirectoryListing: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    generateRefs: PropTypes.func.isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  }

  constructor(props) {
    super(props);
    const { match } = props;

    this.state = {
      isLoading: !!match.params.directoryListingId,
      directoryListingId: match.params.directoryListingId || null,
      directoryListing: emptyDirectoryListing,
      isValidationEnabled: false,
    };
  }

  componentDidMount() {
    const { generateRefs, validate, location } = this.props;
    const { directoryListingId, directoryListing } = this.state;

    generateRefs(Object.keys(validationSchema.fields));
    if (!directoryListingId) {
      let updatedDirectoryListing = { ...directoryListing };

      if (location?.state) {
        updatedDirectoryListing = {
          ...directoryListing,
          name: location.state.name || directoryListing.name,
          address: location.state.address || directoryListing.address,
          phones: location.state.phones || directoryListing.phones,
          emails: location.state.emails || directoryListing.emails,
          categories: location.state.categories ? location.state.categories.map(
            category => categoriesWithNames.find(categoryWithName => categoryWithName.category === category),
          ) : [],
        };
        this.setState({ directoryListing: updatedDirectoryListing });
      }

      validate(updatedDirectoryListing, validationSchema);
      return;
    }

    const { directoryListings } = this.props;
    const directoryListingIndex = directoryListings.findIndex(
      (listing => listing.id === directoryListingId),
    );

    let listingFromGlobalState;
    if (directoryListingIndex > -1) {
      listingFromGlobalState = directoryListings[directoryListingIndex];
    }

    if (listingFromGlobalState) {
      this.setState({
        isLoading: false,
        directoryListing: listingFromGlobalState,
      });
    } else {
      this.getDirectoryListing();
    }
  }

  getDirectoryListing = () => {
    const { directoryListingId } = this.state;
    const { client, validate } = this.props;

    this.setState({ isLoading: true });

    client.query({
      query: getDirectoryListing,
      variables: {
        id: directoryListingId,
      },
    }).then(({ data, errors }) => {
      if (data === null && errors.length > 0) {
        return;
      }

      const { directoryListing } = data;

      validate(directoryListing, validationSchema);

      this.setState(({
        directoryListing,
      }));
    }).finally(() => {
      this.setState({ isLoading: false });
    });
  }

  handleDirectoryListingChange = (key, value) => {
    const { validate } = this.props;
    const { directoryListing } = this.state;

    const updatedDirectoryListing = {
      ...directoryListing,
      [key]: value,
    };

    validate(updatedDirectoryListing, validationSchema);

    this.setState({
      directoryListing: updatedDirectoryListing,
    });
  }

  handleCreate = async () => {
    const {
      createDirectoryListing, history, parentUrl, validate,
    } = this.props;
    const { directoryListing } = this.state;

    this.setState({ isValidationEnabled: true });

    if (!validate(directoryListing, validationSchema, true)) {
      return;
    }

    createDirectoryListing(directoryListing);
    history.push(parentUrl);
  }

  handleEdit = async () => {
    const {
      editDirectoryListing, history, parentUrl, validate,
    } = this.props;
    const { directoryListing } = this.state;

    this.setState({ isValidationEnabled: true });

    if (!validate(directoryListing, validationSchema, true)) {
      return;
    }

    editDirectoryListing(directoryListing);
    history.push(parentUrl);
  }

  handleCancel = async () => {
    const { history, parentUrl } = this.props;
    history.push(parentUrl);
  }

  render() {
    const { errors, formRefs } = this.props;
    const {
      isLoading,
      directoryListingId,
      directoryListing,
      isValidationEnabled,
    } = this.state;

    const categoriesShouldBeConverted = (directoryListing.categories || []).reduce(
      (acc, category) => typeof category === 'string',
      false,
    );

    if (categoriesShouldBeConverted) {
      directoryListing.categories = directoryListing.categories.map(
        category => categoriesWithNames.find(categoryWithName => categoryWithName.category === category),
      );
    }

    return (
      <AdminDirectoryListingScreen
        directoryListing={directoryListing}
        isLoading={isLoading}
        onChange={this.handleDirectoryListingChange}
        onSave={directoryListingId ? this.handleEdit : this.handleCreate}
        onCancel={this.handleCancel}
        formRefs={formRefs}
        errors={isValidationEnabled ? errors : {}}
      />
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  createDirectoryListing: createDirectoryListingAction,
  editDirectoryListing: editDirectoryListingAction,
}, dispatch);

const mapStateToProps = state => ({
  directoryListings: state.directoryListingsStore.directoryListings,
});

export default withValidation(
  withApollo(
    withRouter(
      connect(mapStateToProps, mapDispatchToProps)(AdminDirectoryListingScreenContainer),
    ),
  ),
);
