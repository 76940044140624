import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DownloadIcon from '@material-ui/icons/GetApp';
import IconLoadSml from 'icons/IconLoadSml';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const AsyncButton = (props) => {
  const { 'data-test-id': testId } = props;
  const {
    nonNativeProps: {
      isActive,
      text,
      displayIcon,
      icon: ProvidedIcon,
    },
  } = props;
  const getNativeProps = () => {
    const allProps = { ...props };
    delete allProps.nonNativeProps;

    return allProps;
  };
  const visibleOnActive = {
    visibility: isActive ? 'visible' : 'hidden',
  };
  const hiddenOnActive = {
    visibility: isActive ? 'hidden' : 'visible',
  };

  return (
    <div className={rcStyles.async_btn_container}>
      <Button
        data-test-id={testId}
        {...getNativeProps()}
      >
        <Fragment>
          {displayIcon && (
            <Fragment>
              {ProvidedIcon
                ? (
                  <ProvidedIcon
                    style={hiddenOnActive}
                    className={styles.u_push__right}
                  />
                )
                : <DownloadIcon style={hiddenOnActive} />}
            </Fragment>
          )}
          <span style={hiddenOnActive}>
            {text}
          </span>
        </Fragment>
      </Button>
      <div
        style={visibleOnActive}
        className={rcStyles.async_btn_loader_content}
      >
        {isActive && <IconLoadSml />}
      </div>
    </div>
  );
};

AsyncButton.propTypes = {
  'color': PropTypes.string,
  'variant': PropTypes.string,
  'disabled': PropTypes.bool,
  'nonNativeProps': PropTypes.shape({
    isActive: PropTypes.bool,
    text: PropTypes.string,
    displayIcon: PropTypes.bool,
    icon: PropTypes.func,
  }).isRequired,
  'onClick': PropTypes.func.isRequired,
  'data-test-id': PropTypes.string.isRequired,
};

export default AsyncButton;
