import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';

import AdminLetterTemplatesScreen from 'screens/admin/AdminLetterTemplatesScreen';
import AdminLetterTemplateValuesScreen from 'screens/admin/AdminLetterTemplateValuesScreen';
import AdminLetterTemplatesEstimateScreen from 'screens/admin/AdminLetterTemplatesEstimateScreen';
import AdminLetterTemplatesInvoiceScreen from 'screens/admin/AdminLetterTemplatesInvoiceScreen';

import { featureFlags } from 'constants/features';
import NotFoundScreen from 'screens/NotFoundScreen';
import { RootState } from 'types/ts/state';

import { TenantFeature } from 'types/ts/tenant';
import { Props } from './index.types';

const AdminLetterTemplatesSectionScreen: React.FC<Props> = ({ match, availableFeatures }: Props) => (
  <Switch>
    {availableFeatures.includes(featureFlags.CORRESPONDENCE_VALUES_SCREEN) && (
      <Route
        path={`${match.url}/values`}
        render={() => (
          <AdminLetterTemplateValuesScreen />
        )}
      />
    )}
    {availableFeatures.includes(featureFlags.ESTIMATE_TEMPLATES_SCREEN) && (
      <Route
        path={`${match.url}/estimate`}
        render={() => (
          <AdminLetterTemplatesEstimateScreen />
        )}
      />
    )}
    {availableFeatures.includes(featureFlags.INVOICE_TEMPLATES_SCREEN) && (
      <Route
        path={`${match.url}/invoice`}
        render={() => (
          <AdminLetterTemplatesInvoiceScreen />
        )}
      />
    )}
    {availableFeatures.includes(featureFlags.CORRESPONDENCE_TEMPLATES_SCREEN) && (
      <Route
        exact
        path={`${match.url}`}
        render={() => (
          <AdminLetterTemplatesScreen />
        )}
      />
    )}
    <Route key="not-found" component={NotFoundScreen} />
  </Switch>
);

const mapStateToProps = (state: RootState) => ({
  availableFeatures: state.userStore.user.tenantFeatureFlags as TenantFeature[],
});

export default withRouter(connect(mapStateToProps)(AdminLetterTemplatesSectionScreen));
