import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const clientFactory = links => new ApolloClient({
  link: ApolloLink.from(links),
  cache: new InMemoryCache({
    addTypename: true,
  }),
  defaultOptions,
});

export default clientFactory;
