import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { generateHexId } from 'services/utils';
import { getHomeAddresses } from 'services/utils/bereavement';
import { bereavementType } from 'types/bereavement';
import Viewing from './Viewing';
import { validationSchema } from './validation';

const emptyFormData = {
  location: null,
  startDateTime: null,
  endDateTime: null,
  permittedAttendees: [],
  notes: null,
};

class ViewingContainer extends Component {
  static propTypes = {
    fieldData: PropTypes.objectOf(PropTypes.any),
    bereavement: bereavementType.isRequired,
    onChange: PropTypes.func,
    onRemove: PropTypes.func,
    disabled: PropTypes.bool.isRequired,
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    generateRefs: PropTypes.func.isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  }

  static defaultProps = {
    fieldData: {},
  }

  constructor(props) {
    super(props);

    const { bereavement } = props;
    const { home } = bereavement;
    const [address] = getHomeAddresses(home) || [];
    const { data: homeAddress } = address || {};

    const formData = {
      ...emptyFormData,
      location: { ...(homeAddress || {}) },
    };

    this.state = {
      isFormOpen: false,
      isEditing: false,
      isValidationEnabled: false,
      formData,
    };

    this.dialogRef = React.createRef();
  }

  componentDidMount() {
    const { fieldData, generateRefs } = this.props;
    generateRefs(Object.keys(validationSchema.fields));
    const isFieldDataEmpty = Object.keys(fieldData).length === 0;
    this.setState({ isFormOpen: isFieldDataEmpty });
  }

  handleAttendeesChange = (key) => {
    const { formData } = this.state;
    const newFormData = { ...formData };
    newFormData.permittedAttendees = formData.permittedAttendees.slice();

    const index = newFormData.permittedAttendees.indexOf(key);
    if (index !== -1) {
      newFormData.permittedAttendees.splice(index, 1);
    } else {
      newFormData.permittedAttendees.push(key);
    }

    this.setState({
      formData: newFormData,
    });
  }

  handleChange = (key, value) => {
    const { validate } = this.props;
    const { isValidationEnabled, formData } = this.state;

    const newFormData = {
      ...formData,
      [key]: value,
    };

    if (isValidationEnabled) {
      validate(newFormData, validationSchema);
    }
    this.setState({ formData: newFormData });
  }

  handleEdit = () => {
    const { fieldData } = this.props;
    this.setState({
      isFormOpen: true,
      isEditing: true,
      formData: fieldData,
    });
  }

  handleSave = () => {
    const { onChange, validate } = this.props;
    const { formData } = this.state;
    const data = { ...formData };

    if (!formData.id) {
      data.id = generateHexId();
    }

    this.setState({ isValidationEnabled: true });
    const isValid = validate(data, validationSchema, true, this.dialogRef);

    if (isValid) {
      onChange(data);
      this.closeModal();
    }
  }

  handleCancel = () => {
    const { onRemove } = this.props;
    const { isEditing } = this.state;

    if (!isEditing) {
      onRemove();
    }
    this.closeModal();
  }

  closeModal = () => {
    this.setState({
      isFormOpen: false,
      isEditing: false,
      formData: { ...emptyFormData },
    });
  }

  render() {
    const {
      fieldData, formRefs, errors, disabled,
    } = this.props;

    return (
      <Viewing
        {...this.state}
        fieldData={fieldData}
        onAttendeesChange={this.handleAttendeesChange}
        onChange={this.handleChange}
        onEdit={this.handleEdit}
        onSave={this.handleSave}
        onCancel={this.handleCancel}
        disabled={disabled}
        dialogRef={this.dialogRef}
        formRefs={formRefs}
        errors={errors}
      />
    );
  }
}

export default withValidation(ViewingContainer);
