import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { withApollo } from 'react-apollo';
import { withSnackbar } from 'notistack';

import { caseStatuses } from 'constants/bereavement';
import {
  careCategoryInformationFormTransform,
  careCategoryInformationSaveTransform,
} from 'transforms/arrangement';
import { apolloClientType } from 'types/apollo';
import { bereavementType, arrangementType } from 'types/bereavement';

import ArrangementCareForm from './ArrangementCareForm';
import { editArrangementCareInformation } from './mutations.gql';

const embalmReminderKey = 'embalmReminder';

class ArrangementCareFormContainer extends Component {
  static propTypes = {
    bereavement: bereavementType.isRequired,
    arrangement: arrangementType.isRequired,
    client: apolloClientType.isRequired,
    onSave: PropTypes.func.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    closeSnackbar: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: {
        addFee: false,
      },
      isSaving: false,
      categoryInformation: {
        isEmbalmingRequired: false,
        clothes: null,
        clothesColour: null,
        clothesDisposal: null,
        hazardousImplants: [],
        viewings: [],
        notes: null,
      },
      duplicateSelectionItem: null,
    };
  }

  componentDidMount() {
    const { arrangement } = this.props;

    if (arrangement.categoryInformation && arrangement.categoryInformation.care) {
      this.setState({
        categoryInformation: careCategoryInformationFormTransform(arrangement.categoryInformation.care),
      });
    }
  }

  handleChange = (key, value) => {
    const { enqueueSnackbar, closeSnackbar } = this.props;
    const { categoryInformation } = this.state;

    const newState = {
      ...categoryInformation,
      [key]: value,
    };

    if (key === 'clothes' && value === 'None') {
      newState.clothesDisposal = null;
      newState.isClothesReceived = null;
    }

    if (key === 'isEmbalmingRequired') {
      if (value) {
        enqueueSnackbar(
          'To add embalming fee click \'Add details\' and then \'Add fee\'',
          { key: embalmReminderKey, variant: 'warning' },
        );
      } else {
        closeSnackbar(embalmReminderKey);
      }
    }

    this.setState({ categoryInformation: newState });
  }

  handleSave = () => {
    const {
      client, bereavement, arrangement, onSave, enqueueSnackbar,
    } = this.props;
    const { categoryInformation } = this.state;

    const hasViewings = categoryInformation.viewings && categoryInformation.viewings.length > 0;
    if (categoryInformation.isViewingsNotPermitted && hasViewings) {
      enqueueSnackbar(
        t('You must remove all viewings to select \'Viewing not permitted by client\''),
        { variant: 'warning' },
      );
      return;
    }
    this.setState({ isSaving: true });

    const updatedCategoryInformation = careCategoryInformationSaveTransform(categoryInformation);

    onSave(bereavement.id, arrangement.id, 'care', updatedCategoryInformation);

    const input = {
      bereavementId: bereavement.id,
      arrangementId: arrangement.id,
      ...updatedCategoryInformation,
    };

    client.mutate({
      mutation: editArrangementCareInformation,
      variables: { input },
    }).finally(() => this.setState({ isSaving: false }));
  }

  toggleModal = (key) => {
    this.setState(prevState => ({
      isModalOpen: {
        ...prevState.isModalOpen,
        [key]: !prevState.isModalOpen[key],
      },
    }));
  }

  handleAddHazardousImplants = () => {
    this.setState(prevState => ({
      categoryInformation: {
        ...prevState.categoryInformation,
        hazardousImplants: [
          ...(prevState.categoryInformation.hazardousImplants || []),
          {},
        ],
      },
    }));
  }

  handleOpenDuplicateSelectionModal = (item, callback) => {
    this.setState({
      duplicateSelectionItem: item,
      duplicateItemConfirmCallback: callback,
    });
  }

  handleDuplicateItemConfirm = () => {
    const { duplicateItemConfirmCallback } = this.state;

    duplicateItemConfirmCallback();

    this.setState({
      duplicateSelectionItem: null,
      duplicateItemConfirmCallback: null,
    });
  }

  handleDuplicateItemCancel = () => {
    this.setState({
      duplicateSelectionItem: null,
      duplicateItemConfirmCallback: null,
    });
  }

  render() {
    const { bereavement, arrangement } = this.props;
    const {
      isModalOpen,
      isSaving,
      categoryInformation,
      duplicateSelectionItem,
    } = this.state;
    const disabled = isSaving || bereavement.caseStatus === caseStatuses.CLOSED;

    return (
      <ArrangementCareForm
        bereavement={bereavement}
        arrangement={arrangement}
        categoryInformation={categoryInformation}
        disabled={disabled}
        duplicateSelectionItem={duplicateSelectionItem}
        isModalOpen={isModalOpen}
        isSaving={isSaving}
        onAddHazardousImplants={this.handleAddHazardousImplants}
        onChange={this.handleChange}
        onDuplicateItemConfirm={this.handleDuplicateItemConfirm}
        onDuplicateItemCancel={this.handleDuplicateItemCancel}
        onOpenDuplicateSelectionModal={this.handleOpenDuplicateSelectionModal}
        onSave={this.handleSave}
        toggleModal={this.toggleModal}
      />
    );
  }
}

export default withApollo(withSnackbar(ArrangementCareFormContainer));
