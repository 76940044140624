import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { accountStatuses } from 'constants/account';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const AccountStatusToggleButton = ({ status, onClick, 'data-test-id': dataTestId }) => (
  <div
    className={classNames(
      styles.o_fb,
      styles.o_fb__align_right,
    )}
  >
    <Button
      classes={{
        root: rcStyles.account_status_button_root,
        outlinedPrimary: rcStyles.account_status_button,
        label: rcStyles.account_status_button,
      }}
      color="primary"
      variant="outlined"
      data-test-id={dataTestId}
      onClick={onClick}
    >
      {status === accountStatuses.CLOSED ? t('Reopen account') : t('Close account')}
    </Button>
  </div>
);

AccountStatusToggleButton.propTypes = {
  'status': PropTypes.string.isRequired,
  'onClick': PropTypes.func.isRequired,
  'data-test-id': PropTypes.string.isRequired,
};

export default AccountStatusToggleButton;
