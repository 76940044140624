import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { matchType, historyType } from 'types/reactRouter';
import { navigationPathType } from 'types/common';
import { withRouter } from 'react-router-dom';
import ArrangementNavigationSelection from './ArrangementNavigationSelection';

export class ArrangementNavigationSelectionContainer extends Component {
  static propTypes = {
    match: matchType,
    history: historyType,
    navigationPath: navigationPathType.isRequired,
    isActive: PropTypes.bool.isRequired,
    toggleMenu: PropTypes.func.isRequired,
  }

  handleClick = (pathName) => {
    const { history, match, toggleMenu } = this.props;
    toggleMenu();
    history.push(`${match.url}/${pathName}`);
  };

  render() {
    const {
      navigationPath,
      isActive,
      toggleMenu,
    } = this.props;
    return (
      <ArrangementNavigationSelection
        navigationPath={navigationPath}
        onClick={this.handleClick}
        isActive={isActive}
        toggleMenu={toggleMenu}
      />
    );
  }
}

export default withRouter(ArrangementNavigationSelectionContainer);
