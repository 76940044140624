import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';

const SortMenu = ({
  onOpen,
  onClose,
  onChange,
  isOpen,
  options,
  anchor,
  selectedItem,
  testIds: {
    button: buttonTestId,
    menu: menuTestId,
    menuItem: menuItemTestId,
  },
}) => (
  <Fragment>
    <Button
      onClick={onOpen}
      data-test-id={buttonTestId}
      color="primary"
    >
      {Object.keys(selectedItem).length
        ? selectedItem.label
        : t('Sort')}
      <SwapVertIcon />
    </Button>
    <Menu
      open={isOpen}
      onClose={onClose}
      data-test-id={menuTestId}
      anchorEl={anchor}
    >
      {options.map(option => (
        <MenuItem
          key={option.enum}
          value={option}
          onClick={() => {
            onClose();
            onChange(option);
          }}
          data-test-id={`${menuItemTestId}${option.enum}`}
        >
          {t(option.label)}
        </MenuItem>
      ))}
    </Menu>
  </Fragment>
);

SortMenu.propTypes = {
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  anchor: PropTypes.objectOf(PropTypes.shape({})),
  selectedItem: PropTypes.objectOf(PropTypes.string).isRequired,
  testIds: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default SortMenu;
