import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import NotesDocumentsCorrespondenceGroup from 'components/common/NotesDocumentsCorrespondenceGroup';
import { buildNameString } from 'services/utils';
import {
  correspondenceLetterCategories,
} from 'constants/correspondence';
import { bereavementType } from 'types/bereavement';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const CaseMenu = ({
  bereavement,
  disabled,
  templateDefinitionsLookup,
  openMenu,
  saveDocuments,
  saveNotes,
}) => (
  <div className={classNames(
    styles.o_view,
    styles.has_white_background,
    styles.is_compressed,
  )}
  >
    <div className={classNames(
      styles.o_view__main,
      rcStyles.rc_case_nav,
    )}
    >
      <IconButton
        className={rcStyles.hamburger}
        color="primary"
        variant="contained"
        aria-label="Open"
        onClick={openMenu}
        data-test-id="openMenu"
      >
        <svg className={rcStyles.hamburger__svg} width="28" height="21" xmlns="http://www.w3.org/2000/svg">
          <g>
            <rect width="28" height="3" rx="1" />
            <rect y="9" width="28" height="3" rx="1" />
            <rect y="18" width="28" height="3" rx="1" />
          </g>
        </svg>
      </IconButton>
      <Link
        to={`/case/${bereavement.id}/arrangement`}
        className={rcStyles.base_button_slim}
      >
        {buildNameString(bereavement.deceasedPeople[0].name)}
      </Link>
      <div className={rcStyles.rc_case_nav__doc_links}>
        <NotesDocumentsCorrespondenceGroup
          bereavementId={bereavement.id}
          deceasedPeople={bereavement.deceasedPeople}
          documents={bereavement.documents}
          notes={bereavement.notes}
          correspondenceCategory={correspondenceLetterCategories.CASE}
          templateDefinitionsLookup={templateDefinitionsLookup}
          disabled={disabled}
          buttonClasses={[styles.o_button__secondary]}
          iconClasses={[rcStyles.rc_icon__primary]}
          customColor="primary"
          onDocumentsSave={documentsToSave => saveDocuments(bereavement.id, documentsToSave)}
          onNotesSave={notesToSave => saveNotes(bereavement.id, notesToSave)}
        />
      </div>
    </div>
  </div>
);

CaseMenu.propTypes = {
  bereavement: bereavementType.isRequired,
  disabled: PropTypes.bool.isRequired,
  templateDefinitionsLookup: PropTypes.objectOf(PropTypes.any),
  openMenu: PropTypes.func.isRequired,
  saveNotes: PropTypes.func.isRequired,
  saveDocuments: PropTypes.func.isRequired,
};

export default withRouter(CaseMenu);
