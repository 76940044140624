import React, { Fragment } from 'react';
import classNames from 'classnames';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ButtonBase from '@material-ui/core/ButtonBase';
import Drawer from '@material-ui/core/Drawer';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import withStyles from '@material-ui/core/styles/withStyles';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PersonIcon from '@material-ui/icons/Person';

import ArrangementModal from 'components/arrangement/ArrangementModal';
import { caseStatuses } from 'constants/bereavement';
import { mediaVariants } from 'constants/media';
import { scopes as permissions } from 'constants/scopes';
import styles from 'scss/main.scss';
import {
  buildNameString,
  hasPermissions,
  getUriForMediaByVariant,
  getStartAndEndDates,
  formatCurrency,
} from 'services/utils';
import { buildConfirmationAndCommittalTypeText } from 'services/utils/bereavement';
import { bereavementType } from 'types/bereavement';

import rcStyles from './styles.scss';

const IconLeftExpansionPanelSummary = withStyles({
  expandIcon: {
    left: '8px',
    right: 'auto',
  },
})(ExpansionPanelSummary);

const CaseSideMenu = ({
  bereavement,
  url,
  scopes,
  isOpen,
  isModalOpen,
  getArrangementNavigation,
  onClose,
  onModalOpen,
  onModalClose,
}) => (
  <Fragment>
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={onClose}
      className={rcStyles.drawer_wrapper}
    >
      <div className={rcStyles.drawer}>
        <div className={rcStyles.drawer__head}>
          <div className={rcStyles.drawer__head_close}>
            <IconButton
              className={classNames(
                styles.o_button__secondary,
                styles.o_button__round__small,
              )}
              color="primary"
              variant="contained"
              aria-label="Close"
              onClick={onClose}
              data-test-id="closeMenu"
            >
              <CloseIcon />
            </IconButton>
          </div>
          {bereavement.deceasedPeople[0].image ? (
            <img
              alt={t('Deceased photo')}
              src={getUriForMediaByVariant(bereavement.deceasedPeople[0].image, mediaVariants.FULL_FIT_408_X_408)}
              className={rcStyles.drawer__head_image}
            />
          ) : (
            <div className={rcStyles.person_icon}>
              <PersonIcon className={rcStyles.is_icon} />
            </div>
          )}
          <div className={rcStyles.drawer__head_label}>
            <h2 className={rcStyles.is_title}>{buildNameString(bereavement.deceasedPeople[0].name)}</h2>
            <p className={rcStyles.is_subhead}>
              {bereavement.deceasedPeople[0].deathDateTime
                  && bereavement.deceasedPeople[0].dateOfBirth
                  && (<span>{getStartAndEndDates(bereavement.deceasedPeople[0].dateOfBirth, bereavement.deceasedPeople[0].deathDateTime, 'DD MMM YYYY')}</span>)}
            </p>
          </div>
        </div>
        <MenuList className={rcStyles.drawer__list}>
          <Link
            to={`${url}/arrangement`}
            className={rcStyles.drawer__nav_link}
          >
            <MenuItem className={rcStyles.drawer__list_item}>
              {t('Funeral summary')}
            </MenuItem>
          </Link>
          <Link
            to={`${url}/deceased/details`}
            className={rcStyles.drawer__nav_link}
          >
            <MenuItem className={rcStyles.drawer__list_item}>
              {t('Deceased details')}
            </MenuItem>
          </Link>
          <MenuList className={rcStyles.drawer__list_nested}>
            <Link
              to={`${url}/deceased/care-wishes`}
              className={rcStyles.drawer__nav_link}
            >
              <MenuItem className={rcStyles.drawer__list_item}>
                {t('Care wishes')}
              </MenuItem>
            </Link>
            <Link
              to={`${url}/deceased/care-record`}
              className={rcStyles.drawer__nav_link}
            >
              <MenuItem className={rcStyles.drawer__list_item}>
                {t('Care record')}
              </MenuItem>
            </Link>
            <Link
              to={`${url}/deceased/possessions`}
              className={rcStyles.drawer__nav_link}
            >
              <MenuItem className={rcStyles.drawer__list_item}>
                {t('Possessions')}
              </MenuItem>
            </Link>
            <Link
              to={`${url}/deceased/legal-forms`}
              className={rcStyles.drawer__nav_link}
            >
              <MenuItem className={rcStyles.drawer__list_item}>
                {t('Forms & Legal')}
              </MenuItem>
            </Link>
          </MenuList>
          <Link
            to={`${url}/bereaved`}
            className={rcStyles.drawer__nav_link}
          >
            <MenuItem className={rcStyles.drawer__list_item}>
              {t('Clients')}
            </MenuItem>
          </Link>
          {hasPermissions([permissions.BEREAVEMENT_ACCOUNT_READ], scopes) && (
          <Link
            to={`/accounts/${bereavement.id}`}
            className={rcStyles.drawer__nav_link}
          >
            <MenuItem className={rcStyles.drawer__list_item}>
              {t('Account')}
            </MenuItem>
          </Link>
          )}
        </MenuList>
        {bereavement.arrangements && bereavement.arrangements.map((arrangement) => {
          const { committalType } = arrangement;
          const paths = getArrangementNavigation(committalType);

          return (
            <ExpansionPanel className={rcStyles.drawer__expansion} key={arrangement.id}>
              <IconLeftExpansionPanelSummary
                className={classNames(
                  rcStyles.drawer__expansion_summary,
                  arrangement.isConfirmed ? rcStyles.is_confirmed : rcStyles.is_unconfirmed,
                )}
                expandIcon={<ExpandMoreIcon className={rcStyles.drawer__expansion_icon} />}
              >
                <div className={rcStyles.expansion_panel_layout}>
                  <span className={rcStyles.expansion_panel_primary_area}>
                    <h3 className={rcStyles.is_label}>
                      {buildConfirmationAndCommittalTypeText(arrangement.isConfirmed, arrangement.committalType)}
                    </h3>
                  </span>
                  <span className={rcStyles.expansion_panel_secondary_area}>
                    <h3 className={rcStyles.is_label}>
                      {formatCurrency(arrangement.calculation.finalTotal)}
                    </h3>
                    {arrangement.reference && (<p className={rcStyles.is_subtext}>{`${t('REF: ')}${arrangement.reference}`}</p>)}
                  </span>
                </div>
              </IconLeftExpansionPanelSummary>
              <ExpansionPanelDetails>
                <MenuList className={rcStyles.drawer__list}>
                  {paths
                    .map(path => (
                      <Link
                        to={`${url}/arrangement/${arrangement.id}/${path.path}`}
                        key={path.path}
                        className={rcStyles.drawer__nav_link}
                      >
                        <MenuItem className={rcStyles.drawer__list_item}>
                          {path.name}
                        </MenuItem>
                      </Link>
                    ))}
                </MenuList>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        })}
        <ButtonBase
          color="primary"
          variant="outlined"
          onClick={onModalOpen}
          data-test-id="createArrangement"
          className={rcStyles.create_case_button}
        >
          <AddIcon className={rcStyles.is_icon} />
          {t('Create Arrangement')}
        </ButtonBase>
      </div>
    </Drawer>
    <ArrangementModal
      bereavementId={bereavement.id}
      deceasedPerson={bereavement.deceasedPeople[0]}
      advisoryCommittalType={bereavement.advisoryCommittalType}
      disabled={bereavement.caseStatus === caseStatuses.CLOSED}
      isOpen={isModalOpen}
      additionalPath="/arrangement"
      onClose={onModalClose}
    />
  </Fragment>
);

CaseSideMenu.propTypes = {
  bereavement: bereavementType.isRequired,
  url: PropTypes.string.isRequired,
  scopes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  isOpen: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  getArrangementNavigation: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onModalOpen: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
};

export default CaseSideMenu;
