import React from 'react';

const IconLetterValues = () => (
  <svg
    viewBox="0 0 458 139"
    width="300"
  >
    <defs>
      <path id="a" d="m100.189505 77.8914576c-.2790927-.3033591-.225097-.8079762.000992-1.1369362.789582-1.1964716 1.940247-1.5044763 3.299649-1.3204759 1.817817.245777 3.268261 1.1665893 4.555984 2.3921757.231393.2201869.459114.4443717.837105.8105265.034145-.7490175.068048-1.2481107.07761-1.7473898.085676-4.5024575.227954-9.0051161.217038-13.5071749-.003944-1.5872936.534471-2.6478113 1.966285-3.2971587.236091-.1014986.899877-.124845 1.151379.0005459 1.294022.6216178 1.778715 1.6543438 1.771877 3.0560522-.01186 2.3989314.086974 4.7984131.125728 7.1977157.017182 1.0604137.00133 2.1212143.000335 3.2747106 1.738825-1.8632407 3.679734-1.7927953 4.923116-.0365162 1.523929-1.473372 3.716302-1.0843346 4.665999.9055538 2.823404-1.0830255 4.218465.5236134 4.217433 3.0565463-.025762 2.1769907.03602 4.374332-.245091 6.5239607-.303748 2.3249415-.615995 4.6508363-1.314595 6.8911477-.641962 2.059853-2.072033 3.68872-4.213227 4.3770308-4.704523 1.5124172-11.403244.7951153-13.199959-5.6968659-.033591-.1208633-.094615-.2339174-.133422-.3534473-.967857-2.9924368-2.590481-5.6377506-4.688017-7.9515833-1.17238-1.2931808-2.666852-2.3015439-4.016219-3.4384177l.000992-1.1369362" />
      <filter id="b" height="172.2%" width="192.9%" x="-46.4%" y="-30.6%">
        <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="4" />
        <feColorMatrix in="shadowBlurOuter1" type="matrix" values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.361232517 0" />
      </filter>
      <mask id="c" fill="#fff">
        <path d="m3.5.58333333c-1.12875 0-2.04166667.91291667-2.04166667 2.04166667 0 1.53125 2.04166667 3.79166667 2.04166667 3.79166667s2.04166667-2.26041667 2.04166667-3.79166667c0-1.12875-.91291667-2.04166667-2.04166667-2.04166667zm0 2.77083334c-.4025 0-.72916667-.32666667-.72916667-.72916667s.32666667-.72916667.72916667-.72916667.72916667.32666667.72916667.72916667-.32666667.72916667-.72916667.72916667z" fill="#fff" fillRule="evenodd" />
      </mask>
      <mask id="d" fill="#fff">
        <path d="m12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm-2 15-5-5 1.41-1.41 3.59 3.58 7.59-7.59 1.41 1.42z" fill="#fff" fillRule="evenodd" />
      </mask>
      <mask id="e" fill="#fff">
        <path d="m5.74.57744108c-1.85115 0-3.34833333.90369528-3.34833333 2.02104377 0 1.51578283 3.34833333 3.753367 3.34833333 3.753367s3.34833333-2.23758417 3.34833333-3.753367c0-1.11734849-1.49718333-2.02104377-3.34833333-2.02104377zm0 2.74284512c-.6601 0-1.19583333-.32336701-1.19583333-.72180135s.53573333-.72180135 1.19583333-.72180135 1.19583333.32336701 1.19583333.72180135-.53573333.72180135-1.19583333.72180135z" fill="#fff" fillRule="evenodd" />
      </mask>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g mask="url(#c)" transform="translate(242.220804 45)">
        <path d="m0 0h14.5833333v14.5833333h-14.5833333z" fill="#fff" transform="translate(-3.791667 -3.791667)" />
      </g>
      <ellipse cx="229" cy="111.495127" fill="#00b39e" fillOpacity=".061872" rx="228" ry="23.5" transform="matrix(.9998477 -.01745241 .01745241 .9998477 -1.91098 4.013582)" />
      <g transform="translate(202.720804)">
        <path d="m68.881.0012424 17.119 17.29v86.7099996c0 2.209139-1.790861 4-4 4h-78c-2.209139 0-4-1.790861-4-4v-99.99854114c0-2.209139 1.790861-4 4-4z" fill="#c2dfff" />
        <rect fill="#87c1ff" height="10" rx="1" width="60" x="14" y="27.001242" />
        <rect fill="#87c1ff" height="10" rx="1" width="60" x="14" y="43.001242" />
        <rect fill="#a791c0" height="10" rx="1" width="52" x="14" y="61.001242" />
        <path d="m69.308.0012424 16.692 17.674v.326h-17v-17.99970911z" fill="#87c1ff" />
      </g>
      <g transform="translate(332.720804)">
        <path d="m68.881.0012424 17.119 17.29v86.7099996c0 2.209139-1.790861 4-4 4h-78c-2.209139 0-4-1.790861-4-4v-99.99854114c0-2.209139 1.790861-4 4-4z" fill="#c2dfff" />
        <rect fill="#87c1ff" height="10" rx="1" width="60" x="14" y="27.001242" />
        <rect fill="#87c1ff" height="10" rx="1" width="60" x="14" y="43.001242" />
        <rect fill="#90c5ff" height="10" rx="1" width="38" x="14" y="61.001242" />
        <path d="m69.308.0012424 16.692 17.674v.326h-17v-17.99970911z" fill="#87c1ff" />
      </g>
      <g mask="url(#d)" transform="translate(387 58)">
        <path d="m0 0h50v50h-50z" fill="#00b39e" transform="translate(-13 -13)" />
      </g>
      <g transform="translate(25.720804 3)">
        <g mask="url(#e)" transform="translate(33.62 36.626263)">
          <path d="m0 0h23.9166667v14.4360269h-23.9166667z" fill="#fff" transform="translate(-6.218333 -3.753367)" />
        </g>
        <rect fill="#c2dfff" height="98" rx="11" width="121.36" x=".82" />
        <path d="m11.82 0h99.36c6.075132 0 11 4.92486775 11 11v1.8686869h-121.36v-1.8686869c0-6.07513225 4.92486775-11 11-11z" fill="#a791c0" />
      </g>
      <rect fill="#87c1ff" height="10" rx="1" width="29" x="43.720804" y="41" />
      <rect fill="#a791c0" height="10" rx="1" width="60" x="43.720804" y="55" />
      <path d="m110.720804 53h12v15h-12z" fill="#00bba8" />
      <g fill="#cbbeda">
        <path d="m4.34734537 3.34903729 7.68953803-.85095492c.5489337-.06074719 1.0431778.33500618 1.103925.8839399.0080901.0731052.0080901.14687983 0 .21998504l-.8509549 7.68953809c-.0607472.5489337-.5549913.9446871-1.103925.8839399-.2258443-.0249929-.4364433-.1261547-.5971142-.2868257l-3.41929162-3.41929154-3.41929157-3.41929157c-.39052429-.3905243-.39052429-1.02368928 0-1.41421357.16067092-.16067092.37126995-.2618328.59711426-.28682563z" transform="matrix(.70710678 .70710678 -.70710678 .70710678 318.627111 39.80235)" />
        <path d="m303.720804 50h11v2h-11z" />
      </g>
      <g fill="#cbbeda">
        <path d="m4.34734537 3.34903729 7.68953803-.85095492c.5489337-.06074719 1.0431778.33500618 1.103925.8839399.0080901.0731052.0080901.14687983 0 .21998504l-.8509549 7.68953809c-.0607472.5489337-.5549913.9446871-1.103925.8839399-.2258443-.0249929-.4364433-.1261547-.5971142-.2868257l-3.41929162-3.41929154-3.41929157-3.41929157c-.39052429-.3905243-.39052429-1.02368928 0-1.41421357.16067092-.16067092.37126995-.2618328.59711426-.28682563z" transform="matrix(.70710678 .70710678 -.70710678 .70710678 184.627111 39.80235)" />
        <path d="m169.720804 50h11v2h-11z" />
      </g>
      <g transform="matrix(.94551858 .32556815 -.32556815 .94551858 31.605199 -32.865224)">
        <use fill="#000" filter="url(#b)" xlinkHref="#a" />
        <use fill="#f9d1d1" fillRule="evenodd" xlinkHref="#a" />
      </g>
    </g>
  </svg>
);


export default IconLetterValues;
