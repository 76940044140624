import thunk from 'redux-thunk';
import { addMiddleware } from 'redux-dynamic-middlewares';
import clientFactory from '../clientFactory';
import httpLink from '../links/httpLink';
import accessTokenLink from '../links/accessTokenLink';
import refreshTokenLink from '../links/refreshTokenLink';
import errorHandlingLink from '../links/errorHandlingLink';
import checkUpdateAfterware from '../links/checkUpdateLink';
import websocketStatusLink from '../links/websocketStatusLink';

const defaultClient = clientFactory([
  errorHandlingLink,
  refreshTokenLink,
  checkUpdateAfterware,
  accessTokenLink,
  websocketStatusLink,
  httpLink,
]);

addMiddleware(thunk.withExtraArgument(defaultClient));

export default defaultClient;
