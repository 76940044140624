import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  calendarEventTypes,
  calendarEventsThemeObj,
  unconfirmedEventBackgroundColour,
  unconfirmedEventTextColour,
} from 'constants/calendar';
import { getInitials, buildNameString, buildAddressString } from 'services/utils';
import { nameType, addressType } from 'types/common';
import styles from './styles.scss';

const CalendarEvent = ({ event, view }) => {
  const {
    __typename,
    deceasedName,
    locations,
    location,
    title,
    description,
    wasRaisedFromAnUnconfirmedArrangement,
  } = event;
  const { icon: ThemeIcon, color, weekViewTextColour } = calendarEventsThemeObj[__typename];
  const backgroundColour = wasRaisedFromAnUnconfirmedArrangement ? unconfirmedEventBackgroundColour : color;
  const textColour = wasRaisedFromAnUnconfirmedArrangement ? unconfirmedEventTextColour : weekViewTextColour;
  const isCortege = __typename === calendarEventTypes.CortegeBereavementCalendarEvent;
  const isCustom = __typename === calendarEventTypes.CustomCalendarEvent;
  const eventLocation = isCortege
    ? locations.find(cortegeLocation => cortegeLocation.type === 'FROM')
    : location;

  return (
    <div
      style={{ borderColor: color }}
      className={classNames(styles.eventContentInner, {
        [styles.eventContentInnerDay]: view === 'day',
        [styles.eventContentInnerWeek]: view === 'week',
      })}
    >
      <div
        style={{
          backgroundColor: backgroundColour,
          borderColor: color,
          color: textColour,
        }}
        className={classNames(
          styles.eventContentLeft,
          { [styles.unconfirmedEvent]: wasRaisedFromAnUnconfirmedArrangement },
        )}
      >
        <ThemeIcon />
        {view !== 'day' && <div>{isCustom ? title : getInitials(deceasedName)}</div>}
      </div>
      {view === 'day' && (
      <div
        className={styles.eventContentRight}
        style={{ borderColor: color }}
      >
        <strong className={styles.eventPrimaryText}>
          {isCustom ? title : buildNameString(deceasedName)}
        </strong>
        <p className={styles.eventSecondaryText}>{ isCustom ? description : buildAddressString(eventLocation)}</p>
      </div>
      )}
    </div>

  );
};

CalendarEvent.propTypes = {
  view: PropTypes.string.isRequired,
  event: PropTypes.shape({
    bereavementId: PropTypes.string.isRequired,
    start: PropTypes.instanceOf(Date).isRequired,
    end: PropTypes.instanceOf(Date).isRequired,
    deceasedName: nameType.isRequired,
    locations: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    location: PropTypes.oneOfType([addressType, PropTypes.string]),
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    __typename: PropTypes.oneOf([
      calendarEventTypes.TransferIntoCareBereavementCalendarEvent,
      calendarEventTypes.ExternalTransferBereavementCalendarEvent,
      calendarEventTypes.ViewingBereavementCalendarEvent,
      calendarEventTypes.ArrangementMeetingBereavementCalendarEvent,
      calendarEventTypes.CortegeBereavementCalendarEvent,
      calendarEventTypes.ServiceBereavementCalendarEvent,
      calendarEventTypes.CustomCalendarEvent,
      calendarEventTypes.ReceptionVenueBereavementCalendarEvent,
    ]),
    wasRaisedFromAnUnconfirmedArrangement: PropTypes.bool,
  }),
};

export default CalendarEvent;
