import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { apolloClientType } from 'types/apollo';
import { bereavedPersonConnectionType, ashesRecordType } from 'types/bereavement';

import { getAshesReleaseForm } from './queries.gql';
import AshesReleaseFormModal from './AshesReleaseFormModal';
import { ashesValidation } from './validation';

export class AshesReleaseFormModalContainer extends Component {
  static propTypes = {
    bereavementId: PropTypes.string.isRequired,
    relatedData: PropTypes.objectOf(PropTypes.any),
    deceasedPersonId: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    ashesRecords: PropTypes.arrayOf(ashesRecordType).isRequired,
    bereavedPeopleConnections: PropTypes.arrayOf(bereavedPersonConnectionType).isRequired,
    onClose: PropTypes.func.isRequired,
    client: apolloClientType.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    validate: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      selectedAshesRecords: [],
      selectedClient: props.bereavedPeopleConnections.find(client => client.isPrimaryContact).id,
      isValidationEnabled: false,
      isReleaseFormDownloading: false,
    };
  }

  handleChangeActiveStep = (activeStep) => {
    this.setState({
      activeStep,
      isValidationEnabled: false,
    });
  };

  handleChangeAshes = (ashesId, isChecked) => {
    const { validate } = this.props;
    const { selectedAshesRecords } = this.state;
    let updatedAshesRecords = [...selectedAshesRecords];
    if (isChecked) {
      updatedAshesRecords.push(ashesId);
    } else {
      updatedAshesRecords = updatedAshesRecords.filter(id => ashesId !== id);
    }

    validate({ ashes: updatedAshesRecords }, ashesValidation);
    this.setState({ selectedAshesRecords: updatedAshesRecords });
  }

  handleChangeClient = (connectionId) => {
    this.setState({ selectedClient: connectionId });
  }

  handleSaveAshes = (step = 1) => {
    const { validate } = this.props;
    const { selectedAshesRecords } = this.state;

    this.setState({ isValidationEnabled: true });

    const isValid = validate({ ashes: selectedAshesRecords }, ashesValidation);
    if (isValid) {
      this.handleChangeActiveStep(step);
    }
  }

  handleGenerateReleaseForm = async () => {
    const { client, bereavementId, deceasedPersonId } = this.props;
    const { selectedClient } = this.state;
    this.setState({ isReleaseFormDownloading: true });

    const { data } = await client.query({
      query: getAshesReleaseForm,
      variables: {
        bereavementId,
        deceasedPersonId,
        bereavedPersonConnectionId: selectedClient,
      },
    });

    const { deceasedPeople } = data.bereavement || {};
    const { uri } = deceasedPeople[0].ashesReleaseFormPdf;
    if (uri) {
      window.open(uri, uri).focus();
    }

    this.setState({ isReleaseFormDownloading: false });
  }

  handleClose = () => {
    const { bereavedPeopleConnections, onClose } = this.props;

    this.setState({
      activeStep: 0,
      selectedAshesRecords: [],
      selectedClient: bereavedPeopleConnections.find(client => client.isPrimaryContact).id,
      isValidationEnabled: false,
      isReleaseFormDownloading: false,
    });
    onClose();
  }

  render() {
    const {
      relatedData,
      isOpen,
      ashesRecords,
      bereavedPeopleConnections,
      errors,
    } = this.props;
    const {
      activeStep,
      selectedAshesRecords,
      selectedClient,
      isReleaseFormDownloading,
      isValidationEnabled,
    } = this.state;

    return (
      <AshesReleaseFormModal
        activeStep={activeStep}
        relatedData={relatedData}
        isOpen={isOpen}
        ashesRecords={ashesRecords || []}
        bereavedPeopleConnections={bereavedPeopleConnections}
        selectedAshesRecords={selectedAshesRecords}
        selectedClient={selectedClient}
        isReleaseFormDownloading={isReleaseFormDownloading}
        onChangeActiveStep={this.handleChangeActiveStep}
        onChangeAshes={this.handleChangeAshes}
        onChangeClient={this.handleChangeClient}
        onSaveAshes={this.handleSaveAshes}
        onClose={this.handleClose}
        onGenerateReleaseForm={this.handleGenerateReleaseForm}
        errors={isValidationEnabled ? errors : {}}
      />
    );
  }
}

export default withValidation(withApollo(AshesReleaseFormModalContainer));
