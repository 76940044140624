import moment from 'moment';
import {
  string, object, number, boolean,
} from 'yup';

export const validationSchema = object().shape({
  name: object().shape({
    title: string().nullable(),
    givenName: string().nullable().required('Given name is required'),
    familyName: string().nullable().required('Family name is required'),
  }),
  deathDateTime: string().nullable(),
  dateOfBirth: string().nullable(),
  birthAndDeathDateValidation: string().nullable().when(['deathDateTime', 'dateOfBirth'], {
    is: ((deathDateTime, dateOfBirth) => {
      if (!deathDateTime || !dateOfBirth) {
        return false;
      }
      const deathDateTimeAsMoment = moment(deathDateTime);
      const dateOfBirthAsMoment = moment(dateOfBirth);
      return deathDateTimeAsMoment.isBefore(dateOfBirthAsMoment);
    }),
    then: string().required('Date of birth must be before the date of death.'),
  }),
  ageAtDeath: number().typeError('Age must be a number').nullable().min(0, 'Age of death must be greater than or equal to 0'),
  collectionLocation: object().nullable().shape({
    addressLine1: string().nullable(),
    addressLine2: string().nullable(),
    town: string().nullable(),
    county: string().nullable(),
    postCode: string().nullable(),
    countryCode: string().nullable(),
  }).when(['isReadyForCollection'], {
    is: isReadyForCollection => isReadyForCollection,
    then: object().nullable().shape({
      addressLine1: string().nullable().required('First line of address is required'),
      addressLine2: string().nullable(),
      town: string().nullable().required('Town is required'),
      county: string().nullable(),
      postCode: string().nullable().required('Post Code is required'),
      countryCode: string().nullable().required('Country is required'),
    }).required('Body Location is required'),
  }),
  alsoKnownAs: string().nullable(),
  previousSurname: string().nullable(),
  faith: string().nullable(),
  address: object().nullable().shape({
    addressLine1: string().nullable(),
    addressLine2: string().nullable(),
    town: string().nullable(),
    county: string().nullable(),
    postCode: string().nullable(),
    countryCode: string().nullable(),
  }),
  bodySize: object().nullable().shape({
    depth: number().nullable().min(0, 'Depth must be greater than or equal to 0'),
    length: number().nullable().min(0, 'Length must be greater than or equal to 0'),
    units: string().nullable().oneOf([null, 'INCHES', 'CENTIMETERS']),
    width: number().nullable().min(0, 'Width must be greater than or equal to 0'),
  }),
  placeOfDeath: string().nullable(),
  placeOfBirth: string().nullable(),
  registrationOfDeathDate: string().nullable(),
  isReadyForCollection: boolean().nullable(),
  gender: string().nullable(),
  occupation: string().nullable(),
  nationality: string().nullable(),
  maritalStatus: string().nullable().oneOf([null, 'SINGLE', 'MARRIED', 'CIVILPARTNERED', 'DIVORCED', 'WIDOWED', 'OTHER']),
  weight: number('Body weight must be a number').nullable().min(0, 'Body weight must be greater than or equal to 0'),
  NINumber: string().nullable(),
  NHSNumber: string().nullable(),
}, ['dateOfBirth', 'ageAtDeath']);
