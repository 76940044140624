import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const dialogClasses = {
  paper: rcStyles.rc_dialog__paper,
};

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const AdminHomeGroupModal = ({
  title,
  homeGroup,
  isOpen,
  onHomeGroupChange,
  onSave,
  onCancel,
  disabled,
  formRefs,
  errors,
}) => (
  <Dialog
    onClose={onCancel}
    aria-labelledby="assign-case-title"
    open={isOpen}
    fullWidth
    maxWidth="sm"
    classes={dialogClasses}
    TransitionComponent={Transition}
    disableBackdropClick
    disableEscapeKeyDown
  >
    <DialogTitle disableTypography className={styles.c_dialog__header}>
      <div className={styles.c_dialog__title}>
        {title}
      </div>
    </DialogTitle>
    <DialogContent className={styles.c_dialog__content}>
      <div ref={formRefs.name}>
        <TextField
          label={t('Home group name*')}
          value={homeGroup ? homeGroup.name : ''}
          onChange={event => onHomeGroupChange('name', event.target.value)}
          fullWidth
          inputProps={{
            'data-test-id': 'name',
          }}
          disabled={disabled}
          error={errors && !!errors.name}
          helperText={errors && errors.name}
        />
      </div>
    </DialogContent>
    <DialogActions className={styles.c_dialog__actions}>
      <Button
        onClick={onCancel}
        variant="outlined"
        color="primary"
        data-test-id="cancelCase"
        fullWidth
      >
        {t('Cancel')}
      </Button>
      <Button
        onClick={onSave}
        variant="contained"
        color="primary"
        data-test-id="saveCase"
        fullWidth
      >
        {t('Save')}
      </Button>
    </DialogActions>
  </Dialog>
);

AdminHomeGroupModal.propTypes = {
  title: PropTypes.string,
  homeGroup: PropTypes.objectOf(PropTypes.any),
  isOpen: PropTypes.bool.isRequired,
  onHomeGroupChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
};

export default AdminHomeGroupModal;
