import { string, object, array } from 'yup';

export const validationSchema = object().shape({
  selections: array().of(
    object().shape({
      categoryMetaData: object().shape({
        officiant: object().shape({
          customDetails: object().nullable().shape({
            name: string().required('Name is required'),
            address: object().nullable().shape({
              addressLine1: string().nullable().required('First line of address is required'),
              addressLine2: string().nullable(),
              town: string().required('Town is required'),
              county: string().nullable(),
              postCode: string().nullable().required('Post Code is required'),
              countryCode: string().nullable().required('Country is required'),
            }),
            phone: object().nullable().shape({
              telephone: object().shape({
                countryCode: string().required('Telephone country code is required'),
                number: string().required('Telephone number is required'),
              }),
              type: string().required('Telephone type is required'),
            }),
          }),
        }),
      }),
    }),
  ),
});
