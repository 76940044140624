import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

export const editEventTransform = (customEvent) => {
  const updatedEvent = {
    ...customEvent,
    bereavementId: customEvent.bereavementId && customEvent.bereavementId.id,
    homeIds: customEvent.homeIds
      && customEvent.homeIds.map(unit => unit.id),
    start: moment(customEvent.start).format(),
    end: moment(customEvent.end).format(),
  };

  delete updatedEvent.__typename;

  if (updatedEvent.location) {
    delete updatedEvent.location.__typename;
  }

  return updatedEvent;
};

export const createEventTransform = customEvent => ({
  ...customEvent,
  id: uuidv4(),
  bereavementId: customEvent.bereavementId && customEvent.bereavementId.id,
  homeIds: customEvent.homeIds
    && customEvent.homeIds.map(unit => unit.id),
});
