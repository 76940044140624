import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import ActionCard from 'components/common/ActionCard';
import AutoCompleteTextField from 'components/common/AutoCompleteTextField';
import Card from 'components/common/Card';
import ExpandableFormSection from 'components/common/ExpandableFormSection';
import {
  ashesRecordExternalEditType, ashesRecordActions, ashesRecordActionLabels, ashesRecordActionReturnOptions,
} from 'constants/bereavement';
import { buildNameString, getFormattedDate } from 'services/utils';
import { ashesRecordActionType } from 'types/bereavement';
import { reactRefType, nameType } from 'types/common';

import classNames from 'classnames';
import styles from 'scss/main.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const AshesRecordModal = ({
  formData,
  actions,
  relatedData,
  isOpen,
  disabled,
  onChange,
  onAddAction,
  onActionChange,
  onClose,
  onSave,
  onEditArrangement,
  onEditPersonToReceiveAshes,
  dialogRef,
  errors,
  formRefs,
}) => (
  <Dialog
    open={isOpen}
    fullScreen
    scroll="paper"
    TransitionComponent={Transition}
    className={styles.c_dialogue}
    disableBackdropClick
    disableEscapeKeyDown
  >
    <DialogTitle
      disableTypography
      className={styles.dialog_title_bar}
    >
      <IconButton
        className={classNames(
          styles.o_button__secondary,
          styles.is_close,
        )}
        color="primary"
        aria-label="Close"
        onClick={onClose}
        data-test-id="ashesClose"
      >
        <CloseIcon />
      </IconButton>
      <div
        className={styles.is_title}
        data-test-id="ashesTitle"
      >
        {t('Ashes')}
      </div>
      <div className={styles.is_actions}>
        <Button
          onClick={() => onSave()}
          variant="contained"
          color="secondary"
          data-test-id="ashesSaveAndReturn"
          disabled={disabled}
          className={styles.o_button__secondary}
        >
          {t('Save & return')}
        </Button>
      </div>
    </DialogTitle>
    <div
      ref={dialogRef}
      className={classNames(
        styles.c_dialog__overflow,
      )}
    >
      <DialogContent className={styles.o_view}>
        <div className={styles.o_view__main}>
          <div data-test-id="ashesDetails">
            <ExpandableFormSection
              title={t('Ashes details')}
              titleClasses={[
                styles.pica_light,
                styles.is_primary,
              ]}
              disableExpansion
            >
              <Fragment>
                <Card
                  title={t('Handling of ashes')}
                  titleClasses={[
                    styles.long_primer,
                    styles.is_black,
                  ]}
                  onEdit={() => onEditArrangement(ashesRecordExternalEditType.HANDLING_ASHES)}
                  disabled={disabled}
                  testId="handlingOfAshes"
                >
                  <div data-test-id="handlingOfAshesContainer">
                    {relatedData.handlingOfAshes || t('No options selected in arrangement')}
                  </div>
                </Card>

                <Card
                  onEdit={() => onEditArrangement(ashesRecordExternalEditType.CREMATORIUM_REQUESTED)}
                  disabled={disabled}
                  testId="crematorium"
                  title={t('Crematorium requested')}
                  titleClasses={[
                    styles.long_primer,
                    styles.is_black,
                  ]}
                >
                  <div data-test-id="crematoriumInformationContainer">
                    <p className={styles.u_push__bottom}>
                      {relatedData.crematoriumRequested || t('No crematorium selected in arrangement')}
                    </p>

                    {relatedData.crematoriumDateTime && (
                      <Fragment>
                        <div
                          className={styles.brevier}
                          data-test-id="crematoriumDateAndTimeCardHeader"
                        >
                          {t('Date & time')}
                        </div>
                        <div data-test-id="crematoriumDateAndTime">
                          {getFormattedDate(relatedData.crematoriumDateTime, 'h:mma, DD MMMM YYYY')}
                        </div>
                      </Fragment>
                    )}
                  </div>
                </Card>

                <Card
                  title={t('Containers type requested')}
                  titleClasses={[
                    styles.long_primer,
                    styles.is_black,
                  ]}
                  onEdit={() => onEditArrangement(ashesRecordExternalEditType.CONTAINERS_TYPE_REQUESTED)}
                  disabled={disabled}
                  testId="containers"
                >
                  <div
                    className={classNames(
                      styles.primer,
                      styles.is_bold,
                    )}
                    data-test-id="urnsHeader"
                  >
                    {t('Urns')}
                  </div>
                  <div data-test-id="urns">
                    {relatedData.urns && relatedData.urns.length ? (
                      <ul className={classNames(
                        styles.o_list,
                        styles.is_plain,
                      )}
                      >
                        {(relatedData.urns || []).map(urn => (
                          <li className={styles.o_list__item}>{urn}</li>
                        ))}
                      </ul>
                    ) : t('No urn selected in arrangement')}
                  </div>
                  {(relatedData.urnNotes && !!relatedData.urnNotes.length) && (
                    <Fragment>
                      <div
                        className={classNames(
                          styles.primer,
                          styles.is_bold,
                        )}
                        data-test-id="urnNotesHeader"
                      >
                        {t('Notes')}
                      </div>
                      <div data-test-id="urnNotes">
                        <ul className={classNames(
                          styles.o_list,
                          styles.is_plain,
                        )}
                        >
                          {relatedData.urnNotes.map(note => (
                            <li
                              data-test-id="urnNote"
                              key={note}
                              className={styles.o_list__item}
                            >
                              {note}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Fragment>
                  )}
                </Card>
                <div
                  className={styles.u_push__bottom}
                  ref={formRefs.cremationCertificateNumber}
                >
                  <TextField
                    label={t('Cremation certificate number')}
                    value={formData.cremationCertificateNumber || ''}
                    onChange={event => onChange('cremationCertificateNumber', event.target.value)}
                    inputProps={{
                      'data-test-id': 'cremationCertificateNumber',
                    }}
                    error={errors && !!errors.cremationCertificateNumber}
                    helperText={errors && errors.cremationCertificateNumber}
                    fullWidth
                  />
                </div>
                <div
                  className={styles.u_push__bottom}
                  ref={formRefs.storageLocation}
                >
                  <TextField
                    label={t('Storage location')}
                    value={formData.storageLocation || ''}
                    onChange={event => onChange('storageLocation', event.target.value)}
                    inputProps={{
                      'data-test-id': 'storageLocation',
                    }}
                    error={errors && !!errors.storageLocation}
                    helperText={errors && errors.storageLocation}
                    fullWidth
                  />
                </div>

                <Card
                  title={t('Person to receive ashes')}
                  titleClasses={[
                    styles.long_primer,
                    styles.is_black,
                  ]}
                  onEdit={onEditPersonToReceiveAshes}
                  disabled={disabled}
                  testId="personToReceive"
                >
                  <div
                    className={styles.brevier}
                    data-test-id="personToReceiveAshesNameHeader"
                  >
                    {t('Name')}
                  </div>
                  <div
                    className={styles.u_push__bottom}
                    data-test-id="personToReceiveAshesName"
                  >
                    {relatedData.personToReceiveAshes
                      && buildNameString(relatedData.personToReceiveAshes.name)}
                  </div>
                  {relatedData.personToReceiveAshes.email && (
                    <Fragment>
                      <div
                        className={styles.brevier}
                        data-test-id="personToReceiveAshesEmailHeader"
                      >
                        {t('Email')}
                      </div>
                      <div
                        className={styles.u_push__bottom}
                        data-test-id="personToReceiveAshesEmail"
                      >
                        {relatedData.personToReceiveAshes && relatedData.personToReceiveAshes.email}
                      </div>
                    </Fragment>
                  )}
                  {relatedData.personToReceiveAshes.phone && (
                    <Fragment>
                      <div
                        className={styles.brevier}
                        data-test-id="personToReceiveAshesPhoneHeader"
                      >
                        {t('Phone')}
                      </div>
                      <div data-test-id="personToReceiveAshesPhone">
                        {relatedData.personToReceiveAshes.phone}
                      </div>
                    </Fragment>
                  )}
                </Card>
              </Fragment>
            </ExpandableFormSection>
          </div>

          <div
            ref={formRefs.actions}
            data-test-id="actionTakenWithAshes"
          >
            <ExpandableFormSection
              title={t('Action taken with ashes')}
              disableExpansion
            >
              <Fragment>
                <div
                  className={styles.brevier}
                  data-test-id="actionTakenWithDeedMessage"
                >
                  {t('Create an audit trail to keep track of an ash\'s location.')}
                </div>
                {(errors && errors.actions) && (
                <div className={classNames(
                  styles.u_push__bottom,
                  styles.is_red,
                )}
                >
                  {errors.actions}
                </div>
                )}
                {actions && actions.map((action, index) => (
                  <ActionCard
                    key={action.timeCreated}
                    action={action.actionTaken}
                    createdBy={action.createdBy}
                    createdDateTime={action.createdDateTime}
                    actionOptions={ashesRecordActions}
                    actionOptionLabels={ashesRecordActionLabels}
                    onChange={value => onActionChange('actionTaken', value, index)}
                    disabled={!action.isNew}
                    data-test-id="actionEntry"
                  >
                    <Fragment>
                      {(action.actionTaken === ashesRecordActions.SCATTERED_BY_FD) && (
                      <TextField
                        label={t('Location scattered')}
                        margin="normal"
                        value={action.locationScattered || ''}
                        onChange={event => onActionChange('locationScattered', event.target.value, index)}
                        inputProps={{
                          'data-test-id': 'locationScattered',
                        }}
                        disabled={disabled || !action.isNew}
                        fullWidth
                      />
                      )}
                      {(action.actionTaken === ashesRecordActions.RETURNED_TO_CLIENT) && (
                      <AutoCompleteTextField
                        placeholder={t('Return options')}
                        value={action.returnToClientMethod || ''}
                        suggestions={ashesRecordActionReturnOptions}
                        onChange={value => onActionChange('returnToClientMethod', value, index)}
                        disabled={disabled || !action.isNew}
                        data-test-id="returnToClientMethod"
                      />
                      )}
                    </Fragment>
                  </ActionCard>
                ))}
                <div className={styles.u_push__top}>
                  <Button
                    onClick={onAddAction}
                    variant="outlined"
                    color="primary"
                    data-test-id="addAction"
                  >
                    <AddIcon />
                    {t('Add Action')}
                  </Button>
                </div>
              </Fragment>
            </ExpandableFormSection>
          </div>
        </div>
      </DialogContent>
    </div>
  </Dialog>
);

AshesRecordModal.propTypes = {
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
  relatedData: PropTypes.shape({
    handlingOfAshes: PropTypes.string,
    crematoriumRequested: PropTypes.string,
    crematoriumDateTime: PropTypes.string,
    urns: PropTypes.arrayOf(PropTypes.string),
    engraving: PropTypes.string,
    urnNotes: PropTypes.arrayOf(PropTypes.string),
    personToReceiveAshes: PropTypes.shape({
      name: nameType,
      phone: PropTypes.string,
      email: PropTypes.string,
    }),
  }),
  actions: PropTypes.arrayOf(ashesRecordActionType),
  isOpen: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onAddAction: PropTypes.func.isRequired,
  onActionChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onEditArrangement: PropTypes.func.isRequired,
  onEditPersonToReceiveAshes: PropTypes.func.isRequired,
  dialogRef: reactRefType.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AshesRecordModal;
