import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';

const MainContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const NavigationColumn = styled.section<{ isVisibleForMobile: boolean, backgroundColor: string|undefined }>`
  display: grid;
  grid-template-rows: auto 1fr;
  align-items: flex-start;
  height: 100vh;
  overflow-y: auto;

  ${(props) => {
    if (props?.backgroundColor) {
      return `
        background-color: ${props?.backgroundColor};
      `;
    }
    return `
      background-color: #f3f3f3;
    `;
  }}

  @media (max-width: 767px) {
    background-color: #fff;
    display: ${props => (props.isVisibleForMobile ? 'block' : 'none')};
  }
`;

const DetailsColumn = styled.div<{ isVisibleForMobile: boolean }>`
  display: grid;
  padding: 2rem;
  padding-top: 4rem;
  padding-right: 4rem;
  overflow-y: auto;

  @media (max-width: 767px) {
    display: ${props => (props.isVisibleForMobile ? 'block' : 'none')};
    padding: 1rem;
  }

  & .item__wrapper {
    line-height: 1.5;
    color: #514441;
  }
  & .item__heading,
  & .item__images,
  & .item__images {
    margin-bottom: 1rem;
  }
  & .item__heading {
    font-size: 28px;
  }
  & .item__description {
    margin-bottom: 2rem;
  }
`;

const LogoWrapper = styled.div`
  width: fit-content;
`;

const Logo = styled.img`
  color: #14213d;
  font-size: 28px;
  font-style: italic;
  max-height: 120px;
  max-width: 300px;
  padding: 1rem;

  @media (max-width: 767px) {
    max-height: 80px;
    max-width: 200px;
  }
`;

const ItemsContainer = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 60px 1fr;
  margin-right: 1rem;
  height: 100%;

  @media screen and (min-width: 768px) {
    display: grid;
    grid-gap: 1rem;
  }

  & .menu__wrapper {
    justify-self: center;
  }

  & .items__wrapper {
    line-height: 2rem;
    color: #515551;
    display: grid;
    grid-template-rows: auto 1fr;
  }

  & .category__heading {
    font-size: 28px;
    margin-bottom: 1rem;
  }
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;

  & .inner {
    display: block;
    height: 100px;
    width: 100px;
    margin: auto;

    > svg {
      fill: $color-primary;
    }
  }
`;

const Cards = styled(InfiniteScroll)`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  margin-bottom: 1rem;
`;

const CloseIconWrapper = styled.div`
  display: none;

  @media (max-width: 767px) {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
`;

export default {
  MainContainer,
  NavigationColumn,
  DetailsColumn,
  Logo,
  LogoWrapper,
  ItemsContainer,
  Cards,
  Loader,
  CloseIconWrapper,
};
