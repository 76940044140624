import {
  AccountCorrespondenceLookup,
  CaseCorrespondenceLookup,
  CorrespondenceCategory,
} from 'constants/ts/tenant';
import {
  getArrayIndexForKeyValue,
  updateArrayByIndex,
} from 'services/utils';
import {
  SystemTemplateDefinition,
  TemplateDefinition,
  TemplateDefinitionsLookup,
} from 'types/ts/tenant';

export const buildTemplateDefinitionsLookup = (tenantDefinitions?: TemplateDefinition[]) => {
  const lookup: TemplateDefinitionsLookup = {
    customTemplates: {
      [CorrespondenceCategory.CASE]: [],
      [CorrespondenceCategory.ACCOUNT]: [],
    },
    systemTemplates: {
      [CorrespondenceCategory.CASE]: [...CaseCorrespondenceLookup as SystemTemplateDefinition[]],
      [CorrespondenceCategory.ACCOUNT]: [...AccountCorrespondenceLookup as SystemTemplateDefinition[]],
    },
  };

  if (tenantDefinitions) {
    tenantDefinitions.forEach((definition) => {
      const { category } = definition;
      if (!lookup.customTemplates[category]) {
        return;
      }

      if (definition.type === 'CUSTOM') {
        lookup.customTemplates[category].push(definition);
      } else {
        const index: number = getArrayIndexForKeyValue(lookup.systemTemplates[category], 'type', definition.type);
        if (index > -1) {
          const systemTempalate = lookup.systemTemplates[category][index] as SystemTemplateDefinition;
          const definitionWithKey = {
            ...definition,
            key: systemTempalate.key,
          };
          lookup.systemTemplates[category] = updateArrayByIndex(
            lookup.systemTemplates[category],
            index,
            definitionWithKey,
          );
        } else {
          lookup.systemTemplates[category].push(definition);
        }
      }
    });
  }

  return lookup;
};

export const updateTemplateDefinitionsLookup = (
  lookup: TemplateDefinitionsLookup,
  template: TemplateDefinition,
) => {
  const updatedLookup = {
    ...lookup,
  };

  if (template.type === 'CUSTOM') {
    const catergoryLookup = lookup.customTemplates[template.category];
    const index = getArrayIndexForKeyValue(catergoryLookup, 'id', template.id);
    if (index === -1) {
      updatedLookup.customTemplates[template.category].push(template);
    } else {
      updatedLookup.customTemplates[template.category] = updateArrayByIndex(
        catergoryLookup,
        index,
        template,
      );
    }
  } else {
    const catergoryLookup = lookup.systemTemplates[template.category];
    const index = getArrayIndexForKeyValue(catergoryLookup, 'type', template.type);
    updatedLookup.systemTemplates[template.category] = updateArrayByIndex(
      catergoryLookup,
      index,
      template,
    );
  }

  return updatedLookup;
};

export const deleteDefinitionFromTemplateDefinitionLookup = (
  lookup: TemplateDefinitionsLookup,
  template: TemplateDefinition,
) => {
  const updatedLookup = {
    ...lookup,
  };

  const lookupKey = template.type === 'CUSTOM' ? 'customTemplates' : 'systemTemplates';
  const catergoryLookup = lookup[lookupKey][template.category];
  const index = getArrayIndexForKeyValue(catergoryLookup, 'id', template.id);
  if (index > -1) {
    updatedLookup[lookupKey][template.category].splice(index, 1);
  }

  return updatedLookup;
};
