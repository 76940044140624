import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { withValidation } from '@funeralguide/react-form-validation-hoc';

import {
  editServiceSelectionAction,
  removeServiceSelectionAction,
} from 'actions/arrangement';
import { enqueueSnackbarAction, removeSnackbarAction } from 'actions/snackbar';
import { changeConfirmedArrangementMessage, serviceCategories } from 'constants/arrangement';
import { caseStatuses } from 'constants/bereavement';
import styles from 'scss/main.scss';
import {
  officiantServiceSelectionTransform,
  officiantServiceSelectionsMutationTransform,
} from 'transforms/arrangement';
import { apolloClientType } from 'types/apollo';
import { bereavementType, arrangementType } from 'types/bereavement';
import { historyType } from 'types/reactRouter';

import {
  editArrangementOfficiantInformation,
  removeServiceSelectionMutation,
} from './mutations.gql';
import { validationSchema } from './validation';
import ArrangementOfficiantsForm from './ArrangementOfficiantsForm';

class ArrangementOfficiantsFormContainer extends Component {
  static propTypes = {
    bereavement: bereavementType.isRequired,
    arrangement: arrangementType.isRequired,
    client: apolloClientType.isRequired,
    editServiceSelection: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    removeServiceSelection: PropTypes.func.isRequired,
    history: historyType.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    removeSnackbar: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextSelections = (nextProps?.arrangement?.serviceSelections || [])
      .filter(selection => selection?.service?.category === serviceCategories.OFFICIANTS)
      .map(selection => officiantServiceSelectionTransform(selection));
    const prevFormData = prevState?.formData || { selections: [] };

    if (nextSelections?.length !== prevFormData?.selections?.length) {
      return {
        formData: {
          ...prevFormData,
          selections: nextSelections,
        },
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      isValidationEnabled: false,
      formData: {
        isFamilyArranged: false,
        isConfirmed: false,
        selections: [],
      },
      confirmModal: null,
    };
  }

  componentDidMount() {
    const { arrangement } = this.props;
    const { categoryInformation, serviceSelections } = arrangement || {};
    const { officiant } = categoryInformation || {};
    const selections = serviceSelections
      ?.filter(selection => selection?.service?.category === serviceCategories.OFFICIANTS)
      ?.map(selection => officiantServiceSelectionTransform(selection));

    this.setState({
      formData: {
        isFamilyArranged: officiant?.isFamilyArranged || false,
        isConfirmed: officiant?.isConfirmed || false,
        selections,
      },
    });
  }

  getUpdatedFormDataSelections = (selectionId, key, value) => {
    const { formData } = this.state;

    return {
      ...formData,
      selections: [
        ...(formData.selections.map((selection) => {
          if (selection.id !== selectionId) {
            return selection;
          }
          return {
            ...selection,
            categoryMetaData: {
              ...(selection?.categoryMetaData || {}),
              officiant: {
                ...(selection?.categoryMetaData?.officiant || {}),
                [key]: value,
              },
            },
          };
        })),
      ],
    };
  }

  getFormDataWithSelectionRemoved = (selectionId) => {
    const { formData } = this.state;

    return {
      ...formData,
      selections: [
        ...(formData.selections.filter(({ id }) => id !== selectionId)),
      ],
    };
  }

  handleChange = (key, value) => {
    this.setState((prevState) => {
      const updatedFormData = {
        ...prevState.formData,
        [key]: value,
      };

      return {
        formData: updatedFormData,
      };
    });
  }

  handleRemoveSelection = (selectionId) => {
    const { arrangement } = this.props;
    const { isConfirmed } = arrangement;

    if (isConfirmed) {
      this.setState({
        confirmModal: {
          message: changeConfirmedArrangementMessage,
          icon: 'warning',
          onConfirm: () => {
            this.setState({ confirmModal: null });
            this.handleConfirmRemoveSelection(selectionId);
          },
          onCancel: () => {
            this.setState({ confirmModal: null });
          },
        },
      });

      return;
    }

    this.handleConfirmRemoveSelection(selectionId);
  }

  handleConfirmRemoveSelection = (selectionId) => {
    const {
      bereavement, arrangement, client, removeServiceSelection,
    } = this.props;
    const input = {
      bereavementId: bereavement.id,
      arrangementId: arrangement.id,
      selectionId,
    };

    this.setState({
      formData: this.getFormDataWithSelectionRemoved(selectionId),
    });

    removeServiceSelection(bereavement.id, arrangement.id, selectionId);

    client.mutate({
      mutation: removeServiceSelectionMutation,
      variables: { input },
    });
  }

  handleChangeVariant = (selectionId, variantId) => {
    const { formData } = this.state;
    const updatedFormData = {
      ...formData,
      selections: formData?.selections?.map((selection) => {
        if (selection?.id !== selectionId) {
          return selection;
        }
        return {
          ...selection,
          variantId,
        };
      }),
    };

    this.setState({
      formData: updatedFormData,
    });
  }

  handleToggleServiceVenue = (selectionId, serviceVenueId) => {
    const { formData } = this.state;
    const { selections } = formData || [];
    const foundSelection = selections.find(({ id }) => id === selectionId);
    const { venueSelectionIds } = foundSelection?.categoryMetaData?.officiant || [];

    const existingIndex = venueSelectionIds.indexOf(serviceVenueId);
    const updatedVenueSelectionIds = [
      ...venueSelectionIds,
    ];

    if (existingIndex > -1) {
      updatedVenueSelectionIds.splice(existingIndex, 1);
    } else {
      updatedVenueSelectionIds.push(serviceVenueId);
    }

    this.setState({
      formData: this.getUpdatedFormDataSelections(selectionId, 'venueSelectionIds', updatedVenueSelectionIds),
    });
  }

  handleChangeCustomDetails = (selectionId, customDetails) => {
    const { validate } = this.props;
    const { isValidationEnabled } = this.state;
    const updatedFormData = this.getUpdatedFormDataSelections(selectionId, 'customDetails', customDetails);

    if (isValidationEnabled) {
      validate(updatedFormData, validationSchema);
    }

    this.setState({
      formData: updatedFormData,
    });
  }

  handleChangeDirectoryListingId = (selectionId, directoryListingId) => {
    this.setState({
      formData: this.getUpdatedFormDataSelections(selectionId, 'directoryListingId', directoryListingId),
    });
  }

  handleCreateDirectoryListing = (customDetails) => {
    this.setState({
      confirmModal: {
        title: 'Create directory listing',
        message: 'Would you like to save and exit officiants before going to create the directory listing?',
        confirmLabel: 'Save & continue',
        onConfirm: () => {
          this.setState({ confirmModal: null });
          this.handleConfirmCreateDirectoryListing(customDetails);
        },
        onCancel: () => {
          this.setState({ confirmModal: null });
        },
      },
    });
  }

  handleConfirmCreateDirectoryListing = (customDetails) => {
    this.handleSave(() => {
      const {
        bereavement, arrangement, history, enqueueSnackbar, removeSnackbar,
      } = this.props;

      const routeState = {
        name: customDetails.name,
        address: customDetails.address,
        phones: customDetails.phone ? [customDetails.phone] : null,
        emails: customDetails.email ? [customDetails.email] : null,
        categories: ['OFFICIANT_MINISTER'],
      };

      history.push('/directory-listings/create', routeState);

      enqueueSnackbar({
        message: 'Clicking \'Return to Officiants\' will return you to the officiant you were viewing',
        options: {
          variant: 'warning',
          persist: true,
          action: (
            <>
              <Button
                onClick={() => removeSnackbar()}
                variant="outlined"
                data-test-id="dismiss"
                className={styles.u_push__right}
              >
                {t('Dismiss')}
              </Button>
              <Button
                onClick={() => {
                  history.push(`/case/${bereavement.id}/arrangement/${arrangement.id}/officiants`);
                }}
                variant="outlined"
                data-test-id="returnToOfficiants"
              >
                {t('Return to officiants')}
              </Button>
            </>
          ),
        },
      });
    });
  }

  handleEditDirectoryListing = (directoryListingId) => {
    this.setState({
      confirmModal: {
        title: 'Edit directory listing',
        message: 'Would you like to save and exit officiants before going to edit the directory listing?',
        confirmLabel: 'Save & continue',
        onConfirm: () => {
          this.setState({ confirmModal: null });
          this.handleConfirmEditDirectoryListing(directoryListingId);
        },
        onCancel: () => {
          this.setState({ confirmModal: null });
        },
      },
    });
  }

  handleConfirmEditDirectoryListing = (directoryListing) => {
    this.handleSave(() => {
      const {
        bereavement, arrangement, history, enqueueSnackbar, removeSnackbar,
      } = this.props;

      history.push(`/directory-listings/${directoryListing}`);

      enqueueSnackbar({
        message: 'Clicking \'Return to Officiants\' will return you to the officiant you were viewing',
        options: {
          variant: 'warning',
          persist: true,
          action: (
            <>
              <Button
                onClick={() => removeSnackbar()}
                variant="outlined"
                data-test-id="dismiss"
                className={styles.u_push__right}
              >
                {t('Dismiss')}
              </Button>
              <Button
                onClick={() => {
                  history.push(`/case/${bereavement.id}/arrangement/${arrangement.id}/officiants`);
                }}
                variant="outlined"
                data-test-id="returnToOfficiants"
              >
                {t('Return to officiants')}
              </Button>
            </>
          ),
        },
      });
    });
  }

  handleSave = async (callback) => {
    const {
      client, bereavement, arrangement, editServiceSelection, onSave, validate,
    } = this.props;
    const bereavementId = bereavement.id;
    const arrangementId = arrangement.id;
    const { formData } = this.state;
    const { isFamilyArranged, isConfirmed, selections } = formData;
    const allowUnconfirmArrangement = false;

    this.setState({
      isSaving: true,
      isValidationEnabled: true,
    });

    const isValid = validate(formData, validationSchema);
    if (!isValid) {
      this.setState({ isSaving: false });
      return;
    }

    const categoryInformation = {
      isFamilyArranged,
      isConfirmed,
    };

    onSave(bereavementId, arrangementId, 'officiant', categoryInformation);

    selections.forEach((selection) => {
      editServiceSelection(
        bereavementId,
        arrangementId,
        selection.id,
        selection,
        allowUnconfirmArrangement,
      );
    });

    const input = {
      bereavementId,
      arrangementId,
      ...categoryInformation,
      selections: officiantServiceSelectionsMutationTransform(selections),
    };

    client.mutate({
      mutation: editArrangementOfficiantInformation,
      variables: { input },
    }).finally(() => this.setState({ isSaving: false }));

    if (callback) {
      callback();
    }
  }

  render() {
    const {
      bereavement, arrangement, errors,
    } = this.props;
    const { formData, isSaving, confirmModal } = this.state;

    return (
      <ArrangementOfficiantsForm
        arrangement={arrangement}
        formData={formData}
        errors={errors}
        disabled={isSaving || bereavement.caseStatus === caseStatuses.CLOSED}
        isSaving={isSaving}
        confirmModal={confirmModal}
        onChange={this.handleChange}
        onChangeVariant={this.handleChangeVariant}
        onChangeCustomDetails={this.handleChangeCustomDetails}
        onChangeDirectoryListingId={this.handleChangeDirectoryListingId}
        onCreateDirectoryListing={this.handleCreateDirectoryListing}
        onEditDirectoryListing={this.handleEditDirectoryListing}
        onRemoveSelection={this.handleRemoveSelection}
        onSave={this.handleSave}
        onToggleServiceVenue={this.handleToggleServiceVenue}
      />
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  editServiceSelection: editServiceSelectionAction,
  removeServiceSelection: removeServiceSelectionAction,
  enqueueSnackbar: enqueueSnackbarAction,
  removeSnackbar: removeSnackbarAction,
}, dispatch);

export default withApollo(
  withRouter(
    withValidation(
      connect(null, mapDispatchToProps)(ArrangementOfficiantsFormContainer),
    ),
  ),
);
