import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { packageType, packageSelectionType } from 'types/bereavement';

import { MuiThemeProvider } from '@material-ui/core/styles';
import overrideTheme from 'services/themes/overrideTheme';
import { isArrayEmptyOrFalse } from 'services/utils';

import ExpandableBanner from 'components/common/ExpandableBanner';
import PackageItem from 'components/arrangement/PackageItem';
import ConfirmationModal from 'components/common/ConfirmationModal';
import ArrangementSelectedPackage from 'components/arrangement/ArrangementSelectedPackage';
import { categories } from 'constants/arrangement';
import IconLoad from 'icons/IconLoad';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';


const ArrangementPackagesScreen = ({
  isLoading,
  disabled,
  packages,
  selectedPackage,
  hasMorePackages,
  confirmModalMessage,
  getPackages,
  onSelectBespoke,
  onSelect,
  onUnselect,
  confirmCallback,
  cancelCallback,
  focusNewSelection,
  selectedItemRef,
  expandBannerRef,
}) => (
  <MuiThemeProvider theme={overrideTheme}>
    <div className={styles.o_view}>
      <Helmet>
        <title>{t('Arrangement - Packages')}</title>
      </Helmet>
      <div className={styles.o_view__main}>
        <ExpandableBanner
          title="Packages"
          isClosedOnLoad={!selectedPackage}
          ref={expandBannerRef}
          headerClasses={[rcStyles.expanding_banner__header]}
          iconClasses={[rcStyles.expanding_banner__icon]}
        >
          {selectedPackage && (
            <ArrangementSelectedPackage
              item={selectedPackage}
              onRemove={onUnselect}
              category={categories.PACKAGES}
              disabled={disabled}
              selectedItemRef={selectedItemRef}
            />
          )}
        </ExpandableBanner>
        {!disabled && (
          <Fragment>
            {isLoading && isArrayEmptyOrFalse(packages) ? (
              <div className={styles.o_fb}>
                <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
                  <IconLoad />
                </div>
              </div>
            ) : (
              <Fragment>
                <div className={styles.u_push__top}>
                  <InfiniteScroll
                    dataLength={packages.length}
                    hasMore={packages.length > 0 && hasMorePackages}
                    next={getPackages}
                    className={classNames(
                      styles.c_infinite_scroll,
                      styles.u_overflow__hidden,
                    )}
                  >
                    <div className={styles.c_grid__2col}>
                      <PackageItem
                        isBespoke
                        onSelect={onSelectBespoke}
                        isSelected={false}
                        disabled={disabled}
                        disableFocusSelection
                      />
                      {packages.map(packageItem => (
                        <PackageItem
                          key={packageItem.id}
                          isBespoke={false}
                          packageItem={packageItem}
                          isSelected={!!selectedPackage && packageItem.id === selectedPackage.id}
                          disabled={disabled}
                          onSelect={() => onSelect(packageItem)}
                          onUnselect={onUnselect}
                          focusNewSelection={focusNewSelection}
                        />
                      ))}
                    </div>
                  </InfiniteScroll>
                </div>
                <div>
                  {isLoading && (
                    <div className={classNames(
                      styles.c_page__full_height,
                      styles.c_page__clear_bottom_nav,
                      styles.has_faded_background,
                    )}
                    >
                      <div className={styles.c_svg_loader_infinite_scroll} data-test-id="infiniteScrollLoader">
                        <IconLoad />
                      </div>
                    </div>
                  )}
                </div>
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
      <ConfirmationModal
        message={t(confirmModalMessage)}
        isOpen={!!confirmModalMessage}
        icon="warning"
        confirmLabel={t('Continue')}
        onConfirm={confirmCallback}
        onCancel={cancelCallback}
      />
    </div>
  </MuiThemeProvider>
);

ArrangementPackagesScreen.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  packages: PropTypes.arrayOf(packageType).isRequired,
  selectedPackage: packageSelectionType,
  hasMorePackages: PropTypes.bool.isRequired,
  confirmModalMessage: PropTypes.string,
  getPackages: PropTypes.func.isRequired,
  onSelectBespoke: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onUnselect: PropTypes.func.isRequired,
  confirmCallback: PropTypes.func,
  cancelCallback: PropTypes.func.isRequired,
  focusNewSelection: PropTypes.func.isRequired,
  selectedItemRef: PropTypes.objectOf(PropTypes.any),
  expandBannerRef: PropTypes.objectOf(PropTypes.any),
};

export default ArrangementPackagesScreen;
