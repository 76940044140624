import {
  string, number, object, boolean,
} from 'yup';

export const validationSchema = object().shape({
  name: object().shape({
    title: string().nullable(),
    givenName: string().nullable(),
    middleName: string().nullable(),
    familyName: string().nullable(),
  }),
  email: string().nullable(),
  phoneNumber: string().nullable(),
  postCode: string().nullable(),
  financeAmount: object().shape({
    amount: number().nullable().required('Finance amount is required.').moreThan(0, 'Finance amount must be more than £0.00.'),
    currency: string().nullable(),
  }),
  hasGDPRBeenConfirmed: boolean().oneOf([true], 'Please confirm the applicant has given permission to share their details with Funeral Safe.'),
});
