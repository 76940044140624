import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { matchType } from 'types/reactRouter';
import { bindActionCreators } from 'redux';
import { clearDirectoryListingsAction } from 'actions/directoryListings';

import AdminDirectoryListingSectionScreen from './AdminDirectoryListingSectionScreen';

class AdminDirectoryListingSectionScreenContainer extends Component {
  static propTypes = {
    match: matchType.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    clearDirectoryListings: PropTypes.func.isRequired,
  }

  componentWillUnmount = () => {
    const { clearDirectoryListings } = this.props;
    clearDirectoryListings();
  }

  render() {
    return (
      <AdminDirectoryListingSectionScreen
        {...this.props}
      />
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  clearDirectoryListings: clearDirectoryListingsAction,
}, dispatch);

export default withRouter(
  connect(null, mapDispatchToProps)(AdminDirectoryListingSectionScreenContainer),
);
