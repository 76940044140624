export default {
  translation: {
    '\u00a92019 Funeral Zone Ltd.': '\u00a92019 Funeral Zone Ltd.',
    'If you require assistance, please contact our support team:': 'If you require assistance, please contact our support team:',
    'support@arranger.com': 'support@arranger.com',
    'Username': 'Username',
    'Password': 'Password',
    'Login': 'Login',
    'Login failed - please check your username and password': 'Login failed - please check your username and password',
    'Forgotten password': 'Forgotten password',
    'Enter Manually': 'Enter Manually',
    'Lookup Address': 'Lookup Address',
    'Loading': 'Loading',
    'Enter postal code to find address': 'Enter postal code to find address',
    'The username is required': 'The username is required',
    'The password is required': 'The password is required',
    'Internal error validating the component. Review the validation rules': 'Internal error validating the component. Review the validation rules',
    'Add client': 'Add client',
    'Edit client': 'Edit client',
    'Title': 'Title',
    'Given Name': 'Given Name',
    'Family Name': 'Family Name',
    'Marital Status': 'Marital Status',
    'Maiden Name': 'Maiden Name',
    'Also known as': 'Also known as',
    'Date of birth': 'Date of birth',
    'Gender': 'Gender',
    'Add clinician': 'Add clinician',
    'Coroner': 'Coroner',
    'Clinician': 'Clinician',
    'Body ready for collection': 'Body ready for collection',
    'Date for collection': 'Date for collection',
    'Time for collection': 'Time for collection',
    'Is Infectious': 'Is Infectious',
    'Service Type': 'Service Type',
    'Select Home': 'Select Home',
    'Assigned to': 'Assigned to',
    'Deceased': 'Deceased',
    'Possessions': 'Possessions',
    'First call taken': 'First call taken',
    'Arrangement created': 'Arrangement created',
    'New Arrangement': 'New Arrangement',
    'Create Arrangement': 'Create Arrangement',
    'View estimate': 'View estimate',
    'Continue': 'Continue',
    'Engraving': 'Engraving',

    'relationshipType': 'relationshipType',
    'Email': 'Email',
    'Post': 'Post',
    'Phone': 'Phone',
    'In Person': 'In Person',
    'Brother': 'Brother',
    'Sister': 'Sister',
    'Notes': 'Notes',
    'Next of kin': 'Next of kin',
    'Is primary contact': 'Is primary contact',
    'Primary Bill Payer': 'Primary Bill Payer',
    'Add': 'Add',
    'Date & time': 'Date & time',
    'Contact Support': 'Contact Support',

    'Add Note': 'Add Note',

    'GDPR': 'GDPR',

    'Burial': 'Burial',
    'Cremation': 'Cremation',

    'Logo': 'Logo',
    'Logout': 'Logout',
    'Select': 'Select',
    'View': 'View',

    'Bespoke Funeral Service': 'Bespoke Funeral Service',
    'Create a Personalised service to your needs.': 'Create a Personalised service to your needs.',

    'Reference Number': 'Reference Number',
    'New arrangement for': 'New arrangement for',
    'Cancel': 'Cancel',
    'Create': 'Create',
    'Save': 'Save',

    // Title
    'Mr': 'Mr',
    'Mrs': 'Mrs',
    'Miss': 'Miss',
    'Ms': 'Ms',
    'Master': 'Master',
    'Other': 'Other',

    // Marital status
    'Single': 'Single',
    'Married': 'Married',
    'Civil Partnership': 'Civil Partnership',
    'Divorced': 'Divorced',
    'Widowed': 'Widowed',

    // Faith
    'Faith': 'Faith',
    'Christianity': 'Christianity',
    'Catholicism': 'Catholicism',
    'Anglicanism': 'Anglicanism',
    'Islam': 'Islam',
    'Hinduism': 'Hinduism',
    'Buddhism': 'Buddhism',
    'Judaism': 'Judaism',
    'Agnosticism / Atheism': 'Agnosticism / Atheism',
    'Occupation': 'Occupation',
    'Date of death': 'Date of death',
    'Religous cerimony': 'Religous cerimony',
    'Yes': 'Yes',
    'No': 'No',
    'Doctor & care': 'Doctor & care',
    'MCCD issued': 'MCCD issued',
    'Certificate Number': 'Certificate Number',

    'Place of death': 'Place of death',
    'Hospice': 'Hospice',
    'Hospital': 'Hospital',
    'Nursing Home': 'Nursing Home',
    'Home': 'Home',

    'Length': 'Length',
    'Width': 'Width',
    'Depth': 'Depth',
    'Units': 'Units',

    'Arrangements': 'Arrangements',
    'View deceased details': 'View deceased details',
    'View client details': 'View client details',

    'Dashboard': 'Dashboard',
    'Funerals': 'Funerals',

    'There are errors in the Deceased form': 'There are errors in the Deceased form',
    'At least one client as Primary Contact is required': 'At least one client as Primary Contact is required',
    'Either given name or family name is required': 'Either given name or family name is required',
    'First line of address is required': 'First line of address is required',
    'Town is required': 'Town is required',
    'Post Code is required': 'Post Code is required',
    'Country is required': 'Country is required',
    'Telephone country code is required': 'Telephone country code is required',
    'Telephone number is required': 'Telephone number is required',
    'Telephone type is required': 'Telephone type is required',
    'Email is not valid': 'Email is not valid',
    'Email is required': 'Email is required',
    'Add note': 'Add note',
    'Note': 'Note',
    'Note content is required': 'Note content is required',
    'Note category is required': 'Note category is required',
    'Documents': 'Documents',
    'Upload document': 'Upload document',
    'Upload photo': 'Upload photo',
    'Upload file': 'Upload file',
    'Document title': 'Document title',
    'Document title is required': 'Document title is required',

    'Automatically send emails for this case': 'Automatically send emails for this funeral',
    'You must confirm an arrangement for this funeral before you can download a letter.': 'You must confirm an arrangement for this funeral before you can download a letter.',
    'View all of your active funerals, and all other funerals within your homes.': 'View all of your active funerals, and all other funerals within your homes.',
    'To enable automatic emails, the primary contact requires an email address.': 'To enable automatic emails, the primary contact requires an email address.',

    'Homes': 'Homes',
    'Manage homes': 'Manage homes',
    'Create, edit and manage your funeral homes': 'Create, edit and manage your funeral homes',
    'Create new home': 'Create new home',
    'None': 'None',
    'Home groups': 'Home groups',
    'Manage home groups': 'Manage home groups',
    'Create new home group': 'Create new home group',
    'Home group name is required': 'Home group name is required boooooooooo',
    'Home group name*': 'Home group name*',

    'Publish obituary to Funeral Guide': 'Publish obituary to Funeral Guide',
    'Note: once published, any information which needs to be edited should be updated by logging into Funeral Guide directly.': 'Note: once published, any information which needs to be edited should be updated by logging into Funeral Guide directly.',
    'Select home*': 'Select home*',
    'Select Funeral Guide user*': 'Select Funeral Guide user*',

    'This staff member currently has access to arranger using the following email address': 'This staff member currently has access to arranger using the following email address',
    'If you no longer require this staff member to have access you can simply ‘deactivate’ the account via the button below. The account can be ‘reactivated’ at any time you choose.': 'If you no longer require this staff member to have access you can simply ‘deactivate’ the account via the button below. The account can be ‘reactivated’ at any time you choose.',
  },
};
