import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import RightArrowIcon from '@material-ui/icons/ChevronRight';
import LeftArrowIcon from '@material-ui/icons/ChevronLeft';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import AlarmIcon from '@material-ui/icons/Alarm';
import IconLoadSml from 'icons/IconLoadSml';
import Select from 'components/common/Select';
import { homeType } from 'types/bereavement';

import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const CalendarToolbar = ({
  homes,
  views,
  label,
  onView,
  onNavigate,
  calendarEvents,
  onOpenRunSheet,
  onChangeFilters,
  onToggleCalendar,
  isRunSheetLoading,
  organisationalUnitId,
  isLoading,
  onToggleBookEventModal,
}) => (
  <Toolbar
    className={classNames(
      rcStyles.calendar__header,
      'calendar-toolbar',
    )}
    data-test-id="homeFilter"
  >
    <div className={rcStyles.calendar__toolbar}>
      <Button
        variant="contained"
        onClick={onToggleCalendar}
        color="secondary"
        className={classNames(
          rcStyles.calendar__close_button,
          styles.o_button__round,
        )}
        data-test-id="close"
      >
        <CloseIcon size="small" />
      </Button>
      <div className={rcStyles.calendar__day_selection}>
        <IconButton
          size="medium"
          color="secondary"
          variant="outlined"
          aria-label={t('Prev')}
          onClick={() => onNavigate('PREV')}
          data-test-id="previous"
        >
          <LeftArrowIcon />
        </IconButton>
        <div className={rcStyles.is_label}>
          {label}
        </div>
        <IconButton
          size="medium"
          color="secondary"
          vaiant="outlined"
          aria-label={t('Next')}
          onClick={() => onNavigate('NEXT')}
          data-test-id="next"
        >
          <RightArrowIcon />
        </IconButton>
      </div>
      <div className={rcStyles.calendar__view_type}>
        {views.map(name => (
          <div className={rcStyles.is_option}>
            <Button
              key={name}
              variant="outlined"
              color="secondary"
              onClick={() => onView(name)}
              data-test-id={name}
              disabled={isLoading}
            >
              {t(name)}
            </Button>
          </div>
        ))}
      </div>
      <div className={rcStyles.calendar__home_selection}>
        <Select
          onChange={event => onChangeFilters(event.target.value)}
          value={organisationalUnitId}
          inputProps={{ 'data-test-id': 'homeFilter' }}
          disabled={isLoading}
          displayEmpty
          isDark
          shrink
          fullWidth
        >
          <MenuItem key="all" value="">
            {t('All homes')}
          </MenuItem>
          {homes.map(home => (
            <MenuItem key={home.id} value={home.id}>
              {home.name}
            </MenuItem>
          ))}
        </Select>
      </div>

      <div className={rcStyles.calendar__runsheet}>
        <Button
          variant="outlined"
          color="primary"
          className={classNames(
            rcStyles.is_button,
            styles.o_button__secondary,
          )}
          disabled={isLoading || !organisationalUnitId || calendarEvents.length === 0}
          onClick={onOpenRunSheet}
          data-test-id="runSheet"
        >
          {isRunSheetLoading
            ? (
              <div
                className={classNames(
                  styles.c_svg_loader_sml,
                  styles.c_svg_loader_sml_btn,
                )}
                data-test-id="buttonLoader"
              >
                <IconLoadSml title={t('Saving')} />
              </div>
            )
            : <AlarmIcon size="small" />
          }
          {t('Run Sheet')}
        </Button>
      </div>
      <div className={rcStyles.calendar__add_event}>
        <Button
          variant="outlined"
          onClick={onToggleBookEventModal}
          color="secondary"
          className={styles.o_button__round}
          data-test-id="close"
        >
          <AddIcon size="small" />
        </Button>
      </div>
    </div>
  </Toolbar>
);

CalendarToolbar.propTypes = {
  label: PropTypes.node.isRequired,
  onView: PropTypes.func.isRequired,
  calendarEvents: PropTypes.arrayOf(
    PropTypes.shape({
      bereavementId: PropTypes.string,
      start: PropTypes.instanceOf(Date).isRequired,
      end: PropTypes.instanceOf(Date).isRequired,
      __typename: PropTypes.string,
    }),
  ).isRequired,
  onNavigate: PropTypes.func.isRequired,
  organisationalUnitId: PropTypes.string,
  onOpenRunSheet: PropTypes.func.isRequired,
  onChangeFilters: PropTypes.func.isRequired,
  onToggleCalendar: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isRunSheetLoading: PropTypes.bool.isRequired,
  homes: PropTypes.arrayOf(homeType).isRequired,
  views: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleBookEventModal: PropTypes.func.isRequired,
};

export default CalendarToolbar;
