import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { packageType } from 'types/bereavement';
import Price from 'components/common/Price';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const PackageItemPopUp = ({
  item,
  isSelected,
  disabled,
  onSelectPackage,
  onClose,
  isOpen,
}) => (
  <Dialog
    aria-labelledby="package-details-title"
    open={isOpen}
    fullWidth
    maxWidth="md"
    TransitionComponent={Transition}
    disableBackdropClick
    disableEscapeKeyDown
  >
    <DialogTitle
      disableTypography
      className={styles.c_dialog__header}
      id="package-details-title"
    >
      <div className={styles.c_dialog__title}>
        {t('Package')}
      </div>
    </DialogTitle>
    <DialogContent className={styles.c_dialog__content}>
      <div className={styles.o_fb}>
        <h1 className={styles.pica}>
          {item.name}
        </h1>
        <Price
          price={item.price}
          customClass={classNames(
            styles.c_price_label__lrg,
            styles.is_black,
          )}
        />
      </div>
      <p className={classNames(
        styles.primer,
        styles.u_push__bottom,
      )}
      >
        {item.description}
      </p>
      <ul className={classNames(
        styles.o_list,
        styles.is_plain,
        styles.u_push__bottom_double,
      )}
      >
        {item.products.map((product, index) => (
          <li
            className={styles.o_list__item}
            key={`${index}`}
          >
            <div className={styles.o_list_item__icon}>
              <ArrowRightIcon />
            </div>
            <div className={styles.o_list_item__text}>
              {product.title}
            </div>
          </li>
        ))}
        {item.services.map((service, index) => (
          <li
            className={styles.o_list__item}
            key={`${index}`}
          >
            <div className={styles.o_list_item__icon}>
              <ArrowRightIcon />
            </div>
            <div className={styles.o_list_item__text}>
              {service.title}
            </div>
          </li>
        ))}
      </ul>
      <div className={rcStyles.actions}>
        <Button
          variant="outlined"
          color="primary"
          size="medium"
          onClick={onClose}
          data-test-id="cancel"
        >
          {t('Cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          data-test-id="selectPackage"
          onClick={onSelectPackage}
          disabled={disabled}
        >
          {t(isSelected ? 'Unselect' : 'Select')}
        </Button>
      </div>
    </DialogContent>
  </Dialog>
);

PackageItemPopUp.propTypes = {
  item: packageType.isRequired,
  isSelected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onSelectPackage: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};


export default PackageItemPopUp;
