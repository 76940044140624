import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';

import MeasurementField from 'components/common/MeasurementField';
import { MeasurementDisplayUnit } from 'constants/measurement';
import { coffinSizeType } from 'types/bereavement';

import rcStyles from './styles.scss';

const CoffinSizeFormSection = ({
  value,
  disabled,
  onChange,
  errors,
}) => (
  <>
    <div className={rcStyles.coffin_size}>
      <MeasurementField
        label={t('Length')}
        value={value.length}
        units={MeasurementDisplayUnit.FEET}
        disabled={disabled}
        error={errors && !!errors.length}
        helperText={errors && errors.length}
        onChange={measurement => onChange('length', measurement)}
        data-test-id="length"
        className={rcStyles.length_measurement}
      />
      <MeasurementField
        label={t('Width')}
        value={value.width}
        units={MeasurementDisplayUnit.INCHES}
        disabled={disabled}
        error={errors && !!errors.width}
        helperText={errors && errors.width}
        onChange={measurement => onChange('width', measurement)}
        data-test-id="width"
      />
      <MeasurementField
        label={t('Depth')}
        value={value.depth}
        units={MeasurementDisplayUnit.INCHES}
        disabled={disabled}
        error={errors && !!errors.depth}
        helperText={errors && errors.depth}
        onChange={measurement => onChange('depth', measurement)}
        data-test-id="depth"
      />
    </div>
  </>
);

CoffinSizeFormSection.propTypes = {
  value: coffinSizeType,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any),
};

export default CoffinSizeFormSection;
