import { string, object } from 'yup';

export const validationSchema = object().shape({
  dateTime: string().nullable().required('Appointment date is required'),
  address: object().nullable().shape({
    addressLine1: string().nullable().required('First line of address is required'),
    addressLine2: string().nullable(),
    town: string().nullable().required('Town is required'),
    county: string().nullable(),
    postCode: string().nullable().required('Post Code is required'),
    countryCode: string().nullable().required('Country is required'),
  }).required('Appointment address is required'),
  staffMember: object().nullable().required('Staff member is required'),
});
