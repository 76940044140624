import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { noteCategoryLabels } from 'constants/bereavement';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import { reactRefType } from 'types/common';
import rcStyles from './styles.scss';

const Note = ({
  noteRef,
  fieldData,
  noteTitle,
  disabled,
  errors,
  onChange,
}) => (
  <div
    className={classNames(
      styles.o_block_content,
    )}
    data-test-id="noteContainer"
  >
    <FormControl fullWidth>
      <div
        className={classNames(
          styles.long_primer,
          styles.is_black,
        )}
        data-test-id="noteHeader"
      >
        {fieldData.category && t(noteCategoryLabels[fieldData.category])}
      </div>
      <TextField
        value={fieldData.content}
        onChange={e => onChange(e.target.value)}
        placeholder={t('Note')}
        multiline
        rowsMax={9}
        error={errors && !!errors.content}
        helperText={errors && errors.content}
        disabled={disabled}
        inputProps={{
          'data-test-id': 'noteTextField',
        }}
        InputProps={{
          'inputRef': noteRef,
        }}
      />
      <span
        className={classNames(styles.u_push__top, rcStyles.rc_noteDetails)}
        data-test-id="noteFooter"
      >
        {noteTitle}
      </span>
    </FormControl>
  </div>
);

Note.propTypes = {
  noteRef: reactRefType,
  fieldData: PropTypes.shape({
    name: PropTypes.string,
    content: PropTypes.string.isRequired,
    createdDateTime: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
  }),
  noteTitle: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
};

export default Note;
