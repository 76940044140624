import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';

import {
  addClientAction,
  editClientAction,
  removeClientAction,
} from 'actions/bereavement';
import { bereavedPersonConnectionTransform } from 'transforms/bereavement';
import { apolloClientType } from 'types/apollo';
import { bereavementType, bereavedPersonConnectionType } from 'types/bereavement';

import {
  addBereavedPersonConnection,
  editBereavedPersonConnection,
  removeBereavedPersonConnection,
} from './mutations.gql';
import AshesRecipientModal from './AshesRecipientModal';

class AshesRecipientModalContainer extends Component {
  static propTypes = {
    bereavement: bereavementType.isRequired,
    bereavedPeopleConnections: PropTypes.arrayOf(bereavedPersonConnectionType).isRequired,
    isOpen: PropTypes.bool.isRequired,
    recipientBeingModified: PropTypes.string,
    disabled: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    client: apolloClientType.isRequired,
    addClient: PropTypes.func.isRequired,
    editClient: PropTypes.func.isRequired,
    removeClient: PropTypes.func.isRequired,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isOpen
      && !prevState.recipientId) {
      return {
        recipientId: nextProps.recipientBeingModified,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      recipientId: null,
    };

    this.dialogRef = React.createRef();
  }

  resetState = () => {
    this.setState({
      recipientId: null,
    });
  }

  handleChange = (id) => {
    this.setState({ recipientId: id });
  }

  handleClose = () => {
    const { onClose } = this.props;
    this.resetState();
    onClose();
  }

  handleSave = () => {
    const { onSave } = this.props;
    const { recipientId } = this.state;

    onSave(recipientId);
    this.resetState();
  }

  handleAddRecipient = (bereavementId, bereaved) => {
    const { bereavement, client, addClient } = this.props;

    addClient(bereavement.id, bereaved);

    const input = bereavedPersonConnectionTransform(bereaved, bereavementId);
    client.mutate({
      mutation: addBereavedPersonConnection,
      variables: { input },
    });
  }

  handleEditRecipient = (bereavementId, bereaved) => {
    const { bereavement, client, editClient } = this.props;
    const input = bereavedPersonConnectionTransform(bereaved, bereavementId);

    editClient(bereavement.id, bereaved);

    client.mutate({
      mutation: editBereavedPersonConnection,
      variables: { input },
    });
  }

  handleRemoveRecipient = (id) => {
    const { bereavement, client, removeClient } = this.props;

    removeClient(bereavement.id, id);
    const input = {
      bereavementId: bereavement.id,
      id,
    };

    client.mutate({
      mutation: removeBereavedPersonConnection,
      variables: { input },
    });
  }

  render() {
    const {
      isOpen, bereavement, bereavedPeopleConnections, disabled,
    } = this.props;
    const { recipientId } = this.state;

    return (
      <Fragment>
        <AshesRecipientModal
          isOpen={isOpen}
          bereavement={bereavement}
          bereavedPeopleConnections={bereavedPeopleConnections}
          recipientId={recipientId}
          disabled={disabled}
          onChange={this.handleChange}
          onClose={this.handleClose}
          onSave={this.handleSave}
          onAddRecipient={this.handleAddRecipient}
          onEditRecipient={this.handleEditRecipient}
          onRemoveRecipient={this.handleRemoveRecipient}
          dialogRef={this.dialogRef}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  addClient: addClientAction,
  editClient: editClientAction,
  removeClient: removeClientAction,
}, dispatch);

export default withApollo(
  connect(null, mapDispatchToProps)(AshesRecipientModalContainer),
);
