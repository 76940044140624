import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { t } from 'i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import IconLoad from 'icons/IconLoad';

import CatalogueSelectionItem from 'components/catalogue/CatalogueSelectionItem';
import { catalogueItemTypes, serviceCategories } from 'constants/arrangement';
import { arrangementType, serviceType } from 'types/bereavement';
import { reactRefType } from 'types/common';

import styles from 'scss/main.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const Loader = () => (
  <div className={styles.c_svg_loader_infinite_scroll} data-test-id="loader">
    <IconLoad />
  </div>
);

const FeeModal = ({
  dialogRef,
  isOpen,
  items,
  isLoading,
  hasMoreItems,
  bereavementId,
  arrangement,
  disabled,
  getMoreItems,
  onClose,
  onOpenDuplicateSelectionModal,
}) => (
  <Dialog
    open={isOpen}
    fullScreen
    scroll="paper"
    TransitionComponent={Transition}
    className={styles.c_dialogue}
    disableBackdropClick
    disableEscapeKeyDown
  >
    <DialogTitle
      disableTypography
      className={styles.dialog_title_bar}
    >
      <IconButton
        className={classNames(
          styles.o_button__secondary,
          styles.is_close,
        )}
        color="primary"
        variant="contained"
        aria-label="Close"
        onClick={onClose}
        data-test-id="feeClose"
      >
        <CloseIcon />
      </IconButton>
      <div className={styles.is_title}>
        {t('Add fee')}
      </div>
      <div className={styles.is_actions}>
        <Button
          onClick={onClose}
          variant="contained"
          color="secondary"
          data-test-id="returnToCare"
          disabled={disabled}
          className={styles.o_button__secondary}
        >
          {t('Return to care')}
        </Button>
      </div>
    </DialogTitle>
    <div
      id="dialogOverflow"
      ref={dialogRef}
      className={classNames(
        styles.c_dialog__overflow,
      )}
    >
      <DialogContent>
        <div className={styles.o_block_content}>
          {(isLoading && items && !items.length) && <Loader />}
          <InfiniteScroll
            dataLength={items.length}
            hasMore={hasMoreItems}
            scrollableTarget="dialogOverflow"
            next={getMoreItems}
            loader={<Loader />}
          >
            <ul>
              {items.map(item => (
                <li
                  className={styles.u_push__bottom}
                  key={item.id}
                >
                  <CatalogueSelectionItem
                    bereavementId={bereavementId}
                    arrangement={arrangement}
                    type={catalogueItemTypes.SERVICE}
                    category={serviceCategories.PROFESSIONAL_SERVICES}
                    item={item}
                    onOpenDuplicateSelectionModal={onOpenDuplicateSelectionModal}
                    wasAddedFromCareScreen
                  />
                </li>
              ))}
            </ul>
          </InfiniteScroll>
        </div>
      </DialogContent>
    </div>
  </Dialog>
);

FeeModal.propTypes = {
  dialogRef: reactRefType.isRequired,
  isOpen: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(serviceType).isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasMoreItems: PropTypes.bool.isRequired,
  bereavementId: PropTypes.string.isRequired,
  arrangement: arrangementType,
  disabled: PropTypes.bool,
  getMoreItems: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpenDuplicateSelectionModal: PropTypes.func.isRequired,
};

export default FeeModal;
