export const permittedAttendees = {
  NEXT_OF_KIN: 'Next of kin',
  FAMILY_MEMBERS: 'Family members',
  FRIENDS: 'Friends',
  OPEN: 'Open',
};

export const clothesDisposalTypes = [
  { name: 'Charity', value: 'CHARITY' },
  { name: 'Return to family', value: 'RETURN_TO_FAMILY' },
  { name: 'Remain with body', value: 'REMAIN_WITH_BODY' },
];

export const clothesTypes = [
  'None',
  'Own Clothes',
  'Gown',
];

export const clothesColours = [
  'Blue',
  'Cream',
  'Flower',
  'Green',
  'Grey',
  'Lilac',
  'Maroon',
  'Navy',
  'Oyster',
  'Peach',
  'Pink',
  'White',
];

export const implantTypes = [
  { name: 'Pacemaker', value: 'PACEMAKER' },
  { name: 'ICD', value: 'ICD' },
  { name: 'CRTD', value: 'CRTD' },
  { name: 'Loop recorder', value: 'LOOP_RECORDER' },
  { name: 'VAD', value: 'VAD' },
  { name: 'LVAD', value: 'LVAD' },
  { name: 'RVAD', value: 'RVAD' },
  { name: 'BIVAD', value: 'BIVAD' },
  { name: 'Drug pump', value: 'DRUG_PUMP' },
  { name: 'Bone growth stimulator', value: 'BONE_GROWTH_STIMULATOR' },
  { name: 'Hydrocephalus shunt', value: 'HYDROCEPHALUS_SHUNT' },
  { name: 'Other battery powered', value: 'OTHER_BATTERY_POWERED' },
  { name: 'Fixion nails', value: 'FIXION_NAILS' },
];
