import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MusicFormSection from './MusicFormSection';

class MusicFormSectionContainer extends Component {
  static propTypes = {
    fieldData: PropTypes.objectOf(PropTypes.any),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    fieldData: {
      serviceType: null,
      selections: [],
    },
  }

  handleChange = (key, value) => {
    const { onChange, fieldData } = this.props;

    const newFormData = {
      ...fieldData,
      [key]: value,
    };

    onChange(newFormData);
  }

  render() {
    const { fieldData, disabled } = this.props;
    return (
      <MusicFormSection
        onChange={this.handleChange}
        fieldData={fieldData}
        disabled={disabled}
      />
    );
  }
}

export default MusicFormSectionContainer;
