import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import EmailIcon from '@material-ui/icons/Email';
import LocationIcon from '@material-ui/icons/LocationOn';
import StarIcon from '@material-ui/icons/Star';
import WalletIcon from '@material-ui/icons/AccountBalance';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import PeopleIcon from '@material-ui/icons/People';
import EditIcon from '@material-ui/icons/Edit';
import RemoveIcon from '@material-ui/icons/Remove';
import { bereavedPersonConnectionType } from 'types/bereavement';
import {
  isAddressEmpty, buildAddressString, buildPhonesString, buildEmailsString,
} from 'services/utils';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const BereavedPersonCard = ({
  bereavedPersonConnection,
  customClass,
  errors,
  disabled,
  onEdit,
  onRemove,
  displayRemove,
}) => {
  const {
    isPrimaryContact,
    isBillPayer,
    isNextOfKin,
    relationshipToDeceased,
    bereavedPerson,
  } = bereavedPersonConnection;
  const {
    name,
    phones,
    emails,
    address,
  } = bereavedPerson;
  const { givenName, familyName } = name;
  let displayName = '';
  if (givenName) {
    displayName += `${givenName} `;
  }
  if (familyName) {
    displayName += `${familyName}`;
  }

  return (
    <Fragment>
      <div
        data-test-id="bereavedPersonCard"
        className={classNames(
          rcStyles.bereaved_card,
          customClass,
        )}
      >
        <div className={rcStyles.bereaved_card__title}>
          <h3 className={classNames(
            styles.long_primer,
            styles.is_black,
          )}
          >
            {displayName}
          </h3>
          {onEdit && (
            <IconButton
              onClick={() => onEdit(bereavedPersonConnection)}
              color="primary"
              variant="contained"
              size="small"
              disabled={disabled}
              data-test-id="editBereaved"
            >
              <EditIcon />
            </IconButton>
          )}
        </div>
        {phones?.length > 0 && (
          <div className={rcStyles.icon_row}>
            <SmartphoneIcon className={rcStyles.is_icon} />
            {buildPhonesString(phones)}
          </div>
        )}
        {emails?.length > 0 && (
          <div className={rcStyles.icon_row}>
            <EmailIcon className={rcStyles.is_icon} />
            {buildEmailsString(emails)}
          </div>
        )}
        {!isAddressEmpty(address) && (
          <div className={rcStyles.icon_row}>
            <LocationIcon className={rcStyles.is_icon} />
            {buildAddressString(address)}
          </div>
        )}
        {errors?.address && (
          <div className={classNames(rcStyles.icon_row, rcStyles.address_required)}>
            <LocationIcon className={rcStyles.is_icon} />
            {errors?.address}
          </div>
        )}
        <div className={rcStyles.multi_icon_row}>
          {isPrimaryContact && (
            <div className={rcStyles.row_item}>
              <StarIcon className={rcStyles.is_icon} />
              {t('Primary contact')}
            </div>
          )}
          {isBillPayer && (
            <div className={rcStyles.row_item}>
              <WalletIcon className={rcStyles.is_icon} />
              {t('Primary Bill Payer')}
            </div>
          )}
          {isNextOfKin && (
            <div className={rcStyles.row_item}>
              <PeopleOutlineIcon className={rcStyles.is_icon} />
              {t('Next of kin')}
            </div>
          )}
          {relationshipToDeceased && (
            <div className={rcStyles.row_item}>
              <PeopleIcon className={rcStyles.is_icon} />
              {relationshipToDeceased}
            </div>
          )}
        </div>
      </div>
      <div className={rcStyles.is_edit}>
        {displayRemove && onRemove && (
          <IconButton
            onClick={() => onRemove(bereavedPersonConnection.id)}
            color="primary"
            variant="contained"
            size="small"
            data-test-id="remove"
            disabled={disabled}
            className={styles.o_button__secondary}
          >
            <RemoveIcon />
          </IconButton>
        )}
      </div>
    </Fragment>
  );
};

BereavedPersonCard.propTypes = {
  bereavedPersonConnection: bereavedPersonConnectionType.isRequired,
  customClass: PropTypes.string,
  disabled: PropTypes.bool,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  displayRemove: PropTypes.bool,
  errors: PropTypes.objectOf(PropTypes.any),
};

export default BereavedPersonCard;
