import React, { Component, Fragment } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import queryString from 'query-string';
import { withApollo } from 'react-apollo';
import overrideTheme from 'services/themes/overrideTheme';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bereavementType } from 'types/bereavement';
import { apolloClientType } from 'types/apollo';
import { clearTasksAction, fetchTasksAction } from 'actions/task';
import { taskType, taskCategoryType } from 'types/task';
import { pageInfoType } from 'types/common';
import { getAllowedCategoriesForScopes } from 'services/utils/task';
import { scopes } from 'constants/scopes';
import TaskModal from 'components/tasks/TaskModal';
import CreateCustomTaskModal from 'components/tasks/CreateCustomTaskModal';
import TasksListScreen from './TasksListScreen';
import { getTasksPdf } from './queries.gql';

class TasksListScreenContainer extends Component {
  static propTypes = {
    currentBereavement: bereavementType,
    tasks: PropTypes.arrayOf(taskType),
    client: apolloClientType.isRequired,
    pageInfo: pageInfoType,
    clearTasks: PropTypes.func.isRequired,
    fetchTasks: PropTypes.func.isRequired,
    allowedCategories: PropTypes.arrayOf(taskCategoryType),
    isAllowedToCreateCustomTask: PropTypes.bool.isRequired,
    isAllowedToDismissTasks: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      filters: {
        bereavement: undefined,
        home: null,
        assignee: null,
        category: null,
        status: null,
      },
      openTaskId: null,
      isCreateCustomTaskModalOpen: false,
      isPrintTasksLoading: false,
    };
  }

  componentDidMount() {
    const { clearTasks } = this.props;
    clearTasks();
    this.getTasks({
      reload: true,
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (state.filters.bereavement === undefined) {
      return {
        filters: {
          ...state.filters,
          bereavement: props.currentBereavement || null,
        },
      };
    }
    return null;
  }

  getTasks = ({
    reload,
  }) => {
    const { pageInfo, fetchTasks, allowedCategories } = this.props;
    const { filters } = this.state;
    const cursor = (!reload) ? pageInfo.cursor : null;
    const bereavementId = (filters.bereavement) ? filters.bereavement.id : null;
    const organisationalUnitIds = (filters.home) ? [filters.home.id] : null;
    const assigneeId = (filters.assignee) ? filters.assignee.id : null;

    fetchTasks({
      pagination: { first: 15, after: cursor },
      bereavementId,
      organisationalUnitIds,
      assigneeId,
      categories: filters.category ? [filters.category] : allowedCategories,
      status: filters.status,
    });
  };

  handleOnFilterChange = (key, value) => {
    const { filters } = this.state;

    const additionalChanges = {};
    if (key === 'bereavement') {
      additionalChanges.home = null;
    }

    this.setState({
      filters: {
        ...filters,
        [key]: value,
        ...additionalChanges,
      },
    }, () => { this.getTasks({ reload: true }); });
  };

  handleOnViewTask = (id) => {
    this.setState({ openTaskId: id });
  };

  handleOnCreateCustomTask = () => {
    this.setState({ isCreateCustomTaskModalOpen: true });
  };

  handleCloseModals = () => {
    this.setState({
      openTaskId: null,
      isCreateCustomTaskModalOpen: false,
    });
  }

  handlePrintTasks = () => {
    const { client, allowedCategories } = this.props;
    const { filters } = this.state;

    this.setState({ isPrintTasksLoading: true });
    client.query({
      query: getTasksPdf,
      variables: {
        organisationalUnitIds: (filters.home && filters.home.id) ? [filters.home.id] : [],
        assigneeId: filters.assignee && filters.assignee.id,
        bereavementId: filters.bereavement && filters.bereavement.id,
        status: filters.status,
        categories: filters.category ? [filters.category] : allowedCategories,
        pagination: {
          first: 200,
          after: null,
        },
      },
    }).then(({ data }) => {
      this.setState({ isPrintTasksLoading: false });
      if (!data) {
        return;
      }
      const { uri } = data.tasksPdf.media;
      window.open(uri, uri).focus();
    });
  }

  render() {
    const {
      tasks,
      pageInfo,
      allowedCategories,
      isAllowedToCreateCustomTask,
      isAllowedToDismissTasks,
    } = this.props;
    const {
      filters,
      openTaskId,
      isCreateCustomTaskModalOpen,
      isPrintTasksLoading,
    } = this.state;

    const openTask = (tasks || []).find(task => task.id === openTaskId);

    return (
      <Fragment>
        <MuiThemeProvider theme={overrideTheme}>
          <TaskModal
            isOpen={!!openTask}
            onClose={this.handleCloseModals}
            task={openTask}
            isAllowedToDismissTasks={isAllowedToDismissTasks}
          />
          <CreateCustomTaskModal
            bereavement={filters.bereavement}
            onClose={this.handleCloseModals}
            isOpen={!!(isCreateCustomTaskModalOpen && filters.bereavement)}
          />
          <TasksListScreen
            isPrintTasksLoading={isPrintTasksLoading}
            isLoading={pageInfo.loadingMore}
            tasks={tasks || []}
            onPrintTasks={this.handlePrintTasks}
            hasMoreTasks={pageInfo.hasMore}
            totalTasksFound={pageInfo.totalCount}
            getTasks={() => { this.getTasks({ reload: false }); }}
            filters={filters}
            onFilterChange={this.handleOnFilterChange}
            onViewTask={this.handleOnViewTask}
            onCreateCustomTask={this.handleOnCreateCustomTask}
            canCreateCustomTask={!!filters.bereavement}
            allowedCategories={allowedCategories}
            isAllowedToCreateCustomTask={isAllowedToCreateCustomTask}
          />
        </MuiThemeProvider>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { location } = props;
  const bereavementId = queryString.parse(location.search).bereavementId || null;
  const currentBereavement = state.bereavementStore.bereavements.find(
    existing => existing.id === bereavementId,
  );

  return {
    currentBereavement,
    tasks: state.taskStore.tasks,
    pageInfo: state.taskStore.pageInfo,
    allowedCategories: getAllowedCategoriesForScopes(state.userStore.user.policy.scopes),
    isAllowedToCreateCustomTask: state.userStore.user.policy.scopes.includes(
      scopes.CUSTOM_TASK_WRITE,
    ),
    isAllowedToDismissTasks: state.userStore.user.policy.scopes.includes(
      scopes.TASK_DISMISS,
    ),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  clearTasks: clearTasksAction,
  fetchTasks: fetchTasksAction,
}, dispatch);

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(
    withRouter(TasksListScreenContainer),
  ),
);
