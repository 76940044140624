import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { debounce } from 'debounce';
import PropTypes from 'prop-types';
import { buildDirectoryListingString, removePropertyByName } from 'services/utils';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { statuses } from 'constants/catalogue';
import { emptyDirectoryListing } from 'constants/directoryListing';
import { directoryListingType } from 'types/directoryListing';
import { apolloClientType } from 'types/apollo';
import NameDirectoryListingFormSection from './NameDirectoryListingFormSection';
import { directoryListings } from './queries.gql';
import { createDirectoryListingMutation } from './mutations.gql';
import { validationSchema } from './validation';

class NameDirectoryListingFormSectionContainer extends Component {
  debounceChange = debounce((value) => {
    if (value) {
      this.getSuggestions(value);
    } else {
      this.setState({ suggestions: [] });
    }
  }, 500);

  static propTypes = {
    client: apolloClientType.isRequired,
    fieldData: PropTypes.shape({
      directoryListing: directoryListingType,
    }),
    title: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    generateRefs: PropTypes.func.isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  constructor(props) {
    super(props);

    const lookUpValue = props.fieldData && props.fieldData.directoryListing
      ? buildDirectoryListingString(props.fieldData.directoryListing)
      : '';

    this.state = {
      isModalOpen: false,
      isValidationEnabled: false,
      lookUpValue,
      suggestions: [],
      isLoading: false,
      isSaving: false,
      formData: props.fieldData || {
        name: null,
        directoryListingId: null,
        directoryListing: null,
      },
      modalFormData: emptyDirectoryListing,
    };

    this.lookUpRef = React.createRef();
    this.dialogRef = React.createRef();
  }

  componentDidMount() {
    const { generateRefs } = this.props;
    generateRefs(Object.keys(validationSchema.fields));
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    const { disabled, errors } = this.props;
    const {
      suggestions, formData, lookUpValue, isModalOpen, modalFormData,
      isLoading, isSaving,
    } = this.state;

    return nextState.suggestions !== suggestions
      || nextState.formData.name !== formData.name
      || nextState.formData.directoryListingId !== formData.directoryListingId
      || nextState.lookUpValue !== lookUpValue
      || nextState.isModalOpen !== isModalOpen
      || nextState.modalFormData !== modalFormData
      || nextState.isLoading !== isLoading
      || nextState.isSaving !== isSaving
      || nextProps.errors !== errors
      || nextProps.disabled !== disabled;
  }

  handleModalToggle = (isModalOpen) => {
    this.setState({ isModalOpen, isValidationEnabled: false });

    if (!isModalOpen) {
      setTimeout(() => this.lookUpRef.current.blur(), 50);
      setTimeout(() => this.lookUpRef.current.focus(), 55);
    }
  }

  handleModalChange = (key, value) => {
    const { validate } = this.props;
    const { modalFormData } = this.state;

    const updatedFormData = {
      ...modalFormData,
      [key]: value,
    };

    validate(updatedFormData, validationSchema);
    this.setState({ modalFormData: updatedFormData });
  }

  handleModalSave = () => {
    const { validate, client } = this.props;
    const { modalFormData } = this.state;
    this.setState({ isValidationEnabled: true });

    const isValid = validate(modalFormData, validationSchema, true, this.dialogRef);
    if (isValid) {
      this.setState({ isSaving: true });
      client.mutate({
        mutation: createDirectoryListingMutation,
        variables: { input: modalFormData },
      }).then(({ data: { directoryListings: { createDirectoryListing } }, errors }) => {
        if (errors && errors.length > 0) {
          return;
        }
        const { directoryListing } = createDirectoryListing;
        this.setState({
          isModalOpen: false,
          modalFormData: emptyDirectoryListing,
        });
        this.handleDirectoryListingChange(directoryListing);
      }).finally(() => this.setState({ isSaving: false, isModalOpen: false }));
    }
  }

  handleLookUpChange = (value) => {
    const newState = { lookUpValue: value };
    if (value) {
      newState.isLoading = true;
    }

    this.setState(newState);
    return this.debounceChange(value);
  };

  handleNameChange = (event) => {
    const { onChange } = this.props;
    const { formData } = this.state;
    const { value } = event.target;

    this.setState({
      formData: {
        ...formData,
        name: value,
      },
    });

    if (onChange) {
      onChange({
        name: value,
        directoryListingId: formData.directoryListingId,
        directoryListing: formData.directoryListing,
      });
    }
  }

  handleDirectoryListingChange = (directoryListing) => {
    const { onChange } = this.props;
    const { formData } = this.state;

    this.setState({
      formData: {
        ...formData,
        directoryListingId: directoryListing.id,
        directoryListing,
      },
      lookUpValue: buildDirectoryListingString(directoryListing),
    });

    if (onChange) {
      onChange({ name: formData.name, directoryListingId: directoryListing.id, directoryListing });
    }
  }

  handleDirectoryListingClear = () => {
    const { onChange } = this.props;
    const { formData } = this.state;

    this.setState({
      formData: {
        ...formData,
        directoryListingId: null,
        directoryListing: null,
      },
      lookUpValue: '',
    });

    if (onChange) {
      onChange({ name: formData.name, directoryListingId: null, directoryListing: null });
    }
  }

  getSuggestions = (searchText) => {
    const { client } = this.props;

    client.query({
      query: directoryListings,
      variables: {
        term: searchText,
        status: statuses.PUBLISHED,
        pagination: {
          first: 50,
          after: null,
        },
      },
    }).then((data) => {
      const suggestions = data.data.directoryListings.edges.map(edge => ({
        data: removePropertyByName(edge.node, '__typename'),
        value: buildDirectoryListingString(edge.node),
      }));
      this.setState({
        suggestions,
        isLoading: false,
      });
    });
  }

  render() {
    const {
      title, disabled, formRefs, errors,
    } = this.props;
    const {
      isModalOpen, isValidationEnabled, lookUpValue, formData, modalFormData,
      suggestions, isLoading, isSaving,
    } = this.state;

    return (
      <NameDirectoryListingFormSection
        title={title}
        disabled={disabled}
        name={formData.name}
        lookUpValue={lookUpValue}
        modalFormData={modalFormData}
        isModalOpen={isModalOpen}
        isLoading={isLoading}
        isSaving={isSaving}
        suggestions={suggestions}
        lookUpRef={this.lookUpRef}
        onNameChange={this.handleNameChange}
        onDirectoryListingChange={this.handleDirectoryListingChange}
        onDirectoryListingClear={this.handleDirectoryListingClear}
        onLookUpChange={this.handleLookUpChange}
        onModalOpen={() => this.handleModalToggle(true)}
        onModalClose={() => this.handleModalToggle(false)}
        onModalSave={this.handleModalSave}
        onModalChange={this.handleModalChange}
        dialogRef={this.dialogRef}
        formRefs={formRefs}
        errors={isValidationEnabled ? errors : {}}
      />
    );
  }
}

export default withApollo(withValidation(NameDirectoryListingFormSectionContainer));
