import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from 'scss/main.scss';

const SlideInWindow = ({
  children,
  extTrigger = [],
  additionalClasses = [],
  slideInWindowActive,
  activeWindow,
  toggleSlideInWindow,
  switchWindow,
  toggleInProgress,
  slideInWindowOpen,
  transitionTime,
}) => {
  const display = children.map((curWindow, index) => (
    <div
      className={classNames(
        !index ? styles.c_drawer__primary_view : '',
        activeWindow === curWindow.props.windowName ? styles.is_active : '',
        activeWindow === '' ? styles.is_hidden : '',
        styles.c_drawer__view,
      )}
      key={index}
    >
      {React.cloneElement(curWindow, {
        toggleSlideInWindow,
        switchWindow,
        activeWindow,
        toggleInProgress,
        slideInWindowOpen,
        transitionTime,
        ...curWindow.props,
      })}
    </div>
  ));

  return (
    <React.Fragment>
      <div
        className={classNames(
          slideInWindowActive ? styles.is_active : '',
          styles.c_drawer__overlay,
        )}
        onClick={toggleSlideInWindow}
        role="button"
        tabIndex="-1"
        data-test-id="toggleWindow"
      />

      <div
        className={classNames(
          slideInWindowActive ? styles.is_active : '',
          styles.c_drawer__ext_trigger,
        )}
        onClick={toggleSlideInWindow}
        role="button"
        tabIndex="-1"
        data-test-id="toggleWindowExt"
      >
        {extTrigger}
      </div>

      <div
        className={classNames(
          slideInWindowActive ? styles.is_active : '',
          styles.c_drawer__container,
          ...additionalClasses,
        )}
      >
        {display}
      </div>
    </React.Fragment>
  );
};

SlideInWindow.propTypes = {
  extTrigger: PropTypes.element,
  slideInWindowActive: PropTypes.bool.isRequired,
  activeWindow: PropTypes.string.isRequired,
  toggleSlideInWindow: PropTypes.func.isRequired,
  switchWindow: PropTypes.func.isRequired,
  toggleInProgress: PropTypes.bool.isRequired,
  slideInWindowOpen: PropTypes.bool.isRequired,
  transitionTime: PropTypes.number.isRequired,
  additionalClasses: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.any),
  ),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default SlideInWindow;
