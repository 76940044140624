import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import AshesRecordCard from 'components/bereavement/AshesRecordCard';
import AshesReleaseFormModal from 'components/bereavement/AshesReleaseFormModal';
import { ashesRecordType, bereavedPersonConnectionType } from 'types/bereavement';

import classNames from 'classnames';
import styles from 'scss/main.scss';

const CareRecordAshesSection = ({
  bereavementId,
  deceasedPersonId,
  ashesRecords,
  relatedData,
  bereavedPeopleConnections,
  isReleaseFormModalOpen,
  onAdd,
  onEdit,
  onReleaseFormOpen,
  onReleaseFormClose,
  disabled,
}) => (
  <div className={classNames(styles.u_push__top_double, styles.u_push__sides_double)}>
    <div className={classNames(styles.fb, styles.u_push__bottom)}>
      <Button
        onClick={onAdd}
        variant="outlined"
        color="primary"
        disabled={disabled}
        data-test-id="addAshes"
        className={styles.u_push__right}
      >
        <AddIcon className={styles.u_push__right_half} />
        {t('Add Ashes')}
      </Button>
      <Button
        onClick={onReleaseFormOpen}
        variant="outlined"
        color="primary"
        data-test-id="createAshesReleaseForm"
      >
        {t('Create release form')}
      </Button>
    </div>
    {ashesRecords && ashesRecords.map((ashesRecord, index) => (
      <AshesRecordCard
        key={ashesRecord.id}
        ashesRecord={ashesRecord}
        relatedData={relatedData}
        bereavedPeopleConnections={bereavedPeopleConnections}
        onEdit={editedAshesRecord => onEdit(editedAshesRecord, index)}
        disabled={disabled}
      />
    ))}
    <AshesReleaseFormModal
      bereavementId={bereavementId}
      deceasedPersonId={deceasedPersonId}
      isOpen={isReleaseFormModalOpen}
      ashesRecords={ashesRecords}
      bereavedPeopleConnections={bereavedPeopleConnections}
      onClose={onReleaseFormClose}
    />
  </div>
);

CareRecordAshesSection.propTypes = {
  bereavementId: PropTypes.string.isRequired,
  deceasedPersonId: PropTypes.string.isRequired,
  ashesRecords: PropTypes.arrayOf(ashesRecordType),
  relatedData: PropTypes.objectOf(PropTypes.any),
  bereavedPeopleConnections: PropTypes.arrayOf(bereavedPersonConnectionType),
  isReleaseFormModalOpen: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onReleaseFormOpen: PropTypes.func.isRequired,
  onReleaseFormClose: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default CareRecordAshesSection;
