import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { MuiThemeProvider } from '@material-ui/core/styles';
import overrideTheme from 'services/themes/overrideTheme';
import { dashboardBannerType } from 'types/common';
import AppBar from 'components/common/AppBar';
import DashboardBanner from 'components/common/DashboardBanner';
import classNames from 'classnames';
import styles from 'scss/main.scss';

const ScreenLayout = ({
  title,
  dashboardBannerProps,
  children,
}) => (
  <MuiThemeProvider theme={overrideTheme}>
    <Helmet>
      <title>
        {title}
      </title>
    </Helmet>
    <div
      className={classNames(
        styles.c_page__full_height,
        styles.has_faded_background,
      )}
    >
      <AppBar />
      <DashboardBanner
        {...dashboardBannerProps}
        title={(dashboardBannerProps && dashboardBannerProps.title) || title}
        breadcrumbs={(dashboardBannerProps && dashboardBannerProps.breadcrumbs) || []}
      />
      {children}
    </div>
  </MuiThemeProvider>
);

ScreenLayout.propTypes = {
  title: PropTypes.string.isRequired,
  dashboardBannerProps: dashboardBannerType,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ScreenLayout;
