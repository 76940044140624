import React from 'react';
import { currencySymbols } from 'constants/currency';
import { isNullOrUndefined } from 'services/utils';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import { Props } from './index.types';

const CurrencyField: React.FC<Props> = ({
  defaultValue, InputProps, inputProps, min, max, nullable, onChange, onChangeAmount, value, classes, ...otherProps
}: Props) => {
  const parseAmount = (amount?: string|null) => (
    !isNullOrUndefined(amount) ? parseFloat(amount as string).toFixed(2) : undefined
  );

  return (
    <TextField
      {...otherProps}
      classes={classes}
      type="number"
      defaultValue={(!isNullOrUndefined(value) && parseAmount(`${value}`)) || parseAmount(`${defaultValue}`)}
      value={value}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {currencySymbols.GBP}
          </InputAdornment>
        ),
        ...InputProps,
      }}
      inputProps={{
        'step': '.01',
        ...inputProps,
      }}
      onChange={(event) => {
        if (event.target.value.length === 2
          && event.target.value.charAt(0) === '0'
          && event.target.value.charAt(1) !== '.') {
          const e = event;
          e.target.value = event.target.value.charAt(1);
        }

        let parsedValue = !(nullable && event.target.value.length === 0) ? parseFloat(`${event.target.value || 0}`) : null;
        const isValidValue = !isNullOrUndefined(parsedValue);

        if (min !== undefined && isValidValue && (parsedValue as number) < min) {
          parsedValue = min;
        }
        if (max !== undefined && isValidValue && (parsedValue as number) > max) {
          parsedValue = max;
        }

        if (onChangeAmount) {
          onChangeAmount(parsedValue);
        }
        if (onChange) {
          onChange(event);
        }
      }}
      onBlur={(event) => {
        const e = event;
        let parsedValue = !(nullable && e.target.value.length === 0) ? parseFloat(e.target.value || 0) : null;
        const isValidValue = !isNullOrUndefined(parsedValue);
        if (min !== undefined && isValidValue && (parsedValue as number) < min) {
          parsedValue = min;
        }
        if (max !== undefined && isValidValue && (parsedValue as number) > max) {
          parsedValue = max;
        }
        e.target.value = isValidValue && (parsedValue as number).toFixed(2);
        const returnValue = isValidValue ? parseFloat(parseFloat(`${parsedValue}`).toFixed(2)) : null;

        if (onChangeAmount) {
          onChangeAmount(returnValue);
        }
      }}
      data-test-id="currencyField"
    />
  );
};

export default CurrencyField;
