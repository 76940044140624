import PropTypes from 'prop-types';
import { mediaType } from 'types/media';

export const reactRefType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({ current: PropTypes.any }),
]);

export const nameType = PropTypes.shape({
  title: PropTypes.string,
  givenName: PropTypes.string,
  middleName: PropTypes.string,
  familyName: PropTypes.string,
});

export const moneyType = PropTypes.shape({
  amount: PropTypes.number,
  currency: PropTypes.string,
});

export const addressType = PropTypes.shape({
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  town: PropTypes.string,
  county: PropTypes.string,
  postCode: PropTypes.string,
  countryCode: PropTypes.string,
});

export const telephoneType = PropTypes.shape({
  countryCode: PropTypes.string,
  number: PropTypes.string,
});

export const telephoneContactType = PropTypes.shape({
  telephone: telephoneType,
  type: PropTypes.string,
});

export const phonesType = PropTypes.arrayOf(telephoneContactType);

export const emailsType = PropTypes.arrayOf(PropTypes.string);

export const pageInfoType = PropTypes.shape({
  totalCount: PropTypes.number,
  hasMore: PropTypes.bool.isRequired,
  cursor: PropTypes.string,
  loadingMore: PropTypes.bool.isRequired,
});

export const paginationType = PropTypes.shape({
  after: PropTypes.string,
  first: PropTypes.number,
  hasNextPage: PropTypes.bool,
});

export const navigationPathType = PropTypes.shape({
  path: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
});

export const noteType = PropTypes.shape({
  id: PropTypes.string,
  content: PropTypes.string,
  authorId: PropTypes.string,
  entity: PropTypes.string,
});

export const notesType = PropTypes.arrayOf(noteType);

export const documentType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  mediaId: PropTypes.string.isRequired,
  media: mediaType,
});

export const adminCardType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  icon: PropTypes.func,
  link: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
});

export const dashboardBannerType = PropTypes.shape({
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  buttons: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  description: PropTypes.string,
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  footnote: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
});
