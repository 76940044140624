import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import { transferType } from 'types/bereavement';
import { reactRefType } from 'types/common';
import { textForTransferType } from 'services/utils/bereavement';
import AddressFormSection from 'components/common/AddressFormSection';
import DateTimePicker from 'components/common/DateTimePicker';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const TransferModal = ({
  transfer,
  isOpen,
  onChange,
  onClose,
  onSave,
  dialogRef,
  formRefs,
  errors,
}) => {
  const isInternalTransfer = transfer && transfer.__typename === 'InternalTransfer';
  const isComplete = transfer && transfer.isComplete;

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="payment-modal-title"
      open={isOpen}
      fullScreen
      scroll="paper"
      TransitionComponent={Transition}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <div
        ref={dialogRef}
        className={classNames(
          styles.c_dialog__body,
          styles.c_dialog__overflow,
        )}
        data-test-id="transferModal"
      >
        <DialogTitle
          disableTypography
          className={styles.dialog_title_bar}
        >
          <IconButton
            className={classNames(
              styles.o_button__secondary,
              styles.is_close,
            )}
            color="primary"
            variant="contained"
            aria-label="Close"
            onClick={onClose}
            data-test-id="cancelCase"
          >
            <CloseIcon />
          </IconButton>
          <div className={styles.is_title}>
            {textForTransferType(transfer && transfer.__typename)}
          </div>
          <div className={styles.is_actions}>
            <Button
              onClick={onSave}
              variant="contained"
              color="secondary"
              data-test-id="saveCase"
              fullWidth
              className={rcStyles.save_button}
            >
              {(transfer && transfer.id) ? t('Update care record') : t('Add to care record')}
            </Button>
          </div>
        </DialogTitle>
        <DialogContent className={styles.c_dialog__content}>
          <div
            className={classNames(
              rcStyles.v_spacing,
              styles.o_block_content,
            )}
          >
            <h3 className={styles.pica_light}>
              {t('Details')}
            </h3>
            {isInternalTransfer ? (
              <TextField
                label={t('Transfer from *')}
                value={(transfer && transfer.fromLocation) || ''}
                onChange={event => onChange('fromLocation', event.target.value)}
                fullWidth
                inputProps={{
                  'data-test-id': 'fromLocation',
                }}
                error={errors && errors.fromLocation}
                helperText={errors && errors.fromLocation}
                disabled={isComplete}
              />
            ) : (
              <AddressFormSection
                address={transfer && transfer.fromLocation}
                lookupLabel={t('Transfer from *')}
                onChange={address => onChange('fromLocation', address)}
                errors={errors && errors.fromLocation}
                disabled={isComplete}
                data-test-id="transferFromFormSection"
              />
            )}
            <div ref={formRefs.toLocation}>
              {isInternalTransfer ? (
                <TextField
                  label={t('Transfer to *')}
                  value={(transfer && transfer.toLocation) || ''}
                  onChange={event => onChange('toLocation', event.target.value)}
                  fullWidth
                  inputProps={{
                    'data-test-id': 'toLocation',
                  }}
                  error={errors && errors.toLocation}
                  helperText={errors && errors.toLocation}
                  disabled={isComplete}
                />
              ) : (
                <AddressFormSection
                  address={transfer && transfer.toLocation}
                  lookupLabel={t('Transfer to *')}
                  onChange={address => onChange('toLocation', address)}
                  errors={errors && errors.toLocation}
                  disabled={isComplete}
                  data-test-id="transferToFormSection"
                />
              )}
            </div>
            <div ref={formRefs.instructions}>
              <TextField
                label={t('Instructions')}
                value={(transfer && transfer.instructions) || ''}
                onChange={event => onChange('instructions', event.target.value)}
                fullWidth
                inputProps={{
                  'data-test-id': 'instructions',
                }}
                disabled={isComplete}
              />
            </div>
            <div ref={formRefs.startDateTime}>
              <DateTimePicker
                value={(transfer && transfer.startDateTime) || null}
                label={isInternalTransfer ? t('Date & time of transfer *') : t('Start date & time of transfer *')}
                onChange={date => onChange('startDateTime', date)}
                isClearable
                disabled={isComplete}
                errors={errors && !!errors.startDateTime}
                helperText={errors && errors.startDateTime}
                data-test-id="startDatetime"
              />
            </div>
            {!isInternalTransfer && (
              <div ref={formRefs.endDateTime}>
                <DateTimePicker
                  value={(transfer && transfer.endDateTime) || null}
                  label={t('End date & time of transfer *')}
                  onChange={date => onChange('endDateTime', date)}
                  isClearable
                  disabled={isComplete}
                  errors={errors && !!errors.endDateTime}
                  helperText={errors && errors.endDateTime}
                  data-test-id="endDatetime"
                />
              </div>
            )}
            <div className={rcStyles.transfer_container}>
              <h3 className={rcStyles.is_title}>{t('Complete transfer')}</h3>
              <div ref={formRefs.receivedBy}>
                <TextField
                  label={t('Received by')}
                  value={(transfer && transfer.receivedBy) || ''}
                  onChange={event => onChange('receivedBy', event.target.value)}
                  fullWidth
                  inputProps={{
                    'data-test-id': 'receivedBy',
                  }}
                  disabled={isComplete}
                />
              </div>
              <div className={classNames(styles.u_fullWidth)}>
                <div ref={formRefs.receivedBy}>
                  <TextField
                    label={t('Received by')}
                    value={(transfer && transfer.receivedBy) || ''}
                    onChange={event => onChange('receivedBy', event.target.value)}
                    fullWidth
                    inputProps={{
                      'data-test-id': 'receivedBy',
                    }}
                    disabled={isComplete}
                  />
                </div>
                <div ref={formRefs.completionNotes}>
                  <TextField
                    label={t('Notes')}
                    value={(transfer && transfer.completionNotes) || ''}
                    onChange={event => onChange('completionNotes', event.target.value)}
                    fullWidth
                    multiline
                    rowsMax={4}
                    inputProps={{
                      'data-test-id': 'completionNotes',
                    }}
                    disabled={isComplete}
                  />
                </div>
              </div>
              <div ref={formRefs.isPossessionsChecked}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={!!(transfer && transfer.isPossessionsChecked)}
                      onChange={event => onChange('isPossessionsChecked', event.target.checked)}
                      inputProps={{ 'data-test-id': 'isPossessionsChecked' }}
                      color="primary"
                      disabled={isComplete}
                    />
                    )}
                  label={t('Possessions checked')}
                />
              </div>
              <div ref={formRefs.isIdentificationChecked}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={!!(transfer && transfer.isIdentificationChecked)}
                      onChange={event => onChange('isIdentificationChecked', event.target.checked)}
                      inputProps={{ 'data-test-id': 'isIdentificationChecked' }}
                      color="primary"
                      disabled={isComplete}
                    />
                    )}
                  label={t('Identification checked')}
                />
              </div>
            </div>
            <div className={styles.c_confirmed__wrap}>
              <div
                ref={formRefs.isComplete}
                className={rcStyles.care_complete}
              >
                <FormControlLabel
                  control={(
                    <Checkbox
                      className={styles.c_confirmed__checkbox}
                      checked={!!(transfer && transfer.isComplete)}
                      onChange={event => onChange('isComplete', event.target.checked)}
                      inputProps={{ 'data-test-id': 'isComplete' }}
                      color="primary"
                      disabled={isComplete}
                    />
                  )}
                  label={t('Transfer into care complete')}
                />
              </div>
            </div>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};

TransferModal.propTypes = {
  transfer: transferType,
  isOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  dialogRef: reactRefType.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default TransferModal;
