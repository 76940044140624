export const hydrateTaskTransform = task => ({
  ...task,
  progressJson: JSON.parse(task.progressJson || '[]'),
  detailsJson: JSON.parse(task.detailsJson || '[]'),
});

const aggregatesToConvertToIds = [
  'StaffMember',
];

const convertAggregatesToIds = progressFields => progressFields.map((field) => {
  if (field.value && aggregatesToConvertToIds.includes(field.value.__typename)) {
    return {
      ...field,
      value: field.value.id,
    };
  }
  return field;
});

export const editTaskTransform = (task) => {
  const out = {
    id: task.id,
  };

  if (task.status) {
    out.status = task.status;
  }

  if (task.assignee) {
    out.assigneeId = task.assignee.id;
  }

  if (task.note) {
    out.note = task.note;
  }

  if (task.progressJson) {
    out.progressInputJson = JSON.stringify(convertAggregatesToIds(
      (task.progressJson || []).map(field => ({
        key: field.key,
        value: field.value,
      })),
    ));
  }

  return out;
};

export const createCustomTaskTransform = (task) => {
  const out = {
    id: task.id,
    title: task.title,
    description: task.description,
    category: task.category,
  };

  if (task.assignee) {
    out.assigneeId = task.assignee.id;
  }

  if (task.bereavement) {
    out.bereavementId = task.bereavement.id;
  }

  if (task.organisationalUnit) {
    out.organisationalUnitId = task.organisationalUnit.id;
  }

  return out;
};
