import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import AddressFormSection from 'components/common/AddressFormSection';
import DateTimePicker from 'components/common/DateTimePicker';
import IconLoad from 'icons/IconLoad';
import styles from 'scss/main.scss';
import { getSuggestedArrangementVenueDates } from 'services/utils/arrangement';
import { arrangementType } from 'types/bereavement';

import rcStyles from './styles.scss';

const ArrangementVenueForm = ({
  arrangement,
  categoryInformation,
  disabled,
  isSaving,
  errors,
  formRefs,
  onChange,
  onNumericChange,
  onSave,
}) => {
  const { startDateTime, endDateTime } = categoryInformation;
  return (
    <div className={rcStyles.v_spacing}>
      {isSaving && (
        <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
          <IconLoad title={t('Saving')} />
        </div>
      )}
      <div ref={formRefs.startDateTime}>
        <DateTimePicker
          value={startDateTime || null}
          label={t('Start date & time')}
          placeholder={t('Start date & time')}
          isStartDate
          maxDate={endDateTime}
          timeRange={[startDateTime, endDateTime]}
          getSuggestedDates={() => getSuggestedArrangementVenueDates(arrangement)}
          onChange={date => onChange('startDateTime', date)}
          onClearEndDate={() => onChange('endDateTime', null)}
          isClearable
          disabled={disabled}
          errors={errors && !!errors.startDateTime}
          helperText={errors && errors.startDateTime}
          data-test-id="startDateTime"
        />
      </div>
      <div ref={formRefs.endDateTime}>
        <DateTimePicker
          value={endDateTime || null}
          label={t('End date & time')}
          placeholder={t('End date & time')}
          isEndDate
          minDate={startDateTime}
          timeRange={[startDateTime, endDateTime]}
          onChange={date => onChange('endDateTime', date)}
          isClearable
          disabled={disabled}
          errors={errors && !!errors.endDateTime}
          helperText={errors && errors.endDateTime}
          data-test-id="endDateTime"
        />
      </div>
      <div
        ref={formRefs.funeralHomeBearerQuantity}
        className={rcStyles.col1}
      >
        <TextField
          type="number"
          label={t('Bearers supplied by us')}
          value={categoryInformation.funeralHomeBearerQuantity || ''}
          onChange={event => onNumericChange('funeralHomeBearerQuantity', event.target.value)}
          disabled={disabled}
          fullWidth
          error={errors && errors.funeralHomeBearerQuantity}
          helperText={errors && errors.funeralHomeBearerQuantity}
          inputProps={{
            'data-test-id': 'funeralHomeBearerQuantity',
            'min': 0,
          }}
        />
      </div>
      <div ref={formRefs.familyBearerQuantity}>
        <TextField
          type="number"
          label={t('Bearers supplied by family')}
          value={categoryInformation.familyBearerQuantity || ''}
          onChange={event => onNumericChange('familyBearerQuantity', event.target.value)}
          disabled={disabled}
          error={errors && errors.familyBearerQuantity}
          helperText={errors && errors.familyBearerQuantity}
          fullWidth
          inputProps={{
            'data-test-id': 'familyBearerQuantity',
            'min': 0,
          }}
        />
      </div>
      <div
        ref={formRefs.reservedSeatingQuantity}
        className={classNames(
          rcStyles.col1,
          rcStyles.colspan2,
        )}
      >
        <TextField
          type="number"
          label={t('Reserved seating required')}
          value={categoryInformation.reservedSeatingQuantity || ''}
          onChange={event => onNumericChange('reservedSeatingQuantity', event.target.value)}
          disabled={disabled}
          inputProps={{
            'data-test-id': 'reservedSeatingRequired',
            'min': 0,
          }}
          fullWidth
          error={errors && errors.reservedSeatingQuantity}
          helperText={errors && errors.reservedSeatingQuantity}
        />
      </div>
      <div
        ref={formRefs.alternativeLocation}
        className={classNames(
          rcStyles.col1,
          rcStyles.colspan2,
        )}
      >
        <AddressFormSection
          address={categoryInformation.alternativeLocation}
          onChange={address => onChange('alternativeLocation', address)}
          data-test-id="otherDeliveryLocation"
          lookupLabel={t('Alternate location')}
          errors={errors.alternativeLocation}
          disabled={disabled}
        />
      </div>
      <div
        ref={formRefs.isConfirmed}
        className={classNames(
          styles.c_confirmed__wrap,
          rcStyles.col1,
          rcStyles.colspan2,
        )}
      >
        <FormControlLabel
          control={(
            <Checkbox
              className={styles.c_confirmed__checkbox}
              checked={categoryInformation.isConfirmed || false}
              onChange={event => onChange('isConfirmed', event.target.checked)}
              inputProps={{ 'data-test-id': 'isConfirmed' }}
              disabled={disabled}
              color="primary"
            />
          )}
          label={t('Booking confirmed with venue')}
        />
      </div>
      <span className={rcStyles.col1}>
        <Button
          onClick={onSave}
          color="primary"
          variant="contained"
          disabled={disabled}
          data-test-id="saveVenueCategory"
        >
          {t('Save')}
        </Button>
      </span>
    </div>
  );
};

ArrangementVenueForm.propTypes = {
  arrangement: arrangementType.isRequired,
  categoryInformation: PropTypes.objectOf(PropTypes.any).isRequired,
  disabled: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onNumericChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ArrangementVenueForm;
