import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import classNames from 'classnames';
import { SortableElement } from 'react-sortable-hoc';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import DragnDrop from 'icons/IconDragnDrop';
import styles from 'scss/main.scss';

import rcStyles from './styles.scss';

const SortableCategoryItem = SortableElement(({
  category,
  isSortable,
  onClickIncludeInCatalogue,
  onEditCategory,
  isIncludible,
  isEditable,
}) => (
  <div
    className={classNames(
      rcStyles.sortable_tile,
      isSortable && rcStyles.is_active,
    )}
    style={{
      cursor: isSortable ? 'pointer' : 'auto',
    }}
    data-test-id={`${category.reference}SectionConfiguration`}
  >
    <div
      style={{
        backgroundImage: `url('${category.imagePath}')`,
      }}
      className={rcStyles.is_image}
    />
    <div className={rcStyles.actions}>
      <div className={rcStyles.is_title}>
        {category.name}
      </div>

      {isSortable ? (
        <div className={rcStyles.drag_button}>
          <DragnDrop />
        </div>
      ) : (
        <Fragment>
          {isIncludible && (
            <div className={rcStyles.is_checkbox}>
              <FormControlLabel
                control={(
                  <Checkbox
                    onChange={event => onClickIncludeInCatalogue(category.category, event.target.checked)}
                    inputProps={{ 'data-test-id': 'includeInCatalogue' }}
                    color="primary"
                    classes={{ root: rcStyles.is_checkbox }}
                    checked={category.visible}
                  />
                )}
                label={t('Include in brochure')}
              />
            </div>
          )}
          {isEditable && (
            <IconButton
              className={classNames(
                styles.o_button__secondary,
                styles.o_button__round__small,
                rcStyles.is_edit_icon,
              )}
              color="primary"
              variant="contained"
              aria-label="Close"
              onClick={() => onEditCategory(category.path)}
              data-test-id="closeModal"
            >
              <EditIcon />
            </IconButton>
          )}
        </Fragment>
      )}
    </div>
  </div>
));

SortableCategoryItem.propTypes = {
  category: PropTypes.objectOf(PropTypes.any),
  isSortable: PropTypes.bool.isRequired,
  onClickIncludeInCatalogue: PropTypes.func,
  onEditCategory: PropTypes.func.isRequired,
  isIncludible: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

export default SortableCategoryItem;
