import React, { Fragment } from 'react';
import { t } from 'i18next';
import ExpandableBanner from 'components/common/ExpandableBanner';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import LinkIcon from '@material-ui/icons/Link';
import RoomIcon from '@material-ui/icons/Room';
import IconNotes from 'icons/IconNotes';

import MenuItem from '@material-ui/core/MenuItem';
import MoreVerticalIcon from '@material-ui/icons/MoreVert';
import { Link } from 'react-router-dom';
import {
  buildAddressString,
  buildPhonesString,
  buildEmailsString,
  buildWebsitesString,
} from 'services/utils';
import { statuses } from 'constants/directoryListing';
import { directoryListingType } from 'types/directoryListing';
import { organisationalUnitType } from 'types/organisationalUnit';
import { matchType } from 'types/reactRouter';
import rcStyles from './styles.scss';

const AdminDirectoryListingListItem = ({
  item,
  match,
  anchorElement,
  onMenuOpen,
  onMenuClose,
  onStatusChange,
}) => (
  <ExpandableBanner
    key={item.name}
    headerClasses={[rcStyles.expanding_banner__header]}
    wrapperClasses={[rcStyles.expanding_banner__wrap]}
    isClosedOnLoad
    title={(
      <ListItemTitle item={item} />
    )}
    actionButton={(
      <ActionButton
        match={match}
        anchorElement={anchorElement}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        onStatusChange={onStatusChange}
        item={item}
        data-test-id="statusActionButton"
      />
    )}
  >
    <div className={rcStyles.expanded_body}>
      {item.phones && item.phones.length > 0 && (
        <Fragment>
          <PhoneIcon className={rcStyles.is_icon} />
          {buildPhonesString(item.phones)}
        </Fragment>
      )}
      {item.emails && item.emails.length > 0 && (
        <Fragment>
          <EmailIcon className={rcStyles.is_icon} />
          {buildEmailsString(item.emails)}
        </Fragment>
      )}
      {item.websites && item.websites.length > 0 && (
        <Fragment>
          <LinkIcon className={rcStyles.is_icon} />
          {buildWebsitesString(item.websites)}
        </Fragment>
      )}
      {item.address && (
        <Fragment>
          <RoomIcon className={rcStyles.is_icon} />
          {buildAddressString(item.address)}
        </Fragment>
      )}
      {item.note && (
        <Fragment>
          <IconNotes className={rcStyles.is_icon} />
          {item.note}
        </Fragment>
      )}
      {item.contacts && item.contacts.length > 0 && item.contacts.map(contact => (
        <div className={rcStyles.expanded_body}>
          <h3 className={rcStyles.is_contact_name}>{contact.name}</h3>
          {item.phones && item.phones.length > 0 && (
            <Fragment>
              <PhoneIcon className={rcStyles.is_icon} />
              {buildPhonesString(item.phones)}
            </Fragment>
          )}
          {item.emails && item.emails.length > 0 && (
            <Fragment>
              <EmailIcon className={rcStyles.is_icon} />
              {buildEmailsString(item.emails)}
            </Fragment>
          )}
        </div>
      ))}
    </div>
  </ExpandableBanner>
);

const ActionButton = ({
  match,
  anchorElement,
  onMenuOpen,
  onMenuClose,
  onStatusChange,
  item,
}) => (
  <div>
    <IconButton
      aria-controls="edit-menu"
      aria-haspopup="true"
      data-test-id="moreOptions"
      onClick={onMenuOpen}
      className={rcStyles.rc_edit_btn}
    >
      <MoreVerticalIcon />
    </IconButton>
    <Menu
      anchorEl={anchorElement}
      keepMounted
      open={!!anchorElement}
      onClose={onMenuClose}
    >
      <Link to={`${match.url}/${item.id}`}>
        <MenuItem data-test-id="edit">
          {t('Edit')}
        </MenuItem>
      </Link>
      {(item.status || item.isNew) && (
        <MenuItem
          onClick={() => onStatusChange(item)}
          data-test-id="retireOrPublish"
        >
          {item.status === statuses.PUBLISHED || item.isNew
            ? t('Retire')
            : t('Publish')}
        </MenuItem>
      )}
    </Menu>
  </div>
);

const ListItemTitle = ({ item }) => (
  <div className={rcStyles.list_item__body}>
    <div className={rcStyles.is_name}>
      {item.name}
    </div>
    {item.phones && item.phones.length > 0 && (
      <div className={rcStyles.is_phone}>
        {buildPhonesString(item.phones)}
      </div>
    )}
    {item.emails && item.emails.length > 0 && (
      <div className={rcStyles.is_email}>
        {buildEmailsString(item.emails)}
      </div>
    )}
  </div>
);

ActionButton.propTypes = {
  item: PropTypes.oneOfType([organisationalUnitType, directoryListingType]).isRequired,
  match: matchType.isRequired,
  anchorElement: PropTypes.objectOf(PropTypes.any),
  onMenuOpen: PropTypes.func.isRequired,
  onMenuClose: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func,
};

ListItemTitle.propTypes = {
  item: PropTypes.oneOfType([organisationalUnitType, directoryListingType]).isRequired,
};

AdminDirectoryListingListItem.propTypes = {
  item: PropTypes.oneOfType([organisationalUnitType, directoryListingType]).isRequired,
  match: matchType.isRequired,
  anchorElement: PropTypes.objectOf(PropTypes.any),
  onMenuOpen: PropTypes.func.isRequired,
  onMenuClose: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
};

export default AdminDirectoryListingListItem;
