import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import { arrangementType } from 'types/bereavement';
import { apolloClientType } from 'types/apollo';
import PropTypes from 'prop-types';
import { setRequestedDepositAction, setGlobalDiscountAction } from 'actions/arrangement';
import { debounceMutation } from 'services/utils';
import EstimateTotals from './EstimateTotals';
import {
  setGlobalDiscountMutation,
  setRequestedDepositMutation,
} from './mutations.gql';

class EstimateTotalsContainer extends Component {
  static propTypes = {
    bereavementId: PropTypes.string.isRequired,
    arrangement: arrangementType.isRequired,
    onOpenConfirmationWarningModal: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    setRequestedDeposit: PropTypes.func.isRequired,
    setGlobalDiscount: PropTypes.func.isRequired,
    client: apolloClientType.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSetGlobalDiscount = (globalDiscount) => {
    const { arrangement, onOpenConfirmationWarningModal } = this.props;

    if (arrangement.isConfirmed) {
      onOpenConfirmationWarningModal(() => { this.setGlobalDiscount(globalDiscount); });
      return;
    }

    this.setGlobalDiscount(globalDiscount);
  }

  setGlobalDiscount = (globalDiscount) => {
    const {
      client,
      setGlobalDiscount,
      bereavementId,
      arrangement,
    } = this.props;

    const input = {
      bereavementId,
      arrangementId: arrangement.id,
      amount: globalDiscount,
    };

    setGlobalDiscount(bereavementId, arrangement.id, globalDiscount);
    debounceMutation(client, setGlobalDiscountMutation, input);
  }

  handleSetRequestedDeposit = (requestedDeposit) => {
    const {
      client,
      arrangement,
      bereavementId,
      setRequestedDeposit,
    } = this.props;

    const input = {
      bereavementId,
      arrangementId: arrangement.id,
      amount: requestedDeposit,
    };

    setRequestedDeposit(bereavementId, arrangement.id, requestedDeposit);
    debounceMutation(client, setRequestedDepositMutation, input);
  };

  render() {
    const { arrangement, disabled } = this.props;

    return (
      <EstimateTotals
        arrangement={arrangement}
        disabled={disabled}
        onSetGlobalDiscount={this.handleSetGlobalDiscount}
        onSetRequestedDeposit={this.handleSetRequestedDeposit}
      />
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  setRequestedDeposit: setRequestedDepositAction,
  setGlobalDiscount: setGlobalDiscountAction,
}, dispatch);

export default withApollo(
  withRouter(connect(null, mapDispatchToProps)(EstimateTotalsContainer)),
);
