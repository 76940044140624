import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { withValidation } from '@funeralguide/react-form-validation-hoc';

import {
  sendUserToFuneralSafeReferralAction,
} from 'actions/bereavement';
import { Name } from 'types/ts';

import { validationSchema } from './validation';
import { Props, State } from './index.types';
import FuneralSafeReferralModal from './FuneralSafeReferralModal';

const setURLSearchParameter = (url: URL, key: string, value?: string) => {
  if (value) {
    url.searchParams.set(key, value);
  }
};

class FuneralSafeReferralModalContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { bereavedPerson, funeralTotal } = props;

    this.state = {
      formData: {
        name: bereavedPerson?.name || {},
        email: bereavedPerson?.emails?.[0] || '',
        phoneNumber: bereavedPerson?.phones?.[0]?.telephone?.number || '',
        postCode: bereavedPerson?.address?.postCode || '',
        financeAmount: {
          amount: 0,
          currency: 'GBP',
          ...funeralTotal,
        },
        hasGDPRBeenConfirmed: false,
      },
      isValidationEnabled: false,
    };
  }

  handleApplicantChange = (key: string, value: string|boolean|Name) => {
    const { validate } = this.props;
    const { formData, isValidationEnabled } = this.state;

    const updatedFormData = {
      ...formData,
      [key]: value,
    };

    if (isValidationEnabled) {
      validate(updatedFormData, validationSchema);
    }

    this.setState({ formData: updatedFormData });
  }

  handleFinanceChange = (value: number|null) => {
    const { validate } = this.props;
    const { formData, isValidationEnabled } = this.state;

    const updatedFormData = {
      ...formData,
      financeAmount: { amount: value, currency: 'GBP' },
    };

    if (isValidationEnabled) {
      validate(updatedFormData, validationSchema);
    }

    this.setState({ formData: updatedFormData });
  }

  handleNext = () => {
    const {
      bereavementId,
      arrangementId,
      onClose,
      sendUserToFuneralSafeReferral,
      validate,
    } = this.props;
    const { formData } = this.state;

    this.setState({ isValidationEnabled: true });

    if (!validate(formData, validationSchema)) {
      return;
    }

    if (process.env.FUNERAL_SAFE_REFERRAL_URL) {
      const referralUrl = new URL(process.env.FUNERAL_SAFE_REFERRAL_URL);
      const searchParams = {
        title: formData?.name?.title,
        firstname: formData?.name?.givenName,
        middlename: formData?.name?.middleName,
        lastname: formData?.name?.familyName,
        email: formData?.email,
        mobile: formData?.phoneNumber,
        postcode: formData?.postCode,
        application_amount: formData?.financeAmount.amount,
      };

      (Object.keys(searchParams) as Array<keyof typeof searchParams>).forEach((key) => {
        const value = searchParams[key] as string|undefined;
        setURLSearchParameter(referralUrl, key, value);
      });

      const uri = referralUrl.toString();
      const openWindow = window.open(uri, uri);
      if (openWindow) {
        openWindow.focus();
      }

      const loanAmount = {
        amount: formData?.financeAmount.amount,
        currency: 'GBP',
      };

      sendUserToFuneralSafeReferral(bereavementId, arrangementId, formData.hasGDPRBeenConfirmed, loanAmount);
    }

    onClose();
  }

  render() {
    const {
      funeralTotal, isOpen, errors, onClose,
    } = this.props;
    const { formData } = this.state;

    return (
      <FuneralSafeReferralModal
        formData={formData}
        funeralTotal={funeralTotal}
        isOpen={isOpen}
        errors={errors}
        onApplicantChange={this.handleApplicantChange}
        onFinanceChange={this.handleFinanceChange}
        onClose={onClose}
        onNext={this.handleNext}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  sendUserToFuneralSafeReferral: sendUserToFuneralSafeReferralAction,
}, dispatch);

export default withValidation(
  connect(null, mapDispatchToProps)(FuneralSafeReferralModalContainer),
);
