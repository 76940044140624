import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { setCategoryInformationAction } from 'actions/arrangement';
import { fetchBereavementAction } from 'actions/bereavement';
import {
  clearAllAction, fetchArrangementCatalogueItemsAction,
} from 'actions/catalogue';
import { venueCategoryPaths } from 'constants/arrangement';
import { emptyBereavement } from 'constants/bereavement';
import { scopes as permissions } from 'constants/scopes';
import { focusNewSelection, hasPermissions } from 'services/utils';
import { getAvailableNavigation } from 'services/utils/navigation';
import { bereavementType, productType, serviceType } from 'types/bereavement';
import { matchType, locationType } from 'types/reactRouter';

import ArrangementScreen from './ArrangementScreen';

class ArrangementScreenContainer extends Component {
  static propTypes = {
    bereavementId: PropTypes.string,
    bereavements: PropTypes.arrayOf(bereavementType).isRequired,
    products: PropTypes.arrayOf(productType).isRequired,
    services: PropTypes.arrayOf(serviceType).isRequired,
    disabled: PropTypes.bool.isRequired,
    availableFeatures: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    tenantCategories: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    arrangementCategoryDisplayPreferences: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
    displayCategoryInformation: PropTypes.bool.isRequired,
    displayAppbar: PropTypes.bool,
    isOnline: PropTypes.bool.isRequired,
    scopes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    match: matchType.isRequired,
    location: locationType.isRequired,
    fetchBereavement: PropTypes.func.isRequired,
    fetchCatalogueItems: PropTypes.func.isRequired,
    setCategoryInformation: PropTypes.func,
    clearCatalogue: PropTypes.func.isRequired,
  }

  static getDerivedStateFromProps(props) {
    const {
      bereavements, bereavementId, match, fetchBereavement,
    } = props;
    const currentBereavementId = bereavementId || match.params.bereavementId;
    const bereavement = bereavements.find(existing => existing.id === currentBereavementId);

    if (!bereavement) {
      fetchBereavement(currentBereavementId);
    }

    return {
      bereavement: bereavement || emptyBereavement,
      isLoading: !bereavement,
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      bereavement: null,
      isLoading: true,
    };

    this.expandBannerRef = React.createRef();
    this.selectedItemRef = React.createRef();
  }


  componentDidMount() {
    const {
      fetchCatalogueItems,
      products,
      services,
      isOnline,
      scopes,
    } = this.props;

    if (isOnline && !products.length && !services.length) {
      if (hasPermissions([permissions.PRODUCTS_READ], scopes)) {
        fetchCatalogueItems('products');
      }
      if (hasPermissions([permissions.SERVICES_READ], scopes)) {
        fetchCatalogueItems('services');
      }
    }
  }

  componentWillUnmount() {
    const { clearCatalogue } = this.props;
    clearCatalogue();
  }

  getPathsToDisplay = (arrangement) => {
    const {
      match,
      location,
      availableFeatures,
      tenantCategories,
      arrangementCategoryDisplayPreferences,
    } = this.props;

    const navigationPaths = getAvailableNavigation(
      availableFeatures,
      tenantCategories,
      arrangementCategoryDisplayPreferences,
      arrangement?.committalType,
      true,
    );
    const currentPath = location.pathname.replace(`${match.url}/`, '');

    if (Object.values(venueCategoryPaths).includes(currentPath)) {
      return navigationPaths.filter(navigationPath => (
        navigationPath.path === currentPath || !navigationPath.isVenueScreen
      ));
    }

    return navigationPaths.filter(navigationPath => (
      navigationPath.path === Object.values(venueCategoryPaths)[0] || !navigationPath.isVenueScreen
    ));
  };

  render() {
    const { match, setCategoryInformation } = this.props;
    const { bereavement, isLoading } = this.state;
    const { arrangementId } = match.params;

    const selectedArrangement = arrangementId
      && bereavement.arrangements
      ? bereavement.arrangements
        .find(arrangement => arrangement.id === arrangementId)
      : bereavement.arrangements[0];

    return (
      <ArrangementScreen
        {...this.props}
        bereavement={bereavement}
        arrangement={selectedArrangement}
        isLoading={isLoading}
        navigationPaths={selectedArrangement ? this.getPathsToDisplay(selectedArrangement) : []}
        expandBannerRef={this.expandBannerRef}
        selectedItemRef={this.selectedItemRef}
        onSave={setCategoryInformation}
        focusNewSelection={() => focusNewSelection(this.expandBannerRef, this.selectedItemRef)}
      />
    );
  }
}

const mapStateToProps = state => ({
  bereavements: state.bereavementStore.bereavements,
  isLoadingCatalogueItems: state.catalogueStore.products.isLoading
    || state.catalogueStore.services.isLoading,
  products: state.catalogueStore.products.items,
  services: state.catalogueStore.services.items,
  availableFeatures: state.userStore.user.tenantFeatureFlags,
  tenantCategories: state.userStore.user.tenantCategories,
  arrangementCategoryDisplayPreferences: state.userStore.user.arrangementCategoryDisplayPreferences,
  isOnline: state.userStore.isOnline,
  scopes: state.userStore.user.policy.scopes,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchBereavement: fetchBereavementAction,
  setCategoryInformation: setCategoryInformationAction,
  fetchCatalogueItems: fetchArrangementCatalogueItemsAction,
  clearCatalogue: clearAllAction,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ArrangementScreenContainer));
