import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { debounce } from 'debounce';
import PropTypes from 'prop-types';
import { removePropertyByName } from 'services/utils';
import { apolloClientType } from 'types/apollo';
import CharityJustGivingForm from './CharityJustGivingForm';
import { getCharities } from './queries.gql';

class CharityJustGivingFormContainer extends Component {
  debounceChange = debounce((value) => {
    if (value) {
      this.getSuggestions(value);
    } else {
      this.setState({ suggestions: [] });
    }
  }, 500);

  static propTypes = {
    client: apolloClientType.isRequired,
    formData: PropTypes.objectOf(PropTypes.any).isRequired,
    disabled: PropTypes.bool.isRequired,
    errors: PropTypes.objectOf(PropTypes.any),
    onChange: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      suggestions: [],
      departments: [],
      lookUpValue: '',
      isLoading: false,
      isLoadingCharity: false,
      isLookUpTouched: false,
      isOtherDepartmentSelected: false,
    };
  }

  componentDidMount() {
    const { formData } = this.props;

    if (formData.name) {
      this.setState({
        lookUpValue: formData.name,
        departments: [],
        isOtherDepartmentSelected: formData.department && formData.department.id === 'OTHER',
      });
    }

    if (formData.id) {
      const updateDepartments = (suggestions) => {
        const charity = suggestions.find(suggestion => suggestion?.data?.id === formData.id);
        const departments = charity?.data?.departments.map(department => department) || [];
        this.setState({
          departments,
          isLoadingCharity: false,
        });
      };

      this.setState({ isLoadingCharity: true });
      this.getSuggestions(formData.name, updateDepartments);
    }
  }

  getSuggestions = (searchText, callback) => {
    const { client } = this.props;

    client.query({
      query: getCharities,
      variables: {
        term: searchText,
        limit: 500,
      },
    }).then((data) => {
      const suggestions = data.data.charities
        .map(charity => ({
          data: removePropertyByName(charity, '__typename'),
          value: charity.name,
        }))
        .filter(suggestion => suggestion.value);

      this.setState({ suggestions });

      if (callback) {
        callback(suggestions);
      }
    }).finally(() => {
      this.setState({ isLoading: false });
    });
  }

  handleLookUpChange = (value, changeValue) => {
    const { isLookUpTouched } = this.state;
    const newState = { isLookUpTouched: true, isLoading: true };
    newState.lookUpValue = isLookUpTouched ? value : changeValue;
    if (!isLookUpTouched) {
      this.handleChangeCharity({ name: '', departments: [] });
    }

    this.setState(newState);
    return this.debounceChange(value);
  };

  handleChangeCharity = (charity) => {
    const { formData, onChange } = this.props;

    this.setState({
      isLookUpTouched: false,
      departments: charity.departments.map(department => department),
      lookUpValue: charity.name,
    });

    onChange({
      ...formData,
      id: charity.id,
      name: charity.name,
      description: charity.description,
      type: charity.type,
      imageUrl: charity.imageUrl,
    });
  }

  handleChangeDepartment = (departmentId) => {
    const { formData, onChange } = this.props;
    const { departments } = this.state;
    const allDepartments = [
      ...(departments || []),
      ...(formData.department && formData.department.id === 'OTHER' ? [formData.department] : []),
    ];

    if (!departmentId) {
      this.setState({
        isOtherDepartmentSelected: false,
      });
      onChange({
        ...formData,
        department: null,
      });
      return;
    }

    const isOtherDepartmentSelected = departmentId === 'OTHER';

    this.setState({ isOtherDepartmentSelected });

    const doesDepartmentExist = allDepartments
      .map(department => department.id)
      .indexOf(departmentId) > -1;

    const selectedDepartment = !doesDepartmentExist
      ? { id: departmentId, name: null, otherName: null }
      : allDepartments.find(department => department.id === departmentId);

    onChange({
      ...formData,
      department: selectedDepartment,
    });
  }

  handleChangeDepartmentOtherName = (value) => {
    const { formData, onChange } = this.props;
    const { department } = formData;

    const updatedDepartment = {
      ...department,
      otherName: value,
    };

    onChange({
      ...formData,
      department: updatedDepartment,
    });
  }

  render() {
    const { formData, disabled, errors } = this.props;

    return (
      <CharityJustGivingForm
        formData={formData}
        disabled={disabled}
        errors={errors}
        onChangeCharity={this.handleChangeCharity}
        onChangeDepartment={this.handleChangeDepartment}
        onChangeDepartmentOtherName={this.handleChangeDepartmentOtherName}
        onLookUpChange={this.handleLookUpChange}
        {...this.state}
      />
    );
  }
}

export default withApollo(CharityJustGivingFormContainer);
