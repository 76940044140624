import React from 'react';
import i18next from 'i18next';
import Button from '@material-ui/core/Button';

import styles from 'scss/main.scss';

import FuneralSafeIndicativeCostsTable from 'components/estimate/FuneralSafeIndicativeCostsTable';
import rcStyles from './styles.scss';
import { Props } from './index.types';

const FuneralSafePanel: React.FC<Props> = ({
  toggleEligibilityCheckModal,
  toggleReferralModal,
  financeAmount,
}: Props) => (
  <div className={rcStyles.panel}>
    <div className={rcStyles.panel__header}>
      <h2 className={rcStyles.is_title}>
        {i18next.t('Spread the cost with Funeral Safe')}
      </h2>
      <img src="/img/funeral_safe/logo.png" alt="Funeral Safe logo" className={rcStyles.is_logo} />
    </div>
    <div className={rcStyles.panel__actions}>
      <Button
        onClick={toggleEligibilityCheckModal}
        variant="outlined"
        color="primary"
        data-test-id="checkEligibilityBtn"
      >
        {i18next.t('Check eligibility')}
      </Button>
      <Button
        onClick={toggleReferralModal}
        variant="outlined"
        color="primary"
        data-test-id="submitReferralBtn"
      >
        {i18next.t('Submit referral')}
      </Button>
    </div>
    <FuneralSafeIndicativeCostsTable financeAmount={financeAmount} />
    <p className={styles.minion}>
      Explanations: Funeral Safe Limited provides finance from £500 – £7,500 from 15.9% APR over terms of 12 – 60
      months. EXAMPLE: Loan amount of £2500.00 with an arrangement fee of £25 and a monthly repayment of £227.45 over
      a term of 12 months at a rate of interest per annum of 10.20% Fixed. This is equivalent to 19.9% APR
      representative. Total amount repayable is £2754.50. Your personal circumstances affect the rate we can offer you
      which may differ from the representative APR. We will do a credit assessment of your circumstances when you
      apply.
    </p>
  </div>
);

export default FuneralSafePanel;
