import React from 'react';
import { t } from 'i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import { discountTypes } from 'constants/arrangement';
import { defaultMoneyObject, getFormattedDate } from 'services/utils';
import { bereavementType } from 'types/bereavement';
import Price from 'components/common/Price';

import styles from 'scss/main.scss';
import { getLastFinanceRequestedArrangement } from 'services/utils/arrangement';
import rcStyles from './styles.scss';

const AccountCaseSummary = ({
  bereavement,
  onViewCase,
}) => {
  const confirmedArrangement = (bereavement && bereavement.arrangements
    && bereavement.arrangements.find(item => item.isConfirmed)) || {};
  const discountType = confirmedArrangement && confirmedArrangement.discountType;
  const isGlobalDiscount = discountType === discountTypes.GLOBAL;
  const calculation = confirmedArrangement && confirmedArrangement.calculation;
  const totalWithoutDiscounts = calculation && calculation.totalWithoutDiscounts;
  const finalTotal = (calculation && calculation.finalTotal) || defaultMoneyObject(0);

  const { loanAmount, funeralSafeReferralDate } = getLastFinanceRequestedArrangement(bereavement.arrangements) || {};

  return (
    <div className={classNames(
      styles.o_block_content,
      rcStyles.summary,
    )}
    >
      <h2 className={rcStyles.is_title}>{t('Funeral:')}</h2>
      <div>
        <div data-test-id={isGlobalDiscount ? 'discountedTotalLabel' : 'totalLabel'}>
          {isGlobalDiscount ? t('Discounted total:') : t('Total:')}
        </div>
        <div data-test-id={isGlobalDiscount ? 'discountedTotal' : 'total'}>
          <Price
            price={finalTotal}
            customClass={classNames(
              styles.c_price_label,
              styles.is_black,
              styles.c_price_label__regular,
              styles.is_bold,
              styles.has_white_background,
            )}
          />
        </div>
      </div>
      {isGlobalDiscount && (
        <div>
          <div data-test-id="totalBeforeDiscountLabel">
            {t('Total before discount:')}
          </div>
          <div data-test-id="totalBeforeDiscount">
            <Price
              price={totalWithoutDiscounts}
              customClass={classNames(
                styles.c_price_label,
                styles.is_black,
                styles.c_price_label__regular,
                styles.is_bold,
                styles.has_white_background,
                styles.c_strike_through,
              )}
            />
          </div>
        </div>
      )}
      {funeralSafeReferralDate && (
        <div className={rcStyles.summary__finance}>
          <div>
            {t('Finance:')}
          </div>
          <div>
            <Price
              price={loanAmount}
              customClass={classNames(
                styles.c_price_label,
                styles.c_price_label__regular,
                styles.fixed_height,
                styles.is_bold,
                styles.is_black,
              )}
            />
          </div>
          <div className={rcStyles.summary__finance__applied_date}>
            <span>{`${t('Applied')} `}</span>
            <span>{getFormattedDate(confirmedArrangement.funeralSafeReferralDate)}</span>
          </div>
        </div>
      )}
      <div className={rcStyles.summary__actions}>
        <Button
          onClick={onViewCase}
          color="primary"
          variant="outlined"
          data-test-id="viewCase"
        >
          {t('View Funeral')}
        </Button>
      </div>
    </div>
  );
};

AccountCaseSummary.propTypes = {
  bereavement: bereavementType,
  onViewCase: PropTypes.func.isRequired,
};

export default AccountCaseSummary;
