import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createdByType } from 'types/bereavement';
import { staffMemberType } from 'types/staffMember';
import moment from 'moment';
import Note from './Note';

const emptyNote = {
  id: null,
  createdBy: null,
  content: '',
  createdDateTime: '',
};

class NoteContainer extends Component {
  static propTypes = {
    fieldData: PropTypes.shape({
      id: PropTypes.string.isRequired,
      createdBy: createdByType,
      content: PropTypes.string.isRequired,
      createdDateTime: PropTypes.string,
    }),
    disabled: PropTypes.bool,
    currentStaff: staffMemberType,
    errors: PropTypes.objectOf(PropTypes.any),
    onChange: PropTypes.func,
  }

  constructor(props) {
    super(props);
    this.noteRef = React.createRef();
  }

  componentDidMount() {
    const { fieldData, currentStaff, onChange } = this.props;

    if (!fieldData) {
      onChange({
        id: null,
        content: '',
        createdBy: currentStaff,
        createdDateTime: moment().format(),
      });
      this.noteRef.current.focus();
    }
  }

  onChange = (value) => {
    const { fieldData, onChange } = this.props;
    onChange({ ...fieldData, content: value });
  }

  render() {
    const { fieldData, errors, disabled } = this.props;
    let noteTitle = null;

    if (fieldData && fieldData.createdBy) {
      const { givenName, familyName } = fieldData.createdBy.name;
      noteTitle = `${givenName} ${familyName} - ${moment(fieldData.createdDateTime).fromNow()}`;
    }

    return (
      <Note
        noteRef={this.noteRef}
        fieldData={fieldData || emptyNote}
        noteTitle={noteTitle}
        disabled={disabled}
        errors={errors}
        onChange={this.onChange}
      />
    );
  }
}

export default NoteContainer;
