import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SupportFooter from 'components/support/SupportFooter';
import IconLoad from 'icons/IconLoad';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const ResetPasswordRequestScreen = ({
  email,
  isSubmitting,
  isSubmitted,
  errors,
  emailRef,
  onEmailChange,
  onSubmit,
}) => (
  <div
    className={classNames(
      styles.c_page__full_height,
      styles.has_faded_background,
    )}
  >
    <div className={styles.o_view}>
      <div className={styles.o_view__main}>
        <div className={rcStyles.logo}>
          <img src="/img/arranger-logo-hor-purple.svg" alt="Arranger" />
        </div>
        {isSubmitted ? (
          <div className={styles.u_text__center}>
            {t('Password reset link sent.')}
          </div>
        ) : (
          <form onSubmit={onSubmit}>
            <div className={rcStyles.v_spacing}>
              <p className={styles.primer}>
                {t('Please type in your email address. We\'ll send you a link to change the password. The link will be active for 24 hours.')}
              </p>
              <TextField
                label={t('Email address')}
                data-test-id="email"
                InputProps={{
                  'inputRef': emailRef,
                }}
                value={email}
                onChange={event => onEmailChange(event.target.value)}
                error={!!errors.email}
                helperText={errors.email}
                fullWidth
              />
              <div className={styles.brevier}>
                {t('If you do not receive the email please contact your Arranger administrator.')}
              </div>
              {isSubmitting && (
                <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
                  <IconLoad title={t('Submitting')} />
                </div>
              )}
              <div className={styles.u_text__center}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onSubmit}
                  data-test-id="resetPassword"
                  disabled={isSubmitting}
                  fullWidth
                >
                  {t('Reset Password')}
                </Button>
              </div>
              <Button
                component={Link}
                to="/login"
                color="primary"
                variant="text"
                data-test-id="returnToLogin"
                disabled={isSubmitting}
              >
                {t('Return to Login')}
              </Button>
            </div>
          </form>
        )}
      </div>
    </div>
    <SupportFooter />
  </div>
);

ResetPasswordRequestScreen.propTypes = {
  email: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  emailRef: PropTypes.objectOf(PropTypes.any).isRequired,
  onEmailChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ResetPasswordRequestScreen;
