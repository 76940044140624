import React from 'react';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';

import { Props } from './index.types';

const PortalScreenHelmet: React.FC<Props> = ({ title, description }: Props) => (
  <Helmet>
    <title>{i18next.t(title)}</title>
    <meta name="description" content={i18next.t(description) as string} />
    <meta name="robots" content="noindex,nofollow" />
    <style type="text/css">
      {`
        @font-face {
          font-family: "IBMPlexSans";
          src: url("/fonts/ibm_plex_sans/IBMPlexSans.woff2") format("woff2"),
            url("/fonts/ibm_plex_sans/IBMPlexSans.woff") format("woff"),
            url("/fonts/ibm_plex_sans/IBMPlexSans.eot") format("opentype");
          font-display: auto;
          font-style: normal;
          font-weight: 400;
        }

        @font-face {
          font-family: "IBMPlexSans";
          src: url("/fonts/ibm_plex_sans/IBMPlexSans-Bold.woff2") format("woff2"),
            url("/fonts/ibm_plex_sans/IBMPlexSans-Bold.woff") format("woff"),
            url("/fonts/ibm_plex_sans/IBMPlexSans-Bold.eot") format("opentype");
          font-display: auto;
          font-style: normal;
          font-weight: 700;
        }

        @font-face {
          font-family: "IBMPlexSans";
          src: url("/fonts/ibm_plex_sans/IBMPlexSans-Italic.woff2") format("woff2"),
            url("/fonts/ibm_plex_sans/IBMPlexSans-Italic.woff") format("woff"),
            url("/fonts/ibm_plex_sans/IBMPlexSans-Italic.eot") format("opentype");
          font-display: auto;
          font-style: italic;
          font-weight: 400;
        }

        body,
        #root {
          -webkit-font-smoothing: antialiased;
          /*  nasty fix to override a mui 'feature' that adds 15px padding to
            *  the body when opening a select list
            *  forcing this rule to override it
            */
          padding-right: 0 !important;
          margin: 0;
          padding: 0;
          color: #515551;
          font-family: 'IBMPlexSans', sans-serif;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
          margin: 0;
        }

        hr {
          border: 0;
          height: 1px;
          background: hsla(269, 21%, 95%, 1);
          margin: 2rem 0;
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0;
        }

        a {
          text-decoration: none;
          outline: none;
        }
      `}
    </style>
  </Helmet>
);

export default PortalScreenHelmet;
