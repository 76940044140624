import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hasPermissions } from 'services/utils';
import AppBar from './AppBar';

class AppBarContainer extends Component {
  static propTypes = {
    scopes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    availableFeatures: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    currentBereavementId: PropTypes.string,
  }

  constructor(props) {
    super(props);
    this.state = {
      anchorElement: null,
      isMenuOpen: false,
      openCalendar: false,
    };
  }

  handleMenuOpen = (event) => {
    this.setState({
      anchorElement: event.target,
      isMenuOpen: true,
    });
  }

  handleMenuClose = () => {
    this.setState({
      anchorElement: null,
      isMenuOpen: false,
    });
  }

  handleToggleCalendar = () => {
    const { openCalendar } = this.state;
    this.setState({ openCalendar: !openCalendar });
  }

  hasPermissions = (permissions) => {
    const { scopes } = this.props;
    return hasPermissions(permissions, scopes);
  }

  render() {
    const { currentBereavementId, availableFeatures } = this.props;
    const { anchorElement, isMenuOpen, openCalendar } = this.state;
    return (
      <AppBar
        anchorElement={anchorElement}
        isMenuOpen={isMenuOpen}
        isCalendarOpen={openCalendar}
        availableFeatures={availableFeatures}
        currentBereavementId={currentBereavementId}
        handleMenuOpen={this.handleMenuOpen}
        handleMenuClose={this.handleMenuClose}
        onToggleCalendar={this.handleToggleCalendar}
        hasPermissions={this.hasPermissions}
      />
    );
  }
}

const mapStateToProps = state => ({
  scopes: state.userStore.user.policy && state.userStore.user.policy.scopes,
  availableFeatures: state.userStore.user.tenantFeatureFlags,
});

export default connect(mapStateToProps)(AppBarContainer);
