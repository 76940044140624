import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PhoneFormSection from './PhoneFormSection';

const defaultFormData = {
  telephone: {
    countryCode: 'GB',
    number: '',
  },
  type: 'WORK',
};

class PhoneFormSectionContainer extends Component {
  static propTypes = {
    fieldData: PropTypes.objectOf(PropTypes.any),
    errors: PropTypes.objectOf(PropTypes.any),
    onChange: PropTypes.func,
    hasMargin: PropTypes.bool,
  }

  constructor(props) {
    super(props);

    this.state = {};
  }

  handleOnChangeTelephone = (key, value) => {
    const { onChange, fieldData } = this.props;
    if (!onChange) {
      return;
    }
    const newFormData = fieldData || defaultFormData;

    const changed = {
      ...newFormData,
      telephone: {
        ...newFormData.telephone,
        [key]: value,
      },
    };

    onChange(changed);
  };

  handleOnChange = (key, value) => {
    const { onChange, fieldData } = this.props;
    if (!onChange) {
      return;
    }
    const newFormData = fieldData || defaultFormData;

    const changed = {
      ...newFormData,
      [key]: value,
    };

    onChange(changed);
  };

  render() {
    const { fieldData, errors, hasMargin } = this.props;
    return (
      <PhoneFormSection
        formData={fieldData || defaultFormData}
        errors={errors}
        hasMargin={hasMargin}
        handleOnChangeTelephone={this.handleOnChangeTelephone}
        handleOnChange={this.handleOnChange}
      />
    );
  }
}

export default PhoneFormSectionContainer;
