import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import WarningIcon from '@material-ui/icons/Warning';

import IconDuplicate from 'icons/IconDuplicate';
import IconLoad from 'icons/IconLoad';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const ConfirmationModal = ({
  title,
  message,
  isOpen,
  isAsyncConfirming,
  icon,
  confirmLabel,
  onConfirm,
  onCancel,
  testIds = {},
}) => {
  let iconComponent;
  if (icon?.toLowerCase() === 'warning') {
    iconComponent = (<WarningIcon className={rcStyles.rc_warning} />);
  } else if (icon?.toLowerCase() === 'duplicate') {
    iconComponent = (<IconDuplicate />);
  }

  return (
    <Dialog
      onClose={onCancel}
      aria-labelledby="confirmation-dialog"
      fullWidth
      maxWidth="sm"
      open={isOpen}
      TransitionComponent={Transition}
      data-test-id={testIds.dialog || 'dialog'}
      disableBackdropClick
      disableEscapeKeyDown
    >
      {title && (
        <DialogTitle disableTypography className={styles.c_dialog__header}>
          <div
            className={classNames(styles.c_dialog__title)}
            data-test-id={testIds.title || 'title'}
          >
            {t(title)}
          </div>
        </DialogTitle>
      )}
      <DialogContent
        className={styles.c_dialog__content}
        data-test-id={testIds.message || 'message'}
      >
        <div className={rcStyles.flex}>
          {iconComponent}
          <div>
            {message && message.split('\n').map((line, index) => (
              <Fragment key={line}>
                <p>{t(line)}</p>
                {index < message.split('\n').length - 1 && (<br />)}
              </Fragment>
            ))}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        {onCancel && (
          <Button
            onClick={onCancel}
            variant="outlined"
            color="primary"
            data-test-id={testIds.cancel || 'cancel'}
            fullWidth
          >
            {t('Cancel')}
          </Button>
        )}
        <Button
          onClick={() => { if (isAsyncConfirming) return; onConfirm(); }}
          variant="contained"
          color="primary"
          data-test-id={testIds.confirm || 'confirm'}
          fullWidth
        >
          {isAsyncConfirming
            ? <IconLoad className={styles.c_btn_loading} title={t('Confirming')} />
            : confirmLabel || t('Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  confirmLabel: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  isAsyncConfirming: PropTypes.bool,
  icon: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  testIds: PropTypes.objectOf(PropTypes.any),
};

export default ConfirmationModal;
