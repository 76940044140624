import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import { setBereavementAction, setDeceasedPersonAction } from 'actions/bereavement';
import { editBereavementTransform, editDeceasedTransform, editDeceasedCareRecordTransform } from 'transforms/bereavement';
import { bereavementType } from 'types/bereavement';
import { apolloClientType } from 'types/apollo';
import { matchType, locationType } from 'types/reactRouter';
import CaseDeceasedDetails from './CaseDeceasedDetails';
import { editBereavementMutation, editDeceasedPerson } from './mutations.gql';

class CaseDeceasedDetailsContainer extends Component {
  static propTypes = {
    bereavement: bereavementType.isRequired,
    client: apolloClientType.isRequired,
    setBereavement: PropTypes.func.isRequired,
    setDeceasedPerson: PropTypes.func.isRequired,
    match: matchType.isRequired,
    location: locationType.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isDeceasedFormSaving: false,
      isCareWishesFormSaving: false,
      isCareRecordBodyFormSaving: false,
      activeTab: props.location.pathname,
    };
  }

  handleChangeTab = activeTab => this.setState({ activeTab });

  handleDeceasedFormSave = (deceasedPerson) => {
    const { bereavement, client, setDeceasedPerson } = this.props;

    this.setState({
      isDeceasedFormSaving: true,
    });

    setDeceasedPerson(bereavement.id, deceasedPerson);
    const input = editDeceasedTransform(deceasedPerson, bereavement.id);
    client.mutate({
      mutation: editDeceasedPerson,
      variables: { input },
    }).finally(() => {
      this.setState({
        isDeceasedFormSaving: false,
      });
    });
  }

  handleCareWishesFormSave = (bereavement) => {
    const { client, setBereavement } = this.props;

    this.setState({
      isCareWishesFormSaving: true,
    });

    setBereavement(bereavement);
    const input = editBereavementTransform(bereavement);
    client.mutate({
      mutation: editBereavementMutation,
      variables: { input },
    }).then(() => {
      this.handleDeceasedFormSave(bereavement.deceasedPeople[0]);
    }).finally(() => {
      this.setState({
        isCareWishesFormSaving: false,
      });
    });
  }

  handleCareRecordBodyFormSave = (viewingNotAdvised, reasonForViewingNotAdvised, firstOffices, mortuary, transfers) => {
    const { client, bereavement, setDeceasedPerson } = this.props;
    const deceasedPerson = bereavement.deceasedPeople[0];

    this.setState({
      isCareRecordBodyFormSaving: true,
    });

    const updatedDeceasedPerson = editDeceasedCareRecordTransform(
      deceasedPerson, viewingNotAdvised, reasonForViewingNotAdvised, firstOffices, mortuary, transfers,
    );
    setDeceasedPerson(bereavement.id, updatedDeceasedPerson);
    const input = editDeceasedTransform(updatedDeceasedPerson, bereavement.id);
    client.mutate({
      mutation: editDeceasedPerson,
      variables: { input },
    }).finally(() => {
      this.setState({
        isCareRecordBodyFormSaving: false,
      });
    });
  };

  render() {
    const { bereavement, match } = this.props;
    const {
      isDeceasedFormSaving,
      isCareWishesFormSaving,
      isCareRecordBodyFormSaving,
      activeTab,
    } = this.state;

    return (
      <CaseDeceasedDetails
        bereavement={bereavement}
        match={match}
        activeTab={activeTab}
        onChangeTab={this.handleChangeTab}
        isDeceasedFormSaving={isDeceasedFormSaving}
        isCareWishesFormSaving={isCareWishesFormSaving}
        isCareRecordBodyFormSaving={isCareRecordBodyFormSaving}
        onDeceasedFormSave={this.handleDeceasedFormSave}
        onCareWishesFormSave={this.handleCareWishesFormSave}
        onCareRecordBodyFormSave={this.handleCareRecordBodyFormSave}
      />
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  setBereavement: setBereavementAction,
  setDeceasedPerson: setDeceasedPersonAction,
}, dispatch);

export default withRouter(withApollo(connect(null, mapDispatchToProps)(CaseDeceasedDetailsContainer)));
