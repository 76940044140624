import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import moment from 'moment';
import md5 from 'md5';
import Button from '@material-ui/core/Button';
import PrintIcon from '@material-ui/icons/Print';
import Tooltip from '@material-ui/core/Tooltip';
import ActionButton from 'components/common/ActionButton';
import { featureFlags } from 'constants/features';
import IconLoadSml from 'icons/IconLoadSml';
import { arrangementType, bereavementType } from 'types/bereavement';
import ConfirmationModal from 'components/common/ConfirmationModal';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const EstimateHeader = ({
  bereavement,
  arrangement,
  availableFeatures,
  onPrintEstimate,
  onPrintService,
  onSendEstimateEmail,
  onSendServiceDetailsEmail,
  isPrintEstimateLoading,
  isPrintServiceLoading,
  disabled,
  isOnline,
  confirmationModal,
}) => {
  const primaryContact = bereavement.bereavedPeopleConnections
    && bereavement.bereavedPeopleConnections.find(connection => connection.isPrimaryContact);
  const billPayer = bereavement.bereavedPeopleConnections
    && bereavement.bereavedPeopleConnections.find(connection => connection.isBillPayer);
  const hasPrimaryContactEmail = primaryContact && primaryContact.bereavedPerson.emails.length;
  const hasBillPayerEmail = billPayer && billPayer.bereavedPerson.emails.length;
  const disableServiceEmail = !primaryContact
    || !hasPrimaryContactEmail;
  const disableEstimateEmail = !billPayer
    || !hasBillPayerEmail;
  const estimateId = md5(`estimate/${arrangement.id}`);

  return (
    <div className={rcStyles.v_spacing}>
      <h1 className={classNames(
        styles.pica,
        styles.is_black,
      )}
      >
        {t('Estimate')}
      </h1>
      <div className={rcStyles.header_details}>
        <div>
          <div className={rcStyles.estimate_details}>
            <div className={styles.u_push__right}>
              <h3 className={styles.brevier}>
                {t('Creation: ')}
              </h3>
              <p>{moment(arrangement.timeCreated).format(t('DD/MMM/YYYY HH:mm'))}</p>
            </div>
            <div>
              <h3 className={styles.brevier}>
                {t('Reference: ')}
              </h3>
              <p>
                {arrangement.reference}
              </p>
            </div>
          </div>
        </div>
        <div className={rcStyles.colspan2}>
          <div className={rcStyles.action_buttons}>
            <Tooltip
              title={(
                <React.Fragment>
                  <div className={rcStyles.tooltip_text}>{t('Primary bill payer must include an email address and have \'Automatically send emails\' checked')}</div>
                </React.Fragment>
              )}
              enterTouchDelay={1}
              leaveTouchDelay={3000}
              disableFocusListener={!disableEstimateEmail}
              disableHoverListener={!disableEstimateEmail}
              disableTouchListener={!disableEstimateEmail}
            >
              <ActionButton
                onClick={onSendEstimateEmail}
                disabled={disabled || disableEstimateEmail}
                text={t('Email estimate')}
                data-test-id="emailEstimate"
              />
            </Tooltip>
            {availableFeatures.includes(featureFlags.ESTIMATE_SERVICE_EMAIL) && (
              <Tooltip
                title={(
                  <React.Fragment>
                    <div className={rcStyles.tooltip_text}>{t('Primary contact must include an email address and have \'Automatically send emails\' checked')}</div>
                  </React.Fragment>
                )}
                enterTouchDelay={1}
                leaveTouchDelay={3000}
                disableFocusListener={!disableServiceEmail}
                disableHoverListener={!disableServiceEmail}
                disableTouchListener={!disableServiceEmail}
              >
                <ActionButton
                  onClick={onSendServiceDetailsEmail}
                  disabled={disabled || disableServiceEmail}
                  text={t('Email service')}
                  data-test-id="emailService"
                />
              </Tooltip>
            )}
            <Tooltip
              title={(
                <React.Fragment>
                  <div className={rcStyles.tooltip_text}>{t('Estimates can only be printed when online')}</div>
                </React.Fragment>
              )}
              enterTouchDelay={1}
              leaveTouchDelay={3000}
              disableFocusListener={isOnline}
              disableHoverListener={isOnline}
              disableTouchListener={isOnline}
            >
              <Button
                color="secondary"
                variant="contained"
                size="small"
                className={classNames(styles.o_button__white)}
                onClick={onPrintEstimate}
                data-test-id="print_estimate"
                data-estimate-id={estimateId}
                disabled={!isOnline}
              >
                {isPrintEstimateLoading ? (
                  <div
                    className={classNames(
                      styles.c_svg_loader_sml,
                      styles.c_svg_loader_sml_btn,
                    )}
                    data-test-id="buttonLoader"
                  >
                    <IconLoadSml />
                  </div>
                ) : (
                  <Fragment>
                    <PrintIcon className={styles.u_push__right_half} />
                    {t('Print Estimate')}
                  </Fragment>
                )}
              </Button>
            </Tooltip>
            {availableFeatures.includes(featureFlags.ESTIMATE_PRINT_SERVICE) && (
              <Button
                color="secondary"
                variant="contained"
                size="small"
                className={classNames(styles.o_button__white)}
                onClick={onPrintService}
                data-test-id="print_service"
              >
                {isPrintServiceLoading ? (
                  <div
                    className={classNames(
                      styles.c_svg_loader_sml,
                      styles.c_svg_loader_sml_btn,
                    )}
                    data-test-id="buttonLoader"
                  >
                    <IconLoadSml />
                  </div>
                ) : (
                  <Fragment>
                    <PrintIcon className={styles.u_push__right_half} />
                    {t('Print Service')}
                  </Fragment>
                )}
              </Button>
            )}
          </div>
          {confirmationModal && (
            <ConfirmationModal
              message={confirmationModal.message}
              isOpen={confirmationModal.isOpen}
              icon="warning"
              onConfirm={confirmationModal.confirmAction}
              onCancel={confirmationModal.cancelAction}
            />
          )}
        </div>
      </div>
    </div>
  );
};

EstimateHeader.propTypes = {
  bereavement: bereavementType.isRequired,
  arrangement: arrangementType.isRequired,
  availableFeatures: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onPrintEstimate: PropTypes.func.isRequired,
  onPrintService: PropTypes.func.isRequired,
  onSendEstimateEmail: PropTypes.func.isRequired,
  onSendServiceDetailsEmail: PropTypes.func.isRequired,
  isPrintEstimateLoading: PropTypes.bool.isRequired,
  isPrintServiceLoading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  isOnline: PropTypes.bool.isRequired,
  confirmationModal: PropTypes.objectOf(PropTypes.any),
};

export default EstimateHeader;
