import React from 'react';
import { t } from 'i18next';
import { getFormattedDate } from 'services/utils';
import { historicFuneralAccountChargeType } from 'types/account';
import ExpandableBanner from 'components/common/ExpandableBanner';
import Price from 'components/common/Price';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const TransactionHistoricChargeRow = ({
  charge,
}) => (
  <div data-test-id="historicAccountTransaction">
    <ExpandableBanner
      headerClasses={[rcStyles.expanding_banner__header]}
      wrapperClasses={[rcStyles.expanding_banner__wrap]}
      isClosedOnLoad
      title={(
        <div className={rcStyles.banner_content}>
          <div className={rcStyles.is_title}>
            {t('Historic Funeral Account')}
          </div>
          <div className={rcStyles.is_date_created}>
            <div className={styles.brevier}>
              {t('Created:')}
              <span data-test-id="historicTransactionCreatedOn">
                {getFormattedDate(charge.transferDate)}
              </span>
            </div>
          </div>
          <div className={rcStyles.is_price}>
            <Price
              price={charge.amount}
              customClass={classNames(
                styles.c_price_label__regular,
                styles.is_black,
                styles.is_bold,
              )}
            />
          </div>
        </div>
      )}
    >
      <div className={rcStyles.expanded_content}>
        {t('This account has been transfered from arrangers previous system. The new total has been applied to this account transfer.')}
      </div>
    </ExpandableBanner>
  </div>
);

TransactionHistoricChargeRow.propTypes = {
  charge: historicFuneralAccountChargeType,
};

export default TransactionHistoricChargeRow;
