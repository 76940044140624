import React from 'react';
import { t } from 'i18next';
import { invoiceType } from 'types/account';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import DatePicker from 'components/common/DatePicker';
import DateTimePicker from 'components/common/DateTimePicker';
import { directoryListingType } from 'types/directoryListing';
import { bereavementType } from 'types/bereavement';
import PayeeDetails from 'components/account/PayeeDetails';
import styles from 'scss/main.scss';


import PropTypes from 'prop-types';
import classNames from 'classnames';

import rcStyles from './styles.scss';

const InvoiceDetails = ({
  invoice,
  isInvoiceNumberLoading,
  setIsInvoiceDirty,
  onChangeInvoiceDetails,
  onChangeInvoicePayee,
  onPayeeFormUpdate,
  bereavement,
  defaultInvoiceNumber,
  directoryListings,
  errors,
}) => {
  const { invoice: invoiceErrors, payee: payeeErrors } = errors || {};
  const loadingIcon = isInvoiceNumberLoading ? (
    <InputAdornment position="end" className={styles.o_icon__primary}>
      <img src="/img/loading_field.gif" alt="Loading" className={styles.image} data-test-id="ellipsisLoader" />
    </InputAdornment>
  ) : null;

  return (
    <div className={classNames(
      styles.c_page__full_height,
      styles.has_faded_background,
    )}
    >
      <div className={styles.o_view}>
        <div className={classNames(
          styles.o_view__main,
          styles.o_block_content,
          rcStyles.v_spacing,
        )}
        >
          <div className={rcStyles.colspan2}>
            <PayeeDetails
              bereavedPeopleConnections={bereavement.bereavedPeopleConnections}
              bereavementId={bereavement.id}
              directoryListings={directoryListings}
              onChangeInvoicePayee={onChangeInvoicePayee}
              onPayeeFormUpdate={onPayeeFormUpdate}
              payeeId={invoice.payeeId}
              payeeType={invoice.payeeType}
              setIsInvoiceDirty={setIsInvoiceDirty}
              errors={payeeErrors}
            />
          </div>
          <DateTimePicker
            value={invoice.dateTime}
            label={t('Date *')}
            onChange={date => onChangeInvoiceDetails('dateTime', date)}
            showTimeSelect={false}
            displayFormat="dd/MM/yyyy"
            errors={invoiceErrors && !!invoiceErrors.dateTime}
            helperText={invoiceErrors && invoiceErrors.dateTime}
            data-test-id="invoiceDateInput"
          />
          <DatePicker
            value={invoice.dueDate}
            label={t('Due Date')}
            onChange={date => onChangeInvoiceDetails('dueDate', date)}
            data-test-id="invoiceDueDateInput"
          />
          <TextField
            label={t('Invoice number *')}
            value={invoice.number || ''}
            placeholder={defaultInvoiceNumber}
            onChange={event => onChangeInvoiceDetails('number', event.target.value)}
            InputProps={{
              endAdornment: loadingIcon,
              inputProps: {
                'data-test-id': 'invoiceNumberInput',
              },
            }}
            error={!isInvoiceNumberLoading && (invoiceErrors && !!invoiceErrors.number)}
            helperText={!isInvoiceNumberLoading && (invoiceErrors && invoiceErrors.number)}
            fullWidth
          />
          <TextField
            label={t('Reference')}
            value={invoice.reference || ''}
            onChange={event => onChangeInvoiceDetails('reference', event.target.value)}
            inputProps={{
              'data-test-id': 'invoiceReferenceInput',
            }}
            fullWidth
          />
          <div className={rcStyles.colspan2}>
            <TextField
              label={t('Notes')}
              value={invoice.notes}
              onChange={event => onChangeInvoiceDetails('notes', event.target.value)}
              inputProps={{
                'data-test-id': 'invoiceNotesInput',
              }}
              fullWidth
              multiline
              rows={3}
              rowsMax={9}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

InvoiceDetails.propTypes = {
  invoice: invoiceType,
  isInvoiceNumberLoading: PropTypes.bool.isRequired,
  onChangeInvoiceDetails: PropTypes.func.isRequired,
  onChangeInvoicePayee: PropTypes.func.isRequired,
  onPayeeFormUpdate: PropTypes.func.isRequired,
  defaultInvoiceNumber: PropTypes.string,
  directoryListings: PropTypes.arrayOf(directoryListingType),
  bereavement: bereavementType,
  setIsInvoiceDirty: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any),
};

export default InvoiceDetails;
