import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import { arrangementType } from 'types/bereavement';
import { apolloClientType } from 'types/apollo';
import { selectDiscountTypeAction } from 'actions/arrangement';
import EstimateDiscountSelector from './EstimateDiscountSelector';
import { selectDiscountTypeMutation } from './mutations.gql';

class EstimateDiscountSelectorContainer extends Component {
  static propTypes = {
    bereavementId: PropTypes.string.isRequired,
    arrangement: arrangementType.isRequired,
    disabled: PropTypes.bool.isRequired,
    selectDiscountType: PropTypes.func.isRequired,
    onOpenConfirmationWarningModal: PropTypes.func.isRequired,
    client: apolloClientType.isRequired,
  }

  handleSelectDiscountType = (discountType) => {
    const { arrangement, onOpenConfirmationWarningModal } = this.props;

    if (arrangement.isConfirmed) {
      onOpenConfirmationWarningModal(() => { this.selectDiscountType(discountType); });
      return;
    }

    this.selectDiscountType(discountType);
  }

  selectDiscountType = (discountType) => {
    const {
      client,
      selectDiscountType,
      bereavementId,
      arrangement,
    } = this.props;

    const input = {
      bereavementId,
      arrangementId: arrangement.id,
      type: discountType,
    };

    selectDiscountType(bereavementId, arrangement.id, discountType);
    client.mutate({
      mutation: selectDiscountTypeMutation,
      variables: { input },
    });
  }

  render() {
    const { arrangement, disabled } = this.props;
    return (
      <EstimateDiscountSelector
        arrangement={arrangement}
        disabled={disabled}
        onSelectDiscountType={this.handleSelectDiscountType}
      />
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  selectDiscountType: selectDiscountTypeAction,
}, dispatch);

export default withApollo(
  withRouter(connect(null, mapDispatchToProps)(EstimateDiscountSelectorContainer)),
);
