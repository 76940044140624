import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import AsyncPaginate from 'react-select-async-paginate';
import { getAsyncLookupOptionValue } from 'services/utils';
import classNames from 'classnames';
import styles from 'scss/main.scss';

const customSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? styles.colorGreyUltraLight : styles.colorWhite,
    color: 'inherit',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  control: (provided, state) => {
    const focusedStyle = state.isFocused ? styles.colorOffWhite : styles.colorWhite;
    return {
      'borderBottomStyle': 'solid',
      'borderBottomColor': state.isFocused ? styles.colorHighlight : styles.colorGrey,
      'borderBottomWidth': state.isFocused ? '2px' : '1px',
      'display': 'flex',
      'backgroundColor': state.hasValue ? styles.colorWhite : focusedStyle,
      '&:hover': {
        borderBottomColor: styles.colorBlue,
      },
    };
  },
};

class StatusLookupContainer extends Component {
  static propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        enum: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ]),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    classes: PropTypes.arrayOf(PropTypes.string.isRequired),
    isMulti: PropTypes.bool,
    statuses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  getCategoriesById = (id) => {
    const { statuses, onChange } = this.props;

    onChange(statuses.find(item => item.enum === id));
  }

  getCategories = () => {
    const { statuses } = this.props;
    const newResults = statuses.map(status => ({
      label: status.name,
      value: status,
    }));

    return {
      options: newResults,
      hasMore: false,
      additional: {
        lastCursor: null,
      },
    };
  }

  handleChange = (selected) => {
    const { isMulti, onChange } = this.props;

    if (isMulti) {
      return onChange(selected.map(item => item.value));
    }

    return onChange(selected);
  }

  render() {
    const {
      label,
      disabled,
      isMulti,
      classes,
    } = this.props;
    let { value: propValue } = this.props;

    if (typeof propValue === 'string') {
      this.getCategoriesById(propValue);
    }

    if (isMulti && propValue && typeof propValue[0] === 'string') {
      this.getCategoriesById(propValue);
    }

    if (propValue && typeof propValue === 'object' && !isMulti) {
      propValue = [propValue];
    }

    const value = propValue
      ? propValue.map(item => ({
        label: item.name,
        value: item,
      }))
      : null;

    return (
      <AsyncPaginate
        id="directoryListingSelect"
        className={classNames(classes)}
        value={value}
        onChange={this.handleChange}
        placeholder={label}
        loadOptions={this.getCategories}
        getOptionValue={option => getAsyncLookupOptionValue(option, 'enum')}
        isSearchable
        isMulti={isMulti}
        isDisabled={disabled}
        styles={customSelectStyles}
        disabled={disabled}
      />
    );
  }
}

export default withApollo(StatusLookupContainer);
