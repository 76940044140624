import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ContactSupport from '@material-ui/icons/ContactSupport';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import CloseIcon from '@material-ui/icons/Close';
import CommentIcon from '@material-ui/icons/Comment';
import IconButton from '@material-ui/core/IconButton';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';

import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const SupportInformation = ({
  toggleSlideInWindow,
  switchWindow,
  handleLinks,
  laDeskChatButton,
}) => (
  <div className={classNames(
    styles.js_page,
    styles.c_page__full_height,
    styles.has_background,
  )}
  >
    <Button
      className={rcStyles.vertical_button}
      onClick={toggleSlideInWindow}
      tabIndex="-1"
      role="button"
      variant="contained"
      data-test-id="knowledgeBase"
    >
      <ContactSupport
        className={classNames(styles.u_push__right_half)}
      />
      {t('Knowledge Base')}
    </Button>
    <div className={rcStyles.nav}>
      <IconButton
        className={classNames(
          styles.o_button__white,
        )}
        color="secondary"
        size="small"
        variant="contained"
        aria-label="Close"
        onClick={toggleSlideInWindow}
        data-test-id="close"
      >
        <CloseIcon />
      </IconButton>
    </div>
    <div className={rcStyles.body}>
      <div className={rcStyles.knowledge_base}>
        <h2 className={styles.long_primer}>
          {t('Find help in our knowledge base')}
        </h2>
        <Button
          color="primary"
          variant="contained"
          onClick={() => { handleLinks('documentation'); }}
          data-test-id="documentation"
        >
          <InsertDriveFile
            className={classNames(styles.u_push__right_half)}
          />
          {t('View documentation')}
        </Button>
      </div>
      <div className={rcStyles.contact_options}>
        <div className={rcStyles.contact_options__item}>
          <h3 className={rcStyles.is_title}>
            {t('Send us a message')}
          </h3>
          <div className={rcStyles.is_message}>
            {t('Let us know how we can help and we will get back to you as soon as possible')}
          </div>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => { switchWindow('email'); }}
            data-test-id="email"
          >
            <EmailIcon className={styles.u_push__right_half} />
            {t('Get in touch')}
          </Button>
        </div>
        <div className={rcStyles.contact_options__item}>
          <h3 className={rcStyles.is_title}>{t('Our team is ready to help')}</h3>
          <div className={rcStyles.is_message}>
            {t('Monday to Thursday 8.30am - 5.30pm')}
            <br />
            {t('Friday 8.30am - 5.00pm')}
          </div>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => { laDeskChatButton.onClick(); }}
            data-test-id="laDeskChatButton"
          >
            <CommentIcon />
            {t('Start a live chat')}
          </Button>
        </div>
      </div>
      <div className={rcStyles.base_item}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => { switchWindow('contact'); }}
          data-test-id="contactDetails"
        >
          {t('Contact Details')}
        </Button>
      </div>
    </div>
  </div>
);

SupportInformation.propTypes = {
  toggleSlideInWindow: PropTypes.func,
  switchWindow: PropTypes.func,
  handleLinks: PropTypes.func,
  laDeskChatButton: PropTypes.objectOf(PropTypes.any),
};

export default SupportInformation;
