import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import classNames from 'classnames';
import rcStyles from './styles.scss';

const Arrow = ({
  dir, size, slideCount, currentSlide, ...otherProps
}) => {
  let arrowObject = null;
  const arrowKey = `${dir}-${size}`;

  switch (arrowKey) {
    case 'right-small':
      arrowObject = <ChevronRightIcon />;
      break;
    case 'right-large':
      arrowObject = <ArrowForwardIosIcon />;
      break;
    case 'left-small':
      arrowObject = <ChevronLeftIcon />;
      break;
    case 'left-large':
      arrowObject = <ArrowBackIosIcon />;
      break;
    default:
      arrowObject = <ChevronLeftIcon />;
      break;
  }

  const TheArrow = () => (
    <div
      {...otherProps}
      slidecount={slideCount}
      currentslide={currentSlide}
      className={classNames(
        rcStyles.arrow,
        size === 'small' ? rcStyles.small : rcStyles.large,
        dir === 'right' ? rcStyles.right : rcStyles.left,
      )}
    >
      { arrowObject }
    </div>
  );
  return (
    <Fragment>
      <div>
        <TheArrow />
      </div>
    </Fragment>
  );
};

Arrow.propTypes = {
  'dir': PropTypes.string,
  'size': PropTypes.string,
  'slideCount': PropTypes.number,
  'currentSlide': PropTypes.number,
  'data-role': PropTypes.string,
};

export default Arrow;
