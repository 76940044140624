import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { validationSchema } from './validation';
import CalendarBookEventModal from './CalendarBookEventModal';

export class CalendarBookAppointmentModalContainer extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool,
    selectedEvent: PropTypes.objectOf(PropTypes.any),
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    generateRefs: PropTypes.func.isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isOpen && nextProps.isEditing && !Object.keys(prevState.formData).length) {
      return {
        formData: nextProps.selectedEvent,
      };
    }

    if (!nextProps.isOpen) {
      return {
        formData: {},
        isValidationEnabled: false,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      formData: {},
      isValidationEnabled: false,
    };

    this.dialogRef = React.createRef();
  }

  componentDidMount() {
    const { generateRefs } = this.props;
    generateRefs(Object.keys(validationSchema.fields));
  }

  handleChange = (key, value) => {
    const { formData, isValidationEnabled } = this.state;
    const { validate } = this.props;

    const newFormData = {
      ...formData,
      [key]: value,
    };

    if (isValidationEnabled) {
      validate(newFormData, validationSchema);
    }

    this.setState({ formData: newFormData });
  }

  handleSave = () => {
    const { onSave, validate } = this.props;
    const { formData } = this.state;
    const isEndDateBeforeStartDate = moment(formData.end).isBefore(formData.start);

    this.setState({ isValidationEnabled: true });

    const isValid = validate({ ...formData, isEndDateBeforeStartDate }, validationSchema, true, this.dialogRef);

    if (isValid) {
      onSave(formData);
    }
  }

  handleDelete = () => {
    const { onDelete } = this.props;
    const { formData } = this.state;

    onDelete(formData);
  }

  render() {
    const { formData } = this.state;
    return (
      <CalendarBookEventModal
        {...this.props}
        formData={formData}
        onChange={this.handleChange}
        onDelete={this.handleDelete}
        onSave={this.handleSave}
        dialogRef={this.dialogRef}
      />
    );
  }
}

export default withValidation(CalendarBookAppointmentModalContainer);
