import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bereavedPersonConnectionType } from 'types/bereavement';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { emptyBereavedPerson } from 'constants/bereavement';
import ClientForm from './ClientForm';
import { validationSchema } from './validation';

class ClientFormContainer extends Component {
  static propTypes = {
    bereavedPersonConnection: bereavedPersonConnectionType,
    isOpen: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    isAdding: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    generateRefs: PropTypes.func.isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  static getDerivedStateFromProps(nextProps, state) {
    const {
      bereavedPersonConnection,
      isOpen,
    } = nextProps;
    if (!isOpen) {
      return {
        ...state,
        formData: emptyBereavedPerson,
      };
    }

    if (bereavedPersonConnection.bereavedPerson.id
      && !state.formData.bereavedPerson.id
      && nextProps.isOpen) {
      return {
        ...state,
        formData: {
          ...bereavedPersonConnection,
        },
      };
    }

    if (!bereavedPersonConnection.bereavedPerson.id
      && !state.formData.bereavedPerson.id
      && isOpen) {
      return {
        ...state,
        formData: {
          ...state.formData,
        },
      };
    }

    return state;
  }

  constructor(props) {
    super(props);

    this.state = {
      isValidationEnabled: false,
      formData: emptyBereavedPerson,
    };

    this.dialogRef = React.createRef();
  }

  componentDidMount() {
    const { generateRefs } = this.props;
    generateRefs(Object.keys(validationSchema.fields));
  }

  handleChange = (property, value) => {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [property]: value,
      },
    }));
  }

  handleChangeBereavedPerson = (property, value) => {
    const { validate } = this.props;
    const { formData, isValidationEnabled } = this.state;
    const newState = {
      formData: {
        ...formData,
        bereavedPerson: {
          ...formData.bereavedPerson,
          [property]: value,
        },
      },
    };

    if (isValidationEnabled) {
      validate(newState.formData.bereavedPerson, validationSchema);
    }
    this.setState(newState);
  }

  handleSave = () => {
    const { onSave, validate } = this.props;
    const { formData } = this.state;

    this.setState({ isValidationEnabled: true });

    const isValid = validate(formData.bereavedPerson, validationSchema, true, this.dialogRef);

    if (isValid) {
      onSave(formData);
    }
  }

  handleClose = () => {
    const { onClose } = this.props;

    this.setState({ formData: emptyBereavedPerson });
    onClose();
  }

  render() {
    const {
      isOpen, isSaving, isAdding, formRefs, errors,
    } = this.props;
    const { formData } = this.state;

    return (
      <ClientForm
        bereavedPersonConnection={formData}
        isOpen={isOpen}
        isSaving={isSaving}
        isAdding={isAdding}
        onChangeBereavedPerson={this.handleChangeBereavedPerson}
        onChange={this.handleChange}
        onSave={this.handleSave}
        onClose={this.handleClose}
        formRefs={formRefs}
        dialogRef={this.dialogRef}
        errors={errors}
      />
    );
  }
}

export default withValidation(ClientFormContainer);
