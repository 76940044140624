import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TabContainer from 'components/common/TabContainer';
import PublishObituaryModal from 'components/obituary/PublishObituaryModal';
import { bereavedPersonConnectionType, arrangementType } from 'types/bereavement';
import IconLoad from 'icons/IconLoad';

import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const tabClasses = {
  root: rcStyles.tabs,
  tabs: rcStyles.tabs__floating_indicator,
  tab: rcStyles.tabs__floating_button,
  tabBar: rcStyles.obituary_tabs,
};

const ArrangementObituaryForm = ({
  bereavementId,
  arrangement,
  funeralDate,
  deceasedDates,
  bereavedPeopleConnections,
  selectedAdministrator,
  selectedCategory,
  categories,
  categoryInformation,
  isPublishObituaryModalOpen,
  isObituaryPublished,
  isSaving,
  isCategoryInformationDisabled,
  onCategoryChange,
  onCategoryInformationChange,
  onFZDetailsChange,
  onPublishObituaryModalToggle,
  onCategoryInformationSave,
  formRefs,
  errors,
}) => (
  <TabContainer
    classes={tabClasses}
    onChange={onCategoryChange}
    data-test-id="obituaryTabs"
    tabs={categories.map(item => ({
      label: item.title,
      component: (
        <React.Fragment>
          <selectedCategory.formComponent
            bereavementId={bereavementId}
            arrangement={arrangement}
            funeralDate={funeralDate}
            deceasedDates={deceasedDates}
            bereavedPeopleConnections={bereavedPeopleConnections}
            selectedAdministrator={selectedAdministrator}
            formData={categoryInformation}
            onChange={onCategoryInformationChange}
            isObituaryPublished={isObituaryPublished}
            disabled={isCategoryInformationDisabled}
            errors={errors}
            formRefs={formRefs}
            data-test-id={selectedCategory.title}
          />
          <div className={rcStyles.save_buttons}>
            <Button
              onClick={() => onCategoryInformationSave(false)}
              disabled={isCategoryInformationDisabled}
              size="medium"
              color="primary"
              variant="outlined"
              data-test-id="saveServiceForm"
            >
              {t('Save')}
            </Button>
            {selectedCategory.title === 'Funeral Guide' && (
              <Button
                onClick={() => onPublishObituaryModalToggle(true)}
                disabled={isCategoryInformationDisabled || isObituaryPublished}
                size="medium"
                color="primary"
                variant="contained"
                data-test-id="publishObituary"
              >
                {t('Save and Publish on Funeral Guide')}
              </Button>
            )}
            {isSaving && (
              <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
                <IconLoad title={t('Saving')} />
              </div>
            )}
          </div>
          {isPublishObituaryModalOpen && (
            <PublishObituaryModal
              selectedHomeId={categoryInformation.funeralZoneDetails
                && categoryInformation.funeralZoneDetails.homeId}
              selectedStaffMemberId={categoryInformation.funeralZoneDetails
                && categoryInformation.funeralZoneDetails.arrangerId}
              errors={errors}
              onFZDetailsChange={onFZDetailsChange}
              onCancel={() => onPublishObituaryModalToggle(false)}
              onSubmit={() => onCategoryInformationSave(true)}
            />
          )}
        </React.Fragment>
      ),
    }))}
  />
);

ArrangementObituaryForm.propTypes = {
  bereavementId: PropTypes.string.isRequired,
  arrangement: arrangementType.isRequired,
  funeralDate: PropTypes.string,
  deceasedDates: PropTypes.string.isRequired,
  bereavedPeopleConnections: PropTypes.arrayOf(bereavedPersonConnectionType).isRequired,
  selectedAdministrator: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedCategory: PropTypes.objectOf(PropTypes.any).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  isPublishObituaryModalOpen: PropTypes.bool.isRequired,
  isObituaryPublished: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isCategoryInformationDisabled: PropTypes.bool.isRequired,
  onCategoryChange: PropTypes.func.isRequired,
  categoryInformation: PropTypes.objectOf(PropTypes.any),
  onCategoryInformationChange: PropTypes.func.isRequired,
  onFZDetailsChange: PropTypes.func.isRequired,
  onPublishObituaryModalToggle: PropTypes.func.isRequired,
  onCategoryInformationSave: PropTypes.func.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ArrangementObituaryForm;
