import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { debounce } from 'debounce';
import { emptyBereavedPerson } from 'constants/bereavement';
import {
  createBereavedPersonResult,
  editBereavedPersonTransform,
  editBereavedPersonResult,
} from 'transforms/bereavement';
import { removePropertyByName, buildBereavedLookupLabel, generateHexId } from 'services/utils';
import { bereavedPersonConnectionType, appointmentType } from 'types/bereavement';
import { apolloClientType } from 'types/apollo';
import ClientListSection from './ClientListSection';
import { getBereavedPeople } from './queries.gql';
import { createBereavedPerson, editBereavedPerson } from './mutations.gql';

class ClientListSectionContainer extends Component {
  debounceChange = debounce((value) => {
    if (value) {
      this.getSuggestions(value);
    } else {
      this.setState({ suggestions: [] });
    }
  }, 500);

  static propTypes = {
    bereavementId: PropTypes.string,
    bereavedPeopleConnections: PropTypes.arrayOf(bereavedPersonConnectionType).isRequired,
    appointment: appointmentType,
    canDeleteAllClients: PropTypes.bool,
    disabled: PropTypes.bool,
    client: apolloClientType.isRequired,
    addClient: PropTypes.func.isRequired,
    editClient: PropTypes.func.isRequired,
    removeClient: PropTypes.func.isRequired,
    selectedClientId: PropTypes.string,
    onSelectedClientChange: PropTypes.func,
    displayRemove: PropTypes.bool,
  }

  static defaultProps = {
    displayRemove: true,
  }

  constructor(props) {
    super(props);

    this.state = {
      lookUpValue: '',
      suggestions: [],
      isLoading: false,
      isSaving: false,
      isAdding: false,
      isCreating: false,
      isClientFormOpen: false,
      clientFormData: { ...emptyBereavedPerson },
    };

    this.lookUpRef = React.createRef();
  }

  getSuggestions = (searchText) => {
    const { client } = this.props;
    client.query({
      query: getBereavedPeople,
      variables: {
        term: searchText,
        pagination: {
          first: null,
          after: null,
        },
      },
    }).then((result) => {
      const suggestions = result.data.bereavedPeople.edges.map(({ node }) => ({
        data: removePropertyByName(node, '__typename'),
        value: buildBereavedLookupLabel(node),
      }));
      this.setState({ suggestions, isLoading: false });
    });
  }

  onLookUpChange = (value) => {
    this.setState({ lookUpValue: value, isLoading: true });
    this.debounceChange(value);
  }

  onSelect = (bereavedPerson) => {
    const bereavedPersonConnection = {
      ...emptyBereavedPerson,
      bereavedPerson,
    };
    this.setState({
      lookUpValue: '',
      suggestions: [],
      isClientFormOpen: true,
      isAdding: true,
      clientFormData: bereavedPersonConnection,
    });
  }

  onSelectNew = () => {
    this.setState({
      lookUpValue: '',
      suggestions: [],
      isClientFormOpen: true,
      isAdding: true,
      isCreating: true,
    });
  }

  onEdit = (bereavedPersonConnection) => {
    this.setState({
      isClientFormOpen: true,
      clientFormData: bereavedPersonConnection,
    });
  }

  closeClientForm = () => {
    this.setState({
      isClientFormOpen: false,
      clientFormData: emptyBereavedPerson,
      isAdding: false,
      isCreating: false,
      isSaving: false,
    });
    setTimeout(() => this.lookUpRef.current.blur(), 50);
    setTimeout(() => this.lookUpRef.current.focus(), 55);
  }

  onSave = (formData) => {
    const { client } = this.props;
    const { isCreating } = this.state;
    this.setState({ isSaving: true });

    const input = isCreating
      ? formData.bereavedPerson
      : editBereavedPersonTransform(formData.bereavedPerson);
    client.mutate({
      mutation: isCreating ? createBereavedPerson : editBereavedPerson,
      variables: { input },
    }).then(data => this.bereavedCallback(formData, data));
  }

  bereavedCallback = (formData, data) => {
    const { bereavementId, addClient, editClient } = this.props;
    const { isAdding, isCreating } = this.state;
    const payload = formData;
    if (!payload.id) {
      payload.id = generateHexId();
    }
    payload.bereavedPerson = isCreating
      ? createBereavedPersonResult(data)
      : editBereavedPersonResult(data);
    const callback = isAdding ? addClient : editClient;
    callback(bereavementId, payload);
    this.closeClientForm();
  }

  render() {
    const {
      appointment, bereavedPeopleConnections, disabled, removeClient, canDeleteAllClients,
      selectedClientId, onSelectedClientChange, displayRemove,
    } = this.props;
    const {
      lookUpValue, suggestions, isClientFormOpen, clientFormData, isLoading, isAdding, isSaving,
    } = this.state;

    return (
      <ClientListSection
        bereavedPeopleConnections={bereavedPeopleConnections}
        canDeleteAllClients={canDeleteAllClients}
        disabled={disabled}
        isClientFormOpen={isClientFormOpen}
        clientFormData={clientFormData}
        appointment={appointment}
        lookUpValue={lookUpValue}
        suggestions={suggestions}
        isLoading={isLoading}
        isSaving={isSaving}
        isAdding={isAdding}
        lookUpRef={this.lookUpRef}
        onLookUpChange={this.onLookUpChange}
        onSelect={this.onSelect}
        onSelectNew={this.onSelectNew}
        onEdit={this.onEdit}
        onRemove={removeClient}
        onClose={this.closeClientForm}
        onSave={this.onSave}
        selectedClientId={selectedClientId}
        onSelectedClientChange={onSelectedClientChange}
        displayRemove={displayRemove}
      />
    );
  }
}

export default withApollo(ClientListSectionContainer);
