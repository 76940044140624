import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import RemoveIcon from '@material-ui/icons/Remove';

import ActionCard from 'components/common/ActionCard';
import DatePicker from 'components/common/DatePicker';
import DynamicList from 'components/common/DynamicList';
import MediaUpload from 'components/common/MediaUpload';
import Document from 'components/common/Document';
import { deedActions, deedActionLabels } from 'constants/bereavement';
import { deedActionType } from 'types/bereavement';
import { reactRefType } from 'types/common';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const DeedModal = ({
  formData,
  documents,
  actions,
  isOpen,
  disabled,
  onChange,
  onDocumentsChange,
  onUploadComplete,
  onAddAction,
  onActionChange,
  onClose,
  onSave,
  dialogRef,
  errors,
  formRefs,
}) => (
  <Dialog
    open={isOpen}
    fullScreen
    scroll="paper"
    TransitionComponent={Transition}
    className={styles.c_dialogue}
    disableBackdropClick
    disableEscapeKeyDown
  >
    <DialogTitle
      disableTypography
      className={styles.dialog_title_bar}
    >
      <IconButton
        className={classNames(
          styles.o_button__secondary,
          styles.is_close,
        )}
        color="primary"
        variant="contained"
        aria-label="Close"
        onClick={onClose}
        data-test-id="deedClose"
      >
        <CloseIcon />
      </IconButton>
      <div className={styles.is_title}>
        {t('Deed')}
      </div>
      <div className={styles.is_actions}>
        <Button
          onClick={onSave}
          variant="contained"
          color="secondary"
          data-test-id="deedSaveAndReturn"
          disabled={disabled}
          className={styles.o_button__secondary}
        >
          {t('Save & return')}
        </Button>
      </div>
    </DialogTitle>
    <div
      ref={dialogRef}
      className={classNames(
        styles.c_dialog__overflow,
      )}
    >
      <DialogContent>
        <Fragment>
          <div
            className={classNames(
              rcStyles.v_spacing,
              styles.o_block_content,
              styles.u_push__bottom,
            )}
            data-test-id="deedDetails"
          >

            <h3 className={classNames(
              styles.long_primer,
              rcStyles.colspan2,
            )}
            >
              {t('Deed details')}
            </h3>
            <div ref={formRefs.rightOfBurialNumber}>
              <TextField
                label={t('Exclusive right of burial number')}
                value={formData.rightOfBurialNumber || ''}
                onChange={event => onChange('rightOfBurialNumber', event.target.value)}
                inputProps={{
                  'data-test-id': 'rightOfBurialNumber',
                }}
                error={errors && !!errors.rightOfBurialNumber}
                helperText={errors && errors.rightOfBurialNumber}
                fullWidth
              />
            </div>
            <div ref={formRefs.graveNumber}>
              <TextField
                label={t('Grave number')}
                value={formData.graveNumber || ''}
                onChange={event => onChange('graveNumber', event.target.value)}
                inputProps={{
                  'data-test-id': 'graveNumber',
                }}
                error={errors && !!errors.graveNumber}
                helperText={errors && errors.graveNumber}
                fullWidth
              />
            </div>
            <div ref={formRefs.section}>
              <TextField
                label={t('Section')}
                value={formData.section || ''}
                onChange={event => onChange('section', event.target.value)}
                inputProps={{
                  'data-test-id': 'section',
                }}
                error={errors && !!errors.section}
                helperText={errors && errors.section}
                fullWidth
              />
            </div>
            <div ref={formRefs.dateReceived}>
              <DatePicker
                value={formData.dateReceived || null}
                label={t('Date deeds were received')}
                onChange={date => onChange('dateReceived', date)}
                isClearable
                errors={errors && !!errors.dateReceived}
                helperText={errors && errors.dateReceived}
                data-test-id="dateReceived"
              />
            </div>
            <div ref={formRefs.cemeteryName}>
              <TextField
                label={t('Cemetery name')}
                value={formData.cemeteryName || ''}
                onChange={event => onChange('cemeteryName', event.target.value)}
                inputProps={{
                  'data-test-id': 'cemeteryName',
                }}
                required
                error={errors && !!errors.cemeteryName}
                helperText={errors && errors.cemeteryName}
                fullWidth
              />
            </div>
            <div ref={formRefs.notes}>
              <TextField
                label={t('Deed notes')}
                value={formData.notes || ''}
                onChange={event => onChange('notes', event.target.value)}
                inputProps={{
                  'data-test-id': 'notes',
                }}
                error={errors && !!errors.notes}
                helperText={errors && errors.notes}
                fullWidth
              />
            </div>
            {(documents && !!documents.length) && (
              <div className={rcStyles.colspan2}>
                <DynamicList
                  isStacked
                  component={(
                    <Document />
                  )}
                  addButtonProps={{
                    variant: 'contained',
                    color: 'primary',
                  }}
                  removeButtonProps={{
                    variant: 'outlined',
                    color: 'primary',
                    classes: { root: styles.o_button__round },
                  }}
                  removeButtonInner={
                    <RemoveIcon />
                  }
                  dataList={documents}
                  onChange={onDocumentsChange}
                  hideAddButton
                  secondary
                  data-test-id="deedDocumentsList"
                />
              </div>
            )}
            <MediaUpload
              disabled={disabled}
              showImagePreview={false}
              onUploadDone={onUploadComplete}
              uploadButtonText={t('Upload deed')}
              data-test-id="uploadDeed"
            />
          </div>
          <div className={classNames(
            styles.o_block_content,
            rcStyles.v_spacing,
          )}
          >
            <div>
              <h3 className={styles.long_primer}>{t('Action taken with deed')}</h3>
              <div
                data-test-id="actionTakenWithDeedMessage"
                className={styles.brevier}
              >
                {t('Create an audit trail to keep track of a deed\'s location.')}
              </div>
            </div>
            <div
              className={rcStyles.colspan2}
              data-test-id="actionTakenWithDeed"
            >
              {actions && actions.map((action, index) => (
                <ActionCard
                  key={action.timeCreated}
                  action={action.action}
                  createdBy={action.createdBy}
                  createdDateTime={action.timeCreated}
                  disabled={!action.isNew}
                  actionOptions={deedActions}
                  actionOptionLabels={deedActionLabels}
                  onChange={value => onActionChange(value, index)}
                  data-test-id="deedAction"
                />
              ))}
            </div>
            <div>
              <Button
                onClick={onAddAction}
                variant="outlined"
                color="primary"
                data-test-id="addAction"
              >
                <AddIcon className={styles.u_push__right_half} />
                {t('Add Action')}
              </Button>
            </div>
          </div>
        </Fragment>
      </DialogContent>
    </div>
  </Dialog>
);

DeedModal.propTypes = {
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
  documents: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  actions: PropTypes.arrayOf(deedActionType),
  isOpen: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onDocumentsChange: PropTypes.func.isRequired,
  onUploadComplete: PropTypes.func.isRequired,
  onAddAction: PropTypes.func.isRequired,
  onActionChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  dialogRef: reactRefType.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DeedModal;
