import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import MenuItem from '@material-ui/core/MenuItem';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import { categories } from 'constants/task';
import { buildNameString } from 'services/utils';
import { buildDeceasedNameAndDateOfDeath } from 'services/utils/bereavement';
import { bereavementType } from 'types/bereavement';
import { reactRefType } from 'types/common';
import Select from 'components/common/Select';
import StaffMemberLookup from 'components/common/lookups/StaffMemberLookup';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const buildBereavedString = (bereavement) => {
  const primaryBereaved = bereavement.bereavedPeopleConnections
    .find(bereaved => bereaved.isPrimaryContact);
  return buildNameString(primaryBereaved.bereavedPerson.name);
};

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const CreateCustomTaskModal = ({
  customTask,
  bereavement,
  onClose,
  onChange,
  onSave,
  isOpen,
  dialogRef,
  formRefs,
  errors,
}) => (
  <Dialog
    onClose={onClose}
    aria-labelledby="task-title"
    fullScreen
    scroll="paper"
    open={isOpen}
    TransitionComponent={Transition}
    disableBackdropClick
    disableEscapeKeyDown
  >
    <div
      ref={dialogRef}
      className={classNames(
        styles.c_dialog__body,
        styles.c_dialog__overflow,
      )}
    >
      <DialogTitle
        disableTypography
        className={styles.dialog_title_bar}
      >
        <IconButton
          className={classNames(
            styles.o_button__secondary,
            styles.is_close,
          )}
          color="primary"
          variant="contained"
          aria-label="Close"
          onClick={onClose}
          data-test-id="feeClose"
        >
          <CloseIcon />
        </IconButton>
        <div className={styles.is_title}>
          {t('New custom task')}
        </div>
        <div className={styles.is_actions}>
          <Button
            onClick={onSave}
            type="submit"
            variant="contained"
            data-test-id="saveTask"
            color="secondary"
            className={styles.o_button__secondary}
          >
            {t('Save')}
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>
        <div
          className={classNames(
            styles.o_block_content,
            rcStyles.v_spacing,
          )}
        >
          <div>
            <h3 className={styles.long_primer}>{t('Funeral details')}</h3>
            <p className={styles.brevier}>{t('This task will be saved against the following case')}</p>
          </div>
          {bereavement && (
            <Fragment>
              <TextField
                label={t('Deceased')}
                value={buildDeceasedNameAndDateOfDeath(bereavement)}
                fullWidth
                inputProps={{
                  'data-test-id': 'details-deceased',
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                label={t('Bereaved')}
                value={buildBereavedString(bereavement)}
                fullWidth
                inputProps={{
                  'data-test-id': 'deceased',
                }}
                InputProps={{
                  readOnly: true,
                }}
                className={styles.u_push__bottom}
              />
              <div ref={formRefs.title}>
                <h3 className={styles.long_primer}>{t('Custom task details')}</h3>
                <TextField
                  label={t('Title')}
                  value={customTask.title}
                  onChange={event => onChange('title', event.target.value)}
                  fullWidth
                  inputProps={{
                    'data-test-id': 'title',
                  }}
                  error={!!errors.title}
                  helperText={errors.title}
                />
              </div>
              <TextField
                label={t('Description')}
                value={customTask.description}
                onChange={event => onChange('description', event.target.value)}
                fullWidth
                inputProps={{
                  'data-test-id': 'description',
                }}
              />
              <div ref={formRefs.category}>
                <Select
                  label={t('Category')}
                  value={customTask.category}
                  onChange={event => onChange('category', event.target.value)}
                  inputProps={{
                    'data-test-id': 'category',
                  }}
                  error={!!errors.category}
                  errorText={errors.category}
                >
                  <MenuItem value={null}><em>All</em></MenuItem>
                  {Object.values(categories).map(category => (
                    <MenuItem key={category} value={category}>
                      {t(category.charAt(0).toUpperCase() + category.toLowerCase().slice(1))}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <StaffMemberLookup
                label={t('Assignee')}
                value={customTask.assignee}
                onChange={value => onChange('assignee', value)}
                data-test-id="taskAssigneeLookup"
              />
            </Fragment>
          )}
        </div>
      </DialogContent>
    </div>
  </Dialog>
);

CreateCustomTaskModal.propTypes = {
  customTask: PropTypes.objectOf(PropTypes.any).isRequired,
  bereavement: bereavementType,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  dialogRef: reactRefType.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default CreateCustomTaskModal;
