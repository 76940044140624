import React from 'react';
import classNames from 'classnames';
import i18next from 'i18next';
import { SortableElement } from 'react-sortable-hoc';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import DragnDrop from 'icons/IconDragnDrop';
import styles from 'scss/main.scss';
import { getValueByKey } from 'services/utils/common';

import { Props } from './index.types';
import rcStyles from './styles.scss';

const SortablePackageItem = SortableElement(({
  visibilityKey,
  labelForIncludeItem,
  item,
  isSortable,
  onSetIsVisible,
}: Props) => (
  <div
    className={classNames(
      rcStyles.sortable_tile,
      isSortable && rcStyles.is_active,
    )}
    data-test-id="packageTile"
  >
    <div className={classNames(
      styles.long_primer,
      styles.is_black,
    )}
    >
      {item.name}
    </div>
    <div className={styles.u_push__ends}>
      <ul className={classNames(
        styles.o_list,
        styles.is_plain,
      )}
      >
        {item?.products.map(product => (
          <li
            className={styles.o_list__item}
            key={product?.id}
          >
            <div className={styles.o_list_item__icon}>
              <ArrowRightIcon />
            </div>
            <div className={styles.o_list_item__text}>
              {product.title}
            </div>
          </li>
        ))}
        {item?.services.map(service => (
          <li
            className={styles.o_list__item}
            key={service?.id}
          >
            <div className={styles.o_list_item__icon}>
              <ArrowRightIcon />
            </div>
            <div className={styles.o_list_item__text}>
              {service.title}
            </div>
          </li>
        ))}
      </ul>
    </div>
    {isSortable ? (
      <div className={rcStyles.drag_button}>
        <DragnDrop />
      </div>
    ) : (
      <div className={rcStyles.checkbox}>
        <FormControlLabel
          control={(
            <Checkbox
              onChange={event => onSetIsVisible(item.id, event.target.checked)}
              inputProps={{
                'data-test-id': 'includeItemInCatalogue',
              }}
              color="primary"
              classes={{ root: styles.u_hard__left }}
              checked={getValueByKey(item, visibilityKey)}
            />
          )}
          label={i18next.t(labelForIncludeItem)}
        />
      </div>
    )}
  </div>
));

SortablePackageItem.displayName = 'SortablePackageItem';

export default SortablePackageItem;
