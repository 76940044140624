import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { sortArrangementsByLastEdited } from 'services/utils/arrangement';
import { arrangementType } from 'types/bereavement';

import ArrangementViewingsModal from './ArrangementViewingsModal';

class ArrangementViewingsModalContainer extends Component {
  static propTypes = {
    arrangements: PropTypes.arrayOf(arrangementType).isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCreateAndEditViewings: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { arrangements = [] } = props;
    const sortedArrangements = sortArrangementsByLastEdited(arrangements);

    this.state = {
      sortedArrangements,
      selectedArrangement: arrangements.length ? arrangements[0] : null,
    };
  }

  handleChangeArrangement = (arrangement, checked) => {
    this.setState({ selectedArrangement: checked ? arrangement : null });
  }

  render() {
    const { isOpen, onClose, onCreateAndEditViewings } = this.props;
    const { sortedArrangements, selectedArrangement } = this.state;
    return (
      <ArrangementViewingsModal
        arrangements={sortedArrangements}
        isOpen={isOpen}
        selectedArrangement={selectedArrangement}
        onChangeArrangement={this.handleChangeArrangement}
        onClose={onClose}
        onCreateAndEditViewings={onCreateAndEditViewings}
      />
    );
  }
}

export default ArrangementViewingsModalContainer;
