import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import moment from 'moment';
import classNames from 'classnames';
import { nameType } from 'types/common';
import { buildAddressString, buildNameString } from 'services/utils';

import styles from './styles.scss';


const CalendarEventContentTransfer = ({
  start,
  fromLocation,
  toLocation,
  caseOwner,
  deceasedName,
  bereavedName,
  instructions,
}) => (
  <Fragment>
    <div className={styles.rbc_dialog__body}>
      <div className={styles.rbc_dialog__item}>
        <div className={styles.is_label}>
          {t('Collection date')}
        </div>
        <div className={classNames(styles.rbc_dialog__item_value)}>
          {moment(start).format('DD MMM YYYY')}
        </div>
      </div>
      <div className={styles.rbc_dialog__item}>
        <div className={styles.is_label}>
          {t('Collection time')}
        </div>
        <div className={styles.is_value}>
          {moment(start).format('HH:mm')}
        </div>
      </div>
      {deceasedName && (
        <div className={styles.rbc_dialog__item}>
          <div className={styles.is_label}>
            {t('Deceased name')}
          </div>
          <div className={styles.is_value}>
            {buildNameString(deceasedName)}
          </div>
        </div>
      )}
      {bereavedName && (
        <div className={styles.rbc_dialog__item}>
          <div className={styles.is_label}>
            {t('Client name')}
          </div>
          <div className={styles.is_value}>
            {buildNameString(bereavedName)}
          </div>
        </div>
      )}
      {caseOwner && (
        <div className={styles.rbc_dialog__item}>
          <div className={styles.is_label}>
            {t('Funeral owner')}
          </div>
          <div className={styles.is_value}>
            {buildNameString(caseOwner.name)}
          </div>
        </div>
      )}
      {fromLocation && (
        <div className={classNames(
          styles.rbc_dialog__item,
          styles.rbc_dialog__item__wide,
        )}
        >
          <div className={styles.is_label}>
            {t('Transfer from location')}
          </div>
          <div className={styles.is_value}>
            <address>
              {buildAddressString(fromLocation)}
            </address>
          </div>
        </div>
      )}
      {toLocation && (
        <div className={classNames(
          styles.rbc_dialog__item,
          styles.rbc_dialog__item__wide,
        )}
        >
          <div className={styles.is_label}>
            {t('Transfer to location')}
          </div>
          <div className={styles.is_value}>
            <address>
              {buildAddressString(toLocation)}
            </address>
          </div>
        </div>
      )}
      {instructions && (
        <div className={classNames(
          styles.rbc_dialog__item,
          styles.rbc_dialog__item__wide,
        )}
        >
          <div className={styles.is_label}>
            {t('Instructions')}
          </div>
          <div className={styles.is_value}>
            {instructions}
          </div>
        </div>
      )}
    </div>
  </Fragment>
);

CalendarEventContentTransfer.propTypes = {
  start: PropTypes.instanceOf(Date).isRequired,
  deceasedName: nameType.isRequired,
  bereavedName: nameType.isRequired,
  fromLocation: PropTypes.objectOf(PropTypes.any).isRequired,
  toLocation: PropTypes.objectOf(PropTypes.any).isRequired,
  caseOwner: PropTypes.objectOf(PropTypes.any).isRequired,
  instructions: PropTypes.string,
};

export default CalendarEventContentTransfer;
