import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Select from 'components/common/Select';
import MenuItem from '@material-ui/core/MenuItem';
import BereavementLookup from 'components/common/lookups/BereavementLookup';
import StaffMemberLookup from 'components/common/lookups/StaffMemberLookup';
import OrganisationalUnitLookup from 'components/common/lookups/OrganisationalUnitLookup';
import { taskFiltersType, taskCategoryType } from 'types/task';
import { status } from 'constants/task';
import { organisationalUnitTypes } from 'constants/organisationalUnits';

import rcStyles from './styles.scss';

const TaskFilter = ({
  filters,
  onFilterChange,
  allowedCategories,
}) => (
  <div className={rcStyles.v_spacing}>
    <BereavementLookup
      label={t('Bereavement')}
      value={filters.bereavement}
      onChange={value => onFilterChange('bereavement', value)}
      data-test-id="bereavementLookup"
    />

    <OrganisationalUnitLookup
      label={t('Home')}
      value={filters.home}
      onChange={value => onFilterChange('home', value)}
      type={organisationalUnitTypes.HOME}
      disabled={!!filters.bereavement}
    />

    <StaffMemberLookup
      label={t('Assignee')}
      value={filters.assignee}
      onChange={value => onFilterChange('assignee', value)}
      data-test-id="staffLookup"
    />

    <Select
      label={t('By category')}
      value={filters.category}
      onChange={event => onFilterChange('category', event.target.value)}
      inputProps={{
        'data-test-id': 'filterCategory',
      }}
    >
      {allowedCategories.length > 1 && (
        <MenuItem value={null}><em>All</em></MenuItem>
      )}
      {Object.values(allowedCategories).map(category => (
        <MenuItem key={category} value={category}>
          {t(category.charAt(0).toUpperCase() + category.toLowerCase().slice(1))}
        </MenuItem>
      ))}
    </Select>

    <Select
      label={t('By status')}
      value={filters.status}
      onChange={event => onFilterChange('status', event.target.value)}
      inputProps={{
        'data-test-id': 'filterStatus',
      }}
    >
      <MenuItem value={null}><em>All</em></MenuItem>
      <MenuItem value={status.PENDING}>{t('Pending')}</MenuItem>
      <MenuItem value={status.COMPLETED}>{t('Completed')}</MenuItem>
      <MenuItem value={status.DISMISSED}>{t('Dismissed')}</MenuItem>
    </Select>

  </div>
);

TaskFilter.propTypes = {
  filters: taskFiltersType.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  allowedCategories: PropTypes.arrayOf(taskCategoryType),
};

export default TaskFilter;
