import React, { useState } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';

import classNames from 'classnames';
import styles from 'scss/main.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const CloseAccountModal = ({
  message,
  isOpen,
  onConfirm,
  onCancel,
  testIds = {},
}) => {
  const [reasonForCloseAccount, setReasonForCloseAccount] = useState(null);
  return (
    <Dialog
      onClose={onCancel}
      aria-labelledby="confirmation-dialog"
      fullWidth
      maxWidth="sm"
      open={isOpen}
      TransitionComponent={Transition}
      data-test-id={testIds.dialog || 'dialog'}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle disableTypography className={styles.c_dialog__header}>
        <div
          className={classNames(styles.c_dialog__title)}
          data-test-id={testIds.title || 'title'}
        >
          {t('Close account')}
        </div>
      </DialogTitle>
      <DialogContent
        className={styles.c_dialog__content}
        data-test-id={testIds.message || 'message'}
      >
        <div>{message && message.split('\n').map(line => (<p key={line}>{t(line)}</p>))}</div>
        <div>
          <TextField
            label={t('Reason for closing account')}
            margin="normal"
            value={reasonForCloseAccount}
            multiline
            rows={3}
            rowsMax={9}
            onChange={event => setReasonForCloseAccount(event.target.value)}
            inputProps={{
              'data-test-id': 'accountClosureReason',
            }}
            fullWidth
          />
        </div>
      </DialogContent>
      <DialogActions>
        {onCancel && (
          <Button
            onClick={onCancel}
            variant="outlined"
            color="primary"
            data-test-id={testIds.cancel || 'cancel'}
            fullWidth
          >
            {t('Cancel')}
          </Button>
        )}
        <Button
          onClick={() => onConfirm(reasonForCloseAccount)}
          variant="contained"
          color="primary"
          data-test-id={testIds.confirm || 'confirm'}
          fullWidth
        >
          {t('Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CloseAccountModal.propTypes = {
  message: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  testIds: PropTypes.objectOf(PropTypes.any),
};

export default CloseAccountModal;
