import { BackgroundType, backgroundImageRoot, backgrounds } from 'constants/portal';
import styled from 'styled-components';

const MainContainer = styled.div`
  display: grid;
  grid-template-areas:
    "brand"
    "cover"
    "products"
  ;

  @media (min-width: 768px) {
    grid-template-areas:
      "cover brand"
      "cover products"
    ;
    grid-template-columns: 1fr 1fr;
    height: 100vh;
  }
`;

const HeadlineContainer = styled.section<{ backgroundType: BackgroundType }>`
  align-items: end;
  display: grid;
  grid-area: cover;

  @media screen and (min-width: 768px) {
    height: 100vh;
    position: fixed;
    width: 50%;
  }

  ${(props) => {
    if (props?.backgroundType) {
      const backgroundImage = backgrounds.find(background => background.type === props.backgroundType)?.image;

      return `
        background: url('${backgroundImageRoot}/${backgroundImage}_l.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      `;
    }
    return `
      background-color: #FAF9FB;
    `;
  }}
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: products;
  padding: 1rem;

  @media screen and (min-width: 768px) {
    padding: 1rem 2rem;
  }
`;

const HeadlineBlock = styled.div<{ backgroundColor?: string }>`
  background-color: ${props => props?.backgroundColor || '#14213d'};
  color: #FFF;
  display: flex;
  flex-direction: column-reverse;
  font-size: calc(16px + 20 * ((100vw - 320px) / 680));;
  line-height: 1.2;
  min-height: 100px;
  padding: 1rem;
  width: 50%;


  @media screen and (min-width: 768px) {
    display: initial;
    line-height: 1;
    margin: 2rem;
    min-height: initial;
    padding: 2rem;
    width: fit-content;
  }
`;

const Logo = styled.img`
  color: #14213d;
  grid-area: brand;
  font-size: 18px;
  margin: 1rem 0 1rem 1rem;
  max-height: 80px;
  max-width: 200px;

  @media screen and (min-width: 768px) {
    margin-left: 2rem;
    max-height: 120px;
    max-width: 300px;
  }
`;

const Categories = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  margin-bottom: 2rem;

`;

const Footer = styled.footer`
  border-top: solid 1px #D8D8D8;
  margin-top: auto;
  padding: 1rem 0;
`;

export default {
  MainContainer,
  HeadlineContainer,
  DetailsContainer,
  HeadlineBlock,
  Logo,
  Categories,
  Footer,
};
