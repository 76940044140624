import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { matchType, historyType } from 'types/reactRouter';
import { withRouter } from 'react-router-dom';
import AdminCataloguePackageListItem from './AdminCataloguePackageListItem';


export class AdminCataloguePackageListItemContainer extends Component {
  static propTypes = {
    item: PropTypes.objectOf(PropTypes.any),
    match: matchType.isRequired,
    onStatusChange: PropTypes.func,
    history: historyType,
  }

  constructor(props) {
    super(props);

    this.state = {
      anchorElement: null,
    };
  }

  handleMenuOpen = (event) => {
    this.setState({ anchorElement: event.currentTarget });
  }

  handleMenuClose = () => {
    this.setState({ anchorElement: null });
  }

  render() {
    const {
      match,
      onStatusChange,
      item,
      history,
    } = this.props;
    const { anchorElement } = this.state;
    return (
      <AdminCataloguePackageListItem
        item={item}
        match={match}
        anchorElement={anchorElement}
        onMenuOpen={this.handleMenuOpen}
        onMenuClose={this.handleMenuClose}
        onStatusChange={onStatusChange}
        history={history}
      />
    );
  }
}

export default withRouter(AdminCataloguePackageListItemContainer);
