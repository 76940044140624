export const knowledgeOwlUrlLookup = [
  { arrangerLocation: 'admin', knowledgeOwlPath: 'help/admin' },
  { arrangerLocation: 'first-call', knowledgeOwlPath: 'help/first-call' },
  { arrangerLocation: 'case', knowledgeOwlPath: 'help/case' },
  { arrangerLocation: 'tasks', knowledgeOwlPath: 'help/tasks' },
  { arrangerLocation: 'accounts', knowledgeOwlPath: 'help/accounts' },
  { arrangerLocation: 'case/*/arrangement', knowledgeOwlPath: 'help/cases' },
  { arrangerLocation: 'case/*/deceased/*', knowledgeOwlPath: 'help/case-deceased' },
  { arrangerLocation: 'case/*/bereaved', knowledgeOwlPath: 'help/case-bereaved' },
  { arrangerLocation: 'catalogue', knowledgeOwlPath: 'help/catalogue' },
  { arrangerLocation: 'catalogue/services', knowledgeOwlPath: 'help/services' },
  { arrangerLocation: 'catalogue/services/create', knowledgeOwlPath: 'help/services-create' },
  { arrangerLocation: 'catalogue/services/*', knowledgeOwlPath: 'help/services-edit' },
  { arrangerLocation: 'catalogue/products', knowledgeOwlPath: 'help/products' },
  { arrangerLocation: 'catalogue/products/create', knowledgeOwlPath: 'help/products-create' },
  { arrangerLocation: 'catalogue/products/*', knowledgeOwlPath: 'help/products-edit' },
  { arrangerLocation: 'catalogue/packages', knowledgeOwlPath: 'help/packages' },
  { arrangerLocation: 'catalogue/packages/create', knowledgeOwlPath: 'help/packages-create' },
  { arrangerLocation: 'catalogue/packages/*', knowledgeOwlPath: 'help/packages-edit' },
  { arrangerLocation: 'homes', knowledgeOwlPath: 'help/homes' },
  { arrangerLocation: 'homes/create', knowledgeOwlPath: 'help/homes-create' },
  { arrangerLocation: 'homes/*', knowledgeOwlPath: 'help/homes-edit' },
  { arrangerLocation: 'homegroups', knowledgeOwlPath: 'help/groups' },
  { arrangerLocation: 'staff-members', knowledgeOwlPath: 'help/staff' },
  { arrangerLocation: 'staff-members/create', knowledgeOwlPath: 'help/staff-create' },
  { arrangerLocation: 'staff-members/*', knowledgeOwlPath: 'help/staff-edit' },
  { arrangerLocation: 'directory-listings', knowledgeOwlPath: 'help/directory' },
  { arrangerLocation: 'directory-listings/create', knowledgeOwlPath: 'help/directory-create' },
  { arrangerLocation: 'directory-listings/*', knowledgeOwlPath: 'help/directory-edit' },
  { arrangerLocation: 'templates', knowledgeOwlPath: 'help/templates' },
  { arrangerLocation: 'templates/estimate', knowledgeOwlPath: 'help/template' },
  { arrangerLocation: 'templates/letters', knowledgeOwlPath: 'help/letters' },
  { arrangerLocation: 'case/*/arrangement/*/packages', knowledgeOwlPath: 'help/package-select' },
  { arrangerLocation: 'case/*/arrangement/*/care', knowledgeOwlPath: 'help/care' },
  { arrangerLocation: 'case/*/arrangement/*/crematorium', knowledgeOwlPath: 'help/crematorium' },
  { arrangerLocation: 'case/*/arrangement/*/service-venue', knowledgeOwlPath: 'help/service' },
  { arrangerLocation: 'case/*/arrangement/*/burial', knowledgeOwlPath: 'help/burial' },
  { arrangerLocation: 'case/*/arrangement/*/cortege', knowledgeOwlPath: 'help/vehicles' },
  { arrangerLocation: 'case/*/arrangement/*/coffins', knowledgeOwlPath: 'help/coffins' },
  { arrangerLocation: 'case/*/arrangement/*/urns', knowledgeOwlPath: 'help/urns' },
  { arrangerLocation: 'case/*/arrangement/*/officiants', knowledgeOwlPath: 'help/officiants' },
  { arrangerLocation: 'case/*/arrangement/*/music', knowledgeOwlPath: 'help/music' },
  { arrangerLocation: 'case/*/arrangement/*/flowers', knowledgeOwlPath: 'help/flowers' },
  { arrangerLocation: 'case/*/arrangement/*/other', knowledgeOwlPath: 'help/additional' },
  { arrangerLocation: 'case/*/arrangement/*/memorialisation', knowledgeOwlPath: 'help/memorialisation' },
  { arrangerLocation: 'case/*/arrangement/*/obituary', knowledgeOwlPath: 'help/obituary' },
  { arrangerLocation: 'case/*/arrangement/*/estimate', knowledgeOwlPath: 'help/view-estimate' },
  { arrangerLocation: 'tasks', knowledgeOwlPath: 'help/tasks' },
  { arrangerLocation: 'import-export', knowledgeOwlPath: 'help/reports-report' },
  { arrangerLocation: 'accounts/*', knowledgeOwlPath: 'help/account' },
  { arrangerLocation: 'lite-arrangement', knowledgeOwlPath: 'help/arrangements-list' },
  { arrangerLocation: 'lite-arrangement/create', knowledgeOwlPath: 'help/lite-arrangement-create' },
  { arrangerLocation: 'lite-arrangement/*/catalogue/packages', knowledgeOwlPath: 'help/packages-lite' },
  { arrangerLocation: 'lite-arrangement/*/catalogue/service-venue', knowledgeOwlPath: 'help/service-venues-lite' },
  { arrangerLocation: 'lite-arrangement/*/catalogue/crematorium', knowledgeOwlPath: 'help/crematorium-lite' },
  { arrangerLocation: 'lite-arrangement/*/catalogue/burial', knowledgeOwlPath: 'help/burial-lite' },
  { arrangerLocation: 'lite-arrangement/*/catalogue/cortege', knowledgeOwlPath: 'help/cortege-lite' },
  { arrangerLocation: 'lite-arrangement/*/catalogue/coffins', knowledgeOwlPath: 'help/coffins-lite' },
  { arrangerLocation: 'lite-arrangement/*/catalogue/urns', knowledgeOwlPath: 'help/urns-lite' },
  { arrangerLocation: 'lite-arrangement/*/catalogue/officiants', knowledgeOwlPath: 'help/officiants-lite' },
  { arrangerLocation: 'lite-arrangement/*/catalogue/flowers', knowledgeOwlPath: 'help/flowers-lite' },
  { arrangerLocation: 'lite-arrangement/*/catalogue/other', knowledgeOwlPath: 'help/other-services-lite' },
  { arrangerLocation: 'lite-arrangement/*/catalogue/memorialisation', knowledgeOwlPath: 'help/memorialisation-lite' },
  { arrangerLocation: 'lite-arrangement/*/catalogue/estimate', knowledgeOwlPath: 'help/view-estimate-lite' },
  { arrangerLocation: 'catalogue/settings', knowledgeOwlPath: 'help/settings' },
  { arrangerLocation: 'catalogue/settings/*', knowledgeOwlPath: 'help/settings' },
  { arrangerLocation: 'business-intelligence', knowledgeOwlPath: 'help/business-intelligence' },
];
