import React from 'react';
import PropTypes from 'prop-types';
import { bereavedPersonConnectionType } from 'types/bereavement';
import { directoryListingType } from 'types/directoryListing';
import BereavedPersonCard from 'components/common/cards/BereavedPersonCard';
import DirectoryListingCard from 'components/common/cards/DirectoryListingCard';
import PayeeListModal from 'components/account/PayeeListModal';
import PayeeFormModal from 'components/account/PayeeFormModal';
import Button from '@material-ui/core/Button';

import rcStyles from './styles.scss';

const PayeeDetails = ({
  selectedBereaved,
  selectedDirectoryListing,
  bereavedPeopleConnections,
  isPayeeListModalOpen,
  isPayeeFormModalOpen,
  formData,
  onPayeeChange,
  togglePayeeListModal,
  togglePayeeFormModal,
  createBereavedPerson,
  editBereavedPerson,
  isEditingPayee,
  errors,
}) => (
  <div className={rcStyles.v_spacing}>
    <div>
      <Button
        color="primary"
        variant="contained"
        onClick={togglePayeeListModal}
        data-test-id="changePayee"
      >
        Edit Payee
      </Button>
    </div>
    <div className={rcStyles.colspan2}>
      {selectedBereaved && (
        <BereavedPersonCard
          bereavedPersonConnection={selectedBereaved}
          errors={errors}
          onEdit={togglePayeeFormModal}
        />
      )}
      {selectedDirectoryListing && (
        <DirectoryListingCard
          directoryListing={selectedDirectoryListing}
          errors={errors}
        />
      )}
    </div>
    <PayeeListModal
      bereavedPeopleConnections={bereavedPeopleConnections || []}
      isOpen={isPayeeListModalOpen}
      selectedBereaved={selectedBereaved}
      selectedDirectoryListing={selectedDirectoryListing}
      payeeType={formData.payeeType}
      savePayeeChange={onPayeeChange}
      onClose={togglePayeeListModal}
      createBereavedPerson={createBereavedPerson}
      editBereavedPerson={editBereavedPerson}
    />
    <PayeeFormModal
      isOpen={isPayeeFormModalOpen}
      isAdding={false}
      isSaving={false}
      isEditingPayee={isEditingPayee}
      payee={selectedBereaved}
      onClose={togglePayeeFormModal}
      onSave={editBereavedPerson}
    />
  </div>
);

PayeeDetails.propTypes = {
  selectedBereaved: PropTypes.oneOfType([
    directoryListingType,
    bereavedPersonConnectionType,
  ]),
  selectedDirectoryListing: directoryListingType,
  isPayeeListModalOpen: PropTypes.bool.isRequired,
  isPayeeFormModalOpen: PropTypes.bool.isRequired,
  formData: PropTypes.objectOf(PropTypes.any),
  onPayeeChange: PropTypes.func.isRequired,
  togglePayeeListModal: PropTypes.func.isRequired,
  createBereavedPerson: PropTypes.func.isRequired,
  editBereavedPerson: PropTypes.func.isRequired,
  bereavedPeopleConnections: PropTypes.arrayOf(directoryListingType),
  togglePayeeFormModal: PropTypes.func.isRequired,
  isEditingPayee: PropTypes.bool.isRequired,
  errors: PropTypes.objectOf(PropTypes.any),
};

export default PayeeDetails;
