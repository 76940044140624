import uuidv5 from 'uuid/v5';

import { CorrespondenceCategory, CorrespondenceType } from 'constants/ts/tenant';
import { createSha1HashFromString, generateHexId } from 'services/utils';
import { TemplateDefinition } from 'types/ts/tenant';

export const getEmptyCustomTemplate = () => ({
  id: generateHexId(),
  type: CorrespondenceType.CUSTOM,
  category: CorrespondenceCategory.ACCOUNT,
  title: null,
});

export const getCorrespondenceIdForTemplate = (tenantId: string, template: Partial<TemplateDefinition>) => {
  if (template?.id) {
    return template.id;
  }
  if (template.type !== CorrespondenceType.CUSTOM) {
    return createSha1HashFromString(uuidv5(template.type, tenantId));
  }
  return generateHexId();
};
