import React from 'react';
import { Link } from 'react-router-dom';

import CloseIcon from 'components/portal/CloseIcon';

import { Props } from './index.types';
import Styled from './index.styled';

const CatalogueDrawer: React.FC<Props> = ({
  categories, activeCategory, isOpen, linkPrefix = '', onClose,
}: Props) => (
  <Styled.Drawer
    anchor="left"
    open={isOpen}
    onClose={onClose}
  >
    <Styled.Header>
      <CloseIcon
        onClick={onClose}
        data-test-id="closeDrawerButton"
      />
    </Styled.Header>
    <Styled.Body>
      {categories.map(item => (
        <Styled.CategoryRow key={item.category}>
          <Styled.CategoryImage imagePath={item.imagePath} />
          <Link to={`${linkPrefix}${item.path}`} onClick={onClose} data-test-id="catalogueDrawerLink">
            <Styled.Link isActive={item.category === activeCategory}>{item.name}</Styled.Link>
          </Link>
        </Styled.CategoryRow>
      ))}
    </Styled.Body>
  </Styled.Drawer>
);

export default CatalogueDrawer;
