import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Button from '@material-ui/core/Button';
import ScreenLayout from 'components/common/ScreenLayout';
import styles from 'scss/main.scss';
import classNames from 'classnames';
import { catalogueCategoryType } from 'types/catalogue';
import UnsortableCategoryList from 'components/catalogue/UnsortableCategoryList';

const AdminArrangementSettingsCategoryScreen = ({
  category,
  subCategories,
  onEdit,
  onCancel,
}) => (
  <ScreenLayout
    title={t(`${category.name}`)}
    dashboardBannerProps={{
      breadcrumbs: [
        { path: '/', title: t('Dashboard') },
        { path: '/catalogue', title: t('Brochure management') },
        { path: '/catalogue/arrangement-settings', title: t('Arrangement settings') },
        { path: `/catalogue/arrangement-settings/${category.path}`, title: t(category.name) },
      ],
      description: t('Choose a category from the list below and set the order in which the items should be displayed in the arrangement view.'),
    }}
  >
    <div className={styles.o_view}>
      <div className={classNames(
        styles.o_view__main,
        styles.o_block_content,
      )}
      >
        <div className={styles.u_push__ends}>
          <UnsortableCategoryList
            axis="xy"
            categories={subCategories || []}
            onEditCategory={onEdit}
          />
        </div>
        <div className={classNames(
          styles.o_button_bar,
          styles.has_top_border,
        )}
        >
          <Button
            onClick={onCancel}
            variant="outlined"
            color="primary"
            data-test-id="cancelOrderCategories"
            fullWidth
          >
            {t('Cancel')}
          </Button>
        </div>
      </div>
    </div>
  </ScreenLayout>
);

AdminArrangementSettingsCategoryScreen.propTypes = {
  category: catalogueCategoryType.isRequired,
  subCategories: PropTypes.arrayOf(catalogueCategoryType).isRequired,
  onEdit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default AdminArrangementSettingsCategoryScreen;
