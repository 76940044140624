import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AdminPackageCatalogueItemSelection from './AdminPackageCatalogueItemSelection';

export class AdminPackageCatalogueItemSelectionContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPackageItemModalOpen: false,
      isServiceCategory: false,
      isDisbursementCategory: false,
    };
  }

  handleOpenPackageItemModal = (isServiceCategory, isDisbursementCategory) => {
    this.setState({
      isPackageItemModalOpen: true,
      isServiceCategory,
      isDisbursementCategory,
    });
  }

  handleClosePackageItemModal = () => {
    this.setState({
      isPackageItemModalOpen: false,
      isServiceCategory: false,
      isDisbursementCategory: false,
    });
  }

  render() {
    const {
      isPackageItemModalOpen,
      isServiceCategory,
      isDisbursementCategory,
    } = this.state;
    const {
      packageItem,
      selectedCatalogueItems,
      onMaxQuantityChange,
      onRemoveCatalogueItem,
      onSaveCatalogueItemsSelect,
    } = this.props;
    return (
      <AdminPackageCatalogueItemSelection
        packageItem={packageItem}
        selectedCatalogueItems={selectedCatalogueItems}
        isPackageItemModalOpen={isPackageItemModalOpen}
        isServiceCategory={isServiceCategory}
        isDisbursementCategory={isDisbursementCategory}
        onMaxQuantityChange={onMaxQuantityChange}
        onRemoveCatalogueListItem={onRemoveCatalogueItem}
        onSaveCatalogueItemsSelect={onSaveCatalogueItemsSelect}
        setIsServiceCategory={this.setIsServiceCategory}
        setIsDisbursementCategory={this.setIsDisbursementCategory}
        onOpenPackageItemModal={this.handleOpenPackageItemModal}
        onClosePackageItemModal={this.handleClosePackageItemModal}
      />
    );
  }
}

AdminPackageCatalogueItemSelectionContainer.propTypes = {
  packageItem: PropTypes.objectOf(PropTypes.any),
  selectedCatalogueItems: PropTypes.arrayOf(PropTypes.shape({})),
  onMaxQuantityChange: PropTypes.func.isRequired,
  onRemoveCatalogueItem: PropTypes.func.isRequired,
  onSaveCatalogueItemsSelect: PropTypes.func.isRequired,
};

export default AdminPackageCatalogueItemSelectionContainer;
