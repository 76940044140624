import React from 'react';
import i18next from 'i18next';

import { MediaVariant } from 'constants/ts/media';
import { getUriForMediaByVariant, formatCurrency } from 'services/utils';
import { VariantStatus } from 'types/ts/catalogue';
import { getLowestSalePriceFromVariants, shouldShowFromPrice } from 'services/utils/catalogue';
import { Props } from './index.types';
import Styled from './index.styled';

const CatalogueItemCard: React.FC<Props> = ({ item, active, onClick }: Props) => {
  const activeVariants = item?.variants
    ?.filter(({ status }) => status === VariantStatus.ACTIVE);

  const lowestPrice = getLowestSalePriceFromVariants(activeVariants);
  const lowestPricedVariants = activeVariants?.filter(variant => variant.prices.sale.amount === lowestPrice);
  const displayPrice = (lowestPricedVariants?.[0]?.prices) ? formatCurrency(lowestPricedVariants[0].prices.sale) : null;

  const imageUri = item.images
    && getUriForMediaByVariant(item.images[0], MediaVariant.FIT_900_X_900);

  return (
    <Styled.Card
      className={active ? 'active' : ''}
      imageUri={imageUri}
      onClick={() => onClick(item)}
      data-test-id="catalogueItemCard"
    >
      <div className="card_image" />
      <div className="card_detail">
        <div className="card_detail__title">
          {item.title}
        </div>
        <div className="card_detail__price">
          {shouldShowFromPrice(activeVariants) && (
            <div className="card_detail__price__from">
              {`${i18next.t('From')} `}
            </div>
          )}
          <div className="card_detail__price__amount">
            {displayPrice}
          </div>
        </div>
      </div>
    </Styled.Card>
  );
};

export default CatalogueItemCard;
