import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import TaskDetails from 'components/tasks/TaskDetails';
import TaskProgress from 'components/tasks/TaskProgress';
import StaffMemberLookup from 'components/common/lookups/StaffMemberLookup';
import { taskType } from 'types/task';
import { status } from 'constants/task';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const TaskModal = ({
  onClose,
  onSave,
  task,
  serviceData,
  onUpdateProgressField,
  onUpdateAssignee,
  onUpdateNote,
  onStatusChange,
  isAllowedToDismissTasks,
  disabled,
  isOpen,
  errors,
}) => (
  <Dialog
    onClose={onClose}
    aria-labelledby="task-title"
    fullScreen
    scroll="paper"
    open={isOpen}
    TransitionComponent={Transition}
    disableBackdropClick
    disableEscapeKeyDown
  >
    <div
      className={classNames(
        styles.c_dialog__body,
        styles.c_dialog__overflow,
      )}
    >
      <DialogTitle
        disableTypography
        className={styles.dialog_title_bar}
      >
        <IconButton
          className={classNames(
            styles.o_button__secondary,
            styles.is_close,
          )}
          color="primary"
          variant="contained"
          aria-label="Close"
          onClick={onClose}
          data-test-id="feeClose"
        >
          <CloseIcon />
        </IconButton>
        <div className={styles.is_title}>
          {task.title}
        </div>
        <div className={styles.is_actions}>
          <Button
            onClick={onSave}
            type="submit"
            variant="contained"
            data-test-id="saveTask"
            color="secondary"
            fullWidth
            className={styles.o_button__secondary}
          >
            {t('Save')}
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>
        <div
          className={classNames(
            rcStyles.v_spacing,
            styles.o_block_content,
          )}
        >
          <h3 className={classNames(
            styles.long_primer,
            styles.is_black,
          )}
          >
            {t('Task status')}
          </h3>
          <RadioGroup
            aria-label={t('Task status')}
            value={task.status}
            onChange={event => onStatusChange(event.target.value)}
            className={styles.fb__row}
            data-test-id="taskStatusRadioGroup"
          >
            <FormControlLabel
              value={status.PENDING}
              control={(
                <Radio
                  color="primary"
                  data-test-id={status.PENDING}
                />
              )}
              label={t('Pending')}
              className={classNames(
                styles.c_flag__radio,
                task.status === 'PENDING' ? styles.c_flag__PENDING : styles.c_flag,
              )}
              classes={{ label: styles.c_flag__radio_label }}
            />
            <FormControlLabel
              value={status.COMPLETED}
              control={<Radio color="primary" data-test-id={status.COMPLETED} />}
              label={t('Completed')}
              className={classNames(
                styles.c_flag__radio,
                task.status === 'COMPLETED' ? styles.c_flag__COMPLETED : styles.c_flag,
              )}
              classes={{ label: styles.c_flag__radio_label }}
            />
            <FormControlLabel
              value={status.DISMISSED}
              control={<Radio color="primary" data-test-id={status.DISMISSED} />}
              label={t('Dismissed')}
              className={classNames(
                styles.c_flag__radio,
                task.status === 'DISMISSED' ? styles.c_flag__DISMISSED : styles.c_flag,
              )}
              classes={{ label: styles.c_flag__radio_label }}
              disabled={!isAllowedToDismissTasks}
            />
          </RadioGroup>
          {task.description && (
            <div>
              <h3 className={classNames(
                styles.long_primer,
                styles.is_black,
                styles.u_push__top,
              )}
              >
                {t('Task description')}
              </h3>
              <div className={classNames(
                styles.brevier,
              )}
              >
                {task.description}
              </div>
            </div>
          )}
          <TaskDetails
            task={task}
            serviceData={serviceData}
          />
          {task.progressJson && task.progressJson.length > 0 && (
            <TaskProgress
              task={task}
              onUpdate={onUpdateProgressField}
              disabled={disabled}
              errors={errors.progressJson || {}}
            />
          )}
          <h3 className={styles.long_primer}>
            {t('Task assignee')}
          </h3>
          <StaffMemberLookup
            label={t('Assignee')}
            value={task.assignee}
            onChange={onUpdateAssignee}
            disabled={disabled}
            data-test-id="staffMemberLookUp"
          />
          <h3 className={styles.long_primer}>
            {t('Notes')}
          </h3>
          <TextField
            label={t('Notes')}
            value={task.note || ''}
            onChange={event => onUpdateNote(event.target.value)}
            fullWidth
            inputProps={{
              'data-test-id': 'note',
            }}
            disabled={disabled}
          />
        </div>
      </DialogContent>
    </div>
  </Dialog>
);

TaskModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  task: taskType,
  serviceData: PropTypes.objectOf(PropTypes.any),
  onUpdateProgressField: PropTypes.func.isRequired,
  onUpdateAssignee: PropTypes.func.isRequired,
  onUpdateNote: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  isAllowedToDismissTasks: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default TaskModal;
