import PropTypes from 'prop-types';
import { emailsType, addressType, phonesType } from 'types/common';
import { mediaType } from 'types/media';

export const directoryListingStatusType = PropTypes.string;

export const directoryListingContactType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  emails: emailsType,
  phones: phonesType,
});

export const directoryListingType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  address: addressType,
  email: emailsType,
  phones: phonesType,
  websites: PropTypes.arrayOf(PropTypes.string),
  imageId: PropTypes.string,
  isSupplier: PropTypes.bool,
  note: PropTypes.string,
  status: directoryListingStatusType,
  contacts: PropTypes.arrayOf(directoryListingContactType),
  image: mediaType,
});
