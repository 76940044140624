import React from 'react';
import { connect } from 'react-redux';
import IconAccountBalance from '@material-ui/icons/AccountBalance';
import IconAssignment from '@material-ui/icons/Assignment';
import IconBuild from '@material-ui/icons/Build';
import IconBusiness from '@material-ui/icons/Business';
import IconEmail from '@material-ui/icons/Email';
import IconHome from '@material-ui/icons/Home';
import IconLetter from 'icons/IconLetter';
import IconPeople from '@material-ui/icons/People';

import { scopes as permissions } from 'constants/scopes';
import { featureFlags } from 'constants/features';
import { hasPermissions } from 'services/utils';
import { RootState } from 'types/ts/state';
import { TenantFeature } from 'types/ts/tenant';

import { Props } from './index.types';
import AdminDashboardScreen from './AdminDashboardScreen';

class AdminDashboardScreenContainer extends React.PureComponent<Props> {
  render() {
    const { scopes, availableFeatures, tenantHasGivenConsentToAXeroApp } = this.props;

    const cards = [
      {
        title: 'Funerals',
        text: 'Manage your current funerals and search for previous clients',
        icon: IconAssignment,
        link: '/case',
        testId: 'cases',
        disabled: !availableFeatures.includes(featureFlags.CASES_SCREEN)
          || !hasPermissions([permissions.VIEW_BEREAVEMENTS_TILE], scopes),
      },
      {
        title: 'Arrangements',
        text: 'Manage your current arrangements',
        icon: IconAssignment,
        link: '/lite-arrangement',
        testId: 'arrangements',
        disabled: !availableFeatures.includes(featureFlags.LITE_ARRANGEMENTS_SCREEN)
          || !hasPermissions([permissions.VIEW_LITE_ARRANGEMENTS_TILE], scopes),
      },
      {
        title: 'Brochure',
        text: 'Create and edit products, services and packages for your online brochure',
        icon: IconBuild,
        link: '/catalogue',
        testId: 'catalogue',
        disabled: !hasPermissions([
          permissions.VIEW_SERVICES_TILE,
          permissions.VIEW_PRODUCTS_TILE,
          permissions.VIEW_PACKAGES_TILE,
        ], scopes),
      },
      {
        title: 'Homes',
        text: 'Create, edit and manage your homes',
        icon: IconHome,
        link: '/homes',
        testId: 'homes',
        disabled: !hasPermissions([permissions.VIEW_ORGANISATIONAL_UNITS_TILE], scopes),
      },
      {
        title: 'Staff',
        text: 'Manage your staff roles, status and availability, and assign them to your homes',
        icon: IconPeople,
        link: '/staff-members',
        testId: 'staff',
        disabled: !hasPermissions([permissions.VIEW_STAFF_TILE], scopes),
      },
      {
        title: 'Business Directory',
        text: 'Manage your business directory listings for third party vendors and suppliers',
        icon: IconBusiness,
        link: '/directory-listings',
        testId: 'directory-listings',
        disabled: !availableFeatures.includes(featureFlags.DIRECTORY_LISTINGS_SCREEN)
          || !hasPermissions([permissions.VIEW_DIRECTORY_LISTING_TILE], scopes),
      },
      {
        title: 'Templates',
        text: 'Letter templates for bereaved correspondence',
        icon: IconLetter,
        link: '/templates',
        testId: 'templates',
        disabled: !availableFeatures.includes(featureFlags.CORRESPONDENCE_TEMPLATES_SCREEN)
          || !hasPermissions([permissions.VIEW_TENANTS_TILE], scopes),
      },
      {
        title: 'Templates',
        text: 'Estimate templates for bereaved correspondence',
        icon: IconLetter,
        link: '/templates/estimate',
        testId: 'templates',
        disabled: availableFeatures.includes(featureFlags.CORRESPONDENCE_TEMPLATES_SCREEN)
          || !availableFeatures.includes(featureFlags.ESTIMATE_TEMPLATES_SCREEN)
          || !hasPermissions([permissions.VIEW_TENANTS_TILE], scopes),
      },
      {
        title: 'Import/Export',
        text: 'Export data from Arranger or import brochure items',
        icon: IconEmail,
        link: 'import-export',
        testId: 'reports',
        disabled: !availableFeatures.includes(featureFlags.IMPORT_EXPORT_SCREEN)
          || !hasPermissions([permissions.REPORTS_READ], scopes),
      },
      {
        title: 'Finance',
        text: 'View and manage accounts',
        icon: IconAccountBalance,
        link: '/accounts',
        testId: 'finance',
        disabled: !availableFeatures.includes(featureFlags.ACCOUNTS_SCREEN)
          || !hasPermissions([permissions.BEREAVEMENT_ACCOUNT_READ], scopes)
          || (availableFeatures.includes(featureFlags.XERO_INTEGRATION) && !tenantHasGivenConsentToAXeroApp),
      },
    ];

    return (
      <AdminDashboardScreen
        cards={cards}
      />
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  scopes: state.userStore.user.policy.scopes,
  availableFeatures: state.userStore.user.tenantFeatureFlags as TenantFeature[],
  isOnline: state.userStore.isOnline,
  tenantHasGivenConsentToAXeroApp: state.userStore.user.tenantHasGivenConsentToAXeroApp,
});

export default connect(mapStateToProps)(AdminDashboardScreenContainer);
