import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';

import { reactRefType } from 'types/common';

import styles from 'scss/main.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const EditCustomTemplateModal = ({
  formData,
  dialogRef,
  errors,
  formRefs,
  isOpen,
  onCancel,
  onChange,
  onSave,
}) => (
  <Dialog
    onClose={onCancel}
    aria-labelledby={t('editCustomTemplateModal')}
    open={isOpen}
    fullWidth
    maxWidth="sm"
    TransitionComponent={Transition}
    disableBackdropClick
    disableEscapeKeyDown
  >
    <div ref={dialogRef} className={styles.c_dialog__body}>
      <DialogTitle
        disableTypography
        className={classNames(
          styles.c_dialog__header,
        )}
      >
        <div className={styles.c_dialog__title} data-test-id="editCustomTemplateModalTitle">
          {t('Edit template name')}
        </div>
      </DialogTitle>
      <DialogContent
        className={classNames(
          styles.c_dialog__content,
          styles.u_hard__bottom,
          styles.o_fb,
          styles.u_push__top,
        )}
      >
        <div
          ref={formRefs.title}
          className={classNames(
            styles.o_fb__item,
            styles.u_push__bottom_double,
          )}
        >
          <TextField
            label={t('Letter template name *')}
            margin="normal"
            value={formData.title || ''}
            onChange={event => onChange('title', event.target.value)}
            inputProps={{
              'data-test-id': 'title',
            }}
            error={errors && !!errors.title}
            helperText={errors && errors.title}
            fullWidth
          />
        </div>
        <div className={classNames(
          styles.o_fb__item__half,
          styles.u_push__ends,
        )}
        >
          <Button
            onClick={onCancel}
            variant="outlined"
            color="primary"
            data-test-id="cancelButton"
            fullWidth
          >
            {t('Cancel')}
          </Button>
        </div>
        <div className={classNames(
          styles.o_fb__item__half,
          styles.u_push__ends,
        )}
        >
          <Button
            onClick={onSave}
            variant="contained"
            color="primary"
            data-test-id="saveButton"
            fullWidth
          >
            {t('Save')}
          </Button>
        </div>
      </DialogContent>
    </div>
  </Dialog>
);

EditCustomTemplateModal.propTypes = {
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
  dialogRef: reactRefType.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any),
};

export default EditCustomTemplateModal;
