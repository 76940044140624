import React, { Component } from 'react';
import { t } from 'i18next';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';

import { getCorrespondenceIdForTemplate } from 'services/utils/templates';
import { consolidateReport } from 'transforms/templateReport';
import { apolloClientType } from 'types/apollo';
import { nameType } from 'types/common';
import { correspondenceTemplateType } from 'types/correspondence';

import {
  getDefaultTemplateForConfirmationOfEstimateAndServiceInformationBurial,
  getDefaultTemplateForConfirmationOfEstimateAndServiceInformationCrematorium,
  getDefaultTemplateForCustom,
  getDefaultTemplateForEstimateConfirmationToProceed,
  getDefaultTemplateForObituaryDetails,
  getDefaultTemplateForOfficiantConfirmationBurial,
  getDefaultTemplateForOfficiantConfirmationCremation,
  getDefaultTemplateForPaymentOverdue,
  getDefaultTemplateForPaymentReceipt,
  getDefaultTemplateForPrePaymentInFullRequest,
  getDefaultTemplateForStatementOfAccount,
  getDefaultTemplateForEstimateAtNeed,
  getDefaultTemplateForEstimatePreNeed,
  getDefaultTemplateForInvoice,
  getDefaultTemplateForProformaInvoice,
} from './defaultLetterTemplateQueries.gql';

import {
  editCorrespondenceTemplate,
  setTemplateForCorrespondence,
} from './mutations.gql';

import {
  getPreviewLetterById,
  getPreviewLetterByType,
  validateCorrespondenceMedia,
} from './queries.gql';

import TemplateItem from './TemplateItem';

class TemplateItemContainer extends Component {
  static propTypes = {
    template: correspondenceTemplateType.isRequired,
    allowDefaultDownload: PropTypes.bool,
    client: apolloClientType.isRequired,
    tenantId: PropTypes.string.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    onUpdateTemplateDefinition: PropTypes.func.isRequired,
    isValidationModalOpen: false,
    userId: PropTypes.string.isRequired,
    userName: nameType,
  };

  constructor(props) {
    super(props);
    this.state = {
      isTemplateDownloading: false,
      isDefaultDownloading: false,
      isPreviewDownloading: false,
      isUploading: false,
      report: [],
    };
  }

  handleDownloadDefault = async ({ disableLoader }) => {
    const { client, tenantId, template } = this.props;

    this.setState({ isDefaultDownloading: !disableLoader });

    const { data } = await client.query({
      query: this.getDefaultTemplateQueryForKey(template.key),
      variables: {
        tenantId,
      },
    });

    this.setState({
      isDefaultDownloading: false,
      isTemplateDownloading: false,
    });

    const templateKeyId = template.key.replace('Id', '');
    const {
      correspondence: {
        defaultTemplates,
      },
    } = data;
    const { uri } = defaultTemplates[templateKeyId];

    window.open(uri, uri).focus();
  };

  handleDownload = async () => {
    const { template, enqueueSnackbar } = this.props;

    if (template.media && template.media.uri) {
      const { uri } = template.media;
      return window.open(uri, uri).focus();
    }

    enqueueSnackbar(
      t('There is no existing template so the default template has been downloaded instead.'),
      { variant: 'warning' },
    );

    return this.handleDownloadDefault({ disableLoader: true });
  };

  handlePreview = async () => {
    const { client, tenantId, template } = this.props;

    this.setState({ isPreviewDownloading: true });

    const query = template.id ? getPreviewLetterById : getPreviewLetterByType;
    const key = template.id ? 'id' : 'type';
    const value = template.id ? template.id : template.type;
    const variables = { tenantId, [key]: value };

    const { data } = await client.query({
      query,
      variables,
    });
    const { tenant = {} } = data || {};
    const { correspondencePreview = {} } = tenant || {};
    const { uri } = correspondencePreview || {};

    this.setState({ isPreviewDownloading: false });

    if (uri) {
      window.open(uri, uri).focus();
    }
  };

  handleUploadStart = () => {
    this.setState({
      isUploading: true,
      isValidationModalOpen: true,
    });
  }

  handleUploadComplete = async ([media]) => {
    const { client } = this.props;

    const response = await client.query({
      query: validateCorrespondenceMedia,
      variables: {
        mediaId: media.id,
      },
    });

    const report = response?.data?.validateCorrespondenceMedia;

    if (!report) {
      return;
    }

    this.setState({
      isUploading: false,
      report: consolidateReport(report),
      isValidationModalOpen: true,
    });

    if (report.length === 0) {
      this.handleSetTemplateForCorrespondence(media);
    }
  }

  handleSetTemplateForCorrespondence = (media) => {
    const {
      client,
      tenantId,
      template,
      onUpdateTemplateDefinition,
    } = this.props;

    const updatedTemplate = {
      ...template,
      id: getCorrespondenceIdForTemplate(tenantId, template),
      mediaId: media.id,
      media,
    };

    const mutation = template.media ? editCorrespondenceTemplate : setTemplateForCorrespondence;
    const input = {
      id: tenantId,
      correspondenceId: updatedTemplate.id,
      category: template.category,
      type: template.type,
      title: template.title,
      mediaId: media.id,
    };

    client.mutate({
      mutation,
      variables: { input },
    });

    onUpdateTemplateDefinition(updatedTemplate);
  }

  handleOnContinue = () => {
    this.setState({
      isValidationModalOpen: false,
    });
  }

  getDefaultTemplateQueryForKey = (key) => {
    const keyQueryMap = {
      customId: getDefaultTemplateForCustom,
      estimateConfirmationToProceedId: getDefaultTemplateForEstimateConfirmationToProceed,
      confirmationOfEstimateAndServiceInformationCrematoriumId:
        getDefaultTemplateForConfirmationOfEstimateAndServiceInformationCrematorium,
      confirmationOfEstimateAndServiceInformationBurialId:
        getDefaultTemplateForConfirmationOfEstimateAndServiceInformationBurial,
      officiantConfirmationCremationId: getDefaultTemplateForOfficiantConfirmationCremation,
      officiantConfirmationBurialId: getDefaultTemplateForOfficiantConfirmationBurial,
      prePaymentInFullRequestId: getDefaultTemplateForPrePaymentInFullRequest,
      statementOfAccountId: getDefaultTemplateForStatementOfAccount,
      paymentOverdueId: getDefaultTemplateForPaymentOverdue,
      obituaryDetailsId: getDefaultTemplateForObituaryDetails,
      paymentReceiptId: getDefaultTemplateForPaymentReceipt,
      estimateAtNeedId: getDefaultTemplateForEstimateAtNeed,
      estimatePreNeedId: getDefaultTemplateForEstimatePreNeed,
      invoiceId: getDefaultTemplateForInvoice,
      proformaInvoiceId: getDefaultTemplateForProformaInvoice,
    };
    return keyQueryMap[key];
  };

  render() {
    const { template, allowDefaultDownload } = this.props;
    const {
      isValidationModalOpen,
      report,
    } = this.state;

    return (
      <TemplateItem
        {...this.state}
        template={template}
        allowDefaultDownload={allowDefaultDownload}
        onUploadStart={this.handleUploadStart}
        onUploadComplete={this.handleUploadComplete}
        onPreview={this.handlePreview}
        onDownload={this.handleDownload}
        onDownloadDefault={this.handleDownloadDefault}
        isValidationModalOpen={isValidationModalOpen}
        onContinue={this.handleOnContinue}
        report={report}
      />
    );
  }
}

const mapStateToProps = state => ({
  tenantId: state.userStore.user.tenantId,
});

export default withSnackbar(withApollo(
  withRouter(connect(mapStateToProps)(TemplateItemContainer)),
));
