import React from 'react';
import { t } from 'i18next';
import classNames from 'classnames';

import Price from 'components/common/Price';
import styles from 'scss/main.scss';
import { buildAddressString, getFormattedDate } from 'services/utils';
import { arrangementType } from 'types/bereavement';

import rcStyles from './styles.scss';

const lastEditedDateFormat = 'DD/MMM/YYYY';
const viewingDateFormat = 'DD/MMM/YYYY, HH:mm';

const ArrangementViewingsCard = ({ arrangement }) => {
  const { viewings = [] } = arrangement?.categoryInformation?.care || {};

  return (
    <div className={rcStyles.card}>
      <div className={classNames(
        rcStyles.card__header,
      )}
      >
        <div className={classNames(
          rcStyles.card__header__item,
          arrangement.isConfirmed ? rcStyles.is_confirmed : rcStyles.is_unconfirmed,
        )}
        >
          <div className={rcStyles.card__header__item__inner}>
            {arrangement.isConfirmed ? t('CONFIRMED') : t('UNCONFIRMED')}
          </div>
        </div>
        <div className={rcStyles.card__header__item}>
          <div className={rcStyles.card__header__item__inner}>
            <div className={rcStyles.is_label}>
              {t('Reference:')}
            </div>
            <div className={rcStyles.is_detail}>
              {arrangement.reference || ''}
            </div>
          </div>
        </div>
        <div className={rcStyles.card__header__item}>
          <div className={rcStyles.card__header__item__inner}>
            <div className={rcStyles.is_label}>
              {t('Last edited:')}
            </div>
            <div className={rcStyles.is_detail}>
              {arrangement.timeUpdated
                ? getFormattedDate(arrangement.timeUpdated, lastEditedDateFormat)
                : getFormattedDate(arrangement.timeCreated, lastEditedDateFormat)}
            </div>
          </div>
        </div>
        <div className={rcStyles.card__header__item}>
          <div className={rcStyles.card__header__item__inner_price}>
            <Price
              price={arrangement?.calculation?.finalTotal}
              customClass={classNames(
                styles.c_price_label,
                styles.is_black,
                styles.is_bold,
                styles.has_white_background,
              )}
            />
          </div>
        </div>
      </div>
      <div className={rcStyles.card__body}>
        <div className={classNames(
          rcStyles.viewings__label,
          !!viewings?.length && styles.u_push__bottom,
        )}
        >
          {!viewings?.length ? t('No viewings') : t('Viewings')}
        </div>
        {!!viewings?.length && (
          <ul className={rcStyles.viewings__list}>
            {viewings.map(viewing => (
              <li key={viewing.id} className={rcStyles.viewings__list_item}>
                {`${getFormattedDate(viewing.startDateTime, viewingDateFormat)}, ${buildAddressString(viewing.location)}`}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

ArrangementViewingsCard.propTypes = {
  arrangement: arrangementType,
};

export default ArrangementViewingsCard;
