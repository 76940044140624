import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, matchPath } from 'react-router-dom';

import { setCurrentEnvironmentAction } from 'actions/user';
import routes from 'constants/routes';
import Login from 'screens/LoginScreen';
import OfflineScreen from 'screens/OfflineScreen';
import WebSocketMessageErrorScreen from 'screens/WebSocketMessageErrorScreen';
import { locationType } from 'types/reactRouter';

import MainScreen from './MainScreen';

class MainScreenContainer extends React.Component {
  static propTypes = {
    accessToken: PropTypes.string,
    scopes: PropTypes.arrayOf(PropTypes.string.isRequired),
    availableFeatures: PropTypes.arrayOf(PropTypes.string.isRequired),
    isOnline: PropTypes.bool.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    location: locationType.isRequired,
    setCurrentEnvironment: PropTypes.func.isRequired,
    processingOfRequestInQueueFailed: PropTypes.bool.isRequired,
  }

  static getDerivedStateFromProps(nextProps) {
    const { accessToken } = nextProps;
    return {
      isLoggedIn: !!accessToken,
    };
  }

  static getRoutes(isLoggedIn = false, scopes, availableFeatures) {
    if (!isLoggedIn) {
      return routes.map((route) => {
        if (route.path === '/') {
          return { ...route, title: 'Login' };
        }

        return route;
      });
    }

    if (process.env.FEATURE_FLAG_AVAILABLE_ROUTES) {
      const availableRoutes = process.env.FEATURE_FLAG_AVAILABLE_ROUTES.split(',');
      return routes.filter(route => availableRoutes.includes(route.path));
    }

    return routes.filter((route) => {
      let hasFeatureFlags = true;
      let hasScopes = true;
      if (route.requiredScopes) {
        hasScopes = route.requiredScopes.every(scope => scopes.includes(scope));
      }
      if (route.requiredFeatureFlags) {
        hasFeatureFlags = route.requiredFeatureFlags.every(featureFlag => availableFeatures.includes(featureFlag));
      }

      return hasScopes && hasFeatureFlags;
    });
  }

  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: false,
    };
  }

  componentDidMount() {
    const { setCurrentEnvironment } = this.props;
    setCurrentEnvironment(process.env.CURRENT_ENVIRONMENT);
  }

  render() {
    const {
      scopes,
      availableFeatures,
      isOnline,
      isUpdating,
      location,
      processingOfRequestInQueueFailed,
    } = this.props;
    const { isLoggedIn } = this.state;
    const currentLocation = (location && location.pathname) ? location.pathname : '/';

    const isPortalLocation = currentLocation.includes('/portal/');
    if (isPortalLocation) {
      return null;
    }

    const routeList = MainScreenContainer.getRoutes(isLoggedIn, scopes, availableFeatures);
    const currentRoute = routeList.find(route => !!matchPath(currentLocation, {
      path: route.path,
      exact: true,
      strict: true,
    }));

    const isCurrentRoutePublic = currentRoute && currentRoute.isPublic;
    if (!isLoggedIn && !isCurrentRoutePublic) {
      return <Login redirect={currentLocation} />;
    }

    if (!isOnline) {
      return <OfflineScreen />;
    }

    if (processingOfRequestInQueueFailed && currentLocation !== '/logout') {
      return <WebSocketMessageErrorScreen />;
    }

    return (
      <MainScreen
        routes={routeList}
        isLoggedIn={isLoggedIn}
        isUpdating={isUpdating}
        currentLocation={currentLocation}
      />
    );
  }
}

const mapStateToProps = state => ({
  accessToken: state.userStore.user.accessToken,
  scopes: state.userStore.user.policy && state.userStore.user.policy.scopes,
  availableFeatures: state.userStore.user.tenantFeatureFlags,
  isOnline: state.userStore.isOnline,
  isUpdating: state.userStore.isUpdating,
  processingOfRequestInQueueFailed: state.requestQueueStore.processingOfRequestInQueueFailed,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setCurrentEnvironment: setCurrentEnvironmentAction,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainScreenContainer));
