import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { debounce } from 'debounce';

import { emptyOfficiantCustomDetails } from 'constants/arrangement';
import { scopes } from 'constants/scopes';
import { removePropertyByName } from 'services/utils';
import { apolloClientType } from 'types/apollo';

import { getDirectoryListing, getDirectoryListings } from './queries.gql';
import ArrangementOfficiantDirectoryListingSection from './ArrangementOfficiantDirectoryListingSection';

class ArrangementOfficiantDirectoryListingSectionContainer extends Component {
  debounceChange = debounce((value) => {
    this.getSuggestions(value);
  }, 500);

  static propTypes = {
    client: apolloClientType.isRequired,
    directoryListingId: PropTypes.string,
    customDetails: PropTypes.objectOf(PropTypes.any),
    errors: PropTypes.objectOf(PropTypes.any),
    hasDirectoryListingWritePermission: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    onChangeCustomDetails: PropTypes.func.isRequired,
    onChangeDirectoryListingId: PropTypes.func.isRequired,
    onCreateDirectoryListing: PropTypes.func.isRequired,
    onEditDirectoryListing: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedDirectoryListing: null,
      isLoading: false,
      lookUpValue: '',
      suggestions: [],
    };
  }

  componentDidMount() {
    const { directoryListingId } = this.props;
    this.getSuggestions();

    if (directoryListingId) {
      this.getSelectedDirectoryListing(directoryListingId);
    }
  }

  getSelectedDirectoryListing = (id) => {
    const { client } = this.props;

    client.query({
      query: getDirectoryListing,
      variables: { id },
    }).then((data) => {
      this.setState({
        selectedDirectoryListing: data?.data?.directoryListing || null,
      });
    });
  }

  getSuggestions = (term = null) => {
    const { client } = this.props;

    client.query({
      query: getDirectoryListings,
      variables: {
        term: (term?.length > 0) ? term : null,
        pagination: {
          first: 50,
          after: null,
        },
      },
    }).then((data) => {
      const suggestions = data.data.directoryListings.edges.map(edge => ({
        data: removePropertyByName(edge?.node, '__typename'),
        value: edge?.node?.name,
      }));
      this.setState({
        suggestions,
        isLoading: false,
      });
    });
  }

  handleLookUpChange = (value) => {
    this.setState({
      lookUpValue: value,
      isLoading: !!value,
    });
    this.debounceChange(value);
  }

  handleCreateDirectoryListing = () => {
    const { customDetails, onCreateDirectoryListing } = this.props;
    onCreateDirectoryListing(customDetails);
  }

  handleEditDirectoryListing = () => {
    const { directoryListingId, onEditDirectoryListing } = this.props;
    onEditDirectoryListing(directoryListingId);
  }

  handleChangeDirectoryListing = (directoryListing) => {
    const { onChangeDirectoryListingId } = this.props;
    this.setState({
      selectedDirectoryListing: directoryListing,
    });
    onChangeDirectoryListingId(directoryListing?.id);
  }

  handleRemoveDirectoryListing = () => {
    const { onChangeDirectoryListingId } = this.props;
    this.setState({
      selectedDirectoryListing: null,
    });
    onChangeDirectoryListingId(null);
  }

  handleAddCustomDetails = () => {
    const { onChangeCustomDetails } = this.props;
    onChangeCustomDetails(emptyOfficiantCustomDetails);
  }

  handleChangeCustomDetails = (key, value) => {
    const { customDetails, onChangeCustomDetails } = this.props;
    const updatedCustomDetails = {
      ...(customDetails || {}),
      [key]: value,
    };

    onChangeCustomDetails(updatedCustomDetails);
  }

  handleRemoveCustomDetails = () => {
    const { onChangeCustomDetails } = this.props;
    onChangeCustomDetails(null);
  }

  render() {
    const {
      disabled, customDetails, hasDirectoryListingWritePermission, errors,
    } = this.props;
    const {
      isLoading, lookUpValue,
      selectedDirectoryListing, suggestions,
    } = this.state;

    return (
      <ArrangementOfficiantDirectoryListingSection
        selectedDirectoryListing={selectedDirectoryListing}
        customDetails={customDetails}
        errors={errors}
        hasDirectoryListingWritePermission={hasDirectoryListingWritePermission}
        isLoading={isLoading}
        lookUpValue={lookUpValue}
        suggestions={suggestions}
        disabled={disabled}
        onAddCustomDetails={this.handleAddCustomDetails}
        onChangeCustomDetails={this.handleChangeCustomDetails}
        onChangeDirectoryListing={this.handleChangeDirectoryListing}
        onCreateDirectoryListing={this.handleCreateDirectoryListing}
        onEditDirectoryListing={this.handleEditDirectoryListing}
        onLookUpChange={this.handleLookUpChange}
        onRemoveCustomDetails={this.handleRemoveCustomDetails}
        onRemoveDirectoryListing={this.handleRemoveDirectoryListing}
      />
    );
  }
}

const mapStateToProps = state => ({
  hasDirectoryListingWritePermission: state?.userStore?.user?.policy?.scopes.includes(
    scopes.DIRECTORY_LISTING_WRITE,
  ),
});

export default withApollo(
  connect(mapStateToProps)(ArrangementOfficiantDirectoryListingSectionContainer),
);
