import moment from 'moment';
import { t } from 'i18next';
import { buildAddressString, buildNameString, removePropertyByName } from 'services/utils';
import {
  addressServiceCategories,
  serviceCategories,
  productCategories,
  committalTypes,
} from 'constants/arrangement';
import {
  noteCategories,
  noteCategoryLabels,
  noteCategoriesScopeRestrictions,
} from 'constants/bereavement';

export const getConfirmedArrangementFromBereavement = (bereavement, fallbackToFirst) => {
  let matchedArrangement = null;
  if (bereavement?.arrangements) {
    const { arrangements } = bereavement;
    matchedArrangement = arrangements.find(arrangement => arrangement.isConfirmed);

    if (!matchedArrangement && arrangements.length === 1 && fallbackToFirst) {
      [matchedArrangement] = arrangements;
    }
  }
  return matchedArrangement;
};

export const hasConfirmedArrangement = bereavement => !!getConfirmedArrangementFromBereavement(bereavement, false);

export const getUnconfirmedArrangementsFromBereavement = (bereavement) => {
  const { arrangements = [] } = bereavement || {};
  return arrangements.filter(({ isConfirmed }) => !isConfirmed);
};

export const buildDeceasedNameAndDateOfDeath = (bereavement) => {
  const deceasedPerson = bereavement.deceasedPeople[0];
  const deceasedName = buildNameString(deceasedPerson.name);
  const dateOfDeath = deceasedPerson.deathDateTime && deceasedPerson.deathDateTime.substring(0, 10);

  return `${deceasedName} ${dateOfDeath ? `(${dateOfDeath})` : ''}`;
};

export const updateAgeAtDeath = (deceasedPerson) => {
  if (!deceasedPerson.dateOfBirth || !deceasedPerson.deathDateTime) {
    return deceasedPerson;
  }

  let ageAtDeath = moment(deceasedPerson.deathDateTime).diff(deceasedPerson.dateOfBirth, 'years');

  if (ageAtDeath < 0) {
    ageAtDeath = null;
  }

  return {
    ...deceasedPerson,
    ageAtDeath,
  };
};

export const assignDefaultStatusesToFirstConnection = (connections, currentConnection = {}) => {
  const hasPrimaryContact = connections
    .some(connection => connection.isPrimaryContact)
    || currentConnection.isPrimaryContact;
  const hasBillPayer = connections
    .some(connection => connection.isBillPayer)
    || currentConnection.isBillPayer;

  const updatedConnections = connections;

  if (!hasPrimaryContact) {
    updatedConnections[0].isPrimaryContact = true;
  }
  if (!hasBillPayer) {
    updatedConnections[0].isBillPayer = true;
  }
  return updatedConnections;
};

export const updateClientsBillPayerAndPrimaryContactStatus = (
  bereavedPeopleConnections,
  bereavedPeopleConnection,
  shouldAssignDefaultStatusesToFirstConnection,
) => {
  let updatedConnections = bereavedPeopleConnections.map((connection) => {
    if (connection.id === bereavedPeopleConnection.id) {
      return bereavedPeopleConnection;
    }
    const updatedConnection = {
      ...connection,
      isPrimaryContact: connection.isPrimaryContact,
      isBillPayer: connection.isBillPayer,
    };
    if (bereavedPeopleConnection.isPrimaryContact) {
      updatedConnection.isPrimaryContact = false;
    }
    if (bereavedPeopleConnection.isBillPayer) {
      updatedConnection.isBillPayer = false;
    }
    return updatedConnection;
  });

  if (shouldAssignDefaultStatusesToFirstConnection) {
    updatedConnections = assignDefaultStatusesToFirstConnection(
      updatedConnections,
      bereavedPeopleConnection,
    );
  }
  return updatedConnections;
};

export const generateBereavementObituary = (bereavement, categoryInformation) => {
  const owner = bereavement.bereavedPeopleConnections
    .find(connection => connection.id
      === categoryInformation.onlineAdministratorBereavedPersonConnectionId);

  return {
    deceasedName: bereavement.deceasedPeople[0].name,
    deceasedDateOfBirth: bereavement.deceasedPeople[0].dateOfBirth,
    deceasedDateOfDeath: bereavement.deceasedPeople[0].deathDateTime,
    charityInformation: categoryInformation.charityInformation,
    funeralZoneDetails: categoryInformation.funeralZoneDetails,
    imageId: bereavement.deceasedPeople[0].imageId,
    image: bereavement.deceasedPeople[0].image,
    offlineObituaryMessage: categoryInformation.offlineObituaryMessage,
    ownerContactDetails: {
      obituaryOwnerEmail: owner.emails && owner.emails.length > 0 ? owner.emails[0] : null,
      obituaryOwnerPhoneNumber: owner.phones && owner.phones.length > 0 ? owner.phones[0] : null,
    },
  };
};

export const textForTransferType = (type) => {
  switch (type) {
    case 'ExternalTransfer':
      return t('External Transfer');
    case 'InternalTransfer':
      return t('Internal Transfer');
    case 'TransferIntoCare':
      return t('Transfer Into Care');
    default:
      return null;
  }
};

export const buildTransferByType = (type) => {
  if (!type) {
    return null;
  }
  return {
    __typename: type,
    startDateTime: null,
    endDateTime: null,
    fromLocation: null,
    toLocation: null,
    isComplete: false,
    instructions: '',
    isPossessionsChecked: false,
    isIdentificationChecked: false,
    completionNotes: '',
    receivedBy: '',
  };
};


export const getAllBereavementServices = ({ arrangements }) => {
  const services = arrangements && arrangements
    .reduce((accumulator, arrangement) => (arrangement.serviceSelections
      ? accumulator.concat(arrangement.serviceSelections.map(serviceSelection => serviceSelection.service))
      : accumulator), []);
  return services || [];
};

export const getAllBereavementCategoryInformations = ({ arrangements }) => {
  const catalogueInformations = arrangements && arrangements
    .reduce((accumulator, arrangement) => (arrangement.categoryInformation
      ? accumulator.concat([arrangement.categoryInformation])
      : accumulator), []);
  return catalogueInformations || [];
};

export const getCareViewings = (categoryInformations = []) => {
  const careViewings = categoryInformations && categoryInformations
    .map(categoryInformation => ((categoryInformation.care && categoryInformation.care.viewings)
      && categoryInformation.care.viewings))
    .filter(viewings => viewings)
    .reduce((accumulator, viewings) => accumulator.concat(...viewings), [])
    .filter(viewing => viewing);
  return careViewings || [];
};

export const getDeceasedAddresses = (deceasedPeople) => {
  const addresses = deceasedPeople && deceasedPeople
    .filter(({ address }) => address)
    .map(({ address }) => ({
      label: 'Deceased',
      data: removePropertyByName(address, '__typename'),
    }));
  return addresses || [];
};

export const getClientAddresses = (bereavedPeopleConnections) => {
  const addresses = bereavedPeopleConnections
    .filter(({ bereavedPerson: { address } }) => address)
    .map(({ bereavedPerson: { address, name } }) => ({
      label: buildNameString(name),
      data: removePropertyByName(address, '__typename'),
    }));
  return addresses || [];
};

export const getHomeAddresses = (home) => {
  const addresses = home && [{ label: home.name, data: removePropertyByName(home.address, '__typename') }];
  return addresses || [];
};

export const getServiceCategoryAddresses = (category, services, categoryInformations) => {
  const label = addressServiceCategories.find(({ value }) => value === category).name;

  const serviceAddresses = services && services
    .filter(service => service)
    .map(({ title, address }) => (title && address) && (
      { label: title, data: removePropertyByName(address, '__typename') }
    ))
    .filter(address => address);

  const categoryInformationAddresses = categoryInformations && categoryInformations
    .filter(categoryInformation => categoryInformation)
    .map(({ alternativeLocation }) => alternativeLocation && (
      { label: `${label} alternative location`, data: removePropertyByName(alternativeLocation, '__typename') }
    ))
    .filter(address => address);

  return [
    ...serviceAddresses,
    ...categoryInformationAddresses,
  ];
};

export const getCareViewingAddresses = (viewings) => {
  const addresses = viewings && viewings.map(({ location }) => ({
    label: 'Viewing location',
    data: removePropertyByName(location, '__typename'),
  }));
  return addresses || [];
};

export const getBereavementAddresses = (bereavement) => {
  if (!bereavement) return [];

  const {
    deceasedPeople = [], bereavedPeopleConnections = [], home,
  } = bereavement;

  const services = getAllBereavementServices(bereavement);
  const categoryInformations = getAllBereavementCategoryInformations(bereavement);
  const careViewings = getCareViewings(categoryInformations);

  return [
    ...getDeceasedAddresses(deceasedPeople),
    ...getClientAddresses(bereavedPeopleConnections),
    ...getHomeAddresses(home),
    ...getServiceCategoryAddresses(
      serviceCategories.SERVICE_VENUES,
      services.filter(service => service.category === serviceCategories.SERVICE_VENUES),
      categoryInformations.map(categoryInformation => categoryInformation.serviceVenue),
    ),
    ...getServiceCategoryAddresses(
      serviceCategories.CEMETERIES,
      services.filter(service => service.category === serviceCategories.CEMETERIES),
      categoryInformations.map(categoryInformation => categoryInformation.cemetery),
    ),
    ...getServiceCategoryAddresses(
      serviceCategories.CREMATORIA,
      services.filter(service => service.category === serviceCategories.CREMATORIA),
      categoryInformations.map(categoryInformation => categoryInformation.crematorium),
    ),
    ...getCareViewingAddresses(careViewings),
  ];
};

export const getNoteCategories = (notes = [], restrictedCategories = []) => {
  const noteCountsByCategory = notes.reduce((accumulator, note) => {
    const { category } = note;
    const updatedAccumulator = { ...accumulator };
    if (category) {
      if (!accumulator[category]) {
        updatedAccumulator[category] = 1;
      } else {
        updatedAccumulator[category] += 1;
      }
    }
    return updatedAccumulator;
  }, {});

  return Object.keys(noteCategories).map(key => ({
    label: noteCategoryLabels[key],
    value: key,
    count: noteCountsByCategory[key],
  })).filter(category => !restrictedCategories.includes(category.value));
};

export const getBereavementNoteCategories = scopes => (
  Object.values(noteCategories).filter((noteCategory) => {
    const restriction = noteCategoriesScopeRestrictions
      .find(item => item.category === noteCategory);

    return !(restriction && !scopes.includes(restriction.requiredPermission));
  })
);

export const getSortedActions = (actions, key) => {
  if (!actions || !actions.length) {
    return null;
  }
  return [...actions].sort((action1, action2) => moment(action1[key])
    .diff(moment(action2[key])));
};

export const getLastDeedAction = (actions) => {
  if (!actions || !actions.length) {
    return null;
  }
  return getSortedActions(actions, 'timeCreated').reverse()[0];
};

export const getBereavementCemeteryName = (bereavement) => {
  if (bereavement && bereavement.arrangements) {
    const arrangement = getConfirmedArrangementFromBereavement(bereavement, true);

    if (arrangement) {
      const { categoryInformation, serviceSelections } = arrangement;
      const cemetery = categoryInformation && categoryInformation.cemetery;
      const alternativeLocation = cemetery && cemetery.alternativeLocation;

      if (alternativeLocation) {
        return buildAddressString(alternativeLocation);
      }

      if (serviceSelections) {
        const cemeteryServiceSelection = serviceSelections.find((serviceSelection) => {
          const { service } = serviceSelection;
          return service && service.category === serviceCategories.CEMETERIES;
        });
        const service = cemeteryServiceSelection && cemeteryServiceSelection.service;
        return service && service.title;
      }
    }
  }
  return null;
};

export const getPersonToReceiveAshesData = (bereavedPeopleConnections, bereavedPersonConnectionId) => {
  if (bereavedPeopleConnections) {
    return bereavedPeopleConnections.reduce((result, bereavedPersonConnection) => {
      const isMatch = !bereavedPersonConnectionId
        ? bereavedPersonConnection.isPrimaryContact
        : bereavedPersonConnectionId === bereavedPersonConnection.id;

      if (!result && isMatch) {
        const {
          id,
          bereavedPerson: {
            name, emails, phones,
          },
        } = bereavedPersonConnection;

        return {
          id,
          name,
          email: emails && emails.reduce((email, value) => (email || value), null),
          phone: phones && phones.reduce((phone, value) => (phone || value.telephone.number), null),
        };
      }

      return result;
    }, null);
  }
  return null;
};

export const getBereavementAshesRelatedData = (bereavement) => {
  const relatedData = {
    handlingOfAshes: null,
    crematoriumRequested: null,
    crematoriumDateTime: null,
    urns: [],
    engraving: null,
    urnNotes: [],
    personToReceiveAshes: null,
  };

  if (bereavement && bereavement.arrangements) {
    const { bereavedPeopleConnections, notes } = bereavement;
    const arrangement = getConfirmedArrangementFromBereavement(bereavement, true);

    if (arrangement) {
      const { categoryInformation, serviceSelections, productSelections } = arrangement;
      const { crematorium, urn } = categoryInformation || {};
      const { handlingOfAshes, startDateTime } = crematorium || {};
      const { engraving } = urn || {};
      const urnNotes = notes && notes.filter(note => note.category === noteCategories.URN)
        .map(note => note.content);
      const crematoriumRequested = serviceSelections && serviceSelections
        .reduce((result, serviceSelection) => {
          const { service } = serviceSelection;
          if (!result && service.category === serviceCategories.CREMATORIA) {
            return `${service.title}, ${buildAddressString(service.address)}`;
          }
          return result;
        }, null);
      const urns = productSelections && productSelections
        .filter(productSelection => productSelection.product.category === productCategories.URNS)
        .map(productSelection => productSelection.product.title);

      relatedData.handlingOfAshes = handlingOfAshes;
      relatedData.crematoriumRequested = crematoriumRequested;
      relatedData.crematoriumDateTime = startDateTime;
      relatedData.urns = urns;
      relatedData.engraving = engraving;
      relatedData.urnNotes = urnNotes;
    }

    relatedData.personToReceiveAshes = getPersonToReceiveAshesData(bereavedPeopleConnections);
  }

  return relatedData;
};

export const getAshesRecordLastAction = (actions) => {
  if (!actions || !actions.length) {
    return null;
  }
  return getSortedActions(actions, 'createdDateTime').reverse()[0];
};

export const getAshesRecordPastActions = (actions) => {
  if (!actions || !actions.length) {
    return [];
  }
  return getSortedActions(actions, 'createdDateTime')
    .reverse()
    .filter((action, index) => index > 0);
};

export const buildConfirmationAndCommittalTypeText = (isConfirmed, committalType) => {
  const isConfirmedText = isConfirmed ? t('Confirmed') : t('Unconfirmed');
  const committalTypeText = committalType === committalTypes.CREMATION ? t('Cremation') : t('Burial');

  return `${isConfirmedText} ${committalTypeText}`;
};
