import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { mapStackTrace } from 'sourcemapped-stacktrace';

import AppBar from 'components/common/AppBar';
import DefaultScreenHelmet from 'components/common/DefaultScreenHelmet';
import GenericAppMessage from 'components/common/GenericAppMessage';
import WebSocketMessageError from 'components/common/WebSocketMessageError';
import { historyType } from 'types/reactRouter';

class GlobalErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    history: historyType.isRequired,
    user: PropTypes.objectOf(PropTypes.any).isRequired,
    currentEnvironment: PropTypes.string.isRequired,
    processingOfRequestInQueueFailed: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };

    const { history } = this.props;
    history.listen(this.historyListener);
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  historyListener = () => {
    this.setState({
      error: null,
    });
  }

  buildEventObject = (error, mappedStack, errorInfo) => {
    const { user, currentEnvironment } = this.props;
    const errorTitle = error.stack.split(':')[0];
    const errorContent = error.stack.split(':')[1];
    const errorMessage = errorContent.split('\n')[0];
    const errorObject = {
      title: errorTitle,
      message: errorMessage,
      errorStack: mappedStack.join('\n'),
      componentStack: errorInfo.componentStack,
      datetime: Math.round((new Date()).getTime() / 1000),
      hostname: window.location.hostname || process.env.HOSTNAME,
      environment: currentEnvironment || 'localhost',
      tenantId: user.tenantId,
    };

    if (user.staffMember) {
      errorObject.identityId = user.staffMember.identityInterface.id;
    }

    return errorObject;
  }

  componentDidCatch(error, errorInfo) {
    if (!process.env.REPORT_GATEWAY_URL) {
      return;
    }

    mapStackTrace(error.stack, (mappedStack) => {
      const errorDetails = this.buildEventObject(error, mappedStack, errorInfo);

      fetch(process.env.REPORT_GATEWAY_URL, {
        method: 'POST',
        body: JSON.stringify(errorDetails),
        headers: {
          'Content-Type': 'application/json',
        },
      });
    });
  }

  render() {
    const {
      children,
      user,
      processingOfRequestInQueueFailed,
    } = this.props;
    const { error } = this.state;

    if (error) {
      if (processingOfRequestInQueueFailed) {
        return (
          <>
            {Object.keys(user).length > 0 && <AppBar />}
            <WebSocketMessageError />
          </>
        );
      }

      let displayMessage = t('Something seems to have gone wrong.');

      if (error.screenMessage) {
        displayMessage = error.screenMessage;
      }

      return (
        <>
          <DefaultScreenHelmet />
          {Object.keys(user).length > 0 && <AppBar />}
          <GenericAppMessage
            title={t('We\'re sorry.')}
            error={error}
            subTitle={displayMessage}
            showReturnToDashboardButton={error.name !== 'OfflineError'}
          />
        </>
      );
    }

    return children;
  }
}

const mapStateToProps = state => ({
  user: state.userStore.user,
  currentEnvironment: state.userStore.currentEnvironment,
  processingOfRequestInQueueFailed: state.requestQueueStore.processingOfRequestInQueueFailed,
});

export default withRouter(connect(mapStateToProps)(GlobalErrorBoundary));
