import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Document from './Document';

class DocumentContainer extends Component {
  static propTypes = {
    fieldData: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      mediaId: PropTypes.string,
      media: PropTypes.objectOf(PropTypes.any),
    }),
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    errors: PropTypes.objectOf(PropTypes.any),
  }

  static defaultProps = {
    fieldData: {
      id: '',
      title: '',
      mediaId: '',
      media: {
        id: null,
        uri: '',
      },
    },
  }

  constructor(props) {
    super(props);

    this.state = {
      fieldData: props.fieldData || {},
    };
  }

  onChange = (value) => {
    const { onChange } = this.props;
    const { fieldData: prevFieldData } = this.state;

    const fieldData = {
      ...prevFieldData,
      title: value,
    };

    this.setState({ fieldData });

    if (onChange) {
      onChange(fieldData);
    }
  }

  render() {
    const { fieldData } = this.state;

    const documentExtension = fieldData.title ? fieldData.title.split('.').pop() : '';

    const documentTitle = fieldData.title ? fieldData.title.slice(0, -documentExtension.length - 1) : '';

    const documentUrl = fieldData.media && fieldData.media.uri;

    return (
      <Document
        {...this.props}
        documentTitle={documentTitle}
        documentExtension={documentExtension}
        documentUrl={documentUrl}
        onChange={this.onChange}
      />
    );
  }
}

export default DocumentContainer;
