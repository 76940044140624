import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';

import ostyle from './loader.scss';

const IconLoadSml = ({
  className,
  title,
}) => {
  const classes = className ? `${className}` : ostyle.loader_default;

  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 24 30"
      className={classes}
    >
      <title>{title || t('Loading...')}</title>
      <rect
        x="0"
        y="0"
        width="4"
        height="20"
      >
        <animate
          attributeName="opacity"
          attributeType="XML"
          values="1; .2; 1"
          begin="0s"
          dur="0.6s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x="7"
        y="0"
        width="4"
        height="20"
      >
        <animate
          attributeName="opacity"
          attributeType="XML"
          values="1; .2; 1"
          begin="0.2s"
          dur="0.6s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x="14"
        y="0"
        width="4"
        height="20"
      >
        <animate
          attributeName="opacity"
          attributeType="XML"
          values="1; .2; 1"
          begin="0.4s"
          dur="0.6s"
          repeatCount="indefinite"
        />
      </rect>
    </svg>
  );
};

IconLoadSml.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};

export default IconLoadSml;
