import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import rcStyles from './styles.scss';

const ArrangementNavigationIcon = ({ pathName }) => {
  switch (pathName) {
    case 'care':
      return <img src="/img/nav_menu/care.jpg" alt={pathName} className={rcStyles.is_image} />;
    case 'packages':
      return <img src="/img/nav_menu/packages.jpg" alt={pathName} className={rcStyles.is_image} />;
    case 'crematorium':
      return <img src="/img/nav_menu/crematorium.jpg" alt={pathName} className={rcStyles.is_image} />;
    case 'service-venue':
      return <img src="/img/nav_menu/service.jpg" alt={pathName} className={rcStyles.is_image} />;
    case 'burial':
      return <img src="/img/nav_menu/venue.jpg" alt={pathName} className={rcStyles.is_image} />;
    case 'reception-venue':
      return <img src="/img/nav_menu/reception_venue.jpg" alt={pathName} className={rcStyles.is_image} />;
    case 'cortege':
      return <img src="/img/nav_menu/cortege.jpg" alt={pathName} className={rcStyles.is_image} />;
    case 'coffins':
      return <img src="/img/nav_menu/coffins.jpg" alt={pathName} className={rcStyles.is_image} />;
    case 'urns':
      return <img src="/img/nav_menu/urns.jpg" alt={pathName} className={rcStyles.is_image} />;
    case 'officiants':
      return <img src="/img/nav_menu/officiants.jpg" alt={pathName} className={rcStyles.is_image} />;
    case 'music':
      return <img src="/img/nav_menu/music.jpg" alt={pathName} className={rcStyles.is_image} />;
    case 'flowers':
      return <img src="/img/nav_menu/flowers.jpg" alt={pathName} className={rcStyles.is_image} />;
    case 'other':
      return <img src="/img/nav_menu/other.jpg" alt={pathName} className={rcStyles.is_image} />;
    case 'obituary':
      return <img src="/img/nav_menu/obituaries.jpg" alt={pathName} className={rcStyles.is_image} />;
    case 'estimate':
      return <img src="/img/nav_menu/estimate.png" alt={pathName} className={rcStyles.is_image} />;
    case 'memorialisation':
      return <img src="/img/nav_menu/memorial.jpg" alt={pathName} className={rcStyles.is_image} />;
    default:
      return null;
  }
};

ArrangementNavigationIcon.propTypes = {
  pathName: PropTypes.string.isRequired,
};

const ArrangementNavigationSelection = ({ navigationPath, onClick, isActive }) => (
  <Fragment>
    {navigationPath.path !== 'estimate' && (
      <div
        onClick={() => onClick(navigationPath.path)}
        role="button"
        tabIndex="0"
        className={isActive ? rcStyles.is_selected : ''}
        data-test-id={`menuItem-${navigationPath.path}`}
      >
        <ArrangementNavigationIcon pathName={navigationPath.path} />
        {navigationPath.name}
      </div>
    )}
  </Fragment>
);

ArrangementNavigationSelection.propTypes = {
  navigationPath: PropTypes.objectOf(PropTypes.any),
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default ArrangementNavigationSelection;
