import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { TemplateDefinition, TemplateDefinitionsLookup } from 'types/ts/tenant';
import { buildTemplateDefinitionsLookup, updateTemplateDefinitionsLookup } from 'services/utils/correspondence';
import AdminLetterTemplatesEstimateScreen
  from 'screens/admin/AdminLetterTemplatesEstimateScreen/AdminLetterTemplatesEstimateScreen';
import { getTenantCorrespondenceTemplateDefinitions } from 'screens/admin/AdminLetterTemplatesScreen/queries.gql';
import { RootState } from 'types/ts/state';
import { bindActionCreators, Dispatch } from 'redux';
import {
  setTenantCorrespondenceTemplateDefinitionsAction,
  updateTenantCorrespondenceTemplateDefinitionAction,
} from 'actions/user';
import {
  Props,
  PropsWithoutApolloClient,
  State,
  IsLoadingKey,
} from './index.types';

class AdminLetterTemplatesEstimateScreenContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { tenantCorrespondenceTemplateDefinitions } = props;
    const templateDefinitionsLookup: TemplateDefinitionsLookup = buildTemplateDefinitionsLookup(
      tenantCorrespondenceTemplateDefinitions,
    );

    this.state = {
      templateDefinitionsLookup,
      isLoading: {
        tenantTemplateDefinitions: false,
      },
    };
  }

  componentDidMount() {
    const { client, tenantId, setTenantCorrespondenceTemplateDefinitions } = this.props;

    this.toggleLoading('tenantTemplateDefinitions');

    client.query({
      query: getTenantCorrespondenceTemplateDefinitions,
      variables: { tenantId },
    }).then(({ data }) => {
      const { correspondenceTemplateDefinitions: definitions } = data.tenant;
      const templateDefinitionsLookup: TemplateDefinitionsLookup = buildTemplateDefinitionsLookup(definitions);

      this.setState({ templateDefinitionsLookup });
      setTenantCorrespondenceTemplateDefinitions(definitions);
    }).finally(() => {
      this.toggleLoading('tenantTemplateDefinitions');
    });
  }

  toggleLoading = (key: IsLoadingKey) => {
    this.setState(prevState => ({
      isLoading: {
        ...prevState.isLoading,
        [key]: !prevState.isLoading[key],
      },
    }));
  }

  handleUpdateTemplateDefinition = (template: TemplateDefinition) => {
    const { updateTenantCorrespondenceTemplateDefinition } = this.props;
    const { templateDefinitionsLookup } = this.state;

    const updatedTemplateDefinitionsLookup = updateTemplateDefinitionsLookup(
      templateDefinitionsLookup,
      template,
    );

    this.setState({
      templateDefinitionsLookup: updatedTemplateDefinitionsLookup,
    });

    updateTenantCorrespondenceTemplateDefinition(template);
  };

  render() {
    const {
      isLoading,
      templateDefinitionsLookup,
    } = this.state;
    return (
      <AdminLetterTemplatesEstimateScreen
        templateDefinitionsLookup={templateDefinitionsLookup}
        isLoading={isLoading}
        onUpdateTemplateDefinition={this.handleUpdateTemplateDefinition}
      />
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  tenantId: state.userStore.user.tenantId,
  tenantCorrespondenceTemplateDefinitions: state.userStore.user.tenantCorrespondenceTemplateDefinitions,
  userId: state.userStore.user.staffMember.id,
  userName: state.userStore.user.name,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  setTenantCorrespondenceTemplateDefinitions: setTenantCorrespondenceTemplateDefinitionsAction,
  updateTenantCorrespondenceTemplateDefinition: updateTenantCorrespondenceTemplateDefinitionAction,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(
  withApollo<PropsWithoutApolloClient, State>(AdminLetterTemplatesEstimateScreenContainer),
);
