import React, { Fragment } from 'react';
import { matchType } from 'types/reactRouter';
import { Switch, Route } from 'react-router-dom';
import NotFoundScreen from 'screens/NotFoundScreen';
import LiteArrangementScreen from 'screens/lite/LiteArrangementScreen';
import LiteArrangementListScreen from 'screens/lite/LiteArrangementListScreen';
import ArrangementScreen from 'screens/arrangement/ArrangementScreen';

const LiteArrangementSectionScreen = ({
  match,
}) => (
  <Fragment>
    <Switch>
      <Route
        exact
        key="create"
        path={`${match.url}/create`}
        render={() => (
          <LiteArrangementScreen />
        )}
      />
      <Route
        exact
        key="edit"
        path={`${match.url}/:bereavementId`}
        render={() => (
          <LiteArrangementScreen />
        )}
      />
      <Route
        key="catalogue"
        path={`${match.url}/:bereavementId/catalogue`}
        render={() => (
          <ArrangementScreen
            disabled={false}
            displayCategoryInformation={false}
            displayAppbar
          />
        )}
      />
      <Route
        exact
        key="list"
        path={`${match.url}`}
        render={() => (
          <LiteArrangementListScreen />
        )}
      />
      <Route key="not-found" component={NotFoundScreen} />
    </Switch>
  </Fragment>
);

LiteArrangementSectionScreen.propTypes = {
  match: matchType.isRequired,
};

export default LiteArrangementSectionScreen;
