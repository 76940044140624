import { v4 as uuidv4 } from 'uuid';
import { createHomeTransform, editHomeTransform } from 'transforms/home';
import { organisationalUnitTypes } from 'constants/organisationalUnits';
import { getOrganisationalUnitHomes } from './homes.queries.gql';
import { createOrganisationalUnit, editOrganisationalUnit } from './homes.mutations.gql';

export const homesActions = {
  SET_HOMES: 'HOME_SET_HOMES',
  CLEAR_HOMES: 'HOME_CLEAR_HOMES',
  ADD_HOMES: 'HOME_ADD_HOMES',
  ADD_HOME: 'HOME_ADD_HOME',
  UPDATE_HOME: 'HOME_UPDATE_HOME',
  REMOVE_HOME: 'HOME_REMOVE_HOME',
  UPDATE_FILTERS: 'HOME_UPDATE_FILTERS',
  SET_LOADING: 'HOME_SET_LOADING',
};

export const setHomesAction = payload => (
  { type: homesActions.SET_HOMES, payload }
);

export const clearHomesAction = () => (
  { type: homesActions.CLEAR_HOMES }
);

export const addHomesAction = payload => (
  { type: homesActions.ADD_HOMES, payload }
);

export const addHomeAction = payload => (
  { type: homesActions.ADD_HOME, payload }
);

export const updateHomeAction = payload => (
  { type: homesActions.UPDATE_HOME, payload }
);

export const removeHomeAction = payload => (
  { type: homesActions.REMOVE_HOME, payload }
);

export const updateFiltersAction = (key, value) => (
  { type: homesActions.UPDATE_FILTERS, payload: { key, value } }
);

export const setLoadingAction = payload => (
  { type: homesActions.SET_LOADING, payload }
);

export const fetchHomesAction = filters => (dispatch, getState, client) => {
  dispatch(setLoadingAction(true));
  if (filters) {
    dispatch(clearHomesAction());
  }

  const { pagination } = getState().homesStore;
  const { searchTerm } = filters || getState().homesStore.filters;

  const query = client.query({
    query: getOrganisationalUnitHomes,
    variables: {
      term: searchTerm || null,
      pagination: {
        first: pagination.first,
        after: pagination.after,
      },
    },
  }).then(({ data, errors }) => {
    if (data === null && errors.length > 0) {
      return;
    }

    const { organisationalUnits } = data;
    const homes = organisationalUnits.edges.map(({ node }) => node);
    const updatedPagination = {
      first: pagination.first,
      after: organisationalUnits.pageInfo.endCursor,
      hasNextPage: organisationalUnits.pageInfo.hasNextPage,
    };

    const payload = { homes, pagination: updatedPagination, filters };
    dispatch(addHomesAction(payload));
  }).finally(() => {
    dispatch(setLoadingAction(false));
  });
  return query;
};


export const createHomeAction = home => (dispatch, getState, client) => {
  const homeTransformed = createHomeTransform(home);
  homeTransformed.id = uuidv4();
  homeTransformed.type = organisationalUnitTypes.HOME;
  client.mutate({
    mutation: createOrganisationalUnit,
    variables: { input: homeTransformed },
  });
  dispatch(addHomeAction(homeTransformed));
};

export const editHomeAction = home => (dispatch, getState, client) => {
  const homeTransformed = editHomeTransform(home);
  const mutation = client.mutate({
    mutation: editOrganisationalUnit,
    variables: { input: homeTransformed },
  }).then(({ data }) => {
    if (data) {
      const { organisationalUnits } = data;
      const { editOrganisationalUnit: editOrganisationalUnitResult } = organisationalUnits;
      dispatch(updateHomeAction(editOrganisationalUnitResult.organisationalUnit));
    }
  });
  dispatch(updateHomeAction(homeTransformed));
  return mutation;
};
