import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { saveNotesAction, saveDocumentsAction } from 'actions/bereavement';
import {
  setTenantCorrespondenceTemplateDefinitionsAction,
} from 'actions/user';
import { buildTemplateDefinitionsLookup } from 'services/utils/correspondence';
import { apolloClientType } from 'types/apollo';

import { getTenantCorrespondenceTemplateDefinitions } from './queries.gql';
import CaseMenu from './CaseMenu';

export class CaseMenuContainer extends Component {
  static propTypes = {
    client: apolloClientType.isRequired,
    tenantId: PropTypes.string.isRequired,
    tenantCorrespondenceTemplateDefinitions: PropTypes.arrayOf(PropTypes.any),
    setTenantCorrespondenceTemplateDefinitions: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { tenantCorrespondenceTemplateDefinitions } = props;
    const templateDefinitionsLookup = buildTemplateDefinitionsLookup(tenantCorrespondenceTemplateDefinitions);

    this.state = {
      templateDefinitionsLookup,
    };
  }

  componentDidMount() {
    const { client, tenantId, setTenantCorrespondenceTemplateDefinitions } = this.props;

    client.query({
      query: getTenantCorrespondenceTemplateDefinitions,
      variables: { tenantId },
    }).then(({ data }) => {
      const { correspondenceTemplateDefinitions: definitions } = data.tenant;
      const templateDefinitionsLookup = buildTemplateDefinitionsLookup(definitions);

      this.setState({ templateDefinitionsLookup });
      setTenantCorrespondenceTemplateDefinitions(definitions);
    });
  }

  render() {
    const { templateDefinitionsLookup } = this.state;
    return (
      <CaseMenu
        {...this.props}
        templateDefinitionsLookup={templateDefinitionsLookup}
      />
    );
  }
}

const mapStateToProps = state => ({
  tenantId: state.userStore.user.tenantId,
  tenantCorrespondenceTemplateDefinitions: state.userStore.user.tenantCorrespondenceTemplateDefinitions,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setTenantCorrespondenceTemplateDefinitions: setTenantCorrespondenceTemplateDefinitionsAction,
  saveNotes: saveNotesAction,
  saveDocuments: saveDocumentsAction,
}, dispatch);

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(CaseMenuContainer),
);
