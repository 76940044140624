import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Radio from '@material-ui/core/Radio';
import Slide from '@material-ui/core/Slide';
import BereavedPersonCard from 'components/common/cards/BereavedPersonCard';
import { bereavedPersonConnectionType } from 'types/bereavement';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const dialogClasses = {
  paper: rcStyles.rc_dialog__paper,
};

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const ObituaryAdministratorList = ({
  isModalOpen,
  bereavedPeopleConnections,
  selectedAdministrator,
  disabled,
  onBereavedChange,
  onOpen,
  onCancel,
  onSave,
}) => (
  <Fragment>
    <div className={styles.u_push__bottom}>
      <Button
        color="primary"
        variant="outlined"
        size="medium"
        onClick={onOpen}
        disabled={disabled}
        data-test-id="open"
      >
        {t('Change')}
      </Button>
    </div>
    <Dialog
      onClose={onCancel}
      aria-labelledby="obituary-administrator-title"
      open={isModalOpen}
      fullWidth
      classes={dialogClasses}
      TransitionComponent={Transition}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <div className={classNames(styles.c_dialog__header)}>
        <DialogTitle disableTypography className={styles.c_dialog__title}>
          {t('Select obituary administrator')}
        </DialogTitle>
      </div>
      <DialogContent className={styles.c_dialog__content}>
        {bereavedPeopleConnections.map(bereavedPersonConnection => (
          <div
            key={bereavedPersonConnection.id}
            className={styles.o_fb}
          >
            <Radio
              color="primary"
              name="administrator"
              value={bereavedPersonConnection.id}
              checked={selectedAdministrator === bereavedPersonConnection.id}
              onChange={onBereavedChange}
              data-test-id={bereavedPersonConnection.id}
            />
            <BereavedPersonCard
              bereavedPersonConnection={bereavedPersonConnection}
              customClass={rcStyles.rc_custom_bg}
            />
          </div>
        ))}
      </DialogContent>
      <DialogActions className={styles.c_dialog__actions}>
        <Button
          onClick={onCancel}
          variant="outlined"
          color="primary"
          size="medium"
          data-test-id="cancel"
          fullWidth
        >
          {t('Cancel')}
        </Button>
        <Button
          onClick={onSave}
          variant="contained"
          color="primary"
          size="medium"
          data-test-id="saveObituary"
          fullWidth
        >
          {t('Save')}
        </Button>
      </DialogActions>
    </Dialog>
  </Fragment>
);

ObituaryAdministratorList.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  bereavedPeopleConnections: PropTypes.arrayOf(bereavedPersonConnectionType).isRequired,
  selectedAdministrator: PropTypes.string,
  disabled: PropTypes.bool,
  onBereavedChange: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default ObituaryAdministratorList;
