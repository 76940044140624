import mapActionToReducer from 'redux-action-reducer-mapper';
import reduceReducers from 'reduce-reducers';
import { taskActions } from 'actions/task';
import { getArrayIndexForId, updateArrayByIndex } from 'services/utils';

export const initialState = {
  tasks: [],
  pageInfo: {
    totalCount: null,
    hasMore: true,
    cursor: null,
    loadingMore: false,
  },
};

const setLoadingState = (state, action) => ({
  ...state,
  pageInfo: {
    ...state.pageInfo,
    loadingMore: action.state,
  },
});

const setTasks = (state, action) => {
  const {
    tasks,
    pageInfo,
  } = action.payload;

  const previousTasks = state.tasks || [];
  const newTasks = tasks || [];

  return {
    ...state,
    tasks: !action.reload ? [...previousTasks, ...newTasks] : newTasks,
    pageInfo: {
      ...pageInfo,
      loadingMore: false,
    },
  };
};

const setTask = (state, action) => {
  const { task } = action.payload;

  const index = getArrayIndexForId(state.tasks, task.id);

  return {
    ...state,
    tasks: updateArrayByIndex(state.tasks, index, task),
  };
};

const prependTask = (state, action) => {
  const { task } = action.payload;

  return {
    ...state,
    tasks: [
      {
        ...task,
        dirty: true,
      },
      ...state.tasks,
    ],
  };
};

const actionReducers = mapActionToReducer({
  default: initialState,
  [taskActions.CLEAR_TASKS]: () => initialState,
  [taskActions.SET_TASKS]: setTasks,
  [taskActions.SET_TASK]: setTask,
  [taskActions.PREPEND_TASK]: prependTask,
  [taskActions.SET_LOADING_STATE]: setLoadingState,
});

export const taskReducer = reduceReducers(
  actionReducers,
);
