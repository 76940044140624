import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { telephoneTypes } from 'constants/telephoneTypes';
import { toProperCase } from 'services/utils';

import rcStyles from './styles.scss';

const DirectoryListingContactDetails = ({ directoryListing }) => {
  const contactsByType = new Map();
  const contacts = [];

  Object.keys(telephoneTypes).forEach((key) => {
    contactsByType.set(
      key,
      (directoryListing?.phones
        ?.filter(phone => phone.type === key)
        ?.map(phone => phone?.telephone?.number)
      ) || [],
    );
  });
  contactsByType.set('EMAIL', directoryListing?.emails || []);

  contactsByType.forEach((values, key) => {
    if (values.length) {
      contacts.push(
        <Fragment key={key}>
          <div className={rcStyles.label}>
            {`${toProperCase(key)}:`}
          </div>
          <div className={rcStyles.value}>
            {values.map(value => (
              <div key={value}>
                {value}
              </div>
            ))}
          </div>
        </Fragment>,
      );
    }
  });
  return contacts;
};

DirectoryListingContactDetails.propTypes = {
  directoryListing: PropTypes.objectOf(PropTypes.any),
};

export default DirectoryListingContactDetails;
