import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withValidation } from '@funeralguide/react-form-validation-hoc';

import { validationSchema } from './validation';
import EditCustomTemplateModal from './EditCustomTemplateModal';

class EditCustomTemplateModalContainer extends Component {
  static propTypes = {
    template: PropTypes.objectOf(PropTypes.any).isRequired,
    isOpen: PropTypes.bool.isRequired,
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    generateRefs: PropTypes.func.isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { template } = props;

    this.state = {
      formData: {
        title: template.title,
      },
    };

    this.dialogRef = React.createRef();
  }

  componentDidMount() {
    const { generateRefs } = this.props;

    generateRefs(Object.keys(validationSchema.fields));
  }

  handleClose = () => {
    const { onClose } = this.props;
    onClose();
  }

  handleChange = (key, value) => {
    const { validate } = this.props;

    this.setState((prevState) => {
      const updatedFormData = {
        ...prevState.formData,
        [key]: value,
      };

      validate(updatedFormData, validationSchema, true, this.dialogRef);

      return {
        formData: updatedFormData,
      };
    });
  }

  handleSave = () => {
    const { template, validate, onSave } = this.props;
    const { formData } = this.state;
    const isValid = validate(formData, validationSchema, true, this.dialogRef);

    if (isValid) {
      const updatedTemplate = {
        ...template,
        title: formData.title,
      };

      onSave(updatedTemplate);
      this.handleClose();
    }
  }

  render() {
    const { isOpen, formRefs, errors } = this.props;
    const { formData } = this.state;

    return (
      <EditCustomTemplateModal
        formData={formData}
        dialogRef={this.dialogRef}
        formRefs={formRefs}
        errors={errors}
        isOpen={isOpen}
        onCancel={this.handleClose}
        onChange={this.handleChange}
        onSave={this.handleSave}
      />
    );
  }
}

export default withValidation(EditCustomTemplateModalContainer);
