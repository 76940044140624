import {
  string, object,
} from 'yup';

export const validationSchema = object().shape({
  deceasedName: object().shape({
    title: string().nullable().required('Title is required'),
    givenName: string().nullable().required('Given name is required'),
    familyName: string().nullable().required('Family name is required'),
  }),
  homeId: string().nullable().required('Home is required'),
});
