import mapActionToReducer from 'redux-action-reducer-mapper';
import { userActions } from 'actions/user';
import {
  buildAddressString,
  getArrayIndexForId,
  updateArrayByIndex,
} from 'services/utils';

export const initialState = {
  user: {},
  isOnline: true,
  isUpdating: false,
  currentEnvironment: null,
  addressesUsed: [],
};

const setUser = (state, action) => {
  const { payload } = action;
  if (payload) {
    return {
      ...state,
      user: {
        ...state.user,
        ...payload,
      },
    };
  }

  return state;
};

const setIsUpdating = (state, action) => ({
  ...state,
  isUpdating: action.payload,
});

const setIsOnline = (state, action) => ({
  ...state,
  isOnline: action.payload,
});

const setCurrentEnvironment = (state, action) => {
  const currentEnvironment = action.payload;

  return {
    ...state,
    currentEnvironment,
  };
};

const setXeroConsent = (state, action) => {
  const tenantHasGivenConsentToAXeroApp = action.payload;

  return {
    ...state,
    user: {
      ...state.user,
      tenantHasGivenConsentToAXeroApp,
    },
  };
};

const addAddressToList = (state, action) => {
  const address = action.payload;
  const stringAddresses = state.addressesUsed.map(stateAddress => buildAddressString(stateAddress));
  if (stringAddresses.includes(buildAddressString(address))) {
    return state;
  }

  return {
    ...state,
    addressesUsed: [address, ...state.addressesUsed],
  };
};

const clearAddressList = state => ({
  ...state,
  addressesUsed: [],
});

const setTenantCorrespondenceTemplateDefinitions = (state, action) => {
  const definitions = action.payload || [];

  return {
    ...state,
    user: {
      ...state.user,
      tenantCorrespondenceTemplateDefinitions: [...definitions],
    },
  };
};

const updateTenantCorrespondenceTemplateDefinition = (state, action) => {
  const template = action.payload;
  const { user } = state;
  const { tenantCorrespondenceTemplateDefinitions } = user;

  const definitionIndex = getArrayIndexForId(tenantCorrespondenceTemplateDefinitions, template.id);

  let updatedTemplateDefinitions = [...tenantCorrespondenceTemplateDefinitions];
  if (definitionIndex === -1) {
    updatedTemplateDefinitions.push(template);
  } else {
    updatedTemplateDefinitions = updateArrayByIndex(
      updatedTemplateDefinitions,
      definitionIndex,
      template,
    );
  }

  return {
    ...state,
    user: {
      ...state.user,
      tenantCorrespondenceTemplateDefinitions: [...updatedTemplateDefinitions],
    },
  };
};

const deleteTenantCorrespondenceTemplateDefinition = (state, action) => {
  const template = action.payload;
  const { user } = state;
  const { tenantCorrespondenceTemplateDefinitions } = user;

  const updatedTemplateDefinitions = [...tenantCorrespondenceTemplateDefinitions];

  const definitionIndex = getArrayIndexForId(tenantCorrespondenceTemplateDefinitions, template.id);
  if (definitionIndex > -1) {
    updatedTemplateDefinitions.splice(definitionIndex, 1);
  }

  return {
    ...state,
    user: {
      ...state.user,
      tenantCorrespondenceTemplateDefinitions: [...updatedTemplateDefinitions],
    },
  };
};

const setArrangementCategoryDisplayPreferences = (state, action) => {
  const preferences = action.payload;

  return {
    ...state,
    user: {
      ...state.user,
      arrangementCategoryDisplayPreferences: [...preferences],
    },
  };
};

export const userReducer = mapActionToReducer({
  default: initialState,
  [userActions.SET_USER]: setUser,
  [userActions.REMOVE_USER]: () => initialState,
  [userActions.SET_IS_ONLINE]: setIsOnline,
  [userActions.SET_IS_UPDATING]: setIsUpdating,
  [userActions.SET_CURRENT_ENVIRONMENT]: setCurrentEnvironment,
  [userActions.SET_XERO_CONSENT]: setXeroConsent,
  [userActions.ADD_ADDRESS_TO_LIST]: addAddressToList,
  [userActions.CLEAR_ADDRESS_LIST]: clearAddressList,
  [userActions.SET_TENANT_CORRESPONDENCE_TEMPLATE_DEFINITIONS]: setTenantCorrespondenceTemplateDefinitions,
  [userActions.UPDATE_TENANT_CORRESPONDENCE_TEMPLATE_DEFINITION]: updateTenantCorrespondenceTemplateDefinition,
  [userActions.DELETE_TENANT_CORRESPONDENCE_TEMPLATE_DEFINITION]: deleteTenantCorrespondenceTemplateDefinition,
  [userActions.SET_ARRANGEMENT_CATEGORY_DISPLAY_PREFERENCES]: setArrangementCategoryDisplayPreferences,
});

export default userReducer;
