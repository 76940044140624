import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const IconOfflinemain = (props) => {
  const { className } = props;
  const classes = className ? `${className} ${styles.rc_offline_icon}` : styles.rc_offline_icon;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 491.43 339.59"
      className={classes}
    >
      <path d="M0,108.23C5.5,95.89,16.39,88.44,26.15,80.17c52.29-44.33,112.66-71,180.77-78,106.91-11,199.68,21,277.43,95.51,8.89,8.52,9.39,20.34,1.6,28.69s-20.36,8.59-29.12,0C429.07,99.23,397.57,77.8,361.7,62.85A296.45,296.45,0,0,0,220.52,40.73c-65.66,5.6-123.3,30.27-173,73.55-4.46,3.88-8.66,8.07-13,12.08-12,11.09-28,7.13-33.76-8.33A5.89,5.89,0,0,0,0,116.87Z" />
      <path d="M237.12,339.59c-3.36-1-6.77-1.76-10.06-2.9a50.43,50.43,0,0,1-32.89-57.2c4.86-24.81,27.1-41.88,53-40.67,23.93,1.12,44.29,20.64,46.83,44.88a50.25,50.25,0,0,1-40.74,55,20.37,20.37,0,0,0-2.66.91Z" />
      <path d="M394.38,161.52a77.1,77.1,0,0,1,77,77.79c-.19,42.37-35.1,76.54-78,76.31-42.49-.22-76.83-35.12-76.56-77.77C317.12,195.64,352,161.31,394.38,161.52ZM393.46,225c-1.34-2-2.12-3.67-3.34-4.91C381.46,211.33,372.7,202.68,364,194c-3.28-3.3-7.49-4.73-11.32-2.55-2.83,1.61-5.85,5.11-6.25,8.1s1.53,7.18,3.78,9.59c9.37,10,19.23,19.59,29.1,29.51-1.32,1.39-2.48,2.67-3.71,3.9-8.82,8.83-17.72,17.57-26.45,26.49-4.37,4.47-4.31,10.5-.24,14.61s10.16,4.27,14.64,0c5.79-5.51,11.42-11.2,17-16.93,4.41-4.53,8.63-9.24,13.37-14.33,4.05,4.29,7.17,7.72,10.43,11,6.76,6.82,13.5,13.66,20.42,20.3A10.24,10.24,0,0,0,441.29,272a21.56,21.56,0,0,0-3.88-4.82c-9.43-9.54-18.91-19-29.12-29.29a42.4,42.4,0,0,0,4.42-3.35c8.87-8.78,17.74-17.56,26.49-26.46,3.63-3.7,4.16-8.21,1.77-12.31s-6.33-6.08-11-4.91a15.91,15.91,0,0,0-6.73,4.18C413.59,204.58,404.1,214.29,393.46,225Z" />
      <path d="M406.58,135.25c-4.43-.42-7.51-.82-10.6-1a99.58,99.58,0,0,0-44.88,8.08,9.39,9.39,0,0,1-7-.2c-85.07-41.55-183.7-26.39-251.69,39.41-13.26,12.83-31.52,7-35.21-7.3-2.13-8.22.55-14.94,6.57-20.77,34.05-33,73.84-55.91,120.07-66.57q119.53-27.57,218.8,45.17C403.65,132.81,404.56,133.62,406.58,135.25Z" />
      <path d="M311.18,171.31l-16,35.57c-9.29-2.09-19.43-5.17-29.79-6.56-44.72-6-83.7,6.67-116.67,37.45-5.17,4.83-10.7,8.26-17.93,7.37-8.12-1-14-5.37-16.7-13.21s-.92-15.13,5.12-21.1a175.55,175.55,0,0,1,75.55-44.59c37.84-11,75.4-9.68,112.55,3.49C308.94,170.31,310.52,171,311.18,171.31Z" />
    </svg>
  );
};

IconOfflinemain.propTypes = {
  className: PropTypes.string,
};

export default IconOfflinemain;
