import mapActionToReducer from 'redux-action-reducer-mapper';
import { requestQueueActions } from 'actions/request';

export const initialState = {
  requests: [],
  processingOfRequestInQueueFailed: false,
  locationBeforeError: null,
};

const setProcessingOfRequestInQueueFailed = (state, action) => ({
  ...state,
  processingOfRequestInQueueFailed: action.payload,
});

const handleWebsocketMessage = (state, action) => {
  if (state.processingOfRequestInQueueFailed) {
    return state;
  }

  return {
    ...state,
    processingOfRequestInQueueFailed: action.payload.message.type === 'ERROR',
  };
};

const setLocationBeforeError = (state, action) => ({
  ...state,
  locationBeforeError: action.payload,
});

export const requestQueueReducer = mapActionToReducer({
  default: initialState,
  [requestQueueActions.HANDLE_WEBSOCKET_MESSAGE]: handleWebsocketMessage,
  [requestQueueActions.SET_LOCATION_BEFORE_ERROR]: setLocationBeforeError,
  [requestQueueActions.SET_PROCESSING_OF_REQUEST_IN_QUEUE_FAILED]: setProcessingOfRequestInQueueFailed,
});
