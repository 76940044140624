import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

import { invoiceTypes } from 'constants/account';
import { AccountConsumer } from 'contexts/account';
import DocumentWarning from 'icons/DocumentWarning';
import InvoiceLoad from 'icons/InvoiceLoad';
import InvoiceLoadStatic from 'icons/InvoiceLoad_static';
import { invoiceType } from 'types/account';

import AsyncButton from 'components/common/AsyncButton';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const InvoiceGenerate = ({
  activeStep,
  hasInvoiceBeenUpdated,
  hasInvoiceFailedToGenerate,
  hasInvoiceGenerated,
  invoice,
  isDownloadingInvoice,
  isInvoiceDirty,
  isInvoiceGenerating,
  isSendingToXero,
  tenantHasGivenConsentToAXeroApp,
  onChangeActiveStep,
  onDownloadInvoice,
  onGenerateInvoice,
  onGenerateInvoiceTemplate,
  onSendInvoiceTemplateToXero,
}) => (
  <AccountConsumer>
    {({ hasXeroIntegration }) => (
      <div className={classNames(
        styles.c_page__full_height,
        styles.has_faded_background,
        styles.u_push__top_double,
      )}
      >
        {((hasInvoiceGenerated && !isInvoiceDirty) || hasInvoiceFailedToGenerate) && (
          <div className={styles.o_view}>
            <div className={classNames(
              styles.o_view__main,
              styles.o_block_content,
              styles.u_text__center,
            )}
            >
              {hasInvoiceFailedToGenerate ? (
                <>
                  <div>
                    {isInvoiceGenerating ? (
                      <InvoiceLoad />
                    ) : (
                      <div className={rcStyles.invoice_generation_failed_wrapper}>
                        <DocumentWarning />
                      </div>
                    )}
                  </div>
                  <div className={styles.long_primer} data-test-id="invoiceFailedToGenerate">
                    {t('Invoice generation failed')}
                  </div>
                  <div className={styles.primer}>
                    <p>
                      {t(`An error occurred which prevented the ${invoice.invoiceType === invoiceTypes.PROFORMA ? 'proforma invoice' : 'invoice'} from generating. Click retry to try again.`)}
                    </p>
                  </div>
                  <div className={rcStyles.actions}>
                    <Button
                      onClick={
                        hasXeroIntegration
                          ? onGenerateInvoiceTemplate
                          : onGenerateInvoice
                      }
                      color="primary"
                      variant="contained"
                      data-test-id="retryGenerateInvoice"
                    >
                      {isInvoiceGenerating ? t('Generating invoice') : t('Retry')}
                    </Button>
                  </div>
                  <div className={styles.primer}>
                    <p>{t('If the problem persists, please contact support for assistance.')}</p>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <InvoiceLoadStatic />
                  </div>
                  <div className={styles.long_primer} data-test-id="invoiceGenerated">
                    {hasInvoiceBeenUpdated ? t('Invoice Updated') : t('Invoice Generated')}
                  </div>
                  <div className={styles.primer}>
                    {t(`Your ${invoice.invoiceType === invoiceTypes.PROFORMA ? 'proforma invoice' : 'invoice'} was generated and will show up in your transaction list.`)}
                  </div>
                  <div className={rcStyles.actions}>
                    <Button
                      onClick={() => onChangeActiveStep(activeStep - 1)}
                      color="primary"
                      variant="outlined"
                      data-test-id="editDetails"
                      className={styles.u_push__right}
                    >
                      {t('Edit Details')}
                    </Button>
                    {onDownloadInvoice && (
                      <AsyncButton
                        onClick={() => onDownloadInvoice(invoice.id)}
                        color="primary"
                        variant="outlined"
                        data-test-id="downloadInvoiceAfterGenerated"
                        data-invoice-id={invoice && invoice.id}
                        nonNativeProps={{
                          isActive: isDownloadingInvoice,
                          text: t('Download'),
                          displayIcon: true,
                        }}
                      />
                    )}
                    {hasXeroIntegration
                      && onSendInvoiceTemplateToXero
                      && invoice.invoiceType === invoiceTypes.INVOICE && (
                      <div className={styles.u_push__left}>
                        <AsyncButton
                          onClick={() => onSendInvoiceTemplateToXero(invoice.id)}
                          variant="outlined"
                          color="primary"
                          data-test-id="sendToXero"
                          data-invoice-id={invoice && invoice.id}
                          disabled={isSendingToXero || !tenantHasGivenConsentToAXeroApp}
                          nonNativeProps={{
                            isActive: isSendingToXero,
                            text: t('Send to Xero'),
                            displayIcon: false,
                          }}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {!hasInvoiceGenerated && !hasInvoiceFailedToGenerate && isInvoiceDirty && (
          <div className={styles.o_view}>
            <div className={styles.o_view__main}>
              <div className={classNames(styles.u_text__center, styles.o_block_content)}>
                <div>
                  {isInvoiceGenerating ? <InvoiceLoad /> : <InvoiceLoadStatic />}
                </div>
                <Button
                  onClick={
                    hasXeroIntegration
                      ? onGenerateInvoiceTemplate
                      : onGenerateInvoice
                  }
                  color="secondary"
                  variant="contained"
                  data-test-id="confirmGenerateInvoice"
                >
                  {isInvoiceGenerating ? t('Generating invoice') : t('Generate invoice')}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    )}
  </AccountConsumer>
);

InvoiceGenerate.propTypes = {
  activeStep: PropTypes.number.isRequired,
  hasInvoiceBeenUpdated: PropTypes.bool.isRequired,
  hasInvoiceFailedToGenerate: PropTypes.bool.isRequired,
  hasInvoiceGenerated: PropTypes.bool.isRequired,
  invoice: invoiceType.isRequired,
  isDownloadingInvoice: PropTypes.bool.isRequired,
  isInvoiceDirty: PropTypes.bool.isRequired,
  isInvoiceGenerating: PropTypes.bool.isRequired,
  isSendingToXero: PropTypes.bool.isRequired,
  tenantHasGivenConsentToAXeroApp: PropTypes.bool.isRequired,
  onChangeActiveStep: PropTypes.func.isRequired,
  onDownloadInvoice: PropTypes.func.isRequired,
  onGenerateInvoice: PropTypes.func.isRequired,
  onGenerateInvoiceTemplate: PropTypes.func.isRequired,
  onSendInvoiceTemplateToXero: PropTypes.func.isRequired,
};

export default InvoiceGenerate;
