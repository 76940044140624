import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';

import SortablePackageItem from 'components/catalogue/SortablePackageItem';
import styles from 'scss/main.scss';

import { Props } from './index.types';

const SortablePackageList = SortableContainer(({
  visibilityKey,
  labelForIncludeItem,
  items,
  disabled,
  onSetIsVisible,
}: Props) => (
  <div
    className={styles.c_grid__3col}
    data-test-id="sortablePackageContainer"
  >
    {items.map((item, index) => (
      <SortablePackageItem
        key={index}
        visibilityKey={visibilityKey}
        labelForIncludeItem={labelForIncludeItem}
        item={item}
        index={index}
        sortIndex={index}
        disabled={disabled}
        isSortable={!disabled}
        onSetIsVisible={onSetIsVisible}
      />
    ))}
  </div>
));

SortablePackageList.displayName = 'SortablePackageList';

export default SortablePackageList;
