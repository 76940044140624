import React from 'react';
import PropTypes from 'prop-types';

import rcStyles from './styles.scss';

const ContactCard = ({ name, image, details }) => (
  <div className={rcStyles.contact_card}>
    <div className={rcStyles.contact_card__image}>
      {image}
    </div>
    <div className={rcStyles.contact_card__details}>
      <div className={rcStyles.is_name}>
        {name}
      </div>
      <div className={rcStyles.is_contact_info}>
        {details}
      </div>
    </div>
  </div>
);

ContactCard.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.node,
  details: PropTypes.node,
};

export default ContactCard;
