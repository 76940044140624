import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { directoryListingType } from 'types/directoryListing';
import { matchType } from 'types/reactRouter';
import { Switch, Route } from 'react-router-dom';

import AccountsListScreen from 'screens/accounts/AccountsListScreen';
import AccountsScreen from 'screens/accounts/AccountsScreen';
import NotFoundScreen from 'screens/NotFoundScreen';

const AccountsSectionScreen = ({ match, isLoading, directoryListings }) => (
  <Fragment>
    <Switch>
      <Route
        path={`${match.url}`}
        exact
        render={() => (
          <AccountsListScreen
            isLoadingDirectoryListings={isLoading}
          />
        )}
      />
      <Route
        path={`${match.url}/:bereavementId`}
        render={() => (
          <AccountsScreen
            isLoadingDirectoryListings={isLoading}
            directoryListings={directoryListings}
          />
        )}
      />
      <Route key="not-found" component={NotFoundScreen} />
    </Switch>
  </Fragment>
);

AccountsSectionScreen.propTypes = {
  match: matchType,
  isLoading: PropTypes.bool,
  directoryListings: PropTypes.arrayOf(directoryListingType),
};

export default AccountsSectionScreen;
