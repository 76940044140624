import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import NotFoundScreen from 'screens/NotFoundScreen';
import AdminStaffMembersListScreen from 'screens/admin/AdminStaffMembersListScreen';
import AdminStaffMemberScreen from 'screens/admin/AdminStaffMemberScreen';

const AdminStaffMembersSectionScreen = ({ match }) => (
  <Fragment>
    <Switch>
      <Route
        exact
        key="create"
        path={`${match.url}/create`}
        render={() => (
          <AdminStaffMemberScreen />
        )}
      />
      <Route
        key="edit"
        path={`${match.url}/:staffMemberId`}
        render={() => (
          <AdminStaffMemberScreen />
        )}
      />
      <Route
        exact
        key="list"
        path={`${match.url}`}
        render={() => (
          <AdminStaffMembersListScreen />
        )}
      />
      <Route key="not-found" component={NotFoundScreen} />
    </Switch>
  </Fragment>
);

AdminStaffMembersSectionScreen.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AdminStaffMembersSectionScreen;
