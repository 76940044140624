import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import Select from 'components/common/Select';
import DateTimePicker from 'components/common/DateTimePicker';
import { implantTypes } from 'constants/care';
import rcStyles from './styles.scss';

const HazardousImplantsFormSection = ({ fieldData, disabled, onChange }) => (

  <div className={rcStyles.inline_inputs}>
    <div>
      <Select
        value={fieldData && fieldData.implant}
        onChange={event => onChange('implant', event.target.value)}
        label={t('Hazardous Implants')}
        inputProps={{ 'data-test-id': 'hazardousImplants' }}
        disabled={disabled}
      >
        {implantTypes.map(implantType => (
          <MenuItem
            key={implantType.value}
            value={implantType.value}
          >
            {implantType.name}
          </MenuItem>
        ))}
      </Select>
    </div>
    <div>
      <DateTimePicker
        value={fieldData.dateRemoved || null}
        label={t('Date removed')}
        onChange={date => onChange('dateRemoved', date)}
        isClearable
        disableFuture
        disabled={disabled}
        data-test-id="dateRemoved"
      />
    </div>
  </div>
);

HazardousImplantsFormSection.propTypes = {
  fieldData: PropTypes.shape({
    implant: PropTypes.string,
    dateRemoved: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default HazardousImplantsFormSection;
