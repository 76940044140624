import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import classNames from 'classnames';
import { ALL_NOTES } from 'constants/bereavement';
import { toProperCase } from 'services/utils';

import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const NoteCategoriesList = ({ categories, selectedCategory, onSelectCategory }) => (
  <List component="nav" aria-label="note categories" data-test-id="noteCategoriesList">
    <ListItem
      button
      dense
      selected={selectedCategory && selectedCategory === ALL_NOTES}
      onClick={() => onSelectCategory(ALL_NOTES)}
      data-test-id="notesAllNotes"
      className={classNames(
        selectedCategory && selectedCategory === ALL_NOTES && rcStyles.rc_selected,
        rcStyles.rc_default,
      )}
    >
      <div
        className={classNames(
          rcStyles.rc_note_category_label,
          rcStyles.has_no_count,
        )}
      >
        {t('All notes')}
      </div>
    </ListItem>
    {categories && categories.map(category => (
      <ListItem
        key={category.value}
        button
        dense
        selected={selectedCategory && selectedCategory === category.value}
        onClick={() => onSelectCategory(category.value)}
        data-test-id={`notes${toProperCase(category.value)}`}
        className={classNames(
          selectedCategory && selectedCategory === category.value && rcStyles.rc_selected,
          rcStyles.rc_default,
        )}
      >
        {category.count && (
          <Avatar className={styles.c_indicator__note} data-test-id="noteCategoryCount">
            {category.count}
          </Avatar>
        )}
        <div
          className={classNames(
            rcStyles.rc_note_category_label,
            !category.count && rcStyles.has_no_count,
            category.count && styles.u_push__left,
          )}
          data-test-id="noteCategoryLabel"
        >
          {t(category.label)}
        </div>
      </ListItem>
    ))}
  </List>
);

NoteCategoriesList.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  selectedCategory: PropTypes.string,
  onSelectCategory: PropTypes.func.isRequired,
};

export default NoteCategoriesList;
