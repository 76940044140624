import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { coffinSizeType } from 'types/bereavement';
import CoffinSizeFormSection from './CoffinSizeFormSection';

const emptySize = {
  length: null,
  width: null,
  depth: null,
};

class CoffinSizeFormSectionContainer extends Component {
  static propTypes = {
    value: coffinSizeType,
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any),
  }

  handleChange = (key, propertyValue) => {
    const { value, onChange } = this.props;
    const updatedSize = value || emptySize;

    updatedSize[key] = propertyValue;

    onChange(updatedSize);
  }

  render() {
    const {
      value,
      disabled,
      errors,
    } = this.props;

    return (
      <CoffinSizeFormSection
        value={value || emptySize}
        disabled={disabled}
        onChange={this.handleChange}
        errors={errors}
      />
    );
  }
}

export default CoffinSizeFormSectionContainer;
