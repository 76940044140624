import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';

import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { caseStatuses } from 'constants/bereavement';
import { bereavementType, arrangementType } from 'types/bereavement';
import { apolloClientType } from 'types/apollo';

import ArrangementReceptionVenueForm from './ArrangementReceptionVenueForm';
import { editArrangementReceptionVenueInformation } from './mutations.gql';
import { validationSchema } from './validation';

class ArrangementReceptionVenueFormContainer extends Component {
  static propTypes = {
    bereavement: bereavementType.isRequired,
    arrangement: arrangementType.isRequired,
    client: apolloClientType.isRequired,
    onSave: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    generateRefs: PropTypes.func.isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      isValidationEnabled: false,
      formData: {
        startDateTime: null,
        endDateTime: null,
        arrangedByFamily: false,
        catering: false,
        sequence: null,
        expectedAttendees: null,
        alternativeLocation: null,
        notes: null,
        isConfirmed: false,
      },
    };
  }

  componentDidMount() {
    const { arrangement, generateRefs } = this.props;
    const { formData } = this.state;

    const updatedFormData = {
      ...formData,
      ...((arrangement.categoryInformation && arrangement.categoryInformation.receptionVenue) || {}),
    };

    this.setState({ formData: updatedFormData });
    generateRefs(Object.keys(validationSchema.fields));
  }

  handleSave = () => {
    const {
      client, bereavement, arrangement, onSave, validate,
    } = this.props;
    const { formData } = this.state;

    const isValid = validate(formData, validationSchema, true);

    this.setState({ isValidationEnabled: true });
    if (!isValid) {
      return;
    }

    this.setState({ isSaving: true });
    onSave(bereavement.id, arrangement.id, 'receptionVenue', formData);

    const input = {
      bereavementId: bereavement.id,
      arrangementId: arrangement.id,
      ...formData,
    };

    client.mutate({
      mutation: editArrangementReceptionVenueInformation,
      variables: { input },
    }).finally(() => this.setState({ isSaving: false }));
  }

  handleChange = (key, value) => {
    const { validate } = this.props;
    const { formData } = this.state;

    const updatedFormData = {
      ...formData,
      [key]: value,
    };

    this.setState(({
      formData: updatedFormData,
    }));
    validate(updatedFormData, validationSchema);
  }

  handleNumericChange = (key, value) => {
    const { validate } = this.props;
    const { formData } = this.state;
    const updatedFormData = {
      ...formData,
      [key]: !value && value !== 0 ? null : value,
    };
    this.setState(({
      formData: updatedFormData,
    }));
    validate(updatedFormData, validationSchema);
  }

  render() {
    const {
      bereavement,
      arrangement,
      errors,
      formRefs,
    } = this.props;
    const { isSaving, formData, isValidationEnabled } = this.state;

    return (
      <ArrangementReceptionVenueForm
        arrangement={arrangement}
        formData={formData}
        disabled={isSaving || bereavement.caseStatus === caseStatuses.CLOSED}
        isSaving={isSaving}
        onChange={this.handleChange}
        onNumericChange={this.handleNumericChange}
        onSave={this.handleSave}
        formRefs={formRefs}
        errors={isValidationEnabled ? errors : {}}
      />
    );
  }
}

export default withApollo(withValidation(ArrangementReceptionVenueFormContainer));
