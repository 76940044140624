import { accountActions } from 'actions/account';
import {
  getArrayIndexForId, pluralize, updateArrayByIndex,
} from 'services/utils';
import { updateLinkedInvoice } from 'services/utils/account';
import { accountCalculator } from 'services/accountCalculator';

const updateAccountStatus = (state, action) => {
  const { bereavementId, accountStatus } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const bereavement = state.bereavements[bereavementIndex];

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...bereavement,
        accountStatus,
      },
    ),
  };
};

const addInvoice = (state, action) => {
  const { bereavementId, invoice } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const bereavement = state.bereavements[bereavementIndex];
  const existingInvoices = bereavement.account.invoices || [];

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...bereavement,
        account: {
          ...bereavement.account,
          invoices: [
            ...existingInvoices,
            invoice,
          ],
        },
      },
    ),
  };
};

const addInvoiceTemplate = (state, action) => {
  const { bereavementId, invoiceTemplate } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const bereavement = state.bereavements[bereavementIndex];
  const existingInvoiceTemplates = bereavement.account.invoiceTemplates || [];

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...bereavement,
        account: {
          ...bereavement.account,
          invoiceTemplates: [
            ...existingInvoiceTemplates,
            invoiceTemplate,
          ],
        },
      },
    ),
  };
};

const addAccountTransaction = (state, action, key) => {
  const { bereavementId } = action.payload;
  const transaction = action.payload[key];
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const bereavement = state.bereavements[bereavementIndex];
  const { invoices } = bereavement.account;
  const transactions = bereavement.account[pluralize(key)]
    ? [...bereavement.account[pluralize(key)], transaction]
    : [transaction];

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...bereavement,
        account: {
          ...bereavement.account,
          [pluralize(key)]: transactions,
          invoices: updateLinkedInvoice(
            invoices, transaction.invoiceId, transactions, key,
          ),
        },
      },
    ),
  };
};

const addCredit = (state, action) => addAccountTransaction(state, action, 'credit');

const addPayment = (state, action) => addAccountTransaction(state, action, 'payment');

const addCloseEvent = (state, action) => addAccountTransaction(state, action, 'closeEvent');

const addReopenEvent = (state, action) => addAccountTransaction(state, action, 'reopenEvent');

const updateInvoice = (state, action) => {
  const { bereavementId, invoice } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const bereavement = state.bereavements[bereavementIndex];
  const { account } = bereavement;

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...bereavement,
        account: {
          ...account,
          invoices: [
            ...account.invoices.map(accountInvoice => (
              accountInvoice.id === invoice.id ? invoice : accountInvoice
            )),
          ],
        },
      },
    ),
  };
};

const updateInvoiceTemplate = (state, action) => {
  const { bereavementId, invoiceTemplate } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const bereavement = state.bereavements[bereavementIndex];
  const { account } = bereavement;

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...bereavement,
        account: {
          ...account,
          invoiceTemplates: [
            ...account.invoiceTemplates.map(accountInvoiceTemplate => (
              accountInvoiceTemplate.id === invoiceTemplate.id ? invoiceTemplate : accountInvoiceTemplate
            )),
          ],
        },
      },
    ),
  };
};

const updateAccountTransaction = (state, action, key) => {
  const { bereavementId } = action.payload;
  const transaction = action.payload[key];

  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const bereavement = state.bereavements[bereavementIndex];
  const { invoices } = bereavement.account;
  const transactions = bereavement.account[pluralize(key)].map(existingTransaction => (
    existingTransaction.id === transaction.id ? transaction : existingTransaction
  ));

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...bereavement,
        account: {
          ...bereavement.account,
          [pluralize(key)]: transactions,
          invoices: updateLinkedInvoice(
            invoices, transaction.invoiceId, transactions, key,
          ),
        },
      },
    ),
  };
};

const updateCredit = (state, action) => updateAccountTransaction(state, action, 'credit');

const updatePayment = (state, action) => updateAccountTransaction(state, action, 'payment');

const removeTransaction = (state, action, key) => {
  const { bereavementId, invoiceId } = action.payload;
  const transactionId = action.payload[`${key}Id`];

  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const bereavement = state.bereavements[bereavementIndex];
  const { invoices } = bereavement.account;
  const transactions = bereavement.account[pluralize(key)].filter(existingTransaction => (
    existingTransaction.id !== transactionId
  ));

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...bereavement,
        account: {
          ...bereavement.account,
          [pluralize(key)]: transactions,
          invoices: updateLinkedInvoice(
            invoices, invoiceId, transactions, key,
          ),
        },
      },
    ),
  };
};

const removeCredit = (state, action) => removeTransaction(state, action, 'credit');

const removePayment = (state, action) => removeTransaction(state, action, 'payment');

const updateNextInvoiceNumber = (state, action) => {
  const { bereavementId, nextInvoiceNumber } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const bereavement = state.bereavements[bereavementIndex];

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...bereavement,
        account: {
          ...bereavement.account,
          nextInvoiceNumber,
        },
      },
    ),
  };
};

const setDocuments = (state, action) => {
  const { bereavementId, documents } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const bereavement = state.bereavements[bereavementIndex];

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...bereavement,
        account: {
          ...bereavement.account,
          documents,
        },
      },
    ),
  };
};

export const accountCalculatorReducer = (state, action) => {
  if (!action
    || !action.type
    || !Object.values(accountActions).find(value => action.type === value)
    || !action.payload
    || !action.payload.bereavementId) {
    return state;
  }
  const { bereavementId } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const bereavement = state.bereavements[bereavementIndex];
  const arrangement = (bereavement && bereavement.arrangements
    && bereavement.arrangements.find(item => item.isConfirmed)) || {};
  const calculation = arrangement && arrangement.calculation;
  const caseTotal = calculation && calculation.finalTotal;
  const account = bereavement && bereavement.account;
  if (!account) {
    return state;
  }
  const {
    invoices, invoiceTemplates, credits, payments, historicFuneralAccountCharge,
  } = account;
  const totals = accountCalculator(
    invoices, invoiceTemplates, credits, payments, historicFuneralAccountCharge, caseTotal,
  );

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...bereavement,
        account: {
          ...bereavement.account,
          totals,
        },
      },
    ),
  };
};

export const accountReducer = {
  [accountActions.UPDATE_STATUS]: updateAccountStatus,
  [accountActions.ADD_INVOICE]: addInvoice,
  [accountActions.UPDATE_NEXT_INVOICE_NUMBER]: updateNextInvoiceNumber,
  [accountActions.ADD_INVOICE_TEMPLATE]: addInvoiceTemplate,
  [accountActions.ADD_CREDIT]: addCredit,
  [accountActions.ADD_PAYMENT]: addPayment,
  [accountActions.ADD_CLOSE_EVENT]: addCloseEvent,
  [accountActions.ADD_REOPEN_EVENT]: addReopenEvent,
  [accountActions.UPDATE_CREDIT]: updateCredit,
  [accountActions.UPDATE_PAYMENT]: updatePayment,
  [accountActions.UPDATE_INVOICE]: updateInvoice,
  [accountActions.UPDATE_INVOICE_TEMPLATE]: updateInvoiceTemplate,
  [accountActions.REMOVE_PAYMENT]: removePayment,
  [accountActions.REMOVE_CREDIT]: removeCredit,
  [accountActions.SET_DOCUMENTS]: setDocuments,
};

export default accountReducer;
