import sha1 from 'sha1';
import { uniqueArray } from 'services/utils';
import { categories } from 'constants/arrangement';

export const productCategoriesDefinedInPackage = (packageObject) => {
  if (!packageObject) {
    return [];
  }
  const foundCategories = packageObject.products.reduce(
    (accumulator, product) => [...accumulator, product.category],
    [],
  );
  return uniqueArray(foundCategories);
};

export const serviceCategoriesDefinedInPackage = (packageObject) => {
  if (!packageObject) {
    return [];
  }

  const foundCategories = packageObject.services.reduce(
    (accumulator, service) => [...accumulator, service.category],
    [],
  );
  return uniqueArray(foundCategories);
};

export const categoriesDefinedInPackage = packageObject => [
  ...productCategoriesDefinedInPackage(packageObject),
  ...serviceCategoriesDefinedInPackage(packageObject),
];

export const defaultServiceSelectionsForAPackage = (packageObject) => {
  const packageServices = ((packageObject && packageObject.services) || []).filter(service => (
    service.category === categories.DISBURSEMENTS
    || service.category === categories.PROFESSIONAL_SERVICES
  ));
  return packageServices.map(service => ({
    id: sha1(service.id),
    serviceId: service.id,
    service,
    overridePrice: null,
    isPackageSelection: true,
    variantId: service.variants.find(variant => variant.isDefault).id,
    salePriceAtConfirmation: null,
  }));
};

export const defaultProductSelectionsForAPackage = () => [];

export const determineIsPackageSelection = (packageSelection, item, selections, property) => {
  if (!packageSelection) {
    return false;
  }

  const selectionList = selections || [];
  const packageObject = packageSelection.package;
  const quantitiesProperty = `${property}CategoryMaxQuantities`;
  const idsProperty = `${property}Ids`;

  const selectionsOfCategory = selectionList
    .filter(selection => (
      selection[property].category === item.category && selection.isPackageSelection
    ));

  if (!packageObject[quantitiesProperty]) {
    return packageObject[idsProperty].indexOf(item.id) !== -1;
  }

  const packageItemCategoryMaxQuantity = packageObject[quantitiesProperty]
    .find(maxQuantity => maxQuantity.category === item.category);

  if (!packageItemCategoryMaxQuantity) {
    return packageObject[idsProperty].indexOf(item.id) !== -1;
  }

  return packageObject[idsProperty].indexOf(item.id) !== -1
    && selectionsOfCategory.length < packageItemCategoryMaxQuantity.value;
};
