import { onError } from 'apollo-link-error';
import { store } from 'services/state';
import { Observable } from 'apollo-link';
import { setAnonymousUserAction } from 'actions/portal';
import { setUserAction } from 'actions/user';
import refreshTokenClient from '../clients/refreshTokenClient';
import { refreshToken as refreshTokenQuery } from './refreshToken.gql';

const hasTokenExpired = (graphQLErrors) => {
  if (graphQLErrors && graphQLErrors[0].statusCode === 401) {
    return true;
  }
  return false;
};

const attemptToRefreshToken = accessToken => refreshTokenClient.query({
  query: refreshTokenQuery,
  variables: { accessToken },
});

const refreshTokenLink = onError(({
  graphQLErrors,
  networkError,
  operation,
  forward,
}) => {
  if (!hasTokenExpired(graphQLErrors, networkError)) {
    return null;
  }

  const { isPortalActive } = store.getState().portalStore;
  const { accessToken } = isPortalActive
    ? store.getState().portalStore.user || {}
    : store.getState().userStore.user || {};

  if (!accessToken) {
    return null;
  }

  return new Observable((observer) => {
    attemptToRefreshToken(accessToken)
      .then((refreshResponse) => {
        if (refreshResponse.errors) {
          window.location = '/logout';
          return;
        }
        if (isPortalActive) {
          store.dispatch(setAnonymousUserAction(refreshResponse.data.refreshToken));
        } else {
          store.dispatch(setUserAction(refreshResponse.data.refreshToken));
        }
      })
      .then(() => {
        const subscriber = {
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        };
        forward(operation).subscribe(subscriber);
      })
      .catch((error) => {
        window.location = '/logout';
        observer.error(error);
      });
  });
});

export default refreshTokenLink;
