import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import moment from 'moment';
import classNames from 'classnames';
import { addressType } from 'types/common';
import { buildAddressString } from 'services/utils';

import styles from './styles.scss';

const CalendarEventContentCustom = ({
  start,
  title,
  description,
  end,
  location,
}) => (
  <Fragment>
    <div className={classNames(styles.rbc_dialog__body)}>
      <div className={classNames(styles.rbc_dialog__item, styles.rbc_dialog__item__wide)}>
        <div className={classNames(styles.rbc_dialog__item_label)}>
          {t('Title')}
        </div>
        <div className={classNames(styles.rbc_dialog__item_value)}>
          {title}
        </div>
      </div>
      {description && (
        <div className={classNames(styles.rbc_dialog__item, styles.rbc_dialog__item__wide)}>
          <div className={classNames(styles.rbc_dialog__item_label)}>
            {t('Description')}
          </div>
          <div className={classNames(styles.rbc_dialog__item_value)}>
            {description}
          </div>
        </div>
      )}
      <div className={classNames(styles.rbc_dialog__item)}>
        <div className={classNames(styles.rbc_dialog__item_label)}>{t('Date')}</div>
        <div className={classNames(styles.rbc_dialog__item_value)}>
          {moment(start).format('DD MMM YYYY')}
        </div>
      </div>
      <div className={classNames(styles.rbc_dialog__item)}>
        <div className={classNames(styles.rbc_dialog__item_label)}>
          {t('Start time')}
        </div>
        <div className={classNames(styles.rbc_dialog__item_value)}>
          {moment(start).format('HH:mm')}
        </div>
        <div className={classNames(styles.rbc_dialog__item_label)}>
          {t('End time')}
        </div>
        <div className={classNames(styles.rbc_dialog__item_value)}>
          {moment(end).format('HH:mm')}
        </div>
      </div>
      {location && (
        <div className={classNames(styles.rbc_dialog__item, styles.rbc_dialog__item__wide)}>
          <div className={classNames(styles.rbc_dialog__item_label)}>
            {t('Location')}
          </div>
          <div className={classNames(styles.rbc_dialog__item_value)}>
            <address>
              {buildAddressString(location)}
            </address>
          </div>
        </div>
      )}
    </div>
  </Fragment>
);

CalendarEventContentCustom.propTypes = {
  start: PropTypes.instanceOf(Date).isRequired,
  end: PropTypes.instanceOf(Date).isRequired,
  description: PropTypes.string,
  title: PropTypes.string,
  location: addressType,
};

export default CalendarEventContentCustom;
