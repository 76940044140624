import React from 'react';
import { t } from 'i18next';
import IconEmail from '@material-ui/icons/Email';
import IconPhone from '@material-ui/icons/Phone';
import rcStyles from './styles.scss';

const SupportFooter = () => (

  <div className={rcStyles.help_block}>
    <span className={rcStyles.title}>{t('For assistance, please contact us:')}</span>
    <div className={rcStyles.links}>
      <a
        className={rcStyles.is_link}
        href={`mailto:${t('support@arranger.com')}`}
      >
        <IconEmail className={rcStyles.icon} />
        {t('support@arranger.com')}
      </a>
      <a
        className={rcStyles.is_link}
        href={`tel:${t('01392793080')}`}
      >
        <IconPhone className={rcStyles.icon} />
        {t('01392 793 080')}
      </a>
    </div>
  </div>

);

export default SupportFooter;
