import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';

import { vehicleTypes, vehicleTypeLabels } from 'constants/service';
import { vehiclesInfoType } from 'types/bereavement';
import IconLoad from 'icons/IconLoad';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';
import VehiclesList from './VehiclesList';

const VehiclesForm = ({
  isSaving,
  vehicles,
  errors,
  disabled,
  onSaveVehicles,
  onCreateVehicle,
  onRemoveVehicle,
  onUpdateVehicle,
  onCreateVehicleJourney,
  onUpdateVehicleJourney,
  onRemoveVehicleJourney,
  onDuplicateVehicleJourney,
  onConfirm,
  isConfirmed,
}) => {
  const [anchorElement, setAnchorElement] = useState(null);

  const vehiclesWithErrors = vehicles
    ? vehicles.map((vehicle, index) => ({
      ...vehicle,
      errors: errors[`${index}`] || {},
    }))
    : [];
  const hearseVehicles = vehiclesWithErrors.filter(vehicle => vehicle.isHearse);
  const passengerVehicles = vehiclesWithErrors.filter(vehicle => !vehicle.isHearse);

  return (
    <div className={rcStyles.v_spacing}>
      <VehiclesList
        disabled={disabled}
        vehicles={hearseVehicles}
        onRemoveVehicle={onRemoveVehicle}
        onCreateVehicleJourney={onCreateVehicleJourney}
        onUpdateVehicleJourney={onUpdateVehicleJourney}
        onRemoveVehicleJourney={onRemoveVehicleJourney}
        onDuplicateVehicleJourney={onDuplicateVehicleJourney}
      />
      <VehiclesList
        disabled={disabled}
        vehicles={passengerVehicles}
        onRemoveVehicle={onRemoveVehicle}
        onUpdateVehicle={onUpdateVehicle}
        onCreateVehicleJourney={onCreateVehicleJourney}
        onUpdateVehicleJourney={onUpdateVehicleJourney}
        onRemoveVehicleJourney={onRemoveVehicleJourney}
        onDuplicateVehicleJourney={onDuplicateVehicleJourney}
      />
      <Fragment>
        <span>
          <Button
            color="primary"
            variant="outlined"
            size="medium"
            className={classNames(styles.u_push__bottom)}
            disabled={disabled}
            onClick={(event => setAnchorElement(event.target))}
            aria-owns={anchorElement ? 'add-a-journey-menu' : undefined}
            aria-haspopup="true"
            data-test-id="addAJourney"
          >
            <AddIcon className={styles.u_push__right_half} />
            {t('Add a journey')}
          </Button>
        </span>
        <Menu
          id="add-a-journey-menu"
          anchorEl={anchorElement}
          open={!!anchorElement}
          onClose={() => setAnchorElement()}
        >
          {Object.keys(vehicleTypes).map((key) => {
            const value = vehicleTypes[key];
            return (
              <MenuItem
                key={key}
                onClick={(() => {
                  onCreateVehicle(value);
                  setAnchorElement();
                })}
                data-test-id={`${key}MenuItem`}
              >
                {t(vehicleTypeLabels[key])}
              </MenuItem>
            );
          })}
        </Menu>
      </Fragment>
      <div className={styles.c_confirmed__wrap}>
        <FormControlLabel
          control={(
            <Checkbox
              className={styles.c_confirmed__checkbox}
              checked={isConfirmed}
              onChange={event => onConfirm(event.target.checked)}
              inputProps={{ 'data-test-id': 'isConfirmed' }}
              disabled={disabled}
              color="primary"
            />
          )}
          label={t('Confirm vehicles available for all journeys')}
        />
      </div>
      <span>
        <Button
          variant="contained"
          color="primary"
          data-test-id="saveVehiclesCategory"
          onClick={onSaveVehicles}
          disabled={disabled}
        >
          {t('Save')}
        </Button>
      </span>
      {isSaving && (
        <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
          <IconLoad title={t('Saving')} />
        </div>
      )}
    </div>
  );
};

VehiclesForm.propTypes = {
  vehicles: PropTypes.arrayOf(vehiclesInfoType),
  isSaving: PropTypes.bool,
  errors: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  onSaveVehicles: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCreateVehicle: PropTypes.func.isRequired,
  onRemoveVehicle: PropTypes.func.isRequired,
  onUpdateVehicle: PropTypes.func.isRequired,
  onCreateVehicleJourney: PropTypes.func.isRequired,
  onUpdateVehicleJourney: PropTypes.func.isRequired,
  onRemoveVehicleJourney: PropTypes.func.isRequired,
  onDuplicateVehicleJourney: PropTypes.func.isRequired,
};

export default VehiclesForm;
