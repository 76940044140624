import React from 'react';
import PropTypes from 'prop-types';
import SupportInformation from 'components/support/SupportInformation';
import ContactSupport from 'components/support/ContactSupport';
import EmailSupport from 'components/support/EmailSupport';
import SlideInWindow from 'components/common/SlideInWindow';

const SupportSection = ({ location }) => (
  <SlideInWindow>
    <SupportInformation location={location} />
    <ContactSupport windowName="contact" />
    <EmailSupport windowName="email" />
  </SlideInWindow>
);

SupportSection.propTypes = {
  location: PropTypes.string,
};

export default SupportSection;
