import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Chip from '@material-ui/core/Chip';
import Slider from 'react-slick';

import Arrow from 'components/common/Arrow';

import styles from 'scss/main.scss';
import 'slick-carousel/slick/slick.scss?raw';
import 'slick-carousel/slick/slick-theme.scss?raw';
import rcStyles from './styles.scss';

const TagBanner = ({
  tags,
  onChange,
  selectedTags,
}) => {
  const sliderSettings = {
    infinite: tags.length > 10,
    speed: 500,
    slidesToShow: 1,
    variableWidth: true,
    prevArrow: <Arrow size="small" dir="left" />,
    nextArrow: <Arrow size="small" dir="right" />,
  };

  return (
    <Fragment>
      <Slider
        {...sliderSettings}
      >
        { tags.map(tag => (
          <div key={tag.id} className={styles.u_push__right}>
            <Chip
              label={tag.value}
              onClick={event => onChange(event, tag)}
              className={
                  selectedTags.indexOf(tag.value) >= 0
                    ? rcStyles.tagSelected
                    : rcStyles.tag
              }
              data-test-id="tag"
            />
          </div>
        ))}
      </Slider>
    </Fragment>
  );
};

TagBanner.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  selectedTags: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.shape({}),
};

export default TagBanner;
