import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const IconMemorialisation = (props) => {
  const { className } = props;
  const classes = className ? `${className} ${styles.rc_icon}` : styles.rc_icon;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="14 14 125 125" className={classes}>
      <defs>
        <style>{'.icon-memorialisation-1{fill:#5994d4;}.icon-memorialisation-2{fill:#5994d4;}'}</style>
      </defs>
      <title>Memorialisation</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path className="icon-memorialisation-2" d="M111,55A33.49,33.49,0,0,0,44,55h0v71h67V55Z" />
          <rect className="icon-memorialisation-1" x="54" y="103" width="47" height="3" />
          <rect className="icon-memorialisation-1" x="54" y="93" width="47" height="3" />
          <rect className="icon-memorialisation-1" x="54" y="83" width="47" height="3" />
          <rect className="icon-memorialisation-1" x="57" y="73" width="16.12" height="3" />
          <rect className="icon-memorialisation-1" x="80.88" y="73" width="16.12" height="3" />
          <path className="icon-memorialisation-1" d="M77,53a4,4,0,1,0-4-4A4,4,0,0,0,77,53Zm0,2c-2.67,0-8,1.34-8,4v1a1,1,0,0,0,1,1H84a1,1,0,0,0,1-1V59C85,56.34,79.67,55,77,55Z" />
        </g>
      </g>
    </svg>
  );
};

IconMemorialisation.propTypes = {
  className: PropTypes.string,
};

export default IconMemorialisation;
