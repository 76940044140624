import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { updatePossessionAction, addPossessionAction } from 'actions/bereavement';
import { generateHexId } from 'services/utils';
import { bereavedPersonConnectionType, possessionType } from 'types/bereavement';
import { staffMemberType } from 'types/staffMember';
import { apolloClientType } from 'types/apollo';
import { createDeceasedPossession, editDeceasedPossession } from './mutations.gql';
import PossessionsSection from './PossessionsSection';

class PossessionsSectionContainer extends Component {
  static propTypes = {
    client: apolloClientType.isRequired,
    possessions: PropTypes.arrayOf(possessionType),
    advisoryPossessions: PropTypes.string,
    bereavedPeopleConnections: PropTypes.arrayOf(bereavedPersonConnectionType).isRequired,
    currentStaff: staffMemberType.isRequired,
    bereavementId: PropTypes.string.isRequired,
    deceasedPersonId: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    addPossession: PropTypes.func.isRequired,
    updatePossession: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      editingItem: null,
      isFormVisible: false,
      isReleaseFormModalOpen: false,
    };
  }

  handleAdd = () => {
    this.setState({
      isFormVisible: true,
    });
  }

  handleCancel = (e) => {
    e.preventDefault();
    this.setState({
      editingItem: null,
      isFormVisible: false,
    });
  }

  handleEdit = (data) => {
    this.setState({
      isFormVisible: true,
      editingItem: data,
    });
  }

  handleSave = (formData) => {
    const { editingItem } = this.state;

    if (editingItem) {
      this.updatePossession(formData);
    } else {
      this.addPossession(formData);
    }
    this.setState({ isFormVisible: false, editingItem: null });
  }

  getNewAction = (actionTaken) => {
    const { currentStaff } = this.props;
    const newAction = {
      action: actionTaken,
      timeCreated: moment().format(),
    };

    if (currentStaff) {
      newAction.createdById = currentStaff.id;
      newAction.createdBy = currentStaff;
    }

    return newAction;
  }

  addPossession = (formData) => {
    const {
      bereavementId, deceasedPersonId, client, addPossession,
    } = this.props;

    const possession = {
      id: generateHexId(),
      type: formData.type,
      description: formData.description,
      imageId: formData.image ? formData.image.id : null,
      requiredForViewing: formData.requiredForViewing || false,
      familyWishes: formData.familyWishes || null,
      note: formData.note || null,
    };

    const action = this.getNewAction(formData.actionTaken);

    addPossession(bereavementId, {
      ...possession,
      image: formData.image || null,
      actionHistory: [action],
    });

    client.mutate({
      mutation: createDeceasedPossession,
      variables: {
        input: {
          ...possession,
          actionTaken: formData.actionTaken,
          deceasedPersonId,
          bereavementId,
        },
      },
    });
  }

  updatePossession = (formData) => {
    const {
      bereavementId, deceasedPersonId, client, updatePossession,
    } = this.props;
    const { editingItem } = this.state;

    const action = formData.actionTaken ? this.getNewAction(formData.actionTaken) : null;

    updatePossession({
      bereavementId,
      possessionId: editingItem.id,
      newAction: action,
      image: formData.image,
      note: formData.note || null,
      requiredForViewing: formData.requiredForViewing || false,
      familyWishes: formData.familyWishes || null,
    });
    client.mutate({
      mutation: editDeceasedPossession,
      variables: {
        input: {
          id: editingItem.id,
          imageId: (formData.image && formData.image.id) || formData.imageId,
          actionTaken: formData.actionTaken,
          requiredForViewing: formData.requiredForViewing || false,
          familyWishes: formData.familyWishes || null,
          note: formData.note || null,
          deceasedPersonId,
          bereavementId,
        },
      },
    });
  }

  handleReleaseFormModalChange = (isOpen) => {
    this.setState({ isReleaseFormModalOpen: isOpen });
  }

  render() {
    const {
      bereavementId,
      deceasedPersonId,
      possessions,
      advisoryPossessions,
      bereavedPeopleConnections,
      disabled,
    } = this.props;
    const { isFormVisible, editingItem, isReleaseFormModalOpen } = this.state;
    return (
      <PossessionsSection
        isEditing={!!editingItem}
        possessions={possessions || []}
        advisoryPossessions={advisoryPossessions}
        bereavedPeopleConnections={bereavedPeopleConnections}
        bereavementId={bereavementId}
        deceasedPersonId={deceasedPersonId}
        isFormVisible={isFormVisible}
        isReleaseFormModalOpen={isReleaseFormModalOpen}
        editingItem={editingItem}
        disabled={disabled}
        onAdd={this.handleAdd}
        onCancel={this.handleCancel}
        onEdit={this.handleEdit}
        onSave={this.handleSave}
        onReleaseFormOpen={() => this.handleReleaseFormModalChange(true)}
        onReleaseFormClose={() => this.handleReleaseFormModalChange(false)}
      />
    );
  }
}

const mapStateToProps = state => ({
  currentStaff: state.userStore.user.staffMember,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updatePossession: updatePossessionAction,
  addPossession: addPossessionAction,
}, dispatch);

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(PossessionsSectionContainer),
);
