import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { caseStatuses } from 'constants/bereavement';
import { bereavementType, arrangementType } from 'types/bereavement';
import { apolloClientType } from 'types/apollo';
import ArrangementBurialForm from './ArrangementBurialForm';
import { editArrangementCemeteryInformation } from './mutations.gql';
import { validationSchema } from './validation';

class ArrangementBurialFormContainer extends Component {
  static propTypes = {
    bereavement: bereavementType.isRequired,
    arrangement: arrangementType.isRequired,
    client: apolloClientType.isRequired,
    onSave: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    generateRefs: PropTypes.func.isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      isValidationEnabled: false,
      categoryInformation: {},
    };
  }

  componentDidMount() {
    const { arrangement, generateRefs } = this.props;
    const categoryInformation = arrangement.categoryInformation
      && arrangement.categoryInformation.cemetery
      ? arrangement.categoryInformation.cemetery
      : {
        alternativeLocation: null,
        familyToBackfill: false,
        familyToLowerCoffin: false,
        stonesToBeRemoved: false,
      };
    this.setState({ categoryInformation });
    generateRefs(Object.keys(validationSchema.fields));
  }

  handleSave = () => {
    const {
      client, bereavement, arrangement, onSave, validate,
    } = this.props;
    const { categoryInformation } = this.state;

    const isValid = validate(categoryInformation, validationSchema, true);

    this.setState({ isValidationEnabled: true });
    if (!isValid) {
      return;
    }

    this.setState({ isSaving: true });
    onSave(bereavement.id, arrangement.id, 'cemetery', categoryInformation);

    const input = {
      bereavementId: bereavement.id,
      arrangementId: arrangement.id,
      ...categoryInformation,
    };

    client.mutate({
      mutation: editArrangementCemeteryInformation,
      variables: { input },
    }).finally(() => this.setState({ isSaving: false }));
  }

  handleChange = (key, value) => {
    const { validate } = this.props;
    const { categoryInformation } = this.state;

    const updatedCategoryInformation = {
      ...categoryInformation,
      [key]: value,
    };

    if (key === 'stonesToBeRemoved' && !value) {
      updatedCategoryInformation.stonesRemovalDate = null;
    }

    this.setState(({
      categoryInformation: updatedCategoryInformation,
    }));
    validate(updatedCategoryInformation, validationSchema);
  }

  handleNumericChange = (key, value) => {
    const { validate } = this.props;
    const { categoryInformation } = this.state;
    const updatedCategoryInformation = {
      ...categoryInformation,
      [key]: !value && value !== 0 ? null : value,
    };
    this.setState(({
      categoryInformation: updatedCategoryInformation,
    }));
    validate(updatedCategoryInformation, validationSchema);
  }

  render() {
    const {
      bereavement,
      arrangement,
      errors,
      formRefs,
    } = this.props;
    const { categoryInformation, isSaving, isValidationEnabled } = this.state;

    return (
      <ArrangementBurialForm
        arrangement={arrangement}
        categoryInformation={categoryInformation}
        disabled={isSaving || bereavement.caseStatus === caseStatuses.CLOSED}
        isSaving={isSaving}
        onChange={this.handleChange}
        onNumericChange={this.handleNumericChange}
        onSave={this.handleSave}
        formRefs={formRefs}
        errors={isValidationEnabled ? errors : {}}
      />
    );
  }
}

export default withApollo(withValidation(ArrangementBurialFormContainer));
