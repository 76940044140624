import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';
import IconLink from '@material-ui/icons/Link';

import AddressFormSection from 'components/common/AddressFormSection';
import DynamicList from 'components/common/DynamicList';
import EmailFormSection from 'components/common/EmailFormSection';
import PhoneFormSection from 'components/common/PhoneFormSection';
import CategoryLookup from 'components/common/lookups/CategoryLookup';
import WebsitesFormSection from 'components/common/WebsitesFormSection';
import { categoriesWithNames } from 'constants/directoryListing';
import IconAddEmail from 'icons/IconAddEmail';
import IconAddPhone from 'icons/IconAddPhone';
import { directoryListingType } from 'types/directoryListing';

import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const DirectoryListingForm = ({
  formData,
  onChange,
  formRefs,
  errors,
}) => (
  <div className={rcStyles.v_spacing}>
    <div
      className={rcStyles.colspan2}
      ref={formRefs.name}
    >
      <TextField
        fullWidth
        label="Company Name"
        value={formData.name || ''}
        onChange={event => onChange('name', event.target.value)}
        inputProps={{
          'data-test-id': 'companyName',
        }}
        name="name"
        error={errors && !!errors.name}
        helperText={errors && errors.name}
      />
    </div>
    <div
      className={rcStyles.colspan2}
      ref={formRefs.address}
    >
      <AddressFormSection
        address={formData.address}
        onChange={address => onChange('address', address)}
        errors={errors && errors.address}
        data-test-id="addressFormSection"
      />
    </div>
    <div
      className={rcStyles.colspan2}
      ref={formRefs.categories}
    >
      <FormControl fullWidth>
        <InputLabel shrink>
          {t('Category')}
        </InputLabel>
        <div className={rcStyles.lookup_container}>
          <CategoryLookup
            value={formData.categories}
            label="Select category"
            onChange={value => onChange('categories', value)}
            data-test-id="categoriesLookup"
            categories={categoriesWithNames}
            isMulti
          />
        </div>
      </FormControl>
    </div>
    <div
      className={rcStyles.colspan2}
      ref={formRefs.phones}
    >
      <DynamicList
        component={(
          <PhoneFormSection />
        )}
        addButtonComponent={
          <IconButton data-test-id="addPhone" />
        }
        removeButtonProps={{
          variant: 'outlined',
          color: 'primary',
          size: 'small',
          classes: { root: styles.o_button__round },
        }}
        removeButtonInner={
          <RemoveIcon />
        }
        addButtonProps={{
          variant: 'contained',
          color: 'secondary',
          size: 'small',
          classes: { root: styles.o_button__secondary },
        }}
        addButtonInner={(
          <IconAddPhone />
        )}
        errors={errors && errors.phones}
        onChange={phones => onChange('phones', phones)}
        fullWidth
        secondary
        dataList={formData.phones || []}
        defaultEmpty
        data-test-id="phones"
      />
    </div>
    <div
      className={rcStyles.colspan2}
      ref={formRefs.emails}
    >
      <DynamicList
        component={(
          <EmailFormSection
            label={t('Email')}
            className={styles.o_fb__inline}
            fullWidth
          />
        )}
        addButtonComponent={
          <IconButton data-test-id="addEmail" />
        }
        addButtonProps={{
          variant: 'contained',
          color: 'secondary',
          size: 'small',
          classes: { root: styles.o_button__secondary },
        }}
        addButtonInner={(
          <IconAddEmail />
        )}
        removeButtonProps={{
          variant: 'outlined',
          color: 'primary',
          size: 'small',
          classes: { root: styles.o_button__round },
        }}
        removeButtonInner={
          <RemoveIcon />
        }
        errors={errors && errors.emails}
        onChange={emails => onChange('emails', emails)}
        fullWidth
        secondary
        dataList={formData.emails || []}
        defaultEmpty
        data-test-id="emails"
      />
    </div>
    <div
      className={rcStyles.colspan2}
      ref={formRefs.websites}
    >
      <DynamicList
        component={(
          <WebsitesFormSection
            label={t('Websites')}
            className={styles.o_fb__inline}
            fullWidth
          />
        )}
        addButtonComponent={
          <IconButton data-test-id="addWebsite" />
        }
        addButtonProps={{
          variant: 'outlined',
          color: 'primary',
          size: 'small',
          classes: { root: styles.o_button__secondary },
        }}
        addButtonInner={(
          <IconLink className={styles.o_icon__primary} />
        )}
        removeButtonProps={{
          variant: 'outlined',
          color: 'primary',
          size: 'small',
          classes: { root: styles.o_button__round },
        }}
        removeButtonInner={
          <RemoveIcon />
        }
        errors={errors && errors.websites}
        onChange={websites => onChange('websites', websites)}
        fullWidth
        secondary
        dataList={formData.websites || []}
        defaultEmpty
        data-test-id="websites"
      />
    </div>
    <div
      className={rcStyles.colspan2}
      ref={formRefs.note}
    >
      <TextField
        fullWidth
        label={t('Note')}
        value={formData.note || ''}
        onChange={event => onChange('note', event.target.value)}
        inputProps={{
          'data-test-id': 'note',
        }}
      />
    </div>
  </div>
);

DirectoryListingForm.propTypes = {
  formData: directoryListingType.isRequired,
  onChange: PropTypes.func.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DirectoryListingForm;
