class NotFoundError extends Error {
  constructor(message) {
    super(message);
    this.name = 'NotFoundError';
    this.screenMessage = 'The requested page was not found.';
  }
}

const NotFoundScreen = () => {
  throw new NotFoundError('Page not found');
};

export default NotFoundScreen;
