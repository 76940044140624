import React, { Fragment } from 'react';
import { isNullOrUndefined } from 'services/utils';
import Price from 'components/common/Price';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

type Props = {
  label: string;
  amount: {
    amount: number;
    currency: string;
  };
  amountProps?: {
    'data-test-id': string;
  };
  hasStrikeThrough?: boolean;
  labelClasses?: string[];
  amountClasses?: string[];
};

const Amount: React.FunctionComponent<Props> = ({
  label, amount, amountProps, hasStrikeThrough, labelClasses, amountClasses,
}: Props) => (

  <Fragment>
    {!isNullOrUndefined(amount) && (
      <div
        data-test-id={amountProps && amountProps['data-test-id']}
        className={rcStyles.amount}
      >
        <div className={classNames(
          rcStyles.amount_label,
          labelClasses,
        )}
        >
          {label}
        </div>
        {amount && !isNullOrUndefined(amount.amount) ? (
          <Price
            price={amount}
            customClass={classNames(
              styles.c_price_label__regular,
              rcStyles.amount_price,
              hasStrikeThrough && styles.c_strike_through,
              amountClasses,
            )}
          />
        ) : amount}
      </div>
    )}
  </Fragment>

);

export default Amount;
