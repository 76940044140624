import React, { Fragment, useRef, useState } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import RootRef from '@material-ui/core/RootRef';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import AutoCompleteTextField from 'components/common/AutoCompleteTextField';
import ConfirmationModal from 'components/common/ConfirmationModal';
import DynamicList from 'components/common/DynamicList';
import Select from 'components/common/Select';
import ArrangementSelectedItemList from 'components/arrangement/ArrangementSelectedItemList';
import FeeModal from 'components/arrangement/FeeModal';
import HazardousImplantsFormSection from 'components/bereavement/HazardousImplantsFormSection';
import Viewing from 'components/bereavement/Viewing';
import { catalogueItemTypes, serviceCategories } from 'constants/arrangement';
import { clothesColours, clothesDisposalTypes, clothesTypes } from 'constants/care';
import IconLoad from 'icons/IconLoad';
import {
  arrangementType,
  bereavementType,
  careType,
  serviceType,
} from 'types/bereavement';

import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const ArrangementCareForm = ({
  bereavement,
  arrangement,
  categoryInformation,
  disabled,
  duplicateSelectionItem,
  isModalOpen,
  isSaving,
  onAddHazardousImplants,
  onChange,
  onDuplicateItemConfirm,
  onDuplicateItemCancel,
  onOpenDuplicateSelectionModal,
  onSave,
  toggleModal,
}) => {
  const [anchorElement, setAnchorElement] = useState(null);
  const buttonRef = useRef(null);
  const bereavementId = bereavement?.id;

  return (
    <>
      <div className={rcStyles.v_spacing}>
        {isSaving && (
          <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
            <IconLoad title={t('Saving')} />
          </div>
        )}
        <ArrangementSelectedItemList
          bereavementId={bereavementId}
          arrangement={arrangement}
          type={catalogueItemTypes.SERVICE}
          category={serviceCategories.PROFESSIONAL_SERVICES}
          disabled={disabled}
        />

        <FormControlLabel
          control={(
            <Checkbox
              checked={categoryInformation.isEmbalmingRequired || false}
              onChange={event => onChange('isEmbalmingRequired', event.target.checked)}
              inputProps={{ 'data-test-id': 'isEmbalmingRequired' }}
              disabled={disabled}
              color="primary"
            />
          )}
          label={t('Embalm')}
        />

        <div className={rcStyles.clothes_selectors}>
          <Select
            value={categoryInformation.clothes}
            onChange={event => onChange('clothes', event.target.value)}
            label={t('Clothes')}
            inputProps={{ 'data-test-id': 'clothes' }}
            disabled={disabled}
          >
            {clothesTypes.map(clothesType => (
              <MenuItem key={clothesType} value={clothesType}>
                {clothesType}
              </MenuItem>
            ))}
          </Select>
          {categoryInformation.clothes === 'Gown' && (
            <AutoCompleteTextField
              placeholder={t('Colour')}
              value={categoryInformation?.clothesColour || ''}
              suggestions={clothesColours}
              onChange={colour => onChange('clothesColour', colour)}
              fullWidth
              data-test-id="clothesColour"
            />
          )}
          {categoryInformation.clothes === 'Own Clothes' && (
            <Select
              value={categoryInformation.clothesDisposal}
              onChange={event => onChange('clothesDisposal', event.target.value)}
              label={t('Disposal of clothes')}
              inputProps={{ 'data-test-id': 'clothesDisposal' }}
              disabled={disabled}
            >
              {clothesDisposalTypes.map(clothesDisposalType => (
                <MenuItem key={clothesDisposalType.value} value={clothesDisposalType.value}>
                  {clothesDisposalType.name}
                </MenuItem>
              ))}
            </Select>
          )}

          {categoryInformation.clothesDisposal && (
            <FormControlLabel
              control={(
                <Checkbox
                  checked={categoryInformation.isClothesReceived || false}
                  onChange={event => onChange('isClothesReceived', event.target.checked)}
                  inputProps={{ 'data-test-id': 'isClothesReceived' }}
                  disabled={disabled}
                  color="primary"
                />
              )}
              label={t('Clothes received')}
            />
          )}
        </div>
        <div>
          <DynamicList
            isStacked
            hasPadding
            secondary
            component={<Viewing bereavement={bereavement} />}
            addButtonInner={
              (
                <Fragment>
                  <AddIcon className={styles.u_push__right_half} />
                  {t('Add Viewing')}
                </Fragment>
              )
            }
            addButtonProps={{
              variant: 'outlined',
              color: 'primary',
              size: 'medium',
            }}
            removeButtonInner={<RemoveIcon />}
            removeButtonProps={{
              variant: 'outlined',
              color: 'primary',
              classes: { root: styles.o_button__round },
            }}
            dataList={categoryInformation.viewings || []}
            onChange={data => onChange('viewings', data)}
            disabled={disabled}
            data-test-id="viewings"
          />
        </div>
        {categoryInformation.hazardousImplants && (
          <DynamicList
            isStacked
            component={<HazardousImplantsFormSection />}
            hideAddButton
            removeButtonInner={<RemoveIcon />}
            removeButtonProps={{
              variant: 'outlined',
              color: 'primary',
              classes: { root: styles.o_button__round },
            }}
            dataList={categoryInformation.hazardousImplants || []}
            onChange={data => onChange('hazardousImplants', data)}
            disabled={disabled}
            data-test-id="hazardousImplants"
          />
        )}
        <div>
          <RootRef rootRef={buttonRef}>
            <Button
              color="primary"
              variant="outlined"
              size="medium"
              disabled={disabled}
              onClick={(() => setAnchorElement(buttonRef.current))}
              aria-owns={anchorElement ? 'add-details' : ''}
              aria-haspopup="true"
              data-test-id="addAJourney"
            >
              <AddIcon className={styles.u_push__right_half} />
              {t('Add details')}
            </Button>
          </RootRef>
          <Menu
            id="add-details-menu"
            anchorEl={anchorElement}
            getContentAnchorEl={null}
            open={!!anchorElement}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={() => setAnchorElement()}
          >
            <MenuItem
              onClick={(() => {
                toggleModal('addFee');
                setAnchorElement();
              })}
              data-test-id="addFee"
            >
              {t('Add fee')}
            </MenuItem>
            <MenuItem
              onClick={(() => {
                onAddHazardousImplants();
                setAnchorElement();
              })}
              data-test-id="addHazardousImplants"
            >
              {t('Add hazardous implants')}
            </MenuItem>
          </Menu>
        </div>

        <FormControlLabel
          control={(
            <Checkbox
              checked={categoryInformation.isViewingsNotPermitted || false}
              onChange={event => onChange('isViewingsNotPermitted', event.target.checked)}
              inputProps={{ 'data-test-id': 'isViewingsNotPermitted' }}
              disabled={disabled}
              color="primary"
            />
          )}
          label={t('Viewing not permitted by client')}
        />
        <div className={styles.u_push__bottom_double}>
          <Button
            onClick={onSave}
            color="primary"
            variant="contained"
            data-test-id="saveCareCategory"
            disabled={disabled}
          >
            {t('Save')}
          </Button>
        </div>
      </div>
      {!disabled && isModalOpen.addFee && (
        <FeeModal
          bereavementId={bereavement.id}
          arrangement={arrangement}
          bereavementHomeId={bereavement.homeId}
          isOpen={isModalOpen.addFee}
          onOpenDuplicateSelectionModal={onOpenDuplicateSelectionModal}
          onClose={() => toggleModal('addFee')}
        />
      )}
      <ConfirmationModal
        message={t(`${duplicateSelectionItem?.title} has already been added to this estimate. Do you wish to continue to add this selection?`)}
        isOpen={!!duplicateSelectionItem}
        icon="duplicate"
        onConfirm={onDuplicateItemConfirm}
        onCancel={onDuplicateItemCancel}
      />
    </>
  );
};

ArrangementCareForm.propTypes = {
  bereavement: bereavementType.isRequired,
  arrangement: arrangementType.isRequired,
  categoryInformation: careType.isRequired,
  duplicateSelectionItem: serviceType.isRequired,
  isModalOpen: PropTypes.objectOf(PropTypes.any).isRequired,
  isSaving: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onAddHazardousImplants: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDuplicateItemConfirm: PropTypes.func.isRequired,
  onDuplicateItemCancel: PropTypes.func.isRequired,
  onOpenDuplicateSelectionModal: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default ArrangementCareForm;
