import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import AsyncPaginate from 'react-select-async-paginate';
import { removeDuplicateLookupItems, getAsyncLookupOptionValue } from 'services/utils';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';

import { organisationalUnitType, organisationalUnitTypeType } from 'types/organisationalUnit';
import { apolloClientType } from 'types/apollo';
import { reactRefType } from 'types/common';
import { components } from 'react-select';
import styles from 'scss/main.scss';
import { getOrganisationalUnits, getOrganisationalUnitsById } from './queries.gql';

const customSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? styles.colorGreyUltraLight : styles.colorWhite,
    color: 'inherit',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: '4 0',
    color: 'rgba(0, 0, 0, 0.54)',
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: 4,
    color: 'rgba(0, 0, 0, 0.54)',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '6px 0',
  }),
  input: provided => ({
    ...provided,
    margin: 0,
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 0,
    marginRight: 0,
  }),
  control: (provided, state) => {
    const focusedStyle = state.isFocused ? styles.colorOffWhite : 'inherit';
    return {
      'display': 'flex',
      'backgroundColor': state.hasValue ? 'inherit' : focusedStyle,
      '&:hover': {
        cursor: 'pointer',
      },
      '&:before': {
        'left': 0,
        'right': 0,
        'bottom': 0,
        'content': '"\\00a0"',
        'position': 'absolute',
        'transition': 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        'border-bottom': '1px solid',
        'borderBottomWidth': state.isFocused ? '2px' : '1px',
        'borderBottomColor': state.isFocused ? styles.colorHighlight : 'rgba(0, 0, 0, 0.42)',
        'pointer-events': 'none',
      },
    };
  },
  menu: provided => ({
    ...provided,
    marginTop: 0,
    paddingTop: 4,
    paddingBottom: 4,
  }),
};

const CustomClearIndicator = (props) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles('clearIndicator', props)}
    >
      <CloseIcon />
    </div>
  );
};

CustomClearIndicator.propTypes = {
  getStyles: PropTypes.func.isRequired,
  innerProps: PropTypes.shape({
    ref: reactRefType,
  }),
};

const CustomDropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <ArrowDropDownIcon />
  </components.DropdownIndicator>
);

class OrganisationalUnitLookupContainer extends Component {
  static propTypes = {
    client: apolloClientType.isRequired,
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.string,
      ),
      organisationalUnitType,
      PropTypes.arrayOf(
        organisationalUnitType,
      ),
    ]),
    onChange: PropTypes.func.isRequired,
    type: organisationalUnitTypeType,
    disabled: PropTypes.bool,
    isMulti: PropTypes.bool,
    overridezindex: PropTypes.bool,
    localHomes: PropTypes.arrayOf(organisationalUnitType.isRequired),
    closeMenuOnSelect: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  getOrganisationalUnitsByIds = async (ids) => {
    const { client, onChange } = this.props;
    const { data } = await client.query({
      query: getOrganisationalUnitsById,
      variables: {
        ids,
        pagination: {
          first: 9999,
          after: null,
        },
      },
    });

    onChange(data.organisationalUnits.edges.map(edge => edge.node));
  }

  getLocalOrganisationalUnitsById = (ids) => {
    const { localHomes, onChange } = this.props;
    const homes = ids.map(id => localHomes.find(home => home.id === id));
    onChange(homes);
  }

  getLocalOrganisationalUnits = (term) => {
    const { localHomes } = this.props;
    if (term) {
      const options = localHomes
        .filter(home => home.name.toLowerCase().includes(term.toLowerCase()))
        .map(home => ({
          label: home.name,
          value: home,
        }));
      return {
        options,
        hasMore: false,
      };
    }

    return {
      options: localHomes.map(home => ({
        label: home.name,
        value: home,
      })),
      hasMore: false,
    };
  }

  getOrganisationalUnits = async (term, prevResults, prevMetadata) => {
    const { client, type } = this.props;

    const lastCursor = (prevMetadata && prevMetadata.lastCursor) ? prevMetadata.lastCursor : null;

    const data = await client.query({
      query: getOrganisationalUnits,
      variables: {
        pagination: {
          first: 15,
          after: lastCursor,
        },
        type,
        term: (term !== '') ? term : null,
      },
    });
    const { edges, pageInfo } = data.data.organisationalUnits;
    const newResults = edges.map(({ node }) => ({
      label: node.name,
      value: node,
    }));

    return {
      options: newResults,
      hasMore: pageInfo.hasNextPage,
      additional: {
        lastCursor: pageInfo.endCursor,
      },
    };
  }

  handleChange = (selected) => {
    const { isMulti, onChange } = this.props;
    if (isMulti) {
      const updatedSelected = removeDuplicateLookupItems([...selected]);
      return onChange(updatedSelected.map(item => item.value));
    }
    return selected ? onChange(selected.value) : onChange(selected);
  }

  handleFilters = (option, searchTerm) => {
    const { isMulti } = this.props;
    let { value: propValue } = this.props;

    if (propValue && typeof propValue === 'object' && !isMulti) {
      propValue = [propValue];
    }

    const valueIds = propValue ? propValue.map(item => item.id) : [];
    const isSelected = valueIds.includes(option.value.id);

    if (searchTerm) {
      const isSearchMatch = searchTerm
        && option.label.toLowerCase().includes(searchTerm.trim().toLowerCase());
      return !isSelected && isSearchMatch;
    }
    return !isSelected;
  }

  render() {
    const {
      label,
      disabled,
      isMulti = false,
      localHomes,
      overridezindex,
      closeMenuOnSelect,
    } = this.props;
    let { value: propValue } = this.props;

    if (isMulti && propValue && typeof propValue[0] === 'string') {
      if (localHomes) {
        this.getLocalOrganisationalUnitsById(propValue);
      } else {
        this.getOrganisationalUnitsByIds(propValue);
      }
    }

    if (propValue && typeof propValue === 'object' && !isMulti) {
      propValue = [propValue];
    }

    const value = propValue && Array.isArray(propValue)
      ? propValue.map(item => ({
        label: item.name,
        value: item,
      }))
      : null;

    return (
      <AsyncPaginate
        id="organisationalUnitSelect"
        data-test-id="organisationalUnitSelect"
        value={value}
        onChange={this.handleChange}
        label={label}
        loadOptions={localHomes ? this.getLocalOrganisationalUnits : this.getOrganisationalUnits}
        getOptionValue={option => getAsyncLookupOptionValue(option)}
        filterOption={this.handleFilters}
        closeMenuOnSelect={closeMenuOnSelect === undefined ? !isMulti : closeMenuOnSelect}
        debounceTimeout={500}
        isClearable
        isSearchable
        isMulti={isMulti}
        isDisabled={disabled}
        styles={customSelectStyles}
        className={overridezindex && styles.o_select_override}
        components={{
          ClearIndicator: CustomClearIndicator,
          DropdownIndicator: CustomDropdownIndicator,
        }}
      />
    );
  }
}

export default withApollo(OrganisationalUnitLookupContainer);
