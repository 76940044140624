export const requestQueueActions = {
  HANDLE_WEBSOCKET_MESSAGE: 'REQUEST_QUEUE_HANDLE_WEBSOCKET_MESSAGE',
  SET_LOCATION_BEFORE_ERROR: 'REQUEST_QUEUE_SET_LOCATION_BEFORE_ERROR',
  SET_PROCESSING_OF_REQUEST_IN_QUEUE_FAILED: 'REQUEST_QUEUE_SET_PROCESSING_OF_REQUEST_IN_QUEUE_FAILED',
};

export const handleWebsocketMessageAction = data => ({
  type: requestQueueActions.HANDLE_WEBSOCKET_MESSAGE,
  payload: data,
});

export const setLocationBeforeErrorAction = locationBeforeError => (
  { type: requestQueueActions.SET_LOCATION_BEFORE_ERROR, payload: locationBeforeError }
);

export const setProcessingOfRequestInQueueFailedAction = processingOfRequestInQueueFailedAction => ({
  type: requestQueueActions.SET_PROCESSING_OF_REQUEST_IN_QUEUE_FAILED,
  payload: processingOfRequestInQueueFailedAction,
});
