import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { matchType } from 'types/reactRouter';
import { clearBereavementsAction, clearBereavementFiltersAction } from 'actions/bereavement';
import { featureFlags } from 'constants/features';
import { apolloClientType } from 'types/apollo';
import { getDirectoryListings } from './queries.gql';
import AccountsSectionScreen from './AccountsSectionScreen';

export class AccountsSectionScreenContainer extends Component {
  static propTypes = {
    match: matchType,
    client: apolloClientType.isRequired,
    clearBereavements: PropTypes.func.isRequired,
    clearBereavementFilters: PropTypes.func.isRequired,
    availableFeatures: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      directoryListings: [],
    };
  }

  componentDidMount() {
    this.fetchDirectoryListings();
  }

  componentWillUnmount() {
    const { clearBereavements, clearBereavementFilters } = this.props;

    clearBereavements();
    clearBereavementFilters();
  }

  fetchDirectoryListings = () => {
    const { client } = this.props;
    client.query({
      query: getDirectoryListings,
      variables: {
        pagination: {
          first: 1000,
          after: null,
        },
      },
    }).then(({ data, errors }) => {
      if (data === null && errors.length > 0) {
        return;
      }

      const directoryListings = data.directoryListings.edges.map(edge => edge.node);

      this.setState({
        directoryListings,
      });
    }).finally(() => this.setState({
      isLoading: false,
    }));
  }

  render() {
    const { isLoading, directoryListings } = this.state;
    const { availableFeatures } = this.props;
    const hasXeroIntegration = availableFeatures.includes(featureFlags.XERO_INTEGRATION);

    return (
      <AccountsSectionScreen
        {...this.props}
        hasXeroIntegration={hasXeroIntegration}
        directoryListings={directoryListings}
        isLoading={isLoading}
      />
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  clearBereavements: clearBereavementsAction,
  clearBereavementFilters: clearBereavementFiltersAction,
}, dispatch);

const mapStateToProps = state => ({
  availableFeatures: state.userStore.user.tenantFeatureFlags,
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withApollo(AccountsSectionScreenContainer),
);
