import React from 'react';
import IconApps from '@material-ui/icons/Apps';
import { categories } from 'constants/arrangement';
import IconCoffin from 'icons/IconCoffin';
import IconFlower from 'icons/IconFlower';
import IconMemorialisation from 'icons/IconMemorialisation';
import IconUrn from 'icons/IconUrn';
import IconOther from 'icons/IconOther';
import IconDisbursement from 'icons/IconDisbursement';
import IconVehicle from 'icons/IconVehicle';
import IconCelebrant from 'icons/IconCelebrant';
import IconServiceVenue from 'icons/IconServiceVenue';
import IconLocation from 'icons/IconLocation';
import IconProfessionalService from 'icons/IconProfessionalService';

export const getIconForCategory = (category) => {
  switch (category) {
    case categories.COFFINS:
      return (<IconCoffin />);
    case categories.FLOWERS:
      return (<IconFlower />);
    case categories.URNS:
      return (<IconUrn />);
    case categories.MEMORIALISATION:
      return (<IconMemorialisation />);
    case categories.OFFICIANTS:
      return (<IconCelebrant />);
    case categories.CREMATORIA:
      return (<IconLocation />);
    case categories.VEHICLES:
      return (<IconVehicle />);
    case categories.CEMETERIES:
      return (<IconLocation />);
    case categories.DISBURSEMENTS:
      return (<IconDisbursement />);
    case categories.PROFESSIONAL_SERVICES:
      return (<IconProfessionalService />);
    case categories.SERVICE_VENUES:
      return (<IconServiceVenue />);
    case categories.RECEPTION_VENUES:
      return (<IconLocation />);
    case categories.PACKAGES:
      return (<IconApps />);
    default:
      return (<IconOther />);
  }
};
