import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import LocationIcon from '@material-ui/icons/LocationOn';
import TimeIcon from '@material-ui/icons/AccessTime';
import EyeIcon from '@material-ui/icons/RemoveRedEye';
import EditIcon from '@material-ui/icons/Edit';
import { permittedAttendees } from 'constants/care';
import { isAddressEmpty, buildAddressString } from 'services/utils';
import { reactRefType } from 'types/common';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import ViewingModal from './ViewingModal';

import rcStyles from './styles.scss';

const Viewing = ({
  fieldData,
  formData,
  isFormOpen,
  onAttendeesChange,
  onChange,
  onEdit,
  onSave,
  onCancel,
  disabled,
  dialogRef,
  formRefs,
  errors,
}) => (
  <div
    className={rcStyles.v_spacing__slim}
    data-test-id="viewing"
  >
    <div className={styles.o_fb}>
      <h3 className={styles.long_primer}>
        {t('Viewing')}
      </h3>
      <div>
        <Button
          color="primary"
          size="small"
          variant="contained"
          data-test-id="edit"
          className={classNames(styles.o_button__round, styles.o_button__white)}
          onClick={onEdit}
          disabled={disabled}
        >
          <EditIcon />
        </Button>
      </div>
    </div>
    <ul className={classNames(
      styles.o_list,
      styles.is_plain,
    )}
    >
      {!isAddressEmpty(fieldData.location) && (
        <li
          className={styles.o_list__item}
          data-test-id="viewingAddress"
        >
          <div className={styles.o_list_item__icon}>
            <LocationIcon />
          </div>
          <div className={styles.o_list_item__text}>
            {buildAddressString(fieldData.location)}
          </div>
        </li>
      )}
      {fieldData.startDateTime && (
        <li
          className={styles.o_list__item}
          data-test-id="viewingTime"
        >
          <div className={styles.o_list_item__icon}>
            <TimeIcon />
          </div>
          <div className={styles.o_list_item__text}>
            {moment(fieldData.startDateTime).format('ddd DD MMM - HH:mm')}
          </div>
        </li>
      )}
      {fieldData.permittedAttendees && fieldData.permittedAttendees.length > 0 && (
        <li
          className={styles.o_list__item}
          data-test-id="viewingAttendees"
        >
          <div className={styles.o_list_item__icon}>
            <EyeIcon className={styles.o_list__icon} />
          </div>
          <div className={styles.o_list_item__text}>
            {fieldData.permittedAttendees.map(value => permittedAttendees[value]).join(', ')}
          </div>
        </li>
      )}
    </ul>
    <ViewingModal
      isOpen={isFormOpen}
      formData={formData}
      onAttendeesChange={onAttendeesChange}
      onChange={onChange}
      onSave={onSave}
      onCancel={onCancel}
      data-test-id="viewingModal"
      dialogRef={dialogRef}
      formRefs={formRefs}
      errors={errors}
    />
  </div>
);

Viewing.propTypes = {
  fieldData: PropTypes.objectOf(PropTypes.any).isRequired,
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
  isFormOpen: PropTypes.bool.isRequired,
  onAttendeesChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  dialogRef: reactRefType.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any),
};

export default Viewing;
