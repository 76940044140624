import React from 'react';
import PropTypes from 'prop-types';
import { SortableContainer } from 'react-sortable-hoc';
import { arrangementType } from 'types/bereavement';
import SortableEstimateSelection from 'components/estimate/SortableEstimateSelection';

const SortableEstimateSelectionsList = SortableContainer(({
  selections,
  bereavementId,
  arrangement,
  onOpenConfirmationWarningModal,
}) => (
  <>
    {selections.length > 0 && (
      <div>
        {selections.map((selection, index) => (
          <SortableEstimateSelection
            key={index}
            index={index}
            sortIndex={index}
            bereavementId={bereavementId}
            arrangement={arrangement}
            selection={selection}
            onOpenConfirmationWarningModal={onOpenConfirmationWarningModal}
            disabled={selection.placeholder}
          />
        ))}
      </div>
    )}
  </>
));

SortableEstimateSelectionsList.propTypes = {
  selections: PropTypes.arrayOf(PropTypes.object).isRequired,
  bereavementId: PropTypes.string.isRequired,
  arrangement: arrangementType.isRequired,
  onOpenConfirmationWarningModal: PropTypes.func.isRequired,
};

export default SortableEstimateSelectionsList;
