import React, { Fragment } from 'react';
import { t } from 'i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import IconLoad from 'icons/IconLoad';
import {
  catalogueServiceCategories,
  catalogueProductCategories,
  catalogueStatuses,
} from 'constants/catalogue';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import DirectoryListingLookup from 'components/common/lookups/DirectoryListingLookup';
import OrganisationalUnitLookup from 'components/common/lookups/OrganisationalUnitLookup';
import CategoryLookup from 'components/common/lookups/CategoryLookup';
import StatusLookup from 'components/common/lookups/StatusLookup';
import AdminCatalogueListItem from 'components/admin/AdminCatalogueListItem';
import ScreenLayout from 'components/common/ScreenLayout';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const AdminCatalogueItemListScreen = ({
  isLoading,
  filters,
  onChangeFilters,
  catalogueItems,
  getCatalogueItems,
  hasMoreItems,
  onStatusChange,
  isServiceCategory,
}) => (
  <ScreenLayout
    title={isServiceCategory ? t('Services') : t('Products')}
    dashboardBannerProps={{
      breadcrumbs: [{ path: '/', title: t('Dashboard') }, { path: '/catalogue', title: 'Brochure management' }, { path: `/catalogue/${isServiceCategory ? 'services' : 'products'}`, title: t(`${isServiceCategory ? 'Services' : 'Products'}`) }],
      description: t(`Create and manage all ${isServiceCategory ? 'services' : 'products'}. These will be available to include in packages and arrangements.`),
    }}
  >
    <div className={styles.o_view}>
      <div className={classNames(
        styles.o_view__main,
        styles.o_block_content,
      )}
      >
        <span>
          <Button
            component={Link}
            color="primary"
            variant="outlined"
            to={`/catalogue/${isServiceCategory ? 'service' : 'product'}/create`}
            data-test-id="create"
          >
            {t(`Create a new ${isServiceCategory ? 'service' : 'product'}`)}
          </Button>
        </span>
        <div className={rcStyles.options}>
          <div className={rcStyles.searchbar}>
            <TextField
              fullWidth
              className={styles.u_push__ends}
              value={filters.term}
              onChange={event => onChangeFilters('term', event.target.value, 'debounce')}
              label={t('Search')}
              type="search"
              InputProps={{
                'data-test-id': 'term',
                'startAdornment': (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <CategoryLookup
            value={filters.categories}
            label="Select category"
            onChange={value => onChangeFilters('categories', value)}
            categories={isServiceCategory
              ? catalogueServiceCategories
              : catalogueProductCategories}
            isMulti
            data-test-id="categoryLookup"
          />

          <OrganisationalUnitLookup
            value={filters.organisationalUnitIds}
            label="Select home"
            onChange={value => onChangeFilters(
              'organisationalUnitIds', value,
            )}
            isMulti
            data-test-id="homeLookup"
          />
          <DirectoryListingLookup
            value={filters.supplierIds}
            label="Select supplier"
            isMulti
            isSupplier
            onChange={value => onChangeFilters('supplierIds', value)}
            classes={[rcStyles.rc_lookup]}
            data-test-id="directoryListingLookup"
          />
          <StatusLookup
            value={filters.status}
            label="Select Status"
            onChange={value => onChangeFilters('status', value && value.value)}
            classes={[rcStyles.rc_lookup]}
            statuses={catalogueStatuses}
            data-test-id="statusLookup"
          />
        </div>
        {(isLoading && catalogueItems && catalogueItems.length === 0)
          && (
            <div className={classNames(
              styles.c_page__full_height,
              styles.has_white_background,
            )}
            >
              <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
                <IconLoad />
              </div>
            </div>
          )
        }
        <Fragment>
          <InfiniteScroll
            dataLength={catalogueItems.length}
            hasMore={catalogueItems.length > 0 && hasMoreItems}
            next={getCatalogueItems}
            className={styles.u_overflow__hidden}
            loader={catalogueItems.length > 0 && (
              <div className={classNames(
                styles.u_fullWidth,
                styles.has_white_background,
              )}
              >
                <div className={styles.c_svg_loader_infinite_scroll} data-test-id="infiniteScrollLoader">
                  <IconLoad />
                </div>
              </div>
            )}
          >
            {
              catalogueItems.map(item => (
                <div key={item.id}>
                  <AdminCatalogueListItem
                    item={item}
                    onStatusChange={onStatusChange}
                    isServiceCategory={isServiceCategory}
                  />
                </div>
              ))
            }
          </InfiniteScroll>
        </Fragment>
      </div>
    </div>
  </ScreenLayout>
);

AdminCatalogueItemListScreen.propTypes = {
  isServiceCategory: PropTypes.bool,
  getCatalogueItems: PropTypes.func.isRequired,
  catalogueItems: PropTypes.arrayOf(PropTypes.shape({})),
  onChangeFilters: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  hasMoreItems: PropTypes.bool.isRequired,
  onStatusChange: PropTypes.func.isRequired,
};

export default AdminCatalogueItemListScreen;
