import {
  string, object, array, boolean,
} from 'yup';

export const validationSchema = object().shape({
  title: string().nullable().required('Title is required'),
  start: string().nullable().required('Start date is required'),
  end: string().nullable().required('End date is required'),
  homeIds: array().of(object().shape({
    id: string().nullable(),
    name: string().nullable(),
    home: string().nullable(),
  })).required('At least one home is required'),
  isEndDateBeforeStartDate: boolean().oneOf([false], 'End time cannot be before start time'),
});
