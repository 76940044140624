import { scopes } from 'constants/scopes';

export const paymentMethods = [
  { id: 'CASH', name: 'Cash' },
  { id: 'CHEQUE', name: 'Cheque' },
  { id: 'BACS', name: 'Bacs' },
  { id: 'DEBIT_CARD', name: 'Debit card' },
  { id: 'CREDIT_CARD', name: 'Credit card' },
];

export const payeeTypes = {
  DIRECTORY_LISTING: 'DIRECTORY_LISTING',
  BEREAVED_PERSON_CONNECTION: 'BEREAVED_PERSON_CONNECTION',
};

export const selectionTypes = {
  CUSTOM: 'custom',
  PRODUCT: 'product',
  SERVICE: 'service',
};

export const accountStatuses = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
};

export const accountPermissions = [
  scopes.FINANCE_TASK_READ,
  scopes.BEREAVEMENT_ACCOUNT_READ,
  scopes.BEREAVEMENT_ACCOUNT_WRITE,
];

export const accountTransactionType = {
  CREDIT: 'CREDIT',
  INVOICE: 'INVOICE',
  PAYMENT: 'PAYMENT',
  INVOICE_TEMPLATE: 'INVOICE_TEMPLATE',
  HISTORIC_FUNERAL_ACCOUNT_CHARGE: 'HISTORIC_FUNERAL_ACCOUNT_CHARGE',
  ACCOUNT_CLOSE_EVENT: 'ACCOUNT_CLOSE_EVENT',
  ACCOUNT_REOPEN_EVENT: 'ACCOUNT_REOPEN_EVENT',
};

export const invoiceTypes = {
  INVOICE: 'INVOICE',
  PROFORMA: 'PROFORMA',
};

export const closeAccountMessages = {
  DEFAULT: 'You are about to close this account. All information will become read only. Do you still wish to close this account?',
  HAS_UNINVOICED_TOTAL: 'Please note: There are uninvoiced amounts on this account. All information will become read only if you proceed to close the account. Do you still wish to close this account?',
  HAS_OUTSTANDING_BALANCE: 'Please note: There is still a balance outstanding on this account. All information will become read only if you proceed to close the account. Do you still wish to close this account?',
};

export const invoiceTemplateTransferredStatus = {
  NOT_TRANSFERRED: 'NOT_TRANSFERRED',
  TRANSFERRED: 'TRANSFERRED',
  TRANSFERRED_AND_EDITED: 'TRANSFERRED_AND_EDITED',
};

export const invoiceSectionTitles = {
  PACKAGES: 'Packages',
  DISBURSEMENTS: 'Disbursements',
  PRODUCTS_AND_SERVICES: 'Products & Services',
  PROFESSIONAL_SERVICES: 'Professional Services',
};

export const PACKAGE_NOMINAL_CODE = 500;

export const PRODUCT_CATEGORIES_NOMINAL_CODES = {
  COFFINS: 10000,
  FLOWERS: 20000,
  MEMORIALISATION: 30000,
  URNS: 40000,
  OTHER: 50000,
};

export const SERVICE_CATEGORIES_NOMINAL_CODES = {
  DISBURSEMENTS: 15000,
  VEHICLES: 25000,
  OFFICIANTS: 35000,
  SERVICE_VENUES: 45000,
  CREMATORIA: 55000,
  PROFESSIONAL_SERVICES: 65000,
  OTHER: 75000,
  CEMETERIES: 85000,
};
