import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { locationType } from 'types/reactRouter';
import RouteTabContainer from './RouteTabContainer';

class RouteTabContainerContainer extends Component {
  static propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
    classes: PropTypes.objectOf(PropTypes.any).isRequired,
    onChange: PropTypes.func,
    resetToDefault: PropTypes.bool,
    testIds: PropTypes.objectOf(PropTypes.any),
    location: locationType.isRequired,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.resetToDefault) {
      return { index: 0 };
    }

    if (nextProps.location.pathname !== prevState.value) {
      return { value: nextProps.location.pathname };
    }

    return null;
  }

  constructor(props) {
    super(props);

    const value = props.tabs.find(tab => tab.path === props.location.pathname);
    this.state = {
      value: value ? value.path : props.tabs[0],
    };
  }

  handleChange = (event, value) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
    this.setState({ value });
  }

  render() {
    const {
      tabs,
      classes,
      testIds,
    } = this.props;
    const { value } = this.state;

    return (
      <RouteTabContainer
        value={value}
        tabs={tabs}
        onChange={this.handleChange}
        classes={classes}
        testIds={testIds}
      />
    );
  }
}

export default withRouter(RouteTabContainerContainer);
