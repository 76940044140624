import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import i18next from 'i18next';
import moment from 'moment';
import Button from '@material-ui/core/Button';

import AccountAmount from 'components/account/AccountAmount';
import AccountInvoicesList from 'components/account/AccountInvoicesList';
import ExpandableBanner from 'components/common/ExpandableBanner';
import { buildNameString } from 'services/utils';
import { sortTransactionsByDateTimeDesc } from 'services/utils/account';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';
import { Props } from './index.types';

const AccountsListItem: React.FC<Props> = ({
  bereavement,
  match,
  searchTerm,
  hasXeroIntegration,
}: Props) => {
  const {
    id: bereavementId,
    account,
    bereavedPeopleConnections,
    deceasedPeople,
  } = bereavement || {};
  const deceasedPerson = deceasedPeople[0];
  const deceasedName = buildNameString(deceasedPerson?.name);
  const dateOfBirth = deceasedPerson?.dateOfBirth
    ? moment(deceasedPerson?.dateOfBirth).format('YYYY')
    : '';
  const dateOfDeath = deceasedPerson?.deathDateTime
    ? moment(deceasedPerson?.deathDateTime).format('MMM DD YYYY')
    : '';
  const deceasedDateRange = dateOfBirth && dateOfDeath && `${dateOfBirth} - ${dateOfDeath}`;
  const billPayer = bereavedPeopleConnections?.find(connection => connection?.isBillPayer);
  const billPayerName = buildNameString(billPayer?.bereavedPerson?.name);
  const { invoices = [], proformaInvoices = [], totals } = account || {};
  const doesSearchTermMatchAnInvoiceNumber = !!searchTerm && invoices.map(({ number }) => number).includes(searchTerm);

  const sortedInvoices = sortTransactionsByDateTimeDesc([...invoices, ...proformaInvoices]);

  return (
    <div className={rcStyles.list_item}>
      <ExpandableBanner
        isClosedOnLoad={!doesSearchTermMatchAnInvoiceNumber}
        headerClasses={[rcStyles.header]}
        title={(
          <div className={rcStyles.list_item__title}>
            <Link
              to={`${match.url}/${bereavementId}`}
              className={rcStyles.deceased}
            >
              <div className={rcStyles.is_primary_label}>
                {deceasedName}
              </div>
              <div
                className={classNames(
                  styles.brevier,
                  styles.is_black,
                )}
              >
                {deceasedDateRange}
              </div>
            </Link>
            <div className={rcStyles.billPayer}>
              <div className={rcStyles.is_secondary_label}>
                {i18next.t('Bill Payer')}
              </div>
              <div className={rcStyles.is_secondary_data}>
                {billPayerName}
              </div>
            </div>
            <div className={rcStyles.amounts}>
              {hasXeroIntegration ? (
                <>
                  <AccountAmount label="Uninvoiced" total={totals.totalInvoiceTemplatesUninvoiced} testId="accountUninvoicedTotal" />
                  <AccountAmount label="Invoiced" total={totals.totalInvoiceTemplatesInvoiced} testId="accountInvoicedTotal" />
                </>
              ) : (
                <>
                  <AccountAmount label="Uninvoiced" total={totals.totalUnInvoiced} testId="accountUninvoicedTotal" />
                  <AccountAmount label="Invoiced" total={totals.totalInvoiced} testId="accountInvoicedTotal" />
                  <AccountAmount label="Credit" total={totals.totalCredit} testId="accountCreditTotal" />
                  <AccountAmount label="Paid" total={totals.totalPaid} testId="accountPaidTotal" />
                  <AccountAmount label="Balance" total={totals.balance} testId="accountBalance" />
                </>
              )}
            </div>
          </div>
        )}
      >
        <>
          {sortedInvoices && (
            <AccountInvoicesList
              bereavementId={bereavementId}
              invoices={sortedInvoices}
              searchTerm={searchTerm}
              hasXeroIntegration={hasXeroIntegration}
            />
          )}
          <div className={rcStyles.view_account}>
            <Link to={`${match.url}/${bereavementId}`}>
              <Button
                variant="outlined"
                color="primary"
                data-test-id="cancelCredit"
              >
                {i18next.t('View Account')}
              </Button>
            </Link>
          </div>
        </>
      </ExpandableBanner>
    </div>
  );
};

export default withRouter(AccountsListItem);
