import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import Flag from 'react-flags-svg';
import countryCodeData from 'constants/countryCodes';
import { telephoneTypeList } from 'constants/telephoneTypes';
import Select from 'components/common/Select';

import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const PhoneFormSection = ({
  formData,
  errors,
  handleOnChangeTelephone,
  handleOnChange,
}) => {
  const countryData = countryCodeData
    .find(country => country.code === formData.telephone.countryCode);

  return (
    <div
      data-test-id="phone"
      className={rcStyles.phone_input}
    >
      <div className={rcStyles.is_location}>
        <Select
          label={t('Country')}
          onChange={event => handleOnChangeTelephone('countryCode', event.target.value)}
          value={formData.telephone.countryCode}
          renderValue={() => (
            <Fragment>
              <Flag countryCode={countryData.code.toLowerCase()} />
              <span className={styles.u_push__left}>
                {`${countryData.name} ${countryData.dialCode}`}
              </span>
            </Fragment>
          )}
          inputProps={{
            'data-test-id': 'countryCode',
          }}
          errorText={errors && errors.countryCode}
          fullWidth
        >
          {countryCodeData.map((country, index) => (
            <MenuItem
              key={`${country.code}${index}`}
              value={country.code}
            >
              <Flag countryCode={country.code.toLowerCase()} />
              <span className={styles.u_push__left}>
                {`${country.name} ${country.dialCode}`}
              </span>
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className={rcStyles.is_number}>
        <TextField
          label={t('Phone Number')}
          value={formData.telephone.number}
          onChange={event => handleOnChangeTelephone('number', event.target.value)}
          InputProps={{
            'inputProps': {
              'data-test-id': 'phoneNumber',
            },
            'startAdornment': (
              <InputAdornment position="start">
                {countryData.dialCode}
              </InputAdornment>
            ),
          }}
          error={errors && errors.telephone && !!errors.telephone.number}
          helperText={errors && errors.telephone && errors.telephone.number}
          fullWidth
        />
      </div>
      <div className={rcStyles.is_type}>
        <Select
          label={t('Type')}
          onChange={event => handleOnChange('type', event.target.value)}
          value={formData.type}
          inputProps={{
            'data-test-id': 'phoneType',
          }}
          errorText={errors && errors.type}
          fullWidth
        >
          {
            telephoneTypeList.map(telephoneType => (
              <MenuItem key={telephoneType} value={telephoneType} data-test-id={telephoneType}>
                {`${telephoneType.charAt(0)}${telephoneType.slice(1).toLowerCase()}`}
              </MenuItem>
            ))
          }
        </Select>
      </div>
    </div>
  );
};

PhoneFormSection.propTypes = {
  formData: PropTypes.objectOf(PropTypes.any),
  errors: PropTypes.objectOf(PropTypes.any),
  handleOnChangeTelephone: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
};

export default PhoneFormSection;
