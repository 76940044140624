import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ExpandableFormSection from './ExpandableFormSection';

export class ExpandableFormSectionContainer extends Component {
  static propTypes = {
    isExpandedOnLoad: PropTypes.bool,
    title: PropTypes.string,
    disableExpansion: PropTypes.bool,
  }

  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false,
    };
  }

  componentDidMount() {
    const { isExpandedOnLoad } = this.props;

    if (isExpandedOnLoad) {
      this.toggleIsExpanded();
    }
  }

  toggleIsExpanded = () => {
    this.setState(prevState => ({
      isExpanded: !prevState.isExpanded,
    }));
  }

  render() {
    const { isExpanded } = this.state;
    return (
      <ExpandableFormSection
        {...this.props}
        isExpanded={isExpanded}
        toggleIsExpanded={this.toggleIsExpanded}
      />
    );
  }
}

export default ExpandableFormSectionContainer;
