import { string, number, object } from 'yup';
import { paymentMethods } from 'constants/account';

export const validationSchema = object().shape({
  id: string().nullable().required('ID is required'),
  payeeId: string().nullable().required('Payee is required'),
  payeeType: string().nullable().required('Payee is required'),
  dateTime: string().nullable().required('Payment date is required'),
  amount: object().shape({
    amount: number().nullable().required('Payment amount is required'),
    currency: string().nullable().required('Payment currency is required'),
  }),
  method: string().oneOf(
    paymentMethods.map(paymentMethod => paymentMethod.id),
    'Enter a valid payment method',
  ).nullable().required('Payment method is required'),
  reference: string().nullable(),
});
