import React from 'react';
import { t } from 'i18next';
import { getFormattedDate } from 'services/utils';
import PropTypes from 'prop-types';
import { accountTransactionType } from 'constants/account';
import { closeEventType, reopenEventType } from 'types/account';
import ExpandableBanner from 'components/common/ExpandableBanner';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';

import rcStyles from './styles.scss';

const TransactionEventRow = ({
  event: {
    type, dateTime, reason, isNewest,
  },
  hasBalanceBeenPaid,
}) => {
  const isAccountClosed = type === accountTransactionType.ACCOUNT_CLOSE_EVENT;
  const isAccountReopened = type === accountTransactionType.ACCOUNT_REOPEN_EVENT;
  const testIdPrefix = (isAccountClosed && 'accountClosure') || (isAccountReopened && 'accountReopened') || 'event';
  return (
    <div data-test-id={`${testIdPrefix}Transaction`}>
      <ExpandableBanner
        headerClasses={[rcStyles.expanding_banner__header]}
        wrapperClasses={[rcStyles.expanding_banner__wrap]}
        isClosedOnLoad
        title={(
          <div
            data-test-id={`${testIdPrefix}Banner`}
            className={rcStyles.banner_content}
          >
            <div
              className={rcStyles.is_title}
              data-test-id={`${testIdPrefix}Header`}
            >
              {isAccountClosed && t('Account closed')}
              {isAccountReopened && t('Account re-opened')}
            </div>
            <div
              className={rcStyles.is_date}
              data-test-id={`${testIdPrefix}TransactionCreatedOn`}
            >
              {getFormattedDate(dateTime)}
            </div>
            {isAccountClosed && isNewest && (
              <div
                className={rcStyles.is_icon}
                data-test-id="accountStatusIcon"
              >
                {hasBalanceBeenPaid ? (
                  <CheckCircleIcon className={rcStyles.is_checked} />
                ) : (
                  <WarningIcon className={rcStyles.is_warning} />
                )}
              </div>
            )}
          </div>
        )}
      >
        {reason && (
          <div data-test-id={`${testIdPrefix}Body`}>
            <div data-test-id="closureReason">
              {reason}
            </div>
          </div>
        )}
      </ExpandableBanner>
    </div>
  );
};

TransactionEventRow.propTypes = {
  event: PropTypes.oneOfType([closeEventType, reopenEventType]),
  hasBalanceBeenPaid: PropTypes.bool,
};

export default TransactionEventRow;
