import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const IconLetter = (props) => {
  const { className } = props;
  const classes = className ? `${className} ${styles.rc_icon_primary}` : styles.rc_icon_primary;

  return (
    <svg viewBox="0 0 24 24" className={classes}>
      <path d="M13.2,4.6h-5C7.6,4.6,7,5.2,7,5.8l0,5.3l5,2.7l5-2.7V8.4L13.2,4.6z M12.6,9.2V5.8l3.4,3.4H12.6z" />
      <path d="M20.3,10.9V19c0,0.8-0.8,1.4-1.7,1.4H5.3c-0.9,0-1.7-0.6-1.7-1.4l0-8.1l8.3,4.5L20.3,10.9z" />
    </svg>
  );
};

IconLetter.propTypes = {
  className: PropTypes.string,
};

export default IconLetter;
