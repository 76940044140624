import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getDefaultImage } from 'services/utils';
import { productType, serviceType } from 'types/bereavement';
import CatalogueItemDetails from './CatalogueItemDetails';

class CatalogueItemDetailsContainer extends Component {
  static propTypes = {
    item: PropTypes.oneOfType([productType, serviceType]).isRequired,
    isInPackage: PropTypes.bool,
    disabled: PropTypes.bool,
    onAddSelection: PropTypes.func,
    onClose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isDescriptionExpanded: false,
      activeImage: getDefaultImage(props.item),
    };
  }

  getShortDescription = (description) => {
    const { isDescriptionExpanded } = this.state;
    if (isDescriptionExpanded) {
      return description;
    }
    const shortDescription = description.split(' ').slice(0, 10).join(' ');
    return `${shortDescription}...`;
  };

  handleToggleDescription = () => {
    this.setState(prevState => ({
      isDescriptionExpanded: !prevState.isDescriptionExpanded,
    }));
  }

  handleImageChange = (image) => {
    this.setState({ activeImage: image });
  }

  render() {
    const {
      item, isInPackage, disabled, onAddSelection, onClose,
    } = this.props;
    const { isDescriptionExpanded, activeImage } = this.state;

    return (
      <CatalogueItemDetails
        item={item}
        isInPackage={isInPackage}
        disabled={disabled}
        isDescriptionExpanded={isDescriptionExpanded}
        activeImage={activeImage}
        onToggleDescription={this.handleToggleDescription}
        getShortDescription={this.getShortDescription}
        onAddSelection={onAddSelection}
        onClose={onClose}
        onImageChange={this.handleImageChange}
      />
    );
  }
}

export default CatalogueItemDetailsContainer;
