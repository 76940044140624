import React from 'react';
import classNames from 'classnames';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import DefaultScreenHelmet from 'components/common/DefaultScreenHelmet';
import SupportSection from 'components/support/SupportSection';
import IconLoad from 'icons/IconLoad';
import NotFoundScreen from 'screens/NotFoundScreen';
import styles from 'scss/main.scss';

import rcStyles from './styles.scss';

const MainScreen = ({
  routes,
  isUpdating,
  isLoggedIn,
  currentLocation,
}) => (
  <>
    {isUpdating && (
      <div className={rcStyles.updating_backdrop}>
        <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
          <IconLoad />
        </div>
        <div className={classNames(
          rcStyles.updating_message,
          styles.long_primer,
          styles.is_primary,
          styles.is_light,
        )}
        >
          {t('The application is being updated.')}
        </div>
      </div>
    )}
    <Switch>
      {routes.map((route, i) => (
        <Route
          key={i}
          exact={!route.hasSubRoutes}
          path={route.path}
          render={props => (
            <>
              <DefaultScreenHelmet title={route.title} description={route.description} />
              {isLoggedIn && process.env.FEATURE_FLAG_SUPPORT_PANEL && <SupportSection location={currentLocation} />}
              <route.component
                {...props}
                redirect={currentLocation}
                parent={route.parent}
                title={route.title}
              />
            </>
          )}
        />
      ))}
      <Route key="not-found" component={NotFoundScreen} />
    </Switch>
  </>
);

MainScreen.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  currentLocation: PropTypes.string.isRequired,
};

export default MainScreen;
