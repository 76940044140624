import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import RemoveIcon from '@material-ui/icons/Remove';
import IconApps from '@material-ui/icons/Apps';
import Select from 'components/common/Select';
import Price from 'components/common/Price';
import ArrangementSelectedItemAvatar from 'components/arrangement/ArrangementSelectedItemAvatar';
import { getCatalogueVariantImage } from 'services/utils';
import { productType, serviceType, packageType } from 'types/bereavement';
import { variantStatuses } from 'constants/catalogue';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const ArrangementSelectedItem = ({
  id,
  item,
  selectedVariantId,
  isPackageSelection,
  disabled,
  onVariantChange,
  onRemove,
  selectedItemRef,
}) => (
  <div
    className={rcStyles.selected_item}
    data-test-id="selectionItem"
    ref={selectedItemRef}
  >
    {isPackageSelection && (
      <IconApps />
    )}
    <div className={rcStyles.selected_item__body}>
      <div className={rcStyles.selected_layout}>
        <ArrangementSelectedItemAvatar
          image={getCatalogueVariantImage(item, selectedVariantId)}
          title={item.title}
          category={item.category}
          classes={rcStyles.is_selected_icon}
        />
        <div
          data-test-id="selectionName"
          className={rcStyles.is_selected_title}
        >
          {item.title}
        </div>
        {item.variants && item.variants.length > 1 && (
          <div className={rcStyles.is_selected_variant}>
            <Select
              label={t('Variations')}
              value={selectedVariantId !== null ? selectedVariantId : ''}
              inputProps={{
                'id': 'variations',
                'name': 'variations',
                'data-test-id': 'variations',
              }}
              onChange={event => onVariantChange(id, event.target.value)}
              disabled={disabled}
              autoWidth
            >
              {item.variants.map(variant => (
                <MenuItem
                  className={variant.status !== variantStatuses.ACTIVE ? styles.u_hidden : null}
                  key={variant.id}
                  value={variant.id}
                >
                  {variant.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}
        {!isPackageSelection && (
          <div className={rcStyles.is_selected_price}>
            <Price
              variants={item.variants}
              selectedVariantId={selectedVariantId}
              customClass={classNames(
                styles.c_price_label,
                styles.is_black,
              )}
            />
          </div>
        )}
      </div>
    </div>
    <div className={rcStyles.selected_item__action}>
      <Button
        color="primary"
        variant="outlined"
        size="small"
        data-test-id="remove"
        disabled={disabled}
        onClick={() => onRemove(id)}
        className={classNames(styles.o_button__round)}
      >
        <RemoveIcon />
      </Button>
    </div>
  </div>
);

ArrangementSelectedItem.propTypes = {
  id: PropTypes.string.isRequired,
  item: PropTypes.oneOfType([productType, serviceType, packageType]).isRequired,
  selectedVariantId: PropTypes.string,
  isPackageSelection: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onVariantChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  selectedItemRef: PropTypes.objectOf(PropTypes.any),
};

export default ArrangementSelectedItem;
