import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import styles from 'scss/main.scss';

const ActionButton = ({
  onClick,
  disabled,
  text,
  'data-test-id': testId,
}) => (
  <Button
    color="secondary"
    variant="contained"
    size="small"
    className={styles.o_button__white}
    onClick={onClick}
    data-test-id={testId || ''}
    disabled={disabled || false}
  >
    {text}
  </Button>
);

ActionButton.propTypes = {
  'onClick': PropTypes.func.isRequired,
  'disabled': PropTypes.bool,
  'text': PropTypes.string.isRequired,
  'data-test-id': PropTypes.string,
};


export default ActionButton;
