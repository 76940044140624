import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { createCustomTaskAction } from 'actions/task';
import { bereavementType } from 'types/bereavement';
import { status } from 'constants/task';
import CreateCustomTaskModal from './CreateCustomTaskModal';
import { validationSchema } from './validation';

class CreateCustomTaskModalContainer extends Component {
  static propTypes = {
    bereavement: bereavementType,
    onClose: PropTypes.func.isRequired,
    createCustomTask: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    generateRefs: PropTypes.func.isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  }

  constructor(props) {
    super(props);
    this.state = CreateCustomTaskModalContainer.initialState;
    this.dialogRef = React.createRef();
  }

  componentDidMount() {
    const { generateRefs } = this.props;
    generateRefs(Object.keys(validationSchema.fields));
  }

  handleOnSave = () => {
    const {
      validate,
      createCustomTask,
      bereavement,
    } = this.props;
    const { customTask } = this.state;

    const isValid = validate(customTask, validationSchema, true, this.dialogRef);

    if (!isValid) {
      this.setState({ isValidationActive: true });
      return;
    }

    createCustomTask({
      ...customTask,
      ...{
        bereavement,
        organisationalUnit: (bereavement || {}).home,
      },
    });
    this.handleOnClose();
  }

  handleOnChange = (key, value) => {
    const { validate } = this.props;
    const { customTask, isValidationActive } = this.state;

    const updatedTask = {
      ...customTask,
      [key]: value,
    };

    if (isValidationActive) {
      validate(updatedTask, validationSchema);
    }

    this.setState({
      customTask: updatedTask,
    });
  }

  handleOnClose = () => {
    this.setState(CreateCustomTaskModalContainer.initialState);
    const { onClose } = this.props;
    onClose();
  }

  static initialState = {
    customTask: {
      title: '',
      description: '',
      category: null,
      assignee: null,
      status: status.PENDING,
    },
    isValidationActive: false,
  }

  render() {
    const {
      bereavement,
      formRefs,
      errors,
      isOpen,
    } = this.props;
    const { customTask } = this.state;

    return (
      <CreateCustomTaskModal
        customTask={customTask}
        bereavement={bereavement}
        onClose={this.handleOnClose}
        onChange={this.handleOnChange}
        onSave={this.handleOnSave}
        isOpen={isOpen}
        dialogRef={this.dialogRef}
        formRefs={formRefs}
        errors={errors}
      />
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  createCustomTask: createCustomTaskAction,
}, dispatch);

export default connect(null, mapDispatchToProps)(
  withValidation(CreateCustomTaskModalContainer),
);
