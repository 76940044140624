import React from 'react';
import classNames from 'classnames';

import rcStyles from './styles.scss';
import { Props } from './index.types';

const HighlightableText: React.FC<Props> = ({
  text,
  highlight,
}: Props) => {
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));

  return (
    <span>
      {parts.map((part, i) => (
        <span
          key={i}
          className={classNames(
            part.toLowerCase() === highlight?.toLowerCase() && rcStyles.highlight,
          )}
        >
          {part}
        </span>
      ))}
    </span>
  );
};

export default HighlightableText;
