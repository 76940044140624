import React from 'react';
import PropTypes from 'prop-types';
import { getAccountContext } from './AccountContext';

const AccountConsumer = (props) => {
  const AccountContext = getAccountContext();
  return (
    <AccountContext.Consumer>
      {(context = {}) => props.children(context)}
    </AccountContext.Consumer>
  );
};

AccountConsumer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
};

export default AccountConsumer;
