import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';

import PortalScreenHelmet from 'components/portal/PortalScreenHelmet';
import NotFoundScreen from 'screens/NotFoundScreen';

import { Props } from './PortalScreen.types';

const PortalScreen: React.FC<Props> = ({
  routes,
  currentLocation,
}: Props) => (
  <Switch>
    {routes.map((route, i) => (
      <Route
        key={i}
        exact={!route.hasSubRoutes}
        path={route.path}
        render={props => (
          <Fragment>
            <PortalScreenHelmet title={route.title} description={route.description} />
            <route.component
              {...props}
              redirect={currentLocation}
              parent={route.parent}
              title={route.title}
            />
          </Fragment>
        )}
      />
    ))}
    <Route key="not-found" component={NotFoundScreen} />
  </Switch>
);

export default PortalScreen;
