// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ct-slice-donut{stroke-width:40px !important}:root .ct-label{fill:#fff !important;font-size:16px;font-weight:bold}.ct-chart svg{margin-left:-1rem}.ct-series-a>.ct-slice-donut{stroke:#ffcd42 !important}.ct-series-b>.ct-slice-donut{stroke:#00b39e !important}\n", ""]);
// Exports
exports.locals = {
	"unitWhole": "1rem",
	"unitHalf": ".5rem",
	"unitDouble": "2rem",
	"colorError": "#f44034",
	"colorWarning": "#ffca38",
	"colorPrimary": "#7e5da2",
	"colorButtonSecondary": "#faf9fb",
	"colorGrey": "#bfbcc2",
	"colorGreyLight": "#e5e4e7",
	"colorGreyDark": "#a6a1aa",
	"colorGreyUltraLight": "#f2f0f5",
	"colorSecondaryDarker": "#0d6a9c",
	"colorOffWhite": "#faf9fb",
	"colorBlue": "#108acb",
	"colorWhite": "#fff",
	"colorHighlight": "#108acb",
	"colorBadge": "#6196d6",
	"typographyPrimary": "\"Tondo\",sans-serif",
	"typographyBold": "600"
};
module.exports = exports;
