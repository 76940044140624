import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import SearchIcon from '@material-ui/icons/Search';
import { organisationalUnitType } from 'types/organisationalUnit';
import ScreenLayout from 'components/common/ScreenLayout';
import AdminListItem from 'components/admin/AdminListItem';
import IconLoad from 'icons/IconLoad';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const AdminHomesListScreen = ({
  homes,
  hasMoreHomes,
  getHomes,
  isLoading,
  filters,
  onChangeFilters,
}) => (
  <ScreenLayout
    title={t('Homes')}
    dashboardBannerProps={{
      breadcrumbs: [{ path: '/', title: t('Dashboard') }, { path: '/homes', title: t('Homes') }],
      description: t('Add and manage your funeral homes'),
    }}
  >
    <div className={styles.o_view}>
      <div className={classNames(
        styles.o_view__main,
        styles.o_block_content,
      )}
      >
        <div className={rcStyles.options_layout}>
          <Button
            component={Link}
            color="primary"
            variant="outlined"
            to="/homes/create"
            data-test-id="createHome"
          >
            {t('Create new home')}
          </Button>
          <Button
            component={Link}
            color="primary"
            variant="outlined"
            to="/homegroups"
            data-test-id="homegroups"
          >
            {t('Home groups')}
          </Button>
          <div className={rcStyles.text_search}>
            <TextField
              className={rcStyles.rc_searchbar}
              value={filters.searchTerm}
              onChange={event => onChangeFilters('searchTerm', event.target.value)}
              label={t('Search')}
              type="search"
              InputProps={{
                'data-test-id': 'searchTerm',
                'startAdornment': (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </div>
        </div>

        <InfiniteScroll
          dataLength={homes.length}
          hasMore={homes.length > 0 && hasMoreHomes}
          next={getHomes}
          className={styles.u_overflow__hidden}
        >
          {
            homes.map(home => (
              <div
                key={home.id}
                className={classNames(
                  home.isNew ? styles.c_flag_standard__PENDING : '',
                )}
              >
                <AdminListItem key={home.id} item={home} />
              </div>
            ))
          }
        </InfiniteScroll>
        {isLoading && (
          <div className={styles.c_svg_loader_infinite_scroll} data-test-id="infiniteScrollLoader">
            <IconLoad />
          </div>
        )}
      </div>
    </div>
  </ScreenLayout>
);

AdminHomesListScreen.propTypes = {
  homes: PropTypes.arrayOf(organisationalUnitType.isRequired),
  hasMoreHomes: PropTypes.bool.isRequired,
  getHomes: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  onChangeFilters: PropTypes.func.isRequired,
};

export default AdminHomesListScreen;
