import React from 'react';
import classNames from 'classnames';
import { t } from 'i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVerticalIcon from '@material-ui/icons/MoreVert';

import { caseStatuses } from 'constants/bereavement';
import styles from 'scss/main.scss';
import { buildNameString, formatCurrency, getFormattedDate } from 'services/utils';
import { getLastFinanceRequestedArrangement } from 'services/utils/arrangement';
import { bereavementType } from 'types/bereavement';

import rcStyles from './styles.scss';

const LiteArrangementItem = ({
  bereavement,
  onEditCaseStatus,
  onMenuOpen,
  menuAnchor,
  onMenuClose,
}) => {
  const deceasedName = buildNameString(bereavement.deceasedPeople[0].name);
  const arrangement = bereavement.arrangements[0];
  const isCaseOpen = bereavement.caseStatus === caseStatuses.OPEN;
  const { arrangements } = bereavement;
  const { loanAmount, funeralSafeReferralDate } = getLastFinanceRequestedArrangement(arrangements) || {};

  return (
    <div className={classNames(
      styles.o_block_content,
      rcStyles.list_item,
    )}
    >
      <Link
        style={{
          textDecoration: 'none',
          width: '100%',
        }}
        to={`lite-arrangement/${bereavement.id}`}
      >
        <div
          data-test-id="liteArrangementEntry"
          className={rcStyles.list_item__main}
        >
          <div className={styles.minion}>
            {t('Name:')}
            <div className={styles.brevier}>
              {deceasedName}
            </div>
            <span className={styles.brevier}>
              {arrangement.committalType}
            </span>
          </div>
          <div>
            <div className={styles.minion}>
              {t('Ref:')}
            </div>
            <div className={styles.brevier}>
              {arrangement.reference}
            </div>
          </div>
          <div>
            {loanAmount?.amount && funeralSafeReferralDate && (
              <>
                <div className={styles.minion}>
                  {t('Finance:')}
                </div>
                <div className={styles.brevier}>
                  {`${formatCurrency(loanAmount)} | ${t('Applied ')} ${getFormattedDate(funeralSafeReferralDate)}`}
                </div>
              </>
            )}
          </div>
          <div>
            <div className={styles.minion}>
              {t('Created:')}
            </div>
            <div className={styles.brevier}>
              {moment(arrangement.timeCreated).format('DD/MM/YYYY')}
            </div>
          </div>
          <div>
            <div className={styles.minion}>
              {t('Total')}
            </div>
            <div className={styles.brevier}>
              {formatCurrency(arrangement.calculation.finalTotal)}
            </div>
          </div>
        </div>
      </Link>
      <div>
        <IconButton
          aria-controls="edit-menu"
          aria-haspopup="true"
          data-test-id="moreOptions"
          onClick={onMenuOpen}
          className={rcStyles.rc_edit_btn}
        >
          <MoreVerticalIcon />
        </IconButton>
        <Menu
          anchorEl={menuAnchor}
          open={!!menuAnchor}
          onClose={onMenuClose}
          keepMounted
        >
          <MenuItem
            onClick={() => onEditCaseStatus(isCaseOpen ? caseStatuses.CLOSED : caseStatuses.OPEN)}
            data-test-id="closeArrangement"
          >
            {isCaseOpen ? t('Close') : t('Open')}
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

LiteArrangementItem.propTypes = {
  bereavement: bereavementType.isRequired,
  onEditCaseStatus: PropTypes.func.isRequired,
  onMenuOpen: PropTypes.func.isRequired,
  menuAnchor: PropTypes.objectOf(PropTypes.any),
  onMenuClose: PropTypes.func.isRequired,
};

export default LiteArrangementItem;
