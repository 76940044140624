import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import AsyncPaginate from 'react-select-async-paginate';
import { getAsyncLookupOptionValue } from 'services/utils';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import { reactRefType } from 'types/common';
import { components } from 'react-select';
import styles from 'scss/main.scss';

const customSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? styles.colorGreyUltraLight : styles.colorWhite,
    color: 'inherit',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: '4 0',
    color: 'rgba(0, 0, 0, 0.54)',
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: 4,
    color: 'rgba(0, 0, 0, 0.54)',
  }),
  control: (provided, state) => {
    const focusedStyle = state.isFocused ? styles.colorOffWhite : 'inherit';
    return {
      'display': 'flex',
      'backgroundColor': state.hasValue ? 'inherit' : focusedStyle,
      '&:hover': {
        cursor: 'pointer',
      },
      '&:before': {
        'left': 0,
        'right': 0,
        'bottom': 0,
        'content': '"\\00a0"',
        'position': 'absolute',
        'transition': 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        'border-bottom': '1px solid',
        'borderBottomWidth': state.isFocused ? '2px' : '1px',
        'borderBottomColor': state.isFocused ? styles.colorHighlight : 'rgba(0, 0, 0, 0.42)',
        'pointer-events': 'none',
      },
    };
  },
};

const CustomClearIndicator = (props) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles('clearIndicator', props)}
    >
      <CloseIcon />
    </div>
  );
};

CustomClearIndicator.propTypes = {
  getStyles: PropTypes.func.isRequired,
  innerProps: PropTypes.shape({
    ref: reactRefType,
  }),
};

const CustomDropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <ArrowDropDownIcon />
  </components.DropdownIndicator>
);

class CategoryLookupContainer extends Component {
  static propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        category: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
      PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.shape({
            category: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
          }),
        ]),
      ),
    ]).isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isMulti: PropTypes.bool,
    categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    isClearable: PropTypes.bool,
    overridezindex: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  getCategoriesById = (id) => {
    const { categories, onChange } = this.props;

    onChange(categories.find(item => item.category === id));
  }

  getCategories = () => {
    const { categories } = this.props;

    const alphabeticallySortedCategories = categories
      .sort((a, b) => (a.name.toLowerCase() <= b.name.toLowerCase() ? -1 : 1));

    const newResults = alphabeticallySortedCategories.map(category => ({
      label: category.name,
      value: category,
    }));

    return {
      options: newResults,
      hasMore: false,
      additional: {
        lastCursor: null,
      },
    };
  }

  handleChange = (selected) => {
    const { isMulti, onChange } = this.props;

    if (isMulti) {
      return onChange(selected.map(item => item.value));
    }

    return onChange(selected);
  }

  render() {
    const {
      label,
      disabled,
      isMulti,
      isClearable,
      overridezindex,
    } = this.props;
    let { value: propValue } = this.props;

    if (typeof propValue === 'string') {
      this.getCategoriesById(propValue);
    }

    if (isMulti && propValue && typeof propValue[0] === 'string') {
      this.getCategoriesById(propValue);
    }

    if (propValue && typeof propValue === 'object' && !isMulti) {
      propValue = Array.isArray(propValue) && !propValue.length ? null : [propValue];
    }

    const value = propValue && Array.isArray(propValue)
      ? propValue.map(item => ({
        label: item.name,
        value: item,
      }))
      : null;

    return (
      <AsyncPaginate
        id="directoryListingSelect"
        value={value}
        onChange={this.handleChange}
        placeholder={label}
        loadOptions={this.getCategories}
        getOptionValue={option => getAsyncLookupOptionValue(option, 'category')}
        isClearable={isClearable}
        isSearchable
        isMulti={isMulti}
        isDisabled={disabled}
        styles={customSelectStyles}
        className={overridezindex && styles.o_select_override}
        components={{
          ClearIndicator: CustomClearIndicator,
          DropdownIndicator: CustomDropdownIndicator,
        }}
      />
    );
  }
}

export default withApollo(CategoryLookupContainer);
