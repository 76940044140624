import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import FuneralSafeIndicativeCostsTable from './FuneralSafeIndicativeCostsTable';
import { Props, PropsWithoutApolloClient, State } from './index.types';
import { getMonthlyPaymentsByFinanceAmount } from './queries.gql';

class FuneralSafeIndicativeCostsTableContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      monthlyPayments: [],
    };
  }

  componentDidMount = () => {
    const { client, financeAmount } = this.props;

    if (financeAmount?.amount) {
      client.query({
        query: getMonthlyPaymentsByFinanceAmount,
        variables: { amount: financeAmount },
      })
        .then(({ data }) => {
          this.setState({
            monthlyPayments: data?.loanMonthlyPayments || [],
          });
        });
    }
  };

  render() {
    const { monthlyPayments } = this.state;

    return (
      <FuneralSafeIndicativeCostsTable monthlyPayments={monthlyPayments} />
    );
  }
}

export default withApollo<PropsWithoutApolloClient, State>(FuneralSafeIndicativeCostsTableContainer);
