import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { historyType, matchType } from 'types/reactRouter';
import { apolloClientType } from 'types/apollo';
import { createHomeAction, editHomeAction } from 'actions/homes';
import {
  getHomeResult,
} from 'transforms/home';
import { getOrganisationalUnit } from './queries.gql';
import AdminHomeScreen from './AdminHomeScreen';
import { validationSchema } from './validation';

class AdminHomeScreenContainer extends Component {
  static propTypes = {
    homes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    match: matchType,
    client: apolloClientType.isRequired,
    history: historyType,
    createHome: PropTypes.func.isRequired,
    editHome: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    generateRefs: PropTypes.func.isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  }

  constructor(props) {
    super(props);
    const { match } = props;

    this.state = {
      isLoading: !!match.params.homeId,
      homeId: match.params.homeId || null,
      home: { type: 'HOME' },
      isValidationEnabled: false,
    };
  }

  componentDidMount() {
    const { generateRefs, match } = this.props;
    const { homeId } = match.params;

    generateRefs(Object.keys(validationSchema.fields));

    if (!homeId) {
      return;
    }

    const { homes } = this.props;
    const homeFromGlobalState = homes.find((findHome => findHome.id === homeId));

    if (homeFromGlobalState) {
      this.setState({
        isLoading: false,
        home: homeFromGlobalState,
      });
    } else {
      this.getHome();
    }
  }

  getHome = () => {
    const { client, validate, match } = this.props;
    const { homeId } = match.params;

    this.setState({ isLoading: true });

    client.query({
      query: getOrganisationalUnit,
      variables: {
        id: homeId,
      },
    }).then(({ data, errors }) => {
      if (data === null && errors.length > 0) {
        return;
      }

      const organisationalUnit = getHomeResult(data);
      validate(organisationalUnit, validationSchema);

      this.setState(() => ({
        home: organisationalUnit,
      }));
    }).finally(() => {
      this.setState({ isLoading: false });
    });
  }

  handleHomeChange = (key, value) => {
    const { validate } = this.props;
    const { home, isValidationEnabled } = this.state;

    const newHome = {
      ...home,
      [key]: value,
    };

    if (isValidationEnabled) {
      validate(newHome, validationSchema);
    }

    this.setState({ home: newHome });
  }

  handleCreate = async () => {
    const { createHome, history, validate } = this.props;
    const { home } = this.state;

    this.setState({ isValidationEnabled: true });

    if (!validate(home, validationSchema, true)) {
      return;
    }

    createHome(home);
    history.push('/homes');
  }

  handleEdit = async () => {
    const { editHome, history, validate } = this.props;
    const { home } = this.state;

    this.setState({ isValidationEnabled: true });

    if (!validate(home, validationSchema, true)) {
      return;
    }

    editHome(home);
    history.push('/homes');
  }

  handleCancel = () => {
    const { history } = this.props;
    history.push('/homes');
  }

  render() {
    const { formRefs, errors } = this.props;
    const {
      isLoading,
      homeId,
      home,
      isValidationEnabled,
    } = this.state;

    return (
      <AdminHomeScreen
        home={home}
        isLoading={isLoading}
        updateHome={this.handleHomeChange}
        onSave={homeId ? this.handleEdit : this.handleCreate}
        onCancel={this.handleCancel}
        formRefs={formRefs}
        errors={isValidationEnabled ? errors : {}}
      />
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  createHome: createHomeAction,
  editHome: editHomeAction,
}, dispatch);

const mapStateToProps = state => ({
  homes: state.homesStore.homes,
});

export default withValidation(
  withApollo(
    withRouter(
      connect(mapStateToProps, mapDispatchToProps)(AdminHomeScreenContainer),
    ),
  ),
);
