import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { generateHexId } from 'services/utils';
import { v4 as uuidv4 } from 'uuid';
import { productCategories, serviceCategories } from 'constants/arrangement';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import {
  catalogueProductCategories,
  catalogueServiceCategories,
} from 'constants/catalogue';
import { featureFlags } from 'constants/features';
import { blankCatalogueItemVariant, blankCatalogueItem } from 'constants/adminCatalogue';
import { productType, serviceType } from 'types/bereavement';
import AdminCatalogueItemForm from './AdminCatalogueItemForm';
import { validationSchema } from './validation';

export class AdminCatalogueItemFormContainer extends Component {
  static propTypes = {
    catalogueItemType: PropTypes.string.isRequired,
    item: PropTypes.oneOfType([productType, serviceType]),
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    isNewItem: PropTypes.bool,
    validate: PropTypes.func.isRequired,
    generateRefs: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
    isServiceCategory: PropTypes.bool,
    availableFeatures: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }

  constructor(props) {
    super(props);

    const getCategory = itemCategory => (props.isServiceCategory
      ? catalogueServiceCategories.find(category => category.category === itemCategory)
        || catalogueServiceCategories.find(category => category.category === serviceCategories.OFFICIANTS)
      : catalogueProductCategories.find(category => category.category === itemCategory)
        || catalogueProductCategories.find(category => category.category === productCategories.FLOWERS)
    );

    this.state = {
      formData: props.isNewItem
        ? ({
          ...blankCatalogueItem,
          category: getCategory(),
          id: uuidv4(),
          isNewItem: true,
          variants: [{
            ...blankCatalogueItemVariant,
            id: generateHexId(),
            name: 'Default',
            isDefault: true,
          }],
          status: 'DRAFT',
        })
        : {
          ...props.item,
          category: getCategory(props.item.category),
        },
      isPreviewModalOpen: false,
      selectedVariantForImage: null,
      isValidationEnabled: false,
      selectedImage: null,
    };
  }

  componentDidMount() {
    const { generateRefs } = this.props;
    generateRefs(Object.keys(validationSchema.fields));
  }

  checkIsAddressRequired = () => {
    const { formData } = this.state;
    return (
      formData.category && !![
        serviceCategories.SERVICE_VENUES,
        serviceCategories.CEMETERIES,
        serviceCategories.CREMATORIA,
        serviceCategories.RECEPTION_VENUES,
      ].find(category => formData.category.category === category)
    );
  };


  handleSave = () => {
    const { onSave, validate } = this.props;
    const { formData, isValidationEnabled } = this.state;
    if (!isValidationEnabled) {
      this.setState({ isValidationEnabled: true });
    }

    const formDataToValidate = {
      ...formData,
      variants: formData.variants
        .filter(variant => variant.status !== 'DELETED')
        .map(({ isNew, ...variant }) => variant),
      isAddressRequired: this.checkIsAddressRequired(),
    };

    const isValid = validate(formDataToValidate, validationSchema, true);

    if (isValid) {
      onSave(formData);
    }
  }

  handleChange = (key, value) => {
    const { formData: formDataFromState, isValidationEnabled } = this.state;
    const { validate } = this.props;
    const formData = {
      ...formDataFromState,
      [key]: value,
    };
    this.setState({ formData });

    if (isValidationEnabled) {
      validate({
        ...formData,
        isAddressRequired: this.checkIsAddressRequired(),
      }, validationSchema);
    }
  }

  handleImageUpload = (newImages) => {
    const newImageIds = newImages.map(image => image.id);
    this.setState((prevState) => {
      const imageIds = prevState.formData.imageIds || [];
      const images = prevState.formData.images || [];
      return {
        formData: {
          ...prevState.formData,
          imageIds: [...imageIds, ...newImageIds],
          images: [...images, ...newImages],
        },
      };
    });
  }

  handleAddVariant = () => {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        variants: [
          ...prevState.formData.variants,
          {
            ...blankCatalogueItemVariant,
            id: generateHexId(),
            isNew: true,
          },
        ],
      },
    }));
  }

  handleDeleteVariant = (id) => {
    const { formData } = this.state;
    const variants = formData.variants.reduce((results, variant) => {
      if (!variant.isNew && variant.id === id) {
        return [
          ...results,
          { ...variant, status: 'DELETED' },
        ];
      }
      if (variant.id !== id) {
        return [...results, variant];
      }
      return results;
    }, []);

    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        variants,
      },
    }));
  }

  handleChangeVariant = (key, id, value) => {
    const { formData: formDataFromState, isValidationEnabled } = this.state;
    const { validate } = this.props;
    const newVariants = formDataFromState.variants.map((variant) => {
      if (variant.id === id) {
        return {
          ...variant,
          [key]: value,
        };
      }
      return variant;
    });

    const formData = {
      ...formDataFromState,
      variants: newVariants,
    };

    this.setState({ formData });

    if (isValidationEnabled) {
      validate(formData, validationSchema);
    }
  }

  handleSelectVariantImage = (image) => {
    this.setState({
      selectedImage: image,
    });
  }

  handleSaveVariantImage = (variantId) => {
    const { formData, selectedImage } = this.state;
    const variants = formData.variants.map((variant) => {
      if (variant.id === variantId) {
        return {
          ...variant,
          imageId: selectedImage ? selectedImage.id : null,
          image: selectedImage,
        };
      }
      return variant;
    });
    this.setState({
      formData: {
        ...formData,
        variants,
      },
      selectedImage: null,
      selectedVariantForImage: null,
    });
  }

  togglePreviewModal = () => {
    this.setState(prevState => ({
      isPreviewModalOpen: !prevState.isPreviewModalOpen,
    }));
  }

  toggleSelectImageModal = (variantId = null) => {
    this.setState({
      selectedVariantForImage: variantId,
    });
  }

  handleChangeImages = (images) => {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        images,
        imageIds: images.map(image => image.id),
      },
    }));
  }

  render() {
    const {
      formData,
      isPreviewModalOpen,
      selectedVariantForImage,
      selectedImage,
    } = this.state;
    const {
      catalogueItemType,
      isNewItem,
      errors,
      formRefs,
      onCancel,
      isServiceCategory,
      availableFeatures,
    } = this.props;

    const hasXeroIntegration = availableFeatures.includes(featureFlags.XERO_INTEGRATION);

    return (
      <AdminCatalogueItemForm
        isServiceCategory={isServiceCategory}
        isAddressRequired={this.checkIsAddressRequired()}
        formRefs={formRefs}
        errors={errors}
        formData={formData}
        selectedImage={selectedImage}
        catalogueItemType={catalogueItemType}
        isNewItem={isNewItem}
        isPreviewModalOpen={isPreviewModalOpen}
        selectedVariantForImage={selectedVariantForImage}
        onChange={this.handleChange}
        onImageUpload={this.handleImageUpload}
        onChangeVariant={this.handleChangeVariant}
        onAddVariant={this.handleAddVariant}
        onDeleteVariant={this.handleDeleteVariant}
        onSave={this.handleSave}
        onCancel={onCancel}
        onChangeImages={this.handleChangeImages}
        onSelectVariantImage={this.handleSelectVariantImage}
        onSaveVariantImage={this.handleSaveVariantImage}
        togglePreviewModal={this.togglePreviewModal}
        toggleSelectImageModal={this.toggleSelectImageModal}
        hasXeroIntegration={hasXeroIntegration}
      />
    );
  }
}

const mapStateToProps = state => ({
  availableFeatures: state.userStore.user.tenantFeatureFlags,
});

export default withValidation(connect(mapStateToProps)(AdminCatalogueItemFormContainer));
