import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import DynamicList from 'components/common/DynamicList';
import MusicFormSection from 'components/arrangement/MusicFormSection';
import OrganistFormSection from 'components/arrangement/OrganistFormSection';
import IconLoad from 'icons/IconLoad';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const ArrangementMusicForm = ({
  categoryInformation,
  disabled,
  isSaving,
  onChange,
  onSave,
  venueCategoryInformation,
}) => (

  <div className={rcStyles.v_spacing}>
    <DynamicList
      isStacked
      secondary
      hasPadding
      component={<MusicFormSection />}
      addButtonInner={
        (
          <Fragment>
            <AddIcon className={styles.u_push__right_half} />
            {t('Add Music For Service')}
          </Fragment>
        )
      }
      addButtonProps={{
        variant: 'outlined',
        color: 'primary',
        size: 'medium',
      }}
      removeButtonProps={{
        variant: 'outlined',
        color: 'primary',
        size: 'small',
        classes: { root: styles.o_button__round },
      }}
      removeButtonInner={<RemoveIcon />}
      dataList={categoryInformation.serviceMusic}
      onChange={data => onChange('serviceMusic', data)}
      disabled={disabled}
      classes={styles.u_flush__top}
      data-test-id="musicFormSection"
    />
    <DynamicList
      isStacked
      secondary
      hasPadding
      component={<OrganistFormSection venueCategoryInformation={venueCategoryInformation} />}
      addButtonInner={
        (
          <Fragment>
            <AddIcon data-test-id="addOrganist" className={styles.u_push__right_half} />
            {t('Add Organist')}
          </Fragment>
        )
      }
      addButtonProps={{
        variant: 'outlined',
        color: 'primary',
        size: 'medium',
      }}
      removeButtonProps={{
        variant: 'outlined',
        color: 'primary',
        size: 'small',
        classes: { root: styles.o_button__round },
      }}
      removeButtonInner={<RemoveIcon />}
      dataList={categoryInformation.serviceOrganists}
      onChange={data => onChange('serviceOrganists', data)}
      disabled={disabled}
      className={classNames(styles.c_dynamic_form_border)}
      data-test-id="organistFormSection"
    />
    <div className={styles.c_confirmed__wrap}>
      <FormControlLabel
        control={(
          <Checkbox
            className={styles.c_confirmed__checkbox}
            checked={categoryInformation.isConfirmed || false}
            onChange={event => onChange('isConfirmed', event.target.checked)}
            inputProps={{ 'data-test-id': 'isConfirmed' }}
            disabled={disabled}
            color="primary"
          />
        )}
        label={t('Music confirmed with venue(s)')}
      />
    </div>
    <span>
      <Button
        onClick={onSave}
        color="primary"
        variant="contained"
        disabled={disabled}
        data-test-id="saveMusicForm"
      >
        {t('Save')}
      </Button>
      {isSaving && (
        <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
          <IconLoad title={t('Saving')} />
        </div>
      )}
    </span>
  </div>
);

ArrangementMusicForm.propTypes = {
  categoryInformation: PropTypes.objectOf(PropTypes.any),
  isSaving: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  venueCategoryInformation: PropTypes.objectOf(PropTypes.any),
};

export default ArrangementMusicForm;
