import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconLoad from 'icons/IconLoad';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const PageLoader = ({
  isLoading,
  fullScreen,
}) => (
  <div className={classNames(
    rcStyles.page_loader,
    isLoading ? styles.u_soft__top_double : null,
    isLoading ? rcStyles.page_loader__active : null,
    fullScreen ? rcStyles.page_loader__fullscreen : null,

  )}
  >
    <div className={classNames(
      rcStyles.page_loader_svg,
    )}
    >
      <IconLoad />
    </div>
  </div>
);

PageLoader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
};

export default PageLoader;
