import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { matchType } from 'types/reactRouter';
import AdminCard from 'components/common/cards/AdminCard';
import ScreenLayout from 'components/common/ScreenLayout';
import classNames from 'classnames';
import styles from 'scss/main.scss';

const AdminCatalogueMenuScreen = ({ cards, match }) => (
  <ScreenLayout
    title={t('Manage brochure')}
    dashboardBannerProps={{
      breadcrumbs: [{ path: '/', title: t('Dashboard') }, { path: '/catalogue', title: t('Brochure management') }],
      description: t('Create and edit products, services and packages for your online brochure'),
    }}
  >
    <div className={styles.o_view}>
      <div className={classNames(
        styles.o_view__main,
        styles.has_auto_columns,
      )}
      >
        {cards
          .filter(card => !card.disabled)
          .map((card, index) => (
            <AdminCard
              key={index}
              link={`${match.url}${card.link}`}
              title={card.title}
              icon={card.icon}
              text={card.text}
              testId={card.testId}
            />
          ))}
      </div>
    </div>
  </ScreenLayout>
);

AdminCatalogueMenuScreen.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  match: matchType.isRequired,
};

export default AdminCatalogueMenuScreen;
