import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';

const IconNotes = (props) => {
  const { className } = props;
  const classes = className ? `${className} ${styles.iconOverride}` : styles.rc_icon;
  return (
    <svg viewBox="0 0 24 24" className={classes}>
      <path d="M13.9,3l-2,2H5v16h14V10.7l2-2V21c0,1.1-0.9,2-2,2H5c-1.1,0-2-0.9-2-2V5c0-1.1,0.9-2,2-2H13.9z" />
      <polygon points="9,11.2 17.2,3 20,5.8 11.8,14 9,14 " />
      <path d="M21.8,3.8L20.6,5L18,2.4l1.2-1.2c0.3-0.3,0.7-0.3,1,0c0,0,0,0,0,0l1.6,1.6C22.1,3.1,22.1,3.5,21.8,3.8 C21.8,3.8,21.8,3.8,21.8,3.8L21.8,3.8z" />
    </svg>
  );
};

IconNotes.propTypes = {
  className: PropTypes.string,
};

export default IconNotes;
