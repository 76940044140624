import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { debounce } from 'debounce';
import PropTypes from 'prop-types';

import { catalogueServiceCategories } from 'constants/catalogue';
import { matchType } from 'types/reactRouter';
import { productType, serviceType } from 'types/bereavement';
import {
  updateFiltersAction,
  fetchAdminCatalogueItemsAction,
} from 'actions/catalogue';
import PackageItemModal from './PackageItemModal';

class PackageItemModalContainer extends Component {
  debounceGetCatalogueItems = debounce((refreshConditions) => {
    this.getCatalogueItems(refreshConditions);
  }, 500);

  static propTypes = {
    products: PropTypes.arrayOf(productType),
    services: PropTypes.arrayOf(serviceType),
    filters: PropTypes.objectOf(PropTypes.any).isRequired,
    isDisbursementCategory: PropTypes.bool,
    match: matchType,
    isLoading: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool,
    isServiceCategory: PropTypes.bool,
    pagination: PropTypes.objectOf(PropTypes.any).isRequired,
    fetchCatalogueItems: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    updateFilters: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedCatalogueItemIds: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { isDisbursementCategory, updateFilters } = this.props;
    const { isDisbursementCategory: prevIsDisbursementCategory } = prevProps;

    if (isDisbursementCategory && !prevIsDisbursementCategory) {
      const disbursementCategory = catalogueServiceCategories.find(serviceCategory => serviceCategory.category === 'DISBURSEMENTS');
      const refreshConditions = { clearFilters: false, clearPagination: true };
      updateFilters('categories', [disbursementCategory]);
      this.getCatalogueItems(refreshConditions);
    }
  }

  getCatalogueItems = (refreshConditions) => {
    const { fetchCatalogueItems } = this.props;

    fetchCatalogueItems(refreshConditions);
  }

  handleChangeFilters = (key, value, shouldDebounce) => {
    const { updateFilters } = this.props;
    const refreshConditions = { clearFilters: false, clearPagination: true };
    const fetchItems = shouldDebounce
      ? this.debounceGetCatalogueItems
      : this.getCatalogueItems;

    updateFilters(key, value);
    fetchItems(refreshConditions);
  }

  handleSelectCatalogueItem = (id, isChecked) => {
    this.setState(prevState => ({
      selectedCatalogueItemIds: isChecked ? ([
        ...prevState.selectedCatalogueItemIds,
        id,
      ]) : prevState.selectedCatalogueItemIds.filter(itemId => itemId !== id),
    }));
  }

  handleCloseModal = () => {
    const { isDisbursementCategory, onClose, updateFilters } = this.props;

    if (isDisbursementCategory) {
      const refreshConditions = { clearFilters: false, clearPagination: true };
      updateFilters('categories', []);
      this.getCatalogueItems(refreshConditions);
    }

    onClose();
    this.setState({ selectedCatalogueItemIds: [] });
  };

  handleSave = () => {
    const {
      onSave, isServiceCategory, services, products,
    } = this.props;
    const { selectedCatalogueItemIds } = this.state;
    const allItems = isServiceCategory ? services : products;
    const selectedItems = allItems.filter(item => selectedCatalogueItemIds.includes(item.id));

    onSave(selectedItems);
    this.handleCloseModal();
  }

  render() {
    const {
      isOpen,
      match,
      products,
      services,
      pagination,
      filters,
      isDisbursementCategory,
      isLoading,
      isServiceCategory,
    } = this.props;
    const { selectedCatalogueItemIds } = this.state;
    const catalogueItems = isServiceCategory ? services : products;

    return (
      <PackageItemModal
        catalogueItems={catalogueItems}
        selectedCatalogueItemIds={selectedCatalogueItemIds}
        filters={filters}
        isDisbursementCategory={isDisbursementCategory}
        hasMoreItems={catalogueItems.length > 0 && pagination.hasNextPage}
        match={match}
        isLoading={isLoading}
        isOpen={isOpen}
        isServiceCategory={isServiceCategory}
        getCatalogueItems={this.getCatalogueItems}
        onChangeFilters={this.handleChangeFilters}
        onClose={this.handleCloseModal}
        onSave={this.handleSave}
        onSelectCatalogueItem={this.handleSelectCatalogueItem}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  products: state.catalogueStore.products.items
    .filter(product => !ownProps.selectedCatalogueItems
      .map(item => item.id)
      .includes(product.id)),
  services: state.catalogueStore.services.items
    .filter(service => !ownProps.selectedCatalogueItems
      .map(item => item.id)
      .includes(service.id)),
  filters: ownProps.isServiceCategory
    ? state.catalogueStore.services.filters
    : state.catalogueStore.products.filters,
  pagination: ownProps.isServiceCategory
    ? state.catalogueStore.services.pagination
    : state.catalogueStore.products.pagination,
  isLoading: ownProps.isServiceCategory
    ? state.catalogueStore.services.isLoading
    : state.catalogueStore.products.isLoading,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const itemType = ownProps.isServiceCategory ? 'services' : 'products';

  return bindActionCreators({
    updateFilters: (key, value) => updateFiltersAction(itemType, key, value),
    fetchCatalogueItems: refreshConditions => fetchAdminCatalogueItemsAction(itemType, refreshConditions),
  }, dispatch);
};

export default withApollo(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PackageItemModalContainer),
  ),
);
