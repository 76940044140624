import {
  string, boolean, object, array,
} from 'yup';
import { obituaryCategories } from 'constants/arrangement';
import { telephoneTypes } from 'constants/telephoneTypes';

function validateObituaryAdministrator() {
  const { shouldPublish, onlineAdministratorBereavedPersonConnection } = this.parent;
  if (onlineAdministratorBereavedPersonConnection) {
    const {
      emails,
      phones,
    } = onlineAdministratorBereavedPersonConnection.bereavedPerson;
    const hasPhones = phones && phones.length > 0;
    const hasMobilePhone = hasPhones
      ? phones.some(phone => phone.type === telephoneTypes.MOBILE)
      : false;
    return !shouldPublish || emails.length > 0 || hasMobilePhone;
  }
  return false;
}

export const validationSchema = object().shape({
  funeralDate: string().nullable().when(['selectedCategory'], {
    is: selectedCategory => selectedCategory === obituaryCategories.CATEGORY_FUNERAL_GUIDE,
    then: string().nullable().required('Funeral date is required. Please ensure a date has been entered into at least one of the venue category forms then try again.'),
  }),
  deceasedName: object().shape({
    title: string().nullable(),
    givenName: string().nullable().required('Given name is required'),
    familyName: string().nullable().required('Family name is required'),
  }),
  isOnlineObituaryServiceInfo: boolean().nullable(),
  isOnlineObituaryCommittal: boolean().nullable(),
  isOnlineObituaryReception: boolean().nullable(),
  onlineReception: object().nullable().shape({
    address: object().nullable().required('Reception address is required').shape({
      addressLine1: string().nullable().required('First line of address is required'),
      addressLine2: string().nullable(),
      town: string().nullable().required('Town is required'),
      county: string().nullable(),
      postCode: string().nullable().required('Post Code is required'),
      countryCode: string().nullable().required('Country is required'),
    }),
    dateTime: string().nullable().required('Reception date and time is required'),
  }),
  isOnlineServiceInfo: boolean().nullable(),
  onlineAdministratorBereavedPersonConnectionId: string().required('Obituary administrator is required'),
  onlineAdministratorBereavedPersonConnection: object().test('administratorTest', 'Obituary administrator needs at least one email address or mobile phone number', validateObituaryAdministrator),
  shouldPublish: boolean(),
  selectedCategory: string().nullable(),
  charityInformation:
    object().nullable().shape({
      justGivingCharities: array().of(
        object().shape({
          name: string().required('Charity name is required'),
          department: object().nullable().shape({
            id: string().nullable().when(['otherName'], {
              is: otherName => !otherName,
              then: string().required('Charity department is required'),
            }),
            otherName: string().nullable().when(['id'], {
              is: id => id === 'OTHER',
              then: string().required('Charity department is required'),
            }),
          }, ['id', 'otherName']),
        }),
      ),
      localCharities: array().of(
        object().shape({
          name: string().required('Charity name is required'),
          sendDonationsToFamily: boolean().nullable(),
        }),
      ),
    }),
});
