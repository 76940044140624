import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { committalTypes } from 'constants/arrangement';
import { editClientAction } from 'actions/bereavement';
import { bereavedPersonConnectionType, arrangementType } from 'types/bereavement';
import { apolloClientType } from 'types/apollo';
import { editBereavedPersonTransform, bereavedPersonConnectionTransform } from 'transforms/bereavement';
import ArrangementFuneralGuideForm from './ArrangementFuneralGuideForm';
import { editBereavedPerson, editBereavedPersonConnection } from './mutations.gql';

class ArrangementFuneralGuideFormContainer extends Component {
  static propTypes = {
    client: apolloClientType.isRequired,
    editClient: PropTypes.func.isRequired,
    bereavementId: PropTypes.string.isRequired,
    arrangement: arrangementType.isRequired,
    funeralDate: PropTypes.string,
    deceasedDates: PropTypes.string.isRequired,
    bereavedPeopleConnections: PropTypes.arrayOf(bereavedPersonConnectionType).isRequired,
    selectedAdministrator: PropTypes.objectOf(PropTypes.any).isRequired,
    formData: PropTypes.objectOf(PropTypes.any),
    onChange: PropTypes.func.isRequired,
    isObituaryPublished: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      isClientFormOpen: false,
    };
  }

  getServiceVenueInformation = () => {
    const { arrangement } = this.props;
    if (!arrangement.categoryInformation) {
      return null;
    }

    const categoryInformation = arrangement.categoryInformation.serviceVenue;

    const selectedService = arrangement.serviceSelections && arrangement.serviceSelections
      .find(serviceSelection => serviceSelection.service.category === 'SERVICE_VENUES');
    if (!selectedService || !categoryInformation) {
      return null;
    }

    return {
      address: selectedService.service.address,
      startDateTime: categoryInformation.startDateTime,
    };
  }

  getCommittalInformation = () => {
    const { arrangement } = this.props;
    if (!arrangement.categoryInformation) {
      return null;
    }

    const category = arrangement.committalType === committalTypes.BURIAL
      ? 'CEMETERIES'
      : 'CREMATORIA';
    const categoryInformation = arrangement.committalType === committalTypes.BURIAL
      ? arrangement.categoryInformation.cemetery
      : arrangement.categoryInformation.crematorium;

    const selectedService = arrangement.serviceSelections && arrangement.serviceSelections
      .find(serviceSelection => serviceSelection.service.category === category);
    if (!selectedService || !categoryInformation) {
      return null;
    }

    return {
      address: selectedService.service.address,
      startDateTime: categoryInformation.startDateTime,
    };
  }

  handleClientEdit = () => {
    this.setState({ isClientFormOpen: true });
  }

  handleClientClose = () => {
    this.setState({ isClientFormOpen: false });
  }

  handleClientSave = (formData) => {
    const { client, editClient, bereavementId } = this.props;

    editClient(bereavementId, formData);

    const bereavedPersonInput = editBereavedPersonTransform(formData.bereavedPerson);
    client.mutate({
      mutation: editBereavedPerson,
      variables: { input: bereavedPersonInput },
    });

    const bereavedPersonDetails = bereavedPersonConnectionTransform(formData, bereavementId);
    client.mutate({
      mutation: editBereavedPersonConnection,
      variables: { input: bereavedPersonDetails },
    });
    this.handleClientClose();
  }

  render() {
    const { formData } = this.props;
    const { isClientFormOpen } = this.state;
    const { image } = formData;

    return (
      <ArrangementFuneralGuideForm
        {...this.props}
        serviceVenueInformation={this.getServiceVenueInformation()}
        committalInformation={this.getCommittalInformation()}
        image={image}
        isClientFormOpen={isClientFormOpen}
        onClientEdit={this.handleClientEdit}
        onClientClose={this.handleClientClose}
        onClientSave={this.handleClientSave}
      />
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  editClient: editClientAction,
}, dispatch);

export default withApollo(connect(null, mapDispatchToProps)(ArrangementFuneralGuideFormContainer));
