import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { updateAgeAtDeath } from 'services/utils/bereavement';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { bereavementType } from 'types/bereavement';
import { validationSchema } from './validation';
import DeceasedForm from './DeceasedForm';

class DeceasedFormContainer extends Component {
  static propTypes = {
    bereavement: bereavementType.isRequired,
    disabled: PropTypes.bool,
    onDeceasedFormSave: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    generateRefs: PropTypes.func.isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      deceasedPerson: props.bereavement.deceasedPeople[0] || {},
      isValidationEnabled: false,
    };
  }

  componentDidMount() {
    const { generateRefs } = this.props;
    generateRefs(Object.keys(validationSchema.fields));
  }

  handleDeceasedChange = (key, value) => {
    const { validate } = this.props;
    const { deceasedPerson, isValidationEnabled } = this.state;
    let updatedDeceasedPerson = {
      ...deceasedPerson,
      [key]: value,
    };

    if (key === 'dateOfBirth' || key === 'deathDateTime') {
      updatedDeceasedPerson = updateAgeAtDeath(updatedDeceasedPerson);
    }

    if (key === 'weight' && value === '') {
      updatedDeceasedPerson.weight = null;
    }

    const newState = {
      deceasedPerson: updatedDeceasedPerson,
    };

    if (isValidationEnabled) {
      validate(newState.deceasedPerson, validationSchema);
    }
    this.setState(newState);
  }

  handleImageIdChange = (imageId) => {
    const { validate } = this.props;
    const { deceasedPerson, isValidationEnabled } = this.state;

    const newState = {
      deceasedPerson: {
        ...deceasedPerson,
        imageId,
      },
    };

    if (isValidationEnabled) {
      validate(newState.deceasedPerson, validationSchema);
    }
    this.setState(newState);
  }

  handleImageChange = ([image]) => {
    const { validate } = this.props;
    const { deceasedPerson, isValidationEnabled } = this.state;

    const newState = {
      deceasedPerson: {
        ...deceasedPerson,
        image,
      },
    };

    if (isValidationEnabled) {
      validate(newState.deceasedPerson, validationSchema);
    }
    this.setState(newState);
  }

  handleSave = () => {
    const { validate, onDeceasedFormSave } = this.props;
    const { deceasedPerson } = this.state;

    this.setState({ isValidationEnabled: true });
    const isValid = validate(deceasedPerson, validationSchema, true);

    if (isValid) {
      onDeceasedFormSave(deceasedPerson);
    }
  }

  render() {
    const { formRefs, errors, disabled } = this.props;
    const { deceasedPerson, isValidationEnabled } = this.state;

    return (
      <DeceasedForm
        deceasedPerson={deceasedPerson}
        onDeceasedChange={this.handleDeceasedChange}
        onImageIdChange={this.handleImageIdChange}
        onImageChange={this.handleImageChange}
        onSave={this.handleSave}
        disabled={disabled}
        formRefs={formRefs}
        errors={isValidationEnabled ? errors : {}}
      />
    );
  }
}

export default withValidation(DeceasedFormContainer);
