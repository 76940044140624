import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { historyType } from 'types/reactRouter';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { apolloClientType } from 'types/apollo';
import { arrayMove } from 'services/utils';
import { getTenantCatalogueCategories } from 'services/utils/catalogue';
import { categoryOrderMutationTransform } from 'transforms/catalogue';
import AdminCatalogueSettingsScreen from './AdminCatalogueSettingsScreen';
import { getCategoryOrder } from './queries.gql';
import { setCataloguePdfCategoryOrder } from './mutations.gql';

export class AdminCatalogueSettingsScreenContainer extends Component {
  static propTypes = {
    tenantId: PropTypes.string,
    history: historyType,
    client: apolloClientType,
  }

  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      isSortEnabled: false,
      isVisibilityEnabled: true,
      isLoading: true,
      isSaving: false,
      isCataloguePreviewModalOpen: false,
      editCategoryToNavigate: null,
    };
  }

  componentDidMount() {
    this.getCategories();
  }

  getCategories = () => {
    const { client, tenantId } = this.props;

    client.query({
      query: getCategoryOrder,
      variables: { tenantId },
    }).then(({ data }) => {
      const { tenant: { cataloguePdfCategoryDisplayPreferences } } = data;

      this.setCategories(cataloguePdfCategoryDisplayPreferences);
    });
  }

  toggleCataloguePreviewModal = () => {
    this.setState(prevState => ({
      isCataloguePreviewModalOpen: !prevState.isCataloguePreviewModalOpen,
    }));
  }

  setCategoryToNavigate = (category = null) => {
    this.setState({
      editCategoryToNavigate: category,
    });
  }

  setCategories = (displayPreferences) => {
    const categories = getTenantCatalogueCategories(displayPreferences);

    this.setState({
      categories,
      isLoading: false,
    });
  }

  handleEnableSort = () => {
    this.setState(prevState => ({
      isSortEnabled: !prevState.isSortEnabled,
    }));
  }

  handleSortCategories = ({ oldIndex, newIndex }) => {
    const { categories } = this.state;
    const reorderedCategories = arrayMove(categories, oldIndex, newIndex);

    this.setState({ categories: reorderedCategories });
  }

  handleSetOrder = () => {
    this.setState({ isSortEnabled: false });
  }

  handleIncludeInCatalogue = (category, isSelected) => {
    this.setState(prevState => ({
      categories: prevState.categories.map((stateCategory) => {
        if (stateCategory.category === category) {
          return {
            ...stateCategory,
            visible: isSelected,
          };
        }
        return stateCategory;
      }),
    }));
  }

  handleSave = () => {
    const { categories } = this.state;
    const { client, tenantId } = this.props;

    this.setState({ isSaving: true });
    client.mutate({
      mutation: setCataloguePdfCategoryOrder,
      variables: {
        input: {
          tenantId,
          cataloguePdfCategoryDisplayPreferences: categoryOrderMutationTransform(categories),
        },
      },
    }).finally(() => this.setState({ isSaving: false }));
  }

  handleCancel = () => {
    const { history } = this.props;
    history.push('/catalogue');
  }

  handleEditCategory = () => {
    this.handleSave();
    const { history } = this.props;
    const { editCategoryToNavigate } = this.state;
    history.push(`/catalogue/settings/${editCategoryToNavigate}`);
  }

  render() {
    const {
      categories,
      isSortEnabled,
      isVisibilityEnabled,
      isLoading,
      isSaving,
      isCataloguePreviewModalOpen,
      editCategoryToNavigate,
    } = this.state;

    return (
      <AdminCatalogueSettingsScreen
        isLoading={isLoading}
        isSaving={isSaving}
        categories={categories}
        isSortEnabled={isSortEnabled}
        isVisibilityEnabled={isVisibilityEnabled}
        isCataloguePreviewModalOpen={isCataloguePreviewModalOpen}
        editCategoryToNavigate={editCategoryToNavigate}
        onSortCategories={this.handleSortCategories}
        onEnableSort={this.handleEnableSort}
        onSetOrder={this.handleSetOrder}
        onClickIncludeInCatalogue={this.handleIncludeInCatalogue}
        onSave={this.handleSave}
        onCancel={this.handleCancel}
        toggleCataloguePreviewModal={this.toggleCataloguePreviewModal}
        setCategoryToNavigate={this.setCategoryToNavigate}
        onEditCategory={this.handleEditCategory}
      />
    );
  }
}

const mapStateToProps = state => ({
  tenantId: state.userStore.user.tenantId,
});

export default withApollo(
  withRouter(
    connect(mapStateToProps)(AdminCatalogueSettingsScreenContainer),
  ),
);
