import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import { withSnackbar } from 'notistack';

import { addProductSelectionAction, addServiceSelectionAction } from 'actions/arrangement';
import { catalogueItemTypes } from 'constants/arrangement';
import { generateHexId } from 'services/utils';
import { isDuplicateCategorySelection } from 'services/utils/arrangement';
import { determineIsPackageSelection } from 'services/utils/package';
import { apolloClientType } from 'types/apollo';
import { arrangementType, productType, serviceType } from 'types/bereavement';

import { addProductSelectionMutation, addServiceSelectionMutation } from './mutations.gql';
import CatalogueSelectionItem from './CatalogueSelectionItem';

class CatalogueSelectionItemContainer extends Component {
  static propTypes = {
    bereavementId: PropTypes.string.isRequired,
    arrangement: arrangementType.isRequired,
    type: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    item: PropTypes.oneOfType([productType, serviceType]).isRequired,
    addProductSelection: PropTypes.func.isRequired,
    addServiceSelection: PropTypes.func.isRequired,
    onOpenDuplicateSelectionModal: PropTypes.func.isRequired,
    client: apolloClientType.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    wasAddedFromCareScreen: PropTypes.bool,
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedVariantId: null,
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (state.selectedVariantId !== null) {
      return state;
    }
    return {
      ...state,
      selectedVariantId: nextProps.item.variants[0].id,
    };
  }

  addSelection = (type, action, mutation, wasAddedFromCareScreen) => {
    const {
      client,
      bereavementId,
      arrangement,
      item,
      enqueueSnackbar,
    } = this.props;
    const { selectedVariantId } = this.state;
    const selectionId = generateHexId();

    const isPackageSelection = determineIsPackageSelection(
      arrangement.packageSelection,
      item,
      arrangement[`${type.toLowerCase()}Selections`],
      type.toLowerCase(),
    );

    action(bereavementId, arrangement.id, selectionId, item, isPackageSelection, selectedVariantId);

    const input = {
      bereavementId,
      arrangementId: arrangement.id,
      selection: {
        id: selectionId,
        [`${type.toLowerCase()}Id`]: item.id,
        isPackageSelection,
        variantId: selectedVariantId,
        wasAddedFromCareScreen,
      },
    };
    client.mutate({
      mutation,
      variables: { input },
    });

    enqueueSnackbar(t(`${item.title} was added`), { variant: 'success' });
  }

  handleOnClickAdd = () => {
    const {
      item,
      arrangement,
      type,
      category,
      addProductSelection,
      addServiceSelection,
      onOpenDuplicateSelectionModal,
      wasAddedFromCareScreen,
    } = this.props;
    const isDuplicateSelection = isDuplicateCategorySelection(item, arrangement, type, category);

    const addSelection = () => {
      if (type === catalogueItemTypes.PRODUCT) {
        this.addSelection(type, addProductSelection, addProductSelectionMutation, wasAddedFromCareScreen);
      }

      if (type === catalogueItemTypes.SERVICE) {
        this.addSelection(type, addServiceSelection, addServiceSelectionMutation, wasAddedFromCareScreen);
      }
    };

    if (isDuplicateSelection) {
      onOpenDuplicateSelectionModal(item, () => {
        addSelection();
      });
    } else {
      addSelection();
    }
  }

  handleOnSelectedVariantChanged = (variantId) => {
    this.setState({
      selectedVariantId: variantId,
    });
  }

  render() {
    const { item } = this.props;
    const { selectedVariantId } = this.state;
    return (
      <CatalogueSelectionItem
        title={item.title}
        variants={item.variants}
        selectedVariantId={selectedVariantId}
        onClickAdd={this.handleOnClickAdd}
        onSelectedVariantChanged={this.handleOnSelectedVariantChanged}
      />
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  addProductSelection: addProductSelectionAction,
  addServiceSelection: addServiceSelectionAction,
}, dispatch);

export default withSnackbar(withApollo(
  withRouter(connect(null, mapDispatchToProps)(CatalogueSelectionItemContainer)),
));
