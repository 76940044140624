import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { validationSchema } from './validation';
import PossessionsModal from './PossessionsModal';

class PossessionsModalContainer extends Component {
  static propTypes = {
    editingItem: PropTypes.objectOf(PropTypes.any),
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired,
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    generateRefs: PropTypes.func.isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      formData: {},
      isValidationEnabled: false,
    };

    this.dialogRef = React.createRef();
  }

  componentDidMount() {
    const { editingItem, generateRefs } = this.props;
    generateRefs(Object.keys(validationSchema.fields));
    if (editingItem) {
      this.setState({
        formData: {
          ...editingItem,
          actionTaken: null,
        },
      });
    }
  }

  handleChange = (key, value) => {
    const { validate } = this.props;
    const { formData, isValidationEnabled } = this.state;

    const newFormData = {
      ...formData,
      [key]: value,
    };

    if (isValidationEnabled) {
      validate(newFormData, validationSchema);
    }

    this.setState({
      formData: newFormData,
    });
  }

  uploadCallback = ([image]) => {
    this.handleChange('image', image);
  }

  handleSave = (event) => {
    event.preventDefault();
    const { validate, onSave } = this.props;
    const { formData } = this.state;

    this.setState({ isValidationEnabled: true });
    const isValid = validate(formData, validationSchema, true, this.dialogRef);

    if (isValid) {
      onSave(formData);
    }
  }

  render() {
    const { formData } = this.state;
    const { errors, formRefs, onCancel } = this.props;

    return (
      <PossessionsModal
        {...this.props}
        formData={formData}
        onChange={this.handleChange}
        uploadCallback={this.uploadCallback}
        onSave={this.handleSave}
        onCancel={onCancel}
        dialogRef={this.dialogRef}
        formRefs={formRefs}
        errors={errors}
      />
    );
  }
}

export default withValidation(PossessionsModalContainer);
