import React, { Fragment } from 'react';
import { matchType } from 'types/reactRouter';
import { Switch, Route } from 'react-router-dom';
import NotFoundScreen from 'screens/NotFoundScreen';
import AdminHomesListScreen from 'screens/admin/AdminHomesListScreen';
import AdminHomeScreen from 'screens/admin/AdminHomeScreen';

const AdminHomesSectionScreen = ({
  match,
}) => (
  <Fragment>
    <Switch>
      <Route
        exact
        key="create"
        path={`${match.url}/create`}
        render={() => (
          <AdminHomeScreen />
        )}
      />
      <Route
        key="edit"
        path={`${match.url}/:homeId`}
        render={() => (
          <AdminHomeScreen />
        )}
      />
      <Route
        exact
        key="list"
        path={`${match.url}`}
        render={() => (
          <AdminHomesListScreen />
        )}
      />
      <Route key="not-found" component={NotFoundScreen} />
    </Switch>
  </Fragment>
);

AdminHomesSectionScreen.propTypes = {
  match: matchType.isRequired,
};

export default AdminHomesSectionScreen;
