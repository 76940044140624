import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import {
  editStaffMemberAction,
  createStaffMemberAction,
  updateStaffMemberAction,
  updateIdentityStatusAction,
  createStaffMemberIdentityInterfaceAction,
} from 'actions/staffMembers';
import { v4 as uuidv4 } from 'uuid';
import { historyType, matchType } from 'types/reactRouter';
import { staffMemberType } from 'types/staffMember';
import { apolloClientType } from 'types/apollo';
import {
  getStaffMemberResults,
  staffMemberTransform,
} from 'transforms/staffMember';
import { getStaffMember } from './queries.gql';
import AdminStaffMemberScreen from './AdminStaffMemberScreen';
import { activateAccountValidationSchema, validationSchema } from './validation';

class AdminStaffMemberScreenContainer extends Component {
  static propTypes = {
    staffMembers: PropTypes.arrayOf(staffMemberType).isRequired,
    match: matchType,
    history: historyType,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
    validate: PropTypes.func.isRequired,
    generateRefs: PropTypes.func.isRequired,
    client: apolloClientType.isRequired,
    createStaffMember: PropTypes.func.isRequired,
    editStaffMember: PropTypes.func.isRequired,
    updateIdentityStatus: PropTypes.func.isRequired,
    createStaffMemberIdentityInterface: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      selectedRoles: null,
      isArrangerUser: false,
      selectedHomes: null,
      isNewStaffMember: false,
      isValidationEnabled: false,
    };
  }

  componentDidMount() {
    const { match, generateRefs } = this.props;
    const { staffMemberId } = match.params;
    const { staffMembers } = this.props;
    const staffMemberFromGlobalState = staffMembers
      .find((staffmember => staffmember.id === staffMemberId));

    generateRefs(Object.keys(validationSchema.fields));

    if (!staffMemberId) {
      this.setState({
        isLoading: false,
        isNewStaffMember: true,
      });
      return;
    }

    if (!staffMemberFromGlobalState) {
      this.getStaffMember(staffMemberId);
      return;
    }

    this.setState({
      isLoading: false,
      staffMember: staffMemberFromGlobalState,
      isArrangerUser: staffMemberFromGlobalState.identityInterface
        && staffMemberFromGlobalState.identityInterface.isActive,
    });
  }

  getStaffMember = (staffMemberId) => {
    const { client } = this.props;

    this.setState({ isLoading: true });

    client.query({
      query: getStaffMember,
      variables: {
        id: staffMemberId,
      },
    }).then(({ data, errors }) => {
      if (!data && errors.length) {
        return;
      }

      const staffMember = getStaffMemberResults(data);

      this.setState({
        staffMember,
        isArrangerUser: staffMember.identityInterface
          && staffMember.identityInterface.isActive,
      });
    }).finally(() => {
      this.setState({ isLoading: false });
    });
  }

  toggleIsArrangerUser = () => {
    const { validate } = this.props;
    const { staffMember } = this.state;
    const isValidToggle = validate(staffMember, activateAccountValidationSchema, true);

    if (isValidToggle) {
      this.setState(prevState => ({
        isArrangerUser: !prevState.isArrangerUser,
      }));
    }
  }

  handleChange = (key, value) => {
    const { validate } = this.props;
    const { staffMember, isValidationEnabled } = this.state;
    let { isArrangerUser } = this.state;
    const newStaffMember = {
      ...staffMember,
      [key]: value,
    };

    if (isValidationEnabled) {
      validate(newStaffMember, validationSchema);
    }

    if (!newStaffMember.emails || newStaffMember.emails.length === 0) {
      isArrangerUser = false;
    }

    this.setState({
      staffMember: newStaffMember,
      isArrangerUser,
    });
  }

  handleUpdateLookupValues = (key, value) => {
    this.setState(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }

  handleCancel = () => {
    const { history } = this.props;
    history.push('/staff-members');
  }

  handleSave = async () => {
    const { validate, history } = this.props;
    const {
      staffMember,
      isNewStaffMember,
      selectedHomes,
      selectedRoles,
    } = this.state;
    const isValid = validate({
      ...staffMember,
      selectedHomes,
    }, validationSchema, true);
    const newId = isNewStaffMember && uuidv4();

    this.setState({ isValidationEnabled: true });

    if (!isValid) {
      return;
    }

    const updatedStaffMember = staffMemberTransform(
      staffMember, selectedHomes, selectedRoles, newId,
    );

    if (isNewStaffMember) {
      this.createStaffMember(updatedStaffMember);
    } else {
      this.updateStaffMember(updatedStaffMember);
    }

    history.push('/staff-members');
  }

  createStaffMember = (updatedStaffMember) => {
    const { isArrangerUser } = this.state;
    const { createStaffMember, createStaffMemberIdentityInterface } = this.props;

    createStaffMember(updatedStaffMember);

    if (isArrangerUser) {
      createStaffMemberIdentityInterface(updatedStaffMember);
    }
  }

  updateStaffMember = (staffMember) => {
    const { isArrangerUser } = this.state;
    const {
      editStaffMember,
      updateIdentityStatus,
      createStaffMemberIdentityInterface,
    } = this.props;
    const hasActiveIdentityInterface = staffMember.identityInterface
      && staffMember.identityInterface.isActive;
    const shouldDeactivateIdentity = !isArrangerUser && hasActiveIdentityInterface;
    const shouldActivateIdentity = isArrangerUser && !hasActiveIdentityInterface;
    const updatedStaffMember = {
      ...staffMember,
      roleIds: shouldDeactivateIdentity ? [] : staffMember.roleIds,
    };

    editStaffMember(updatedStaffMember);

    if (shouldActivateIdentity) {
      if (!staffMember.identityInterface) {
        createStaffMemberIdentityInterface(updatedStaffMember);
      } else {
        updateIdentityStatus({ staffMember: updatedStaffMember, status: 'activate' });
      }
    }
    if (shouldDeactivateIdentity) {
      updateIdentityStatus({ staffMember: updatedStaffMember, status: 'deactivate' });
    }
  }

  render() {
    const {
      staffMember,
      selectedRoles,
      isLoading,
      selectedHomes,
      isArrangerUser,
    } = this.state;
    const { errors, formRefs } = this.props;

    return (
      <AdminStaffMemberScreen
        isLoading={isLoading}
        staffMember={staffMember || {}}
        errors={errors}
        formRefs={formRefs}
        onChange={this.handleChange}
        onSave={this.handleSave}
        onCancel={this.handleCancel}
        selectedRoles={isArrangerUser ? selectedRoles : []}
        selectedHomes={selectedHomes}
        onUpdateLookupValues={this.handleUpdateLookupValues}
        toggleIsArrangerUser={this.toggleIsArrangerUser}
        isArrangerUser={isArrangerUser}
      />
    );
  }
}

const mapStateToProps = state => ({
  staffMembers: state.staffMembersStore.staffMembers,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  editStaffMember: editStaffMemberAction,
  createStaffMember: createStaffMemberAction,
  updateStaffMember: updateStaffMemberAction,
  updateIdentityStatus: updateIdentityStatusAction,
  createStaffMemberIdentityInterface: createStaffMemberIdentityInterfaceAction,
}, dispatch);

export default withRouter(withValidation(
  withApollo(
    connect(mapStateToProps, mapDispatchToProps)(AdminStaffMemberScreenContainer),
  ),
));
