import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import CatalogueItemDetails from 'components/catalogue/CatalogueItemDetails';

import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const CatalogueItemPreviewModal = ({
  isOpen,
  item,
  disabled,
  onClose,
}) => (
  <Dialog
    onClose={onClose}
    fullScreen
    open={isOpen}
    TransitionComponent={Transition}
    className={styles.c_dialogue}
    disableBackdropClick
    disableEscapeKeyDown
  >
    <DialogTitle
      disableTypography
      className={classNames(
        styles.c_dialog__header,
        styles.is_very_slim,
        styles.u_flush__bottom,
      )}
    >
      <div className={classNames(
        styles.o_fb,
        styles.o_fb__no_wrap,
      )}
      >
        <div className={classNames(styles.o_fb__item__push_right)}>
          <IconButton
            className={classNames(styles.o_button__secondary)}
            color="primary"
            variant="contained"
            aria-label="Close"
            onClick={onClose}
            data-test-id="close"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </DialogTitle>
    <CatalogueItemDetails
      item={item}
      key={item.id}
      disabled={disabled}
      onClose={onClose}
      className={rcStyles.rc_scroll}
    />

  </Dialog>
);

CatalogueItemPreviewModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  item: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default CatalogueItemPreviewModal;
