import moment from 'moment';
import {
  array, string, object, number, boolean,
} from 'yup';

export const validationSchema = object().shape({
  name: object().shape({
    title: string().nullable().required('Title is required'),
    givenName: string().nullable().required('Given name is required'),
    familyName: string().nullable().required('Family name is required'),
  }),
  deathDateTime: string().nullable(),
  dateOfBirth: string().nullable(),
  birthAndDeathDateValidation: string().nullable().when(['deathDateTime', 'dateOfBirth'], {
    is: ((deathDateTime, dateOfBirth) => {
      if (!deathDateTime || !dateOfBirth) {
        return false;
      }
      const deathDateTimeAsMoment = moment(deathDateTime);
      const dateOfBirthAsMoment = moment(dateOfBirth);
      return deathDateTimeAsMoment.isBefore(dateOfBirthAsMoment);
    }),
    then: string().required('Date of birth must be before the date of death.'),
  }),
  ageAtDeath: number().typeError('Age must be a number').nullable(),
  collectionLocation: object().nullable().shape({
    addressLine1: string().nullable().required('First line address is required'),
    addressLine2: string().nullable(),
    town: string().nullable().required('Town is required'),
    county: string().nullable(),
    postCode: string().nullable().required('Post Code is required'),
    countryCode: string().nullable().required('Country is required'),
  }),
  alsoKnownAs: string().nullable(),
  previousSurname: string().nullable(),
  faith: string().nullable(),
  address: object().nullable().shape({
    addressLine1: string().nullable(),
    addressLine2: string().nullable(),
    town: string().nullable(),
    county: string().nullable(),
    postCode: string().nullable(),
    countryCode: string().nullable(),
  }),
  transfersIntoCare: array().of(
    object().nullable().shape({
      fromLocation: object().nullable(),
      toLocation: object().nullable(),
      startDateTime: string().nullable(),
      endDateTime: string().nullable(),
      instructions: string().nullable(),
    }),
  ).when(['isReadyForCollection'], {
    is: isReadyForCollection => isReadyForCollection,
    then: array().of(
      object().nullable().shape({
        fromLocation: object().nullable().shape({
          addressLine1: string().nullable().required('First line address is required'),
          addressLine2: string().nullable(),
          town: string().nullable().required('Town is required'),
          county: string().nullable(),
          postCode: string().nullable().required('Post Code is required'),
          countryCode: string().nullable().required('Country is required'),
        }).required('Transfer from is required'),
        toLocation: object().nullable().shape({
          addressLine1: string().nullable().required('First line address is required'),
          addressLine2: string().nullable(),
          town: string().nullable().required('Town is required'),
          county: string().nullable(),
          postCode: string().nullable().required('Post Code is required'),
          countryCode: string().nullable().required('Country is required'),
        }).required('Transfer to is required'),
        startDateTime: string().nullable().required('Start date & time of transfer is required'),
        endDateTime: string().nullable().required('End date & time of transfer is required'),
        instructions: string().nullable(),
      }),
    ),
  }),
  placeOfDeath: string().nullable(),
  registrationOfDeathDate: string().nullable(),
  isMCCDIssued: boolean().nullable(),
  MCCDNumber: string().nullable(),
  isReadyForCollection: boolean(),
  isInfectious: boolean(),
  gender: string().nullable(),
  occupation: string().nullable(),
  nationality: string().nullable(),
  NINumber: string().nullable(),
  NHSNumber: string().nullable(),
  maritalStatus: string().nullable().oneOf([null, 'SINGLE', 'MARRIED', 'CIVILPARTNERED', 'DIVORCED', 'WIDOWED', 'OTHER']),
}, ['dateOfBirth', 'ageAtDeath']);
