
import InvoiceDetails from 'components/account/InvoiceDetails';
import InvoiceBuilder from 'components/account/InvoiceBuilder';
import InvoiceGenerate from 'components/account/InvoiceGenerate';
import { invoiceTypes } from 'constants/account';

export const generateInvoiceSteps = {
  [invoiceTypes.INVOICE]: [
    {
      title: 'Build invoice',
      component: InvoiceBuilder,
    },
    {
      title: 'Invoice details',
      component: InvoiceDetails,
    },
    {
      title: 'Generate invoice',
      component: InvoiceGenerate,
    },
  ],
  [invoiceTypes.PROFORMA]: [
    {
      title: 'Build proforma invoice',
      component: InvoiceBuilder,
    },
    {
      title: 'Invoice details',
      component: InvoiceDetails,
    },
    {
      title: 'Generate invoice',
      component: InvoiceGenerate,
    },
  ],
};
