import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import {
  possessionTypes,
  possessionActionTaken,
  possessionsFamilyWishes,
} from 'constants/possession';
import { reactRefType } from 'types/common';
import MediaUpload from 'components/common/MediaUpload';
import Select from 'components/common/Select';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';


const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const PossessionsModal = ({
  onCancel,
  isOpen,
  editingItem,
  onSave,
  formData,
  onChange,
  uploadCallback,
  isEditing,
  dialogRef,
  formRefs,
  errors,
}) => (
  <Dialog
    onClose={onCancel}
    aria-labelledby="possessions-modal"
    open={isOpen}
    fullScreen
    scroll="paper"
    TransitionComponent={Transition}
    disableBackdropClick
    disableEscapeKeyDown
  >
    <div
      className={classNames(
        styles.c_dialog__body,
        styles.c_dialog__overflow,
      )}
    >
      <DialogTitle
        disableTypography
        className={styles.dialog_title_bar}
      >
        <IconButton
          className={classNames(
            styles.o_button__secondary,
            styles.is_close,
          )}
          color="primary"
          variant="contained"
          aria-label="Close"
          onClick={onCancel}
          data-test-id="cancel"
        >
          <CloseIcon />
        </IconButton>
        <div className={styles.is_title}>
          {editingItem ? t('Edit Possession') : t('Add Possession')}
        </div>
        <div className={styles.is_actions}>
          <Button
            onClick={onSave}
            type="submit"
            variant="contained"
            data-test-id="savePossessions"
            color="secondary"
            className={styles.o_button__secondary}
          >
            {t('Save')}
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>
        <div
          ref={dialogRef}
          className={classNames(
            rcStyles.v_spacing,
            rcStyles.body_layout,
            styles.o_block_content,
          )}
        >
          <div ref={formRefs.image}>
            <MediaUpload
              images={formData.image ? [formData.image] : []}
              acceptImages
              provisionalUploadMessage={t('')}
              showImagePreview
              disabled={!!formData.image}
              contentDisposition="inline"
              error={errors.image}
              onUploadDone={uploadCallback}
              data-test-id="possessionImageUpload"
            />
          </div>
          <div className={rcStyles.body_layout__form}>
            <div ref={formRefs.type}>
              <Select
                value={formData.type}
                onChange={event => onChange('type', event.target.value)}
                name="type"
                label={t('Type')}
                inputProps={{ 'data-test-id': 'typeOfPossession' }}
                disabled={isEditing}
                errorText={errors.type}
                fullWidth
              >
                {possessionTypes.map((type, key) => (
                  <MenuItem key={key} value={type.value} data-test-id={type.value}>
                    {type.placeholder}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div ref={formRefs.description}>
              <TextField
                label={t('Description')}
                name="description"
                type="text"
                value={formData.description || ''}
                onChange={event => onChange('description', event.target.value)}
                inputProps={{ 'data-test-id': 'description' }}
                disabled={isEditing}
                error={errors.description}
                helperText={errors.description}
                fullWidth
              />
            </div>
            <div ref={formRefs.requiredForViewing}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={formData.requiredForViewing || false}
                    onChange={event => onChange('requiredForViewing', event.target.checked)}
                    inputProps={{ 'data-test-id': 'requiredForViewing' }}
                    color="primary"
                  />
                )}
                label={t('Required for viewing & service')}
              />
            </div>
            <div ref={formRefs.familyWishes}>
              <Select
                value={formData.familyWishes}
                onChange={event => onChange('familyWishes', event.target.value)}
                name="familyWishes"
                label={t('Family wishes (post service)')}
                inputProps={{ 'data-test-id': 'familyWishes' }}
              >
                {possessionsFamilyWishes.map((action, key) => (
                  <MenuItem key={key} value={action.value} data-test-id={action.value}>
                    {action.placeholder}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div ref={formRefs.note}>
              <TextField
                label={t('Note')}
                name="note"
                type="text"
                value={formData.note || ''}
                onChange={event => onChange('note', event.target.value)}
                inputProps={{ 'data-test-id': 'note' }}
                fullWidth
              />
            </div>
            <div ref={formRefs.actionTaken}>
              <Select
                value={formData.actionTaken}
                onChange={event => onChange('actionTaken', event.target.value)}
                name="actionTaken"
                label={t('Action Taken')}
                inputProps={{ 'data-test-id': 'actionTaken' }}
                errorText={errors.actionTaken}
              >
                {possessionActionTaken.map((action, key) => (
                  <MenuItem key={key} value={action.value} data-test-id={action.value}>
                    {action.placeholder}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </DialogContent>
    </div>
  </Dialog>
);

PossessionsModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  editingItem: PropTypes.objectOf(PropTypes.any),
  onSave: PropTypes.func.isRequired,
  formData: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func.isRequired,
  uploadCallback: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  dialogRef: reactRefType.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default PossessionsModal;
