import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { getUriForMediaByVariant } from 'services/utils';
import { mediaVariants } from 'constants/media';
import { mediaType } from 'types/media';
import rcStyles from './styles.scss';

const SortableThumbnailItem = SortableElement(({ thumbnailImage, sortIndex, onDelete }) => (
  <div className={rcStyles.media_upload__thumb}>
    <div
      style={{
        backgroundImage: `url('${getUriForMediaByVariant(thumbnailImage, mediaVariants.FIT_200_X_200)}')`,
      }}
      className={rcStyles.media_upload__image}
    />
    {sortIndex === 0 && <div className={rcStyles.media_upload__featured}>{t('Featured')}</div>}
    <IconButton
      className={rcStyles.is_delete}
      color="primary"
      variant="contained"
      aria-label="Delete"
      onClick={() => onDelete(thumbnailImage.id)}
      data-test-id="delete"
    >
      <CloseIcon />
    </IconButton>
  </div>
));

export const SortableThumbnailList = SortableContainer(({ thumbnailImages, onDelete }) => (
  <div className={rcStyles.media_upload__thumbs}>
    {thumbnailImages.map((thumbnailImage, index) => (
      <SortableThumbnailItem
        thumbnailImage={thumbnailImage}
        key={index}
        index={index}
        sortIndex={index}
        onDelete={onDelete}
      />
    ))}
  </div>
));

SortableThumbnailItem.propTypes = {
  thumbnailImage: mediaType.isRequired,
};

SortableThumbnailList.propTypes = {
  thumbnailImages: PropTypes.arrayOf(mediaType.isRequired).isRequired,
  onDelete: PropTypes.func.isRequired,
};
