import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import IconApps from '@material-ui/icons/Apps';
import Price from 'components/common/Price';
import { getDefaultImage, getUriForMediaByVariant } from 'services/utils';
import { productType } from 'types/bereavement';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const CatalogueItem = ({
  item,
  disabled,
  isInPackage,
  hasArrangement,
  onAddSelection,
  onView,
}) => {
  const image = getDefaultImage(item);
  const imageVariantUri = image && getUriForMediaByVariant(image);

  return (
    <div className={rcStyles.product_tile}>
      <a
        className={rcStyles.is_body}
        data-test-id="catalogueItemContainer"
      >
        {hasArrangement && (
          <Fragment>
            {isInPackage && (
              <div className={rcStyles.package_flag}>
                <IconApps className={styles.u_push__right} />
                {t('available with package')}
              </div>
            )}
            <div className={rcStyles.price}>
              <Price
                variants={item.variants}
                customClass={styles.is_boxed}
              />
            </div>
            <div className={rcStyles.image}>
              {image && <img alt={item.title} src={imageVariantUri} />}
            </div>
          </Fragment>
        )}
      </a>
      <div className={rcStyles.main}>
        <div className={classNames(
          styles.long_primer,
          styles.is_black,
        )}
        >
          {item.title}
        </div>
      </div>
      <div className={rcStyles.actions}>
        <Button
          onClick={onView}
          variant="outlined"
          color="primary"
          data-test-id="view"
        >
          {t('View')}
        </Button>
        {hasArrangement && (
          <Button
            variant="outlined"
            color="primary"
            onClick={onAddSelection}
            data-test-id="add"
            disabled={disabled}
          >
            {t('Add')}
          </Button>
        )}
      </div>
    </div>
  );
};

CatalogueItem.propTypes = {
  item: productType.isRequired,
  disabled: PropTypes.bool,
  isInPackage: PropTypes.bool.isRequired,
  hasArrangement: PropTypes.bool.isRequired,
  onAddSelection: PropTypes.func,
  onView: PropTypes.func.isRequired,
};

export default CatalogueItem;
