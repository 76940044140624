import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Radio from '@material-ui/core/Radio';
import Slide from '@material-ui/core/Slide';
import AddIcon from '@material-ui/icons/Add';
import BereavedPersonCard from 'components/common/cards/BereavedPersonCard';
import DirectoryListingLookup from 'components/common/lookups/DirectoryListingLookup';
import PayeeFormModal from 'components/account/PayeeFormModal';
import { bereavedPersonConnectionType } from 'types/bereavement';
import { directoryListingType } from 'types/directoryListing';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const PayeeListModal = ({
  bereavedPeopleConnections,
  isOpen,
  isPayeeFormModalOpen,
  isEditingPayee,
  selectedBereaved,
  selectedDirectoryListing,
  payee,
  onBereavedChange,
  onDirectoryListingChange,
  onCancel,
  onSave,
  togglePayeeFormModal,
  savePayee,
}) => (
  <Dialog
    onClose={onCancel}
    aria-labelledby="select-payee-title"
    open={isOpen}
    fullScreen
    scroll="paper"
    TransitionComponent={Transition}
    disableBackdropClick
    disableEscapeKeyDown
  >
    <div
      className={classNames(
        styles.c_dialog__body,
        styles.c_dialog__overflow,
      )}
    >
      <DialogTitle
        disableTypography
        className={styles.dialog_title_bar}
      >
        <IconButton
          className={classNames(
            styles.o_button__secondary,
            styles.is_close,
          )}
          color="primary"
          variant="contained"
          aria-label="Close"
          onClick={onCancel}
          data-test-id="cancel"
        >
          <CloseIcon />
        </IconButton>
        <div className={styles.is_title}>
          {t('Select payee')}
        </div>
        <div className={styles.is_actions}>
          <Button
            onClick={onSave}
            variant="contained"
            color="secondary"
            disabled={!selectedBereaved && !selectedDirectoryListing}
            data-test-id="savePayee"
            fullWidth
          >
            {t('Save')}
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>
        <div
          className={classNames(
            rcStyles.v_spacing,
            styles.o_block_content,
          )}
        >
          <h3 className={classNames(
            styles.long_primer,
            styles.is_black,
          )}
          >
            Select a payee
          </h3>
          {bereavedPeopleConnections.map(bereavedPersonConnection => (
            <Fragment>
              <div
                key={bereavedPersonConnection.id}
                className={styles.o_fb}
              >
                <Radio
                  color="primary"
                  name="administrator"
                  value={bereavedPersonConnection.id}
                  checked={selectedBereaved
                    ? selectedBereaved.id === bereavedPersonConnection.id
                    : false}
                  onChange={event => onBereavedChange(event.target.value)}
                  data-test-id={bereavedPersonConnection.id}
                />
                <BereavedPersonCard
                  bereavedPersonConnection={bereavedPersonConnection}
                  customClass={rcStyles.rc_custom_bg}
                  onEdit={() => togglePayeeFormModal(bereavedPersonConnection)}
                />
              </div>
            </Fragment>
          ))}
          <h3 className={rcStyles.add_payees__title}>
            Add a new payee
          </h3>
          <div className={rcStyles.add_payees}>
            <div className={rcStyles.add_payees__private}>
              <span>
                <Button
                  data-test-id="newPayee"
                  onClick={() => togglePayeeFormModal(null)}
                  color="primary"
                  variant="outlined"
                  size="medium"
                >
                  <AddIcon />
                  {t('Private or individual')}
                </Button>
              </span>
            </div>
            <div className={rcStyles.add_payees__business}>
              <h4 className={styles.primer}>
                {t('Business or solicitor')}
              </h4>
              <DirectoryListingLookup
                value={selectedDirectoryListing}
                label="Select from directory listings"
                onChange={changedValue => onDirectoryListingChange(changedValue && changedValue.value)}
                classes={[rcStyles.rc_lookup]}
                data-test-id="directoryListingLookup"
              />
              <PayeeFormModal
                isOpen={isPayeeFormModalOpen}
                isEditingPayee={isEditingPayee}
                payee={payee}
                onClose={() => togglePayeeFormModal(null)}
                onSave={savePayee}
              />
            </div>
          </div>
        </div>
      </DialogContent>
    </div>
  </Dialog>
);

PayeeListModal.propTypes = {
  bereavedPeopleConnections: PropTypes.arrayOf(bereavedPersonConnectionType).isRequired,
  isOpen: PropTypes.bool.isRequired,
  isPayeeFormModalOpen: PropTypes.bool.isRequired,
  isEditingPayee: PropTypes.bool.isRequired,
  selectedBereaved: bereavedPersonConnectionType,
  selectedDirectoryListing: directoryListingType,
  payee: bereavedPersonConnectionType,
  onBereavedChange: PropTypes.func.isRequired,
  onDirectoryListingChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  togglePayeeFormModal: PropTypes.func.isRequired,
  savePayee: PropTypes.func.isRequired,
};

export default PayeeListModal;
