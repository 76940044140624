import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import IconLoop from '@material-ui/icons/Loop';

import AsyncButton from 'components/common/AsyncButton';
import ExpandableBanner from 'components/common/ExpandableBanner';
import Price from 'components/common/Price';
import { invoiceTypes, invoiceTemplateTransferredStatus } from 'constants/account';
import { AccountConsumer } from 'contexts/account';
import { getFormattedDate } from 'services/utils';
import {
  isInvoice,
  isProforma,
  getInvoiceTransferredStatus,
} from 'services/utils/account';
import { invoiceType } from 'types/account';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const TransactionTemplateRow = ({
  bereavementId,
  invoiceTemplate,
  targetInvoiceId,
  hasConfirmedArrangement,
  isMarkingHasBeenSentToClient,
  onDownloadInvoice,
  isDownloadingInvoice,
  onEditInvoice,
  onSendInvoiceTemplateToXero,
  onMarkHasBeenSentToClient,
  invoiceTemplateSendingToXero,
  tenantHasGivenConsentToAXeroApp,
}) => {
  const withTooltip = (title, children) => (
    <Tooltip
      title={title}
      placement="top-start"
      enterTouchDelay={1}
      leaveTouchDelay={3000}
    >
      {children}
    </Tooltip>
  );
  const getTransferredStatusIcon = () => {
    const transferredStatus = getInvoiceTransferredStatus(invoiceTemplate);
    if (transferredStatus === invoiceTemplateTransferredStatus.TRANSFERRED) {
      return withTooltip(
        t('Transferred'),
        <IconLoop className={rcStyles.rc_transferred} />,
      );
    }
    if (transferredStatus === invoiceTemplateTransferredStatus.TRANSFERRED_AND_EDITED) {
      return withTooltip(
        t('Transferred, but has been edited'),
        <IconLoop className={rcStyles.rc_transferred_and_edited} />,
      );
    }
    return withTooltip(
      t('Not transferred'),
      <IconLoop className={rcStyles.rc_not_transferred} />,
    );
  };

  return (
    <AccountConsumer>
      {({ isAccountOpen, hasWritePermissions }) => (
        <div
          data-test-id={isInvoice(invoiceTemplate) ? 'invoiceTemplateTransaction' : 'invoiceProformaTemplateTransaction'}
        >
          <ExpandableBanner
            headerClasses={[rcStyles.expanding_banner__header]}
            wrapperClasses={[rcStyles.expanding_banner__wrap]}
            isClosedOnLoad={!(targetInvoiceId === invoiceTemplate?.id)}
            title={(
              <div className={rcStyles.banner_content}>
                <div className={rcStyles.is_title}>
                  {isInvoice(invoiceTemplate) && (t('Invoice'))}
                  {isProforma(invoiceTemplate) && t('Proforma Invoice')}
                  <span className={rcStyles.is_icon}>
                    {isInvoice(invoiceTemplate) && getTransferredStatusIcon()}
                  </span>
                </div>
                <div className={rcStyles.is_date_created}>
                  <div className={styles.brevier}>
                    {t('Created:')}
                  </div>
                  <span data-test-id="invoiceTemplateCreatedOn">
                    {getFormattedDate(invoiceTemplate.createdAt)}
                  </span>
                </div>
                <div className={rcStyles.is_date_edited}>
                  {invoiceTemplate.editedAt && (
                    <Fragment>
                      <div className={styles.brevier}>
                        {t('Edited:')}
                      </div>
                      <span data-test-id="invoiceTemplateEditedAt">
                        {getFormattedDate(invoiceTemplate.editedAt)}
                      </span>
                    </Fragment>
                  )}
                </div>
                <div className={rcStyles.is_price}>
                  <div data-test-id="invoiceTemplateTotalAmountDue">
                    <Price
                      price={invoiceTemplate.totals.total}
                      customClass={classNames(
                        styles.c_price_label__regular,
                        styles.is_black,
                        styles.is_bold,
                      )}
                    />
                  </div>
                </div>
              </div>
            )}
          >
            <div className={rcStyles.expanded_content}>
              {invoiceTemplate.number && (
                <div>
                  <h3 className={styles.brevier}>
                    {t('Number')}
                  </h3>
                  <span data-test-id="invoiceTemplateNumber">
                    {invoiceTemplate.number}
                  </span>
                </div>
              )}
              {invoiceTemplate.reference && (
                <div>
                  <h3 className={styles.brevier}>
                    {t('Reference')}
                  </h3>
                  <span data-test-id="invoiceTemplateReference">
                    {invoiceTemplate.reference}
                  </span>
                </div>
              )}
              {isInvoice(invoiceTemplate) && invoiceTemplate.transferredToXeroAt && (
                <div>
                  <div className={styles.brevier}>
                    {t('Transferred')}
                  </div>
                  <span data-test-id="invoiceTransferred">
                    {getFormattedDate(invoiceTemplate.transferredToXeroAt)}
                  </span>
                </div>
              )}
              {invoiceTemplate.invoiceType !== invoiceTypes.PROFORMA && (
                <div className={rcStyles.is_invoice_sent}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={invoiceTemplate.hasBeenSentToClient || false}
                        onChange={event => onMarkHasBeenSentToClient(
                          bereavementId,
                          invoiceTemplate,
                          event.target.checked,
                        )}
                        inputProps={{ 'data-test-id': 'markHasBeenSentToClient' }}
                        color="primary"
                        className={rcStyles.rc_checkbox}
                        disabled={isMarkingHasBeenSentToClient}
                      />
                    )}
                    label={t('Invoice sent')}
                  />
                </div>
              )}
              <div className={rcStyles.actions_bar}>
                {(isInvoice(invoiceTemplate) || isProforma(invoiceTemplate))
                  && !invoiceTemplate?.dateVoided && !!invoiceTemplate?.sections?.length && (
                  withTooltip(
                    t(!hasConfirmedArrangement ? 'Confirm an arrangement to edit this invoice.' : ''),
                    (
                      <div>
                        <Button
                          onClick={() => onEditInvoice(invoiceTemplate.id)}
                          variant="outlined"
                          color="primary"
                          disabled={(!(isAccountOpen && hasWritePermissions) && invoiceTemplate.hasBeenSentToXero)
                            || hasConfirmedArrangement}
                          data-test-id="editInvoiceTemplate"
                        >
                          {t('Edit')}
                        </Button>
                      </div>
                    ),
                  )
                )}

                {isInvoice(invoiceTemplate) && !!onSendInvoiceTemplateToXero && (
                  <AsyncButton
                    onClick={() => onSendInvoiceTemplateToXero(invoiceTemplate.id)}
                    variant="outlined"
                    color="primary"
                    data-test-id="sendToXero"
                    data-invoice-id={invoiceTemplate && invoiceTemplate.id}
                    disabled={!(isAccountOpen && hasWritePermissions)
                      || invoiceTemplateSendingToXero === invoiceTemplate.id
                      || !tenantHasGivenConsentToAXeroApp}
                    nonNativeProps={{
                      isActive: invoiceTemplateSendingToXero === invoiceTemplate.id,
                      text: t('Send to Xero'),
                      displayIcon: false,
                    }}
                  />
                )}
                <div className={rcStyles.is_download}>
                  <AsyncButton
                    onClick={onDownloadInvoice}
                    variant="outlined"
                    color="primary"
                    data-test-id="invoiceTemplateDownload"
                    data-invoice-id={invoiceTemplate && invoiceTemplate.id}
                    disabled={isDownloadingInvoice}
                    nonNativeProps={{
                      isActive: isDownloadingInvoice,
                      text: t('Download'),
                      displayIcon: true,
                    }}
                  />
                </div>
              </div>
            </div>
          </ExpandableBanner>
        </div>
      )}
    </AccountConsumer>
  );
};

TransactionTemplateRow.propTypes = {
  bereavementId: PropTypes.string.isRequired,
  invoiceTemplate: invoiceType,
  targetInvoiceId: PropTypes.string,
  hasConfirmedArrangement: PropTypes.bool.isRequired,
  isMarkingHasBeenSentToClient: PropTypes.bool.isRequired,
  onDownloadInvoice: PropTypes.func.isRequired,
  isDownloadingInvoice: PropTypes.bool,
  onEditInvoice: PropTypes.func.isRequired,
  onSendInvoiceTemplateToXero: PropTypes.func.isRequired,
  onMarkHasBeenSentToClient: PropTypes.func.isRequired,
  invoiceTemplateSendingToXero: PropTypes.string,
  tenantHasGivenConsentToAXeroApp: PropTypes.bool.isRequired,
};

export default TransactionTemplateRow;
