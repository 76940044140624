import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bereavementType } from 'types/bereavement';

import LiteArrangementItem from './LiteArrangementItem';

export class LiteArrangementItemContainer extends Component {
  static propTypes = {
    onEditCaseStatus: PropTypes.func.isRequired,
    bereavement: bereavementType,
  }

  constructor(props) {
    super(props);

    this.state = {
      menuAnchor: null,
    };
  }

  handleMenuOpen = (event) => {
    this.setState({ menuAnchor: event.currentTarget });
  }

  handleMenuClose = () => {
    this.setState({ menuAnchor: null });
  }

  handleEditCaseStatus = (status) => {
    const { onEditCaseStatus, bereavement } = this.props;

    onEditCaseStatus(bereavement.id, status);
    this.handleMenuClose();
  }

  render() {
    const { menuAnchor } = this.state;

    return (
      <LiteArrangementItem
        {...this.props}
        menuAnchor={menuAnchor}
        onMenuOpen={this.handleMenuOpen}
        onMenuClose={this.handleMenuClose}
        onEditCaseStatus={this.handleEditCaseStatus}
      />
    );
  }
}

export default LiteArrangementItemContainer;
