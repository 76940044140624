import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

import ArrangementOfficiantSelectedItem from 'components/arrangement/ArrangementOfficiantSelectedItem';
import ConfirmationModal from 'components/common/ConfirmationModal';
import IconLoad from 'icons/IconLoad';
import styles from 'scss/main.scss';

import rcStyles from './styles.scss';

const ArrangementOfficiantsForm = ({
  arrangement,
  confirmModal,
  formData,
  errors,
  disabled,
  isSaving,
  onChange,
  onChangeVariant,
  onChangeCustomDetails,
  onChangeDirectoryListingId,
  onCreateDirectoryListing,
  onEditDirectoryListing,
  onRemoveSelection,
  onSave,
  onToggleServiceVenue,
}) => (
  <>
    <div className={rcStyles.v_spacing}>
      <FormControlLabel
        control={(
          <Checkbox
            checked={formData.isFamilyArranged}
            onChange={event => onChange('isFamilyArranged', event.target.checked)}
            inputProps={{ 'data-test-id': 'isFamilyArranged' }}
            color="primary"
            disabled={disabled}
          />
          )}
        label={t('Arranged by family')}
      />
      {formData?.selections?.map((selection, index) => (
        <ArrangementOfficiantSelectedItem
          key={selection.id}
          item={selection}
          errors={errors?.selections?.[index]}
          arrangement={arrangement}
          disabled={disabled}
          onChangeVariant={variantId => onChangeVariant(selection?.id, variantId)}
          onChangeCustomDetails={customDetails => onChangeCustomDetails(selection?.id, customDetails)}
          onChangeDirectoryListingId={directoryListingId => (
            onChangeDirectoryListingId(selection?.id, directoryListingId)
          )}
          onCreateDirectoryListing={onCreateDirectoryListing}
          onEditDirectoryListing={onEditDirectoryListing}
          onRemove={onRemoveSelection}
          onToggleServiceVenue={serviceVenueId => onToggleServiceVenue(selection?.id, serviceVenueId)}
        />
      ))}
      {!formData.isFamilyArranged && (
        <div className={classNames(
          styles.c_confirmed__wrap,
          rcStyles.colspan3,
        )}
        >
          <FormControlLabel
            control={(
              <Checkbox
                className={styles.c_confirmed__checkbox}
                checked={formData.isConfirmed || false}
                onChange={event => onChange('isConfirmed', event.target.checked)}
                inputProps={{ 'data-test-id': 'isConfirmed' }}
                disabled={disabled}
                color="primary"
              />
            )}
            label={t('Booking confirmed with officiant(s)')}
          />
        </div>
      )}
      <span className={rcStyles.col1}>
        <Button
          onClick={() => onSave()}
          color="primary"
          variant="contained"
          data-test-id="saveOfficiantInformation"
          disabled={disabled}
        >
          {t('Save')}
        </Button>
      </span>
      {isSaving && (
      <div className={styles.o_fb__item__whole}>
        <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
          <IconLoad title={t('Saving')} />
        </div>
      </div>
      )}
    </div>
    {confirmModal && (
      <ConfirmationModal
        title={t(confirmModal?.title)}
        icon={confirmModal?.icon}
        message={t(confirmModal?.message)}
        confirmLabel={t(confirmModal?.confirmLabel)}
        isOpen={!!confirmModal}
        onConfirm={confirmModal?.onConfirm}
        onCancel={confirmModal?.onCancel}
        testIds={{
          dialog: 'confirmationModal',
          confirm: 'confirm',
        }}
      />
    )}
  </>
);

ArrangementOfficiantsForm.propTypes = {
  arrangement: PropTypes.objectOf(PropTypes.any),
  confirmModal: PropTypes.objectOf(PropTypes.any),
  formData: PropTypes.objectOf(PropTypes.any),
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  isSaving: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeVariant: PropTypes.func.isRequired,
  onChangeCustomDetails: PropTypes.func.isRequired,
  onChangeDirectoryListingId: PropTypes.func.isRequired,
  onCreateDirectoryListing: PropTypes.func.isRequired,
  onEditDirectoryListing: PropTypes.func.isRequired,
  onRemoveSelection: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onToggleServiceVenue: PropTypes.func.isRequired,
};

export default ArrangementOfficiantsForm;
