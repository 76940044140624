import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { SnackbarProvider } from 'notistack';
import { MuiThemeProvider } from '@material-ui/core/styles';
import overrideTheme from 'services/themes/overrideTheme';
import { ApolloProvider } from 'react-apollo';
import client from 'services/network/clients/defaultClient';
import { persistor, store } from 'services/state';
import MainScreen from 'screens/MainScreen';
import PortalScreen from 'screens/portal/PortalScreen';
import SnackbarNotifier from 'components/common/SnackbarNotifier';
import ScrollToTop from 'components/common/ScrollToTop';
import GlobalErrorBoundaryScreen from 'screens/GlobalErrorBoundaryScreen';
import 'services/translations/translation';

import styles from 'scss/main.scss';

const App = () => (
  <ApolloProvider client={client}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Fragment>
            <ScrollToTop />
            <MuiThemeProvider theme={overrideTheme}>
              <GlobalErrorBoundaryScreen>
                <SnackbarProvider
                  maxSnack={3}
                  classes={{
                    variantSuccess: styles.c_notification__success,
                    variantError: styles.c_notification__error,
                    variantWarning: styles.c_notification__warning,
                    variantInfo: styles.c_notification__info,
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                >
                  <Fragment>
                    <MainScreen />
                    <PortalScreen />
                    <SnackbarNotifier />
                  </Fragment>
                </SnackbarProvider>
              </GlobalErrorBoundaryScreen>
            </MuiThemeProvider>
          </Fragment>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </ApolloProvider>
);

export default App;
