import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import moment from 'moment';

import {
  createInvoiceTemplateAction,
  editInvoiceAction,
  editInvoiceTemplateAction,
  generateInvoiceAction,
} from 'actions/account';
import { invoiceTypes, invoiceSectionTitles, payeeTypes } from 'constants/account';
import { generateHexId, defaultMoneyObject } from 'services/utils';
import {
  calculateSectionsTotal,
  getArrangementSelections,
  getInvoicePayee,
} from 'services/utils/account';
import { getConfirmedArrangementFromBereavement } from 'services/utils/bereavement';
import { generateInvoiceInputTransform, generateInvoiceTemplateInputTransform } from 'transforms/account';
import { invoiceType } from 'types/account';
import { apolloClientType } from 'types/apollo';
import { bereavementType } from 'types/bereavement';
import { directoryListingType } from 'types/directoryListing';

import InvoiceGenerationModal from './InvoiceGenerationModal';
import { getNextInvoiceNumbers } from './queries.gql';
import {
  editInvoiceMutation,
  editInvoiceTemplateMutation,
  generateInvoiceMutation,
  generateInvoiceTemplateMutation,
  generateProformaInvoiceMutation,
  generateProformaInvoiceTemplateMutation,
} from './mutations.gql';
import { validationSchema } from './validation';

export class InvoiceGenerationModalContainer extends Component {
  static propTypes = {
    bereavement: bereavementType,
    client: apolloClientType.isRequired,
    directoryListings: PropTypes.arrayOf(directoryListingType),
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
    hasXeroIntegration: PropTypes.bool.isRequired,
    invoiceBeingEdited: invoiceType,
    isDownloadingInvoice: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    isSendingToXero: PropTypes.bool.isRequired,
    tenantHasGivenConsentToAXeroApp: PropTypes.bool.isRequired,
    tenantId: PropTypes.string.isRequired,
    createInvoiceTemplate: PropTypes.func.isRequired,
    editInvoice: PropTypes.func.isRequired,
    editInvoiceTemplate: PropTypes.func.isRequired,
    generateInvoice: PropTypes.func.isRequired,
    generateRefs: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onDownloadInvoice: PropTypes.func.isRequired,
    onSendInvoiceTemplateToXero: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { invoiceBeingEdited, hasXeroIntegration } = this.props;

    const invoice = invoiceBeingEdited;

    if (hasXeroIntegration) {
      invoice.hasBeenSentToClient = false;
      invoice.hasBeenSentToXero = false;
    }

    this.state = {
      activeStep: 0,
      hasInvoiceBeenUpdated: false,
      hasInvoiceFailedToGenerate: false,
      hasInvoiceGenerated: false,
      invoice,
      isInvoiceDirty: false,
      isInvoiceGenerating: false,
      isInvoiceNumberLoading: false,
    };
  }

  componentDidMount() {
    const { bereavement, generateRefs } = this.props;
    const { invoice } = this.state;
    const arrangement = getConfirmedArrangementFromBereavement(bereavement, false);

    generateRefs(Object.keys(validationSchema.fields));

    this.validate(invoice);

    if (!arrangement) {
      this.updateSections([]);
      return;
    }
    if (invoice.isNew) {
      this.updateSections(getArrangementSelections(arrangement, invoice));
    } else {
      const sections = invoice.sections.map(section => ({
        ...section,
        items: section.items.map(item => ({
          ...item,
          isSelected: item.isSelected !== false,
        })),
      }));
      this.updateSections(sections);
    }
  }

  getInvoicePayee = (invoice) => {
    const { bereavement, directoryListings } = this.props;
    const { bereavedPeopleConnections } = bereavement;
    const { payeeId, payeeType } = invoice;

    const payee = getInvoicePayee(
      bereavedPeopleConnections,
      directoryListings,
      payeeId,
      payeeType,
    );

    return payeeType === payeeTypes.BEREAVED_PERSON_CONNECTION
      ? payee?.bereavedPerson
      : payee;
  }

  validate = (invoice, payee = this.getInvoicePayee(invoice)) => {
    const { validate } = this.props;

    return validate({ invoice, payee }, validationSchema);
  }

  getNextInvoiceNumbers = () => {
    const { client, tenantId } = this.props;
    const { invoice } = this.state;

    if (invoice.number) {
      return;
    }
    this.setState({ isInvoiceNumberLoading: true });

    client.query({
      query: getNextInvoiceNumbers,
      variables: { id: tenantId },
    }).then(({
      data: {
        tenant: {
          nextInvoiceNumber,
          nextProformaInvoiceNumber,
          paymentInformation,
        },
      },
    }) => {
      this.setState((prevState) => {
        const updatedInvoice = {
          ...prevState.invoice,
          number: invoice.invoiceType === invoiceTypes.INVOICE
            ? nextInvoiceNumber
            : nextProformaInvoiceNumber,
          dueDate: moment().add(paymentInformation.paymentPeriodInDays, 'days').format('YYYY-MM-DD'),
        };

        this.validate(updatedInvoice);

        return ({
          isInvoiceNumberLoading: false,
          invoice: updatedInvoice,
        });
      });
    });
  }

  updateSections = (sections, isUserInput = true) => {
    this.setState(prevState => ({
      isInvoiceDirty: isUserInput,
      invoice: {
        ...prevState.invoice,
        sections,
        total: calculateSectionsTotal(sections),
      },
    }));
  }

  handleGenerateInvoice = () => {
    const { invoice } = this.state;
    const {
      bereavement,
      client,
      generateInvoice,
      editInvoice,
    } = this.props;
    const invoiceData = {
      ...invoice,
      createdAt: invoice.createdAt,
    };

    this.setState({ isInvoiceGenerating: true });

    let mutation = null;
    if (invoice.isNew) {
      mutation = invoice.invoiceType === invoiceTypes.INVOICE
        ? generateInvoiceMutation
        : generateProformaInvoiceMutation;
    } else {
      mutation = editInvoiceMutation;
    }

    client.mutate({
      mutation,
      variables: {
        input: {
          bereavementId: bereavement.id,
          ...generateInvoiceInputTransform(invoice),
        },
      },
    }).then(() => new Promise(resolve => setTimeout(() => resolve(), 5000)))
      .then(() => {
        if (invoice.isNew) {
          delete invoiceData.isNew;
          generateInvoice(bereavement.id, invoiceData);
        } else {
          editInvoice(bereavement.id, invoiceData);
        }

        this.setState({
          hasInvoiceBeenUpdated: !invoice.isNew,
          invoice: invoiceData,
          isInvoiceGenerating: false,
          hasInvoiceGenerated: true,
          isInvoiceDirty: false,
          hasInvoiceFailedToGenerate: false,
        });
      })
      .catch(() => {
        this.setState({
          isInvoiceGenerating: false,
          hasInvoiceGenerated: false,
          hasInvoiceFailedToGenerate: true,
        });
      });
  }

  handleGenerateInvoiceTemplate = () => {
    const { invoice } = this.state;
    const {
      bereavement,
      client,
      createInvoiceTemplate,
      editInvoiceTemplate,
    } = this.props;
    const invoiceData = {
      ...invoice,
      createdAt: invoice.createdAt,
      transferredToXeroAt: invoice.transferredToXeroAt,
    };

    this.setState({ isInvoiceGenerating: true });

    let mutation = null;
    if (invoice.isNew) {
      mutation = invoice.invoiceType === invoiceTypes.INVOICE
        ? generateInvoiceTemplateMutation
        : generateProformaInvoiceTemplateMutation;
    } else {
      mutation = editInvoiceTemplateMutation;
    }

    client.mutate({
      mutation,
      variables: {
        input: {
          bereavementId: bereavement.id,
          ...generateInvoiceTemplateInputTransform(invoice),
        },
      },
    }).then(() => new Promise(resolve => setTimeout(() => resolve(), 5000)))
      .then(() => {
        if (invoice.isNew) {
          delete invoiceData.isNew;
          createInvoiceTemplate(bereavement.id, invoiceData);
        } else {
          editInvoiceTemplate(bereavement.id, invoiceData);
        }

        this.setState({
          hasInvoiceBeenUpdated: !invoice.isNew,
          invoice: invoiceData,
          isInvoiceGenerating: false,
          hasInvoiceGenerated: true,
          isInvoiceDirty: false,
          hasInvoiceFailedToGenerate: false,
        });
      })
      .catch(() => {
        this.setState({
          isInvoiceGenerating: false,
          hasInvoiceGenerated: false,
          hasInvoiceFailedToGenerate: true,
        });
      });
  }

  handleChangeInvoiceDetails = (key, value, isUserInput = true) => {
    const { invoice } = this.state;

    const updatedInvoice = {
      ...invoice,
      [key]: value,
    };

    this.validate(updatedInvoice);

    this.setState(prevState => ({
      isInvoiceDirty: (!isUserInput && prevState.isInvoiceDirty) || isUserInput,
      invoice: {
        ...prevState.invoice,
        [key]: value,
      },
    }));
  }

  handleChangeInvoicePayee = (payeeId, payeeType, isUserInput = true) => {
    const { invoice } = this.state;

    const updatedInvoice = {
      ...invoice,
      payeeId,
      payeeType,
    };

    this.validate(updatedInvoice);

    this.setState(prevState => ({
      isInvoiceDirty: (!isUserInput && prevState.isInvoiceDirty) || isUserInput,
      invoice: {
        ...prevState.invoice,
        payeeId,
        payeeType,
      },
    }));
  }

  handlePayeeFormUpdate = (payee) => {
    const { invoice } = this.state;
    this.validate(invoice, payee);
  }

  setIsValid = (isValid) => {
    const { activeStep } = this.state;

    if (activeStep === 1) {
      this.setState({ isInvoiceDetailsValid: isValid });
    }
  };

  handleAddCustomCharge = ({ amount, ...customCharge }) => {
    const { invoice } = this.state;
    const updatedCustomCharge = {
      ...customCharge,
      id: generateHexId(),
      isSelected: true,
      amount: defaultMoneyObject(amount),
    };
    const sectionTitle = customCharge.isDisbursement
      ? invoiceSectionTitles.DISBURSEMENTS
      : invoiceSectionTitles.PRODUCTS_AND_SERVICES;

    const disbursementSection = invoice.sections.find(section => section.title === sectionTitle);
    if (!disbursementSection) {
      invoice.sections.push({
        title: sectionTitle,
        items: [],
      });
    }

    const newSections = invoice.sections.map((section) => {
      if (section.title === sectionTitle) {
        section.items.push(updatedCustomCharge);
      }
      return section;
    });

    this.setState(prevState => ({
      isInvoiceDirty: true,
      invoice: {
        ...prevState.invoice,
        sections: newSections,
        total: calculateSectionsTotal(newSections),
      },
    }));
  };

  handleChangeActiveStep = (activeStep) => {
    const { invoice } = this.state;
    if (activeStep === 1 && invoice.isNew) {
      this.getNextInvoiceNumbers();
    }
    this.setState({ activeStep });
  };

  handleClose = () => {
    const { onClose } = this.props;
    onClose();
  }

  setIsInvoiceDirty = isInvoiceDirty => this.setState({ isInvoiceDirty });

  render() {
    const {
      bereavement,
      directoryListings,
      errors,
      formRefs,
      isDownloadingInvoice,
      isOpen,
      isSendingToXero,
      tenantHasGivenConsentToAXeroApp,
      onDownloadInvoice,
      onSendInvoiceTemplateToXero,
    } = this.props;
    const {
      activeStep,
      hasInvoiceBeenUpdated,
      hasInvoiceFailedToGenerate,
      hasInvoiceGenerated,
      invoice,
      isInvoiceDetailsValid,
      isInvoiceDirty,
      isInvoiceGenerating,
      isInvoiceNumberLoading,
      nextInvoiceNumber,
    } = this.state;
    const disabledSteps = errors && Object.keys(errors).length ? [2] : [];

    return (
      <InvoiceGenerationModal
        activeStep={activeStep}
        bereavement={bereavement}
        defaultInvoiceNumber={bereavement.account.nextInvoiceNumber}
        directoryListings={directoryListings}
        disabledSteps={disabledSteps}
        errors={errors}
        formRefs={formRefs}
        hasInvoiceBeenUpdated={hasInvoiceBeenUpdated}
        hasInvoiceFailedToGenerate={hasInvoiceFailedToGenerate}
        hasInvoiceGenerated={hasInvoiceGenerated}
        invoice={invoice}
        isDownloadingInvoice={isDownloadingInvoice}
        isInvoiceDetailsValid={isInvoiceDetailsValid}
        isInvoiceDirty={isInvoiceDirty}
        isInvoiceGenerating={isInvoiceGenerating}
        isInvoiceNumberLoading={isInvoiceNumberLoading}
        isOpen={isOpen}
        isSendingToXero={isSendingToXero}
        nextInvoiceNumber={nextInvoiceNumber}
        tenantHasGivenConsentToAXeroApp={tenantHasGivenConsentToAXeroApp}
        onAddCustomCharge={this.handleAddCustomCharge}
        onChangeActiveStep={this.handleChangeActiveStep}
        onChangeInvoiceDetails={this.handleChangeInvoiceDetails}
        onChangeInvoicePayee={this.handleChangeInvoicePayee}
        onClose={this.handleClose}
        onDownloadInvoice={onDownloadInvoice}
        onGenerateInvoice={this.handleGenerateInvoice}
        onGenerateInvoiceTemplate={this.handleGenerateInvoiceTemplate}
        onPayeeFormUpdate={this.handlePayeeFormUpdate}
        onSendInvoiceTemplateToXero={onSendInvoiceTemplateToXero}
        setIsInvoiceDirty={this.setIsInvoiceDirty}
        updateSections={this.updateSections}
      />
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  createInvoiceTemplate: createInvoiceTemplateAction,
  editInvoice: editInvoiceAction,
  editInvoiceTemplate: editInvoiceTemplateAction,
  generateInvoice: generateInvoiceAction,
}, dispatch);

export default withValidation(
  withApollo(
    connect(null, mapDispatchToProps)(InvoiceGenerationModalContainer),
  ),
);
