import React, { useEffect } from 'react';
import i18next from 'i18next';
import classNames from 'classnames';
import { withSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';

import NameFormSection from 'components/common/NameFormSection';
import styles from 'scss/main.scss';
import { Name } from 'types/ts';

import rcStyles from './styles.scss';
import { Props } from './index.types';

const GDPR_SNACKBAR_KEY = 'hasGDPRBeenConfirmed';

const FuneralSafeApplicantForm: React.FC<Props> = ({
  formData, errors, enqueueSnackbar, closeSnackbar, onApplicantChange,
}: Props) => {
  useEffect(() => {
    if (errors.hasGDPRBeenConfirmed) {
      enqueueSnackbar(
        (
          <div className={rcStyles.gdpr_snackbar__message}>
            <div>{errors.hasGDPRBeenConfirmed}</div>
            <div>
              <Button
                onClick={() => closeSnackbar(GDPR_SNACKBAR_KEY)}
                color="secondary"
                variant="outlined"
                data-test-id="dismiss"
              >
                {i18next.t('Dismiss')}
              </Button>
            </div>
          </div>
        ),
        {
          key: GDPR_SNACKBAR_KEY,
          variant: 'error',
          persist: true,
        },
      );
    } else {
      closeSnackbar(GDPR_SNACKBAR_KEY);
    }

    return () => {
      closeSnackbar(GDPR_SNACKBAR_KEY);
    };
  }, [errors.hasGDPRBeenConfirmed]);

  return (
    <div className={rcStyles.v_spacing}>
      <div className={rcStyles.colspan2}>
        <NameFormSection
          name={formData.name}
          customStyles={rcStyles}
          labelGivenName={i18next.t('First name')}
          labelMiddleName={i18next.t('Middle name(s)')}
          labelFamilyName={i18next.t('Family name')}
          onChangeHandler={(name: Name) => onApplicantChange('name', name)}
        />
      </div>
      <div className={rcStyles.colspan2}>
        <TextField
          label={i18next.t('Email')}
          value={formData.email}
          onChange={event => onApplicantChange('email', event.target.value)}
          fullWidth
          inputProps={{ 'data-test-id': 'email' }}
        />
      </div>
      <div className={rcStyles.colspan2}>
        <TextField
          label={i18next.t('Phone number')}
          value={formData.phoneNumber}
          onChange={event => onApplicantChange('phoneNumber', event.target.value)}
          fullWidth
          inputProps={{ 'data-test-id': 'phoneNumber' }}
        />
      </div>
      <div className={rcStyles.colspan2}>
        <TextField
          label={i18next.t('Post code')}
          value={formData.postCode}
          onChange={event => onApplicantChange('postCode', event.target.value)}
          fullWidth
          inputProps={{ 'data-test-id': 'postCode' }}
        />
      </div>
      <div className={rcStyles.colspan2}>
        <div className={classNames(rcStyles.gdpr_check, !!errors?.hasGDPRBeenConfirmed && rcStyles.error)}>
          <FormControlLabel
            classes={{ root: rcStyles.gdpr_check__form_control_label }}
            control={(
              <Checkbox
                value={formData.hasGDPRBeenConfirmed}
                onChange={event => onApplicantChange('hasGDPRBeenConfirmed', event.target.checked)}
                inputProps={{ 'data-test-id': 'hasGDPRBeenConfirmed' }}
                color="primary"
                classes={{ root: styles.u_hard__left }}
              />
            )}
            label={i18next.t('Applicant has confirmed this information can be sent to Funeral Safe to use in their approval process.')}
          />
        </div>
      </div>
    </div>
  );
};

export default withSnackbar(FuneralSafeApplicantForm);
