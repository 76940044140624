import { ContactPreference } from 'constants/ts';
import {
  Address, Money, Name, TelephoneContact,
} from 'types/ts';

export type BereavedPerson = {
  name: Name|null;
  address: Address|null;
  billingAddress: Address|null;
  phones: TelephoneContact[]|null;
  emails: string[]|null;
};

export type BereavedPersonConnection = {
  id: string;
  bereavedPersonId: string;
  relationshipToDeceased?: string|null;
  isPrimaryContact?: boolean|null;
  isBillPayer?: boolean|null;
  isNextOfKin?: boolean|null;
  contactPreferences?: ContactPreference[]|null;
  bereavedPerson?: BereavedPerson|null;
};

export type DeceasedPerson = {
  dateOfBirth: string;
  deathDateTime: string;
  name: Name;
};

export enum InvoiceType {
  INVOICE = 'INVOICE',
  PROFORMA = 'PROFORMA',
}

export type Invoice = {
  id: string;
  dateVoided: string;
  number: string;
  totals: {
    total: Money;
    taxTotal: Money;
    totalWithoutTax: Money;
    totalPaid: Money;
    totalCredit: Money;
    balance: Money;
  };
  invoiceType: InvoiceType;
};

export type Account = {
  invoices: Invoice[];
  proformaInvoices: Invoice[];
  totals: {
    balance: Money;
    totalCredit: Money;
    totalInvoiced: Money;
    totalPaid: Money;
    totalUnInvoiced: Money;
    totalInvoiceTemplatesUninvoiced: Money;
    totalInvoiceTemplatesInvoiced: Money;
  }
};

export type Bereavement = {
  id: string;
  account: Account;
  bereavedPeopleConnections: BereavedPersonConnection[];
  deceasedPeople: DeceasedPerson[];
};
