
import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const IconCare = (props) => {
  const { className } = props;
  const classes = className ? `${className} ${styles.icon}` : styles.icon;

  return (
    <svg viewBox="0 0 24 24" className={classes}>
      <path d="M12.2,5.3C13,4.6,13.9,4.2,15.1,4c2.5-0.2,5,1.5,5.2,4.2c0.2,1.7-0.2,3-1.5,4.4 c-1.9,1.9-3.8,3.8-5.6,5.7c-0.6,0.8-1.3,0.8-2.1,0c-1.9-1.9-3.8-3.8-5.6-5.7c-1.5-1.7-1.9-4-1-5.9C5.9,4,9.1,3.3,11.6,5 C11.8,5.2,12,5.2,12.2,5.3z" />
    </svg>
  );
};

IconCare.propTypes = {
  className: PropTypes.string,
};

export default IconCare;
