import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import DocumentsModal from './DocumentsModal';
import { validationSchema } from './validation';

class DocumentsModalContainer extends Component {
  static propTypes = {
    documentsList: PropTypes.arrayOf(PropTypes.shape({})),
    disabled: PropTypes.bool.isRequired,
    validate: PropTypes.func.isRequired,
    isValid: PropTypes.bool,
    errors: PropTypes.objectOf(PropTypes.any),
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.isOpen && !nextProps.isOpen) {
      return {
        documentsList: nextProps.documentsList || [],
      };
    }

    return null;
  }

  static defaultProps = {
    documentsList: [],
    isValid: true,
  }

  constructor(props) {
    super(props);

    const { documentsList } = this.props;

    this.state = {
      documentsList: documentsList || [],
    };
  }

  componentDidMount() {
    const { documentsList } = this.state;
    const { validate } = this.props;

    validate(documentsList, validationSchema);
  }

  handleUploadComplete = ([media], file) => {
    const { documentsList } = this.state;

    const newDocument = {
      id: null,
      title: file?.[0]?.name,
      mediaId: media?.id,
      media,
    };

    this.setState({
      documentsList: [
        ...documentsList,
        newDocument,
      ],
    });
  }

  handleChange = (value) => {
    if (!value && !value.length) {
      return;
    }

    const { validate } = this.props;

    const documentsList = value.map(document => ({
      id: document.id || null,
      title: document.title,
      mediaId: document.media.id,
      media: document.media,
    }));

    validate(documentsList, validationSchema);

    this.setState({ documentsList });
  }

  handleClose = () => {
    const { documentsList, onClose } = this.props;
    this.setState({ documentsList });
    onClose();
  }

  handleSave = () => {
    const { onSave, onClose, isValid } = this.props;
    const { documentsList } = this.state;

    this.setState({
      isValidationEnabled: true,
    });

    if (!isValid) {
      return;
    }

    onSave(documentsList);
    onClose();
  }

  render() {
    const {
      disabled,
      errors,
      isOpen,
    } = this.props;

    const { documentsList, isValidationEnabled } = this.state;

    return (
      <DocumentsModal
        isOpen={isOpen}
        documentsList={documentsList || []}
        disabled={disabled}
        onSave={this.handleSave}
        onChange={this.handleChange}
        onClose={this.handleClose}
        onUploadComplete={this.handleUploadComplete}
        errors={isValidationEnabled && errors}
      />
    );
  }
}

export default withValidation(DocumentsModalContainer);
