import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import PropTypes from 'prop-types';
import { bereavementType, arrangementType } from 'types/bereavement';
import { apolloClientType } from 'types/apollo';
import { caseStatuses } from 'constants/bereavement';
import ArrangementCoffinsForm from './ArrangementCoffinsForm';
import { validationSchema } from './validation';
import { editArrangementCoffinInformation } from './mutations.gql';

class ArrangementCoffinsFormContainer extends Component {
  static propTypes = {
    bereavement: bereavementType.isRequired,
    arrangement: arrangementType.isRequired,
    client: apolloClientType.isRequired,
    onSave: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      isValidationEnabled: false,
      categoryInformation: {},
    };
  }

  componentDidMount() {
    const { arrangement } = this.props;
    const categoryInformation = arrangement.categoryInformation
      && arrangement.categoryInformation.coffin
      ? arrangement.categoryInformation.coffin
      : {};
    this.setState({ categoryInformation });
  }

  handleSave = () => {
    const {
      client,
      bereavement,
      arrangement,
      onSave,
      validate,
    } = this.props;
    const { categoryInformation } = this.state;

    this.setState({ isValidationEnabled: true });
    const isValid = validate(categoryInformation, validationSchema, true);

    if (isValid) {
      this.setState({ isSaving: true });
      onSave(bereavement.id, arrangement.id, 'coffin', categoryInformation);

      const input = {
        bereavementId: bereavement.id,
        arrangementId: arrangement.id,
        ...categoryInformation,
      };

      client.mutate({
        mutation: editArrangementCoffinInformation,
        variables: { input },
      }).finally(() => this.setState({ isSaving: false }));
    }
  }

  handleChange = (key, value) => {
    const { validate } = this.props;
    const { categoryInformation, isValidationEnabled } = this.state;

    const updatedCategoryInformation = {
      ...categoryInformation,
      [key]: value,
    };

    if (isValidationEnabled) {
      validate(updatedCategoryInformation, validationSchema);
    }

    this.setState({ categoryInformation: updatedCategoryInformation });
  }

  render() {
    const { bereavement, errors } = this.props;
    const { isSaving, categoryInformation } = this.state;

    return (
      <ArrangementCoffinsForm
        categoryInformation={categoryInformation}
        disabled={isSaving || bereavement.caseStatus === caseStatuses.CLOSED}
        isSaving={isSaving}
        onChange={this.handleChange}
        onSave={this.handleSave}
        errors={errors}
      />
    );
  }
}

export default withValidation(withApollo((ArrangementCoffinsFormContainer)));
