import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import AddressFormSection from 'components/common/AddressFormSection';
import AutoCompleteTextField from 'components/common/AutoCompleteTextField';
import DateTimePicker from 'components/common/DateTimePicker';
import { handlingOfAshesTypes } from 'constants/service';
import IconLoad from 'icons/IconLoad';
import styles from 'scss/main.scss';
import { getSuggestedArrangementVenueDates } from 'services/utils/arrangement';
import { arrangementType } from 'types/bereavement';

import rcStyles from './styles.scss';

const ArrangementCrematoriumForm = ({
  arrangement,
  categoryInformation,
  disabled,
  isSaving,
  onChange,
  onNumericChange,
  onSave,
  formRefs,
  errors,
}) => {
  const { startDateTime, endDateTime } = categoryInformation;
  return (
    <div className={rcStyles.v_spacing}>
      <div ref={formRefs.startDateTime}>
        <DateTimePicker
          value={startDateTime || null}
          label={t('Start date & time')}
          placeholder={t('Start date & time')}
          isStartDate
          maxDate={endDateTime}
          timeRange={[startDateTime, endDateTime]}
          getSuggestedDates={() => getSuggestedArrangementVenueDates(arrangement)}
          onChange={date => onChange('startDateTime', date)}
          onClearEndDate={() => onChange('endDateTime', null)}
          isClearable
          disabled={disabled}
          errors={errors && !!errors.startDateTime}
          helperText={errors && errors.startDateTime}
          data-test-id="startDateTime"
        />
      </div>
      <div ref={formRefs.endDateTime}>
        <DateTimePicker
          value={endDateTime || null}
          label={t('End date & time')}
          placeholder={t('End date & time')}
          isEndDate
          minDate={startDateTime}
          timeRange={[startDateTime, endDateTime]}
          onChange={date => onChange('endDateTime', date)}
          isClearable
          disabled={disabled}
          errors={errors && !!errors.endDateTime}
          helperText={errors && errors.endDateTime}
          data-test-id="endDateTime"
        />
      </div>
      <div
        ref={formRefs.funeralHomeBearerQuantity}
        className={rcStyles.col1}
      >
        <TextField
          type="number"
          label={t('Bearers supplied by us')}
          value={categoryInformation.funeralHomeBearerQuantity || ''}
          onChange={event => onNumericChange('funeralHomeBearerQuantity', event.target.value)}
          disabled={disabled}
          fullWidth
          error={errors && errors.funeralHomeBearerQuantity}
          helperText={errors && errors.funeralHomeBearerQuantity}
          inputProps={{
            'data-test-id': 'funeralHomeBearerQuantity',
            'min': 0,
          }}
        />
      </div>
      <div ref={formRefs.familyBearerQuantity}>
        <TextField
          type="number"
          label={t('Bearers supplied by family')}
          value={categoryInformation.familyBearerQuantity || ''}
          onChange={event => onNumericChange('familyBearerQuantity', event.target.value)}
          disabled={disabled}
          fullWidth
          inputProps={{
            'data-test-id': 'familyBearerQuantity',
            'min': 0,
          }}
          error={errors && errors.familyBearerQuantity}
          helperText={errors && errors.familyBearerQuantity}
        />
      </div>
      <div
        className={rcStyles.col1}
        ref={formRefs.reservedSeatingQuantity}
      >
        <TextField
          type="number"
          label={t('Reserved seating required')}
          value={categoryInformation.reservedSeatingQuantity || ''}
          onChange={event => onNumericChange('reservedSeatingQuantity', event.target.value)}
          inputProps={{
            'data-test-id': 'reservedSeatingRequired',
            'min': 0,
          }}
          disabled={disabled}
          fullWidth
          error={errors && errors.reservedSeatingQuantity}
          helperText={errors && errors.reservedSeatingQuantity}
        />
      </div>
      <div ref={formRefs.handlingOfAshes}>
        <AutoCompleteTextField
          placeholder={t('Handling of ashes')}
          value={categoryInformation.handlingOfAshes || ''}
          suggestions={handlingOfAshesTypes}
          onChange={handlingOfAshes => onChange('handlingOfAshes', handlingOfAshes)}
          disabled={disabled}
          data-test-id="handlingOfAshes"
        />
      </div>
      <div ref={formRefs.cremationReference}>
        <TextField
          label={t('Cremation reference')}
          value={categoryInformation.cremationReference || ''}
          onChange={event => onNumericChange('cremationReference', event.target.value)}
          inputProps={{
            'data-test-id': 'cremationReference',
          }}
          disabled={disabled}
          fullWidth
          error={errors && errors.cremationReference}
          helperText={errors && errors.cremationReference}
        />
      </div>
      <div
        ref={formRefs.alternativeLocation}
        className={classNames(
          rcStyles.col1,
          rcStyles.colspan2,
        )}
      >
        <AddressFormSection
          address={categoryInformation.alternativeLocation}
          onChange={address => onChange('alternativeLocation', address)}
          data-test-id="otherDeliveryLocation"
          lookupLabel={t('Alternate location')}
          errors={errors.alternativeLocation}
          disabled={disabled}
        />
      </div>
      <div className={rcStyles.checklist}>
        <div className={rcStyles.checklist__layout}>
          <div ref={formRefs.isTrolley}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={categoryInformation.isTrolley || false}
                  onChange={event => onChange('isTrolley', event.target.checked)}
                  inputProps={{ 'data-test-id': 'isTrolley' }}
                  disabled={disabled}
                  color="primary"
                />
              )}
              label={t('Bier')}
            />
          </div>
          <div ref={formRefs.isWitnessCommittal}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={categoryInformation.isWitnessCommittal || false}
                  onChange={event => onChange('isWitnessCommittal', event.target.checked)}
                  inputProps={{ 'data-test-id': 'isWitnessCommittal' }}
                  disabled={disabled}
                  color="primary"
                />
              )}
              label={t('Witness committal')}
            />
          </div>
          <div ref={formRefs.isCurtainsOpen}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={categoryInformation.isCurtainsOpen || false}
                  onChange={event => onChange('isCurtainsOpen', event.target.checked)}
                  inputProps={{ 'data-test-id': 'isCurtainsOpen' }}
                  disabled={disabled}
                  color="primary"
                />
              )}
              label={t('Coffin to remain in view')}
            />
          </div>
          <div ref={formRefs.isFamilyWalking}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={categoryInformation.isFamilyWalking || false}
                  onChange={event => onChange('isFamilyWalking', event.target.checked)}
                  inputProps={{ 'data-test-id': 'isFamilyWalking' }}
                  disabled={disabled}
                  color="primary"
                />
              )}
              label={t('Family walking')}
            />
          </div>
          <div ref={formRefs.isUnattendedCremation}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={categoryInformation.isUnattendedCremation || false}
                  onChange={event => onChange('isUnattendedCremation', event.target.checked)}
                  inputProps={{ 'data-test-id': 'isUnattendedCremation' }}
                  disabled={disabled}
                  color="primary"
                />
              )}
              label={t('Unattended cremation')}
            />
          </div>
        </div>
      </div>
      <div
        ref={formRefs.isConfirmed}
        className={classNames(
          styles.c_confirmed__checkbox,
          rcStyles.col1,
          rcStyles.colspan2,
        )}
      >
        <FormControlLabel
          control={(
            <Checkbox
              checked={categoryInformation.isConfirmed || false}
              onChange={event => onChange('isConfirmed', event.target.checked)}
              inputProps={{ 'data-test-id': 'isConfirmed' }}
              disabled={disabled}
              color="primary"
            />
          )}
          label={t('Confirm venue availability')}
        />
      </div>
      <span className={rcStyles.col1}>
        <Button
          onClick={onSave}
          color="primary"
          variant="contained"
          disabled={disabled}
          data-test-id="saveCrematoriumCategory"
        >
          {t('Save')}
        </Button>
      </span>
      {isSaving && (
        <div className={styles.o_fb__whole}>
          <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
            <IconLoad title={t('Saving')} />
          </div>
        </div>
      )}
    </div>
  );
};

ArrangementCrematoriumForm.propTypes = {
  arrangement: arrangementType.isRequired,
  categoryInformation: PropTypes.objectOf(PropTypes.any).isRequired,
  disabled: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onNumericChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ArrangementCrematoriumForm;
