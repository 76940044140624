import { generateHexId } from 'services/utils';

export const enqueueSnackbarAction = notification => ({
  type: 'ENQUEUE_SNACKBAR',
  notification: {
    key: generateHexId(),
    ...notification,
  },
});

export const removeSnackbarAction = key => ({
  type: 'REMOVE_SNACKBAR',
  key,
});
