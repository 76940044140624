import React from 'react';
import i18next from 'i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import styles from 'scss/main.scss';

import rcStyles from './styles.scss';
import { Props } from './index.types';

const AdminCard: React.FC<Props> = ({
  link,
  icon: CardIcon,
  title,
  text,
  testId,
  isBeta,
}: Props) => (
  <Link
    to={link}
    color="primary"
    className={rcStyles.link}
  >
    <div
      className={classNames(
        isBeta ? rcStyles.rc_is_beta : null,
      )}
      data-test-id={testId}
    >
      <div className={rcStyles.icon}>
        {CardIcon && <CardIcon />}
      </div>
      <div className={rcStyles.main}>
        <div className={styles.long_primer}>
          {i18next.t(title)}
        </div>
        <div className={styles.primer}>
          {text && i18next.t(text)}
        </div>
      </div>
    </div>
  </Link>
);

export default AdminCard;
