import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createAshesRecordAction, editAshesRecordAction } from 'actions/bereavement';
import AshesRecordModal from 'components/bereavement/AshesRecordModal';
import { emptyAshesRecord } from 'constants/bereavement';
import { bereavementType, ashesRecordType } from 'types/bereavement';

import CareRecordAshesSection from './CareRecordAshesSection';

export class CareRecordAshesSectionContainer extends Component {
  static propTypes = {
    bereavement: bereavementType.isRequired,
    deceasedPersonId: PropTypes.string.isRequired,
    ashesRecords: PropTypes.arrayOf(ashesRecordType),
    relatedData: PropTypes.objectOf(PropTypes.any),
    disabled: PropTypes.bool,
    createAshesRecord: PropTypes.func.isRequired,
    editAshesRecord: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      ashesRecordBeingModified: null,
      isReleaseFormModalOpen: false,
    };
  }

  handleReleaseFormChange = (isReleaseFormModalOpen) => {
    this.setState({ isReleaseFormModalOpen });
  }

  handleAddAshesRecord = () => {
    const {
      relatedData: {
        personToReceiveAshes: {
          id: personToReceiveAshes,
        },
      },
    } = this.props;

    const newAshesRecord = {
      ...emptyAshesRecord,
      personToReceiveAshes,
    };
    this.setState({ ashesRecordBeingModified: newAshesRecord });
  }

  handleEditAshesRecord = (ashesRecord) => {
    this.setState({ ashesRecordBeingModified: ashesRecord });
  }

  handleAshesRecordModalClose = () => {
    this.setState({ ashesRecordBeingModified: null });
  }

  handleAshesRecordSave = (ashesRecord) => {
    const {
      bereavement: { id: bereavementId },
      deceasedPersonId,
      createAshesRecord,
      editAshesRecord,
    } = this.props;

    const isNewAshesRecord = !ashesRecord.id;

    if (isNewAshesRecord) {
      createAshesRecord(bereavementId, deceasedPersonId, ashesRecord);
    } else {
      editAshesRecord(bereavementId, deceasedPersonId, ashesRecord);
    }

    this.setState({ ashesRecordBeingModified: null });
  }

  render() {
    const {
      bereavement, ashesRecords, relatedData, disabled,
    } = this.props;
    const { bereavedPeopleConnections } = bereavement;
    const { ashesRecordBeingModified, isReleaseFormModalOpen } = this.state;

    return (
      <Fragment>
        <CareRecordAshesSection
          bereavementId={bereavement.id}
          deceasedPersonId={bereavement.deceasedPeople[0].id}
          ashesRecords={ashesRecords}
          relatedData={relatedData}
          bereavedPeopleConnections={bereavedPeopleConnections}
          isReleaseFormModalOpen={isReleaseFormModalOpen}
          onAdd={this.handleAddAshesRecord}
          onEdit={this.handleEditAshesRecord}
          onReleaseFormOpen={() => this.handleReleaseFormChange(true)}
          onReleaseFormClose={() => this.handleReleaseFormChange(false)}
          disabled={disabled}
        />
        <AshesRecordModal
          ashesRecordBeingModified={ashesRecordBeingModified}
          relatedData={relatedData}
          bereavement={bereavement}
          isOpen={!!ashesRecordBeingModified}
          onClose={this.handleAshesRecordModalClose}
          onSave={this.handleAshesRecordSave}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  createAshesRecord: createAshesRecordAction,
  editAshesRecord: editAshesRecordAction,
}, dispatch);

export default connect(null, mapDispatchToProps)(CareRecordAshesSectionContainer);
