import React, { Component } from 'react';

import { getFloatOrNull } from 'services/utils';
import {
  getDisplayUnitMeasurements,
  getUnitMeasurement,
  getMeasurementConfigInput,
} from 'services/utils/measurement';
import { MeasurementInputConfig } from 'types/ts/measurement';

import { Props, State } from './index.types';
import MeasurementField from './MeasurementField';

class MeasurementFieldContainer extends Component<Props, State> {
  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const { value, units } = nextProps;
    if (value !== prevState.lastMeasurement
      || units !== prevState.lastDisplayUnit) {
      const measurementValue: number|null = getFloatOrNull(value);
      const measurementValues = getDisplayUnitMeasurements(measurementValue, units);
      return {
        measurementValues,
        lastDisplayUnit: units,
        lastMeasurement: measurementValue,
      };
    }
    return null;
  }

  constructor(props: Props) {
    super(props);

    const { value = 0, units } = props;
    const measurementValue = getFloatOrNull(value);
    const measurementValues = getDisplayUnitMeasurements(measurementValue, units);
    this.state = {
      lastDisplayUnit: units,
      lastMeasurement: measurementValue,
      measurementValues,
    };
  }

  handleChange = (key: string, value: string) => {
    const { units, onChange } = this.props;
    const { measurementValues } = this.state;

    const updatedMeasurementValues = measurementValues.map((measurementValue) => {
      const inputConfig: MeasurementInputConfig|undefined = getMeasurementConfigInput(
        units, measurementValue.unit,
      );

      if (key === measurementValue.unit) {
        const { onChangeValue } = inputConfig || {};
        let updatedValue: number|null = null;

        if (onChangeValue && value.length) {
          updatedValue = onChangeValue(value);
        }

        return {
          ...measurementValue,
          value: updatedValue,
        };
      }
      return measurementValue;
    });

    const measurement = getUnitMeasurement(updatedMeasurementValues, units);
    this.setState({
      lastMeasurement: measurement,
      measurementValues: updatedMeasurementValues,
    });

    onChange(measurement);
  };

  render() {
    const {
      units, className, label, disabled, 'data-test-id': testId,
    } = this.props;
    const { measurementValues } = this.state;

    return (
      <MeasurementField
        className={className}
        label={label}
        measurementValues={measurementValues}
        units={units}
        disabled={disabled}
        onChange={this.handleChange}
        data-test-id={testId}
      />
    );
  }
}

export default MeasurementFieldContainer;
