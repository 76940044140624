import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import MoreVerticalIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';

import AddressFormSection from 'components/common/AddressFormSection';
import ContactCard from 'components/common/ContactCard';
import LookUp from 'components/common/lookups/LookUp';
import PhoneFormSection from 'components/common/PhoneFormSection';
import DirectoryListingContactDetails from 'components/directoryListings/DirectoryListingContactDetails';
import styles from 'scss/main.scss';

import rcStyles from './styles.scss';

const ArrangementOfficiantDirectoryListingSection = ({
  customDetails,
  disabled,
  errors,
  hasDirectoryListingWritePermission,
  isLoading,
  lookUpValue,
  selectedDirectoryListing,
  suggestions,
  onAddCustomDetails,
  onChangeCustomDetails,
  onChangeDirectoryListing,
  onCreateDirectoryListing,
  onEditDirectoryListing,
  onLookUpChange,
  onRemoveCustomDetails,
  onRemoveDirectoryListing,
}) => {
  const [anchorElement, setAnchorElement] = useState(null);
  const loadingIcon = isLoading ? (
    <InputAdornment position="end" className={styles.o_icon__primary}>
      <img src="/img/loading_field.gif" alt="Loading" className={styles.image} data-test-id="ellipsisLoader" />
    </InputAdornment>
  ) : null;

  return (
    <div className={rcStyles.directory_listing}>
      {!customDetails && !selectedDirectoryListing && (
        <LookUp
          label={t('Add officiant details')}
          lookUpValue={lookUpValue}
          suggestions={suggestions}
          onChange={onLookUpChange}
          onSelect={onChangeDirectoryListing}
          onSelectNew={onAddCustomDetails}
          selectNewText={t('Add custom details')}
          startComponent={(
            <InputAdornment position="start">
              <SearchIcon className={rcStyles.directory_listing__icon} />
            </InputAdornment>
          )}
          endComponent={loadingIcon}
          disabled={disabled}
          fullWidth
          data-test-id="directoryListingLookup"
        />
      )}
      {selectedDirectoryListing && (
        <div className={rcStyles.directory_listing__container}>
          <div className={rcStyles.directory_listing__card}>
            <ContactCard
              name={selectedDirectoryListing?.name}
              details={(
                <DirectoryListingContactDetails directoryListing={selectedDirectoryListing} />
              )}
            />
          </div>
          <div className={rcStyles.directory_listing__actions}>
            <IconButton
              aria-controls="officiant-directory-listing-more"
              aria-haspopup="true"
              data-test-id="officiantDirectoryListingMore"
              onClick={event => setAnchorElement(event.currentTarget)}
            >
              <MoreVerticalIcon />
            </IconButton>
            <Menu
              anchorEl={anchorElement}
              keepMounted
              open={!!anchorElement}
              onClose={() => setAnchorElement(null)}
            >
              <MenuItem
                data-test-id="removeDirectoryListing"
                onClick={() => {
                  onRemoveDirectoryListing();
                  setAnchorElement(null);
                }}
              >
                {t('Remove')}
              </MenuItem>
              {hasDirectoryListingWritePermission && (
                <MenuItem
                  data-test-id="editDirectoryListing"
                  onClick={() => {
                    onEditDirectoryListing();
                    setAnchorElement(null);
                  }}
                >
                  {t('Edit Directory Listing')}
                </MenuItem>
              )}
            </Menu>
          </div>
        </div>
      )}
      {customDetails && (
        <div className={rcStyles.custom_details}>
          <div className={rcStyles.custom_details__form}>
            <div className={rcStyles.custom_details__name}>
              <TextField
                label={t('Name')}
                value={customDetails.name || ''}
                onChange={event => onChangeCustomDetails('name', event.target.value)}
                inputProps={{
                  'data-test-id': 'customDetailsName',
                }}
                error={!!errors?.name}
                helperText={errors?.name}
                fullWidth
              />
            </div>
            <div className={rcStyles.custom_details__address}>
              <AddressFormSection
                address={customDetails.address}
                errors={errors?.address}
                onChange={address => onChangeCustomDetails('address', address)}
                data-test-id="customDetailsAddressFormSection"
                disabled={disabled}
              />
            </div>
            <div className={rcStyles.custom_details__phone}>
              <PhoneFormSection
                fieldData={customDetails.phone}
                errors={errors?.phone}
                onChange={phone => onChangeCustomDetails('phone', phone)}
                data-test-id="customDetailsPhoneFormSection"
              />
            </div>
            <div className={rcStyles.custom_details__email}>
              <TextField
                label={t('Email')}
                value={customDetails.email || ''}
                onChange={event => onChangeCustomDetails('email', event.target.value)}
                inputProps={{
                  'data-test-id': 'customDetailsEmail',
                }}
                fullWidth
              />
            </div>
          </div>
          <div className={rcStyles.custom_details__actions}>
            <IconButton
              aria-controls="officiant-directory-listing-more"
              aria-haspopup="true"
              data-test-id="officiantDirectoryListingMore"
              onClick={event => setAnchorElement(event.currentTarget)}
            >
              <MoreVerticalIcon />
            </IconButton>
            <Menu
              anchorEl={anchorElement}
              keepMounted
              open={!!anchorElement}
              onClose={() => setAnchorElement(null)}
            >
              <MenuItem
                data-test-id="removeDirectoryListing"
                onClick={() => {
                  onRemoveCustomDetails();
                  setAnchorElement(null);
                }}
              >
                {t('Remove')}
              </MenuItem>
              {hasDirectoryListingWritePermission && (
                <MenuItem
                  data-test-id="createDirectoryListing"
                  onClick={() => {
                    onCreateDirectoryListing();
                    setAnchorElement(null);
                  }}
                >
                  {t('Create Directory Listing')}
                </MenuItem>
              )}
            </Menu>
          </div>
        </div>
      )}
    </div>
  );
};

ArrangementOfficiantDirectoryListingSection.propTypes = {
  customDetails: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool.isRequired,
  errors: PropTypes.objectOf(PropTypes.any),
  hasDirectoryListingWritePermission: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  lookUpValue: PropTypes.string.isRequired,
  selectedDirectoryListing: PropTypes.objectOf(PropTypes.any),
  suggestions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  onAddCustomDetails: PropTypes.func.isRequired,
  onChangeCustomDetails: PropTypes.func.isRequired,
  onChangeDirectoryListing: PropTypes.func.isRequired,
  onCreateDirectoryListing: PropTypes.func.isRequired,
  onEditDirectoryListing: PropTypes.func.isRequired,
  onLookUpChange: PropTypes.func.isRequired,
  onRemoveCustomDetails: PropTypes.func.isRequired,
  onRemoveDirectoryListing: PropTypes.func.isRequired,
};

export default ArrangementOfficiantDirectoryListingSection;
