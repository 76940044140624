import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { invoiceSectionItemType } from 'types/account';

import InvoiceSectionItem from './InvoiceSectionItem';

class InvoiceSectionItemContainer extends Component {
  static propTypes = {
    item: invoiceSectionItemType.isRequired,
    index: PropTypes.number.isRequired,
    sectionName: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onEditSectionItem: PropTypes.func.isRequired,
    onSelectItem: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      itemMenuAnchorElement: null,
      isOverrideItem: !!props.item.overridePrice || !!props.item.overrideTitle,
    };
  }

  handleEditSectionItem = (key, value) => {
    const {
      item,
      index,
      sectionName,
      onEditSectionItem,
    } = this.props;
    const updatedItem = {
      ...item,
      [key]: value,
    };
    onEditSectionItem(sectionName, updatedItem, index);
  }

  toggleOverrideItem = () => {
    const {
      item,
      index,
      sectionName,
      onEditSectionItem,
    } = this.props;
    const { isOverrideItem } = this.state;
    let updatedItem;

    if (isOverrideItem) {
      const { overrideTitle, overridePrice, ...originalItemProps } = item;
      updatedItem = { ...originalItemProps };
    } else {
      updatedItem = {
        ...item,
        overrideTitle: item.title,
      };
    }

    onEditSectionItem(sectionName, updatedItem, index);
    this.setState({ isOverrideItem: !isOverrideItem });
    this.handleMenuClose();
  }

  handleMenuOpen = (event) => {
    this.setState({ itemMenuAnchorElement: event.currentTarget });
  }

  handleMenuClose = () => {
    this.setState({ itemMenuAnchorElement: null });
  }

  render() {
    const {
      item,
      index,
      sectionName,
      disabled,
      onSelectItem,
    } = this.props;
    const { itemMenuAnchorElement, isOverrideItem } = this.state;

    return (
      <InvoiceSectionItem
        item={item}
        index={index}
        sectionName={sectionName}
        disabled={disabled}
        isOverrideItem={isOverrideItem}
        itemMenuAnchorElement={itemMenuAnchorElement}
        onEditSectionItem={this.handleEditSectionItem}
        onSelectItem={onSelectItem}
        onMenuOpen={this.handleMenuOpen}
        onMenuClose={this.handleMenuClose}
        toggleOverrideItem={this.toggleOverrideItem}
      />
    );
  }
}


export default InvoiceSectionItemContainer;
