import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVerticalIcon from '@material-ui/icons/MoreVert';
import { Link } from 'react-router-dom';
import {
  buildAddressString,
  buildPhonesString,
  buildEmailsString,
  buildWebsitesString,
} from 'services/utils';
import { statuses } from 'constants/directoryListing';
import { directoryListingType } from 'types/directoryListing';
import { organisationalUnitType } from 'types/organisationalUnit';
import { matchType } from 'types/reactRouter';

import rcStyles from './styles.scss';

const AdminListItem = ({
  item,
  match,
  anchorElement,
  onMenuOpen,
  onMenuClose,
  onStatusChange,
}) => (
  <div className={rcStyles.list_item}>
    <div>
      <h3 className={rcStyles.is_title}>{item.name}</h3>
      {item.address && (
        <p className={rcStyles.is_subtext}>
          {buildAddressString(item.address)}
        </p>
      )}
      {item.phones && item.phones.length > 0 && (
        <p className={rcStyles.is_subtext}>
          {buildPhonesString(item.phones)}
        </p>
      )}
      {item.emails && item.emails.length > 0 && (
        <p className={rcStyles.is_subtext}>
          {buildEmailsString(item.emails)}
        </p>
      )}
      {item.websites && item.websites.length > 0 && (
        <p className={rcStyles.is_subtext}>
          {buildWebsitesString(item.websites)}
        </p>
      )}
    </div>
    <div>
      <IconButton
        aria-controls="edit-menu"
        aria-haspopup="true"
        data-test-id="moreOptions"
        onClick={onMenuOpen}
      >
        <MoreVerticalIcon />
      </IconButton>
      <Menu
        anchorEl={anchorElement}
        keepMounted
        open={!!anchorElement}
        onClose={onMenuClose}
      >
        <Link to={`${match.url}/${item.id}`}>
          <MenuItem data-test-id="edit">
            {t('Edit')}
          </MenuItem>
        </Link>
        {item.status && (
          <MenuItem
            onClick={() => onStatusChange(item)}
            data-test-id="retireOrPublish"
          >
            {item.status === statuses.PUBLISHED
              ? t('Retire')
              : t('Publish')}
          </MenuItem>
        )}
      </Menu>
    </div>
  </div>
);

AdminListItem.propTypes = {
  item: PropTypes.oneOfType([organisationalUnitType, directoryListingType]).isRequired,
  match: matchType.isRequired,
  anchorElement: PropTypes.objectOf(PropTypes.any),
  onMenuOpen: PropTypes.func.isRequired,
  onMenuClose: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func,
};

export default AdminListItem;
