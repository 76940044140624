import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SelectView from './Select';

class Select extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    errorText: PropTypes.string,
    disabled: PropTypes.bool,
  }

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  shouldComponentUpdate = (nextProps) => {
    const {
      value, children, errorText, disabled,
    } = this.props;
    return nextProps.value !== value
      || nextProps.children !== children
      || nextProps.errorText !== errorText
      || nextProps.disabled !== disabled;
  }

  render() {
    return (<SelectView {...this.props} />);
  }
}

export default Select;
