import { variantStatuses } from 'constants/catalogue';

export const blankCatalogueItemVariant = {
  id: '',
  isDefault: false,
  isGuidePrice: false,
  name: '',
  prices: {
    cost: { amount: 0, currency: 'GBP' },
    retail: { amount: 0, currency: 'GBP' },
    sale: { amount: 0, currency: 'GBP' },
  },
  status: variantStatuses.ACTIVE,
};

export const statusActions = {
  PUBLISH: 'PUBLISH',
  RETIRE: 'RETIRE',
  DELETE: 'DELETE',
};

export const blankCatalogueItem = {
  title: '',
  category: '',
  subtitle: '',
  description: '',
  reference: '',
  variants: [
    {
      name: 'Default',
      isGuidePrice: true,
      isDefault: true,
      status: variantStatuses.ACTIVE,
      prices: {
        sale: {
          amount: 0,
          currency: 'GBP',
        },
        retail: {
          amount: 0,
          currency: 'GBP',
        },
        cost: {
          amount: 0,
          currency: 'GBP',
        },
      },
    },
  ],
  salesTaxBand: 'ZERO',
  tags: [],
  displayAsDisbursementToBereaved: false,
  supplierId: null,
  imageIds: null,
  images: [],
  note: '',
  organisationalUnitIds: [],
};

export const blankPackageItem = {
  id: '',
  timeCreated: '',
  name: '',
  price: {
    amount: null,
    currency: 'GBP',
  },
  nominalCode: null,
  salesTaxBand: 'FULL',
  isFixed: false,
  description: '',
  productIds: [],
  serviceIds: [],
  productCategoryMaxQuantities: [],
  serviceCategoryMaxQuantities: [],
  status: '',
  products: [],
  services: [],
};
