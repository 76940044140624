import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import { debounce } from 'debounce';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { addAddressToListAction } from 'actions/user';
import { buildAddressString, removePropertyByName, isAddressEmpty } from 'services/utils';
import { getBereavementAddresses } from 'services/utils/bereavement';
import { addressType } from 'types/common';
import { apolloClientType } from 'types/apollo';
import { BereavementConsumer } from 'contexts/bereavement';
import AddressFormSection from './AddressFormSection';
import { getAddresses, getAddress } from './queries.gql';

class AddressFormSectionContainer extends Component {
  debounceChange = debounce((value) => {
    if (value) {
      this.getSuggestions(value);
    } else {
      this.setState({ suggestions: [] });
    }
  }, 500);

  static propTypes = {
    errors: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    client: apolloClientType.isRequired,
    query: PropTypes.string,
    lookupLabel: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    margin: PropTypes.bool,
    address: addressType,
    addressesUsed: PropTypes.arrayOf(addressType).isRequired,
    addAddressToList: PropTypes.func.isRequired,
  }

  static defaultProps = {
    margin: true,
  }

  static getDerivedStateFromProps(props, state) {
    const { address, isLookUpTouched } = state;
    const isPrePopulatingAddress = !isLookUpTouched
      && isAddressEmpty(address)
      && !isAddressEmpty(props.address);

    if (isPrePopulatingAddress
      || (address !== props.address && !isLookUpTouched)) {
      return {
        address: props.address,
        lookUpValue: buildAddressString(props.address),
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      editingAddress: null,
      lookUpValue: '',
      suggestions: [],
      isLookUpTouched: false,
      isLoading: false,
    };

    this.lookUpRef = React.createRef();
    this.addressRef = React.createRef();
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    const {
      address,
      disabled,
      errors,
      addressesUsed,
    } = this.props;
    const {
      suggestions,
      editingAddress,
      lookUpValue,
      isModalOpen,
      isLoading,
    } = this.state;

    if (
      nextProps.disabled !== disabled
      || nextProps.address !== address
      || nextProps.errors !== errors
      || nextProps.addressesUsed !== addressesUsed
      || nextState.suggestions !== suggestions
      || nextState.editingAddress !== editingAddress
      || nextState.lookUpValue !== lookUpValue
      || nextState.isModalOpen !== isModalOpen
      || nextState.isLoading !== isLoading
    ) {
      return true;
    }

    return false;
  }

  getSuggestions = (searchText) => {
    const { client, query } = this.props;

    client.query({
      query: query || getAddresses,
      variables: {
        term: searchText,
        context: 'GB',
        limit: 10,
      },
    }).then((data) => {
      const suggestions = data.data.addressBestGuess
        .map(node => ({
          data: { ...removePropertyByName(node, '__typename'), fetchMore: true },
          value: buildAddressString(node),
        }))
        .filter(suggestion => suggestion.value);

      this.setState({ suggestions });
    }).finally(() => {
      this.setState({ isLoading: false });
    });
  }

  getAddress = async (udprn) => {
    this.setState({ isLoading: true });
    const { client } = this.props;

    const { data } = await client.query({
      query: getAddress,
      variables: {
        udprn,
      },
    });

    this.setState({ isLoading: false });
    return removePropertyByName(data.addressForUdprn, '__typename');
  }

  onLookUpChange = (value, changeValue) => {
    const { onChange } = this.props;
    const { isLookUpTouched } = this.state;
    const lookUpValue = changeValue || '';
    const newState = { isLookUpTouched: true, isLoading: true };
    newState.lookUpValue = isLookUpTouched ? value : lookUpValue;
    if (!isLookUpTouched) {
      newState.editingAddress = null;
      onChange(null);
    }

    if (!value) {
      newState.isLoading = false;
    }
    this.setState(newState);
    return this.debounceChange(value);
  };

  handleOnChange = ({ target: { name, value } }) => {
    this.setState(prevState => ({
      editingAddress: {
        ...prevState.editingAddress,
        [name]: value,
      },
    }));
  }

  handleOnSelect = async (address) => {
    const { onChange, addAddressToList } = this.props;
    const editingAddress = address.fetchMore ? await this.getAddress(address.udprn) : address;

    addAddressToList(editingAddress);

    this.setState({
      isModalOpen: false,
      address: editingAddress,
      suggestions: [],
      editingAddress: null,
      lookUpValue: buildAddressString(editingAddress),
      isLookUpTouched: false,
    });

    onChange(editingAddress);
  }

  handleCancelModal = () => {
    this.setState({
      isModalOpen: false,
      editingAddress: null,
    });

    setTimeout(() => this.lookUpRef.current.blur(), 50);
  }

  handleSaveModal = () => {
    const { onChange, addAddressToList } = this.props;
    const { editingAddress } = this.state;

    addAddressToList(editingAddress);

    this.setState({
      isModalOpen: false,
      suggestions: [],
      address: editingAddress,
      editingAddress: null,
      lookUpValue: buildAddressString(editingAddress),
      isLookUpTouched: false,
    });

    onChange(editingAddress);
    setTimeout(() => this.lookUpRef.current.blur(), 50);
  }

  handleOpenModal = () => {
    this.setState(prevState => ({
      isModalOpen: true,
      editingAddress: isAddressEmpty(prevState.address) ? { countryCode: 'GB' } : prevState.address,
    }));
    setTimeout(() => this.addressRef.current.focus(), 55);
  }

  handleClear = () => {
    const { onChange } = this.props;

    this.setState({ editingAddress: null });
    onChange(null);
  }

  render() {
    const {
      address, lookupLabel, fullWidth, errors, disabled, addressesUsed, margin,
    } = this.props;
    const {
      editingAddress, suggestions, isModalOpen, lookUpValue, isLoading,
    } = this.state;

    return (
      <BereavementConsumer>
        {bereavement => (
          <AddressFormSection
            address={address}
            bereavementAddresses={getBereavementAddresses(bereavement)}
            addressesUsed={addressesUsed}
            editingAddress={editingAddress}
            errors={errors}
            disabled={disabled}
            margin={margin}
            lookUpValue={lookUpValue}
            isLoading={isLoading}
            suggestions={suggestions}
            lookupLabel={lookupLabel}
            fullWidth={fullWidth}
            isModalOpen={isModalOpen}
            lookUpRef={this.lookUpRef}
            addressRef={this.addressRef}
            onChange={this.handleOnChange}
            onLookUpChange={this.onLookUpChange}
            onSelect={this.handleOnSelect}
            onCancelModal={this.handleCancelModal}
            onSaveModal={this.handleSaveModal}
            onOpenModal={this.handleOpenModal}
            onClear={this.handleClear}
          />
        )}
      </BereavementConsumer>
    );
  }
}

const mapStateToProps = state => ({
  addressesUsed: state.userStore.addressesUsed,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  addAddressToList: addAddressToListAction,
}, dispatch);

export default withApollo(connect(mapStateToProps, mapDispatchToProps)(AddressFormSectionContainer));
