import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AutoCompleteTextFieldView from './AutoCompleteTextField';

class AutoCompleteTextField extends Component {
  static propTypes = {
    'placeholder': PropTypes.string,
    'value': PropTypes.string,
    'fullWidth': PropTypes.bool,
    'disabled': PropTypes.bool,
    'error': PropTypes.string,
    'classes': PropTypes.string,
    'startComponent': PropTypes.objectOf(PropTypes.any),
    'endComponent': PropTypes.objectOf(PropTypes.any),
    'onChange': PropTypes.func.isRequired,
    'suggestions': PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    'data-test-id': PropTypes.string,
  }

  constructor(props) {
    super(props);

    this.state = {
      isFocused: false,
    };
  }

  onChange = (event) => {
    const { onChange } = this.props;
    const { value } = event.target;
    return onChange(value);
  }

  onSelect = (selected) => {
    const { onChange } = this.props;
    onChange(selected);
  }

  setFocus = (isFocused) => {
    this.setState({ isFocused });
  }

  render() {
    const {
      placeholder, value, suggestions, classes, fullWidth,
      startComponent, endComponent, disabled, error, 'data-test-id': testId,
    } = this.props;
    const { isFocused } = this.state;

    return (
      <AutoCompleteTextFieldView
        placeholder={placeholder}
        fullWidth={fullWidth}
        disabled={disabled}
        error={error}
        classes={classes}
        startComponent={startComponent}
        endComponent={endComponent}
        suggestions={suggestions}
        value={value}
        isFocused={isFocused}
        onChange={this.onChange}
        onSelect={this.onSelect}
        setFocus={this.setFocus}
        testId={testId}
      />
    );
  }
}

export default AutoCompleteTextField;
