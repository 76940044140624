import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const IconOther = (props) => {
  const { className } = props;
  const classes = className ? `${className} ${styles.rc_icon}` : styles.rc_icon;

  return (
    <svg viewBox="-2 -1.2 27 27" className={classes}><path d="M20.5,11 L19,11 L19,7 C19,5.9 18.1,5 17,5 L13,5 L13,3.5 C13,2.12 11.88,1 10.5,1 C9.12,1 8,2.12 8,3.5 L8,5 L4,5 C2.9,5 2.01,5.9 2.01,7 L2.01,10.8 L3.5,10.8 C4.99,10.8 6.2,12.01 6.2,13.5 C6.2,14.99 4.99,16.2 3.5,16.2 L2,16.2 L2,20 C2,21.1 2.9,22 4,22 L7.8,22 L7.8,20.5 C7.8,19.01 9.01,17.8 10.5,17.8 C11.99,17.8 13.2,19.01 13.2,20.5 L13.2,22 L17,22 C18.1,22 19,21.1 19,20 L19,16 L20.5,16 C21.88,16 23,14.88 23,13.5 C23,12.12 21.88,11 20.5,11 L20.5,11 Z" /></svg>
  );
};

IconOther.propTypes = {
  className: PropTypes.string,
};

export default IconOther;
