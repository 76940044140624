import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import moment from 'moment';
import { withValidation } from '@funeralguide/react-form-validation-hoc';

import { vehicleTypes } from 'constants/service';
import { caseStatuses } from 'constants/bereavement';
import {
  mockJourney,
  mockHearseVehicle,
  mockPassengerVehicle,
} from 'mocks/vehicles';
import { updateArrayByIndex, generateHexId } from 'services/utils';
import { duplicateVehicleJourney } from 'services/utils/arrangement';
import { duplicateVehicleTransform } from 'transforms/arrangement';
import { apolloClientType } from 'types/apollo';
import { bereavementType, arrangementType } from 'types/bereavement';

import VehiclesForm from './VehiclesForm';
import { editVehicleInfo } from './mutations.gql';
import { validationSchema } from './validation';

class ArrangementVehiclesFormContainer extends Component {
  static propTypes = {
    bereavement: bereavementType.isRequired,
    arrangement: arrangementType.isRequired,
    client: apolloClientType.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    validate: PropTypes.func.isRequired,
    setErrors: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      vehicles: [],
      isConfirmed: false,
    };
  }

  componentDidMount() {
    const {
      arrangement: {
        categoryInformation,
      },
    } = this.props;
    if (categoryInformation && categoryInformation.vehicles) {
      const {
        vehicles: { vehicles, isConfirmed },
      } = categoryInformation;
      this.setState({
        vehicles: vehicles || [],
        isConfirmed,
      });
    }
  }

  handleConfirm = (checked) => {
    this.setState({
      isConfirmed: checked,
    });
  };

  handleCreateVehicle = (vehicleType) => {
    const newVehicle = vehicleType === vehicleTypes.HEARSE
      ? mockHearseVehicle
      : mockPassengerVehicle;

    this.setState(prevState => ({
      vehicles: [...prevState.vehicles].concat({
        ...newVehicle,
        id: generateHexId(),
        journeys: [
          {
            ...mockJourney,
            id: generateHexId(),
            type: 'TO',
            dateTime: moment().format(),
          },
          {
            ...mockJourney,
            id: generateHexId(),
            type: 'FROM',
            dateTime: moment().add(1, 'hour').format(),
          },
        ],
      }),
    }));
  }

  handleRemoveVehicle = (vehicleId) => {
    this.handleClearErrors();
    this.setState(prevState => ({
      vehicles: prevState.vehicles.filter(vehicle => vehicle.id !== vehicleId),
    }));
  }

  handleCreateVehicleJourney = (vehicleId, newJourney = mockJourney) => {
    const { vehicles } = this.state;
    const vehicleIndex = vehicles.findIndex((vehicle => vehicle.id === vehicleId));
    const vehicleToUpdate = vehicles[vehicleIndex];
    const updatedVehicles = updateArrayByIndex(
      vehicles,
      vehicleIndex,
      {
        ...vehicleToUpdate,
        journeys: [...vehicleToUpdate.journeys].concat({
          ...newJourney,
          id: generateHexId(),
          dateTime: moment().format(),
        }),
      },
    );

    this.handleClearErrors();
    this.setState({ vehicles: updatedVehicles });
  }

  handleRemoveVehicleJourney = (vehicleId, journeyId) => {
    const { vehicles } = this.state;
    const updatedVehicles = vehicles.map((vehicle) => {
      if (vehicle.id !== vehicleId) return vehicle;

      return {
        ...vehicle,
        journeys: vehicle.journeys.filter((journey) => {
          if (journey.id === journeyId) {
            return false;
          }

          return true;
        }),
      };
    });

    this.handleClearErrors();
    this.setState(() => ({
      vehicles: updatedVehicles,
    }));
  }

  handleUpdateVehicle = (vehicleId, updateObj) => {
    const { vehicles } = this.state;
    const vehicleIndex = vehicles.findIndex((vehicle => vehicle.id === vehicleId));
    const vehicleToUpdate = vehicles[vehicleIndex];
    const updatedVehicles = updateArrayByIndex(
      vehicles,
      vehicleIndex,
      {
        ...vehicleToUpdate,
        ...updateObj.item,
      },
    );

    this.handleClearErrors();
    this.setState({ vehicles: updatedVehicles });
  }

  handleUpdateVehicleJourney = (vehicleId, updateObj) => {
    const { vehicles } = this.state;
    const vehicleIndex = vehicles.findIndex((vehicle => vehicle.id === vehicleId));
    const vehicleToUpdate = vehicles[vehicleIndex];
    const updatedVehicles = updateArrayByIndex(
      vehicles,
      vehicleIndex,
      {
        ...vehicleToUpdate,
        journeys: updateArrayByIndex(
          vehicleToUpdate.journeys,
          updateObj.index,
          {
            ...vehicleToUpdate.journeys[updateObj.index],
            ...updateObj.item,
          },
        ),
      },
    );

    this.handleClearErrors();
    this.setState({ vehicles: updatedVehicles });
  }

  handleDuplicateVehicleJourney = (vehicle, vehicleType) => {
    const { vehicles } = this.state;
    const updatedVehicles = [
      ...vehicles,
      duplicateVehicleJourney(
        duplicateVehicleTransform(vehicle),
        vehicleType,
      ),
    ];
    this.setState({ vehicles: updatedVehicles });
  }

  handleSaveVehicles = () => {
    const {
      client,
      bereavement,
      arrangement,
      validate,
      onSave,
    } = this.props;
    const { vehicles } = this.state;
    const isValid = validate(vehicles, validationSchema);

    if (!isValid && vehicles.length > 0) {
      return;
    }

    this.setState({ isSaving: true });
    const { isConfirmed } = this.state;
    const arrangementId = arrangement.id;
    onSave(bereavement.id, arrangementId, 'vehicles', {
      vehicles,
      isConfirmed,
    });
    client.mutate({
      mutation: editVehicleInfo,
      variables: {
        bereavementId: bereavement.id,
        arrangementId,
        vehicles,
        isConfirmed,
      },
    }).finally(() => this.setState({ isSaving: false }));
  }

  handleClearErrors = () => {
    const { setErrors } = this.props;
    setErrors({});
  }

  render() {
    const { bereavement, errors } = this.props;
    const {
      vehicles, isSaving, isConfirmed,
    } = this.state;

    return (
      <VehiclesForm
        vehicles={vehicles}
        isSaving={isSaving}
        isConfirmed={isConfirmed}
        errors={errors}
        disabled={isSaving || bereavement.caseStatus === caseStatuses.CLOSED}
        onSaveVehicles={this.handleSaveVehicles}
        onCreateVehicle={this.handleCreateVehicle}
        onUpdateVehicle={this.handleUpdateVehicle}
        onRemoveVehicle={this.handleRemoveVehicle}
        onCreateVehicleJourney={this.handleCreateVehicleJourney}
        onUpdateVehicleJourney={this.handleUpdateVehicleJourney}
        onRemoveVehicleJourney={this.handleRemoveVehicleJourney}
        onDuplicateVehicleJourney={this.handleDuplicateVehicleJourney}
        onConfirm={this.handleConfirm}
      />
    );
  }
}

export default withApollo(withValidation(ArrangementVehiclesFormContainer));
