import React from 'react';
import classNames from 'classnames';
import i18next from 'i18next';

import ScreenLayout from 'components/common/ScreenLayout';
import ConfirmationModal from 'components/common/ConfirmationModal';
import CreateNewTemplateButton from 'components/letterTemplates/CreateNewTemplateButton';
import EditCustomTemplateModal from 'components/letterTemplates/EditCustomTemplateModal';
import PageTabs from 'components/letterTemplates/PageTabs';
import TemplatesList from 'components/letterTemplates/TemplatesList';
import TemplateGenerationModal from 'components/letterTemplates/TemplateGenerationModal';
import { correspondenceLetterCategories } from 'constants/correspondence';
import { CorrespondenceCategory, CorrespondenceType } from 'constants/ts/tenant';
import IconLoad from 'icons/IconLoad';
import InvoiceLoadStatic from 'icons/InvoiceLoad_static';
import styles from 'scss/main.scss';

import rcStyles from './styles.scss';
import { Props } from './AdminLetterTemplatesScreen.types';

const AdminLetterTemplatesScreen: React.FC<Props> = ({
  templateDefinitionsLookup,
  isLoading,
  isModalOpen,
  customTemplateBeingModified,
  customTemplateBeingDeleted,
  onDeleteCustomTemplate,
  onSaveCustomTemplate,
  onUpdateTemplateDefinition,
  setCustomTemplateBeingModified,
  setCustomTemplateBeingDeleted,
  toggleModal,
}: Props) => {
  const { customTemplates, systemTemplates } = templateDefinitionsLookup || {};
  const hasCustomTemplates = customTemplates && (customTemplates[CorrespondenceCategory.CASE].length > 0
    || customTemplates[CorrespondenceCategory.ACCOUNT].length > 0);
  const systemTemplatesForCase = systemTemplates[CorrespondenceCategory.CASE].filter(
    template => template.type !== CorrespondenceType.ESTIMATE_PRE_NEED
      && template.type !== CorrespondenceType.ESTIMATE_AT_NEED,
  );

  return (
    <ScreenLayout
      title={i18next.t('Letter Templates')}
      dashboardBannerProps={{
        breadcrumbs: [{ path: '/', title: i18next.t('Dashboard') }, { path: '/templates', title: 'Letter templates' }],
        description: i18next.t('Customise letters which are available within a funerals correspondence list.') as string,
      }}
    >
      <PageTabs />
      <div className={styles.o_view}>
        <div className={styles.o_view__main}>
          <div className={styles.c_k_tile}>
            <div className={styles.c_k_tile__header}>
              <h3 className={styles.is_heading}>{i18next.t('Customise letters with funeral details')}</h3>
            </div>
            <p className={styles.primer}>
              {i18next.t(`Save time & prevent errors by automatically including funeral details in letter templates.
              Download a full list of available values which can be added to any letter template
              or create and upload your own letter templates.`)}
            </p>
            <div className={styles.c_k_tile__action_bar}>
              <CreateNewTemplateButton
                onClick={() => toggleModal('generateTemplate')}
                data-test-id="createNewTemplateButton"
              />
            </div>
          </div>
          <div className={classNames(
            styles.o_block_content,
            rcStyles.v_spacing,
          )}
          >
            {isLoading.tenantTemplateDefinitions ? (
              <div className={styles.u_soft__ends_double}>
                <div className={styles.c_svg_loader_big} data-test-id="customTenantTemplateDefinitionsLoader">
                  <IconLoad />
                </div>
              </div>
            ) : (
              <>
                {hasCustomTemplates ? (
                  <div className={rcStyles.v_spacing}>
                    <h3 className={classNames(
                      styles.pica,
                      styles.is_black,
                    )}
                    >
                      {i18next.t('Custom templates')}
                    </h3>
                    <div>
                      <TemplatesList
                        items={customTemplates.CASE}
                        category={correspondenceLetterCategories.CASE}
                        allowDefaultDownload={false}
                        onUpdateTemplateDefinition={onUpdateTemplateDefinition}
                        onEditTitle={() => toggleModal('editCustomTemplate')}
                        setCustomTemplateBeingModified={setCustomTemplateBeingModified}
                        onDelete={() => toggleModal('deleteCustomTemplateConfirmation')}
                        setCustomTemplateBeingDeleted={setCustomTemplateBeingDeleted}
                      />
                    </div>
                    <div>
                      <TemplatesList
                        items={customTemplates.ACCOUNT}
                        category={correspondenceLetterCategories.ACCOUNT}
                        allowDefaultDownload={false}
                        onUpdateTemplateDefinition={onUpdateTemplateDefinition}
                        onEditTitle={() => toggleModal('editCustomTemplate')}
                        setCustomTemplateBeingModified={setCustomTemplateBeingModified}
                        onDelete={() => toggleModal('deleteCustomTemplateConfirmation')}
                        setCustomTemplateBeingDeleted={setCustomTemplateBeingDeleted}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <div className={rcStyles.custom_template_cta}>
                      <div className={classNames(rcStyles.is_graphic)}>
                        <InvoiceLoadStatic />
                      </div>

                      <p className={rcStyles.is_label}>
                        {i18next.t(`Create and customise your own letter templates that you can download for each case.
                        Save time and personalise your customer experience.`)}
                      </p>

                      <div className={rcStyles.is_action}>
                        <CreateNewTemplateButton
                          onClick={() => toggleModal('generateTemplate')}
                          data-test-id="createNewTemplateButton"
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <div className={classNames(
            styles.o_block_content,
            rcStyles.v_spacing,
          )}
          >
            <h3 className={classNames(
              styles.pica,
              styles.is_black,
            )}
            >
              {i18next.t('Arranger templates')}
            </h3>
            <div>
              <TemplatesList
                items={systemTemplatesForCase}
                category={correspondenceLetterCategories.CASE}
                onUpdateTemplateDefinition={onUpdateTemplateDefinition}
              />
            </div>
            <div>
              <TemplatesList
                items={systemTemplates.ACCOUNT}
                category={correspondenceLetterCategories.ACCOUNT}
                onUpdateTemplateDefinition={onUpdateTemplateDefinition}
              />
            </div>
          </div>
        </div>
      </div>
      {isModalOpen.generateTemplate && (
        <TemplateGenerationModal
          isOpen={isModalOpen.generateTemplate}
          onClose={() => toggleModal('generateTemplate')}
          onUpdateTemplateDefinition={onUpdateTemplateDefinition}
        />
      )}
      {isModalOpen.editCustomTemplate && (
        <EditCustomTemplateModal
          template={customTemplateBeingModified}
          isOpen={isModalOpen.editCustomTemplate}
          onClose={() => toggleModal('editCustomTemplate')}
          onSave={onSaveCustomTemplate}
        />
      )}
      {isModalOpen.deleteCustomTemplateConfirmation && (
        <ConfirmationModal
          message={i18next.t(`Are you sure you want to delete ${customTemplateBeingDeleted?.title}?`)}
          isOpen={isModalOpen.deleteCustomTemplateConfirmation}
          onConfirm={onDeleteCustomTemplate}
          onCancel={() => toggleModal('deleteCustomTemplateConfirmation')}
        />
      )}
    </ScreenLayout>
  );
};

export default AdminLetterTemplatesScreen;
