import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { arrangementType } from 'types/bereavement';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import MoreVerticalIcon from '@material-ui/icons/MoreVert';
import EstimateOverridablePrice from 'components/estimate/EstimateOverridablePrice';
import SortableEstimateSelectionsList from 'components/estimate/SortableEstimateSelectionsList';
import { discountTypes } from 'constants/arrangement';

import rcStyles from './styles.scss';

const EstimatePackageRows = ({
  bereavementId,
  arrangement,
  selections,
  originalTitle,
  overrideTitle,
  onOpenConfirmationWarningModal,
  disabled,
  onEditPackageItemOverride,
  itemMenuAnchorElement,
  onMenuOpen,
  onMenuClose,
  isOverrideItem,
  onOverridePackage,
  onResetPackage,
  onSortEnd,
}) => {
  const originalPrice = arrangement.isConfirmed
    ? arrangement.packageSelection.salePriceAtConfirmation
    : arrangement.packageSelection.package.price;

  return (
    <Fragment>
      <div className={rcStyles.estimate_package_header}>
        <Fragment>
          {isOverrideItem ? (
            <TextField
              label={t('Title')}
              margin="none"
              value={overrideTitle !== null ? overrideTitle : originalTitle}
              onChange={event => onEditPackageItemOverride('overrideTitle', event.target.value)}
              inputProps={{
                'data-test-id': 'estimatePackageOverrideTitle',
              }}
              fullWidth
              className={rcStyles.is_title}
            />
          ) : (
            <h1
              className={rcStyles.is_title}
              data-test-id="estimatePackageTitle"
            >
              {originalTitle}
            </h1>
          )}
        </Fragment>
        <div
          className={rcStyles.is_prices}
          data-test-id="packagePrice"
        >
          <EstimateOverridablePrice
            originalPrice={originalPrice}
            overridePrice={arrangement.packageSelection.overridePrice}
            canOverridePrice={arrangement.discountType === discountTypes.ITEMISED}
            disabled={disabled}
            onChange={value => onEditPackageItemOverride('overridePrice', value)}
            data-test-id="editPackageSelection"
          />
        </div>
        <div className={rcStyles.is_actions}>
          <IconButton
            aria-controls="edit-menu"
            aria-haspopup="true"
            data-test-id="estimatePackageOptionsButton"
            onClick={onMenuOpen}
          >
            <MoreVerticalIcon />
          </IconButton>
        </div>
        <Menu
          anchorEl={itemMenuAnchorElement}
          open={!!itemMenuAnchorElement}
          onClose={onMenuClose}
          PaperProps={{
            'data-test-id': 'estimatePackageOptions',
          }}
        >
          {isOverrideItem ? (
            <MenuItem
              data-test-id="estimatePackageReset"
              onClick={onResetPackage}
            >
              {t('Reset')}
            </MenuItem>
          ) : (
            <MenuItem
              data-test-id="estimatePackageEditTitle"
              onClick={onOverridePackage}
            >
              {t('Edit Title')}
            </MenuItem>
          )}
        </Menu>
      </div>
      <SortableEstimateSelectionsList
        selections={selections}
        bereavementId={bereavementId}
        arrangement={arrangement}
        onOpenConfirmationWarningModal={onOpenConfirmationWarningModal}
        disabled={disabled}
        onSortEnd={onSortEnd}
        distance={1}
      />
    </Fragment>
  );
};

EstimatePackageRows.propTypes = {
  bereavementId: PropTypes.string.isRequired,
  arrangement: arrangementType.isRequired,
  selections: PropTypes.arrayOf(PropTypes.object).isRequired,
  originalTitle: PropTypes.string,
  overrideTitle: PropTypes.string,
  onOpenConfirmationWarningModal: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  onEditPackageItemOverride: PropTypes.func.isRequired,
  onMenuOpen: PropTypes.func.isRequired,
  onMenuClose: PropTypes.func.isRequired,
  itemMenuAnchorElement: PropTypes.shape({}),
  isOverrideItem: PropTypes.bool.isRequired,
  onOverridePackage: PropTypes.func.isRequired,
  onResetPackage: PropTypes.func.isRequired,
  onSortEnd: PropTypes.func.isRequired,
};

export default EstimatePackageRows;
