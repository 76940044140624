import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { matchType } from 'types/reactRouter';
import { bindActionCreators } from 'redux';
import { clearHomesAction } from 'actions/homes';

import AdminHomesSectionScreen from './AdminHomesSectionScreen';

class AdminHomesSectionScreenContainer extends Component {
  static propTypes = {
    match: matchType.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    clearHomes: PropTypes.func.isRequired,
  }

  componentWillUnmount = () => {
    const { clearHomes } = this.props;
    clearHomes();
  }

  render() {
    return (
      <AdminHomesSectionScreen
        {...this.props}
      />
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  clearHomes: clearHomesAction,
}, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(AdminHomesSectionScreenContainer));
