import mapActionToReducer from 'redux-action-reducer-mapper';
import { updateArrayByIndex } from 'services/utils';
import { homeGroupsActions } from 'actions/homeGroups';

export const initialState = {
  homeGroups: [],
  pagination: {
    first: 10,
    after: null,
    hasNextPage: true,
  },
  filters: {
    searchTerm: '',
  },
  isLoading: false,
};

const clearHomeGroups = state => ({
  ...initialState,
  filters: state.filters,
  isLoading: state.isLoading,
});

const addHomeGroups = (state, action) => {
  const { payload: { homeGroups, pagination } } = action;
  return {
    ...state,
    homeGroups: [...state.homeGroups, ...homeGroups],
    pagination: {
      ...state.pagination,
      ...pagination,
    },
  };
};

const addHomeGroup = (state, action) => {
  const { payload } = action;

  const newHome = {
    ...payload,
    isNew: true,
  };

  const newHomeGroup = [...state.homeGroups];
  newHomeGroup.unshift(newHome);

  if (payload) {
    return {
      ...state,
      homeGroups: newHomeGroup,
      pagination: state.pagination,
      filters: state.filters,
    };
  }

  return state;
};

const updateHomeGroup = (state, action) => {
  const { payload } = action;

  if (payload) {
    const homeGroupsIndex = state.homeGroups.findIndex((homeGroup => homeGroup.id === payload.id));

    if (homeGroupsIndex > -1) {
      return {
        ...state,
        homeGroups: updateArrayByIndex(state.homeGroups, homeGroupsIndex, payload),
        pagination: state.pagination,
        filters: state.filters,
      };
    }
  }

  return state;
};

const removeHomeGroup = (state, action) => {
  const { payload } = action;
  if (payload && payload.id) {
    const updatedHomeGroup = state.homeGroups.filter(item => (item.id !== payload.id));

    return {
      ...state,
      homeGroups: updatedHomeGroup,
      pagination: state.pagination,
      filters: state.filters,
    };
  }

  return state;
};

const updateFilters = (state, action) => {
  const { payload: { key, value } } = action;
  return {
    ...state,
    filters: {
      ...state.filters,
      [key]: value,
    },
  };
};

const setLoading = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    isLoading: payload,
  };
};

export const homeGroupsReducer = mapActionToReducer({
  default: initialState,
  [homeGroupsActions.CLEAR_ALL]: () => initialState,
  [homeGroupsActions.CLEAR_HOMEGROUPS]: clearHomeGroups,
  [homeGroupsActions.ADD_HOMEGROUP]: addHomeGroup,
  [homeGroupsActions.ADD_HOMEGROUPS]: addHomeGroups,
  [homeGroupsActions.UPDATE_HOMEGROUP]: updateHomeGroup,
  [homeGroupsActions.REMOVE_HOMEGROUP]: removeHomeGroup,
  [homeGroupsActions.UPDATE_FILTERS]: updateFilters,
  [homeGroupsActions.SET_LOADING]: setLoading,
});
