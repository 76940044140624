import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import moment from 'moment';
import { taskType } from 'types/task';
import { textForCategory } from 'services/utils/task';
import CardActionArea from '@material-ui/core/CardActionArea';
import { buildNameString } from 'services/utils';
import { committalTypes } from 'constants/arrangement';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const TaskRow = ({
  task,
  onViewTask,
  serviceData,
}) => (

  <CardActionArea
    onClick={() => { onViewTask(task.id); }}
    data-test-id="viewTask"
  >
    <div className={classNames(
      rcStyles.task_card,
      task.status === 'PENDING' ? rcStyles.is_pending : '',
      task.status === 'COMPLETED' ? rcStyles.is_completed : '',
      task.status === 'DISMISSED' ? rcStyles.is_dismissed : '',
    )}
    >
      <div className={rcStyles.task_card__column}>
        <h3 className={classNames(
          styles.minion,
          styles.is_grey,
        )}
        >
          {t('Category')}
        </h3>
        <div className={rcStyles.definition}>
          {t(textForCategory(task.category))}
        </div>
      </div>
      <div className={rcStyles.task_card__column}>
        <h3 className={classNames(
          styles.minion,
          styles.is_grey,
        )}
        >
          {t('Title')}
        </h3>
        <div className={rcStyles.definition}>
          {task.title}
        </div>
      </div>
      {task.assignee && (
        <div className={rcStyles.task_card__column}>
          <h3 className={classNames(
            styles.minion,
            styles.is_grey,
          )}
          >
            {t('Assignee')}
          </h3>
          <div className={rcStyles.definition}>
            {buildNameString(task.assignee.name)}
          </div>
        </div>
      )}

      {task.bereavement && (
        <div className={rcStyles.task_card__column}>
          <h3 className={classNames(
            styles.minion,
            styles.is_grey,
          )}
          >
            {t('Deceased')}
          </h3>
          <div className={rcStyles.definition}>
            {buildNameString(task.bereavement.deceasedPeople[0].name)}
          </div>
        </div>
      )}

      {serviceData && (
        <div className={rcStyles.task_card__column}>
          <h3 className={classNames(
            styles.minion,
            styles.is_grey,
          )}
          >
            {t(`${serviceData.committalType === committalTypes.CREMATION ? 'Cremation' : 'Burial'}`)}
          </h3>
          <div className={rcStyles.definition}>
            {moment(serviceData.startDateTime).format('DD MMMM YYYY - HH:mm')}
          </div>
        </div>
      )}
    </div>
  </CardActionArea>

);

TaskRow.propTypes = {
  task: taskType.isRequired,
  onViewTask: PropTypes.func.isRequired,
  serviceData: PropTypes.shape({
    committalType: PropTypes.string.isRequired,
    startDateTime: PropTypes.string,
  }),
};

export default TaskRow;
