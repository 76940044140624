export const handlingOfAshesTypes = [
  'Scattering of ashes at the Crematorium (witnessed)',
  'Scattering of ashes at the Crematorium (unwitnessed)',
  'Collection from Crematorium by Funeral Director',
  'Collection from Crematorium by Client',
  'Collection from Crematorium by nominated person',
  'Interment',
  'To be collected & retained by us',
  'Hold at Crematorium',
  'Deliver to family',
  'FD Memorial garden',
];

export const vehicleTypes = {
  HEARSE: 'HEARSE',
  PASSENGER_VEHICLE: 'PASSENGER_VEHICLE',
  LIMOUSINE: 'LIMOUSINE',
  PRIVATE_AMBULANCE: 'PRIVATE_AMBULANCE',
};

export const vehicleTypeLabels = {
  [vehicleTypes.HEARSE]: 'Hearse',
  [vehicleTypes.PASSENGER_VEHICLE]: 'Passenger Vehicle',
  [vehicleTypes.LIMOUSINE]: 'Limousine',
  [vehicleTypes.PRIVATE_AMBULANCE]: 'Private ambulance',
};
