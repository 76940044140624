import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { t } from 'i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';

import CorrespondenceRow from 'components/bereavement/CorrespondenceRow';
import {
  correspondenceLetterCategoryNames,
} from 'constants/correspondence';

import styles from 'scss/main.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const CorrespondenceModal = ({
  isLoading,
  onDownload,
  onClose,
  isOpen,
  correspondenceCategory,
  templateDefinitionsLookup,
  bereavementId,
}) => {
  const customTemplates = templateDefinitionsLookup.customTemplates[correspondenceCategory];
  const systemTemplates = templateDefinitionsLookup.systemTemplates[correspondenceCategory];

  return (
    <Dialog
      fullScreen
      onClose={onClose}
      open={isOpen}
      TransitionComponent={Transition}
      className={styles.c_dialogue}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle
        disableTypography
        className={styles.dialog_title_bar}
      >
        <IconButton
          className={classNames(
            styles.o_button__secondary,
            styles.is_close,
          )}
          color="primary"
          variant="contained"
          aria-label="Close"
          onClick={onClose}
          data-test-id="close"
        >
          <CloseIcon />
        </IconButton>
        <div className={styles.is_title}>
          {t('Correspondence')}
        </div>
      </DialogTitle>
      <DialogContent className={styles.o_view}>
        <div className={classNames(
          styles.o_view__main,
          styles.u_push__ends,
        )}
        >
          {customTemplates && !!customTemplates.length && (
            <div className={styles.o_block_content}>
              <div
                className={classNames(
                  styles.long_primer,
                  styles.is_black,
                  styles.u_push__bottom,
                )}
              >
                {t('Custom templates')}
              </div>
              <div className={classNames(styles.o_list)}>
                {customTemplates.map((template) => {
                  const key = `customTemplate_${template.id}`;
                  const updatedTemplate = {
                    ...template,
                    key,
                  };

                  return (
                    <CorrespondenceRow
                      key={key}
                      template={updatedTemplate}
                      bereavementId={bereavementId}
                      isLoading={isLoading[key] || false}
                      data-test-id="customTemplate"
                      onDownload={onDownload}
                    />
                  );
                })}
              </div>
            </div>
          )}
          <div className={styles.o_block_content}>
            <div
              className={classNames(
                styles.long_primer,
                styles.is_black,
                styles.u_push__bottom,
              )}
            >
              {t(`${correspondenceLetterCategoryNames[correspondenceCategory]} templates`)}
            </div>
            <div className={classNames(styles.o_list)}>
              {systemTemplates && systemTemplates.map(template => (
                <CorrespondenceRow
                  key={template.key}
                  template={template}
                  bereavementId={bereavementId}
                  isLoading={isLoading[template.key] || false}
                  data-test-id={template.key}
                  onDownload={onDownload}
                />
              ))}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

CorrespondenceModal.propTypes = {
  isLoading: PropTypes.objectOf(PropTypes.any),
  onDownload: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  bereavementId: PropTypes.string.isRequired,
  correspondenceCategory: PropTypes.string.isRequired,
  templateDefinitionsLookup: PropTypes.objectOf(PropTypes.any),
};

export default CorrespondenceModal;
