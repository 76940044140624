import { createMuiTheme } from '@material-ui/core/styles';
import vars from 'scss/abstracts/_variables.scss';

const overrideTheme = createMuiTheme({
  palette: {
    primary: {
      main: vars.colorPrimary,
    },
    contrastThreshold: 3,
    secondary: {
      main: vars.colorButtonSecondary,
      contrastText: vars.colorPrimary,
    },
  },
  typography: {
    fontFamily: vars.typographyPrimary,
  },
  overrides: {
    MuiIconButton: {
      root: {
        padding: '6px',
      },
    },
    MuiTab: {
      label: {
        fontWeight: '700',
        fontSize: '16px',
      },
    },
    MuiDialogTitle: {
      root: {
        marginBottom: vars.unitWhole,
        padding: '0',
      },
    },
    MuiDialogContent: {
      root: {
        overflowX: 'visible',
        overflowY: 'visible',
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'center',
      },
    },
    MuiFormLabel: {
      root: {
        color: vars.colorError,
      },
      focused: {
        color: vars.colorWhite,
      },
      filled: {
        color: vars.colorWhite,
      },
    },
    MuiInputBase: {
      root: {
        borderBottom: '1px solid #ffffff',
        color: vars.colorWhite,
      },
      input: {
        height: '19px', // fix for g, y, q cut off
      },
    },
    MuiInput: {
      root: {
        borderColor: vars.colorWhite,
      },
      inputType: {
        borderBottom: '1px solid #ffffff',
      },
      error: {
        '&&&&:after': {
          borderBottomColor: vars.colorError,
        },
      },
    },
    MuiInputLabel: {
      root: {
        'color': vars.colorWhite,
        '&$focused': { // increase the specificity for the pseudo class
          color: vars.colorWhite,
        },
      },
    },
    MuiButton: {
      contained: {
        boxShadow: 'none',
        fontWeight: vars.typographyBold,
      },
      outlined: {
        boxShadow: 'none',
        fontWeight: vars.typographyBold,
      },
      text: {
        padding: 0,
        margin: 0,
      },
      containedSecondary: {
        '&:hover': {
          backgroundColor: vars.colorGrey,
        },
      },
    },
    MuiFab: {
      root: {
        padding: 0,
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
        marginTop: 0,
        display: 'inline-flex',
        alignItems: 'center',
      },
    },
  },
});

export default overrideTheme;
