import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { historyType } from 'types/reactRouter';
import WebSocketMessageError from './WebSocketMessageError';

class WebSocketMessageErrorContainer extends Component {
  static propTypes = {
    history: historyType.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  handleOnLogout = () => {
    const { history } = this.props;
    history.push('/logout');
  }

  render() {
    return (
      <WebSocketMessageError
        onLogout={this.handleOnLogout}
      />
    );
  }
}

export default withRouter(WebSocketMessageErrorContainer);
