import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { debounce } from 'debounce';
import PropTypes from 'prop-types';
import { matchType } from 'types/reactRouter';
import { fetchHomesAction, updateFiltersAction } from 'actions/homes';
import { organisationalUnitType } from 'types/organisationalUnit';
import AdminHomesListScreen from './AdminHomesListScreen';

class AdminHomesListScreenContainer extends Component {
  debounceGetHomes = debounce((filters) => {
    this.getHomes(filters);
  }, 500);

  static propTypes = {
    homes: PropTypes.arrayOf(organisationalUnitType).isRequired,
    pagination: PropTypes.objectOf(PropTypes.any),
    filters: PropTypes.objectOf(PropTypes.any),
    isLoading: PropTypes.bool.isRequired,
    fetchHomes: PropTypes.func.isRequired,
    updateFilters: PropTypes.func.isRequired,
    match: matchType,
  }

  componentDidMount() {
    const { homes } = this.props;

    if (!homes || homes.length === 0) {
      this.getHomes();
    }
  }

  getHomes = (filters) => {
    const { fetchHomes } = this.props;
    fetchHomes(filters);
  }

  handleChangeFilters = (key, value) => {
    const { filters, updateFilters } = this.props;

    if (value === filters[key]) {
      return;
    }
    updateFilters(key, value);
    this.debounceGetHomes({ ...filters, [key]: value });
  }

  render() {
    const {
      match,
      homes,
      pagination,
      filters,
      isLoading,
    } = this.props;

    return (
      <AdminHomesListScreen
        homes={homes}
        hasMoreHomes={homes.length > 0 && pagination.hasNextPage}
        getHomes={this.getHomes}
        isLoading={isLoading}
        match={match}
        filters={filters}
        onChangeFilters={this.handleChangeFilters}
      />
    );
  }
}

const mapStateToProps = state => ({
  homes: state.homesStore.homes,
  pagination: state.homesStore.pagination,
  filters: state.homesStore.filters,
  isLoading: state.homesStore.isLoading,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchHomes: fetchHomesAction,
  updateFilters: updateFiltersAction,
}, dispatch);

export default withApollo(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AdminHomesListScreenContainer),
  ),
);
