import moment from 'moment';
import { categories, serviceCategories } from 'constants/arrangement';
import {
  accountTransactionType,
  closeAccountMessages,
  invoiceTypes,
  invoiceTemplateTransferredStatus,
  invoiceSectionTitles,
  payeeTypes,
  PRODUCT_CATEGORIES_NOMINAL_CODES,
  SERVICE_CATEGORIES_NOMINAL_CODES,
  PACKAGE_NOMINAL_CODE,
} from 'constants/account';
import {
  defaultMoneyObject,
  generateHexId,
  getArrayIndexForId,
  updateArrayByIndex,
  isNullOrUndefined,
  getFormattedDate,
  getSelectionAggregate,
} from 'services/utils';
import { variantStatuses } from 'constants/catalogue';

export const getPriceForPackageSelection = (packageSelection) => {
  if (packageSelection.overridePrice) {
    return packageSelection.overridePrice;
  }
  if (packageSelection.salePriceAtConfirmation) {
    return packageSelection.salePriceAtConfirmation;
  }
  return packageSelection.package.price;
};

export const getVariantTitleForSelection = (selection) => {
  const selectionAggregate = getSelectionAggregate(selection);
  const numberOfVariants = selectionAggregate.variants
    ?.filter(variant => variant.status === variantStatuses.ACTIVE).length ?? 0;
  if (numberOfVariants < 2) {
    return null;
  }
  return selectionAggregate.variants.find(variant => variant.id === selection.variantId).name;
};

export const getTitleForSelection = (selection) => {
  if (selection.isCustomCharge) {
    return selection.title;
  }

  const selectionAggregate = getSelectionAggregate(selection);
  const selectionVariantTitle = getVariantTitleForSelection(selection);

  let title = '';

  if (selection.overrideTitle) {
    title = selection.overrideTitle;
  } else {
    title = (selectionVariantTitle) ? `${selectionAggregate.title}. ${selectionVariantTitle}` : selectionAggregate.title;
  }

  return (selection.isPackageSelection) ? `${title} (Included in Package)` : title;
};

export const getPriceForSelection = (selection) => {
  if (selection.overridePrice) {
    return selection.overridePrice;
  }
  if (selection.salePriceAtConfirmation) {
    return selection.salePriceAtConfirmation;
  }
  if (selection.originalPrice) {
    return selection.originalPrice;
  }
  return getSelectionAggregate(selection).variants.find(
    variant => variant.id === selection.variantId,
  ).prices.sale;
};

export const calculateSectionsTotal = (sections) => {
  const total = sections.reduce((acc, section) => (
    acc + section.items
      .filter(item => !item.isPackageSelection && item.isSelected)
      .reduce((sectionTotal, item) => {
        const amount = item.overridePrice ? item.overridePrice.amount : item.amount.amount;
        return sectionTotal + amount;
      }, 0)
  ), 0);
  return defaultMoneyObject(total);
};

export const isDisbursementSelection = (selection) => {
  if (!selection) {
    return false;
  }
  const selectionItem = selection.product || selection.service;
  const { category, displayAsDisbursementToBereaved } = selectionItem || {};

  return (category === serviceCategories.DISBURSEMENTS) || displayAsDisbursementToBereaved;
};

export const getArrangementSelections = (arrangement) => {
  const sections = [];
  if (arrangement.packageSelection) {
    const packagePrice = getPriceForPackageSelection(arrangement.packageSelection);
    sections.push({
      title: invoiceSectionTitles.PACKAGES,
      items: [{
        nominalCode: PACKAGE_NOMINAL_CODE,
        title: arrangement.packageSelection.package.name,
        description: arrangement.packageSelection.package.description,
        amount: packagePrice,
        taxBand: 'ZERO',
        isSelected: true,
      }],
    });
  }

  const { productSelections, serviceSelections } = arrangement;
  const selections = [...(productSelections || []), ...(serviceSelections || [])];
  const professionalServices = [];
  const disbursements = [];
  const otherSelections = [];

  selections.forEach((selection) => {
    const aggregate = getSelectionAggregate(selection);
    const title = getTitleForSelection(selection);

    if (!aggregate.nominalCode) {
      aggregate.nominalCode = selection.service
        ? SERVICE_CATEGORIES_NOMINAL_CODES[aggregate.category]
        : PRODUCT_CATEGORIES_NOMINAL_CODES[aggregate.category];
    }
    const item = {
      nominalCode: aggregate.nominalCode || null,
      title,
      description: aggregate.description || null,
      amount: selection.isPackageSelection ? defaultMoneyObject(0) : getPriceForSelection(selection),
      taxBand: aggregate.salesTaxBand,
      isSelected: true,
      isPackageSelection: selection.isPackageSelection,
    };
    if (selection.isDisbursement || isDisbursementSelection(selection)) {
      disbursements.push(item);
      return;
    }
    if (aggregate.category === categories.PROFESSIONAL_SERVICES) {
      professionalServices.push(item);
      return;
    }
    otherSelections.push(item);
  });

  if (otherSelections.length > 0) {
    sections.push({
      title: invoiceSectionTitles.PRODUCTS_AND_SERVICES,
      items: otherSelections,
    });
  }
  if (disbursements.length > 0) {
    sections.push({
      title: invoiceSectionTitles.DISBURSEMENTS,
      items: disbursements,
    });
  }
  if (professionalServices.length > 0) {
    sections.push({
      title: invoiceSectionTitles.PROFESSIONAL_SERVICES,
      items: professionalServices,
    });
  }

  return sections;
};

const getTransactionDateTime = transaction => moment(
  transaction.createdAt || transaction.dateTime || transaction.transferDateTime,
);

export const sortTransactionsByDateTimeDesc = transactions => transactions.sort(
  (transaction1, transaction2) => moment(getTransactionDateTime(transaction2))
    .diff(getTransactionDateTime(transaction1)),
);

export const getSortedTransactionsByDateTimeDesc = (transactions) => {
  const unsortedTransactions = [
    ...transactions.credits,
    ...transactions.payments,
    ...transactions.invoices,
    ...transactions.invoiceTemplates,
    transactions.historicFuneralAccountCharge,
    ...transactions.closeEvents,
    ...transactions.reopenEvents,
  ].filter(transaction => transaction);

  return unsortedTransactions.sort((transaction1, transaction2) => moment(getTransactionDateTime(transaction2))
    .diff(getTransactionDateTime(transaction1)));
};

export const formatHistoricFuneralAccountCharge = (
  historicFuneralAccountCharge, historicFuneralAccountTransferDateTime,
) => ({
  id: generateHexId(),
  amount: historicFuneralAccountCharge,
  transferDateTime: historicFuneralAccountTransferDateTime,
});

export const formatCloseEvents = closeEvents => sortTransactionsByDateTimeDesc(closeEvents || [])
  .reduce((result, event, index) => [...result, { ...event, isNewest: !index }], []);

export const formatAccount = (account) => {
  const {
    historicFuneralAccountCharge,
    historicFuneralAccountTransferDateTime,
    closeEvents,
  } = account;

  const updatedAccount = {
    ...account,
    closeEvents: formatCloseEvents(closeEvents),
  };

  return (
    historicFuneralAccountCharge
      ? ({
        ...updatedAccount,
        historicFuneralAccountCharge: formatHistoricFuneralAccountCharge(
          historicFuneralAccountCharge,
          historicFuneralAccountTransferDateTime,
        ),
      })
      : updatedAccount
  );
};

export const applyTypesToTransactions = ({
  credits,
  invoices,
  invoiceTemplates,
  payments,
  historicFuneralAccountCharge,
  closeEvents,
  reopenEvents,
}) => ({
  credits: credits ? credits.map(credit => ({
    ...credit,
    type: accountTransactionType.CREDIT,
  })) : [],
  invoices: invoices ? invoices
    .map(invoice => ({
      ...invoice,
      type: accountTransactionType.INVOICE,
    })) : [],
  invoiceTemplates: invoiceTemplates ? invoiceTemplates
    .map(invoice => ({
      ...invoice,
      type: accountTransactionType.INVOICE_TEMPLATE,
    })) : [],
  payments: payments ? payments.map(payment => ({
    ...payment,
    type: accountTransactionType.PAYMENT,
  })) : [],
  historicFuneralAccountCharge: historicFuneralAccountCharge ? ({
    ...historicFuneralAccountCharge,
    type: accountTransactionType.HISTORIC_FUNERAL_ACCOUNT_CHARGE,
  }) : null,
  closeEvents: closeEvents ? closeEvents.map(event => ({
    ...event,
    type: accountTransactionType.ACCOUNT_CLOSE_EVENT,
  })) : [],
  reopenEvents: reopenEvents ? reopenEvents.map(event => ({
    ...event,
    type: accountTransactionType.ACCOUNT_REOPEN_EVENT,
  })) : [],
});

export const updateLinkedInvoice = (invoices, invoiceId, transactions, key) => {
  if (isNullOrUndefined(invoiceId)) {
    return invoices;
  }

  const linkedInvoiceIndex = getArrayIndexForId(invoices, invoiceId);
  const linkedInvoice = (linkedInvoiceIndex > -1) && invoices[linkedInvoiceIndex];
  if (linkedInvoice && linkedInvoice.totals) {
    const invoiceTransactions = transactions.filter(
      invoiceTransaction => invoiceTransaction.invoiceId === invoiceId,
    );
    const transactionTotal = defaultMoneyObject((
      invoiceTransactions
        .map(item => item.amount)
        .reduce((accumulator, item) => accumulator + item.amount, 0)
    ) || 0);
    const { total } = linkedInvoice.totals;
    const otherTransactionTotal = linkedInvoice.totals[key === 'credit' ? 'totalPaid' : 'totalCredit'];
    const balance = defaultMoneyObject(
      total.amount - (transactionTotal.amount + ((otherTransactionTotal && otherTransactionTotal.amount) || 0)),
    );
    linkedInvoice.totals = {
      ...linkedInvoice.totals,
      [key === 'credit' ? 'totalCredit' : 'totalPaid']: transactionTotal,
      balance,
    };
  }

  return linkedInvoice
    ? updateArrayByIndex(invoices, linkedInvoiceIndex, linkedInvoice)
    : invoices;
};

export const isInvoice = invoice => invoice.invoiceType === invoiceTypes.INVOICE;

export const isProforma = invoice => invoice.invoiceType === invoiceTypes.PROFORMA;

export const getCloseAccountConfirmationMessage = (accountTotals) => {
  const { totalUnInvoiced, balance } = accountTotals;
  if (totalUnInvoiced.amount > 0) {
    return closeAccountMessages.HAS_UNINVOICED_TOTAL;
  } if (balance.amount > 0) {
    return closeAccountMessages.HAS_OUTSTANDING_BALANCE;
  }
  return closeAccountMessages.DEFAULT;
};

export const getDefaultInvoiceNote = (deceasedName, funeralDate) => {
  const { title, givenName, familyName } = deceasedName;
  const formattedFuneralDate = funeralDate && getFormattedDate(funeralDate, 'DD/MM/YYYY');
  let defaultNote = `Charges and disbursements in connection with the funeral of the late ${title} ${givenName} ${familyName}`;
  if (funeralDate) {
    defaultNote = defaultNote.concat(` on ${formattedFuneralDate}`);
  }
  return defaultNote;
};

export const getInvoiceTransferredStatus = (invoiceTemplate) => {
  const { transferredToXeroAt, editedAt } = invoiceTemplate || {};
  if (transferredToXeroAt) {
    if (!editedAt || moment(transferredToXeroAt).isAfter(editedAt)) {
      return invoiceTemplateTransferredStatus.TRANSFERRED;
    }
    if (moment(transferredToXeroAt).isBefore(editedAt)) {
      return invoiceTemplateTransferredStatus.TRANSFERRED_AND_EDITED;
    }
  }
  return invoiceTemplateTransferredStatus.NOT_TRANSFERRED;
};

export const getEmptyInvoice = (invoiceType, invoiceNotes = '') => ({
  id: generateHexId(),
  invoiceType,
  payeeId: null,
  payeeType: null,
  dateTime: moment().format(),
  number: null,
  reference: null,
  notes: invoiceNotes,
  customCharges: [],
  selections: [],
  isNew: true,
});

export const getInvoicePayee = (bereavedPeopleConnections, directoryListings, payeeId, payeeType) => {
  let selectedBereaved = null;
  let selectedDirectoryListing = null;

  if (payeeId) {
    selectedBereaved = (payeeType === payeeTypes.BEREAVED_PERSON_CONNECTION)
      ? bereavedPeopleConnections.find(bereaved => bereaved.id === payeeId)
      : null;
    selectedDirectoryListing = (payeeType === payeeTypes.DIRECTORY_LISTING)
      ? directoryListings.find(directoryListing => directoryListing.id === payeeId)
      : null;
  }
  if (!payeeId || (!selectedBereaved && !selectedDirectoryListing)) {
    selectedBereaved = bereavedPeopleConnections.find(bereaved => bereaved.isBillPayer);
  }

  return selectedBereaved || selectedDirectoryListing;
};
