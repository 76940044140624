import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { productType, serviceType } from 'types/bereavement';
import IconLoad from 'icons/IconLoad';
import ScreenLayout from 'components/common/ScreenLayout';
import AdminCatalogueItemForm from 'components/admin/AdminCatalogueItemForm';
import classNames from 'classnames';

import styles from 'scss/main.scss';

const AdminCatalogueItemScreen = ({
  isNewItem,
  catalogueItemType,
  isLoading,
  item,
  isServiceCategory,
  onCancel,
  onSave,
}) => {
  const catalogueItemTypeName = isServiceCategory ? 'service' : 'product';

  return (
    <ScreenLayout
      title={isNewItem ? t(`Create ${catalogueItemTypeName}`) : t(`Edit ${catalogueItemTypeName}`)}
      dashboardBannerProps={{
        title: t(`${isNewItem ? 'Create' : 'Edit'} ${isServiceCategory ? 'a service' : 'a product'}`),
        breadcrumbs: [{ path: '/', title: t('Dashboard') }, { path: '/catalogue', title: 'Brochure management' }, { path: `/catalogue/${isServiceCategory ? 'services' : 'products'}`, title: t(`${isServiceCategory ? 'Services' : 'Products'}`) }],
        description: t(`Create and manage all ${isServiceCategory ? 'services' : 'products'}. These will be available to include in packages and arrangements.`),
      }}
    >
      {isLoading
        ? (
          <div className={classNames(
            styles.c_page__full_height,
            styles.has_faded_background,
          )}
          >
            <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
              <IconLoad />
            </div>
          </div>
        )
        : (
          <AdminCatalogueItemForm
            isNewItem={isNewItem}
            catalogueItemType={catalogueItemType}
            isServiceCategory={isServiceCategory}
            item={item}
            onSave={onSave}
            onCancel={onCancel}
          />
        )
      }
    </ScreenLayout>
  );
};

AdminCatalogueItemScreen.propTypes = {
  isNewItem: PropTypes.bool,
  catalogueItemType: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  item: PropTypes.oneOfType([
    productType,
    serviceType,
    PropTypes.objectOf(PropTypes.any),
  ]).isRequired,
  isServiceCategory: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default AdminCatalogueItemScreen;
