import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import Button from '@material-ui/core/Button';
import { confirmArrangement as confirmArrangementAction } from 'actions/arrangement';
import { enqueueSnackbarAction, removeSnackbarAction } from 'actions/snackbar';
import {
  ashesRecordActions, ashesRecordExternalEditOptions, deceasedTab, deceasedCareRecordTab,
} from 'constants/bereavement';
import AshesRecipientModal from 'components/bereavement/AshesRecipientModal';
import ConfirmationModal from 'components/common/ConfirmationModal';
import { updateArrayByIndex } from 'services/utils';
import { getConfirmedArrangementFromBereavement, getSortedActions, getPersonToReceiveAshesData } from 'services/utils/bereavement';
import { bereavementType } from 'types/bereavement';
import { historyType } from 'types/reactRouter';
import { staffMemberType } from 'types/staffMember';
import styles from 'scss/main.scss';

import { validationSchema } from './validation';
import AshesRecordModal from './AshesRecordModal';

class AshesRecordModalContainer extends Component {
  static propTypes = {
    bereavement: bereavementType.isRequired,
    isOpen: PropTypes.bool.isRequired,
    ashesRecordBeingModified: PropTypes.objectOf(PropTypes.any),
    relatedData: PropTypes.objectOf(PropTypes.any),
    disabled: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    generateRefs: PropTypes.func.isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
    setErrors: PropTypes.func.isRequired,
    currentStaffMember: staffMemberType,
    history: historyType.isRequired,
    confirmArrangement: PropTypes.func.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    removeSnackbar: PropTypes.func.isRequired,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isOpen
      && nextProps.ashesRecordBeingModified
      && !Object.keys(prevState.formData).length) {
      return {
        formData: {
          id: nextProps.ashesRecordBeingModified.id,
          cremationCertificateNumber: nextProps.ashesRecordBeingModified.cremationCertificateNumber || '',
          storageLocation: nextProps.ashesRecordBeingModified.storageLocation || '',
          personToReceiveAshes: nextProps.ashesRecordBeingModified.personToReceiveAshes || '',
        },
        actions: nextProps.ashesRecordBeingModified.actions || [],
        isValidationEnabled: false,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      formData: {},
      actions: [],
      isValidationEnabled: false,
      externalEditConfig: null,
      recipientBeingModified: null,
    };

    this.dialogRef = React.createRef();
  }

  componentDidMount() {
    const { generateRefs } = this.props;
    generateRefs(Object.keys(validationSchema.fields));
  }

  resetState = () => {
    this.setState({
      formData: {},
      actions: [],
      isValidationEnabled: false,
      recipientBeingModified: null,
    });
  }

  handleChange = (key, value) => {
    const { validate } = this.props;
    const { formData, isValidationEnabled } = this.state;
    const updatedFormData = {
      ...formData,
      [key]: value,
    };

    if (isValidationEnabled) {
      validate(updatedFormData, validationSchema);
    }

    this.setState({ formData: updatedFormData });
  }

  handleAddAction = () => {
    const { currentStaffMember } = this.props;
    const { actions } = this.state;
    const updatedActions = [
      ...actions,
      {
        actionTaken: null,
        createdDateTime: moment().format(),
        createdById: currentStaffMember.id,
        createdBy: { ...currentStaffMember },
        isNew: true,
      },
    ];
    this.setState({ actions: updatedActions });
  }

  handleActionChange = (key, value, index) => {
    const { actions } = this.state;
    const action = actions[index];

    const updatedAction = {
      ...action,
      [key]: value,
    };

    if (key === 'actionTaken') {
      if (value !== ashesRecordActions.SCATTERED_BY_FD) {
        updatedAction.locationScattered = null;
      }
      if (value !== ashesRecordActions.RETURNED_TO_CLIENT) {
        updatedAction.returnToClientMethod = null;
      }
    }

    const updatedActions = updateArrayByIndex(
      actions,
      index,
      updatedAction,
    );

    this.setState({ actions: updatedActions });
  }

  handleClose = () => {
    const { onClose, setErrors } = this.props;
    this.resetState();
    setErrors(null);
    onClose();
  }

  handleSave = (callback) => {
    const { onSave, validate } = this.props;
    const { formData, actions } = this.state;

    const updatedAshesRecord = {
      ...formData,
      actions: actions.reduce((validActions, action) => {
        if (action.actionTaken) {
          return [...validActions, action];
        }
        return validActions;
      }, []),
    };

    this.setState({ isValidationEnabled: true });
    const isValid = validate(updatedAshesRecord, validationSchema, true, this.dialogRef);

    if (isValid) {
      onSave(updatedAshesRecord);
      this.resetState();
    }

    if (callback) {
      callback(isValid);
    }
  }

  handleEditArrangement = (externalEditType) => {
    const { bereavement, enqueueSnackbar } = this.props;
    const arrangement = getConfirmedArrangementFromBereavement(bereavement, true);
    if (arrangement) {
      const externalEditConfig = ashesRecordExternalEditOptions[externalEditType];
      this.setState({ externalEditConfig });
    } else {
      enqueueSnackbar({
        message: 'Multiple arrangements exist, please confirm an arrangement to edit this selection.',
        options: {
          variant: 'warning',
        },
      });
    }
  }

  handleConfirmEditArrangement = () => {
    this.handleSave((isValid) => {
      if (isValid) {
        const {
          bereavement, history, enqueueSnackbar, removeSnackbar, confirmArrangement,
        } = this.props;
        const { externalEditConfig } = this.state;
        const arrangement = getConfirmedArrangementFromBereavement(bereavement, true);
        if (arrangement) {
          history.push(`/case/${bereavement.id}/arrangement/${arrangement.id}/${externalEditConfig.arrangementPath}`);

          enqueueSnackbar({
            message: 'Returning to ashes will save your changes and confirm the arrangement',
            options: {
              variant: 'warning',
              persist: true,
              action: (
                <Fragment>
                  <Button
                    onClick={() => removeSnackbar()}
                    variant="outlined"
                    data-test-id="dismiss"
                    className={styles.u_push__right}
                  >
                    Dismiss
                  </Button>
                  <Button
                    onClick={() => {
                      confirmArrangement(bereavement.id, arrangement.id);
                      history.push(`/case/${bereavement.id}/deceased/details`, { tab: deceasedTab.CARE_RECORD, subTab: deceasedCareRecordTab.ASHES });
                    }}
                    variant="outlined"
                    data-test-id="returnToAshes"
                  >
                    {t('Return to ashes')}
                  </Button>
                </Fragment>
              ),
            },
          });
        }
      }

      this.setState({ externalEditConfig: null });
    });
  }

  handleCancelEditArrangement = () => {
    this.setState({ externalEditConfig: null });
  }

  handleEditPersonToReceiveAshes = () => {
    const { formData: { personToReceiveAshes } } = this.state;
    this.setState({ recipientBeingModified: personToReceiveAshes });
  }

  handleChangeRecipient = (recipientId) => {
    this.handleChange('personToReceiveAshes', recipientId);
    this.setState({ recipientBeingModified: null });
  }

  handleCloseChangeRecipient = () => {
    this.setState({ recipientBeingModified: null });
  }

  render() {
    const {
      bereavement, isOpen, relatedData, disabled, errors, formRefs,
    } = this.props;
    const {
      formData, actions, externalEditConfig, recipientBeingModified,
    } = this.state;
    const { bereavedPeopleConnections } = bereavement;
    const sortedActions = getSortedActions(actions, 'createdDateTime');
    const updatedRelatedData = {
      ...relatedData,
      personToReceiveAshes: getPersonToReceiveAshesData(
        bereavedPeopleConnections,
        formData.personToReceiveAshes,
      ),
    };

    return (
      <Fragment>
        <AshesRecordModal
          isOpen={isOpen}
          formData={formData}
          actions={sortedActions}
          relatedData={updatedRelatedData}
          bereavedPeopleConnections={bereavedPeopleConnections}
          disabled={disabled}
          onChange={this.handleChange}
          onAddAction={this.handleAddAction}
          onActionChange={this.handleActionChange}
          onClose={this.handleClose}
          onSave={this.handleSave}
          onEditArrangement={this.handleEditArrangement}
          onEditPersonToReceiveAshes={this.handleEditPersonToReceiveAshes}
          dialogRef={this.dialogRef}
          errors={errors}
          formRefs={formRefs}
        />
        <ConfirmationModal
          title={externalEditConfig && t(externalEditConfig.dialogTitle)}
          message={externalEditConfig ? t(externalEditConfig.dialogMessage) : 'Confirmation message'}
          confirmLabel={t('Save & continue')}
          isOpen={!!externalEditConfig}
          onConfirm={this.handleConfirmEditArrangement}
          onCancel={this.handleCancelEditArrangement}
          testIds={{
            dialog: 'confirmationModal',
            confirm: 'saveAndContinue',
          }}
        />
        <AshesRecipientModal
          isOpen={!!recipientBeingModified}
          bereavement={bereavement}
          recipientBeingModified={recipientBeingModified}
          bereavedPeopleConnections={bereavedPeopleConnections}
          disabled={disabled}
          onClose={this.handleCloseChangeRecipient}
          onSave={this.handleChangeRecipient}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  currentStaffMember: state.userStore.user.staffMember,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  confirmArrangement: confirmArrangementAction,
  enqueueSnackbar: enqueueSnackbarAction,
  removeSnackbar: removeSnackbarAction,
}, dispatch);

export default withValidation(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AshesRecordModalContainer),
  ),
);
