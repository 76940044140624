import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { caseStatuses } from 'constants/bereavement';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { bereavementType } from 'types/bereavement';
import { organisationalUnitType } from 'types/organisationalUnit';
import { arrangementTypes, committalTypes } from 'constants/arrangement';
import { validationSchema } from './validation';
import LiteArrangentForm from './LiteArrangementForm';

class LiteArrangementFormContainer extends Component {
  static propTypes = {
    bereavement: bereavementType,
    organisationalUnits: PropTypes.arrayOf(organisationalUnitType).isRequired,
    isLoading: PropTypes.bool,
    isNewBereavement: PropTypes.bool,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    setErrors: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    generateRefs: PropTypes.func.isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      isValidationEnabled: false,
      isArrangementValid: false,
    };
  }

  componentDidMount() {
    const {
      isNewBereavement, bereavement, generateRefs, validate,
    } = this.props;
    generateRefs(Object.keys(validationSchema.fields));
    if (isNewBereavement && !bereavement) {
      this.setState({
        formData: {
          committalType: committalTypes.CREMATION,
          type: arrangementTypes.AT_NEED,
        },
      });
      return;
    }

    const formData = {
      deceasedName: bereavement.deceasedPeople
        ? bereavement.deceasedPeople[0].name
        : bereavement.deceasedName,
      home: bereavement.home,
      homeId: bereavement.homeId,
      arrangementReference: bereavement.arrangements
        ? bereavement.arrangements[0].reference
        : bereavement.arrangementReference,
      committalType: bereavement.arrangements
        ? bereavement.arrangements[0].committalType
        : bereavement.committalType,
      type: bereavement.arrangements
        ? bereavement.arrangements[0].type
        : bereavement.type,
    };
    const isArrangementValid = validate(formData, validationSchema);
    this.setState({
      formData,
      isArrangementValid,
    });
  }

  setIsValid = (isValid) => {
    this.setState({
      isArrangementValid: isValid,
    });
  }

  handleChange = (key, value) => {
    const { validate, setErrors } = this.props;
    const { formData, isValidationEnabled } = this.state;

    let newFormData = {
      ...formData,
      [key]: value,
    };

    if (key === 'home') {
      newFormData = {
        ...newFormData,
        homeId: value ? value.id : null,
      };
    }

    this.setState({ formData: newFormData });

    const isValid = validate(newFormData, validationSchema);
    this.setIsValid(isValid);

    if (!isValidationEnabled) {
      setErrors({});
    }
  }

  handleSave = () => {
    const { validate, onSave } = this.props;
    const { isArrangementValid, formData } = this.state;

    this.setState({ isValidationEnabled: true });

    validate(formData, validationSchema, true);

    if (isArrangementValid) {
      onSave(formData);
    }
  }

  render() {
    const {
      bereavement,
      organisationalUnits,
      isLoading,
      isNewBereavement,
      onCancel,
      formRefs,
      errors,
    } = this.props;
    const { formData } = this.state;

    return (
      <LiteArrangentForm
        bereavement={bereavement}
        disabled={bereavement && bereavement.caseStatus === caseStatuses.CLOSED}
        organisationalUnits={organisationalUnits}
        isLoading={isLoading}
        isNewBereavement={isNewBereavement}
        formData={formData}
        onChange={this.handleChange}
        onSave={this.handleSave}
        onCancel={onCancel}
        formRefs={formRefs}
        errors={errors}
      />
    );
  }
}

export default withValidation(LiteArrangementFormContainer);
