import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { addPaymentTransform } from 'transforms/account';
import { paymentType, invoiceType } from 'types/account';
import { bereavedPersonConnectionType } from 'types/bereavement';
import { directoryListingType } from 'types/directoryListing';
import { generateHexId, isAddressEmpty } from 'services/utils';
import { accountTransactionType, payeeTypes } from 'constants/account';
import {
  addClientAction,
  editClientAction,
  createBereavedPersonAction,
  editBereavedPersonAction,
} from 'actions/bereavement';
import AccountAddPaymentModal from './AccountAddPaymentModal';
import { validationSchema } from './validation';

class AccountAddPaymentModalContainer extends Component {
  static propTypes = {
    bereavementId: PropTypes.string.isRequired,
    bereavedPeopleConnections: PropTypes.arrayOf(bereavedPersonConnectionType).isRequired,
    transactionBeingModified: paymentType,
    directoryListings: PropTypes.arrayOf(directoryListingType.isRequired).isRequired,
    setTransactionBeingModified: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onAddPayment: PropTypes.func.isRequired,
    addClient: PropTypes.func.isRequired,
    editClient: PropTypes.func.isRequired,
    invoices: PropTypes.arrayOf(invoiceType),
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    generateRefs: PropTypes.func.isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
    createBereavedPerson: PropTypes.func.isRequired,
    editBereavedPerson: PropTypes.func.isRequired,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {};
    if (nextProps.bereavedPeopleConnections && prevState.selectedBereaved) {
      const updatedConnection = nextProps.bereavedPeopleConnections
        .find(connection => connection.id === prevState.selectedBereaved.id);

      newState = {
        selectedBereaved: updatedConnection,
      };
    }

    if (nextProps.isOpen && nextProps.transactionBeingModified && !prevState.formData.id) {
      const {
        transactionBeingModified: transaction,
        bereavedPeopleConnections,
        directoryListings,
      } = nextProps;

      newState = {
        ...newState,
        isEditingTransaction: true,
        isEditingPayee: false,
        formData: {
          ...prevState.formData,
          id: transaction.id,
          createdAt: transaction.createdAt || null,
          amount: transaction.amount,
          payeeId: transaction.payeeId,
          dateTime: transaction.dateTime,
          method: transaction.method,
          invoice: transaction.invoiceId || null,
          reference: transaction.reference || null,
          payeeType: transaction.payeeType,
        },
        selectedBereaved: transaction.payeeType === payeeTypes.BEREAVED_PERSON_CONNECTION
          ? bereavedPeopleConnections
            .find(bereaved => bereaved.id === transaction.payeeId)
          : null,
        selectedDirectoryListing: transaction.payeeType === payeeTypes.DIRECTORY_LISTING
          ? directoryListings
            .find(listing => listing.id === transaction.payeeId)
          : null,
      };
    }

    return newState || null;
  }

  constructor(props) {
    super(props);

    this.state = {
      formData: {
        dateTime: moment().format(),
      },
      selectedBereaved: null,
      selectedDirectoryListing: null,
      isPayeeListModalOpen: false,
      isPayeeFormModalOpen: false,
      hasPayeeAddressError: false,
      isEditingTransaction: false,
    };

    this.dialogRef = React.createRef();
  }

  componentDidMount() {
    const { generateRefs } = this.props;

    generateRefs(Object.keys(validationSchema.fields));
    this.setInitialPayee();
  }

  setInitialPayee = () => {
    const {
      bereavedPeopleConnections,
      directoryListings,
      transactionBeingModified,
    } = this.props;

    if (!transactionBeingModified) {
      this.setPayeeToPrimaryBillPayer();
      return;
    }

    const selectedBereaved = (transactionBeingModified.payeeType === payeeTypes.BEREAVED_PERSON_CONNECTION)
      ? bereavedPeopleConnections.find(bereaved => bereaved.id === transactionBeingModified.payeeId)
      : null;
    const selectedDirectoryListing = (transactionBeingModified.payeeType === payeeTypes.DIRECTORY_LISTING)
      ? directoryListings.find(directoryListing => directoryListing.id === transactionBeingModified.payeeId)
      : null;

    this.handlePayeeChange(
      selectedBereaved || selectedDirectoryListing,
      (transactionBeingModified && transactionBeingModified.payeeType) || payeeTypes.BEREAVED_PERSON_CONNECTION,
    );
  }

  setPayeeToPrimaryBillPayer = () => {
    const { bereavedPeopleConnections } = this.props;

    this.setState(prevState => ({
      selectedBereaved: bereavedPeopleConnections
        .find(bereaved => bereaved.isBillPayer && !isAddressEmpty(bereaved.bereavedPerson.address)),
      selectedDirectoryListing: null,
      formData: {
        ...prevState.formData,
        payeeType: payeeTypes.BEREAVED_PERSON_CONNECTION,
      },
    }));
  }

  handlePayeeChange = (payee, payeeType) => {
    const address = payee.bereavedPerson ? payee.bereavedPerson.address : payee.address;
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        payeeType,
      },
      selectedBereaved: payeeType === payeeTypes.BEREAVED_PERSON_CONNECTION ? payee : null,
      selectedDirectoryListing: payeeType === payeeTypes.DIRECTORY_LISTING ? payee : null,
      hasPayeeAddressError: isAddressEmpty(address),
    }));
  }

  togglePayeeListModal = () => {
    this.setState(prevState => ({
      isPayeeListModalOpen: !prevState.isPayeeListModalOpen,
    }));
  }

  togglePayeeFormModal = (isEditingPayee) => {
    this.setState(prevState => ({
      isPayeeFormModalOpen: !prevState.isPayeeFormModalOpen,
      isEditingPayee,
    }));
  }

  handleChange = (key, value) => {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [key]: value,
      },
    }));
  }

  handleClose = () => {
    const { onClose, setTransactionBeingModified } = this.props;
    this.setState({
      isEditingTransaction: false,
      formData: {
        dateTime: moment().format(),
      },
    });
    setTransactionBeingModified(accountTransactionType.CREDIT, null);
    this.setPayeeToPrimaryBillPayer();
    onClose();
  }

  handleCreateBereavedPerson = (bereavedPersonConnection) => {
    const {
      createBereavedPerson,
      bereavementId,
      addClient,
      editClient,
    } = this.props;
    const newConnectionId = generateHexId();
    const newBereavedPersonConnection = { ...bereavedPersonConnection, id: newConnectionId };

    addClient(bereavementId, newBereavedPersonConnection);
    createBereavedPerson({
      newBereavedPersonConnection,
      bereavementId,
      onCreateBereavedPersonCallback: (bereaved) => {
        this.handlePayeeChange(
          bereaved,
          payeeTypes.BEREAVED_PERSON_CONNECTION,
        );
        editClient(bereavementId, { ...bereaved, id: newConnectionId });
      },
    });
  }

  handleEditBereavedPerson = (bereavedPersonConnection) => {
    const { bereavementId, editClient, editBereavedPerson } = this.props;

    editClient(bereavementId, bereavedPersonConnection);
    editBereavedPerson(bereavementId, bereavedPersonConnection);
  }

  handleSave = () => {
    const { validate, onAddPayment } = this.props;
    const {
      formData,
      selectedBereaved,
      selectedDirectoryListing,
      isEditingTransaction,
    } = this.state;
    const payment = addPaymentTransform(formData, selectedBereaved, selectedDirectoryListing);
    let isValid = validate(payment, validationSchema, true, this.dialogRef);

    const address = selectedBereaved
      ? selectedBereaved.bereavedPerson.address
      : selectedDirectoryListing.address;

    if (isAddressEmpty(address)) {
      isValid = false;
      this.setState({ hasPayeeAddressError: true });
    }

    if (isValid) {
      onAddPayment(payment, isEditingTransaction);
      this.handleClose();
    }
  }

  render() {
    const {
      bereavementId,
      bereavedPeopleConnections,
      isOpen,
      invoices,
      errors,
      formRefs,
    } = this.props;
    const {
      isPayeeListModalOpen,
      isPayeeFormModalOpen,
      formData,
      selectedBereaved,
      selectedDirectoryListing,
      hasPayeeAddressError,
      isEditingTransaction,
      isEditingPayee,
    } = this.state;

    return (
      <AccountAddPaymentModal
        selectedBereaved={selectedBereaved}
        selectedDirectoryListing={selectedDirectoryListing}
        formData={formData}
        isOpen={isOpen}
        isEditingPayment={isEditingTransaction}
        isEditingPayee={isEditingPayee}
        isPayeeListModalOpen={isPayeeListModalOpen}
        isPayeeFormModalOpen={isPayeeFormModalOpen}
        bereavementId={bereavementId}
        bereavedPeopleConnections={bereavedPeopleConnections}
        hasPayeeAddressError={hasPayeeAddressError}
        togglePayeeListModal={this.togglePayeeListModal}
        togglePayeeFormModal={this.togglePayeeFormModal}
        onPayeeChange={this.handlePayeeChange}
        onCancel={this.handleClose}
        onSave={this.handleSave}
        onChange={this.handleChange}
        createBereavedPerson={this.handleCreateBereavedPerson}
        editBereavedPerson={this.handleEditBereavedPerson}
        invoices={invoices}
        errors={errors}
        dialogRef={this.dialogRef}
        formRefs={formRefs}
      />
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  createBereavedPerson: createBereavedPersonAction,
  editBereavedPerson: editBereavedPersonAction,
  addClient: addClientAction,
  editClient: editClientAction,
}, dispatch);

export default withApollo(
  withRouter(
    withValidation(
      connect(null, mapDispatchToProps)(AccountAddPaymentModalContainer),
    ),
  ),
);
