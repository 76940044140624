import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { connect } from 'react-redux';
import { debounce } from 'debounce';
import PropTypes from 'prop-types';
import { historyType } from 'types/reactRouter';
import {
  fetchHomeGroupsAction,
  createHomeGroupAction,
  editHomeGroupAction,
  clearHomeGroupsAction,
  updateFiltersAction,
} from 'actions/homeGroups';
import AdminHomeGroupListScreen from './AdminHomeGroupListScreen';
import { validationSchema } from './validation';

class AdminHomeGroupListScreenContainer extends Component {
  debounceGetHomeGroups = debounce((filters) => {
    this.getHomeGroups(filters);
  }, 500);

  static propTypes = {
    homeGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    pagination: PropTypes.objectOf(PropTypes.any),
    filters: PropTypes.objectOf(PropTypes.any),
    isLoading: PropTypes.bool.isRequired,
    fetchHomeGroups: PropTypes.func.isRequired,
    createHomeGroup: PropTypes.func.isRequired,
    editHomeGroup: PropTypes.func.isRequired,
    clearHomeGroups: PropTypes.func.isRequired,
    updateFilters: PropTypes.func.isRequired,
    history: historyType,
    setErrors: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    generateRefs: PropTypes.func.isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      homeGroup: {},
      isModalOpen: false,
      isValidationEnabled: false,
    };
  }

  componentDidMount() {
    const { generateRefs, homeGroups } = this.props;

    generateRefs(Object.keys(validationSchema.fields));
    if (!homeGroups || homeGroups.length === 0) {
      this.getHomeGroups();
    }
  }

  componentWillUnmount = () => {
    const { clearHomeGroups } = this.props;
    clearHomeGroups();
  }

  getHomeGroups = (filters) => {
    const { fetchHomeGroups } = this.props;
    fetchHomeGroups(filters);
  }

  handleChangeFilters = (key, value) => {
    const { filters, updateFilters } = this.props;

    if (value === filters[key]) {
      return;
    }
    updateFilters(key, value);
    this.debounceGetHomeGroups({ ...filters, [key]: value });
  }

  handleHomeGroupChange = (key, value) => {
    const { validate } = this.props;
    const { homeGroup } = this.state;

    const newHomeGroup = {
      ...homeGroup,
      [key]: value,
    };

    validate(newHomeGroup, validationSchema);

    this.setState({ homeGroup: newHomeGroup });
  }

  handleModalOpen = (homeGroup) => {
    const { setErrors } = this.props;

    setErrors({});

    this.setState({
      isModalOpen: true,
      isValidationEnabled: false,
      homeGroup,
    });
  }

  handleCreate = async () => {
    const {
      createHomeGroup, validate, history,
    } = this.props;
    const { homeGroup } = this.state;

    this.setState({ isValidationEnabled: true });

    if (!validate(homeGroup, validationSchema, true)) {
      return;
    }

    createHomeGroup(homeGroup);
    this.setState({ isModalOpen: false });
    history.push('/homegroups');
  }

  handleEdit = async () => {
    const { editHomeGroup, validate, history } = this.props;
    const { homeGroup } = this.state;

    this.setState({ isValidationEnabled: true });

    if (!validate(homeGroup, validationSchema, true)) {
      return;
    }

    editHomeGroup(homeGroup);
    this.setState({ isModalOpen: false });
    history.push('/homegroups');
  }

  handleCancel = () => {
    this.setState({ isModalOpen: false });
  }

  render() {
    const {
      homeGroup,
      isModalOpen,
      isValidationEnabled,
    } = this.state;
    const {
      homeGroups,
      pagination,
      filters,
      isLoading,
      formRefs,
      errors,
    } = this.props;

    return (
      <AdminHomeGroupListScreen
        homeGroups={homeGroups}
        selectedHomeGroup={homeGroup}
        hasMoreHomeGroups={homeGroups.length > 0 && pagination.hasNextPage}
        getHomeGroups={this.getHomeGroups}
        isLoading={isLoading}
        isModalOpen={isModalOpen}
        filters={filters}
        onHomeGroupChange={this.handleHomeGroupChange}
        onChangeFilters={this.handleChangeFilters}
        onSave={(homeGroup && homeGroup.id) ? this.handleEdit : this.handleCreate}
        onCancel={this.handleCancel}
        onModalOpen={this.handleModalOpen}
        formRefs={formRefs}
        errors={isValidationEnabled ? errors : {}}
      />
    );
  }
}

const mapStateToProps = state => ({
  homeGroups: state.homeGroupsStore.homeGroups,
  pagination: state.homeGroupsStore.pagination,
  filters: state.homeGroupsStore.filters,
  isLoading: state.homeGroupsStore.isLoading,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchHomeGroups: fetchHomeGroupsAction,
  createHomeGroup: createHomeGroupAction,
  editHomeGroup: editHomeGroupAction,
  clearHomeGroups: clearHomeGroupsAction,
  updateFilters: updateFiltersAction,
}, dispatch);

export default withValidation(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(
      withValidation(AdminHomeGroupListScreenContainer),
    ),
  ),
);
