import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import EmailIcon from '@material-ui/icons/Email';
import LocationIcon from '@material-ui/icons/LocationOn';
import WebsiteIcon from '@material-ui/icons/Business';
import EditIcon from '@material-ui/icons/Edit';
import RemoveIcon from '@material-ui/icons/Remove';
import { directoryListingType } from 'types/directoryListing';
import {
  isAddressEmpty, buildAddressString, buildPhonesString, buildEmailsString, buildWebsitesString,
} from 'services/utils';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const DirectoryListingCard = ({
  directoryListing,
  customClass,
  disabled,
  errors,
  onEdit,
  onRemove,
}) => {
  const {
    name,
    phones,
    emails,
    websites,
    address,
  } = directoryListing;

  return (
    <div
      data-test-id="directoryListingCard"
      className={rcStyles.directory_item}
    >
      <div className={classNames(
        rcStyles.directory_item__card,
        customClass,
      )}
      >
        <div className={classNames(
          styles.o_fb,
          styles.u_push__bottom,
        )}
        >
          <div className={styles.long_primer}>{name}</div>
          {onEdit && (
            <div className={rcStyles.is_card_action}>
              <IconButton
                onClick={() => onEdit(directoryListing)}
                color="primary"
                variant="contained"
                size="small"
                disabled={disabled}
                data-test-id="edit"
              >
                <EditIcon />
              </IconButton>
            </div>
          )}
        </div>
        <ul className={classNames(
          styles.o_list,
          styles.is_simple,
        )}
        >
          {phones.length > 0 && (
            <li className={styles.o_list__item}>
              <div className={styles.o_list_item__icon}>
                <SmartphoneIcon />
              </div>
              <div className={styles.o_list_item__text}>
                {buildPhonesString(phones)}
              </div>
            </li>
          )}
          {emails && emails.length > 0 && (
            <li className={styles.o_list__item}>
              <div className={styles.o_list_item__icon}>
                <EmailIcon />
              </div>
              <div className={styles.o_list_item__text}>
                {buildEmailsString(emails)}
              </div>
            </li>
          )}
          {websites && websites.length > 0 && (
            <li className={styles.o_list__item}>
              <div className={styles.o_list_item__icon}>
                <WebsiteIcon />
              </div>
              <div className={styles.o_list_item__text}>
                {buildWebsitesString(websites)}
              </div>
            </li>
          )}
          {!isAddressEmpty(address) && (
            <li className={styles.o_list__item}>
              <div className={styles.o_list_item__icon}>
                <LocationIcon />
              </div>
              <div className={styles.o_list_item__text}>
                {buildAddressString(address)}
              </div>
            </li>
          )}
          {errors?.address && (
            <li className={styles.o_list__item}>
              <div className={classNames(styles.o_list_item__icon, rcStyles.address_required)}>
                <LocationIcon className={rcStyles.is_icon} />
              </div>
              <div className={classNames(styles.o_list_item__text, rcStyles.address_required)}>
                {errors?.address}
              </div>
            </li>
          )}
        </ul>
      </div>
      <div className={rcStyles.directory_item__action}>
        {onRemove && (
          <IconButton
            onClick={onRemove}
            color="primary"
            variant="contained"
            size="small"
            data-test-id="remove"
            disabled={disabled}
            className={styles.o_button__secondary}
          >
            <RemoveIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};

DirectoryListingCard.propTypes = {
  directoryListing: directoryListingType.isRequired,
  customClass: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.objectOf(PropTypes.any),
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
};

export default DirectoryListingCard;
