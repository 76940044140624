import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from 'components/common/Select';
import DateTimePicker from 'components/common/DateTimePicker';
import { serviceTypes } from 'constants/bereavement';

import rcStyles from './styles.scss';

const OrganistFormSection = ({
  fieldData,
  disabled,
  onChange,
  venueCategoryInformation,
}) => {
  const defaultStartDateTime = venueCategoryInformation
    && venueCategoryInformation[fieldData.serviceType]
    && venueCategoryInformation[fieldData.serviceType].startDateTime;

  return (
    <FormGroup>
      <div className={rcStyles.v_spacing}>
        <div>
          <Select
            value={fieldData.serviceType}
            onChange={event => onChange('serviceType', event.target.value)}
            name="servicetype"
            label={t('Service')}
            inputProps={{ 'data-test-id': 'serviceType' }}
            disabled={disabled}
          >
            {serviceTypes.map((serviceType, index) => (
              <MenuItem key={index} value={serviceType.value}>{t(serviceType.name)}</MenuItem>
            ))}
          </Select>
        </div>
        <DateTimePicker
          value={fieldData.dateTime || defaultStartDateTime || null}
          placeholder={t('Date & Time')}
          onChange={date => onChange('dateTime', date)}
          isClearable
          disabled={disabled}
          data-test-id="dateTime"
          label={t('Date & time')}
        />
        <div className={rcStyles.colspan2}>
          <TextField
            label={t('Organist')}
            name="name"
            fullWidth
            value={fieldData.name || ''}
            onChange={event => onChange('name', event.target.value)}
            inputProps={{ 'data-test-id': 'name' }}
            disabled={disabled}
          />
        </div>
      </div>
    </FormGroup>
  );
};

OrganistFormSection.propTypes = {
  fieldData: PropTypes.objectOf(PropTypes.any).isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  venueCategoryInformation: PropTypes.objectOf(PropTypes.any),
};

export default OrganistFormSection;
