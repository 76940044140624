import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getAvailableNavigation } from 'services/utils/navigation';
import { bereavementType } from 'types/bereavement';

import CaseSideMenu from './CaseSideMenu';

class CaseSideMenuContainer extends Component {
  static propTypes = {
    arrangementCategoryDisplayPreferences: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
    availableFeatures: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    bereavement: bereavementType.isRequired,
    isOpen: PropTypes.bool.isRequired,
    scopes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    tenantCategories: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    url: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
    };
  }

  handleModalToggle = (isModalOpen) => {
    if (isModalOpen) {
      const { onClose } = this.props;
      onClose();
    }
    this.setState({
      isModalOpen,
    });
  }

  render() {
    const {
      arrangementCategoryDisplayPreferences,
      availableFeatures,
      tenantCategories,
    } = this.props;
    const { isModalOpen } = this.state;

    const getArrangementNavigation = committalType => getAvailableNavigation(
      availableFeatures,
      tenantCategories,
      arrangementCategoryDisplayPreferences,
      committalType,
      true,
    );

    return (
      <CaseSideMenu
        {...this.props}
        getArrangementNavigation={getArrangementNavigation}
        isModalOpen={isModalOpen}
        onModalOpen={() => this.handleModalToggle(true)}
        onModalClose={() => this.handleModalToggle(false)}
      />
    );
  }
}

const mapStateToProps = state => ({
  availableFeatures: state.userStore.user.tenantFeatureFlags,
  tenantCategories: state.userStore.user.tenantCategories,
  arrangementCategoryDisplayPreferences: state.userStore.user.arrangementCategoryDisplayPreferences,
});

export default connect(mapStateToProps)(CaseSideMenuContainer);
