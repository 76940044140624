import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import { caseStatuses } from 'constants/bereavement';
import { bereavementType, arrangementType } from 'types/bereavement';
import { apolloClientType } from 'types/apollo';
import ArrangementFlowersForm from './ArrangementFlowersForm';
import { editArrangementFlowersInformation } from './mutations.gql';

class ArrangementFlowersFormContainer extends Component {
  static propTypes = {
    bereavement: bereavementType.isRequired,
    arrangement: arrangementType.isRequired,
    client: apolloClientType.isRequired,
    onSave: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      categoryInformation: {
        isFamilyArranged: false,
        isReturnToFamily: false,
        deliveredToLocation: null,
        otherDeliveryLocation: null,
      },
    };
  }

  componentDidMount() {
    const { arrangement } = this.props;

    if (arrangement.categoryInformation && arrangement.categoryInformation.flowers) {
      this.setState({ categoryInformation: arrangement.categoryInformation.flowers });
    }
  }

  handleSave = () => {
    this.setState({ isSaving: true });
    const {
      client, bereavement, arrangement, onSave,
    } = this.props;
    const { categoryInformation } = this.state;
    onSave(bereavement.id, arrangement.id, 'flowers', categoryInformation);

    const input = {
      bereavementId: bereavement.id,
      arrangementId: arrangement.id,
      ...categoryInformation,
    };

    client.mutate({
      mutation: editArrangementFlowersInformation,
      variables: { input },
    }).finally(() => this.setState({ isSaving: false }));
  }

  handleChange = (key, value) => {
    const { categoryInformation } = this.state;
    const updatedCategoryInformation = {
      ...categoryInformation,
      [key]: value,
    };
    if (key === 'isFamilyArranged' && value) {
      updatedCategoryInformation.isConfirmed = null;
    }

    this.setState({ categoryInformation: updatedCategoryInformation });

    if (key === 'deliveredToLocation') {
      this.setState(prevState => ({
        categoryInformation: {
          ...prevState.categoryInformation,
          otherDeliveryLocation: null,
        },
      }));
    }
  }

  render() {
    const { bereavement } = this.props;
    const { isSaving, categoryInformation } = this.state;

    return (
      <ArrangementFlowersForm
        categoryInformation={categoryInformation}
        disabled={isSaving || bereavement.caseStatus === caseStatuses.CLOSED}
        isSaving={isSaving}
        onChange={this.handleChange}
        onSave={this.handleSave}
      />
    );
  }
}


export default withApollo(ArrangementFlowersFormContainer);
