import i18next from 'i18next';
import en from './en';

i18next.init({
  lng: 'en',
  nsSeparator: false,
  keySeparator: false,
  fallbackLng: false,
  react: {
    wait: true,
  },
  resources: {
    en,
  },
});
