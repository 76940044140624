import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';

import {
  fetchArrangementCataloguePackagesAction,
  orderArrangementPackagesAsyncAction,
} from 'actions/catalogue';
import { scopes as permissions } from 'constants/scopes';
import { hasPermissions, arrayMove } from 'services/utils';
import { historyType } from 'types/reactRouter';

import AdminArrangementPackagesOrderScreen from './AdminArrangementPackagesOrderScreen';

const sortByArrangementOrder = items => items
  .sort((item1, item2) => item2.arrangementOrder - item1.arrangementOrder);

class AdminArrangementPackagesOrderScreenContainer extends Component {
  static propTypes = {
    scopes: PropTypes.arrayOf(PropTypes.string.isRequired),
    packages: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
    history: historyType.isRequired,
    fetchPackages: PropTypes.func.isRequired,
    orderArrangementPackages: PropTypes.func.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { packages } = nextProps;
    if (prevState.items.length === 0 || (packages.length > prevState.items.length)) {
      const { items: prevItems } = prevState;
      const newItems = sortByArrangementOrder(
        packages.filter(({ id }) => !prevItems.find(({ id: prevItemId }) => id === prevItemId)),
      );
      const items = [
        ...prevItems,
        ...newItems,
      ];

      return {
        items,
        isLoading: false,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      items: [],
      isSortEnabled: false,
      isSaving: false,
    };
  }

  componentDidMount() {
    const {
      packages,
      scopes,
      fetchPackages,
    } = this.props;

    if (hasPermissions([permissions.PACKAGES_READ], scopes)) {
      if (packages.length === 0) {
        fetchPackages();
      }

      if (packages.length > 0) {
        const items = sortByArrangementOrder(packages);
        this.setState({ items });
      }
    }
  }

  handleCancel = () => {
    const { history } = this.props;
    history.push('/catalogue/arrangement-settings');
  }

  handleSave = () => {
    const { orderArrangementPackages, enqueueSnackbar } = this.props;
    const { items } = this.state;

    this.setState({ isSaving: true });

    orderArrangementPackages(items, () => {
      this.setState({ isSaving: false });
      enqueueSnackbar(
        t('Order and visibility saved successfully'),
        {
          variant: 'success',
        },
      );
      this.handleCancel();
    });
  }

  handleSetIsVisible = (id, isVisible) => {
    const { items } = this.state;
    const updatedItems = items.map((item) => {
      const updatedItem = { ...item };
      if (updatedItem.id === id) {
        updatedItem.arrangementVisibility = isVisible;
      }
      return updatedItem;
    });
    this.setState({ items: updatedItems });
  }

  handleSortItems = ({ oldIndex, newIndex }) => {
    const { items } = this.state;
    const reorderedItems = arrayMove(items, oldIndex, newIndex);
    this.setState({ items: reorderedItems });
  }

  handleToggleIsSortEnabled = () => {
    this.setState(prevState => ({
      isSortEnabled: !prevState.isSortEnabled,
    }));
  }

  render() {
    const { items, isSaving, isSortEnabled } = this.state;

    return (
      <AdminArrangementPackagesOrderScreen
        items={items}
        isLoading={items.length === 0}
        isSaving={isSaving}
        isSortEnabled={isSortEnabled}
        onCancel={this.handleCancel}
        onSave={this.handleSave}
        onSetIsVisible={this.handleSetIsVisible}
        onSortItems={this.handleSortItems}
        onToggleIsSortEnabled={this.handleToggleIsSortEnabled}
      />
    );
  }
}

const mapStateToProps = state => ({
  scopes: state.userStore.user.policy.scopes,
  packages: state.catalogueStore.packages.items,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchPackages: fetchArrangementCataloguePackagesAction,
  orderArrangementPackages: orderArrangementPackagesAsyncAction,
}, dispatch);

export default withRouter(
  withSnackbar(
    connect(mapStateToProps, mapDispatchToProps)(AdminArrangementPackagesOrderScreenContainer),
  ),
);
