import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import { arrangementType } from 'types/bereavement';
import { apolloClientType } from 'types/apollo';
import { catalogueItemTypes } from 'constants/arrangement';
import {
  editProductSelectionAction,
  editServiceSelectionAction,
  removeProductSelectionAction,
  removeServiceSelectionAction,
  unconfirmArrangementAction,
} from 'actions/arrangement';
import {
  editProductSelectionMutation,
  editServiceSelectionMutation,
  removeProductSelectionMutation,
  removeServiceSelectionMutation,
} from './mutations.gql';
import ArrangementSelectedItemList from './ArrangementSelectedItemList';

class ArrangementSelectedItemListContainer extends Component {
  static propTypes = {
    client: apolloClientType.isRequired,
    bereavementId: PropTypes.string.isRequired,
    arrangement: arrangementType.isRequired,
    type: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    editProductSelection: PropTypes.func.isRequired,
    editServiceSelection: PropTypes.func.isRequired,
    removeProductSelection: PropTypes.func.isRequired,
    removeServiceSelection: PropTypes.func.isRequired,
    unconfirmArrangement: PropTypes.func.isRequired,
    selectedItemRef: PropTypes.objectOf(PropTypes.any),
  }

  constructor(props) {
    super(props);

    this.state = {
      selectionToChange: null,
      variantToChange: null,
    };
  }

  handleVariantChange = (selectionId, variantId) => {
    const { arrangement } = this.props;

    if (arrangement.isConfirmed) {
      this.setState({ selectionToChange: selectionId, variantToChange: variantId });
    } else {
      this.changeVariant(selectionId, variantId);
    }
  }

  handleVariantChangeConfirm = () => {
    const { bereavementId, arrangement, unconfirmArrangement } = this.props;
    const { selectionToChange, variantToChange } = this.state;
    this.setState({ selectionToChange: null, variantToChange: null });
    unconfirmArrangement(bereavementId, arrangement.id);
    this.changeVariant(selectionToChange, variantToChange);
  }

  changeVariant = (selectionId, variantId) => {
    const {
      client, bereavementId, arrangement, type,
      editProductSelection, editServiceSelection,
    } = this.props;

    const editSelectionMethod = type === catalogueItemTypes.PRODUCT
      ? editProductSelection
      : editServiceSelection;
    const editSelectionMutation = type === catalogueItemTypes.PRODUCT
      ? editProductSelectionMutation
      : editServiceSelectionMutation;

    const input = {
      bereavementId,
      arrangementId: arrangement.id,
      selectionId,
      variantId,
    };

    editSelectionMethod(bereavementId, arrangement.id, selectionId, { variantId });
    client.mutate({
      mutation: editSelectionMutation,
      variables: { input },
    });
  }

  handleRemoveSelection = (selectionId) => {
    const { arrangement } = this.props;

    if (arrangement.isConfirmed) {
      this.setState({ selectionToChange: selectionId });
    } else {
      this.removeSelection(selectionId);
    }
  }

  handleRemoveSelectionConfirm = () => {
    const { arrangement, unconfirmArrangement } = this.props;
    const { selectionToChange } = this.state;
    this.setState({ selectionToChange: null });
    unconfirmArrangement(arrangement.id);
    this.removeSelection(selectionToChange);
  }

  removeSelection = (selectionId) => {
    const {
      client, bereavementId, arrangement, type,
      removeProductSelection, removeServiceSelection,
    } = this.props;

    const removeSelectionMethod = type === catalogueItemTypes.PRODUCT
      ? removeProductSelection
      : removeServiceSelection;
    const removeSelectionMutation = type === catalogueItemTypes.PRODUCT
      ? removeProductSelectionMutation
      : removeServiceSelectionMutation;

    const input = {
      bereavementId,
      arrangementId: arrangement.id,
      selectionId,
    };

    removeSelectionMethod(bereavementId, arrangement.id, selectionId);
    client.mutate({
      mutation: removeSelectionMutation,
      variables: { input },
    });
  }

  handleConfirm = () => {
    const { selectionToChange, variantToChange } = this.state;

    if (variantToChange) {
      this.handleVariantChangeConfirm();
    }

    if (selectionToChange && !variantToChange) {
      this.handleRemoveSelectionConfirm();
    }
  }

  handleCancel = () => {
    const { selectionToChange, variantToChange } = this.state;

    if (variantToChange) {
      this.setState({ selectionToChange: null });
    }

    if (selectionToChange && !variantToChange) {
      this.setState({
        selectionToChange: null,
        variantToChange: null,
      });
    }
  }

  render() {
    const {
      arrangement,
      type,
      category,
      disabled,
      selectedItemRef,
    } = this.props;
    const { selectionToChange } = this.state;

    const selections = arrangement[`${type.toLowerCase()}Selections`]
      ? arrangement[`${type.toLowerCase()}Selections`].filter(selection => selection[`${type.toLowerCase()}`].category === category)
      : [];
    const packageSelections = selections
      && selections.filter(selection => selection.isPackageSelection);
    const nonPackageSelections = selections
      && selections.filter(selection => !selection.isPackageSelection);

    return (
      <ArrangementSelectedItemList
        packageSelections={packageSelections}
        nonPackageSelections={nonPackageSelections}
        type={type}
        disabled={disabled}
        selectionToChange={selectionToChange}
        onRemoveSelection={this.handleRemoveSelection}
        onVariantChange={this.handleVariantChange}
        selectedItemRef={selectedItemRef}
        onConfirm={this.handleConfirm}
        onCancel={this.handleCancel}
      />
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  editProductSelection: editProductSelectionAction,
  editServiceSelection: editServiceSelectionAction,
  removeProductSelection: removeProductSelectionAction,
  removeServiceSelection: removeServiceSelectionAction,
  unconfirmArrangement: unconfirmArrangementAction,
}, dispatch);

export default withApollo(
  connect(null, mapDispatchToProps)(ArrangementSelectedItemListContainer),
);
