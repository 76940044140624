import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import PropTypes from 'prop-types';
import { generateHexId } from 'services/utils';
import { noteCategories, ALL_NOTES } from 'constants/bereavement';
import { staffMemberType } from 'types/staffMember';
import { noteType } from 'types/common';
import { validationSchema } from './validation';
import NotesModal from './NotesModal';

class NotesModalContainer extends Component {
  static propTypes = {
    notesList: PropTypes.arrayOf(noteType),
    currentStaff: staffMemberType.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any),
    isOpen: PropTypes.bool.isRequired,
    restrictedCategories: PropTypes.arrayOf(PropTypes.string),
    defaultCategory: PropTypes.string,
  }

  static defaultProps = {
    notesList: [],
    defaultCategory: ALL_NOTES,
  }

  constructor(props) {
    super(props);

    const { notesList, restrictedCategories, defaultCategory } = this.props;

    let filteredNotes = notesList;
    let additionalNotes = [];
    if (notesList) {
      filteredNotes = notesList.filter(note => !restrictedCategories.includes(note.category));
      additionalNotes = notesList.filter(note => restrictedCategories.includes(note.category));
    }

    this.state = {
      notesList: filteredNotes || [],
      additionalNotes: additionalNotes || [],
      isValidationEnabled: false,
      selectedCategory: defaultCategory,
      didNotesChange: false,
    };
  }

  componentDidMount() {
    const { notesList } = this.state;
    const { validate } = this.props;

    validate(notesList, validationSchema);
  }

  handleAdd = (value) => {
    if (!value) {
      return;
    }
    const { notesList, selectedCategory } = this.state;
    const currentSelectedCategory = selectedCategory !== ALL_NOTES && selectedCategory;
    const updatedNote = {
      ...value,
      id: value.id || generateHexId(),
      category: value.category || currentSelectedCategory || noteCategories.GENERAL,
    };
    const updatedNotesList = [
      ...notesList,
      updatedNote,
    ];

    this.setState({
      notesList: updatedNotesList,
      didNotesChange: true,
    });
  }

  handleChange = (value) => {
    if (!value) {
      return;
    }
    const { validate } = this.props;
    const { notesList } = this.state;
    const updatedNotesList = notesList.map(note => (note.id === value.id ? value : note));

    validate(updatedNotesList, validationSchema);

    this.setState({
      notesList: updatedNotesList,
      didNotesChange: true,
    });
  }

  handleRemove = (value) => {
    if (!value) {
      return;
    }

    const { validate } = this.props;
    const { isValidationEnabled, notesList } = this.state;
    const updatedNotesList = notesList.filter(note => note.id !== value.id);

    if (isValidationEnabled) {
      validate(updatedNotesList, validationSchema);
    }

    this.setState({
      notesList: updatedNotesList,
      didNotesChange: true,
    });
  }

  saveNotes = () => {
    const {
      currentStaff,
      onSave,
      validate,
    } = this.props;
    const { notesList, additionalNotes } = this.state;

    this.setState({ isValidationEnabled: true });

    const isValid = validate(notesList, validationSchema);
    if (!isValid) {
      return false;
    }

    const notes = [...notesList, ...additionalNotes].map(note => ({
      id: note.id || generateHexId(),
      content: note.content,
      category: note.category,
      createdDateTime: note.createdDateTime || new Date().toISOString(),
      createdById: note.createdById || currentStaff.id,
      createdBy: note.createdBy || currentStaff,
      updatedDateTime: new Date().toISOString(),
      updatedById: currentStaff.id,
      updatedBy: currentStaff,
    }));

    onSave(notes);
    return true;
  }

  closeModal = () => {
    const { notesList } = this.state;
    const { onClose } = this.props;

    this.setState({
      notesList,
      isValidationEnabled: false,
      didNotesChange: false,
      selectedCategory: ALL_NOTES,
    });

    onClose();
  }

  handleSave = () => {
    const { didNotesChange } = this.state;
    const notesSaved = didNotesChange && this.saveNotes();

    if (notesSaved || !didNotesChange) {
      this.closeModal();
    }
  }

  handleSelectCategory = (category) => {
    const { didNotesChange } = this.state;
    const notesSaved = didNotesChange && this.saveNotes();

    if (notesSaved || !didNotesChange) {
      this.setState({
        selectedCategory: category,
        isValidationEnabled: false,
        didNotesChange: false,
      });
    }
  }

  render() {
    const {
      currentStaff, errors, disabled, isOpen, restrictedCategories,
    } = this.props;
    const { notesList, isValidationEnabled, selectedCategory } = this.state;

    return (
      <NotesModal
        isOpen={isOpen}
        notesList={notesList || []}
        currentStaff={currentStaff}
        disabled={disabled}
        errors={isValidationEnabled ? errors : {}}
        selectedCategory={selectedCategory}
        onSelectCategory={this.handleSelectCategory}
        restrictedCategories={restrictedCategories}
        onSave={this.handleSave}
        onAdd={this.handleAdd}
        onChange={this.handleChange}
        onRemove={this.handleRemove}
        onClose={this.handleSave}
      />
    );
  }
}

const mapStateToProps = state => ({
  currentStaff: state.userStore.user.staffMember,
});

export default withValidation(
  connect(mapStateToProps)(NotesModalContainer),
);
