import React from 'react';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';

import { Props } from './index.types';

const DefaultScreenHelmet: React.FC<Props> = ({ title, description }: Props) => (
  <Helmet>
    <title>{i18next.t(title || (process.env.APP_TITLE || ''))}</title>
    <meta
      name="description"
      content={i18next.t(description || 'Arranger provides everything you need to manage funeral arrangements, on one online easy-to-use platform') as string}
    />
    <style type="text/css">
      {`
        @font-face {
          font-family: "Tondo";
          src: url("/fonts/tondo/Tondo_W_Rg.woff2") format("woff2"),
            url("/fonts/tondo/Tondo_W_Rg.woff") format("woff"),
            url("/fonts/tondo/Tondo_W_Rg.eot") format("opentype");
          font-display: auto;
          font-style: normal;
          font-weight: 400;
        }

        @font-face {
          font-family: "Tondo";
          src: url("/fonts/tondo/Tondo_W_Bd.woff2") format("woff2"),
            url("/fonts/tondo/Tondo_W_Bd.woff") format("woff"),
            url("/fonts/tondo/Tondo_W_Bd.eot") format("opentype");
          font-display: auto;
          font-style: normal;
          font-weight: 700;
        }

        @font-face {
          font-family: "Tondo";
          src: url("/fonts/tondo/Tondo_W_Lt.woff2") format("woff2"),
            url("/fonts/tondo/Tondo_W_Lt.woff") format("woff"),
            url("/fonts/tondo/Tondo_W_Lt.eot") format("opentype");
          font-display: auto;
          font-style: normal;
          font-weight: 300;
        }

        body,
        #root {
          -webkit-font-smoothing: antialiased;
          /*  nasty fix to override a mui 'feature' that adds 15px padding to
            *  the body when opening a select list
            *  forcing this rule to override it
            */
          padding-right: 0 !important;
          margin: 0;
          padding: 0;
          font-family: 'Tondo', sans-serif;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
          margin: 0;
        }

        hr {
          border: 0;
          height: 1px;
          background: hsla(269, 21%, 95%, 1);
          margin: 2rem 0;
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0;
        }

        a {
          text-decoration: none;
          outline: none;
        }
      `}
    </style>
  </Helmet>
);

export default DefaultScreenHelmet;
