import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { packageType } from 'types/bereavement';
import PackageItem from './PackageItem';

class PackageItemContainer extends Component {
  static propTypes = {
    packageItem: packageType,
    onSelect: PropTypes.func.isRequired,
    onUnselect: PropTypes.func,
    isBespoke: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    focusNewSelection: PropTypes.func,
    disableFocusSelection: PropTypes.bool,
  }

  constructor(props) {
    super(props);

    this.state = {
      isPopUpOpen: false,
    };
  }

  handleView = () => {
    this.setState({ isPopUpOpen: true });
  }

  handleClose = () => {
    this.setState({ isPopUpOpen: false });
  }

  handleSelect = () => {
    const {
      onSelect,
      onUnselect,
      packageItem,
      isSelected,
      focusNewSelection,
      disableFocusSelection,
    } = this.props;
    const callback = isSelected ? onUnselect : onSelect;

    callback(packageItem ? packageItem.id : undefined);

    this.setState({ isPopUpOpen: false });

    if (!disableFocusSelection) {
      focusNewSelection();
    }
  }

  render() {
    const {
      packageItem,
      isBespoke,
      isSelected,
      disabled,
    } = this.props;
    const { isPopUpOpen } = this.state;

    return (
      <PackageItem
        isBespoke={isBespoke}
        isSelected={isSelected}
        packageItem={packageItem}
        isPopUpOpen={isPopUpOpen}
        disabled={disabled}
        onSelect={this.handleSelect}
        onView={this.handleView}
        onClose={this.handleClose}
      />
    );
  }
}

export default PackageItemContainer;
