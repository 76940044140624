import {
  string, object, boolean,
} from 'yup';

export const validationSchema = object().shape({
  viewingNotAdvised: boolean().nullable(),
  reasonForViewingNotAdvised: string().nullable(),
  firstOffices: object().shape({
    eyeCare: object().shape({
      hasEyeCareBeenCompleted: boolean(),
      other: string().nullable(),
    }),
    mouthCare: object().shape({
      haveDenturesBeenInserted: boolean(),
      haveSuturesBeenClosed: boolean(),
      other: string().nullable(),
    }),
    disinfection: object().shape({
      eyesHaveBeenDisinfected: boolean(),
      noseHasBeenDisinfected: boolean(),
      mouthHasBeenDisinfected: boolean(),
      remainsHaveBeenDisinfected: boolean(),
      orificesHaveBeenPacked: boolean(),
    }),
    notes: string().nullable(),
  }),
  mortuary: object().shape({
    bodyHasBeenEmbalmed: boolean().nullable(),
    bodyHasBeenDressed: boolean(),
    bodyHasBeenEncoffined: boolean(),
    notes: string().nullable(),
  }),
});
