import styled from 'styled-components';

const Card = styled.div<{ imagePath: string }>`
  border: 1px solid #D8D8D8;
  border-radius: 3px;
  box-shadow: none;
  display: grid;
  grid-template-areas:
    "image"
    "label"
  ;
  grid-template-rows: 160px auto;
  transition: all 0.4s cubic-bezier(.25,.8,.25,1);

  &:hover {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }

  & .card_image {
    background-image: ${props => props?.imagePath && `url('${props.imagePath}')`};
    background-position: center;
    background-size: cover;
    content: '';
    grid-area: image;
    height: 160px;
  }

  & .card_title {
    color: #544451;
    font-size: 1rem;
    grid-area: label;
    line-height: 1;
    padding: 0.5rem;

    &:visited {
      color: #544451;
    }

    @media screen and (min-width: 768px) {
      font-size: 1.125rem;
      padding: 1rem;
    }
  }
`;

export default {
  Card,
};
