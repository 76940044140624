import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import moment from 'moment';
import classNames from 'classnames';

import { buildAddressString, buildNameString } from 'services/utils';

import styles from './styles.scss';

const CalendarEventContentViewing = ({
  start,
  caseOwner,
  location,
  deceasedName,
  bereavedName,
}) => (
  <Fragment>
    <div className={styles.rbc_dialog__body}>
      <div className={styles.rbc_dialog__item}>
        <div className={styles.is_label}>
          {t('Date')}
        </div>
        <div className={classNames(styles.rbc_dialog__item_value)}>
          {moment(start).format('DD MMM YYYY')}
        </div>
      </div>
      <div className={styles.rbc_dialog__item}>
        <div className={styles.is_label}>
          {t('Viewing time')}
        </div>
        <div className={styles.is_value}>
          {moment(start).format('HH:mm')}
        </div>
      </div>
      {deceasedName && (
        <div className={styles.rbc_dialog__item}>
          <div className={styles.is_label}>
            {t('Deceased name')}
          </div>
          <div className={styles.is_value}>
            {buildNameString(deceasedName)}
          </div>
        </div>
      )}
      {bereavedName && (
        <div className={styles.rbc_dialog__item}>
          <div className={styles.is_label}>
            {t('Client name')}
          </div>
          <div className={styles.is_value}>
            {buildNameString(bereavedName)}
          </div>
        </div>
      )}
      {caseOwner && (
        <div className={styles.rbc_dialog__item}>
          <div className={styles.is_label}>
            {t('Funeral owner')}
          </div>
          <div className={styles.is_value}>
            {buildNameString(caseOwner.name)}
          </div>
        </div>
      )}
      {location && (
        <div className={classNames(
          styles.rbc_dialog__item,
          styles.rbc_dialog__item__wide,
        )}
        >
          <div className={styles.is_label}>
            {t('Viewing location')}
          </div>
          <div className={styles.is_value}>
            <address>
              {buildAddressString(location)}
            </address>
          </div>
        </div>
      )}
    </div>
  </Fragment>
);

CalendarEventContentViewing.propTypes = {
  start: PropTypes.instanceOf(Date).isRequired,
  deceasedName: PropTypes.objectOf(PropTypes.any).isRequired,
  bereavedName: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  caseOwner: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default CalendarEventContentViewing;
