import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import AddressFormSection from 'components/common/AddressFormSection';
import DatePicker from 'components/common/DatePicker';
import DateTimePicker from 'components/common/DateTimePicker';
import MeasurementField from 'components/common/MeasurementField';
import Select from 'components/common/Select';
import StaffMemberLookup from 'components/common/lookups/StaffMemberLookup';
import { progressFieldType } from 'constants/task';
import styles from 'scss/main.scss';
import { mapObject } from 'services/utils';
import { taskType } from 'types/task';

const renderField = (progressField, index, onUpdate, disabled, errors) => {
  switch (progressField.type) {
    case progressFieldType.ADDRESS:
      return (
        <AddressFormSection
          lookupLabel={progressField.label}
          address={progressField.value}
          onChange={value => onUpdate(progressField.key, value)}
          disabled={disabled}
          errors={errors[index]}
          data-test-id="addressFormSection"
        />
      );
    case progressFieldType.BOOLEAN:
      return (
        <FormControl
          error={!!errors[index]}
        >
          <FormControlLabel
            control={(
              <Checkbox
                checked={progressField.value || false}
                onChange={event => onUpdate(progressField.key, event.target.checked)}
                inputProps={{ 'data-test-id': progressField.key }}
                color="primary"
              />
            )}
            label={progressField.label}
            disabled={disabled}
          />
          {errors[index] && (
            <FormHelperText>{errors[index]}</FormHelperText>
          )}
        </FormControl>
      );
    case progressFieldType.DATE:
      return (
        <DatePicker
          value={progressField.value}
          label={progressField.label}
          onChange={date => onUpdate(progressField.key, date)}
          isClearable
          errors={!!errors[index]}
          helperText={errors[index]}
          disabled={disabled}
          data-test-id={progressField.key}
        />
      );
    case progressFieldType.DATE_TIME:
      return (
        <DateTimePicker
          value={progressField.value}
          label={progressField.label}
          onChange={dateTime => onUpdate(progressField.key, dateTime)}
          isClearable
          disabled={disabled}
          errors={!!errors[index]}
          helperText={errors[index]}
          data-test-id={progressField.key}
        />
      );
    case progressFieldType.MEASUREMENT:
      return (
        <MeasurementField
          label={progressField.label}
          value={progressField.value || null}
          units={progressField.unit}
          disabled={disabled}
          error={errors && !!errors[index]}
          helperText={errors && errors[index]}
          onChange={measurement => onUpdate(progressField.key, measurement)}
          data-test-id="measurement-task"
        />
      );
    case progressFieldType.STAFF_MEMBER:
      return (
        <div>
          <StaffMemberLookup
            label={progressField.label}
            value={progressField.value}
            onChange={value => onUpdate(progressField.key, value)}
            disabled={disabled}
            data-test-id="staffMemberLookup"
          />
          {errors[index] && (
            <FormHelperText error>{errors[index]}</FormHelperText>
          )}
        </div>
      );
    case progressFieldType.STATIC_SELECT:
      return (
        <Select
          label={progressField.label}
          value={progressField.value}
          onChange={event => onUpdate(progressField.key, event.target.value)}
          inputProps={{
            'data-test-id': progressField.key,
          }}
          fullWidth
          disabled={disabled}
          error={!!errors[index]}
          errorText={errors[index]}
        >
          {progressField.options.map(option => (
            <MenuItem value={option?.value} key={option?.value}>{option?.label}</MenuItem>
          ))}
        </Select>
      );
    case progressFieldType.TEXT:
      return (
        <TextField
          label={progressField.label}
          value={progressField.value || ''}
          onChange={event => onUpdate(progressField.key, event.target.value)}
          fullWidth
          inputProps={{
            'data-test-id': progressField.key,
          }}
          disabled={disabled}
          error={!!errors[index]}
          helperText={errors[index]}
        />
      );
    default:
      return null;
  }
};

const TaskProgress = ({
  task,
  onUpdate,
  disabled,
  errors,
}) => (
  <Fragment>
    <h3 className={styles.long_primer}>
      {t('Task progress')}
    </h3>
    {task.progressJson.map((progressField, index) => (
      <div
        key={progressField.key}
      >
        {renderField(
          progressField,
          index,
          onUpdate,
          disabled,
          mapObject(errors, error => error.value),
        )}
      </div>
    ))}
  </Fragment>
);

TaskProgress.propTypes = {
  task: taskType.isRequired,
  onUpdate: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default TaskProgress;
