import { createHttpLink } from 'apollo-link-http';

let host = document.location.hostname;
const acceptLanguage = (navigator.languages && navigator.languages.length > 0) ? navigator.languages[0] : '';

if (host === 'localhost' || host === 'bs-local.com') {
  host = process.env.HOSTNAME;
}

const httpLink = createHttpLink({
  uri: process.env.GRAPHQL_GATEWAY_URL,
  headers: {
    'x-hostname': host || '',
    'x-client-id': 'fas-client-web',
    'x-display-errors': 1,
    'Accept-Language': acceptLanguage,
  },
});

export default httpLink;
