import React, { Fragment } from 'react';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import PersonIcon from '@material-ui/icons/Person';
import { getUriForMediaByVariant, getStartAndEndDates } from 'services/utils';
import { matchType } from 'types/reactRouter';
import { mediaVariants } from 'constants/media';
import { committalTypeLabels } from 'constants/arrangement';
import classNames from 'classnames';
import Badge from '@material-ui/core/Badge';
import IconNotes from 'icons/IconNotes';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import { noteCategoryLabels } from 'constants/bereavement';
import iconStyles from 'icons/styles.scss';
import rcStyles from './styles.scss';

const CaseCard = ({
  bereavementData,
  match,
}) => {
  const deceasedPeople = bereavementData.deceasedPeople[0];
  const imageVariantUri = deceasedPeople.image
    && getUriForMediaByVariant(deceasedPeople.image, mediaVariants.FIT_200_X_200);
  const deceasedName = `${deceasedPeople.name.givenName} ${deceasedPeople.name.familyName}`;
  const deceasedDates = getStartAndEndDates(deceasedPeople.dateOfBirth, deceasedPeople.deathDateTime);
  const ownerName = bereavementData.owner
    ? `${bereavementData.owner.name.givenName} ${bereavementData.owner.name.familyName}`
    : null;
  const homeName = bereavementData.home && bereavementData.home.name;
  const confirmedArrangement = bereavementData.arrangements.find(arrangement => arrangement.isConfirmed);
  const committalType = confirmedArrangement && committalTypeLabels
    .find(type => type.value === confirmedArrangement.committalType).label;
  const { notes } = bereavementData;
  const mostRecentNote = notes?.reduce((a, b) => {
    const lastEditedTimeA = a.updatedDateTime ?? a.createdDateTime;
    const lastEditedTimeB = b.updatedDateTime ?? b.createdDateTime;

    return lastEditedTimeA > lastEditedTimeB ? a : b;
  });

  return (
    <Link
      to={`${match.url}/${bereavementData.id}/arrangement`}
      className={classNames(
        rcStyles.case_card_parent,
        confirmedArrangement ? rcStyles.is_confirmed : rcStyles.is_unconfirmed,
      )}
    >
      <div
        className={rcStyles.case_card}
        data-test-id="caseCard"
      >
        {
          imageVariantUri ? (
            <div
              alt={deceasedName}
              style={{
                background: `url('${imageVariantUri}')`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
              className={rcStyles.case_card__image}
            />
          ) : (
            <div className={rcStyles.case_card__image}>
              <PersonIcon />
            </div>
          )
        }
        <div className={rcStyles.case_card__content}>
          <div
            className={rcStyles.is_title}
            data-test-id="caseCardDeceasedName"
          >
            {deceasedName}
          </div>
          <div className={rcStyles.is_dates}>
            {deceasedPeople.dateOfBirth ? deceasedDates : ''}
          </div>
          {confirmedArrangement ? (
            <div className={rcStyles.is_confirmed}>
              {t(committalType)}
            </div>
          ) : (
            <div className={rcStyles.is_unconfirmed}>
              {t('Unconfirmed')}
            </div>
          )}
          <div className={rcStyles.is_owners}>
            <div>{ownerName}</div>
            <div>{homeName}</div>
          </div>
        </div>
      </div>
      <div className={rcStyles.note_container}>
        { notes && (
          <IconButton
            variant="outlined"
            color="primary"
            data-test-id={`notesButton-${bereavementData.id}`}
            className={rcStyles.note_button}
          >
            <Badge
              data-test-id={`notesCounter-${bereavementData.id}`}
              badgeContent={notes.length}
              color="secondary"
              fill="primary"
            >
              <IconNotes className={iconStyles.rc_icon_primary} />
            </Badge>
          </IconButton>
        ) }
        { notes?.length && (
          <div className={rcStyles.note_popup}>
            <h3>Latest note</h3>
            <div>
              <strong>Category</strong>
              :&nbsp;
              {noteCategoryLabels[mostRecentNote.category]}
            </div>
            <div>
              { mostRecentNote.updatedBy ? (
                <Fragment>
                  <strong>Updated By</strong>
                  :&nbsp;
                  {`${mostRecentNote.updatedBy.name.givenName} ${mostRecentNote.updatedBy.name.familyName}`}
                </Fragment>
              ) : (
                <Fragment>
                  <strong>Created By</strong>
                  :&nbsp;
                  {`${mostRecentNote.createdBy.name.givenName} ${mostRecentNote.createdBy.name.familyName}`}
                </Fragment>
              ) }
            </div>
            <div>
              { mostRecentNote.updatedDateTime ? (
                <Fragment>
                  <strong>Last Updated</strong>
                  :&nbsp;
                  <time dateTime={mostRecentNote.updatedDateTime}>
                    {moment(mostRecentNote.updatedDateTime).fromNow()}
                  </time>
                </Fragment>
              ) : (
                <Fragment>
                  <strong>Created At</strong>
                  :&nbsp;
                  <time dateTime={mostRecentNote.createdDateTime}>
                    {moment(mostRecentNote.createdDateTime).fromNow()}
                  </time>
                </Fragment>
              ) }
            </div>
            <div>
              <strong>Note</strong>
              :&nbsp;
              {mostRecentNote.content}
            </div>
          </div>
        ) }
      </div>
    </Link>
  );
};

CaseCard.propTypes = {
  bereavementData: PropTypes.objectOf(PropTypes.any).isRequired,
  match: matchType.isRequired,
};

export default CaseCard;
