import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import RemoveIcon from '@material-ui/icons/Remove';
import NameFormSection from 'components/common/NameFormSection';
import AddressFormSection from 'components/common/AddressFormSection';
import PhoneFormSection from 'components/common/PhoneFormSection';
import EmailFormSection from 'components/common/EmailFormSection';
import MediaUpload from 'components/common/MediaUpload';
import DynamicList from 'components/common/DynamicList';
import Select from 'components/common/Select';
import IconAddEmail from 'icons/IconAddEmail';
import IconAddPhone from 'icons/IconAddPhone';
import { employmentStatuses } from 'constants/staffMembers';
import { staffMemberType } from 'types/staffMember';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const AdminStaffMembersForm = ({
  staffMember,
  errors,
  formRefs,
  onChange,
}) => {
  const {
    name, address, image, emails, phones, jobTitle,
  } = staffMember;
  return (
    <div className={classNames(
      styles.c_k_tile,
      rcStyles.v_spacing,
    )}
    >
      <div
        ref={formRefs.name}
        className={rcStyles.colspan3}
      >
        <NameFormSection
          name={name || {}}
          labelTitle={t('Title *')}
          labelGivenName={t('Given Name *')}
          labelFamilyName={t('Family Name *')}
          onChangeHandler={newName => onChange('name', newName)}
          errors={errors.name}
        />
      </div>
      <div
        ref={formRefs.address}
        className={rcStyles.colspan3}
      >
        <AddressFormSection
          address={address || {}}
          lookupLabel="Address *"
          onChange={newAddress => onChange('address', newAddress)}
          errors={errors.address}
          fullWidth
          data-test-id="addressFormSection"
        />
      </div>
      <div
        ref={formRefs.phones}
        className={rcStyles.colspan3}
      >
        <DynamicList
          component={(
            <PhoneFormSection hasMargin />
          )}
          addButtonComponent={
            <IconButton data-test-id="addPhone" />
          }
          removeButtonProps={{
            variant: 'outlined',
            color: 'primary',
            size: 'small',
            classes: {
              root: styles.o_button__round,
            },
          }}
          removeButtonInner={
            <RemoveIcon />
          }
          addButtonProps={{
            variant: 'contained',
            color: 'primary',
            size: 'small',
            classes: {
              root: styles.o_button__secondary,
            },
          }}
          addButtonInner={(
            <IconAddPhone />
          )}
          dataList={phones || []}
          errors={errors.phones}
          onChange={newPhone => onChange('phones', newPhone)}
          fullWidth
          secondary
          defaultEmpty
          data-test-id="phones"
        />
      </div>
      <div
        ref={formRefs.emails}
        className={rcStyles.colspan3}
      >
        <DynamicList
          component={(
            <EmailFormSection
              label={t('Email')}
              className={styles.o_fb__inline}
              fullWidth
            />
          )}
          addButtonComponent={
            <IconButton data-test-id="addEmail" />
          }
          addButtonProps={{
            variant: 'contained',
            color: 'primary',
            size: 'small',
            classes: {
              root: styles.o_button__secondary,
            },
          }}
          addButtonInner={(
            <IconAddEmail />
          )}
          removeButtonProps={{
            variant: 'outlined',
            color: 'primary',
            size: 'small',
            classes: { root: styles.o_button__round },
          }}
          removeButtonInner={
            <RemoveIcon />
          }
          dataList={emails || []}
          errors={errors.emails || []}
          onChange={newEmail => onChange('emails', newEmail)}
          fullWidth
          secondary
          defaultEmpty
          data-test-id="emails"
        />
      </div>
      <div className={rcStyles.colspan2}>
        <TextField
          label="Job Title"
          margin="normal"
          value={jobTitle || ''}
          onChange={event => onChange('jobTitle', event.target.value)}
          inputProps={{
            'data-test-id': 'jobTitle',
          }}
          fullWidth
        />
      </div>
      <div
        ref={formRefs.employmentStatus}
        className={rcStyles.colspan2}
      >
        <Select
          label={t('Employment Status')}
          value={staffMember.employmentStatus}
          onChange={event => onChange('employmentStatus', event.target.value)}
          inputProps={{
            'data-test-id': 'employmentStatus',
          }}
          fullWidth
          errorText={errors.employmentStatus}
        >
          {employmentStatuses.map(employmentStatus => (
            <MenuItem
              key={employmentStatus.id}
              value={employmentStatus.id}
            >
              {employmentStatus.name}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className={rcStyles.colspan2}>
        <MediaUpload
          images={image ? [image] : []}
          acceptImages
          provisionalUploadMessage={t('')}
          showImagePreview
          contentDisposition="inline"
          onUploadDone={([newImage]) => onChange('image', newImage)}
          data-test-id="staffMemberImageUpload"
        />
      </div>
    </div>
  );
};

AdminStaffMembersForm.propTypes = {
  staffMember: staffMemberType,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AdminStaffMembersForm;
