import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import WarningIcon from '@material-ui/icons/Warning';

import DocumentWarning from 'icons/DocumentWarning';
import styles from 'scss/main.scss';
import { startCase } from 'services/utils';

import rcStyles from './styles.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const CorrespondenceReportModal = ({
  report,
  isOpen,
  showDefaultValuesInLetter,
  onContinue,
  onCancel,
  onChange,
}) => (
  <Dialog
    onClose={onCancel}
    aria-labelledby="correspondence-report-modal"
    fullWidth
    maxWidth="sm"
    open={isOpen}
    TransitionComponent={Transition}
    data-test-id="correspondence-report-modal"
    disableBackdropClick
    disableEscapeKeyDown
  >
    <DialogContent
      className={styles.c_dialog__content}
      data-test-id="templateReportDialogContent"
    >
      <div className={classNames(
        styles.u_push__top,
      )}
      >
        <div className={rcStyles.template_report}>
          <div className={rcStyles.report_outcome_icon_wrapper}>
            <DocumentWarning />
          </div>
          <div>
            <span><b>{t('Funeral information is missing')}</b></span>
            <span>, </span>
            <span>{t('it cannot be included in this letter. You can ignore this and download the letter or enter it and try again.')}</span>
          </div>
          <FormControlLabel
            control={(
              <Checkbox
                checked={showDefaultValuesInLetter}
                onChange={event => onChange(event.target.checked)}
                inputProps={{ 'data-test-id': 'showDefaultValuesInLetter' }}
                color="primary"
                classes={{ root: styles.u_hard__left }}
              />
            )}
            label={t('Show default values in letter')}
          />
          <ul className={rcStyles.template_report_list}>
            {report.map(item => (
              <li key={item?.templatePlaceholder} className={rcStyles.template_report_item}>
                <div className={rcStyles.template_report_item_placeholder}>
                  {startCase(t(item?.templatePlaceholder))}
                </div>
                <div className={rcStyles.template_report_item_icon}>
                  <WarningIcon />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={onCancel}
        variant="outlined"
        color="primary"
        data-test-id="correspondenceReportCancelButton"
        fullWidth
      >
        {t('Cancel')}
      </Button>
      <Button
        onClick={onContinue}
        variant="contained"
        color="primary"
        data-test-id="correspondenceReportContinueButton"
        fullWidth
      >
        {t('Continue')}
      </Button>
    </DialogActions>
  </Dialog>
);

CorrespondenceReportModal.propTypes = {
  report: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  isOpen: PropTypes.bool.isRequired,
  showDefaultValuesInLetter: PropTypes.bool.isRequired,
  onContinue: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CorrespondenceReportModal;
