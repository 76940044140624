import React from 'react';
import PropTypes from 'prop-types';

import istyle from './invoiceloader.scss';

const InvoiceLoad = ({
  className,
}) => {
  const classes = className ? `${className}` : istyle.doc_loader;
  return (
    <svg className={classes} width="145px" height="164px" viewBox="0 0 145 164">
      <defs>
        <path d="M2.71723429,0.0717655795 C1.21510638,0.0729616579 -0.00118431442,1.30133421 -7.10542736e-15,2.8155695 L0.0842110344,118.253883 C0.0853970809,119.768119 1.30346685,120.9941 2.80559476,120.9941 L99.2750599,120.922334 C100.777188,120.921138 101.993478,119.692168 101.992294,118.177932 L101.908083,2.74021656 C101.906897,1.22598127 100.688827,1.27897692e-13 99.1866994,1.27897692e-13 L2.71723429,0.0717655795 Z" id="path-1" />
        <filter x="-33.3%" y="-26.4%" width="166.7%" height="156.2%" filterUnits="objectBoundingBox" id="filter-2">
          <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation="11" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.104867788 0" type="matrix" in="shadowBlurOuter1" />
        </filter>
      </defs>
      <g id="Accounts" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Invoice-Preview-" transform="translate(-539.000000, -287.000000)">
          <g id="Group-2" transform="translate(561.000000, 307.000000)">
            <g id="Fill-1">
              <use fill="black" fillOpacity="1" filter="url(#filter-2)" href="#path-1" />
              <use fill="#FEFEFE" fillRule="evenodd" href="#path-1" />
            </g>
            <rect className={istyle.rec1} fill="#C8BBDB" x="14" y="26" width="75" height="6" rx="3" />
            <rect className={istyle.rec2} fill="#C8BBDB" x="14" y="37" width="37" height="6" rx="3" />
            <rect className={istyle.rec3} fill="#C8BBDB" x="14" y="63" width="75" height="6" rx="3" />
            <rect className={istyle.rec4} fill="#C8BBDB" x="14" y="74" width="37" height="6" rx="3" />
          </g>
        </g>
      </g>
    </svg>
  );
};

InvoiceLoad.propTypes = {
  className: PropTypes.string,
};

export default InvoiceLoad;
