import React from 'react';
import classNames from 'classnames';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import MoreVerticalIcon from '@material-ui/icons/MoreVert';

import Price from 'components/common/Price';
import ArrangementCategoryLink from 'components/arrangement/ArrangementCategoryLink';
import {
  committalTypes,
  serviceCategories,
  productCategories,
  emptyCategoryInformation,
} from 'constants/arrangement';
import styles from 'scss/main.scss';
import { buildAddressString, getFormattedDate } from 'services/utils';
import { arrangementType } from 'types/bereavement';

import rcStyles from './styles.scss';

const ArrangementListItem = ({
  item,
  anchorElement,
  disabled,
  isExpanded,
  viewArrangement,
  confirmArrangement,
  unconfirmArrangement,
  onMenuOpen,
  onMenuClose,
  onEditReference,
  toggleArrangementCollapse,
}) => {
  const { committalType } = item;
  const isCremation = committalType === committalTypes.CREMATION;
  const categoryInformation = item.categoryInformation || emptyCategoryInformation;
  const serviceSelections = item.serviceSelections || [];
  const productSelections = item.productSelections || [];

  const serviceVenueSelection = serviceSelections
    .find(({ service }) => service.category === serviceCategories.SERVICE_VENUES);
  const crematoriumSelection = serviceSelections
    .find(({ service }) => service.category === serviceCategories.CREMATORIA);
  const cemeterySelection = serviceSelections
    .find(({ service }) => service.category === serviceCategories.CEMETERIES);
  const officiantSelection = serviceSelections
    .find(({ service }) => service.category === serviceCategories.OFFICIANTS);
  const coffinSelection = productSelections
    .find(({ product }) => product.category === productCategories.COFFINS);
  const urnSelection = productSelections
    .find(({ product }) => product.category === productCategories.URNS);
  const flowersSelections = productSelections
    .filter(({ product }) => product.category === productCategories.FLOWERS);
  const vehiclesSelections = serviceSelections
    .filter(({ service }) => service.category === serviceCategories.VEHICLES);

  return (
    <li
      key={item.id}
      data-test-id="arrangement"
      className={rcStyles.arrangement_item}
    >
      <ButtonBase
        classes={{ root: rcStyles.expanding_button }}
        onClick={toggleArrangementCollapse}
        data-test-id="arrangementExpandToggle"
      >
        <div className={rcStyles.banner_content}>
          <div className={classNames(
            rcStyles.banner_content__primary,
            item.isConfirmed ? rcStyles.is_confirmed : rcStyles.is_unconfirmed,
          )}
          >
            {isExpanded ? <ArrowDropUp /> : <ArrowDropDown />}
            <div className={rcStyles.is_labels}>
              <h3 className={rcStyles.is_title}>{item.committalType}</h3>
              <p className={styles.brevier}>{item.isConfirmed ? 'Confirmed' : 'Unconfirmed'}</p>
            </div>
          </div>
          <div className={rcStyles.is_detail}>
            <div className={rcStyles.is_detail__reference}>
              <div>{t('Reference')}</div>
              <div>{item.reference}</div>
            </div>
            {item.loanAmount?.amount && item.funeralSafeReferralDate && (
              <div className={rcStyles.is_detail__finance}>
                <div>{t('Finance:')}</div>
                <div>
                  <Price
                    price={item.loanAmount}
                    customClass={classNames(
                      styles.c_price_label,
                      styles.c_price_label__regular,
                      styles.fixed_height,
                      styles.is_bold,
                      styles.is_black,
                    )}
                  />
                  <span>{' | '}</span>
                  <span>{`${t('Applied')} `}</span>
                  <span>{getFormattedDate(item.funeralSafeReferralDate)}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </ButtonBase>
      <div
        data-test-id="arrangementPrice"
        className={rcStyles.arrangement_item__price}
      >
        <Link
          to={`arrangement/${item.id}/estimate`}
        >
          <div className={rcStyles.is_primary}>
            <Price price={item.calculation.finalTotal} />
          </div>
        </Link>
      </div>
      <div className={rcStyles.arrangement_item__action}>
        <IconButton
          aria-controls="arrangement-more"
          aria-haspopup="true"
          data-test-id="arrangementMore"
          onClick={onMenuOpen}
        >
          <MoreVerticalIcon />
        </IconButton>
        <Menu
          anchorEl={anchorElement}
          open={!!anchorElement}
          onClose={onMenuClose}
        >
          <MenuItem
            data-test-id="editReference"
            onClick={() => { onMenuClose(); onEditReference(item); }}
          >
            {t('Edit reference')}
          </MenuItem>
        </Menu>
      </div>
      <div className={rcStyles.expanded_content}>
        <Collapse in={isExpanded}>
          <div className={rcStyles.arrangement__grid}>
            <ArrangementCategoryLink
              arrangementId={item.id}
              label="Service"
              path="service-venue"
              isConfirmed={categoryInformation?.serviceVenue?.isConfirmed}
              title={serviceVenueSelection?.service?.title ?? t('Not selected')}
              description={buildAddressString(serviceVenueSelection?.service?.address)}
            />
            {committalType === committalTypes.CREMATION && (
              <ArrangementCategoryLink
                arrangementId={item.id}
                label="Cremation"
                path="crematorium"
                isConfirmed={categoryInformation?.crematorium?.isConfirmed}
                title={serviceVenueSelection?.service?.title ?? t('Not selected')}
                description={buildAddressString(crematoriumSelection?.service?.address)}
              />
            )}
            <ArrangementCategoryLink
              arrangementId={item.id}
              label="Burial"
              path="burial"
              isConfirmed={categoryInformation?.cemetery?.isConfirmed}
              title={cemeterySelection?.service?.title ?? t('Not selected')}
              description={buildAddressString(cemeterySelection?.service?.address)}
            />
            <ArrangementCategoryLink
              arrangementId={item.id}
              label="Cortege"
              path="cortege"
              isConfirmed={categoryInformation?.vehicles?.isConfirmed}
              title={
                vehiclesSelections.length > 0
                  ? vehiclesSelections.map((selection, index) => (
                    <span key={selection.id}>
                      {`${selection.service.title}`}
                      {vehiclesSelections.length > 1 && index !== vehiclesSelections.length - 1 && ', '}
                    </span>
                  ))
                  : t('Not selected')
              }
            />
            <ArrangementCategoryLink
              arrangementId={item.id}
              label="Coffin"
              path="coffins"
              isConfirmed={categoryInformation?.coffin?.isConfirmed}
              title={coffinSelection?.product?.title ?? t('Not selected')}
            />
            {isCremation && (
              <ArrangementCategoryLink
                arrangementId={item.id}
                label="Urn"
                path="urns"
                isConfirmed={categoryInformation?.urn?.isConfirmed}
                title={urnSelection?.product?.title ?? t('Not selected')}
              />
            )}
            <ArrangementCategoryLink
              arrangementId={item.id}
              label="Officiant"
              path="officiants"
              isConfirmed={categoryInformation?.officiant?.isConfirmed}
              title={officiantSelection?.product?.title ?? t('Not selected')}
            />
            <ArrangementCategoryLink
              arrangementId={item.id}
              label="Flowers"
              path="flowers"
              isConfirmed={categoryInformation?.flowers?.isConfirmed}
              title={
                flowersSelections.length > 0
                  ? flowersSelections.map(flowers => (
                    <span key={flowers.id}>{`${flowers.product.title} hearse, `}</span>
                  ))
                  : t('Not selected')
              }
            />
            <ArrangementCategoryLink
              arrangementId={item.id}
              label="Estimate Total"
              path="estimate"
              description={(
                <div className={rcStyles.is_estimate_price}>
                  <Price price={item.calculation.finalTotal} />
                </div>
              )}
            />
          </div>
          <div className={classNames(styles.c_k_tile__action_bar, styles.u_push__right, styles.u_push__bottom)}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={() => (
                item.isConfirmed
                  ? unconfirmArrangement(item.id)
                  : confirmArrangement(item.id)
              )}
              data-test-id="confirmation"
              disabled={disabled}
            >
              <span>{item.isConfirmed ? t('UNCONFIRM') : t('CONFIRM')}</span>
            </Button>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={() => viewArrangement(item.id)}
              data-test-id="viewAndEdit"
            >
              {disabled ? t('View') : t('View & Edit')}
            </Button>
          </div>
        </Collapse>
      </div>
    </li>
  );
};

ArrangementListItem.propTypes = {
  item: arrangementType.isRequired,
  anchorElement: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
  isExpanded: PropTypes.bool.isRequired,
  viewArrangement: PropTypes.func.isRequired,
  confirmArrangement: PropTypes.func.isRequired,
  unconfirmArrangement: PropTypes.func.isRequired,
  onMenuOpen: PropTypes.func.isRequired,
  onMenuClose: PropTypes.func.isRequired,
  onEditReference: PropTypes.func.isRequired,
  toggleArrangementCollapse: PropTypes.func.isRequired,
};

export default ArrangementListItem;
