import {
  object,
  array,
  string,
  boolean,
} from 'yup';

export const validationSchema = array().of(
  object().shape({
    id: string(),
    isHearse: boolean(),
    noOfPassengers: string().nullable(),
    journeys: array().of(
      object().nullable().shape({
        id: string(),
        type: string(),
        dateTime: string(),
        location: object().nullable().shape({
          addressLine1: string().nullable().required('First line of address is required'),
          addressLine2: string().nullable(),
          town: string().required('Town is required'),
          county: string().nullable(),
          postCode: string().nullable().required('Post Code is required'),
          countryCode: string().nullable().required('Country is required'),
        }),
      }),
    )
      .min(2, 'please add at least one journey')
      .test('journeys test', { msg: 'You must have one To and From route' }, (journeyArray) => {
        const journeyTypes = journeyArray.map(journey => journey.type);
        if (journeyTypes.includes('TO') && journeyTypes.includes('FROM')) {
          return true;
        }

        return false;
      }),
  }),
).min(1, 'please add at least one vehicle');
