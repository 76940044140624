import {
  catalogueItemTypes,
  discountTypes,
  serviceCategories,
  productCategories,
} from 'constants/arrangement';
import { catalogueItemSortOrder, catalogueCategoriesData } from 'constants/catalogue';
import { uniqueArrayByIds } from 'services/utils';


export const getFilteredItemsByPackage = (catalogueItems, type, arrangement) => {
  const { packageSelection } = arrangement;

  const itemIdsProperty = type === catalogueItemTypes.PRODUCT ? 'productIds' : 'serviceIds';

  const itemIdsInPackage = packageSelection && packageSelection.package
    ? packageSelection.package[itemIdsProperty]
    : [];
  const isPackageFixed = packageSelection
    && packageSelection.package
    && packageSelection.package.isFixed;

  const filteredItems = catalogueItems
    .filter(product => !(isPackageFixed && itemIdsInPackage.indexOf(product.id) === -1));

  return { filteredItems, itemIdsInPackage };
};

export const getFilteredCatalogueItems = (
  catalogueItems, category, type, selectedTags, arrangement,
) => {
  const filteredItemsByCategory = category
    ? catalogueItems.filter(item => item.category === category)
    : catalogueItems;
  const filteredItemsByTags = selectedTags.length > 0
    ? filteredItemsByCategory.filter(
      item => item.tags.some(tag => selectedTags.indexOf(tag) !== -1),
    )
    : filteredItemsByCategory;

  const items = filteredItemsByTags.filter(item => item.arrangementVisibility)
    .sort((item1, item2) => item2.arrangementOrder - item1.arrangementOrder);

  if (arrangement) {
    return getFilteredItemsByPackage(items, type, arrangement);
  }

  return { filteredItems: items };
};

export const getSortedCatalogueItems = (
  catalogueItems,
  sortOrder,
) => {
  if (!sortOrder) {
    return catalogueItems;
  }
  const getSalePrice = catalogueItem => catalogueItem.variants.find(variant => variant.isDefault).prices.sale.amount;
  const priceSort = (first, second) => {
    if (getSalePrice(first) < getSalePrice(second)) {
      return -1;
    }
    if (getSalePrice(first) > getSalePrice(second)) {
      return 1;
    }
    return 0;
  };
  if (sortOrder === catalogueItemSortOrder.PRICE_LOW_TO_HIGH) {
    return catalogueItems.sort(priceSort);
  }
  if (sortOrder === catalogueItemSortOrder.PRICE_HIGH_TO_LOW) {
    return catalogueItems.sort((first, second) => priceSort(first, second) * -1);
  }
  if (sortOrder === catalogueItemSortOrder.A_Z) {
    return catalogueItems.sort((first, second) => first.title.localeCompare(second.title));
  }
  return catalogueItems;
};

export const getSelectionPrice = (arrangement, selection) => {
  if (selection.isPackageSelection) {
    return {
      amount: 0,
      currency: 'GBP',
    };
  }
  const overridePriceShouldBeUsed = selection.overridePrice
    && arrangement.discountType === discountTypes.ITEMISED;
  if (overridePriceShouldBeUsed) {
    return selection.overridePrice;
  }
  if (selection.package) {
    return selection.package.price;
  }
  return selection.salePriceAtConfirmation;
};

export const getTitleForLineItemFromProductSelection = (selection) => {
  const productVariant = selection.product.variants.find(
    variant => variant.id === selection.variantId,
  );
  let productTitle = selection.product.title;
  if (productVariant && selection.product.variants.length > 1) {
    productTitle += ` (${productVariant.name})`;
  }
  if (selection.isPackageSelection) {
    productTitle += ' (Included in package)';
  }
  return productTitle;
};

export const getTitleForLineItemFromServiceSelection = (selection) => {
  const serviceVariant = selection.service.variants.find(
    variant => variant.id === selection.variantId,
  );
  let serviceTitle = selection.service.title;
  if (serviceVariant && selection.service.variants.length > 1) {
    serviceTitle += ` (${serviceVariant.name})`;
  }
  if (selection.isPackageSelection) {
    serviceTitle += ' (Included in package)';
  }
  return serviceTitle;
};

export const addCatalogueItemsToPackage = (packageItem, catalogueItems) => {
  const getDefaultCategoryMaxQuantities = (items, maxQuantities) => (items
    .map(({ category }) => ({ category, value: 1 }))
    .reduce((unique, item) => (
      unique.find(uniqueItem => uniqueItem.category === item.category) ? unique : [...unique, item]), []))
    .filter(maxQuantity => !maxQuantities.find(({ category }) => category === maxQuantity.category));

  const { serviceCategoryMaxQuantities, productCategoryMaxQuantities } = packageItem;
  const services = uniqueArrayByIds([
    ...packageItem.services,
    ...catalogueItems.filter(({ category }) => Object.keys(serviceCategories).includes(category)),
  ]);
  const products = uniqueArrayByIds([
    ...packageItem.products,
    ...catalogueItems.filter(({ category }) => Object.keys(productCategories).includes(category)),
  ]);
  const newServiceCategoryMaxQuanities = getDefaultCategoryMaxQuantities(services, serviceCategoryMaxQuantities);
  const newProductCategoryMaxQuantities = getDefaultCategoryMaxQuantities(products, productCategoryMaxQuantities);

  return {
    ...packageItem,
    services,
    products,
    serviceIds: services.map(service => service.id),
    productIds: products.map(product => product.id),
    serviceCategoryMaxQuantities: [...serviceCategoryMaxQuantities, ...newServiceCategoryMaxQuanities],
    productCategoryMaxQuantities: [...productCategoryMaxQuantities, ...newProductCategoryMaxQuantities],
  };
};

export const getTenantCatalogueCategories = (categoryDisplayPreferences) => {
  const categories = categoryDisplayPreferences || [];

  if (categories.length !== catalogueCategoriesData.length) {
    catalogueCategoriesData.forEach((defaultCategory) => {
      if (!categories.find(item => item.category === defaultCategory.category)) {
        categories.push({ ...defaultCategory, visible: true });
      }
    });
  }

  return categories
    .map(category => ({
      ...category,
      ...catalogueCategoriesData
        .find(categoryData => (categoryData.category === category.category)),
    }));
};

export const getLowestSalePriceFromVariants = variants => variants?.map(variant => variant.prices.sale.amount)
  .reduce(
    (previousAmount, currentAmount) => {
      if (previousAmount === null) {
        return currentAmount;
      }

      if (currentAmount === null) {
        return previousAmount;
      }

      if (previousAmount < currentAmount) {
        return previousAmount;
      }

      return currentAmount;
    },
    null,
  );

export const shouldShowFromPrice = (variants) => {
  const lowestPrice = getLowestSalePriceFromVariants(variants);

  return variants?.some((variant) => {
    const { amount } = variant.prices.sale;

    return amount !== lowestPrice || variant.isGuidePrice;
  });
};
