import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import { reactRefType } from 'types/common';
import DateTimePicker from 'components/common/DateTimePicker';
import LocationFormSection from 'components/common/LocationFormSection';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const dialogClasses = {
  paper: rcStyles.rc_dialog__paper,
};

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const ViewingModal = ({
  formData,
  onAttendeesChange,
  onChange,
  onSave,
  onCancel,
  isOpen,
  dialogRef,
  formRefs,
  errors,
}) => (
  <Dialog
    onClose={onCancel}
    open={isOpen}
    classes={dialogClasses}
    TransitionComponent={Transition}
    disableBackdropClick
    disableEscapeKeyDown
    fullScreen
    scroll="paper"
  >
    <div ref={dialogRef} className={styles.c_dialog__body}>
      <DialogTitle
        disableTypography
        className={styles.dialog_title_bar}
      >
        <IconButton
          className={classNames(
            styles.o_button__secondary,
            styles.is_close,
          )}
          color="primary"
          variant="contained"
          aria-label="Close"
          onClick={onCancel}
          data-test-id="feeClose"
        >
          <CloseIcon />
        </IconButton>
        <div className={styles.is_title}>
          {t('Viewings')}
        </div>
        <div className={styles.is_actions}>
          <Button
            onClick={onSave}
            variant="contained"
            color="secondary"
            className={styles.o_button__secondary}
            data-test-id="viewing_save"
          >
            {t('Save')}
          </Button>
        </div>
      </DialogTitle>
      <DialogContent className={classNames(styles.u_island_large)}>
        <div className={classNames(rcStyles.v_spacing)}>
          <div ref={formRefs.location}>
            <LocationFormSection
              value={formData.location}
              errors={errors.location}
              valueKey="location"
              lookupLabel="Viewing location"
              data-test-id="locationFormSection"
              onChange={onChange}
            />
          </div>
          <div ref={formRefs.startDateTime}>
            <DateTimePicker
              value={formData.startDateTime || null}
              label={t('Start date & time')}
              onChange={date => onChange('startDateTime', date)}
              isClearable
              errors={errors && !!errors.startDateTime}
              helperText={errors && errors.startDateTime}
              data-test-id="startDateTime"
            />
          </div>
          <div ref={formRefs.endDateTime}>
            <DateTimePicker
              value={formData.endDateTime || null}
              label={t('End date & time')}
              onChange={date => onChange('endDateTime', date)}
              isClearable
              errors={errors && !!errors.endDateTime}
              helperText={errors && errors.endDateTime}
              data-test-id="endDateTime"
            />
          </div>
          <div>
            <ul className={styles.o_list}>
              <li>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={!!formData.isCoffinClosed}
                      onChange={event => onChange('isCoffinClosed', event.target.checked)}
                      inputProps={{ 'data-test-id': 'isCoffinClosed' }}
                      color="primary"
                    />
                  )}
                  label={t('Closed coffin')}
                />
              </li>
              <li>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={!!formData.isMemorialBoxRequired}
                      onChange={event => onChange('isMemorialBoxRequired', event.target.checked)}
                      inputProps={{ 'data-test-id': 'isMemorialBoxRequired' }}
                      color="primary"
                    />
                  )}
                  label={t('Memorial box required')}
                />
              </li>
              <li>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={!!formData.isMemorialBookRequired}
                      onChange={event => onChange('isMemorialBookRequired', event.target.checked)}
                      inputProps={{ 'data-test-id': 'isMemorialBookRequired' }}
                      color="primary"
                    />
                  )}
                  label={t('Memorial book required')}
                />
              </li>
            </ul>
          </div>
          <div>
            <h5 className={styles.long_primer}>
              Permitted attendees
            </h5>
            <ul
              ref={formRefs.permittedAttendees}
              className={styles.o_list}
            >
              <li>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={formData.permittedAttendees.indexOf('NEXT_OF_KIN') !== -1}
                      onChange={event => onAttendeesChange('NEXT_OF_KIN', event.target.checked)}
                      inputProps={{ 'data-test-id': 'nextOfKin' }}
                      color="primary"
                    />
                  )}
                  label={t('Next of kin')}
                />
              </li>
              <li>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={formData.permittedAttendees.indexOf('FAMILY_MEMBERS') !== -1}
                      onChange={event => onAttendeesChange('FAMILY_MEMBERS', event.target.checked)}
                      inputProps={{ 'data-test-id': 'familyMembers' }}
                      color="primary"
                    />
                  )}
                  label={t('Family members')}
                />
              </li>
              <li>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={formData.permittedAttendees.indexOf('FRIENDS') !== -1}
                      onChange={event => onAttendeesChange('FRIENDS', event.target.checked)}
                      inputProps={{ 'data-test-id': 'friends' }}
                      color="primary"
                    />
                  )}
                  label={t('Friends')}
                />
              </li>
              <li>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={formData.permittedAttendees.indexOf('OPEN') !== -1}
                      onChange={event => onAttendeesChange('OPEN', event.target.checked)}
                      inputProps={{ 'data-test-id': 'open' }}
                      color="primary"
                    />
                  )}
                  label={t('Open')}
                />
              </li>
            </ul>
          </div>
          <div className={classNames(
            styles.o_fb__item,
            styles.u_push__top,
          )}
          >
            <TextField
              label={t('Notes')}
              value={formData.notes || ''}
              onChange={event => onChange('notes', event.target.value)}
              fullWidth
              inputProps={{
                'data-test-id': 'viewingNotes',
              }}
              multiline
            />
          </div>
        </div>
      </DialogContent>
    </div>
  </Dialog>
);

ViewingModal.propTypes = {
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
  onAttendeesChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  dialogRef: reactRefType.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ViewingModal;
