import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import IconInsertDriveFile from '@material-ui/icons/InsertDriveFile';
import NotesModal from 'components/common/NotesModal';
import DocumentsModal from 'components/common/DocumentsModal';
import CorrespondenceModal from 'components/bereavement/CorrespondenceModal';
import IconNotes from 'icons/IconNotes';
import IconLetter from 'icons/IconLetter';
import { ALL_NOTES, noteCategories } from 'constants/bereavement';
import { documentType, notesType } from 'types/common';
import { deceasedPersonType } from 'types/bereavement';

import classNames from 'classnames';
import styles from 'scss/main.scss';

const NotesDocumentsCorrespondenceGroup = ({
  bereavementId,
  deceasedPeople,
  documents,
  notes,
  correspondenceCategory,
  templateDefinitionsLookup,
  hasFinanceOnlyNotes,
  disabled,
  buttonClasses,
  iconClasses,
  customColor,
  isDocumentsModalOpen,
  isCorrespondenceModalOpen,
  isNotesModalOpen,
  toggleDocumentsModal,
  toggleCorrespondenceModal,
  toggleNotesModal,
  onDocumentsSave,
  onNotesSave,
}) => (
  <Fragment>
    <IconButton
      className={classNames(...buttonClasses)}
      onClick={toggleDocumentsModal}
      color={customColor}
      variant="contained"
      data-test-id="documentsButton"
    >
      {documents ? (
        <Badge
          data-test-id="documentsCounter"
          badgeContent={documents.length}
          color="primary"
          className={styles.badgeColor}
        >
          <IconInsertDriveFile className={classNames(...iconClasses)} />
        </Badge>
      ) : (
        <IconInsertDriveFile className={classNames(...iconClasses)} />
      )}
    </IconButton>
    <IconButton
      className={classNames(...buttonClasses)}
      onClick={toggleCorrespondenceModal}
      color={customColor}
      variant="contained"
      data-test-id="correspondenceButton"
    >
      <IconLetter className={classNames(...iconClasses)} />
    </IconButton>
    <IconButton
      className={classNames(...buttonClasses)}
      onClick={toggleNotesModal}
      color={customColor}
      variant="outlined"
      data-test-id="notesButton"
    >
      {notes ? (
        <Badge
          data-test-id="notesCounter"
          badgeContent={hasFinanceOnlyNotes
            ? notes.length
            : notes.filter(note => ![noteCategories.ACCOUNTS_FINANCE_ONLY].includes(note.category)).length}
          color="primary"
        >
          <IconNotes className={classNames(...iconClasses)} />
        </Badge>
      ) : (
        <IconNotes className={classNames(...iconClasses)} />
      )}
    </IconButton>
    <NotesModal
      bereavementId={bereavementId}
      name={deceasedPeople[0].name}
      restrictedCategories={!hasFinanceOnlyNotes
        ? [noteCategories.ACCOUNTS_FINANCE_ONLY]
        : []}
      notesList={notes}
      defaultCategory={ALL_NOTES}
      onClose={toggleNotesModal}
      onSave={onNotesSave}
      isOpen={isNotesModalOpen}
    />
    <DocumentsModal
      isOpen={isDocumentsModalOpen}
      documentsList={documents}
      disabled={disabled}
      onClose={toggleDocumentsModal}
      onSave={onDocumentsSave}
    />
    <CorrespondenceModal
      isOpen={isCorrespondenceModalOpen}
      bereavementId={bereavementId}
      onClose={toggleCorrespondenceModal}
      correspondenceCategory={correspondenceCategory}
      templateDefinitionsLookup={templateDefinitionsLookup}
    />
  </Fragment>
);

NotesDocumentsCorrespondenceGroup.propTypes = {
  bereavementId: PropTypes.string.isRequired,
  deceasedPeople: PropTypes.arrayOf(deceasedPersonType).isRequired,
  documents: PropTypes.arrayOf(documentType),
  notes: notesType,
  correspondenceCategory: PropTypes.string.isRequired,
  templateDefinitionsLookup: PropTypes.objectOf(PropTypes.any),
  hasFinanceOnlyNotes: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  buttonClasses: PropTypes.arrayOf(PropTypes.string).isRequired,
  iconClasses: PropTypes.arrayOf(PropTypes.string).isRequired,
  customColor: PropTypes.string.isRequired,
  isDocumentsModalOpen: PropTypes.bool.isRequired,
  isCorrespondenceModalOpen: PropTypes.bool.isRequired,
  isNotesModalOpen: PropTypes.bool.isRequired,
  toggleDocumentsModal: PropTypes.func.isRequired,
  toggleCorrespondenceModal: PropTypes.func.isRequired,
  toggleNotesModal: PropTypes.func.isRequired,
  onDocumentsSave: PropTypes.func.isRequired,
  onNotesSave: PropTypes.func.isRequired,
};

export default NotesDocumentsCorrespondenceGroup;
