import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { appointmentType, homeType } from 'types/bereavement';
import AppointmentModal from './AppointmentModal';
import { validationSchema } from './validation';

class AppointmentModalContainer extends Component {
  static propTypes = {
    appointment: appointmentType,
    home: homeType,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
    generateRefs: PropTypes.func.isRequired,
    setErrors: PropTypes.func.isRequired,
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    const { isDirty } = prevState;
    if (!isDirty && nextProps.appointment) {
      return {
        appointmentForm: nextProps.appointment,
        selectedHome: null,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      appointmentForm: {
        address: null,
        dateTime: null,
        staffMember: null,
      },
      selectedHome: null,
      isDirty: false,
      isValidationEnabled: false,
    };

    this.dialogRef = React.createRef();
  }

  componentDidMount() {
    const { generateRefs } = this.props;
    generateRefs(Object.keys(validationSchema.fields));
  }

  handleChange = (key, value, home) => {
    const { validate } = this.props;
    const { appointmentForm, isValidationEnabled, selectedHome } = this.state;

    const newState = {
      appointmentForm: {
        ...appointmentForm,
        [key]: value,
      },
      selectedHome: home || selectedHome,
      isDirty: true,
    };

    if (isValidationEnabled) {
      validate(newState.appointmentForm, validationSchema);
    }
    this.setState(newState);
  };

  handleSave = () => {
    const { onSave, validate } = this.props;
    const { appointmentForm, selectedHome } = this.state;
    this.setState({ isValidationEnabled: true });
    const isValid = validate(appointmentForm, validationSchema, true, this.dialogRef);

    if (isValid) {
      const appointmentData = { ...appointmentForm };
      appointmentData.staffMemberId = appointmentForm.staffMember.id;
      onSave(appointmentData, selectedHome);
      this.handleClose();
    }
  }

  handleClose = () => {
    this.setState({
      appointmentForm: {
        address: null,
        dateTime: null,
        staffMember: null,
      },
      isDirty: false,
      isValidationEnabled: false,
    });
    const { setErrors, onClose } = this.props;
    setErrors({});
    onClose();
  };

  render() {
    const {
      home,
      isOpen,
      errors,
      formRefs,
    } = this.props;
    const { appointmentForm, isDirty } = this.state;

    return (
      <AppointmentModal
        appointment={appointmentForm}
        home={home}
        isOpen={isOpen}
        dialogRef={this.dialogRef}
        formRefs={formRefs}
        errors={errors}
        isDirty={isDirty}
        onChange={this.handleChange}
        onCancel={this.handleClose}
        onSave={this.handleSave}
      />
    );
  }
}

export default withValidation(AppointmentModalContainer);
