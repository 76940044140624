import React, { Component } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { fetchArrangementCatalogueItemsAction, orderArrangementItemsAsyncAction } from 'actions/catalogue';
import { hasPermissions, arrayMove } from 'services/utils';
import { arrangementSettingsCategories, catalogueCategoriesData } from 'constants/catalogue';
import { catalogueItemTypes } from 'constants/arrangement';
import { scopes as permissions } from 'constants/scopes';
import { matchType, historyType } from 'types/reactRouter';
import { productType, serviceType } from 'types/bereavement';
import AdminArrangementProductsServicesOrderScreen from './AdminArrangementProductsServicesOrderScreen';

const filterItemsByCategoryAndSortByArrangementOrder = (category, items) => items
  .filter(item => item.category === category.category)
  .sort((item1, item2) => item2.arrangementOrder - item1.arrangementOrder);

class AdminArrangementProductsServicesOrderScreenContainer extends Component {
  static propTypes = {
    scopes: PropTypes.arrayOf(PropTypes.string.isRequired),
    products: PropTypes.arrayOf(productType).isRequired,
    services: PropTypes.arrayOf(serviceType).isRequired,
    match: matchType.isRequired,
    history: historyType.isRequired,
    fetchCatalogueItems: PropTypes.func.isRequired,
    orderArrangementItems: PropTypes.func.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    const {
      match: {
        params: {
          category,
          subCategory,
        },
      },
    } = props;

    let categoryData = catalogueCategoriesData.find(
      catalogueCategory => catalogueCategory.path === (subCategory || category),
    );

    const arrangementCategoryData = arrangementSettingsCategories.find(
      catalogueCategory => catalogueCategory.path === category,
    );

    if (arrangementCategoryData && !subCategory) {
      categoryData = {
        ...arrangementCategoryData.resourceCategories[0],
        name: arrangementCategoryData.name,
        path: arrangementCategoryData.path,
      };
    }

    this.state = {
      items: [],
      category: categoryData,
      arrangementCategory: arrangementCategoryData,
      isSortEnabled: false,
      isSaving: false,
    };
  }

  componentDidMount() {
    const {
      products,
      services,
      scopes,
      fetchCatalogueItems,
    } = this.props;

    const { category } = this.state;

    if (category.type === catalogueItemTypes.PRODUCT && hasPermissions([permissions.PRODUCTS_READ], scopes)) {
      if (products.length === 0) {
        fetchCatalogueItems('products');
      }

      if (products.length > 0) {
        const categoryItems = filterItemsByCategoryAndSortByArrangementOrder(category, products);
        this.setItemsInState(categoryItems);
      }
    }

    if (category.type === catalogueItemTypes.SERVICE && hasPermissions([permissions.SERVICES_READ], scopes)) {
      if (services.length === 0) {
        fetchCatalogueItems('services');
      }

      if (services.length > 0) {
        const categoryItems = filterItemsByCategoryAndSortByArrangementOrder(category, services);
        this.setItemsInState(categoryItems);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { services: prevServices, products: prevProducts } = prevProps;
    const { category } = this.state;
    const { services, products } = this.props;
    if (services !== prevServices || products !== prevProducts) {
      const categoryItems = filterItemsByCategoryAndSortByArrangementOrder(
        category,
        category.type === catalogueItemTypes.PRODUCT ? products : services,
      );
      this.setItemsInState(categoryItems);
    }
  }

  setItemsInState(items) {
    this.setState({ items });
  }

  handleToggleIsSortEnabled = () => {
    this.setState(prevState => ({
      isSortEnabled: !prevState.isSortEnabled,
    }));
  }

  handleSortItems = ({ oldIndex, newIndex }) => {
    const { items } = this.state;
    const reorderedItems = arrayMove(items, oldIndex, newIndex);

    this.setState({ items: reorderedItems });
  }

  handleSetIsVisible = (id, isVisible) => {
    const { items } = this.state;
    const updatedItems = items.map((item) => {
      const updatedItem = { ...item };
      if (updatedItem.id === id) {
        updatedItem.arrangementVisibility = isVisible;
      }
      return updatedItem;
    });
    this.setState({ items: updatedItems });
  }

  handleCancel = () => {
    const { history } = this.props;
    const { arrangementCategory, category } = this.state;
    if (arrangementCategory && arrangementCategory.path !== category.path) {
      history.push(`/catalogue/arrangement-settings/${arrangementCategory.path}`);
    } else {
      history.push('/catalogue/arrangement-settings');
    }
  }

  handleSave = () => {
    const { orderArrangementItems, enqueueSnackbar } = this.props;
    const { category, items } = this.state;
    this.setState({ isSaving: true });

    orderArrangementItems(category.type, items, () => {
      this.setState({ isSaving: false });
      enqueueSnackbar(
        t('Order and visibility saved successfully'),
        {
          variant: 'success',
        },
      );
      this.handleCancel();
    });
  }

  render() {
    const {
      category,
      arrangementCategory,
      isSortEnabled,
      isSaving,
      items,
    } = this.state;

    const breadcrumbs = [
      { path: '/', title: t('Dashboard') }, { path: '/catalogue', title: t('Brochure management') },
      { path: '/catalogue/arrangement-settings', title: t('Arrangement settings') },
      { path: `/catalogue/arrangement-settings/${category.path}`, title: t(category.name) },
    ];

    if (arrangementCategory && arrangementCategory.path !== category.path) {
      breadcrumbs.splice(
        3,
        0,
        {
          path: `/catalogue/arrangement-settings/${arrangementCategory.path}`,
          title: t(arrangementCategory.name),
        },
      );
    }

    return (
      <AdminArrangementProductsServicesOrderScreen
        breadcrumbs={breadcrumbs}
        category={category}
        items={items}
        isSortEnabled={isSortEnabled}
        isLoading={items.length === 0}
        isSaving={isSaving}
        onToggleIsSortEnabled={this.handleToggleIsSortEnabled}
        onSortItems={this.handleSortItems}
        onSetIsVisible={this.handleSetIsVisible}
        onCancel={this.handleCancel}
        onSave={this.handleSave}
      />
    );
  }
}

const mapStateToProps = state => ({
  scopes: state.userStore.user.policy.scopes,
  products: state.catalogueStore.products.items,
  services: state.catalogueStore.services.items,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchCatalogueItems: fetchArrangementCatalogueItemsAction,
  orderArrangementItems: orderArrangementItemsAsyncAction,
}, dispatch);

export default withRouter(
  withSnackbar(
    connect(mapStateToProps, mapDispatchToProps)(AdminArrangementProductsServicesOrderScreenContainer),
  ),
);
