import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { organisationalUnitTypes } from 'constants/organisationalUnits';
import OrganisationalUnitLookup from 'components/common/lookups/OrganisationalUnitLookup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import NameFormSection from 'components/common/NameFormSection';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import { bereavementType } from 'types/bereavement';
import { organisationalUnitType } from 'types/organisationalUnit';
import { arrangementTypes, committalTypes } from 'constants/arrangement';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const LiteArrangementForm = ({
  bereavement,
  organisationalUnits,
  disabled,
  onChange,
  formData,
  onSave,
  onCancel,
  isNewBereavement,
  formRefs,
  errors,
}) => (
  <div className={classNames(
    styles.o_view,
    styles.is_slim,
    rcStyles.center_content,
  )}
  >
    <div className={styles.o_view__main}>
      <div className={classNames(
        rcStyles.tile,
        rcStyles.v_spacing,
      )}
      >
        <div className={classNames(
          styles.pica_light,
          styles.is_primary,
        )}
        >
          {t(isNewBereavement ? 'New Arrangement' : 'Edit Arrangement')}
        </div>
        <div ref={formRefs.deceasedName}>
          <NameFormSection
            name={formData.deceasedName || {}}
            labelTitle={t('Title')}
            onChangeHandler={deceasedName => onChange('deceasedName', deceasedName)}
            labelGivenName={t('Given Name *')}
            labelFamilyName={t('Family Name *')}
            errors={errors && errors.deceasedName}
            disabled={disabled}
          />
        </div>
        <TextField
          label={t('Reference Number')}
          value={formData.arrangementReference || ''}
          onChange={event => onChange('arrangementReference', event.target.value)}
          fullWidth
          inputProps={{
            'data-test-id': 'arrangementReference',
          }}
          disabled={disabled}
        />
        <div ref={formRefs.homeId}>
          {errors && errors.homeId && (
            <FormHelperText error>
              {errors && errors.homeId}
            </FormHelperText>
          )}
          <OrganisationalUnitLookup
            value={formData.home ? formData.home : bereavement && bereavement.homeId}
            label="Select Home"
            onChange={home => onChange('home', home && home.length ? home[0] : home)}
            type={organisationalUnitTypes.HOME}
            disabled={!isNewBereavement}
            localHomes={organisationalUnits}
            data-test-id="homeLookup"
          />
        </div>
        <div>
          <div className={styles.brevier}>
            {t('Committal Type')}
          </div>
          <FormControl
            component="fieldset"
            className={classNames(
              styles.c_dialogOptionsContainer,
              styles.u_text__center,
            )}
            disabled={!isNewBereavement}
          >
            <RadioGroup
              name="committalType"
              value={formData.committalType || ''}
              onChange={event => onChange('committalType', event.target.value)}
              data-test-id="committalTypeGroup"
            >
              <FormControlLabel
                value={committalTypes.CREMATION}
                control={(
                  <Radio
                    color="primary"
                    inputProps={{
                      'data-test-id': 'committalTypeCremation',
                    }}
                  />
                )}
                label="Cremation"
              />
              <FormControlLabel
                value={committalTypes.BURIAL}
                control={(
                  <Radio
                    color="primary"
                    inputProps={{
                      'data-test-id': 'committalTypeBurial',
                    }}
                  />
                )}
                label="Burial"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div>
          <div className={styles.brevier}>
            {t('Funeral Stage')}
          </div>
          <FormControl
            component="fieldset"
            className={classNames(
              styles.c_dialogOptionsContainer,
              styles.u_text__center,
            )}
            disabled={!isNewBereavement}
          >
            <RadioGroup
              name="arrangementType"
              value={formData.type || ''}
              onChange={event => onChange('type', event.target.value)}
              data-test-id="arrangementTypeGroup"
            >
              <FormControlLabel
                value={arrangementTypes.PRE_NEED}
                control={(
                  <Radio
                    color="primary"
                    inputProps={{
                      'data-test-id': 'arrangementTypePreNeed',
                    }}
                  />
                )}
                label="Pre-Need"
              />
              <FormControlLabel
                value={arrangementTypes.AT_NEED}
                control={(
                  <Radio
                    color="primary"
                    inputProps={{
                      'data-test-id': 'arrangementTypeAtNeed',
                    }}
                  />
                )}
                label="At-Need"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className={rcStyles.actions}>
          <Button
            onClick={onCancel}
            variant="outlined"
            color="primary"
            data-test-id="cancel"
          >
            {t('Cancel')}
          </Button>
          <Button
            disabled={disabled}
            onClick={onSave}
            variant="contained"
            color="primary"
            data-test-id="saveBereavement"
          >
            {t(`${isNewBereavement ? 'Create' : 'Continue'}`)}
          </Button>
        </div>
      </div>
    </div>
  </div>
);

LiteArrangementForm.propTypes = {
  bereavement: bereavementType,
  organisationalUnits: PropTypes.arrayOf(organisationalUnitType).isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.objectOf(PropTypes.any),
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isNewBereavement: PropTypes.bool,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any),
};

export default LiteArrangementForm;
