import { MeasurementUnit, MeasurementDisplayUnit, measurementConfig } from 'constants/measurement';
import { MeasurementValue, MeasurementInputConfig } from 'types/ts/measurement';

export const getMeasurementConfigInput = (
  units: MeasurementDisplayUnit, unit: MeasurementUnit,
) => measurementConfig[units].inputs.find(({ type }) => type === unit);

export const getDisplayUnitMeasurements = (
  value: number|null = 0,
  units: MeasurementDisplayUnit,
): MeasurementValue[] => {
  if (!units) {
    return [];
  }

  const { inputs }: { inputs: MeasurementInputConfig[] } = measurementConfig[units];

  return inputs.map((input: MeasurementInputConfig): MeasurementValue => ({
    unit: input.type,
    value: input.toValue ? input.toValue(value) : value,
  }));
};

export const getUnitMeasurement = (
  measurements: MeasurementValue[],
  units: MeasurementDisplayUnit,
): number | null => {
  if (!measurements || !measurements.length || !units) {
    return null;
  }

  const unitMeasurement = measurements.reduce((prevMeasurement: number|null, { unit, value }) => {
    const inputConfig: MeasurementInputConfig|undefined = getMeasurementConfigInput(units, unit);
    const { toMeasurement } = inputConfig || {};

    const measurement = toMeasurement ? toMeasurement(value) : value;
    let nextMeasurement = prevMeasurement;
    if (typeof measurement === 'number') {
      if (nextMeasurement !== null) {
        nextMeasurement += measurement;
      } else {
        nextMeasurement = measurement;
      }
    }

    return nextMeasurement;
  }, null);

  return unitMeasurement;
};

export const convertFeetToInches = (value: number|null) => (value != null ? value * 12 : value);

export const getFeetFromInches = (value: number|null) => (value != null ? Math.floor(value / 12) : value);

export const getRemainingInchesFromFeet = (value: number|null) => (value != null ? value % 12 : value);

export const getValueAsFloat = (value: string) => parseFloat(value);

export const getValueAsInt = (value: string) => parseInt(value, 10);

export const convertToPositiveNumber = (value: number) => {
  if (value < 0) {
    return Math.abs(value);
  }
  return value;
};

export const limitToMaxNumber = (max: number) => (value: number) => {
  if (value > max) {
    const valueAsString = `${value}`;
    const substring = valueAsString.substr(0, valueAsString.length - 1);
    return parseFloat(substring);
  }
  return value;
};
