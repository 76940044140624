import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FirstCallHeader from 'components/bereavement/FirstCallHeader';
import TabContainer from 'components/common/TabContainer';
import FirstCallDeceasedForm from 'components/bereavement/FirstCallDeceasedForm';
import ClientListSection from 'components/client/ClientListSection';
import BookAppointmentModal from 'components/bereavement/BookAppointmentModal';
import ConfirmationModal from 'components/common/ConfirmationModal';
import overrideTheme from 'services/themes/overrideTheme';
import { bereavementType } from 'types/bereavement';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const tabClasses = {
  tabBar: classNames(rcStyles.first_call__tab_container),
  tabs: styles.c_tab_indicator,
  tab: classNames(rcStyles.tab_default, styles.primerHeadDefault),
  tabContent: styles.primerTabMain,
};

const FirstCallScreen = ({
  bereavement,
  isSaving,
  isValidationEnabled,
  isAutomaticEmailsConfirmationModalOpen,
  isDeceasedFormValid,
  isBereavedFormValid,
  setDeceasedFormValid,
  setAppointment,
  updateBereavementByKey,
  updateFullBereavement,
  onSubmit,
  addClient,
  editClient,
  removeClient,
  onAutomaticEmailConfirmation,
  toggleAutomaticEmailsConfirmationModal,
}) => (
  <MuiThemeProvider theme={overrideTheme}>
    <div className={classNames(
      styles.c_page__full_height,
      styles.has_faded_background,
    )}
    >
      <FirstCallHeader
        bereavement={bereavement}
        isSaving={isSaving}
        isDeceasedFormValid={isDeceasedFormValid}
        isBereavedFormValid={isBereavedFormValid}
        onSubmit={onSubmit}
        updateBereavementByKey={updateBereavementByKey}
      >
        <a name="top" data-test-id="top">&nbsp;</a>
      </FirstCallHeader>
      <TabContainer
        classes={tabClasses}
        tabs={[
          {
            label: 'Deceased',
            component: (
              <div className={styles.o_view}>
                <div className={styles.o_view__main}>
                  <FirstCallDeceasedForm
                    bereavement={bereavement}
                    isValidationEnabled={isValidationEnabled}
                    setIsValid={setDeceasedFormValid}
                    updateBereavementByKey={updateBereavementByKey}
                    updateFullBereavement={updateFullBereavement}
                  />
                </div>
              </div>
            ),
          },
          {
            label: 'Client',
            component: (
              <div className={styles.o_view}>
                <div className={styles.o_view__main}>
                  <div className={styles.o_block_content}>
                    <div className={styles.u_soft__ends}>
                      <BookAppointmentModal
                        appointment={bereavement.appointment}
                        buttonColor="primary"
                        setAppointment={setAppointment}
                        btnWillExpand
                      />
                    </div>
                    <div className={styles.u_soft__ends}>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            checked={bereavement.sendEmailsToBereavedConsent}
                            onChange={event => onAutomaticEmailConfirmation(event.target.checked)}
                            color="primary"
                            inputProps={{ 'data-test-id': 'sendEmailsToBereavedConsent' }}
                          />
                        )}
                        label={t('Automatically send emails for this funeral')}
                      />
                    </div>
                    <ClientListSection
                      bereavementId={bereavement.id}
                      bereavedPeopleConnections={bereavement.bereavedPeopleConnections}
                      appointment={bereavement.appointment}
                      addClient={addClient}
                      editClient={editClient}
                      removeClient={removeClient}
                      canDeleteAllClients
                    />
                    <ConfirmationModal
                      title="Automatically send emails for this funeral"
                      message="To enable automatic emails, the primary contact requires an email address."
                      isOpen={isAutomaticEmailsConfirmationModalOpen}
                      onConfirm={toggleAutomaticEmailsConfirmationModal}
                    />
                  </div>
                </div>
              </div>
            ),
          },
        ]}
      />
    </div>
  </MuiThemeProvider>
);

FirstCallScreen.propTypes = {
  bereavement: bereavementType.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isValidationEnabled: PropTypes.bool.isRequired,
  isDeceasedFormValid: PropTypes.bool.isRequired,
  isBereavedFormValid: PropTypes.bool.isRequired,
  setDeceasedFormValid: PropTypes.func.isRequired,
  setAppointment: PropTypes.func.isRequired,
  updateBereavementByKey: PropTypes.func.isRequired,
  updateFullBereavement: PropTypes.func.isRequired,
  addClient: PropTypes.func.isRequired,
  editClient: PropTypes.func.isRequired,
  removeClient: PropTypes.func.isRequired,
  onAutomaticEmailConfirmation: PropTypes.func.isRequired,
  isAutomaticEmailsConfirmationModalOpen: PropTypes.bool.isRequired,
  toggleAutomaticEmailsConfirmationModal: PropTypes.func.isRequired,
};

export default FirstCallScreen;
