import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import { catalogueItemTypes } from 'constants/arrangement';
import { featureFlags } from 'constants/features';

import NotFoundScreen from 'screens/NotFoundScreen';
import AdminArrangementProductsServicesOrderScreen from 'screens/admin/AdminArrangementProductsServicesOrderScreen';
import AdminArrangementSettingsCategoryRouter from 'screens/admin/AdminArrangementSettingsCategoryRouter';
import AdminArrangementSettingsScreen from 'screens/admin/AdminArrangementSettingsScreen';
import AdminCatalogueItemListScreen from 'screens/admin/AdminCatalogueItemListScreen';
import AdminCatalogueItemScreen from 'screens/admin/AdminCatalogueItemScreen';
import AdminCatalogueMenuScreen from 'screens/admin/AdminCatalogueMenuScreen';
import AdminCataloguePackageItemScreen from 'screens/admin/AdminCataloguePackageItemScreen';
import AdminCataloguePackagesListScreen from 'screens/admin/AdminCataloguePackagesListScreen';
import AdminCataloguePackagesOrderScreen from 'screens/admin/AdminCataloguePackagesOrderScreen';
import AdminCatalogueProductsServicesOrderScreen from 'screens/admin/AdminCatalogueProductsServicesOrderScreen';
import AdminCatalogueSettingsScreen from 'screens/admin/AdminCatalogueSettingsScreen';
import { matchType } from 'types/reactRouter';

const AdminCatalogueSectionScreen = ({ match, availableFeatures }) => (
  <Fragment>
    <Switch>
      <Route
        path={`${match.url}/service/edit/:itemId`}
        render={() => (
          <AdminCatalogueItemScreen
            catalogueItemType={catalogueItemTypes.SERVICE}
            isServiceCategory
          />
        )}
      />
      <Route
        path={`${match.url}/product/edit/:itemId`}
        render={() => (
          <AdminCatalogueItemScreen
            catalogueItemType={catalogueItemTypes.PRODUCT}
          />
        )}
      />
      <Route
        path={`${match.url}/service/create`}
        render={() => (
          <AdminCatalogueItemScreen
            catalogueItemType={catalogueItemTypes.SERVICE}
            isNewItem
            isServiceCategory
          />
        )}
      />
      <Route
        path={`${match.url}/product/create`}
        render={() => (
          <AdminCatalogueItemScreen
            catalogueItemType={catalogueItemTypes.PRODUCT}
            isNewItem
          />
        )}
      />
      <Route
        path={`${match.url}/services`}
        render={() => (
          <AdminCatalogueItemListScreen
            catalogueItemType={catalogueItemTypes.SERVICE}
            isServiceCategory
          />
        )}
      />
      <Route
        path={`${match.url}/products`}
        render={() => (
          <AdminCatalogueItemListScreen
            catalogueItemType={catalogueItemTypes.PRODUCT}
          />
        )}
      />
      <Route
        path={`${match.url}/packages`}
        render={() => (
          <AdminCataloguePackagesListScreen />
        )}
      />
      <Route
        path={`${match.url}/package/create`}
        render={() => (
          <AdminCataloguePackageItemScreen
            isNewItem
          />
        )}
      />
      <Route
        path={`${match.url}/package/edit/:itemId`}
        render={() => (
          <AdminCataloguePackageItemScreen />
        )}
      />
      <Route
        exact
        path={`${match.url}/settings`}
        render={() => (
          <AdminCatalogueSettingsScreen />
        )}
      />
      <Route
        path={`${match.url}/settings/packages`}
        render={() => (
          <AdminCataloguePackagesOrderScreen />
        )}
      />
      <Route
        path={`${match.url}/settings/:category`}
        render={() => (
          <AdminCatalogueProductsServicesOrderScreen />
        )}
      />
      {availableFeatures.includes(featureFlags.CATALOGUE_ARRANGEMENT_SETTINGS_SCREEN) && (
        <Route
          exact
          path={`${match.url}/arrangement-settings`}
          render={() => (
            <AdminArrangementSettingsScreen />
          )}
        />
      )}
      {availableFeatures.includes(featureFlags.CATALOGUE_ARRANGEMENT_SETTINGS_SCREEN) && (
        <Route
          exact
          path={`${match.url}/arrangement-settings/:category`}
          render={() => (
            <AdminArrangementSettingsCategoryRouter />
          )}
        />
      )}
      {availableFeatures.includes(featureFlags.CATALOGUE_ARRANGEMENT_SETTINGS_SCREEN) && (
        <Route
          exact
          path={`${match.url}/arrangement-settings/:category/:subCategory`}
          render={() => (
            <AdminArrangementProductsServicesOrderScreen />
          )}
        />
      )}
      <Route
        exact
        path={`${match.url}`}
        render={() => (
          <AdminCatalogueMenuScreen match={match} />
        )}
      />
      <Route key="not-found" component={NotFoundScreen} />
    </Switch>
  </Fragment>
);

AdminCatalogueSectionScreen.propTypes = {
  availableFeatures: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  match: matchType.isRequired,
};

export default AdminCatalogueSectionScreen;
