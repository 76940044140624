import React, { PureComponent } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { resetCatalogueItemsAction } from 'actions/portal';
import { getTenantCatalogueCategories } from 'services/utils/catalogue';
import { CatalogueCategory } from 'types/ts/catalogue';
import { RootState } from 'types/ts/state';

import { Props } from './index.types';
import PortalCatalogueScreen from './PortalCatalogueScreen';

class PortalCatalogueScreenContainer extends PureComponent<Props> {
  componentDidMount() {
    const { resetCatalogueItems } = this.props;
    resetCatalogueItems();
  }

  render() {
    const { tenant, home, match } = this.props;
    if (!tenant) {
      return null;
    }

    const { bereavedPortalStyles } = tenant || {};
    const {
      bereavedPortalLogo: logo,
    } = bereavedPortalStyles || {};
    const {
      cataloguePdfCategoryDisplayPreferences: displayPreferences,
    } = tenant || {};
    const categories: CatalogueCategory[] = getTenantCatalogueCategories(displayPreferences)
      .filter((category: CatalogueCategory) => category.visible);

    return (
      <PortalCatalogueScreen
        categories={categories}
        logoUri={logo?.uri}
        home={home}
        url={match.url}
      />
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  tenant: state?.portalStore?.tenant,
  home: state?.portalStore?.home,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  resetCatalogueItems: resetCatalogueItemsAction,
}, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PortalCatalogueScreenContainer),
);
