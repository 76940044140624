import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MusicSelectionFormSection from './MusicSelectionFormSection';

class MusicSelectionFormSectionContainer extends Component {
  static propTypes = {
    fieldData: PropTypes.objectOf(PropTypes.any),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    fieldData: {
      moment: null,
      time: null,
      title: null,
    },
  }

  handleChange = (key, value) => {
    const { onChange, fieldData } = this.props;

    const newFormData = {
      ...fieldData,
      [key]: value,
    };

    onChange(newFormData);
  }

  render() {
    const { fieldData, disabled } = this.props;
    return (
      <MusicSelectionFormSection
        fieldData={fieldData}
        disabled={disabled}
        onChange={this.handleChange}
      />
    );
  }
}

export default MusicSelectionFormSectionContainer;
