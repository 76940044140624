import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const ExpandableFormSection = ({
  title,
  titleClasses,
  isExpanded,
  toggleIsExpanded,
  disabled,
  disableExpansion,
  children,
  hideInitialElements,
}) => (
  <div className={styles.o_block_content}>
    <h3 className={classNames(
      titleClasses || styles.long_primer,
      styles.is_black,
      styles.u_push__bottom,
    )}
    >
      {title || ''}
    </h3>
    {!hideInitialElements && (
      <div>{children[0] || children}</div>
    )}
    <Collapse in={isExpanded}>
      {!hideInitialElements ? children[1] : children}
    </Collapse>
    {!disableExpansion && (
      <Button
        onClick={toggleIsExpanded}
        className={rcStyles.expansion_button}
        size="small"
        color="primary"
        variant="outlined"
        disabled={disabled}
        data-test-id="expandFormSection"
      >
        {t(`${isExpanded ? 'Less' : 'More'}`)}
        {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>
    )}
  </div>
);

ExpandableFormSection.propTypes = {
  title: PropTypes.string,
  titleClasses: PropTypes.arrayOf(PropTypes.string),
  isExpanded: PropTypes.bool,
  toggleIsExpanded: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  disableExpansion: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  hideInitialElements: PropTypes.bool,
};

export default ExpandableFormSection;
