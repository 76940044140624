import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import PersonIcon from '@material-ui/icons/Person';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';
import { mediaVariants } from 'constants/media';
import { buildNameString, getUriForMediaByVariant } from 'services/utils';
import { staffMemberType } from 'types/staffMember';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const AdminStaffMembersListItem = ({ staffMember }) => {
  const imageUri = getUriForMediaByVariant(staffMember.image, mediaVariants.FULL_FIT_50_X_50);
  const isArrangerUser = staffMember.identityInterface
    && staffMember.identityInterface.isActive;

  return (
    <div className={classNames(
      staffMember.isNew ? styles.c_flag__PENDING : '',
      rcStyles.staff_list__item,
    )}
    >
      <div className={rcStyles.staff_photo}>
        <div className={rcStyles.is_checkmark}>
          {isArrangerUser && (
            <CheckCircleIcon />
          )}
        </div>
        <div className={rcStyles.is_image}>
          {imageUri ? (
            <Avatar>
              <img
                alt={staffMember.name.givenName}
                src={imageUri}
              />
            </Avatar>
          ) : (
            <div className={rcStyles.is_placeholder}>
              <PersonIcon />
            </div>
          )}
        </div>
      </div>
      <div className={rcStyles.user_details}>
        <h3 className={rcStyles.is_name}>
          {buildNameString(staffMember.name)}
        </h3>
        <span className={rcStyles.is_role}>
          {staffMember.jobTitle}
        </span>
      </div>
      <div className={rcStyles.action_icon}>
        <Link to={`/staff-members/${staffMember.id}`}>
          <IconButton
            color="primary"
            variant="contained"
            data-test-id="edit"
          >
            <EditIcon />
          </IconButton>
        </Link>
      </div>
    </div>
  );
};

AdminStaffMembersListItem.propTypes = {
  staffMember: staffMemberType,
};

export default AdminStaffMembersListItem;
