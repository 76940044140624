import { arrangementActions } from 'actions/arrangement';
import { getArrayIndexForId, updateArrayByIndex } from 'services/utils';
import { getOriginalPriceForSelection } from 'services/utils/arrangement';
import { defaultServiceSelectionsForAPackage, defaultProductSelectionsForAPackage } from 'services/utils/package';
import estimateCalculator from 'services/estimateCalculator';

const setConfirmedPricesOnSelections = selections => selections.map(selection => ({
  ...selection,
  salePriceAtConfirmation: getOriginalPriceForSelection(selection),
}));

const unsetConfirmedPricesOnSelections = selections => selections.map(
  selection => ({ ...selection, salePriceAtConfirmation: null }),
);

const confirmArrangement = (state, action) => {
  const { bereavementId, arrangementId } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const { arrangements } = state.bereavements[bereavementIndex];
  if (!arrangements) {
    return state;
  }

  const arrangementExists = arrangements.find(arrangement => arrangement.id === arrangementId);
  if (!arrangementExists) {
    return state;
  }

  const updatedArrangements = arrangements.map((arrangement) => {
    const updatedArrangement = { ...arrangement, isConfirmed: false };
    if (updatedArrangement.id !== arrangementId) {
      return updatedArrangement;
    }

    updatedArrangement.isConfirmed = true;
    if (updatedArrangement.productSelections) {
      updatedArrangement.productSelections = setConfirmedPricesOnSelections(
        updatedArrangement.productSelections,
      );
    }
    if (updatedArrangement.serviceSelections) {
      updatedArrangement.serviceSelections = setConfirmedPricesOnSelections(
        updatedArrangement.serviceSelections,
      );
    }
    if (updatedArrangement.packageSelection) {
      updatedArrangement.packageSelection = {
        ...updatedArrangement.packageSelection,
        salePriceAtConfirmation: updatedArrangement.packageSelection.package.price,
      };
    }

    return updatedArrangement;
  });

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...state.bereavements[bereavementIndex],
        arrangements: updatedArrangements,
      },
    ),
  };
};

const unconfirmArrangement = (state, action) => {
  const { bereavementId, arrangementId } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const { arrangements } = state.bereavements[bereavementIndex];
  if (!arrangements) {
    return state;
  }

  const arrangementExists = arrangements.find(arrangement => arrangement.id === arrangementId);
  if (!arrangementExists) {
    return state;
  }

  const updatedArrangements = arrangements.map((arrangement) => {
    const updatedArrangement = {
      ...arrangement,
      isConfirmed: false,
      productSelections: arrangement.productSelections
        && unsetConfirmedPricesOnSelections(arrangement.productSelections),
      serviceSelections: arrangement.serviceSelections
        && unsetConfirmedPricesOnSelections(arrangement.serviceSelections),
    };
    return updatedArrangement;
  });

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...state.bereavements[bereavementIndex],
        arrangements: updatedArrangements,
      },
    ),
  };
};

const addSelection = (typeOfSelection, state, action) => {
  const {
    bereavementId,
    arrangementId,
    selectionId,
    item,
    isPackageSelection,
    variantId,
    overridePrice,
  } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const { arrangements } = state.bereavements[bereavementIndex];
  const arrangementIndex = getArrayIndexForId(arrangements, arrangementId);

  const newSelection = {
    id: selectionId,
    isPackageSelection,
    overridePrice,
    variantId,
    salePriceAtConfirmation: null,
  };
  newSelection[typeOfSelection] = item;
  newSelection[`${typeOfSelection}Id`] = item.id;

  const existingSelections = arrangements[arrangementIndex][`${typeOfSelection}Selections`]
    ? arrangements[arrangementIndex][`${typeOfSelection}Selections`]
    : [];

  const newState = {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...state.bereavements[bereavementIndex],
        arrangements: updateArrayByIndex(
          arrangements,
          arrangementIndex,
          {
            ...arrangements[arrangementIndex],
            [`${typeOfSelection}Selections`]: [
              ...existingSelections,
              newSelection,
            ],
          },
        ),
      },
    ),
  };

  if (!arrangements[arrangementIndex].isConfirmed) {
    return newState;
  }

  return unconfirmArrangement(newState, {
    payload: { bereavementId, arrangementId },
  });
};

const addProductSelection = (state, action) => addSelection('product', state, action);

const addServiceSelection = (state, action) => addSelection('service', state, action);

const editSelection = (typeOfSelection, state, action) => {
  const {
    bereavementId,
    arrangementId,
    selectionProps,
    selectionId,
    allowUnconfirmArrangement = true,
  } = action.payload;
  const selectionsArray = `${typeOfSelection}Selections`;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const bereavement = state.bereavements[bereavementIndex];

  if (!bereavement.arrangements) {
    return state;
  }

  const arrangementIndex = getArrayIndexForId(bereavement.arrangements, arrangementId);

  if (!bereavement.arrangements[arrangementIndex][selectionsArray]) {
    return state;
  }

  const selectionIndex = getArrayIndexForId(
    bereavement.arrangements[arrangementIndex][selectionsArray],
    selectionId,
  );

  const newState = {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...bereavement,
        arrangements: updateArrayByIndex(
          bereavement.arrangements,
          arrangementIndex,
          {
            ...bereavement.arrangements[arrangementIndex],
            [selectionsArray]: updateArrayByIndex(
              bereavement.arrangements[arrangementIndex][selectionsArray],
              selectionIndex,
              {
                ...bereavement.arrangements[arrangementIndex][selectionsArray][selectionIndex],
                ...selectionProps,
              },
            ),
          },
        ),
      },
    ),
  };

  if (!allowUnconfirmArrangement || !bereavement.arrangements[arrangementIndex].isConfirmed) {
    return newState;
  }

  return unconfirmArrangement(newState, {
    payload: { bereavementId, arrangementId },
  });
};

const editProductSelection = (state, action) => editSelection('product', state, action);

const editServiceSelection = (state, action) => editSelection('service', state, action);

const removeSelection = (typeOfSelection, state, action) => {
  const { bereavementId, arrangementId, selectionId } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const { arrangements } = state.bereavements[bereavementIndex];
  const selectionsArray = `${typeOfSelection}Selections`;
  const arrangementIndex = getArrayIndexForId(arrangements, arrangementId);

  if (!arrangements[arrangementIndex][selectionsArray]) {
    return state;
  }

  const selections = arrangements[arrangementIndex][selectionsArray]
    .filter(selection => selection.id !== selectionId);

  const newState = {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...state.bereavements[bereavementIndex],
        arrangements: updateArrayByIndex(
          arrangements,
          arrangementIndex,
          {
            ...arrangements[arrangementIndex],
            [selectionsArray]: selections,
          },
        ),
      },
    ),
  };

  if (!arrangements[arrangementIndex].isConfirmed) {
    return newState;
  }

  return unconfirmArrangement(newState, {
    payload: { bereavementId, arrangementId },
  });
};

const removeProductSelection = (state, action) => removeSelection('product', state, action);

const removeServiceSelection = (state, action) => removeSelection('service', state, action);

const setCategoryInformation = (state, action) => {
  const {
    bereavementId, arrangementId, category, value,
  } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const bereavement = state.bereavements[bereavementIndex];
  const { arrangements } = bereavement;
  const arrangementIndex = getArrayIndexForId(arrangements, arrangementId);

  const categoryInformation = {
    ...arrangements[arrangementIndex].categoryInformation,
  };

  if (['crematorium', 'cemetery'].includes(category)
    && value.startDateTime) {
    bereavement.committalDate = value.startDateTime;
    if (!categoryInformation.serviceVenue || !categoryInformation.serviceVenue.startDateTime) {
      bereavement.funeralDate = bereavement.committalDate;
    }
  }

  if (category === 'serviceVenue' && value.startDateTime) {
    bereavement.funeralDate = value.startDateTime;
  }

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...state.bereavements[bereavementIndex],
        arrangements: updateArrayByIndex(
          arrangements,
          arrangementIndex,
          {
            ...arrangements[arrangementIndex],
            categoryInformation: {
              ...categoryInformation,
              [category]: value,
            },
          },
        ),
      },
    ),
  };
};

const editPackageSelection = (state, action) => {
  const { bereavementId, arrangementId } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const { arrangements } = state.bereavements[bereavementIndex];
  const arrangementIndex = getArrayIndexForId(arrangements, arrangementId);

  if (!arrangements[arrangementIndex].packageSelection) {
    return state;
  }

  const newState = {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...state.bereavements[bereavementIndex],
        arrangements: updateArrayByIndex(
          arrangements,
          arrangementIndex,
          {
            ...arrangements[arrangementIndex],
            packageSelection: {
              ...arrangements[arrangementIndex].packageSelection,
              ...action.payload.packageProps,
            },
          },
        ),
      },
    ),
  };

  if (!arrangements[arrangementIndex].isConfirmed) {
    return newState;
  }

  return unconfirmArrangement(newState, {
    payload: { bereavementId, arrangementId },
  });
};

const selectDiscountType = (state, action) => {
  const { bereavementId, arrangementId, discountType } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const { arrangements } = state.bereavements[bereavementIndex];
  const arrangementIndex = getArrayIndexForId(arrangements, arrangementId);

  const newState = {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...state.bereavements[bereavementIndex],
        arrangements: updateArrayByIndex(
          arrangements,
          arrangementIndex,
          {
            ...arrangements[arrangementIndex],
            discountType,
          },
        ),
      },
    ),
  };

  if (!arrangements[arrangementIndex].isConfirmed) {
    return newState;
  }

  return unconfirmArrangement(newState, {
    payload: { bereavementId, arrangementId },
  });
};

const setRequestedDeposit = (state, action) => {
  const { bereavementId, arrangementId, requestedDeposit } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const { arrangements } = state.bereavements[bereavementIndex];
  const arrangementIndex = getArrayIndexForId(arrangements, arrangementId);

  const newState = {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...state.bereavements[bereavementIndex],
        arrangements: updateArrayByIndex(
          arrangements,
          arrangementIndex,
          {
            ...arrangements[arrangementIndex],
            requestedDeposit,
          },
        ),
      },
    ),
  };

  if (!arrangements[arrangementIndex].isConfirmed) {
    return newState;
  }

  return unconfirmArrangement(newState, {
    payload: { bereavementId, arrangementId },
  });
};

const setGlobalDiscount = (state, action) => {
  const { bereavementId, arrangementId } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const { arrangements } = state.bereavements[bereavementIndex];
  const arrangementIndex = getArrayIndexForId(arrangements, arrangementId);

  const newState = {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...state.bereavements[bereavementIndex],
        arrangements: updateArrayByIndex(
          arrangements,
          arrangementIndex,
          {
            ...arrangements[arrangementIndex],
            globalDiscount: action.payload.globalDiscount,
          },
        ),
      },
    ),
  };

  if (!arrangements[arrangementIndex].isConfirmed) {
    return newState;
  }

  return unconfirmArrangement(newState, {
    payload: { bereavementId, arrangementId },
  });
};

const unselectPackage = (state, action) => {
  const { bereavementId, arrangementId } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const { arrangements } = state.bereavements[bereavementIndex];
  const arrangementIndex = arrangements && getArrayIndexForId(arrangements, arrangementId);

  if (!arrangements || !arrangements[arrangementIndex]) {
    return state;
  }

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...state.bereavements[bereavementIndex],
        arrangements: updateArrayByIndex(
          arrangements,
          arrangementIndex,
          {
            ...arrangements[arrangementIndex],
            packageSelection: null,
            productSelections: [],
            serviceSelections: [],
          },
        ),
      },
    ),
  };
};

const selectPackage = (state, action) => {
  const { bereavementId, arrangementId, packageItem } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const { arrangements } = state.bereavements[bereavementIndex];
  const arrangementIndex = arrangements && getArrayIndexForId(arrangements, arrangementId);

  if (!arrangements || !arrangements[arrangementIndex]) {
    return state;
  }

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...state.bereavements[bereavementIndex],
        arrangements: updateArrayByIndex(
          arrangements,
          arrangementIndex,
          {
            ...arrangements[arrangementIndex],
            packageSelection: {
              overridePrice: null,
              packageId: packageItem.id,
              package: packageItem,
            },
            productSelections: defaultProductSelectionsForAPackage(packageItem),
            serviceSelections: defaultServiceSelectionsForAPackage(packageItem),
          },
        ),
      },
    ),
  };
};

export const arrangementsCalculatorReducer = (state, action) => {
  if (!action
    || !action.payload
    || !action.payload.bereavementId
    || action.payload.arrangementId === undefined) {
    return state;
  }

  const { bereavementId, arrangementId } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const { arrangements } = state.bereavements[bereavementIndex];

  if (!arrangements) {
    return state;
  }

  const arrangementIndex = getArrayIndexForId(arrangements, arrangementId);
  const arrangement = arrangements[arrangementIndex];

  if (!arrangement) {
    return state;
  }

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...state.bereavements[bereavementIndex],
        arrangements: updateArrayByIndex(
          arrangements,
          arrangementIndex,
          {
            ...arrangement,
            calculation: estimateCalculator(arrangement),
          },
        ),
      },
    ),
  };
};

export const setOrderOfEstimateSelections = (state, action) => {
  const { bereavementId, arrangementId, selectionIds } = action.payload;

  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const { arrangements } = state.bereavements[bereavementIndex];
  const arrangementIndex = arrangements && getArrayIndexForId(arrangements, arrangementId);

  if (!arrangements || !arrangements[arrangementIndex]) {
    return state;
  }

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...state.bereavements[bereavementIndex],
        arrangements: updateArrayByIndex(
          arrangements,
          arrangementIndex,
          {
            ...arrangements[arrangementIndex],
            orderOfEstimateSelections: selectionIds,
          },
        ),
      },
    ),
  };
};

export const clearOrderOfEstimateSelections = (state, action) => {
  const { bereavementId, arrangementId } = action.payload;

  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const { arrangements } = state.bereavements[bereavementIndex];
  const arrangementIndex = arrangements && getArrayIndexForId(arrangements, arrangementId);

  if (!arrangements || !arrangements[arrangementIndex]) {
    return state;
  }

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...state.bereavements[bereavementIndex],
        arrangements: updateArrayByIndex(
          arrangements,
          arrangementIndex,
          {
            ...arrangements[arrangementIndex],
            orderOfEstimateSelections: [],
          },
        ),
      },
    ),
  };
};

export const arrangementReducer = {
  [arrangementActions.ADD_PRODUCT_SELECTION]: addProductSelection,
  [arrangementActions.ADD_SERVICE_SELECTION]: addServiceSelection,
  [arrangementActions.EDIT_PRODUCT_SELECTION]: editProductSelection,
  [arrangementActions.EDIT_SERVICE_SELECTION]: editServiceSelection,
  [arrangementActions.REMOVE_PRODUCT_SELECTION]: removeProductSelection,
  [arrangementActions.REMOVE_SERVICE_SELECTION]: removeServiceSelection,
  [arrangementActions.SET_CATEGORY_INFORMATION]: setCategoryInformation,
  [arrangementActions.EDIT_PACKAGE_SELECTION]: editPackageSelection,
  [arrangementActions.SELECT_DISCOUNT_TYPE]: selectDiscountType,
  [arrangementActions.SET_REQUESTED_DEPOSIT]: setRequestedDeposit,
  [arrangementActions.SET_GLOBAL_DISCOUNT]: setGlobalDiscount,
  [arrangementActions.CONFIRM_ARRANGEMENT]: confirmArrangement,
  [arrangementActions.UNCONFIRM_ARRANGEMENT]: unconfirmArrangement,
  [arrangementActions.SELECT_PACKAGE]: selectPackage,
  [arrangementActions.UNSELECT_PACKAGE]: unselectPackage,
  [arrangementActions.SET_ORDER_OF_ESTIMATE_SELECTIONS]: setOrderOfEstimateSelections,
  [arrangementActions.CLEAR_ORDER_OF_ESTIMATE_SELECTIONS]: clearOrderOfEstimateSelections,
};

export default arrangementReducer;
