import React, { Component } from 'react';
import { committalTypes } from 'constants/arrangement';
import { arrangementType } from 'types/bereavement';
import { matchType, historyType } from 'types/reactRouter';
import ArrangementServiceTabs from './ArrangementServiceTabs';

export class ArrangementServiceTabsContainer extends Component {
  static propTypes = {
    arrangement: arrangementType.isRequired,
    history: historyType.isRequired,
    match: matchType.isRequired,
  };

  handlePathChange = (selectedPath) => {
    const { history, match } = this.props;

    history.push(`${match.url}/${selectedPath}`);
  }

  getServiceCategories = () => {
    const { arrangement } = this.props;
    const isBurial = arrangement && arrangement.committalType === committalTypes.BURIAL;
    const categories = [
      { path: 'service-venue', title: 'Service Venue' },
      !isBurial && { path: 'crematorium', title: 'Crematorium' },
      { path: 'burial', title: 'Burial' },
      { path: 'reception-venue', title: 'Reception' },
    ];
    return categories.filter(category => category);
  }

  render = () => (
    <ArrangementServiceTabs
      {...this.props}
      serviceCategories={this.getServiceCategories()}
      onPathChange={this.handlePathChange}
    />
  );
}

export default ArrangementServiceTabsContainer;
