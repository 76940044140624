import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MuiThemeProvider } from '@material-ui/core/styles';
import overrideTheme from 'services/themes/overrideTheme';
import Skeleton from 'react-loading-skeleton';
import { productType, serviceType } from 'types/bereavement';
import CatalogueItem from 'components/catalogue/CatalogueItem';
import CatalogueItemsSliderModal from 'components/catalogue/CatalogueItemsSliderModal';
import TagBanner from 'components/common/TagBanner';
import SortMenu from 'components/common/SortMenu';
import ConfirmationModal from 'components/common/ConfirmationModal';
import { allCatalogueItemSortOrders } from 'constants/catalogue';
import { changeConfirmedArrangementMessage } from 'constants/arrangement';
import IconLoad from 'icons/IconLoad';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const CatalogueItemList = ({
  catalogueItems,
  tags,
  areTagsLoading,
  itemIdsInPackage,
  hasMoreCatalogueItems,
  hasArrangement,
  isArrangementConfirmed,
  isLoading,
  duplicateSelectionItem,
  confirmSelectionItem,
  detailDisplayIndex,
  disabled,
  onAddSelection,
  focusNewSelection,
  onTagChange,
  getMoreCatalogueItems,
  setDetailDisplay,
  onDuplicateItemConfirm,
  onDuplicateItemCancel,
  onSelectionConfirm,
  onSelectionCancel,
  onSortChange,
}) => (
  <Fragment>
    <MuiThemeProvider theme={overrideTheme}>
      <div className={rcStyles.rc_slider_wrap}>
        {areTagsLoading ? (
          hasArrangement && <Skeleton height={25} />
        ) : (
          <TagBanner
            areTagsLoading={areTagsLoading}
            tags={tags}
            onChange={onTagChange}
            data-test-id="tagBanner"
          />
        )}
      </div>
      <span className={styles.u_text__right}>
        <SortMenu
          options={allCatalogueItemSortOrders}
          testIds={{
            button: 'sortArrangementButton',
            menu: 'sortArrangementMenu',
            menuItem: 'SortArrangementFilter',
          }}
          onChangeFilters={onSortChange}
        />
      </span>
      <div className={styles.u_push__bottom_xl}>
        {isLoading && (
          <div className={classNames(styles.c_loading_list)}>
            <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
              <IconLoad />
            </div>
          </div>
        )}

        <InfiniteScroll
          dataLength={catalogueItems.length}
          hasMore={hasMoreCatalogueItems}
          next={getMoreCatalogueItems}
          className={classNames(
            styles.u_overflow__hidden,
          )}
          loader={catalogueItems.length > 0 && (
            <div className={styles.c_svg_loader_infinite_scroll} data-test-id="infiniteScrollLoader">
              <IconLoad />
            </div>
          )}
        >
          <div className={classNames(styles.c_grid__3col)}>
            {catalogueItems.map((item, index) => (
              <CatalogueItem
                item={item}
                key={item.id}
                isInPackage={itemIdsInPackage && itemIdsInPackage.includes(item.id)}
                hasArrangement={hasArrangement}
                isArrangementConfirmed={isArrangementConfirmed}
                disabled={disabled}
                onAddSelection={() => onAddSelection(item)}
                onView={() => setDetailDisplay(index)}
                focusNewSelection={focusNewSelection}
              />
            ))}
          </div>
        </InfiniteScroll>
        <CatalogueItemsSliderModal
          isOpen={detailDisplayIndex !== null}
          allItems={catalogueItems}
          itemIdsInPackage={itemIdsInPackage}
          disabled={disabled}
          onAddSelection={onAddSelection}
          onClose={setDetailDisplay}
          focusNewSelection={focusNewSelection}
          detailDisplayIndex={detailDisplayIndex}
        />
      </div>
      <ConfirmationModal
        message={t(`${duplicateSelectionItem?.title} has already been added to this arrangement. Do you wish to continue to add this selection?`)}
        isOpen={!!duplicateSelectionItem}
        icon="duplicate"
        onConfirm={onDuplicateItemConfirm}
        onCancel={onDuplicateItemCancel}
      />
      <ConfirmationModal
        message={t(changeConfirmedArrangementMessage)}
        isOpen={!!confirmSelectionItem}
        icon="warning"
        onConfirm={onSelectionConfirm}
        onCancel={onSelectionCancel}
      />
    </MuiThemeProvider>
  </Fragment>
);

CatalogueItemList.propTypes = {
  catalogueItems: PropTypes.oneOfType([
    PropTypes.arrayOf(productType).isRequired,
    PropTypes.arrayOf(serviceType).isRequired,
  ]).isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  areTagsLoading: PropTypes.bool.isRequired,
  itemIdsInPackage: PropTypes.arrayOf(PropTypes.string),
  hasMoreCatalogueItems: PropTypes.bool.isRequired,
  hasArrangement: PropTypes.bool.isRequired,
  isArrangementConfirmed: PropTypes.bool,
  isLoading: PropTypes.bool,
  duplicateSelectionItem: PropTypes.oneOfType([
    productType.isRequired,
    serviceType.isRequired,
  ]),
  confirmSelectionItem: PropTypes.oneOfType([
    productType.isRequired,
    serviceType.isRequired,
  ]),
  detailDisplayIndex: PropTypes.number,
  disabled: PropTypes.bool,
  onAddSelection: PropTypes.func.isRequired,
  onTagChange: PropTypes.func,
  focusNewSelection: PropTypes.func,
  getMoreCatalogueItems: PropTypes.func.isRequired,
  setDetailDisplay: PropTypes.func.isRequired,
  onDuplicateItemConfirm: PropTypes.func.isRequired,
  onDuplicateItemCancel: PropTypes.func.isRequired,
  onSelectionConfirm: PropTypes.func.isRequired,
  onSelectionCancel: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
};

CatalogueItemList.defaultProps = {
  tags: [],
};

export default CatalogueItemList;
