import {
  arrangementPaths,
  serviceCategories,
  productCategories,
  catalogueItemTypes,
  arrangementCategories,
} from './arrangement';

export const catalogueCategoriesData = [
  {
    category: productCategories.FLOWERS,
    name: 'Flowers',
    imagePath: '/img/nav_menu/flowers.jpg',
    reference: 'flowers',
    type: catalogueItemTypes.PRODUCT,
    path: arrangementPaths.FLOWERS,
    isEditable: true,
  },
  {
    category: productCategories.URNS,
    name: 'Urns',
    imagePath: '/img/nav_menu/urns.jpg',
    reference: 'urns',
    type: catalogueItemTypes.PRODUCT,
    path: arrangementPaths.URNS,
    isEditable: true,
  },
  {
    category: productCategories.MEMORIALISATION,
    name: 'Memorialisation',
    imagePath: '/img/nav_menu/memorial.jpg',
    reference: 'memorialisation',
    type: catalogueItemTypes.PRODUCT,
    path: arrangementPaths.MEMORIALISATION,
    isEditable: true,
  },
  {
    category: productCategories.COFFINS,
    name: 'Coffins',
    imagePath: '/img/nav_menu/coffins.jpg',
    reference: 'coffins',
    type: catalogueItemTypes.PRODUCT,
    path: arrangementPaths.COFFINS,
    isEditable: true,
  },
  {
    category: serviceCategories.VEHICLES,
    name: 'Vehicles',
    imagePath: '/img/nav_menu/cortege.jpg',
    reference: 'vehicles',
    type: catalogueItemTypes.SERVICE,
    path: arrangementPaths.CORTEGE,
    isEditable: true,
  },
  {
    category: serviceCategories.OFFICIANTS,
    name: 'Officiants',
    imagePath: '/img/nav_menu/officiants.jpg',
    reference: 'officiants',
    type: catalogueItemTypes.SERVICE,
    path: arrangementPaths.OFFICIANTS,
    isEditable: true,
  },
  {
    category: serviceCategories.SERVICE_VENUES,
    name: 'Service venues',
    imagePath: '/img/nav_menu/service.jpg',
    reference: 'serviceVenues',
    type: catalogueItemTypes.SERVICE,
    path: arrangementPaths.SERVICE_VENUE,
    isEditable: true,
  },
  {
    category: serviceCategories.CREMATORIA,
    name: 'Crematoria',
    imagePath: '/img/nav_menu/crematorium.jpg',
    reference: 'crematoria',
    type: catalogueItemTypes.SERVICE,
    path: arrangementPaths.CREMATORIUM,
    isEditable: true,
  },
  {
    category: serviceCategories.CEMETERIES,
    name: 'Cemeteries',
    imagePath: '/img/nav_menu/cemetery.jpg',
    reference: 'cemeteries',
    type: catalogueItemTypes.SERVICE,
    path: arrangementPaths.BURIAL,
    isEditable: true,
  },
  {
    category: serviceCategories.PROFESSIONAL_SERVICES,
    name: 'Professional services',
    imagePath: '/img/nav_menu/estimate.png',
    reference: 'professionalServices',
    type: catalogueItemTypes.SERVICE,
    path: 'professional-services',
    isEditable: true,
  },
  {
    category: serviceCategories.RECEPTION_VENUES,
    name: 'Reception venues',
    imagePath: '/img/nav_menu/reception_venue.jpg',
    reference: 'receptionVenues',
    type: catalogueItemTypes.SERVICE,
    path: arrangementPaths.RECEPTION_VENUE,
    isEditable: true,
  },
  {
    category: serviceCategories.OTHER,
    name: 'Other',
    imagePath: '/img/nav_menu/other.jpg',
    reference: 'other',
    type: catalogueItemTypes.SERVICE,
    path: arrangementPaths.OTHER,
    isEditable: true,
  },
  {
    category: 'PACKAGES',
    name: 'Packages',
    imagePath: '/img/nav_menu/packages.jpg',
    reference: 'packages',
    path: arrangementPaths.PACKAGES,
    isEditable: true,
  },
];

const getCategoriesDataByCategories = (categories = []) => catalogueCategoriesData.filter(
  category => categories.includes(category.category),
);

export const arrangementSettingsCategories = [
  {
    category: arrangementCategories.PACKAGES,
    resourceCategories: getCategoriesDataByCategories([arrangementCategories.PACKAGES]),
    name: 'Packages',
    imagePath: '/img/nav_menu/packages.jpg',
    reference: 'packages',
    path: 'packages',
    isEditable: true,
  },
  {
    category: arrangementCategories.CARE,
    resourceCategories: getCategoriesDataByCategories([serviceCategories.PROFESSIONAL_SERVICES]),
    name: 'Care',
    imagePath: '/img/nav_menu/care.jpg',
    reference: 'care',
    path: 'care',
    isEditable: true,
    requiresFeatureFlag: true,
  },
  {
    category: arrangementCategories.VENUES,
    resourceCategories: getCategoriesDataByCategories([
      serviceCategories.RECEPTION_VENUES,
      serviceCategories.SERVICE_VENUES,
      serviceCategories.CREMATORIA,
      serviceCategories.CEMETERIES,
    ]),
    name: 'Venues',
    imagePath: '/img/nav_menu/venue.jpg',
    reference: 'venues',
    path: 'venues',
    isEditable: true,
  },
  {
    category: arrangementCategories.CORTEGE,
    resourceCategories: getCategoriesDataByCategories([serviceCategories.VEHICLES]),
    name: 'Cortege',
    imagePath: '/img/nav_menu/cortege.jpg',
    path: arrangementPaths.CORTEGE,
    isEditable: true,
  },
  {
    category: arrangementCategories.COFFINS,
    resourceCategories: getCategoriesDataByCategories([productCategories.COFFINS]),
    name: 'Coffins',
    imagePath: '/img/nav_menu/coffins.jpg',
    path: arrangementPaths.COFFINS,
    isEditable: true,
  },
  {
    category: arrangementCategories.URNS,
    resourceCategories: getCategoriesDataByCategories([productCategories.URNS]),
    name: 'Urns',
    imagePath: '/img/nav_menu/urns.jpg',
    path: arrangementPaths.URNS,
    isEditable: true,
  },
  {
    category: arrangementCategories.OFFICIANTS,
    resourceCategories: getCategoriesDataByCategories([serviceCategories.OFFICIANTS]),
    name: 'Officiants',
    imagePath: '/img/nav_menu/officiants.jpg',
    path: arrangementPaths.OFFICIANTS,
    isEditable: true,
  },
  {
    category: arrangementCategories.MUSIC,
    name: 'Music',
    imagePath: '/img/nav_menu/music.jpg',
    requiresFeatureFlag: true,
  },
  {
    category: arrangementCategories.FLOWERS,
    resourceCategories: getCategoriesDataByCategories([productCategories.FLOWERS]),
    name: 'Flowers',
    imagePath: '/img/nav_menu/flowers.jpg',
    path: arrangementPaths.FLOWERS,
    isEditable: true,
  },
  {
    category: arrangementCategories.OTHER,
    resourceCategories: getCategoriesDataByCategories([serviceCategories.OTHER]),
    name: 'Other',
    imagePath: '/img/nav_menu/other.jpg',
    path: arrangementPaths.OTHER,
    isEditable: true,
  },
  {
    category: arrangementCategories.MEMORIALISATION,
    resourceCategories: getCategoriesDataByCategories([productCategories.MEMORIALISATION]),
    name: 'Memorialisation',
    imagePath: '/img/nav_menu/memorial.jpg',
    path: arrangementPaths.MEMORIALISATION,
    isEditable: true,
  },
  {
    category: arrangementCategories.OBITUARY,
    name: 'Obituary',
    imagePath: '/img/nav_menu/obituaries.jpg',
    requiresFeatureFlag: true,
  },
];

export const catalogueStatuses = [
  { name: 'Published', enum: 'PUBLISHED' },
  { name: 'Draft', enum: 'DRAFT' },
  { name: 'Retired', enum: 'RETIRED' },
];

export const catalogueProductCategories = [
  {
    category: productCategories.FLOWERS,
    name: 'Flowers',
  },
  {
    category: productCategories.URNS,
    name: 'Urns',
  },
  {
    category: productCategories.MEMORIALISATION,
    name: 'Memorialisation',
  },
  {
    category: productCategories.COFFINS,
    name: 'Coffins',
  },
];

export const catalogueServiceCategories = [
  {
    category: 'DISBURSEMENTS',
    name: 'Disbursements',
  },
  {
    category: serviceCategories.VEHICLES,
    name: 'Vehicles',
  },
  {
    category: serviceCategories.OFFICIANTS,
    name: 'Officiants',
  },
  {
    category: serviceCategories.SERVICE_VENUES,
    name: 'Service venues',
  },
  {
    category: serviceCategories.CREMATORIA,
    name: 'Crematoria',
  },
  {
    category: serviceCategories.PROFESSIONAL_SERVICES,
    name: 'Professional services',
  },
  {
    category: serviceCategories.OTHER,
    name: 'Other',
  },
  {
    category: serviceCategories.CEMETERIES,
    name: 'Cemeteries',
  },
  {
    category: serviceCategories.RECEPTION_VENUES,
    name: 'Reception venue',
  },
];

export const catalogueItemSortOrder = {
  PRICE_LOW_TO_HIGH: 'PRICE_LOW_TO_HIGH',
  PRICE_HIGH_TO_LOW: 'PRICE_HIGH_TO_LOW',
  A_Z: 'A_Z',
};

export const allCatalogueItemSortOrders = [
  { enum: catalogueItemSortOrder.PRICE_LOW_TO_HIGH, label: 'Price Low - High' },
  { enum: catalogueItemSortOrder.PRICE_HIGH_TO_LOW, label: 'Price High - Low' },
  { enum: catalogueItemSortOrder.A_Z, label: 'Product A-Z' },
];

export const statuses = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
  RETIRED: 'RETIRED',
};

export const variantStatuses = {
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
};
