import React from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import { featureFlags } from 'constants/features';
import styles from 'scss/main.scss';
import { RootState } from 'types/ts/state';
import { TenantFeature } from 'types/ts/tenant';

import rcStyles from './styles.scss';
import { Props } from './index.types';

const PageTabs: React.FC<Props> = ({ availableFeatures }: Props) => {
  const tabs = [
    {
      to: '/templates',
      label: 'Letter Templates',
      disabled: !availableFeatures.includes(featureFlags.CORRESPONDENCE_TEMPLATES_SCREEN),
    },
    {
      to: '/templates/estimate',
      label: 'Estimate',
      disabled: !availableFeatures.includes(featureFlags.ESTIMATE_TEMPLATES_SCREEN),
    },
    {
      to: '/templates/invoice',
      label: 'Invoice',
      disabled: !availableFeatures.includes(featureFlags.INVOICE_TEMPLATES_SCREEN),
    },
    {
      to: '/templates/values',
      label: 'Values',
      disabled: !availableFeatures.includes(featureFlags.CORRESPONDENCE_VALUES_SCREEN),
    },
  ];

  return (
    <div className={classNames(styles.o_view, rcStyles.container)}>
      <div className={styles.o_view__main}>
        <div className={rcStyles.page_tabs}>
          {tabs.filter(tab => !tab.disabled)
            .map(({ to, label }) => (
              <NavLink
                to={to}
                exact
                className={rcStyles.page_tab}
                activeClassName={rcStyles.page_tab__active}
              >
                {i18next.t(label)}
              </NavLink>
            ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  availableFeatures: state.userStore.user.tenantFeatureFlags as TenantFeature[],
});

export default connect(mapStateToProps)(PageTabs);
