import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import PropTypes from 'prop-types';
import { arrangementType } from 'types/bereavement';
import EstimateSelection from 'components/estimate/EstimateSelection';

const SortableEstimateSelection = SortableElement(({
  bereavementId,
  arrangement,
  selection,
  onOpenConfirmationWarningModal,
  disabled,
}) => (
  <EstimateSelection
    bereavementId={bereavementId}
    arrangement={arrangement}
    selection={selection}
    onOpenConfirmationWarningModal={onOpenConfirmationWarningModal}
    disabled={disabled}
  />
));

SortableEstimateSelection.propTypes = {
  bereavementId: PropTypes.string.isRequired,
  arrangement: arrangementType.isRequired,
  onOpenConfirmationWarningModal: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  selection: PropTypes.any.isRequired,
};

export default SortableEstimateSelection;
