import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const IconCoffin = (props) => {
  const { className } = props;
  const classes = className ? `${className} ${styles.rc_icon}` : styles.rc_icon;

  return (
    <svg viewBox="0 0 24 24" className={classes}>
      <path d="M16.4 2.3H8L6.2 8.6l2.4 13.2H15.9l2.7-13-2.2-6.5zm-2 17.6h-.7l2.1-11.3-1.3-4.4h.6L16.8 9l-2.4 10.9z" />
    </svg>
  );
};

IconCoffin.propTypes = {
  className: PropTypes.string,
};

export default IconCoffin;
