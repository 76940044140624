import { categories, categoriesForScope } from 'constants/task';
import { committalTypes } from 'constants/arrangement';

export const textForCategoryDefinitions = {
  [categories.ARRANGER]: 'Arranger',
  [categories.DRIVER]: 'Driver',
  [categories.MORTUARY]: 'Mortuary',
  [categories.OPERATIONS]: 'Operations',
  [categories.FINANCE]: 'Finance',
  [categories.WORKSHOP]: 'Workshop',
};

export const textForCategory = category => textForCategoryDefinitions[category];

export const updateValueForFieldByKey = (fieldset, key, value) => {
  const index = fieldset.reduce((acc, field, i) => {
    if (field.key === key) {
      return i;
    }
    return (acc !== undefined) ? acc : undefined;
  }, undefined);
  const newFieldset = [...fieldset];
  newFieldset[index] = {
    ...fieldset[index],
    value,
  };
  return newFieldset;
};

export const getAllowedCategoriesForScopes = scopes => scopes.reduce((acc, scope) => {
  if (categoriesForScope[scope] === undefined) {
    return acc;
  }
  return [
    ...acc,
    ...categoriesForScope[scope].filter(category => !acc.includes(category)),
  ];
}, []);

export const getServiceData = (task = {}) => {
  const confirmedArrangement = task.bereavement
    && task.bereavement.arrangements
    && task.bereavement.arrangements
      .find(arrangement => arrangement.isConfirmed);

  if (!confirmedArrangement) {
    return null;
  }

  const { categoryInformation, committalType } = confirmedArrangement;
  const serviceData = {
    committalType: confirmedArrangement.committalType,
    startDateTime: committalType === committalTypes.CREMATION
      ? categoryInformation && categoryInformation.crematorium && categoryInformation.crematorium.startDateTime
      : categoryInformation && categoryInformation.cemetery && categoryInformation.cemetery.startDateTime,
  };

  return serviceData.startDateTime ? serviceData : null;
};
