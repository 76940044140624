import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { isValidNumericKeyCode, isValidToFixedDigit } from 'services/utils';

const NumberField = (props) => {
  const {
    label, value, fixed, inputProps, onBlur, onChange, onKeyDown, 'data-test-id': dataTestId,
  } = props;
  return (
    <TextField
      {...props}
      label={label}
      type="number"
      value={value && parseFloat(value)}
      inputProps={{
        'step': '1',
        ...inputProps,
        'data-test-id': dataTestId,
      }}
      onKeyDown={(event) => {
        if (onKeyDown) {
          onKeyDown(event);
        } else {
          const { keyCode, ctrlKey, metaKey } = event;
          if (!isValidNumericKeyCode(keyCode, ctrlKey, metaKey)) {
            event.preventDefault();
          }
        }
      }}
      onBlur={(event) => {
        if (onBlur) {
          onBlur(event);
        } else {
          const e = event;
          const parsedValue = parseFloat(event.target.value);
          if (Number.isNaN(parsedValue)) {
            e.target.value = null;
          } else {
            e.target.value = isValidToFixedDigit(fixed)
              ? parsedValue.toFixed(fixed)
              : parsedValue;
          }
          if (onChange) {
            onChange(e);
          }
        }
      }}
    />
  );
};

NumberField.propTypes = {
  'label': PropTypes.string.isRequired,
  'value': PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  'defaultValue': PropTypes.number,
  'disabled': PropTypes.bool,
  'fixed': PropTypes.number,
  'fullWidth': PropTypes.bool,
  'InputProps': PropTypes.shape({
    'inputProps': PropTypes.objectOf(PropTypes.any),
    'endAdornment': PropTypes.node,
  }),
  'inputProps': PropTypes.objectOf(PropTypes.any),
  'onBlur': PropTypes.func,
  'onChange': PropTypes.func,
  'onKeyDown': PropTypes.func,
  'data-test-id': PropTypes.string,
};

export default NumberField;
