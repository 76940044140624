import { invoiceTypes } from 'constants/account';
import { defaultMoneyObject } from 'services/utils';

export const accountCalculator = (
  invoices,
  invoiceTemplates,
  credits,
  payments,
  historicFuneralAccountCharge,
  caseTotal,
) => {
  const historicChargeAmount = historicFuneralAccountCharge
    ? historicFuneralAccountCharge.amount
    : 0;

  const totalInvoiced = ((invoices && invoices
    .filter(item => !item.dateVoided && item.invoiceType === invoiceTypes.INVOICE)
    .map(item => item.totals.total)
    .reduce((accumulator, item) => accumulator + item.amount, 0))
    || 0) + historicChargeAmount;

  const totalUninvoiced = ((caseTotal && caseTotal.amount) || 0) - totalInvoiced;

  const totalCredit = (credits && credits
    .map(item => item.amount)
    .reduce((accumulator, item) => accumulator + item.amount, 0)) || 0;

  const totalPaid = (payments && payments
    .map(item => item.amount)
    .reduce((accumulator, item) => accumulator + item.amount, 0)) || 0;

  const balance = totalInvoiced - (totalCredit + totalPaid);

  const totalInvoiceTemplatesInvoiced = ((invoiceTemplates && invoiceTemplates
    .filter(item => !item.dateVoided && item.invoiceType === invoiceTypes.INVOICE)
    .map(item => item.totals.total)
    .reduce((accumulator, item) => accumulator + item.amount, 0))
    || 0);

  const totalInvoiceTemplatesUninvoiced = ((caseTotal && caseTotal.amount) || 0) - totalInvoiceTemplatesInvoiced;

  return {
    totalUnInvoiced: defaultMoneyObject(totalUninvoiced > -1 ? totalUninvoiced : 0),
    totalInvoiced: defaultMoneyObject(totalInvoiced),
    totalCredit: defaultMoneyObject(totalCredit),
    totalPaid: defaultMoneyObject(totalPaid),
    balance: defaultMoneyObject(balance),
    totalInvoiceTemplatesUninvoiced: defaultMoneyObject(
      totalInvoiceTemplatesUninvoiced > -1
        ? totalInvoiceTemplatesUninvoiced
        : 0,
    ),
    totalInvoiceTemplatesInvoiced: defaultMoneyObject(totalInvoiceTemplatesInvoiced),
  };
};
