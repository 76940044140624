import React, { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, matchPath } from 'react-router-dom';
import PortalLogin from 'screens/portal/PortalLoginScreen';
import {
  getTenantAction,
  resetPortalStoreAction,
  setAnonymousUserAction,
  setIsPortalActiveAction,
} from 'actions/portal';
import { portalRoutes } from 'constants/portal';
import { RootState } from 'types/ts/state';

import PortalScreen from './PortalScreen';
import { Props, State, RouteParams } from './index.types';

class PortalScreenContainer extends Component<Props, State> {
  static getDerivedStateFromProps(nextProps: Props) {
    const { accessToken } = nextProps;
    return {
      isLoggedIn: !!accessToken,
    };
  }

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoggedIn: false,
    };
  }

  render() {
    const {
      location,
      tenantId,
      getTenant,
      portalHomeId,
      resetPortalStore,
      setIsPortalActive,
    } = this.props;
    const { isLoggedIn } = this.state;
    const currentLocation = (location && location.pathname) ? location.pathname : '/';
    const isPortalLocation = currentLocation.includes('/portal/');
    const isMatch = matchPath<RouteParams>(currentLocation, {
      path: '/portal/:homeId',
      exact: false,
      strict: false,
    });

    if (!isPortalLocation || !isMatch) {
      resetPortalStore();
      return null;
    }

    const { homeId } = isMatch.params;

    if (!isLoggedIn || homeId !== portalHomeId) {
      setIsPortalActive(true);
      return <PortalLogin homeId={homeId} redirect={currentLocation} />;
    }

    if (isPortalLocation && !!tenantId) {
      getTenant(tenantId);
    }

    return (
      <PortalScreen
        routes={portalRoutes}
        currentLocation={currentLocation}
      />
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  accessToken: state?.portalStore?.user?.accessToken,
  tenantId: state?.portalStore?.tenant?.id || null,
  portalHomeId: state?.portalStore?.home?.id || null,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getTenant: getTenantAction,
  resetPortalStore: resetPortalStoreAction,
  setAnonymousUser: setAnonymousUserAction,
  setIsPortalActive: setIsPortalActiveAction,
}, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PortalScreenContainer),
);
