import mapActionToReducer from 'redux-action-reducer-mapper';

const defaultState = {
  notifications: [],
};

export const snackbarReducer = mapActionToReducer({
  default: defaultState,
  ENQUEUE_SNACKBAR: (state, action) => ({
    ...state,
    notifications: [
      ...state.notifications,
      {
        ...action.notification,
      },
    ],
  }),
  REMOVE_SNACKBAR: (state, action) => ({
    ...state,
    notifications: state.notifications.filter(
      notification => notification.key !== action.key,
    ),
  }),
});

export default snackbarReducer;
