import { removePropertyByName, defaultMoneyObject } from 'services/utils';
import { statuses, variantStatuses } from 'constants/catalogue';
import { v4 as uuidv4 } from 'uuid';

export const filtersForQueryTransform = filters => ({
  organisationalUnitIds: filters.organisationalUnitIds ? filters.organisationalUnitIds.map(item => item.id) : null,
  supplierIds: filters.supplierIds.map(item => item.id),
  categories: filters.categories.map(item => item.category),
  status: filters.status.enum,
  term: filters.term,
});

export const buildPackageObjectForMutation = (packageItem) => {
  const mutationInput = {
    clientMutationId: packageItem.clientMutationId,
    id: packageItem.id,
    name: packageItem.name,
    price: {
      currency: packageItem.price.currency,
      amount: packageItem.price.amount || 0,
    },
    nominalCode: packageItem.nominalCode,
    salesTaxBand: packageItem.salesTaxBand,
    isFixed: packageItem.isFixed,
    description: packageItem.description,
    productIds: packageItem.productIds,
    serviceIds: packageItem.serviceIds,
    productCategoryMaxQuantities: packageItem.productCategoryMaxQuantities,
    serviceCategoryMaxQuantities: packageItem.serviceCategoryMaxQuantities,
    organisationalUnitIds: packageItem.organisationalUnits
      && packageItem.organisationalUnits
        .map(unit => unit.id),
  };
  return removePropertyByName(mutationInput, '__typename');
};

export const addPackageTransform = packageItem => ({
  ...packageItem,
  id: uuidv4(),
  status: packageItem.isNewItem ? statuses.DRAFT : packageItem.status,
});

export const buildItemObjectForMutation = (item) => {
  const mutationInput = {
    clientMutationId: item.clientMutationId,
    id: item.id,
    title: item.title,
    subtitle: item.subtitle,
    description: item.description,
    salesTaxBand: item.salesTaxBand,
    tags: item.tags,
    imageIds: item.imageIds,
    reference: item.reference,
    nominalCode: item.nominalCode,
    note: item.note,
    supplierId: item.supplier && item.supplier.id,
    organisationalUnitIds: item.organisationalUnits
      && item.organisationalUnits
        .map(unit => unit.id),
    displayAsDisbursementToBereaved: item.displayAsDisbursementToBereaved,
    variants: item.variants
      .filter(variant => variant.status !== variantStatuses.DELETED)
      .map(variant => ({
        id: variant.id,
        name: variant.name,
        prices: {
          sale: variant.prices.sale || defaultMoneyObject(0),
          cost: variant.prices.cost,
          retail: variant.prices.retail,
        },
        isGuidePrice: variant.isGuidePrice,
        isDefault: variant.isDefault,
        imageId: variant.imageId,
      })),
  };

  if (item.address) {
    mutationInput.address = item.address;
  }

  if (item.vehicleType) {
    mutationInput.vehicleType = item.vehicleType;
  }

  return removePropertyByName(mutationInput, '__typename');
};

export const createCatalogueItemTransform = (item) => {
  const transformedVariants = item.variants.map((variant, index) => {
    const updatedVariant = {
      ...variant,
      isDefault: index === 0,
    };
    delete updatedVariant.status;
    return updatedVariant;
  });
  const transformedItem = buildItemObjectForMutation({
    ...item,
    variants: transformedVariants,
  });
  transformedItem.category = item.category;
  return transformedItem;
};

export const categoryOrderMutationTransform = categories => (
  categories.map(item => ({ category: item.category, visible: item.visible }))
);

export const catalogueOrderMutationTransform = items => (
  items.map(({ id, catalogueVisibility }) => ({
    id,
    catalogueVisibility,
  }))
);

export const arrangementOrderMutationTransform = items => (
  items.map(({ id, arrangementVisibility }) => ({
    id,
    arrangementVisibility,
  }))
);
