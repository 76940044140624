import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import { arrangementType, productSelectionType, serviceSelectionType } from 'types/bereavement';
import { historyType, matchType } from 'types/reactRouter';
import { apolloClientType } from 'types/apollo';
import {
  editProductSelectionAction,
  editServiceSelectionAction,
  removeProductSelectionAction,
  removeServiceSelectionAction,
} from 'actions/arrangement';
import {
  getVariantTitleForDisplay,
  getPlaceholderTextForCategory,
  getOriginalPriceForSelection,
  getIsFamilyArranged,
} from 'services/utils/arrangement';
import { getSelectionAggregate, getCatalogueVariantImage, debounceMutation } from 'services/utils';
import {
  routesForCategory,
  discountTypes,
  serviceCategories,
  productCategories,
  editableSelectionCategories,
} from 'constants/arrangement';
import { getSelectionPrice } from 'services/utils/catalogue';
import EstimateSelection from './EstimateSelection';
import {
  editProductSelectionMutation,
  editServiceSelectionMutation,
  removeProductSelectionMutation,
  removeServiceSelectionMutation,
} from './mutations.gql';

class EstimateSelectionContainer extends Component {
  static propTypes = {
    bereavementId: PropTypes.string.isRequired,
    arrangement: arrangementType,
    selection: PropTypes.oneOfType([
      productSelectionType.isRequired,
      serviceSelectionType.isRequired,
      PropTypes.shape({
        placeholder: PropTypes.string.isRequired,
      }),
    ]).isRequired,
    onOpenConfirmationWarningModal: PropTypes.func,
    disabled: PropTypes.bool.isRequired,
    history: historyType.isRequired,
    match: matchType.isRequired,
    editProductSelection: PropTypes.func.isRequired,
    editServiceSelection: PropTypes.func.isRequired,
    removeProductSelection: PropTypes.func.isRequired,
    removeServiceSelection: PropTypes.func.isRequired,
    onOpenAddSelectionModal: PropTypes.func,
    client: apolloClientType.isRequired,
  }

  constructor(props) {
    super(props);
    const { selection } = props;
    this.state = {
      itemMenuAnchorElement: null,
      isOverrideItem: selection && !!selection.overrideTitle,
    };
  }

  handleMenuOpen = (event) => {
    this.setState({ itemMenuAnchorElement: event.currentTarget });
  }

  handleMenuClose = () => {
    this.setState({ itemMenuAnchorElement: null });
  }

  handleOverrideSelection = () => {
    const { arrangement, onOpenConfirmationWarningModal, selection } = this.props;
    this.handleMenuClose();

    if (arrangement.isConfirmed) {
      onOpenConfirmationWarningModal(() => {
        this.editOverrideProperty('overrideTitle', this.getSelectionTitle());
        this.editOverrideProperty('overridePrice', getSelectionPrice(arrangement, selection));
        this.setState({ isOverrideItem: true });
      });
      return;
    }
    this.editOverrideProperty('overrideTitle', this.getSelectionTitle());
    this.editOverrideProperty('overridePrice', getSelectionPrice(arrangement, selection));
    this.setState({ isOverrideItem: true });
  }

  handleResetSelection = () => {
    const { arrangement, onOpenConfirmationWarningModal } = this.props;
    this.handleMenuClose();

    if (arrangement.isConfirmed) {
      onOpenConfirmationWarningModal(() => {
        this.editOverrideProperty('overrideTitle', null);
        this.editOverrideProperty('overridePrice', null);
        this.setState({ isOverrideItem: false });
      });
      return;
    }
    this.editOverrideProperty('overrideTitle', null);
    this.editOverrideProperty('overridePrice', null);
    this.setState({ isOverrideItem: false });
  }

  handleEditSelectionItemOverride = (key, value) => {
    const { arrangement, onOpenConfirmationWarningModal } = this.props;
    if (arrangement.isConfirmed) {
      onOpenConfirmationWarningModal(() => { this.editOverrideProperty(key, value); });
      return;
    }
    this.editOverrideProperty(key, value);
  }

  editOverrideProperty = (key, value) => {
    const {
      client,
      bereavementId,
      arrangement,
      selection,
      editProductSelection,
      editServiceSelection,
    } = this.props;

    const input = {
      bereavementId,
      arrangementId: arrangement.id,
      selectionId: selection.id,
      [key]: value,
    };

    if (selection.product !== undefined) {
      editProductSelection(bereavementId, arrangement.id, selection.id, { [key]: value });
      debounceMutation(client, editProductSelectionMutation, input);
    }
    if (selection.service !== undefined) {
      editServiceSelection(bereavementId, arrangement.id, selection.id, { [key]: value });
      debounceMutation(client, editServiceSelectionMutation, input);
    }
  }

  handleOnClickEdit = (category) => {
    const { history, match, onOpenAddSelectionModal } = this.props;
    const url = match.url.substring(0, match.url.lastIndexOf('/'));

    if (!routesForCategory[category]) {
      this.handleMenuClose();
      onOpenAddSelectionModal();
      return;
    }

    history.push(`${url}/${routesForCategory[category]}`);
  };

  handleOnClickDelete = () => {
    const { arrangement, onOpenConfirmationWarningModal } = this.props;

    if (arrangement.isConfirmed) {
      onOpenConfirmationWarningModal(this.deleteSelection);
      return;
    }

    this.deleteSelection();
  }

  deleteSelection = () => {
    const {
      bereavementId,
      arrangement,
      selection,
      removeProductSelection,
      removeServiceSelection,
      client,
    } = this.props;

    const input = {
      bereavementId,
      arrangementId: arrangement.id,
      selectionId: selection.id,
    };

    if (selection.product !== undefined) {
      removeProductSelection(bereavementId, arrangement.id, selection.id);
      client.mutate({
        mutation: removeProductSelectionMutation,
        variables: { input },
      });
    }
    if (selection.service !== undefined) {
      removeServiceSelection(bereavementId, arrangement.id, selection.id);
      client.mutate({
        mutation: removeServiceSelectionMutation,
        variables: { input },
      });
    }
  };

  getSelectionTitle = () => {
    const { arrangement, selection } = this.props;
    const selectionAggregate = getSelectionAggregate(selection);
    return (!selection.placeholder)
      ? selectionAggregate.title
      : getPlaceholderTextForCategory(
        selection.placeholder.category,
        getIsFamilyArranged(arrangement, selection.placeholder.category),
      );
  }

  propsForSelection = () => {
    const { arrangement, selection } = this.props;
    const selectionAggregate = getSelectionAggregate(selection);
    return {
      title: selectionAggregate.title,
      subtitle: getVariantTitleForDisplay(selection),
      overrideTitle: selection.overrideTitle || selectionAggregate.title,
      originalPrice: getOriginalPriceForSelection(selection),
      overridePrice: selection.overridePrice,
      selectionType: selection.service ? 'Service' : 'Product',
      category: selectionAggregate.category,
      image: getCatalogueVariantImage(selectionAggregate, selection.variantId || null),
      isPlaceholder: false,
      isFamilyArranged: false,
      shouldShowPrice: !selection.isPackageSelection,
      shouldShowEditMenu: editableSelectionCategories[selectionAggregate.category] !== undefined,
      shouldShowDeleteButton: true,
      canOverrideTitle: true,
      canOverridePrice: arrangement.discountType === discountTypes.ITEMISED,
    };
  }

  propsForPlaceholder = () => {
    const { arrangement, selection } = this.props;
    const isFamilyArranged = getIsFamilyArranged(arrangement, selection.placeholder.category);
    const { category } = selection.placeholder;
    const isService = Object.keys(serviceCategories).includes(category);
    const isProduct = Object.keys(productCategories).includes(category);

    return {
      title: getPlaceholderTextForCategory(selection.placeholder.category, isFamilyArranged),
      subtitle: null,
      overrideTitle: null,
      originalPrice: null,
      overridePrice: null,
      selectionType: (isService && 'Service') || (isProduct && 'Product'),
      category,
      image: null,
      isPlaceholder: true,
      isFamilyArranged,
      shouldShowPrice: false,
      shouldShowEditMenu: editableSelectionCategories[selection.placeholder.category] !== undefined,
      shouldShowDeleteButton: false,
      canOverrideTitle: false,
      canOverridePrice: false,
    };
  }

  render() {
    const { selection, disabled, onOpenAddSelectionModal } = this.props;
    const { itemMenuAnchorElement, isOverrideItem } = this.state;

    const {
      title,
      subtitle,
      overrideTitle,
      originalPrice,
      overridePrice,
      selectionType,
      category,
      image,
      isPlaceholder,
      isFamilyArranged,
      shouldShowPrice,
      shouldShowEditMenu,
      shouldShowDeleteButton,
      canOverrideTitle,
      canOverridePrice,
    } = (!selection.placeholder) ? this.propsForSelection() : this.propsForPlaceholder();

    return (
      <EstimateSelection
        originalTitle={title}
        originalSubtitle={subtitle}
        overrideTitle={overrideTitle}
        originalPrice={originalPrice}
        overridePrice={overridePrice}
        selectionType={selectionType}
        category={category}
        image={image}
        isPlaceholder={isPlaceholder}
        isFamilyArranged={isFamilyArranged}
        disabled={disabled}
        shouldShowPrice={shouldShowPrice}
        shouldShowEditMenu={shouldShowEditMenu}
        shouldShowDeleteButton={shouldShowDeleteButton}
        canOverrideTitle={canOverrideTitle}
        canOverridePrice={canOverridePrice}
        onEditSelectionItemOverride={this.handleEditSelectionItemOverride}
        onClickEdit={this.handleOnClickEdit}
        onClickDelete={this.handleOnClickDelete}
        itemMenuAnchorElement={itemMenuAnchorElement}
        onMenuOpen={this.handleMenuOpen}
        onMenuClose={this.handleMenuClose}
        isOverrideItem={isOverrideItem}
        onOverrideSelection={this.handleOverrideSelection}
        onResetSelection={this.handleResetSelection}
        onOpenAddSelectionModal={onOpenAddSelectionModal}
      />
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  editProductSelection: editProductSelectionAction,
  editServiceSelection: editServiceSelectionAction,
  removeProductSelection: removeProductSelectionAction,
  removeServiceSelection: removeServiceSelectionAction,
}, dispatch);

export default withApollo(
  withRouter(connect(null, mapDispatchToProps)(EstimateSelectionContainer)),
);
