import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';

import AdminCataloguePreviewModal from 'components/admin/AdminCataloguePreviewModal';
import SortableCategoryList from 'components/catalogue/SortableCategoryList';
import ConfirmationModal from 'components/common/ConfirmationModal';
import ScreenLayout from 'components/common/ScreenLayout';
import IconDragnDrop from 'icons/IconDragnDrop';
import IconLoad from 'icons/IconLoad';
import IconLoadSml from 'icons/IconLoadSml';
import styles from 'scss/main.scss';

import rcStyles from './styles.scss';

const AdminCatalogueSettingsScreen = ({
  categories,
  isSortEnabled,
  isVisibilityEnabled,
  isLoading,
  isSaving,
  isCataloguePreviewModalOpen,
  editCategoryToNavigate,
  onEnableSort,
  onSetOrder,
  onCancel,
  onSave,
  onSortCategories,
  toggleCataloguePreviewModal,
  setCategoryToNavigate,
  onClickIncludeInCatalogue,
  onEditCategory,
}) => (
  <ScreenLayout
    title={t('Brochure settings')}
    dashboardBannerProps={{
      breadcrumbs: [{ path: '/', title: t('Dashboard') }, { path: '/catalogue', title: t('Brochure management') }, { path: '/catalogue/settings', title: t('Brochure settings') }],
      description: t('Decide what’s included in your online brochure by setting the visibility and order for each category. Preview your brochure at any time using the preview option and when you’re done, make your changes permanent by clicking save.'),
    }}
  >
    <div className={styles.o_view}>
      <div className={classNames(
        styles.o_block_content,
        styles.o_view__main,
      )}
      >
        {isLoading ? (
          <div className={classNames(
            styles.c_page__full_height,
          )}
          >
            <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
              <IconLoad />
            </div>
          </div>
        ) : (
          <Fragment>
            <span>
              <Button
                onClick={isSortEnabled ? onSetOrder : onEnableSort}
                variant="outlined"
                color="primary"
                data-test-id="enableCategorySort"
              >
                {isSortEnabled ? t('Done ordering') : t('Set order')}
              </Button>
            </span>
            {isSortEnabled && (
              <div className={classNames(
                styles.o_fb__item__flexOne,
                rcStyles.rc_bg_main_sortable,
              )}
              >
                <div className={
                  classNames(
                    styles.o_fb,
                    styles.o_fb__align_left,
                  )}
                >
                  <div className={classNames(
                    styles.u_push__right,
                    rcStyles.rc_sortable_spacing,
                  )}
                  >
                    <IconDragnDrop />
                  </div>
                  <div className={classNames(
                    styles.u_push__ends,
                    styles.primer,
                    styles.is_grey_dark,
                  )}
                  >
                    {t('Press and hold to drag & re-order a category')}
                  </div>
                </div>
              </div>
            )}
          </Fragment>
        )}
        <div className={styles.u_push__ends}>
          <SortableCategoryList
            axis="xy"
            categories={categories || []}
            onSortEnd={onSortCategories}
            onClickIncludeInCatalogue={onClickIncludeInCatalogue}
            onEditCategory={setCategoryToNavigate}
            disabled={!isSortEnabled}
            isIncludible={isVisibilityEnabled}
          />
        </div>
        <div className={classNames(
          styles.o_fb,
        )}
        >
          <div className={styles.o_fb__item__third}>
            <Button
              onClick={onCancel}
              variant="outlined"
              color="primary"
              data-test-id="cancelOrderCategories"
              fullWidth
            >
              {t('Cancel')}
            </Button>
          </div>
          <div className={styles.o_fb__item__third}>
            <Button
              onClick={toggleCataloguePreviewModal}
              variant="outlined"
              color="primary"
              data-test-id="previewCatalogueButton"
              fullWidth
            >
              {t('Preview')}
            </Button>
          </div>
          <div className={styles.o_fb__item__third}>
            <Button
              onClick={onSave}
              variant="contained"
              color="primary"
              data-test-id="saveOrderCategories"
              disabled={isSortEnabled}
              fullWidth
            >
              {isSaving ? (
                <div
                  className={classNames(
                    styles.c_svg_loader_sml,
                    styles.c_svg_loader_sml_btn,
                  )}
                  data-test-id="buttonLoader"
                >
                  <IconLoadSml />
                </div>
              ) : t('Save')}
            </Button>
          </div>
        </div>
      </div>
    </div>
    <ConfirmationModal
      title={t('Unsaved changes')}
      message={t('You will need to save the changes made to the categories before proceeding')}
      confirmLabel={t('Save & continue')}
      isOpen={!!editCategoryToNavigate}
      onConfirm={onEditCategory}
      onCancel={() => setCategoryToNavigate(null)}
      testIds={{
        dialog: 'confirmationModal',
        confirm: 'saveAndContinue',
      }}
    />
    <AdminCataloguePreviewModal
      isOpen={isCataloguePreviewModalOpen}
      onCancel={toggleCataloguePreviewModal}
      categories={categories.filter(category => category.visible)}
    />
  </ScreenLayout>
);

AdminCatalogueSettingsScreen.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.any),
  isSortEnabled: PropTypes.bool,
  isVisibilityEnabled: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isCataloguePreviewModalOpen: PropTypes.bool.isRequired,
  editCategoryToNavigate: PropTypes.string,
  onSortCategories: PropTypes.func.isRequired,
  onClickIncludeInCatalogue: PropTypes.func.isRequired,
  onSetOrder: PropTypes.func.isRequired,
  onEnableSort: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  toggleCataloguePreviewModal: PropTypes.func.isRequired,
  setCategoryToNavigate: PropTypes.func.isRequired,
  onEditCategory: PropTypes.func.isRequired,
};

export default AdminCatalogueSettingsScreen;
