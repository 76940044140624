import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';

import { RootState } from 'types/ts/state';

import {
  Props, PropsWithoutApolloClient, State, TemplateValue, CategoryVariables,
} from './index.types';
import { getCorrespondenceVariables } from './queries.gql';
import AdminLetterTemplateValuesScreen from './AdminLetterTemplateValuesScreen';

class AdminLetterTemplateValuesScreenContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      term: null,
      isLoading: false,
      values: [],
    };
  }

  componentDidMount() {
    this.getCorrespondenceVariables();
  }

  getCorrespondenceVariables = () => {
    const { client, tenantId } = this.props;

    this.setState({ isLoading: true });

    client.query({
      query: getCorrespondenceVariables,
      variables: { tenantId },
    }).then(({ data }) => {
      const { tenant } = data;
      if (!tenant) {
        return;
      }

      const { correspondenceVariables }: { correspondenceVariables: TemplateValue[] } = tenant;

      const values = correspondenceVariables.reduce((acc, variable: TemplateValue) => {
        const updatedAcc = [...acc];
        const { category } = variable;
        const match = updatedAcc.find(({ key }) => key === category);
        if (!match) {
          updatedAcc.push({ key: category, variables: [variable] });
        } else {
          match.variables = [
            ...match.variables,
            variable,
          ];
        }

        return updatedAcc;
      }, [] as CategoryVariables[]);

      this.setState({ values });
    }).finally(() => {
      this.setState({ isLoading: false });
    });
  }

  handleSearchValues = (term: string) => {
    this.setState({ term });
  }

  getFilteredValues = (): CategoryVariables[] => {
    const { term, values } = this.state;

    let filteredValues: CategoryVariables[] = [...values];
    if (term) {
      filteredValues = values
        .map(value => ({
          ...value,
          variables: value.variables.filter(
            variable => variable.name.toLowerCase().includes(term.toLowerCase())
            || variable.category.toLowerCase().includes(term.toLowerCase()),
          ),
        }))
        .filter(value => value.variables.length);
    }

    return filteredValues;
  }

  render() {
    const { term, isLoading } = this.state;
    const values = this.getFilteredValues();

    return (
      <AdminLetterTemplateValuesScreen
        term={term}
        isLoading={isLoading}
        values={values}
        onSearchValues={this.handleSearchValues}
      />
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  tenantId: state.userStore.user.tenantId,
});

export default connect(mapStateToProps)(
  withApollo<PropsWithoutApolloClient, State>(AdminLetterTemplateValuesScreenContainer),
);
