import { removePropertyByName } from 'services/utils';

export const getStaffMemberResults = response => removePropertyByName(response.staffMember, '__typename');

export const staffMemberTransform = (staffMember, organisationalUnits, roles, newId) => {
  const updatedStaffMember = {
    ...removePropertyByName(staffMember, '__typename'),
    organisationalUnitIds: organisationalUnits.map(unit => unit.id),
    roleIds: roles && roles.map(role => role.id),
    id: newId || staffMember.id,
    imageId: staffMember.image && staffMember.image.id,
  };

  delete updatedStaffMember.isNew;

  return updatedStaffMember;
};

export const staffMemberMutationTransform = (staffMember) => {
  const updatedStaffMember = {
    id: staffMember.id,
    name: staffMember.name,
    address: staffMember.address,
    phones: staffMember.phones,
    emails: staffMember.emails,
    employmentStatus: staffMember.employmentStatus,
    jobTitle: staffMember.jobTitle,
    organisationalUnitIds: staffMember.organisationalUnitIds,
    imageId: staffMember.image && staffMember.image.id,
    roleIds: staffMember.roleIds,
  };
  return updatedStaffMember;
};
