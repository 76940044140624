import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import CardActionArea from '@material-ui/core/CardActionArea';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';
import { buildAddressString } from 'services/utils';
import { textForTransferType } from 'services/utils/bereavement';
import { transferType } from 'types/bereavement';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const TransferRow = ({
  transfer,
  onClick,
  onRemove,
}) => (
  <div className={rcStyles.transfer_row}>
    <CardActionArea
      onClick={onClick}
      data-test-id="transferRowContainer"
    >
      <div className={classNames(
        rcStyles.layout,
        transfer.isComplete ? rcStyles.is_completed : rcStyles.is_pending,
      )}
      >
        <h3 className={rcStyles.layout__title}>
          {t(textForTransferType(transfer && transfer.__typename))}
        </h3>
        <div className={rcStyles.layout_item}>
          <div className={styles.brevier}>
            {t('Transfer from')}
          </div>
          <div className={styles.primer}>
            {buildAddressString(transfer.fromLocation)}
          </div>
        </div>
        <div className={rcStyles.layout_item}>
          <div className={styles.brevier}>
            {t('Transfer to')}
          </div>
          <div className={styles.primer}>
            {buildAddressString(transfer.toLocation)}
          </div>
        </div>
      </div>
    </CardActionArea>
    <div>
      {!transfer.isComplete && (
        <IconButton
          className={styles.o_button__secondary}
          onClick={onRemove}
          data-test-id="removeTransfer"
        >
          <RemoveIcon />
        </IconButton>
      )}
    </div>
  </div>
);

TransferRow.propTypes = {
  transfer: transferType.isRequired,
  onClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default TransferRow;
