import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import RootRef from '@material-ui/core/RootRef';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DoneIcon from '@material-ui/icons/Done';

import ArrangementSelectedItemAvatar from 'components/arrangement/ArrangementSelectedItemAvatar';
import ArrangementVenueDetails from 'components/arrangement/ArrangementVenueDetails';
import ContactCard from 'components/common/ContactCard';
import { officiantVenueCategories } from 'constants/arrangement';
import { getCatalogueVariantImage } from 'services/utils';

import rcStyles from './styles.scss';

const ArrangementOfficiantVenueLookup = ({
  arrangement,
  venueSelectionIds,
  disabled,
  onToggleServiceVenue,
}) => {
  const listRef = useRef(null);
  const [anchorElement, setAnchorElement] = useState(null);
  const serviceVenues = arrangement?.serviceSelections
    ?.filter((serviceSelection) => {
      const { service } = serviceSelection;
      return officiantVenueCategories.includes(service?.category);
    });
  const selectedServiceVenues = serviceVenues
    ?.filter(({ id }) => venueSelectionIds.includes(id));

  const VenueMenuItem = withStyles({
    root: {
      width: listRef?.current?.clientWidth,
      height: 'auto',
      padding: 0,
    },
  })(MenuItem);

  return (
    <>
      <RootRef rootRef={listRef}>
        <List ref={listRef} component="ul" disablePadding>
          {!selectedServiceVenues.length && (
            <ListItem
              button
              disabled={disabled}
              onClick={event => setAnchorElement(event.currentTarget)}
              data-test-id="attendingServiceListItem"
            >
              <div className={rcStyles.attending_service}>
                <div className={rcStyles.attending_service__arrow}>
                  <ArrowDropDownIcon />
                </div>
                <div className={rcStyles.attending_service__container}>
                  <div className={rcStyles.attending_service__header}>
                    {t('Attending service')}
                  </div>
                </div>
              </div>
            </ListItem>
          )}
          {!!selectedServiceVenues.length && (
            <ListItem
              button
              disabled={disabled}
              onClick={event => setAnchorElement(event.currentTarget)}
              data-test-id="officiantServiceVenueListItem"
            >
              <div className={rcStyles.list_item}>
                {selectedServiceVenues?.map(({ service, variantId }) => (
                  <ContactCard
                    key={service?.category}
                    image={(
                      <ArrangementSelectedItemAvatar
                        image={getCatalogueVariantImage(service, variantId)}
                        title={service?.title}
                        category={service?.category}
                        classes={rcStyles.is_selected_icon}
                      />
                    )}
                    details={(
                      <ArrangementVenueDetails arrangement={arrangement} service={service} />
                    )}
                  />
                ))}
              </div>
            </ListItem>
          )}
        </List>
      </RootRef>
      <Menu
        anchorEl={anchorElement}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        open={!!anchorElement}
        onClose={() => setAnchorElement(null)}
      >
        {!serviceVenues?.length && (
          <VenueMenuItem>
            <div className={rcStyles.menu_item__no_venues_available}>
              {t('No venues available for this arrangement')}
            </div>
          </VenueMenuItem>
        )}
        {serviceVenues?.map(serviceVenue => (
          <VenueMenuItem
            key={serviceVenue.id}
            onClick={() => onToggleServiceVenue(serviceVenue.id)}
            data-test-id="serviceVenueMenuItem"
          >
            <div className={rcStyles.menu_item}>
              <div className={rcStyles.menu_item__icon}>
                {venueSelectionIds.includes(serviceVenue.id) && (
                  <DoneIcon />
                )}
              </div>
              <ArrangementVenueDetails arrangement={arrangement} service={serviceVenue?.service} />
            </div>
          </VenueMenuItem>
        ))}
      </Menu>
    </>
  );
};

ArrangementOfficiantVenueLookup.propTypes = {
  arrangement: PropTypes.objectOf(PropTypes.any).isRequired,
  venueSelectionIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool.isRequired,
  onToggleServiceVenue: PropTypes.func.isRequired,
};

export default ArrangementOfficiantVenueLookup;
