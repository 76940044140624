import React from 'react';
import Carousel from 'react-material-ui-carousel';

import Styled from './index.styled';
import { Props } from './index.types';

const CatalogueItemImageViewer: React.FC<Props> = ({
  item,
}: Props) => (
  <Styled.CarouselWrapper>
    <Carousel
      autoPlay={false}
      indicators={false}
      navButtonsAlwaysVisible
      navButtonsAlwaysInvisible={item?.images?.length === 1}
    >
      {item?.images?.map(image => (
        <Styled.Image
          key={image?.id}
          imageUri={image?.uri}
        />
      ))}
    </Carousel>
  </Styled.CarouselWrapper>
);

export default CatalogueItemImageViewer;
