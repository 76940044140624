import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import ScreenLayout from 'components/common/ScreenLayout';
import IconLoad from 'icons/IconLoad';
import AdminDirectoryListingListItem from 'components/admin/AdminDirectoryListingListItem';
import { organisationalUnitType } from 'types/organisationalUnit';
import StatusLookup from 'components/common/lookups/StatusLookup';
import classNames from 'classnames';
import { categoriesWithNames, statusesWithNames } from 'constants/directoryListing';
import CategoryLookup from 'components/common/lookups/CategoryLookup';

import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const AdminDirectoryListingListScreen = ({
  directoryListings,
  hasMoreDirectoryListings,
  getDirectoryListings,
  isLoading,
  filters,
  onChangeFilters,
  onStatusChange,
}) => (
  <ScreenLayout
    title={t('Directory Listings')}
    dashboardBannerProps={{
      breadcrumbs: [{ path: '/', title: t('Dashboard') }, { path: '/directory-listings', title: t('Directory Listings') }],
      description: t('Manage and edit your business contacts, suppliers, vendors and associated businesses'),
    }}
  >
    <div className={classNames(
      styles.c_page__full_height,
      styles.has_faded_background,
    )}
    >
      <div className={styles.o_view}>
        <div className={classNames(
          styles.o_view__main,
          styles.o_block_content,
        )}
        >
          <span>
            <Button
              component={Link}
              color="primary"
              variant="outlined"
              to="/directory-listings/create"
              data-test-id="create"
            >
              {t('Create directory listing')}
            </Button>
          </span>
          <div className={rcStyles.options}>
            <div className={rcStyles.searchbar}>
              <TextField
                value={filters.searchTerm}
                onChange={event => onChangeFilters('searchTerm', event.target.value)}
                label={t('Search')}
                type="search"
                InputProps={{
                  'data-test-id': 'searchTerm',
                  'startAdornment': (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </div>
            <CategoryLookup
              value={filters.categories}
              label="Select category"
              onChange={value => onChangeFilters('categories', value)}
              data-test-id="categoriesLookup"
              categories={categoriesWithNames}
              isMulti
            />
            <StatusLookup
              value={filters.status}
              label="Select Status"
              onChange={value => onChangeFilters('status', value && value.value)}
              classes={[rcStyles.rc_lookup]}
              statuses={statusesWithNames}
              data-test-id="statusLookup"
            />
          </div>
          {(isLoading && directoryListings && directoryListings.length === 0)
          && (
            <div className={classNames(
              styles.c_page__full_height,
              styles.has_no_background,
              styles.o_fb,
            )}
            >
              <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
                <IconLoad />
              </div>
            </div>
          )
          }
          <div className={rcStyles.overflow_hack}>
            <InfiniteScroll
              dataLength={directoryListings.length}
              hasMore={directoryListings.length > 0 && hasMoreDirectoryListings}
              next={getDirectoryListings}
              loader={directoryListings.length > 0 && (
                <div className={styles.c_svg_loader_infinite_scroll} data-test-id="infiniteScrollLoader">
                  <IconLoad />
                </div>
              )}
            >
              {
                directoryListings.map(directoryListing => (
                  <div
                    key={directoryListing.id}
                    className={styles.u_push__sides}
                  >
                    <AdminDirectoryListingListItem
                      item={directoryListing}
                      onStatusChange={onStatusChange}
                    />
                  </div>
                ))
              }
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  </ScreenLayout>
);

AdminDirectoryListingListScreen.propTypes = {
  directoryListings: PropTypes.arrayOf(organisationalUnitType.isRequired),
  hasMoreDirectoryListings: PropTypes.bool.isRequired,
  getDirectoryListings: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  onChangeFilters: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
};

export default AdminDirectoryListingListScreen;
