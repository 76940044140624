import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Button from '@material-ui/core/Button';
import AutoCompleteTextField from 'components/common/AutoCompleteTextField';
import { reasonsForNoMCCDIssued } from 'constants/bereavement';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import DynamicList from 'components/common/DynamicList';
import NameDirectoryListingFormSection from 'components/directoryListings/NameDirectoryListingFormSection';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const CareWishesForm = ({
  bereavementId,
  formData,
  disabled,
  onChange,
  onSave,
  formRefs,
}) => (
  <Fragment>
    <div className={styles.u_push__bottom}>
      <FormGroup>
        <FormControlLabel
          control={(
            <Checkbox
              checked={formData.doNotPerform}
              onChange={event => onChange('doNotPerform', event.target.checked)}
              inputProps={{
                'data-test-id': 'doNotPerformFirstOffices',
              }}
              color="primary"
              disabled={disabled}
            />
            )}
          label={t('Opt out of First Offices')}
        />
      </FormGroup>
      {formData.doNotPerform && (
        <div
          className={styles.u_push__bottom}
          ref={formRefs.reasonNotToPerform}
        >
          <TextField
            label={t('Opt out reason')}
            placeholder={t('Opt out reason')}
            value={formData.reasonNotToPerform}
            onChange={event => onChange('reasonNotToPerform', event.target.value)}
            inputProps={{
              'data-test-id': 'reasonNotToPerformFirstOffices',
            }}
            multiline
            fullWidth
          />
        </div>
      )}
      <div ref={formRefs.shouldCaptureFingerPrint}>
        <FormGroup>
          <FormControlLabel
            control={(
              <Checkbox
                checked={!!formData.shouldCaptureFingerPrint}
                onChange={event => onChange('shouldCaptureFingerPrint', event.target.checked)}
                inputProps={{
                  'data-test-id': 'shouldCaptureFingerprint',
                }}
                color="primary"
                disabled={disabled}
              />
              )}
            label={t('Capture Fingerprint')}
          />
        </FormGroup>
      </div>
      <div ref={formRefs.shouldCollectLockOfHair}>
        <FormGroup>
          <FormControlLabel
            control={(
              <Checkbox
                checked={!!formData.shouldCollectLockOfHair}
                onChange={event => onChange('shouldCollectLockOfHair', event.target.checked)}
                inputProps={{
                  'data-test-id': 'shouldCollectLockOfHair',
                }}
                color="primary"
                disabled={disabled}
              />
            )}
            label={t('Collect Lock of Hair')}
          />
        </FormGroup>
      </div>
      <div ref={formRefs.isFamilyToPrepareDeceased}>
        <FormGroup>
          <FormControlLabel
            control={(
              <Checkbox
                checked={!!formData.isFamilyToPrepareDeceased}
                onChange={event => onChange('isFamilyToPrepareDeceased', event.target.checked)}
                inputProps={{
                  'data-test-id': 'isFamilyToPrepareDeceased',
                }}
                color="primary"
                disabled={disabled}
              />
            )}
            label={t('Family to prepare deceased')}
          />
        </FormGroup>
      </div>
    </div>
    <div
      className={rcStyles.v_spacing}
      ref={formRefs.isMCCDIssued}
    >
      <h3 className={classNames(
        styles.long_primer,
        rcStyles.colspan2,
      )}
      >
        {t('Clinician & Forms')}
      </h3>
      <div className={rcStyles.colspan2}>
        <FormControl data-test-id="isMccdIssued" component="fieldset">
          <div className={rcStyles.radio}>
            <div className={rcStyles.radio__label}>
              {t('MCCD issued')}
            </div>
            <RadioGroup
              aria-label={t('MCCD Issued')}
              name="isMCCDIssued"
              value={`${formData.isMCCDIssued}`}
              onChange={event => onChange('isMCCDIssued', event.target.value === 'true')}
              row
              data-test-id="isMCCDIssuedRadioGroup"
            >
              <FormControlLabel
                value="true"
                control={<Radio color="primary" disabled={disabled} data-test-id="isMCCDIssuedTrue" />}
                label={t('Yes')}
              />
              <FormControlLabel
                value="false"
                control={<Radio color="primary" disabled={disabled} data-test-id="isMCCDIssuedFalse" />}
                label={t('No')}
              />
            </RadioGroup>
          </div>
        </FormControl>
      </div>
      {formData.isMCCDIssued === false && (
        <div
          ref={formRefs.reasonForNoMCCDIssued}
          className={rcStyles.colspan2}
        >
          <AutoCompleteTextField
            placeholder={t('Reason for no MCCD')}
            value={formData.reasonForNoMCCDIssued || ''}
            suggestions={reasonsForNoMCCDIssued}
            onChange={reasonForNoMCCDIssued => onChange('reasonForNoMCCDIssued', reasonForNoMCCDIssued)}
            disabled={disabled}
            data-test-id="reasonForNoMCCDIssued"
          />
        </div>
      )}
      {bereavementId && (
      <div
        ref={formRefs.MCCDNumber}
        className={classNames(
          rcStyles.text_field,
          rcStyles.colspan2,
        )}
      >

        <TextField
          id="MCCDNumber"
          label={t('Certificate Number')}
          value={formData.MCCDNumber || ''}
          onChange={event => onChange('MCCDNumber', event.target.value)}
          InputLabelProps={{
            FormLabelClasses: {
              root: rcStyles.text_field__label,
              focused: rcStyles.text_field__focused,
              filled: rcStyles.text_field__shurnk,
            },
          }}
          inputProps={{
            'data-test-id': 'MCCDNumber',
          }}
          fullWidth
          disabled={disabled}
        />
      </div>
      )}

      <div
        className={rcStyles.colspan2}
        ref={formRefs.clinicians}
      >
        <DynamicList
          isStacked
          secondary
          component={(
            <NameDirectoryListingFormSection
              title={t('Clinician')}
              disabled={disabled}
            />
            )}
          addButtonProps={{
            variant: 'outlined',
            color: 'primary',
            size: 'medium',
          }}
          addButtonInner={(
            <div className={rcStyles.icon_button}>
              <AddIcon />
              {t('Add clinician')}
            </div>
            )}
          removeButtonProps={{
            variant: 'outlined',
            size: 'small',
            color: 'primary',
            classes: { root: styles.o_button__round },
          }}
          removeButtonInner={
            <RemoveIcon />
            }
          dataList={formData.clinicians}
          onChange={updatedClinicians => onChange('clinicians', updatedClinicians)}
          className={classNames(styles.u_fullWidth)}
          disabled={disabled}
          defaultEmpty
          data-test-id="clinicianFormSection"
        />
      </div>
      <div
        ref={formRefs.coroner}
        className={rcStyles.colspan2}
      >
        <NameDirectoryListingFormSection
          title={t('Coroner')}
          fieldData={formData.coroner}
          onChange={nameAddress => onChange('coroner', nameAddress)}
          disabled={disabled}
          data-test-id="coronerFormSection"
        />
      </div>
      <div className={styles.u_push__bottom}>
        <FormGroup>
          <FormControlLabel
            control={(
              <Checkbox
                checked={formData.isInfectious}
                onChange={event => onChange('isInfectious', event.target.checked)}
                inputProps={{
                  'data-test-id': 'isInfectious',
                }}
                color="primary"
                disabled={disabled}
              />
            )}
            label={t('Is Infectious')}
          />
        </FormGroup>
      </div>
    </div>
    <div className={rcStyles.colspan2}>
      <Button
        variant="contained"
        color="primary"
        onClick={onSave}
        data-test-id="saveDeceasedForm"
        disabled={disabled}
      >
        {t('Save')}
      </Button>
    </div>
  </Fragment>
);
CareWishesForm.propTypes = {
  bereavementId: PropTypes.string.isRequired,
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default CareWishesForm;
