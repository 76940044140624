import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { t } from 'i18next';
import classNames from 'classnames';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '@material-ui/icons/Close';
import IconCalendar from '@material-ui/icons/DateRange';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

import DatePickerHeader from 'components/common/DatePickerHeader';
import { defaultTimeInterval } from 'constants/date';
import {
  getDateFromISOString,
  getMinDateAsDate,
  getMaxDateAsDate,
  getStartDateTimeRange,
  getEndDateTimeRange,
  getFormattedDate,
} from 'services/utils';
import styles from 'scss/main.scss';

import rcStyles from './styles.scss';
import 'react-datepicker/dist/react-datepicker-cssmodules.css?raw';
import './overrides.css?raw';

class DateTimePickerWrapper extends Component {
  static propTypes = {
    'value': PropTypes.string,
    'label': PropTypes.string,
    'placeholder': PropTypes.string,
    'name': PropTypes.string,
    'openToDate': PropTypes.string,
    'minDate': PropTypes.string,
    'maxDate': PropTypes.string,
    'minTime': PropTypes.string,
    'maxTime': PropTypes.string,
    'timeIntervals': PropTypes.number,
    'InputProps': PropTypes.objectOf(PropTypes.any),
    'inputProps': PropTypes.objectOf(PropTypes.any),
    'disabled': PropTypes.bool,
    'errors': PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.objectOf(PropTypes.any),
    ]),
    'helperText': PropTypes.string,
    'fullWidth': PropTypes.bool,
    'onChange': PropTypes.func.isRequired,
    'onClearEndDate': PropTypes.func,
    'isClearable': PropTypes.bool,
    'disablePast': PropTypes.bool,
    'disableFuture': PropTypes.bool,
    'displayFormat': PropTypes.string,
    'isStartDate': PropTypes.bool,
    'isEndDate': PropTypes.bool,
    'timeDisplayFormat': PropTypes.string,
    'timeRange': PropTypes.arrayOf(PropTypes.string),
    'showTimeSelect': PropTypes.bool,
    'getSuggestedDates': PropTypes.func,
    'data-test-id': PropTypes.string,
  };

  static defaultProps = {
    'displayFormat': 'dd/MM/yyyy HH:mm',
    'fullWidth': true,
    'isEndDate': false,
    'isStartDate': false,
    'showTimeSelect': true,
    'timeDisplayFormat': 'HH:mm',
    'timeIntervals': defaultTimeInterval,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedSuggestion: null,
    };

    this.containerRef = React.createRef();
  }

  getMinDate = () => {
    const { minDate } = this.props;
    return getMinDateAsDate(minDate);
  }

  getMaxDate = () => {
    const { maxDate } = this.props;
    return getMaxDateAsDate(maxDate);
  }

  getTimeRange = () => {
    const { timeRange, isStartDate, isEndDate } = this.props;
    if (!timeRange) {
      return null;
    }

    const [startDateTime, endDateTime] = timeRange;
    if (isStartDate) {
      return getStartDateTimeRange(startDateTime, endDateTime);
    }
    if (isEndDate) {
      return getEndDateTimeRange(startDateTime, endDateTime);
    }
    return timeRange;
  }

  handleSuggestionChange = (suggestedDate) => {
    const { onClearEndDate } = this.props;
    this.handleChange(suggestedDate?.value, false);

    if (onClearEndDate) {
      const maxDate = this.getMaxDate();
      const normalisedDateTime = moment(suggestedDate?.value).second(0).millisecond(0);
      if (maxDate && normalisedDateTime.isAfter(moment(maxDate))) {
        onClearEndDate();
      }
    }

    this.setState({ selectedSuggestion: suggestedDate });
  }

  handleChange = (dateTime, allowDateCapping = true) => {
    const { onChange } = this.props;
    const minDate = this.getMinDate();
    const maxDate = this.getMaxDate();
    let dateTimeAsString = null;

    if (dateTime) {
      const normalisedDateTime = moment(dateTime).second(0).millisecond(0);
      let updatedDateTime = moment(dateTime).second(0);

      if (minDate && normalisedDateTime.isBefore(minDate)) {
        updatedDateTime = allowDateCapping ? minDate : updatedDateTime;
      }
      if (maxDate && normalisedDateTime.isAfter(maxDate)) {
        updatedDateTime = allowDateCapping ? maxDate : updatedDateTime;
      }

      dateTimeAsString = updatedDateTime && updatedDateTime.format();
    }

    this.setState({ selectedSuggestion: null });

    if (onChange) {
      onChange(dateTimeAsString);
    }
  };

  handleClear = () => {
    const { onChange } = this.props;

    this.setState({ selectedSuggestion: null });

    if (onChange) {
      onChange(null);
    }
  }

  getSuggestedDatesContainer = () => {
    const { getSuggestedDates } = this.props;
    const { selectedSuggestion } = this.state;

    const suggestedDates = getSuggestedDates && getSuggestedDates();

    const DatePickerContainer = ({ className, children }) => {
      const [isSuggestionsExpanded, setIsSuggestionsExpanded] = useState(false);

      return (
        <div className={rcStyles.datepicker_container}>
          <div className="react-datepicker__triangle" />
          {suggestedDates && (
            <div
              className={rcStyles.datepicker_container_suggested_banner}
              role="button"
              tabIndex="0"
              onClick={() => setIsSuggestionsExpanded(!isSuggestionsExpanded)}
              data-test-id="suggested-dates-banner"
            >
              <KeyboardArrowDown
                className={rcStyles.datepicker_container_suggested_banner_arrow}
                style={{
                  transition: 'transform 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                  transform: isSuggestionsExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
              />
              <div className={rcStyles.datepicker_container_suggested_banner_header}>
                {selectedSuggestion ? selectedSuggestion.label : t('New date')}
              </div>
            </div>
          )}
          <CalendarContainer className={className}>
            <div ref={this.containerRef}>
              {suggestedDates && isSuggestionsExpanded
                ? (
                  <div style={{
                    width: this?.containerRef?.current?.offsetParent.offsetWidth,
                    height: this?.containerRef?.current?.offsetParent.offsetHeight,
                  }}
                  >
                    {suggestedDates?.length
                      ? (
                        <ul className={rcStyles.suggested_date_list}>
                          {suggestedDates.map(suggestedDate => (
                            <li
                              key={suggestedDate.key}
                              className={rcStyles.suggested_date_list_item}
                              role="menuitem"
                              tabIndex="0"
                              onClick={() => this.handleSuggestionChange(suggestedDate)}
                              data-test-id="suggested-date"
                            >
                              <div className={rcStyles.suggested_date_label}>{suggestedDate?.label}</div>
                              <div className={rcStyles.suggested_value_date}>
                                {getFormattedDate(suggestedDate?.value, 'DD/MM/YYYY')}
                              </div>
                              <div className={rcStyles.suggested_value_time}>
                                {getFormattedDate(suggestedDate?.value, 'HH:mm')}
                              </div>
                            </li>
                          ))}
                        </ul>
                    )
                      : (
                        <div className={rcStyles.suggested_dates_empty}>{t('No dates have been added yet.')}</div>
                    )
                  }
                  </div>
                )
                : children
              }
            </div>
          </CalendarContainer>
        </div>
      );
    };

    return DatePickerContainer;
  }

  render() {
    const {
      value: valueAsISOString,
      name,
      label,
      placeholder,
      openToDate,
      minTime: minTimeAsISOString,
      maxTime: maxTimeAsISOString,
      disabled,
      errors,
      helperText,
      fullWidth,
      isClearable,
      disablePast,
      disableFuture,
      displayFormat,
      timeDisplayFormat,
      isStartDate,
      isEndDate,
      showTimeSelect,
      InputProps,
      inputProps,
      'data-test-id': dataTestId,
      ...otherProps
    } = this.props;

    const value = getDateFromISOString(valueAsISOString);
    const timeRange = this.getTimeRange();
    const minDate = this.getMinDate();
    const maxDate = this.getMaxDate();
    const minTime = minTimeAsISOString || ((timeRange && timeRange.length > 0) && timeRange[0]);
    const maxTime = maxTimeAsISOString || ((timeRange && timeRange.length > 1) && timeRange[1]);
    const disablePastDate = disablePast && new Date();
    const disableFutureDate = disableFuture && new Date();

    let updatedOpenToDate = new Date();
    if (minDate) {
      updatedOpenToDate = minDate;
    } else if (maxDate) {
      updatedOpenToDate = maxDate;
    }
    if (openToDate) {
      updatedOpenToDate = openToDate;
    }

    const ClearIcon = (
      <InputAdornment position="end" className={styles.o_icon__blue}>
        <IconButton onClick={this.handleClear}>
          <CloseIcon />
        </IconButton>
      </InputAdornment>
    );

    const suggestedDatesContainer = this.getSuggestedDatesContainer();

    return (
      <div className={classNames(
        rcStyles.datetimepicker,
      )}
      >
        <div className={rcStyles.datetimepickerIcon}>
          <InputAdornment position="start">
            <IconCalendar className={styles.o_icon__blue} />
          </InputAdornment>
        </div>
        <DatePicker
          {...otherProps}
          renderCustomHeader={DatePickerHeader}
          selected={value}
          placeholderText={placeholder}
          openToDate={updatedOpenToDate}
          minDate={minDate || disablePastDate}
          maxDate={maxDate || disableFutureDate}
          minTime={minTime}
          maxTime={maxTime}
          onChange={this.handleChange}
          disabled={disabled}
          customInput={(
            <TextField
              label={label}
              name={name}
              InputLabelProps={{
                FormLabelClasses: {
                  root: rcStyles.datetimepickerLabel,
                  focused: rcStyles.datetimepickerFocused,
                  filled: rcStyles.datetimepickerShurnk,
                },
              }}
              inputProps={{
                ...(inputProps || {}),
                'data-test-id': dataTestId,
              }}
              disabled={disabled}
              error={errors}
              fullWidth={fullWidth}
              helperText={helperText}
            />
          )}
          showTimeSelect={showTimeSelect}
          timeFormat={timeDisplayFormat}
          dateFormat={displayFormat}
          disabledKeyboardNavigation
          popperPlacement="top-start"
          calendarContainer={suggestedDatesContainer}
        />
        <div className={classNames(
          rcStyles.datetimepickerClear,
        )}
        >
          {(!disabled && isClearable && value) && ClearIcon}
        </div>
      </div>
    );
  }
}

export default DateTimePickerWrapper;
