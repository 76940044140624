import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import IconDelete from '@material-ui/icons/Delete';
import { homeType } from 'types/bereavement';
import { reactRefType } from 'types/common';
import AddressFormSection from 'components/common/AddressFormSection';
import BereavementLookup from 'components/common/lookups/BereavementLookup';
import DateTimePicker from 'components/common/DateTimePicker';
import OrganisationalUnitLookup from 'components/common/lookups/OrganisationalUnitLookup';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const CalendarBookEventModal = ({
  isOpen,
  isEditing,
  onClose,
  onSave,
  homes,
  formData,
  onChange,
  onDelete,
  dialogRef,
  formRefs,
  errors,
}) => (
  <Dialog
    onClose={onClose}
    open={isOpen}
    TransitionComponent={Transition}
    className={styles.c_dialogue}
    fullScreen
    scroll="paper"
    disableBackdropClick
    disableEscapeKeyDown
  >
    <div
      ref={dialogRef}
      className={classNames(
        styles.c_dialog__body,
        styles.c_dialog__overflow,
      )}
    >
      <DialogTitle
        disableTypography
        className={styles.dialog_title_bar}
      >
        <IconButton
          className={classNames(
            styles.o_button__secondary,
            styles.is_close,
          )}
          color="primary"
          variant="contained"
          aria-label="Close"
          onClick={onClose}
          data-test-id="feeClose"
        >
          <CloseIcon />
        </IconButton>

        <div className={styles.is_title}>
          {isEditing ? t('Edit event') : t('Book event')}
        </div>
        <div className={styles.is_actions}>
          <Button
            onClick={onSave}
            variant="contained"
            color="secondary"
            data-test-id="calendarEventSave"
            fullWidth
            className={styles.o_button__secondary}
          >
            {isEditing ? t('Edit event') : t('Save')}
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>
        <div
          className={classNames(
            rcStyles.v_spacing,
            styles.o_block_content,
          )}
        >
          <div className={
            classNames(
              rcStyles.modal_actions,
              rcStyles.colspan2,
            )
          }
          >
            <Button
              onClick={onDelete}
              variant="outlined"
              color="primary"
              data-test-id="calendarEventDelete"
            >
              <IconDelete />
              {t('Delete this event')}
            </Button>
          </div>
          <div
            ref={formRefs.title}
            className={rcStyles.colspan2}
          >
            <TextField
              label={t('Title / name of event *')}
              value={formData.title || ''}
              onChange={event => onChange('title', event.target.value)}
              fullWidth
              inputProps={{
                'data-test-id': 'possessions',
              }}
              error={errors && errors.title}
              helperText={errors && errors.title}
            />
          </div>
          <div
            className={rcStyles.colspan2}
          >
            <TextField
              label={t('Event description (optional)')}
              value={formData.description || ''}
              onChange={event => onChange('description', event.target.value)}
              fullWidth
              inputProps={{
                'data-test-id': 'possessions',
              }}
            />
          </div>
          <div ref={formRefs.start}>
            <DateTimePicker
              value={formData.start || null}
              label={t('Start date & time *')}
              onChange={date => onChange('start', date)}
              isClearable
              errors={errors && errors.start}
              helperText={errors && errors.start}
              data-test-id="startDateTime"
            />
          </div>
          <div ref={formRefs.end}>
            <div ref={formRefs.isEndDateBeforeStartDate} />
            <DateTimePicker
              value={formData.end || null}
              label={t('End date & time *')}
              onChange={date => onChange('end', date)}
              isClearable
              errors={(errors && errors.end) || (errors && errors.isEndDateBeforeStartDate)}
              helperText={(errors && errors.end) || (errors && errors.isEndDateBeforeStartDate)}
              data-test-id="endDateTime"
            />
          </div>
          <div
            ref={formRefs.homeIds}
            className={classNames(
              rcStyles.colspan2,
              styles.u_push__top,
            )}
          >
            <OrganisationalUnitLookup
              localHomes={homes}
              value={formData.homeIds || []}
              placeholder={t('Select which homes calendar this meeting will appear on *')}
              onChange={value => onChange('homeIds', value)}
              isMulti
              data-test-id="homesLookup"
            />
          </div>
          <div
            className={
              rcStyles.colspan2
            }
          >
            {errors.homeIds && (<FormHelperText error>{errors.homeIds}</FormHelperText>)}
            <AddressFormSection
              address={formData.location}
              lookupLabel={t('Location')}
              onChange={location => onChange('location', location)}
              fullWidth
              className={classNames(
                styles.u_fullWidth,
              )}
              data-test-id="locationFormSection"
            />
          </div>
          <div
            className={classNames(
              rcStyles.colspan2,
              styles.u_push__top,
            )}
          >
            <BereavementLookup
              label={t('Bereavement')}
              value={formData.bereavementId || null}
              onChange={value => onChange('bereavementId', value)}
              data-test-id="bereavementLookup"
            />
          </div>
        </div>
      </DialogContent>
    </div>
  </Dialog>
);

CalendarBookEventModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  homes: PropTypes.arrayOf(homeType),
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  dialogRef: reactRefType.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default CalendarBookEventModal;
