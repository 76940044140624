import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { t } from 'i18next';
import Button from '@material-ui/core/Button';
import IconAdd from '@material-ui/icons/Add';

import styles from 'scss/main.scss';

const CreateNewTemplateButton = ({
  'data-test-id': testId,
  onClick,
}) => (
  <Button
    className={classNames(
      styles.u_push__right,
    )}
    onClick={onClick}
    color="primary"
    variant="outlined"
    data-test-id={testId}
  >
    <IconAdd />
    {t('Create new template')}
  </Button>
);

CreateNewTemplateButton.propTypes = {
  'onClick': PropTypes.func.isRequired,
  'data-test-id': PropTypes.string.isRequired,
};

export default CreateNewTemplateButton;
