import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { variantsType } from 'types/media';
import { moneyType } from 'types/common';
import { formatCurrency } from 'services/utils';
import classNames from 'classnames';
import styles from 'scss/main.scss';

const Price = ({
  variants = [],
  selectedVariantId,
  price,
  customClass,
  dataTestId,
}) => {
  let selectedVariant = null;
  if (selectedVariantId && variants.length) {
    selectedVariant = variants.find(variant => variant.id === selectedVariantId);
  } else if (variants.length) {
    selectedVariant = variants.find(variant => variant.isDefault);
  }

  const money = selectedVariant ? selectedVariant.prices.sale : price;
  if (!money) {
    return null;
  }
  const displayPrice = formatCurrency(money);

  const isGuidePrice = ((selectedVariant || {}).isGuidePrice || false);

  return (
    <Fragment>
      <div className={classNames(customClass, styles.c_price_label)} data-test-id={dataTestId}>
        {isGuidePrice && (
          <span>{t('From ')}</span>
        )}
        <span>
          {displayPrice.split('.')[0]}
        </span>
        <span className={styles.decimal}>
          {displayPrice.split('.')[1]}
        </span>
      </div>
    </Fragment>
  );
};

Price.propTypes = {
  variants: PropTypes.arrayOf(variantsType),
  selectedVariantId: PropTypes.string,
  price: moneyType,
  customClass: PropTypes.string,
  dataTestId: PropTypes.string,
};

Price.defaultProps = {
  customClass: styles.c_price_label,
};

export default Price;
