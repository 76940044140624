import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TagBanner from './TagBanner';

class TagBannerContainer extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    tags: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedTags: [],
    };
  }

  handleSelectedTag = (event, tag) => {
    event.preventDefault();
    const { onChange } = this.props;
    const { selectedTags } = this.state;

    if (selectedTags.indexOf(tag.value) >= 0) {
      selectedTags.splice(selectedTags.indexOf(tag.value), 1);
    } else {
      selectedTags.push(tag.value);
    }

    this.setState({ selectedTags });
    onChange(selectedTags);
  };

  render() {
    const { tags } = this.props;
    const { selectedTags } = this.state;

    return (
      <TagBanner
        onChange={this.handleSelectedTag}
        tags={tags}
        selectedTags={selectedTags}
      />
    );
  }
}

export default TagBannerContainer;
