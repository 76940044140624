import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import AdminHomeGroupsListItem from 'components/admin/AdminHomeGroupsListItem';
import AdminHomeGroupModal from 'components/admin/AdminHomeGroupModal';
import ScreenLayout from 'components/common/ScreenLayout';
import InfiniteScroll from 'react-infinite-scroll-component';
import SearchIcon from '@material-ui/icons/Search';
import IconLoad from 'icons/IconLoad';
import classNames from 'classnames';
import styles from 'scss/main.scss';

const AdminHomeGroupListScreen = ({
  homeGroups,
  selectedHomeGroup,
  hasMoreHomeGroups,
  getHomeGroups,
  isLoading,
  isModalOpen,
  filters,
  onChangeFilters,
  onHomeGroupChange,
  onModalOpen,
  onSave,
  onCancel,
  formRefs,
  errors,
}) => (
  <ScreenLayout
    title={t('Manage home groups')}
    dashboardBannerProps={{
      breadcrumbs: [{ path: '/', title: t('Dashboard') }, { path: '/homes', title: t('Homes') }, { path: '/homegroups', title: t('Home groups') }],
      description: t('Create, edit and manage your home groups'),
    }}
  >
    <div className={styles.o_view}>
      <div className={classNames(
        styles.o_view__main,
        styles.o_block_content,
      )}
      >
        <span>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => onModalOpen()}
            data-test-id="create"
          >
            {t('Create new home group')}
          </Button>
        </span>

        <TextField
          className={styles.u_push__ends}
          value={filters.searchTerm}
          onChange={event => onChangeFilters('searchTerm', event.target.value)}
          label={t('Search')}
          type="search"
          InputProps={{
            'data-test-id': 'searchTerm',
            'startAdornment': (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          fullWidth
          disabled={isLoading}
        />
        <InfiniteScroll
          dataLength={homeGroups.length}
          hasMore={homeGroups.length > 0 && hasMoreHomeGroups}
          next={getHomeGroups}
          className={styles.u_overflow__hidden}
        >
          {
            homeGroups.map((homeGroup, index) => (
              <div
                key={index}
                className={homeGroup.isNew ? styles.c_flag_standard__PENDING : ''}
              >
                <AdminHomeGroupsListItem
                  key={homeGroup.id}
                  homeGroup={homeGroup}
                  onModalOpen={onModalOpen}
                  isLoading={isLoading}
                />
              </div>
            ))
          }
        </InfiniteScroll>
        <div>
          {isLoading && (
            <div className={styles.c_svg_loader_infinite_scroll} data-test-id="infiniteScrollLoader">
              <IconLoad />
            </div>
          )}
        </div>
      </div>
      <AdminHomeGroupModal
        title="Home Group"
        homeGroup={selectedHomeGroup}
        isOpen={isModalOpen}
        onHomeGroupChange={onHomeGroupChange}
        onSave={onSave}
        onCancel={onCancel}
        formRefs={formRefs}
        errors={errors}
      />
    </div>
  </ScreenLayout>
);

AdminHomeGroupListScreen.propTypes = {
  homeGroups: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.any),
  ),
  selectedHomeGroup: PropTypes.objectOf(PropTypes.any),
  hasMoreHomeGroups: PropTypes.bool.isRequired,
  getHomeGroups: PropTypes.func.isRequired,
  onModalOpen: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  onChangeFilters: PropTypes.func.isRequired,
  onHomeGroupChange: PropTypes.func.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AdminHomeGroupListScreen;
