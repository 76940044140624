import React, { Fragment } from 'react';
import i18next from 'i18next';

import CatalogueItemImageViewer from 'components/portal/CatalogueItemImageViewer';
import ExpandableText from 'components/common/ExpandableText';
import { formatCurrency } from 'services/utils';
import { VariantStatus } from 'types/ts/catalogue';

import { getLowestSalePriceFromVariants, shouldShowFromPrice } from 'services/utils/catalogue';
import Styled from './index.styled';
import { Props } from './index.types';

const CatalogueItemDetails: React.FC<Props> = ({ item }: Props) => {
  const activeVariants = item?.variants
    ?.filter(({ status }) => status === VariantStatus.ACTIVE);

  const nonDefaultVariants = activeVariants?.filter((variant) => {
    if (activeVariants.length === 1) {
      return !variant.isDefault;
    }
    return true;
  });

  const lowestPrice = getLowestSalePriceFromVariants(activeVariants);
  const lowestPricedVariants = activeVariants?.filter(variant => variant.prices.sale.amount === lowestPrice);
  const displayPrice = (lowestPricedVariants?.[0]?.prices) ? formatCurrency(lowestPricedVariants[0].prices.sale) : null;

  return (
    <Styled.DetailsWrapper>
      <div className="item__wrapper">
        <h1 className="item__heading">{item?.title}</h1>
        <div className="item__price">
          {shouldShowFromPrice(activeVariants) && (
            <span className="item__price__from">
              {`${i18next.t('From')} `}
            </span>
          )}
          {displayPrice && (
            <span className="item__price__amount">
              {displayPrice}
            </span>
          )}
        </div>
        {item?.images?.length && (
          <div className="item__images">
            <CatalogueItemImageViewer item={item} />
          </div>
        )}
        <div className="item__description">
          {item?.images?.length ? <ExpandableText text={item?.description} clamp={50} />
            : <p>{item?.description}</p>}
        </div>
        {!!nonDefaultVariants?.length && (
          <div className="item__options">
            <Styled.Options>
              <div className="header">{i18next.t('Options')}</div>
              <div className="options_list">
                {nonDefaultVariants?.map((variant, index) => {
                  const variantPrice = variant.prices.sale;
                  return (
                    <Fragment key={variant.id}>
                      <div className="is_image">
                        <img src={variant.image?.uri} alt="" />
                      </div>
                      <div className="details">
                        <div className="details__name">
                          {variant.name}
                        </div>
                        {variantPrice && (
                          <div className="details__price">
                            {variant.isGuidePrice && (
                              <div className="details__price__label">
                                {i18next.t('From')}
                              </div>
                            )}
                            <div className="details__price__amount">
                              {formatCurrency(variantPrice)}
                            </div>
                          </div>
                        )}
                      </div>
                      {index < (nonDefaultVariants.length - 1) && (
                        <hr className="item_break" />
                      )}
                    </Fragment>
                  );
                })}
              </div>
            </Styled.Options>
          </div>
        )}
      </div>
    </Styled.DetailsWrapper>
  );
};

export default CatalogueItemDetails;
