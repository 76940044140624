import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const IconProfessionalService = (props) => {
  const { className } = props;
  const classes = className ? `${className} ${styles.rc_icon}` : styles.rc_icon;

  return (
    <svg viewBox="17 17.5 26 26" className={classes}><path d="M32,25H28V23h4Zm6,0H34V23l-2-2H28l-2,2v2H22a2,2,0,0,0-2,2v3a2,2,0,0,0,2,2h6V30h4v2h6a2,2,0,0,0,2-2V27A2,2,0,0,0,38,25ZM28,34V33H21v4a2,2,0,0,0,2,2H37a2,2,0,0,0,2-2V33H32v1Z" /></svg>
  );
};

IconProfessionalService.propTypes = {
  className: PropTypes.string,
};

export default IconProfessionalService;
