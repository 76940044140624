import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SortableElement } from 'react-sortable-hoc';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { mediaVariants } from 'constants/media';
import DragnDrop from 'icons/IconDragnDrop';
import styles from 'scss/main.scss';
import { getUriForMediaByVariant } from 'services/utils';
import { productType, serviceType } from 'types/bereavement';

import rcStyles from './styles.scss';

const SortableProductServiceItem = SortableElement(({
  visibilityKey,
  labelForIncludeItem,
  item,
  isSortable,
  onSetIsVisible,
  isIncludible,
}) => {
  const image = item.images && item.images.length > 0
    ? getUriForMediaByVariant(item.images[0], mediaVariants.FULL_FIT_640_X_640)
    : { uri: null };
  return (
    <div
      className={classNames(
        rcStyles.sortable_tile,
        isSortable && rcStyles.is_active,
      )}
      style={{
        cursor: isSortable ? 'pointer' : 'auto',
      }}
      data-test-id={`${item.title}Tile`}
    >
      <div
        style={{
          backgroundImage: `url('${image}')`,
        }}
        className={rcStyles.is_image}
      />
      <div>
        <div className={styles.long_primer}>
          {item.title}
        </div>
        <div>
          {isSortable ? (
            <div className={rcStyles.drag_button}>
              <DragnDrop />
            </div>
          ) : (
            <div>
              {isIncludible && (
                <FormControlLabel
                  control={(
                    <Checkbox
                      onChange={event => onSetIsVisible(item.id, event.target.checked)}
                      inputProps={{ 'data-test-id': 'includeItemInCatalogue' }}
                      color="primary"
                      classes={{ root: styles.u_hard__left }}
                      checked={item[visibilityKey]}
                    />
                  )}
                  label={labelForIncludeItem}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

SortableProductServiceItem.propTypes = {
  visibilityKey: PropTypes.string.isRequired,
  labelForIncludeItem: PropTypes.string.isRequired,
  item: PropTypes.oneOfType([
    productType.isRequired,
    serviceType.isRequired,
  ]),
  isSortable: PropTypes.bool.isRequired,
  onSetIsVisible: PropTypes.func.isRequired,
  isIncludible: PropTypes.bool,
};

export default SortableProductServiceItem;
