import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import IconButton from '@material-ui/core/IconButton';
import IconDragnDrop from 'icons/IconDragnDrop';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import WarningIcon from '@material-ui/icons/Warning';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import MoreVerticalIcon from '@material-ui/icons/MoreVert';
import { mediaType } from 'types/media';
import { moneyType } from 'types/common';
import ArrangementSelectedItemAvatar from 'components/arrangement/ArrangementSelectedItemAvatar';
import EstimateOverridablePrice from 'components/estimate/EstimateOverridablePrice';

import rcStyles from './styles.scss';

const EstimateSelection = ({
  originalTitle,
  originalSubtitle,
  overrideTitle,
  originalPrice,
  overridePrice,
  selectionType,
  category,
  image,
  isPlaceholder,
  isFamilyArranged,
  disabled,
  shouldShowPrice,
  canOverrideTitle,
  canOverridePrice,
  shouldShowEditMenu,
  shouldShowDeleteButton,
  onClickEdit,
  onEditSelectionItemOverride,
  onClickDelete,
  itemMenuAnchorElement,
  onMenuOpen,
  onMenuClose,
  isOverrideItem,
  onOverrideSelection,
  onResetSelection,
}) => (
  <div className={rcStyles.estimate_item}>
    <div className={rcStyles.estimate_item__main}>
      <div className={rcStyles.is_image}>
        <ArrangementSelectedItemAvatar
          image={image}
          title={overrideTitle || originalTitle}
          category={category}
        />
      </div>
      {isPlaceholder && !isFamilyArranged && (
        <Avatar
          className={rcStyles.is_avatar}
        >
          <WarningIcon
            className={rcStyles.is_warning}
          />
        </Avatar>
      )}
      {isOverrideItem ? (
        <TextField
          className={rcStyles.is_title}
          label={t('Title')}
          margin="none"
          value={overrideTitle !== null ? overrideTitle : originalTitle}
          onChange={event => onEditSelectionItemOverride('overrideTitle', event.target.value)}
          inputProps={{
            'data-test-id': 'estimateSelectionOverrideTitle',
          }}
          fullWidth
        />
      ) : (
        <Fragment>
          <div
            className={rcStyles.is_title}
            data-test-id="estimateSelectionTitle"
          >
            {overrideTitle || originalTitle}
            {originalSubtitle && (
              <span>{`. ${originalSubtitle} .`}</span>
            )}
          </div>
        </Fragment>
      )}
      <div
        data-test-id="lineItemPrice"
        className={rcStyles.is_price}
      >
        {shouldShowPrice && (
          <EstimateOverridablePrice
            originalPrice={originalPrice}
            overridePrice={overridePrice}
            canOverridePrice={canOverridePrice}
            onChange={value => onEditSelectionItemOverride('overridePrice', value)}
            disabled={disabled}
            data-test-id="estimateOverridablePrice"
          />
        )}
      </div>
      {shouldShowEditMenu && (
        <Fragment>
          <IconButton
            aria-controls="edit-menu"
            aria-haspopup="true"
            data-test-id="estimateSelectionOptionsButton"
            onClick={onMenuOpen}
            className={rcStyles.is}
          >
            <MoreVerticalIcon />
          </IconButton>
          <Menu
            anchorEl={itemMenuAnchorElement}
            open={!!itemMenuAnchorElement}
            onClose={onMenuClose}
            PaperProps={{
              'data-test-id': 'estimateSelectionOptions',
            }}
          >
            {canOverrideTitle && (
              isOverrideItem ? (
                <MenuItem
                  data-test-id="estimateSelectionReset"
                  onClick={onResetSelection}
                >
                  {t('Reset')}
                </MenuItem>
              ) : (
                <MenuItem
                  data-test-id="estimateSelectionEditTitle"
                  onClick={onOverrideSelection}
                >
                  {t('Edit Title')}
                </MenuItem>
              )
            )}
            {shouldShowDeleteButton && (
              <MenuItem
                data-test-id="estimateSelectionReset"
                onClick={onClickDelete}
              >
                {t('Delete')}
              </MenuItem>
            )}
            <MenuItem
              data-test-id="estimateSelectionChangeItem"
              onClick={() => onClickEdit(category)}
            >
              {t(`Change ${selectionType}`)}
            </MenuItem>
          </Menu>
        </Fragment>
      )}
    </div>
    {!isPlaceholder && (
      <div className={rcStyles.estimate_item__drag}>
        <IconDragnDrop
          className={rcStyles.is_icon}
        />
      </div>
    )}
  </div>
);

EstimateSelection.propTypes = {
  originalTitle: PropTypes.string.isRequired,
  originalSubtitle: PropTypes.string,
  overrideTitle: PropTypes.string,
  originalPrice: moneyType,
  overridePrice: moneyType,
  selectionType: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  image: mediaType,
  isPlaceholder: PropTypes.bool.isRequired,
  isFamilyArranged: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  shouldShowPrice: PropTypes.bool.isRequired,
  canOverrideTitle: PropTypes.bool.isRequired,
  canOverridePrice: PropTypes.bool.isRequired,
  shouldShowEditMenu: PropTypes.bool.isRequired,
  shouldShowDeleteButton: PropTypes.bool.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onEditSelectionItemOverride: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  itemMenuAnchorElement: PropTypes.objectOf(PropTypes.any),
  onMenuOpen: PropTypes.func.isRequired,
  onMenuClose: PropTypes.func.isRequired,
  isOverrideItem: PropTypes.bool.isRequired,
  onOverrideSelection: PropTypes.func.isRequired,
  onResetSelection: PropTypes.func.isRequired,
};

export default EstimateSelection;
