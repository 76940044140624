import React, { Fragment } from 'react';
import { t } from 'i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { buildNameString, defaultMoneyObject } from 'services/utils';
import { accountTotalsType } from 'types/account';
import { bereavedPersonType } from 'types/bereavement';
import Amount from 'components/common/Amount';

import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const AccountTotalsSummary = ({
  billPayer,
  totals,
  hasXeroIntegration,
}) => (
  <div className={rcStyles.summary}>
    <div className={rcStyles.summary__group}>
      <div className={rcStyles.summary__name_label}>
        {t('Primary Bill Payer')}
      </div>
      <div className={rcStyles.sumamry__name}>
        {buildNameString(billPayer.name)}
      </div>
    </div>
    <div className={classNames(
      rcStyles.summary__data,
      hasXeroIntegration && rcStyles.summary__data_xero,
    )}
    >
      {hasXeroIntegration ? (
        <Fragment>
          <Amount
            label={t('Uninvoiced')}
            labelClasses={[
              rcStyles.is_uninvoiced,
              rcStyles.amount_label,
            ]}
            amount={(totals
              && totals.totalInvoiceTemplatesUninvoiced
              && totals.totalInvoiceTemplatesUninvoiced.amount >= 0)
              ? totals.totalInvoiceTemplatesUninvoiced
              : defaultMoneyObject(0)}
            amountProps={{ 'data-test-id': 'accountTemplateUninvoicedTotal' }}
            amountClasses={[styles.is_white]}
          />
          <Amount
            label={t('Invoiced')}
            labelClasses={[
              rcStyles.is_invoiced,
              rcStyles.amount_label,
            ]}
            amount={totals && totals.totalInvoiceTemplatesInvoiced}
            amountProps={{ 'data-test-id': 'accountTemplateInvoicedTotal' }}
            amountClasses={[styles.is_white]}
          />
        </Fragment>
      ) : (
        <Fragment>
          <Amount
            label={t('Uninvoiced')}
            labelClasses={[
              rcStyles.is_uninvoiced,
              rcStyles.amount_label,
            ]}
            amount={(totals && totals.totalUnInvoiced.amount >= 0) ? totals.totalUnInvoiced : defaultMoneyObject(0)}
            amountProps={{ 'data-test-id': 'accountUninvoicedTotal' }}
            amountClasses={[styles.is_white]}
          />
          <Amount
            label={t('Invoiced')}
            labelClasses={[
              rcStyles.is_invoiced,
              rcStyles.amount_label,
            ]}
            amount={totals && totals.totalInvoiced}
            amountProps={{ 'data-test-id': 'accountInvoicedTotal' }}
            amountClasses={[styles.is_white]}
          />
          <Amount
            label={t('Credit')}
            labelClasses={[
              rcStyles.is_credit,
              rcStyles.amount_label,
            ]}
            amount={totals && totals.totalCredit}
            amountProps={{ 'data-test-id': 'accountCreditTotal' }}
            amountClasses={[styles.is_white]}
          />
          <Amount
            label={t('Paid')}
            labelClasses={[
              rcStyles.is_paid,
              rcStyles.amount_label,
            ]}
            amount={totals && totals.totalPaid}
            amountProps={{ 'data-test-id': 'accountPaidTotal' }}
            amountClasses={[styles.is_white]}
          />
          <Amount
            label={t('Balance')}
            labelClasses={[
              rcStyles.is_balance,
              rcStyles.amount_label,
            ]}
            amount={totals && totals.balance}
            amountProps={{ 'data-test-id': 'accountBalanceTotal' }}
            amountClasses={[styles.is_white]}
          />
        </Fragment>
      )}
    </div>
  </div>
);

AccountTotalsSummary.propTypes = {
  billPayer: bereavedPersonType.isRequired,
  totals: accountTotalsType.isRequired,
  hasXeroIntegration: PropTypes.bool.isRequired,
};

export default AccountTotalsSummary;
