import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import { caseStatuses } from 'constants/bereavement';
import { bereavementType, arrangementType } from 'types/bereavement';
import { apolloClientType } from 'types/apollo';
import ArrangementOtherForm from './ArrangementOtherForm';
import { editArrangementOtherInformation } from './mutations.gql';

class ArrangementOtherFormContainer extends Component {
  static propTypes = {
    bereavement: bereavementType.isRequired,
    arrangement: arrangementType.isRequired,
    client: apolloClientType.isRequired,
    onSave: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      categoryInformation: {},
    };
  }

  componentDidMount() {
    const { arrangement } = this.props;
    const categoryInformation = arrangement.categoryInformation
      && arrangement.categoryInformation.other
      ? arrangement.categoryInformation.other
      : {};
    this.setState({ categoryInformation });
  }

  handleSave = () => {
    this.setState({ isSaving: true });
    const {
      client, bereavement, arrangement, onSave,
    } = this.props;
    const { categoryInformation } = this.state;
    onSave(bereavement.id, arrangement.id, 'other', categoryInformation);

    const input = {
      bereavementId: bereavement.id,
      arrangementId: arrangement.id,
      ...categoryInformation,
    };

    client.mutate({
      mutation: editArrangementOtherInformation,
      variables: { input },
    }).finally(() => this.setState({ isSaving: false }));
  }

  handleChange = (key, value) => {
    this.setState(prevState => ({
      categoryInformation: {
        ...prevState.categoryInformation,
        [key]: value,
      },
    }));
  }

  render() {
    const { bereavement } = this.props;
    const { isSaving, categoryInformation } = this.state;

    return (
      <ArrangementOtherForm
        categoryInformation={categoryInformation}
        disabled={isSaving || bereavement.caseStatus === caseStatuses.CLOSED}
        isSaving={isSaving}
        onChange={this.handleChange}
        onSave={this.handleSave}
      />
    );
  }
}

export default withApollo(ArrangementOtherFormContainer);
