import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

const MenuIconButton = styled(IconButton)<{ variant: string }>`
  background-color: transparent;
  margin-right: $unit;

  &:hover {
    background-color: transparent;
  }

  & svg {
    fill: black;
    transition: all 0.6s;

    &:hover {
      fill: black;
    }
  }
`;

export default {
  MenuIconButton,
};
