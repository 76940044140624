import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { matchType, historyType, locationType } from 'types/reactRouter';
import { bindActionCreators } from 'redux';
import {
  clearAllAction,
} from 'actions/catalogue';
import AdminCatalogueSectionScreen from './AdminCatalogueSectionScreen';

class AdminCatalogueSectionScreenContainer extends Component {
  static propTypes = {
    availableFeatures: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    match: matchType.isRequired,
    location: locationType.isRequired,
    history: historyType.isRequired,
    clearAll: PropTypes.func.isRequired,
  }

  componentWillUnmount() {
    const { clearAll } = this.props;
    clearAll();
  }


  render() {
    const { availableFeatures } = this.props;

    return (
      <AdminCatalogueSectionScreen {...this.props} availableFeatures={availableFeatures} />
    );
  }
}

const mapStateToProps = state => ({
  availableFeatures: state.userStore.user.tenantFeatureFlags,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  clearAll: clearAllAction,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(
  AdminCatalogueSectionScreenContainer,
));
