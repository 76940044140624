export const deleteCacheStorage = () => {
  caches.keys().then((names) => {
    names.forEach((name) => {
      if (name.indexOf('webpack-offline') >= 0) {
        // eslint-disable-next-line no-console
        console.log(`Deleting Cache Storage for ${name}`);
        caches.delete(name);
      }
    });
  });
};
