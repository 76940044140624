class WebSocketMessageError extends Error {
  constructor(message) {
    super(message);
    this.name = 'WebSocketMessageError';
    this.screenMessage = 'Please log out and in again';
  }
}

const WebSocketMessageErrorScreen = () => {
  throw new WebSocketMessageError('Logout required');
};

export default WebSocketMessageErrorScreen;
