import { ApolloLink } from 'apollo-link';
import { store } from 'services/state';
import { handleWebsocketMessageAction, setLocationBeforeErrorAction } from 'actions/request';

class WebsocketStatusLink extends ApolloLink {
  constructor({
    shouldReconnect,
    maxReconnectionAttempts,
  }) {
    super();
    this.ws = {
      readyState: WebSocket.CLOSED,
    };
    this.shouldReconnect = shouldReconnect || false;
    this.maxReconnectionAttempts = maxReconnectionAttempts || null;
    this.reconnectionAttempts = 0;
  }

  connectWebsocketClient() {
    if (!process.env.WEBSOCKETS_ENDPOINT_URL) {
      this.ws = {
        readyState: -1,
      };
      return;
    }
    const endpoint = new URL(process.env.WEBSOCKETS_ENDPOINT_URL);
    const accessToken = store.getState().userStore.user.accessToken || false;
    if (accessToken) {
      endpoint.searchParams.set('x-access-token', accessToken);
    }
    this.ws = new WebSocket(endpoint, []);
    this.ws.onopen = () => {
      this.reconnectionAttempts = 0;
    };
    this.ws.onclose = () => {
      if (this.shouldReconnect
        && (this.maxReconnectionAttempts && this.reconnectionAttempts < this.maxReconnectionAttempts)
      ) {
        this.reconnectionAttempts += 1;
        setTimeout(
          this.connectWebsocketClient,
          5000,
        );
      }
    };
    this.ws.onmessage = (request) => {
      const data = JSON.parse(request.data) || request.data;
      store.dispatch(handleWebsocketMessageAction(data));
      store.dispatch(setLocationBeforeErrorAction(window.location.pathname));
    };
    this.ws.onerror = (err) => {
      if (!err || err.target) {
        return;
      }
      if (err.target.readyState && err.target.readyState === WebSocket.CLOSED) {
        setTimeout(
          this.connectWebsocketClient,
          5000,
        );
      }
    };
  }

  request(operation, forward) {
    if (operation.operationName === 'login' && this.ws.readyState === WebSocket.OPEN) {
      this.ws.close();
    }

    if (this.ws.readyState === WebSocket.CLOSED) {
      this.connectWebsocketClient();
    }
    return forward(operation);
  }
}

const websocketStatusLink = new WebsocketStatusLink({
  shouldReconnect: true,
  maxReconnectionAttempts: 10,
});

export default websocketStatusLink;
