import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { withApollo } from 'react-apollo';
import { apolloClientType } from 'types/apollo';
import AdminHomeForm from './AdminHomeForm';
import { getHomeGroups } from './queries.gql';

class HomeFormContainer extends Component {
  static propTypes = {
    client: apolloClientType.isRequired,
    home: PropTypes.objectOf(PropTypes.any).isRequired,
    updateHome: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
  }

  constructor(props) {
    super(props);
    this.state = { groups: null };
  }

  componentDidMount() {
    this.getHomeGroups();
  }

  getHomeGroups = () => {
    const { client } = this.props;

    client.query({
      query: getHomeGroups,
      variables: {
        pagination: {
          first: 500,
          after: null,
        },
      },
    }).then(({ data }) => {
      const organisationalUnits = data.organisationalUnits.edges.map(({ node }) => node);
      organisationalUnits.unshift({ id: 'none', type: 'GROUP', name: t('None') });

      this.setState(() => ({ groups: organisationalUnits }));
    });
  }

  render() {
    const {
      home, updateHome, formRefs, errors, disabled,
    } = this.props;
    const { groups } = this.state;

    return (
      <AdminHomeForm
        home={home}
        groups={groups}
        onHomeChange={updateHome}
        disabled={disabled}
        formRefs={formRefs}
        errors={errors}
      />
    );
  }
}

export default connect()(withApollo(HomeFormContainer));
