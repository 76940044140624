import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import PossessionsModal from 'components/bereavement/PossessionsModal';
import PossessionsReleaseFormModal from 'components/bereavement/PossessionsReleaseFormModal';
import PossessionItem from 'components/bereavement/PossessionItem';
import { bereavedPersonConnectionType, possessionType } from 'types/bereavement';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const PossessionsSection = ({
  bereavementId,
  deceasedPersonId,
  isEditing,
  possessions,
  advisoryPossessions,
  bereavedPeopleConnections,
  isFormVisible,
  isReleaseFormModalOpen,
  editingItem,
  disabled,
  onAdd,
  onCancel,
  onEdit,
  onSave,
  onReleaseFormOpen,
  onReleaseFormClose,
}) => (
  <div className={rcStyles.v_spacing}>
    <div>
      <Button
        onClick={onAdd}
        variant="outlined"
        color="primary"
        disabled={disabled}
        data-test-id="addPossession"
        className={styles.u_push__right}
      >
        <AddIcon className={styles.u_push__right_half} />
        {t('Add Possession')}
      </Button>
      <Button
        onClick={onReleaseFormOpen}
        variant="outlined"
        color="primary"
        disabled={disabled}
        data-test-id="addReleaseForm"
      >
        {t('Create release form')}
      </Button>
    </div>
    {possessions.length > 0 && possessions.map(possession => (
      <PossessionItem
        key={possession.id}
        possession={possession}
        disabled={disabled}
        onEdit={onEdit}
      />
    ))}
    {advisoryPossessions && (
      <Fragment>
        <hr className={classNames(rcStyles.c_large_separator)} />
        <div>
          <h3 className={classNames(
            styles.primer,
            styles.u_push__bottom,
          )}
          >
            {t('Advised possessions from first call:')}
          </h3>
          <p className={classNames(
            styles.primer,
            styles.is_grey_dark,
          )}
          >
            {advisoryPossessions}
          </p>
        </div>
      </Fragment>
    )}
    {isFormVisible && (
      <PossessionsModal
        editingItem={editingItem}
        onCancel={onCancel}
        onSave={onSave}
        isOpen={isFormVisible}
        isEditing={isEditing}
      />
    )}
    <PossessionsReleaseFormModal
      bereavementId={bereavementId}
      deceasedPersonId={deceasedPersonId}
      isOpen={isReleaseFormModalOpen}
      possessions={possessions}
      bereavedPeopleConnections={bereavedPeopleConnections}
      onClose={onReleaseFormClose}
    />
  </div>
);

PossessionsSection.propTypes = {
  bereavementId: PropTypes.string.isRequired,
  deceasedPersonId: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
  possessions: PropTypes.arrayOf(possessionType).isRequired,
  advisoryPossessions: PropTypes.string,
  bereavedPeopleConnections: PropTypes.arrayOf(bereavedPersonConnectionType).isRequired,
  isFormVisible: PropTypes.bool.isRequired,
  isReleaseFormModalOpen: PropTypes.bool.isRequired,
  editingItem: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onReleaseFormOpen: PropTypes.func.isRequired,
  onReleaseFormClose: PropTypes.func.isRequired,
};

export default PossessionsSection;
