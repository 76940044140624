import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { addressType } from 'types/common';
import { buildAddressString } from 'services/utils';

import styles from 'scss/main.scss';

const BereavementAddressItem = ({ label, address }) => (
  <div>
    <span
      className={classNames(
        styles.primer,
        styles.is_bold,
        styles.is_primary,
      )}
    >
      {label}
      :&nbsp;
    </span>
    <span>{buildAddressString(address)}</span>
  </div>
);

BereavementAddressItem.propTypes = {
  label: PropTypes.string.isRequired,
  address: addressType,
};

export default BereavementAddressItem;
