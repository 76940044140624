import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import ArrangementModal from 'components/arrangement/ArrangementModal';
import AppBar from 'components/common/AppBar';
import ArrangementList from 'components/arrangement/ArrangementList';
import CaseDeceasedDetails from 'components/bereavement/CaseDeceasedDetails';
import CaseMenu from 'components/bereavement/CaseMenu';
import CaseSideMenu from 'components/bereavement/CaseSideMenu';
import CaseSummaryTile from 'components/bereavement/CaseSummaryTile';
import ClientListSection from 'components/client/ClientListSection';
import ConfirmationModal from 'components/common/ConfirmationModal';
import { caseStatuses } from 'constants/bereavement';
import { BereavementProvider } from 'contexts/bereavement';
import ArrangementScreen from 'screens/arrangement/ArrangementScreen';
import NotFoundScreen from 'screens/NotFoundScreen';
import overrideTheme from 'services/themes/overrideTheme';
import { arrangementType, bereavementType } from 'types/bereavement';
import { matchType } from 'types/reactRouter';

import styles from 'scss/main.scss';
import IconLoad from 'icons/IconLoad';

const CaseScreen = ({
  bereavement,
  scopes,
  match,
  locationState,
  isLoading,
  isMenuOpen,
  statusToChange,
  toggleMenu,
  onStatusChange,
  onStatusChangeConfirm,
  onStatusChangeCancel,
  editBereavement,
  addBereaved,
  editBereaved,
  removeBereaved,
  toggleAutomaticEmailsConfirmationModal,
  isAutomaticEmailsConfirmationModalOpen,
  onAutomaticEmailConfirmation,
  editingArrangement,
  isModalOpen,
  onModalOpen,
  onModalClose,
}) => {
  const isCaseClosed = bereavement.caseStatus === caseStatuses.CLOSED;
  const content = isLoading
    ? (
      <div className={classNames(
        styles.c_page__full_height,
        styles.has_faded_background,
        styles.o_fb,
      )}
      >
        <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
          <IconLoad />
        </div>
      </div>
    )
    : (
      <div className={classNames(
        styles.c_page__full_height,
        styles.has_faded_background,
      )}
      >
        <AppBar currentBereavementId={bereavement.id} />
        <CaseMenu
          bereavement={bereavement}
          disabled={isCaseClosed}
          openMenu={toggleMenu}
        />
        <CaseSideMenu
          bereavement={bereavement}
          url={match.url}
          scopes={scopes}
          isOpen={isMenuOpen}
          onClose={toggleMenu}
        />
        <ConfirmationModal
          title={t('Funeral Status')}
          message={t('You are about to close this Funeral. All information will become read only. Do you wish to proceed?')}
          isOpen={!!statusToChange}
          onConfirm={onStatusChangeConfirm}
          onCancel={onStatusChangeCancel}
        />
        <BereavementProvider value={bereavement}>
          <Switch>
            <Route
              exact
              path={`${match.url}/arrangement`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>{t('Funeral - arrangement')}</title>
                  </Helmet>
                  <CaseSummaryTile
                    bereavement={bereavement}
                    disabled={isCaseClosed}
                    editBereavement={editBereavement}
                    onStatusChange={onStatusChange}
                    onArrangementModalOpen={onModalOpen}
                  />
                  <ArrangementList
                    bereavementId={bereavement.id}
                    advisoryCommittalType={bereavement.advisoryCommittalType}
                    arrangements={bereavement.arrangements}
                    deceasedPerson={bereavement.deceasedPeople[0]}
                    disabled={isCaseClosed}
                    onArrangementModalOpen={onModalOpen}
                  />
                  <ArrangementModal
                    bereavementId={bereavement.id}
                    deceasedPerson={bereavement.deceasedPeople[0]}
                    advisoryCommittalType={bereavement.advisoryCommittalType}
                    isOpen={isModalOpen}
                    arrangement={editingArrangement}
                    disabled={isCaseClosed}
                    onClose={onModalClose}
                  />
                </Fragment>
              )}
            />
            <Route
              path={`${match.url}/arrangement/:arrangementId`}
              render={() => (
                <ArrangementScreen
                  bereavementId={bereavement.id}
                  disabled={isCaseClosed}
                  displayCategoryInformation
                />
              )}
            />
            <Route
              path={`${match.url}/deceased`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>{t('Funeral - deceased')}</title>
                    <meta name="description" content="" />
                  </Helmet>
                  <CaseDeceasedDetails
                    bereavement={bereavement}
                    locationState={locationState}
                  />
                </Fragment>
              )}
            />
            <Route
              path={`${match.url}/bereaved`}
              render={() => (
                <Fragment>
                  <Helmet>
                    <title>{t('Funeral - bereaved')}</title>
                    <meta name="description" content="" />
                  </Helmet>
                  <div className={styles.o_view}>
                    <div className={styles.o_view__main}>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            checked={bereavement.sendEmailsToBereavedConsent}
                            onChange={event => onAutomaticEmailConfirmation(event.target.checked)}
                            inputProps={{ 'data-test-id': 'sendEmailsToBereavedConsent' }}
                            color="primary"
                            disabled={isCaseClosed}
                          />
                        )}
                        label={t('Automatically send emails for this funeral')}
                      />
                      <div className={styles.o_block_content}>
                        <ClientListSection
                          bereavementId={bereavement.id}
                          bereavedPeopleConnections={bereavement.bereavedPeopleConnections}
                          disabled={isCaseClosed}
                          addClient={addBereaved}
                          editClient={editBereaved}
                          removeClient={removeBereaved}
                        />
                      </div>
                    </div>
                  </div>
                  <ConfirmationModal
                    title="Automatically send emails for this funeral"
                    message="To enable automatic emails, the primary contact requires an email address."
                    isOpen={isAutomaticEmailsConfirmationModalOpen}
                    onConfirm={toggleAutomaticEmailsConfirmationModal}
                  />
                </Fragment>
              )}
            />
            <Route key="not-found" component={NotFoundScreen} />
          </Switch>
        </BereavementProvider>
      </div>
    );

  return (
    <MuiThemeProvider theme={overrideTheme}>
      {content}
    </MuiThemeProvider>
  );
};

CaseScreen.propTypes = {
  bereavement: bereavementType.isRequired,
  scopes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  match: matchType.isRequired,
  locationState: PropTypes.objectOf(PropTypes.any),
  isLoading: PropTypes.bool.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  statusToChange: PropTypes.string,
  toggleMenu: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  onStatusChangeConfirm: PropTypes.func.isRequired,
  onStatusChangeCancel: PropTypes.func.isRequired,
  editBereavement: PropTypes.func.isRequired,
  addBereaved: PropTypes.func.isRequired,
  editBereaved: PropTypes.func.isRequired,
  removeBereaved: PropTypes.func.isRequired,
  toggleAutomaticEmailsConfirmationModal: PropTypes.func.isRequired,
  onAutomaticEmailConfirmation: PropTypes.func.isRequired,
  isAutomaticEmailsConfirmationModalOpen: PropTypes.bool.isRequired,
  editingArrangement: arrangementType,
  isModalOpen: PropTypes.bool.isRequired,
  onModalOpen: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
};

export default CaseScreen;
