import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import Avatar from '@material-ui/core/Avatar';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import IconLoadSml from 'icons/IconLoadSml';
import { arrangementType } from 'types/bereavement';
import { arrangementTypes } from 'constants/arrangement';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const dialogClasses = {
  paper: styles.c_dialog__paper,
};

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const ArrangementModal = ({
  isOpen,
  arrangement,
  isSaving,
  committalType,
  type,
  reference,
  name,
  imageURL,
  onCreate,
  onEdit,
  onChange,
  onClose,
}) => {
  const saveButtonLabel = arrangement ? t('Save') : t('Create');
  return (
    <Fragment>
      <Dialog
        open={isOpen}
        classes={dialogClasses}
        fullScreen
        scroll="paper"
        TransitionComponent={Transition}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <div
          className={classNames(
            styles.c_dialog__body,
            styles.c_dialog__overflow,
          )}
        >
          <DialogTitle
            disableTypography
            className={styles.dialog_title_bar}
          >
            <IconButton
              className={classNames(
                styles.o_button__secondary,
                styles.is_close,
              )}
              color="primary"
              variant="contained"
              aria-label="Close"
              onClick={onClose}
              data-test-id="cancel"
            >
              <CloseIcon />
            </IconButton>
            <div className={styles.is_title}>
              {arrangement ? t('Arrangement') : t('New arrangement')}
            </div>
            <div className={styles.is_actions}>
              <Button
                onClick={arrangement ? onEdit : onCreate}
                variant="contained"
                color="secondary"
                data-test-id="createArrangement"
                fullWidth
              >
                {isSaving ? (
                  <div
                    className={classNames(
                      styles.c_svg_loader_sml,
                      styles.c_svg_loader_sml_white,
                      styles.c_svg_loader_sml_btn,
                    )}
                    data-test-id="buttonLoader"
                  >
                    <IconLoadSml title={t('Saving')} />
                  </div>
                ) : saveButtonLabel
                }
              </Button>
            </div>
          </DialogTitle>

          <DialogContent className={styles.c_dialog__content}>
            <div className={classNames(
              styles.o_block_content,
              rcStyles.v_spacing,
            )}
            >
              <div className={rcStyles.heading}>
                {imageURL
                  && (
                    <Avatar
                      className={rcStyles.avatar}
                      src={imageURL}
                      alt={name}
                    />
                  )
                }
                <div className={classNames(
                  styles.pica,
                  styles.is_black,
                )}
                >
                  {name}
                </div>
              </div>
              <div>
                <div className={styles.long_primer}>
                  {t('Committal Type')}
                </div>
                <FormControl component="fieldset">
                  <RadioGroup
                    name="committalType"
                    value={committalType}
                    onChange={event => onChange('committalType', event.target.value)}
                    data-test-id="arrangementCommittalTypeGroup"
                  >
                    <FormControlLabel
                      value={t('CREMATION')}
                      control={<Radio color="primary" disabled={!!arrangement} data-test-id="cremation" />}
                      label={t('Cremation')}
                    />
                    <FormControlLabel
                      value={t('BURIAL')}
                      control={<Radio color="primary" disabled={!!arrangement} data-test-id="burial" />}
                      label={t('Burial')}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <div className={styles.long_primer}>
                  {t('Funeral Stage')}
                </div>
                <FormControl component="fieldset">
                  <RadioGroup
                    name="arrangementType"
                    value={type}
                    onChange={event => onChange('type', event.target.value)}
                    data-test-id="arrangementTypeGroup"
                  >
                    <FormControlLabel
                      value={arrangementTypes.PRE_NEED}
                      control={<Radio color="primary" disabled={!!arrangement} data-test-id="pre-need" />}
                      label={t('Pre-Need')}
                    />
                    <FormControlLabel
                      value={arrangementTypes.AT_NEED}
                      control={<Radio color="primary" disabled={!!arrangement} data-test-id="at-need" />}
                      label={t('At-Need')}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <TextField
                label={t('Reference Number')}
                value={reference || ''}
                onChange={event => onChange('reference', event.target.value)}
                inputProps={{
                  'data-test-id': 'depth',
                }}
                fullWidth
              />
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </Fragment>
  );
};

ArrangementModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  arrangement: arrangementType,
  isSaving: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  imageURL: PropTypes.string,
  committalType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  reference: PropTypes.string,
  onCreate: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};


export default ArrangementModal;
