import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Select from 'components/common/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Slide from '@material-ui/core/Slide';
import NameFormSection from 'components/common/NameFormSection';
import AddressFormSection from 'components/common/AddressFormSection';
import PhoneFormSection from 'components/common/PhoneFormSection';
import EmailFormSection from 'components/common/EmailFormSection';
import AutoCompleteTextField from 'components/common/AutoCompleteTextField';
import DynamicList from 'components/common/DynamicList';
import IconAddEmail from 'icons/IconAddEmail';
import IconAddPhone from 'icons/IconAddPhone';
import { bereavedPersonConnectionType } from 'types/bereavement';
import { reactRefType } from 'types/common';
import { relationshipsToDeceased } from 'constants/bereavement';
import classNames from 'classnames';
import IconLoadSml from 'icons/IconLoadSml';
import CloseIcon from '@material-ui/icons/Close';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const ClientForm = ({
  bereavedPersonConnection,
  isOpen,
  isAdding,
  isSaving,
  onChange,
  onChangeBereavedPerson,
  onClose,
  onSave,
  dialogRef,
  formRefs,
  errors,
}) => {
  const { bereavedPerson } = bereavedPersonConnection;
  const dialogTitle = isAdding ? 'Add Client' : 'Edit Client';

  const checkBoxClass = {
    root: rcStyles.rc_checkBox,
  };

  return (
    <Dialog
      aria-labelledby="New Client"
      aria-describedby="New Client"
      open={isOpen}
      onClose={onClose}
      fullScreen
      scroll="paper"
      TransitionComponent={Transition}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <div
        ref={dialogRef}
        className={classNames(
          styles.c_dialog__body,
          styles.c_dialog__overflow,
        )}
      >
        <DialogTitle
          disableTypography
          className={styles.dialog_title_bar}
        >
          <IconButton
            className={classNames(
              styles.o_button__secondary,
              styles.is_close,
            )}
            color="primary"
            variant="contained"
            aria-label="Close"
            onClick={onClose}
            data-test-id="feeClose"
          >
            <CloseIcon />
          </IconButton>
          <div className={styles.is_title}>
            {t(dialogTitle)}
          </div>
          <div className={styles.is_actions}>
            <Button
              disabled={isSaving}
              onClick={onSave}
              variant="contained"
              color="secondary"
              data-test-id="saveClient"
              className={styles.o_button__secondary}
            >
              {isSaving ? (
                <div
                  className={classNames(
                    styles.c_svg_loader_sml,
                    styles.c_svg_loader_sml_btn,
                  )}
                  data-test-id="buttonLoader"
                >
                  <IconLoadSml title={t('Saving')} />
                </div>
              ) : t('Save')}
            </Button>
          </div>
        </DialogTitle>
        <DialogContent className={styles.c_dialog__content}>
          <div
            className={classNames(
              rcStyles.v_spacing,
              styles.o_block_content,
            )}
          >
            <div
              ref={formRefs.name}
              className={rcStyles.colspan3}
            >
              <NameFormSection
                name={bereavedPerson.name}
                labelTitle={t('Title *')}
                onChangeHandler={name => onChangeBereavedPerson('name', name)}
                labelGivenName={t('Given Name *')}
                labelFamilyName={t('Family Name *')}
                errors={errors.name}
              />
            </div>
            <div>
              <Select
                id="contactPreference"
                label={t('Contact Preference')}
                value={bereavedPersonConnection?.contactPreferences?.[0] || ''}
                onChange={event => onChange('contactPreferences', [event.target.value])}
                inputProps={{
                  'name': 'contactPreference',
                  'id': 'contactPreference',
                  'data-test-id': 'contactPreference',
                }}
              >
                <MenuItem />
                <MenuItem value="EMAIL">{t('Email')}</MenuItem>
                <MenuItem value="ADDRESS">{t('Post')}</MenuItem>
                <MenuItem value="PHONE">{t('Phone')}</MenuItem>
              </Select>
            </div>
            <div
              ref={formRefs.phones}
              className={rcStyles.colspan3}
            >
              <DynamicList
                component={(
                  <PhoneFormSection hasMargin />
                )}
                addButtonComponent={
                  <IconButton data-test-id="addPhone" />
                }
                removeButtonProps={{
                  variant: 'outlined',
                  color: 'primary',
                  size: 'small',
                  classes: {
                    root: styles.o_button__round,
                  },
                }}
                removeButtonInner={
                  <RemoveIcon />
                }
                addButtonProps={{
                  variant: 'contained',
                  color: 'primary',
                  size: 'small',
                  classes: {
                    root: classNames(
                      styles.o_button__secondary,
                      styles.u_push__top,
                    ),
                  },
                }}
                addButtonInner={(
                  <IconAddPhone />
                )}
                dataList={bereavedPerson.phones || []}
                errors={errors.phones}
                onChange={phones => onChangeBereavedPerson('phones', phones)}
                fullWidth
                secondary
                data-test-id="phones"
              />
            </div>
            <div
              ref={formRefs.emails}
              className={rcStyles.colspan3}
            >
              <DynamicList
                component={(
                  <EmailFormSection
                    label={t('Email')}
                    className={styles.o_fb__inline}
                    fullWidth
                  />
                )}
                addButtonComponent={
                  <IconButton data-test-id="addEmail" />
                }
                addButtonProps={{
                  variant: 'contained',
                  color: 'secondary',
                  size: 'small',
                  classes: {
                    root: classNames(
                      styles.o_button__secondary,
                      styles.u_push__top,
                    ),
                  },
                }}
                addButtonInner={(
                  <IconAddEmail />
                )}
                removeButtonProps={{
                  variant: 'outlined',
                  color: 'primary',
                  size: 'small',
                  classes: { root: styles.o_button__round },
                }}
                removeButtonInner={
                  <RemoveIcon />
                }
                dataList={bereavedPerson.emails || []}
                errors={errors.emails}
                onChange={emails => onChangeBereavedPerson('emails', emails)}
                fullWidth
                secondary
                data-test-id="emails"
              />
            </div>
            <div
              ref={formRefs.address}
              className={rcStyles.colspan3}
            >
              <AddressFormSection
                address={bereavedPerson.address}
                onChange={address => onChangeBereavedPerson('address', address)}
                data-test-id="address"
                errors={errors.address}
                lookupLabel={t('Address')}
              />
            </div>
            <div className={rcStyles.col1}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={bereavedPersonConnection.isPrimaryContact}
                    onChange={event => onChange('isPrimaryContact', event.target.checked)}
                    color="primary"
                    classes={checkBoxClass}
                    inputProps={{
                      'data-test-id': 'primaryContact',
                    }}
                  />
                )}
                label={t('Primary contact')}
              />
            </div>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={bereavedPersonConnection.isBillPayer}
                  onChange={event => onChange('isBillPayer', event.target.checked)}
                  color="primary"
                  classes={checkBoxClass}
                  inputProps={{
                    'data-test-id': 'isBillPayer',
                  }}
                />
              )}
              label={t('Primary Bill Payer')}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={bereavedPersonConnection.isNextOfKin}
                  onChange={event => onChange('isNextOfKin', event.target.checked)}
                  color="primary"
                  classes={checkBoxClass}
                  inputProps={{
                    'data-test-id': 'isNextOfKin',
                  }}
                />
              )}
              label={t('Next of kin')}
            />

            <div className={rcStyles.colspan3}>
              <AutoCompleteTextField
                placeholder={t('Relationship to Deceased')}
                value={bereavedPersonConnection.relationshipToDeceased || ''}
                suggestions={relationshipsToDeceased}
                onChange={relationship => onChange('relationshipToDeceased', relationship)}
                fullWidth
                data-test-id="relationshipToDeceased"
              />
            </div>
            <div
              ref={formRefs.note}
              className={rcStyles.colspan3}
            >
              <TextField
                id="notes"
                label={t('Notes')}
                value={bereavedPerson.note || ''}
                onChange={event => onChangeBereavedPerson('note', event.target.value)}
                inputProps={{
                  'data-test-id': 'notes',
                }}
                fullWidth
              />
            </div>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};

ClientForm.propTypes = {
  bereavedPersonConnection: bereavedPersonConnectionType.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isAdding: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onChangeBereavedPerson: PropTypes.func.isRequired,
  dialogRef: reactRefType.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ClientForm;
