import mapActionToReducer from 'redux-action-reducer-mapper';
import { updateArrayByIndex } from 'services/utils';
import { homesActions } from 'actions/homes';

export const initialState = {
  homes: [],
  pagination: {
    first: 10,
    after: null,
    hasNextPage: true,
  },
  filters: {
    searchTerm: '',
  },
  isLoading: false,
};

const setHomes = (state, action) => {
  const { payload: { homes, pagination, filters } } = action;
  return {
    ...state,
    homes: homes || state.homes,
    pagination: {
      ...state.pagination,
      ...pagination,
    },
    filters: filters ? { ...filters } : { ...state.filters },
  };
};

const clearHomes = state => ({
  ...state,
  homes: initialState.homes,
  pagination: initialState.pagination,
});

const addHomes = (state, action) => {
  const { payload: { homes, pagination } } = action;
  return {
    ...state,
    homes: [...state.homes, ...homes],
    pagination: {
      ...state.pagination,
      ...pagination,
    },
  };
};

const addHome = (state, action) => {
  const { payload } = action;

  const newHome = {
    ...payload,
    isNew: true,
  };

  const newHomes = [...state.homes];
  newHomes.unshift(newHome);

  if (payload) {
    return {
      ...state,
      homes: newHomes,
      pagination: state.pagination,
      filters: state.filters,
    };
  }

  return state;
};

const updateHome = (state, action) => {
  const { payload } = action;

  if (payload) {
    const homeIndex = state.homes.findIndex((home => home.id === payload.id));

    if (homeIndex > -1) {
      return {
        ...state,
        homes: updateArrayByIndex(state.homes, homeIndex, payload),
        pagination: state.pagination,
        filters: state.filters,
      };
    }
  }

  return state;
};

const removeHome = (state, action) => {
  const { payload } = action;
  if (payload && payload.id) {
    const updatedHomes = state.homes.filter(item => (item.id !== payload.id));

    return {
      ...state,
      homes: updatedHomes,
      pagination: state.pagination,
      filters: state.filters,
    };
  }

  return state;
};

const updateFilters = (state, action) => {
  const { payload: { key, value } } = action;
  return {
    ...state,
    filters: {
      ...state.filters,
      [key]: value,
    },
  };
};

const setLoading = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    isLoading: payload,
  };
};

export const homesReducer = mapActionToReducer({
  default: initialState,
  [homesActions.SET_HOMES]: setHomes,
  [homesActions.CLEAR_ALL]: () => initialState,
  [homesActions.CLEAR_HOMES]: clearHomes,
  [homesActions.ADD_HOMES]: addHomes,
  [homesActions.ADD_HOME]: addHome,
  [homesActions.UPDATE_HOME]: updateHome,
  [homesActions.REMOVE_HOME]: removeHome,
  [homesActions.UPDATE_FILTERS]: updateFilters,
  [homesActions.SET_LOADING]: setLoading,
});
