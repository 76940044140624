import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import moment from 'moment';
import classNames from 'classnames';
import { nameType } from 'types/common';
import { buildAddressString, buildNameString } from 'services/utils';
import { receptionVenueSequenceLabels } from 'constants/arrangement';

import styles from './styles.scss';

const CalendarEventContentReceptionVenue = ({
  start,
  caseOwner,
  location,
  alternativeLocation,
  deceasedName,
  bereavedName,
  expectedAttendees,
  sequence,
}) => (
  <Fragment>
    <div className={styles.rbc_dialog__body}>
      <div className={styles.rbc_dialog__item}>
        <div className={styles.is_label}>
          {t('Date')}
        </div>
        <div className={styles.is_value}>
          {moment(start).format('DD MMM YYYY')}
        </div>
      </div>
      <div className={styles.rbc_dialog__item}>
        <div className={styles.is_label}>
          {t('Start time')}
        </div>
        <div className={classNames(styles.rbc_dialog__item_value)}>
          {moment(start).format('HH:mm')}
        </div>
      </div>
      {deceasedName && (
        <div className={styles.rbc_dialog__item}>
          <div className={styles.is_label}>
            {t('Deceased name')}
          </div>
          <div className={styles.is_value}>
            {buildNameString(deceasedName)}
          </div>
        </div>
      )}
      {bereavedName && (
        <div className={styles.rbc_dialog__item}>
          <div className={styles.is_label}>
            {t('Client name')}
          </div>
          <div className={styles.is_value}>
            {buildNameString(bereavedName)}
          </div>
        </div>
      )}
      {caseOwner && (
        <div className={styles.rbc_dialog__item}>
          <div className={styles.is_label}>
            {t('Funeral owner')}
          </div>
          <div className={styles.is_value}>
            {buildNameString(caseOwner.name)}
          </div>
        </div>
      )}
      {location && (
        <div className={styles.rbc_dialog__item}>
          <div className={styles.is_label}>
            {t('Location')}
          </div>
          <div className={styles.is_value}>
            <address>
              {buildAddressString(location)}
            </address>
          </div>
        </div>
      )}
      {alternativeLocation && (
        <div className={styles.rbc_dialog__item}>
          <div className={styles.is_label}>
            {t('Alternative Location')}
          </div>
          <div className={styles.is_value}>
            <address>
              {buildAddressString(alternativeLocation)}
            </address>
          </div>
        </div>
      )}
      {sequence && (
        <div className={styles.rbc_dialog__item}>
          <div className={styles.is_label}>
            {t('Reception')}
          </div>
          <div className={styles.is_value}>
            {receptionVenueSequenceLabels[sequence]}
          </div>
        </div>
      )}
      {expectedAttendees && (
        <div className={styles.rbc_dialog__item}>
          <div className={styles.is_label}>
            {t('Expected Attendees')}
          </div>
          <div className={styles.is_value}>
            {expectedAttendees}
          </div>
        </div>
      )}
    </div>
  </Fragment>
);

CalendarEventContentReceptionVenue.propTypes = {
  start: PropTypes.instanceOf(Date).isRequired,
  deceasedName: nameType.isRequired,
  bereavedName: nameType.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  alternativeLocation: PropTypes.objectOf(PropTypes.any),
  caseOwner: PropTypes.objectOf(PropTypes.any).isRequired,
  expectedAttendees: PropTypes.number,
  sequence: PropTypes.string,
};

export default CalendarEventContentReceptionVenue;
