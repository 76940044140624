import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { clearStaffMembersAction } from 'actions/staffMembers';
import AdminStaffMembersSectionScreen from './AdminStaffMembersSectionScreen';

class AdminStaffMembersSectionScreenContainer extends Component {
  static propTypes = {
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    clearStaffMembers: PropTypes.func.isRequired,
  }

  componentWillUnmount = () => {
    const { clearStaffMembers } = this.props;
    clearStaffMembers();
  }

  render() {
    return (
      <AdminStaffMembersSectionScreen {...this.props} />
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  clearStaffMembers: clearStaffMembersAction,
}, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(
  AdminStaffMembersSectionScreenContainer,
));
