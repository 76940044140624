import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { apolloClientType } from 'types/apollo';
import PublishObituaryModal from './PublishObituaryModal';
import { getFuneralZoneHomes } from './queries.gql';

class PublishObituaryModalContainer extends Component {
  static propTypes = {
    selectedHomeId: PropTypes.string,
    selectedStaffMemberId: PropTypes.string,
    client: apolloClientType.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    onFZDetailsChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      homes: [],
      staffMembers: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.getHomes();
  }

  getHomes = async () => {
    const { client } = this.props;

    this.setState({ isLoading: true });

    const data = await client.query({
      query: getFuneralZoneHomes,
      variables: {},
    });
    const homes = data.data.funeralZoneHomes;
    this.setState({
      homes,
      staffMembers: [],
      isLoading: false,
    });
  }

  handleHomeChange = (event) => {
    const { onFZDetailsChange } = this.props;
    const { homes } = this.state;

    const { employees } = homes.find(funeralZoneHome => funeralZoneHome.id === event.target.value);
    this.setState({ staffMembers: employees });

    onFZDetailsChange([
      { key: 'homeId', value: event.target.value },
      { key: 'arrangerId', value: null },
    ]);
  }

  handleStaffChange = (event) => {
    const { onFZDetailsChange } = this.props;

    onFZDetailsChange([{ key: 'arrangerId', value: event.target.value }]);
  }

  render() {
    const {
      selectedHomeId,
      selectedStaffMemberId,
      errors,
      onCancel,
      onSubmit,
    } = this.props;
    const { homes, staffMembers, isLoading } = this.state;

    return (
      <PublishObituaryModal
        homes={homes}
        staffMembers={staffMembers}
        selectedHomeId={selectedHomeId}
        selectedStaffMemberId={selectedStaffMemberId}
        isLoading={isLoading}
        errors={errors}
        onCancel={onCancel}
        onSubmit={onSubmit}
        onHomeChange={this.handleHomeChange}
        onStaffChange={this.handleStaffChange}
      />
    );
  }
}

export default withApollo(PublishObituaryModalContainer);
