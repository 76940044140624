import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { t } from 'i18next';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';

import StepNavigation from 'components/common/StepNavigation';
import { generateTemplateSteps } from 'constants/templates';

import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

const TemplateGenerationModal = ({
  activeStep,
  errors,
  formRefs,
  template,
  disabledSteps,
  isOpen,
  isPreviewDownloading,
  isTemplateValid,
  isUploading,
  hasBeenUploaded,
  onChangeActiveStep,
  onChangeTemplateDetails,
  onClose,
  onPreview,
  onUploadStart,
  onUploadComplete,
  report,
  templateHasErrors,
  hasBeenSetOnTenant,
}) => {
  const TemplateGenerationStep = generateTemplateSteps[activeStep].component;

  return (
    <Dialog
      onClose={onClose}
      fullScreen
      open={isOpen}
      TransitionComponent={Transition}
      className={styles.c_dialogue}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle
        disableTypography
        className={styles.dialog_title_bar}
      >
        <IconButton
          className={classNames(
            styles.o_button__secondary,
            styles.is_close,
          )}
          color="primary"
          variant="contained"
          aria-label="Close"
          onClick={onClose}
          data-test-id="closeModal"
        >
          <CloseIcon />
        </IconButton>
        <div
          className={styles.is_title}
          data-test-id="createLetterTemplate"
        >
          {t('Create letter template')}
        </div>
        <div className={styles.is_actions}>
          <Button
            color="secondary"
            variant="contained"
            onClick={activeStep === 1 ? () => onClose() : () => onChangeActiveStep(activeStep + 1)}
            disabled={(activeStep === 0 && !isTemplateValid) || (activeStep === 1 && isUploading)}
            data-test-id="changeStep"
          >
            {activeStep === 0 && t('Next')}
            {activeStep === 1 && t('Back to letter templates')}
          </Button>
        </div>
      </DialogTitle>
      <div className={classNames(rcStyles.wrap)}>
        <StepNavigation
          activeStep={activeStep}
          disabledSteps={disabledSteps}
          navigationSteps={generateTemplateSteps}
          onChangeActiveStep={onChangeActiveStep}
        />
        <TemplateGenerationStep
          template={template}
          isTemplateValid={isTemplateValid}
          isUploading={isUploading}
          hasBeenUploaded={hasBeenUploaded}
          isPreviewDownloading={isPreviewDownloading}
          errors={errors}
          formRefs={formRefs}
          onChangeTemplateDetails={onChangeTemplateDetails}
          onClose={onClose}
          onPreview={onPreview}
          onProceedToUpload={() => onChangeActiveStep(1)}
          onUploadStart={onUploadStart}
          onUploadComplete={onUploadComplete}
          report={report}
          templateHasErrors={templateHasErrors}
          hasBeenSetOnTenant={hasBeenSetOnTenant}
        />
      </div>
    </Dialog>
  );
};

TemplateGenerationModal.propTypes = {
  activeStep: PropTypes.number.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  isOpen: PropTypes.bool.isRequired,
  template: PropTypes.objectOf(PropTypes.any).isRequired,
  disabledSteps: PropTypes.arrayOf(PropTypes.number),
  isTemplateValid: PropTypes.bool.isRequired,
  isUploading: PropTypes.bool.isRequired,
  hasBeenUploaded: PropTypes.bool.isRequired,
  isPreviewDownloading: PropTypes.bool.isRequired,
  onChangeActiveStep: PropTypes.func.isRequired,
  onChangeTemplateDetails: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
  onUploadStart: PropTypes.func.isRequired,
  onUploadComplete: PropTypes.func.isRequired,
  report: PropTypes.arrayOf(PropTypes.string),
  templateHasErrors: PropTypes.bool.isRequired,
  hasBeenSetOnTenant: PropTypes.bool.isRequired,
};

export default TemplateGenerationModal;
