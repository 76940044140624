import PropTypes from 'prop-types';

export const correspondenceTemplateType = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  key: PropTypes.string,
  mediaId: PropTypes.string,
  media: PropTypes.objectOf(PropTypes.any),
  createdBy: PropTypes.objectOf(PropTypes.any),
  createdDateTime: PropTypes.string,
  lastEditedBy: PropTypes.objectOf(PropTypes.any),
  lastEditedDateTime: PropTypes.string,
});
