import {
  Product, Service, CatalogueItemOrder, CatalogueItemType, ProductCategory, ServiceCategory, Package,
} from 'types/ts/catalogue';
import { OrganisationalUnit } from 'types/ts/organisationalUnit';
import { Tenant } from 'types/ts/tenant';
import { AnonymousUser } from 'types/ts/user';
import { Edges, Pagination } from 'types/ts';

export enum PortalAction {
  SET_IS_PORTAL_ACTIVE = 'PORTAL_SET_IS_PORTAL_ACTIVE',
  SET_ANONYMOUS_USER = 'PORTAL_SET_ANONYMOUS_USER',
  SET_HOME = 'PORTAL_SET_HOME',
  SET_TENANT = 'PORTAL_SET_TENANT',
  SET_CATALOGUE_ITEMS_LOADING = 'PORTAL_SET_CATALOGUE_ITEMS_LOADING',
  ADD_CATALOGUE_ITEMS = 'PORTAL_ADD_CATALOGUE_ITEMS',
  RESET_CATALOGUE_ITEMS = 'PORTAL_RESET_CATALOGUE_ITEMS',
  RESET_PORTAL_STORE = 'PORTAL_RESET_PORTAL_STORE',
  SET_CATALOGUE_PACKAGES_LOADING = 'PORTAL_SET_CATALOGUE_PACKAGES_LOADING',
  ADD_CATALOGUE_PACKAGES = 'PORTAL_ADD_CATALOGUE_PACKAGES',
  RESET_CATALOGUE_PACKAGES = 'PORTAL_RESET_CATALOGUE_PACKAGES',
}

export type SetIsPortalActiveAction = {
  readonly type: typeof PortalAction.SET_IS_PORTAL_ACTIVE;
  readonly payload: boolean;
};

export type SetAnonymousUserAction = {
  readonly type: typeof PortalAction.SET_ANONYMOUS_USER;
  readonly payload: AnonymousUser|null;
};

export type SetHomeAction = {
  readonly type: typeof PortalAction.SET_HOME;
  readonly payload: OrganisationalUnit|null;
};

export type SetTenantAction = {
  readonly type: typeof PortalAction.SET_TENANT;
  readonly payload: Tenant|null;
};

export type SetCatalogueItemsLoadingAction = {
  readonly type: typeof PortalAction.SET_CATALOGUE_ITEMS_LOADING;
  readonly payload: boolean;
};

export type AddCatalogueItemsAction = {
  readonly type: typeof PortalAction.ADD_CATALOGUE_ITEMS;
  readonly payload: {
    items: Product[]|Service[],
    pagination: Pagination,
    order: CatalogueItemOrder,
    type: CatalogueItemType,
    category: ProductCategory|ServiceCategory,
  },
};

export type ResetCatalogueItemsAction = {
  readonly type: typeof PortalAction.RESET_CATALOGUE_ITEMS;
};

export type SetCataloguePackagesLoadingAction = {
  readonly type: typeof PortalAction.SET_CATALOGUE_PACKAGES_LOADING;
  readonly payload: boolean;
};

export type AddCataloguePackagesAction = {
  readonly type: typeof PortalAction.ADD_CATALOGUE_PACKAGES;
  readonly payload: {
    items: Package[],
    pagination: Pagination,
    order: CatalogueItemOrder,
  },
};

export type ResetCataloguePackagesAction = {
  readonly type: typeof PortalAction.RESET_CATALOGUE_PACKAGES;
};

export type ResetStoreAction = {
  readonly type: typeof PortalAction.RESET_PORTAL_STORE;
};

export type PortalActions =
  | SetIsPortalActiveAction
  | SetAnonymousUserAction
  | SetHomeAction
  | ResetStoreAction;

export type GetHomeResponse = { data: { organisationalUnit: OrganisationalUnit } };

export type GetTenantResponse = { data: { tenant: Tenant } };

export type GetCatalogueItemsResponse = {
  data: {
    products?: Edges<Product>;
    services?: Edges<Service>;
  }
};

export type GetCataloguePackagesResponse = {
  data: {
    packages?: Edges<Package>;
  }
};
