import React, { Component } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { fetchArrangementCatalogueItemsAction, orderCatalogueItemsAsyncAction } from 'actions/catalogue';
import { hasPermissions, arrayMove } from 'services/utils';
import { catalogueCategoriesData } from 'constants/catalogue';
import { catalogueItemTypes } from 'constants/arrangement';
import { scopes as permissions } from 'constants/scopes';
import { matchType, historyType } from 'types/reactRouter';
import { productType, serviceType } from 'types/bereavement';
import AdminCatalogueProductsServicesOrderScreen from './AdminCatalogueProductsServicesOrderScreen';

class AdminCatalogueProductsServicesOrderScreenContainer extends Component {
  static propTypes = {
    scopes: PropTypes.arrayOf(PropTypes.string.isRequired),
    products: PropTypes.arrayOf(productType).isRequired,
    services: PropTypes.arrayOf(serviceType).isRequired,
    match: matchType.isRequired,
    history: historyType.isRequired,
    fetchCatalogueItems: PropTypes.func.isRequired,
    orderCatalogueItems: PropTypes.func.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { products, services } = nextProps;
    if ((products.length > 0 || services.length > 0) && prevState.items.length === 0) {
      const allItems = prevState.category.type === catalogueItemTypes.PRODUCT ? products : services;
      const categoryItems = allItems
        .filter(item => item.category === prevState.category.category)
        .sort((item1, item2) => item1.catalogueOrder - item2.catalogueOrder);
      return {
        items: categoryItems,
        isLoading: false,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);

    const {
      products,
      services,
      match,
      fetchCatalogueItems,
      scopes,
    } = props;
    const { category } = match.params;
    const categoryData = catalogueCategoriesData.find(catalogueCategory => catalogueCategory.path === category);

    if (products.length === 0 && hasPermissions([permissions.PRODUCTS_READ], scopes)) {
      this.setState({ isLoading: true });
      fetchCatalogueItems('products');
    }
    if (services.length === 0 && hasPermissions([permissions.SERVICES_READ], scopes)) {
      this.setState({ isLoading: true });
      fetchCatalogueItems('services');
    }

    const allItems = categoryData.type === catalogueItemTypes.PRODUCT ? products : services;
    const categoryItems = allItems
      .filter(item => item.category === categoryData.category)
      .sort((item1, item2) => item2.catalogueOrder - item1.catalogueOrder);

    this.state = {
      category: categoryData,
      items: categoryItems || [],
      isSortEnabled: false,
      isLoading: false,
      isSaving: false,
      isIncludible: true,
    };
  }

  handleToggleIsSortEnabled = () => {
    this.setState(prevState => ({
      isSortEnabled: !prevState.isSortEnabled,
    }));
  }

  handleSortItems = ({ oldIndex, newIndex }) => {
    const { items } = this.state;
    const reorderedItems = arrayMove(items, oldIndex, newIndex);

    this.setState({ items: reorderedItems });
  }

  handleSetIsVisible = (id, isVisible) => {
    const { items } = this.state;
    const updatedItems = items.map((item) => {
      const updatedItem = { ...item };
      if (updatedItem.id === id) {
        updatedItem.catalogueVisibility = isVisible;
      }
      return updatedItem;
    });
    this.setState({ items: updatedItems });
  }

  handleCancel = () => {
    const { history } = this.props;
    history.push('/catalogue/settings');
  }

  handleSave = () => {
    const { orderCatalogueItems, enqueueSnackbar } = this.props;
    const { category, items } = this.state;
    this.setState({ isSaving: true });

    orderCatalogueItems(category.type, items, () => {
      this.setState({ isSaving: false });
      enqueueSnackbar(
        t('Order and visibility saved successfully'),
        {
          variant: 'success',
        },
      );
      this.handleCancel();
    });
  }

  render() {
    const {
      category,
      items,
      isSortEnabled,
      isLoading,
      isSaving,
      isIncludible,
    } = this.state;
    return (
      <AdminCatalogueProductsServicesOrderScreen
        isIncludible={isIncludible}
        category={category}
        items={items}
        isSortEnabled={isSortEnabled}
        isLoading={isLoading}
        isSaving={isSaving}
        onToggleIsSortEnabled={this.handleToggleIsSortEnabled}
        onSortItems={this.handleSortItems}
        onSetIsVisible={this.handleSetIsVisible}
        onCancel={this.handleCancel}
        onSave={this.handleSave}
      />
    );
  }
}

const mapStateToProps = state => ({
  scopes: state.userStore.user.policy.scopes,
  products: state.catalogueStore.products.items,
  services: state.catalogueStore.services.items,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchCatalogueItems: fetchArrangementCatalogueItemsAction,
  orderCatalogueItems: orderCatalogueItemsAsyncAction,
}, dispatch);

export default withRouter(
  withSnackbar(
    connect(mapStateToProps, mapDispatchToProps)(AdminCatalogueProductsServicesOrderScreenContainer),
  ),
);
