import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AddressFormSection from 'components/common/AddressFormSection';
import Select from 'components/common/Select';
import { flowersDeliveryLocation } from 'constants/flowers';
import IconLoad from 'icons/IconLoad';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const ArrangementFlowersForm = ({
  categoryInformation,
  disabled,
  isSaving,
  onChange,
  onSave,
}) => (
  <div className={rcStyles.v_spacing}>
    <FormControlLabel
      control={(
        <Checkbox
          checked={categoryInformation.isFamilyArranged || false}
          onChange={event => onChange('isFamilyArranged', event.target.checked)}
          inputProps={{ 'data-test-id': 'isFamilyArranged' }}
          disabled={disabled}
          color="primary"
        />
      )}
      label={t('Arranged by family')}
    />
    <FormControlLabel
      control={(
        <Checkbox
          checked={categoryInformation.isFamilyFlowersOnly || false}
          onChange={event => onChange('isFamilyFlowersOnly', event.target.checked)}
          inputProps={{ 'data-test-id': 'isFamilyFlowersOnly' }}
          disabled={disabled}
          color="primary"
        />
      )}
      label={t('Family flowers only')}
    />
    <FormControlLabel
      control={(
        <Checkbox
          checked={categoryInformation.isReturnToFamily || false}
          onChange={event => onChange('isReturnToFamily', event.target.checked)}
          inputProps={{ 'data-test-id': 'isReturnToFamily' }}
          disabled={disabled}
          color="primary"
        />
      )}
      label={t('Return to family')}
    />

    <div className={rcStyles.colspan3}>
      <Select
        value={categoryInformation.deliveredToLocation}
        onChange={event => onChange('deliveredToLocation', event.target.value)}
        label={t('Delivered to location')}
        inputProps={{ 'data-test-id': 'deliveredToLocation' }}
        disabled={disabled}
      >
        {flowersDeliveryLocation.map(deliveryLocation => (
          <MenuItem key={deliveryLocation.value} value={deliveryLocation.value}>
            {deliveryLocation.name}
          </MenuItem>
        ))}
      </Select>
      {categoryInformation.deliveredToLocation === 'OTHER' && (
        <AddressFormSection
          address={categoryInformation.otherDeliveryLocation}
          onChange={address => onChange('otherDeliveryLocation', address)}
          data-test-id="otherDeliveryLocation"
          disabled={disabled}
        />
      )}
    </div>
    {!categoryInformation.isFamilyArranged && (
      <div className={classNames(
        styles.c_confirmed__wrap,
        rcStyles.colspan3,
      )}
      >
        <FormControlLabel
          control={(
            <Checkbox
              className={styles.c_confirmed__checkbox}
              checked={categoryInformation.isConfirmed || false}
              onChange={event => onChange('isConfirmed', event.target.checked)}
              inputProps={{ 'data-test-id': 'isConfirmed' }}
              disabled={disabled}
              color="primary"
            />
          )}
          label={t('Order placed/Availability confirmed')}
        />
      </div>
    )}
    <span>
      <Button
        onClick={onSave}
        color="primary"
        variant="contained"
        disabled={disabled}
        data-test-id="saveFlowersCategory"
      >
        {t('Save')}
      </Button>
    </span>
    {isSaving && (
      <div className={styles.u_fullWidth}>
        <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
          <IconLoad title={t('Saving')} />
        </div>
      </div>
    )}
  </div>
);

ArrangementFlowersForm.propTypes = {
  categoryInformation: PropTypes.objectOf(PropTypes.any).isRequired,
  disabled: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default ArrangementFlowersForm;
