import React from 'react';
import PropTypes from 'prop-types';

import { getFormattedDate } from 'services/utils';
import {
  getOfficiantVenueDisplayText,
  getServiceVenueCategoryInformationByCategory,
} from 'services/utils/arrangement';

import rcStyles from './styles.scss';

const ArrangementVenueDetails = ({ arrangement, service }) => {
  const { category } = service;
  const categoryInformation = getServiceVenueCategoryInformationByCategory(
    arrangement?.categoryInformation,
    category,
  );
  const { startDateTime = null } = categoryInformation || { };

  return (
    <div className={rcStyles.venue_details__container}>
      <div className={rcStyles.venue_details__header}>
        {getOfficiantVenueDisplayText(category)}
      </div>
      <div className={rcStyles.venue_details__title}>
        {service?.title}
      </div>
      {startDateTime && (
        <div className={rcStyles.venue_details__date_time}>
          {`${getFormattedDate(startDateTime)} @ ${getFormattedDate(startDateTime, 'HH:mm')}`}
        </div>
      )}
    </div>
  );
};

ArrangementVenueDetails.propTypes = {
  arrangement: PropTypes.objectOf(PropTypes.any).isRequired,
  service: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ArrangementVenueDetails;
