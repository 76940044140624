import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { t } from 'i18next';
import PropTypes from 'prop-types';

import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const Options = ({
  suggestions, innerRef, isFocused, onSelect,
}) => suggestions.map(suggestion => (
  <MenuItem
    key={suggestion}
    buttonRef={innerRef}
    selected={isFocused}
    component="div"
    onMouseDown={() => onSelect(suggestion)}
    data-test-id={suggestion}
  >
    {suggestion}
  </MenuItem>
));

const AutoCompleteTextFieldView = ({
  onChange, onSelect, suggestions, value, placeholder, fullWidth, disabled,
  classes, isFocused, setFocus, startComponent, endComponent, error, testId,
}) => (
  <FormControl
    data-test-id="autoComplete"
    className={styles.u_fullWidth}
  >
    <TextField
      onChange={(e) => { e.persist(); return onChange(e); }}
      value={value}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      label={t(placeholder)}
      InputProps={{
        'className': classes,
        'inputProps': {
          'autoComplete': 'false',
          'data-test-id': testId || 'input',
        },
        'startAdornment': startComponent,
        'endAdornment': endComponent,
      }}
      fullWidth={fullWidth}
      disabled={disabled}
      error={!!error}
      helperText={error}
    />
    {isFocused && (
      <div className={rcStyles.rc_options}>
        <Options
          suggestions={suggestions}
          onSelect={onSelect}
          className={rcStyles.rc_children}
        />
      </div>
    )}
  </FormControl>
);

AutoCompleteTextFieldView.propTypes = {
  placeholder: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  classes: PropTypes.string,
  value: PropTypes.string.isRequired,
  isFocused: PropTypes.bool.isRequired,
  startComponent: PropTypes.objectOf(PropTypes.any),
  endComponent: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  setFocus: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  testId: PropTypes.string,
};

export default AutoCompleteTextFieldView;
