import React from 'react';
import PropTypes from 'prop-types';
import { buildAddressString } from 'services/utils';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const AdminHomeGroupsListItem = ({
  homeGroup,
  isLoading,
  onModalOpen,
}) => (
  <div className={rcStyles.list_item}>
    <div>
      <h3 className={styles.primer}>{homeGroup.name}</h3>
      {(homeGroup.type === 'HOME') && (
        <span className={styles.brevier}>{buildAddressString(homeGroup.address)}</span>
      )}
    </div>
    <div>
      <IconButton
        color="primary"
        variant="contained"
        onClick={() => onModalOpen(homeGroup)}
        disabled={isLoading}
        data-test-id="open"
      >
        <EditIcon />
      </IconButton>
    </div>
  </div>
);

AdminHomeGroupsListItem.propTypes = {
  homeGroup: PropTypes.objectOf(PropTypes.any),
  isLoading: PropTypes.bool,
  onModalOpen: PropTypes.func,
};

export default AdminHomeGroupsListItem;
