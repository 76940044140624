import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { historyType, matchType } from 'types/reactRouter';
import { deceasedPersonType, arrangementType } from 'types/bereavement';
import { apolloClientType } from 'types/apollo';
import { addArrangementAction, editArrangementAction } from 'actions/bereavement';
import { generateHexId, buildNameString } from 'services/utils';
import { getAvailableNavigation } from 'services/utils/navigation';
import { discountTypes, arrangementTypes } from 'constants/arrangement';
import ArrangementModal from './ArrangementModal';
import { addArrangementMutation, editArrangementMutation } from './mutations.gql';

class ArrangementModalContainer extends Component {
  static propTypes = {
    client: apolloClientType.isRequired,
    history: historyType.isRequired,
    match: matchType.isRequired,
    arrangement: arrangementType,
    currentStaff: PropTypes.objectOf(PropTypes.any).isRequired,
    bereavementId: PropTypes.string.isRequired,
    deceasedPerson: deceasedPersonType.isRequired,
    advisoryCommittalType: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    additionalPath: PropTypes.string,
    addArrangement: PropTypes.func.isRequired,
    editArrangement: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    availableFeatures: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    tenantCategories: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    arrangementCategoryDisplayPreferences: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (!nextProps.isOpen && prevState.isDirty) {
      return {
        type: nextProps.advisoryCommittalType || 'CREMATION',
        reference: '',
        isDirty: false,
      };
    }
    if (nextProps.isOpen && nextProps.arrangement && !prevState.isDirty) {
      return {
        type: nextProps.arrangement.type,
        committalType: nextProps.arrangement.committalType,
        reference: nextProps.arrangement.reference,
        isDirty: true,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      type: '',
      committalType: '',
      reference: null,
      isDirty: false,
      isSaving: false,
    };
  }

  componentDidMount() {
    const { advisoryCommittalType } = this.props;
    this.setState({
      committalType: advisoryCommittalType || 'CREMATION',
      type: arrangementTypes.AT_NEED,
    });
  }

  onChange = (key, value) => {
    this.setState({ [key]: value, isDirty: true });
  }

  onCreate = () => {
    this.setState({ isSaving: true });
    const {
      addArrangement,
      client,
      history,
      match,
      bereavementId,
      currentStaff,
      additionalPath = '',
      onClose,
      availableFeatures,
      tenantCategories,
      arrangementCategoryDisplayPreferences,
    } = this.props;
    const { committalType, type, reference } = this.state;

    onClose();
    const arrangementId = generateHexId();

    const input = {
      id: arrangementId,
      bereavementId,
      reference,
      committalType,
      type,
    };

    addArrangement(
      bereavementId,
      {
        ...input,
        createdById: currentStaff.id,
        createdBy: currentStaff,
        timeCreated: new Date().toISOString(),
        discountType: discountTypes.NONE,
        categoryInformation: null,
      },
    );

    const navigationPaths = getAvailableNavigation(
      availableFeatures,
      tenantCategories,
      arrangementCategoryDisplayPreferences,
      committalType,
    );

    history.push(`${match.url}${additionalPath}/${arrangementId}/${navigationPaths[0].path}`);
    client.mutate({
      mutation: addArrangementMutation,
      variables: { input },
    }).finally(() => this.setState({ isSaving: false }));
  }

  onEdit = () => {
    this.setState({ isSaving: true });
    const {
      editArrangement,
      client,
      bereavementId,
      arrangement,
      onClose,
    } = this.props;
    const { reference } = this.state;

    const input = {
      arrangementId: arrangement.id,
      bereavementId,
      reference,
    };

    editArrangement(bereavementId, arrangement.id, { reference });
    client.mutate({
      mutation: editArrangementMutation,
      variables: { input },
    }).finally(() => {
      this.setState({ isSaving: false });
      onClose();
    });
  }

  render() {
    const {
      deceasedPerson,
      disabled,
      isOpen,
      arrangement,
      onClose,
    } = this.props;
    const {
      isDirty,
      isSaving,
      committalType,
      type,
      reference,
    } = this.state;

    return (
      <ArrangementModal
        isOpen={isOpen}
        arrangement={arrangement}
        isDirty={isDirty}
        isSaving={isSaving}
        type={type}
        committalType={committalType}
        reference={reference}
        disabled={disabled}
        name={buildNameString(deceasedPerson.name)}
        imageURL={deceasedPerson.image && deceasedPerson.image.uri}
        onChange={this.onChange}
        onCreate={this.onCreate}
        onEdit={this.onEdit}
        onClose={onClose}
      />
    );
  }
}

const mapStateToProps = state => ({
  currentStaff: state.userStore.user.staffMember,
  availableFeatures: state.userStore.user.tenantFeatureFlags,
  tenantCategories: state.userStore.user.tenantCategories,
  arrangementCategoryDisplayPreferences: state.userStore.user.arrangementCategoryDisplayPreferences,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  addArrangement: addArrangementAction,
  editArrangement: editArrangementAction,
}, dispatch);

export default withApollo(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ArrangementModalContainer)),
);
