import styled from 'styled-components';

const DetailsWrapper = styled.div`
  & .item_description {
    color: #515551;
    font-size: 18px;
    line-height: 1.556;

    & a {
      color: #515551;
      font-size: 18px;
      line-height: 1.556;
    }
  }
`;

const Options = styled.div`
  & .header {
    font-size: 24px;
    font-weight: bold;
    color: #515551;
    margin-bottom: 1rem;
  }

  & .options_list {
    align-items: center;
    display: grid;
    grid-column-gap: 2rem;
    grid-template-columns: auto 1fr;
    margin-bottom: 1rem;

    &:last-of-type {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    & .is_image {
      background-color: #f5f5f5;
      grid-column-start: 1;

      > img {
        background-color: #FAF9FB;
        display: block;
        height: inherit;
        min-height: 80px;
        max-width: 100%;
        width: 80px;
      }
    }

    & .details {
      display: flex;
      flex-direction: column;
      font-size: 1.125rem;
      grid-column-start: 2;
      position: relative;

      &__name {
        font-size: 1rem;
        line-height: 1.5;
        padding-bottom: 1rem;
      }

      &__price {
        font-size: 1.125rem;
        font-weight: bold;
        line-height: 1rem;
        grid-area: price;

        &__label {
          font-size: 0.9rem;
          font-weight: normal;
        }

        &__amount {
          font-size: 1.125rem;
        }
      }
    }

    & .item_break {
        background: #979797;
        grid-column-end: 3;
        grid-column-start: 1;
        margin: 1rem 0 1rem 0;
        width: 100%;
      }
  }
`;

export default {
  DetailsWrapper,
  Options,
};
