import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Slide from '@material-ui/core/Slide';
import Select from 'components/common/Select';

import IconLoad from 'icons/IconLoad';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const PublishObituaryModal = ({
  homes,
  staffMembers,
  selectedHomeId,
  selectedStaffMemberId,
  isLoading,
  errors,
  onHomeChange,
  onStaffChange,
  onCancel,
  onSubmit,
}) => (
  <Dialog
    onClose={onCancel}
    aria-labelledby="publish-obituary-title"
    fullWidth
    maxWidth="sm"
    open
    TransitionComponent={Transition}
    disableBackdropClick
    disableEscapeKeyDown
  >
    <DialogTitle disableTypography className={styles.c_dialog__header}>
      <div className={classNames(styles.c_dialog__title)}>
        {t('Publish obituary to Funeral Guide')}
      </div>
    </DialogTitle>
    <DialogContent className={styles.c_dialog__content}>
      {t('Note: once published, any information which needs to be edited should be updated by logging into Funeral Guide directly.')}
      {errors.funeralDate && (
      <FormHelperText className={styles.u_push__bottom} error>
        {errors.funeralDate}
      </FormHelperText>
      )}
      {errors.onlineAdministratorBereavedPersonConnectionId && (
        <FormHelperText className={styles.u_push__bottom} error>
          {errors.onlineAdministratorBereavedPersonConnectionId}
        </FormHelperText>
      )}
      {errors.onlineAdministratorBereavedPersonConnection && (
        <FormHelperText className={styles.u_push__bottom} error>
          {errors.onlineAdministratorBereavedPersonConnection}
        </FormHelperText>
      )}
      {
        isLoading ? (
          <div className={rcStyles.rc_loading_container}>
            <div className={classNames(
              styles.c_svg_loader_big,
              styles.c_svg_loader_big_modal,
            )}
            >
              <IconLoad title={t('Saving')} />
            </div>
          </div>
        ) : (
          <Fragment>
            <Select
              label={t('Select home*')}
              value={selectedHomeId}
              onChange={onHomeChange}
              inputProps={{
                'data-test-id': 'fz-home',
              }}
              fullWidth
              disabled={!homes || homes.length === 0}
            >
              {homes.map(home => (
                <MenuItem key={home.id} value={home.id}>{home.name}</MenuItem>
              ))}
            </Select>
            <Select
              label={t('Select Funeral Guide user*')}
              value={selectedStaffMemberId}
              onChange={onStaffChange}
              inputProps={{
                'data-test-id': 'fz-staff',
              }}
              fullWidth
              disabled={!selectedHomeId}
            >
              {staffMembers.map(staffMember => (
                <MenuItem key={staffMember.id} value={staffMember.id}>{staffMember.name}</MenuItem>
              ))}
            </Select>
          </Fragment>
        )
      }
    </DialogContent>
    <DialogActions>
      {onCancel && (
        <Button
          onClick={onCancel}
          variant="contained"
          color="secondary"
          data-test-id="cancel"
          fullWidth
        >
          {t('Cancel')}
        </Button>
      )}
      <Button
        onClick={onSubmit}
        variant="contained"
        color="primary"
        data-test-id="confirm"
        fullWidth
      >
        {t('Publish')}
      </Button>
    </DialogActions>
  </Dialog>
);

PublishObituaryModal.propTypes = {
  homes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  staffMembers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  selectedHomeId: PropTypes.string,
  selectedStaffMemberId: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  onHomeChange: PropTypes.func.isRequired,
  onStaffChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default PublishObituaryModal;
