import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { knowledgeOwlUrlLookup } from 'constants/knowledgeOwlUrlLookup';
import SupportInformation from './SupportInformation';

const knowledgeOwlUrl = 'http://funeral-zone-support.knowledgeowl.com/';

export class SupportInformationContainer extends Component {
  handleLinks = (location) => {
    switch (location) {
      case 'documentation':
        window.open(`${knowledgeOwlUrl}${this.defineKnowledgeOwlPath()}`, '_blank');
        break;

      default:
        break;
    }
  }

  defineKnowledgeOwlPath = () => {
    const { location } = this.props;

    const arrangerPaths = location
      .split('/')
      .filter(Boolean)
      .map((arrangerPath) => {
        const res = /\d/.test(arrangerPath) && arrangerPath.length > 15 ? '*' : arrangerPath;
        return res;
      });

    const match = knowledgeOwlUrlLookup.find((knowledgeOwlItem) => {
      const knowledgeOwlPaths = knowledgeOwlItem.arrangerLocation.split('/');
      return JSON.stringify(knowledgeOwlPaths) === JSON.stringify(arrangerPaths);
    });

    const res = !match ? 'help' : match.knowledgeOwlPath;
    return res;
  }

  render() {
    const { switchWindow, toggleSlideInWindow, userStore } = this.props;
    const { laDeskChatButton } = window;
    const userName = userStore.user.name.givenName;
    return (
      <SupportInformation
        handleLinks={this.handleLinks}
        switchWindow={switchWindow}
        toggleSlideInWindow={toggleSlideInWindow}
        userName={userName}
        laDeskChatButton={laDeskChatButton}
      />
    );
  }
}

SupportInformationContainer.propTypes = {
  toggleSlideInWindow: PropTypes.func,
  switchWindow: PropTypes.func,
  userStore: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.string.isRequired,
};

const mapStateToProps = state => state;

export default connect(mapStateToProps)(SupportInformationContainer);
