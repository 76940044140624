export enum CatalogueItemOrderStrategy {
  SCORE = 'SCORE',
  CREATED_AT = 'CREATED_AT',
  UPDATED_AT = 'UPDATED_AT',
  PROMOTED_FIRST = 'PROMOTED_FIRST',
  TITLE = 'TITLE',
  NAME = 'NAME',
  PRICE = 'PRICE',
  CATALOGUE_ORDER = 'CATALOGUE_ORDER',
  ARRANGEMENT_ORDER = 'ARRANGEMENT_ORDER',
}
