import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { bereavementType } from 'types/bereavement';
import { editBereavementCareWishesTransform } from 'transforms/bereavement';
import { validationSchema } from './validation';
import CareWishesForm from './CareWishesForm';

class CareWishesFormContainer extends Component {
  static propTypes = {
    bereavement: bereavementType.isRequired,
    disabled: PropTypes.bool,
    onCareWishesFormSave: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    generateRefs: PropTypes.func.isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  }

  constructor(props) {
    super(props);

    const { bereavement } = props;
    const deceasedPerson = bereavement
      && bereavement.deceasedPeople && bereavement.deceasedPeople[0];
    const { firstOffices } = deceasedPerson;

    this.state = {
      formData: {
        doNotPerform: (firstOffices && firstOffices.doNotPerform) || false,
        reasonNotToPerform: (firstOffices && firstOffices.reasonNotToPerform) || null,
        shouldCaptureFingerPrint: (deceasedPerson && deceasedPerson.shouldCaptureFingerPrint) || false,
        shouldCollectLockOfHair: (deceasedPerson && deceasedPerson.shouldCollectLockOfHair) || false,
        isFamilyToPrepareDeceased: (deceasedPerson && deceasedPerson.isFamilyToPrepareDeceased) || false,
        isMCCDIssued: (deceasedPerson && deceasedPerson.isMCCDIssued) || false,
        reasonForNoMCCDIssued: (deceasedPerson && deceasedPerson.reasonForNoMCCDIssued) || false,
        MCCDNumber: (deceasedPerson && deceasedPerson.MCCDNumber) || '',
        isInfectious: (deceasedPerson && deceasedPerson.isInfectious) || false,
        clinicians: (bereavement && bereavement.clinicians) || [],
        coroner: {
          name: (bereavement && bereavement.coronerName) || null,
          directoryListingId: (bereavement && bereavement.coronerDirectoryId) || null,
          directoryListing: (bereavement && bereavement.coronerDirectoryListing) || null,
        },
      },
      isValidationEnabled: false,
    };
  }

  componentDidMount() {
    const { generateRefs } = this.props;
    generateRefs(Object.keys(validationSchema.fields));
  }

  handleChange = (key, value) => {
    const { validate } = this.props;
    const { formData, isValidationEnabled } = this.state;
    const updatedFormData = {
      ...formData,
      [key]: value,
    };

    if (isValidationEnabled) {
      validate(updatedFormData, validationSchema);
    }

    this.setState({ formData: updatedFormData });
  }

  handleSave = () => {
    const { bereavement, validate, onCareWishesFormSave } = this.props;
    const { formData } = this.state;

    this.setState({ isValidationEnabled: true });

    const isValid = validate(formData, validationSchema, true);

    if (isValid) {
      onCareWishesFormSave(
        editBereavementCareWishesTransform(bereavement, formData),
      );
    }
  }

  render() {
    const {
      bereavement, formRefs, errors, disabled,
    } = this.props;
    const { formData, isValidationEnabled } = this.state;
    return (
      <CareWishesForm
        bereavementId={bereavement.id}
        formData={formData}
        disabled={disabled}
        errors={isValidationEnabled ? errors : {}}
        formRefs={formRefs}
        onDeceasedChange={this.handleDeceasedChange}
        onBereavementChange={this.handleBereavementChange}
        onCoronerChange={this.handleCoronerChange}
        onChange={this.handleChange}
        onSave={this.handleSave}
      />
    );
  }
}

export default withValidation(CareWishesFormContainer);
