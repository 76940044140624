import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { debounce } from 'debounce';
import PropTypes from 'prop-types';
import {
  fetchDirectoryListingsAction,
  updateFiltersAction,
  publishDirectoryListingAction,
  retireDirectoryListingAction,
} from 'actions/directoryListings';
import { statuses } from 'constants/directoryListing';
import { directoryListingType } from 'types/directoryListing';
import AdminDirectoryListingListScreen from './AdminDirectoryListingListScreen';

class AdminDirectoryListingListScreenContainer extends Component {
  debounceGetDirectoryListings = debounce((filters) => {
    this.getDirectoryListings(filters);
  }, 500);

  static propTypes = {
    directoryListings: PropTypes.arrayOf(directoryListingType).isRequired,
    pagination: PropTypes.objectOf(PropTypes.any),
    filters: PropTypes.objectOf(PropTypes.any),
    isLoading: PropTypes.bool.isRequired,
    fetchDirectoryListings: PropTypes.func.isRequired,
    updateFilters: PropTypes.func.isRequired,
    publishDirectoryListing: PropTypes.func.isRequired,
    retireDirectoryListing: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { directoryListings } = this.props;

    if (!directoryListings || directoryListings.length === 0) {
      this.getDirectoryListings();
    }
  }

  getDirectoryListings = (filters) => {
    const { fetchDirectoryListings } = this.props;
    fetchDirectoryListings(filters);
  }

  handleChangeFilters = (key, value) => {
    const { filters, updateFilters } = this.props;

    if (value === filters[key]) {
      return;
    }
    updateFilters(key, value);
    this.debounceGetDirectoryListings({ ...filters, [key]: value });
  }

  handleStatusChange = (directoryListing) => {
    const { publishDirectoryListing, retireDirectoryListing } = this.props;
    if (directoryListing.status === statuses.PUBLISHED || directoryListing.isNew) {
      retireDirectoryListing(directoryListing);
    } else {
      publishDirectoryListing(directoryListing);
    }
  }

  render() {
    const {
      directoryListings,
      pagination,
      filters,
      isLoading,
    } = this.props;

    return (
      <AdminDirectoryListingListScreen
        directoryListings={directoryListings}
        hasMoreDirectoryListings={directoryListings.length > 0 && pagination.hasNextPage}
        getDirectoryListings={this.getDirectoryListings}
        isLoading={isLoading}
        filters={filters}
        onChangeFilters={this.handleChangeFilters}
        onStatusChange={this.handleStatusChange}
      />
    );
  }
}

const mapStateToProps = state => ({
  directoryListings: state.directoryListingsStore.directoryListings,
  pagination: state.directoryListingsStore.pagination,
  filters: state.directoryListingsStore.filters,
  isLoading: state.directoryListingsStore.isLoading,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchDirectoryListings: fetchDirectoryListingsAction,
  updateFilters: updateFiltersAction,
  publishDirectoryListing: publishDirectoryListingAction,
  retireDirectoryListing: retireDirectoryListingAction,
}, dispatch);

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(AdminDirectoryListingListScreenContainer),
);
