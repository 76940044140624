import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bereavedPersonConnectionType } from 'types/bereavement';
import ObituaryAdministratorList from './ObituaryAdministratorList';

class ObituaryAdministratorListContainer extends Component {
  static propTypes = {
    bereavedPeopleConnections: PropTypes.arrayOf(bereavedPersonConnectionType).isRequired,
    selectedAdministrator: bereavedPersonConnectionType.isRequired,
    disabled: PropTypes.bool,
    onAdministratorChange: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      selectedAdministrator: null,
    };
  }

  handleBereavedChange = (event) => {
    const { value } = event.target;
    this.setState({ selectedAdministrator: value });
  }

  handleOpen = () => {
    const { selectedAdministrator } = this.props;
    if (selectedAdministrator) {
      this.setState({ selectedAdministrator: selectedAdministrator.id });
    }
    this.setState({ isModalOpen: true });
  }

  handleCancel = () => {
    this.setState({
      isModalOpen: false,
      selectedAdministrator: null,
    });
  }

  handleSave = () => {
    const { bereavedPeopleConnections, onAdministratorChange } = this.props;
    const { selectedAdministrator } = this.state;
    const bereavedPersonConnection = bereavedPeopleConnections
      .find(connection => connection.id === selectedAdministrator);

    onAdministratorChange(bereavedPersonConnection);
    this.handleCancel();
  }

  render() {
    const { isModalOpen, selectedAdministrator } = this.state;

    return (
      <ObituaryAdministratorList
        {...this.props}
        isModalOpen={isModalOpen}
        selectedAdministrator={selectedAdministrator}
        onBereavedChange={this.handleBereavedChange}
        onOpen={this.handleOpen}
        onCancel={this.handleCancel}
        onSave={this.handleSave}
      />
    );
  }
}

export default ObituaryAdministratorListContainer;
