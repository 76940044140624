import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { addressType } from 'types/common';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const AddressFormSectionModal = ({
  address,
  isOpen,
  errors,
  onChange,
  disabled,
  addressRef,
  onCancel,
  onSave,
}) => (
  <Dialog
    open={isOpen}
    onClose={onCancel}
    fullScreen
    scroll="paper"
    aria-labelledby="responsive-dialog-title"
    TransitionComponent={Transition}
    disableBackdropClick
    disableEscapeKeyDown
  >
    <div
      className={classNames(
        styles.c_dialog__body,
        styles.c_dialog__overflow,
      )}
    >
      <form onSubmit={(event) => { event.preventDefault(); onSave(); return false; }}>
        <DialogTitle
          id="responsive-dialog-title"
          disableTypography
          className={styles.dialog_title_bar}
        >
          <IconButton
            className={classNames(
              styles.o_button__secondary,
              styles.is_close,
            )}
            color="primary"
            variant="contained"
            aria-label="Close"
            onClick={onCancel}
            data-test-id="feeClose"
          >
            <CloseIcon />
          </IconButton>
          <div className={styles.is_title}>
            {t('Add address')}
          </div>
          <div className={styles.is_actions}>
            <Button
              type="submit"
              color="secondary"
              data-test-id="saveAddress"
              variant="contained"
            >
              {t('Save')}
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <div
            className={classNames(
              rcStyles.v_spacing,
              styles.o_block_content,
            )}
          >
            <TextField
              label="Address Line 1"
              value={address && address.addressLine1 ? address.addressLine1 : ''}
              onChange={onChange}
              InputProps={{
                'inputRef': addressRef,
                'inputProps': {
                  'data-test-id': 'addressLine1',
                },
              }}
              name="addressLine1"
              error={errors && !!errors.addressLine1}
              helperText={errors && errors.addressLine1}
              fullWidth
            />
            <TextField
              label="Address Line 2"
              value={address && address.addressLine2 ? address.addressLine2 : ''}
              name="addressLine2"
              onChange={onChange}
              inputProps={{
                'data-test-id': 'addressLine2',
              }}
              error={errors && !!errors.addressLine2}
              helperText={errors && errors.addressLine2}
              fullWidth
              disabled={disabled}
            />
            <TextField
              label="Town"
              value={address && address.town ? address.town : ''}
              onChange={onChange}
              inputProps={{
                'data-test-id': 'town',
              }}
              name="town"
              error={errors && !!errors.town}
              helperText={errors && errors.town}
              fullWidth
              disabled={disabled}
            />
            <TextField
              label="County"
              value={address && address.county ? address.county : ''}
              name="county"
              onChange={onChange}
              inputProps={{
                'data-test-id': 'county',
              }}
              error={errors && !!errors.county}
              helperText={errors && errors.county}
              fullWidth
              disabled={disabled}
            />
            <TextField
              label="Post Code"
              value={address && address.postCode ? address.postCode : ''}
              onChange={onChange}
              name="postCode"
              inputProps={{
                'data-test-id': 'postCode',
              }}
              error={errors && !!errors.postCode}
              helperText={errors && errors.postCode}
              fullWidth
              disabled={disabled}
            />
          </div>
        </DialogContent>
      </form>
    </div>
  </Dialog>
);

AddressFormSectionModal.propTypes = {
  address: addressType,
  isOpen: PropTypes.bool.isRequired,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)]),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  addressRef: PropTypes.objectOf(PropTypes.any).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default AddressFormSectionModal;
