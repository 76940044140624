import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import ConfirmationModal from 'components/common/ConfirmationModal';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import IconPointer from '@material-ui/icons/TouchApp';
import IconLoadSml from 'icons/IconLoadSml';
import { reactRefType } from 'types/common';
import { mediaType } from 'types/media';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';
import { SortableThumbnailList } from './SortableThumbnails';

const MediaUpload = ({
  fileInputRef,
  displayImage,
  originalImage,
  provisionalUploadMessage,
  thumbnailImages,
  isImagesOnly,
  isMulti,
  uploadButtonText,
  accept,
  showImagePreview = true,
  isUploading,
  disabled,
  customButton,
  onMediaUpload,
  onSortEnd,
  onDelete,
  onDeleteSingleImage,
  onConfirm,
  isConfirmationModalOpen,
  confirmationMessage,
  onCancel,
  testId,
}) => (
  <FormGroup className={rcStyles.form_group}>
    <div className={rcStyles.media_upload}>
      <div className={rcStyles.media_upload__button}>
        <span>
          <Button
            className={rcStyles.media_upload__button}
            color="primary"
            variant="contained"
            size="medium"
            component="label"
            disabled={disabled || isUploading}
            data-test-id={testId || 'mediaUploadButton'}
            {...(customButton && customButton.props)}
          >
            {isUploading ? (
              <div
                className={classNames(
                  styles.c_svg_loader_sml,
                  styles.c_svg_loader_sml_btn,
                  styles.c_svg_loader_sml_white,
                )}
                data-test-id="buttonLoader"
              >
                <IconLoadSml title={t('Uploading')} />
              </div>
            ) : (
              <Fragment>
                {!customButton && (
                  <Fragment>
                    <AddIcon />
                    {uploadButtonText}
                  </Fragment>
                )}
                {customButton && customButton.props.children}
              </Fragment>
            )}
            <input
              ref={fileInputRef}
              type="file"
              style={{ display: 'none' }}
              accept={accept}
              id="contained-button-file"
              data-test-id="mediaUpload"
              multiple={isMulti}
              name="image"
              onChange={event => onMediaUpload(event.target.files)}
            />
          </Button>
        </span>
      </div>
      <Fragment>
        {isMulti && isImagesOnly ? (
          <div className={rcStyles.media_upload__support}>
            <IconPointer />
            <div className={rcStyles.media_upload__support_label}>
              {t('Drag and drop the images to change their order. The featured image will be the image displayed on the product tile and detailed product page. Images are recommended to be a minimum of 1280px x 960px.')}
            </div>
          </div>
        ) : (
          provisionalUploadMessage && (
            <div className={rcStyles.media_upload__support_label}>
              {provisionalUploadMessage}
            </div>
          )
        )}
      </Fragment>
      {isMulti && displayImage ? (
        <SortableThumbnailList
          axis="xy"
          thumbnailImages={thumbnailImages || []}
          onSortEnd={onSortEnd}
          onDelete={onDelete}
        />
      ) : (
        <Fragment>
          {showImagePreview && (
            <Fragment>
              {displayImage ? (
                <div className={rcStyles.media_upload__thumbs}>
                  <div
                    className={rcStyles.media_upload__thumb}
                    style={{ backgroundImage: `url('${displayImage}')` }}
                  >
                    <div data-test-id="uploadedImage" />
                    <a
                      href={`${originalImage}`}
                      className={classNames(
                        rcStyles.is_hidden_button,
                        styles.pseudo_button,
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('View Original')}
                    </a>
                    {onDeleteSingleImage && (
                      <IconButton
                        className={rcStyles.is_delete}
                        color="primary"
                        variant="contained"
                        aria-label="Delete"
                        onClick={onDeleteSingleImage}
                        data-test-id="removeImage"
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </div>
                </div>
              ) : null
              }
            </Fragment>
          )}
        </Fragment>
      )}
      {confirmationMessage && (
        <ConfirmationModal
          message={confirmationMessage}
          isOpen={isConfirmationModalOpen}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      )}
    </div>
  </FormGroup>
);

MediaUpload.propTypes = {
  fileInputRef: reactRefType,
  onMediaUpload: PropTypes.func.isRequired,
  displayImage: PropTypes.string,
  originalImage: PropTypes.string,
  provisionalUploadMessage: PropTypes.string,
  thumbnailImages: PropTypes.arrayOf(mediaType.isRequired),
  isMulti: PropTypes.bool.isRequired,
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  uploadButtonText: PropTypes.string,
  showImagePreview: PropTypes.bool,
  isUploading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  isImagesOnly: PropTypes.bool.isRequired,
  onSortEnd: PropTypes.func.isRequired,
  customButton: PropTypes.node,
  onConfirm: PropTypes.func,
  onDelete: PropTypes.func,
  onDeleteSingleImage: PropTypes.func,
  onCancel: PropTypes.func,
  isConfirmationModalOpen: PropTypes.bool.isRequired,
  confirmationMessage: PropTypes.string,
  testId: PropTypes.string,
};

export default MediaUpload;
