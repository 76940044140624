import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import { withValidation } from '@funeralguide/react-form-validation-hoc';

import { updateBereavementAction } from 'actions/bereavement';
import ArrangementFuneralGuideForm from 'components/arrangement/ArrangementFuneralGuideForm';
import ArrangementPublicationForm from 'components/arrangement/ArrangementPublicationForm';
import { obituaryCategories } from 'constants/arrangement';
import { emptyBereavedPerson, caseStatuses } from 'constants/bereavement';
import { featureFlags } from 'constants/features';
import { getStartAndEndDates } from 'services/utils';
import { generateBereavementObituary } from 'services/utils/bereavement';
import { apolloClientType } from 'types/apollo';
import { editObituaryInformationMutationTransform } from 'transforms/arrangement';
import { bereavementType, arrangementType } from 'types/bereavement';

import ArrangementObituaryForm from './ArrangementObituaryForm';
import { validationSchema } from './validation';
import { editArrangementObituaryInformationMutation } from './mutations.gql';

const defaultObituaryInformation = {
  isOnlineObituaryServiceInfo: null,
  isOnlineObituaryCommittal: null,
  isOnlineObituaryReception: null,
  onlineReception: null,
};

class ArrangementObituaryFormContainer extends Component {
  static propTypes = {
    bereavement: bereavementType.isRequired,
    arrangement: arrangementType.isRequired,
    client: apolloClientType.isRequired,
    hasFuneralZoneAccount: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    updateBereavement: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    generateRefs: PropTypes.func.isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  static getDerivedStateFromProps(nextProps, state) {
    if (state.categoryInformation
      && state.categoryInformation.onlineAdministratorBereavedPersonConnectionId) {
      const bereavedId = state.categoryInformation.onlineAdministratorBereavedPersonConnectionId;
      const updatedConnection = nextProps.bereavement.bereavedPeopleConnections
        .find(connection => connection.id === bereavedId);
      return {
        categoryInformation: {
          ...state.categoryInformation,
          onlineAdministratorBereavedPersonConnection: updatedConnection,
          funeralDate: nextProps.bereavement.funeralDate,
        },
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.categories = [
      {
        title: obituaryCategories.CATEGORY_PUBLICATION,
        formComponent: ArrangementPublicationForm,
      },
    ];

    if (props.hasFuneralZoneAccount) {
      this.categories.unshift({
        title: obituaryCategories.CATEGORY_FUNERAL_GUIDE,
        formComponent: ArrangementFuneralGuideForm,
      });
    }

    this.state = {
      isValidationEnabled: false,
      isSaving: false,
      isPublishObituaryModalOpen: false,
      categoryInformation: {},
      selectedCategory: this.categories[0],
    };
  }

  componentDidMount() {
    const { arrangement, bereavement, generateRefs } = this.props;
    const { categoryInformation } = arrangement;

    generateRefs(Object.keys(validationSchema.fields));
    if (categoryInformation && categoryInformation.obituary) {
      const { obituary } = categoryInformation;
      const administratorId = obituary.onlineAdministratorBereavedPersonConnectionId;
      const selectedAdministrator = administratorId && bereavement.bereavedPeopleConnections
        .find(connection => connection.id === administratorId);
      this.setState({
        categoryInformation: {
          ...categoryInformation.obituary,
          onlineAdministratorBereavedPersonConnection: selectedAdministrator,
          funeralDate: bereavement.funeralDate,
          image: obituary.image || null,
          imageId: obituary.imageId || null,
        },
      });
    } else {
      const primaryBereaved = bereavement.bereavedPeopleConnections
        .find(bereaved => bereaved.isPrimaryContact);
      this.setState({
        categoryInformation: {
          ...defaultObituaryInformation,
          onlineAdministratorBereavedPersonConnectionId: primaryBereaved.id,
          onlineAdministratorBereavedPersonConnection: primaryBereaved,
          funeralDate: bereavement.funeralDate,
          deceasedName: bereavement.deceasedPeople[0].name,
          deceasedImage: bereavement.deceasedPeople[0].image || null,
          deceasedImageId: bereavement.deceasedPeople[0].image
            ? bereavement.deceasedPeople[0].image.id
            : null,
        },
      });
    }
  }

  handleCategoryChange = (selected) => {
    const selectedCategory = this.categories[selected];
    this.setState({ selectedCategory });
  }

  handleCategoryInformationSave = (shouldPublish) => {
    const {
      client, bereavement, arrangement, onSave, validate, updateBereavement,
    } = this.props;
    const { categoryInformation, selectedCategory } = this.state;

    this.setState({ isValidationEnabled: true });
    const isValid = validate({
      ...categoryInformation,
      shouldPublish,
      selectedCategory: selectedCategory.title,
    }, validationSchema, true);

    if (isValid) {
      this.setState({ isSaving: true, isPublishObituaryModalOpen: false });
      onSave(bereavement.id, arrangement.id, 'obituary', categoryInformation);

      const input = editObituaryInformationMutationTransform(
        bereavement.id, arrangement.id, categoryInformation, shouldPublish,
      );

      if (shouldPublish) {
        const bereavementObituary = generateBereavementObituary(bereavement, categoryInformation);
        updateBereavement(bereavement.id, 'obituary', bereavementObituary);
      }

      client.mutate({
        mutation: editArrangementObituaryInformationMutation,
        variables: { input },
      }).finally(() => this.setState({ isSaving: false, isValidationEnabled: false }));
    }
  }

  handleCategoryInformationChange = (key, value) => {
    const { validate } = this.props;
    const { categoryInformation, isValidationEnabled, selectedCategory } = this.state;

    const newState = {
      ...categoryInformation,
      [key]: value,
    };

    if (key === 'isOnlineObituaryReception') {
      newState.onlineReception = value ? {
        address: null,
        dateTime: null,
      } : null;
    }

    if (key === 'onlineAdministratorBereavedPersonConnection') {
      newState.onlineAdministratorBereavedPersonConnectionId = value.id;
    }

    if (isValidationEnabled) {
      validate({ ...newState, selectedCategory: selectedCategory.title }, validationSchema);
    }
    this.setState({ categoryInformation: newState });
  }

  handlePublishObituaryToggle = (isOpen) => {
    this.setState({ isPublishObituaryModalOpen: isOpen });
  }

  handleFZDetailsChange = (changes) => {
    const { validate } = this.props;
    const { categoryInformation, isValidationEnabled, selectedCategory } = this.state;

    const funeralZoneDetails = changes.reduce((details, nextChange) => ({
      ...details,
      [nextChange.key]: nextChange.value,
    }), categoryInformation.funeralZoneDetails);

    const newState = {
      ...categoryInformation,
      funeralZoneDetails,
    };

    if (isValidationEnabled) {
      validate({ ...newState, selectedCategory: selectedCategory.title }, validationSchema);
    }
    this.setState({ categoryInformation: newState });
  }

  render() {
    const {
      bereavement, arrangement, errors, formRefs,
    } = this.props;
    const {
      isSaving,
      categoryInformation,
      selectedCategory,
      isValidationEnabled,
      isPublishObituaryModalOpen,
    } = this.state;
    const { id, funeralDate, deceasedPeople } = bereavement;
    const { dateOfBirth, deathDateTime } = deceasedPeople[0];

    const deceasedDates = getStartAndEndDates(dateOfBirth, deathDateTime, 'DD MMM YY');

    const selectedAdministrator = bereavement.bereavedPeopleConnections
      .find(bereaved => bereaved.id
        === categoryInformation.onlineAdministratorBereavedPersonConnectionId)
        || emptyBereavedPerson;

    return (
      <ArrangementObituaryForm
        bereavementId={id}
        arrangement={arrangement}
        funeralDate={funeralDate}
        deceasedDates={deceasedDates}
        bereavedPeopleConnections={bereavement.bereavedPeopleConnections}
        selectedAdministrator={selectedAdministrator}
        selectedCategory={selectedCategory}
        categories={this.categories}
        categoryInformation={categoryInformation}
        isPublishObituaryModalOpen={isPublishObituaryModalOpen}
        isObituaryPublished={!!bereavement.obituary}
        isSaving={isSaving}
        isCategoryInformationDisabled={isSaving || bereavement.caseStatus === caseStatuses.CLOSED}
        onCategoryChange={this.handleCategoryChange}
        onCategoryInformationChange={this.handleCategoryInformationChange}
        onPublishObituaryModalToggle={this.handlePublishObituaryToggle}
        onFZDetailsChange={this.handleFZDetailsChange}
        onCategoryInformationSave={this.handleCategoryInformationSave}
        publishObituary={this.publishObituary}
        formRefs={formRefs}
        errors={isValidationEnabled ? errors : {}}
      />
    );
  }
}

const mapStateToProps = state => ({
  hasFuneralZoneAccount: state.userStore.user.tenantFeatureFlags
    .includes(featureFlags.FUNERAL_ZONE_OBITUARIES),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateBereavement: updateBereavementAction,
}, dispatch);

export default withApollo(connect(mapStateToProps, mapDispatchToProps)(
  withValidation(ArrangementObituaryFormContainer),
));
