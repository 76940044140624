import React from 'react';

const DocumentUploading = () => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Letter-Templates" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Uploading-animation" transform="translate(-397.000000, -587.000000)">
        <g id="Group-4-Copy" transform="translate(397.394292, 587.000000)">
          <ellipse id="Oval" fillOpacity="0.061871722" fill="#00B39E" transform="translate(105.000000, 106.529304) rotate(-1.000000) translate(-105.000000, -106.529304) " cx="105" cy="106.529304" rx="104" ry="23.5" />
          <g id="Group-3" transform="translate(62.096210, 0.000000)">
            <path d="M68.881,0.00124240492 L86,17.2912424 L86,104.001242 C86,106.210381 84.209139,108.001242 82,108.001242 L4,108.001242 C1.790861,108.001242 2.705415e-16,106.210381 0,104.001242 L0,4.00270086 C-2.705415e-16,1.79356186 1.790861,0.00270086056 4,0.00270086056 L68.881,0.00124240492 Z" id="Combined-Shape" fill="#C2DFFF" />
            <rect id="Rectangle" fill="#87C1FF" x="14" y="27.0012424" width="60" height="10" rx="1" />
            <rect id="Rectangle" fill="#87C1FF" x="14" y="43.0012424" width="60" height="10" rx="1" />
            <rect id="Rectangle" fill="#87C1FF" x="14" y="61.0012424" width="52" height="10" rx="1" />
            <path d="M69.308,0.00124240492 L86,17.6752424 L86,18.0012424 L69,18.0012424 L69,0.00153328612 L69.308,0.00124240492 Z" id="Combined-Shape" fill="#87C1FF" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default DocumentUploading;
