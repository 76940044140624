import { scopes } from 'constants/scopes';
import {
  object,
  array,
  string,
  mixed,
  boolean,
} from 'yup';

export const categories = {
  ARRANGER: 'ARRANGER',
  DRIVER: 'DRIVER',
  MORTUARY: 'MORTUARY',
  OPERATIONS: 'OPERATIONS',
  FINANCE: 'FINANCE',
  WORKSHOP: 'WORKSHOP',
};

export const status = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
  DISMISSED: 'DISMISSED',
};

export const detailsFieldType = {
  BOOLEAN: 'BOOLEAN',
  DATE: 'DATE',
  DATETIME: 'DATETIME',
  MEASUREMENT: 'MEASUREMENT',
  TEXT: 'TEXT',
};

export const progressFieldType = {
  ADDRESS: 'ADDRESS',
  BOOLEAN: 'BOOLEAN',
  DATE: 'DATE',
  DATE_TIME: 'DATE_TIME',
  MEASUREMENT: 'MEASUREMENT',
  STAFF_MEMBER: 'STAFF_MEMBER',
  STATIC_SELECT: 'STATIC_SELECT',
  TEXT: 'TEXT',
};

export const categoriesForScope = {
  [scopes.ARRANGER_TASK_READ]: [categories.ARRANGER],
  [scopes.DRIVER_TASK_READ]: [categories.DRIVER],
  [scopes.MORTUARY_TASK_READ]: [categories.MORTUARY],
  [scopes.OPERATIONS_TASK_READ]: [categories.OPERATIONS],
  [scopes.FINANCE_TASK_READ]: [categories.FINANCE],
  [scopes.WORKSHOP_TASK_READ]: [categories.WORKSHOP],
};

export const taskValidationSchema = object({
  progressJson: array().of(object().shape({
    key: string().required(),
    required: boolean().required(),
    type: string().required(),
    label: string().required(),
    value: mixed().when(['required', 'type', 'label'], (required, type, label, schema) => {
      const message = `${label} is required`;
      if (!required) {
        return schema;
      }
      if (type === progressFieldType.BOOLEAN) {
        return schema.oneOf([true], message);
      }
      if (type === progressFieldType.TEXT) {
        return schema.notOneOf([''], message).required(message);
      }
      return schema.required(message);
    }),
  })),
});
