import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import ScreenLayout from 'components/common/ScreenLayout';
import AdminStaffMembersListItem from 'components/admin/AdminStaffMembersListItem';
import OrganisationalUnitLookup from 'components/common/lookups/OrganisationalUnitLookup';
import { organisationalUnitTypes } from 'constants/organisationalUnits';
import { staffMemberType } from 'types/staffMember';
import classNames from 'classnames';
import IconLoad from 'icons/IconLoad';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';


const AdminStaffMembersListScreen = ({
  staffMembers,
  hasMoreStaffMembers,
  getStaffMembers,
  isLoading,
  filters,
  onChangeFilters,
}) => (
  <ScreenLayout
    title={t('Staff Members')}
    dashboardBannerProps={{
      breadcrumbs: [{ path: '/', title: t('Dashboard') }, { path: '/staff-members', title: t('Staff Members') }],
      description: t('Add and edit staff members. Assign staff to homes and manage roles.'),
    }}
  >
    <div className={styles.o_view}>
      <div className={styles.o_view__main}>
        <div className={styles.o_block_content}>
          <Button
            component={Link}
            color="primary"
            variant="outlined"
            to="/staff-members/create"
            data-test-id="create"
            className={styles.u_push__bottom}
          >
            {t('Add member of staff')}
          </Button>
          <div className={rcStyles.search}>
            <TextField
              value={filters.searchTerm}
              onChange={event => onChangeFilters('searchTerm', event.target.value)}
              label={t('Search')}
              type="search"
              InputProps={{
                'data-test-id': 'searchTerm',
                'startAdornment': (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
            <div className={rcStyles.home_lookup}>
              <OrganisationalUnitLookup
                value={filters.organisationalUnits}
                label="Select home"
                onChange={value => onChangeFilters('organisationalUnits', value)}
                type={organisationalUnitTypes.HOME}
                isMulti
                data-test-id="homeLookup"
              />
            </div>
          </div>
          {(isLoading && staffMembers && staffMembers.length === 0)
          && (
            <div className={classNames(
              styles.c_page__full_height,
              styles.has_no_background,
            )}
            >
              <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
                <IconLoad />
              </div>
            </div>
          )
          }
          <Fragment>
            <InfiniteScroll
              dataLength={staffMembers.length}
              hasMore={staffMembers.length > 0 && hasMoreStaffMembers}
              next={getStaffMembers}
              className={classNames(
                styles.u_fullWidth,
                styles.u_overflow__hidden,
              )}
              loader={staffMembers.length > 0 && (
                <div className={styles.c_svg_loader_infinite_scroll} data-test-id="infiniteScrollLoader">
                  <IconLoad />
                </div>
              )}
            >
              {
                staffMembers.map(staffMember => (
                  <AdminStaffMembersListItem key={staffMember.id} staffMember={staffMember} />
                ))
              }
            </InfiniteScroll>
          </Fragment>
        </div>
      </div>
    </div>
  </ScreenLayout>
);

AdminStaffMembersListScreen.propTypes = {
  staffMembers: PropTypes.arrayOf(staffMemberType.isRequired),
  hasMoreStaffMembers: PropTypes.bool.isRequired,
  getStaffMembers: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  onChangeFilters: PropTypes.func.isRequired,
};

export default AdminStaffMembersListScreen;
