import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import AddressFormSection from 'components/common/AddressFormSection';
import DatePicker from 'components/common/DatePicker';
import DateTimePicker from 'components/common/DateTimePicker';
import Select from 'components/common/Select';
import IconLoad from 'icons/IconLoad';
import styles from 'scss/main.scss';
import { getSuggestedArrangementVenueDates } from 'services/utils/arrangement';
import { arrangementType } from 'types/bereavement';

import rcStyles from './styles.scss';

const ArrangementBurialForm = ({
  arrangement,
  categoryInformation,
  disabled,
  isSaving,
  onChange,
  onNumericChange,
  onSave,
  formRefs,
  errors,
}) => {
  const { startDateTime, endDateTime } = categoryInformation;
  return (
    <div className={rcStyles.v_spacing}>
      <div ref={formRefs.startDateTime}>
        <DateTimePicker
          value={startDateTime || null}
          label={t('Start date & time')}
          placeholder={t('Start date & time')}
          isStartDate
          maxDate={endDateTime}
          timeRange={[startDateTime, endDateTime]}
          getSuggestedDates={() => getSuggestedArrangementVenueDates(arrangement)}
          onChange={date => onChange('startDateTime', date)}
          onClearEndDate={() => onChange('endDateTime', null)}
          isClearable
          errors={errors && !!errors.startDateTime}
          helperText={errors && errors.startDateTime}
          disabled={disabled}
          data-test-id="startDateTime"
        />
      </div>
      <div ref={formRefs.endDateTime}>
        <DateTimePicker
          value={endDateTime || null}
          label={t('End date & time')}
          placeholder={t('End date & time')}
          isEndDate
          minDate={startDateTime}
          timeRange={[startDateTime, endDateTime]}
          onChange={date => onChange('endDateTime', date)}
          isClearable
          disabled={disabled}
          errors={errors && !!errors.endDateTime}
          helperText={errors && errors.endDateTime}
          data-test-id="endDateTime"
        />
      </div>
      <div
        ref={formRefs.funeralHomeBearerQuantity}
        className={rcStyles.col1}
      >
        <TextField
          type="number"
          label={t('Bearers supplied by us')}
          value={categoryInformation.funeralHomeBearerQuantity || ''}
          onChange={event => onNumericChange('funeralHomeBearerQuantity', event.target.value)}
          disabled={disabled}
          error={errors && errors.funeralHomeBearerQuantity}
          helperText={errors && errors.funeralHomeBearerQuantity}
          fullWidth
          inputProps={{
            'data-test-id': 'funeralHomeBearerQuantity',
            'min': 0,
          }}
        />
      </div>
      <div ref={formRefs.familyBearerQuantity}>
        <TextField
          type="number"
          label={t('Bearers supplied by family')}
          value={categoryInformation.familyBearerQuantity || ''}
          onChange={event => onNumericChange('familyBearerQuantity', event.target.value)}
          disabled={disabled}
          error={errors && errors.familyBearerQuantity}
          helperText={errors && errors.familyBearerQuantity}
          fullWidth
          inputProps={{
            'data-test-id': 'familyBearerQuantity',
            'min': 0,
          }}
        />
      </div>
      <div
        className={rcStyles.col1}
        ref={formRefs.graveType}
      >
        <Select
          label={t('Grave type')}
          value={categoryInformation.graveType}
          onChange={event => onChange('graveType', event.target.value)}
          inputProps={{
            'data-test-id': 'graveType',
          }}
          fullWidth
          disabled={disabled}
        >
          <MenuItem value="ADULT">{t('Adult')}</MenuItem>
          <MenuItem value="CHILD">{t('Child')}</MenuItem>
          <MenuItem value="CREMATED_REMAINS">{t('Cremated remains')}</MenuItem>
        </Select>
      </div>
      <div ref={formRefs.graveSize}>
        <Select
          label={t('Grave size')}
          value={categoryInformation.graveSize}
          onChange={event => onChange('graveSize', event.target.value)}
          inputProps={{
            'data-test-id': 'graveSize',
          }}
          fullWidth
          disabled={disabled}
        >
          <MenuItem value="SINGLE">{t('Single')}</MenuItem>
          <MenuItem value="DOUBLE">{t('Double')}</MenuItem>
          <MenuItem value="TRIPLE">{t('Triple')}</MenuItem>
        </Select>
      </div>
      <div ref={formRefs.graveDetails}>
        <TextField
          label={t('Grave details')}
          value={categoryInformation.graveDetails || ''}
          onChange={event => onChange('graveDetails', event.target.value)}
          inputProps={{
            'data-test-id': 'graveDetails',
          }}
          disabled={disabled}
          fullWidth
        />
      </div>
      <div className={rcStyles.col1}>
        <TextField
          label={t('Deeds/Certificate number')}
          value={categoryInformation.deedsCertificateNumber || ''}
          onChange={event => onChange('deedsCertificateNumber', event.target.value)}
          inputProps={{
            'data-test-id': 'deedsCertificateNumber',
          }}
          disabled={disabled}
          fullWidth
        />
      </div>
      <div
        className={classNames(
          rcStyles.col1,
          rcStyles.colspan2,
        )}
        ref={formRefs.alternativeLocation}
      >
        <AddressFormSection
          address={categoryInformation.alternativeLocation}
          onChange={address => onChange('alternativeLocation', address)}
          data-test-id="otherDeliveryLocation"
          lookupLabel={t('Alternate location')}
          errors={errors.alternativeLocation}
          disabled={disabled}
        />
      </div>
      <div ref={formRefs.familyToBackfill}>
        <FormControlLabel
          control={(
            <Checkbox
              checked={categoryInformation.familyToBackfill || false}
              onChange={event => onChange('familyToBackfill', event.target.checked)}
              inputProps={{ 'data-test-id': 'familyToBackfill' }}
              disabled={disabled}
              color="primary"
            />
          )}
          label={t('Family to backfill')}
        />
      </div>
      <div ref={formRefs.familyToLowerCoffin}>
        <FormControlLabel
          control={(
            <Checkbox
              checked={categoryInformation.familyToLowerCoffin || false}
              onChange={event => onChange('familyToLowerCoffin', event.target.checked)}
              inputProps={{ 'data-test-id': 'familyToLowerCoffin' }}
              disabled={disabled}
              color="primary"
            />
          )}
          label={t('Family to lower coffin')}
        />
      </div>
      <div
        className={classNames(
          rcStyles.col1,
          rcStyles.colspan3,
        )}
        ref={formRefs.stonesToBeRemoved}
      >
        <div className={rcStyles.stones_layout}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={categoryInformation.stonesToBeRemoved || false}
                onChange={event => onChange('stonesToBeRemoved', event.target.checked)}
                inputProps={{ 'data-test-id': 'stonesToBeRemoved' }}
                disabled={disabled}
                color="primary"
              />
            )}
            label={t('Stones to be removed')}
          />
          {categoryInformation.stonesToBeRemoved && (
            <div ref={formRefs.stonesRemovalDate}>
              <DatePicker
                value={categoryInformation.stonesRemovalDate || null}
                label={t('Stones removal date')}
                onChange={date => onChange('stonesRemovalDate', date)}
                isClearable
                disabled={disabled}
                errors={errors && !!errors.stonesRemovalDate}
                helperText={errors && errors.stonesRemovalDate}
                data-test-id="stonesRemovalDate"
              />
            </div>
          )}
        </div>
      </div>
      <div
        className={classNames(
          rcStyles.col1,
          rcStyles.colspan2,
          styles.c_confirmed__checkbox,
        )}
        ref={formRefs.isConfirmed}
      >
        <FormControlLabel
          control={(
            <Checkbox
              checked={categoryInformation.isConfirmed || false}
              onChange={event => onChange('isConfirmed', event.target.checked)}
              inputProps={{ 'data-test-id': 'isConfirmed' }}
              disabled={disabled}
              color="primary"
            />
          )}
          label={t('Booking confirmed with venue')}
        />
      </div>
      <span className={rcStyles.col1}>
        <Button
          onClick={onSave}
          color="primary"
          variant="contained"
          disabled={disabled}
          data-test-id="saveBurialCategory"
        >
          {t('Save')}
        </Button>
      </span>
      {isSaving && (
        <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
          <IconLoad title={t('Saving')} />
        </div>
      )}
    </div>
  );
};

ArrangementBurialForm.propTypes = {
  arrangement: arrangementType.isRequired,
  categoryInformation: PropTypes.objectOf(PropTypes.any).isRequired,
  disabled: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onNumericChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ArrangementBurialForm;
