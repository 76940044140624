import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from 'components/common/Select';
import IconLoad from 'icons/IconLoad';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const EmailSupport = ({
  toggleSlideInWindow,
  switchWindow,
  onInput,
  formData,
  submissionComplete,
  onSubmit,
  cancelSubmit,
  removeConfirmation,
  isSending,
  hasMutationError,
  formRefs,
  errors,
}) => (
  <div
    className={classNames(
      styles.js_page,
      styles.c_page__full_height,
      styles.has_white_background,
    )}
  >
    <div className={classNames(
      styles.c_drawer__nav,
    )}
    >
      <IconButton
        className={classNames(
          styles.o_button__white,
          styles.c_drawer__back_btn,
        )}
        color="secondary"
        size="small"
        variant="contained"
        onClick={() => {
          switchWindow();
          removeConfirmation();
        }}
        data-test-id="removeConfirmation"
      >
        <ChevronLeft />
      </IconButton>
      <IconButton
        className={styles.o_button__white}
        color="secondary"
        size="small"
        variant="contained"
        aria-label="Close"
        onClick={toggleSlideInWindow}
        data-test-id="close"
      >
        <CloseIcon />
      </IconButton>
    </div>
    <div className={classNames(
      styles.c_drawer__main,
      styles.u_island,
    )}
    >
      {submissionComplete ? (
        <div className={classNames(
          rcStyles.v_spacing,
          rcStyles.fade_in_md,
        )}
        >
          <h2 className={classNames(
            styles.long_primer,
            styles.u_push__bottom,
          )}
          >
            {!hasMutationError ? (
              t('Thank you, your request has been sent')
            ) : (
              t('It looks like there has been an error sending your request. Please try again, or you can call us directly.')
            )}
          </h2>
          <div className={styles.primer}>
            {t('One of our friendly team members will get in touch with you soon. We aim to respond to your query within 2 working days.')}
          </div>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              switchWindow();
              removeConfirmation();
            }}
            data-test-id="backToHelp"
          >
            {t('Back to help & support')}
          </Button>
        </div>
      ) : (
        <div className={rcStyles.v_spacing}>
          <h2 className={styles.long_primer}>
            {t('Send us a message')}
          </h2>
          <InputLabel htmlFor="contact_reason">{t('Reason for enquiry')}</InputLabel>
          <Select
            onChange={e => onInput('contactReason', e.target.value)}
            inputProps={{
              'data-test-id': 'emailSupport',
              'id': 'contact_reason',
            }}
            fullWidth
            disabled={isSending}
            value={formData.contactReason}
            error={errors && !!errors.contactReason}
            errorText={errors && errors.contactReason}
          >
            <MenuItem value="ACCOUNT_QUERY">Account Query</MenuItem>
            <MenuItem value="TECHNICAL_ISSUE">Technical Support</MenuItem>
            <MenuItem value="HELP_AND_SUPPORT">Help & Support</MenuItem>
          </Select>
          <div ref={formRefs.contactMessage}>
            <TextField
              value={formData.contactMessage}
              onChange={e => onInput('contactMessage', e.target.value)}
              fullWidth
              multiline
              rows={3}
              rowsMax={3}
              disabled={isSending}
              inputProps={{ 'data-test-id': 'emailSupport' }}
              label={t('How can we help?')}
              error={errors && !!errors.contactMessage}
              helperText={errors && errors.contactMessage}
            />
          </div>
          <div className={rcStyles.actions}>
            <Button
              color="primary"
              variant="outlined"
              onClick={cancelSubmit}
              data-test-id="cancelSubmit"
            >
              {t('Cancel')}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={onSubmit}
              data-test-id="sendMessage"
            >
              {t('Send Message')}
            </Button>
          </div>
        </div>
      )}
      {isSending && (
        <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
          <IconLoad />
        </div>
      )}
    </div>
  </div>
);

EmailSupport.propTypes = {
  isSending: PropTypes.bool.isRequired,
  toggleSlideInWindow: PropTypes.func.isRequired,
  switchWindow: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  cancelSubmit: PropTypes.func.isRequired,
  onInput: PropTypes.func.isRequired,
  removeConfirmation: PropTypes.func.isRequired,
  submissionComplete: PropTypes.bool.isRequired,
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
  hasMutationError: PropTypes.bool.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default EmailSupport;
