import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { debounce } from 'debounce';
import PropTypes from 'prop-types';
import { fetchStaffMembersAction, updateFiltersAction } from 'actions/staffMembers';
import { staffMemberType } from 'types/staffMember';
import AdminStaffMembersListScreen from './AdminStaffMembersListScreen';

class AdminStaffMembersListScreenContainer extends Component {
  debounceGetStaffMembers = debounce((filters) => {
    this.getStaffMembers(filters);
  }, 500);

  static propTypes = {
    staffMembers: PropTypes.arrayOf(staffMemberType).isRequired,
    pagination: PropTypes.objectOf(PropTypes.any),
    filters: PropTypes.objectOf(PropTypes.any),
    isLoading: PropTypes.bool.isRequired,
    fetchStaffMembers: PropTypes.func.isRequired,
    updateFilters: PropTypes.func.isRequired,
    match: PropTypes.objectOf(PropTypes.any),
  }

  componentDidMount() {
    const { staffMembers } = this.props;

    if (!staffMembers || staffMembers.length === 0) {
      this.getStaffMembers();
    }
  }

  getStaffMembers = (filters) => {
    const { fetchStaffMembers } = this.props;
    fetchStaffMembers(filters);
  }

  handleChangeFilters = (key, value) => {
    const { filters, updateFilters } = this.props;

    if (value === filters[key]) {
      return;
    }
    updateFilters(key, value);
    this.debounceGetStaffMembers({ ...filters, [key]: value });
  }

  render() {
    const {
      match,
      staffMembers,
      pagination,
      filters,
      isLoading,
    } = this.props;

    return (
      <AdminStaffMembersListScreen
        staffMembers={staffMembers}
        hasMoreStaffMembers={staffMembers.length > 0 && pagination.hasNextPage}
        getStaffMembers={this.getStaffMembers}
        isLoading={isLoading}
        match={match}
        filters={filters}
        onChangeFilters={this.handleChangeFilters}
      />
    );
  }
}

const mapStateToProps = state => ({
  staffMembers: state.staffMembersStore.staffMembers,
  pagination: state.staffMembersStore.pagination,
  filters: state.staffMembersStore.filters,
  isLoading: state.staffMembersStore.isLoading,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchStaffMembers: fetchStaffMembersAction,
  updateFilters: updateFiltersAction,
}, dispatch);

export default withApollo(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AdminStaffMembersListScreenContainer),
  ),
);
