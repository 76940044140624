import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { historyType } from 'types/reactRouter';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVerticalIcon from '@material-ui/icons/MoreVert';
import { getUriForMediaByVariant } from 'services/utils';
import { statusActions } from 'constants/adminCatalogue';
import { catalogueServiceCategories, catalogueProductCategories, statuses } from 'constants/catalogue';
import { mediaVariants } from 'constants/media';

import { getIconForCategory } from 'services/utils/icon';
import Price from 'components/common/Price';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const AdminCatalogueListItem = ({
  item,
  history,
  anchorElement,
  onMenuOpen,
  onMenuClose,
  onStatusChange,
  isServiceCategory,
}) => {
  const categoryName = [
    ...catalogueServiceCategories,
    ...catalogueProductCategories,
  ].find(category => category.category === item.category).name;

  const price = item.variants && item.variants[0].prices.sale;
  const imageUri = item.images
    && getUriForMediaByVariant(item.images[0], mediaVariants.FULL_FIT_60_X_60);

  return (
    <div className={classNames(
      rcStyles.list_item,
      item.isNewItem ? classNames(
        rcStyles.is_pending,
      ) : '',
    )}
    >
      <div className={rcStyles.media}>
        {imageUri ? (
          <Avatar className={rcStyles.is_image}>
            <img alt={item.title} src={imageUri} />
          </Avatar>
        ) : (
          <div className={rcStyles.is_placeholder}>
            {getIconForCategory(item.category)}
          </div>
        )}
      </div>
      <div className={rcStyles.main}>
        <h3 className={rcStyles.is_title}>{item.title}</h3>
      </div>
      <div className={rcStyles.price}>
        <span className={styles.brevier}>{categoryName}</span>
        <Price price={price} />
      </div>
      <div className={rcStyles.is_action}>
        <div className={rcStyles.rc_edit_btn}>
          <IconButton
            aria-controls="edit-menu"
            aria-haspopup="true"
            data-test-id="moreOptions"
            onClick={onMenuOpen}
          >
            <MoreVerticalIcon />
          </IconButton>
        </div>

        <Menu
          anchorEl={anchorElement}
          keepMounted
          open={!!anchorElement}
          onClose={onMenuClose}
        >
          <MenuItem
            data-test-id="edit"
            onClick={() => history.push(`/catalogue/${isServiceCategory ? 'service' : 'product'}/edit/${item.id}`)}
          >
            {t('Edit')}
          </MenuItem>
          {(item.status === statuses.RETIRED || item.status === statuses.DRAFT) && (
            <MenuItem
              onClick={() => {
                onStatusChange(item, statusActions.PUBLISH);
                onMenuClose();
              }}
              data-test-id="publish"
            >
              {t('Publish')}
            </MenuItem>
          )}
          {item.status === statuses.DRAFT && (
            <MenuItem
              onClick={() => onStatusChange(item, statusActions.DELETE)}
              data-test-id="delete"
            >
              {t('Delete')}
            </MenuItem>
          )}
          {item.status === statuses.PUBLISHED && (
            <MenuItem
              onClick={() => onStatusChange(item, statusActions.RETIRE)}
              data-test-id="retire"
            >
              {t('Retire')}
            </MenuItem>
          )}
        </Menu>
      </div>
    </div>
  );
};

AdminCatalogueListItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  history: historyType,
  anchorElement: PropTypes.objectOf(PropTypes.any),
  onMenuOpen: PropTypes.func.isRequired,
  onMenuClose: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func,
  isServiceCategory: PropTypes.bool,
};

export default AdminCatalogueListItem;
