import React from 'react';
import PropTypes from 'prop-types';
import { getAccountContext } from './AccountContext';

const AccountProvider = ({
  isAccountOpen, hasWritePermissions, hasXeroIntegration, children,
}) => {
  const AccountContext = getAccountContext();
  return (
    <AccountContext.Provider value={{ isAccountOpen, hasWritePermissions, hasXeroIntegration }}>
      {children}
    </AccountContext.Provider>
  );
};

AccountProvider.propTypes = {
  isAccountOpen: PropTypes.bool.isRequired,
  hasWritePermissions: PropTypes.bool.isRequired,
  hasXeroIntegration: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default AccountProvider;
