import { discountTypes } from 'constants/arrangement';
import {
  confirmArrangementMutation,
  unconfirmArrangementMutation,
  setOrderOfEstimateSelectionsMutation,
} from './arrangement.mutations.gql';

export const arrangementActions = {
  ADD_PRODUCT_SELECTION: 'ARRANGEMENT_ADD_PRODUCT_SELECTION',
  ADD_SERVICE_SELECTION: 'ARRANGEMENT_ADD_SERVICE_SELECTION',
  REMOVE_PRODUCT_SELECTION: 'ARRANGEMENT_REMOVE_PRODUCT_SELECTION',
  REMOVE_SERVICE_SELECTION: 'ARRANGEMENT_REMOVE_SERVICE_SELECTION',
  SET_CATEGORY_INFORMATION: 'ARRANGEMENT_SET_CATEGORY_INFORMATION',
  EDIT_PACKAGE_SELECTION: 'ARRANGEMENT_EDIT_PACKAGE_SELECTION',
  SELECT_DISCOUNT_TYPE: 'ARRANGEMENT_SELECT_DISCOUNT_TYPE',
  SET_REQUESTED_DEPOSIT: 'SET_REQUESTED_DEPOSIT',
  SET_GLOBAL_DISCOUNT: 'ARRANGEMENT_SET_GLOBAL_DISCOUNT',
  CONFIRM_ARRANGEMENT: 'ARRANGEMENT_CONFIRM_ARRANGEMENT',
  UNCONFIRM_ARRANGEMENT: 'ARRANGEMENT_UNCONFIRM_ARRANGEMENT',
  SELECT_PACKAGE: 'ARRANGEMENT_SELECT_PACKAGE',
  UNSELECT_PACKAGE: 'ARRANGEMENT_UNSELECT_PACKAGE',
  EDIT_PRODUCT_SELECTION: 'ARRANGEMENT_EDIT_PRODUCT_SELECTION',
  EDIT_SERVICE_SELECTION: 'ARRANGEMENT_EDIT_SERVICE_SELECTION',
  RESET_OVERRIDE_PRICES: 'ARRANGEMENT_RESET_OVERRIDE_PRICES',
  SET_ORDER_OF_ESTIMATE_SELECTIONS: 'ARRANGEMENT_SET_ORDER_OF_ESTIMATE_SELECTIONS',
  CLEAR_ORDER_OF_ESTIMATE_SELECTIONS: 'ARRANGEMENT_CLEAR_ORDER_OF_ESTIMATE_SELECTIONS',
};

export const setEstimateSelectionsOrderAction = (
  bereavementId,
  arrangementId,
  selectionIds,
) => (
  {
    type: arrangementActions.SET_ORDER_OF_ESTIMATE_SELECTIONS,
    payload: {
      bereavementId,
      arrangementId,
      selectionIds,
    },
  }
);

export const clearEstimateSelectionsOrderAction = (bereavementId, arrangementId) => ({
  type: arrangementActions.CLEAR_ORDER_OF_ESTIMATE_SELECTIONS,
  payload: { bereavementId, arrangementId },
});

export const setEstimateSelectionsOrder = (
  bereavementId,
  arrangementId,
  selectionIds,
) => (dispatch, getState, client) => {
  const input = { bereavementId, arrangementId, selectionIds };
  dispatch(setEstimateSelectionsOrderAction(bereavementId, arrangementId, selectionIds));

  client.mutate({
    mutation: setOrderOfEstimateSelectionsMutation,
    variables: { input },
  });
};

export const addProductSelectionAction = (
  bereavementId,
  arrangementId,
  selectionId,
  item,
  isPackageSelection,
  variantId,
  overridePrice,
) => (
  {
    type: arrangementActions.ADD_PRODUCT_SELECTION,
    payload: {
      bereavementId,
      arrangementId,
      selectionId,
      item,
      isPackageSelection,
      variantId,
      overridePrice,
    },
  }
);

export const addServiceSelectionAction = (
  bereavementId,
  arrangementId,
  selectionId,
  item,
  isPackageSelection,
  variantId,
  overridePrice,
) => (
  {
    type: arrangementActions.ADD_SERVICE_SELECTION,
    payload: {
      bereavementId,
      arrangementId,
      selectionId,
      item,
      isPackageSelection,
      variantId,
      overridePrice,
    },
  }
);

export const removeProductSelectionAction = (bereavementId, arrangementId, selectionId) => ({
  type: arrangementActions.REMOVE_PRODUCT_SELECTION,
  payload: { bereavementId, arrangementId, selectionId },
});

export const removeServiceSelectionAction = (bereavementId, arrangementId, selectionId) => ({
  type: arrangementActions.REMOVE_SERVICE_SELECTION,
  payload: { bereavementId, arrangementId, selectionId },
});

export const setCategoryInformationAction = (bereavementId, arrangementId, category, value) => ({
  type: arrangementActions.SET_CATEGORY_INFORMATION,
  payload: {
    bereavementId,
    arrangementId,
    category,
    value,
  },
});

export const editPackageSelectionAction = (bereavementId, arrangementId, packageProps) => ({
  type: arrangementActions.EDIT_PACKAGE_SELECTION,
  payload: { bereavementId, arrangementId, packageProps },
});

export const selectDiscountTypeAction = (bereavementId, arrangementId, discountType) => ({
  type: arrangementActions.SELECT_DISCOUNT_TYPE,
  payload: { bereavementId, arrangementId, discountType },
});

export const setRequestedDepositAction = (bereavementId, arrangementId, requestedDeposit) => ({
  type: arrangementActions.SET_REQUESTED_DEPOSIT,
  payload: { bereavementId, arrangementId, requestedDeposit },
});

export const setGlobalDiscountAction = (bereavementId, arrangementId, globalDiscount) => ({
  type: arrangementActions.SET_GLOBAL_DISCOUNT,
  payload: { bereavementId, arrangementId, globalDiscount },
});

export const selectPackageAction = (bereavementId, arrangementId, packageItem) => (dispatch) => {
  dispatch({
    type: arrangementActions.SELECT_PACKAGE,
    payload: { bereavementId, arrangementId, packageItem },
  });
  dispatch(selectDiscountTypeAction(bereavementId, arrangementId, discountTypes.NONE));
  dispatch(setGlobalDiscountAction(bereavementId, arrangementId, null));
  dispatch(clearEstimateSelectionsOrderAction(bereavementId, arrangementId));
};

export const unselectPackageAction = (bereavementId, arrangementId) => (dispatch) => {
  dispatch({
    type: arrangementActions.UNSELECT_PACKAGE,
    payload: { bereavementId, arrangementId },
  });
  dispatch(selectDiscountTypeAction(bereavementId, arrangementId, discountTypes.NONE));
  dispatch(setGlobalDiscountAction(bereavementId, arrangementId, null));
  dispatch(clearEstimateSelectionsOrderAction(bereavementId, arrangementId));
};

export const confirmArrangementAction = (bereavementId, arrangementId) => ({
  type: arrangementActions.CONFIRM_ARRANGEMENT,
  payload: { bereavementId, arrangementId },
});

export const unconfirmArrangementAction = (bereavementId, arrangementId) => ({
  type: arrangementActions.UNCONFIRM_ARRANGEMENT,
  payload: { bereavementId, arrangementId },
});

export const editProductSelectionAction = (
  bereavementId,
  arrangementId,
  selectionId,
  selectionProps,
) => ({
  type: arrangementActions.EDIT_PRODUCT_SELECTION,
  payload: {
    bereavementId,
    arrangementId,
    selectionId,
    selectionProps,
  },
});

export const editServiceSelectionAction = (
  bereavementId,
  arrangementId,
  selectionId,
  selectionProps,
  allowUnconfirmArrangement = true,
) => ({
  type: arrangementActions.EDIT_SERVICE_SELECTION,
  payload: {
    bereavementId,
    arrangementId,
    selectionId,
    selectionProps,
    allowUnconfirmArrangement,
  },
});

export const confirmArrangement = (bereavementId, arrangementId) => (dispatch, getState, client) => {
  const input = { bereavementId, arrangementId };

  dispatch(confirmArrangementAction(bereavementId, arrangementId));

  client.mutate({
    mutation: confirmArrangementMutation,
    variables: { input },
  });
};

export const unconfirmArrangement = (bereavementId, arrangementId) => (dispatch, getState, client) => {
  const input = { bereavementId, arrangementId };

  dispatch(unconfirmArrangementAction(bereavementId, arrangementId));

  client.mutate({
    mutation: unconfirmArrangementMutation,
    variables: { input },
  });
};
