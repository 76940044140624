import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import ListSubheader from '@material-ui/core/ListSubheader';
import AddIcon from '@material-ui/icons/Add';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from 'scss/main.scss';
import rcStyles from './styles.scss';


const Options = ({
  suggestions, innerRef, isFocused, onSelect,
}) => suggestions.map((suggestion, index) => (
  <MenuItem
    key={`${suggestion.key || suggestion.value}_${index}`}
    buttonRef={innerRef}
    selected={isFocused}
    component="div"
    onMouseDown={(event) => {
      if (event.button === 0) {
        onSelect(suggestion.data);
      }
    }}
    data-test-id="lookUpOption"
  >
    <div className={styles.o_txt_overflow_ellipsis}>
      {suggestion.value}
    </div>
  </MenuItem>
));

const LookUpView = ({
  onChange,
  onSelect,
  onSelectNew,
  suggestions,
  additionalLabel,
  additionalSuggestions,
  lookUpValue,
  label,
  fullWidth,
  disabled,
  classes,
  selectNewText,
  isFocused,
  onFocus,
  onBlur,
  startComponent,
  endComponent,
  error,
  helperText,
  lookUpRef,
}) => {
  const createNew = onSelectNew && (
    <MenuItem
      key="create-new"
      selected={isFocused}
      component="div"
      onMouseDown={() => onSelectNew && onSelectNew()}
      data-test-id="createNew"
    >
      <AddIcon className={rcStyles.lookupIcon} />
      <div className={classNames(
        rcStyles.rc_txtShift,
      )}
      >
        {selectNewText}
      </div>
    </MenuItem>
  );

  return (
    <FormControl
      data-test-id="lookUp"
      fullWidth={fullWidth}
    >
      <div className={rcStyles.has_icon_input}>
        {startComponent}
        <TextField
          autoComplete="off"
          onChange={(e) => { e.persist(); return onChange(e); }}
          value={lookUpValue}
          placeholder={t(label)}
          label={t(label)}
          onFocus={onFocus}
          onBlur={onBlur}
          InputLabelProps={{
            FormLabelClasses: {
              root: rcStyles.lookupLabel,
              focused: rcStyles.lookupLabelFocused,
              filled: rcStyles.lookupLabelShurnk,
            },
          }}
          InputProps={{
            'classes': classes,
            'inputRef': lookUpRef,
            'inputProps': {
              'data-test-id': 'address-lookup',
              'tabIndex': '0',
            },
          }}
          error={error}
          helperText={helperText}
          fullWidth={fullWidth}
          disabled={disabled}
        />
        {isFocused && (
          <div className={rcStyles.rc_options} data-test-id="lookUpOptions">
            {createNew}
            <Options suggestions={suggestions} onSelect={onSelect} />
            {additionalLabel && additionalSuggestions && additionalSuggestions.length > 0 && (
              <ListSubheader>
                <span
                  className={classNames(
                    styles.primer,
                    styles.is_bold,
                    styles.is_primary,
                  )}
                >
                  {additionalLabel}
                </span>
              </ListSubheader>
            )}
            {additionalSuggestions && (<Options suggestions={additionalSuggestions} onSelect={onSelect} />)}
          </div>
        )}
        <div className={rcStyles.lookupEditIcon}>
          {endComponent}
        </div>
      </div>
    </FormControl>
  );
};

LookUpView.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.objectOf(PropTypes.any),
  classes: PropTypes.objectOf(PropTypes.any),
  lookUpValue: PropTypes.string.isRequired,
  selectNewText: PropTypes.string.isRequired,
  isFocused: PropTypes.bool.isRequired,
  startComponent: PropTypes.objectOf(PropTypes.any),
  endComponent: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSelectNew: PropTypes.func,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  additionalLabel: PropTypes.string,
  additionalSuggestions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    data: PropTypes.any,
  })),
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  lookUpRef: PropTypes.objectOf(PropTypes.any),
};

LookUpView.defaultProps = {
  classes: {},
};

export default LookUpView;
