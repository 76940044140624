import { string, object } from 'yup';

export const validationSchema = object().shape({
  rightOfBurialNumber: string().nullable(),
  graveNumber: string().nullable(),
  section: string().nullable(),
  dateReceived: string().nullable(),
  cemeteryName: string().nullable().required('Cemetery name is required'),
  notes: string().nullable(),
});
