import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import { reactRefType } from 'types/common';
import CurrencyField from 'components/common/CurrencyField';

import styles from 'scss/main.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const AddCustomItemModal = ({
  formData,
  isOpen,
  hasXeroIntegration,
  onCancel,
  onSave,
  onChange,
  dialogRef,
  formRefs,
  errors,
}) => (
  <Dialog
    onClose={onCancel}
    aria-labelledby="add-custom-charge-title"
    open={isOpen}
    fullWidth
    maxWidth="sm"
    TransitionComponent={Transition}
    disableBackdropClick
    disableEscapeKeyDown
  >
    <div ref={dialogRef} className={styles.c_dialog__body}>
      <DialogTitle disableTypography className={styles.c_dialog__header}>
        <div className={styles.c_dialog__title}>
          {t('Add / edit custom item')}
        </div>
      </DialogTitle>
      <DialogContent className={styles.c_dialog__content}>
        <div ref={formRefs.title}>
          <TextField
            label={t('Item Title')}
            margin="normal"
            value={formData.title || ''}
            onChange={event => onChange('title', event.target.value)}
            inputProps={{
              'data-test-id': 'title',
            }}
            error={errors && !!errors.title}
            helperText={errors && errors.title}
            fullWidth
          />
        </div>
        <div ref={formRefs.description}>
          <TextField
            label={t('Item Description')}
            margin="normal"
            value={formData.description || ''}
            onChange={event => onChange('description', event.target.value)}
            inputProps={{
              'data-test-id': 'description',
            }}
            error={errors && !!errors.description}
            helperText={errors && errors.description}
            fullWidth
          />
        </div>
        {hasXeroIntegration && (
          <div ref={formRefs.nominalCode}>
            <TextField
              label={t('Nominal code')}
              margin="normal"
              value={formData.nominalCode || ''}
              onChange={event => onChange('nominalCode', event.target.value)}
              inputProps={{
                'data-test-id': 'nominalCode',
              }}
              error={errors && !!errors.nominalCode}
              helperText={errors && errors.nominalCode}
              fullWidth
            />
          </div>
        )}
        <div ref={formRefs.amount}>
          <CurrencyField
            label={t('Item price')}
            margin="normal"
            value={formData.amount}
            min={0}
            InputProps={{
              inputProps: {
                'data-test-id': 'amount',
              },
            }}
            onChangeAmount={value => onChange('amount', value)}
            error={errors && !!errors.amount}
            helperText={errors && errors.amount}
            fullWidth
          />
        </div>
        <FormControlLabel
          control={(
            <Checkbox
              onChange={event => onChange('taxBand', event.target.checked ? 'FULL' : 'ZERO')}
              inputProps={{ 'data-test-id': 'taxBand' }}
              color="primary"
              classes={{ root: styles.u_hard__left }}
            />
          )}
          label={t('Sale price includes tax')}
        />
        <FormControlLabel
          control={(
            <Checkbox
              onChange={event => onChange('isDisbursement', event.target.checked)}
              inputProps={{ 'data-test-id': 'isDisbursement' }}
              color="primary"
              classes={{ root: styles.u_hard__left }}
            />
          )}
          label={t("Add as 'Disbursement'")}
        />
      </DialogContent>
      <DialogActions className={styles.c_dialog__actions}>
        <Button
          onClick={onCancel}
          variant="outlined"
          color="primary"
          data-test-id="cancelItem"
          fullWidth
        >
          {t('Cancel')}
        </Button>
        <Button
          onClick={onSave}
          variant="contained"
          color="primary"
          data-test-id="saveItem"
          fullWidth
        >
          {t('Save')}
        </Button>
      </DialogActions>
    </div>
  </Dialog>
);

AddCustomItemModal.propTypes = {
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
  isOpen: PropTypes.bool.isRequired,
  hasXeroIntegration: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  dialogRef: reactRefType.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AddCustomItemModal;
