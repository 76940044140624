import { isNullOrUndefined } from 'services/utils';
import {
  string, object, number, boolean,
} from 'yup';

export const validationSchema = object().shape({
  startDateTime: string().nullable().test('testEndDateIsNotNull', 'Start date & time is required', function testEndDateIsNotNull(value) {
    const { endDateTime } = this.parent;
    if (!isNullOrUndefined(endDateTime) && isNullOrUndefined(value)) {
      return false;
    }
    return true;
  }),
  endDateTime: string().nullable().test('testStartDateIsNotNull', 'End date & time is required', function testStartDateIsNotNull(value) {
    const { startDateTime } = this.parent;
    if (!isNullOrUndefined(startDateTime) && isNullOrUndefined(value)) {
      return false;
    }
    return true;
  }),
  funeralHomeBearerQuantity: number().nullable()
    .min(0, 'The number cannot be negative'),
  familyBearerQuantity: number().nullable()
    .min(0, 'The number cannot be negative'),
  reservedSeatingQuantity: number().nullable()
    .min(0, 'The number cannot be negative'),
  isExistingGrave: boolean().nullable(),
  existingGraveNumber: string().nullable(),
  deedsCertificateNumber: string().nullable(),
  graveDetails: string().nullable(),
  graveSize: string().nullable(),
  currentOccupancy: number().nullable(),
  isConfirmed: boolean().nullable(),
  graveType: string().nullable(),
  alternativeLocation: object().nullable().shape({
    addressLine1: string().nullable().required('First line address of address is required'),
    addressLine2: string().nullable(),
    town: string().nullable().required('Town is required'),
    county: string().nullable(),
    postCode: string().nullable().required('Post Code is required'),
    countryCode: string().nullable().required('Country is required'),
  }),
});
