import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  confirmArrangement as confirmArrangementAction,
  unconfirmArrangement as unconfirmArrangementAction,
} from 'actions/arrangement';
import { getAvailableNavigation } from 'services/utils/navigation';
import { arrangementType, deceasedPersonType } from 'types/bereavement';
import { historyType, matchType } from 'types/reactRouter';

import ArrangementList from './ArrangementList';

class ArrangementListContainer extends Component {
  static propTypes = {
    bereavementId: PropTypes.string.isRequired,
    arrangements: PropTypes.arrayOf(arrangementType).isRequired,
    deceasedPerson: deceasedPersonType.isRequired,
    advisoryCommittalType: PropTypes.string,
    disabled: PropTypes.bool,
    confirmArrangement: PropTypes.func.isRequired,
    unconfirmArrangement: PropTypes.func.isRequired,
    history: historyType.isRequired,
    match: matchType.isRequired,
    availableFeatures: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    tenantCategories: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    arrangementCategoryDisplayPreferences: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      arrangementCollapse: props.arrangements.map(() => false),
    };
  }

  confirmArrangement = (arrangementId) => {
    const { bereavementId, confirmArrangement } = this.props;
    confirmArrangement(bereavementId, arrangementId);
  }

  unconfirmArrangement = (arrangementId) => {
    const { bereavementId, unconfirmArrangement } = this.props;
    unconfirmArrangement(bereavementId, arrangementId);
  }

  viewArrangement = (id) => {
    const {
      arrangementCategoryDisplayPreferences,
      arrangements,
      availableFeatures,
      history,
      match,
      tenantCategories,
    } = this.props;

    const committalType = arrangements.find(arrangement => arrangement?.id === id)?.committalType;
    const navigationPaths = getAvailableNavigation(
      availableFeatures,
      tenantCategories,
      arrangementCategoryDisplayPreferences,
      committalType,
    );

    history.push(`${match.url}/${id}/${navigationPaths[0].path}`);
  }

  moveConfirmedArrangementToBeginning = () => {
    const { arrangements } = this.props;
    const confirmedArrangement = arrangements.find(arrangement => arrangement.isConfirmed);
    const sortedArrangements = arrangements.filter(arrangement => !arrangement.isConfirmed);
    if (confirmedArrangement) {
      sortedArrangements.unshift(confirmedArrangement);
      return sortedArrangements;
    }
    return arrangements;
  }

  toggleArrangementCollapse = (indexToToggle) => {
    this.setState(prevState => ({
      arrangementCollapse: prevState.arrangementCollapse.map((isCollapsed, index) => {
        if (indexToToggle === index) {
          return !isCollapsed;
        }
        return isCollapsed;
      }),
    }));
  }

  render() {
    const { arrangementCollapse } = this.state;

    return (
      <ArrangementList
        {...this.props}
        arrangements={this.moveConfirmedArrangementToBeginning()}
        arrangementCollapse={arrangementCollapse}
        viewArrangement={this.viewArrangement}
        addArrangement={this.addArrangement}
        confirmArrangement={this.confirmArrangement}
        unconfirmArrangement={this.unconfirmArrangement}
        toggleArrangementCollapse={this.toggleArrangementCollapse}
      />
    );
  }
}

const mapStateToProps = state => ({
  availableFeatures: state.userStore.user.tenantFeatureFlags,
  tenantCategories: state.userStore.user.tenantCategories,
  arrangementCategoryDisplayPreferences: state.userStore.user.arrangementCategoryDisplayPreferences,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  confirmArrangement: confirmArrangementAction,
  unconfirmArrangement: unconfirmArrangementAction,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ArrangementListContainer));
