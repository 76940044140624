import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import MenuItem from '@material-ui/core/MenuItem';
import Select from 'components/common/Select';
import { discountTypes } from 'constants/arrangement';
import { arrangementType } from 'types/bereavement';
import rcStyles from './styles.scss';

const EstimateDiscountSelector = ({
  arrangement,
  disabled,
  onSelectDiscountType,
}) => (
  <div className={rcStyles.rc_discount_select_wrapper}>
    <Select
      label={t('Discount type')}
      value={arrangement.discountType}
      onChange={event => onSelectDiscountType(event.target.value)}
      inputProps={{
        'data-test-id': 'discount-type',
      }}
      disabled={disabled}
    >
      <MenuItem value={discountTypes.NONE} data-test-id="none">{t('None')}</MenuItem>
      <MenuItem value={discountTypes.ITEMISED} data-test-id="itemised">{t('Itemised')}</MenuItem>
      <MenuItem value={discountTypes.GLOBAL} data-test-id="global">{t('Global')}</MenuItem>
    </Select>
  </div>
);

EstimateDiscountSelector.propTypes = {
  arrangement: arrangementType.isRequired,
  disabled: PropTypes.bool.isRequired,
  onSelectDiscountType: PropTypes.func.isRequired,
};

export default EstimateDiscountSelector;
