import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { getUriForMediaByVariant, arrayMove, getFileExtension } from 'services/utils';
import { mediaType } from 'types/media';
import { apolloClientType } from 'types/apollo';
import { imagesMimeTypes, documentsMimeTypes } from 'constants/media';
import { enqueueSnackbarAction } from 'actions/snackbar';
import { getMediaUploadUrl, getMedia } from './queries.gql';
import MediaUpload from './MediaUpload';

class MediaUploadContainer extends Component {
  static propTypes = {
    'client': apolloClientType.isRequired,
    'images': PropTypes.arrayOf(mediaType),
    'data-test-id': PropTypes.string.isRequired,
    'isMulti': PropTypes.bool,
    'accept': PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    'provisionalUploadMessage': PropTypes.string,
    'acceptImages': PropTypes.bool,
    'acceptDocuments': PropTypes.bool,
    'showImagePreview': PropTypes.bool,
    'disabled': PropTypes.bool,
    'contentDisposition': PropTypes.string,
    'onUploadStart': PropTypes.func,
    'onUploadDone': PropTypes.func.isRequired,
    'onChangeImages': PropTypes.func,
    'customButton': PropTypes.node,
    'shouldDisplayConfirmation': PropTypes.bool,
    'confirmationMessage': PropTypes.string,
    'onUploadInitialized': PropTypes.func,
    'enqueueSnackbar': PropTypes.func.isRequired,
    'onDeleteSingleImage': PropTypes.func,
    'uploadButtonText': PropTypes.string,
  };

  static defaultProps = {
    contentDisposition: 'attachment',
    isMulti: false,
  };

  static getDerivedStateFromProps(nextProps) {
    const { images } = nextProps;
    return {
      displayImage: images && images.length > 0 ? getUriForMediaByVariant(images[0]) : '',
      originalImage: images && images.length > 0 ? images[0].uri : '',
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      displayImage: '',
      originalImage: '',
      isConfirmationModalOpen: false,
      filesAwaitingConfirmation: null,
      isUploading: false,
    };

    if (props.acceptImages) {
      this.mimeTypes = imagesMimeTypes;
      this.setUploadButtonText(props.isMulti ? t('Upload photo(s)') : t('Upload photo'));
    } else if (props.acceptDocuments) {
      this.mimeTypes = documentsMimeTypes;
      this.setUploadButtonText(t('Upload document'));
    } else {
      this.mimeTypes = imagesMimeTypes.concat(documentsMimeTypes);
      this.setUploadButtonText(t('Upload file'));
    }

    this.fileInputRef = React.createRef();
  }

  setUploadButtonText = (defaultText) => {
    const { uploadButtonText } = this.props;
    this.uploadButtonText = uploadButtonText || defaultText;
  }

  getTempUrl = async (file) => {
    const { client } = this.props;
    const fileHash = `${file.name}-${file.lastModified}-${file.size}`;
    return client.query({
      query: getMediaUploadUrl,
      variables: {
        fileHash,
      },
    });
  }

  uploadFile = async (url, file) => {
    const { accept, contentDisposition } = this.props;

    if (accept && !accept.includes(getFileExtension(file.name))) {
      const { enqueueSnackbar } = this.props;
      enqueueSnackbar({
        message: `${file.name} file type unsupported.`,
        options: { variant: 'error' },
      });
      return null;
    }

    const { uri } = url;

    const res = await fetch(uri, {
      method: 'PUT',
      body: file,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': `${file.type || 'image/*'}`,
        'Content-Disposition': `${contentDisposition}; filename=${file.name}`,
      },
    });
    const { response } = res;
    return response;
  }

  getMedia = async (tempUrls) => {
    const { client } = this.props;
    return client.query({
      query: getMedia,
      variables: {
        ids: tempUrls.map(tempUrl => tempUrl.id),
      },
    });
  }

  handleMediaUpload = async (fileList, hasConfirmedUpload) => {
    const {
      images,
      isMulti,
      onUploadStart,
      shouldDisplayConfirmation,
      onUploadInitialized,
      onUploadDone,
    } = this.props;
    const files = Array.from(fileList);

    if ((!files || files.length === 0) && !hasConfirmedUpload) {
      if (this.fileInputRef.current) {
        this.fileInputRef.current.value = '';
      }
      return;
    }

    if (shouldDisplayConfirmation && !hasConfirmedUpload) {
      this.setState({
        filesAwaitingConfirmation: files,
      });
      this.toggleConfirmationModal();
      return;
    }

    this.setState({ isUploading: true });

    const fileType = files[0].type;
    const hasPreviousImage = !isMulti || (images && images.length > 0);
    if (this.mimeTypes.includes(fileType) && !hasPreviousImage) {
      this.setState({
        displayImage: URL.createObjectURL(files[0]),
        originalImage: URL.createObjectURL(files[0]),
        filesAwaitingConfirmation: shouldDisplayConfirmation && !hasConfirmedUpload && files,
      });
    }

    if (onUploadInitialized) {
      onUploadInitialized();
    }

    const tempUrls = await Promise.all(
      files.map(async (currentFile) => {
        const result = await this.getTempUrl(currentFile);
        const tempUrl = result.data.mediaUploadRequest;
        if (onUploadStart) {
          onUploadStart(tempUrl.id);
        }

        await this.uploadFile(tempUrl, currentFile);
        return tempUrl;
      }),
    );
    const mediaItems = await this.getMedia(tempUrls);
    this.setState({
      isUploading: false,
      filesAwaitingConfirmation: [],
    });

    onUploadDone(mediaItems.data.media, fileList);

    if (this.fileInputRef.current) {
      this.fileInputRef.current.value = '';
    }
  };

  handleSortEnd = ({ oldIndex, newIndex }) => {
    const { images, onChangeImages } = this.props;
    const repositionedImages = arrayMove(images, oldIndex, newIndex);

    onChangeImages(repositionedImages);
  }

  handleDelete = (id) => {
    const { images, onChangeImages } = this.props;
    const updatedImages = images.filter(image => image.id !== id);

    onChangeImages(updatedImages);
  }

  handleCancelUpload = () => {
    this.setState({ filesAwaitingConfirmation: null });
    if (this.fileInputRef.current) {
      this.fileInputRef.current.value = '';
    }
    this.toggleConfirmationModal();
  }

  handleConfirm = () => {
    const { filesAwaitingConfirmation } = this.state;
    this.handleMediaUpload(filesAwaitingConfirmation, true);
    this.toggleConfirmationModal();
  }

  toggleConfirmationModal = () => {
    this.setState(prevState => ({
      isConfirmationModalOpen: !prevState.isConfirmationModalOpen,
    }));
  }

  render() {
    const {
      displayImage, originalImage, isConfirmationModalOpen, isUploading,
    } = this.state;
    const {
      images,
      isMulti,
      showImagePreview,
      disabled,
      customButton,
      accept,
      acceptImages,
      acceptDocuments,
      confirmationMessage,
      provisionalUploadMessage,
      onDeleteSingleImage,
      'data-test-id': testId,
    } = this.props;

    const isImagesOnly = (acceptImages && !acceptDocuments) || false;

    return (
      <MediaUpload
        displayImage={displayImage}
        originalImage={originalImage}
        testId={testId}
        fileInputRef={this.fileInputRef}
        provisionalUploadMessage={provisionalUploadMessage}
        thumbnailImages={images}
        isMulti={isMulti}
        uploadButtonText={this.uploadButtonText}
        accept={accept || this.mimeTypes.toString()}
        showImagePreview={showImagePreview}
        isImagesOnly={isImagesOnly}
        isUploading={isUploading}
        disabled={disabled}
        customButton={customButton}
        onMediaUpload={this.handleMediaUpload}
        onSortEnd={this.handleSortEnd}
        onDelete={this.handleDelete}
        onDeleteSingleImage={onDeleteSingleImage}
        toggleConfirmationModal={this.toggleConfirmationModal}
        isConfirmationModalOpen={isConfirmationModalOpen}
        confirmationMessage={confirmationMessage}
        onConfirm={this.handleConfirm}
        onCancel={this.handleCancelUpload}
      />
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  enqueueSnackbar: enqueueSnackbarAction,
}, dispatch);

export default withApollo(connect(null, mapDispatchToProps)(MediaUploadContainer));
