import Sync from '@material-ui/icons/Sync';
import PersonOutline from '@material-ui/icons/PersonOutline';
import PeopleOutline from '@material-ui/icons/PeopleOutline';
import Visibility from '@material-ui/icons/Visibility';
import Face from '@material-ui/icons/Face';
import Flag from '@material-ui/icons/Flag';
import IconVehicle from 'icons/IconVehicle';

export const calendarEventTypes = {
  CustomCalendarEvent: 'CustomCalendarEvent',
  TransferIntoCareBereavementCalendarEvent: 'TransferIntoCareBereavementCalendarEvent',
  ExternalTransferBereavementCalendarEvent: 'ExternalTransferBereavementCalendarEvent',
  ViewingBereavementCalendarEvent: 'ViewingBereavementCalendarEvent',
  ArrangementMeetingBereavementCalendarEvent: 'ArrangementMeetingBereavementCalendarEvent',
  CortegeBereavementCalendarEvent: 'CortegeBereavementCalendarEvent',
  ServiceBereavementCalendarEvent: 'ServiceBereavementCalendarEvent',
  ReceptionVenueBereavementCalendarEvent: 'ReceptionVenueBereavementCalendarEvent',
};

export const unconfirmedEventBackgroundColour = '#F4F3F6';
export const unconfirmedEventTextColour = '#BFBCC2';

export const calendarEventsThemeObj = {
  CustomCalendarEvent: {
    title: 'Custom event',
    icon: Flag,
    color: '#7ad0c9',
    weekViewTextColour: '#000000',
  },
  TransferIntoCareBereavementCalendarEvent: {
    title: 'Transfer Into Care',
    icon: Sync,
    color: '#ffb668',
    weekViewTextColour: '#000000',
  },
  ExternalTransferBereavementCalendarEvent: {
    title: 'External Transfer',
    icon: Sync,
    color: '#ffb668',
    weekViewTextColour: '#000000',
  },
  ViewingBereavementCalendarEvent: {
    title: 'Viewing',
    icon: Visibility,
    color: '#b1def9',
    weekViewTextColour: '#000000',
  },
  ArrangementMeetingBereavementCalendarEvent: {
    title: 'Meeting',
    icon: Face,
    color: '#4e4e72',
    weekViewTextColour: '#FFFFFF',
  },
  CortegeBereavementCalendarEvent: {
    title: 'Cortege',
    icon: IconVehicle,
    color: '#ff8787',
    textColour: '#FFFFFF',
  },
  ServiceBereavementCalendarEvent: {
    title: 'Funeral Service Details',
    icon: PersonOutline,
    color: '#c8c3e5',
    weekViewTextColour: '#000000',
  },
  ReceptionVenueBereavementCalendarEvent: {
    title: 'Reception Details',
    icon: PeopleOutline,
    color: '#c8c3e5',
    weekViewTextColour: '#000000',
  },
};

export const serviceEventTypes = {
  CREMATORIA: 'Cremation',
  CEMETERIES: 'Burial',
  SERVICE_VENUES: 'Service',
};

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
