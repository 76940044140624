import React, { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import { History, Location } from 'history';

import {
  setAnonymousUserAction,
  getHomeAction,
  getTenantAction,
} from 'actions/portal';
import PageLoader from 'components/common/PageLoader';

import { anonymousLogin } from './queries.gql';
import Styled from './index.styled';
import { Props, State, PropsWithoutApolloClient } from './index.types';

class PortalLoginScreenContainer extends Component<Props, State> {
  static redirect(location: Location, redirect: string, history: History) {
    if (redirect && location.pathname.includes('/portal')) {
      history.push(redirect);
    } else {
      history.push('/portal');
    }
  }

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    this.handleAnonymousLogin();
  }

  handleAnonymousLogin = () => {
    const {
      homeId,
      history,
      location,
      redirect,
      client,
      setAnonymousUser,
      getHome,
      getTenant,
    } = this.props;

    this.setState({ isLoading: true });

    client.query({
      query: anonymousLogin,
    }).then(({ data }) => {
      const { token } = data || {};
      if (!token) {
        return;
      }
      const { tenantId } = token;

      setAnonymousUser(token);
      getHome(homeId);
      getTenant(tenantId);

      PortalLoginScreenContainer.redirect(location, redirect, history);
    }).finally(() => {
      this.setState({
        isLoading: false,
      });
    });
  };

  render() {
    const { isLoading } = this.state;
    return (
      <Styled.PageLoaderContainer>
        <PageLoader
          isLoading={isLoading}
        />
      </Styled.PageLoaderContainer>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  setAnonymousUser: setAnonymousUserAction,
  getHome: getHomeAction,
  getTenant: getTenantAction,
}, dispatch);

export default connect(null, mapDispatchToProps)(
  withRouter(withApollo<PropsWithoutApolloClient, State>(PortalLoginScreenContainer)),
);
