import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import rcStyles from './styles.scss';

const RouteTabContainer = ({
  value,
  tabs,
  onChange,
  classes,
  testIds = {},
}) => (
  <div data-test-id="tabContainer" className={classes.root}>
    <div className={rcStyles.tab_container}>
      <Tabs
        value={value}
        onChange={onChange}
        classes={{ indicator: classes.tabs }}
        data-test-id={testIds.tabs || 'tabs'}
        className={classes.tabBar}
        variant="scrollable"
        scrollButtons="auto"
      >
        {tabs.map((tab, i) => (
          <Tab
            component={Link}
            value={tab.path}
            to={tab.path}
            className={classes.tab}
            key={`tabh-${i}`}
            label={tab.label}
            data-test-id={`${testIds.tabHeader || 'tabContainerHeader'}${i}`}
          />
        ))}
      </Tabs>
    </div>
    <Switch>
      {tabs.map((tab, i) => (
        <Route
          key={tab.path}
          path={tab.path}
          render={() => (
            <div
              key={`tabc-${i}`}
              data-test-id={`${testIds.tabContents || 'tabContainerContents'}${i}`}
            >
              <div className={classes.tabContent}>
                {tab.component}
              </div>
            </div>
          )}
        />
      ))}
    </Switch>
  </div>
);

RouteTabContainer.propTypes = {
  value: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  testIds: PropTypes.objectOf(PropTypes.any),
};

export default RouteTabContainer;
