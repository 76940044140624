export const scopes = {
  BEREAVED_READ: 'BEREAVED_READ',
  BEREAVED_WRITE: 'BEREAVED_WRITE',
  VIEW_BEREAVED_TILE: 'VIEW_BEREAVED_TILE',
  CONTACTS_READ: 'CONTACTS_READ',
  CONTACTS_WRITE: 'CONTACTS_WRITE',
  VIEW_CONTACTS_TILE: 'VIEW_CONTACTS_TILE',
  DIRECTORY_LISTING_READ: 'DIRECTORY_LISTING_READ',
  DIRECTORY_LISTING_WRITE: 'DIRECTORY_LISTING_WRITE',
  VIEW_DIRECTORY_LISTING_TILE: 'VIEW_DIRECTORY_LISTING_TILE',
  ORGANISATIONAL_UNITS_READ: 'ORGANISATIONAL_UNITS_READ',
  ORGANISATIONAL_UNITS_WRITE: 'ORGANISATIONAL_UNITS_WRITE',
  VIEW_ORGANISATIONAL_UNITS_TILE: 'VIEW_ORGANISATIONAL_UNITS_TILE',
  PACKAGES_READ: 'PACKAGES_READ',
  PACKAGES_WRITE: 'PACKAGES_WRITE',
  VIEW_PACKAGES_TILE: 'VIEW_PACKAGES_TILE',
  PRODUCTS_READ: 'PRODUCTS_READ',
  PRODUCTS_WRITE: 'PRODUCTS_WRITE',
  VIEW_PRODUCTS_TILE: 'VIEW_PRODUCTS_TILE',
  SERVICES_READ: 'SERVICES_READ',
  SERVICES_WRITE: 'SERVICES_WRITE',
  VIEW_SERVICES_TILE: 'VIEW_SERVICES_TILE',
  STAFF_READ: 'STAFF_READ',
  STAFF_WRITE: 'STAFF_WRITE',
  VIEW_STAFF_TILE: 'VIEW_STAFF_TILE',
  STAFF_ROLES_READ: 'STAFF_ROLES_READ',
  TENANTS_LIST: 'TENANTS_LIST',
  TENANTS_READ: 'TENANTS_READ',
  TENANTS_WRITE: 'TENANTS_WRITE',
  VIEW_TENANTS_TILE: 'VIEW_TENANTS_TILE',
  IDENTITY_WRITE: 'IDENTITY_WRITE',
  BEREAVEMENT_READ: 'BEREAVEMENT_READ',
  BEREAVEMENT_WRITE: 'BEREAVEMENT_WRITE',
  VIEW_BEREAVEMENTS_TILE: 'VIEW_BEREAVEMENTS_TILE',
  VIEW_LITE_ARRANGEMENTS_TILE: 'VIEW_LITE_ARRANGEMENTS_TILE',
  REPORTS_READ: 'REPORTS_READ',
  BEREAVEMENT_ACCOUNT_READ: 'BEREAVEMENT_ACCOUNT_READ',
  BEREAVEMENT_ACCOUNT_WRITE: 'BEREAVEMENT_ACCOUNT_WRITE',
  CUSTOM_TASK_WRITE: 'CUSTOM_TASK_WRITE',
  ARRANGER_TASK_READ: 'ARRANGER_TASK_READ',
  DRIVER_TASK_READ: 'DRIVER_TASK_READ',
  MORTUARY_TASK_READ: 'MORTUARY_TASK_READ',
  OPERATIONS_TASK_READ: 'OPERATIONS_TASK_READ',
  FINANCE_TASK_READ: 'FINANCE_TASK_READ',
  WORKSHOP_TASK_READ: 'WORKSHOP_TASK_READ',
  TASK_DISMISS: 'TASK_DISMISS',
  BUSINESS_INTELLIGENCE_READ: 'BUSINESS_INTELLIGENCE_READ',
};
