import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { removeSnackbarAction } from 'actions/snackbar';

class SnackbarNotifier extends Component {
  static propTypes = {
    notifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    removeSnackbar: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      displayed: [],
    };
  }

  shouldComponentUpdate = (nextProps) => {
    const { notifications } = this.props;
    const propIds = nextProps.notifications.map(notification => notification.key);
    const stateIds = notifications.map(notification => notification.key);
    return (propIds.sort().toString() !== stateIds.sort().toString());
  }

  componentDidUpdate = () => {
    const { notifications = [], enqueueSnackbar, removeSnackbar } = this.props;
    const { displayed } = this.state;

    notifications.forEach((notification) => {
      if (displayed.includes(notification.key)) {
        return;
      }
      enqueueSnackbar(notification.message, notification.options);
      this.setState({
        displayed: [
          ...displayed,
          notification.key,
        ],
      });
      removeSnackbar(notification.key);
    });
  }

  render() {
    return null;
  }
}

const mapStateToProps = store => ({
  notifications: store.snackbarStore.notifications,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  removeSnackbar: removeSnackbarAction,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withSnackbar(SnackbarNotifier));
