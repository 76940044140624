import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { deedActionLabels } from 'constants/bereavement';
import { getFormattedDate } from 'services/utils';
import { getLastDeedAction } from 'services/utils/bereavement';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const DeedCard = ({
  deed,
  disabled,
  onEdit,
}) => {
  const lastDeedAction = getLastDeedAction(deed.actions);
  return (
    <div
      className={rcStyles.deed}
      data-test-id="deedCard"
    >
      <div className={styles.o_fb}>
        <div
          data-test-id="deedCardHeader"
          className={classNames(
            styles.long_primer,
            styles.u_push__bottom,
            styles.is_black,
          )}
        >
          {t('Deed')}
        </div>
        {onEdit && (
          <div>
            <IconButton
              onClick={() => onEdit(deed)}
              color="primary"
              variant="contained"
              size="small"
              disabled={disabled}
              data-test-id="editDeed"
            >
              <EditIcon />
            </IconButton>
          </div>
        )}
      </div>
      <ul className={classNames(
        styles.o_list,
        styles.is_plain,
      )}
      >
        {deed.rightOfBurialNumber && (
          <li
            className={styles.o_list__item}
            data-test-id="deedDataRow"
          >
            <span data-test-id="deedRightOfBurialLabel">{t('Right of burial: ')}</span>
            <span data-test-id="deedRightOfBurial">{deed.rightOfBurialNumber}</span>
          </li>
        )}
        {deed.graveNumber && (
          <li
            className={styles.o_list__item}
            data-test-id="deedDataRow"
          >
            <span data-test-id="deedGraveNumberLabel">{t('Grave Number: ')}</span>
            <span data-test-id="deedGraveNumber">{deed.graveNumber}</span>
          </li>
        )}
        {deed.section && (
          <li
            className={styles.o_list__item}
            data-test-id="deedDataRow"
          >
            <span data-test-id="deedSectionLabel">{t('Section: ')}</span>
            <span data-test-id="deedSection">{deed.section}</span>
          </li>
        )}
        {deed.cemeteryName && (
          <li
            className={styles.o_list__item}
            data-test-id="deedDataRow"
          >
            <span data-test-id="deedCemeteryLabel">{t('Cemetery: ')}</span>
            <span data-test-id="deedCemetery">{deed.cemeteryName}</span>
          </li>
        )}
        {deed.dateReceived && (
          <li
            className={styles.o_list__item}
            data-test-id="deedDataRow"
          >
            <span data-test-id="deedReceivedLabel">{t('Received: ')}</span>
            <span data-test-id="deedReceived">{getFormattedDate(deed.dateReceived, 'DD/MM/YYYY')}</span>
          </li>
        )}
        {(deed.actions && !!deed.actions.length) && (
          <li
            className={styles.o_list__item}
            data-test-id="deedDataRow"
          >
            <strong>
              <span data-test-id="deedLastActionLabel">{t('Last action: ')}</span>
              <span data-test-id="deedLastAction">{lastDeedAction && deedActionLabels[lastDeedAction.action]}</span>
            </strong>
          </li>
        )}
      </ul>
    </div>
  );
};

DeedCard.propTypes = {
  deed: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
  onEdit: PropTypes.func,
};

export default DeedCard;
