import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { deedType } from 'types/bereavement';
import DeedCard from 'components/bereavement/DeedCard';

import classNames from 'classnames';
import styles from 'scss/main.scss';

const DeedsSection = ({
  deeds,
  onAdd,
  onEdit,
  disabled,
}) => (
  <div>
    <div className={classNames(styles.fb, styles.u_push__bottom)}>
      <Button
        onClick={onAdd}
        variant="outlined"
        color="primary"
        disabled={disabled}
        data-test-id="addDeed"
      >
        <AddIcon className={styles.u_push__right_half} />
        {t('Add Deed')}
      </Button>
    </div>
    {deeds && deeds.map((deed, index) => (
      <DeedCard
        key={deed.id}
        deed={deed}
        onEdit={editedDeed => onEdit(editedDeed, index)}
        disabled={disabled}
      />
    ))}
  </div>
);

DeedsSection.propTypes = {
  deeds: PropTypes.arrayOf(deedType),
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default DeedsSection;
