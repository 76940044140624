import React from 'react';
import i18next from 'i18next';

import CatalogueCategoryCard from 'components/portal/CatalogueCategoryCard';
import { headlineText, headlineBgColor, BackgroundType } from 'constants/portal';
import {
  buildAddressString, buildPhonesString, buildEmailsString,
} from 'services/utils';

import { Props } from './PortalCatalogueScreen.types';
import Styled from './PortalCatalogueScreen.styled';

const PortalCatalogueScreen: React.FC<Props> = ({
  categories, home, logoUri, url,
}: Props) => {
  const {
    name, address, phones, emails,
  } = home || {};

  return (
    <Styled.MainContainer>
      <Styled.HeadlineContainer backgroundType={BackgroundType.WILD_FLOWERS}>
        <Styled.HeadlineBlock backgroundColor={headlineBgColor}>
          {i18next.t(headlineText)}
        </Styled.HeadlineBlock>
      </Styled.HeadlineContainer>
      <Styled.Logo src={logoUri} alt={name} aria-label={name} />
      <Styled.DetailsContainer>
        <Styled.Categories>
          {categories && categories.map(category => (
            <CatalogueCategoryCard
              key={category.reference}
              category={category}
              url={url}
            />
          ))}
        </Styled.Categories>
        <Styled.Footer>
          <div>{name}</div>
          <div>
            {buildAddressString(address)}
          </div>
          <div>
            {phones && `${i18next.t('Phone')}: ${buildPhonesString([phones[0]], false)}`}
          </div>
          <div>
            {emails && `${i18next.t('Email')}: ${buildEmailsString([emails[0]])}`}
          </div>
        </Styled.Footer>
      </Styled.DetailsContainer>
    </Styled.MainContainer>
  );
};

export default PortalCatalogueScreen;
