import { string, object, array } from 'yup';
import { isAddressEmpty } from 'services/utils';

export const validationSchema = object().shape({
  name: object().shape({
    title: string().nullable(),
    givenName: string().nullable().required('Given name is required'),
    familyName: string().nullable().required('Family name is required'),
  }),
  address: object().nullable().shape({
    addressLine1: string().nullable().required('First line of address is required'),
    addressLine2: string().nullable(),
    town: string().required('Town is required'),
    county: string().nullable(),
    postCode: string().nullable().required('Post Code is required'),
    countryCode: string().nullable().required('Country is required'),
  }).required('Address is required'),
  phones: array().of(
    object().shape({
      telephone: object().shape({
        countryCode: string().required('Telephone country code is required'),
        number: string().required('Telephone number is required'),
      }),
      type: string().required('Telephone type is required'),
    }),
  ).test('bereavedTest', 'At least one contact method is required', function validateBereaved(value) {
    const { address, emails } = this.parent;
    const isAddressValid = !isAddressEmpty(address);
    if (!isAddressValid && emails.length === 0) {
      return value.length > 0;
    }
    return true;
  }),
  emails: array().of(
    string().nullable().email('Email is not valid').required('Email is required'),
  ).test('bereavedTest', 'At least one contact method is required', function validateBereaved(value) {
    const { address, phones } = this.parent;
    const isAddressValid = !isAddressEmpty(address);
    if (!isAddressValid && phones.length === 0) {
      return value.length > 0;
    }
    return true;
  }),
  note: string().nullable(),
});
