import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hasPermissions } from 'services/utils';
import { scopes as scopePermissions } from 'constants/scopes';
import { documentType, notesType } from 'types/common';
import { deceasedPersonType } from 'types/bereavement';
import NotesDocumentsCorrespondenceGroup from './NotesDocumentsCorrespondenceGroup';

class NotesDocumentsCorrespondenceGroupContainer extends Component {
  static propTypes = {
    bereavementId: PropTypes.string.isRequired,
    deceasedPeople: PropTypes.arrayOf(deceasedPersonType).isRequired,
    documents: PropTypes.arrayOf(documentType),
    notes: notesType,
    correspondenceCategory: PropTypes.string.isRequired,
    templateDefinitionsLookup: PropTypes.objectOf(PropTypes.any),
    disabled: PropTypes.bool,
    buttonClasses: PropTypes.arrayOf(PropTypes.string).isRequired,
    iconClasses: PropTypes.arrayOf(PropTypes.string).isRequired,
    customColor: PropTypes.string,
    hasFinanceOnlyNotes: PropTypes.bool.isRequired,
    onDocumentsSave: PropTypes.func.isRequired,
    onNotesSave: PropTypes.func.isRequired,
  };

  static defaultProps = {
    customColor: 'secondary',
  }

  constructor(props) {
    super(props);

    this.state = {
      isDocumentsModalOpen: false,
      isCorrespondenceModalOpen: false,
      isNotesModalOpen: false,
    };
  }

  toggleDocumentsModal = () => {
    this.setState(prevState => ({
      isDocumentsModalOpen: !prevState.isDocumentsModalOpen,
    }));
  }

  toggleCorrespondenceModal = () => {
    this.setState(prevState => ({
      isCorrespondenceModalOpen: !prevState.isCorrespondenceModalOpen,
    }));
  }

  toggleNotesModal = () => {
    this.setState(prevState => ({
      isNotesModalOpen: !prevState.isNotesModalOpen,
    }));
  }

  render() {
    const {
      isDocumentsModalOpen,
      isCorrespondenceModalOpen,
      isNotesModalOpen,
    } = this.state;

    return (
      <NotesDocumentsCorrespondenceGroup
        {...this.props}
        isDocumentsModalOpen={isDocumentsModalOpen}
        isCorrespondenceModalOpen={isCorrespondenceModalOpen}
        isNotesModalOpen={isNotesModalOpen}
        toggleDocumentsModal={this.toggleDocumentsModal}
        toggleCorrespondenceModal={this.toggleCorrespondenceModal}
        toggleNotesModal={this.toggleNotesModal}
      />
    );
  }
}

const mapStateToProps = state => ({
  hasFinanceOnlyNotes: hasPermissions(
    [scopePermissions.BEREAVEMENT_ACCOUNT_READ],
    state.userStore.user.policy.scopes,
  ),
});

export default connect(mapStateToProps)(NotesDocumentsCorrespondenceGroupContainer);
