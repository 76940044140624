import { ApolloLink } from 'apollo-link';

import { setCurrentEnvironmentAction, setIsUpdatingAction } from 'actions/user';
import { store } from 'services/state';

const LOCAL_STORAGE_LAST_PROMOTED_TIME = 'lastPromotedTime';

const checkUpdateAfterware = new ApolloLink((operation, forward) => (
  forward(operation).map((response) => {
    const { response: res } = operation.getContext();
    const { currentEnvironment } = store.getState().userStore;
    const lastPromotedTime = localStorage.getItem(LOCAL_STORAGE_LAST_PROMOTED_TIME) || null;

    if (!currentEnvironment) {
      return response;
    }

    const promotedEnvHeader = res.headers && res.headers.get('x-promoted-environment');
    if (promotedEnvHeader !== currentEnvironment) {
      store.dispatch(setCurrentEnvironmentAction(promotedEnvHeader));
    }

    const promotedTimeHeader = res.headers && res.headers.get('x-client-web-promoted-time');
    if (!!promotedTimeHeader && promotedTimeHeader !== lastPromotedTime) {
      store.dispatch(setIsUpdatingAction(true));
      localStorage.setItem(LOCAL_STORAGE_LAST_PROMOTED_TIME, promotedTimeHeader);

      setTimeout(() => {
        store.dispatch(setIsUpdatingAction(false));
        window.location.reload();
      }, 2000);
    }

    return response;
  })
));

export default checkUpdateAfterware;
