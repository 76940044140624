import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import ConfirmationModal from 'components/common/ConfirmationModal';
import { productSelectionType, serviceSelectionType } from 'types/bereavement';
import { changeConfirmedArrangementMessage } from 'constants/arrangement';

import classNames from 'classnames';
import styles from 'scss/main.scss';

import ArrangementSelectedItem from './ArrangementSelectedItem';

const ArrangementSelectedItemList = ({
  packageSelections,
  nonPackageSelections,
  type,
  disabled,
  selectionToChange,
  onRemoveSelection,
  onVariantChange,
  selectedItemRef,
  onConfirm,
  onCancel,
}) => (
  <Fragment>
    {packageSelections && packageSelections.length > 0 && (
      <Fragment>
        {nonPackageSelections && nonPackageSelections.length > 0 && (
          <p className={classNames(
            styles.primer,
            styles.is_primary,
            styles.is_bold,
            styles.u_push__bottom,
          )}
          >
            {t('Included in package')}
          </p>
        )}
        <div>
          {packageSelections.map(selection => (
            <ArrangementSelectedItem
              key={selection.id}
              id={selection.id}
              item={selection[`${type.toLowerCase()}`]}
              selectedVariantId={selection.variantId}
              isPackageSelection={selection.isPackageSelection}
              disabled={disabled}
              onVariantChange={onVariantChange}
              onRemove={onRemoveSelection}
            />
          ))}
        </div>
      </Fragment>
    )}
    {nonPackageSelections && nonPackageSelections.length > 0 && (
      <Fragment>
        {packageSelections && packageSelections.length > 0 && (
          <p className={classNames(
            styles.primer,
            styles.is_primary,
            styles.is_bold,
            styles.u_push__bottom,
          )}
          >
            {t('Additional items not included in package')}
          </p>
        )}
        <div>
          {nonPackageSelections.map(selection => (
            <ArrangementSelectedItem
              key={selection.id}
              id={selection.id}
              item={selection[`${type.toLowerCase()}`]}
              selectedVariantId={selection.variantId}
              isPackageSelection={selection.isPackageSelection}
              disabled={disabled}
              onVariantChange={onVariantChange}
              onRemove={onRemoveSelection}
              selectedItemRef={selectedItemRef}
            />
          ))}
        </div>
      </Fragment>
    )}
    <ConfirmationModal
      message={t(changeConfirmedArrangementMessage)}
      isOpen={!!selectionToChange}
      icon="warning"
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  </Fragment>
);

ArrangementSelectedItemList.propTypes = {
  packageSelections: PropTypes.arrayOf(PropTypes.oneOfType([
    productSelectionType.isRequired,
    serviceSelectionType.isRequired,
  ])),
  nonPackageSelections: PropTypes.arrayOf(PropTypes.oneOfType([
    productSelectionType.isRequired,
    serviceSelectionType.isRequired,
  ])),
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  selectionToChange: PropTypes.string,
  onRemoveSelection: PropTypes.func.isRequired,
  onVariantChange: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedItemRef: PropTypes.objectOf(PropTypes.any),
};

export default ArrangementSelectedItemList;
