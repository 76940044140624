export enum CorrespondenceCategory {
  ACCOUNT = 'ACCOUNT',
  CASE = 'CASE',
}

export enum CorrespondenceType {
  CONFIRMATION_OF_ESTIMATE_AND_SERVICE_INFO_BURIAL = 'CONFIRMATION_OF_ESTIMATE_AND_SERVICE_INFO_BURIAL',
  CONFIRMATION_OF_ESTIMATE_AND_SERVICE_INFO_CREMATION = 'CONFIRMATION_OF_ESTIMATE_AND_SERVICE_INFO_CREMATION',
  CUSTOM = 'CUSTOM',
  ESTIMATE_CONFIRMATION_TO_PROCEED = 'ESTIMATE_CONFIRMATION_TO_PROCEED',
  OBITUARY_DETAILS = 'OBITUARY_DETAILS',
  OFFICIANT_CONFIRMATION_BURIAL = 'OFFICIANT_CONFIRMATION_BURIAL',
  OFFICIANT_CONFIRMATION_CREMATION = 'OFFICIANT_CONFIRMATION_CREMATION',
  PAYMENT_OVERDUE = 'PAYMENT_OVERDUE',
  PAYMENT_RECEIPT = 'PAYMENT_RECEIPT',
  PRE_PAYMENT_IN_FULL_REQUEST = 'PRE_PAYMENT_IN_FULL_REQUEST',
  STATEMENT_OF_ACCOUNT = 'STATEMENT_OF_ACCOUNT',
  ESTIMATE_AT_NEED = 'ESTIMATE_AT_NEED',
  ESTIMATE_PRE_NEED = 'ESTIMATE_PRE_NEED',
  INVOICE = 'INVOICE',
  PROFORMA_INVOICE = 'PROFORMA_INVOICE',
}

export const AccountCorrespondenceLookup = [
  {
    title: 'Pre-payment (full)',
    category: CorrespondenceCategory.ACCOUNT,
    type: CorrespondenceType.PRE_PAYMENT_IN_FULL_REQUEST,
    key: 'prePaymentInFullRequestId',
  },
  {
    title: 'Statement of account',
    category: CorrespondenceCategory.ACCOUNT,
    type: CorrespondenceType.STATEMENT_OF_ACCOUNT,
    key: 'statementOfAccountId',
  },
  {
    title: 'Payment overdue',
    category: CorrespondenceCategory.ACCOUNT,
    type: CorrespondenceType.PAYMENT_OVERDUE,
    key: 'paymentOverdueId',
  },
  {
    title: 'Payment receipt',
    category: CorrespondenceCategory.ACCOUNT,
    type: CorrespondenceType.PAYMENT_RECEIPT,
    key: 'paymentReceiptId',
  },
  {
    title: 'Invoice',
    category: CorrespondenceCategory.ACCOUNT,
    type: CorrespondenceType.INVOICE,
    key: 'invoiceId',
  },
  {
    title: 'Proforma Invoice',
    category: CorrespondenceCategory.ACCOUNT,
    type: CorrespondenceType.PROFORMA_INVOICE,
    key: 'proformaInvoiceId',
  },
];

export const CaseCorrespondenceLookup = [
  {
    title: 'Estimate confirmation to proceed',
    category: CorrespondenceCategory.CASE,
    type: CorrespondenceType.ESTIMATE_CONFIRMATION_TO_PROCEED,
    key: 'estimateConfirmationToProceedId',
  },
  {
    title: 'Confirmation of estimate and service information for cremation',
    category: CorrespondenceCategory.CASE,
    type: CorrespondenceType.CONFIRMATION_OF_ESTIMATE_AND_SERVICE_INFO_CREMATION,
    key: 'confirmationOfEstimateAndServiceInformationCrematoriumId',
  },
  {
    title: 'Confirmation of estimate and service information for burial',
    category: CorrespondenceCategory.CASE,
    type: CorrespondenceType.CONFIRMATION_OF_ESTIMATE_AND_SERVICE_INFO_BURIAL,
    key: 'confirmationOfEstimateAndServiceInformationBurialId',
  },
  {
    title: 'Officiant confirmation for cremation',
    category: CorrespondenceCategory.CASE,
    type: CorrespondenceType.OFFICIANT_CONFIRMATION_CREMATION,
    key: 'officiantConfirmationCremationId',
  },
  {
    title: 'Officiant confirmation for burial',
    category: CorrespondenceCategory.CASE,
    type: CorrespondenceType.OFFICIANT_CONFIRMATION_BURIAL,
    key: 'officiantConfirmationBurialId',
  },
  {
    title: 'Obituary details',
    category: CorrespondenceCategory.CASE,
    type: CorrespondenceType.OBITUARY_DETAILS,
    key: 'obituaryDetailsId',
  },
  {
    title: 'At-need Estimate',
    category: CorrespondenceCategory.CASE,
    type: CorrespondenceType.ESTIMATE_AT_NEED,
    key: 'estimateAtNeedId',
  },
  {
    title: 'Pre-need Estimate',
    category: CorrespondenceCategory.CASE,
    type: CorrespondenceType.ESTIMATE_PRE_NEED,
    key: 'estimatePreNeedId',
  },
];
