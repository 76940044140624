import PropTypes from 'prop-types';
import { progressFieldType, detailsFieldType, categories } from 'constants/task';

export const taskFiltersType = PropTypes.shape({
  bereavement: PropTypes.objectOf(PropTypes.any),
  home: PropTypes.objectOf(PropTypes.any),
  assignee: PropTypes.objectOf(PropTypes.any),
  category: PropTypes.string,
  status: PropTypes.string,
});

export const progressFieldTypeType = PropTypes.oneOf(
  Object.values(progressFieldType),
);

export const taskProgressFieldType = PropTypes.shape({
  key: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: progressFieldTypeType.isRequired,
  value: PropTypes.any,
  required: PropTypes.bool,
});

export const detailsFieldTypeType = PropTypes.oneOf(
  Object.values(detailsFieldType),
);

export const taskDetailsFieldType = PropTypes.shape({
  key: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: detailsFieldTypeType.isRequired,
  value: PropTypes.any,
});

export const taskCategoryType = PropTypes.oneOf(
  Object.values(categories),
);

export const taskType = PropTypes.shape({
  id: PropTypes.string,
  cateory: taskCategoryType,
  progressJson: PropTypes.arrayOf(taskProgressFieldType),
  detailsJson: PropTypes.arrayOf(taskDetailsFieldType),
});
