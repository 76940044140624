import moment from 'moment';
import { accountStatuses } from 'constants/account';
import { generateHexId } from 'services/utils';
import { saveDocumentsInputTransform } from 'transforms/bereavement';
import {
  accountInvoiceTransform,
  accountInvoiceTemplateTransform,
  createCreditMutationTransform,
  createPaymentMutationTransform,
  voidInvoiceMutationTransform,
  voidInvoiceTransform,
  editCreditMutationTransform,
  editPaymentMutationTransform,
  closeEventTransform,
  reopenEventTransform,
} from 'transforms/account';
import {
  getAccountDocuments,
  getInvoicePDF,
  getInvoiceTemplatePDF,
} from './account.queries.gql';
import {
  closeAccountMutation,
  reopenAccountMutation,
  addCreditMutation,
  addPaymentMutation,
  voidInvoiceMutation,
  deleteCreditMutation,
  deletePaymentMutation,
  editCreditMutation,
  editPaymentMutation,
  markInvoiceTemplateAsSentToClientMutation,
  sendInvoiceTemplateToXeroMutation,
  saveAccountDocumentsMutation,
} from './account.mutations.gql';
import { enqueueSnackbarAction } from './snackbar';

export const accountActions = {
  UPDATE_STATUS: 'ACCOUNT_UPDATE_STATUS',
  ADD_CLOSE_EVENT: 'ACCOUNT_ADD_CLOSE_EVENT',
  ADD_REOPEN_EVENT: 'ACCOUNT_ADD_REOPEN_EVENT',
  ADD_INVOICE: 'ACCOUNT_ADD_INVOICE',
  ADD_INVOICE_TEMPLATE: 'ACCOUNT_ADD_INVOICE_TEMPLATE',
  ADD_CREDIT: 'ACCOUNT_ADD_CREDIT',
  ADD_PAYMENT: 'ACCOUNT_ADD_PAYMENT',
  ADD_CUSTOM_CHARGE: 'ACCOUNT_ADD_CUSTOM_CHARGE',
  UPDATE_INVOICE: 'ACCOUNT_UPDATE_INVOICE',
  UPDATE_INVOICE_TEMPLATE: 'ACCOUNT_UPDATE_INVOICE_TEMPLATE',
  UPDATE_CREDIT: 'ACCOUNT_UPDATE_CREDIT',
  UPDATE_PAYMENT: 'ACCOUNT_UPDATE_PAYMENT',
  UPDATE_NEXT_INVOICE_NUMBER: 'ACCOUNT_UPDATE_NEXT_INVOICE_NUMBER',
  REMOVE_PAYMENT: 'ACCOUNT_REMOVE_PAYMENT',
  REMOVE_CREDIT: 'ACCOUNT_REMOVE_CREDIT',
  SET_DOCUMENTS: 'ACCOUNT_SET_DOCUMENTS',
};

export const updateAccountStatusAction = (bereavementId, accountStatus) => (
  { type: accountActions.UPDATE_STATUS, payload: { bereavementId, accountStatus } }
);

export const addCloseEventAction = (bereavementId, closeEvent) => (
  { type: accountActions.ADD_CLOSE_EVENT, payload: { bereavementId, closeEvent } }
);

export const addReopenEventAction = (bereavementId, reopenEvent) => (
  { type: accountActions.ADD_REOPEN_EVENT, payload: { bereavementId, reopenEvent } }
);

export const addInvoiceAction = (bereavementId, invoice) => (
  { type: accountActions.ADD_INVOICE, payload: { bereavementId, invoice } }
);

export const addInvoiceTemplateAction = (bereavementId, invoiceTemplate) => (
  { type: accountActions.ADD_INVOICE_TEMPLATE, payload: { bereavementId, invoiceTemplate } }
);

export const updateInvoiceAction = (bereavementId, invoice) => (
  { type: accountActions.UPDATE_INVOICE, payload: { bereavementId, invoice } }
);

export const updateInvoiceTemplateAction = (bereavementId, invoiceTemplate) => (
  { type: accountActions.UPDATE_INVOICE_TEMPLATE, payload: { bereavementId, invoiceTemplate } }
);

export const updateNextInvoiceNumber = (bereavementId, nextInvoiceNumber) => (
  { type: accountActions.UPDATE_NEXT_INVOICE_NUMBER, payload: { bereavementId, nextInvoiceNumber } }
);

export const addPaymentAction = (bereavementId, payment) => (
  { type: accountActions.ADD_PAYMENT, payload: { bereavementId, payment } }
);

export const addCreditAction = (bereavementId, credit) => (
  { type: accountActions.ADD_CREDIT, payload: { bereavementId, credit } }
);

export const removePaymentAction = (bereavementId, paymentId, invoiceId) => (
  { type: accountActions.REMOVE_PAYMENT, payload: { bereavementId, paymentId, invoiceId } }
);

export const removeCreditAction = (bereavementId, creditId, invoiceId) => (
  { type: accountActions.REMOVE_CREDIT, payload: { bereavementId, creditId, invoiceId } }
);

export const updateCreditAction = (bereavementId, credit) => (
  { type: accountActions.UPDATE_CREDIT, payload: { bereavementId, credit } }
);

export const updatePaymentAction = (bereavementId, payment) => (
  { type: accountActions.UPDATE_PAYMENT, payload: { bereavementId, payment } }
);

export const addCustomChargeAction = (bereavementId, invoiceId, customCharge) => ({
  type: accountActions.ADD_CUSTOM_CHARGE,
  payload: {
    bereavementId, invoiceId, customCharge,
  },
});

export const setDocumentsAction = (bereavementId, documents) => ({
  type: accountActions.SET_DOCUMENTS,
  payload: { bereavementId, documents },
});

export const closeAccountAction = (bereavementId, reason) => (dispatch, getState, client) => {
  const closeEvent = closeEventTransform(
    reason,
    getState().userStore.user.staffMember.id,
  );

  dispatch(updateAccountStatusAction(bereavementId, accountStatuses.CLOSED));
  dispatch(addCloseEventAction(bereavementId, closeEvent));

  client.mutate({
    mutation: closeAccountMutation,
    variables: {
      input: {
        bereavementId,
        reason,
      },
    },
  });
};

export const reopenAccountAction = bereavementId => (dispatch, getState, client) => {
  const reopenEvent = reopenEventTransform(
    getState().userStore.user.staffMember.id,
  );

  dispatch(updateAccountStatusAction(bereavementId, accountStatuses.OPEN));
  dispatch(addReopenEventAction(bereavementId, reopenEvent));

  client.mutate({
    mutation: reopenAccountMutation,
    variables: {
      input: {
        bereavementId,
      },
    },
  });
};

export const createCustomCharge = (bereavementId, invoiceId, customCharge) => (dispatch) => {
  dispatch(addCustomChargeAction(bereavementId, invoiceId, customCharge));
};

export const createCreditAction = credit => (dispatch, getState, client) => {
  dispatch(addCreditAction(credit.bereavementId, credit));

  client.mutate({
    mutation: addCreditMutation,
    variables: { input: createCreditMutationTransform(credit) },
  });
};

export const editPaymentAction = payment => (dispatch, getState, client) => {
  dispatch(updatePaymentAction(payment.bereavementId, payment));

  client.mutate({
    mutation: editPaymentMutation,
    variables: { input: editPaymentMutationTransform(payment) },
  });
};

export const deletePaymentAction = payment => (dispatch, getState, client) => {
  dispatch(removePaymentAction(payment.bereavementId, payment.id, payment.invoiceId));

  client.mutate({
    mutation: deletePaymentMutation,
    variables: { input: { bereavementId: payment.bereavementId, paymentId: payment.id } },
  });
};

export const deleteCreditAction = credit => (dispatch, getState, client) => {
  dispatch(removeCreditAction(credit.bereavementId, credit.id, credit.invoiceId));

  client.mutate({
    mutation: deleteCreditMutation,
    variables: { input: { bereavementId: credit.bereavementId, creditId: credit.id } },
  });
};

export const editCreditAction = credit => (dispatch, getState, client) => {
  dispatch(updateCreditAction(credit.bereavementId, credit));

  client.mutate({
    mutation: editCreditMutation,
    variables: { input: editCreditMutationTransform(credit) },
  });
};

export const voidInvoiceAction = (bereavementId, invoice) => (dispatch, getState, client) => {
  dispatch(updateInvoiceAction(bereavementId, voidInvoiceTransform(invoice, new Date())));

  client.mutate({
    mutation: voidInvoiceMutation,
    variables: { input: voidInvoiceMutationTransform(bereavementId, invoice.id) },
  });
};

export const createPaymentAction = payment => (dispatch, getState, client) => {
  dispatch(addPaymentAction(payment.bereavementId, payment));

  client.mutate({
    mutation: addPaymentMutation,
    variables: { input: createPaymentMutationTransform(payment) },
  });
};

export const generateInvoiceAction = (bereavementId, invoice) => (dispatch) => {
  const formattedInvoice = accountInvoiceTransform(invoice);

  dispatch(addInvoiceAction(bereavementId, formattedInvoice));
};

export const editInvoiceAction = (bereavementId, invoice) => (dispatch) => {
  const formattedInvoice = accountInvoiceTransform(invoice);

  dispatch(updateInvoiceAction(bereavementId, formattedInvoice));
};

export const createInvoiceTemplateAction = (bereavementId, invoiceTemplate) => (dispatch) => {
  const formattedInvoiceTemplate = accountInvoiceTemplateTransform(invoiceTemplate, true);

  dispatch(addInvoiceTemplateAction(bereavementId, formattedInvoiceTemplate));
};

export const editInvoiceTemplateAction = (bereavementId, invoiceTemplate) => (dispatch) => {
  const formattedInvoice = accountInvoiceTemplateTransform(invoiceTemplate, false);

  dispatch(updateInvoiceTemplateAction(bereavementId, formattedInvoice));
};

export const markInvoiceAsHasBeenSentToClientAction = (
  bereavementId,
  invoiceTemplate,
  hasBeenSentToClient,
) => (dispatch, getState, client) => {
  dispatch(updateInvoiceTemplateAction(
    bereavementId,
    {
      ...invoiceTemplate,
      hasBeenSentToClient,
    },
  ));

  client.mutate({
    mutation: markInvoiceTemplateAsSentToClientMutation,
    variables: {
      input: {
        bereavementId,
        invoiceTemplateId: invoiceTemplate.id,
        hasBeenSentToClient,
      },
    },
  });
};

export const sendInvoiceTemplateToXeroAction = (
  bereavementId, invoiceTemplate, handleSetInvoiceTemplateSendingToXero,
) => (dispatch, getState, client) => {
  client.mutate({
    mutation: sendInvoiceTemplateToXeroMutation,
    variables: {
      input: {
        bereavementId,
        invoiceTemplateId: invoiceTemplate.id,
      },
    },
  }).then(({ error }) => {
    handleSetInvoiceTemplateSendingToXero();
    if (error) {
      dispatch(enqueueSnackbarAction({
        message: error,
        options: {
          variant: 'error',
        },
      }));
      return;
    }

    dispatch(enqueueSnackbarAction({
      message: 'Invoice template successfully sent to Xero',
      options: {
        variant: 'success',
      },
    }));

    dispatch(updateInvoiceTemplateAction(
      bereavementId,
      {
        ...invoiceTemplate,
        transferredToXeroAt: moment().format(),
      },
    ));
  });
};

export const downloadInvoiceTemplateAction = (
  bereavementId, invoiceId, callback,
) => (dispatch, getState, client) => {
  client
    .query({
      query: getInvoiceTemplatePDF,
      variables: { bereavementId, invoiceId },
    })
    .then(({ data }) => {
      const { bereavement: { account: { invoiceTemplates } } } = data;
      const { pdf } = (invoiceTemplates && invoiceTemplates[0]) || {};
      const uri = pdf?.media?.uri;
      if (uri) {
        window.open(uri, uri).focus();
      }
    })
    .finally(() => callback());
};

export const downloadInvoiceAction = (
  bereavementId, invoiceId, callback,
) => (dispatch, getState, client) => {
  client
    .query({
      query: getInvoicePDF,
      variables: { bereavementId, invoiceId },
    })
    .then(({ data }) => {
      const { bereavement: { account: { invoices } } } = data;
      const { pdf } = (invoices && invoices[0]) || {};
      const uri = pdf?.media?.uri;
      if (uri) {
        window.open(uri, uri).focus();
      }
    })
    .finally(() => callback());
};

export const getDocumentsAction = bereavementId => (dispatch, getState, client) => {
  client.query({
    query: getAccountDocuments,
    variables: {
      id: bereavementId,
    },
  }).then(({ data }) => {
    const { documents } = data.bereavement.account;
    dispatch(setDocumentsAction(bereavementId, documents));
  });
};

export const saveDocumentsAction = (bereavementId, documents) => (dispatch, getState, client) => {
  const documentsListInput = documents.map(document => ({
    id: document.id || generateHexId(),
    title: document.title,
    mediaId: document.media.id,
    media: document.media,
  }));

  dispatch(setDocumentsAction(bereavementId, documentsListInput));

  client.mutate({
    mutation: saveAccountDocumentsMutation,
    variables: {
      input: {
        bereavementId,
        documents: saveDocumentsInputTransform(documentsListInput),
      },
    },
  });
};
