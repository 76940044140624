import React, { useState } from 'react';
import i18next from 'i18next';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';

import CurrencyField from 'components/common/CurrencyField';
import Price from 'components/common/Price';
import styles from 'scss/main.scss';

import rcStyles from './styles.scss';
import { Props } from './index.types';

const FuneralSafeFinanceForm: React.FC<Props> = ({
  financeAmount, funeralTotal, errors, onFinanceAmountChange,
}: Props) => {
  const [isFinanceAmountEditable, setIsFinanceAmountEditable] = useState(false);

  return (
    <div className={rcStyles.finance_form}>
      <div className={rcStyles.finance_form__funeral_total}>
        <div>{i18next.t('Funeral total:')}</div>
        <Price
          price={funeralTotal}
          customClass={classNames(
            styles.c_price_label__regular,
            styles.is_black,
            styles.fixed_height,
            styles.no_padding,
          )}
        />
      </div>
      {isFinanceAmountEditable && (
        <div className={rcStyles.finance_form__finance_amount}>
          <CurrencyField
            label={i18next.t('Finance amount:')}
            classes={{ root: styles.c_price_label__regular }}
            value={financeAmount?.amount ?? ''}
            min={0}
            nullable
            error={!!errors?.financeAmount?.amount}
            helperText={errors?.financeAmount?.amount}
            InputProps={{
              inputProps: {
                'data-test-id': 'financeAmount',
              },
            }}
            onChangeAmount={onFinanceAmountChange}
          />
          <Button
            onClick={() => {
              setIsFinanceAmountEditable(false);
            }}
            variant="outlined"
            color="primary"
            data-test-id="update"
          >
            {i18next.t('Update')}
          </Button>
        </div>
      )}
      {!isFinanceAmountEditable && (
        <div>
          <div className={classNames(rcStyles.finance_form__finance_amount, rcStyles.read_only)}>
            <div>{i18next.t('Finance amount:')}</div>
            <Price
              price={financeAmount}
              customClass={classNames(
                styles.c_price_label,
                styles.is_black,
                styles.fixed_height,
                styles.no_padding,
              )}
            />
          </div>
          {errors?.financeAmount?.amount && (
            <FormHelperText error>{errors?.financeAmount?.amount}</FormHelperText>
          )}
        </div>
      )}
      <div className={classNames(rcStyles.change_finance__message, styles.minion)}>
        {i18next.t('You do not have to take out finance for 100% of the funeral cost.')}
      </div>
      <div className={rcStyles.change_finance__amount}>
        <Button
          color="primary"
          onClick={() => setIsFinanceAmountEditable(!isFinanceAmountEditable)}
          disabled={isFinanceAmountEditable}
          data-test-id="change-finance-amount"
        >
          {i18next.t('Change Finance Amount')}
        </Button>
      </div>
    </div>
  );
};

export default FuneralSafeFinanceForm;
