import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';

import { apolloClientType } from 'types/apollo';

import { validateCatalogueImport } from './queries.gql';
import { importCatalogueMutation } from './mutations.gql';
import ImportFromCSVModal from './ImportFromCSVModal';

class ImportFromCSVModalContainer extends Component {
  static propTypes = {
    client: apolloClientType.isRequired,
    tenantId: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      isUploading: false,
      importHasErrors: false,
      report: [],
    };
  }

  handleChangeActiveStep = (activeStep) => {
    this.setState({ activeStep });
  };

  handleUploadInitialized = () => {
    this.setState({ isUploading: true });
  };

  handleUploadDone = async ([media]) => {
    const { client, tenantId } = this.props;

    const response = await client.query({
      query: validateCatalogueImport,
      variables: {
        input: {
          tenantId,
          archiveId: media?.id,
        },
      },
    });

    const result = response?.data?.validateCatalogueImport;
    if (!result) {
      this.setState({ isUploading: false });
      this.handleChangeActiveStep(1);
      return;
    }

    const { status, importerReport } = result;

    const errorStates = ['ERROR'];
    const importHasErrors = errorStates.includes(status);
    const report = importerReport
      ?.filter(reportItem => reportItem.status === 'ERROR')
      ?.map(({ row, metadata }) => ({
        row,
        message: metadata?.find(({ key }) => key === 'message')?.value || 'Failed check',
      }));

    this.setState({
      importHasErrors,
      report,
    });

    if (!importHasErrors) {
      this.handleImport(media?.id);
    } else {
      this.setState({ isUploading: false });
      this.handleChangeActiveStep(1);
    }
  };

  handleImport = (archiveId) => {
    const { client, tenantId } = this.props;

    client.mutate({
      mutation: importCatalogueMutation,
      variables: {
        input: {
          tenantId,
          archiveId,
        },
      },
    }).finally(() => {
      this.setState({
        isUploading: false,
      });
    });

    this.setState({ isUploading: false });
    this.handleChangeActiveStep(1);
  };

  render() {
    const { isOpen, onClose } = this.props;
    const {
      activeStep,
      importHasErrors,
      isUploading,
      report,
    } = this.state;

    const disabledSteps = activeStep === 0 ? [1] : [];

    return (
      <ImportFromCSVModal
        activeStep={activeStep}
        disabledSteps={disabledSteps}
        importHasErrors={importHasErrors}
        isOpen={isOpen}
        isUploading={isUploading}
        report={report}
        onChangeActiveStep={this.handleChangeActiveStep}
        onClose={onClose}
        onUploadInitialized={this.handleUploadInitialized}
        onUploadDone={this.handleUploadDone}
      />
    );
  }
}

const mapStateToProps = state => ({
  tenantId: state.userStore.user.tenantId,
});

export default withApollo(
  connect(mapStateToProps)(ImportFromCSVModalContainer),
);
