import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Button from '@material-ui/core/Button';
import Price from 'components/common/Price';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { packageType } from 'types/bereavement';
import IconCare from 'icons/IconCare';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';
import PackageItemPopUp from './PackageItemPopUp';

const PackageItem = ({
  packageItem,
  isBespoke,
  isSelected,
  isPopUpOpen,
  disabled,
  onSelect,
  onView,
  onClose,
}) => {
  if (isBespoke) {
    return (
      <div className={styles.c_k_tile}>
        <div className={styles.is_heading}>
          <div className={rcStyles.graphic}>
            <IconCare className={rcStyles.is_icon} />
          </div>
          <h2 className={classNames(
            styles.pica,
            styles.is_black,
          )}
          >
            {t('Bespoke Funeral Service')}
          </h2>
        </div>
        <div className={rcStyles.content_block}>
          {t('Create a service personalised to your needs.')}
        </div>
        <div className={rcStyles.actions}>
          <Button
            size="large"
            variant="outlined"
            color="primary"
            onClick={onSelect}
            data-test-id="startPlanning"
          >
            {t('Create a Bespoke Funeral Service')}
          </Button>
        </div>
      </div>
    );
  }

  const { name, price, description } = packageItem;

  return (
    <div
      className={styles.c_k_tile}
      data-test-id="packageCard"
    >
      <PackageItemPopUp
        isOpen={isPopUpOpen}
        onSelectPackage={onSelect}
        onClose={onClose}
        item={packageItem}
        isSelected={isSelected}
        disabled={disabled}
      />
      <div className={classNames(
        styles.pica,
        styles.is_black,
      )}
      >
        <Price
          price={price}
          customClass={styles.c_price_label__lrg}
        />
        <div>
          {name}
        </div>
      </div>
      <div className={rcStyles.content_block}>
        <p className={styles.u_push__bottom}>
          {description}
        </p>
        <ul className={classNames(
          styles.o_list,
          styles.is_plain,
        )}
        >
          {packageItem.products && packageItem.products.map((product, index) => (
            <li
              className={styles.o_list__item}
              key={`${index}`}
            >
              <div className={styles.o_list_item__icon}>
                <ArrowRightIcon className={styles.o_icon__primary} />
              </div>
              <div className={styles.o_list_item__text}>
                {product.title}
              </div>
            </li>
          ))}
          {packageItem.services && packageItem.services.map((service, index) => (
            <li
              className={styles.o_list__item}
              key={`${index}`}
            >
              <div className={styles.o_list_item__icon}>
                <ArrowRightIcon className={styles.o_icon__primary} />
              </div>
              <div className={styles.o_list_item__text}>
                {service.title}
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className={rcStyles.actions}>
        <Button
          size="large"
          variant="outlined"
          color="primary"
          onClick={onView}
          data-test-id="packageCardViewButton"
        >
          {t('View')}
        </Button>
        <Button
          size="large"
          variant="outlined"
          color="primary"
          onClick={onSelect}
          className={isSelected ? styles.o_button__white : ''}
          disabled={disabled}
          data-test-id="packageCardSelectButton"
        >
          {t(isSelected ? 'Unselect' : 'Select')}
        </Button>
      </div>
    </div>
  );
};

PackageItem.propTypes = {
  packageItem: packageType,
  isBespoke: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isPopUpOpen: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default PackageItem;
