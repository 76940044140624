import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const IconAddPhone = (props) => {
  const { className } = props;
  const classes = className ? `${className} ${styles.rc_icon}` : styles.rc_icon;

  return (
    <svg viewBox="0 0 24 24" className={classes}>
      <path d="M17.1,17.1c-1.1,0-2.2-0.2-3.2-0.5c-0.3-0.1-0.7,0-0.9,0.2l-2,2c-2.5-1.3-4.6-3.3-5.9-5.9l2-2 c0.2-0.2,0.3-0.6,0.2-0.9C7.1,9.1,6.9,8,6.9,6.9C6.9,6.4,6.5,6,6,6H2.9C2.4,6,2,6.4,2,6.9C2,15.2,8.8,22,17.1,22 c0.5,0,0.9-0.4,0.9-0.9V18C18,17.5,17.6,17.1,17.1,17.1L17.1,17.1z" />
      <polygon points="23,4.5 19.5,4.5 19.5,1 17.5,1 17.5,4.5 14,4.5 14,6.5 17.5,6.5 17.5,10 19.5,10 19.5,6.5 23,6.5" />
    </svg>
  );
};

IconAddPhone.propTypes = {
  className: PropTypes.string,
};

export default IconAddPhone;
