import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import ScreenLayout from 'components/common/ScreenLayout';
import AdminHomeForm from 'components/admin/AdminHomeForm';
import IconLoad from 'icons/IconLoad';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import Button from '@material-ui/core/Button';

const AdminHomeScreen = ({
  home,
  isLoading,
  updateHome,
  onSave,
  onCancel,
  formRefs,
  errors,
}) => (
  <ScreenLayout
    title={t('Manage homes')}
    dashboardBannerProps={{
      breadcrumbs: [{ path: '/', title: t('Dashboard') }, { path: '/homes', title: t('Homes') }],
      description: t('Add and manage your funeral homes'),
    }}
  >
    <div className={classNames(
      styles.c_page__full_height,
      styles.has_faded_background,
    )}
    >
      {isLoading
        ? (
          <div className={styles.c_loading_container}>
            <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
              <IconLoad />
            </div>
          </div>
        )
        : (
          <Fragment>
            <div className={styles.o_view}>
              <div className={classNames(
                styles.o_view__main,
                styles.o_block_content,
              )}
              >
                <AdminHomeForm
                  home={home}
                  updateHome={updateHome}
                  formRefs={formRefs}
                  errors={errors}
                />
                <div className={styles.o_button_bar}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={onCancel}
                    data-test-id="cancelHome"
                  >
                    {t('Cancel')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onSave}
                    data-test-id="saveHome"
                  >
                    {t('Save')}
                  </Button>
                </div>
              </div>
            </div>
          </Fragment>
        )
      }
    </div>
  </ScreenLayout>
);

AdminHomeScreen.propTypes = {
  home: PropTypes.objectOf(PropTypes.any),
  isLoading: PropTypes.bool,
  updateHome: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any),
};

export default AdminHomeScreen;
