import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import Price from 'components/common/Price';
import { catalogueServiceCategories, catalogueProductCategories } from 'constants/catalogue';
import { getUriForMediaByVariant } from 'services/utils';
import { getIconForCategory } from 'services/utils/icon';
import { mediaVariants } from 'constants/media';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const PackageModalListItem = ({
  item,
  isSelected,
  onSelectCatalogueItem,
}) => {
  const categoryName = [
    ...catalogueServiceCategories,
    ...catalogueProductCategories,
  ].find(category => category.category === item.category).name;

  const price = item.variants && item.variants[0].prices.sale;
  const imageUri = item.images
    && getUriForMediaByVariant(item.images[0], mediaVariants.FIT_200_X_200);

  return (
    <Fragment>
      <div className={rcStyles.is_avatar}>
        {imageUri ? (
          <img alt={item.title} src={imageUri} />
        ) : (
          <div className={rcStyles.is_avatar_placeholder}>
            {getIconForCategory(item.category)}
          </div>
        )}
      </div>
      <h4 className={classNames(
        styles.primer,
        rcStyles.is_title,
      )}
      >
        {item.title}
      </h4>
      <div className={rcStyles.is_price}>
        <div className={styles.brevier}>{categoryName}</div>
        <Price price={price} />
      </div>
      <div className={rcStyles.is_action}>
        <Checkbox
          checked={isSelected}
          onChange={event => onSelectCatalogueItem(item.id, event.target.checked)}
          inputProps={{ 'data-test-id': 'selectCatalogueItem' }}
          color="primary"
        />
      </div>
    </Fragment>
  );
};

PackageModalListItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  isSelected: PropTypes.bool,
  onSelectCatalogueItem: PropTypes.func.isRequired,
};

export default PackageModalListItem;
