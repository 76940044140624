import {
  string, object, boolean, array,
} from 'yup';

export const validationSchema = object().shape({
  doNotPerform: boolean().nullable(),
  reasonNotToPerform: string().nullable(),
  shouldCaptureFingerPrint: boolean().nullable(),
  shouldCollectLockOfHair: boolean().nullable(),
  isFamilyToPrepareDeceased: boolean().nullable(),
  isMCCDIssued: boolean().nullable(),
  reasonForNoMCCDIssued: string().nullable(),
  MCCDNumber: string().nullable(),
  isInfectious: boolean().nullable(),
  clinicians: array().of(
    object().nullable().shape({
      name: string().nullable(),
      directoryListingId: string().nullable(),
    }),
  ),
  coroner: object().shape({
    name: string().nullable(),
    directoryId: string().nullable(),
  }).nullable(),
});
