import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { directoryListingType } from 'types/directoryListing';
import { organisationalUnitType } from 'types/organisationalUnit';
import { matchType } from 'types/reactRouter';
import AdminListItem from './AdminListItem';

class AdminListItemContainer extends Component {
  static propTypes = {
    item: PropTypes.oneOfType([directoryListingType, organisationalUnitType]).isRequired,
    match: matchType.isRequired,
    onStatusChange: PropTypes.func,
  }

  constructor(props) {
    super(props);

    this.state = {
      anchorElement: null,
    };
  }

  handleMenuOpen = (event) => {
    this.setState({ anchorElement: event.currentTarget });
  }

  handleMenuClose = () => {
    this.setState({ anchorElement: null });
  }

  render() {
    const { item, match, onStatusChange } = this.props;
    const { anchorElement } = this.state;
    return (
      <AdminListItem
        item={item}
        match={match}
        anchorElement={anchorElement}
        onMenuOpen={this.handleMenuOpen}
        onMenuClose={this.handleMenuClose}
        onStatusChange={onStatusChange}
      />
    );
  }
}

export default withRouter(AdminListItemContainer);
