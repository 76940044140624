import mapActionToReducer from 'redux-action-reducer-mapper';
import { updateArrayByIndex } from 'services/utils';
import { staffMembersActions } from 'actions/staffMembers';

export const initialState = {
  staffMembers: [],
  pagination: {
    first: 10,
    after: null,
    hasNextPage: true,
  },
  filters: {
    searchTerm: '',
    organisationalUnits: null,
  },
  isLoading: false,
  lastMutatedStaffMember: null,
};

const setStaffMembers = (state, action) => {
  const { payload: { staffMembers, pagination, filters } } = action;
  return {
    ...state,
    staffMembers: staffMembers || state.staffMembers,
    pagination: {
      ...state.pagination,
      ...pagination,
    },
    filters: {
      ...state.filters,
      ...filters,
    },
  };
};

const clearStaffMembers = state => ({
  ...state,
  staffMembers: initialState.staffMembers,
  pagination: initialState.pagination,
  lastMutatedStaffMember: initialState.lastMutatedStaffMember,
});

const addStaffMembers = (state, action) => {
  const { payload: { staffMembers, pagination } } = action;
  const { lastMutatedStaffMember } = state;
  const updatedStaffMembers = [...state.staffMembers, ...staffMembers]
    .map(staffMember => ((!!lastMutatedStaffMember && lastMutatedStaffMember.id === staffMember.id)
      ? lastMutatedStaffMember : staffMember));

  return {
    ...state,
    staffMembers: updatedStaffMembers,
    pagination: {
      ...state.pagination,
      ...pagination,
    },
    lastMutatedStaffMember: null,
  };
};

const addStaffMember = (state, action) => {
  const { payload } = action;

  const newStaffMember = {
    ...payload,
    isNew: true,
  };

  const newStaffMembers = [...state.staffMembers];
  newStaffMembers.unshift(newStaffMember);

  if (payload) {
    return {
      ...state,
      staffMembers: newStaffMembers,
      pagination: state.pagination,
      filters: state.filters,
    };
  }

  return state;
};

const updateStaffMember = (state, action) => {
  const { payload } = action;

  if (payload) {
    const staffMemberIndex = state.staffMembers
      .findIndex((staffMember => staffMember.id === payload.id));

    if (staffMemberIndex > -1) {
      return {
        ...state,
        staffMembers: updateArrayByIndex(state.staffMembers, staffMemberIndex, payload),
        pagination: state.pagination,
        filters: state.filters,
        lastMutatedStaffMember: null,
      };
    }
    return {
      ...state,
      lastMutatedStaffMember: payload,
    };
  }

  return state;
};

const removeStaffMember = (state, action) => {
  const { payload } = action;
  if (payload && payload.id) {
    const updatedStaffMembers = state.staffMembers.filter(item => (item.id !== payload.id));

    return {
      ...state,
      staffMembers: updatedStaffMembers,
      pagination: state.pagination,
      filters: state.filters,
    };
  }

  return state;
};

const updateFilters = (state, action) => {
  const { payload: { key, value } } = action;
  return {
    ...state,
    filters: {
      ...state.filters,
      [key]: value,
    },
  };
};

const setLoading = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    isLoading: payload,
  };
};

export const staffMembersReducer = mapActionToReducer({
  default: initialState,
  [staffMembersActions.SET_STAFF_MEMBERS]: setStaffMembers,
  [staffMembersActions.CLEAR_ALL]: () => initialState,
  [staffMembersActions.CLEAR_STAFF_MEMBERS]: clearStaffMembers,
  [staffMembersActions.ADD_STAFF_MEMBERS]: addStaffMembers,
  [staffMembersActions.ADD_STAFF_MEMBER]: addStaffMember,
  [staffMembersActions.UPDATE_STAFF_MEMBER]: updateStaffMember,
  [staffMembersActions.REMOVE_STAFF_MEMBER]: removeStaffMember,
  [staffMembersActions.UPDATE_FILTERS]: updateFilters,
  [staffMembersActions.SET_LOADING]: setLoading,
});
