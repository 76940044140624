import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LookUpView from './LookUp';

class LookUp extends Component {
  static propTypes = {
    lookUpValue: PropTypes.string.isRequired,
    error: PropTypes.objectOf(PropTypes.any),
    helperText: PropTypes.objectOf(PropTypes.any),
    label: PropTypes.string,
    selectNewText: PropTypes.string,
    classes: PropTypes.objectOf(PropTypes.any),
    startComponent: PropTypes.objectOf(PropTypes.any),
    endComponent: PropTypes.objectOf(PropTypes.any),
    onChange: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    onSelectNew: PropTypes.func,
    suggestions: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.any,
      data: PropTypes.any,
    })).isRequired,
    additionalLabel: PropTypes.string,
    additionalSuggestions: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.any,
      data: PropTypes.any,
    })),
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    lookUpRef: PropTypes.objectOf(PropTypes.any),
  }

  static defaultProps = {
    selectNewText: 'Create new',
    label: 'Address',
  }

  constructor(props) {
    super(props);

    this.state = {
      isFocused: false,
      hasSelected: false,
    };
  }


  handleOnBlur = () => this.setState({ isFocused: false });

  handleOnFocus = () => this.setState({ isFocused: true });

  handleOnChange = (event) => {
    const { onChange } = this.props;
    const { value } = event.target;
    this.setState({ hasSelected: false });

    return onChange(value, event.nativeEvent.data);
  }

  handleOnSelect = (selected) => {
    const { onSelect } = this.props;
    this.setState({ hasSelected: true });

    onSelect(selected);
  }

  render() {
    const { isFocused, hasSelected } = this.state;

    return (
      <LookUpView
        {...this.props}
        isFocused={isFocused}
        onChange={this.handleOnChange}
        onSelect={this.handleOnSelect}
        onBlur={this.handleOnBlur}
        onFocus={this.handleOnFocus}
        hasSelected={hasSelected}
      />
    );
  }
}

export default LookUp;
