import React, { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  getCataloguePackagesAction,
  resetCataloguePackagesAction,
} from 'actions/portal';
import { getTenantCatalogueCategories } from 'services/utils/catalogue';
import {
  CatalogueCategory,
  CatalogueItemOrder,
} from 'types/ts/catalogue';
import { RootState } from 'types/ts/state';

import { Props } from './index.types';
import PortalCataloguePackagesScreen from './PortalCataloguePackagesScreen';

class PortalCataloguePackagesScreenContainer extends Component<Props> {
  componentDidMount() {
    const { resetCataloguePackages } = this.props;

    resetCataloguePackages();
    this.getCataloguePackages();
  }

  getCategoryPathFromRouteMatch = (props: Props) => {
    const { match } = props;
    return match?.params?.category;
  };

  getCategory = (): CatalogueCategory => {
    const { tenant } = this.props;
    const {
      cataloguePdfCategoryDisplayPreferences: displayPreferences,
    } = tenant || {};

    return getTenantCatalogueCategories(displayPreferences)
      .filter((item: CatalogueCategory) => item.visible)
      .find((item: CatalogueCategory) => item.category === 'PACKAGES');
  }

  getCataloguePackages = (sortOrder?: CatalogueItemOrder) => {
    const { match, getCataloguePackages } = this.props;
    const { params: { homeId } } = match;

    getCataloguePackages(homeId, sortOrder);
  }

  handleGetCataloguePackages = () => {
    this.getCataloguePackages();
  }

  render() {
    const { cataloguePackages, home, tenant } = this.props;
    const { bereavedPortalStyles, cataloguePdfCategoryDisplayPreferences: displayPreferences } = tenant || {};
    const { bereavedPortalLogo } = bereavedPortalStyles || {};
    const category = this.getCategory();

    return (
      <PortalCataloguePackagesScreen
        category={category}
        cataloguePackages={cataloguePackages}
        displayPreferences={displayPreferences}
        home={home}
        logoUri={bereavedPortalLogo?.uri}
        getCataloguePackages={this.handleGetCataloguePackages}
      />
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  cataloguePackages: state?.portalStore?.cataloguePackages,
  home: state?.portalStore?.home,
  tenant: state?.portalStore?.tenant,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  getCataloguePackages: getCataloguePackagesAction,
  resetCataloguePackages: resetCataloguePackagesAction,
}, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PortalCataloguePackagesScreenContainer),
);
