import moment from 'moment';
import debounce from 'debounce';
import sha1 from 'sha1';
import { months } from 'constants/calendar';
import { mediaVariants } from 'constants/media';

const getRandomHex = (length) => {
  const maxLength = 8;
  const min = 16 ** (Math.min(length, maxLength) - 1);
  const max = (16 ** Math.min(length, maxLength)) - 1;
  const number = Math.floor(Math.random() * (max - min + 1)) + min;
  let character = number.toString(16);
  while (character.length < length) {
    character += getRandomHex(length - maxLength);
  }
  return character;
};

export const toProperCase = (value) => {
  const textToTransform = value;
  return textToTransform
    .replace(/[^a-zA-Z_ ]/g, '')
    .split('_')
    .reduce((accumulator, text) => {
      if (text.length > 1) {
        return accumulator.concat(text.charAt(0).toUpperCase() + text.substr(1).toLowerCase());
      }
      return accumulator.concat(text.toUpperCase());
    }, '');
};

export const toTitleCase = (value) => {
  const textToTransform = value;
  return textToTransform
    .split(/(?=[A-Z])/)
    .reduce((accumulator, text) => {
      if (text.length > 1) {
        return accumulator.concat(text.charAt(0).toUpperCase() + text.substr(1).toLowerCase());
      }
      return accumulator.concat(text.toUpperCase());
    }, '');
};

export const startCase = (text) => {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const pluralize = (key, suffix = 's') => key && `${key}${suffix}`;

export const generateHexId = () => getRandomHex(40);

export const createSha1HashFromString = stringToHash => sha1(stringToHash);

export const isValueEmptyOrFalse = (value) => {
  if (typeof value === 'string') {
    return !value.trim();
  }

  return value !== 0 && !value;
};

export const isArrayEmptyOrFalse = array => !Array.isArray(array) || !array.length;

export const isObjectEmpty = value => !value
  || Object.keys(value).length === 0
  || !Object.keys(value).some(key => !!value[key]);

export const isBodySizeEmpty = bodySize => !bodySize || isObjectEmpty(bodySize);

export const isValidToFixedDigit = fixedDigit => Number.isInteger(fixedDigit) && fixedDigit >= 0 && fixedDigit <= 100;

export const parseNumericValue = value => (!Number.isNaN(parseFloat(value))
  && (value.length > 0) ? parseFloat(value) : null);

export const isValidNumericKeyCode = (keyCode, ctrlKey, metaKey) => !!(
  (keyCode >= 48 && keyCode <= 57)
    || (keyCode >= 96 && keyCode <= 105)
    || (keyCode >= 37 && keyCode <= 40)
    || [8, 9, 13, 45, 46, 69, 109, 110, 144, 173, 189, 190].includes(keyCode)
    || ((metaKey || ctrlKey) && [65, 67, 73, 86, 88, 90].includes(keyCode))
);

export const isBodySizeValid = bodySize => !isBodySizeEmpty(bodySize)
  && Object.keys(bodySize).length === 4
  && !Object.keys(bodySize).some(key => !bodySize[key]);

export const isAddressEmpty = (address) => {
  if (!address) {
    return true;
  }

  return Object.keys(address).length === 0 || isObjectEmpty(address);
};

export const isNoteEmpty = note => !(note
  && note.content
  && note.createdBy
  && note.createdBy.name
  && note.createdDateTime);

export const objectPropertiesToString = (obj, separator, keys) => {
  if (!obj) {
    return null;
  }

  const keysToUse = keys || Object.keys(obj);

  return keysToUse
    .map(key => obj[key])
    .filter(item => item)
    .join(separator);
};

export const buildNameString = (name, includeTitle) => {
  if (!name) {
    return '';
  }

  const keysToIncludeBeforeDefaultValues = includeTitle ? ['title'] : [];
  const keysToInclude = [...keysToIncludeBeforeDefaultValues, 'givenName', 'familyName'];
  return objectPropertiesToString(name, ' ', keysToInclude);
};

export const buildAddressString = (address) => {
  if (typeof address === 'string') {
    return address;
  }
  if (isAddressEmpty(address)) {
    return '';
  }
  const keysToInclude = ['addressLine1', 'addressLine2', 'town', 'county', 'postCode'];
  return objectPropertiesToString(address, ', ', keysToInclude);
};

export const buildPhonesString = (phones, includeType = true) => {
  if (!phones) {
    return '';
  }

  return phones.reduce((acc, phone) => {
    let result = acc ? `${acc}, ${phone.telephone.number}` : phone.telephone.number;
    if (includeType && phone.type) {
      result += ` (${phone.type.substring(0, 1)}${phone.type.substring(1).toLowerCase()})`;
    }
    return result;
  }, '');
};

export const buildEmailsString = (emails) => {
  if (!emails) {
    return '';
  }
  return emails.reduce((acc, email) => `${acc}, ${email}`);
};

export const buildWebsitesString = (websites) => {
  if (!websites) {
    return '';
  }
  return websites.reduce((acc, website) => `${acc}, ${website}`);
};

export const buildDirectoryListingString = (directoryListing) => {
  if (!directoryListing || (!directoryListing.name && !directoryListing.address)) {
    return '';
  }
  return `${directoryListing.name} ${buildAddressString(directoryListing.address)}`;
};

export const buildBereavedLookupLabel = (bereavedPerson) => {
  if (bereavedPerson) {
    let label = '';
    label += buildNameString(bereavedPerson.name);
    if (!isAddressEmpty(bereavedPerson.address)) {
      label += `, ${buildAddressString(bereavedPerson.address)}`;
    }
    if (bereavedPerson.phones && bereavedPerson.phones[0]) label += `, Phone: ${bereavedPerson.phones[0].telephone.number}`;
    if (bereavedPerson.emails && bereavedPerson.emails[0]) label += `, Email: ${bereavedPerson.emails[0]}`;
    return label;
  }

  return '---';
};

export const buildBodySizeValue = (bodySize) => {
  if (!isBodySizeValid(bodySize)) {
    return '';
  }
  return `${bodySize.length}x${bodySize.width}x${bodySize.depth} ${bodySize.units}`;
};

export const removePropertyByName = (obj, key, isShallow, excludedTypenames) => {
  if (!obj) {
    return null;
  }
  const object = obj;
  Object.keys(object).forEach((property) => {
    if (!Object.prototype.hasOwnProperty.call(object, property)) {
      return;
    }
    if (object[property] && typeof (object[property]) === 'object' && !isShallow) {
      removePropertyByName(object[property], key, false, excludedTypenames);
    } else if (property === key) {
      const { __typename } = object;
      if (!excludedTypenames || (__typename && excludedTypenames && !excludedTypenames.includes(__typename))) {
        delete object[property];
      }
    }
  });
  return object;
};

export const getArrayIndexForKeyValue = (array, key, value) => {
  if (!array) {
    return -1;
  }

  return array.findIndex(object => (object[key] === value));
};

export const getArrayIndexForId = (array, id) => {
  if (!array) {
    return -1;
  }

  return array.findIndex(object => (object.id === id));
};

export const uniqueArray = (array) => {
  if (!array || array.length === 0) {
    return [];
  }
  return array.filter((value, index, self) => self.indexOf(value) === index);
};

export const uniqueArrayByIds = (array) => {
  if (!array || array.length === 0) {
    return [];
  }
  return array.filter((value, index, self) => getArrayIndexForId(self, value.id) === index);
};

export const updateArrayByIndex = (array, targetIndex, newItem) => {
  if (!array) {
    return [];
  }

  return array.map((item, index) => {
    if (index === targetIndex) {
      return newItem;
    }
    return item;
  });
};

export const isNullOrUndefined = value => (value === null || value === undefined);

export const formatCurrency = ({ amount, currency }) => new Intl.NumberFormat('en-GB', { style: 'currency', currency }).format(amount);

export const defaultMoneyObject = amount => ({ amount: amount || 0, currency: 'GBP' });

export const defaultNullableMoneyObject = amount => ({ amount: !isNullOrUndefined(amount) ? amount : null, currency: 'GBP' });

export const getUriForMediaByVariant = (mediaObject, variantName = mediaVariants.FIT_900_X_900) => {
  if (!mediaObject) {
    return '';
  }
  const variants = mediaObject.variants || [];
  const selectedVariant = variants.find(variant => variant.name === variantName);
  return selectedVariant ? selectedVariant.uri : mediaObject.uri;
};

export const getFormattedDate = (dateString, format = 'DD MMMM YYYY') => {
  const date = moment(dateString);
  return `${date.format(format)}`;
};

export const getDateFromISOString = date => date && moment(date).toDate();

export const getStartAndEndDates = (startDateString, endDateString, format = 'DD MMMM YYYY') => {
  const startDate = moment(startDateString);
  const endDate = moment(endDateString);
  return `${startDate.format(format)} - ${endDate.format(format)}`;
};

export const getMonth = date => months.findIndex(month => month === moment(date).format('MMMM'));

export const getYearRange = (start, end) => {
  const startYear = start || 1900;
  const endYear = end || new Date().getFullYear() + 1;
  return Array(endYear - startYear + 1).fill().map((_, idx) => startYear + idx);
};

export const getHourFromDate = date => (date ? moment(date).format('HH') : null);

export const getMinuteFromDate = date => (date ? moment(date).format('mm') : null);

export const isSameDate = (date1, date2) => {
  if (!date1 || !date2) {
    return false;
  }
  const startDate = moment(date1).format('YYYY-MM-DD');
  const endDate = moment(date2).format('YYYY-MM-DD');
  return moment(startDate).isSame(endDate, 'day');
};

export const getMaxDateAsDate = date => date && moment(date).second(0).millisecond(0).subtract('1', 'minute')
  .toDate();

export const getMinDateAsDate = date => date && moment(date).second(0).millisecond(0).add('1', 'minute')
  .toDate();

export const getStartDateTimeRange = (startDateTime, endDateTime) => {
  const current = endDateTime || new Date();
  const maxDateTime = (endDateTime && (!startDateTime || isSameDate(startDateTime, endDateTime)))
    ? moment(current)
      .hour(getHourFromDate(endDateTime))
      .minute(getMinuteFromDate(endDateTime))
      .second(0)
      .millisecond(0)
      .subtract('1', 'minute')
      .toDate()
    : moment(current).hour(23).minute(59).second(0)
      .millisecond(0)
      .toDate();
  const minDateTime = moment(maxDateTime).hour(0).minute(0).second(0)
    .millisecond(0)
    .toDate();
  return [minDateTime, maxDateTime];
};

export const getEndDateTimeRange = (startDateTime, endDateTime) => {
  const current = startDateTime || new Date();
  const minDateTime = (startDateTime && (!endDateTime || isSameDate(startDateTime, endDateTime)))
    ? moment(current)
      .hour(getHourFromDate(startDateTime))
      .minute(getMinuteFromDate(startDateTime))
      .second(0)
      .millisecond(0)
      .add('1', 'minute')
      .toDate()
    : moment(current).hour(0).minute(0).second(0)
      .millisecond(0)
      .toDate();
  const maxDateTime = moment(minDateTime).hour(23).minute(59).second(0)
    .millisecond(0)
    .toDate();
  return [minDateTime, maxDateTime];
};

export const debounceMutation = debounce((client, mutation, input) => {
  client.mutate({
    mutation,
    variables: { input },
  });
}, 500);

export const getInitials = (name) => {
  if (!name) {
    return '';
  }
  const { givenName, familyName } = name;
  if ((!givenName && !familyName)) {
    return '';
  }

  const initialsStr = [givenName, familyName]
    .filter(Boolean)
    .map(namePart => namePart.charAt(0).toUpperCase())
    .join('');

  return initialsStr;
};

export const hasPermissions = (requiredPermissions = [], scopes = []) => {
  const validPermissions = requiredPermissions
    .filter(permission => scopes.find(scope => scope === permission));

  return validPermissions.length === requiredPermissions.length;
};

export const getDefaultImage = item => item.images && item.images[0];

export const getCatalogueVariantImage = (item, variantId) => {
  if (item.variants && variantId) {
    const matchedVariant = item.variants.find(variant => variant.id === variantId);
    if (matchedVariant && matchedVariant.imageId && matchedVariant.image) {
      return matchedVariant.image;
    }
  }
  return getDefaultImage(item);
};

export const preloadImageVariants = (items, variants, type) => {
  if (!window.preloadedImages) {
    window.preloadedImages = {};
  }
  window.preloadedImages[type] = [];
  items.forEach((item) => {
    const defaultImage = getDefaultImage(item);
    if (defaultImage) {
      variants.forEach((variant) => {
        const url = getUriForMediaByVariant(defaultImage, variant);
        const image = new Image();
        image.src = url;
        window.preloadedImages[type].push(image);
      });
    }
  });
};

export const mapObject = (obj, fn) => Object.entries(obj).reduce(
  (acc, [key, value]) => ({ ...acc, [key]: fn(value, key) }), {},
);

export const scrollToElement = (elementRef) => {
  const elementCheck = setInterval(() => {
    const element = elementRef.current;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (elementRef.current) {
      window.scrollTo({
        top: element.getBoundingClientRect().top + scrollTop,
        left: 0,
        behavior: 'smooth',
      });
      clearInterval(elementCheck);
    }
  }, 100);
};

export const focusNewSelection = (expandBannerRef, selectedItemRef) => {
  expandBannerRef.current.expandBanner();
  scrollToElement(selectedItemRef);
};

export const removeDuplicateLookupItems = (lookupItems) => {
  const updatedLookupItems = lookupItems.reduce((unique, item) => {
    const duplicate = unique.find(uniqueItem => uniqueItem.value.id === item.value.id);
    return duplicate ? unique : [...unique, item];
  }, []);
  return updatedLookupItems;
};

export const removeDuplicateItems = (items) => {
  const updatedItems = items.reduce((unique, item) => {
    const duplicate = unique.find(uniqueItem => uniqueItem.id === item.id);
    return duplicate ? unique : [...unique, item];
  }, []);
  return updatedItems;
};

export const arrayMove = (array, from, to) => {
  const newArray = array.slice();
  newArray.splice(to < 0 ? newArray.length + to : to, 0, newArray.splice(from, 1)[0]);
  return newArray;
};

export const getFileExtension = (filename) => {
  const match = (/[.]/.exec(filename));
  return match ? `.${/[^.]+$/.exec(filename)[0]}` : undefined;
};

export const getAsyncLookupOptionValue = (option, key = 'id') => {
  if (!isNullOrUndefined(option)) {
    const { value } = option;
    if (typeof value === 'string') {
      return value;
    }
    if (typeof value === 'object' && !isNullOrUndefined(value[key])) {
      return value[key];
    }
  }
  return null;
};

export const getSelectionAggregate = (selection) => {
  if (selection.isCustomCharge) {
    return selection;
  }
  return selection.service || selection.product;
};

export const getFloatOrNull = (value) => {
  if (typeof value === 'string') {
    return value.length > 0 ? parseFloat(value) : null;
  }
  return value;
};

export const compose = (...fns) => x => fns.reduceRight((y, f) => f(y), x);

export const pipe = (...fns) => x => fns.reduce((y, f) => f(y), x);
