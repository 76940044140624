import React, { Component } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';

import {
  fetchArrangementCataloguePackagesAction,
  orderCataloguePackagesAsyncAction,
} from 'actions/catalogue';
import { catalogueCategoriesData } from 'constants/catalogue';
import { scopes as permissions } from 'constants/scopes';
import { hasPermissions, arrayMove } from 'services/utils';
import { packageType } from 'types/bereavement';
import { historyType } from 'types/reactRouter';

import AdminCataloguePackagesOrderScreen from './AdminCataloguePackagesOrderScreen';

const sortByCatalogueOrder = items => items
  .sort((item1, item2) => item2.catalogueOrder - item1.catalogueOrder);

class AdminCataloguePackagesOrderScreenContainer extends Component {
  static propTypes = {
    scopes: PropTypes.arrayOf(PropTypes.string.isRequired),
    packages: PropTypes.arrayOf(packageType).isRequired,
    history: historyType.isRequired,
    fetchPackages: PropTypes.func.isRequired,
    orderCataloguePackages: PropTypes.func.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { packages } = nextProps;
    if (prevState.items.length === 0 || (packages.length > prevState.items.length)) {
      const { items: prevItems } = prevState;
      const newItems = sortByCatalogueOrder(
        packages.filter(({ id }) => !prevItems.find(({ id: prevItemId }) => id === prevItemId)),
      );
      const items = [
        ...prevItems,
        ...newItems,
      ];

      return {
        items,
        isLoading: false,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);

    const {
      packages,
      fetchPackages,
      scopes,
    } = props;
    const categoryData = catalogueCategoriesData.find(catalogueCategory => catalogueCategory.category === 'PACKAGES');

    if (packages.length === 0 && hasPermissions([permissions.PACKAGES_READ], scopes)) {
      this.setState({ isLoading: true });
      fetchPackages();
    }

    this.state = {
      category: categoryData,
      items: packages.sort((p1, p2) => p2.catalogueOrder - p1.catalogueOrder) || [],
      isSortEnabled: false,
      isLoading: false,
      isSaving: false,
      isIncludible: true,
    };
  }

  handleToggleIsSortEnabled = () => {
    this.setState(prevState => ({
      isSortEnabled: !prevState.isSortEnabled,
    }));
  }

  handleSortItems = ({ oldIndex, newIndex }) => {
    const { items } = this.state;
    const reorderedItems = arrayMove(items, oldIndex, newIndex);

    this.setState({ items: reorderedItems });
  }

  handleSetIsVisible = (id, isVisible) => {
    const { items } = this.state;
    const updatedItems = items.map((item) => {
      const updatedItem = { ...item };
      if (updatedItem.id === id) {
        updatedItem.catalogueVisibility = isVisible;
      }
      return updatedItem;
    });
    this.setState({ items: updatedItems });
  }

  handleCancel = () => {
    const { history } = this.props;
    history.push('/catalogue/settings');
  }

  handleSave = () => {
    const { orderCataloguePackages, enqueueSnackbar } = this.props;
    const { items } = this.state;
    this.setState({ isSaving: true });

    orderCataloguePackages(items, () => {
      this.setState({ isSaving: false });
      enqueueSnackbar(
        t('Order and visibility saved successfully'),
        {
          variant: 'success',
        },
      );
      this.handleCancel();
    });
  }

  render() {
    const {
      category,
      items,
      isSortEnabled,
      isLoading,
      isSaving,
      isIncludible,
    } = this.state;

    return (
      <AdminCataloguePackagesOrderScreen
        isIncludible={isIncludible}
        category={category}
        items={items}
        isSortEnabled={isSortEnabled}
        isLoading={isLoading}
        isSaving={isSaving}
        onToggleIsSortEnabled={this.handleToggleIsSortEnabled}
        onSortItems={this.handleSortItems}
        onSetIsVisible={this.handleSetIsVisible}
        onCancel={this.handleCancel}
        onSave={this.handleSave}
      />
    );
  }
}

const mapStateToProps = state => ({
  scopes: state.userStore.user.policy.scopes,
  packages: state.catalogueStore.packages.items,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchPackages: fetchArrangementCataloguePackagesAction,
  orderCataloguePackages: orderCataloguePackagesAsyncAction,
}, dispatch);

export default withRouter(
  withSnackbar(
    connect(mapStateToProps, mapDispatchToProps)(AdminCataloguePackagesOrderScreenContainer),
  ),
);
