export const telephoneTypes = {
  WORK: 'WORK',
  HOME: 'HOME',
  MOBILE: 'MOBILE',
  FAX: 'FAX',
};

export const telephoneTypeList = [
  'WORK',
  'HOME',
  'MOBILE',
  'FAX',
];
