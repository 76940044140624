import React from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { apolloClientType } from 'types/apollo';
import ResetPasswordRequestScreen from './ResetPasswordRequestScreen';
import { requestResetSecret } from './mutations.gql';
import { validationSchema } from './validation';

class ResetPasswordRequestScreenContainer extends React.Component {
  static propTypes = {
    client: apolloClientType.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    validate: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      isSubmitting: false,
      isSubmitted: false,
      isValidationEnabled: false,
    };

    this.emailRef = React.createRef();
  }

  componentDidMount() {
    if (this.emailRef && this.emailRef.current) {
      this.emailRef.current.focus();
    }
  }

  handleEmailChange = (email) => {
    const { validate } = this.props;
    const { isValidationEnabled } = this.state;

    if (isValidationEnabled) {
      validate({ email }, validationSchema);
    }
    this.setState({ email });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { client, validate } = this.props;
    const { email } = this.state;

    this.setState({ isValidationEnabled: true });

    const isValid = validate({ email }, validationSchema);
    if (!isValid) {
      return;
    }

    this.setState({ isSubmitting: true });

    await client.mutate({
      mutation: requestResetSecret,
      variables: {
        input: {
          provider: 'PASSWORD',
          public: email,
        },
      },
    });

    this.setState({
      isSubmitting: false,
      isSubmitted: true,
    });
  }

  render() {
    const { errors } = this.props;
    const {
      email,
      isSubmitting,
      isSubmitted,
      isValidationEnabled,
    } = this.state;

    return (
      <ResetPasswordRequestScreen
        email={email}
        isSubmitting={isSubmitting}
        isSubmitted={isSubmitted}
        errors={isValidationEnabled ? errors : {}}
        emailRef={this.emailRef}
        onEmailChange={this.handleEmailChange}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

export default withApollo(withValidation(ResetPasswordRequestScreenContainer));
