import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { locationTypes } from 'constants/bereavement';
import { addressTransform } from 'transforms/common';
import { addressType } from 'types/common';
import LocationFormSection from './LocationFormSection';

class LocationFormSectionContainer extends Component {
  static propTypes = {
    value: addressType,
    valueKey: PropTypes.string,
    error: PropTypes.string,
    lookupLabel: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      locationType: props.value ? locationTypes.OTHER : locationTypes.FUNERAL_HOME,
      selectedHome: null,
    };
  }

  handleChangeLocationType = (locationType) => {
    const { valueKey, onChange } = this.props;
    const onChangeArgs = [];
    if (valueKey) {
      onChangeArgs.push(valueKey);
    }
    onChangeArgs.push(null);
    onChange(...onChangeArgs);
    this.setState({ locationType, selectedHome: null });
  }

  handleSelectHome = (home) => {
    const { valueKey, onChange } = this.props;
    const { address } = home;
    const onChangeArgs = [];
    if (valueKey) {
      onChangeArgs.push(valueKey);
    }
    onChangeArgs.push(addressTransform(address));
    onChangeArgs.push(home);
    onChange(...onChangeArgs);
    this.setState({ selectedHome: home });
  }

  render() {
    const { locationType, selectedHome } = this.state;

    return (
      <LocationFormSection
        {...this.props}
        locationType={locationType}
        selectedHome={selectedHome}
        onChangeLocationType={this.handleChangeLocationType}
        onSelectHome={this.handleSelectHome}
      />
    );
  }
}

export default LocationFormSectionContainer;
