import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const IconCharity = (props) => {
  const { className } = props;
  const classes = className ? `${className} ${styles.rc_icon}` : styles.rc_icon;

  return (
    <svg viewBox="0 0 24 24" className={classes}>
      <path d="M11.9,16.4c-0.2,0.3-0.3,0.5-0.4,0.7c-0.6,1.1-1.2,2.1-1.8,3.1c-0.1,0.2-0.2,0.4-0.4,0.7c-0.1,0.2-0.2,0.2-0.4,0 c-0.6-0.8-1.1-1.7-1.7-2.4c-0.1-0.2-0.1-0.3,0-0.5c0.6-1.2,1.2-2.3,1.9-3.5c0.2-0.3,0.4-0.7,0.6-1c0.1-0.1,0.1-0.2,0-0.4 c-0.6-1-1.2-1.9-1.8-2.8C7.3,9.4,7.1,8.4,7,7.5c-0.1-1.1,0.2-2,0.7-2.8C8.6,3.3,9.5,2.5,11,2.1c1-0.2,1.9,0,2.9,0.4 C14.8,3,15.4,3.5,16,4.3c0.4,0.5,0.6,1.1,0.8,1.8c0.4,1.5,0.1,2.8-0.7,4.1c-0.5,0.9-1.2,1.8-1.8,2.7c-0.2,0.2-0.1,0.4,0,0.6 c0.5,0.8,0.9,1.6,1.4,2.3c0.4,0.7,0.7,1.4,1.1,2.1c0.1,0.2,0.1,0.3,0,0.5c-0.5,0.8-1.1,1.6-1.6,2.4c-0.1,0.2-0.4,0.2-0.5,0l-2.5-4.1 C12.1,16.6,12,16.5,11.9,16.4z M14.7,5.6c0-0.1-0.1-0.1-0.2-0.2c-0.6-0.4-1.2-0.7-1.9-0.8c-1.1-0.2-2.2,0-3.3,0.6 C9.2,5.4,9.1,5.5,9.2,5.8c0.3,0.5,0.6,1.1,0.9,1.7c0.5,0.9,1.1,1.7,1.6,2.4c0.1,0.1,0.2,0.2,0.3,0c1-1.3,1.9-2.6,2.6-4.1 C14.7,5.8,14.7,5.7,14.7,5.6z" />
    </svg>
  );
};

IconCharity.propTypes = {
  className: PropTypes.string,
};

export default IconCharity;
