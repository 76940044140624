import styled from 'styled-components';

const CarouselWrapper = styled.div`
  border-bottom: 1px solid #d8d8d8;
`;

const Image = styled.div<{ imageUri?: string }>`
  background: ${props => props?.imageUri && `url('${props.imageUri}')`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding-top: 76.7%;
  width: 100%;
`;

export default {
  CarouselWrapper,
  Image,
};
