import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Price from 'components/common/Price';
import { moneyType } from 'types/common';
import { defaultMoneyObject, isNullOrUndefined } from 'services/utils';
import CurrencyField from 'components/common/CurrencyField';
import classNames from 'classnames';

import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const EstimateOverridablePrice = ({
  originalPrice,
  overridePrice,
  canOverridePrice,
  disabled,
  onChange,
}) => {
  const priceHasBeenOverriden = (
    canOverridePrice
    && overridePrice
    && overridePrice.amount
    && overridePrice.amount !== originalPrice.amount
  );

  return (
    <Fragment>
      <Price
        price={originalPrice}
        customClass={classNames(
          styles.c_price_label,
          styles.is_black,
          rcStyles.price,
          { [styles.c_strike_through]: priceHasBeenOverriden },
        )}
      />
      {canOverridePrice && (
        <div className={rcStyles.price_edit}>
          <CurrencyField
            min={0}
            nullable
            label={t('New price')}
            value={(overridePrice !== null && overridePrice !== undefined) ? overridePrice.amount : null}
            InputProps={{
              inputProps: {
                'data-test-id': 'newPrice',
              },
            }}
            onChangeAmount={(value) => {
              onChange(!isNullOrUndefined(value) ? defaultMoneyObject(value) : null);
            }}
            disabled={disabled}
          />
        </div>
      )}
    </Fragment>
  );
};

EstimateOverridablePrice.propTypes = {
  originalPrice: moneyType.isRequired,
  overridePrice: moneyType,
  canOverridePrice: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default EstimateOverridablePrice;
