import { setContext } from 'apollo-link-context';
import { store } from 'services/state';

const accessTokenLink = setContext((_, { headers }) => {
  const { isPortalActive } = store.getState().portalStore;
  const { accessToken, tenantId } = isPortalActive
    ? store.getState().portalStore.user || {}
    : store.getState().userStore.user || {};

  return {
    headers: {
      ...headers,
      'x-access-token': accessToken || '',
      'x-tenant-id': tenantId || '',
    },
  };
});

export default accessTokenLink;
