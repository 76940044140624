import { string, object } from 'yup';

export const validationSchema = object().shape({
  invoice: object().shape({
    payeeId: string().nullable(),
    payeeType: string().nullable(),
    dateTime: string().nullable().required('Date is required'),
    number: string().nullable().required('Invoice number is required'),
    reference: string().nullable(),
    notes: string().nullable(),
  }),
  payee: object().shape({
    address: object().shape({
      addressLine1: string().nullable().required('First line of address is required'),
      addressLine2: string().nullable(),
      town: string().nullable().required('Town is required'),
      county: string().nullable(),
      postCode: string().nullable().required('Post Code is required'),
      countryCode: string().nullable().required('Country is required'),
    }).nullable().required('Address is required for payee.'),
  }),
});
