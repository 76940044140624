import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import RemoveIcon from '@material-ui/icons/Remove';
import Price from 'components/common/Price';
import { catalogueServiceCategories, catalogueProductCategories } from 'constants/catalogue';
import { getUriForMediaByVariant } from 'services/utils';
import { getIconForCategory } from 'services/utils/icon';
import { mediaVariants } from 'constants/media';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const CatalogueItemListItem = ({
  item,
  onDelete,
}) => {
  const categoryName = [
    ...catalogueServiceCategories,
    ...catalogueProductCategories,
  ].find(category => category.category === item.category).name;

  const price = item.variants && item.variants[0].prices.sale;
  const imageUri = item.images
    && getUriForMediaByVariant(item.images[0], mediaVariants.FULL_FIT_50_X_50);

  return (
    <div className={classNames(
      rcStyles.list_item,
      item.isNewItem ? rcStyles.list_item__new : '',
    )}
    >
      <div className={rcStyles.is_avatar}>
        {imageUri ? (
          <Avatar>
            <img alt={item.title} src={imageUri} />
          </Avatar>
        ) : (
          <div className={rcStyles.is_avatar_placeholder}>
            {getIconForCategory(item.category)}
          </div>
        )}
      </div>
      <div className={rcStyles.is_title}>{item.title}</div>
      <div className={rcStyles.is_price}>
        <div className={rcStyles.category_name}>{categoryName}</div>
        <Price price={price} />
      </div>
      <div className={rcStyles.is_action}>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          className={classNames(styles.o_button__round)}
          onClick={onDelete}
          data-test-id="delete"
        >
          <RemoveIcon aria-label={t('Delete')} />
        </Button>
      </div>
    </div>
  );
};

CatalogueItemListItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  onDelete: PropTypes.func.isRequired,
};

export default CatalogueItemListItem;
