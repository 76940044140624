import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CharityFormSection from './CharityFormSection';

const defaultCharityInformation = {
  justGivingCharities: [],
  localCharities: [],
};

class CharityFormSectionContainer extends Component {
  static propTypes = {
    charityInformation: PropTypes.objectOf(PropTypes.any),
    disabled: PropTypes.bool.isRequired,
    errors: PropTypes.objectOf(PropTypes.any),
    onChange: PropTypes.func.isRequired,
  }

  handleAdd = (type) => {
    const { charityInformation: information, onChange } = this.props;
    const charityInformation = information || defaultCharityInformation;

    const newCharity = type === 'justGivingCharities'
      ? { id: null, department: { id: null, otherName: null } }
      : { name: '', sendDonationsToFamily: false };

    const updatedCharities = {
      ...charityInformation,
      [type]: [
        ...charityInformation[type],
        newCharity,
      ],
    };

    onChange(updatedCharities);
  }

  handleRemove = (type, indexToRemove) => {
    const { charityInformation: information, onChange } = this.props;
    const charityInformation = information || defaultCharityInformation;

    const updatedCharities = charityInformation[type]
      .filter((charity, index) => index !== indexToRemove);

    onChange({
      ...charityInformation,
      [type]: updatedCharities,
    });
  }

  handleChange = (type, indexToChange, updatedCharity) => {
    const { charityInformation: information, onChange } = this.props;
    const charityInformation = information || defaultCharityInformation;

    const updatedCharities = charityInformation[type].map((charity, index) => {
      if (index === indexToChange) {
        return updatedCharity;
      }
      return charity;
    });

    onChange({
      ...charityInformation,
      [type]: updatedCharities,
    });
  }

  render() {
    const { charityInformation: information, disabled, errors } = this.props;

    const charityInformation = information || defaultCharityInformation;

    return (
      <CharityFormSection
        charityInformation={charityInformation}
        disabled={disabled}
        errors={errors}
        onAdd={this.handleAdd}
        onRemove={this.handleRemove}
        onChange={this.handleChange}
      />
    );
  }
}

export default CharityFormSectionContainer;
