import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';

import MediaUpload from 'components/common/MediaUpload';
import InvoiceLoad from 'icons/InvoiceLoad';
import InvoiceLoadStatic from 'icons/InvoiceLoad_static';
import IconLoadSml from 'icons/IconLoadSml';
import styles from 'scss/main.scss';

import rcStyles from './styles.scss';

const UploadPanel = ({ isUploading, onUploadInitialized, onUploadDone }) => (
  <div
    className={classNames(
      styles.c_page__full_height,
      styles.has_faded_background,
    )}
  >
    <div className={styles.o_view}>
      <div
        className={classNames(
          styles.o_view__main,
          styles.o_block_content,
          rcStyles.v_spacing,
        )}
      >
        <div className={rcStyles.is_image}>
          {isUploading && (
            <InvoiceLoad />
          )}
          {!isUploading && (
            <InvoiceLoadStatic />
          )}
        </div>
        <div className={rcStyles.is_content}>
          <p className={classNames(styles.primer, styles.u_push__bottom)}>
            {t('Upload a Zip file to begin the import.')}
          </p>
          <p className={classNames(styles.primer, styles.u_push__bottom)}>
            {t('Your file will be validated and if successful it will be imported immediately.')}
          </p>
          <div className={rcStyles.actions}>
            <MediaUpload
              showImagePreview={false}
              contentDisposition="inline"
              acceptDocuments
              accept={['.zip']}
              onUploadInitialized={onUploadInitialized}
              onUploadDone={onUploadDone}
              customButton={(
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  data-test-id="uploadTemplate"
                >
                  {isUploading ? (
                    <div
                      className={classNames(
                        styles.c_svg_loader_sml,
                        styles.c_svg_loader_sml_btn,
                      )}
                      data-test-id="buttonLoader"
                    >
                      <IconLoadSml />
                    </div>
                  ) : t('Upload ZIP')}
                </Button>
              )}
              data-test-id="importCatalogue"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

UploadPanel.propTypes = {
  isUploading: PropTypes.bool.isRequired,
  onUploadInitialized: PropTypes.func.isRequired,
  onUploadDone: PropTypes.func.isRequired,
};

export default UploadPanel;
