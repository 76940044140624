import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import { arrangementType } from 'types/bereavement';
import { apolloClientType } from 'types/apollo';
import PropTypes from 'prop-types';
import { editPackageSelectionAction } from 'actions/arrangement';
import { debounceMutation } from 'services/utils';
import {
  filterSelectionsOnArrangement,
  filterSelectionsOptions,
  getArrangementSelectionsSortedByEstimateOrder,
  getPlaceholderSelectionsForArrangement,
} from 'services/utils/arrangement';
import EstimatePackageRows from './EstimatePackageRows';
import { editPackageSelectionMutation } from './mutations.gql';

class EstimatePackageRowsContainer extends Component {
  static propTypes = {
    bereavementId: PropTypes.string.isRequired,
    arrangement: arrangementType.isRequired,
    onOpenConfirmationWarningModal: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    editPackageSelection: PropTypes.func.isRequired,
    client: apolloClientType.isRequired,
    onSortEnd: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    const { arrangement } = props;
    const packageSelection = arrangement && arrangement.packageSelection;

    this.state = {
      itemMenuAnchorElement: null,
      isOverrideItem: packageSelection && !!packageSelection.overrideTitle,
    };
  }

  getSelections = () => {
    const { arrangement } = this.props;
    const filters = [
      filterSelectionsOptions.PACKAGE,
      filterSelectionsOptions.NON_DISPLAY_AS_DISBURSEMENTS,
      filterSelectionsOptions.NON_PROFESSIONAL_SERVICES,
    ];
    const selections = getArrangementSelectionsSortedByEstimateOrder(
      arrangement,
      filterSelectionsOnArrangement(arrangement, filters),
    );
    return [
      ...selections,
      ...getPlaceholderSelectionsForArrangement(arrangement, filterSelectionsOptions.PACKAGE),
    ];
  }

  handleOverridePackage = () => {
    const { arrangement, onOpenConfirmationWarningModal } = this.props;
    this.handleMenuClose();

    if (arrangement.isConfirmed) {
      onOpenConfirmationWarningModal(() => {
        this.editOverrideProperty('overrideTitle', arrangement.packageSelection.package.name);
        this.setState({ isOverrideItem: true });
      });
      return;
    }
    this.editOverrideProperty('overrideTitle', null);
    this.setState({ isOverrideItem: true });
  }

  handleResetPackage = () => {
    const { arrangement, onOpenConfirmationWarningModal } = this.props;
    this.handleMenuClose();

    if (arrangement.isConfirmed) {
      onOpenConfirmationWarningModal(() => {
        this.editOverrideProperty('overrideTitle', null);
        this.setState({ isOverrideItem: false });
      });
      return;
    }
    this.editOverrideProperty('overrideTitle', null);
    this.setState({ isOverrideItem: false });
  }

  handleMenuOpen = (event) => {
    this.setState({ itemMenuAnchorElement: event.currentTarget });
  }

  handleMenuClose = () => {
    this.setState({ itemMenuAnchorElement: null });
  }

  handleEditPackageItemOverride = (key, value) => {
    const { arrangement, onOpenConfirmationWarningModal } = this.props;
    if (arrangement.isConfirmed) {
      onOpenConfirmationWarningModal(() => { this.editOverrideProperty(key, value); });
      return;
    }
    this.editOverrideProperty(key, value);
  }

  editOverrideProperty = (key, value) => {
    const {
      client,
      editPackageSelection,
      bereavementId,
      arrangement,
    } = this.props;

    editPackageSelection(bereavementId, arrangement.id, { [key]: value });

    const input = {
      bereavementId,
      arrangementId: arrangement.id,
      [key]: value,
    };
    debounceMutation(client, editPackageSelectionMutation, input);
  }

  handleSelectionReorder = ({ oldIndex, newIndex }) => {
    const { onSortEnd } = this.props;
    const selections = this.getSelections();
    onSortEnd(selections[oldIndex], selections[newIndex]);
  }

  render() {
    const {
      bereavementId,
      arrangement,
      disabled,
      onOpenConfirmationWarningModal,
    } = this.props;
    const {
      itemMenuAnchorElement,
      isOverrideItem,
    } = this.state;
    const { overrideTitle } = arrangement && arrangement.packageSelection;

    return (
      <EstimatePackageRows
        bereavementId={bereavementId}
        arrangement={arrangement}
        selections={this.getSelections()}
        originalTitle={arrangement.packageSelection.package.name}
        overrideTitle={overrideTitle}
        onOpenConfirmationWarningModal={onOpenConfirmationWarningModal}
        disabled={disabled}
        onEditPackageItemOverride={this.handleEditPackageItemOverride}
        itemMenuAnchorElement={itemMenuAnchorElement}
        onMenuOpen={this.handleMenuOpen}
        onMenuClose={this.handleMenuClose}
        isOverrideItem={isOverrideItem}
        onOverridePackage={this.handleOverridePackage}
        onResetPackage={this.handleResetPackage}
        onSortEnd={this.handleSelectionReorder}
      />
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  editPackageSelection: editPackageSelectionAction,
}, dispatch);

export default withApollo(
  withRouter(connect(null, mapDispatchToProps)(EstimatePackageRowsContainer)),
);
