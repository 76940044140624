import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';

import MediaUpload from 'components/common/MediaUpload';
import TemplateReportModal from 'components/letterTemplates/TemplateReportModal/TemplateReportModal';
import IconLoadSml from 'icons/IconLoadSml';
import { buildNameString, getFormattedDate } from 'services/utils';
import { constructDefaultCorrespondenceTestUploadId } from 'services/utils/testIds';
import { correspondenceTemplateType } from 'types/correspondence';

import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const TemplateItem = ({
  template,
  allowDefaultDownload,
  onUploadComplete,
  onUploadStart,
  onDownload,
  onDownloadDefault,
  onPreview,
  isTemplateDownloading,
  isPreviewDownloading,
  isDefaultDownloading,
  isUploading,
  isValidationModalOpen,
  onContinue,
  report,
}) => {
  const {
    createdBy,
    createdDateTime,
    lastEditedBy,
    lastEditedDateTime,
  } = template;

  let updatedLabel;
  if (lastEditedBy && lastEditedBy.name && lastEditedDateTime) {
    updatedLabel = `Last updated by ${buildNameString(lastEditedBy.name, false)} on ${getFormattedDate(lastEditedDateTime)}`;
  } else if (createdBy && createdBy.name && createdDateTime) {
    updatedLabel = `Created by ${buildNameString(createdBy.name, false)} on ${getFormattedDate(createdDateTime)}`;
  }

  return (
    <>
      {updatedLabel && (
        <p className={classNames(
          styles.brevier,
          styles.u_push__bottom,
        )}
        >
          {updatedLabel}
        </p>
      )}
      <div className={rcStyles.letter_option}>
        <div className={classNames(
          rcStyles.letter_option__item,
          rcStyles.is_selected,
        )}
        >
          <p className={rcStyles.is_label}>{t('Download and edit existing template')}</p>
          <Button
            onClick={onDownload}
            variant="outlined"
            color="primary"
            data-test-id="downloadExistingTemplate"
            className={classNames(
              styles.u_push__right,
            )}
          >
            {isTemplateDownloading ? (
              <div
                className={classNames(
                  styles.c_svg_loader_sml,
                  styles.c_svg_loader_sml_btn,
                )}
                data-test-id="buttonLoader"
              >
                <IconLoadSml />
              </div>
            ) : (
              <>
                {t('Download')}
              </>
            )}
          </Button>
        </div>
        <div className={classNames(
          rcStyles.letter_option__item,
          rcStyles.is_pending,
        )}
        >
          <p className={rcStyles.is_label}>{t('Upload edited template to update')}</p>
          <MediaUpload
            showImagePreview={false}
            contentDisposition="inline"
            acceptDocuments
            accept={['.docx', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}
            onUploadDone={onUploadComplete}
            onUploadInitialized={onUploadStart}
            shouldDisplayConfirmation
            confirmationMessage="Are you sure you want to replace the current template?"
            customButton={(
              <Button
                variant="outlined"
                color="primary"
                data-test-id="uploadTemplate"
              >
                {t('Upload')}
              </Button>
            )}
            data-test-id="letterTemplateUpload"
          />
        </div>
        <div className={classNames(
          rcStyles.letter_option__item,
          rcStyles.is_complete,
        )}
        >
          <p className={rcStyles.is_label}>{t('Preview letter with sample funeral data')}</p>
          <Button
            onClick={onPreview}
            variant="outlined"
            color="primary"
            data-test-id="downloadPreviewTemplate"
          >
            {isPreviewDownloading ? (
              <div
                className={classNames(
                  styles.c_svg_loader_sml,
                  styles.c_svg_loader_sml_btn,
                )}
                data-test-id="buttonLoader"
              >
                <IconLoadSml />
              </div>
            ) : (
              <>
                {t('Preview')}
              </>
            )}
          </Button>
        </div>
      </div>
      {allowDefaultDownload && (
        <div>
          {isDefaultDownloading ? (
            <div
              className={classNames(
                styles.c_svg_loader_sml,
                styles.c_svg_loader_sml_btn,
              )}
              data-test-id="buttonLoader"
            >
              <IconLoadSml />
            </div>
          ) : (
            <>
              <p className={styles.brevier}>
                {t('If required you can ')}
                <span
                  onClick={onDownloadDefault}
                  variant="outlined"
                  color="primary"
                  data-test-id="downloadDefaultTemplate"
                  data-test-correspondence-upload-id={constructDefaultCorrespondenceTestUploadId(template.type)}
                  role="button"
                  tabIndex="-1"
                  style={{ fontStyle: 'italic', textDecoration: 'underline', cursor: 'pointer' }}
                >
                  {t('download the original template ')}
                </span>
                <span>
                  {t('to fix or update your document')}
                </span>
              </p>
            </>
          )}
        </div>
      )}
      <TemplateReportModal
        onContinue={onContinue}
        isOpen={isValidationModalOpen}
        isUploading={isUploading}
        report={report}
      />
    </>
  );
};

TemplateItem.propTypes = {
  template: correspondenceTemplateType.isRequired,
  allowDefaultDownload: PropTypes.bool.isRequired,
  onUploadComplete: PropTypes.func.isRequired,
  onUploadStart: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
  onDownloadDefault: PropTypes.func.isRequired,
  isTemplateDownloading: PropTypes.bool.isRequired,
  isPreviewDownloading: PropTypes.bool.isRequired,
  isDefaultDownloading: PropTypes.bool.isRequired,
  isUploading: PropTypes.bool.isRequired,
  isValidationModalOpen: PropTypes.bool.isRequired,
  onContinue: PropTypes.func.isRequired,
  report: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TemplateItem;
