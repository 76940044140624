import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { historyType } from 'types/reactRouter';
import PropTypes from 'prop-types';
import { catalogueCategoryType } from 'types/catalogue';
import AdminArrangementSettingsCategoryScreen from './AdminArrangementSettingsCategoryScreen';

class AdminArrangementSettingsCategoryScreenContainer extends Component {
  static propTypes = {
    category: catalogueCategoryType.isRequired,
    subCategories: PropTypes.arrayOf(catalogueCategoryType).isRequired,
    history: historyType.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      category: props.category,
      subCategories: props.subCategories,
    };
  }

  handleEditCategory = (subCategory) => {
    const { history } = this.props;
    const { category } = this.state;
    history.push(`/catalogue/arrangement-settings/${category.path}/${subCategory}`);
  }

  render() {
    const {
      category,
      subCategories,
    } = this.state;

    return (
      <AdminArrangementSettingsCategoryScreen
        category={category}
        subCategories={subCategories}
        onEdit={this.handleEditCategory}
      />
    );
  }
}

export default withRouter(AdminArrangementSettingsCategoryScreenContainer);
