import React from 'react';

let accountContext;

export const getAccountContext = () => {
  if (!accountContext) {
    accountContext = React.createContext({});
  }
  return accountContext;
};
