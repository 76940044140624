import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { v4 as uuidv4 } from 'uuid';

import {
  createBereavementForLiteAction,
  fetchLiteBereavementAction,
  editBereavementForLiteAction,
} from 'actions/bereavement';
import { featureFlags } from 'constants/features';
import { generateHexId } from 'services/utils';
import { getAvailableNavigation } from 'services/utils/navigation';
import { bereavementType } from 'types/bereavement';
import { organisationalUnitType } from 'types/organisationalUnit';
import { historyType, matchType, locationType } from 'types/reactRouter';

import LiteArrangementScreen from './LiteArrangementScreen';

class LiteArrangementScreenContainer extends Component {
  static propTypes = {
    bereavements: PropTypes.arrayOf(bereavementType.isRequired).isRequired,
    isOnline: PropTypes.bool.isRequired,
    organisationalUnits: PropTypes.arrayOf(organisationalUnitType).isRequired,
    availableFeatures: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    tenantCategories: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    arrangementCategoryDisplayPreferences: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
    shouldDisplaySplashScreenLogo: PropTypes.bool.isRequired,
    createBereavementForLite: PropTypes.func.isRequired,
    editBereavementForLite: PropTypes.func.isRequired,
    fetchLiteBereavement: PropTypes.func.isRequired,
    history: historyType,
    match: matchType,
    location: locationType,
  };

  static getDerivedStateFromProps(props, state) {
    const {
      bereavements, isOnline, match, fetchLiteBereavement,
    } = props;
    const bereavement = bereavements.find(existing => existing.id === match.params.bereavementId);

    if (!bereavement && match.params.bereavementId && isOnline) {
      fetchLiteBereavement(match.params.bereavementId);
    }

    return {
      bereavement: bereavement || state.bereavement || null,
      isLoading: !!match.params.bereavementId && !bereavement,
      isNewBereavement: !match.params.bereavementId,
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      bereavement: null,
      isLoading: true,
      isNewBereavement: false,
      step: props.location.state && props.location.state.isNew ? 2 : 0,
    };
  }

  handleSave = (formData) => {
    const {
      match,
      history,
      availableFeatures,
      tenantCategories,
      arrangementCategoryDisplayPreferences,
      createBereavementForLite,
      editBereavementForLite,
    } = this.props;
    const { isNewBereavement, bereavement, step } = this.state;

    const navigationPaths = getAvailableNavigation(
      availableFeatures,
      tenantCategories,
      arrangementCategoryDisplayPreferences,
      formData?.committalType,
    );

    if (step === 0 && isNewBereavement) {
      this.setState({
        bereavement: formData,
        step: 1,
      });
    } else if (step === 0 && !isNewBereavement) {
      const updatedBereavement = {
        ...bereavement,
        deceasedPeople: [
          {
            ...bereavement.deceasedPeople[0],
            name: { ...formData?.deceasedName },
          },
        ],
        arrangements: [
          {
            ...bereavement.arrangements[0],
            reference: formData?.arrangementReference,
          },
        ],
      };

      this.setState({
        bereavement: updatedBereavement,
        step: 1,
      });
      editBereavementForLite({ bereavement: updatedBereavement });
    } else if (step === 1 && isNewBereavement) {
      bereavement.bereavedPerson = formData;
      const newId = uuidv4();
      createBereavementForLite(bereavement, newId);
      history.push({
        pathname: `/lite-arrangement/${newId}`,
        state: { isNew: true },
      });
    } else if (step === 1 && !isNewBereavement) {
      if (bereavement.bereavedPeopleConnections && bereavement.bereavedPeopleConnections[0]) {
        bereavement.bereavedPeopleConnections[0].bereavedPerson = {
          ...bereavement.bereavedPeopleConnections[0].bereavedPerson,
          ...formData,
        };
      } else {
        bereavement.bereavedPeopleConnections = [{
          id: generateHexId(),
          bereavedPersonId: formData.id,
          relationshipToDeceased: null,
          isPrimaryContact: true,
          isBillPayer: true,
          isNextOfKin: false,
          contactPreferences: null,
          bereavedPerson: formData,
        }];
      }

      editBereavementForLite({ bereavement });
      history.push(`${match.url}/catalogue/${navigationPaths[0].path}`);
    }
  };

  handleStartArrangement = () => {
    const {
      match,
      history,
      availableFeatures,
      tenantCategories,
      arrangementCategoryDisplayPreferences,
    } = this.props;
    const { bereavement } = this.state;

    const arrangement = bereavement?.arrangements?.[0];
    const navigationPaths = getAvailableNavigation(
      availableFeatures,
      tenantCategories,
      arrangementCategoryDisplayPreferences,
      arrangement?.committalType,
    );

    history.push(`${match.url}/catalogue/${navigationPaths[0].path}`);
  };

  handleCancel = () => {
    const { history } = this.props;
    const { step } = this.state;

    if (step === 0) {
      history.push('/lite-arrangement');
    }
    if (step === 1) {
      this.setState({ step: 0 });
    }
  };

  render = () => {
    const { organisationalUnits, shouldDisplaySplashScreenLogo } = this.props;
    const {
      bereavement,
      isNewBereavement,
      isLoading,
      step,
    } = this.state;

    return (
      <LiteArrangementScreen
        bereavement={bereavement}
        organisationalUnits={organisationalUnits}
        shouldDisplaySplashScreenLogo={shouldDisplaySplashScreenLogo}
        isNewBereavement={isNewBereavement}
        isLoading={isLoading}
        step={step}
        onStart={this.handleStartArrangement}
        onSave={this.handleSave}
        onCancel={this.handleCancel}
      />
    );
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  createBereavementForLite: createBereavementForLiteAction,
  editBereavementForLite: editBereavementForLiteAction,
  fetchLiteBereavement: fetchLiteBereavementAction,
}, dispatch);

const mapStateToProps = state => ({
  isOnline: state.userStore.isOnline,
  organisationalUnits: state.userStore.user.staffMember.organisationalUnitsFlattened,
  shouldDisplaySplashScreenLogo:
    state.userStore.user.tenantFeatureFlags.includes(featureFlags.LITE_SPLASH_SCREEN_LOGO),
  bereavements: state.bereavementStore.bereavements,
  availableFeatures: state.userStore.user.tenantFeatureFlags,
  tenantCategories: state.userStore.user.tenantCategories,
  arrangementCategoryDisplayPreferences: state.userStore.user.arrangementCategoryDisplayPreferences,
});

export default withRouter(
  withApollo(
    connect(mapStateToProps, mapDispatchToProps)(LiteArrangementScreenContainer),
  ),
);
