import { store } from 'services/state';
import { setIsOnlineAction } from 'actions/user';

export const initialiseNetworkSubscriptions = () => {
  window.addEventListener('online', () => {
    store.dispatch(setIsOnlineAction(true));
    window.location.reload();
  });

  window.addEventListener('offline', () => {
    store.dispatch(setIsOnlineAction(false));
  });
};
