import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Button from '@material-ui/core/Button';
import { accountTransactionType, payeeTypes, paymentMethods } from 'constants/account';
import { getFormattedDate, buildNameString } from 'services/utils';
import { invoiceType, paymentType } from 'types/account';
import { bereavedPersonType } from 'types/bereavement';
import { directoryListingType } from 'types/directoryListing';
import ExpandableBanner from 'components/common/ExpandableBanner';
import Price from 'components/common/Price';
import { AccountConsumer } from 'contexts/account';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const TransactionPaymentRow = ({
  directoryListings,
  payment,
  linkedInvoice,
  bereavedPeopleConnections,
  toggleAddPaymentModal,
  toggleConfirmDeleteModal,
  setTransactionBeingModified,
}) => {
  const directoryListing = directoryListings && directoryListings.find(listing => listing.id === payment.payeeId);
  const bereavedPerson = bereavedPeopleConnections.find(connection => connection.id === payment.payeeId);
  const payeeName = payment.payeeType === payeeTypes.BEREAVED_PERSON_CONNECTION
    ? (bereavedPerson && bereavedPerson.bereavedPerson) && buildNameString(bereavedPerson.bereavedPerson.name)
    : directoryListing && directoryListing.name;

  return (
    <AccountConsumer>
      {({ isAccountOpen, hasWritePermissions }) => (
        <div data-test-id="paymentTransaction">
          <ExpandableBanner
            headerClasses={[rcStyles.expanding_banner__header]}
            wrapperClasses={[rcStyles.expanding_banner__wrap]}
            isClosedOnLoad
            title={(
              <div className={rcStyles.banner_content}>
                <div className={rcStyles.is_title}>
                  {t('Payment')}
                </div>
                <div className={rcStyles.is_date_paid}>
                  <div className={styles.brevier}>
                    {t('Paid')}
                  </div>
                  <span data-test-id="paymentPaidOn">
                    {getFormattedDate(payment.dateTime)}
                  </span>
                </div>
                <div
                  className={rcStyles.is_price}
                  data-test-id="paymentAmount"
                >
                  <Price
                    price={payment.amount}
                    customClass={classNames(
                      styles.c_price_label__regular,
                      styles.is_black,
                      styles.is_bold,
                    )}
                  />
                </div>
              </div>
            )}
          >
            <div className={rcStyles.expanded_content}>
              {linkedInvoice && (
                <div className={rcStyles.is_invoice_number}>
                  <div className={styles.brevier}>
                    {t('Invoice number')}
                  </div>
                  <span data-test-id="paymentInvoiceNumber">
                    {linkedInvoice.number}
                  </span>
                </div>
              )}
              {payeeName && (
                <div className={rcStyles.is_paid_by}>
                  <div className={styles.brevier}>
                    {t('Paid by')}
                  </div>
                  <span data-test-id="paymentPayeeName">
                    {payeeName}
                  </span>
                </div>
              )}
              {payment.method && (
                <div className={rcStyles.is_method}>
                  <div className={styles.brevier}>
                    {t('Method')}
                  </div>
                  <span data-test-id="paymentMethod">
                    {t(paymentMethods.find(method => method.id === payment.method).name)}
                  </span>
                </div>
              )}
              <div className={rcStyles.actions_bar}>
                <Button
                  onClick={() => {
                    setTransactionBeingModified(accountTransactionType.PAYMENT, payment);
                    toggleAddPaymentModal();
                  }}
                  variant="outlined"
                  color="primary"
                  disabled={!(isAccountOpen && hasWritePermissions)}
                  data-test-id="editPayment"
                >
                  {t('Edit')}
                </Button>
                <Button
                  onClick={() => {
                    setTransactionBeingModified(accountTransactionType.PAYMENT, payment);
                    toggleConfirmDeleteModal();
                  }}
                  variant="outlined"
                  color="primary"
                  disabled={!(isAccountOpen && hasWritePermissions)}
                  data-test-id="deletePayment"
                  className={rcStyles.is_delete}
                >
                  {t('Delete ')}
                </Button>
              </div>
            </div>
          </ExpandableBanner>
        </div>
      )}
    </AccountConsumer>
  );
};

TransactionPaymentRow.propTypes = {
  payment: paymentType.isRequired,
  linkedInvoice: invoiceType,
  setTransactionBeingModified: PropTypes.func.isRequired,
  toggleAddPaymentModal: PropTypes.func.isRequired,
  toggleConfirmDeleteModal: PropTypes.func.isRequired,
  directoryListings: PropTypes.arrayOf(directoryListingType),
  bereavedPeopleConnections: PropTypes.arrayOf(bereavedPersonType),
};

export default TransactionPaymentRow;
