import { emptyOfficiantServiceSelectionCategoryMetaData } from 'constants/arrangement';

export const editObituaryInformationMutationTransform = (
  bereavementId, arrangementId, categoryInformation, shouldPublish,
) => {
  const {
    deceasedImage,
    deceasedImageId,
    onlineAdministratorBereavedPersonConnection,
    funeralDate,
    image,
    ...obituaryInformation
  } = categoryInformation;

  if (categoryInformation.charityInformation) {
    obituaryInformation.charityInformation = { ...categoryInformation.charityInformation };
    obituaryInformation.charityInformation.justGivingCharities = obituaryInformation
      .charityInformation.justGivingCharities
      .map((charity) => {
        const result = { id: charity.id, department: {} };
        if (charity.department && charity.department.id) {
          result.department.id = charity.department.id;
        }
        if (charity.department && charity.department.otherName) {
          result.department.otherName = charity.department.otherName;
        }
        return result;
      });
  }

  return {
    bereavementId,
    arrangementId,
    ...obituaryInformation,
    shouldPublish,
  };
};

export const duplicateVehicleTransform = ({ errors, ...vehicle }) => vehicle;

export const careCategoryInformationFormTransform = (care) => {
  const { clothes } = care;

  if (clothes && clothes.includes('Gown')) {
    const colourValue = clothes.replace(/Gown/g, '').trim();
    if (colourValue.length) {
      const clothesColour = colourValue.replace(/[()]/g, '');
      return {
        ...care,
        clothes: 'Gown',
        clothesColour,
      };
    }
  }

  return care;
};

export const careCategoryInformationSaveTransform = (care) => {
  const { clothes, clothesColour } = care;

  let updatedClothes = clothes;
  if (clothes && clothes.includes('Gown') && clothesColour && !!clothesColour.length) {
    updatedClothes += ` (${clothesColour})`;
  }

  const updatedCare = {
    ...care,
    clothes: updatedClothes,
  };
  delete updatedCare.clothesColour;

  return updatedCare;
};

export const officiantServiceSelectionTransform = selection => ({
  ...selection,
  categoryMetaData: {
    ...(selection?.categoryMetaData || {}),
    officiant: {
      ...emptyOfficiantServiceSelectionCategoryMetaData,
      ...(selection?.categoryMetaData?.officiant || {}),
    },
  },
});

export const officiantServiceSelectionsMutationTransform = selections => selections
  ?.map(({ service, salePriceAtConfirmation, ...otherProps }) => ({ ...otherProps }));
