import {
  string, boolean, object,
} from 'yup';

export const validationSchema = object().shape({
  image: object().nullable(),
  type: string().nullable().required('Possession type is required'),
  description: string().nullable().required('Description is required'),
  requiredForViewing: boolean().nullable(),
  familyWishes: string().nullable(),
  note: string().nullable(),
  actionTaken: string().nullable().test('possessionActionTest', 'Action taken is required', function validateActionTaken(value) {
    const { actionHistory } = this.parent;
    return value || actionHistory;
  }),
});
