import * as yup from 'yup';

const {
  string,
  array,
  object,
} = yup;

yup.addMethod(string, 'phoneCustom', function getPhoneValidation(message) {
  return this.test('testPhoneNumber', message, function validatePhone(number) {
    const { path, createError } = this;
    // Validate phone regex
    let valid = true;
    if (number) {
      valid = (/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/).test(number);
    }
    return valid || createError({ path, message });
  });
});

export const validationSchema = object().shape({
  name: string().nullable().required('Name is required'),
  address: object().shape({
    addressLine1: string().nullable().required('Address line 1 is required'),
    town: string().nullable().required('Town is required'),
    postCode: string().nullable().required('Post code is required'),
  }),
  phones: array().of(
    object().shape({
      telephone: object().shape({
        countryCode: string().required('Telephone country code is required'),
        number: string()
          .phoneCustom('Please enter a valid phone number')
          .required('Telephone number is required'),
      }),
      type: string().required('Telephone type is required'),
    }),
  ).min(1, 'You must supply at least one phone number').required('Telephone number is required'),
  emails: array().of(
    string().nullable().email('Email is not valid').required('Email address is required'),
  ).nullable(),
  websites: array().of(string()).nullable(),
});
