import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconCalendar from '@material-ui/icons/DateRange';
import { getDateFromISOString } from 'services/utils';
import DatePickerHeader from 'components/common/DatePickerHeader';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';
import 'react-datepicker/dist/react-datepicker.css?raw';
import './overrides.css?raw';

class DatePickerWrapper extends Component {
  static propTypes = {
    'value': PropTypes.string,
    'label': PropTypes.string,
    'placeholder': PropTypes.string,
    'name': PropTypes.string,
    'minDate': PropTypes.string,
    'maxDate': PropTypes.string,
    'InputProps': PropTypes.objectOf(PropTypes.any),
    'inputProps': PropTypes.objectOf(PropTypes.any),
    'disabled': PropTypes.bool,
    'errors': PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.objectOf(PropTypes.any),
    ]),
    'helperText': PropTypes.string,
    'fullWidth': PropTypes.bool,
    'onChange': PropTypes.func.isRequired,
    'isClearable': PropTypes.bool,
    'disablePast': PropTypes.bool,
    'disableFuture': PropTypes.bool,
    'displayFormat': PropTypes.string,
    'returnFormat': PropTypes.string,
    'data-test-id': PropTypes.string,
    'icon': PropTypes.objectOf(PropTypes.any),
  };

  static defaultProps = {
    'displayFormat': 'dd/MM/yyyy',
    'returnFormat': 'YYYY-MM-DD',
    'fullWidth': true,
  };

  handleChange = (date) => {
    const { returnFormat, onChange } = this.props;
    if (onChange) {
      const dateTimeAsString = date && moment(date).format(returnFormat);
      onChange(dateTimeAsString);
    }
  };

  handleClear = () => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(null);
    }
  }

  render() {
    const {
      value: valueAsString,
      name,
      label,
      placeholder,
      icon,
      minDate: minDateAsString,
      maxDate: maxDateAsString,
      disabled,
      errors,
      helperText,
      fullWidth,
      isClearable,
      disablePast,
      disableFuture,
      displayFormat,
      InputProps,
      inputProps,
      'data-test-id': dataTestId,
      ...otherProps
    } = this.props;

    const value = getDateFromISOString(valueAsString);
    const minDate = getDateFromISOString(minDateAsString);
    const maxDate = getDateFromISOString(maxDateAsString);
    const disablePastDate = disablePast && new Date();
    const disableFutureDate = disableFuture && new Date();

    const defaultIcon = (
      <IconCalendar className={styles.o_icon__blue} />
    );

    return (
      <div className={classNames(
        rcStyles.datepicker,
      )}
      >
        <div className={rcStyles.datepickerIcon}>
          <InputAdornment position="start">
            {icon || defaultIcon}
          </InputAdornment>
        </div>
        <DatePicker
          {...otherProps}
          renderCustomHeader={DatePickerHeader}
          selected={value}
          placeholderText={placeholder}
          minDate={minDate || disablePastDate}
          maxDate={maxDate || disableFutureDate}
          onChange={this.handleChange}
          customInput={(
            <TextField
              label={label}
              name={name}
              InputLabelProps={{
                FormLabelClasses: {
                  root: rcStyles.datepickerLabel,
                  focused: rcStyles.datepickerLabelFocused,
                  filled: rcStyles.datepickerLabelShurnk,
                },
              }}
              inputProps={{
                ...(inputProps || {}),
                'data-test-id': dataTestId,
              }}
              disabled={disabled}
              error={errors}
              fullWidth={fullWidth}
              helperText={helperText}
            />
          )}
          dateFormat={displayFormat}
          disabledKeyboardNavigation
        />
      </div>
    );
  }
}

export default DatePickerWrapper;
