import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { matchType, historyType } from 'types/reactRouter';
import { productType, serviceType } from 'types/bereavement';
import { bindActionCreators } from 'redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  fetchCatalogueItemAction,
  createCatalogueItemAction,
  editCatalogueItemAction,
} from 'actions/catalogue';
import AdminCatalogueItemScreen from './AdminCatalogueItemScreen';

class AdminCatalogueItemScreenContainer extends Component {
  static propTypes = {
    isNewItem: PropTypes.bool,
    isServiceCategory: PropTypes.bool,
    catalogueItemType: PropTypes.string.isRequired,
    match: matchType.isRequired,
    products: PropTypes.arrayOf(productType),
    services: PropTypes.arrayOf(serviceType),
    fetchCatalogueItem: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    catalogueItems: PropTypes.arrayOf(PropTypes.oneOfType([
      productType,
      serviceType,
    ])),
    history: historyType.isRequired,
    saveCatalogueItem: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      item: {},
    };
  }

  static getDerivedStateFromProps(props, prevState) {
    const { match, isNewItem, isServiceCategory } = props;

    if (isNewItem || (prevState.item && prevState.item.id)) {
      return null;
    }

    const { itemId } = match.params;
    const item = isServiceCategory
      ? props.services.find(service => service.id === itemId)
      : props.products.find(product => product.id === itemId);
    return item ? { item } : null;
  }

  componentDidMount() {
    const { match } = this.props;
    const { itemId } = match.params;

    if (!itemId) {
      return;
    }

    const { catalogueItems } = this.props;
    const catalogueItem = catalogueItems.find(item => item.id === itemId);

    if (!catalogueItem) {
      this.getItem(itemId);
      return;
    }
    this.setState({ item: catalogueItem });
  }

  getItem = (itemId) => {
    const { fetchCatalogueItem } = this.props;
    fetchCatalogueItem(itemId);
  }

  handleSave = (formData) => {
    const {
      saveCatalogueItem,
      history,
      isServiceCategory,
    } = this.props;
    const cataloguePath = isServiceCategory ? 'services' : 'products';

    saveCatalogueItem({
      ...formData,
      category: formData.category.category,
    });
    history.push(`/catalogue/${cataloguePath}`);
  }

  handleCancel = () => {
    const { history, isServiceCategory } = this.props;
    const cataloguePath = isServiceCategory ? 'services' : 'products';

    history.push(`/catalogue/${cataloguePath}`);
  }

  render() {
    const {
      isNewItem,
      catalogueItemType,
      isLoading,
      isServiceCategory,
    } = this.props;
    const { item } = this.state;

    return (
      <AdminCatalogueItemScreen
        isLoading={isLoading}
        isServiceCategory={isServiceCategory}
        item={item}
        isNewItem={isNewItem}
        catalogueItemType={catalogueItemType}
        onSave={this.handleSave}
        onCancel={this.handleCancel}
        onChangeFormData={this.handleChangeFormData}
        onChangeVariant={this.handleChangeVariant}
        onAddVariant={this.handleAddVariant}
        onDeleteVariant={this.handleDeleteVariant}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const itemType = ownProps.isServiceCategory ? 'services' : 'products';

  return {
    products: state.catalogueStore.products.items,
    services: state.catalogueStore.services.items,
    catalogueItems: ownProps.isServiceCategory
      ? state.catalogueStore.services.items
      : state.catalogueStore.products.items,
    isLoading: state.catalogueStore[itemType].isLoading,
  };
};


const mapDispatchToProps = (dispatch, ownProps) => {
  const itemType = ownProps.isServiceCategory ? 'services' : 'products';

  return bindActionCreators({
    fetchCatalogueItem: id => fetchCatalogueItemAction(itemType, id),
    saveCatalogueItem: ownProps.isNewItem
      ? item => createCatalogueItemAction(itemType, item)
      : item => editCatalogueItemAction(itemType, item),
  }, dispatch);
};

export default withApollo(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AdminCatalogueItemScreenContainer),
  ),
);
