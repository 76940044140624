import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TabContainer from './TabContainer';

class TabContainerContainer extends Component {
  static propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
    classes: PropTypes.objectOf(PropTypes.any).isRequired,
    onChange: PropTypes.func,
    activeIndex: PropTypes.number,
    resetToDefault: PropTypes.bool,
    testIds: PropTypes.objectOf(PropTypes.any),
  };

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.resetToDefault) {
      return { index: 0 };
    }

    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      index: props.activeIndex || 0,
    };
  }

  handleChange = (event, value) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
    this.setState({ index: value });
  }

  render() {
    const { index } = this.state;
    const { tabs, classes, testIds } = this.props;
    return (
      <TabContainer
        index={index}
        tabs={tabs}
        onChange={this.handleChange}
        classes={classes}
        testIds={testIds}
      />
    );
  }
}

export default TabContainerContainer;
