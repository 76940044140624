import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import NameFormSection from 'components/common/NameFormSection';

import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const ArrangementPublicationForm = ({
  deceasedDates,
  formData,
  disabled,
  onChange,
  formRefs,
  errors,
}) => (

  <div className={rcStyles.v_spacing}>
    <TextField
      label={t('Publication names')}
      value={formData.offlinePublicationNames || ''}
      onChange={event => onChange('offlinePublicationNames', event.target.value)}
      inputProps={{
        'data-test-id': 'offlinePublicationNames',
      }}
      disabled={disabled}
      fullWidth
    />
    <div ref={formRefs.deceasedName}>
      <NameFormSection
        name={formData.deceasedName || {}}
        labelTitle={t('Title')}
        onChangeHandler={name => onChange('deceasedName', name)}
        labelGivenName={t('First Name')}
        labelFamilyName={t('Last Name')}
        disabled={disabled}
        hideTitle
        fullWidth
        errors={errors.deceasedName}
      />
    </div>
    <p>{deceasedDates}</p>
    <TextField
      label={t('Obituary message')}
      value={formData.offlineObituaryMessage || ''}
      onChange={event => onChange('offlineObituaryMessage', event.target.value)}
      inputProps={{
        'data-test-id': 'offlineObituaryMessage',
      }}
      disabled={disabled}
      multiline
      rows="4"
      fullWidth
      className={styles.u_push__bottom}
    />
  </div>
);

ArrangementPublicationForm.propTypes = {
  deceasedDates: PropTypes.string.isRequired,
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any),
};

export default ArrangementPublicationForm;
