import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';

import { serviceCategories } from 'constants/arrangement';
import { isArrayEmptyOrFalse } from 'services/utils';
import { apolloClientType } from 'types/apollo';
import { arrangementType, serviceType } from 'types/bereavement';

import { getServices } from './queries.gql';
import FeeModal from './FeeModal';

const defaultFirst = 15;

class FeeModalContainer extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    bereavementId: PropTypes.string.isRequired,
    arrangement: arrangementType,
    bereavementHomeId: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onOpenDuplicateSelectionModal: PropTypes.func.isRequired,
    client: apolloClientType.isRequired,
    services: PropTypes.arrayOf(serviceType),
    isOnline: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      items: [],
      pagination: {
        first: defaultFirst,
        after: null,
        hasMore: false,
      },
      isLoading: false,
    };

    this.dialogRef = React.createRef();
  }

  componentDidMount() {
    this.getItems();
  }

  getItems = () => {
    const {
      client,
      isOnline,
      services = [],
      bereavementHomeId,
    } = this.props;
    const { pagination } = this.state;

    if (isOnline) {
      this.setState({ isLoading: true });

      client.query({
        query: getServices,
        variables: {
          categories: [serviceCategories.PROFESSIONAL_SERVICES],
          organisationalUnitIds: [bereavementHomeId],
          pagination: {
            first: pagination.first,
            after: pagination.after,
          },
        },
      })
        .then((response) => {
          const items = response.data.services && response.data.services.edges
            .map(({ node }) => node);
          const pageInfo = response.data.services && response.data.services.pageInfo;

          this.setState(prevState => ({
            items: [...prevState.items, ...items],
            pagination: {
              ...prevState.pagination,
              after: pageInfo.endCursor,
              hasMore: pageInfo.hasNextPage,
            },
            isLoading: false,
          }));
        });
    } else {
      const items = services
        .filter(({ category, organisationalUnitIds }) => {
          const isProfessionalService = category === serviceCategories.PROFESSIONAL_SERVICES;
          const hasMatchingOrganisationalUnitIdOrIsEmpty = isArrayEmptyOrFalse(organisationalUnitIds)
            || organisationalUnitIds.includes(bereavementHomeId);
          return isProfessionalService
            && hasMatchingOrganisationalUnitIdOrIsEmpty;
        })
        .sort((item1, item2) => (item1.title > item2.title ? 1 : -1));

      this.setState({ items });
    }
  }

  render() {
    const {
      arrangement,
      bereavementId,
      disabled,
      isOpen,
      onClose,
      onOpenDuplicateSelectionModal,
    } = this.props;
    const { items, isLoading, pagination } = this.state;

    return (
      <FeeModal
        dialogRef={this.dialogRef}
        isOpen={isOpen}
        bereavementId={bereavementId}
        arrangement={arrangement}
        items={items}
        isLoading={isLoading}
        disabled={disabled}
        hasMoreItems={pagination.hasMore}
        getMoreItems={this.getItems}
        onClose={onClose}
        onOpenDuplicateSelectionModal={onOpenDuplicateSelectionModal}
      />
    );
  }
}

const mapStateToProps = state => ({
  services: state.catalogueStore.services.items,
  isOnline: state.userStore.isOnline,
});

export default withApollo(connect(mapStateToProps)(FeeModalContainer));
