import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DynamicList from './DynamicList';

class DynamicListContainer extends Component {
  static propTypes = {
    'component': PropTypes.element.isRequired,
    'addButtonProps': PropTypes.objectOf(PropTypes.any),
    'removeButtonProps': PropTypes.objectOf(PropTypes.any),
    'addButtonInner': PropTypes.element,
    'removeButtonInner': PropTypes.element,
    'dataList': PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({})),
      PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
    'errors': PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.objectOf(PropTypes.any),
      PropTypes.arrayOf(PropTypes.shape({})),
      PropTypes.arrayOf(PropTypes.string),
    ]),
    'onChange': PropTypes.func,
    'onAddItem': PropTypes.func,
    'onChangeItem': PropTypes.func,
    'onRemoveItem': PropTypes.func,
    'disabled': PropTypes.bool,
    'defaultEmpty': PropTypes.bool,
    'hideAddButton': PropTypes.bool,
    'hideRemoveButton': PropTypes.bool,
    'data-test-id': PropTypes.string,
  }

  constructor(props) {
    super(props);
    this.state = {
      hasNewEmptyField: false,
      keySeed: 0,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.defaultEmpty && nextProps.dataList.length < 1 && !prevState.hasNewEmptyField) {
      return {
        hasNewEmptyField: true,
      };
    }
    return null;
  }

  handleAdd = () => {
    const { hasNewEmptyField } = this.state;
    if (hasNewEmptyField) {
      return;
    }
    this.setState({ hasNewEmptyField: true });
  }

  handleRemove = (indexToRemove, value) => {
    const { dataList, onChange, onRemoveItem } = this.props;
    const { hasNewEmptyField } = this.state;

    const stateChanges = {};

    if (hasNewEmptyField && indexToRemove > dataList.length - 1) {
      stateChanges.hasNewEmptyField = false;
    }

    stateChanges.keySeed = Math.random();

    // Math.random() is used here so that everytime items are
    // removed from the list, the order is recalculated.

    this.setState(stateChanges);

    if (onRemoveItem) {
      onRemoveItem(value);
    }

    if (onChange) {
      onChange(
        dataList.filter((item, index) => (index !== indexToRemove)),
      );
    }
  }

  handleCreate = (indexToUpdate, value) => {
    const { dataList, onChange, onAddItem } = this.props;
    const { hasNewEmptyField } = this.state;

    if (hasNewEmptyField && indexToUpdate > dataList.length - 1) {
      this.setState({ hasNewEmptyField: false });
    }

    if (onAddItem) {
      onAddItem(value);
    }

    if (onChange) {
      onChange([...dataList, value]);
    }
  }

  handleChange = (indexToUpdate, value) => {
    const { dataList, onChange, onChangeItem } = this.props;
    const { hasNewEmptyField } = this.state;

    if (hasNewEmptyField && indexToUpdate > dataList.length - 1) {
      this.handleCreate(indexToUpdate, value);
      return;
    }

    if (onChangeItem) {
      onChangeItem(value);
      return;
    }

    if (onChange) {
      onChange(dataList.map((item, index) => (
        (index === indexToUpdate) ? value : item
      )));
    }
  }

  keyForIndex = (index) => {
    const { keySeed } = this.state;
    return `${keySeed}-${index}`;
  }

  static defaultValues = {
    hideAddButton: false,
    hideRemoveButton: false,
  }

  render() {
    const {
      dataList,
      disabled,
      hideAddButton,
      hideRemoveButton,
    } = this.props;
    const { hasNewEmptyField } = this.state;

    const addButtonEnabled = !disabled && !hasNewEmptyField;
    const removeButtonEnabled = !disabled && !(hasNewEmptyField && dataList.length === 0);

    let fields = dataList;

    if (hasNewEmptyField) {
      fields = [...fields, undefined];
    }

    return (
      <DynamicList
        {...this.props}
        fields={fields}
        onAdd={this.handleAdd}
        onChange={this.handleChange}
        onRemove={this.handleRemove}
        addButtonEnabled={addButtonEnabled}
        removeButtonEnabled={removeButtonEnabled}
        keyForIndex={this.keyForIndex}
        hideAddButton={hideAddButton}
        hideRemoveButton={hideRemoveButton}
      />
    );
  }
}

export default DynamicListContainer;
