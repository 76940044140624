import { string, object, array } from 'yup';

export const validationSchema = object().shape({
  name: object().shape({
    title: string().nullable().required('Title is required'),
    givenName: string().nullable().required('Given name is required'),
    familyName: string().nullable().required('Family name is required'),
  }),
  address: object().nullable().shape({
    addressLine1: string().nullable().required('First line of address is required'),
    addressLine2: string().nullable(),
    town: string().required('Town is required'),
    county: string().nullable(),
    postCode: string().nullable().required('Post Code is required'),
    countryCode: string().nullable().required('Country is required'),
  }),
  phones: array().of(
    object().shape({
      telephone: object().shape({
        countryCode: string(),
        number: string(),
      }),
      type: string(),
    }),
  ),
  emails: array().of(
    string().nullable().email('Email is not valid'),
  ),
  note: string().nullable(),
});
