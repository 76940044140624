const Promise = require('es6-promise');

if (!('Promise' in window)) {
  window.Promise = Promise;
} else if (!('finally' in window.Promise.prototype)) {
  window.Promise.prototype.finally = Promise.prototype.finally;
}

// fetch() polyfill for making API calls.
require('whatwg-fetch');
