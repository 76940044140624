import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { taskType, taskFiltersType, taskCategoryType } from 'types/task';
import { getServiceData } from 'services/utils/task';
import PrintIcon from '@material-ui/icons/Print';
import IconLoadSml from 'icons/IconLoadSml';
import ScreenLayout from 'components/common/ScreenLayout';
import TaskRow from 'components/tasks/TaskRow';
import TaskFilter from 'components/tasks/TaskFilter';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import IconLoad from 'icons/IconLoad';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';


const customTaskButton = (onClick, disabled) => (
  <Button
    data-test-id="createCustomTask"
    color="primary"
    variant="contained"
    fullWidth
    onClick={onClick}
    disabled={disabled}
  >
    <AddIcon className={styles.u_push__right} />
    {t('New custom task')}
  </Button>
);

const TasksListScreen = ({
  isLoading,
  tasks,
  hasMoreTasks,
  totalTasksFound,
  getTasks,
  filters,
  onFilterChange,
  onViewTask,
  onCreateCustomTask,
  canCreateCustomTask,
  allowedCategories,
  isAllowedToCreateCustomTask,
  onPrintTasks,
  isPrintTasksLoading,
}) => (
  <ScreenLayout
    title={t('Tasks')}
    dashboardBannerProps={{
      breadcrumbs: [{ path: '/', title: t('Dashboard') }, { path: '/tasks', title: t('Tasks') }],
      description: t('View tasks by home, case, category, status or assignee.'),
    }}
  >
    <div className={styles.o_view}>
      <div className={styles.o_view__main}>
        <div className={rcStyles.button_group}>
          {isAllowedToCreateCustomTask && (
            <div className={rcStyles.button_group__button}>
              {(canCreateCustomTask) ? (
                customTaskButton(onCreateCustomTask, false)
              ) : (
                <Tooltip title="To create a custom task, you must select a case">
                  <span>
                    {customTaskButton(onCreateCustomTask, true)}
                  </span>
                </Tooltip>
              )}
            </div>
          )}
          <Button
            color="primary"
            variant="contained"
            onClick={onPrintTasks}
            data-test-id="printTasks"
            disabled={!totalTasksFound}
            className={rcStyles.button_group__button}
          >
            {isPrintTasksLoading ? (
              <div
                className={classNames(
                  styles.c_svg_loader_sml,
                  styles.c_svg_loader_sml_btn,
                  styles.c_svg_loader_sml_white,
                )}
                data-test-id="buttonLoader"
              >
                <IconLoadSml />
              </div>
            ) : (
              <Fragment>
                <PrintIcon className={styles.u_push__right} />
                {t('Print tasks')}
              </Fragment>
            )}
          </Button>
        </div>

        <div className={styles.o_block_content}>
          <h3 className={classNames(
            styles.long_primer,
            styles.u_push__bottom,
          )}
          >
            {t('Search & Filter')}
          </h3>
          <TaskFilter
            filters={filters}
            onFilterChange={onFilterChange}
            allowedCategories={allowedCategories}
          />
        </div>
        <div className={styles.o_block_content}>
          <div className={classNames(
            styles.o_fb,
            styles.u_push__bottom,
          )}
          >
            <div className={styles.brevier}>
              {t('Tasks found: {{ totalTasksFound }}', { totalTasksFound })}
              {isLoading && (
                <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
                  <IconLoad />
                </div>
              )}
            </div>
            <div className={rcStyles.state_key}>
              <div className={classNames(
                rcStyles.is_pending,
                rcStyles.state_key__item,
              )}
              >
                {t('Pending')}
              </div>
              <div className={classNames(
                rcStyles.is_completed,
                rcStyles.state_key__item,
              )}
              >
                {t('Completed')}
              </div>
              <div className={classNames(
                rcStyles.is_dismissed,
                rcStyles.state_key__item,
              )}
              >
                {t('Dismissed')}
              </div>
            </div>
          </div>
          <div>
            <InfiniteScroll
              dataLength={tasks.length}
              hasMore={tasks.length > 0 && hasMoreTasks}
              next={getTasks}
              className={rcStyles.task_row_scroller}
            >
              {
                tasks.map(task => (
                  <TaskRow
                    task={task}
                    onViewTask={onViewTask}
                    key={task.id}
                    serviceData={getServiceData(task)}
                  />
                ))
              }
            </InfiniteScroll>
            <div>
              {tasks.length !== 0 && isLoading && (
                <div className={classNames(
                  styles.o_fb,
                  styles.o_fb__align_center,
                )}
                >
                  <div className={styles.c_svg_loader_infinite_scroll} data-test-id="infiniteScrollLoader">
                    <IconLoad />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  </ScreenLayout>
);

TasksListScreen.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  tasks: PropTypes.arrayOf(taskType),
  hasMoreTasks: PropTypes.bool.isRequired,
  totalTasksFound: PropTypes.number,
  getTasks: PropTypes.func.isRequired,
  filters: taskFiltersType,
  onFilterChange: PropTypes.func.isRequired,
  onViewTask: PropTypes.func.isRequired,
  onCreateCustomTask: PropTypes.func.isRequired,
  canCreateCustomTask: PropTypes.bool.isRequired,
  allowedCategories: PropTypes.arrayOf(taskCategoryType),
  isAllowedToCreateCustomTask: PropTypes.bool.isRequired,
  onPrintTasks: PropTypes.func.isRequired,
  isPrintTasksLoading: PropTypes.bool,
};

export default TasksListScreen;
