import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';

import {
  clearBereavementsAction,
  fetchBereavementAction,
  editBereavementAction,
  updateBereavementAction,
  addClientAction,
  editClientAction,
  removeClientAction,
} from 'actions/bereavement';
import { caseStatuses, emptyBereavement } from 'constants/bereavement';
import { bereavedPersonConnectionTransform } from 'transforms/bereavement';
import { apolloClientType } from 'types/apollo';
import { bereavementType } from 'types/bereavement';
import { matchType, locationType, historyType } from 'types/reactRouter';

import {
  editCaseStatusMutation,
  addBereavedPersonConnection,
  editBereavedPersonConnection,
  removeBereavedPersonConnection,
} from './mutations.gql';
import CaseScreen from './CaseScreen';

class CaseScreenContainer extends Component {
  static propTypes = {
    bereavements: PropTypes.arrayOf(bereavementType.isRequired).isRequired,
    scopes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    client: apolloClientType.isRequired,
    match: matchType.isRequired,
    location: locationType.isRequired,
    history: historyType.isRequired,
    clearBereavements: PropTypes.func.isRequired,
    fetchBereavement: PropTypes.func.isRequired,
    editBereavement: PropTypes.func.isRequired,
    updateBereavement: PropTypes.func.isRequired,
    addClient: PropTypes.func.isRequired,
    editClient: PropTypes.func.isRequired,
    removeClient: PropTypes.func.isRequired,
  }

  static getDerivedStateFromProps(props) {
    const { bereavements, match, fetchBereavement } = props;
    const bereavement = bereavements.find(existing => !existing.isConcise
      && existing.id === match.params.bereavementId);

    if (!bereavement) {
      fetchBereavement(match.params.bereavementId);
    }

    return { bereavement: bereavement || emptyBereavement, isLoading: !bereavement };
  }

  constructor(props) {
    super(props);

    this.state = {
      bereavement: {},
      isLoading: true,
      isMenuOpen: false,
      isModalOpen: false,
      statusToChange: null,
      isAutomaticEmailsConfirmationModalOpen: false,
      editingArrangement: null,
    };
  }

  componentDidMount() {
    const { history } = this.props;

    this.unlisten = history.listen(() => {
      const { isMenuOpen } = this.state;
      if (isMenuOpen) {
        this.setState({ isMenuOpen: false });
      }
    });
  }

  componentWillUnmount() {
    const { clearBereavements } = this.props;

    clearBereavements();
    this.unlisten();
  }

  handleStatusChange = () => {
    const { bereavement } = this.state;
    const status = bereavement.caseStatus === caseStatuses.OPEN ? caseStatuses.CLOSED : caseStatuses.OPEN;
    if (status === caseStatuses.CLOSED) {
      this.setState({ statusToChange: status });
    } else {
      this.editBereavementStatus(status);
    }
  }

  handleStatusChangeConfirm = () => {
    const { statusToChange } = this.state;
    this.editBereavementStatus(statusToChange);
    this.setState({ statusToChange: null });
  }

  editBereavement = (updates) => {
    const { editBereavement } = this.props;
    const { bereavement } = this.state;

    const payload = { ...bereavement };
    updates.forEach(({ key, value }) => {
      payload[key] = value;
    });

    editBereavement(payload);
  }

  editBereavementStatus = (status) => {
    const { client, updateBereavement } = this.props;
    const { bereavement } = this.state;

    updateBereavement(bereavement.id, 'caseStatus', status);
    client.mutate({
      mutation: editCaseStatusMutation,
      variables: {
        input: {
          id: bereavement.id,
          caseStatus: status,
        },
      },
    });
  }

  toggleAutomaticEmailsConfirmationModal = () => {
    this.setState(prevState => ({
      isAutomaticEmailsConfirmationModalOpen: !prevState.isAutomaticEmailsConfirmationModalOpen,
    }));
  }

  handleAutomaticEmailConfirmation = (checked) => {
    const { bereavement: { bereavedPeopleConnections } } = this.state;
    const primaryContact = bereavedPeopleConnections
      .find(connection => connection.isPrimaryContact);

    if (primaryContact && primaryContact.bereavedPerson.emails.length) {
      this.editBereavement([{
        key: 'sendEmailsToBereavedConsent',
        value: checked,
      }]);
      return;
    }
    this.toggleAutomaticEmailsConfirmationModal();
  }

  addBereaved = (bereavementId, bereaved) => {
    const { client, addClient } = this.props;
    const { bereavement } = this.state;

    addClient(bereavement.id, bereaved);

    const input = bereavedPersonConnectionTransform(bereaved, bereavementId);
    client.mutate({
      mutation: addBereavedPersonConnection,
      variables: { input },
    });
  }

  editBereaved = (bereavementId, bereaved) => {
    const { client, editClient } = this.props;
    const { bereavement } = this.state;
    const input = bereavedPersonConnectionTransform(bereaved, bereavementId);

    editClient(bereavement.id, bereaved);

    client.mutate({
      mutation: editBereavedPersonConnection,
      variables: { input },
    });
  }

  removeBereaved = (id) => {
    const { client, removeClient } = this.props;
    const { bereavement } = this.state;

    removeClient(bereavement.id, id);
    const input = {
      bereavementId: bereavement.id,
      id,
    };

    client.mutate({
      mutation: removeBereavedPersonConnection,
      variables: { input },
    });
  }

  toggleMenu = () => {
    this.setState(prevState => ({
      isMenuOpen: !prevState.isMenuOpen,
    }));
  }

  handleModalOpen = (arrangement) => {
    this.setState({
      isModalOpen: true,
      editingArrangement: arrangement || null,
    });
  }

  handleModalClose = () => {
    this.setState({
      isModalOpen: false,
      editingArrangement: null,
    });
  }

  render() {
    const { match, location, scopes } = this.props;
    const {
      bereavement,
      editingArrangement,
      isLoading,
      isMenuOpen,
      isModalOpen,
      deceasedForm,
      statusToChange,
      isAutomaticEmailsConfirmationModalOpen,
    } = this.state;

    const primaryContactIndex = bereavement
      .bereavedPeopleConnections
      .findIndex(bereaved => bereaved.isPrimaryContact);

    const hasPrimaryContactSetEmail = primaryContactIndex !== -1 && bereavement
      .bereavedPeopleConnections[primaryContactIndex]
      .bereavedPerson
      .emails.length !== 0;

    return (
      <CaseScreen
        match={match}
        locationState={location.state}
        bereavement={bereavement}
        scopes={scopes}
        isLoading={isLoading}
        isMenuOpen={isMenuOpen}
        deceasedForm={deceasedForm}
        statusToChange={statusToChange}
        isAutomaticEmailsConfirmationModalOpen={isAutomaticEmailsConfirmationModalOpen}
        hasPrimaryContactSetEmail={hasPrimaryContactSetEmail}
        hasPrimaryContact={primaryContactIndex !== -1}
        setIsDeceasedFormValid={this.setIsDeceasedFormValid}
        toggleMenu={this.toggleMenu}
        onAutomaticEmailConfirmation={this.handleAutomaticEmailConfirmation}
        onStatusChangeConfirm={this.handleStatusChangeConfirm}
        onStatusChangeCancel={() => this.setState({ statusToChange: null })}
        onStatusChange={this.handleStatusChange}
        editBereavement={this.editBereavement}
        addBereaved={this.addBereaved}
        editBereaved={this.editBereaved}
        removeBereaved={this.removeBereaved}
        toggleAutomaticEmailsConfirmationModal={this.toggleAutomaticEmailsConfirmationModal}
        editingArrangement={editingArrangement}
        isModalOpen={isModalOpen}
        onModalOpen={this.handleModalOpen}
        onModalClose={this.handleModalClose}
      />
    );
  }
}

const mapStateToProps = state => ({
  bereavements: state.bereavementStore.bereavements,
  scopes: state.userStore.user.policy.scopes,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  clearBereavements: clearBereavementsAction,
  fetchBereavement: fetchBereavementAction,
  editBereavement: editBereavementAction,
  updateBereavement: updateBereavementAction,
  addClient: addClientAction,
  editClient: editClientAction,
  removeClient: removeClientAction,
}, dispatch);

export default withApollo(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CaseScreenContainer),
  ),
);
