import React from 'react';
import PropTypes from 'prop-types';

import istyle from './invoiceloader.scss';

const IconCreateArrangement = ({
  className,
}) => {
  const classes = className ? `${className}` : istyle.doc_loader;
  return (
    <svg className={classes} height="195" viewBox="0 0 157 195" width="157" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <path id="a" d="m4.00050676 0h116.99949324c2.209139 0 4 1.790861 4 4v155c0 2.209139-1.790861 4-4 4h-116.99949324c-2.209139 0-4-1.790861-4-4v-155c0-2.209139 1.790861-4 4-4z" />
        <filter id="b" height="130.7%" width="140%" x="-20%" y="-14.1%">
          <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="8" />
          <feColorMatrix in="shadowBlurOuter1" type="matrix" values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.100824956 0" />
        </filter>
        <mask id="c" fill="#fff">
          <path d="m0 0h31.99984v32h-31.99984z" fill="#fff" fillRule="evenodd" />
        </mask>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(16 14)">
        <use fill="#000" filter="url(#b)" xlinkHref="#a" />
        <use fill="#fff" fillRule="evenodd" xlinkHref="#a" />
        <g transform="translate(46 15)">
          <path d="m31.99984 16c0 8.8368-7.1632 16-16 16s-16-7.1632-16-16 7.1632-16 16-16 16 7.1632 16 16" fill="#8158a7" mask="url(#c)" />
          <g stroke="#fff">
            <path d="m19.8095238 8.9047619c0 2.1036191-1.7051428 3.8095238-3.8095238 3.8095238-2.103619 0-3.8095238-1.7059047-3.8095238-3.8095238 0-2.10361904 1.7059048-3.8095238 3.8095238-3.8095238 2.104381 0 3.8095238 1.70590476 3.8095238 3.8095238z" />
            <path d="m16 16c4.4221277 0 8 3.5778723 8 8m-16 0c0-4.4221277 3.5785532-8 8-8" />
          </g>
        </g>
        <path d="m89.8623188 62h-54.7246376c-1.1757247 0-2.1376812-1.125-2.1376812-2.5s.9619565-2.5 2.1376812-2.5h54.7246376c1.1757247 0 2.1376812 1.125 2.1376812 2.5s-.9619565 2.5-2.1376812 2.5" fill="#8158a7" opacity=".5" />
        <path d="m75.5630937 70h-24.1270312c-.78975 0-1.4360625-.766-1.4360625-1.703v-1.594c0-.937.6463125-1.703 1.4360625-1.703h24.1270312c.7905938 0 1.4369063.766 1.4369063 1.703v1.594c0 .937-.6463125 1.703-1.4369063 1.703" fill="#8158a7" opacity=".5" />
        <path d="m29 112.5c0 4.69455-3.8063 8.5-8.5 8.5s-8.5-3.80545-8.5-8.5 3.8063-8.5 8.5-8.5 8.5 3.80545 8.5 8.5" fill="#ffb702" />
        <path d="m35.1376812 109c-1.1757247 0-2.1376812-1.125-2.1376812-2.5s.9619565-2.5 2.1376812-2.5h54.7246376c1.1757247 0 2.1376812 1.125 2.1376812 2.5s-.9619565 2.5-2.1376812 2.5z" fill="#8158a7" opacity=".25" />
        <path d="m58.5630937 116h-24.1270312c-.78975 0-1.4360625-.766-1.4360625-1.703v-1.594c0-.937.6463125-1.703 1.4360625-1.703h24.1270312c.7905938 0 1.4369063.766 1.4369063 1.703v1.594c0 .937-.6463125 1.703-1.4369063 1.703" fill="#8158a7" opacity=".25" />
        <path d="m29 135.5c0 4.69455-3.8063 8.5-8.5 8.5s-8.5-3.80545-8.5-8.5 3.8063-8.5 8.5-8.5 8.5 3.80545 8.5 8.5" fill="#00b390" />
        <g fill="#8158a7">
          <path d="m89.8623188 132h-54.7246376c-1.1757247 0-2.1376812-1.125-2.1376812-2.5s.9619565-2.5 2.1376812-2.5h54.7246376c1.1757247 0 2.1376812 1.125 2.1376812 2.5s-.9619565 2.5-2.1376812 2.5" opacity=".25" />
          <path d="m58.5630937 139h-24.1270312c-.78975 0-1.4360625-.766-1.4360625-1.703v-1.594c0-.937.6463125-1.703 1.4360625-1.703h24.1270312c.7905938 0 1.4369063.766 1.4369063 1.703v1.594c0 .937-.6463125 1.703-1.4369063 1.703" opacity=".25" />
        </g>
      </g>
    </svg>
  );
};

IconCreateArrangement.propTypes = {
  className: PropTypes.string,
};

export default IconCreateArrangement;
