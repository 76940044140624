import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import { dashboardBannerType } from 'types/common';
import Breadcrumbs from 'components/common/Breadcrumbs';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const DashboardBanner = ({
  breadcrumbs,
  title,
  subtitle,
  buttons,
  description,
  header,
  footnote,
}) => (
  <AppBar position="static" elevation={0}>
    <div className={styles.c_page__header}>
      <div className={styles.is_breadcrumb}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </div>
      <div className={styles.c_page__header_main}>
        {title && (
          <h1 className={classNames(
            styles.double_pica,
            styles.is_white,
          )}
          >
            {title}
          </h1>
        )}
        {subtitle && (
          <div className={styles.o_fb__item}>
            {subtitle}
          </div>
        )}
        {description && (
          <p className={rcStyles.banner_description}>
            {description}
          </p>
        )}
      </div>
      {header && (
        <div className={styles.is_utility_button}>
          {header}
        </div>
      )}
      {buttons && (
        <div className={styles.is_utility_list}>
          {buttons}
        </div>
      )}
      {footnote && (
        <div className={styles.is_info_row}>
          {footnote}
        </div>
      )}
    </div>

  </AppBar>
);

DashboardBanner.propTypes = dashboardBannerType;

export default DashboardBanner;
