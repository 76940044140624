import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { homeType } from 'types/bereavement';
import { organisationalUnitTypes } from 'constants/organisationalUnits';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import OrganisationalUnitLookup from 'components/common/lookups/OrganisationalUnitLookup';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const AdminCataloguePreviewModal = ({
  isOpen,
  onPreview,
  onCancel,
  selectedHome,
  onChangeHome,
  errors,
}) => (
  <Dialog
    onClose={onCancel}
    fullWidth
    maxWidth="sm"
    open={isOpen}
    TransitionComponent={Transition}
    data-test-id="previewCatalogueDialog"
    disableBackdropClick
    disableEscapeKeyDown
  >
    <DialogTitle disableTypography className={styles.c_dialog__header}>
      <div
        className={classNames(styles.c_dialog__title)}
        data-test-id="previewCatalogueTitle"
      >
        {t('Online brochure - preview')}
      </div>
    </DialogTitle>
    <DialogContent
      className={classNames(
        styles.c_dialog__content,
        styles.o_fb,
      )}
    >
      <div
        className={styles.o_fb__item}
        data-test-id="previewCatalogueDescription"
      >
        {t('Product and service visibility can be configured per home. Select a home to preview the online brochure. Only products and services that you have configured to be visible to the selected home will be included.')}
      </div>
      <div
        className={classNames(
          styles.o_fb__item,
          styles.u_push__top,
        )}
      >
        <OrganisationalUnitLookup
          value={selectedHome}
          label="Select home"
          onChange={value => onChangeHome(value)}
          data-test-id="organisationalUnitLookup"
          type={organisationalUnitTypes.HOME}
        />
        {errors && errors.selectedHome && (
          <FormHelperText className={rcStyles.rc_error} error>{errors.selectedHome}</FormHelperText>
        )}
      </div>
    </DialogContent>
    <DialogActions
      className={styles.u_island}
    >
      <Button
        onClick={onCancel}
        variant="outlined"
        color="primary"
        data-test-id="previewCatalogueCancel"
        fullWidth
      >
        {t('Cancel')}
      </Button>
      <Button
        onClick={onPreview}
        variant="contained"
        color="primary"
        data-test-id="previewCatalogueConfirm"
        fullWidth
      >
        {t('Preview brochure')}
      </Button>
    </DialogActions>
  </Dialog>
);

AdminCataloguePreviewModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onPreview: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  selectedHome: homeType,
  onChangeHome: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AdminCataloguePreviewModal;
