import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CloseIcon from '@material-ui/icons/Close';
import { ALL_NOTES } from 'constants/bereavement';
import { getNoteCategories } from 'services/utils/bereavement';
import { noteType } from 'types/common';
import { staffMemberType } from 'types/staffMember';
import DynamicList from 'components/common/DynamicList';
import NoteComponent from 'components/common/Note';
import NoteCategoriesList from 'components/common/NoteCategoriesList';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const NotesModal = ({
  notesList,
  currentStaff,
  disabled,
  errors,
  selectedCategory,
  onSelectCategory,
  restrictedCategories,
  onAdd,
  onChange,
  onRemove,
  onSave,
  onClose,
  isOpen,
}) => {
  const filteredNotesList = selectedCategory !== ALL_NOTES
    ? notesList.filter(note => note.category === selectedCategory)
    : notesList;

  const notesErrorLookup = notesList.reduce((accumulator, note, index) => {
    const { category } = note;
    if (category === selectedCategory || selectedCategory === ALL_NOTES) {
      return [
        ...accumulator,
        { originalIndex: index },
      ];
    }
    return accumulator;
  }, []);

  const categoryErrors = {};
  Object.keys(errors).forEach((key) => {
    const index = notesErrorLookup.findIndex(value => value.originalIndex === parseInt(key, 10));
    categoryErrors[index] = errors[key];
  });

  return (
    <Dialog
      open={isOpen}
      fullScreen
      scroll="paper"
      TransitionComponent={Transition}
      className={styles.c_dialogue}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle
        disableTypography
        className={styles.dialog_title_bar}
      >
        <IconButton
          className={classNames(styles.o_button__secondary)}
          color="primary"
          variant="contained"
          aria-label="Close"
          onClick={onClose}
          data-test-id="notesClose"
        >
          <CloseIcon />
        </IconButton>
        <div
          className={styles.is_title}
          data-test-id="notesTitle"
        >
          {t('Notes')}
        </div>
        <div className={styles.is_actions}>
          <Button
            onClick={onSave}
            variant="contained"
            color="secondary"
            data-test-id="saveAndReturn"
            disabled={disabled}
            className={styles.o_button__secondary}
          >
            {t('Save & return')}
          </Button>
        </div>
      </DialogTitle>
      <div className={styles.c_dialog__overflow}>
        <DialogContent
          className={classNames(
            styles.c_page_has_aside,
            rcStyles.rc_layout,
          )}
        >
          <div
            className={classNames(
              styles.c_page_aside,
              styles.is_contained,
              styles.rc_page__full_height,
              styles.has_shadow,
            )}
          >
            <NoteCategoriesList
              categories={getNoteCategories(notesList, restrictedCategories)}
              selectedCategory={selectedCategory}
              onSelectCategory={onSelectCategory}
            />
          </div>
          <div
            className={styles.c_page_main}
            data-test-id="notesContainer"
          >
            <DynamicList
              isStacked
              component={(
                <NoteComponent currentStaff={currentStaff} />
              )}
              addButtonProps={{
                variant: 'contained',
                color: 'primary',
              }}
              hideAddButton={selectedCategory === ALL_NOTES}
              addButtonInner={(
                <Fragment>
                  <AddIcon />
                  {t('Add note')}
                </Fragment>
              )}
              removeButtonProps={{
                variant: 'outlined',
                color: 'primary',
                classes: { root: styles.o_button__round },
              }}
              removeButtonInner={
                <RemoveIcon />
              }
              dataList={filteredNotesList}
              onAddItem={onAdd}
              onChangeItem={onChange}
              onRemoveItem={onRemove}
              disabled={disabled}
              errors={categoryErrors}
              data-test-id="notes"
            />
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};

NotesModal.propTypes = {
  notesList: PropTypes.arrayOf(noteType).isRequired,
  currentStaff: staffMemberType.isRequired,
  disabled: PropTypes.bool,
  errors: PropTypes.objectOf(PropTypes.any),
  selectedCategory: PropTypes.string,
  onSelectCategory: PropTypes.func.isRequired,
  onAdd: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  restrictedCategories: PropTypes.arrayOf(PropTypes.string),
};

export default NotesModal;
