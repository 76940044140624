import React, { Fragment } from 'react';
import { t } from 'i18next';
import { navigationPathType } from 'types/common';
import { MuiThemeProvider } from '@material-ui/core/styles';
import overrideTheme from 'services/themes/overrideTheme';
import PropTypes from 'prop-types';
import IconMenu from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';
import IconClear from '@material-ui/icons/Clear';
import LeftArrowIcon from '@material-ui/icons/ChevronLeft';
import RightArrowIcon from '@material-ui/icons/ChevronRight';

import ArrangementNavigationSelection from 'components/arrangement/ArrangementNavigationSelection';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const ArrangementNavigationMenu = ({
  onEstimateClick,
  nextPathName,
  previousPathName,
  onNext,
  onPrevious,
  navigationPaths,
  isOpen,
  toggleMenu,
  currentIndex,
}) => (
  <Fragment>
    <MuiThemeProvider theme={overrideTheme}>
      <div
        className={isOpen ? styles.is_active : ''}
        onClick={toggleMenu}
        tabIndex="-1"
        role="button"
        data-test-id="navigationContainer"
      />
      <div
        className={classNames(
          rcStyles.case_menu,
          isOpen ? rcStyles.is_active : '',
        )}
      >
        <div className={rcStyles.case_menu__head}>
          <div className={rcStyles.previous_section}>
            {(previousPathName && !isOpen) && (
              <Button
                className={rcStyles.menu__button}
                size="medium"
                color="primary"
                variant="text"
                tabIndex="0"
                onClick={previousPathName && onPrevious}
                data-test-id="previous"
              >
                <LeftArrowIcon />
                <span className={rcStyles.previous_text}>{t(previousPathName)}</span>
              </Button>
            )}
          </div>
          <Button
            className={rcStyles.menu__button}
            size="medium"
            color="primary"
            variant="text"
            tabIndex="0"
            onClick={toggleMenu}
            data-test-id="menu"
          >
            {isOpen
              ? <IconClear />
              : <IconMenu /> }
            {t('Menu')}
          </Button>
          <div className={rcStyles.next_section}>
            {(nextPathName && !isOpen) && (
              <Button
                className={rcStyles.menu__button}
                size="medium"
                color="primary"
                variant="text"
                tabIndex="0"
                onClick={nextPathName && onNext}
                data-test-id="next"
              >
                <span className={rcStyles.next_text}>{t(nextPathName)}</span>
                <RightArrowIcon />
              </Button>
            )}
          </div>
        </div>
        <div
          className={classNames(
            rcStyles.case_menu__grid,
            isOpen ? styles.is_active : '',
          )}
        >
          {navigationPaths.map((navigationPath, index) => (
            <ArrangementNavigationSelection
              navigationPath={navigationPath}
              key={index}
              isActive={currentIndex === index}
              toggleMenu={toggleMenu}
            />
          ))}
        </div>

        <div className={classNames(
          rcStyles.case_menu__actions,
          isOpen ? styles.is_active : '',
        )}
        >
          <Button
            variant="outlined"
            color="secondary"
            onClick={onEstimateClick}
            data-test-id="estimate"
          >
            Estimate
          </Button>
        </div>
      </div>
    </MuiThemeProvider>
  </Fragment>
);

ArrangementNavigationMenu.propTypes = {
  onEstimateClick: PropTypes.func.isRequired,
  nextPathName: PropTypes.string,
  previousPathName: PropTypes.string,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  navigationPaths: PropTypes.arrayOf(navigationPathType),
  isOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  currentIndex: PropTypes.number.isRequired,
};

export default ArrangementNavigationMenu;
