import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { withValidation } from '@funeralguide/react-form-validation-hoc';

import { updateArrayByIndex } from 'services/utils';
import { getSortedActions } from 'services/utils/bereavement';
import { staffMemberType } from 'types/staffMember';

import { validationSchema } from './validation';
import DeedModal from './DeedModal';

export class DeedModalContainer extends Component {
  static propTypes = {
    deedBeingModified: PropTypes.objectOf(PropTypes.any),
    isOpen: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    generateRefs: PropTypes.func.isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
    setErrors: PropTypes.func.isRequired,
    currentStaffMember: staffMemberType,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isOpen
      && nextProps.deedBeingModified
      && !Object.keys(prevState.formData).length) {
      return {
        formData: {
          id: nextProps.deedBeingModified.id,
          rightOfBurialNumber: nextProps.deedBeingModified.rightOfBurialNumber || '',
          graveNumber: nextProps.deedBeingModified.graveNumber || null,
          section: nextProps.deedBeingModified.section || '',
          dateReceived: nextProps.deedBeingModified.dateReceived || null,
          cemeteryName: nextProps.deedBeingModified.cemeteryName || '',
          notes: nextProps.deedBeingModified.notes || '',
        },
        documents: nextProps.deedBeingModified.documents || [],
        actions: nextProps.deedBeingModified.actions || [],
        isValidationEnabled: false,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      formData: {},
      documents: [],
      actions: [],
      isValidationEnabled: false,
    };

    this.dialogRef = React.createRef();
  }

  componentDidMount() {
    const { generateRefs } = this.props;
    generateRefs(Object.keys(validationSchema.fields));
  }

  resetState = () => {
    this.setState({
      formData: {}, documents: [], actions: [], isValidationEnabled: false,
    });
  }

  handleChange = (key, value) => {
    const { validate } = this.props;
    const { formData, isValidationEnabled } = this.state;
    const updatedFormData = {
      ...formData,
      [key]: value,
    };

    if (isValidationEnabled) {
      validate(updatedFormData, validationSchema);
    }

    this.setState({ formData: updatedFormData });
  }

  handleDocumentsChange = (value) => {
    if (!value && !value.length) {
      return;
    }

    const documents = value.map(document => ({
      id: document.id || null,
      title: document.title,
      mediaId: document.media.id,
    }));

    this.setState({ documents });
  }

  handleUploadComplete = ([media], file) => {
    const { documents } = this.state;
    const newDocument = {
      id: null,
      title: file.name,
      mediaId: media.id,
      media,
    };

    this.setState({ documents: [...documents, newDocument] });
  }

  handleAddAction = () => {
    const { currentStaffMember } = this.props;
    const { actions } = this.state;
    const updatedActions = [
      ...actions,
      {
        action: null,
        timeCreated: moment().format(),
        createdById: currentStaffMember.id,
        createdBy: { ...currentStaffMember },
        isNew: true,
      },
    ];
    this.setState({ actions: updatedActions });
  }

  handleActionChange = (value, index) => {
    const { actions } = this.state;
    const action = actions[index];
    const updatedActions = updateArrayByIndex(
      actions,
      index,
      {
        ...action,
        action: value,
      },
    );
    this.setState({ actions: updatedActions });
  }

  handleClose = () => {
    const { onClose, setErrors } = this.props;
    this.resetState();
    setErrors(null);
    onClose();
  }

  handleSave = () => {
    const { onSave, validate } = this.props;
    const { formData, documents, actions } = this.state;
    const { isNew, ...otherFormDataProps } = formData;

    const updatedDeed = {
      ...otherFormDataProps,
      actions: actions.map(({ isNew: isNewAction, ...otherActionProps }) => otherActionProps),
      documents,
    };

    this.setState({ isValidationEnabled: true });
    const isValid = validate(formData, validationSchema, true, this.dialogRef);

    if (isValid) {
      onSave(updatedDeed);
      this.resetState();
    }
  }

  render() {
    const {
      isOpen, disabled, errors, formRefs,
    } = this.props;
    const { formData, documents, actions } = this.state;
    const sortedActions = getSortedActions(actions, 'timeCreated');

    return (
      <DeedModal
        formData={formData}
        documents={documents}
        actions={sortedActions}
        isOpen={isOpen}
        disabled={disabled}
        onChange={this.handleChange}
        onDocumentsChange={this.handleDocumentsChange}
        onActionChange={this.handleActionChange}
        onUploadComplete={this.handleUploadComplete}
        onAddAction={this.handleAddAction}
        onClose={this.handleClose}
        onSave={this.handleSave}
        dialogRef={this.dialogRef}
        errors={errors}
        formRefs={formRefs}
      />
    );
  }
}

const mapStateToProps = state => ({
  currentStaffMember: state.userStore.user.staffMember,
});

export default withValidation(connect(mapStateToProps)(DeedModalContainer));
