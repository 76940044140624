import { ApolloClient } from 'apollo-client';
import { Dispatch } from 'redux';
import {
  SetAnonymousUserAction,
  SetHomeAction,
  SetIsPortalActiveAction,
  SetTenantAction,
  AddCatalogueItemsAction,
  ResetStoreAction,
  GetHomeResponse,
  GetTenantResponse,
  GetCatalogueItemsResponse,
  SetCatalogueItemsLoadingAction,
  ResetCatalogueItemsAction,
  PortalAction,
  SetCataloguePackagesLoadingAction,
  AddCataloguePackagesAction,
  ResetCataloguePackagesAction,
  GetCataloguePackagesResponse,
} from 'actions/portal.types';
import { initialState } from 'reducers/portal';
import {
  CatalogueItemOrder,
  CatalogueItemType,
  Package,
  Product,
  ProductCategory,
  Service,
  ServiceCategory,
} from 'types/ts/catalogue';
import { OrganisationalUnit } from 'types/ts/organisationalUnit';
import { Tenant } from 'types/ts/tenant';
import { RootState } from 'types/ts/state';
import { AnonymousUser } from 'types/ts/user';
import { Pagination } from 'types/ts';
import {
  getPackages,
  getProducts,
  getOrganisationalUnit,
  getServices,
  getTenant as getTenantQuery,
} from './portal.queries.gql';

export const setIsPortalActiveAction = (payload: boolean): SetIsPortalActiveAction => ({
  type: PortalAction.SET_IS_PORTAL_ACTIVE, payload,
});

export const setAnonymousUserAction = (payload: AnonymousUser): SetAnonymousUserAction => ({
  type: PortalAction.SET_ANONYMOUS_USER, payload,
});

export const setHomeAction = (payload: OrganisationalUnit|null): SetHomeAction => ({
  type: PortalAction.SET_HOME, payload,
});

export const setTenantAction = (payload: Tenant|null): SetTenantAction => ({
  type: PortalAction.SET_TENANT, payload,
});

export const setCatalogueItemsLoadingAction = (payload: boolean): SetCatalogueItemsLoadingAction => ({
  type: PortalAction.SET_CATALOGUE_ITEMS_LOADING, payload,
});

export const addCatalogueItemsAction = (
  items: Product[]|Service[],
  pagination: Pagination,
  order: CatalogueItemOrder,
  type: CatalogueItemType,
  category: ProductCategory|ServiceCategory,
): AddCatalogueItemsAction => ({
  type: PortalAction.ADD_CATALOGUE_ITEMS,
  payload: {
    items,
    pagination,
    order,
    type,
    category,
  },
});

export const resetCatalogueItemsAction = (): ResetCatalogueItemsAction => ({
  type: PortalAction.RESET_CATALOGUE_ITEMS,
});

export const setCataloguePackagesLoadingAction = (payload: boolean): SetCataloguePackagesLoadingAction => ({
  type: PortalAction.SET_CATALOGUE_PACKAGES_LOADING, payload,
});

export const addCataloguePackagesAction = (
  items: Package[],
  pagination: Pagination,
  order: CatalogueItemOrder,
): AddCataloguePackagesAction => ({
  type: PortalAction.ADD_CATALOGUE_PACKAGES,
  payload: {
    items,
    pagination,
    order,
  },
});

export const resetCataloguePackagesAction = (): ResetCataloguePackagesAction => ({
  type: PortalAction.RESET_CATALOGUE_PACKAGES,
});

export const resetPortalStoreAction = (): ResetStoreAction => ({
  type: PortalAction.RESET_PORTAL_STORE,
});

export const getHomeAction = (homeId: string|null) => (
  dispatch: Dispatch,
  getState: () => RootState,
  client: ApolloClient<unknown>,
) => {
  client.query({
    query: getOrganisationalUnit,
    variables: { id: homeId },
  }).then(({ data }: GetHomeResponse) => {
    const { organisationalUnit } = data;
    dispatch(setHomeAction(organisationalUnit));
  });
};

export const getTenantAction = (tenantId: string|null) => (
  dispatch: Dispatch,
  getState: () => RootState,
  client: ApolloClient<unknown>,
) => {
  client.query({
    query: getTenantQuery,
    variables: { id: tenantId },
  }).then(({ data }: GetTenantResponse) => {
    const { tenant } = data;
    dispatch(setTenantAction(tenant));
  });
};

export const getCatalogueItemsAction = (
  type: CatalogueItemType,
  category: ProductCategory|ServiceCategory,
  homeId: string,
  order?: CatalogueItemOrder,
) => (
  dispatch: Dispatch,
  getState: () => RootState,
  client: ApolloClient<unknown>,
) => {
  const { catalogueItems } = getState()?.portalStore;
  const {
    isLoading,
    pagination: currentPagination,
    category: currentCategory,
    order: currentOrder,
  } = catalogueItems;

  if (isLoading) {
    return;
  }

  dispatch(setCatalogueItemsLoadingAction(true));

  let pagination: Pagination = { ...currentPagination };
  let itemsOrder: CatalogueItemOrder = order || { ...currentOrder };
  if (currentCategory !== category) {
    pagination = {
      ...pagination,
      after: null,
      hasNextPage: true,
    };
    itemsOrder = order || {
      ...initialState.catalogueItems.order,
    };
  }

  const isProductType = type === CatalogueItemType.PRODUCT;
  const query = isProductType ? getProducts : getServices;
  const variables = {
    pagination: {
      first: pagination.first,
      after: pagination.after,
    },
    categories: [category],
    organisationalUnitIds: [homeId],
    order: itemsOrder,
  };

  client.query({
    query,
    variables,
  }).then(({ data }: GetCatalogueItemsResponse) => {
    const itemRoot = isProductType ? data?.products : data?.services;
    const items = itemRoot?.edges?.map(({ node }) => node) || [];
    const updatedPagination: Pagination = {
      ...pagination,
      after: itemRoot?.pageInfo.endCursor || null,
      hasNextPage: !!itemRoot?.pageInfo.hasNextPage,
    };

    dispatch(addCatalogueItemsAction(items, updatedPagination, itemsOrder, type, category));
    dispatch(setCatalogueItemsLoadingAction(false));
  });
};

export const getNextCatalogueItemsAction = () => (
  dispatch: Dispatch,
  getState: () => RootState,
  client: ApolloClient<unknown>,
) => {
  const { catalogueItems, home } = getState()?.portalStore;
  const { type, category, order } = catalogueItems;
  const { id: homeId } = home || {};

  if (!type || !category || !homeId || !order) {
    return;
  }

  getCatalogueItemsAction(
    type,
    category,
    homeId,
    order,
  )(dispatch, getState, client);
};

export const getCataloguePackagesAction = (
  homeId: string,
  order?: CatalogueItemOrder,
) => (
  dispatch: Dispatch,
  getState: () => RootState,
  client: ApolloClient<unknown>,
) => {
  const { cataloguePackages } = getState()?.portalStore;
  const {
    isLoading,
    pagination: currentPagination,
    order: currentOrder,
  } = cataloguePackages;

  if (isLoading) {
    return;
  }

  dispatch(setCataloguePackagesLoadingAction(true));

  const pagination: Pagination = { ...currentPagination };
  const itemsOrder: CatalogueItemOrder = order || { ...currentOrder };
  const query = getPackages;
  const variables = {
    pagination: {
      first: pagination.first,
      after: pagination.after,
    },
    organisationalUnitIds: [homeId],
    order: itemsOrder,
  };

  client.query({
    query,
    variables,
  }).then(({ data }: GetCataloguePackagesResponse) => {
    const itemRoot = data?.packages;
    const items = itemRoot?.edges?.map(({ node }) => node) || [];
    const updatedPagination: Pagination = {
      ...pagination,
      after: itemRoot?.pageInfo.endCursor || null,
      hasNextPage: !!itemRoot?.pageInfo.hasNextPage,
    };

    dispatch(addCataloguePackagesAction(items, updatedPagination, itemsOrder));
    dispatch(setCataloguePackagesLoadingAction(false));
  });
};

export const getNextCataloguePackagesAction = () => (
  dispatch: Dispatch,
  getState: () => RootState,
  client: ApolloClient<unknown>,
) => {
  const { cataloguePackages, home } = getState()?.portalStore;
  const { order } = cataloguePackages;
  const { id: homeId } = home || {};

  if (!homeId || !order) {
    return;
  }

  getCataloguePackagesAction(
    homeId,
    order,
  )(dispatch, getState, client);
};
