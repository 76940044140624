import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Amount from 'components/common/Amount';
import { totalsType } from 'types/bereavement';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const AccountsListSummary = ({ totals, hasXeroIntegration }) => (
  <div className={rcStyles.balances}>
    <Amount
      label={t('Uninvoiced')}
      labelClasses={[
        rcStyles.is_uninvoiced,
        rcStyles.amount_label,
      ]}
      amount={totals
          && (!hasXeroIntegration ? totals.uninvoiced?.count : totals.totalInvoiceTemplatesUninvoiced?.count)}
      amountProps={{ 'data-test-id': 'accountsUninvoiced' }}
    />
    <Amount
      label={t('Uninvoiced amount')}
      labelClasses={[
        rcStyles.is_uninvoiced,
        rcStyles.amount_label,
      ]}
      amount={totals
          && (!hasXeroIntegration ? totals.uninvoiced?.total : totals.totalInvoiceTemplatesUninvoiced?.total)}
      amountProps={{ 'data-test-id': 'accountsUninvoicedAmount' }}
      amountClasses={[styles.is_white]}
    />

    {!hasXeroIntegration && (
      <>
        <Amount
          label={t('Outstanding')}
          labelClasses={[
            rcStyles.is_outstanding,
            rcStyles.amount_label,
          ]}
          amount={totals
            && totals.withOutstandingBalance?.count}
          amountProps={{ 'data-test-id': 'accountsWithOutstandingBalance' }}
        />
        <Amount
          label={t('Outstanding balance')}
          labelClasses={[
            rcStyles.is_outstanding,
            rcStyles.amount_label,
          ]}
          amount={totals
            && totals.withOutstandingBalance?.total}
          amountProps={{ 'data-test-id': 'accountsWithOutstandingBalanceAmount' }}
          amountClasses={[styles.is_white]}
        />
      </>
    )}
  </div>
);

AccountsListSummary.propTypes = {
  totals: totalsType.isRequired,
  hasXeroIntegration: PropTypes.bool.isRequired,
};

export default AccountsListSummary;
