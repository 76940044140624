import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';

import ClientListSection from 'components/client/ClientListSection';
import { bereavementType, bereavedPersonConnectionType } from 'types/bereavement';
import { reactRefType } from 'types/common';

import classNames from 'classnames';
import styles from 'scss/main.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const AshesRecipientModal = ({
  isOpen,
  recipientId,
  bereavement,
  bereavedPeopleConnections,
  disabled,
  onChange,
  onClose,
  onSave,
  onAddRecipient,
  onEditRecipient,
  onRemoveRecipient,
  dialogRef,
}) => (
  <Dialog
    open={isOpen}
    fullScreen
    scroll="paper"
    TransitionComponent={Transition}
    className={styles.c_dialogue}
    disableBackdropClick
    disableEscapeKeyDown
  >
    <DialogTitle
      disableTypography
      className={styles.dialog_title_bar}
    >
      <IconButton
        className={classNames(
          styles.o_button__secondary,
          styles.is_close,
        )}
        color="primary"
        aria-label="Close"
        onClick={onClose}
        data-test-id="changeRecipientClose"
      >
        <CloseIcon />
      </IconButton>
      <div
        className={styles.is_title}
        data-test-id="changeRecipientTitle"
      >
        {t('Change recipient')}
      </div>
      <div className={styles.is_actions}>
        <Button
          onClick={() => onSave()}
          variant="contained"
          color="secondary"
          data-test-id="saveAndReturnToAshes"
          disabled={disabled}
          className={styles.o_button__secondary}
        >
          {t('Save & return to ashes')}
        </Button>
      </div>
    </DialogTitle>
    <div
      ref={dialogRef}
      className={classNames(
        styles.c_dialog__overflow,
      )}
    >
      <div className={styles.o_view}>
        <DialogContent className={styles.o_view__main}>
          <ClientListSection
            bereavementId={bereavement.id}
            bereavedPeopleConnections={bereavedPeopleConnections}
            selectedClientId={recipientId}
            disabled={disabled}
            addClient={onAddRecipient}
            editClient={onEditRecipient}
            removeClient={onRemoveRecipient}
            onSelectedClientChange={onChange}
            displayRemove={false}
          />
        </DialogContent>
      </div>
    </div>
  </Dialog>
);

AshesRecipientModal.propTypes = {
  recipientId: PropTypes.string,
  bereavement: bereavementType.isRequired,
  bereavedPeopleConnections: PropTypes.arrayOf(bereavedPersonConnectionType).isRequired,
  isOpen: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  dialogRef: reactRefType.isRequired,
  onAddRecipient: PropTypes.func.isRequired,
  onEditRecipient: PropTypes.func.isRequired,
  onRemoveRecipient: PropTypes.func.isRequired,
};

export default AshesRecipientModal;
