import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import classNames from 'classnames';

import styles from 'scss/main.scss';
import { catalogueCategoryType } from 'types/catalogue';

import rcStyles from './styles.scss';

const UnsortableCategoryItem = ({
  category,
  onEditCategory,
}) => (
  <div
    className={rcStyles.sortable_tile}
    data-test-id={`${category.reference}SectionConfiguration`}
  >
    <div
      style={{ backgroundImage: `url('${category.imagePath}')` }}
      className={rcStyles.rc_img_sortable}
    />
    <div className={rcStyles.drag_drop}>
      <div className={rcStyles.is_label}>
        {category.name}
      </div>
      <IconButton
        className={classNames(
          styles.o_button__secondary,
          styles.o_button__round__small,
          rcStyles.is_edit_icon,
        )}
        color="primary"
        variant="contained"
        aria-label="Close"
        onClick={() => onEditCategory(category.path)}
        data-test-id="closeModal"
      >
        <EditIcon />
      </IconButton>
    </div>
  </div>
);

UnsortableCategoryItem.propTypes = {
  category: catalogueCategoryType.isRequired,
  onEditCategory: PropTypes.func.isRequired,
};

export default UnsortableCategoryItem;
