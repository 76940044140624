import React from 'react';

import Styled from './index.styled';
import { Props } from './index.types';

const NavigationMenuIcon: React.FC<Props> = ({
  onClick,
  'data-test-id': testId,
}: Props) => (
  <Styled.MenuIconButton
    color="primary"
    variant="contained"
    aria-label="Open"
    onClick={onClick}
    data-test-id={testId}
  >
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
    </svg>
  </Styled.MenuIconButton>
);

export default NavigationMenuIcon;
