import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';

import Card from 'components/common/Card';
import { ashesRecordActionLabels } from 'constants/bereavement';
import { buildNameString, getFormattedDate } from 'services/utils';
import { getAshesRecordLastAction, getAshesRecordPastActions, getPersonToReceiveAshesData } from 'services/utils/bereavement';
import { ashesRecordType, bereavedPersonConnectionType } from 'types/bereavement';

import classNames from 'classnames';
import styles from 'scss/main.scss';

const AshesRecordCard = ({
  ashesRecord,
  relatedData,
  bereavedPeopleConnections,
  disabled,
  onEdit,
}) => {
  const lastAction = getAshesRecordLastAction(ashesRecord.actions);
  const previousActions = getAshesRecordPastActions(ashesRecord.actions);

  const updatedRelatedData = {
    ...relatedData,
    personToReceiveAshes: getPersonToReceiveAshesData(
      bereavedPeopleConnections,
      ashesRecord.personToReceiveAshes,
    ),
  };

  return (
    <Card
      title={t('Ashes')}
      onEdit={onEdit ? () => onEdit(ashesRecord) : null}
      disabled={disabled}
      testId="ashes"
    >
      <Fragment>
        <div className={classNames(styles.o_fb, styles.u_push__bottom)}>
          <div
            className={styles.o_fb__item__half}
            data-test-id="cremationCertificateNumberContainer"
          >
            <div className={classNames(styles.primer, styles.is_primary)}>
              {t('Cremation certificate number:')}
            </div>
            <div data-test-id="certificateNumber">
              {ashesRecord.cremationCertificateNumber || t('Not specified')}
            </div>
          </div>
          <div
            className={styles.o_fb__item__half}
            data-test-id="handlingOfAshesContainer"
          >
            <div className={classNames(styles.primer, styles.is_primary)}>
              {t('Handling of ashes:')}
            </div>
            <div data-test-id="handlingOfAshes">
              {updatedRelatedData.handlingOfAshes || t('No options selected in arrangement')}
            </div>
          </div>
        </div>

        <div className={classNames(styles.o_fb, styles.u_push__bottom)}>
          <div
            className={styles.o_fb__item__half}
            data-test-id="storageLocationContainer"
          >
            <div className={classNames(styles.primer, styles.is_primary)}>
              {t('Storage location:')}
            </div>
            <div data-test-id="storageLocation">
              {ashesRecord.storageLocation || t('Not specified')}
            </div>
          </div>
          <div
            className={styles.o_fb__item__half}
            data-test-id="personToReceiveAshesContainer"
          >
            <div className={classNames(styles.primer, styles.is_primary)}>
              {t('Person to receive ashes:')}
            </div>
            <div data-test-id="personToReceiveAshes">
              {buildNameString(updatedRelatedData.personToReceiveAshes.name)}
            </div>
          </div>
        </div>

        <div className={classNames(styles.o_fb, styles.u_push__bottom)}>
          <div
            className={styles.o_fb__item__half}
            data-test-id="lastActionContainer"
          >
            <div className={classNames(styles.primer, styles.is_primary)}>
              {t('Last action:')}
            </div>
            <div data-test-id="lastAction">
              {lastAction && (
                <div data-test-id="actionEntry">
                  {ashesRecordActionLabels[lastAction.actionTaken]}
                  {', '}
                  {getFormattedDate(lastAction.createdDateTime, 'DD MMM - HH:mm')}
                </div>
              )}
            </div>
          </div>
        </div>

        {!!previousActions.length && (
          <div className={classNames(styles.o_fb, styles.u_push__bottom)}>
            <div
              className={styles.o_fb__item__half}
              data-test-id="pastActionsContainer"
            >
              <div className={classNames(styles.primer, styles.is_primary)}>
                {t('Past actions:')}
              </div>
              <div data-test-id="pastActions">
                <ul>
                  {previousActions.map(({ actionTaken, createdDateTime }) => (
                    <li key={createdDateTime} data-test-id="actionEntry">
                      {ashesRecordActionLabels[actionTaken]}
                      {', '}
                      {getFormattedDate(createdDateTime, 'DD MMM - HH:mm')}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    </Card>
  );
};
AshesRecordCard.propTypes = {
  ashesRecord: ashesRecordType,
  relatedData: PropTypes.objectOf(PropTypes.any),
  bereavedPeopleConnections: PropTypes.arrayOf(bereavedPersonConnectionType),
  disabled: PropTypes.bool,
  onEdit: PropTypes.func,
};

export default AshesRecordCard;
