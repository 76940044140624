import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { matchType } from 'types/reactRouter';
import { apolloClientType } from 'types/apollo';
import ResetPasswordScreen from './ResetPasswordScreen';
import { validationSchema } from './validation';
import { resetSecret } from './mutations.gql';

class ResetPasswordScreenContainer extends React.Component {
  static propTypes = {
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    match: matchType.isRequired,
    client: apolloClientType.isRequired,
    validate: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      passwordFormData: {
        password: '',
        confirmPassword: '',
      },
      token: null,
      isSubmitting: false,
      isSubmitted: false,
      isValidationEnabled: false,
    };

    this.passwordRef = React.createRef();
  }

  componentDidMount() {
    const { match } = this.props;
    const { token } = match.params;

    this.setState({ token });

    if (this.emailRef && this.passwordRef.current) {
      this.passwordRef.current.focus();
    }
  }

  handlePasswordChange = (key, value) => {
    const { validate } = this.props;
    const { passwordFormData, isValidationEnabled } = this.state;

    const updatedFormData = {
      ...passwordFormData,
      [key]: value,
    };

    if (isValidationEnabled) {
      validate(updatedFormData, validationSchema);
    }
    this.setState({ passwordFormData: updatedFormData });
  }

  handlePasswordSave = async (event) => {
    event.preventDefault();
    const { client, validate } = this.props;
    const { passwordFormData, token } = this.state;

    this.setState({ isValidationEnabled: true });

    const isValid = validate(passwordFormData, validationSchema);
    if (!isValid) {
      return;
    }

    this.setState({ isSubmitting: true });

    await client.mutate({
      mutation: resetSecret,
      variables: {
        input: {
          resetToken: token,
          newSecret: passwordFormData.password,
        },
      },
    });

    this.setState({
      isSubmitting: false,
      isSubmitted: true,
    });
  }

  render() {
    const { errors } = this.props;
    const {
      passwordFormData,
      token,
      isSubmitting,
      isSubmitted,
      isValidationEnabled,
    } = this.state;

    return (
      <ResetPasswordScreen
        passwordFormData={passwordFormData}
        token={token}
        isSubmitting={isSubmitting}
        isSubmitted={isSubmitted}
        errors={isValidationEnabled ? errors : {}}
        passwordRef={this.passwordRef}
        onPasswordChange={this.handlePasswordChange}
        onPasswordSave={this.handlePasswordSave}
      />
    );
  }
}

export default withRouter(withApollo(withValidation(ResetPasswordScreenContainer)));
