import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import SearchIcon from '@material-ui/icons/Search';
import ClientForm from 'components/client/ClientForm';
import BereavedPersonCard from 'components/common/cards/BereavedPersonCard';
import LookUp from 'components/common/lookups/LookUp';
import { bereavedPersonConnectionType } from 'types/bereavement';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const ClientListSection = ({
  bereavedPeopleConnections,
  disabled,
  clientFormData,
  isClientFormOpen,
  lookUpValue,
  suggestions,
  isLoading,
  isSaving,
  isAdding,
  lookUpRef,
  onLookUpChange,
  onSelect,
  onSelectNew,
  onEdit,
  onRemove,
  onSave,
  onClose,
  canDeleteAllClients,
  selectedClientId,
  onSelectedClientChange,
  displayRemove,
}) => {
  const loadingIcon = isLoading ? (
    <InputAdornment position="end" className={styles.o_icon__primary}>
      <img src="/img/loading_field.gif" alt="Loading" className={styles.image} data-test-id="ellipsisLoader" />
    </InputAdornment>
  ) : null;

  return (
    <div
      className={rcStyles.v_spacing}
      data-test-id="firstCallClientSection"
    >
      <LookUp
        label={t('Add client')}
        lookUpValue={lookUpValue}
        suggestions={suggestions}
        onChange={onLookUpChange}
        onSelect={onSelect}
        onSelectNew={onSelectNew}
        selectNewText="Add client"
        startComponent={(
          <InputAdornment position="start">
            <SearchIcon className={rcStyles.client_search_icon} />
          </InputAdornment>
        )}
        endComponent={loadingIcon}
        lookUpRef={lookUpRef}
        disabled={disabled}
        fullWidth
        data-test-id="clientLookup"
      />
      {bereavedPeopleConnections.map(bereavedPersonConnection => (
        <div
          className={rcStyles.clients_list__row}
          key={bereavedPersonConnection.id}
        >
          {onSelectedClientChange && (
            <div className={styles.u_push__right}>
              <Radio
                color="primary"
                value={bereavedPersonConnection.id}
                checked={selectedClientId
                  ? selectedClientId === bereavedPersonConnection.id
                  : false}
                onChange={event => onSelectedClientChange(event.target.value)}
                data-test-id={bereavedPersonConnection.id}
              />
            </div>
          )}
          <BereavedPersonCard
            key={bereavedPersonConnection.bereavedPerson.id}
            onRemove={onRemove}
            bereavedPersonConnection={bereavedPersonConnection}
            onEdit={onEdit}
            displayRemove={displayRemove && (canDeleteAllClients || bereavedPeopleConnections.length > 1)}
            disabled={disabled}
          />
        </div>
      ))}
      <ClientForm
        bereavedPeopleConnections={bereavedPeopleConnections}
        bereavedPersonConnection={clientFormData}
        isOpen={isClientFormOpen}
        isSaving={isSaving}
        isAdding={isAdding}
        onClose={onClose}
        onSave={onSave}
      />
    </div>
  );
};

ClientListSection.propTypes = {
  bereavedPeopleConnections: PropTypes.arrayOf(bereavedPersonConnectionType).isRequired,
  disabled: PropTypes.bool,
  clientFormData: PropTypes.objectOf(PropTypes.any),
  isClientFormOpen: PropTypes.bool.isRequired,
  lookUpValue: PropTypes.string.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isAdding: PropTypes.bool.isRequired,
  lookUpRef: PropTypes.objectOf(PropTypes.any).isRequired,
  onLookUpChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSelectNew: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  canDeleteAllClients: PropTypes.bool,
  selectedClientId: PropTypes.string,
  onSelectedClientChange: PropTypes.func,
  displayRemove: PropTypes.bool,
};

export default ClientListSection;
