import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import IconNotes from 'icons/IconNotes';
import AssignCaseModal from 'components/bereavement/AssignCaseModal';
import NotesModal from 'components/common/NotesModal';
import { noteCategories } from 'constants/bereavement';
import { buildNameString } from 'services/utils';
import { bereavementType } from 'types/bereavement';

import classNames from 'classnames';
import styles from 'scss/main.scss';

const FirstCallHeader = ({
  bereavement,
  isSaving,
  isDeceasedFormValid,
  isBereavedFormValid,
  isCaseModalOpen,
  isNotesModalOpen,
  onHomeChange,
  onOwnerChange,
  onModalOpen,
  onModalClose,
  onNotesModalOpen,
  onNotesModalClose,
  onSave,
  onCancel,
  updateBereavementByKey,
}) => (
  <div className={styles.c_page__header}>
    <div className={styles.c_page__header_main}>
      <h1 className={classNames(
        styles.double_pica,
        styles.is_white,
      )}
      >
        {t('First Call')}
        <IconButton
          className={classNames(
            styles.o_button__secondary,
            styles.u_push__bottom_double,
            styles.u_push__left,
          )}
          onClick={onNotesModalOpen}
          color="secondary"
          variant="contained"
          data-test-id="openNotes"
        >
          {bereavement.notes ? (
            <Badge badgeContent={bereavement.notes.length} color="primary">
              <IconNotes className={styles.iconColorSecondary} />
            </Badge>
          ) : (
            <IconNotes className={styles.iconColorSecondary} />
          )}
        </IconButton>
      </h1>
      <NotesModal
        isOpen={isNotesModalOpen}
        bereavementId={bereavement.id}
        name={bereavement.deceasedPeople[0].name}
        restrictedCategories={[noteCategories.ACCOUNTS_FINANCE_ONLY]}
        notesList={bereavement.notes}
        onSave={notes => updateBereavementByKey('notes', notes)}
        onClose={onNotesModalClose}
      />
    </div>
    <div className={styles.is_utility_button}>
      <Button
        variant="contained"
        color="secondary"
        onClick={onCancel}
        data-test-id="cancelCreateCaseButton"
        className={styles.u_push__right}
      >
        {t('Cancel')}
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={onModalOpen}
        className={styles.btnVertical}
        data-test-id="saveCreateCaseButton"
      >
        {t('Save & Create')}
      </Button>
    </div>
    <AssignCaseModal
      title={`Create a Funeral for ${buildNameString(bereavement.deceasedPeople[0].name)}`}
      saveButtonText="Create Funeral"
      home={bereavement.home}
      owner={bereavement.owner}
      isOpen={isCaseModalOpen}
      isSaving={isSaving}
      isDeceasedFormValid={isDeceasedFormValid}
      isBereavedFormValid={isBereavedFormValid}
      onHomeChange={onHomeChange}
      onOwnerChange={onOwnerChange}
      onClose={onModalClose}
      onSave={onSave}
    />
  </div>
);

FirstCallHeader.propTypes = {
  bereavement: bereavementType.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isDeceasedFormValid: PropTypes.bool.isRequired,
  isBereavedFormValid: PropTypes.bool.isRequired,
  isCaseModalOpen: PropTypes.bool.isRequired,
  isNotesModalOpen: PropTypes.bool.isRequired,
  onHomeChange: PropTypes.func.isRequired,
  onOwnerChange: PropTypes.func.isRequired,
  onModalOpen: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onNotesModalOpen: PropTypes.func.isRequired,
  onNotesModalClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  updateBereavementByKey: PropTypes.func.isRequired,
};

export default FirstCallHeader;
