import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { historyType } from 'types/reactRouter';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { organisationalUnitTypes } from 'constants/organisationalUnits';
import moment from 'moment';

import CalendarEventContentCustom from 'components/calendar/CalendarEventContent/CalendarEventContentCustom';
import CalendarEventContentTransfer from 'components/calendar/CalendarEventContent/CalendarEventContentTransfer';
import CalendarEventContentViewing from 'components/calendar/CalendarEventContent/CalendarEventContentViewing';
import CalendarEventContentMeeting from 'components/calendar/CalendarEventContent/CalendarEventContentMeeting';
import CalendarEventContentCortege from 'components/calendar/CalendarEventContent/CalendarEventContentCortege';
import CalendarEventContentService from 'components/calendar/CalendarEventContent/CalendarEventContentService';
import CalendarEventContentReceptionVenue from 'components/calendar/CalendarEventContent/CalendarEventContentReceptionVenue';
import { calendarEventTypes, calendarEventsThemeObj } from 'constants/calendar';
import { apolloClientType } from 'types/apollo';
import { editEventTransform, createEventTransform } from 'transforms/calendar';
import {
  findOrganisationalUnit,
  getStaffMemberHomes,
  getBereavementCalendarEvents,
} from './queries.gql';
import { createCustomEvent, editCustomEvent, deleteCustomEvent } from './mutations.gql';
import Calendar from './Calendar';

class CalendarContainer extends Component {
  static propTypes = {
    history: historyType.isRequired,
    client: apolloClientType.isRequired,
    isCalendarOpen: PropTypes.bool.isRequired,
    onToggleCalendar: PropTypes.func.isRequired,
    identityTypeId: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props);
    const DEFAULT_VIEW = 'day';
    this.state = {
      homes: [],
      calendarEvents: [],
      currentDate: moment(),
      organisationalUnitId: null,
      currentView: DEFAULT_VIEW,
      end: moment().endOf(DEFAULT_VIEW),
      start: moment().startOf(DEFAULT_VIEW),
      isLoading: false,
      isRunSheetLoading: false,
      isBookEventModalOpen: false,
      isEventModalOpen: false,
      isEditingEvent: false,
      selectedEvent: {
        title: 'Event Title',
      },
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentView, currentDate } = this.state;
    const { isCalendarOpen } = this.props;

    if (!prevProps.isCalendarOpen && isCalendarOpen) {
      this.getBereavementCalendarEvents();
      this.getStaffMemberHomes();
    }

    // If the user clicks on the week header links
    // handleViewChange & handleNavigationChange are called together
    // and not changing the start and end date correctly.
    if (prevState.currentView !== currentView) {
      this.updateTimes(currentDate, currentView);
    }
  }

  getBereavementCalendarEvents() {
    const { start, end, organisationalUnitId } = this.state;
    const { client } = this.props;

    this.setState({ isLoading: true });

    let input = {
      start: start.format(),
      end: end.format(),
      pagination: { first: 1000, after: null },
    };

    if (organisationalUnitId) {
      input = {
        ...input,
        ids: [organisationalUnitId],
      };
    }

    client.query({
      query: getBereavementCalendarEvents,
      variables: input,
    }).then((res) => {
      if (res.errors && res.errors.length > 0) {
        return;
      }

      const { edges } = res.data.calendarEvents;
      const changeCalendarStringsToDate = edges.map(({ node }) => ({
        ...node,
        start: moment(node.start).toDate(),
        end: moment(node.end).toDate(),
        bereavementId: node.requiredBereavementId || node.bereavementId,
      }));
      this.setState({
        calendarEvents: changeCalendarStringsToDate,
      });
    }).finally(() => this.setState({ isLoading: false }));
  }

  getStaffMemberHomes = () => {
    const { client, identityTypeId } = this.props;
    client.query({
      query: getStaffMemberHomes,
      variables: {
        id: identityTypeId,
      },
    }).then((data) => {
      const result = data.data.staffMember.organisationalUnitsFlattened.filter(
        home => home.type === organisationalUnitTypes.HOME,
      );
      this.setState(() => ({ homes: result }));
    });
  }

  handleViewChange = (view) => {
    const { currentDate } = this.state;
    this.setState({ currentView: view });
    this.updateTimes(currentDate, view);
  }

  handleNavigationChange = (date, view) => {
    const newDate = moment(date);
    this.setState({ currentDate: newDate });
    this.updateTimes(date, view);
  }

  updateTimes = (date, view) => {
    let start;
    let end;
    if (view === 'day') {
      start = moment(date).startOf('day');
      end = moment(date).endOf('day');
    } else if (view === 'week') {
      start = moment(date).startOf('isoWeek');
      end = moment(date).endOf('isoWeek');
    } else if (view === 'month') {
      start = moment(date).startOf('month').subtract(7, 'days');
      end = moment(date).endOf('month').add(7, 'days');
    } else if (view === 'agenda') {
      start = moment(date).startOf('day');
      end = moment(date).endOf('day').add(1, 'month');
    }

    this.setState({
      start,
      end,
    }, this.getBereavementCalendarEvents);
  }

  handleFiltersChange = (id) => {
    this.setState({
      organisationalUnitId: id,
    }, this.getBereavementCalendarEvents);
  };

  handleOpenRunSheet = () => {
    const { start, end, organisationalUnitId } = this.state;
    const { client } = this.props;

    this.setState({ isRunSheetLoading: true });
    const input = {
      start: start.format(),
      end: end.format(),
      homeId: organisationalUnitId,
    };

    client
      .query({
        query: findOrganisationalUnit,
        variables: input,
      })
      .then(({ data, errors }) => {
        if (data === null && errors.length > 0) {
          return;
        }
        const {
          organisationalUnit: {
            runSheet: { uri },
          },
        } = data;
        this.setState({ isRunSheetLoading: false });
        window.open(uri, uri).focus();
      })
      .catch(() => this.setState({ isRunSheetLoading: false }));
  }

  handleToggleBookEventModal = () => {
    this.setState(prevState => ({
      isBookEventModalOpen: !prevState.isBookEventModalOpen,
      isEditingEvent: prevState.isBookEventModalOpen && false,
    }));
  }

  handleSaveCustomEvent = (customEvent) => {
    const { client } = this.props;
    const { isEditingEvent, calendarEvents } = this.state;
    const input = isEditingEvent ? editEventTransform(customEvent) : createEventTransform(customEvent);
    const updatedCalendarEvents = isEditingEvent
      ? calendarEvents.map(event => (event.id === customEvent.id && {
        ...customEvent,
        end: moment(customEvent.end).toDate(),
        start: moment(customEvent.start).toDate(),
      }) || event)
      : ([
        ...calendarEvents,
        {
          id: input.id,
          bereavementId: input.bereavementId,
          description: input.description || null,
          end: moment(input.end).toDate(),
          location: input.location || null,
          start: moment(input.start).toDate(),
          title: input.title,
          homeIds: input.homeIds,
          __typename: 'CustomCalendarEvent',
        },
      ]);

    this.setState({ calendarEvents: updatedCalendarEvents });
    this.handleToggleBookEventModal(updatedCalendarEvents);
    client.mutate({
      mutation: isEditingEvent ? editCustomEvent : createCustomEvent,
      variables: { input },
    });
  }

  handleDeleteCustomEvent = (customEvent) => {
    const { client } = this.props;
    const { calendarEvents } = this.state;

    const calendarEventsExcludingDeletedEvent = calendarEvents.filter(event => event.id !== customEvent.id);

    this.setState({
      calendarEvents: calendarEventsExcludingDeletedEvent,
    });
    this.handleToggleBookEventModal(calendarEventsExcludingDeletedEvent);

    client.mutate({
      mutation: deleteCustomEvent,
      variables: {
        input: {
          id: customEvent.id,
        },
      },
    });
  }

  handleOpenEvent = (event) => {
    const { title } = calendarEventsThemeObj[event.__typename];
    const isCustomEvent = event.__typename === 'CustomCalendarEvent';
    this.setState({
      isEventModalOpen: !isCustomEvent,
      isBookEventModalOpen: isCustomEvent,
      isEditingEvent: isCustomEvent,
      selectedEvent: {
        ...event,
        title: isCustomEvent ? event.title : title,
      },
    });
  };

  handleCloseEventModal = () => {
    this.setState({ isEventModalOpen: false });
  };

  handleOpenCase = () => {
    const { history, onToggleCalendar } = this.props;
    const { selectedEvent: { bereavementId } } = this.state;
    this.handleCloseEventModal();
    onToggleCalendar();
    history.push(`/case/${bereavementId}/arrangement`);
  }

  renderEventContent = (selectedEvent) => {
    switch (selectedEvent.__typename) {
      case calendarEventTypes.CustomCalendarEvent:
        return <CalendarEventContentCustom {...selectedEvent} />;
      case calendarEventTypes.TransferIntoCareBereavementCalendarEvent:
      case calendarEventTypes.ExternalTransferBereavementCalendarEvent:
        return <CalendarEventContentTransfer {...selectedEvent} />;
      case calendarEventTypes.ViewingBereavementCalendarEvent:
        return <CalendarEventContentViewing {...selectedEvent} />;
      case calendarEventTypes.ArrangementMeetingBereavementCalendarEvent:
        return <CalendarEventContentMeeting {...selectedEvent} />;
      case calendarEventTypes.CortegeBereavementCalendarEvent:
        return <CalendarEventContentCortege {...selectedEvent} />;
      case calendarEventTypes.ServiceBereavementCalendarEvent:
        return <CalendarEventContentService {...selectedEvent} />;
      case calendarEventTypes.ReceptionVenueBereavementCalendarEvent:
        return <CalendarEventContentReceptionVenue {...selectedEvent} />;
      default: return 'No content found';
    }
  }

  render() {
    const {
      isLoading,
      isBookEventModalOpen,
      isEventModalOpen,
      isEditingEvent,
      homes,
      currentDate,
      currentView,
      calendarEvents,
      isRunSheetLoading,
      organisationalUnitId,
      selectedEvent,
    } = this.state;
    const {
      history,
      isCalendarOpen,
      onToggleCalendar,
    } = this.props;

    return (
      <Calendar
        isCalendarOpen={isCalendarOpen}
        isLoading={isLoading}
        isBookEventModalOpen={isBookEventModalOpen}
        isRunSheetLoading={isRunSheetLoading}
        isEventModalOpen={isEventModalOpen}
        isEditingEvent={isEditingEvent}
        onToggleCalendar={onToggleCalendar}
        onToggleBookEventModal={this.handleToggleBookEventModal}
        onViewChange={this.handleViewChange}
        onSaveCustomEvent={this.handleSaveCustomEvent}
        onDeleteCustomEvent={this.handleDeleteCustomEvent}
        onOpenRunSheet={this.handleOpenRunSheet}
        onNavigate={this.handleNavigationChange}
        onChangeFilters={this.handleFiltersChange}
        onOpenEvent={this.handleOpenEvent}
        onCloseEventModal={this.handleCloseEventModal}
        onOpenCase={this.handleOpenCase}
        homes={homes}
        history={history}
        currentView={currentView}
        calendarEvents={calendarEvents}
        currentDate={currentDate.toDate()}
        organisationalUnitId={organisationalUnitId}
        selectedEvent={selectedEvent}
        renderEventContent={this.renderEventContent}
      />
    );
  }
}

const mapStateToProps = state => ({
  homes: state.userStore.user.staffMember.organisationalUnitsFlattened,
  identityTypeId: state.userStore.user.identityTypeId,
});

export default withApollo(
  withRouter(connect(
    mapStateToProps,
  )(CalendarContainer)),
);
