import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const IconDisbursement = (props) => {
  const { className } = props;
  const classes = className ? `${className} ${styles.rc_icon}` : styles.rc_icon;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="3.4 3.4 27 27" className={classes}>
      <defs>
        <style>{'.cls-1,.cls-2{fill:none;}.cls-1{clip-rule:evenodd;}.cls-3{clip-path:url(#clip-path);}.cls-4{clip-path:url(#clip-path-2);}.cls-5{isolation:isolate;}.cls-6{clip-path:url(#clip-path-5);}'}</style>
        <clipPath id="clip-path">
          <path className="cls-1" d="M21,18.5A1.5,1.5,0,1,1,22.5,17,1.5,1.5,0,0,1,21,18.5ZM17,21H27V13H17Zm9,2v1a2,2,0,0,1-2,2H10a2,2,0,0,1-2-2V10a2,2,0,0,1,2-2H24a2,2,0,0,1,2,2v1H17a2,2,0,0,0-2,2v8a2,2,0,0,0,2,2Z" />
        </clipPath>
        <clipPath id="clip-path-2">
          <rect className="cls-2" x="5" y="5" width="24" height="24" />
        </clipPath>
        <clipPath id="clip-path-5">
          <rect className="cls-2" x="8" y="8" width="19" height="18" />
        </clipPath>
      </defs>
      <title>Disbursement</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g className="cls-3">
            <g className="cls-4">
              <g className="cls-5">
                <g className="cls-4">
                  <g className="cls-4">
                    <g className="cls-6">
                      <rect className="cls-7" width="34" height="34" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconDisbursement.propTypes = {
  className: PropTypes.string,
};

export default IconDisbursement;
