import mapActionToReducer from 'redux-action-reducer-mapper';
import { catalogueActions } from 'actions/catalogue';

export const initialState = {
  products: {
    items: [],
    isLoading: false,
    activeRequest: null,
    pagination: {
      first: 10,
      after: null,
      hasNextPage: false,
    },
    filters: {
      term: '',
      categories: [],
      supplierIds: [],
      organisationalUnitIds: [],
      status: {
        name: 'Published',
        enum: 'PUBLISHED',
      },
    },
  },
  services: {
    items: [],
    isLoading: false,
    activeRequest: null,
    pagination: {
      first: 10,
      after: null,
      hasNextPage: false,
    },
    filters: {
      term: '',
      categories: [],
      supplierIds: [],
      organisationalUnitIds: [],
      status: {
        name: 'Published',
        enum: 'PUBLISHED',
      },
    },
  },
  packages: {
    items: [],
    isLoading: false,
    activeRequest: null,
    pagination: {
      first: 10,
      after: null,
      hasNextPage: false,
    },
    filters: {
      term: '',
      status: {
        name: 'Published',
        enum: 'PUBLISHED',
      },
      organisationalUnitIds: [],
    },
  },
  isLoadingAdminCatalogue: false,
};


const removePackage = (state, action) => ({
  ...state,
  packages: {
    ...state.packages,
    items: state.packages.items.filter(item => item.id !== action.id),
  },
});

const addPackages = (state, action) => {
  const { packages, pagination, filters } = action.payload;

  const updatedPackages = state.packages.items.map((packageItem) => {
    const existingPackage = packages.find(existing => existing.id === packageItem.id);
    if (existingPackage) {
      return existingPackage;
    }
    return packageItem;
  });
  const newPackages = packages.filter(
    packageItem => !state.packages.items.find(existing => existing.id === packageItem.id),
  );

  return {
    ...state,
    packages: {
      ...state.packages,
      items: [...updatedPackages, ...newPackages],
      pagination: {
        ...state.packages.pagination,
        ...pagination,
      },
      filters: filters ? { ...filters } : { ...state.packages.filters },
    },
  };
};

const updatePackage = (state, action) => {
  const { packageItem: item } = action;
  const packages = state.packages.items
    .map(packageItem => (packageItem.id === item.id ? item : packageItem));

  return {
    ...state,
    packages: {
      ...state.packages,
      items: packages,
    },
  };
};

const removeCatalogueItem = (state, action) => {
  const { payload: { itemType, id } } = action;

  return {
    ...state,
    [itemType]: {
      ...state[itemType],
      items: state[itemType].items.filter(item => item.id !== id),
    },
  };
};

const updateCatalogueItem = (state, action) => {
  const { payload: { itemType, item: payloadItem } } = action;
  const catalogueItems = state[itemType].items
    .map(item => (item.id === payloadItem.id ? {
      ...payloadItem,
      variants: payloadItem.variants,
      hasBeenUpdated: true,
    } : item));

  return {
    ...state,
    [itemType]: {
      ...state[itemType],
      items: catalogueItems,
    },
  };
};

const updateArrangementItem = (state, action) => {
  const { payload: { itemType, item: payloadItem } } = action;
  const arrangementItems = state[itemType].items
    .map(item => (item.id === payloadItem.id ? {
      ...payloadItem,
      variants: payloadItem.variants,
      hasBeenUpdated: true,
    } : item));

  return {
    ...state,
    [itemType]: {
      ...state[itemType],
      items: arrangementItems,
    },
  };
};

const setIsLoading = (state, action) => {
  const { payload: { itemType, isLoading } } = action;

  return {
    ...state,
    [itemType]: {
      ...state[itemType],
      isLoading,
    },
  };
};

const clearItems = (state, action) => {
  const { payload: { itemType } } = action;

  return {
    ...state,
    [itemType]: {
      ...state[itemType],
      items: [],
    },
  };
};

const clearFilters = (state, action) => {
  const { payload: { itemType } } = action;

  return {
    ...state,
    [itemType]: {
      ...state[itemType],
      filters: initialState[itemType].filters,
    },
  };
};

const clearPagination = (state, action) => {
  const { payload: { itemType } } = action;

  return {
    ...state,
    [itemType]: {
      ...state[itemType],
      pagination: initialState[itemType].pagination,
    },
  };
};

const updateFilters = (state, action) => {
  const { payload: { itemType, key, value } } = action;
  return {
    ...state,
    [itemType]: {
      ...state[itemType],
      filters: {
        ...state[itemType].filters,
        [key]: value,
      },
    },
  };
};

const addCatalogueItems = (state, action) => {
  const {
    catalogueItems, pagination, filters, itemType,
  } = action.payload;

  const updatedItems = state[itemType].items.map((item) => {
    const { hasBeenUpdated, ...existingItem } = item;
    const matchedApiItem = catalogueItems.find(catalogueItem => catalogueItem.id === existingItem.id
      && !hasBeenUpdated);
    if (matchedApiItem) {
      return matchedApiItem;
    }
    return existingItem;
  });
  const newItems = catalogueItems.filter(
    item => !state[itemType].items.find(existing => existing.id === item.id),
  );

  return {
    ...state,
    [itemType]: {
      ...state[itemType],
      items: [...updatedItems, ...newItems],
      pagination: {
        ...state[itemType].pagination,
        ...pagination,
      },
      filters: filters ? { ...filters } : { ...state[itemType].filters },
    },
  };
};

const setIsLoadingAdminCatalogue = (state, action) => {
  const { payload: { isLoading } } = action;

  return {
    ...state,
    isLoadingAdminCatalogue: isLoading,
  };
};

const setPackagesActiveRequest = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    packages: {
      ...state.packages,
      activeRequest: payload,
    },
  };
};

const setProductsActiveRequest = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    products: {
      ...state.products,
      activeRequest: payload,
    },
  };
};

const setServicesActiveRequest = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    services: {
      ...state.services,
      activeRequest: payload,
    },
  };
};

export const catalogueReducer = mapActionToReducer({
  default: initialState,
  [catalogueActions.CLEAR_ALL]: () => initialState,
  [catalogueActions.CLEAR_ITEMS]: clearItems,
  [catalogueActions.CLEAR_FILTERS]: clearFilters,
  [catalogueActions.CLEAR_PAGINATION]: clearPagination,
  [catalogueActions.UPDATE_PACKAGE]: updatePackage,
  [catalogueActions.UPDATE_CATALOGUE_ITEM]: updateCatalogueItem,
  [catalogueActions.UPDATE_ARRANGEMENT_ITEM]: updateArrangementItem,
  [catalogueActions.UPDATE_FILTERS]: updateFilters,
  [catalogueActions.REMOVE_PACKAGE]: removePackage,
  [catalogueActions.REMOVE_CATALOGUE_ITEM]: removeCatalogueItem,
  [catalogueActions.ADD_PACKAGES]: addPackages,
  [catalogueActions.ADD_CATALOGUE_ITEMS]: addCatalogueItems,
  [catalogueActions.SET_IS_LOADING]: setIsLoading,
  [catalogueActions.SET_IS_LOADING_ADMIN_CATALOGUE]: setIsLoadingAdminCatalogue,
  [catalogueActions.SET_PACKAGES_ACTIVE_REQUEST]: setPackagesActiveRequest,
  [catalogueActions.SET_PRODUCTS_ACTIVE_REQUEST]: setProductsActiveRequest,
  [catalogueActions.SET_SERVICES_ACTIVE_REQUEST]: setServicesActiveRequest,
});
