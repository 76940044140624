import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const IconDuplicate = (props) => {
  const { className } = props;
  const classes = className ? `${className} ${styles.rc_icon}` : null;

  return (
    <svg height="100" viewBox="0 0 118 100" width="118" className={classes}>
      <defs>
        <rect id="a" height="56" rx="2" width="50" />
        <filter id="b" height="162.5%" width="170%" x="-35%" y="-27.7%">
          <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5.5" />
          <feColorMatrix in="shadowBlurOuter1" type="matrix" values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.145651224 0" />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <ellipse cx="59" cy="49.748711" fill="#00b39e" fillOpacity=".061872" rx="51" ry="28" transform="matrix(-.8660254 .5 -.5 -.8660254 134.969854 63.332359)" />
        <g transform="translate(18.832704 5)">
          <rect fill="#c2dfff" height="56" rx="2" width="50" />
          <rect fill="#87c1ff" height="22" rx="1" width="38" x="6" y="7" />
          <path d="m12 22.5 6.1543105-6 2.9226895 3 7.1801841-7 9.7428159 10z" fill="#00b39e" />
          <rect fill="#a791c0" height="6" rx="1" width="16" x="6" y="44" />
          <rect fill="#87c1ff" height="6" rx="1" width="38" x="6" y="33" />
        </g>
        <g transform="translate(36.665409 16.251289)">
          <use fill="#000" filter="url(#b)" xlinkHref="#a" />
          <use fill="#c2dfff" fillRule="evenodd" xlinkHref="#a" />
          <rect fill="#87c1ff" height="22" rx="1" width="38" x="6" y="7" />
          <path d="m12 22.5 6.1543105-6 2.9226895 3 7.1801841-7 9.7428159 10z" fill="#00b39e" />
          <rect fill="#a791c0" height="6" rx="1" width="16" x="6" y="44" />
          <rect fill="#87c1ff" height="6" rx="1" width="38" x="6" y="33" />
        </g>
        <circle cx="83.332704" cy="66.5" fill="#ffca3a" r="7.5" />
        <g fill="#fff">
          <path d="m81.832704 61h3v6h-3z" />
          <circle cx="83.332704" cy="70.5" r="1.5" />
        </g>
      </g>
    </svg>
  );
};

IconDuplicate.propTypes = {
  className: PropTypes.string,
};

export default IconDuplicate;
