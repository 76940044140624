import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Toolbar from '@material-ui/core/Toolbar';
import PageLoader from 'components/common/PageLoader';
import IconLoad from 'icons/IconLoad';
import SearchIcon from '@material-ui/icons/Search';
import Select from 'components/common/Select';
import SortMenu from 'components/common/SortMenu';
import AccountsListItem from 'components/account/AccountsListItem';
import AccountsListSummary from 'components/account/AccountsListSummary';
import ScreenLayout from 'components/common/ScreenLayout';
import { accountStatuses } from 'constants/account';
import { sortAccountsFilters } from 'constants/bereavement';
import { bereavementType, totalsType } from 'types/bereavement';
import { organisationalUnitType } from 'types/organisationalUnit';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const AccountsListScreen = ({
  bereavements,
  totals,
  homes,
  filters,
  statusFilter,
  accountStatus,
  hasXeroIntegration,
  isLoading,
  hasMoreItems,
  getBereavements,
  onFiltersChange,
  onStatusFilterChange,
}) => (
  <ScreenLayout
    title={t('Accounts')}
    dashboardBannerProps={{
      breadcrumbs: [{ path: '/', title: t('Dashboard') }, { path: '/accounts', title: 'Account management' }],
      description: t('View and manage accounts'),
      footnote: (
        <AccountsListSummary
          totals={totals}
          hasXeroIntegration={hasXeroIntegration}
        />
      ),
    }}
  >
    <div className={classNames(
      styles.o_view,
      styles.has_white_background,
    )}
    >
      <div className={styles.o_view__main}>
        <Toolbar className={rcStyles.toolbar}>
          <Select
            value={accountStatus}
            onChange={event => onStatusFilterChange('accountStatus', event.target.value)}
            label={t('Account Status')}
            inputProps={{ 'data-test-id': 'accountStatus' }}
          >
            <MenuItem value="ALL">{t('All')}</MenuItem>
            <MenuItem value={accountStatuses.OPEN}>{t('Open')}</MenuItem>
            <MenuItem value={accountStatuses.CLOSED}>{t('Closed')}</MenuItem>
          </Select>
          <Select
            value={statusFilter}
            onChange={event => onStatusFilterChange('paymentStatus', event.target.value)}
            label={t('Payment Status')}
            inputProps={{ 'data-test-id': 'paymentStatus' }}
          >
            <MenuItem value="all">{t('All')}</MenuItem>
            <MenuItem value="hasUnInvoicedAmount">{t('Uninvoiced')}</MenuItem>
            <MenuItem value="hasBalanceOutstanding">{t('Outstanding')}</MenuItem>
          </Select>
          <Select
            value={filters.organisationalUnitId}
            onChange={event => onFiltersChange('organisationalUnitId', event.target.value)}
            label={t('Home')}
            inputProps={{ 'data-test-id': 'homeFilter' }}
          >
            {homes.map(home => (
              <MenuItem key={home.id} value={home.id}>
                {home.name}
              </MenuItem>
            ))}
          </Select>
          <TextField
            className={styles.u_fullWidth}
            value={filters.searchTerm}
            onChange={event => onFiltersChange('searchTerm', event.target.value)}
            label={t('Search')}
            type="search"
            InputProps={{
              'data-test-id': 'searchTerm',
              'startAdornment': (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Toolbar>
      </div>
    </div>
    <div className={styles.o_view}>
      <div className={styles.o_view__main}>
        <span className={styles.u_text__right}>
          <SortMenu
            options={sortAccountsFilters}
            testIds={{
              button: 'sortAccountsButton',
              menu: 'sortAccountsMenu',
              menuItem: 'sortAccountsFilter',
            }}
            onChangeFilters={filter => onFiltersChange('sortOrders', [filter])}
          />
        </span>
        <PageLoader
          isLoading={isLoading && bereavements && bereavements.length === 0}
        />
        {!isLoading && bereavements.length === 0 ? (

          <div className={styles.o_block_content}>
            <h3 className={styles.long_primer}>
              {t('No accounts to show')}
            </h3>
          </div>
        ) : (
          <InfiniteScroll
            className={classNames(
              rcStyles.rc_infinite_scroll,
              rcStyles.v_spacing,
            )}
            dataLength={bereavements.length}
            hasMore={hasMoreItems}
            next={getBereavements}
            loader={bereavements.length > 0 && (
              <div className={styles.u_fullWidth}>
                <div className={styles.c_svg_loader_infinite_scroll} data-test-id="infiniteScrollLoader">
                  <IconLoad />
                </div>
              </div>
            )}
          >
            {
              bereavements.map(bereavement => (
                <div key={bereavement.id}>
                  <AccountsListItem
                    bereavement={bereavement}
                    searchTerm={filters.searchTerm}
                    hasXeroIntegration={hasXeroIntegration}
                    data-test-id="accountListItem"
                  />
                </div>
              ))
            }
          </InfiniteScroll>
        )}
      </div>
    </div>
  </ScreenLayout>
);

AccountsListScreen.propTypes = {
  bereavements: PropTypes.arrayOf(bereavementType),
  totals: totalsType,
  homes: PropTypes.arrayOf(organisationalUnitType.isRequired).isRequired,
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  statusFilter: PropTypes.string,
  accountStatus: PropTypes.string.isRequired,
  hasXeroIntegration: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasMoreItems: PropTypes.bool,
  getBereavements: PropTypes.func.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  onStatusFilterChange: PropTypes.func.isRequired,
};

export default AccountsListScreen;
