import { defaultMoneyObject } from 'services/utils';
import {
  getOriginalPriceForSelection,
  filterSelectionsOnArrangement,
  filterSelectionsOptions,
} from 'services/utils/arrangement';
import { discountTypes } from 'constants/arrangement';

const getTotalDisbursements = (arrangement) => {
  const total = filterSelectionsOnArrangement(
    arrangement,
    [filterSelectionsOptions.DISPLAY_AS_DISBURSEMENTS],
  ).reduce((accumulator, selection) => {
    const originalPrice = getOriginalPriceForSelection(selection);
    const finalPrice = (!selection.overridePrice) ? originalPrice : selection.overridePrice;
    return accumulator + finalPrice.amount;
  }, 0);
  return defaultMoneyObject(total);
};

const allSelections = arrangement => [
  ...(arrangement.productSelections || []),
  ...(arrangement.serviceSelections || []),
];

const calculateWithDiscountNone = (arrangement) => {
  let finalTotal = 0;

  if (arrangement.packageSelection) {
    const packagePrice = arrangement.packageSelection.package.price.amount;
    finalTotal += packagePrice;
  }

  finalTotal += allSelections(arrangement).reduce((accumulator, selection) => {
    if (selection.isPackageSelection) {
      return accumulator;
    }
    const originalPrice = getOriginalPriceForSelection(selection);
    return accumulator + originalPrice.amount;
  }, 0);

  return {
    finalTotal: defaultMoneyObject(finalTotal),
    discounts: defaultMoneyObject(0),
    totalWithoutDiscounts: defaultMoneyObject(finalTotal),
    totalDisbursements: getTotalDisbursements(arrangement),
  };
};

const calculateWithDiscountItemised = (arrangement) => {
  const originalCalculation = calculateWithDiscountNone(arrangement);

  let discounts = 0;

  if (arrangement.packageSelection && arrangement.packageSelection.overridePrice) {
    const packagePrice = arrangement.packageSelection.package.price;
    const packageOverridePrice = arrangement.packageSelection.overridePrice;
    discounts += (packagePrice.amount - packageOverridePrice.amount);
  }

  discounts += allSelections(arrangement).reduce((accumulator, selection) => {
    if (!selection.overridePrice || selection.isPackageSelection) {
      return accumulator;
    }
    const original = getOriginalPriceForSelection(selection);
    const override = selection.overridePrice;
    return accumulator + (original.amount - override.amount);
  }, 0);

  let totalWithoutDiscounts = originalCalculation.finalTotal.amount;
  const finalTotal = totalWithoutDiscounts - discounts;

  if (discounts < 0) {
    discounts = 0;
    totalWithoutDiscounts = finalTotal;
  }

  return {
    finalTotal: defaultMoneyObject(finalTotal),
    discounts: defaultMoneyObject(discounts),
    totalWithoutDiscounts: defaultMoneyObject(totalWithoutDiscounts),
    totalDisbursements: getTotalDisbursements(arrangement),
  };
};

const calculateWithDiscountGlobal = (arrangement) => {
  const originalCalculation = calculateWithDiscountNone(arrangement);

  const totalWithoutDiscounts = originalCalculation.finalTotal.amount;
  const discounts = (arrangement.globalDiscount && arrangement.globalDiscount.amount) || 0;
  const finalTotal = totalWithoutDiscounts - discounts;

  return {
    finalTotal: defaultMoneyObject(finalTotal),
    discounts: defaultMoneyObject(discounts),
    totalWithoutDiscounts: defaultMoneyObject(totalWithoutDiscounts),
    totalDisbursements: getTotalDisbursements(arrangement),
  };
};

const estimateCalculator = (arrangement) => {
  if (arrangement.discountType === discountTypes.NONE) {
    return calculateWithDiscountNone(arrangement);
  }

  if (arrangement.discountType === discountTypes.ITEMISED) {
    return calculateWithDiscountItemised(arrangement);
  }

  if (arrangement.discountType === discountTypes.GLOBAL) {
    return calculateWithDiscountGlobal(arrangement);
  }

  throw new Error('Unrecognised discountType on arrangment.');
};

export default estimateCalculator;
