import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ExpandableBanner from './ExpandableBanner';

class ExpandableBannerContainer extends Component {
  static propTypes = {
    headerClasses: PropTypes.arrayOf(PropTypes.string),
    containerClasses: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.node,
    ]),
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    disable: PropTypes.bool,
    hasTab: PropTypes.bool,
    shouldCollapseFromExternalControl: PropTypes.bool,
    isClosedOnLoad: PropTypes.bool,
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.shouldCollapseFromExternalControl) {
      return { isExpanded: false };
    }
    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false,
    };
  }

  componentDidMount = () => {
    const { isClosedOnLoad } = this.props;
    this.setState({ isExpanded: !isClosedOnLoad });
  }

  expandBanner = () => {
    this.setState({
      isExpanded: true,
    });
  }

  toggleIsExpanded = () => {
    const { disable, children } = this.props;
    if (disable || !children) {
      return;
    }
    this.setState(prevState => ({
      isExpanded: !prevState.isExpanded,
    }));
  }

  render() {
    const { isExpanded } = this.state;

    return (
      <ExpandableBanner
        {...this.props}
        isExpanded={isExpanded}
        toggleIsExpanded={this.toggleIsExpanded}
      />
    );
  }
}

export default ExpandableBannerContainer;
