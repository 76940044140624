import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { t } from 'i18next';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVerticalIcon from '@material-ui/icons/MoreVert';

import ExpandableBanner from 'components/common/ExpandableBanner';
import TemplateItem from 'components/letterTemplates/TemplateItem';
import { correspondenceLetterCategoryNames } from 'constants/correspondence';

import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const TemplatesList = ({
  items,
  category,
  allowDefaultDownload = true,
  onUpdateTemplateDefinition,
  onEditTitle,
  setCustomTemplateBeingModified,
  onDelete,
  setCustomTemplateBeingDeleted,
}) => {
  const [anchorElement, setAnchorElement] = useState(null);
  const [targetTemplate, setTargetTemplate] = useState(null);

  const handleOpenMenu = (event, template) => {
    event.stopPropagation();
    setAnchorElement(event.currentTarget);
    setTargetTemplate(template);
  };

  const handleCloseMenu = () => {
    setAnchorElement(null);
    setTargetTemplate(null);
  };

  const handleEditCustomTemplate = (event) => {
    event.stopPropagation();

    onEditTitle();
    setCustomTemplateBeingModified(targetTemplate);

    setAnchorElement(null);
  };

  const handleDeleteCustomTemplate = (event) => {
    event.stopPropagation();

    onDelete();
    setCustomTemplateBeingDeleted(targetTemplate);

    setAnchorElement(null);
  };

  return (
    <>
      {items && !!items.length && (
        <>
          {category && (
            <h3 className={classNames(
              styles.long_primer,
              styles.is_black,
            )}
            >
              {t(correspondenceLetterCategoryNames[category])}
            </h3>
          )}
          {items.map(item => (
            <ExpandableBanner
              key={item.id || item.title}
              headerClasses={[rcStyles.expanding_banner__header]}
              title={(
                <div className={rcStyles.banner_content}>
                  <div className={rcStyles.is_title}>
                    {t(item.title)}
                  </div>
                  {item.type === 'CUSTOM' && (
                    <IconButton
                      aria-controls="edit-menu"
                      aria-haspopup="true"
                      data-test-id="moreOptions"
                      onClick={event => handleOpenMenu(event, item)}
                    >
                      <MoreVerticalIcon />
                    </IconButton>
                  )}
                </div>
          )}
              isClosedOnLoad
            >
              <TemplateItem
                template={item}
                allowDefaultDownload={allowDefaultDownload}
                onUpdateTemplateDefinition={onUpdateTemplateDefinition}
              />
            </ExpandableBanner>
          ))}
          <Menu
            anchorEl={anchorElement}
            keepMounted
            open={!!anchorElement}
            onClose={handleCloseMenu}
          >
            <MenuItem
              data-test-id="editTitle"
              onClick={handleEditCustomTemplate}
            >
              {t('Edit title')}
            </MenuItem>
            <MenuItem
              data-test-id="delete"
              onClick={handleDeleteCustomTemplate}
            >
              {t('Delete')}
            </MenuItem>
          </Menu>
        </>
      )}
    </>
  );
};

TemplatesList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  category: PropTypes.string,
  allowDefaultDownload: PropTypes.bool,
  onUpdateTemplateDefinition: PropTypes.func.isRequired,
  onEditTitle: PropTypes.func,
  setCustomTemplateBeingModified: PropTypes.func,
  onDelete: PropTypes.func,
  setCustomTemplateBeingDeleted: PropTypes.func,
};

export default TemplatesList;
