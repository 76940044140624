import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import AsyncPaginate from 'react-select-async-paginate';
import { currencySymbols } from 'constants/currency';
import { invoiceTypes } from 'constants/account';
import { invoiceType } from 'types/account';
import { getAsyncLookupOptionValue } from 'services/utils';
import classNames from 'classnames';
import styles from 'scss/main.scss';

const customSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? styles.colorGreyUltraLight : styles.colorWhite,
    color: 'inherit',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  control: (provided, state) => {
    const focusedStyle = state.isFocused ? styles.colorOffWhite : styles.colorWhite;
    return {
      'borderBottomStyle': 'solid',
      'borderBottomColor': state.isFocused ? styles.colorGreyUltraLight : styles.colorGreyDark,
      'borderBottomWidth': state.isFocused ? '2px' : '1px',
      'display': 'flex',
      'backgroundColor': state.hasValue ? styles.colorGreyUltraLight : focusedStyle,
      '&:hover': {
        borderBottomColor: styles.colorBlue,
      },
    };
  },
};

class InvoiceLookupContainer extends Component {
  static propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      invoiceType,
      PropTypes.string,
      PropTypes.shape({
        category: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
      PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.shape({
            category: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
          }),
        ]),
      ),
    ]),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    classes: PropTypes.arrayOf(PropTypes.string.isRequired),
    isMulti: PropTypes.bool,
    localInvoices: PropTypes.arrayOf(invoiceType),
    isClearable: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  getInvoiceById = (id) => {
    const { onChange } = this.props;
    const invoices = this.getLocalInvoices().options;

    onChange(invoices.find(item => item.value.id === id));
  }

  getLocalInvoices = () => {
    const { localInvoices } = this.props;
    const newResults = localInvoices
      .filter(invoice => !invoice.dateVoided && invoice.invoiceType === invoiceTypes.INVOICE)
      .map(invoice => ({
        label: `${invoice.number} - ${currencySymbols.GBP}${invoice.totals.total.amount}`,
        value: invoice,
      }));

    return {
      options: newResults,
      hasMore: false,
      additional: {
        lastCursor: null,
      },
    };
  }

  handleChange = (selected) => {
    const { isMulti, onChange } = this.props;
    if (!isMulti && !selected) {
      return onChange(null);
    }
    if (isMulti) {
      return onChange(selected.map(item => item.value));
    }

    return onChange(selected);
  }

  render() {
    const {
      label,
      disabled,
      isMulti,
      classes,
      isClearable,
    } = this.props;
    let { value: propValue } = this.props;

    if (typeof propValue === 'string') {
      this.getInvoiceById(propValue);
    }

    if (isMulti && propValue && typeof propValue[0] === 'string') {
      this.getInvoiceById(propValue);
    }

    if (propValue && typeof propValue === 'object' && !isMulti) {
      propValue = Array.isArray(propValue) && !propValue.length ? null : [propValue];
    }

    const value = propValue && Array.isArray(propValue)
      ? propValue.map(invoice => ({
        label: `${invoice.number} - ${currencySymbols.GBP}${invoice.totals.total.amount}`,
        value: invoice,
      }))
      : null;

    return (
      <AsyncPaginate
        id="invoiceSelect"
        className={classNames(classes)}
        value={value || ''}
        onChange={this.handleChange}
        placeholder={label}
        loadOptions={this.getLocalInvoices}
        getOptionValue={option => getAsyncLookupOptionValue(option)}
        isClearable={isClearable}
        isSearchable
        isMulti={isMulti}
        isDisabled={disabled}
        styles={customSelectStyles}
      />
    );
  }
}

export default withApollo(InvoiceLookupContainer);
