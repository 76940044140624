/* eslint-disable react/no-danger */
import React from 'react';
import DOMPurify, { sanitize } from 'dompurify';
import i18next from 'i18next';
import classNames from 'classnames';
import Collapse from '@material-ui/core/Collapse';
import IconMore from '@material-ui/icons/ExpandMore';
import IconLess from '@material-ui/icons/ExpandLess';

import { getFormattedDate } from 'services/utils';

import { Props } from './AnnouncementsSection.types';
import rcStyles from './styles.scss';

const AnnouncementsSection: React.FC<Props> = ({
  releaseAnnouncement, isExpanded, onToggleIsExpanded,
}: Props) => {
  DOMPurify.addHook('afterSanitizeAttributes', (node) => {
    if (node instanceof HTMLAnchorElement && 'target' in node) {
      node.setAttribute('rel', 'noopener noreferrer');
    }
  });

  return (
    <div className={rcStyles.announcements__section}>
      <div className={rcStyles.announcements__grid}>
        <div className={rcStyles.announcements__col}>
          <div className={rcStyles.announcements__accordion}>
            <div className={rcStyles.announcements__accordion__summary}>
              <div
                className={rcStyles.announcements__header}
                data-test-id={isExpanded ? 'accordionExpand' : 'accordionCollapse'}
                onClick={onToggleIsExpanded}
                role="button"
                tabIndex={-1}
              >
                <div className={rcStyles.announcements__header__text}>
                  {i18next.t('What\'s new')}
                </div>
                <div className={rcStyles.announcements__col}>
                  <div className={classNames(rcStyles.announcements__badge, rcStyles.date)}>
                    {getFormattedDate(releaseAnnouncement?.dateTime, 'DD.MM.YY')}
                  </div>
                </div>
                <div className={rcStyles.announcements__header__icon}>
                  {isExpanded ? <IconLess /> : <IconMore />}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div />
        <Collapse in={isExpanded}>
          <div className={rcStyles.announcements__accordion__details}>
            <div className={rcStyles.announcements__content}>
              <div dangerouslySetInnerHTML={{ __html: sanitize(releaseAnnouncement?.content ?? '', { ADD_ATTR: ['target'] }) }} />
            </div>
            <div className={rcStyles.announcements__actions}>
              {releaseAnnouncement?.linkUri && (
                <a href={releaseAnnouncement?.linkUri} className={rcStyles.announcements__link__release_notes}>
                  {i18next.t('Release notes')}
                </a>
              )}
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default AnnouncementsSection;
