import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ScreenLayout from 'components/common/ScreenLayout';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import IconLoad from 'icons/IconLoad';
import IconDragnDrop from 'icons/IconDragnDrop';
import SortableCategoryList from 'components/catalogue/SortableCategoryList';
import IconLoadSml from 'icons/IconLoadSml';

import rcStyles from './styles.scss';

const AdminArrangementSettingsScreen = ({
  categories,
  isLoading,
  isVisibilityEnabled,
  isSortEnabled,
  isSaving,
  onCancel,
  onEditCategory,
  onSetOrder,
  onEnableSort,
  onSortCategories,
  onSave,
}) => (
  <ScreenLayout
    title={t('Arrangement settings')}
    dashboardBannerProps={{
      breadcrumbs: [{ path: '/', title: t('Dashboard') }, { path: '/catalogue', title: t('Brochure management') }, { path: '/catalogue/arrangement-settings', title: t('Arrangement settings') }],
      description: t('Set the display order for categories in the arrangement view. When you\'re done, click save.'),
    }}
  >
    <div className={styles.o_view}>
      <div className={classNames(
        styles.o_block_content,
        styles.o_view__main,
      )}
      >
        {isLoading ? (
          <div className={styles.c_page__full_height}>
            <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
              <IconLoad />
            </div>
          </div>
        ) : (
          <Fragment>
            <span>
              <Button
                onClick={isSortEnabled ? onSetOrder : onEnableSort}
                variant="outlined"
                color="primary"
                data-test-id={isSortEnabled ? 'disableCategorySort' : 'enableCategorySort'}
              >
                {isSortEnabled ? t('Done ordering') : t('Set order')}
              </Button>
            </span>
            {isSortEnabled && (
              <div className={rcStyles.reorder_notes}>
                <IconDragnDrop />
                <div className={rcStyles.is_label}>
                  {t('Press and hold to drag & re-order a category')}
                </div>
              </div>
            )}
          </Fragment>
        )}
        <div className={styles.u_push__ends}>
          <SortableCategoryList
            axis="xy"
            categories={categories || []}
            onEditCategory={onEditCategory}
            onSortEnd={onSortCategories}
            disabled={!isSortEnabled}
            isIncludible={isVisibilityEnabled}
          />
        </div>
        <div className={classNames(
          styles.o_button_bar,
          styles.has_top_border,
        )}
        >
          <Button
            onClick={onCancel}
            variant="outlined"
            color="primary"
            data-test-id="arrangementSettingsCancelButton"
          >
            {t('Cancel')}
          </Button>
          <Button
            onClick={onSave}
            variant="contained"
            color="primary"
            data-test-id="saveArrangementCategoriesOrder"
            disabled={isSortEnabled}
          >
            {isSaving ? (
              <div
                className={classNames(
                  styles.c_svg_loader_sml,
                  styles.c_svg_loader_sml_btn,
                )}
                data-test-id="buttonLoader"
              >
                <IconLoadSml />
              </div>
            ) : t('Save')}
          </Button>
        </div>
      </div>
    </div>
  </ScreenLayout>
);

AdminArrangementSettingsScreen.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.any),
  isLoading: PropTypes.bool.isRequired,
  isVisibilityEnabled: PropTypes.bool.isRequired,
  isSortEnabled: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onSetOrder: PropTypes.func.isRequired,
  onEnableSort: PropTypes.func.isRequired,
  onSortCategories: PropTypes.func.isRequired,
  onEditCategory: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default AdminArrangementSettingsScreen;
