import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import { toTitleCase } from 'services/utils';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const Card = ({
  title,
  cardClasses,
  titleClasses,
  children,
  onEdit,
  disabled,
  testId,
}) => (
  <div
    className={classNames(
      rcStyles.card,
      cardClasses,
    )}
    data-test-id={`${testId}Card`}
  >
    <div className={classNames(
      styles.o_fb,
      styles.u_push__bottom,
    )}
    >
      {title && (
        <div
          className={classNames(
            titleClasses || styles.long_primer,
          )}
          data-test-id={`${testId}CardHeader`}
        >
          {title}
        </div>
      )}
      {onEdit && (
        <div className={rcStyles.is_header_action}>
          <IconButton
            onClick={onEdit}
            color="primary"
            variant="contained"
            size="small"
            disabled={disabled}
            data-test-id={`edit${toTitleCase(testId)}`}
          >
            <EditIcon />
          </IconButton>
        </div>
      )}
    </div>
    {children}
  </div>
);

Card.propTypes = {
  title: PropTypes.string,
  cardClasses: PropTypes.arrayOf(PropTypes.string),
  titleClasses: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onEdit: PropTypes.func,
  disabled: PropTypes.bool,
  testId: PropTypes.string.isRequired,
};

export default Card;
