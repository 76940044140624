import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import IconLoad from 'icons/IconLoad';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import AdminCataloguePackageForm from 'components/admin/AdminCataloguePackageForm';
import AdminPackageCatalogueItemSelection from 'components/admin/AdminPackageCatalogueItemSelection';
import ScreenLayout from 'components/common/ScreenLayout';
import classNames from 'classnames';

import styles from 'scss/main.scss';

const AdminCataloguePackageItemScreen = ({
  packageItem,
  selectedCatalogueItems,
  isLoading,
  isNewItem,
  isPackageItemFormValid,
  isValidationEnabled,
  setIsValid,
  onCancel,
  onChange,
  onMaxQuantityChange,
  onRemoveCatalogueItem,
  onSave,
  onSaveCatalogueItemsSelect,
  formRefs,
  errors,
}) => (
  <ScreenLayout
    title={isNewItem ? t('Create Package') : t('Edit Package')}
    dashboardBannerProps={{
      breadcrumbs: [{ path: '/', title: t('Dashboard') }, { path: '/catalogue', title: 'Brochure management' }, { path: '/catalogue/packages', title: t('Packages') }],
      title: t(`${isNewItem ? 'Create' : 'Edit'} ${'a package'}`),
      description: t('On this page you can create or edit packages. Add product or service selections into the package or set a limit on the number of choices in each product or service category.'),
    }}
  >
    {isLoading
      ? (
        <div className={classNames(styles.c_page__full_height, styles.has_background)}>
          <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
            <IconLoad />
          </div>
        </div>
      )
      : (
        <div className={styles.o_view}>
          <div className={styles.o_view__main}>
            <AdminCataloguePackageForm
              packageItem={packageItem}
              errors={errors}
              formRefs={formRefs}
              isNewItem={isNewItem}
              onChange={onChange}
              isValidationEnabled={isValidationEnabled}
              isPackageItemFormValid={isPackageItemFormValid}
              setIsValid={setIsValid}
              data-test-id="packageForm"
            />
            <AdminPackageCatalogueItemSelection
              packageItem={packageItem}
              selectedCatalogueItems={selectedCatalogueItems}
              onMaxQuantityChange={onMaxQuantityChange}
              onRemoveCatalogueItem={onRemoveCatalogueItem}
              onSaveCatalogueItemsSelect={onSaveCatalogueItemsSelect}
            />

            <div className={styles.c_k_tile}>
              <div className={classNames(
                styles.c_k_tile__action_bar,
                styles.is_clean,
                styles.u_hard__top,
              )}
              >
                <Button
                  onClick={onCancel}
                  variant="outlined"
                  color="primary"
                  data-test-id="cancel"
                >
                  {t('Cancel')}
                </Button>
                <Button
                  onClick={onSave}
                  variant="contained"
                  color="primary"
                  data-test-id={`${isNewItem ? 'create' : 'save'}`}
                >
                  {t(`${isNewItem ? 'Create' : 'Save'}`)}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )
    }
  </ScreenLayout>
);

AdminCataloguePackageItemScreen.propTypes = {
  packageItem: PropTypes.objectOf(PropTypes.any),
  selectedCatalogueItems: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool.isRequired,
  isNewItem: PropTypes.bool,
  isPackageItemFormValid: PropTypes.bool.isRequired,
  isValidationEnabled: PropTypes.bool.isRequired,
  setIsValid: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onMaxQuantityChange: PropTypes.func.isRequired,
  onRemoveCatalogueItem: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onSaveCatalogueItemsSelect: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(AdminCataloguePackageItemScreen);
