import {
  string, object,
} from 'yup';

export const validationSchema = object().shape({
  name: object().shape({
    title: string().nullable().required('Title is required'),
    givenName: string().nullable().required('Given name is required'),
    familyName: string().nullable().required('Family name is required'),
  }),
  email: string().nullable().email('Please enter a valid email address'),
});
