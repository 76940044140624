import React, { Component } from 'react';
import { bodySizeType } from 'types/bereavement';
import PropTypes from 'prop-types';
import BodySizeFormSectionView from './BodySizeFormSection';

const emptyBodySize = {
  length: null, width: null, depth: null, units: 'INCHES',
};

class BodySizeFormSection extends Component {
  static propTypes = {
    bodySize: bodySizeType,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any),
  }

  constructor(props) {
    super(props);

    const { bodySize } = props;

    this.state = {
      unit: bodySize?.units || 'INCHES',
      bodySize: {
        ...emptyBodySize,
        ...(bodySize || {}),
      },
    };
  }

  onChange = (key, value) => {
    const { onChange } = this.props;
    const { bodySize } = this.state;

    const updatedBodySize = {
      ...bodySize,
      [key]: value,
    };

    this.setState({
      bodySize: updatedBodySize,
      unit: updatedBodySize.units || 'INCHES',
    });

    onChange('bodySize', updatedBodySize);
  }

  render() {
    const { disabled, errors } = this.props;
    const { bodySize, unit } = this.state;
    return (
      <BodySizeFormSectionView
        bodySize={bodySize}
        unit={unit}
        disabled={disabled}
        onChange={this.onChange}
        errors={errors}
      />
    );
  }
}

export default BodySizeFormSection;
