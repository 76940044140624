import React, { Fragment } from 'react';
import { matchType } from 'types/reactRouter';
import { Switch, Route } from 'react-router-dom';
import NotFoundScreen from 'screens/NotFoundScreen';
import AdminDirectoryListingListScreen from 'screens/admin/AdminDirectoryListingListScreen';
import AdminDirectoryListingScreen from 'screens/admin/AdminDirectoryListingScreen';

const AdminHomesSectionScreen = ({
  match,
}) => (
  <Fragment>
    <Switch>
      <Route
        exact
        key="create"
        path={`${match.url}/create`}
        render={() => (
          <AdminDirectoryListingScreen parentUrl={match.url} />
        )}
      />
      <Route
        key="edit"
        path={`${match.url}/:directoryListingId`}
        render={() => (
          <AdminDirectoryListingScreen parentUrl={match.url} />
        )}
      />
      <Route
        exact
        key="list"
        path={`${match.url}`}
        render={() => (
          <AdminDirectoryListingListScreen parentUrl={match.url} />
        )}
      />
      <Route key="not-found" component={NotFoundScreen} />
    </Switch>
  </Fragment>
);

AdminHomesSectionScreen.propTypes = {
  match: matchType.isRequired,
};

export default AdminHomesSectionScreen;
