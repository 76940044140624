import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const IconUrn = (props) => {
  const { className } = props;
  const classes = className ? `${className} ${styles.rc_icon}` : styles.rc_icon;

  return (
    <svg viewBox="0.2 0.2 24 24" className={classes}><path d="M17.4 7.4l-.2-.5H7.4l-.3.5c0 .1-3.7 7.8 2.1 14.6l.3.3H15l.3-.3c6-6.8 2.2-14.5 2.1-14.6zm-3.2 13.1h-3.8c-4-4.9-2.5-10.1-1.8-11.7h7.5c.6 1.5 2.2 6.8-1.9 11.7zM15.2 3.9h-.7c0-1-.8-1.9-1.8-1.9h-.6c-1 0-1.8.8-1.8 1.9h-.7c-1 0-1.8.8-1.8 1.8h9.4c-.2-1-1.1-1.8-2-1.8z" /></svg>
  );
};

IconUrn.propTypes = {
  className: PropTypes.string,
};

export default IconUrn;
