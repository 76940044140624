import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddCircle from '@material-ui/icons/Edit';
import LocationOn from '@material-ui/icons/LocationOn';
import CloseIcon from '@material-ui/icons/Close';
import { isAddressEmpty, buildAddressString } from 'services/utils';
import { addressType } from 'types/common';
import BereavementAddressItem from 'components/common/BereavementAddressItem';
import LookUp from 'components/common/lookups/LookUp';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

import AddressModal from './AddressModal';

const AddressFormSection = ({
  address,
  margin,
  bereavementAddresses,
  addressesUsed,
  editingAddress,
  errors,
  lookUpValue,
  isLoading,
  isModalOpen,
  suggestions,
  lookupLabel,
  disabled,
  lookUpRef,
  addressRef,
  onChange,
  onLookUpChange,
  onSelect,
  onCancelModal,
  onSaveModal,
  onOpenModal,
  onClear,
}) => {
  const locationIcon = (
    <InputAdornment
      position="start"
      className={classNames(
        styles.o_icon__blue,
        rcStyles.location_icon,
      )}
    >
      <LocationOn />
    </InputAdornment>
  );

  const loadingIcon = isLoading && (
    <InputAdornment position="end" className={rcStyles.loading_icon}>
      <img src="/img/loading_field.gif" alt="Loading" className={styles.image} data-test-id="ellipsisLoader" />
    </InputAdornment>
  );

  const clearIcon = !isAddressEmpty(address) && !disabled && (
    <InputAdornment position="end" className={rcStyles.remove_icon}>
      <IconButton onClick={onClear} data-test-id="removeAddressButton">
        <CloseIcon />
      </IconButton>
    </InputAdornment>
  );

  const manualAddressIcon = !disabled && (
    <InputAdornment
      position="end"
      className={styles.o_icon__primary}
    >
      <IconButton onClick={onOpenModal} data-test-id="openEnterAddressButton">
        <AddCircle className={rcStyles.manual_address_icon} />
      </IconButton>
    </InputAdornment>
  );

  const bereavementSuggestions = bereavementAddresses.map(({ label, data }, index) => ({
    key: `${index}_${label}`,
    value: <BereavementAddressItem label={label} address={data} />,
    data,
  }));

  const bereavementAddressLabels = bereavementAddresses.map(({ data }) => buildAddressString(data));
  const additionalSuggestions = addressesUsed
    .filter(addressUsed => !bereavementAddressLabels.includes(buildAddressString(addressUsed)))
    .map((addressUsed) => {
      const stringAddress = buildAddressString(addressUsed);
      return {
        key: stringAddress,
        value: stringAddress,
        data: addressUsed,
      };
    });

  return (
    <Fragment>
      <div
        data-test-id="addressFormSection"
        className={classNames(
          styles.u_fullWidth,
          margin && rcStyles.rc_margin_bottom,
        )}
      >
        <LookUp
          lookUpValue={lookUpValue}
          suggestions={(!suggestions || !suggestions.length) ? (bereavementSuggestions || []) : (suggestions || [])}
          additionalLabel="Recent:"
          additionalSuggestions={additionalSuggestions.slice(0, 5)}
          label={lookupLabel}
          onChange={onLookUpChange}
          startComponent={locationIcon}
          endComponent={(
            <Fragment>
              {isLoading && (
                <div className={classNames(
                  rcStyles.loading_icon,
                )}
                >
                  {loadingIcon}
                </div>
              )}
              {clearIcon && (
                <div className={classNames(
                  styles.o_fb,
                  styles.o_fb__v_bottom,
                )}
                >
                  <div className={rcStyles.option_icon}>
                    {clearIcon}
                  </div>
                </div>
              )}
              {manualAddressIcon && (
                <div className={rcStyles.option_icon}>
                  {manualAddressIcon}
                </div>
              )}
            </Fragment>
          )}
          data-test-id="addressLookUp"
          fullWidth
          error={errors && Object.keys(errors).length > 0}
          disabled={disabled}
          onSelect={onSelect}
          lookUpRef={lookUpRef}
        />
        {errors && (
          <FormHelperText
            className={styles.u_push__bottom}
            error
          >
            {typeof errors === 'string' && errors}
            {typeof errors === 'object' && t('There are errors in the address')}
          </FormHelperText>
        )}
      </div>
      <AddressModal
        address={editingAddress}
        isOpen={isModalOpen}
        errors={errors}
        disabled={disabled}
        addressRef={addressRef}
        onChange={onChange}
        onCancel={onCancelModal}
        onSave={onSaveModal}
        data-test-id="addressModal"
      />
    </Fragment>
  );
};

AddressFormSection.propTypes = {
  address: addressType,
  bereavementAddresses: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    data: addressType,
  })),
  addressesUsed: PropTypes.arrayOf(addressType).isRequired,
  editingAddress: addressType,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  lookUpValue: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  margin: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  lookupLabel: PropTypes.string,
  disabled: PropTypes.bool,
  lookUpRef: PropTypes.objectOf(PropTypes.any).isRequired,
  addressRef: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  onLookUpChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onCancelModal: PropTypes.func.isRequired,
  onSaveModal: PropTypes.func.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default AddressFormSection;
