import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { historyType, matchType, locationType } from 'types/reactRouter';
import { navigationPathType } from 'types/common';
import PropTypes from 'prop-types';
import ArrangementNavigationMenu from './ArrangementNavigationMenu';

class ArrangementNavigationMenuContainer extends Component {
  static propTypes = {
    navigationPaths: PropTypes.arrayOf(navigationPathType).isRequired,
    history: historyType.isRequired,
    location: locationType,
    match: matchType.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  currentPathIndex = () => {
    const { navigationPaths, location, match } = this.props;
    const currentPath = location.pathname.replace(`${match.url}/`, '');
    return navigationPaths.findIndex(navigationPath => navigationPath.path === currentPath);
  }

  handleNext = () => {
    const { navigationPaths, history, match } = this.props;
    this.setState({ isOpen: false });
    return history.push(`${match.url}/${navigationPaths[this.currentPathIndex() + 1].path}`);
  }

  handlePrevious = () => {
    const { navigationPaths, history, match } = this.props;
    this.setState({ isOpen: false });
    return history.push(`${match.url}/${navigationPaths[this.currentPathIndex() - 1].path}`);
  }

  toggleMenu = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  }

  handleEstimateClick = () => {
    const { history, match } = this.props;
    this.toggleMenu();
    return history.push(`${match.url}/estimate`);
  };

  render() {
    const { navigationPaths } = this.props;
    const { isOpen } = this.state;
    const currentIndex = this.currentPathIndex();
    const isLastCategory = currentIndex === navigationPaths.length - 1;
    const isFirstCategory = !currentIndex;
    const nextPathName = !isLastCategory
      ? navigationPaths[currentIndex + 1]
      && navigationPaths[currentIndex + 1].name
      : '';
    const previousPathName = !isFirstCategory
      ? navigationPaths[currentIndex - 1]
      && navigationPaths[currentIndex - 1].name
      : '';

    return (
      <ArrangementNavigationMenu
        onEstimateClick={this.handleEstimateClick}
        onNext={this.handleNext}
        onPrevious={this.handlePrevious}
        onViewEstimate={this.handleViewEstimate}
        navigationPaths={navigationPaths}
        nextPathName={nextPathName}
        previousPathName={previousPathName}
        isOpen={isOpen}
        toggleMenu={this.toggleMenu}
        currentIndex={currentIndex}
      />
    );
  }
}

export default withRouter(ArrangementNavigationMenuContainer);
