export const discountTypes = {
  NONE: 'NONE',
  GLOBAL: 'GLOBAL',
  ITEMISED: 'ITEMISED',
};

export const productCategories = {
  COFFINS: 'COFFINS',
  FLOWERS: 'FLOWERS',
  URNS: 'URNS',
  MEMORIALISATION: 'MEMORIALISATION',
};

export const serviceCategories = {
  DISBURSEMENTS: 'DISBURSEMENTS',
  VEHICLES: 'VEHICLES',
  OFFICIANTS: 'OFFICIANTS',
  SERVICE_VENUES: 'SERVICE_VENUES',
  CREMATORIA: 'CREMATORIA',
  PROFESSIONAL_SERVICES: 'PROFESSIONAL_SERVICES',
  OTHER: 'OTHER',
  CEMETERIES: 'CEMETERIES',
  RECEPTION_VENUES: 'RECEPTION_VENUES',
};

export const categories = {
  ...productCategories,
  ...serviceCategories,
  PACKAGES: 'PACKAGES',
};

export const officiantVenueCategories = [
  serviceCategories.SERVICE_VENUES,
  serviceCategories.CREMATORIA,
  serviceCategories.CEMETERIES,
  serviceCategories.RECEPTION_VENUES,
];

export const venueCategoryPaths = {
  SERVICE_VENUE: 'service-venue',
  CREMATORIUM: 'crematorium',
  BURIAL: 'burial',
  RECEPTION_VENUE: 'reception-venue',
};

export const arrangementPaths = {
  PACKAGES: 'packages',
  CARE: 'care',
  SERVICE_VENUE: 'service-venue',
  CREMATORIUM: 'crematorium',
  BURIAL: 'burial',
  RECEPTION_VENUE: 'reception-venue',
  CORTEGE: 'cortege',
  COFFINS: 'coffins',
  URNS: 'urns',
  OFFICIANTS: 'officiants',
  MUSIC: 'music',
  FLOWERS: 'flowers',
  OTHER: 'other',
  MEMORIALISATION: 'memorialisation',
  OBITUARY: 'obituary',
  ESTIMATE: 'estimate',
};

export const requiredCategoriesForBurial = [
  categories.SERVICE_VENUES,
  categories.CEMETERIES,
  categories.RECEPTION_VENUES,
  categories.VEHICLES,
  categories.COFFINS,
  categories.OFFICIANTS,
  categories.FLOWERS,
  categories.MEMORIALISATION,
];

export const requiredCategoriesForCremation = [
  categories.SERVICE_VENUES,
  categories.CEMETERIES,
  categories.RECEPTION_VENUES,
  categories.VEHICLES,
  categories.COFFINS,
  categories.OFFICIANTS,
  categories.FLOWERS,
  categories.MEMORIALISATION,
  categories.URNS,
  categories.CREMATORIA,
];

export const additionalRequiredCategoriesForPackage = [
  categories.OTHER,
];

export const routesForCategory = {
  [serviceCategories.SERVICE_VENUES]: 'service-venue',
  [serviceCategories.CREMATORIA]: 'crematorium',
  [serviceCategories.CEMETERIES]: 'burial',
  [serviceCategories.RECEPTION_VENUES]: 'reception-venue',
  [serviceCategories.VEHICLES]: 'cortege',
  [productCategories.COFFINS]: 'coffins',
  [productCategories.URNS]: 'urns',
  [serviceCategories.OFFICIANTS]: 'officiants',
  [productCategories.FLOWERS]: 'flowers',
  [serviceCategories.OTHER]: 'other',
  [productCategories.MEMORIALISATION]: 'memorialisation',
  [serviceCategories.PROFESSIONAL_SERVICES]: 'professional-services',
};

export const editableSelectionCategories = {
  ...serviceCategories,
  ...productCategories,
};

export const committalTypes = {
  CREMATION: 'CREMATION',
  BURIAL: 'BURIAL',
};

export const committalTypeLabels = [
  { value: committalTypes.CREMATION, label: 'Cremation' },
  { value: committalTypes.BURIAL, label: 'Burial' },
];

export const changeConfirmedArrangementMessage = 'This arrangement has been confirmed.\nIf you continue - this arrangement will be unconfirmed.';
export const changeConfirmedArrangementAndPackageMessage = 'This arrangement has been confirmed.\nIf you continue - this arrangement will be reset and selections lost.';
export const changePackageMessage = 'You have already selected a package.\nIf you continue - then all current selections will be lost.';

export const catalogueItemTypes = {
  PRODUCT: 'PRODUCT',
  SERVICE: 'SERVICE',
};

export const addressServiceCategories = [
  { name: 'Service venue', value: serviceCategories.SERVICE_VENUES },
  { name: 'Crematoria', value: serviceCategories.CREMATORIA },
  { name: 'Cemetery', value: serviceCategories.CEMETERIES },
  { name: 'Reception venue', value: serviceCategories.RECEPTION_VENUES },
];

export const coffinShapes = {
  TRADITIONAL: 'TRADITIONAL',
  CASKET: 'CASKET',
  AMERICAN_CASKET: 'AMERICAN_CASKET',
  ROUND_OR_TEAR: 'ROUND_OR_TEAR',
};

export const coffinShapeOptions = [
  { name: 'Traditional', value: coffinShapes.TRADITIONAL },
  { name: 'Casket', value: coffinShapes.CASKET },
  { name: 'American Casket', value: coffinShapes.AMERICAN_CASKET },
  { name: 'Round/Tear', value: coffinShapes.ROUND_OR_TEAR },
];

export const coffinHandleTypeOptions = [
  'Standard',
  'Bar',
  'Hinged',
];

export const emptyCategoryInformation = {
  crematorium: null,
  serviceVenue: null,
  cemetery: null,
  receptionVenue: null,
  care: null,
  vehicles: null,
  coffin: null,
  urn: null,
  officiant: null,
  music: null,
  flowers: null,
  charities: null,
  other: null,
  obituary: null,
  memorialisation: null,
};

export const receptionVenueSequenceTypes = {
  PRE_SERVICE: 'PRE_SERVICE',
  POST_SERVICE: 'POST_SERVICE',
};

export const receptionVenueSequenceLabels = {
  [receptionVenueSequenceTypes.PRE_SERVICE]: 'Pre service',
  [receptionVenueSequenceTypes.POST_SERVICE]: 'Post service',
};

export const arrangementTypes = {
  AT_NEED: 'AT_NEED',
  PRE_NEED: 'PRE_NEED',
};

export const arrangementCategories = {
  PACKAGES: 'PACKAGES',
  CARE: 'CARE',
  VENUES: 'VENUES',
  CORTEGE: 'CORTEGE',
  COFFINS: 'COFFINS',
  URNS: 'URNS',
  OFFICIANTS: 'OFFICIANTS',
  MUSIC: 'MUSIC',
  FLOWERS: 'FLOWERS',
  OTHER: 'OTHER',
  MEMORIALISATION: 'MEMORIALISATION',
  OBITUARY: 'OBITUARY',
};

export const emptyOfficiantServiceSelectionCategoryMetaData = {
  venueSelectionIds: [],
  directoryListingId: null,
  customDetails: null,
};

export const emptyOfficiantCustomDetails = {
  name: '',
  phone: null,
  address: null,
  email: null,
};

export const obituaryCategories = {
  CATEGORY_FUNERAL_GUIDE: 'Funeral Guide',
  CATEGORY_PUBLICATION: 'Publication',
};
