import ArrangementPackagesScreen from 'screens/arrangement/ArrangementPackagesScreen';
import ArrangementCareForm from 'components/arrangement/ArrangementCareForm';
import ArrangementCrematoriumForm from 'components/arrangement/ArrangementCrematoriumForm';
import ArrangementVenueForm from 'components/arrangement/ArrangementVenueForm';
import ArrangementBurialForm from 'components/arrangement/ArrangementBurialForm';
import ArrangementReceptionVenueForm from 'components/arrangement/ArrangementReceptionVenueForm';
import ArrangementVehiclesForm from 'components/arrangement/ArrangementVehiclesForm';
import ArrangementCoffinsForm from 'components/arrangement/ArrangementCoffinsForm';
import ArrangementUrnsForm from 'components/arrangement/ArrangementUrnsForm';
import ArrangementOfficiantsForm from 'components/arrangement/ArrangementOfficiantsForm';
import ArrangementMusicForm from 'components/arrangement/ArrangementMusicForm';
import ArrangementFlowersForm from 'components/arrangement/ArrangementFlowersForm';
import ArrangementOtherForm from 'components/arrangement/ArrangementOtherForm';
import ArrangementObituaryForm from 'components/arrangement/ArrangementObituaryForm';
import ArrangementMemorialisationForm from 'components/arrangement/ArrangementMemorialisationForm';
import ArrangementEstimateScreen from 'screens/arrangement/ArrangementEstimateScreen';
import {
  arrangementPaths, productCategories, serviceCategories, catalogueItemTypes,
} from 'constants/arrangement';

export const pathNavigations = {
  PACKAGES: 'PACKAGES',
  CARE: 'CARE',
  VENUES: 'VENUES',
  CREMATORIUM: 'CREMATORIUM',
  CEMETERIES: 'CEMETERIES',
  RECEPTION_VENUES: 'RECEPTION_VENUES',
  CORTEGE: 'CORTEGE',
  COFFINS: 'COFFINS',
  URNS: 'URNS',
  OFFICIANTS: 'OFFICIANTS',
  MUSIC: 'MUSIC',
  FLOWERS: 'FLOWERS',
  OTHER: 'OTHER',
  MEMORIALISATION: 'MEMORIALISATION',
  OBITUARY: 'OBITUARY',
  ESTIMATE: 'ESTIMATE',
};

export const navigation = [
  {
    path: arrangementPaths.PACKAGES,
    name: 'Packages',
    component: ArrangementPackagesScreen,
    pathCategory: pathNavigations.PACKAGES,
    isScreen: true,
  },
  {
    path: arrangementPaths.CARE,
    name: 'Care',
    component: ArrangementCareForm,
    pathCategory: pathNavigations.CARE,
    requiresFeatureFlag: true,
  },
  {
    path: arrangementPaths.SERVICE_VENUE,
    name: 'Venues',
    category: serviceCategories.SERVICE_VENUES,
    type: catalogueItemTypes.SERVICE,
    component: ArrangementVenueForm,
    isVenueScreen: true,
    pathCategory: pathNavigations.VENUES,
  },
  {
    path: arrangementPaths.CREMATORIUM,
    name: 'Crematorium',
    category: serviceCategories.CREMATORIA,
    type: catalogueItemTypes.SERVICE,
    component: ArrangementCrematoriumForm,
    isVenueScreen: true,
    pathCategory: pathNavigations.CREMATORIUM,
  },
  {
    path: arrangementPaths.BURIAL,
    name: 'Burial',
    category: serviceCategories.CEMETERIES,
    type: catalogueItemTypes.SERVICE,
    component: ArrangementBurialForm,
    isVenueScreen: true,
    pathCategory: pathNavigations.CEMETERIES,
  },
  {
    path: arrangementPaths.RECEPTION_VENUE,
    name: 'Reception',
    category: serviceCategories.RECEPTION_VENUES,
    type: catalogueItemTypes.SERVICE,
    component: ArrangementReceptionVenueForm,
    isVenueScreen: true,
    pathCategory: pathNavigations.RECEPTION_VENUES,
  },
  {
    path: arrangementPaths.CORTEGE,
    name: 'Cortege',
    category: serviceCategories.VEHICLES,
    type: catalogueItemTypes.SERVICE,
    component: ArrangementVehiclesForm,
    pathCategory: pathNavigations.CORTEGE,
  },
  {
    path: arrangementPaths.COFFINS,
    name: 'Coffins',
    category: productCategories.COFFINS,
    type: catalogueItemTypes.PRODUCT,
    component: ArrangementCoffinsForm,
    pathCategory: pathNavigations.COFFINS,
  },
  {
    path: arrangementPaths.URNS,
    name: 'Urns',
    category: productCategories.URNS,
    type: catalogueItemTypes.PRODUCT,
    component: ArrangementUrnsForm,
    pathCategory: pathNavigations.URNS,
  },
  {
    path: arrangementPaths.OFFICIANTS,
    name: 'Officiants',
    category: serviceCategories.OFFICIANTS,
    type: catalogueItemTypes.SERVICE,
    component: ArrangementOfficiantsForm,
    pathCategory: pathNavigations.OFFICIANTS,
    hideSelectedItems: true,
  },
  {
    path: arrangementPaths.MUSIC,
    name: 'Music',
    component: ArrangementMusicForm,
    pathCategory: pathNavigations.MUSIC,
    requiresFeatureFlag: true,
  },
  {
    path: arrangementPaths.FLOWERS,
    name: 'Flowers',
    category: productCategories.FLOWERS,
    type: catalogueItemTypes.PRODUCT,
    component: ArrangementFlowersForm,
    pathCategory: pathNavigations.FLOWERS,
  },
  {
    path: arrangementPaths.OTHER,
    name: 'Other',
    category: serviceCategories.OTHER,
    type: catalogueItemTypes.SERVICE,
    component: ArrangementOtherForm,
    pathCategory: pathNavigations.OTHER,
  },
  {
    path: arrangementPaths.MEMORIALISATION,
    name: 'Memorialisation',
    category: productCategories.MEMORIALISATION,
    type: catalogueItemTypes.PRODUCT,
    component: ArrangementMemorialisationForm,
    pathCategory: pathNavigations.MEMORIALISATION,
  },
  {
    path: arrangementPaths.OBITUARY,
    name: 'Obituary',
    component: ArrangementObituaryForm,
    pathCategory: pathNavigations.OBITUARY,
    requiresFeatureFlag: true,
  },
  {
    path: arrangementPaths.ESTIMATE,
    name: 'Estimate',
    component: ArrangementEstimateScreen,
    isScreen: true,
    pathCategory: pathNavigations.ESTIMATE,
  },
];

export default navigation;
