import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const IconAddEmail = (props) => {
  const { className } = props;
  const classes = className ? `${className} ${styles.rc_icon}` : styles.rc_icon;

  return (
    <svg viewBox="0 0 24 24" className={classes}>
      <path d="M14.5,10h-12C1.7,10,1,10.7,1,11.5l0,9C1,21.3,1.7,22,2.5,22h12c0.8,0,1.5-0.7,1.5-1.5v-9 C16,10.7,15.3,10,14.5,10z M14.2,13.3l-5.6,3.3L3,13.3V12l5.6,3.3l5.6-3.3V13.3z" />
      <path d="M19.5,4.5H23v2h-3.5V10h-2V6.5H14v-2h3.5V1h2V4.5z" />
    </svg>
  );
};

IconAddEmail.propTypes = {
  className: PropTypes.string,
};

export default IconAddEmail;
