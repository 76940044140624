import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from 'components/common/Select';
import LookUp from 'components/common/lookups/LookUp';
import IconCharity from 'icons/IconCharity';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const CharityJustGivingForm = ({
  formData,
  disabled,
  errors,
  suggestions,
  departments,
  lookUpValue,
  isLoading,
  isLoadingCharity,
  isOtherDepartmentSelected,
  onChangeCharity,
  onChangeDepartment,
  onChangeDepartmentOtherName,
  onLookUpChange,
}) => {
  const locationIcon = (
    <InputAdornment
      position="start"
      className={classNames(
        styles.o_icon__primary,
        rcStyles.inline_icon,
      )}
    >
      <IconCharity />
    </InputAdornment>
  );

  const loadingIcon = isLoading ? (
    <InputAdornment position="end" className={styles.o_icon__primary}>
      <img src="/img/loading_field.gif" alt="Loading" className={styles.image} data-test-id="ellipsisLoader" />
    </InputAdornment>
  ) : null;

  return (
    <div className={rcStyles.v_spacing}>
      <LookUp
        lookUpValue={lookUpValue}
        suggestions={suggestions}
        label={t('Enter a charity registered with JustGiving')}
        onChange={onLookUpChange}
        startComponent={locationIcon}
        endComponent={loadingIcon}
        fullWidth
        disabled={disabled}
        error={errors?.name}
        onSelect={charity => onChangeCharity(charity)}
        data-test-id="charityLookup"
      />
      {formData?.id && (
        <div className={rcStyles.department_inputs}>
          <div className={rcStyles.department_inputs__item}>
            <Select
              label={t('Department')}
              value={formData?.department?.id || null}
              onChange={event => onChangeDepartment(event.target.value)}
              inputProps={{
                'data-test-id': 'department',
              }}
              displayEmpty
              disabled={disabled || isLoadingCharity || !formData.id}
              error={errors?.department?.id}
              helperText={errors?.department?.id}
            >
              <MenuItem value={null}>{t('No department')}</MenuItem>
              {departments.map(department => (
                <MenuItem key={department.id} value={department.id}>{department.name}</MenuItem>
              ))}
              <MenuItem value="OTHER">{t('Other')}</MenuItem>
            </Select>
          </div>
          {isOtherDepartmentSelected && (
            <TextField
              label={t('Department name')}
              value={formData.department && formData.department?.otherName ? formData.department.otherName : ''}
              onChange={event => onChangeDepartmentOtherName(event.target.value)}
              inputProps={{
                'data-test-id': 'departmentOtherName',
              }}
              disabled={disabled || isLoadingCharity || !formData.id}
              error={errors?.department?.otherName}
              helperText={errors?.department?.otherName}
              className={rcStyles.department_inputs__item}
            />
          )}
        </div>
      )}
    </div>
  );
};

CharityJustGivingForm.propTypes = {
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
  disabled: PropTypes.bool.isRequired,
  errors: PropTypes.objectOf(PropTypes.any),
  suggestions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  departments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  lookUpValue: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingCharity: PropTypes.bool.isRequired,
  isOtherDepartmentSelected: PropTypes.bool.isRequired,
  onChangeCharity: PropTypes.func.isRequired,
  onChangeDepartment: PropTypes.func.isRequired,
  onChangeDepartmentOtherName: PropTypes.func.isRequired,
  onLookUpChange: PropTypes.func.isRequired,
};

export default CharityJustGivingForm;
