import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { accountTransactionType } from 'constants/account';
import { addCreditTransform } from 'transforms/account';
import { creditType, invoiceType } from 'types/account';
import AccountAddCreditModal from './AccountAddCreditModal';
import { validationSchema } from './validation';

class AccountAddCreditModalContainer extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    invoices: PropTypes.arrayOf(invoiceType),
    transactionBeingModified: creditType,
    setTransactionBeingModified: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    generateRefs: PropTypes.func.isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isOpen && nextProps.transactionBeingModified && !Object.keys(prevState.formData).length) {
      return {
        isEditingTransaction: true,
        formData: {
          id: nextProps.transactionBeingModified.id,
          amount: nextProps.transactionBeingModified.amount,
          reason: nextProps.transactionBeingModified.reason || null,
          invoice: nextProps.transactionBeingModified.invoiceId || '',
          createdAt: nextProps.transactionBeingModified.createdAt || null,
        },
      };
    }
    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      formData: {},
      isValidationEnabled: false,
    };

    this.dialogRef = React.createRef();
  }

  componentDidMount() {
    const { generateRefs } = this.props;
    generateRefs(Object.keys(validationSchema.fields));
  }

  handleChange = (key, value) => {
    const { validate } = this.props;
    const { formData } = this.state;
    const newFormData = {
      ...formData,
      [key]: value,
    };
    validate(newFormData, validationSchema);
    this.setState({ formData: newFormData });
  }

  handleClose = () => {
    const { onClose, setTransactionBeingModified } = this.props;
    this.setState({
      formData: {},
      isEditingTransaction: false,
      isValidationEnabled: false,
    });
    setTransactionBeingModified(accountTransactionType.CREDIT, null);
    onClose();
  }

  handleSave = () => {
    const { onSave, validate } = this.props;
    const { formData, isEditingTransaction } = this.state;

    const credit = addCreditTransform(formData);
    const isValid = validate(credit, validationSchema, true, this.dialogRef);

    this.setState({
      isValidationEnabled: true,
    });

    if (isValid) {
      onSave(credit, isEditingTransaction);
      this.handleClose();
    }
  }

  render() {
    const {
      isOpen, formRefs, errors, invoices,
    } = this.props;
    const { formData, isValidationEnabled, isEditingTransaction } = this.state;
    return (
      <AccountAddCreditModal
        formData={formData}
        isOpen={isOpen}
        onClose={this.handleClose}
        onSave={this.handleSave}
        onChange={this.handleChange}
        invoices={invoices}
        isEditing={isEditingTransaction}
        dialogRef={this.dialogRef}
        formRefs={formRefs}
        errors={isValidationEnabled ? errors : {}}
      />
    );
  }
}

export default withValidation(AccountAddCreditModalContainer);
