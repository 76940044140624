import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { arrangementType } from 'types/bereavement';
import ArrangementListItem from './ArrangementListItem';


export class ArrangementListItemContainer extends Component {
  static propTypes = {
    item: arrangementType.isRequired,
    disabled: PropTypes.bool,
    isExpanded: PropTypes.bool.isRequired,
    viewArrangement: PropTypes.func.isRequired,
    confirmArrangement: PropTypes.func.isRequired,
    unconfirmArrangement: PropTypes.func.isRequired,
    onEditReference: PropTypes.func.isRequired,
    toggleArrangementCollapse: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      anchorElement: null,
    };
  }

  handleMenuOpen = (event) => {
    this.setState({ anchorElement: event.currentTarget });
  }

  handleMenuClose = () => {
    this.setState({ anchorElement: null });
  }

  render() {
    const { anchorElement } = this.state;
    return (
      <ArrangementListItem
        {...this.props}
        anchorElement={anchorElement}
        onMenuOpen={this.handleMenuOpen}
        onMenuClose={this.handleMenuClose}
      />
    );
  }
}

export default ArrangementListItemContainer;
