import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { withSnackbar } from 'notistack';

import CorrespondenceReportModal from 'components/bereavement/CorrespondenceReportModal';
import { apolloClientType } from 'types/apollo';

import {
  getGeneratedCorrespondenceById,
  getGeneratedCorrespondenceByType,
} from './queries.gql';
import CorrespondenceModal from './CorrespondenceModal';

class CorrespondenceModalContainer extends Component {
  static propTypes = {
    bereavementId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    client: apolloClientType.isRequired,
    correspondenceCategory: PropTypes.string.isRequired,
    templateDefinitionsLookup: PropTypes.objectOf(PropTypes.any),
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: {
        estimateConfirmationToProceed: false,
        confirmationOfEstimateAndServiceInformationCrematorium: false,
        confirmationOfEstimateAndServiceInformationBurial: false,
        officiantConfirmationCremation: false,
        officiantConfirmationBurial: false,
        prePaymentInFullRequest: false,
        statementOfAccount: false,
        paymentOverdue: false,
        paymentReceipt: false,
        obituaryDetails: false,
        custom: false,
      },
      template: null,
      report: [],
      correspondenceUri: null,
      showDefaultValuesInLetter: true,
    };
  }

  handleDownload = async (template) => {
    const { client, bereavementId } = this.props;
    const { showDefaultValuesInLetter } = this.state;

    this.setState(prevState => ({
      template,
      isLoading: {
        ...prevState.isLoading,
        [template.key]: true,
      },
    }));

    const query = template.id ? getGeneratedCorrespondenceById : getGeneratedCorrespondenceByType;
    const key = template.id ? 'id' : 'type';
    const value = template.id ? template.id : template.type;
    const variables = {
      bereavementId,
      [key]: value,
      hideDefaultValues: !showDefaultValuesInLetter,
    };

    const { data } = await client.query({
      query,
      variables,
    });

    const { bereavement = {} } = data || {};
    const { generatedCorrespondence } = bereavement || {};
    const { media, report } = generatedCorrespondence || {};
    const { uri } = media || {};

    const hasReport = !!report.length;

    this.setState(prevState => ({
      isLoading: {
        ...prevState.isLoading,
        [template.key]: false,
      },
      report: hasReport ? report : [],
      correspondenceUri: hasReport ? uri : null,
    }));

    if (!hasReport) {
      window.open(uri, uri).focus();
    }
  }

  handleCancelCorrespondenceDownload = () => {
    this.setState({
      report: [],
      correspondenceUri: null,
      template: null,
      showDefaultValuesInLetter: true,
    });
  }

  handleContinueCorrespondenceDownload = () => {
    const {
      correspondenceUri,
      showDefaultValuesInLetter,
      template,
    } = this.state;
    if (correspondenceUri && showDefaultValuesInLetter) {
      window.open(correspondenceUri, correspondenceUri).focus();
    }
    if (correspondenceUri && !showDefaultValuesInLetter) {
      this.handleDownload(template);
    }
    this.setState({
      report: [],
      correspondenceUri: null,
      showDefaultValuesInLetter: true,
    });
  }

  handleShowDefaultValuesInLetter = (checked) => {
    this.setState({ showDefaultValuesInLetter: checked });
  }

  render() {
    const {
      isLoading,
      report,
      showDefaultValuesInLetter,
    } = this.state;
    return (
      <>
        <CorrespondenceModal
          {...this.props}
          onDownload={this.handleDownload}
          isLoading={isLoading}
        />
        <CorrespondenceReportModal
          report={report}
          isOpen={!!report?.length}
          showDefaultValuesInLetter={showDefaultValuesInLetter}
          onCancel={this.handleCancelCorrespondenceDownload}
          onContinue={this.handleContinueCorrespondenceDownload}
          onChange={this.handleShowDefaultValuesInLetter}
        />
      </>
    );
  }
}

export default withSnackbar(
  withApollo(CorrespondenceModalContainer),
);
