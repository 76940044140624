import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { months } from 'constants/calendar';
import { getMonth, getYearRange } from 'services/utils';

const DatePickerHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => (
  <div
    style={{
      margin: 10,
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
      {'<'}
    </button>
    <select
      value={months[getMonth(date)]}
      onChange={({ target: { value: monthValue } }) => changeMonth(months.indexOf(monthValue))}
    >
      {months.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
    <select
      value={moment(date).format('YYYY')}
      onChange={({ target: { value: yearValue } }) => changeYear(yearValue)}
    >
      {getYearRange().map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
    <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
      {'>'}
    </button>
  </div>
);

DatePickerHeader.propTypes = {
  date: PropTypes.objectOf(PropTypes.any),
  changeYear: PropTypes.func,
  changeMonth: PropTypes.func,
  decreaseMonth: PropTypes.func,
  increaseMonth: PropTypes.func,
  prevMonthButtonDisabled: PropTypes.bool,
  nextMonthButtonDisabled: PropTypes.bool,
};

export default DatePickerHeader;
