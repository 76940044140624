import { v4 as uuidv4 } from 'uuid';
import { removePropertyByName } from 'services/utils';
import { editDirectoryListingInputTransform } from 'transforms/directoryListing';
import { getDirectoryListings } from './directoryListings.queries.gql';
import {
  createDirectoryListingMutation,
  editDirectoryListingMutation,
  publishDirectoryListingMutation,
  retireDirectoryListingMutation,
} from './directoryListings.mutations.gql';

export const directoryListingsActions = {
  SET_DIRECTORY_LISTINGS: 'DIRECTORY_LISTING_SET_DIRECTORY_LISTINGS',
  CLEAR_DIRECTORY_LISTINGS: 'DIRECTORY_LISTING_CLEAR_DIRECTORY_LISTINGS',
  ADD_DIRECTORY_LISTINGS: 'DIRECTORY_LISTING_ADD_DIRECTORY_LISTINGS',
  ADD_DIRECTORY_LISTING: 'DIRECTORY_LISTING_ADD_DIRECTORY_LISTING',
  UPDATE_DIRECTORY_LISTING: 'DIRECTORY_LISTING_UPDATE_DIRECTORY_LISTING',
  REMOVE_DIRECTORY_LISTING: 'DIRECTORY_LISTING_REMOVE_DIRECTORY_LISTING',
  UPDATE_FILTERS: 'DIRECTORY_LISTING_UPDATE_FILTERS',
  SET_LOADING: 'DIRECTORY_LISTING_SET_LOADING',
};

export const setDirectoryListingsAction = payload => (
  { type: directoryListingsActions.SET_DIRECTORY_LISTINGS, payload }
);

export const clearDirectoryListingsAction = () => (
  { type: directoryListingsActions.CLEAR_DIRECTORY_LISTINGS }
);

export const addDirectoryListingsAction = payload => (
  { type: directoryListingsActions.ADD_DIRECTORY_LISTINGS, payload }
);

export const addDirectoryListingAction = payload => (
  { type: directoryListingsActions.ADD_DIRECTORY_LISTING, payload }
);

export const updateDirectoryListingAction = payload => (
  { type: directoryListingsActions.UPDATE_DIRECTORY_LISTING, payload }
);

export const removeDirectoryListingAction = payload => (
  { type: directoryListingsActions.REMOVE_DIRECTORY_LISTING, payload }
);

export const updateFiltersAction = (key, value) => (
  { type: directoryListingsActions.UPDATE_FILTERS, payload: { key, value } }
);

export const setLoadingAction = payload => (
  { type: directoryListingsActions.SET_LOADING, payload }
);

export const fetchDirectoryListingsAction = (filters, optionalPaginationFirst) => (dispatch, getState, client) => {
  dispatch(setLoadingAction(true));
  if (filters) {
    dispatch(clearDirectoryListingsAction());
  }

  const { pagination } = getState().directoryListingsStore;
  const { searchTerm, status, categories } = filters || getState().directoryListingsStore.filters;

  const query = client.query({
    query: getDirectoryListings,
    variables: {
      status: status.enum,
      categories: categories?.map(category => category.category) || null,
      term: searchTerm || null,
      pagination: {
        first: optionalPaginationFirst || pagination.first,
        after: pagination.after,
      },
      sortOrder: getState().directoryListingsStore.sortOrder,
    },
  }).then(({ data, errors }) => {
    if (data === null && errors.length > 0) {
      return;
    }
    const { directoryListings } = data;
    const newDirectoryListings = directoryListings.edges.map(({ node }) => node);
    const updatedPagination = {
      first: pagination.first,
      after: directoryListings.pageInfo.endCursor,
      hasNextPage: directoryListings.pageInfo.hasNextPage,
    };
    const payload = {
      directoryListings: newDirectoryListings,
      pagination: updatedPagination,
      filters,
    };
    dispatch(addDirectoryListingsAction(payload));
  }).finally(() => {
    dispatch(setLoadingAction(false));
  });
  return query;
};


export const createDirectoryListingAction = directoryListing => (dispatch, getState, client) => {
  const newDirectoryListing = { ...directoryListing };
  newDirectoryListing.categories = (directoryListing.categories || []).map(category => category.category);
  newDirectoryListing.id = uuidv4();
  client.mutate({
    mutation: createDirectoryListingMutation,
    variables: { input: newDirectoryListing },
  });
  dispatch(addDirectoryListingAction(newDirectoryListing));
};

export const editDirectoryListingAction = directoryListing => (dispatch, getState, client) => {
  const editedDirectoryListing = removePropertyByName(directoryListing, '__typename');
  const mutation = client.mutate({
    mutation: editDirectoryListingMutation,
    variables: { input: editDirectoryListingInputTransform(editedDirectoryListing) },
  }).then(({ data }) => {
    if (data) {
      const { directoryListings } = data;
      const { editDirectoryListing: editDirectoryListingResult } = directoryListings;
      dispatch(updateDirectoryListingAction(editDirectoryListingResult.directoryListing));
    }
  });
  dispatch(updateDirectoryListingAction(directoryListing));
  return mutation;
};

export const publishDirectoryListingAction = directoryListing => (dispatch, getState, client) => {
  client.mutate({
    mutation: publishDirectoryListingMutation,
    variables: {
      input: {
        id: directoryListing.id,
      },
    },
  });
  dispatch(removeDirectoryListingAction(directoryListing.id));
};

export const retireDirectoryListingAction = directoryListing => (dispatch, getState, client) => {
  client.mutate({
    mutation: retireDirectoryListingMutation,
    variables: {
      input: {
        id: directoryListing.id,
      },
    },
  });
  dispatch(removeDirectoryListingAction(directoryListing.id));
};
