import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import InvoiceOverridablePrice from 'components/account/InvoiceOverridablePrice';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import MoreVerticalIcon from '@material-ui/icons/MoreVert';
import { invoiceSectionItemType } from 'types/account';

import rcStyles from './styles.scss';

const InvoiceSectionItem = ({
  item,
  index,
  sectionName,
  disabled,
  isOverrideItem,
  itemMenuAnchorElement,
  onEditSectionItem,
  onSelectItem,
  onMenuOpen,
  onMenuClose,
  toggleOverrideItem,
}) => (
  <div className={rcStyles.selection_item}>
    <FormControlLabel
      control={(
        <Checkbox
          checked={item.isSelected}
          onChange={event => onSelectItem(sectionName, index, event.target.checked)}
          inputProps={{
            'data-test-id': 'selectCheckbox',
          }}
          color="primary"
        />
      )}
    />
    {isOverrideItem ? (
      <TextField
        label={t('Title')}
        margin="normal"
        value={item.overrideTitle === null ? item.title : item.overrideTitle}
        onChange={event => onEditSectionItem('overrideTitle', event.target.value)}
        inputProps={{
          'data-test-id': 'overrideTitle',
        }}
        fullWidth
      />
    ) : (
      <div data-test-id="originalTitle">
        {item.title}
      </div>
    )}
    <InvoiceOverridablePrice
      originalPrice={item.amount}
      overridePrice={item.overridePrice}
      isOverrideItem={isOverrideItem}
      onChange={price => onEditSectionItem('overridePrice', price)}
      disabled={disabled}
      data-test-id="overridePriceContainer"
    />
    <IconButton
      aria-controls="edit-menu"
      aria-haspopup="true"
      data-test-id="optionsMenu"
      onClick={onMenuOpen}
    >
      <MoreVerticalIcon />
    </IconButton>
    <Menu
      anchorEl={itemMenuAnchorElement}
      open={!!itemMenuAnchorElement}
      onClose={onMenuClose}
    >
      {isOverrideItem ? (
        <MenuItem
          data-test-id="resetMenu"
          onClick={toggleOverrideItem}
        >
          {t('Reset')}
        </MenuItem>
      ) : (
        <MenuItem
          data-test-id="overrideMenu"
          onClick={toggleOverrideItem}
        >
          {t('Edit Title & Price')}
        </MenuItem>
      )}
    </Menu>
  </div>
);

InvoiceSectionItem.propTypes = {
  item: invoiceSectionItemType.isRequired,
  index: PropTypes.number.isRequired,
  sectionName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onEditSectionItem: PropTypes.func.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  onMenuOpen: PropTypes.func.isRequired,
  onMenuClose: PropTypes.func.isRequired,
  itemMenuAnchorElement: PropTypes.objectOf(PropTypes.any),
  isOverrideItem: PropTypes.bool.isRequired,
  toggleOverrideItem: PropTypes.func.isRequired,
};

export default InvoiceSectionItem;
