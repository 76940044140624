import { removePropertyByName } from 'services/utils';

export const getHomeResult = response => removePropertyByName(response.organisationalUnit, '__typename');

export const createHomeTransform = (home) => {
  const homeTransformed = { ...home };
  homeTransformed.type = 'HOME';
  if (homeTransformed.parentId === 'none') {
    homeTransformed.parentId = null;
  }
  return homeTransformed;
};

export const editHomeTransform = (home) => {
  const { type, isNew, ...homeTransformed } = home;
  if (homeTransformed.parentId === 'none') {
    homeTransformed.parentId = null;
  }
  removePropertyByName(homeTransformed, '__typename');
  return homeTransformed;
};
