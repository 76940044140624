import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import { invoiceTypes } from 'constants/account';
import StepNavigation from 'components/common/StepNavigation';
import { generateInvoiceSteps } from 'constants/invoicing';
import { invoiceType } from 'types/account';
import { bereavementType } from 'types/bereavement';
import { directoryListingType } from 'types/directoryListing';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';


const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const InvoiceGenerationModal = ({
  activeStep,
  bereavement,
  directoryListings,
  disabledSteps,
  errors,
  hasInvoiceBeenUpdated,
  hasInvoiceFailedToGenerate,
  hasInvoiceGenerated,
  invoice,
  isDownloadingInvoice,
  isInvoiceDirty,
  isInvoiceGenerating,
  isInvoiceNumberLoading,
  isOpen,
  isSendingToXero,
  nextInvoiceNumber,
  setIsInvoiceDirty,
  tenantHasGivenConsentToAXeroApp,
  onAddCustomCharge,
  onChangeActiveStep,
  onChangeInvoiceDetails,
  onChangeInvoicePayee,
  onClose,
  onDownloadInvoice,
  onGenerateInvoice,
  onGenerateInvoiceTemplate,
  onPayeeFormUpdate,
  onSendInvoiceTemplateToXero,
  updateSections,
}) => {
  const InvoicingStep = generateInvoiceSteps[invoice.invoiceType][activeStep].component;

  return (
    <Fragment>
      <div className={rcStyles.rc_main_wrap}>
        <Dialog
          onClose={onClose}
          fullScreen
          open={isOpen}
          TransitionComponent={Transition}
          className={styles.c_dialogue}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogTitle
            disableTypography
            className={styles.dialog_title_bar}
          >
            <IconButton
              className={classNames(
                styles.o_button__secondary,
                styles.o_button__round__small,
                styles.is_close,
              )}
              color="primary"
              variant="contained"
              aria-label="Close"
              onClick={onClose}
              data-test-id="closeModal"
            >
              <CloseIcon />
            </IconButton>
            <div
              className={styles.is_title}
              data-test-id={invoice.invoiceType === invoiceTypes.INVOICE
                ? 'buildInvoiceHeading'
                : 'buildProformaInvoiceHeading'}
            >
              {t(generateInvoiceSteps[invoice.invoiceType][activeStep].title)}
            </div>
            <div className={styles.is_actions}>
              {activeStep > 0 && activeStep !== 2 && !hasInvoiceGenerated && (
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={() => onChangeActiveStep(activeStep - 1)}
                  data-test-id="changeStep"
                >
                  {t('Back')}
                </Button>
              )}
              <Button
                color="secondary"
                variant="contained"
                onClick={activeStep === 2 ? () => onClose() : () => onChangeActiveStep(activeStep + 1)}
                disabled={activeStep === 1 && (errors && !!Object.keys(errors).length)}
                data-test-id="changeStep"
              >
                {activeStep === 0 && t('Next')}
                {activeStep === 1 && t('Next')}
                {activeStep === 2 && t('Back to account')}
              </Button>
            </div>
          </DialogTitle>
          <div className={classNames(
            rcStyles.wrap,
          )}
          >
            <StepNavigation
              activeStep={activeStep}
              disabledSteps={disabledSteps}
              navigationSteps={generateInvoiceSteps[invoice.invoiceType]}
              onChangeActiveStep={onChangeActiveStep}
            />
            <InvoicingStep
              activeStep={activeStep}
              bereavement={bereavement}
              defaultInvoiceNumber={nextInvoiceNumber}
              directoryListings={directoryListings}
              errors={errors}
              hasInvoiceBeenUpdated={hasInvoiceBeenUpdated}
              hasInvoiceFailedToGenerate={hasInvoiceFailedToGenerate}
              hasInvoiceGenerated={hasInvoiceGenerated}
              invoice={invoice}
              isDownloadingInvoice={isDownloadingInvoice}
              isInvoiceDirty={isInvoiceDirty}
              isInvoiceGenerating={isInvoiceGenerating}
              isInvoiceNumberLoading={isInvoiceNumberLoading}
              isSendingToXero={isSendingToXero}
              tenantHasGivenConsentToAXeroApp={tenantHasGivenConsentToAXeroApp}
              onAddCustomCharge={onAddCustomCharge}
              onChangeActiveStep={onChangeActiveStep}
              onChangeInvoiceDetails={onChangeInvoiceDetails}
              onChangeInvoicePayee={onChangeInvoicePayee}
              onDownloadInvoice={onDownloadInvoice}
              onGenerateInvoice={onGenerateInvoice}
              onGenerateInvoiceTemplate={onGenerateInvoiceTemplate}
              onPayeeFormUpdate={onPayeeFormUpdate}
              onSendInvoiceTemplateToXero={onSendInvoiceTemplateToXero}
              setIsInvoiceDirty={setIsInvoiceDirty}
              updateSections={updateSections}
            />
          </div>
        </Dialog>
      </div>
    </Fragment>
  );
};

InvoiceGenerationModal.propTypes = {
  activeStep: PropTypes.number.isRequired,
  bereavement: bereavementType,
  directoryListings: PropTypes.arrayOf(directoryListingType),
  disabledSteps: PropTypes.arrayOf(PropTypes.number),
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  hasInvoiceBeenUpdated: PropTypes.bool.isRequired,
  hasInvoiceFailedToGenerate: PropTypes.bool.isRequired,
  hasInvoiceGenerated: PropTypes.bool.isRequired,
  invoice: invoiceType.isRequired,
  isDownloadingInvoice: PropTypes.bool,
  isInvoiceDirty: PropTypes.bool.isRequired,
  isInvoiceGenerating: PropTypes.bool.isRequired,
  isInvoiceNumberLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isSendingToXero: PropTypes.bool.isRequired,
  nextInvoiceNumber: PropTypes.string,
  tenantHasGivenConsentToAXeroApp: PropTypes.bool.isRequired,
  onAddCustomCharge: PropTypes.func.isRequired,
  onChangeActiveStep: PropTypes.func.isRequired,
  onChangeInvoiceDetails: PropTypes.func.isRequired,
  onChangeInvoicePayee: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDownloadInvoice: PropTypes.func.isRequired,
  onGenerateInvoice: PropTypes.func.isRequired,
  onGenerateInvoiceTemplate: PropTypes.func.isRequired,
  onPayeeFormUpdate: PropTypes.func.isRequired,
  onSendInvoiceTemplateToXero: PropTypes.func.isRequired,
  setIsInvoiceDirty: PropTypes.func.isRequired,
  updateSections: PropTypes.func.isRequired,
};

export default InvoiceGenerationModal;
