import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import NameFormSection from 'components/common/NameFormSection';
import IconLoadSml from 'icons/IconLoadSml';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const LiteArrangementForm = ({
  formData,
  isNewBereavement,
  isSaving,
  onChange,
  onSave,
  onCancel,
  formRefs,
  errors,
}) => (
  <div className={classNames(
    styles.o_view,
    styles.is_slim,
    rcStyles.center_content,
  )}
  >
    <div className={styles.o_view__main}>
      <div className={classNames(
        rcStyles.tile,
        rcStyles.v_spacing,
      )}
      >
        <div className={classNames(
          styles.pica_light,
          styles.is_primary,
        )}
        >
          {t('Client Information')}
        </div>
        <div ref={formRefs.clientName}>
          <NameFormSection
            name={formData.name || {}}
            labelTitle={t('Title')}
            onChangeHandler={name => onChange('name', name)}
            labelGivenName={t('Given Name *')}
            labelFamilyName={t('Family Name *')}
            errors={errors && errors.name}
          />
        </div>
        <TextField
          label="Email"
          value={formData.email || ''}
          onChange={event => onChange('email', event.target.value)}
          fullWidth
          inputProps={{
            'data-test-id': 'clientEmail',
          }}
          error={errors && !!errors.email}
          helperText={errors && errors.email}
        />
        <div className={rcStyles.actions}>
          <Button
            onClick={onCancel}
            variant="outlined"
            color="primary"
            data-test-id="cancel"
          >
            {t('Back')}
          </Button>
          <Button
            onClick={onSave}
            variant="contained"
            color="primary"
            data-test-id="saveBereavement"
          >
            {isSaving ? (
              <div
                className={classNames(
                  styles.c_svg_loader_sml,
                  styles.c_svg_loader_sml_white,
                  styles.c_svg_loader_sml_btn,
                )}
                data-test-id="buttonLoader"
              >
                <IconLoadSml title={t('Saving')} />
              </div>
            ) : t(isNewBereavement ? 'Create' : 'Continue')}
          </Button>
        </div>
      </div>
    </div>
  </div>
);

LiteArrangementForm.propTypes = {
  formData: PropTypes.objectOf(PropTypes.any),
  isNewBereavement: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any),
};

export default LiteArrangementForm;
