import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import RemoveIcon from '@material-ui/icons/Remove';
import { relationshipsToDeceased } from 'constants/bereavement';
import { bereavedPersonConnectionType } from 'types/bereavement';
import { reactRefType } from 'types/common';
import DynamicList from 'components/common/DynamicList';
import AddressFormSection from 'components/common/AddressFormSection';
import AutoCompleteTextField from 'components/common/AutoCompleteTextField';
import EmailFormSection from 'components/common/EmailFormSection';
import NameFormSection from 'components/common/NameFormSection';
import PhoneFormSection from 'components/common/PhoneFormSection';
import Select from 'components/common/Select';
import IconAddEmail from 'icons/IconAddEmail';
import IconAddPhone from 'icons/IconAddPhone';
import IconLoadSml from 'icons/IconLoadSml';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const PayeeFormModal = ({
  bereavedPersonConnection,
  isOpen,
  isSaving,
  isEditingPayee,
  onChange,
  onChangeBereavedPerson,
  onClose,
  onSave,
  dialogRef,
  formRefs,
  errors,
}) => {
  const { bereavedPerson } = bereavedPersonConnection;

  return (
    <Dialog
      aria-labelledby="New Client"
      aria-describedby="New Client"
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      TransitionComponent={Transition}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <div ref={dialogRef} className={styles.c_dialog__body}>
        <DialogTitle
          disableTypography
          className={styles.c_dialog__header}
        >
          <div className={styles.c_dialog__title}>
            {isEditingPayee ? t('Edit Payee') : t('Add Payee')}
          </div>
        </DialogTitle>
        <DialogContent className={styles.c_dialog__content}>
          <div className={rcStyles.v_spacing}>
            <div ref={formRefs.name}>
              <NameFormSection
                name={bereavedPerson.name}
                labelTitle={t('Title')}
                onChangeHandler={name => onChangeBereavedPerson('name', name)}
                labelGivenName={t('Given Name *')}
                labelFamilyName={t('Family Name *')}
                errors={errors.name}
              />
            </div>
            <div>
              <Select
                id="contactPreference"
                label={t('Contact Preference')}
                value={bereavedPersonConnection?.contactPreferences?.[0] || ''}
                onChange={event => onChange('contactPreferences', [event.target.value])}
                inputProps={{
                  'name': 'contactPreference',
                  'id': 'contactPreference',
                  'data-test-id': 'contactPreference',
                }}
              >
                <MenuItem />
                <MenuItem value="EMAIL">{t('Email')}</MenuItem>
                <MenuItem value="ADDRESS">{t('Post')}</MenuItem>
                <MenuItem value="PHONE">{t('Phone')}</MenuItem>
              </Select>
            </div>
            <div ref={formRefs.phones}>
              <DynamicList
                component={(
                  <PhoneFormSection />
                )}
                addButtonComponent={
                  <IconButton data-test-id="addPhone" />
                }
                removeButtonProps={{
                  variant: 'outlined',
                  color: 'primary',
                  size: 'small',
                  classes: {
                    root: styles.o_button__round,
                  },
                }}
                removeButtonInner={
                  <RemoveIcon />
                }
                addButtonProps={{
                  variant: 'contained',
                  color: 'primary',
                  size: 'small',
                  classes: {
                    root: styles.o_button__secondary,
                  },
                }}
                addButtonInner={(
                  <IconAddPhone />
                )}
                dataList={bereavedPerson.phones || []}
                errors={errors.phones}
                onChange={phones => onChangeBereavedPerson('phones', phones)}
                fullWidth
                secondary
                data-test-id="phones"
              />
            </div>
            <div ref={formRefs.emails}>
              <DynamicList
                component={(
                  <EmailFormSection
                    label={t('Email')}
                    fullWidth
                  />
                )}
                addButtonComponent={
                  <IconButton data-test-id="addEmail" />
                }
                addButtonProps={{
                  variant: 'contained',
                  color: 'primary',
                  size: 'small',
                  classes: {
                    root: styles.o_button__secondary,
                  },
                }}
                addButtonInner={(
                  <IconAddEmail />
                )}
                removeButtonProps={{
                  variant: 'outlined',
                  color: 'primary',
                  size: 'small',
                  classes: { root: styles.o_button__round },
                }}
                removeButtonInner={
                  <RemoveIcon />
                }
                dataList={bereavedPerson.emails || []}
                errors={errors.emails}
                onChange={emails => onChangeBereavedPerson('emails', emails)}
                fullWidth
                secondary
                data-test-id="emails"
              />
            </div>
            <div ref={formRefs.address}>
              <AddressFormSection
                address={bereavedPerson.address}
                onChange={address => onChangeBereavedPerson('address', address)}
                data-test-id="address"
                errors={errors.address}
                lookupLabel="Address *"
              />
            </div>
            <AutoCompleteTextField
              placeholder={t('Relationship to Deceased')}
              value={bereavedPersonConnection.relationshipToDeceased || ''}
              suggestions={relationshipsToDeceased}
              onChange={relationship => onChange('relationshipToDeceased', relationship)}
              fullWidth
              data-test-id="relationshipToDeceased"
            />
            <div ref={formRefs.note}>
              <TextField
                id="notes"
                label={t('Notes')}
                value={bereavedPerson.note || ''}
                onChange={event => onChangeBereavedPerson('note', event.target.value)}
                inputProps={{
                  'data-test-id': 'notes',
                }}
                fullWidth
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions className={styles.c_dialog__actions}>
          <Button
            onClick={onClose}
            variant="outlined"
            color="primary"
            data-test-id="cancel"
            fullWidth
          >
            {t('Cancel')}
          </Button>
          <Button
            disabled={isSaving}
            onClick={onSave}
            variant="contained"
            color="primary"
            data-test-id="saveClient"
            fullWidth
          >
            {isSaving ? (
              <div
                className={classNames(
                  styles.c_svg_loader_sml,
                  styles.c_svg_loader_sml_white,
                  styles.c_svg_loader_sml_btn,
                )}
                data-test-id="buttonLoader"
              >
                <IconLoadSml title={t('Saving')} />
              </div>
            ) : t('Save')}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

PayeeFormModal.propTypes = {
  bereavedPersonConnection: bereavedPersonConnectionType.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isEditingPayee: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onChangeBereavedPerson: PropTypes.func.isRequired,
  dialogRef: reactRefType.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any),
};

export default PayeeFormModal;
