import { string, object, boolean } from 'yup';

export const validationSchema = object().shape({
  name: object().shape({
    title: string().nullable(),
    givenName: string().nullable(),
    middleName: string().nullable(),
    familyName: string().nullable(),
  }),
  email: string().nullable(),
  phoneNumber: string().nullable(),
  postCode: string().nullable(),
  hasGDPRBeenConfirmed: boolean().oneOf([true], 'Please confirm the applicant has given permission to share their details with Funeral Safe.'),
});
