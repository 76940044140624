import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { historyType } from 'types/reactRouter';
import GenericAppMessage from './GenericAppMessage';

class GenericAppMessageContainer extends Component {
  static propTypes = {
    error: PropTypes.objectOf(PropTypes.any),
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    showReturnToDashboardButton: PropTypes.bool.isRequired,
    history: historyType.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  handleOnReturnToDashboard = () => {
    const { history } = this.props;
    history.push('/');
  }

  render() {
    const {
      title, subTitle, error, showReturnToDashboardButton,
    } = this.props;
    return (
      <GenericAppMessage
        title={title}
        subTitle={subTitle}
        error={error}
        showReturnToDashboardButton={showReturnToDashboardButton}
        onReturnToDashboard={this.handleOnReturnToDashboard}
      />
    );
  }
}

export default withRouter(GenericAppMessageContainer);
