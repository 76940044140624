import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OrganistFormSection from './OrganistFormSection';

class OrganistFormSectionContainer extends Component {
  static propTypes = {
    fieldData: PropTypes.objectOf(PropTypes.any),
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    venueCategoryInformation: PropTypes.objectOf(PropTypes.any),
  };

  static defaultProps = {
    fieldData: {
      serviceType: null,
      name: null,
      dateTime: null,
    },
  }

  handleChange = (key, value) => {
    const { onChange, fieldData } = this.props;

    const newFormData = {
      ...fieldData,
      [key]: value,
    };

    onChange(newFormData);
  }

  render() {
    const { fieldData, disabled, venueCategoryInformation } = this.props;
    return (
      <OrganistFormSection
        fieldData={fieldData}
        disabled={disabled}
        onChange={this.handleChange}
        venueCategoryInformation={venueCategoryInformation}
      />
    );
  }
}

export default OrganistFormSectionContainer;
