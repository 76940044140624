import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const IconLocation = (props) => {
  const { className } = props;
  const classes = className ? `${className} ${styles.rc_icon}` : styles.rc_icon;

  return (
    <svg viewBox="-0.2 -0.4 24 24" className={classes}>
      <path d="M12,2 C8.13,2 5,5.13 5,9 C5,14.25 12,22 12,22 C12,22 19,14.25 19,9 C19,5.13 15.87,2 12,2 L12,2 Z M12,11.5 C10.62,11.5 9.5,10.38 9.5,9 C9.5,7.62 10.62,6.5 12,6.5 C13.38,6.5 14.5,7.62 14.5,9 C14.5,10.38 13.38,11.5 12,11.5 L12,11.5 Z" />
    </svg>
  );
};

IconLocation.propTypes = {
  className: PropTypes.string,
};

export default IconLocation;
