import moment from 'moment';
import { payeeTypes } from 'constants/account';
import { generateHexId, defaultMoneyObject } from 'services/utils';

const sectionsApiTransform = (sections, isInvoiceTemplate = false) => sections.map(section => ({
  title: section.title,
  items: section.items
    .filter(item => item.isSelected)
    .map((item) => {
      const transformedItem = {
        title: item.overrideTitle || item.title,
        description: item.description || null,
        amount: item.overridePrice ? item.overridePrice : item.amount,
        taxBand: item.taxBand,
      };
      if (item.nominalCode && isInvoiceTemplate) {
        transformedItem.nominalCode = item.nominalCode;
      }
      return transformedItem;
    }),
}));

export const generateInvoiceInputTransform = invoice => ({
  id: invoice.id,
  number: invoice.number,
  dateTime: invoice.dateTime,
  dueDate: invoice.dueDate,
  reference: invoice.reference || null,
  notes: invoice.notes || null,
  sections: sectionsApiTransform(invoice.sections),
  payeeId: invoice.payeeId,
  payeeType: invoice.payeeType,
});

export const generateInvoiceTemplateInputTransform = invoice => ({
  id: invoice.id,
  number: invoice.number,
  dateTime: invoice.dateTime,
  dueDate: invoice.dueDate,
  reference: invoice.reference || null,
  notes: invoice.notes || null,
  sections: sectionsApiTransform(invoice.sections, true),
  payeeId: invoice.payeeId,
  payeeType: invoice.payeeType,
  hasBeenSentToClient: invoice.hasBeenSentToClient,
});

export const createInvoiceTemplateInputTransform = ({ hasBeenSentToXero, ...otherInvoiceProps }) => ({
  ...otherInvoiceProps,
});

export const accountInvoiceTransform = invoiceData => ({
  id: invoiceData.id,
  invoiceType: invoiceData.invoiceType,
  number: invoiceData.number,
  dateTime: invoiceData.dateTime,
  createdAt: invoiceData.createdAt || moment().format(),
  editedAt: invoiceData.isNew ? null : moment().format(),
  reference: invoiceData.reference || null,
  discount: invoiceData.discount || null,
  notes: invoiceData.notes || null,
  dueDate: invoiceData.dueDate,
  payeeId: invoiceData.payeeId,
  payeeType: invoiceData.payeeType,
  customCharges: invoiceData.customCharges,
  sections: sectionsApiTransform(invoiceData.sections),
  totals: {
    total: invoiceData.total,
    totalPaid: defaultMoneyObject(0),
    totalCredit: defaultMoneyObject(0),
    balance: invoiceData.total,
  },
});

export const accountInvoiceTemplateTransform = invoiceTemplateData => ({
  id: invoiceTemplateData.id,
  invoiceType: invoiceTemplateData.invoiceType,
  number: invoiceTemplateData.number,
  dateTime: invoiceTemplateData.dateTime,
  createdAt: invoiceTemplateData.createdAt || moment().format(),
  editedAt: invoiceTemplateData.isNew ? null : moment().format(),
  reference: invoiceTemplateData.reference || null,
  discount: invoiceTemplateData.discount || null,
  notes: invoiceTemplateData.notes || null,
  dueDate: invoiceTemplateData.dueDate || null,
  sections: sectionsApiTransform(invoiceTemplateData.sections),
  totals: {
    total: invoiceTemplateData.total,
    totalPaid: defaultMoneyObject(0),
    totalCredit: defaultMoneyObject(0),
    balance: invoiceTemplateData.total,
  },
  hasBeenSentToClient: invoiceTemplateData.hasBeenSentToClient,
  hasBeenSentToXero: invoiceTemplateData.hasBeenSentToXero,
  transferredToXeroAt: invoiceTemplateData.transferredToXeroAt,
});

export const editCreditMutationTransform = credit => ({
  creditId: credit.id,
  bereavementId: credit.bereavementId,
  invoiceId: credit.invoiceId || null,
  amount: credit.amount,
  reason: credit.reason,
});

export const editPaymentMutationTransform = payment => ({
  paymentId: payment.id,
  bereavementId: payment.bereavementId,
  payeeId: payment.payeeId || null,
  payeeType: payment.payeeType || null,
  invoiceId: payment.invoiceId || null,
  dateTime: payment.dateTime || null,
  amount: payment.amount,
  method: payment.method,
  reference: payment.reference || null,
});

export const addPaymentTransform = (formData, selectedBereaved, selectedDirectoryListing) => {
  const payeeId = formData.payeeType === payeeTypes.BEREAVED_PERSON_CONNECTION
    ? selectedBereaved.id
    : selectedDirectoryListing.id;

  const isToday = (formData.dateTime && moment(formData.dateTime).isSame(new Date(), 'day')) || false;

  return {
    id: formData.id || generateHexId(),
    payeeId,
    createdAt: formData.createdAt || moment().format(),
    invoiceId: (formData.invoice && formData.invoice.id) || null,
    payeeType: formData.payeeType,
    amount: formData.amount,
    dateTime: isToday ? moment().format() : (formData.dateTime || moment().format()),
    method: formData.method,
    reference: formData.reference || null,
  };
};

export const addCreditTransform = (formData) => {
  const credit = {
    ...formData,
    id: formData.id || generateHexId(),
    createdAt: formData.createdAt || moment().format(),
    dateTime: formData.dateTime || moment().format(),
    invoiceId: (formData.invoice && formData.invoice.id) || null,
    amount: formData.amount,
    reason: formData.reason,
  };

  delete credit.invoice;

  return credit;
};

export const createCreditMutationTransform = credit => ({
  id: credit.id,
  bereavementId: credit.bereavementId,
  invoiceId: credit.invoiceId || null,
  amount: credit.amount,
  reason: credit.reason,
});

export const createPaymentMutationTransform = payment => ({
  id: payment.id,
  bereavementId: payment.bereavementId,
  payeeId: payment.payeeId,
  payeeType: payment.payeeType,
  amount: payment.amount,
  method: payment.method,
  dateTime: payment.dateTime,
  invoiceId: payment.invoiceId || null,
  reference: payment.reference || null,
});

export const voidInvoiceTransform = (invoice, dateVoided) => {
  const { type, ...updatedInvoice } = invoice;
  return {
    ...updatedInvoice,
    dateVoided: moment(dateVoided).format('YYYY-MM-DD'),
  };
};

export const voidInvoiceMutationTransform = (bereavementId, invoiceId) => ({
  bereavementId,
  invoiceId,
});

export const closeEventTransform = (reason, staffMemberId) => ({
  dateTime: moment().format(),
  staffMemberId,
  reason,
});

export const reopenEventTransform = staffMemberId => ({
  dateTime: moment().format(),
  staffMemberId,
});
