import React from 'react';
import { onError } from 'apollo-link-error';
import { t } from 'i18next';
import Button from '@material-ui/core/Button';

import { enqueueSnackbarAction } from 'actions/snackbar';
import { store } from 'services/state';

const parseErrorMessage = (graphQLErrors) => {
  const defaultErrorMessage = t('An error has occured.');
  if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
    const domainExceptionPrefix = 'Domain exception: ';
    const { message } = graphQLErrors[0];
    if (message.indexOf(domainExceptionPrefix) === 0) {
      return message.replace(domainExceptionPrefix, '');
    }
  }
  return defaultErrorMessage;
};

const errorHandlingLink = onError(({ graphQLErrors }) => {
  store.dispatch(enqueueSnackbarAction({
    message: parseErrorMessage(graphQLErrors),
    options: {
      variant: 'error',
      persist: true,
      action: (
        <Button size="small" data-test-id="dismiss">{t('Dismiss')}</Button>
      ),
    },
  }));
});

export default errorHandlingLink;
