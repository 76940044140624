import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { withApollo } from 'react-apollo';
import { apolloClientType } from 'types/apollo';
import validationSchema from './validation';
import { contactUs } from './mutations.gql';
import EmailSupport from './EmailSupport';

export class EmailSupportContainer extends Component {
  static propTypes = {
    slideInWindowOpen: PropTypes.bool,
    transitionTime: PropTypes.number,
    givenName: PropTypes.string,
    switchWindow: PropTypes.func.isRequired,
    client: apolloClientType.isRequired,
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    generateRefs: PropTypes.func.isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      formData: {
        contactReason: '',
        contactMessage: '',
      },
      submissionComplete: false,
      isSending: false,
      hasMutationError: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { slideInWindowOpen } = nextProps;
    const { submissionComplete } = prevState;
    if (!slideInWindowOpen) {
      return {
        ...prevState,
        formData: {
          contactReason: submissionComplete ? '' : prevState.formData.contactReason,
          contactMessage: submissionComplete ? '' : prevState.formData.contactMessage,
        },
        submissionComplete: false,
      };
    }
    return null;
  }

  componentDidMount() {
    const { generateRefs } = this.props;
    generateRefs(Object.keys(validationSchema.fields));
  }

  removeConfirmation = () => {
    const { transitionTime } = this.props;
    setTimeout(() => {
      this.setState({
        formData: {
          contactReason: '',
          contactMessage: '',
        },
        submissionComplete: false,
      });
    }, transitionTime);
  };

  handleInput = (key, value) => {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [key]: value,
      },
    }));
  };

  cancelSubmit = () => {
    const { switchWindow } = this.props;
    switchWindow();
    this.setState({
      formData: {
        contactReason: '',
        contactMessage: '',
      },
    });
  };

  handleSubmit = () => {
    const { formData } = this.state;
    const { validate, client } = this.props;

    if (!validate(formData, validationSchema, true)) {
      return;
    }

    const input = {
      reasonForQuery: formData.contactReason,
      message: formData.contactMessage,
    };

    this.setState({
      isSending: true,
    });

    client.mutate({
      mutation: contactUs,
      variables: { input },
    }).then(() => {
      this.setState({
        isSending: false,
        submissionComplete: true,
        hasMutationError: false,
      });
    }).catch(() => {
      this.setState({
        isSending: false,
        hasMutationError: true,
      });
    });
  };

  render() {
    const { givenName, formRefs, errors } = this.props;
    const {
      formData,
      submissionComplete,
      isSending,
      hasMutationError,
    } = this.state;

    return (
      <EmailSupport
        userName={givenName}
        onSubmit={this.handleSubmit}
        onInput={this.handleInput}
        formData={formData}
        submissionComplete={submissionComplete}
        cancelSubmit={this.cancelSubmit}
        removeConfirmation={this.removeConfirmation}
        isSending={isSending}
        hasMutationError={hasMutationError}
        formRefs={formRefs}
        errors={errors}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = state => state.userStore.user.name;

export default withValidation(withApollo(connect(mapStateToProps)(EmailSupportContainer)));
