import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import NotFoundScreen from 'screens/NotFoundScreen';
import AppBar from 'components/common/AppBar';
import ArrangementNavigationMenu from 'components/arrangement/ArrangementNavigationMenu';
import ArrangementSelectedItemList from 'components/arrangement/ArrangementSelectedItemList';
import ExpandableBanner from 'components/common/ExpandableBanner';
import CatalogueItemList from 'components/catalogue/CatalogueItemList';
import ArrangementServiceTabs from 'components/arrangement/ArrangementServiceTabs';
import { hasCategorySelectedItems } from 'services/utils/arrangement';
import { featureFlags } from 'constants/features';
import {
  bereavementType,
  arrangementType,
  productType,
  serviceType,
} from 'types/bereavement';
import { matchType, historyType } from 'types/reactRouter';
import { navigationPathType } from 'types/common';
import IconLoad from 'icons/IconLoad';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const ArrangementScreen = ({
  bereavement,
  arrangement,
  navigationPaths,
  availableFeatures,
  disabled,
  displayCategoryInformation,
  displayAppbar,
  history,
  match,
  isLoading,
  onSave,
  expandBannerRef,
  selectedItemRef,
  focusNewSelection,
  isLoadingCatalogueItems,
  products,
  services,
}) => (isLoading
  ? (
    <div className={classNames(
      styles.c_page__full_height,
      styles.has_faded_background,
      styles.o_fb,
    )}
    >
      <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
        <IconLoad />
      </div>
    </div>
  )
  : (
    <Fragment>
      {displayAppbar && <AppBar />}
      <Switch>
        {navigationPaths.map(navigationPath => (
          navigationPath.isScreen ? (
            <Route
              key={navigationPath.path}
              path={`${match.url}/${navigationPath.path}`}
              render={() => (
                <navigationPath.component
                  bereavement={bereavement}
                  arrangement={arrangement}
                  disabled={disabled}
                  navigationPaths={navigationPaths}
                  onSave={onSave}
                />
              )}
            />
          ) : (
            <Route
              key={navigationPath.path}
              path={`${match.url}/${navigationPath.path}`}
              render={() => (
                <div className={styles.o_view}>
                  <Helmet>
                    <title>{t(`Arrangement - ${navigationPath.name}`)}</title>
                  </Helmet>
                  <div className={styles.o_view__main}>
                    <div>
                      {navigationPath.isVenueScreen && (
                        <ArrangementServiceTabs
                          history={history}
                          match={match}
                          arrangement={arrangement}
                        />
                      )}
                      <ExpandableBanner
                        title={navigationPath.name}
                        isClosedOnLoad={navigationPath.type
                          && !hasCategorySelectedItems(
                            arrangement,
                            navigationPath.type,
                            navigationPath.category,
                          )}
                        disable={!navigationPath.type}
                        ref={expandBannerRef}
                        hasTab={navigationPath.isVenueScreen}
                        headerClasses={[rcStyles.expanding_banner__header]}
                        iconClasses={[rcStyles.expanding_banner__icon]}
                      >
                        {navigationPath.type
                          && (!navigationPath.hideSelectedItems
                            || !availableFeatures.includes(featureFlags.DISPLAY_CATEGORY_INFORMATION))
                          && (
                            <ArrangementSelectedItemList
                              bereavementId={bereavement.id}
                              arrangement={arrangement}
                              type={navigationPath.type}
                              category={navigationPath.category}
                              disabled={disabled}
                              selectedItemRef={selectedItemRef}
                            />
                          )
                        }
                        {availableFeatures.includes(featureFlags.DISPLAY_CATEGORY_INFORMATION)
                          && displayCategoryInformation
                          && (
                            <navigationPath.component
                              bereavement={bereavement}
                              arrangement={arrangement}
                              onSave={onSave}
                            />
                          )}
                      </ExpandableBanner>
                    </div>
                    {!disabled && navigationPath.type && !isLoadingCatalogueItems && (
                      <CatalogueItemList
                        bereavementId={bereavement.id}
                        arrangement={arrangement}
                        selectedHomeId={bereavement.homeId}
                        category={navigationPath.category}
                        type={navigationPath.type}
                        focusNewSelection={focusNewSelection}
                        products={products}
                        services={services}
                      />
                    )}
                  </div>
                </div>
              )}
            />
          )
        ))}
        <Route key="not-found" component={NotFoundScreen} />
      </Switch>
      <ArrangementNavigationMenu
        committalType={arrangement.committalType}
        navigationPaths={navigationPaths}
      />
    </Fragment>
  )
);

ArrangementScreen.propTypes = {
  bereavement: bereavementType.isRequired,
  arrangement: arrangementType.isRequired,
  navigationPaths: PropTypes.arrayOf(navigationPathType).isRequired,
  availableFeatures: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  disabled: PropTypes.bool.isRequired,
  displayCategoryInformation: PropTypes.bool.isRequired,
  displayAppbar: PropTypes.bool,
  match: matchType.isRequired,
  history: historyType.isRequired,
  isLoading: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  expandBannerRef: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedItemRef: PropTypes.objectOf(PropTypes.any).isRequired,
  focusNewSelection: PropTypes.func.isRequired,
  isLoadingCatalogueItems: PropTypes.bool,
  products: PropTypes.arrayOf(productType),
  services: PropTypes.arrayOf(serviceType),
};

export default ArrangementScreen;
