import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import DownloadIcon from '@material-ui/icons/GetApp';
import { t } from 'i18next';

import classNames from 'classnames';
import rcStyles from './styles.scss';

const Document = ({
  documentTitle,
  documentExtension,
  documentUrl,
  onChange,
  errors,
}) => (
  <div className={rcStyles.doc_row}>
    <div className={rcStyles.doc_name}>
      <TextField
        label={t('File name')}
        onChange={e => onChange(`${e.target.value}.${documentExtension}`)}
        value={documentTitle}
        placeholder={t('Document title')}
        error={errors && !!errors.title}
        helperText={errors && errors.title}
        inputProps={{
          'data-test-id': 'documentTitle',
        }}
        fullWidth
      />
    </div>
    <div className={rcStyles.doc_extension}>
      {documentExtension && (
        `.${documentExtension}`
      )}
    </div>
    {documentUrl && (
      <a
        className={classNames(
          rcStyles.doc_download,
          rcStyles.rc_icon_download,
        )}
        href={documentUrl}
        data-test-id="downloadDocument"
      >
        <DownloadIcon color="primary" />
      </a>
    )}
  </div>
);

Document.propTypes = {
  documentTitle: PropTypes.string,
  documentExtension: PropTypes.string,
  documentUrl: PropTypes.string,
  onChange: PropTypes.func,
  errors: PropTypes.objectOf(PropTypes.any),
};

export default Document;
