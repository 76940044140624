import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logoutAction } from 'actions/user';
import { historyType } from 'types/reactRouter';

class LogoutScreen extends Component {
  static propTypes = {
    history: historyType.isRequired,
    logout: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { history, logout } = this.props;
    logout();
    history.push('/');
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  logout: logoutAction,
}, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(LogoutScreen));
