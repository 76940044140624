import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CareRecordSection from './CareRecordSection';

export class CareRecordSectionContainer extends Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.activeTab.indexOf('/care-record') === -1) {
      return { activeCareRecordTab: 0 };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      activeCareRecordTab: 0,
    };
  }

  handleChangeTab = activeCareRecordTab => this.setState({ activeCareRecordTab });

  render() {
    const { activeCareRecordTab } = this.state;

    return (
      <CareRecordSection
        {...this.props}
        onChangeTab={this.handleChangeTab}
        activeCareRecordTab={activeCareRecordTab}
      />
    );
  }
}

export default CareRecordSectionContainer;
