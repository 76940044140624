/* eslint-disable @funeralguide/test-selectors/onChange */
/* eslint-disable @funeralguide/test-selectors/textfield */
import React from 'react';
import i18next from 'i18next';
import classNames from 'classnames';
import { TextField, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import ExpandableBanner from 'components/common/ExpandableBanner';
import ScreenLayout from 'components/common/ScreenLayout';
import PageTabs from 'components/letterTemplates/PageTabs';
import IconLetterValues from 'icons/IconLetterValues';
import IconLoad from 'icons/IconLoad';
import { startCase, toProperCase } from 'services/utils';
import styles from 'scss/main.scss';

import TemplateValueDetails from 'components/letterTemplates/TemplateValueDetails';
import rcStyles from './styles.scss';
import { Props } from './AdminLetterTemplateValuesScreen.types';
import { CategoryVariables } from './index.types';

const categorySort = ((a: CategoryVariables, b: CategoryVariables) => {
  if (a.key < b.key) { return -1; }
  if (a.key > b.key) { return 1; }
  return 0;
});

const AdminLetterTemplateValuesScreen: React.FC<Props> = ({
  term, isLoading, values, onSearchValues,
}: Props) => (
  <ScreenLayout
    title={i18next.t('Letter Values')}
    dashboardBannerProps={{
      breadcrumbs: [{ path: '/', title: i18next.t('Dashboard') }, { path: '/templates/values', title: 'Letter Values' }],
      description: i18next.t('Save time and prevent errors by automatically including funeral details in letter templates.') as string,
    }}
  >
    <PageTabs />
    <div className={styles.o_view}>
      <div className={styles.o_view__main}>
        <div className={classNames(
          styles.u_text__center,
          styles.u_island,
        )}
        >
          <IconLetterValues />
          <p>
            {i18next.t('Copy and paste letter values to use in templates')}
          </p>
        </div>
        <div className={rcStyles.values_container}>
          <div className={rcStyles.values_search}>
            <TextField
              fullWidth
              value={term as string}
              onChange={event => onSearchValues(event.target.value)}
              type="search"
              placeholder={i18next.t('Search') as string}
              InputProps={{
                // 'data-test-id': 'term',
                'startAdornment': (
                  <InputAdornment position="start">
                    <SearchIcon className={rcStyles.search} />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={styles.u_island}>
            {isLoading ? (
              <div className={styles.u_soft__ends_double}>
                <div className={styles.c_svg_loader_infinite_scroll} data-test-id="customTenantTemplateDefinitionsLoader">
                  <IconLoad />
                </div>
              </div>
            ) : (
              values.sort(categorySort).map(value => (
                <div key={value.key}>
                  <h3 className={rcStyles.is_heading}>
                    {i18next.t(`${startCase(toProperCase(value.key))} values`)}
                  </h3>
                  {value.variables.map(item => (
                    <ExpandableBanner
                      key={item.name}
                      headerClasses={[rcStyles.expanding_banner__header]}
                      wrapperClasses={[rcStyles.expanding_banner__wrap]}
                      title={(
                        <div className={rcStyles.banner_content}>
                          {startCase(i18next.t(item.name))}
                        </div>
                  )}
                      isClosedOnLoad
                    >
                      <TemplateValueDetails templateValue={item} />
                    </ExpandableBanner>
                  ))}
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  </ScreenLayout>
);

export default AdminLetterTemplateValuesScreen;
