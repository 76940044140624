import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { historyType } from 'types/reactRouter';
import { bereavementType } from 'types/bereavement';
import FirstCallHeader from './FirstCallHeader';

class FirstCallHeaderContainer extends Component {
  static propTypes = {
    history: historyType.isRequired,
    bereavement: bereavementType.isRequired,
    isDeceasedFormValid: PropTypes.bool.isRequired,
    isBereavedFormValid: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    updateBereavementByKey: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isCaseModalOpen: false,
      isNotesModalOpen: false,
    };
  }

  handleHomeChange = (home) => {
    const { updateBereavementByKey } = this.props;
    const homeId = home ? home.id : null;

    updateBereavementByKey('homeId', homeId);
    updateBereavementByKey('home', home);
    updateBereavementByKey('ownerId', null);
    updateBereavementByKey('owner', null);
  }

  handleOwnerChange = (owner) => {
    const { updateBereavementByKey } = this.props;
    const ownerId = owner ? owner.id : null;

    updateBereavementByKey('ownerId', ownerId);
    updateBereavementByKey('owner', owner);
  }

  handleModalOpen = () => {
    this.setState({
      isCaseModalOpen: true,
    });
  };

  handleModalClose = () => {
    this.setState({
      isCaseModalOpen: false,
    });
  };

  handleCancel = () => {
    const { history } = this.props;
    history.goBack();
  }

  setNotesOpen = (isOpen) => {
    this.setState({ isNotesModalOpen: isOpen });
  }

  render() {
    const { onSubmit } = this.props;
    const { isCaseModalOpen, isNotesModalOpen } = this.state;

    return (
      <FirstCallHeader
        {...this.props}
        isCaseModalOpen={isCaseModalOpen}
        isNotesModalOpen={isNotesModalOpen}
        onHomeChange={this.handleHomeChange}
        onOwnerChange={this.handleOwnerChange}
        onModalOpen={this.handleModalOpen}
        onModalClose={this.handleModalClose}
        onNotesModalOpen={() => this.setNotesOpen(true)}
        onNotesModalClose={() => this.setNotesOpen(false)}
        onSave={onSubmit}
        onCancel={this.handleCancel}
      />
    );
  }
}

export default withRouter(FirstCallHeaderContainer);
