import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { t } from 'i18next';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import BereavedPersonCard from 'components/common/cards/BereavedPersonCard';
import PossessionItem from 'components/bereavement/PossessionItem';
import StepNavigation from 'components/common/StepNavigation';
import InvoiceLoad from 'icons/InvoiceLoad';
import InvoiceLoadStatic from 'icons/InvoiceLoad_static';
import { bereavedPersonConnectionType, possessionType } from 'types/bereavement';

import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

const PossessionsReleaseFormModal = ({
  activeStep,
  isOpen,
  possessions,
  bereavedPeopleConnections,
  selectedPossessions,
  selectedClient,
  isReleaseFormDownloading,
  onChangeActiveStep,
  onChangePossessions,
  onChangeClient,
  onSavePossessions,
  onClose,
  onGenerateReleaseForm,
  errors,
}) => (
  <Dialog
    onClose={onClose}
    fullScreen
    open={isOpen}
    TransitionComponent={Transition}
    className={styles.c_dialogue}
    disableBackdropClick
    disableEscapeKeyDown
  >
    <DialogTitle
      disableTypography
      className={styles.dialog_title_bar}
    >
      <IconButton
        className={classNames(
          styles.o_button__secondary,
          styles.is_close,
        )}
        color="primary"
        variant="contained"
        aria-label="Close"
        onClick={onClose}
        data-test-id="closeModal"
      >
        <CloseIcon />
      </IconButton>
      <div className={styles.is_title}>
        {t('Possessions release form')}
      </div>
      <div className={styles.is_actions}>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            if (activeStep === 0) {
              return onSavePossessions();
            }
            if (activeStep === 1) {
              return onChangeActiveStep(2);
            }
            return onClose();
          }}
          disabled={false}
          data-test-id="changeStep"
        >
          {activeStep !== 2 && t('Next')}
          {activeStep === 2 && t('Close')}
        </Button>
      </div>
    </DialogTitle>

    <StepNavigation
      activeStep={activeStep}
      navigationSteps={[
        { title: 'Select possessions' },
        { title: 'Select client' },
        { title: 'Create form' },
      ]}
      onChangeActiveStep={(step) => {
        if (activeStep === 0) {
          return onSavePossessions(step);
        }
        return onChangeActiveStep(step);
      }}
    />
    <div className={styles.o_view}>
      {activeStep === 0 && (

        <div className={styles.o_view__main}>
          {errors.possessions && (
            <FormHelperText error className={rcStyles.error}>
              {errors.possessions}
            </FormHelperText>
          )}
          {possessions.map(possession => (
            <div
              key={possession.id}
              className={rcStyles.possessions_item}
            >
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={selectedPossessions.includes(possession.id)}
                    onChange={event => onChangePossessions(possession.id, event.target.checked)}
                    inputProps={{ 'data-test-id': `possessionCheck-${possession.id}` }}
                    color="primary"
                    classes={{ root: styles.u_hard__left }}
                  />
                )}
              />
              <PossessionItem
                possession={possession}
                disabled={false}
              />
            </div>
          ))}
        </div>
      )}
      {activeStep === 1 && (
        <div className={styles.o_view__main}>
          {bereavedPeopleConnections.map(bereavedPersonConnection => (
            <div
              key={bereavedPersonConnection.id}
              className={rcStyles.client_select}
            >
              <Radio
                color="primary"
                name="client"
                value={bereavedPersonConnection.id}
                checked={selectedClient === bereavedPersonConnection.id}
                onChange={event => onChangeClient(event.target.value)}
                data-test-id={bereavedPersonConnection.id}
                className={styles.u_push__right}
              />
              <BereavedPersonCard
                bereavedPersonConnection={bereavedPersonConnection}
                customClass={rcStyles.bereaved_card}
              />
            </div>
          ))}
        </div>
      )}
      {activeStep === 2 && (
        <div className={styles.o_view__main}>
          <div className={rcStyles.generate_release_form}>
            <div className={rcStyles.is_media}>
              {isReleaseFormDownloading ? (
                <InvoiceLoad />
              ) : (
                <InvoiceLoadStatic />
              )}
            </div>
            <div className={rcStyles.is_buttons}>
              <Button
                onClick={() => onChangeActiveStep(0)}
                color="secondary"
                variant="contained"
                data-test-id="backToReleaseForm"
              >
                <EditIcon />
                {t('Edit form')}
              </Button>
              <Button
                onClick={onGenerateReleaseForm}
                color="primary"
                variant="contained"
                data-test-id="confirmGenerateInvoice"
              >
                {isReleaseFormDownloading ? (
                  <Fragment>
                    {t('Generating release form')}
                  </Fragment>
                ) : (
                  <Fragment>
                    {t('Download')}
                  </Fragment>
                )}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  </Dialog>
);

PossessionsReleaseFormModal.propTypes = {
  activeStep: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  possessions: PropTypes.arrayOf(possessionType).isRequired,
  bereavedPeopleConnections: PropTypes.arrayOf(bereavedPersonConnectionType).isRequired,
  selectedPossessions: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  selectedClient: PropTypes.string.isRequired,
  isReleaseFormDownloading: PropTypes.bool.isRequired,
  onChangeActiveStep: PropTypes.func.isRequired,
  onChangePossessions: PropTypes.func.isRequired,
  onChangeClient: PropTypes.func.isRequired,
  onSavePossessions: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onGenerateReleaseForm: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default PossessionsReleaseFormModal;
