import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import { statusActions } from 'constants/adminCatalogue';
import { debounce } from 'debounce';
import { packageType } from 'types/bereavement';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  fetchAdminCataloguePackagesAction,
  updateFiltersAction,
  publishPackageAction,
  retirePackageAction,
  deletePackageAction,
} from 'actions/catalogue';
import AdminCataloguePackagesListScreen from './AdminCataloguePackagesListScreen';

export class AdminCataloguePackagesListScreenContainer extends Component {
  debounceGetPackages = debounce((refreshConditions) => {
    this.getPackages(refreshConditions, true);
  }, 500);

  static propTypes = {
    fetchPackages: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    pagination: PropTypes.objectOf(PropTypes.any).isRequired,
    packages: PropTypes.arrayOf(packageType),
    filters: PropTypes.objectOf(PropTypes.any),
    updateFilters: PropTypes.func.isRequired,
    publishPackage: PropTypes.func.isRequired,
    retirePackage: PropTypes.func.isRequired,
    deletePackage: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { packages } = this.props;
    const hasPackages = !!packages.length;

    if (!hasPackages) {
      this.getPackages();
    }
  }

  handleChangeFilters = (key, value, shouldDebounce) => {
    const { updateFilters, filters } = this.props;
    const refreshConditions = { clearFilters: false, clearPagination: true };

    if (value === filters[key]) {
      return;
    }

    updateFilters(key, value);

    if (shouldDebounce) {
      this.debounceGetPackages(refreshConditions);
      return;
    }

    this.getPackages(refreshConditions);
  }

  getPackages = (refreshConditions, isDebouncing) => {
    const { fetchPackages } = this.props;

    fetchPackages(refreshConditions, isDebouncing);
  }

  handleStatusChange = (item, statusAction) => {
    const {
      publishPackage,
      retirePackage,
      deletePackage,
    } = this.props;

    switch (statusAction) {
      case statusActions.PUBLISH:
        return publishPackage(item);
      case statusActions.RETIRE:
        return retirePackage(item.id);
      case statusActions.DELETE:
        return deletePackage(item.id);
      default:
        return false;
    }
  }

  orderPackageItems = packageItems => ([
    ...packageItems.filter(item => item.isNewItem),
    ...packageItems.filter(item => !item.isNewItem),
  ]);

  render() {
    const {
      packages,
      pagination,
      isLoading,
      fetchPackages,
      filters,
    } = this.props;
    return (
      <AdminCataloguePackagesListScreen
        filters={filters}
        fetchPackages={fetchPackages}
        isLoading={isLoading}
        packages={this.orderPackageItems(packages)}
        hasMoreItems={pagination.hasNextPage}
        onChangeFilters={this.handleChangeFilters}
        onStatusChange={this.handleStatusChange}
      />
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  updateFilters: (key, value) => updateFiltersAction('packages', key, value),
  fetchPackages: fetchAdminCataloguePackagesAction,
  publishPackage: publishPackageAction,
  retirePackage: retirePackageAction,
  deletePackage: deletePackageAction,
}, dispatch);

const mapStateToProps = state => ({
  packages: state.catalogueStore.packages.items,
  isLoading: state.catalogueStore.packages.isLoading,
  pagination: state.catalogueStore.packages.pagination,
  filters: state.catalogueStore.packages.filters,
});

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(AdminCataloguePackagesListScreenContainer),
);
