import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import EstimateAddSelectionDialog from 'components/estimate/EstimateAddSelectionDialog';
import SortableEstimateSelectionsList from 'components/estimate/SortableEstimateSelectionsList';
import { categories, catalogueItemTypes } from 'constants/arrangement';
import { arrangementType } from 'types/bereavement';
import styles from 'scss/main.scss';

const EstimateAddons = ({
  arrangement,
  bereavementId,
  bereavementHomeId,
  onClickAddProfessionalService,
  onOpenConfirmationWarningModal,
  isProfessionalServicesModalOpen,
  onCloseModal,
  onOpenDuplicateSelectionModal,
  onClickAddDisbursement,
  isDisbursementsModalOpen,
  disabled,
  professionalServiceSelections,
  disbursementSelections,
  onSortEndForDisbursements,
  onSortEndForProfessionalServices,
}) => (
  <div className={styles.c_grid__1col}>
    {process.env.FEATURE_FLAG_ESTIMATE_PROFESSIONAL_SERVICES && (
      <div>
        <h3 className={styles.long_primer}>
          {t('Professional service fees')}
        </h3>
        <Button
          color="primary"
          variant="outlined"
          size="medium"
          onClick={onClickAddProfessionalService}
          data-test-id="addProfessionalService"
          disabled={disabled}
          className={styles.u_push__ends}
        >
          <AddIcon />
          {t('Add')}
        </Button>
        {professionalServiceSelections.length > 0 && (
          <SortableEstimateSelectionsList
            bereavementId={bereavementId}
            arrangement={arrangement}
            selections={professionalServiceSelections}
            onOpenConfirmationWarningModal={onOpenConfirmationWarningModal}
            onSortEnd={onSortEndForProfessionalServices}
            distance={1}
          />
        )}
        <EstimateAddSelectionDialog
          open={isProfessionalServicesModalOpen}
          type={catalogueItemTypes.SERVICE}
          category={categories.PROFESSIONAL_SERVICES}
          onCloseModal={onCloseModal}
          bereavementId={bereavementId}
          bereavementHomeId={bereavementHomeId}
          arrangement={arrangement}
          onOpenDuplicateSelectionModal={onOpenDuplicateSelectionModal}
        />
      </div>
    )}
    {process.env.FEATURE_FLAG_ESTIMATE_DISBURSEMENTS && (
      <div>
        <h3 className={styles.long_primer}>
          {t('Disbursement fees')}
        </h3>
        <Button
          color="primary"
          variant="outlined"
          size="medium"
          onClick={onClickAddDisbursement}
          data-test-id="addDisbursement"
          disabled={disabled}
          className={styles.u_push__ends}
        >
          <AddIcon />
          {t('Add')}
        </Button>
        {disbursementSelections.length > 0 && (
          <SortableEstimateSelectionsList
            bereavementId={bereavementId}
            arrangement={arrangement}
            selections={disbursementSelections}
            onOpenConfirmationWarningModal={onOpenConfirmationWarningModal}
            distance={1}
            onSortEnd={onSortEndForDisbursements}
          />
        )}
        <EstimateAddSelectionDialog
          open={isDisbursementsModalOpen}
          type={catalogueItemTypes.SERVICE}
          category={categories.DISBURSEMENTS}
          onCloseModal={onCloseModal}
          bereavementId={bereavementId}
          bereavementHomeId={bereavementHomeId}
          arrangement={arrangement}
          onOpenDuplicateSelectionModal={onOpenDuplicateSelectionModal}
        />
      </div>
    )}
  </div>
);

EstimateAddons.propTypes = {
  bereavementId: PropTypes.string.isRequired,
  bereavementHomeId: PropTypes.string.isRequired,
  arrangement: arrangementType.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClickAddProfessionalService: PropTypes.func.isRequired,
  onClickAddDisbursement: PropTypes.func.isRequired,
  isProfessionalServicesModalOpen: PropTypes.bool.isRequired,
  isDisbursementsModalOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onOpenConfirmationWarningModal: PropTypes.func.isRequired,
  onOpenDuplicateSelectionModal: PropTypes.func.isRequired,
  disbursementSelections: PropTypes.arrayOf(PropTypes.object).isRequired,
  professionalServiceSelections: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSortEndForDisbursements: PropTypes.func.isRequired,
  onSortEndForProfessionalServices: PropTypes.func.isRequired,
};

export default EstimateAddons;
