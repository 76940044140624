import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const ArrangementServiceTabs = ({
  onPathChange,
  match,
  history,
  serviceCategories,
}) => (
  <div className={classNames(
    styles.o_fb,
    styles.o_fb__align_left,
  )}
  >
    {serviceCategories.map((category, index) => (
      <div
        key={category.path}
        className={classNames(
          rcStyles.tab_expand,
        )}
      >
        <Button
          data-test-id={`tab__${category.path}`}
          onClick={() => { onPathChange(category.path); }}
          key={index}
          className={`${match.url}/${serviceCategories[index].path}` === history.location.pathname
            ? classNames(
              rcStyles.is_active,
              rcStyles.tab_expand__item,
            )
            : classNames(
              rcStyles.is_inactive,
              rcStyles.tab_expand__item,
            )
            }
        >
          {category.title}
        </Button>
      </div>
    ))}
  </div>
);

ArrangementServiceTabs.propTypes = {
  onPathChange: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  serviceCategories: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any).isRequired).isRequired,
};


export default ArrangementServiceTabs;
