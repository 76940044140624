import React, { Fragment } from 'react';
import classNames from 'classnames';
import { t } from 'i18next';
import PropTypes from 'prop-types';

import CurrencyField from 'components/common/CurrencyField';
import Price from 'components/common/Price';
import { discountTypes } from 'constants/arrangement';
import { defaultMoneyObject, getFormattedDate, isNullOrUndefined } from 'services/utils';
import styles from 'scss/main.scss';
import { arrangementType } from 'types/bereavement';

import rcStyles from './styles.scss';

const EstimateTotals = ({
  arrangement,
  disabled,
  onSetGlobalDiscount,
  onSetRequestedDeposit,
}) => (
  <div className={rcStyles.totals}>
    <h2 className={styles.long_primer}>
      {t('Totals')}
    </h2>
    <div className={rcStyles.estimate_totals}>
      {process.env.FEATURE_FLAG_ESTIMATE_DISBURSEMENTS && (
        <div className={rcStyles.estimate_totals__line}>
          <span className={rcStyles.estimate_totals__label}>
            {t('Total disbursements: ')}
          </span>
          <Price
            price={arrangement.calculation.totalDisbursements}
            customClass={classNames(
              styles.c_price_label,
              styles.is_black,
            )}
          />
        </div>
      )}
      {(arrangement.discountType !== discountTypes.NONE) && (
        <Fragment>
          <div className={rcStyles.estimate_totals__line}>
            <span className={rcStyles.estimate_totals__label}>
              {t('Total before discount: ')}
            </span>
            <Price
              price={arrangement.calculation.totalWithoutDiscounts}
              customClass={classNames(
                styles.c_price_label,
                styles.is_black,
              )}
            />
          </div>
          <div className={rcStyles.estimate_totals__line}>
            <span className={rcStyles.estimate_totals__label}>
              {t('Amount of discount: ')}
            </span>
            {(arrangement.discountType === discountTypes.ITEMISED) && (
              <Price
                price={arrangement.calculation.discounts}
                customClass={classNames(
                  styles.c_price_label,
                  styles.is_black,
                )}
              />
            )}
            {(arrangement.discountType === discountTypes.GLOBAL) && (
              <CurrencyField
                classes={{
                  root:
                    classNames(styles.c_price_label),
                }}
                value={(arrangement.globalDiscount && arrangement.globalDiscount.amount) || null}
                min={0}
                nullable
                InputProps={{
                  inputProps: {
                    'data-test-id': 'globalDiscount',
                  },
                }}
                onChangeAmount={(value) => {
                  onSetGlobalDiscount(defaultMoneyObject(value));
                }}
                disabled={disabled}
              />
            )}
          </div>
        </Fragment>
      )}
      <div className={rcStyles.estimate_totals__line}>
        <span className={rcStyles.estimate_totals__label}>
          {t('Total estimate: ')}
        </span>
        <Price
          price={arrangement.calculation.finalTotal}
          customClass={classNames(
            styles.c_price_label,
            styles.is_black,
          )}
        />
      </div>
      <div className={rcStyles.estimate_totals__line}>
        <span className={rcStyles.estimate_totals__label}>
          {t('Deposit required: ')}
        </span>
        <CurrencyField
          classes={{
            root:
              classNames(styles.c_price_label),
          }}
          value={(arrangement.requestedDeposit && arrangement.requestedDeposit.amount) || null}
          min={0}
          nullable
          InputProps={{
            inputProps: {
              'data-test-id': 'requestedDeposit',
            },
          }}
          onChangeAmount={(value) => {
            onSetRequestedDeposit(!isNullOrUndefined(value) ? defaultMoneyObject(value) : null);
          }}
          disabled={disabled}
        />
      </div>
    </div>
    {arrangement?.funeralSafeReferralDate && (
      <div className={rcStyles.requested_finance}>
        <div className={rcStyles.requested_finance__line}>
          <div className={rcStyles.requested_finance__label}>
            {t('Requested finance')}
          </div>
          <Price
            price={arrangement?.loanAmount}
            customClass={classNames(
              styles.c_price_label,
              styles.is_black,
              rcStyles.requested_finance__amount,
            )}
          />
          <div className={rcStyles.requested_finance__label}>
            {t('Applied')}
          </div>
          <div className={rcStyles.requested_finance__applied_date}>
            {getFormattedDate(arrangement?.funeralSafeReferralDate)}
          </div>
        </div>
      </div>
    )}
  </div>
);

EstimateTotals.propTypes = {
  arrangement: arrangementType.isRequired,
  disabled: PropTypes.bool.isRequired,
  onSetGlobalDiscount: PropTypes.func.isRequired,
  onSetRequestedDeposit: PropTypes.func.isRequired,
};

export default EstimateTotals;
