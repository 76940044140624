export const emptyDirectoryListing = {
  name: '',
  address: null,
  emails: [],
  phones: [],
  websites: [],
  categories: [],
  note: null,
};

export const statuses = {
  PUBLISHED: 'PUBLISHED',
  RETIRED: 'RETIRED',
};

export const statusesWithNames = [
  { name: 'Published', enum: statuses.PUBLISHED },
  { name: 'Retired', enum: statuses.RETIRED },
];

export const categoriesWithNames = [
  { name: 'Hospital', category: 'HOSPITAL' },
  { name: 'Hospice', category: 'HOSPICE' },
  { name: 'Care Home', category: 'CARE_HOME' },
  { name: 'GP Surgery', category: 'GP_SURGERY' },
  { name: 'Florist', category: 'FLORIST' },
  { name: 'Supplier', category: 'SUPPLIER' },
  { name: 'Printer', category: 'PRINTER' },
  { name: 'Doctor', category: 'DOCTOR' },
  { name: 'Solicitor', category: 'SOLICITOR' },
  { name: 'Bank', category: 'BANK' },
  { name: 'Funeral Plan Provider', category: 'FUNERAL_PLAN_PROVIDER' },
  { name: 'Other', category: 'OTHER' },
  { name: 'Officiant/Minister', category: 'OFFICIANT_MINISTER' },
];

export const sortOrderStrategies = {
  NAME: 'NAME',
};

export const sortOrderDirections = {
  ASCENDING: 'ASCENDING',
  DESCENDING: 'DESCENDING',
};
