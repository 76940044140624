import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import CatalogueItemCard from 'components/portal/CatalogueItemCard';
import CatalogueDrawer from 'components/portal/CatalogueDrawer';
import CatalogueItemDetails from 'components/portal/CatalogueItemDetails';
import CloseIcon from 'components/portal/CloseIcon';
import NavigationMenuIcon from 'components/portal/NavigationMenuIcon';
import IconLoad from 'icons/IconLoad';
import { getTenantCatalogueCategories } from 'services/utils/catalogue';
import { CatalogueCategory } from 'types/ts/catalogue';

import Styled from './PortalCatalogueCategoryScreen.styled';
import { Props } from './PortalCatalogueCategoryScreen.types';

const Loader = () => (
  <Styled.Loader>
    <div className="inner">
      <IconLoad />
    </div>
  </Styled.Loader>
);

const PortalCatalogueCategoryScreen: React.FC<Props> = ({
  category,
  home,
  logoUri,
  displayPreferences,
  catalogueItems,
  selectedItem,
  showDetails,
  getCatalogueItems,
  onHideDetails,
  onSelectCatalogueItem,
}: Props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const items = [...catalogueItems?.items];
  const { id: homeId, name } = home || {};
  const url = `/portal/${homeId}`;
  const categories: CatalogueCategory[] = getTenantCatalogueCategories(displayPreferences)
    .filter((item: CatalogueCategory) => item.visible);

  return (
    <>
      <Styled.MainContainer>
        <Styled.NavigationColumn isVisibleForMobile={!showDetails} backgroundColor="#FAF9FB" id="scrollableContainer">
          <Styled.LogoWrapper>
            <Link to={url}>
              <Styled.Logo src={logoUri} alt={name} aria-label={name} />
            </Link>
          </Styled.LogoWrapper>
          <Styled.ItemsContainer>
            <div className="menu__wrapper">
              <NavigationMenuIcon
                onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                data-test-id="navigationMenuIcon"
              />
            </div>
            <div className="items__wrapper">
              <h1 className="category__heading">{category?.name}</h1>
              {(catalogueItems.isLoading && items?.length === 0) && <Loader />}
              <Styled.Cards
                dataLength={items.length}
                hasMore={catalogueItems.pagination.hasNextPage}
                next={getCatalogueItems}
                scrollableTarget="scrollableContainer"
                loader={items.length > 0 && <Loader />}
              >
                {items.map(item => (
                  <CatalogueItemCard
                    key={item.id}
                    item={item}
                    active={selectedItem?.id === item.id}
                    onClick={onSelectCatalogueItem}
                    data-test-id="catalogueItemCard"
                  />
                ))}
              </Styled.Cards>
            </div>
          </Styled.ItemsContainer>
        </Styled.NavigationColumn>
        <Styled.DetailsColumn isVisibleForMobile={showDetails}>
          <Styled.CloseIconWrapper>
            <CloseIcon
              onClick={() => onHideDetails()}
              data-test-id="closeDetails"
            />
          </Styled.CloseIconWrapper>
          <CatalogueItemDetails item={selectedItem} />
        </Styled.DetailsColumn>
      </Styled.MainContainer>
      <CatalogueDrawer
        linkPrefix={`/portal/${home?.id}/catalogue/`}
        activeCategory={category.name}
        categories={categories}
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(!isDrawerOpen)}
      />
    </>
  );
};

export default PortalCatalogueCategoryScreen;
