import mapActionToReducer from 'redux-action-reducer-mapper';
import { updateArrayByIndex } from 'services/utils';
import { directoryListingsActions } from 'actions/directoryListings';
import {
  statuses,
  statusesWithNames,
  sortOrderStrategies,
  sortOrderDirections,
} from 'constants/directoryListing';

export const initialState = {
  directoryListings: [],
  pagination: {
    first: 10,
    after: null,
    hasNextPage: true,
  },
  filters: {
    searchTerm: '',
    categories: null,
    status: statusesWithNames.find(status => status.enum === statuses.PUBLISHED),
  },
  sortOrder: {
    strategy: sortOrderStrategies.NAME,
    direction: sortOrderDirections.ASCENDING,
  },
  isLoading: false,
};

const setDirectoryListings = (state, action) => {
  const { payload: { directoryListings, pagination, filters } } = action;
  return {
    ...state,
    directoryListings,
    pagination: {
      ...state.pagination,
      ...pagination,
    },
    filters: filters ? { ...filters } : { ...state.filters },
  };
};

const clearDirectoryListings = state => ({
  ...state,
  directoryListings: initialState.directoryListings,
  pagination: initialState.pagination,
});

const addDirectoryListings = (state, action) => {
  const { payload: { directoryListings, pagination } } = action;
  return {
    ...state,
    directoryListings: [...state.directoryListings, ...directoryListings],
    pagination: {
      ...state.pagination,
      ...pagination,
    },
  };
};

const addDirectoryListing = (state, action) => {
  const { payload } = action;

  const newDirectoryListing = {
    ...payload,
    isNew: true,
  };

  const newDirectoryListings = [...state.directoryListings];
  newDirectoryListings.unshift(newDirectoryListing);

  if (payload) {
    return {
      ...state,
      directoryListings: newDirectoryListings,
      pagination: state.pagination,
      filters: state.filters,
    };
  }

  return state;
};

const updateDirectoryListing = (state, action) => {
  const { payload } = action;

  if (payload) {
    const directoryListingIndex = state.directoryListings
      .findIndex((directoryListings => directoryListings.id === payload.id));

    if (directoryListingIndex > -1) {
      return {
        ...state,
        directoryListings: updateArrayByIndex(
          state.directoryListings,
          directoryListingIndex,
          payload,
        ),
        pagination: state.pagination,
        filters: state.filters,
      };
    }
  }

  return state;
};

const removeDirectoryListing = (state, action) => {
  const { payload: id } = action;
  if (id) {
    const updatedDirectoryListings = state.directoryListings
      .filter(item => (item.id !== id));

    return {
      ...state,
      directoryListings: updatedDirectoryListings,
      pagination: state.pagination,
      filters: state.filters,
    };
  }

  return state;
};

const updateFilters = (state, action) => {
  const { payload: { key, value } } = action;
  return {
    ...state,
    filters: {
      ...state.filters,
      [key]: value,
    },
  };
};

const setLoading = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    isLoading: payload,
  };
};

export const directoryListingsReducer = mapActionToReducer({
  default: initialState,
  [directoryListingsActions.SET_DIRECTORY_LISTINGS]: setDirectoryListings,
  [directoryListingsActions.CLEAR_ALL]: () => initialState,
  [directoryListingsActions.CLEAR_DIRECTORY_LISTINGS]: clearDirectoryListings,
  [directoryListingsActions.ADD_DIRECTORY_LISTINGS]: addDirectoryListings,
  [directoryListingsActions.ADD_DIRECTORY_LISTING]: addDirectoryListing,
  [directoryListingsActions.UPDATE_DIRECTORY_LISTING]: updateDirectoryListing,
  [directoryListingsActions.REMOVE_DIRECTORY_LISTING]: removeDirectoryListing,
  [directoryListingsActions.UPDATE_FILTERS]: updateFilters,
  [directoryListingsActions.SET_LOADING]: setLoading,
});
