import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import DirectoryListingForm from 'components/directoryListings/DirectoryListingForm';
import { reactRefType } from 'types/common';
import { directoryListingType } from 'types/directoryListing';
import IconLoad from 'icons/IconLoad';
import styles from 'scss/main.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const NameDirectoryListingFormSectionModal = ({
  formData,
  isSaving,
  onChange,
  onCancel,
  onSave,
  isOpen,
  errors,
  dialogRef,
  formRefs,
}) => (
  <Dialog
    onClose={onCancel}
    open={isOpen}
    fullWidth
    maxWidth="md"
    aria-labelledby="responsive-dialog-title"
    TransitionComponent={Transition}
    disableBackdropClick
    disableEscapeKeyDown
  >
    <div ref={dialogRef} className={styles.c_dialog__body}>
      <DialogTitle
        id="responsive-dialog-title"
        disableTypography
        className={styles.c_dialog__header}
      >
        <div className={styles.c_dialog__title}>
          {t('Create directory listing')}
        </div>
      </DialogTitle>
      <DialogContent className={styles.c_dialog__content}>
        <DirectoryListingForm
          formData={formData}
          onChange={onChange}
          formRefs={formRefs}
          errors={errors}
          data-test-id="directoryListingForm"
        />
      </DialogContent>
      <DialogActions className={styles.c_dialog__actions}>
        <Button
          fullWidth
          color="primary"
          variant="outlined"
          data-test-id="cancel"
          disabled={isSaving}
          onClick={onCancel}
        >
          {t('Cancel')}
        </Button>
        <Button
          fullWidth
          type="submit"
          color="primary"
          data-test-id="saveBusinessDirectory"
          variant="contained"
          disabled={isSaving}
          onClick={onSave}
        >
          {t('Save')}
        </Button>
      </DialogActions>
    </div>
    {isSaving && (
      <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
        <IconLoad title={t('Saving')} />
      </div>
    )}
  </Dialog>
);
NameDirectoryListingFormSectionModal.propTypes = {
  formData: directoryListingType,
  isSaving: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  dialogRef: reactRefType.isRequired,
  errors: PropTypes.objectOf(PropTypes.any),
};
export default NameDirectoryListingFormSectionModal;
