import md5 from 'md5';
import { snakeCase } from 'change-case';

export const constructCorrespondenceTestUploadId = (correspondenceType, bereavementId) => (
  md5(`correspondence/${snakeCase(correspondenceType).toUpperCase()}/${bereavementId}`)
);

export const constructDefaultCorrespondenceTestUploadId = correspondenceType => (
  md5(`correspondence/${snakeCase(correspondenceType).toUpperCase()}/default`)
);

export const generateAccountsCsvUriId = (start, end) => (
  md5(`csv/accounts/${start}/${end}`)
);

export const generateAccountsPaymentsCsvUriId = (start, end) => (
  md5(`csv/accounts/payments/${start}/${end}`)
);

export const generatePrimaryContactCsvUriId = (start, end) => (
  md5(`csv/primaryContactInformation/${start}/${end}`)
);
