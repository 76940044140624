import { getTenantCatalogueCategories } from 'services/utils/catalogue';
import {
  CatalogueCategory, CatalogueItemType, ProductCategory, ServiceCategory,
} from 'types/ts/catalogue';
import { Tenant } from 'types/ts/tenant';

export const getTenantCatalogueCategoryByCategoryPath = (
  tenant: Tenant | null, categoryPath: string,
): CatalogueCategory => {
  const { cataloguePdfCategoryDisplayPreferences } = tenant || {};

  return getTenantCatalogueCategories(cataloguePdfCategoryDisplayPreferences)
    .filter((item: CatalogueCategory) => item.visible)
    .find((item: CatalogueCategory) => item.path === categoryPath);
};

export const getCategoryTypeFromCatalogueCategory = (
  catalogueCategory: CatalogueCategory,
): ProductCategory|ServiceCategory => {
  const type = catalogueCategory.type as CatalogueItemType;
  const isProductType = type === CatalogueItemType.PRODUCT;
  return isProductType
    ? catalogueCategory.category as ProductCategory
    : catalogueCategory.category as ServiceCategory;
};
