import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Slide from '@material-ui/core/Slide';

import ArrangementViewingsCard from 'components/arrangement/ArrangementViewingsCard';
import styles from 'scss/main.scss';
import { checkArrangementsHaveViewings } from 'services/utils/arrangement';
import { arrangementType } from 'types/bereavement';

import rcStyles from './styles.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const ArrangementViewingsModal = ({
  arrangements,
  isOpen,
  selectedArrangement,
  onChangeArrangement,
  onCreateAndEditViewings,
  onClose,
}) => {
  const selectedArrangementHasViewings = selectedArrangement
    && checkArrangementsHaveViewings([selectedArrangement]);

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="arrangement-viewings-title"
      open={isOpen}
      fullWidth
      maxWidth="md"
      TransitionComponent={Transition}
    >
      <div className={styles.c_dialog__body}>
        <DialogContent className={classNames(
          styles.c_dialog__content,
          styles.has_no_header,
          styles.has_no_bottom,
        )}
        >
          {!arrangements?.length && (
            <div>{t('To schedule a viewing first create an arrangement.')}</div>
          )}
          <div>
            <div
              className={classNames(
                rcStyles.select_arrangement_label,
                styles.u_push__bottom_double,
              )}
            >
              {t('Select arrangement')}
            </div>
            <div>
              {arrangements.map(arrangement => (
                <div
                  key={arrangement.id}
                  className={rcStyles.arrangement_viewings_item}
                >
                  <FormControlLabel
                    classes={{ root: rcStyles.arrangement_radio }}
                    control={(
                      <Radio
                        checked={selectedArrangement?.id === arrangement.id}
                        onChange={event => onChangeArrangement(arrangement, event.target.checked)}
                        inputProps={{ 'data-test-id': `arrangement-${arrangement.id}` }}
                        color="primary"
                        classes={{ root: styles.u_hard__left }}
                      />
                    )}
                  />
                  <ArrangementViewingsCard
                    key={arrangement.id}
                    arrangement={arrangement}
                  />
                </div>
              ))}
            </div>
          </div>
        </DialogContent>
        <DialogActions className={styles.c_dialog__actions}>
          <Button
            onClick={onClose}
            variant="outlined"
            color="primary"
            data-test-id="cancel"
            fullWidth
          >
            {t('Cancel')}
          </Button>
          <Button
            onClick={() => onCreateAndEditViewings(selectedArrangement)}
            disabled={!selectedArrangement}
            variant="contained"
            color="primary"
            data-test-id="createAndEditViewings"
            fullWidth
          >
            {selectedArrangementHasViewings ? t('Create & Edit Viewings') : t('Create Viewings')}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

ArrangementViewingsModal.propTypes = {
  arrangements: PropTypes.arrayOf(arrangementType).isRequired,
  isOpen: PropTypes.bool.isRequired,
  selectedArrangement: arrangementType,
  onChangeArrangement: PropTypes.func.isRequired,
  onCreateAndEditViewings: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ArrangementViewingsModal;
