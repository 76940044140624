import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import TextField from '@material-ui/core/TextField';
import RemoveIcon from '@material-ui/icons/Remove';
import AddressFormSection from 'components/common/AddressFormSection';
import PhoneFormSection from 'components/common/PhoneFormSection';
import EmailFormSection from 'components/common/EmailFormSection';
import WebsitesFormSection from 'components/common/WebsitesFormSection';
import DynamicList from 'components/common/DynamicList';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import IconAddEmail from 'icons/IconAddEmail';
import IconAddPhone from 'icons/IconAddPhone';
import IconLink from '@material-ui/icons/Link';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const AdminHomeForm = ({
  home,
  groups,
  onHomeChange,
  disabled,
  formRefs,
  errors,
}) => {
  const {
    name, address, phones, emails, websites, note, parentId,
  } = home;

  return (
    <Fragment>
      <div className={rcStyles.v_spacing}>
        <div
          ref={formRefs.name}
          className={rcStyles.colspan2}
        >
          <TextField
            label={t('Home name*')}
            value={name || ''}
            onChange={event => onHomeChange('name', event.target.value)}
            fullWidth
            inputProps={{
              'data-test-id': 'name',
            }}
            disabled={disabled}
            error={errors && !!errors.name}
            helperText={errors && errors.name}
          />
        </div>
        <div
          ref={formRefs.address}
          className={rcStyles.colspan2}
        >
          <AddressFormSection
            address={address || {}}
            lookupLabel="Address *"
            onChange={newAddress => onHomeChange('address', newAddress)}
            className={styles.u_fullWidth}
            errors={errors.address}
            disabled={disabled}
            data-test-id="addressFormSection"
          />
        </div>
        <div
          ref={formRefs.phones}
          className={rcStyles.colspan3}
        >
          <DynamicList
            component={(
              <PhoneFormSection />
            )}
            addButtonComponent={
              <IconButton data-test-id="addPhone" />
            }
            removeButtonProps={{
              variant: 'outlined',
              color: 'primary',
              size: 'small',
              classes: { root: styles.o_button__round },
            }}
            removeButtonInner={
              <RemoveIcon />
            }
            addButtonProps={{
              variant: 'contained',
              color: 'primary',
              size: 'small',
              classes: {
                root: styles.o_button__secondary,
              },
            }}
            addButtonInner={(
              <IconAddPhone />
            )}
            dataList={phones || []}
            errors={errors.phones}
            onChange={newPhones => onHomeChange('phones', newPhones)}
            defaultEmpty
            fullWidth
            secondary
            data-test-id="phones"
          />
        </div>
        <div
          ref={formRefs.emails}
          className={rcStyles.colspan3}
        >
          <DynamicList
            component={(
              <EmailFormSection
                label={t('Email')}
                className={styles.o_fb__inline}
                fullWidth
              />
            )}
            addButtonComponent={
              <IconButton data-test-id="addEmail" />
            }
            addButtonProps={{
              variant: 'contained',
              color: 'primary',
              size: 'small',
              classes: {
                root: styles.o_button__secondary,
              },
            }}
            addButtonInner={(
              <IconAddEmail />
            )}
            removeButtonProps={{
              variant: 'outlined',
              color: 'primary',
              size: 'small',
              classes: { root: styles.o_button__round },
            }}
            removeButtonInner={
              <RemoveIcon />
            }
            dataList={emails || []}
            errors={errors.emails}
            onChange={newEmails => onHomeChange('emails', newEmails)}
            defaultEmpty
            fullWidth
            secondary
            data-test-id="emails"
          />
        </div>
        <div
          ref={formRefs.websites}
          className={rcStyles.colspan3}
        >
          <DynamicList
            component={(
              <WebsitesFormSection
                label={t('Websites')}
                className={styles.o_fb__inline}
                fullWidth
              />
            )}
            addButtonComponent={
              <IconButton data-test-id="addWebsite" />
            }
            addButtonProps={{
              variant: 'contained',
              color: 'secondary',
              size: 'small',
              classes: { root: styles.o_button__secondary },
            }}
            addButtonInner={(
              <IconLink className={styles.o_icon__primary} />
            )}
            removeButtonProps={{
              variant: 'outlined',
              color: 'primary',
              classes: { root: styles.o_button__round },
            }}
            removeButtonInner={
              <RemoveIcon />
            }
            disabled={disabled}
            errors={errors && errors.websites}
            onChange={newWebsites => onHomeChange('websites', newWebsites)}
            defaultEmpty
            fullWidth
            secondary
            dataList={websites || []}
            data-test-id="websites"
          />
        </div>
        <div className={rcStyles.colspan2}>
          <h3 className={styles.long_primer}>{t('Group')}</h3>
          <FormControl
            component="fieldset"
            className={classNames(
              styles.c_dialogOptionsContainer,
              styles.u_text__center,
            )}
          >
            <RadioGroup
              name="parentId"
              value={parentId || 'none'}
              onChange={event => onHomeChange('parentId', event.target.value)}
              data-test-id="parentGroup"
            >
              {
                groups && groups.map(group => (
                  <FormControlLabel
                    value={group.id}
                    key={group.id || 'none'}
                    control={<Radio color="primary" data-test-id={group.id} />}
                    label={group.name}
                  />
                ))
              }
            </RadioGroup>
          </FormControl>
        </div>
        <div className={rcStyles.colspan2}>
          <TextField
            fullWidth
            label={t('Note')}
            value={note || ''}
            onChange={event => onHomeChange('note', event.target.value)}
            disabled={disabled}
            margin="normal"
            inputProps={{
              'data-test-id': 'note',
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

AdminHomeForm.propTypes = {
  home: PropTypes.objectOf(PropTypes.any),
  groups: PropTypes.arrayOf(PropTypes.shape({})),
  onHomeChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any),
};

export default AdminHomeForm;
