import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import ArrangementListItem from 'components/arrangement/ArrangementListItem';
import IconCreateArrangement from 'icons/IconCreateArrangement';
import { deceasedPersonType, arrangementType } from 'types/bereavement';
import { buildNameString } from 'services/utils';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const ArrangementList = ({
  arrangements,
  deceasedPerson,
  arrangementCollapse,
  disabled,
  onArrangementModalOpen,
  viewArrangement,
  confirmArrangement,
  unconfirmArrangement,
  toggleArrangementCollapse,
}) => (
  <div className={classNames(
    styles.o_view,
    styles.is_flush,
  )}
  >
    <div className={styles.o_view__main}>
      {!arrangements || arrangements.length === 0
        ? (
          <div className={styles.c_k_tile}>
            <div className={rcStyles.create_feature}>
              <IconCreateArrangement
                className={rcStyles.create_feature__graphic}
              />
              <p className={rcStyles.create_feature__message}>
                {t(`You can create multiple arrangements for ${buildNameString(deceasedPerson.name)}
                  allowing you to compare plans and confirm
                  the funeral arrangement that will best honour ${buildNameString(deceasedPerson.name)}'s needs.`)}
              </p>
              <Button
                color="primary"
                variant="contained"
                onClick={() => onArrangementModalOpen()}
                disabled={disabled}
                data-test-id="createArrangement"
              >
                {t('Create Arrangement')}
              </Button>
            </div>
          </div>
        )
        : (
          <Fragment>
            <span>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => onArrangementModalOpen()}
                disabled={disabled}
                data-test-id="createArrangement"
              >
                <AddIcon className={styles.u_push__right_half} />
                {t('Create Arrangement')}
              </Button>
            </span>
            <ul
              className={classNames(
                styles.o_list,
                styles.is_block_list,
              )}
              data-test-id="arrangementList"
            >
              {arrangements.map((item, index) => (
                <ArrangementListItem
                  key={item.id}
                  item={item}
                  disabled={disabled}
                  isExpanded={arrangementCollapse[index]}
                  viewArrangement={viewArrangement}
                  confirmArrangement={confirmArrangement}
                  unconfirmArrangement={unconfirmArrangement}
                  onEditReference={onArrangementModalOpen}
                  toggleArrangementCollapse={() => toggleArrangementCollapse(index)}
                />
              ))}
            </ul>
          </Fragment>
        )
      }
    </div>
  </div>
);

ArrangementList.propTypes = {
  arrangements: PropTypes.arrayOf(arrangementType).isRequired,
  deceasedPerson: deceasedPersonType.isRequired,
  arrangementCollapse: PropTypes.arrayOf(PropTypes.bool.isRequired).isRequired,
  disabled: PropTypes.bool,
  onArrangementModalOpen: PropTypes.func.isRequired,
  viewArrangement: PropTypes.func.isRequired,
  confirmArrangement: PropTypes.func.isRequired,
  unconfirmArrangement: PropTypes.func.isRequired,
  toggleArrangementCollapse: PropTypes.func.isRequired,
};

export default ArrangementList;
