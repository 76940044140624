import React, { Component } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import overrideTheme from 'services/themes/overrideTheme';

import SlideInWindow from './SlideInWindow';

export class SlideInWindowContainer extends Component {
  state = {
    slideInWindowActive: false,
    activeWindow: '',
    toggleInProgress: false,
    slideInWindowOpen: false,
    transitionTime: 500,
  }

  toggleSlideInWindow = () => {
    const { slideInWindowActive, transitionTime } = this.state;

    this.setState({
      slideInWindowActive: !slideInWindowActive,
      toggleInProgress: true,
    });

    setTimeout(() => {
      this.switchWindow();
      this.setState({
        toggleInProgress: false,
        slideInWindowOpen: !slideInWindowActive,
      });
    }, transitionTime);
  }

  switchWindow = (activeWindow = '') => {
    this.setState({
      activeWindow,
    });
  }

  render() {
    const {
      slideInWindowActive,
      activeWindow,
      toggleInProgress,
      slideInWindowOpen,
      transitionTime,
    } = this.state;

    return (
      <MuiThemeProvider theme={overrideTheme}>
        <SlideInWindow
          {...this.props}
          slideInWindowActive={slideInWindowActive}
          toggleSlideInWindow={this.toggleSlideInWindow}
          switchWindow={this.switchWindow}
          activeWindow={activeWindow}
          toggleInProgress={toggleInProgress}
          slideInWindowOpen={slideInWindowOpen}
          transitionTime={transitionTime}
        />
      </MuiThemeProvider>
    );
  }
}

export default SlideInWindowContainer;
