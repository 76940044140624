import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import { t } from 'i18next';
import { bereavementType, arrangementType } from 'types/bereavement';
import { apolloClientType } from 'types/apollo';
import EstimateHeader from './EstimateHeader';
import { getEstimatePdf, getServicePdf } from './queries.gql';
import {
  sendArrangementEstimateEmail,
  sendArrangementServiceDetailsEmail,
} from './mutations.gql';

class EstimateHeaderContainer extends Component {
  static propTypes = {
    bereavement: bereavementType,
    arrangement: arrangementType.isRequired,
    availableFeatures: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    disabled: PropTypes.bool.isRequired,
    client: apolloClientType.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    isOnline: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      isPrintEstimateLoading: false,
      isPrintServiceLoading: false,
      confirmationModal: null,
    };
  }

  handleOnPrintEstimate = () => {
    this.setState({
      isPrintEstimateLoading: true,
    });
    const { client, bereavement, arrangement } = this.props;
    client
      .query({
        query: getEstimatePdf,
        variables: {
          bereavementId: bereavement.id,
          arrangementId: arrangement.id,
        },
      })
      .then((response) => {
        const { uri } = response.data.bereavement.arrangements[0].estimatePdf.media;
        window.open(uri, uri).focus();
      })
      .finally(() => {
        this.setState({
          isPrintEstimateLoading: false,
        });
      });
  }

  handleOnPrintService = () => {
    this.setState({
      isPrintServiceLoading: true,
    });
    const { client, bereavement, arrangement } = this.props;
    client
      .query({
        query: getServicePdf,
        variables: {
          bereavementId: bereavement.id,
          arrangementId: arrangement.id,
        },
      })
      .then((response) => {
        const { uri } = response.data.bereavement.arrangements[0].servicePdf;
        window.open(uri, uri).focus();
      })
      .finally(() => {
        this.setState({
          isPrintServiceLoading: false,
        });
      });
  }

  handleOnSendEstimateEmail = () => {
    const { bereavement, enqueueSnackbar } = this.props;

    const billPayer = bereavement.bereavedPeopleConnections.find(bereaved => bereaved.isBillPayer);

    if (!billPayer?.bereavedPerson?.emails?.length) {
      enqueueSnackbar(
        t('The bill payer must have an email address.'),
        { variant: 'warning' },
      );
      return;
    }

    this.setState({
      confirmationModal: {
        isOpen: true,
        message: `Are you sure you wish to email the estimate to ${billPayer.bereavedPerson.emails[0]}`,
        confirmAction: () => {
          const { client, arrangement } = this.props;

          const input = {
            bereavementId: bereavement.id,
            arrangementId: arrangement.id,
          };

          client.mutate({
            mutation: sendArrangementEstimateEmail,
            variables: input,
          });

          enqueueSnackbar(
            t('Estimate email was sent'),
            { variant: 'success' },
          );

          this.setState({
            confirmationModal: null,
          });
        },
        cancelAction: () => {
          this.setState({
            confirmationModal: null,
          });
        },
      },
    });
  }

  handleOnSendServiceDetailsEmail = () => {
    const { bereavement, enqueueSnackbar, arrangement } = this.props;

    if (!arrangement.isConfirmed) {
      enqueueSnackbar(
        t('Arrangement must be confirmed before service email can be sent.'),
        { variant: 'warning' },
      );
      return;
    }

    const primaryBereaved = bereavement.bereavedPeopleConnections
      .find(bereaved => bereaved.isPrimaryContact);

    if (!primaryBereaved?.bereavedPerson?.emails?.length) {
      enqueueSnackbar(
        t('The primary contact must have an email address.'),
        { variant: 'warning' },
      );
      return;
    }

    this.setState({
      confirmationModal: {
        isOpen: true,
        message: `Are you sure you wish to email the service confirmation to ${primaryBereaved.bereavedPerson.emails[0]}`,
        confirmAction: () => {
          const { client } = this.props;

          const input = {
            bereavementId: bereavement.id,
            arrangementId: arrangement.id,
          };

          client.mutate({
            mutation: sendArrangementServiceDetailsEmail,
            variables: input,
          });

          enqueueSnackbar(
            t('Service details email was sent'),
            { variant: 'success' },
          );

          this.setState({
            confirmationModal: null,
          });
        },
        cancelAction: () => {
          this.setState({
            confirmationModal: null,
          });
        },
      },
    });
  }

  render() {
    const {
      arrangement,
      disabled,
      bereavement,
      availableFeatures,
      isOnline,
    } = this.props;
    const {
      isPrintEstimateLoading,
      isPrintServiceLoading,
      confirmationModal,
    } = this.state;

    return (
      <EstimateHeader
        isOnline={isOnline}
        arrangement={arrangement}
        bereavement={bereavement}
        availableFeatures={availableFeatures}
        onPrintEstimate={this.handleOnPrintEstimate}
        onPrintService={this.handleOnPrintService}
        onSendEstimateEmail={this.handleOnSendEstimateEmail}
        onSendServiceDetailsEmail={this.handleOnSendServiceDetailsEmail}
        isPrintEstimateLoading={isPrintEstimateLoading}
        isPrintServiceLoading={isPrintServiceLoading}
        disabled={disabled}
        confirmationModal={confirmationModal}
      />
    );
  }
}

export default withSnackbar(withApollo(EstimateHeaderContainer));
