export const correspondenceLetterCategories = {
  ACCOUNT: 'ACCOUNT',
  CASE: 'CASE',
};

export const correspondenceLetterCategoryNames = {
  ACCOUNT: 'Account',
  CASE: 'Funeral',
};

export const correspondenceLetterCategoryLabels = {
  ACCOUNT: 'Accounts letter template',
  CASE: 'Funerals letter template',
};
