import React, { Component } from 'react';
import {
  filterSelectionsOnArrangement,
  filterSelectionsOptions, getArrangementSelectionsSortedByEstimateOrder,
  getPlaceholderSelectionsForArrangement,
} from 'services/utils/arrangement';
import PropTypes from 'prop-types';
import { arrangementType } from 'types/bereavement';
import SortableEstimateSelectionsList from 'components/estimate/SortableEstimateSelectionsList';

class EstimateNonPackageSelectionsContainer extends Component {
  static propTypes = {
    bereavementId: PropTypes.string.isRequired,
    arrangement: arrangementType.isRequired,
    onOpenConfirmationWarningModal: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    onSortEnd: PropTypes.func.isRequired,
  }

  getSelections() {
    const { arrangement } = this.props;
    const nonPackageFilters = [
      filterSelectionsOptions.NON_PACKAGE,
      filterSelectionsOptions.NON_DISPLAY_AS_DISBURSEMENTS,
      filterSelectionsOptions.NON_PROFESSIONAL_SERVICES,
    ];
    const selections = getArrangementSelectionsSortedByEstimateOrder(
      arrangement,
      filterSelectionsOnArrangement(arrangement, nonPackageFilters),
    );
    return [
      ...selections,
      ...getPlaceholderSelectionsForArrangement(arrangement, filterSelectionsOptions.NON_PACKAGE),
    ];
  }

  handleOnSortEnd = ({ newIndex, oldIndex }) => {
    const { onSortEnd } = this.props;
    const selections = this.getSelections();
    onSortEnd(selections[oldIndex], selections[newIndex]);
  }

  render() {
    const {
      bereavementId,
      arrangement,
      onOpenConfirmationWarningModal,
      disabled,
    } = this.props;

    return (
      <SortableEstimateSelectionsList
        bereavementId={bereavementId}
        arrangement={arrangement}
        onOpenConfirmationWarningModal={onOpenConfirmationWarningModal}
        selections={this.getSelections()}
        disabled={disabled}
        distance={1}
        onSortEnd={this.handleOnSortEnd}
      />
    );
  }
}

export default EstimateNonPackageSelectionsContainer;
