import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import TextField from '@material-ui/core/TextField';

const EmailFormSection = ({
  value,
  errors,
  handleOnChange,
}) => (
  <div data-test-id="Email">
    <TextField
      label={t('Email')}
      value={value || ''}
      onChange={event => handleOnChange(event.target.value)}
      error={!!errors}
      helperText={errors}
      inputProps={{
        'data-test-id': 'Email',
      }}
      fullWidth
    />
  </div>
);

EmailFormSection.propTypes = {
  value: PropTypes.string,
  errors: PropTypes.string,
  handleOnChange: PropTypes.func.isRequired,
};

export default EmailFormSection;
