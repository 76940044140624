import styled from 'styled-components';
import MUIDrawer from '@material-ui/core/Drawer';

const Drawer = styled(MUIDrawer)`
  max-width: 540px;
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
`;

const Body = styled.div`
  max-width: 80vw;
  padding: 0 1rem;
  width: 450px;
`;

const CategoryRow = styled.div`
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #D8D8D8;
  column-gap: 1rem;
  cursor: pointer;
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-template-rows: 40px;
  padding: 0.5rem;
  transition: all 0.4s;

  @media screen and (min-width: 768px) {
    grid-template-columns: 60px 1fr;
    grid-template-rows: 60px;
  }

  &:hover {
    background-color: #FAF9FB;
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

const CategoryImage = styled.div<{ imagePath: string }>`
  background: ${props => props?.imagePath && `url('${props.imagePath}')`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  content: '';
  height: 100%;
  margin-right: 1rem;
  overflow: hidden;
  width: 100%;
`;

const Link = styled.div<{ isActive: boolean }>`
  color: #514441;
  font-weight: ${props => (props.isActive ? '700' : '400')};
`;

export default {
  Drawer,
  Header,
  Body,
  CategoryRow,
  CategoryImage,
  Link,
};
