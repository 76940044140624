import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { debounce } from 'debounce';
import { bereavementType, totalsType } from 'types/bereavement';
import { paginationType } from 'types/common';
import { organisationalUnitType } from 'types/organisationalUnit';
import { ownersFilters, homesFilters, sortAccountsFilters } from 'constants/bereavement';
import { featureFlags } from 'constants/features';
import { accountStatuses } from 'constants/account';
import { withApollo } from 'react-apollo';
import {
  fetchBereavementsAction,
  updateFiltersAction,
  clearBereavementsAction,
  clearBereavementFiltersAction,
} from 'actions/bereavement';
import AccountsListScreen from './AccountsListScreen';

export class AccountsListScreenContainer extends Component {
  debounceGetBereavements = debounce((filters) => {
    const { clearBereavements } = this.props;
    clearBereavements();
    this.getMoreBereavements(filters);
  }, 500);

  static propTypes = {
    bereavements: PropTypes.arrayOf(bereavementType.isRequired).isRequired,
    homes: PropTypes.arrayOf(organisationalUnitType.isRequired).isRequired,
    filters: PropTypes.objectOf(PropTypes.any).isRequired,
    hasXeroIntegration: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool,
    isLoadingDirectoryListings: PropTypes.bool,
    fetchBereavements: PropTypes.func.isRequired,
    updateFilters: PropTypes.func.isRequired,
    totals: totalsType,
    pagination: paginationType.isRequired,
    clearBereavements: PropTypes.func.isRequired,
    clearBereavementFilters: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      homes: [{
        id: homesFilters[0].id,
        name: t(homesFilters[0].name),
      }, ...props.homes],
      sortBereavementsAnchorElement: null,
      hasSortOptionBeenSelected: false,
    };
  }

  componentDidMount() {
    const {
      updateFilters,
      clearBereavements,
      clearBereavementFilters,
    } = this.props;

    clearBereavements();
    clearBereavementFilters();
    updateFilters('organisationalUnitId', homesFilters[0].id);
    updateFilters('ownerId', ownersFilters[1].id);
    updateFilters('accountStatus', accountStatuses.OPEN);
    updateFilters('sortOrders', [sortAccountsFilters[0].enum]);
    this.getMoreBereavements();
  }

  getMoreBereavements = (filters) => {
    const { fetchBereavements } = this.props;
    fetchBereavements(filters);
  }

  handleFiltersChange = (key, value) => {
    const { filters, updateFilters } = this.props;

    if (value === filters[key]) {
      return;
    }

    if (key === 'sortOrders') {
      this.setState({ hasSortOptionBeenSelected: true });
    }

    updateFilters(key, value);
    this.debounceGetBereavements({ ...filters, [key]: value });
  }

  handleStatusFilterChange = (key, value) => {
    const { filters, updateFilters } = this.props;

    if (key === 'paymentStatus') {
      filters.hasUnInvoicedAmount = value === 'hasUnInvoicedAmount';
      filters.hasBalanceOutstanding = value === 'hasBalanceOutstanding';

      updateFilters('hasUnInvoicedAmount', value === 'hasUnInvoicedAmount');
      updateFilters('hasBalanceOutstanding', value === 'hasBalanceOutstanding');
    }

    if (key === 'accountStatus') {
      filters.accountStatus = value !== 'ALL' ? value : null;
    }

    this.debounceGetBereavements(filters);
  }

  handleSortBereavementsMenuOpen = (event) => {
    this.setState({ sortBereavementsAnchorElement: event.currentTarget });
  }

  handleSortBereavementsMenuClose = () => {
    this.setState({ sortBereavementsAnchorElement: null });
  }

  render() {
    const {
      bereavements,
      totals,
      filters,
      hasXeroIntegration,
      isLoading,
      pagination,
      isLoadingDirectoryListings,
    } = this.props;
    const { homes, sortBereavementsAnchorElement, hasSortOptionBeenSelected } = this.state;
    let statusFilter = 'all';
    if (filters.hasUnInvoicedAmount) {
      statusFilter = 'hasUnInvoicedAmount';
    }
    if (filters.hasBalanceOutstanding) {
      statusFilter = 'hasBalanceOutstanding';
    }
    const accountStatus = filters.accountStatus || 'ALL';

    return (
      <AccountsListScreen
        bereavements={bereavements}
        totals={totals}
        homes={homes}
        filters={filters}
        hasXeroIntegration={hasXeroIntegration}
        statusFilter={statusFilter}
        accountStatus={accountStatus}
        hasMoreItems={bereavements.length > 0 && pagination.hasNextPage}
        isLoading={isLoading || isLoadingDirectoryListings}
        getBereavements={this.getMoreBereavements}
        onFiltersChange={this.handleFiltersChange}
        onStatusFilterChange={this.handleStatusFilterChange}
        toggleSortBereavementsMenu={this.toggleSortBereavementsMenu}
        isSortBereavementsMenuOpen={!!sortBereavementsAnchorElement}
        onSortBereavementsMenuOpen={this.handleSortBereavementsMenuOpen}
        onSortBereavementsMenuClose={this.handleSortBereavementsMenuClose}
        sortBereavementsAnchorElement={sortBereavementsAnchorElement}
        hasSortOptionBeenSelected={hasSortOptionBeenSelected}
      />
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchBereavements: fetchBereavementsAction,
  updateFilters: updateFiltersAction,
  clearBereavements: clearBereavementsAction,
  clearBereavementFilters: clearBereavementFiltersAction,
}, dispatch);


const mapStateToProps = state => ({
  bereavements: state.bereavementStore.bereavements,
  totals: state.bereavementStore.totals,
  pagination: state.bereavementStore.pagination,
  isLoading: state.bereavementStore.isLoading,
  filters: state.bereavementStore.filters,
  homes: state.userStore.user.staffMember.organisationalUnitsFlattened,
  hasXeroIntegration: state.userStore.user.tenantFeatureFlags.includes(featureFlags.XERO_INTEGRATION),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withApollo(AccountsListScreenContainer),
);
