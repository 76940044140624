import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import AddPhotoIcon from '@material-ui/icons/AddPhotoAlternate';
import { possessionType } from 'types/bereavement';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const PossessionItem = ({
  possession,
  disabled,
  onEdit,
  getImageVariant,
  getType,
  getLastAction,
  getPastActions,
  getFamilyWishesPlaceholder,
}) => (
  <div className={rcStyles.possession_list__item}>

    {possession.image ? (
      <div
        style={{ backgroundImage: `url('${getImageVariant(possession.image)}')` }}
        className={rcStyles.possession_list__image}
      />
    ) : (
      <div className={rcStyles.possession_list__image_placeholder}>
        <AddPhotoIcon className={styles.is_primary} />
      </div>
    )}

    <div>
      <p className={classNames(
        styles.primer,
        styles.is_bold,
      )}
      >
        {`${getType(possession.type)}: ${possession.description}`}
      </p>
      <p className={classNames(
        styles.brevier,
        styles.is_italic,
        styles.is_grey,
        styles.u_push__bottom,
      )}
      >
        {getFamilyWishesPlaceholder(possession.familyWishes)}
      </p>
      <p>{t('Last action')}</p>
      <p className={classNames(
        styles.primer,
        styles.is_grey,
      )}
      >
        {getLastAction(possession.actionHistory)}
      </p>
      {getPastActions(possession.actionHistory).length > 0 && (
        <div>
          <p>{t('Past actions')}</p>
          {getPastActions(possession.actionHistory).map((action, actionIndex) => (
            <div
              key={actionIndex}
              className={classNames(
                styles.primer,
                styles.is_grey,
              )}
            >
              {action}
            </div>
          ))}
        </div>
      )}
    </div>
    {onEdit && (
      <div>
        <IconButton
          onClick={() => onEdit(possession)}
          variant="contained"
          color="primary"
          classes={{ root: styles.rc_button_bg }}
          disabled={disabled}
          data-test-id="editPossession"
        >
          <EditIcon />
        </IconButton>
      </div>
    )}
  </div>
);

PossessionItem.propTypes = {
  possession: possessionType.isRequired,
  disabled: PropTypes.bool.isRequired,
  onEdit: PropTypes.func,
  getImageVariant: PropTypes.func.isRequired,
  getType: PropTypes.func.isRequired,
  getLastAction: PropTypes.func.isRequired,
  getPastActions: PropTypes.func.isRequired,
  getFamilyWishesPlaceholder: PropTypes.func.isRequired,
};

export default PossessionItem;
