import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import classNames from 'classnames';
import DocumentUploading from 'icons/DocumentUploading';
import DocumentSuccess from 'icons/DocumentSuccess';
import DocumentWarning from 'icons/DocumentWarning';

import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const TemplateReportModal = ({
  onContinue,
  isOpen,
  report,
  isUploading,
}) => (
  <Dialog
    onClose={onContinue}
    aria-labelledby="template-report-dialog"
    fullWidth
    maxWidth="md"
    open={isOpen}
    TransitionComponent={Transition}
    data-test-id="templateReportDialog"
    disableBackdropClick
    disableEscapeKeyDown
  >
    <DialogContent
      className={styles.c_dialog__content}
      data-test-id="templateReportDialogContent"
    >
      <div className={classNames(
        styles.u_push__top,
      )}
      >
        <div className={rcStyles.templateReport}>
          <div className={rcStyles.reportOutcomeIconWrapper}>
            {isUploading && (
              <DocumentUploading />
            )}
            {!isUploading && report.length === 0 && (
              <DocumentSuccess />
            )}
            {!isUploading && report.length > 0 && (
              <DocumentWarning />
            )}
          </div>
          {isUploading && (
            <h3 className={classNames(
              styles.long_primer,
              styles.is_black,
            )}
            >
              Uploading...
            </h3>
          )}
          {!isUploading && report.length === 0 && (
            <>
              <h3 className={classNames(
                styles.long_primer,
                styles.is_black,
              )}
              >
                Template uploaded successfully
              </h3>
              <p>
                {t('This template is immediately available for use. When downloaded any values you\'ve included will be '
                  + 'automatically populated based on the information entered for each funeral.')}
              </p>
            </>
          )}
          {!isUploading && report.length > 0 && (
            <>
              <h3 className={classNames(
                styles.long_primer,
                styles.is_black,
              )}
              >
                {t('The following issues were found with your template')}
              </h3>
              <p>
                <span>Get help with errors: </span>
                <a
                  href="http://funeral-zone-support.knowledgeowl.com/help/upload-messages"
                >
                  http://funeral-zone-support.knowledgeowl.com/help/upload-messages
                </a>
              </p>
              {report.map(item => (
                <span>{item}</span>
              ))}
            </>
          )}
        </div>
      </div>
    </DialogContent>
    {!isUploading && (
      <DialogActions>
        <Button
          onClick={onContinue}
          variant="contained"
          color="primary"
          data-test-id="templateReportContinueButton"
          fullWidth
        >
          {t('Continue')}
        </Button>
      </DialogActions>
    )}
  </Dialog>
);

TemplateReportModal.propTypes = {
  onContinue: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isUploading: PropTypes.bool.isRequired,
  report: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TemplateReportModal;
