import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SortMenu from './SortMenu';

export class SortMenuContainer extends Component {
  static propTypes = {
    onChangeFilters: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    selectedValue: PropTypes.string,
  }

  constructor(props) {
    super(props);

    let selectedItem = {};
    if (props.selectedValue && props.options) {
      selectedItem = props.options.find(option => option.enum === props.selectedValue);
    }

    this.state = {
      anchor: null,
      selectedItem,
    };
  }

  handleOpen = (event) => {
    this.setState({ anchor: event.currentTarget });
  }

  handleClose = () => {
    this.setState({ anchor: null });
  }

  handleChange = (selectedItem) => {
    const { onChangeFilters } = this.props;

    this.setState({ selectedItem });
    onChangeFilters(selectedItem.enum);
  }

  render() {
    const { anchor, selectedItem } = this.state;

    return (
      <SortMenu
        {...this.props}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        onChange={this.handleChange}
        anchor={anchor}
        isOpen={!!anchor}
        selectedItem={selectedItem}
      />
    );
  }
}

export default SortMenuContainer;
