import PropTypes from 'prop-types';
import { moneyType } from './common';

export const invoiceSectionItemType = PropTypes.shape({
  amount: moneyType.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  taxBand: PropTypes.string,
});

export const invoiceSectionType = PropTypes.shape({
  items: PropTypes.arrayOf(invoiceSectionItemType),
});

export const invoiceTotalsType = PropTypes.shape({
  balance: moneyType,
  total: moneyType,
  totalCredit: moneyType,
  totalPaid: moneyType,
});

export const invoiceType = PropTypes.shape({
  data: PropTypes.string,
  dateVoided: PropTypes.string,
  dateTime: PropTypes.string,
  discount: PropTypes.string,
  id: PropTypes.string,
  notes: PropTypes.string,
  number: PropTypes.string,
  reference: PropTypes.string,
  sections: PropTypes.arrayOf(invoiceSectionType),
  totals: invoiceTotalsType,
});

export const creditType = PropTypes.shape({
  id: PropTypes.string,
  amount: moneyType.isRequired,
  reason: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  invoiceId: PropTypes.string,
});

export const paymentType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  payeeId: PropTypes.string.isRequired,
  payeeType: PropTypes.string,
  amount: moneyType.isRequired,
  reference: PropTypes.string,
  method: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  invoiceId: PropTypes.string,
});

export const historicFuneralAccountChargeType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  amount: moneyType.isRequired,
  transferDateTime: PropTypes.string.isRequired,
});

export const accountTotalsType = PropTypes.shape({
  totalUnInvoiced: moneyType.isRequired,
  totalInvoiced: moneyType.isRequired,
  totalCredit: moneyType.isRequired,
  totalPaid: moneyType.isRequired,
  balance: moneyType.isRequired,
  totalInvoiceTemplatesInvoiced: moneyType.isRequired,
  totalInvoiceTemplatesUninvoiced: moneyType.isRequired,
});

export const accountType = PropTypes.shape({
  invoices: PropTypes.arrayOf(invoiceType),
  payments: PropTypes.arrayOf(paymentType),
  credits: PropTypes.arrayOf(creditType),
  historicFuneralAccountCharge: moneyType,
  historicFuneralAccountTransferDateTime: PropTypes.string,
  totals: accountTotalsType.isRequired,
});

export const customChargeType = PropTypes.shape({
  description: PropTypes.string,
  id: PropTypes.string,
  isDisbursement: PropTypes.bool,
  isSelected: PropTypes.bool,
  originalPrice: moneyType,
  overridePrice: moneyType,
  taxBand: PropTypes.string,
  title: PropTypes.string,
});

export const sectionsType = PropTypes.arrayOf(PropTypes.shape({
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    amount: moneyType.isRequired,
    isSelected: PropTypes.bool.isRequired,
  })),
}));

export const closeEventType = PropTypes.shape({
  dateTime: PropTypes.string.isRequired,
  staffMemberId: PropTypes.string.isRequired,
  reason: PropTypes.string,
});

export const reopenEventType = PropTypes.shape({
  dateTime: PropTypes.string.isRequired,
  staffMemberId: PropTypes.string.isRequired,
});
