import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const IconDanger = (props) => {
  const { className } = props;
  const classes = className ? `${className} ${styles.rc_icon}` : styles.rc_icon;

  return (
    <svg className={classes} viewBox="0 0 24 24">
      <path fill="hsla(4,90%,58%,1)" d="M13,14H11V10H13M13,18H11V16H13M1,21H23L12,2L1,21Z" />
    </svg>
  );
};

IconDanger.propTypes = {
  className: PropTypes.string,
};

export default IconDanger;
