
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FullAddress"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Address"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addressLine1"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"addressLine2"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"town"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"county"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"postCode"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"countryCode"},"arguments":[],"directives":[]}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FullAddressBestGuess"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AddressWithUdprn"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addressLine1"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"addressLine2"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"town"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"county"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"postCode"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"countryCode"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"udprn"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":241}};
    doc.loc.source = {"body":"fragment FullAddress on Address {\n  addressLine1\n  addressLine2\n  town\n  county\n  postCode\n  countryCode\n}\n\nfragment FullAddressBestGuess on AddressWithUdprn {\n  addressLine1\n  addressLine2\n  town\n  county\n  postCode\n  countryCode\n  udprn\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
