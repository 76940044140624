import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';

import MediaUpload from 'components/common/MediaUpload';
import DocumentWarning from 'icons/DocumentWarning';
import DocumentSuccess from 'icons/DocumentSuccess';
import InvoiceLoad from 'icons/InvoiceLoad';
import IconLoadSml from 'icons/IconLoadSml';
import styles from 'scss/main.scss';

import rcStyles from './styles.scss';

const ResultsPanel = ({
  importHasErrors,
  isUploading,
  report,
  onClose,
  onUploadInitialized,
  onUploadDone,
}) => (
  <div
    className={classNames(
      styles.c_page__full_height,
      styles.has_faded_background,
    )}
  >
    <div className={styles.o_view}>
      <div
        className={classNames(
          styles.o_view__main,
          styles.o_block_content,
          rcStyles.v_spacing,
        )}
      >
        <div className={rcStyles.is_image}>
          {isUploading && (
            <InvoiceLoad />
          )}
          {!isUploading && importHasErrors && (
            <DocumentWarning
              classes={[rcStyles.importReportIcon]}
              viewBox="30 -20 145 164"
            />
          )}
          {!isUploading && !importHasErrors && (
            <DocumentSuccess
              classes={[rcStyles.importReportIcon]}
              viewBox="30 -20 145 164"
            />
          )}
        </div>
        <div className={rcStyles.is_content}>
          {importHasErrors && (
            <>
              <p className={classNames(styles.primer, styles.u_push__bottom)}>
                {!isUploading ? t('Import failed.') : ''}
              </p>
              <div className={rcStyles.actions}>
                <MediaUpload
                  showImagePreview={false}
                  contentDisposition="inline"
                  acceptDocuments
                  accept={['.zip']}
                  onUploadInitialized={onUploadInitialized}
                  onUploadDone={onUploadDone}
                  customButton={(
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      data-test-id="uploadTemplate"
                    >
                      {isUploading ? (
                        <div
                          className={classNames(
                            styles.c_svg_loader_sml,
                            styles.c_svg_loader_sml_btn,
                          )}
                          data-test-id="buttonLoader"
                        >
                          <IconLoadSml />
                        </div>
                      ) : t('Upload Again')}
                    </Button>
                  )}
                  data-test-id="importCatalogue"
                />
              </div>
              {!isUploading && report?.length && (
                <div className={rcStyles.report_errors}>
                  <div className={rcStyles.report_errors__header}>
                    <div className={rcStyles.report_errors__header__title}>{`${report?.length} ${t('errors found')}`}</div>
                    <div className={rcStyles.report_errors__header__subtitle}>
                      {t('Please fix and upload again')}
                    </div>
                  </div>
                  <div className={rcStyles.report_errors__body}>
                    <ul className={rcStyles.report_errors__list}>
                      {report && report.map((item, index) => (
                        <li
                          key={index}
                          className={rcStyles.report_errors__list_item}
                        >
                          <div className={rcStyles.report_item_detail}>{!!item?.row && `${t('Row')} ${item.row}`}</div>
                          <div className={rcStyles.report_item_detail}>{!!item?.message && item.message}</div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </>
          )}
          {!importHasErrors && (
            <>
              <p className={classNames(styles.primer, styles.u_push__bottom)}>
                {t('The file was successfully imported.')}
              </p>
              <p className={classNames(styles.primer, styles.u_push__bottom)}>
                {t('The contents of the file have been added as draft products & services in your catalogue.')}
              </p>
              <div className={rcStyles.actions}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onClose}
                  data-test-id="backToImportExport"
                >
                  {t('Back to import/export')}
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  </div>
);

ResultsPanel.propTypes = {
  importHasErrors: PropTypes.bool.isRequired,
  isUploading: PropTypes.bool.isRequired,
  report: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  onClose: PropTypes.func.isRequired,
  onUploadInitialized: PropTypes.func.isRequired,
  onUploadDone: PropTypes.func.isRequired,
};

export default ResultsPanel;
