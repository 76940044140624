import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import { getUriForMediaByVariant } from 'services/utils';
import { getIconForCategory } from 'services/utils/icon';
import { mediaVariants } from 'constants/media';
import { mediaType } from 'types/media';

import rcStyles from './styles.scss';

const ArrangementSelectedItemAvatar = ({
  image,
  title,
  category,
}) => (
  <Fragment>
    {image ? (
      <Avatar
        alt={title}
        src={getUriForMediaByVariant(image, mediaVariants.FIT_200_X_200)}
      />
    ) : (
      <Avatar
        alt={title}
        className={rcStyles.is_avatar}
      >
        {getIconForCategory(category)}
      </Avatar>
    )}
  </Fragment>
);

ArrangementSelectedItemAvatar.propTypes = {
  image: mediaType,
  title: PropTypes.string.isRequired,
  category: PropTypes.string,
};

export default ArrangementSelectedItemAvatar;
