import { pipe } from 'services/utils';
import {
  convertFeetToInches,
  getFeetFromInches,
  getRemainingInchesFromFeet,
  getValueAsFloat,
  getValueAsInt,
  convertToPositiveNumber,
  limitToMaxNumber,
} from 'services/utils/measurement';

export enum MeasurementUnit {
  FEET = 'FEET',
  INCHES = 'INCHES',
  CENTIMETERS = 'CENTIMETERS',
  KGS = 'KGS',
}

export enum MeasurementDisplayUnit {
  INCHES = 'INCHES',
  FEET = 'FEET',
  CENTIMETERS = 'CENTIMETERS',
  KGS = 'KGS',
}

export const measurementConfig = {
  [MeasurementDisplayUnit.INCHES]: {
    inputs: [{
      type: MeasurementUnit.INCHES,
      adornment: 'inches',
      isFloat: true,
      onChangeValue: (value: string) => pipe(getValueAsFloat, convertToPositiveNumber)(value),
    }],
  },
  [MeasurementDisplayUnit.FEET]: {
    inputs: [
      {
        type: MeasurementUnit.FEET,
        adornment: 'feet',
        isFloat: false,
        toMeasurement: (value: number|null) => convertFeetToInches(value),
        toValue: (value: number|null) => getFeetFromInches(value),
        onChangeValue: (value: string) => pipe(getValueAsInt, convertToPositiveNumber)(value),
      },
      {
        type: MeasurementUnit.INCHES,
        adornment: 'inches',
        isFloat: true,
        toValue: (value: number|null) => getRemainingInchesFromFeet(value),
        onChangeValue: (value: string) => pipe(getValueAsFloat, convertToPositiveNumber, limitToMaxNumber(11))(value),
      },
    ],
  },
  [MeasurementDisplayUnit.CENTIMETERS]: {
    inputs: [{
      type: MeasurementUnit.CENTIMETERS,
      adornment: 'cm',
      isFloat: true,
      onChangeValue: (value: string) => pipe(getValueAsFloat, convertToPositiveNumber)(value),
    }],
  },
  [MeasurementDisplayUnit.KGS]: {
    inputs: [{
      type: MeasurementUnit.KGS,
      adornment: 'Kg',
      isFloat: true,
      onChangeValue: (value: string) => pipe(getValueAsFloat, convertToPositiveNumber)(value),
    }],
  },
};
