import { v4 as uuidv4 } from 'uuid';
import { fetchBereavementAction } from 'actions/bereavement';
import { status as taskStatus } from 'constants/task';
import {
  hydrateTaskTransform,
  editTaskTransform,
  createCustomTaskTransform,
} from 'transforms/task';
import { getTasks } from './task.queries.gql';
import { editTask, createCustomTask } from './task.mutations.gql';

export const taskActions = {
  CLEAR_TASKS: 'TASK_CLEAR_TASKS',
  SET_TASKS: 'TASK_SET_TASKS',
  SET_TASK: 'TASK_SET_TASK',
  PREPEND_TASK: 'TASK_PREPEND_TASK',
  SET_LOADING_STATE: 'TASK_SET_LOADING_STATE',
};

export const clearTasksAction = () => ({
  type: taskActions.CLEAR_TASKS,
});

export const setTasksAction = (tasks, pageInfo, reload) => ({
  type: taskActions.SET_TASKS,
  payload: {
    tasks,
    pageInfo,
  },
  reload,
});

export const setTaskAction = task => ({
  type: taskActions.SET_TASK,
  payload: { task },
});

export const prependTaskAction = task => ({
  type: taskActions.PREPEND_TASK,
  payload: { task },
});

export const setTasksLoadingStateAction = state => ({
  type: taskActions.SET_LOADING_STATE,
  state,
});

export const fetchTasksAction = ({
  pagination,
  bereavementId,
  organisationalUnitIds,
  assigneeId,
  categories,
  status,
}) => (dispatch, getState, client) => {
  dispatch(setTasksLoadingStateAction(true));
  const query = client.query({
    query: getTasks,
    variables: {
      pagination,
      bereavementId,
      organisationalUnitIds,
      assigneeId,
      categories,
      status,
    },
  }).then(({ data }) => {
    const tasks = data.tasks.edges.map(edge => hydrateTaskTransform(edge.node));
    dispatch(setTasksAction(
      tasks,
      {
        totalCount: data.tasks.totalCount,
        hasMore: data.tasks.pageInfo.hasNextPage,
        cursor: data.tasks.pageInfo.endCursor,
      },
      !(pagination.after),
    ));
  }).catch(() => {
    dispatch(setTasksLoadingStateAction(false));
  });
  return query;
};

export const editTaskAction = task => (dispatch, getState, client) => {
  dispatch(setTaskAction(task));
  client.mutate({
    mutation: editTask,
    variables: { input: editTaskTransform(task) },
  }).then(() => {
    if (task.bereavementId && task.status === taskStatus.COMPLETED) {
      setTimeout(() => dispatch(fetchBereavementAction(task.bereavementId)), 5000);
    }
  });
};

export const createCustomTaskAction = task => (dispatch, getState, client) => {
  const taskToSave = {
    ...task,
    id: uuidv4(),
  };

  dispatch(prependTaskAction(taskToSave));
  client.mutate({
    mutation: createCustomTask,
    variables: {
      input: createCustomTaskTransform(taskToSave),
    },
  });
};
