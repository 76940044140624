import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { transferType } from 'types/bereavement';
import TransferModal from './TransferModal';
import { validationSchema } from './validation';

export class TransferModalContainer extends Component {
  static propTypes = {
    transfer: transferType,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    generateRefs: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
    setErrors: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    const { transfer } = props;

    this.state = {
      transfer,
      isValidationEnabled: false,
    };

    this.dialogRef = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    const { transfer: transferFromState } = state;
    if (!transferFromState) {
      const { transfer: transferFromProps } = props;
      return { transfer: transferFromProps };
    }
    return null;
  }

  componentDidMount() {
    const { generateRefs } = this.props;
    generateRefs(Object.keys(validationSchema.fields));
  }

  handleChange = (key, value) => {
    const { validate } = this.props;
    const { transfer, isValidationEnabled } = this.state;
    const updatedTransfer = {
      ...transfer,
      [key]: value,
    };

    if (isValidationEnabled) {
      validate(updatedTransfer, validationSchema);
    }

    this.setState({ transfer: updatedTransfer });
  }

  handleClose = () => {
    const { onClose, setErrors } = this.props;
    this.setState({
      transfer: null,
      isValidationEnabled: false,
    });
    setErrors({});
    onClose();
  }

  handleSave = () => {
    const { onSave, validate } = this.props;
    const { transfer } = this.state;

    this.setState({ isValidationEnabled: true });

    const isTransferValid = validate(transfer, validationSchema, true, this.dialogRef);

    if (transfer.__typename === 'InternalTransfer') {
      transfer.endDateTime = transfer.startDateTime;
    }

    if (isTransferValid) {
      onSave(transfer);
      this.handleClose();
    }
  }

  render() {
    const { isOpen, formRefs, errors } = this.props;
    const { transfer } = this.state;
    return (
      <TransferModal
        transfer={transfer}
        isOpen={isOpen}
        onChange={this.handleChange}
        onSave={this.handleSave}
        onClose={this.handleClose}
        dialogRef={this.dialogRef}
        formRefs={formRefs}
        errors={errors}
      />
    );
  }
}

export default withValidation(TransferModalContainer);
