import * as yup from 'yup';
import { isAddressEmpty } from 'services/utils';

const {
  string,
  boolean,
  array,
  object,
} = yup;

yup.addMethod(string, 'phoneCustom', function getPhoneValidation(message) {
  return this.test('testPhoneNumber', message, function validatePhone(number) {
    const { path, createError } = this;
    // Validate phone regex
    let valid = true;
    if (number) {
      valid = (/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/).test(number);
    }
    return valid || createError({ path, message });
  });
});

export const validationSchema = object().shape({
  name: string().required('Name is required'),
  address: object().nullable().shape({
    addressLine1: string().required('First line of address is required'),
    addressLine2: string().nullable(),
    town: string().required('Town is required'),
    county: string().nullable(),
    postCode: string().required('Post Code is required'),
    countryCode: string().required('Country is required'),
  }).test('directoryListingTest', 'At least one contact method is required', function validateDirectoryListing(value) {
    const { phones, emails } = this.parent;
    if (phones.length === 0 && emails.length === 0) {
      return !isAddressEmpty(value);
    }
    return true;
  }),
  note: string().nullable(),
  isSupplier: boolean(),
  phones: array().of(
    object().shape({
      telephone: object().shape({
        countryCode: string().required('Telephone country code is required'),
        number: string()
          .phoneCustom('Please enter a valid phone number')
          .required('Telephone number is required'),
      }),
      type: string().required('Telephone type is required'),
    }),
  ).test('directoryListingTest', 'At least one contact method is required', function validateDirectoryListing(value) {
    const { address, emails } = this.parent;
    const isAddressValid = !isAddressEmpty(address);
    if (!isAddressValid && emails.length === 0) {
      return value.length > 0;
    }
    return true;
  }),
  emails: array().of(
    string().nullable().email('Email is not valid').required('Email is required'),
  ).test('directoryListingTest', 'At least one contact method is required', function validateDirectoryListing(value) {
    const { address, phones } = this.parent;
    const isAddressValid = !isAddressEmpty(address);
    if (!isAddressValid && phones.length === 0) {
      return value.length > 0;
    }
    return true;
  }),
  websites: array().of(string()),
});
