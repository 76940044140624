import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { generateHexId, isValueEmptyOrFalse } from 'services/utils';
import { buildTransferByType } from 'services/utils/bereavement';
import { emptyFirstOffices, emptyMortuary, transferTypes } from 'constants/bereavement';
import { addressType } from 'types/common';
import { firstOfficesType, mortuaryType, transferType } from 'types/bereavement';
import CareRecordBodyForm from './CareRecordBodyForm';
import { validationSchema } from './validation';

class CareRecordBodyFormContainer extends Component {
  static propTypes = {
    viewingNotAdvised: PropTypes.bool,
    reasonForViewingNotAdvised: PropTypes.bool,
    firstOffices: firstOfficesType,
    mortuary: mortuaryType,
    transfers: PropTypes.arrayOf(transferType),
    collectionLocation: addressType,
    disabled: PropTypes.bool,
    onCareRecordBodyFormSave: PropTypes.func.isRequired,
    activeTab: PropTypes.string.isRequired,
    activeCareRecordTab: PropTypes.number.isRequired,
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    generateRefs: PropTypes.func.isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  }

  constructor(props) {
    super(props);

    const {
      viewingNotAdvised,
      reasonForViewingNotAdvised,
      firstOffices,
      mortuary,
      transfers,
    } = props;
    const eyeCare = firstOffices ? firstOffices.eyeCare : {};
    const mouthCare = firstOffices ? firstOffices.mouthCare : {};
    const disinfection = firstOffices ? firstOffices.disinfection : {};

    const initialisedFirstOffices = {
      eyeCare: {
        ...emptyFirstOffices.eyeCare,
        ...eyeCare,
      },
      mouthCare: {
        ...emptyFirstOffices.mouthCare,
        ...mouthCare,
      },
      disinfection: {
        ...emptyFirstOffices.disinfection,
        ...disinfection,
      },
      notes: firstOffices?.notes,
    };
    const initialisedMortuary = {
      ...emptyMortuary,
      ...mortuary,
    };
    const initialisedTransfers = transfers || [];


    this.state = {
      viewingNotAdvised: viewingNotAdvised || false,
      reasonForViewingNotAdvised,
      firstOffices: initialisedFirstOffices,
      firstOfficesTotalSelectedFields: this.getTotalSelectedFirstOfficesFields(initialisedFirstOffices),
      mortuary: initialisedMortuary,
      mortuaryTotalSelectedFields: this.getTotalSelectedMortuaryFields(initialisedMortuary),
      transfers: initialisedTransfers,
      isValidationEnabled: false,
      addTransferMenuAnchorElement: null,
      activeTransfer: null,
    };
  }

  componentDidMount() {
    const { generateRefs } = this.props;
    generateRefs(Object.keys(validationSchema.fields));
  }

  getTotalSelectedItems = object => (
    Object.keys(object).reduce((selectedItems, key) => {
      const value = object[key];
      return isValueEmptyOrFalse(value) ? selectedItems : selectedItems + 1;
    }, 0)
  )

  getTotalSelectedFirstOfficesFields = (firstOffices) => {
    const { eyeCare, mouthCare, disinfection } = firstOffices;
    return this.getTotalSelectedItems(eyeCare)
      + this.getTotalSelectedItems(mouthCare)
      + this.getTotalSelectedItems(disinfection);
  }

  getTotalSelectedMortuaryFields = mortuary => this.getTotalSelectedItems(mortuary);

  handleFirstOfficesChange = (key, value) => {
    const { firstOffices } = this.state;
    const updatedFirstOffices = {
      ...firstOffices,
      [key]: value,
    };
    const newState = {
      firstOffices: updatedFirstOffices,
      firstOfficesTotalSelectedFields: this.getTotalSelectedFirstOfficesFields(updatedFirstOffices),
    };
    this.setState(newState);
  }

  handleFirstOfficesSectionChange = (section, key, value) => {
    const { firstOffices } = this.state;
    const updatedSection = {
      ...firstOffices[section],
      [key]: value,
    };
    const updatedFirstOffices = {
      ...firstOffices,
      [section]: updatedSection,
    };
    const newState = {
      firstOffices: updatedFirstOffices,
      firstOfficesTotalSelectedFields: this.getTotalSelectedFirstOfficesFields(updatedFirstOffices),
    };
    this.setState(newState);
  }

  handleMortuaryChange = (key, value) => {
    const { mortuary } = this.state;
    const updatedMortuary = {
      ...mortuary,
      [key]: value,
    };
    const newState = {
      mortuary: updatedMortuary,
      mortuaryTotalSelectedFields: this.getTotalSelectedMortuaryFields(updatedMortuary),
    };
    this.setState(newState);
  }

  handleViewingNotAdvisedChange = (key, value) => {
    this.setState({ [key]: value });
  }

  toggleAddTransferMenu = (target) => {
    this.setState({ addTransferMenuAnchorElement: target });
  }

  toggleTransferModal = (value) => {
    const attachBodyLocation = (transfer) => {
      const { collectionLocation: fromLocation } = this.props;
      return value === transferTypes.TRANSFER_INTO_CARE
        ? { ...transfer, fromLocation }
        : transfer;
    };
    const activeTransfer = (typeof value === 'string')
      ? attachBodyLocation(buildTransferByType(value))
      : value;
    this.setState({
      activeTransfer,
    });
  }

  handleSaveTransfer = (transfer) => {
    const { transfers } = this.state;
    const { id } = transfer;
    const isNew = !id;

    if (isNew) {
      const updatedTransfer = {
        id: generateHexId(),
        ...transfer,
      };
      const updatedTransfers = [updatedTransfer, ...transfers]
        .sort((transfer1, transfer2) => (
          moment(transfer1.endDateTime).isBefore(moment(transfer2.endDateTime)) ? -1 : 1
        ));
      this.setState({ transfers: updatedTransfers });
    } else {
      const updatedTransfers = transfers.map((existingTransfer) => {
        if (existingTransfer.id === id) {
          return transfer;
        }
        return existingTransfer;
      });
      this.setState({ transfers: updatedTransfers });
    }
  }

  handleRemoveTransfer = (transferId) => {
    const { transfers } = this.state;
    this.setState({
      transfers: transfers.filter(existingTransfer => existingTransfer.id !== transferId),
    });
  }

  handleSave = () => {
    const { validate, onCareRecordBodyFormSave } = this.props;
    const {
      viewingNotAdvised,
      reasonForViewingNotAdvised,
      firstOffices,
      mortuary,
      transfers,
    } = this.state;

    this.setState({ isValidationEnabled: true });

    const validationObject = {
      viewingNotAdvised,
      reasonForViewingNotAdvised,
      firstOffices,
      mortuary,
    };
    const isValid = validate(validationObject, validationSchema, true);

    if (isValid) {
      onCareRecordBodyFormSave(viewingNotAdvised, reasonForViewingNotAdvised, firstOffices, mortuary, transfers);
    }
  }

  render() {
    const {
      disabled, formRefs, errors, activeTab, activeCareRecordTab,
    } = this.props;
    const {
      viewingNotAdvised,
      reasonForViewingNotAdvised,
      firstOffices,
      firstOfficesTotalSelectedFields,
      mortuary,
      mortuaryTotalSelectedFields,
      transfers,
      isValidationEnabled,
      addTransferMenuAnchorElement,
      activeTransfer,
    } = this.state;
    return (
      <CareRecordBodyForm
        activeTab={activeTab}
        activeCareRecordTab={activeCareRecordTab}
        viewingNotAdvised={viewingNotAdvised}
        reasonForViewingNotAdvised={reasonForViewingNotAdvised}
        firstOffices={firstOffices}
        firstOfficesTotalSelectedFields={firstOfficesTotalSelectedFields}
        mortuary={mortuary}
        mortuaryTotalSelectedFields={mortuaryTotalSelectedFields}
        transfers={transfers}
        disabled={disabled}
        onFirstOfficesChange={this.handleFirstOfficesChange}
        onFirstOfficesSectionChange={this.handleFirstOfficesSectionChange}
        onMortuaryChange={this.handleMortuaryChange}
        onViewingNotAdvisedChange={this.handleViewingNotAdvisedChange}
        onSave={this.handleSave}
        anchorElement={addTransferMenuAnchorElement}
        toggleAddTransferMenu={this.toggleAddTransferMenu}
        toggleTransferModal={this.toggleTransferModal}
        activeTransfer={activeTransfer}
        onSaveTransfer={this.handleSaveTransfer}
        onRemoveTransfer={this.handleRemoveTransfer}
        formRefs={formRefs}
        errors={isValidationEnabled ? errors : {}}
      />
    );
  }
}

export default withValidation(CareRecordBodyFormContainer);
