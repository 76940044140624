import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import IconLoad from 'icons/IconLoad';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { catalogueStatuses } from 'constants/catalogue';
import AdminCataloguePackageListItem from 'components/admin/AdminCataloguePackageListItem';
import StatusLookup from 'components/common/lookups/StatusLookup';
import OrganisationalUnitLookup from 'components/common/lookups/OrganisationalUnitLookup';
import ScreenLayout from 'components/common/ScreenLayout';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const AdminCataloguePackagesListScreen = ({
  onChangeFilters,
  packages,
  hasMoreItems,
  isLoading,
  filters,
  onStatusChange,
  fetchPackages,
}) => (
  <ScreenLayout
    title={t('Packages')}
    dashboardBannerProps={{
      breadcrumbs: [{ path: '/', title: t('Dashboard') }, { path: '/catalogue', title: t('Brochure management') }, { path: '/catalogue/packages', title: t('Packages') }],
      description: t('Create and edit funeral packages by adding custom products, services and fees.'),
    }}
  >
    <div className={styles.o_view}>
      <div className={classNames(
        styles.o_block_content,
        styles.o_view__main,
      )}
      >
        <div className={rcStyles.options}>
          <span>
            <Button
              component={Link}
              color="primary"
              variant="outlined"
              to="/catalogue/package/create"
              data-test-id="create"
            >
              {t('New Package')}
            </Button>
          </span>
          <TextField
            className={rcStyles.colspan2}
            value={filters.term}
            onChange={event => onChangeFilters('term', event.target.value, 'debounce')}
            label={t('Search')}
            type="search"
            InputProps={{
              'data-test-id': 'term',
              'startAdornment': (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <OrganisationalUnitLookup
            value={filters.organisationalUnitIds}
            label="Select home"
            onChange={value => onChangeFilters(
              'organisationalUnitIds', value,
            )}
            isMulti
            data-test-id="homeLookup"
          />
          <StatusLookup
            value={filters.status}
            label="Select Status"
            onChange={value => onChangeFilters('status', value && value.value)}
            classes={[rcStyles.rc_lookup]}
            statuses={catalogueStatuses}
            data-test-id="statusLookup"
          />
        </div>
        <div>
          {(isLoading && packages && packages.length === 0)
            && (
              <div className={classNames(
                styles.c_page__full_height,
                styles.has_white_background,
              )}
              >
                <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
                  <IconLoad />
                </div>
              </div>
            )
          }
          <InfiniteScroll
            dataLength={packages.length}
            hasMore={packages.length > 0 && hasMoreItems}
            next={fetchPackages}
            className={styles.u_overflow__hidden}
            loader={packages.length > 0 && (
              <div className={styles.u_fullWidth}>
                <div className={styles.c_svg_loader_infinite_scroll} data-test-id="infiniteScrollLoader">
                  <IconLoad />
                </div>
              </div>
            )}
          >
            {
              packages.map(item => (
                <div
                  className={classNames(
                    item.isNew ? styles.c_flag__PENDING : '',
                  )}
                  data-test-id="packageItem"
                  key={item.id}
                >
                  <AdminCataloguePackageListItem
                    item={item}
                    onStatusChange={onStatusChange}
                  />
                </div>
              ))
            }
          </InfiniteScroll>
        </div>
      </div>
    </div>
  </ScreenLayout>
);

AdminCataloguePackagesListScreen.propTypes = {
  onChangeFilters: PropTypes.func.isRequired,
  packages: PropTypes.arrayOf(PropTypes.shape({})),
  hasMoreItems: PropTypes.bool,
  isLoading: PropTypes.bool,
  filters: PropTypes.objectOf(PropTypes.any),
  onStatusChange: PropTypes.func.isRequired,
  fetchPackages: PropTypes.func.isRequired,
};

export default AdminCataloguePackagesListScreen;
