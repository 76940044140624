import React from 'react';
import i18next from 'i18next';
import Button from '@material-ui/core/Button';

import styles from 'scss/main.scss';
import { Props } from './index.types';

const ImportFromCSVButton: React.FC<Props> = ({
  'data-test-id': testId,
  onClick,
}: Props) => (
  <Button
    className={styles.u_push__right}
    onClick={onClick}
    color="primary"
    variant="contained"
    data-test-id={testId}
  >
    {i18next.t('Import from CSV')}
  </Button>
);

export default ImportFromCSVButton;
