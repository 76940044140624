import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';

import RouteTabContainer from 'components/common/RouteTabContainer';
import DeceasedForm from 'components/bereavement/DeceasedForm';
import CareWishesForm from 'components/bereavement/CareWishesForm';
import CareRecordSection from 'components/bereavement/CareRecordSection';
import PossessionsSection from 'components/bereavement/PossessionsSection';
import DeedsSection from 'components/bereavement/DeedsSection';
import { caseStatuses } from 'constants/bereavement';
import { bereavementType } from 'types/bereavement';
import { matchType } from 'types/reactRouter';
import IconLoad from 'icons/IconLoad';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const tabClasses = {
  root: classNames(rcStyles.tabs),
  tabs: classNames(rcStyles.tabs__floating_indicator),
  tab: classNames(rcStyles.tabs__floating_button),
};

const CaseDeceasedDetails = ({
  bereavement,
  match,
  activeTab,
  isDeceasedFormSaving,
  isCareWishesFormSaving,
  isCareRecordBodyFormSaving,
  onDeceasedFormSave,
  onCareWishesFormSave,
  onCareRecordBodyFormSave,
  onChangeTab,
}) => {
  const deceasedPerson = bereavement.deceasedPeople[0];
  const { deeds } = deceasedPerson;

  return (
    <div className={styles.o_view}>
      <div className={styles.o_view__main}>
        <RouteTabContainer
          classes={tabClasses}
          onChange={value => onChangeTab(value)}
          data-test-id="deceasedTabs"
          tabs={[
            {
              path: `${match.url}/details`,
              label: t('Deceased'),
              component: (
                <Fragment>
                  {isDeceasedFormSaving && (
                    <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
                      <IconLoad title={t('Saving')} />
                    </div>
                  )}
                  <div className={styles.o_block_content}>
                    <DeceasedForm
                      bereavement={bereavement}
                      disabled={isDeceasedFormSaving || bereavement.caseStatus === caseStatuses.CLOSED}
                      onDeceasedFormSave={onDeceasedFormSave}
                    />
                  </div>
                </Fragment>
              ),
            },
            {
              path: `${match.url}/care-wishes`,
              label: t('Care Wishes'),
              component: (
                <Fragment>
                  {isDeceasedFormSaving && (
                    <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
                      <IconLoad title={t('Saving')} />
                    </div>
                  )}
                  <div className={styles.o_block_content}>
                    <CareWishesForm
                      bereavement={bereavement}
                      disabled={isCareWishesFormSaving
                        || bereavement.caseStatus === caseStatuses.CLOSED}
                      onCareWishesFormSave={onCareWishesFormSave}
                    />
                  </div>
                </Fragment>
              ),
            },
            {
              path: `${match.url}/care-record`,
              label: t('Care Record'),
              component: (
                <Fragment>
                  <div className={styles.o_block_content}>
                    <CareRecordSection
                      disabled={isCareRecordBodyFormSaving
                        || bereavement.caseStatus === caseStatuses.CLOSED}
                      onCareRecordBodyFormSave={onCareRecordBodyFormSave}
                      bereavement={bereavement}
                      deceasedPerson={deceasedPerson}
                      activeTab={activeTab}
                    />
                  </div>
                  {isCareRecordBodyFormSaving && (
                    <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
                      <IconLoad title={t('Saving')} />
                    </div>
                  )}
                </Fragment>
              ),
            },
            {
              path: `${match.url}/possessions`,
              label: t('Possessions'),
              component: (
                <Fragment>
                  <div className={styles.o_block_content}>
                    <PossessionsSection
                      possessions={deceasedPerson.possessions}
                      advisoryPossessions={bereavement.advisoryPossessions}
                      bereavedPeopleConnections={bereavement.bereavedPeopleConnections}
                      bereavementId={bereavement.id}
                      deceasedPersonId={deceasedPerson.id}
                      disabled={bereavement.caseStatus === caseStatuses.CLOSED}
                    />
                  </div>
                </Fragment>
              ),
            },
            {
              path: `${match.url}/legal-forms`,
              label: t('Legal & Forms'),
              component: (
                <Fragment>
                  <div className={styles.o_block_content}>
                    <DeedsSection
                      bereavement={bereavement}
                      deceasedPersonId={deceasedPerson.id}
                      deeds={deeds}
                      disabled={bereavement.caseStatus === caseStatuses.CLOSED}
                    />
                  </div>
                </Fragment>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

CaseDeceasedDetails.propTypes = {
  bereavement: bereavementType,
  match: matchType.isRequired,
  activeTab: PropTypes.string.isRequired,
  isDeceasedFormSaving: PropTypes.bool.isRequired,
  isCareWishesFormSaving: PropTypes.bool.isRequired,
  isCareRecordBodyFormSaving: PropTypes.bool.isRequired,
  onDeceasedFormSave: PropTypes.func.isRequired,
  onCareWishesFormSave: PropTypes.func.isRequired,
  onCareRecordBodyFormSave: PropTypes.func.isRequired,
  onChangeTab: PropTypes.func.isRequired,
};

export default CaseDeceasedDetails;
