import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Button from '@material-ui/core/Button';
import ScreenLayout from 'components/common/ScreenLayout';
import DirectoryListingForm from 'components/directoryListings/DirectoryListingForm';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import IconLoad from 'icons/IconLoad';

const AdminDirectoryListingScreen = ({
  directoryListing,
  isLoading,
  onChange,
  onSave,
  onCancel,
  formRefs,
  errors,
}) => (
  <ScreenLayout
    title={t('Directory Listings')}
    dashboardBannerProps={{
      breadcrumbs: [{ path: '/', title: t('Dashboard') }, { path: '/directory-listings', title: t('Directory Listings') }],
      description: t('Manage your business contacts and suppliers'),
    }}
  >
    {isLoading
      ? (
        <div className={classNames(
          styles.c_page__full_height,
          styles.has_faded_background,
        )}
        >
          <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
            <IconLoad />
          </div>
        </div>
      )
      : (
        <div className={styles.o_view}>
          <div className={classNames(
            styles.o_view__main,
            styles.o_block_content,
          )}
          >
            <DirectoryListingForm
              formData={directoryListing}
              onChange={onChange}
              formRefs={formRefs}
              errors={errors}
              data-test-id="directoryListingForm"
            />
            <div className={classNames(
              styles.u_push__top,
              styles.u_fullWidth,
              styles.o_fb,
              styles.o_fb__align_right,
            )}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={onCancel}
                data-test-id="cancelDirectoryListing"
                className={classNames(
                  styles.u_push__right,
                )}
              >
                {t('Cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={onSave}
                data-test-id="saveDirectoryListing"
              >
                {t('Save')}
              </Button>
            </div>
          </div>
        </div>
      )
    }
  </ScreenLayout>
);

AdminDirectoryListingScreen.propTypes = {
  directoryListing: PropTypes.objectOf(PropTypes.any),
  isLoading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any),
};

export default AdminDirectoryListingScreen;
