import React from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';

const TabContainer = ({
  index,
  tabs,
  onChange,
  classes,
  testIds = {},
}) => (
  <div data-test-id="tabContainer" className={classes.root}>
    <Tabs
      value={index}
      onChange={onChange}
      classes={{ indicator: classes.tabs }}
      data-test-id={testIds.tabs || 'tabs'}
      className={classes.tabBar}
    >
      {tabs.map((tab, i) => (
        <Tab
          className={classes.tab}
          key={`tabh-${i}`}
          label={tab.label}
          data-test-id={`${testIds.tabHeader || 'tabContainerHeader'}${i}`}
        />
      ))}
    </Tabs>
    {tabs.map((tab, i) => (
      <div
        key={`tabc-${i}`}
        style={i !== index ? { display: 'none' } : {}}
        data-test-id={`${testIds.tabContents || 'tabContainerContents'}${i}`}
      >
        <div className={classes.tabContent}>
          {tab.component}
        </div>
      </div>
    ))}
  </div>

);

TabContainer.propTypes = {
  index: PropTypes.number.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  testIds: PropTypes.objectOf(PropTypes.any),
};

export default TabContainer;
