class OfflineError extends Error {
  constructor(message) {
    super(message);
    this.name = 'OfflineError';
    this.screenMessage = 'You are offline. Please check your internet connection.';
  }
}

const OfflineScreen = () => {
  throw new OfflineError('You are offline');
};

export default OfflineScreen;
