import React, { Fragment } from 'react';
import { t } from 'i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconAdd from '@material-ui/icons/Add';
import IconLoad from 'icons/IconLoad';
import { buildNameString } from 'services/utils';
import { getCloseAccountConfirmationMessage } from 'services/utils/account';
import { hasConfirmedArrangement } from 'services/utils/bereavement';
import { creditType, invoiceType, paymentType } from 'types/account';
import { bereavementType } from 'types/bereavement';
import { directoryListingType } from 'types/directoryListing';
import ConfirmationModal from 'components/common/ConfirmationModal';
import CloseAccountModal from 'components/account/CloseAccountModal';
import InvoiceGenerationModal from 'components/account/InvoiceGenerationModal';
import AccountAddPaymentModal from 'components/account/AccountAddPaymentModal';
import AccountAddCreditModal from 'components/account/AccountAddCreditModal';
import AccountTotalsSummary from 'components/account/AccountTotalsSummary';
import TransactionRows from 'components/account/TransactionRows';
import AccountCaseSummary from 'components/account/AccountCaseSummary';
import AccountStatusToggleButton from 'components/account/AccountStatusToggleButton';
import ScreenLayout from 'components/common/ScreenLayout';
import NotesDocumentsCorrespondenceGroup from 'components/common/NotesDocumentsCorrespondenceGroup';
import { accountStatuses, accountTransactionType, invoiceTypes } from 'constants/account';
import {
  correspondenceLetterCategories,
} from 'constants/correspondence';
import { AccountConsumer } from 'contexts/account';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const AccountsScreen = ({
  bereavement,
  templateDefinitionsLookup,
  tenantId,
  directoryListings,
  isLoading,
  isModalOpen,
  anchorElements,
  toggleModal,
  toggleGenerateInvoiceModal,
  toggleMenu,
  toggleAccountStatus,
  onConfirmCloseAccount,
  onCancelCloseAccount,
  onAddCredit,
  onAddPayment,
  onDownloadInvoice,
  currentInvoiceDownloading,
  onSendInvoiceTemplateToXero,
  onVoidInvoice,
  invoiceToVoid,
  invoiceBeingEdited,
  onConfirmVoidInvoice,
  onCancelVoidInvoice,
  transactionBeingModified,
  setTransactionBeingModified,
  setTransactionBeingDeleted,
  onViewCase,
  onConfirmDeleteTransaction,
  onDocumentsSave,
  onNotesSave,
  onEditInvoice,
  isMarkingHasBeenSentToClient,
  onMarkHasBeenSentToClient,
  tenantHasGivenConsentToAXeroApp,
  invoiceTemplateSendingToXero,
  targetInvoiceId,
}) => {
  const {
    bereavedPeopleConnections,
    notes,
    account,
    account: {
      documents,
      invoiceTemplates,
      invoices,
      totals,
    },
    accountStatus,
  } = bereavement;
  const deceasedPerson = bereavement.deceasedPeople[0];
  const deceasedName = buildNameString(deceasedPerson.name);
  const dateOfBirth = deceasedPerson.dateOfBirth
    ? moment(deceasedPerson.dateOfBirth).format('MMM DD YYYY')
    : '';
  const dateOfDeath = deceasedPerson.deathDateTime
    ? moment(deceasedPerson.deathDateTime).format('MMM DD YYYY')
    : '';
  const deceasedDateRange = dateOfBirth && dateOfDeath && `${dateOfBirth} - ${dateOfDeath}`;
  const billPayer = bereavedPeopleConnections.find(connection => connection.isBillPayer).bereavedPerson;
  const activeTransactionName = transactionBeingModified.transactionType === accountTransactionType.PAYMENT
    ? 'payment' : 'credit';

  return (
    <AccountConsumer>
      {({ isAccountOpen, hasWritePermissions, hasXeroIntegration }) => (
        <ScreenLayout
          title={t('Accounts')}
          dashboardBannerProps={{
            title: deceasedName,
            breadcrumbs: [{ path: '/', title: t('Dashboard') }, { path: '/accounts', title: 'Account management' }],
            subtitle: (
              <div className={classNames(
                styles.primer,
                styles.is_white,
              )}
              >
                {deceasedDateRange}
              </div>
            ),
            buttons: (
              <NotesDocumentsCorrespondenceGroup
                bereavementId={bereavement.id}
                deceasedPeople={bereavement.deceasedPeople}
                documents={documents}
                notes={notes}
                correspondenceCategory={correspondenceLetterCategories.ACCOUNT}
                templateDefinitionsLookup={templateDefinitionsLookup}
                disabled={!(isAccountOpen && hasWritePermissions)}
                buttonClasses={[rcStyles.utility_buttons]}
                iconClasses={[rcStyles.is_icon]}
                onDocumentsSave={documentsToSave => onDocumentsSave(bereavement.id, documentsToSave)}
                onNotesSave={notesToSave => onNotesSave(bereavement.id, notesToSave)}
              />
            ),
            header: (
              <AccountStatusToggleButton
                status={accountStatus}
                onClick={toggleAccountStatus}
                data-test-id={accountStatus === accountStatuses.OPEN ? 'closeAccount' : 'reopenAccount'}
              />
            ),
            footnote: (
              <AccountTotalsSummary
                totals={totals}
                billPayer={billPayer}
                hasXeroIntegration={hasXeroIntegration}
              />
            ),
          }}
        >
          <div className={styles.o_view}>
            {isLoading ? (
              <div className={classNames(styles.c_page__full_height, styles.has_faded_background)}>
                <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
                  <IconLoad />
                </div>
              </div>
            ) : (
              <div className={styles.o_view__main}>
                <AccountCaseSummary
                  bereavement={bereavement}
                  onViewCase={onViewCase}
                />
                <div className={classNames(
                  rcStyles.v_spacing,
                  styles.o_block_content,
                )}
                >
                  <div className={rcStyles.transaction_summary}>
                    <h2
                      className={classNames(
                        styles.pica_light,
                        styles.is_black,
                      )}
                    >
                      {hasXeroIntegration ? t('Templates') : t('Transactions')}
                    </h2>
                    <div className={rcStyles.actions}>
                      {!hasXeroIntegration && (
                        <Button
                          onClick={event => toggleMenu('newTransactions', event.target)}
                          color="primary"
                          variant="outlined"
                          disabled={!(isAccountOpen && hasWritePermissions)}
                          data-test-id="newTransaction"
                        >
                          <IconAdd />
                          {t('New transaction')}
                        </Button>
                      )}
                      <Menu
                        anchorEl={anchorElements.newTransactions}
                        open={!!anchorElements.newTransactions}
                        onClose={() => toggleMenu('newTransactions', null)}
                      >
                        <MenuItem
                          data-test-id="newTransactionPayment"
                          onClick={(() => {
                            toggleModal('addPayment');
                            toggleMenu('newTransactions', null);
                          })}
                        >
                          {t('Payment')}
                        </MenuItem>
                        <MenuItem
                          data-test-id="newTransactionCredit"
                          onClick={(() => {
                            toggleModal('addCredit');
                            toggleMenu('newTransactions', null);
                          })}
                        >
                          {t('Credit')}
                        </MenuItem>
                      </Menu>
                      <Button
                        onClick={event => toggleMenu('generateInvoice', event.target)}
                        color="primary"
                        variant="contained"
                        disabled={!(isAccountOpen && hasWritePermissions)}
                        data-test-id="generateInvoice"
                      >
                        {t('Generate invoice')}
                      </Button>
                      <Menu
                        id="add-transfer-menu"
                        anchorEl={anchorElements.generateInvoice}
                        open={!!anchorElements.generateInvoice}
                        onClose={() => toggleMenu('generateInvoice', null)}
                      >
                        <MenuItem
                          data-test-id="newInvoice"
                          onClick={(() => {
                            toggleGenerateInvoiceModal(invoiceTypes.INVOICE);
                            toggleMenu('generateInvoice', null);
                          })}
                        >
                          {t('Invoice')}
                        </MenuItem>
                        <MenuItem
                          data-test-id="newProformaInvoice"
                          onClick={(() => {
                            toggleGenerateInvoiceModal(invoiceTypes.PROFORMA);
                            toggleMenu('generateInvoice', null);
                          })}
                        >
                          {t('Proforma')}
                        </MenuItem>
                      </Menu>
                    </div>
                  </div>
                  <TransactionRows
                    bereavementId={bereavement.id}
                    hasConfirmedArrangement={hasConfirmedArrangement(bereavement)}
                    hasXeroIntegration={hasXeroIntegration}
                    account={account}
                    invoiceTemplates={invoiceTemplates}
                    invoices={invoices}
                    toggleAddCreditModal={() => toggleModal('addCredit')}
                    toggleConfirmDeleteModal={() => toggleModal('confirmDelete')}
                    bereavedPeopleConnections={bereavedPeopleConnections}
                    currentInvoiceDownloading={currentInvoiceDownloading}
                    onVoidInvoice={onVoidInvoice}
                    onDownloadInvoice={onDownloadInvoice}
                    onSendInvoiceTemplateToXero={onSendInvoiceTemplateToXero}
                    directoryListings={directoryListings}
                    toggleAddPaymentModal={() => toggleModal('addPayment')}
                    setTransactionBeingModified={setTransactionBeingModified}
                    setTransactionBeingDeleted={setTransactionBeingDeleted}
                    onEditInvoice={onEditInvoice}
                    isMarkingHasBeenSentToClient={isMarkingHasBeenSentToClient}
                    onMarkHasBeenSentToClient={onMarkHasBeenSentToClient}
                    tenantHasGivenConsentToAXeroApp={tenantHasGivenConsentToAXeroApp}
                    invoiceTemplateSendingToXero={invoiceTemplateSendingToXero}
                    targetInvoiceId={targetInvoiceId}
                  />
                </div>
              </div>
            )}
          </div>
          {isModalOpen.generateInvoice && (
            <InvoiceGenerationModal
              invoiceBeingEdited={invoiceBeingEdited}
              hasXeroIntegration={hasXeroIntegration}
              tenantHasGivenConsentToAXeroApp={tenantHasGivenConsentToAXeroApp}
              isOpen={!!isModalOpen.generateInvoice}
              tenantId={tenantId}
              onClose={toggleGenerateInvoiceModal}
              bereavement={bereavement}
              onDownloadInvoice={onDownloadInvoice}
              isSendingToXero={invoiceBeingEdited.id === invoiceTemplateSendingToXero}
              onSendInvoiceTemplateToXero={onSendInvoiceTemplateToXero}
              isDownloadingInvoice={currentInvoiceDownloading !== null}
              directoryListings={directoryListings}
            />
          )}
          {!hasXeroIntegration && (
            <Fragment>
              <AccountAddPaymentModal
                invoices={invoices}
                directoryListings={directoryListings}
                isOpen={!!isModalOpen.addPayment}
                onClose={() => toggleModal('addPayment')}
                onAddPayment={onAddPayment}
                bereavementId={bereavement.id}
                bereavedPeopleConnections={bereavement.bereavedPeopleConnections}
                transactionBeingModified={transactionBeingModified.transactionType === accountTransactionType.PAYMENT
                  ? transactionBeingModified.transaction : null}
                setTransactionBeingModified={setTransactionBeingModified}
              />
              <AccountAddCreditModal
                invoices={invoices}
                isOpen={!!isModalOpen.addCredit}
                onSave={onAddCredit}
                onClose={() => toggleModal('addCredit')}
                transactionBeingModified={transactionBeingModified.transactionType === accountTransactionType.CREDIT
                  ? transactionBeingModified.transaction : null}
                setTransactionBeingModified={setTransactionBeingModified}
              />
            </Fragment>
          )}
          <CloseAccountModal
            message={getCloseAccountConfirmationMessage(totals)}
            isOpen={!!isModalOpen.closeAccount}
            onConfirm={onConfirmCloseAccount}
            onCancel={onCancelCloseAccount}
            testIds={{
              dialog: 'accountClosureModal',
              title: 'accountClosureTitle',
              message: 'accountClosureMessage',
              cancel: 'cancelAccountClose',
              confirm: 'confirmAccountClose',
            }}
          />
          <ConfirmationModal
            title={t('Void invoice')}
            message={t('Please note: If you void this invoice, the balance outstanding on the account will be updated. Do you still wish to void this invoice?')}
            isOpen={!!invoiceToVoid}
            onConfirm={onConfirmVoidInvoice}
            onCancel={onCancelVoidInvoice}
          />
          <ConfirmationModal
            title={transactionBeingModified.transactionType === accountTransactionType.PAYMENT
              ? t('Delete payment')
              : t('Delete credit')}
            message={t(`Please note: If you delete this ${activeTransactionName}, the balance outstanding on the account will be updated. Do you still wish to delete this ${activeTransactionName}?`)}
            isOpen={!!isModalOpen.confirmDelete}
            onConfirm={onConfirmDeleteTransaction}
            testIds={{
              confirm: transactionBeingModified.transactionType === accountTransactionType.PAYMENT
                ? 'confirmDeletePayment'
                : 'confirmDeleteCredit',
              cancel: transactionBeingModified.transactionType === accountTransactionType.PAYMENT
                ? 'cancelDeletePayment'
                : 'cancelDeleteCredit',
              message: transactionBeingModified.transactionType === accountTransactionType.PAYMENT
                ? 'confirmPaymentDeletionMessage'
                : 'confirmCreditDeletionMessage',
            }}
            onCancel={() => {
              setTransactionBeingModified();
              toggleModal('confirmDelete');
            }}
          />
        </ScreenLayout>
      )}
    </AccountConsumer>
  );
};

AccountsScreen.propTypes = {
  bereavement: bereavementType.isRequired,
  templateDefinitionsLookup: PropTypes.objectOf(PropTypes.any),
  tenantId: PropTypes.string.isRequired,
  directoryListings: PropTypes.arrayOf(directoryListingType),
  isLoading: PropTypes.bool,
  currentInvoiceDownloading: invoiceType,
  invoiceToVoid: invoiceType,
  invoiceBeingEdited: invoiceType,
  anchorElements: PropTypes.objectOf(PropTypes.any).isRequired,
  isModalOpen: PropTypes.objectOf(PropTypes.any).isRequired,
  toggleGenerateInvoiceModal: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  toggleAccountStatus: PropTypes.func.isRequired,
  onConfirmCloseAccount: PropTypes.func.isRequired,
  onCancelCloseAccount: PropTypes.func.isRequired,
  onAddCredit: PropTypes.func.isRequired,
  onAddPayment: PropTypes.func.isRequired,
  onDownloadInvoice: PropTypes.func.isRequired,
  onSendInvoiceTemplateToXero: PropTypes.func.isRequired,
  onVoidInvoice: PropTypes.func.isRequired,
  onConfirmVoidInvoice: PropTypes.func.isRequired,
  onCancelVoidInvoice: PropTypes.func.isRequired,
  transactionBeingModified: PropTypes.shape({
    transactionType: PropTypes.string,
    transaction: PropTypes.oneOfType([creditType, paymentType]),
  }),
  setTransactionBeingModified: PropTypes.func,
  onViewCase: PropTypes.func.isRequired,
  setTransactionBeingDeleted: PropTypes.func,
  onConfirmDeleteTransaction: PropTypes.func.isRequired,
  onDocumentsSave: PropTypes.func.isRequired,
  onNotesSave: PropTypes.func.isRequired,
  onEditInvoice: PropTypes.func.isRequired,
  isMarkingHasBeenSentToClient: PropTypes.bool.isRequired,
  onMarkHasBeenSentToClient: PropTypes.func.isRequired,
  tenantHasGivenConsentToAXeroApp: PropTypes.bool.isRequired,
  invoiceTemplateSendingToXero: PropTypes.string,
  targetInvoiceId: PropTypes.string,
};

export default AccountsScreen;
