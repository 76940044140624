import mapActionToReducer from 'redux-action-reducer-mapper';
import {
  SetAnonymousUserAction,
  SetHomeAction,
  SetIsPortalActiveAction,
  SetTenantAction,
  AddCatalogueItemsAction,
  SetCatalogueItemsLoadingAction,
  PortalAction,
  SetCataloguePackagesLoadingAction,
  AddCataloguePackagesAction,
} from 'actions/portal.types';
import { OrderDirection } from 'constants/ts';
import { CatalogueItemOrderStrategy } from 'constants/ts/catalogue';
import { PortalState } from './portal.types';

export const initialState: PortalState = {
  isPortalActive: false,
  user: null,
  home: null,
  tenant: null,
  catalogueItems: {
    items: [],
    type: null,
    category: null,
    isLoading: false,
    pagination: {
      first: 50,
      after: null,
      hasNextPage: false,
    },
    order: {
      direction: OrderDirection.DESCENDING,
      strategy: CatalogueItemOrderStrategy.CATALOGUE_ORDER,
    },
  },
  cataloguePackages: {
    items: [],
    isLoading: false,
    pagination: {
      first: 50,
      after: null,
      hasNextPage: false,
    },
    order: {
      direction: OrderDirection.DESCENDING,
      strategy: CatalogueItemOrderStrategy.CATALOGUE_ORDER,
    },
  },
};

const setIsPortalActive = (state: PortalState, action: SetIsPortalActiveAction): PortalState => ({
  ...state,
  isPortalActive: action.payload,
});

const setAnonymousUser = (state: PortalState, action: SetAnonymousUserAction) => {
  const { payload } = action;

  if (payload) {
    return {
      ...state,
      user: {
        ...payload,
      },
    };
  }

  return state;
};

const setHome = (state: PortalState, action: SetHomeAction): PortalState => ({
  ...state,
  home: action.payload,
});

const setTenant = (state: PortalState, action: SetTenantAction): PortalState => ({
  ...state,
  tenant: action.payload,
});

const setCatalogueItemsLoading = (state: PortalState, action: SetCatalogueItemsLoadingAction): PortalState => ({
  ...state,
  catalogueItems: {
    ...state.catalogueItems,
    isLoading: action.payload,
  },
});

const addCatalogueItems = (state: PortalState, action: AddCatalogueItemsAction): PortalState => {
  const { catalogueItems: existingCatalogueItems } = state;
  const { type: existingType, category: existingCategory } = existingCatalogueItems;
  const {
    items, category, order, pagination, type,
  } = action.payload;

  const isAdditive = existingType === type && existingCategory === category;

  const updatedItems = isAdditive ? [
    ...existingCatalogueItems.items,
    ...items,
  ] : [...items];

  return {
    ...state,
    catalogueItems: {
      ...state.catalogueItems,
      category,
      items: updatedItems,
      order,
      pagination,
      type,
    },
  };
};

const resetCatalogueItems = (state: PortalState): PortalState => ({
  ...state,
  catalogueItems: {
    ...initialState.catalogueItems,
  },
});

const setCataloguePackagesLoading = (state: PortalState, action: SetCataloguePackagesLoadingAction): PortalState => ({
  ...state,
  cataloguePackages: {
    ...state.cataloguePackages,
    isLoading: action.payload,
  },
});

const addCataloguePackages = (state: PortalState, action: AddCataloguePackagesAction): PortalState => {
  const {
    items, order, pagination,
  } = action.payload;

  const updatedItems = [
    ...(state.cataloguePackages.items || []),
    ...items,
  ];

  return {
    ...state,
    cataloguePackages: {
      ...state.cataloguePackages,
      items: updatedItems,
      order,
      pagination,
    },
  };
};

const resetCataloguePackages = (state: PortalState): PortalState => ({
  ...state,
  cataloguePackages: {
    ...initialState.cataloguePackages,
  },
});

export const portalReducer = mapActionToReducer({
  'default': initialState,
  [PortalAction.SET_IS_PORTAL_ACTIVE]: setIsPortalActive,
  [PortalAction.SET_ANONYMOUS_USER]: setAnonymousUser,
  [PortalAction.SET_HOME]: setHome,
  [PortalAction.SET_TENANT]: setTenant,
  [PortalAction.SET_CATALOGUE_ITEMS_LOADING]: setCatalogueItemsLoading,
  [PortalAction.ADD_CATALOGUE_ITEMS]: addCatalogueItems,
  [PortalAction.RESET_CATALOGUE_ITEMS]: resetCatalogueItems,
  [PortalAction.SET_CATALOGUE_PACKAGES_LOADING]: setCataloguePackagesLoading,
  [PortalAction.ADD_CATALOGUE_PACKAGES]: addCataloguePackages,
  [PortalAction.RESET_CATALOGUE_PACKAGES]: resetCataloguePackages,
  [PortalAction.RESET_PORTAL_STORE]: () => initialState,
});

export default portalReducer;
