import React, { Fragment } from 'react';
import { t } from 'i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import DatePicker from 'components/common/DatePicker';
import DateTimePicker from 'components/common/DateTimePicker';
import MeasurementField from 'components/common/MeasurementField';
import { committalTypes } from 'constants/arrangement';
import { detailsFieldType } from 'constants/task';
import styles from 'scss/main.scss';
import { textForCategory } from 'services/utils/task';
import { buildNameString } from 'services/utils';
import { taskType } from 'types/task';

const renderField = (detailsField) => {
  switch (detailsField.type) {
    case detailsFieldType.BOOLEAN:
      return (
        <FormControlLabel
          control={(
            <Checkbox
              checked={detailsField.value || false}
              inputProps={{ 'data-test-id': detailsField.key }}
              color="primary"
              disabled
            />
          )}
          label={detailsField.label}
        />
      );
    case detailsFieldType.DATE:
      return (
        <DatePicker
          value={detailsField.value}
          label={detailsField.label}
          disabled
          data-test-id="datepicker-task"
        />
      );
    case detailsFieldType.DATETIME:
      return (
        <DateTimePicker
          value={detailsField.value}
          label={detailsField.label}
          disabled
          data-test-id="datepicker-task"
        />
      );
    case detailsFieldType.MEASUREMENT:
      return (
        <MeasurementField
          label={detailsField.label}
          value={detailsField.value || null}
          units={detailsField.unit}
          disabled
          data-test-id="measurement-task"
        />
      );
    case detailsFieldType.TEXT:
      return (
        <TextField
          label={detailsField.label}
          value={detailsField.value || ''}
          fullWidth
          inputProps={{
            'data-test-id': detailsField.key,
          }}
          InputProps={{
            readOnly: true,
          }}
        />
      );
    default:
      return null;
  }
};

const TaskDetails = ({
  task,
  serviceData,
}) => (
  <Fragment>
    <h3 className={styles.long_primer}>
      {t('Task details')}
    </h3>
    <div key="TASK_DECEASED_NAME">
      <TextField
        label={t('Deceased')}
        value={buildNameString(task.bereavement && task.bereavement.deceasedPeople[0].name)}
        fullWidth
        inputProps={{
          'data-test-id': 'TASK_DECEASED_NAME',
        }}
        InputProps={{
          readOnly: true,
        }}
      />
    </div>
    <div key="TASK_CATEGORY">
      <TextField
        label={t('Category')}
        value={textForCategory(task.category) || ''}
        fullWidth
        inputProps={{
          'data-test-id': 'TASK_CATEGORY',
        }}
        InputProps={{
          readOnly: true,
        }}
      />
    </div>
    {serviceData && (
      <div key="SERVICE_TIME">
        <TextField
          label={t(`${serviceData.committalType === committalTypes.CREMATION ? 'Cremation' : 'Burial'}`)}
          value={moment(serviceData.startDateTime).format('DD MMMM YYYY - HH:mm')}
          fullWidth
          inputProps={{
            'data-test-id': 'SERVICE_TIME',
          }}
          InputProps={{
            readOnly: true,
          }}
        />
      </div>
    )}
    {task.detailsJson && task.detailsJson.map(detailsField => (
      <div key={detailsField.key}>
        {renderField(detailsField)}
      </div>
    ))}
  </Fragment>
);

TaskDetails.propTypes = {
  task: taskType.isRequired,
  serviceData: PropTypes.shape({
    committalType: PropTypes.string.isRequired,
    startDateTime: PropTypes.string,
  }),
};

export default TaskDetails;
