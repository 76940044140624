import React from 'react';
import i18next from 'i18next';
import CheckIcon from '@material-ui/icons/Check';

import ExpandableText from 'components/common/ExpandableText';
import { formatCurrency } from 'services/utils';

import { Props } from './index.types';
import Styled from './index.styled';

const CataloguePackageCard: React.FC<Props> = ({ item }: Props) => {
  const { price, products = [], services = [] } = item;
  const displayPrice = formatCurrency(price);

  return (
    <Styled.Card
      data-test-id="cataloguePackageCard"
    >
      <div className="card_detail">
        <div className="card_detail__title">
          {item.name}
        </div>
        <div className="card_detail__price">
          {displayPrice}
        </div>
        <h3 className="card_detail__package_includes">
          {i18next.t('Package includes')}
        </h3>
        <ul className="card_detail__package_items">
          {products?.map(product => (
            <li
              key={product.id}
              className="card_detail__package_item"
            >
              <div className="card_detail__package_item__check">
                <CheckIcon />
              </div>
              <div>
                {product.title}
              </div>
            </li>
          ))}
          {services?.map(service => (
            <li
              key={service.id}
              className="card_detail__package_item"
            >
              <div className="card_detail__package_item__check">
                <CheckIcon />
              </div>
              <div>
                {service.title}
              </div>
            </li>
          ))}
        </ul>
        <div className="card_detail__description">
          <ExpandableText text={item?.description} clamp={50} />
        </div>
      </div>
    </Styled.Card>
  );
};

export default CataloguePackageCard;
