import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { emptyBereavedPerson } from 'constants/bereavement';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { bereavedPersonConnectionType } from 'types/bereavement';
import PayeeFormModal from './PayeeFormModal';
import { validationSchema } from './validation';

class PayeeFormModalContainer extends Component {
  static propTypes = {
    payee: bereavedPersonConnectionType,
    isEditingPayee: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    generateRefs: PropTypes.func.isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isOpen
      && nextProps.payee
      && nextProps.payee.bereavedPerson
      && nextProps.payee.bereavedPerson.id !== prevState.formData.bereavedPerson.id
    ) {
      return {
        ...prevState,
        formData: nextProps.payee,
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      isValidationEnabled: false,
      formData: props.payee || emptyBereavedPerson,
    };

    this.dialogRef = React.createRef();
  }

  componentDidMount() {
    const { generateRefs } = this.props;
    generateRefs(Object.keys(validationSchema.fields));
  }

  handleChange = (property, value) => {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [property]: value,
      },
    }));
  }

  handleChangeBereavedPerson = (property, value) => {
    const { validate } = this.props;
    const { formData, isValidationEnabled } = this.state;
    const newState = {
      formData: {
        ...formData,
        bereavedPerson: {
          ...formData.bereavedPerson,
          [property]: value,
        },
      },
    };

    if (isValidationEnabled) {
      validate(newState.formData.bereavedPerson, validationSchema);
    }
    this.setState(newState);
  }

  handleSave = () => {
    const { onSave, onClose, validate } = this.props;
    const { formData } = this.state;
    this.setState({ isValidationEnabled: true });
    const isValid = validate(formData.bereavedPerson, validationSchema, true, this.dialogRef);

    if (isValid) {
      onSave(formData);
      onClose();
    }
  }

  handleClose = () => {
    const { onClose } = this.props;

    this.setState({ formData: emptyBereavedPerson });
    onClose();
  }

  render() {
    const {
      isOpen, isSaving, formRefs, errors, isEditingPayee,
    } = this.props;
    const { formData } = this.state;

    return (
      <PayeeFormModal
        bereavedPersonConnection={formData}
        isOpen={isOpen}
        isEditingPayee={isEditingPayee}
        isSaving={isSaving}
        onChangeBereavedPerson={this.handleChangeBereavedPerson}
        onChange={this.handleChange}
        onSave={this.handleSave}
        onClose={this.handleClose}
        dialogRef={this.dialogRef}
        formRefs={formRefs}
        errors={errors}
      />
    );
  }
}

export default withValidation(PayeeFormModalContainer);
