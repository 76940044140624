import { v4 as uuidv4 } from 'uuid';
import { createHomeTransform, editHomeTransform } from 'transforms/home';
import { organisationalUnitTypes } from 'constants/organisationalUnits';
import { getOrganisationalUnitGroups } from './homeGroups.queries.gql';
import { createOrganisationalUnit, editOrganisationalUnit } from './homes.mutations.gql';

export const homeGroupsActions = {
  CLEAR_ALL: 'HOMEGROUP_CLEAR_ALL',
  CLEAR_HOMEGROUPS: 'HOMEGROUP_CLEAR_HOMEGROUPS',
  SET_HOMEGROUPS: 'HOMEGROUP_SET_HOMEGROUPS',
  ADD_HOMEGROUPS: 'HOMEGROUP_ADD_HOMEGROUPS',
  ADD_HOMEGROUP: 'HOMEGROUP_ADD_HOMEGROUP',
  UPDATE_HOMEGROUP: 'HOMEGROUP_UPDATE_HOMEGROUP',
  REMOVE_HOMEGROUP: 'HOMEGROUP_REMOVE_HOMEGROUP',
  UPDATE_FILTERS: 'HOMEGROUP_UPDATE_FILTERS',
  SET_LOADING: 'HOMEGROUP_SET_LOADING',
};

export const clearAllAction = () => (
  { type: homeGroupsActions.CLEAR_ALL }
);

export const clearHomeGroupsAction = () => (
  { type: homeGroupsActions.CLEAR_HOMEGROUPS }
);

export const setHomeGroupsAction = payload => (
  { type: homeGroupsActions.SET_HOMEGROUPS, payload }
);

export const addHomeGroupAction = payload => (
  { type: homeGroupsActions.ADD_HOMEGROUP, payload }
);

export const addHomeGroupsAction = payload => (
  { type: homeGroupsActions.ADD_HOMEGROUPS, payload }
);

export const updateHomeGroupAction = payload => (
  { type: homeGroupsActions.UPDATE_HOMEGROUP, payload }
);

export const removeHomeGroupAction = payload => (
  { type: homeGroupsActions.REMOVE_HOMEGROUP, payload }
);

export const updateFiltersAction = (key, value) => (
  { type: homeGroupsActions.UPDATE_FILTERS, payload: { key, value } }
);

export const setLoadingAction = payload => (
  { type: homeGroupsActions.SET_LOADING, payload }
);

export const fetchHomeGroupsAction = filters => (dispatch, getState, client) => {
  dispatch(setLoadingAction(true));
  if (filters) {
    dispatch(clearHomeGroupsAction());
  }

  const { pagination } = getState().homeGroupsStore;
  const { searchTerm } = filters || getState().homeGroupsStore.filters;

  const query = client.query({
    query: getOrganisationalUnitGroups,
    variables: {
      term: searchTerm || null,
      pagination: {
        first: pagination.first,
        after: pagination.after,
      },
    },
  }).then(({ data, errors }) => {
    if (data === null && errors.length > 0) {
      return;
    }

    const { organisationalUnits } = data;
    const homeGroups = organisationalUnits.edges.map(edge => edge.node);
    const updatedPagination = {
      first: pagination.first,
      after: organisationalUnits.pageInfo.endCursor,
      hasNextPage: organisationalUnits.pageInfo.hasNextPage,
    };

    const payload = { homeGroups, pagination: updatedPagination, filters };
    dispatch(addHomeGroupsAction(payload));
  }).finally(() => {
    dispatch(setLoadingAction(false));
  });
  return query;
};

export const createHomeGroupAction = homeGroup => (dispatch, getState, client) => {
  const homeGroupTransformed = createHomeTransform(homeGroup);
  homeGroupTransformed.id = uuidv4();
  homeGroupTransformed.type = organisationalUnitTypes.GROUP;
  client.mutate({
    mutation: createOrganisationalUnit,
    variables: { input: homeGroupTransformed },
  });
  dispatch(addHomeGroupAction(homeGroupTransformed));
};

export const editHomeGroupAction = homeGroup => (dispatch, getState, client) => {
  const homeGroupTransformed = editHomeTransform(homeGroup);
  const mutation = client.mutate({
    mutation: editOrganisationalUnit,
    variables: { input: homeGroupTransformed },
  }).then(({ data }) => {
    if (data) {
      const { organisationalUnits } = data;
      const { editOrganisationalUnit: editOrganisationalUnitResult } = organisationalUnits;
      dispatch(updateHomeGroupAction(editOrganisationalUnitResult.organisationalUnit));
    }
  });
  dispatch(updateHomeGroupAction(homeGroupTransformed));
  return mutation;
};
