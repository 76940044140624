import React, { Fragment } from 'react';
import { t } from 'i18next';
import { Helmet } from 'react-helmet';
import { MuiThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import overrideTheme from 'services/themes/overrideTheme';
import Button from '@material-ui/core/Button';
import LiteArrangementForm from 'components/lite/LiteArrangementForm';
import LiteClientForm from 'components/lite/LiteClientForm';
import { organisationalUnitType } from 'types/organisationalUnit';
import IconLoad from 'icons/IconLoad';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const backgroundImage = 'url(/img/dignity_background/landscape-background.jpg)';
const backgroundImageStyles = {
  background: backgroundImage,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  height: '100%',
};

const LiteArrangementScreen = ({
  bereavement,
  organisationalUnits,
  shouldDisplaySplashScreenLogo,
  onSave,
  onCancel,
  onStart,
  isNewBereavement,
  step,
  isLoading,
}) => {
  const deceasedName = bereavement && bereavement.deceasedPeople ? bereavement.deceasedPeople[0].name : {};

  return (
    <MuiThemeProvider theme={overrideTheme}>
      <Helmet>
        <title>{t(isNewBereavement ? 'Create arrangement' : 'Edit arrangement')}</title>
      </Helmet>
      <div
        className={classNames(
          styles.c_page__full_height,
        )}
        style={backgroundImageStyles}
      >
        <div className={rcStyles.page_layout}>
          {step === 0 && (
            <Fragment>
              {isLoading ? (
                <div className={styles.has_background}>
                  <div className={styles.c_loading_container}>
                    <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
                      <IconLoad />
                    </div>
                  </div>
                </div>
              ) : (
                <LiteArrangementForm
                  bereavement={bereavement}
                  organisationalUnits={organisationalUnits}
                  isNewBereavement={isNewBereavement}
                  isLoading={isLoading}
                  onSave={onSave}
                  onCancel={onCancel}
                />
              )}
            </Fragment>
          )}
          {step === 1 && (
            <Fragment>
              <LiteClientForm
                bereavement={bereavement}
                isNewBereavement={isNewBereavement}
                onSave={onSave}
                onCancel={onCancel}
              />
            </Fragment>
          )}
          {step === 2 && (
            <div className={rcStyles.content_block}>
              <div>
                {shouldDisplaySplashScreenLogo && (
                  <img src="/img/showcase_logo.svg" width="300" alt="Dignity Showcase" />
                )}
              </div>
              <div className={classNames(
                styles.pica_light,
                styles.u_push__bottom,
              )}
              >
                {t('Celebrating the life of')}
                <br />
                {`${deceasedName.givenName} ${deceasedName.familyName}`}
              </div>
              <div>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={onStart}
                  data-test-id="startArrangement"
                >
                  {t('Start')}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </MuiThemeProvider>
  );
};

LiteArrangementScreen.propTypes = {
  bereavement: PropTypes.objectOf(PropTypes.any),
  organisationalUnits: PropTypes.arrayOf(organisationalUnitType).isRequired,
  shouldDisplaySplashScreenLogo: PropTypes.bool.isRequired,
  isNewBereavement: PropTypes.bool.isRequired,
  step: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onStart: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default LiteArrangementScreen;
