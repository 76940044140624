import { isNullOrUndefined } from 'services/utils';

export const addressTransform = (address) => {
  if (isNullOrUndefined(address)) {
    return null;
  }

  const { __typename, ...otherAddressProps } = address;
  return otherAddressProps;
};
