import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { invoiceType } from 'types/account';
import InvoiceBuilder from './InvoiceBuilder';

export class InvoiceBuilderContainer extends Component {
  static propTypes = {
    invoice: invoiceType.isRequired,
    updateSections: PropTypes.func.isRequired,
    onAddCustomCharge: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      isCustomItemModalOpen: false,
      addCustomItemAnchorElement: null,
    };
  }

  handleSelectSection = (sectionName, isSelected) => {
    const { invoice, updateSections } = this.props;
    const updatedSections = invoice.sections.map((section) => {
      const updatedSection = { ...section };
      if (section.title === sectionName) {
        updatedSection.items = section.items.map(item => ({
          ...item,
          isSelected,
        }));
      }
      return updatedSection;
    });
    updateSections(updatedSections);
  }

  handleSelectItem = (sectionName, index, isSelected) => {
    const { invoice, updateSections } = this.props;
    const updatedSections = invoice.sections.map((section) => {
      const updatedSection = { ...section };
      if (section.title === sectionName) {
        updatedSection.items[index] = { ...updatedSection.items[index], isSelected };
      }
      return updatedSection;
    });
    updateSections(updatedSections);
  }

  handleEditSectionItem = (sectionName, updatedItem, index) => {
    const { invoice, updateSections } = this.props;
    const updatedSections = invoice.sections.map((section) => {
      const updatedSection = { ...section };
      if (section.title === sectionName) {
        updatedSection.items[index] = updatedItem;
      }
      return updatedSection;
    });
    updateSections(updatedSections);
  }

  toggleAddCustomItemMenu = (target) => {
    this.setState({ addCustomItemAnchorElement: target });
  }

  toggleCustomItemModal = () => {
    this.setState(prevState => ({
      isCustomItemModalOpen: !prevState.isCustomItemModalOpen,
    }));
  }

  render() {
    const {
      invoice,
      onAddCustomCharge,
    } = this.props;
    const {
      isCustomItemModalOpen,
      addCustomItemAnchorElement,
    } = this.state;

    return (
      <div>
        <InvoiceBuilder
          sections={invoice.sections}
          total={invoice.total}
          isCustomItemModalOpen={isCustomItemModalOpen}
          addCustomItemAnchorElement={addCustomItemAnchorElement}
          onEditSectionItem={this.handleEditSectionItem}
          onSelectSection={this.handleSelectSection}
          onSelectItem={this.handleSelectItem}
          toggleCustomItemModal={this.toggleCustomItemModal}
          toggleAddCustomItemMenu={this.toggleAddCustomItemMenu}
          onAddCustomCharge={onAddCustomCharge}
        />
      </div>
    );
  }
}

export default InvoiceBuilderContainer;
