import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import DownloadIcon from '@material-ui/icons/GetApp';

import IconLoad from 'icons/IconLoad';
import { constructCorrespondenceTestUploadId } from 'services/utils/testIds';

import styles from 'scss/main.scss';

const CorrespondenceRow = ({
  bereavementId,
  template,
  isLoading,
  'data-test-id': testId,
  onDownload,
}) => (
  <div className={styles.o_list__item}>
    {template.title}
    <div className={styles.o_list_item__secondary}>
      {isLoading
        ? (
          <div className={styles.c_svg_loader_list}>
            <IconLoad />
          </div>
        )
        : (
          <IconButton
            data-test-id={testId}
            data-test-correspondence-uri={template.key && bereavementId
              && constructCorrespondenceTestUploadId(template.key, bereavementId)}
            onClick={() => onDownload(template)}
            color="primary"
            className={classNames(
              styles.o_button__round,
              styles.o_button__round__small,
              styles.u_hard,
              styles.o_button__secondary,
            )}
          >
            <DownloadIcon />
          </IconButton>
        )
      }
    </div>
  </div>
);

CorrespondenceRow.propTypes = {
  'bereavementId': PropTypes.string.isRequired,
  'template': PropTypes.objectOf(PropTypes.any).isRequired,
  'isLoading': PropTypes.bool.isRequired,
  'data-test-id': PropTypes.string.isRequired,
  'onDownload': PropTypes.func.isRequired,
};

export default CorrespondenceRow;
