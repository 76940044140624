import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import Select from 'components/common/Select';
import DynamicList from 'components/common/DynamicList';
import MusicSelectionFormSection from 'components/arrangement/MusicSelectionFormSection';
import { serviceTypes } from 'constants/bereavement';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';


const MusicFormSection = ({ fieldData, disabled, onChange }) => (
  <Fragment>
    <FormGroup>
      <div className={rcStyles.inline_inputs}>
        <div>
          <Select
            value={fieldData.serviceType}
            onChange={event => onChange('serviceType', event.target.value)}
            name="servicetype"
            label={t('Service')}
            inputProps={{ 'data-test-id': 'serviceType' }}
            disabled={disabled}
          >
            {serviceTypes.map((serviceType, index) => (
              <MenuItem key={index} value={serviceType.value}>{t(serviceType.name)}</MenuItem>
            ))}
          </Select>
        </div>
        <TextField
          label={t('Reference number')}
          value={fieldData.wesleyRequestReference || ''}
          onChange={event => onChange('wesleyRequestReference', event.target.value)}
          inputProps={{
            'data-test-id': 'wesleyRequestReference',
          }}
          disabled={disabled}
          fullWidth
        />
      </div>
    </FormGroup>
    <DynamicList
      isStacked
      component={<MusicSelectionFormSection className={styles.u_hard} />}
      addButtonInner={
        (
          <div className={styles.o_fb__inline}>
            <AddIcon />
            {t('Add Selection')}
          </div>
        )
      }
      addButtonProps={{
        variant: 'outlined',
        color: 'primary',
        size: 'medium',
      }}
      removeButtonProps={{
        variant: 'outlined',
        color: 'primary',
        size: 'small',
        classes: { root: styles.o_button__round },
      }}
      removeButtonInner={<RemoveIcon />}
      dataList={fieldData.selections}
      onChange={selections => onChange('selections', selections)}
      disabled={disabled}
      fullWidth
      data-test-id="musicSelections"
    />
  </Fragment>
);

MusicFormSection.propTypes = {
  fieldData: PropTypes.objectOf(PropTypes.any).isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default MusicFormSection;
