import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { Helmet } from 'react-helmet';
import { MuiThemeProvider } from '@material-ui/core/styles';
import overrideTheme from 'services/themes/overrideTheme';
import ReactBigCalendar from 'react-big-calendar';
import CalendarBookEventModal from 'components/calendar/CalendarBookEventModal';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import CalendarEvent from 'components/calendar/CalendarEvent';
import CalendarToolbar from 'components/calendar/CalendarToolbar';
import { calendarEventTypes, serviceEventTypes } from 'constants/calendar';
import { homeType } from 'types/bereavement';
import IconLoad from 'icons/IconLoad';

import classNames from 'classnames';
import './react-big-calendar-override.scss?raw';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const buttonClasses = {
  root: rcStyles.rc_nav__button,
};

moment.locale('ko', {
  week: {
    dow: 1,
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const localizer = ReactBigCalendar.momentLocalizer(moment);

const Calendar = ({
  isCalendarOpen,
  isBookEventModalOpen,
  isLoading,
  isRunSheetLoading,
  isEventModalOpen,
  isEditingEvent,
  onNavigate,
  onViewChange,
  onOpenRunSheet,
  onChangeFilters,
  onToggleCalendar,
  onToggleBookEventModal,
  onSaveCustomEvent,
  onDeleteCustomEvent,
  onChangeCustomEvent,
  onOpenEvent,
  onOpenCase,
  homes,
  currentDate,
  currentView,
  calendarEvents,
  organisationalUnitId,
  onCloseEventModal,
  selectedEvent,
  renderEventContent,
}) => {
  const isCortege = (
    selectedEvent.__typename === calendarEventTypes.CortegeBereavementCalendarEvent
  );
  const isService = (
    selectedEvent.__typename === calendarEventTypes.ServiceBereavementCalendarEvent
  );

  return (
    <MuiThemeProvider theme={overrideTheme}>
      {isCalendarOpen && (
        <Helmet>
          <title>{`Calendar - ${currentDate}`}</title>
        </Helmet>
      )}
      <Button
        onClick={onToggleCalendar}
        data-test-id="openCalendar"
        classes={buttonClasses}
      >
        {t('Calendar')}
      </Button>
      <Dialog
        fullScreen={false}
        open={isEventModalOpen}
        onClose={onCloseEventModal}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="sm"
        TransitionComponent={Transition}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle
          id="responsive-dialog-title"
          disableTypography
          className={classNames(
            styles.c_dialog__header,
            styles.u_soft__ends,
            styles.u_push__bottom_double,
            selectedEvent && `c_dialog__header__${selectedEvent.title.toLowerCase()}`,
          )}
        >
          <div className={classNames(
            styles.long_primer,
            styles.is_white,
          )}
          >
            {selectedEvent.title}
          </div>
          <div className={classNames(
            styles.primer,
            styles.is_white,
          )}
          >
            {isCortege && selectedEvent.isHearse && 'Hearse'}
            {isCortege && !selectedEvent.isHearse && 'Passengers'}
            {isService && serviceEventTypes[selectedEvent.eventType]}
          </div>
        </DialogTitle>
        <DialogContent
          className={classNames(
            styles.c_dialog__content,
            styles.u_hard__bottom,
          )}
        >
          {selectedEvent && renderEventContent(selectedEvent)}
        </DialogContent>
        <DialogActions className={rcStyles.actions}>
          <Button
            onClick={onCloseEventModal}
            variant="contained"
            color="secondary"
            data-test-id="closeEventModal"
          >
            {t('Close')}
          </Button>
          <Button
            onClick={onOpenCase}
            variant="contained"
            color="primary"
            autoFocus
            data-test-id="openCase"
          >
            {t('Open the funeral')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen
        onClose={onCloseEventModal}
        open={isCalendarOpen}
        aria-labelledby="responsive-dialog-title"
      >
        <Fragment>
          <ReactBigCalendar
            localizer={localizer}
            defaultDate={currentDate}
            defaultView={currentView}
            components={{
              event: eventProps => (
                <CalendarEvent
                  {...eventProps}
                  view={currentView}
                />
              ),
              toolbar: toolbarProps => (
                <CalendarToolbar
                  {...toolbarProps}
                  isLoading={isLoading}
                  homes={homes}
                  calendarEvents={calendarEvents}
                  onOpenRunSheet={onOpenRunSheet}
                  onChangeFilters={onChangeFilters}
                  onToggleCalendar={onToggleCalendar}
                  onToggleBookEventModal={onToggleBookEventModal}
                  isRunSheetLoading={isRunSheetLoading}
                  organisationalUnitId={organisationalUnitId}
                />
              ),
            }}
            timeslots={2}
            showMultiDayTimes
            onSelectEvent={onOpenEvent}
            onView={onViewChange}
            onNavigate={onNavigate}
            events={calendarEvents}
            style={{ height: '100vh' }}
            views={{ week: true, day: true }}
          />
          {isLoading && (
            <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
              <IconLoad />
            </div>
          )}
        </Fragment>
      </Dialog>
      <CalendarBookEventModal
        isOpen={isBookEventModalOpen}
        onClose={onToggleBookEventModal}
        onSave={onSaveCustomEvent}
        onDelete={onDeleteCustomEvent}
        onChangeCustomEvent={onChangeCustomEvent}
        homes={homes}
        selectedEvent={selectedEvent}
        isEditing={isEditingEvent}
      />
    </MuiThemeProvider>
  );
};

Calendar.propTypes = {
  isCalendarOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isRunSheetLoading: PropTypes.bool.isRequired,
  isBookEventModalOpen: PropTypes.bool.isRequired,
  isEventModalOpen: PropTypes.bool.isRequired,
  isEditingEvent: PropTypes.bool.isRequired,
  onOpenRunSheet: PropTypes.func.isRequired,
  onChangeFilters: PropTypes.func.isRequired,
  onToggleCalendar: PropTypes.func.isRequired,
  onToggleBookEventModal: PropTypes.func.isRequired,
  onChangeCustomEvent: PropTypes.func,
  onNavigate: PropTypes.func.isRequired,
  onViewChange: PropTypes.func.isRequired,
  onOpenEvent: PropTypes.func.isRequired,
  onCloseEventModal: PropTypes.func.isRequired,
  onOpenCase: PropTypes.func.isRequired,
  onSaveCustomEvent: PropTypes.func.isRequired,
  onDeleteCustomEvent: PropTypes.func.isRequired,
  homes: PropTypes.arrayOf(homeType).isRequired,
  currentDate: PropTypes.instanceOf(Date).isRequired,
  currentView: PropTypes.string.isRequired,
  selectedEvent: PropTypes.objectOf(PropTypes.any).isRequired,
  renderEventContent: PropTypes.func.isRequired,
  organisationalUnitId: PropTypes.string,
  calendarEvents: PropTypes.arrayOf(
    PropTypes.shape({
      bereavementId: PropTypes.string,
      start: PropTypes.instanceOf(Date).isRequired,
      end: PropTypes.instanceOf(Date).isRequired,
      __typename: PropTypes.string,
    }),
  ).isRequired,
};

export default Calendar;
