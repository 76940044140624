import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';

import {
  addClientAction,
  editClientAction,
  createBereavedPersonAction,
  editBereavedPersonAction,
} from 'actions/bereavement';
import { payeeTypes } from 'constants/account';
import { generateHexId } from 'services/utils';
import { getInvoicePayee } from 'services/utils/account';

import PayeeDetails from './PayeeDetails';

export class PayeeDetailsContainer extends Component {
  static propTypes = {
    bereavedPeopleConnections: PropTypes.arrayOf(PropTypes.any),
    directoryListings: PropTypes.arrayOf(PropTypes.any),
    bereavementId: PropTypes.string.isRequired,
    addClient: PropTypes.func.isRequired,
    editClient: PropTypes.func.isRequired,
    createBereavedPerson: PropTypes.func.isRequired,
    editBereavedPerson: PropTypes.func.isRequired,
    payeeId: PropTypes.string,
    payeeType: PropTypes.string,
    onChangeInvoicePayee: PropTypes.func.isRequired,
    onPayeeFormUpdate: PropTypes.func.isRequired,
    setIsInvoiceDirty: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any),
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.bereavedPeopleConnections && prevState.selectedBereaved) {
      const updatedConnection = nextProps.bereavedPeopleConnections
        .find(connection => connection.id === prevState.selectedBereaved.id);
      return {
        selectedBereaved: updatedConnection,
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedBereaved: null,
      selectedDirectoryListing: null,
      isPayeeListModalOpen: false,
      isPayeeFormModalOpen: false,
      isEditingPayee: false,
      formData: {},
    };
  }

  componentDidMount() {
    this.setInitialPayee();
  }

  setInitialPayee = () => {
    const {
      bereavedPeopleConnections,
      directoryListings,
      payeeId,
      payeeType,
    } = this.props;
    const payee = getInvoicePayee(bereavedPeopleConnections, directoryListings, payeeId, payeeType);
    this.handlePayeeChange(
      payee,
      payeeType || payeeTypes.BEREAVED_PERSON_CONNECTION,
      false,
    );
  }

  handlePayeeChange = (payee, payeeType, isUserInput = true) => {
    const { onChangeInvoicePayee } = this.props;

    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        payeeType,
      },
      selectedBereaved: payeeType === payeeTypes.BEREAVED_PERSON_CONNECTION ? payee : null,
      selectedDirectoryListing: payeeType === payeeTypes.DIRECTORY_LISTING ? payee : null,
    }));

    onChangeInvoicePayee(payee.id, payeeType, isUserInput);
  }

  handleCreateBereavedPerson = (bereavedPersonConnection) => {
    const {
      createBereavedPerson,
      bereavementId,
      addClient,
      editClient,
    } = this.props;
    const newConnectionId = generateHexId();
    const newBereavedPersonConnection = { ...bereavedPersonConnection, id: newConnectionId };

    addClient(bereavementId, newBereavedPersonConnection);
    createBereavedPerson({
      newBereavedPersonConnection,
      bereavementId,
      onCreateBereavedPersonCallback: (bereaved) => {
        this.handlePayeeChange(
          bereaved,
          payeeTypes.BEREAVED_PERSON_CONNECTION,
        );
        editClient(bereavementId, { ...bereaved, id: newConnectionId });
      },
    });
  }

  handleEditBereavedPerson = (bereavedPersonConnection) => {
    const {
      bereavementId,
      editClient,
      editBereavedPerson,
      setIsInvoiceDirty,
      onPayeeFormUpdate,
    } = this.props;

    editClient(bereavementId, bereavedPersonConnection);
    editBereavedPerson(bereavementId, bereavedPersonConnection);
    setIsInvoiceDirty(true);

    if (onPayeeFormUpdate) {
      onPayeeFormUpdate(bereavedPersonConnection?.bereavedPerson);
    }
  }

  togglePayeeFormModal = (isEditingPayee) => {
    this.setState(prevState => ({
      isPayeeFormModalOpen: !prevState.isPayeeFormModalOpen,
      isEditingPayee,
    }));
  }

  togglePayeeListModal = () => {
    this.setState(prevState => ({
      isPayeeListModalOpen: !prevState.isPayeeListModalOpen,
    }));
  }

  render() {
    const { bereavedPeopleConnections, errors } = this.props;
    const {
      selectedBereaved,
      selectedDirectoryListing,
      isPayeeListModalOpen,
      isPayeeFormModalOpen,
      formData,
      isEditingPayee,
    } = this.state;

    return (
      <PayeeDetails
        isEditingPayee={isEditingPayee}
        selectedBereaved={selectedBereaved}
        bereavedPeopleConnections={bereavedPeopleConnections}
        selectedDirectoryListing={selectedDirectoryListing}
        isPayeeListModalOpen={isPayeeListModalOpen}
        isPayeeFormModalOpen={isPayeeFormModalOpen}
        togglePayeeFormModal={this.togglePayeeFormModal}
        togglePayeeListModal={this.togglePayeeListModal}
        formData={formData}
        errors={errors}
        onPayeeChange={this.handlePayeeChange}
        createBereavedPerson={this.handleCreateBereavedPerson}
        editBereavedPerson={this.handleEditBereavedPerson}
      />
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  createBereavedPerson: createBereavedPersonAction,
  editBereavedPerson: editBereavedPersonAction,
  addClient: addClientAction,
  editClient: editClientAction,
}, dispatch);

export default withApollo(
  withRouter(connect(null, mapDispatchToProps)(PayeeDetailsContainer)),
);
