import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import AddCustomItemModal from './AddCustomItemModal';
import { validationSchema } from './validation';

class AddCustomItemModalContainer extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    hasXeroIntegration: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    generateRefs: PropTypes.func.isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      formData: {
        title: null,
        description: null,
        amount: null,
        taxBand: 'ZERO',
        isDisbursement: false,
      },
      isValidationEnabled: false,
    };

    this.dialogRef = React.createRef();
  }

  componentDidMount() {
    const { generateRefs } = this.props;
    generateRefs(Object.keys(validationSchema.fields));
  }

  handleSave = () => {
    const { onSave, validate } = this.props;
    const { formData, isValidationEnabled } = this.state;

    if (!isValidationEnabled) {
      this.setState({ isValidationEnabled: true });
    }

    const isValid = validate(formData, validationSchema, true, this.dialogRef);
    if (isValid) {
      onSave(formData);
      this.handleClose();
    }
  }

  handleClose = () => {
    const { onClose } = this.props;
    this.setState({
      formData: {
        taxBand: 'ZERO',
        isDisbursement: false,
      },
      isValidationEnabled: false,
    });
    onClose();
  }

  handleChange = (key, value) => {
    const { validate } = this.props;
    const { formData } = this.state;
    const newFormData = {
      ...formData,
      [key]: value,
    };
    validate(newFormData, validationSchema);
    this.setState({ formData: newFormData });
  }

  render() {
    const {
      isOpen,
      hasXeroIntegration,
      errors,
      formRefs,
    } = this.props;
    const { formData, isValidationEnabled } = this.state;
    return (
      <AddCustomItemModal
        formData={formData}
        isOpen={isOpen}
        hasXeroIntegration={hasXeroIntegration}
        onCancel={this.handleClose}
        onSave={this.handleSave}
        onChange={this.handleChange}
        dialogRef={this.dialogRef}
        formRefs={formRefs}
        errors={isValidationEnabled ? errors : {}}
      />
    );
  }
}

export default withValidation(AddCustomItemModalContainer);
