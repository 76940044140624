import {
  removePropertyByName, generateHexId, defaultMoneyObject,
} from 'services/utils';
import { discountTypes } from 'constants/arrangement';
import { transferTypes } from 'constants/bereavement';
import { accountStatuses } from 'constants/account';

export const transferMutationTransform = (transfer) => {
  const { __typename, ...updatedTransfer } = transfer;
  return updatedTransfer;
};

export const deceasedPersonTransferIntoCareTransform = (deceasedPerson) => {
  const { ...updatedDeceasedPerson } = deceasedPerson;
  if (!deceasedPerson.isReadyForCollection) {
    updatedDeceasedPerson.transfersIntoCare = null;
  } else if (updatedDeceasedPerson.transfersIntoCare) {
    const { transfersIntoCare } = updatedDeceasedPerson;
    updatedDeceasedPerson.transfersIntoCare = transfersIntoCare.map(transferIntoCare => (
      {
        id: generateHexId(),
        ...transferIntoCare,
      }
    ));
  }
  return updatedDeceasedPerson;
};

export const addBereavementTransform = (bereavement) => {
  const { bereavedPeopleConnections, deceasedPeople } = bereavement;
  const hasPrimaryContact = bereavedPeopleConnections
    .find(connection => connection.isPrimaryContact);
  const hasBillPayer = bereavedPeopleConnections
    .find(connection => connection.isBillPayer);

  if (!hasPrimaryContact) {
    bereavedPeopleConnections[0].isPrimaryContact = true;
  }
  if (!hasBillPayer) {
    bereavedPeopleConnections[0].isBillPayer = true;
  }

  const updatedDeceasedPeople = deceasedPeople
    && deceasedPeople.map((deceasedPerson) => {
      if (deceasedPerson.isReadyForCollection) {
        const { transfersIntoCare, ...deceasedPersonWithoutTransfersIntoCare } = deceasedPerson;
        const updatedTransfersIntoCare = transfersIntoCare && transfersIntoCare.map(transferIntoCare => ({
          ...transferIntoCare,
          __typename: 'TransferIntoCare',
        }));
        return {
          ...deceasedPersonWithoutTransfersIntoCare,
          transfers: updatedTransfersIntoCare || null,
        };
      }
      return deceasedPerson;
    });

  return {
    ...bereavement,
    deceasedPeople: updatedDeceasedPeople,
    accountStatus: accountStatuses.OPEN,
    bereavedPeopleConnections,
  };
};

export const createBereavementTransform = (bereavement) => {
  const bereavedPeopleConnections = bereavement.bereavedPeopleConnections.map(bereaved => ({
    id: bereaved.id,
    bereavedPersonId: bereaved.bereavedPerson.id,
    relationshipToDeceased: bereaved.relationshipToDeceased,
    isPrimaryContact: bereaved.isPrimaryContact,
    isBillPayer: bereaved.isBillPayer,
    isNextOfKin: bereaved.isNextOfKin,
    contactPreferences: bereaved.contactPreferences,
  }));

  const clinicians = bereavement.clinicians.map(clinician => ({
    name: clinician.name,
    directoryListingId: clinician.directoryListingId,
  }));

  const notes = bereavement.notes.map(note => ({
    id: note.id,
    content: note.content,
    category: note.category,
  }));

  let appointment = null;
  if (bereavement.appointment) {
    appointment = { ...bereavement.appointment };
    delete appointment.staffMember;
  }

  return {
    id: bereavement.id,
    deceasedPeople: bereavement.deceasedPeople,
    bereavedPeopleConnections,
    clinicians,
    coronerDirectoryId: bereavement.coronerDirectoryId,
    coronerName: bereavement.coronerName,
    firstCallType: bereavement.firstCallType,
    homeId: bereavement.homeId,
    ownerId: bereavement.ownerId,
    appointment,
    advisoryPossessions: bereavement.advisoryPossessions,
    advisoryCommittalType: bereavement.advisoryCommittalType,
    sendEmailsToBereavedConsent: bereavement.sendEmailsToBereavedConsent,
    notes,
  };
};

export const editBereavementTransform = (bereavement) => {
  let appointment = null;
  if (bereavement.appointment) {
    appointment = { ...bereavement.appointment };
    delete appointment.staffMember;
  }

  return {
    id: bereavement.id,
    clinicians: bereavement.clinicians && bereavement.clinicians.map(clinician => ({
      name: clinician.name,
      directoryListingId: clinician.directoryListingId,
    })),
    coronerDirectoryId: bereavement.coronerDirectoryId,
    coronerName: bereavement.coronerName,
    firstCallType: bereavement.firstCallType,
    advisoryPossessions: bereavement.advisoryPossessions,
    advisoryCommittalType: bereavement.advisoryCommittalType,
    ownerId: bereavement.ownerId,
    homeId: bereavement.homeId,
    appointment,
    sendEmailsToBereavedConsent: bereavement.sendEmailsToBereavedConsent,
  };
};

export const editDeceasedTransform = (deceased, bereavementId) => {
  const { transfers, ...deceasedPerson } = deceased;
  delete deceasedPerson.possessions;
  delete deceasedPerson.deeds;
  delete deceasedPerson.image;
  delete deceasedPerson.ashesRecords;

  const getTransfersByType = type => transfers && transfers
    .filter(transfer => transfer.__typename === type)
    .map(transfer => transferMutationTransform(transfer));

  const externalTransfers = getTransfersByType(transferTypes.EXTERNAL_TRANSFER) || [];
  const internalTransfers = getTransfersByType(transferTypes.INTERNAL_TRANSFER) || [];
  const transfersIntoCare = getTransfersByType(transferTypes.TRANSFER_INTO_CARE) || [];

  const updatedDeceasedPerson = {
    ...deceasedPerson,
    externalTransfers,
    internalTransfers,
    transfersIntoCare,
    isReadyForCollection: transfersIntoCare && transfersIntoCare.length > 0,
  };

  return {
    ...updatedDeceasedPerson,
    bereavementId,
  };
};

export const editBereavementCareWishesTransform = (bereavement, careWishes) => {
  const deceasedPeople = bereavement && bereavement.deceasedPeople;
  return {
    ...bereavement,
    deceasedPeople: deceasedPeople.map((deceasedPerson, index) => {
      if (index === 0) {
        return {
          ...deceasedPerson,
          firstOffices: {
            ...deceasedPerson.firstOffices,
            doNotPerform: careWishes.doNotPerform,
            reasonNotToPerform: careWishes.reasonNotToPerform,
          },
          shouldCaptureFingerPrint: careWishes.shouldCaptureFingerPrint,
          shouldCollectLockOfHair: careWishes.shouldCollectLockOfHair,
          isFamilyToPrepareDeceased: careWishes.isFamilyToPrepareDeceased,
          isMCCDIssued: careWishes.isMCCDIssued,
          reasonForNoMCCDIssued: careWishes.reasonForNoMCCDIssued,
          MCCDNumber: careWishes.MCCDNumber,
          isInfectious: careWishes.isInfectious,
        };
      }
      return deceasedPerson;
    }),
    clinicians: careWishes.clinicians || [],
    coronerName: (careWishes.coroner && careWishes.coroner.name) || null,
    coronerDirectoryId: (careWishes.coroner && careWishes.coroner.directoryListingId) || null,
    coronerDirectoryListing: (careWishes.coroner && careWishes.coroner.directoryListing) || null,
  };
};

export const editDeceasedCareRecordTransform = (
  deceased,
  viewingNotAdvised,
  reasonForViewingNotAdvised,
  firstOffices,
  mortuary,
  transfers,
) => (
  {
    ...deceased,
    viewingNotAdvised,
    reasonForViewingNotAdvised,
    firstOffices,
    mortuary,
    transfers,
  }
);

export const editBereavedPersonTransform = (bereavedPerson) => {
  const result = bereavedPerson;
  delete result.timeCreated;
  return result;
};

export const bereavedPersonConnectionForLiteTransform = (bereavedConnection) => {
  const result = {
    ...bereavedConnection,
    bereavedPersonId: bereavedConnection.bereavedPerson.id,
  };
  delete result.bereavedPerson;
  delete result.newlyCreated;

  return result;
};

export const bereavedPersonConnectionTransform = (bereaved, bereavementId) => {
  const result = {
    bereavementId,
    ...bereaved,
    bereavedPersonId: bereaved.bereavedPerson.id,
  };
  delete result.bereavedPerson;
  delete result.newlyCreated;

  return result;
};

const bereavementQueryResponseTransform = (bereavement) => {
  const updatedBereavement = removePropertyByName(
    bereavement,
    '__typename',
    false,
    [
      transferTypes.EXTERNAL_TRANSFER,
      transferTypes.INTERNAL_TRANSFER,
      transferTypes.TRANSFER_INTO_CARE,
    ],
  );
  const { deceasedPeople } = updatedBereavement;

  const updatedDeceasedPeople = deceasedPeople && deceasedPeople.map((deceasedPerson) => {
    const { transfers } = deceasedPerson;
    const updatedTransfers = transfers && transfers.map((transfer) => {
      const {
        externalFromLocation,
        internalFromLocation,
        transferIntoCareFromLocation,
        externalToLocation,
        internalToLocation,
        transferIntoCareToLocation,
        ...updatedTransfer
      } = transfer;
      return {
        ...updatedTransfer,
        fromLocation: externalFromLocation || internalFromLocation || transferIntoCareFromLocation,
        toLocation: externalToLocation || internalToLocation || transferIntoCareToLocation,
      };
    });
    return {
      ...deceasedPerson,
      transfers: updatedTransfers,
    };
  });

  return {
    ...updatedBereavement,
    deceasedPeople: updatedDeceasedPeople,
  };
};

export const getBereavementResult = ({ bereavement }) => bereavementQueryResponseTransform(bereavement);

export const getBereavementsResult = (bereavements, isConcise = false) => (
  bereavements
    .map(({ node }) => {
      if (isConcise) {
        return {
          ...bereavementQueryResponseTransform(node),
          isConcise: true,
        };
      }
      return bereavementQueryResponseTransform(node);
    })
);

export const createBereavedPersonResult = (response) => {
  const { bereavedPerson } = response.data.bereaved.createBereavedPerson;
  return removePropertyByName(bereavedPerson, '__typename');
};

export const editBereavedPersonResult = (response) => {
  const { bereavedPerson } = response.data.bereaved.editBereavedPerson;
  return removePropertyByName(bereavedPerson, '__typename');
};

export const createLiteBereavementTransform = (bereavementData, id) => {
  const bereavement = {
    id,
    deceasedPeople: [{
      name: bereavementData.deceasedName,
      id: generateHexId(),
    }],
    bereavedPeopleConnections: [{
      id: generateHexId(),
      bereavedPersonId: bereavementData.bereavedPerson.id,
      relationshipToDeceased: null,
      isPrimaryContact: true,
      isBillPayer: true,
      isNextOfKin: false,
      contactPreferences: null,
      bereavedPerson: bereavementData.bereavedPerson,
    }],
    arrangements: [{
      id: generateHexId(),
      committalType: bereavementData.committalType,
      type: bereavementData.type,
      reference: bereavementData.arrangementReference,
      discountType: discountTypes.NONE,
      calculation: {
        finalTotal: defaultMoneyObject(),
        discounts: defaultMoneyObject(),
        totalWithoutDiscounts: defaultMoneyObject(),
        totalDisbursements: defaultMoneyObject(),
      },
    }],
    homeId: bereavementData.homeId,
    home: bereavementData.home,
  };

  return bereavement;
};

export const editLiteBereavementTransform = (bereavementData) => {
  const bereavement = bereavementData;
  bereavement.deceasedPeople[0].name = bereavementData.deceasedName;
  bereavement.arrangements[0].reference = bereavementData.arrangementReference;
  delete bereavement.deceasedName;
  delete bereavement.arrangementReference;

  return bereavement;
};

export const createLiteBereavementMutationInputTransform = bereavement => ({
  bereavementId: bereavement.id,
  arrangementId: bereavement.arrangements[0].id,
  deceasedPersonId: bereavement.deceasedPeople[0].id,
  homeId: bereavement.homeId,
  deceasedName: bereavement.deceasedPeople[0].name,
  arrangementReference: bereavement.arrangements[0].reference,
  committalType: bereavement.arrangements[0].committalType,
  type: bereavement.arrangements[0].type,
  bereavedPeopleConnections: [bereavedPersonConnectionForLiteTransform(bereavement.bereavedPeopleConnections[0])],
});

export const editLiteBereavementMutationInputTransform = bereavement => ({
  bereavementId: bereavement.id,
  arrangementId: bereavement.arrangements[0].id,
  deceasedPersonId: bereavement.deceasedPeople[0].id,
  deceasedName: bereavement.deceasedPeople[0].name,
  arrangementReference: bereavement.arrangements[0].reference,
  bereavedPeopleConnections: [bereavedPersonConnectionForLiteTransform(bereavement.bereavedPeopleConnections[0])],
});

export const saveNotesInputTransform = notes => notes.map(note => ({
  id: note.id,
  content: note.content,
  category: note.category,
}));

export const saveDocumentsInputTransform = documents => documents.map(document => ({
  id: document.id,
  title: document.title,
  mediaId: document.mediaId,
}));

export const saveAshesRecordInputTransform = ashesRecord => ({
  ...ashesRecord,
  actions: ashesRecord.actions && ashesRecord.actions.length
    ? ashesRecord.actions
      .filter(({ isNew }) => isNew)
      .map(({ actionTaken, locationScattered, returnToClientMethod }) => ({
        actionTaken,
        locationScattered,
        returnToClientMethod,
      }))
    : [],
});

export const saveAshesRecordActionsTransform = actions => (actions && actions.length
  ? actions
    .filter(action => !!action.actionTaken)
    .map(({ isNew, ...action }) => ({
      ...action,
    }))
  : []);

export const saveDeedInputTransform = deed => ({
  ...deed,
  actions: deed.actions && deed.actions.length
    ? deed.actions
      .map(action => ({
        id: action.id,
        action: action.action,
      }))
    : [],
  documents: deed.documents && deed.documents.length
    ? deed.documents
      .map(({ media, ...otherDocumentProps }) => ({
        ...otherDocumentProps,
      }))
    : [],
});

export const saveDeedActionsTransform = actions => (actions && actions.length
  ? actions
    .filter(action => !!action.action)
    .map(action => ({
      ...action,
      id: action.id || generateHexId(),
    }))
  : []);

export const saveDeedDocumentsTransform = documents => (documents && documents.length
  ? documents
    .map(document => ({
      ...document,
      id: document.id || generateHexId(),
    }))
  : []);
