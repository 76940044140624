import React, { Component } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import queryString from 'query-string';
import ScreenLayout from 'components/common/ScreenLayout';
import PageLoader from 'components/common/PageLoader';
import { setXeroConsentAction } from 'actions/user';
import { featureFlags } from 'constants/features';
import { apolloClientType } from 'types/apollo';
import { historyType } from 'types/reactRouter';
import { supplyXeroAuthorisationCodeMutation } from './mutations.gql';

class XeroAuthenticationScreenContainer extends Component {
  static propTypes = {
    tenantId: PropTypes.string.isRequired,
    tenantHasGivenConsentToAXeroApp: PropTypes.bool.isRequired,
    availableFeatures: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    xeroStateCode: PropTypes.string.isRequired,
    client: apolloClientType.isRequired,
    history: historyType.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    setXeroConsent: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const {
      tenantId,
      tenantHasGivenConsentToAXeroApp,
      availableFeatures,
      xeroStateCode,
      history,
      client,
      enqueueSnackbar,
      setXeroConsent,
    } = this.props;

    if (tenantHasGivenConsentToAXeroApp) {
      history.push('/');
      return;
    }

    const hasXeroIntegration = availableFeatures.includes(featureFlags.XERO_INTEGRATION);
    const queryStrings = queryString.parse(window.location.search);
    const authorisationCode = queryStrings.code;
    const stateCode = queryStrings.state;

    if (hasXeroIntegration && authorisationCode && stateCode === xeroStateCode) {
      client.mutate({
        mutation: supplyXeroAuthorisationCodeMutation,
        variables: {
          input: {
            id: tenantId,
            authorisationCode,
            redirectUri: window.location.href.split('?')[0],
          },
        },
      }).then(() => {
        setXeroConsent(true);
        history.push('/');
      });

      return;
    }

    if (hasXeroIntegration) {
      this.triggerXeroAuthenticationFlow();
      return;
    }

    enqueueSnackbar(
      t('Xero Integration is disabled.'),
      { variant: 'error' },
    );
  }

  triggerXeroAuthenticationFlow = () => {
    const { xeroStateCode } = this.props;
    const xeroUrl = `${process.env.XERO_AUTHENTICATION_URL}?response_type=code&client_id=${process.env.XERO_CLIENT_ID}&redirect_uri=${window.location.href}&scope=offline_access accounting.transactions accounting.settings accounting.contacts&state=${xeroStateCode}`;
    window.location.href = xeroUrl;
  };

  render() {
    return (
      <ScreenLayout
        title="Xero Authentication"
        dashboardBannerProps={{
          breadcrumbs: [{ path: '/', title: t('Dashboard') }, { path: '/xero-authentication', title: 'Xero Authentication' }],
          description: t('Perform authentication with Xero invoicing platform'),
        }}
      >
        <PageLoader
          isLoading
        />
      </ScreenLayout>
    );
  }
}

const mapStateToProps = state => ({
  tenantId: state.userStore.user.tenantId,
  tenantHasGivenConsentToAXeroApp: state.userStore.user.tenantHasGivenConsentToAXeroApp,
  availableFeatures: state.userStore.user.tenantFeatureFlags,
  xeroStateCode: state.userStore.user.xeroStateCode,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setXeroConsent: setXeroConsentAction,
}, dispatch);

export default withSnackbar(
  withRouter(
    withApollo(
      connect(mapStateToProps, mapDispatchToProps)(XeroAuthenticationScreenContainer),
    ),
  ),
);
