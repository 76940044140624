import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { journeyType } from 'types/bereavement';

import MenuItem from '@material-ui/core/MenuItem';
import Select from 'components/common/Select';
import AddressFormSection from 'components/common/AddressFormSection';
import DateTimePicker from 'components/common/DateTimePicker';

import rcStyles from './styles.scss';


const VehicleJourney = (props) => {
  const journeyTypes = [
    { value: 'TO', label: 'To' },
    { value: 'FROM', label: 'From' },
    { value: 'MEETING', label: 'Meeting' },
    { value: 'VIA', label: 'Route via' },
    { value: 'PICK_UP', label: 'Pick up' },
  ];

  const {
    journey,
    errors,
    disabled,
    onAddressChange,
    onSelectTypeChange,
    onDatePickerChange,
  } = props;

  return (
    <div className={rcStyles.journey__item_main}>
      <div className={rcStyles.is_type}>
        <Select
          fullWidth
          name="journeyType"
          disabled={disabled}
          value={journey.type}
          label={t('Journey type')}
          onChange={onSelectTypeChange}
          inputProps={{ 'data-test-id': 'journeyType' }}
        >
          {journeyTypes.map(journeyTypeItem => (
            <MenuItem
              value={journeyTypeItem.value}
              key={`journey-type-${journeyTypeItem.value}`}
            >
              {t(journeyTypeItem.label)}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className={rcStyles.is_destination}>
        <AddressFormSection
          disabled={disabled}
          data-test-id="address"
          address={journey.location}
          onChange={onAddressChange}
          errors={errors && errors.location}
        />
      </div>
      <div className={rcStyles.is_time}>
        <DateTimePicker
          value={journey.dateTime}
          label={t('Date & time')}
          name="journeyDateTime"
          onChange={onDatePickerChange}
          isClearable
          disabled={disabled}
          data-test-id="dateTime"
          className={rcStyles.journey_time}
        />
      </div>
    </div>
  );
};

VehicleJourney.propTypes = {
  journey: journeyType.isRequired,
  errors: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool.isRequired,
  onAddressChange: PropTypes.func.isRequired,
  onSelectTypeChange: PropTypes.func.isRequired,
  onDatePickerChange: PropTypes.func.isRequired,
};

export default VehicleJourney;
