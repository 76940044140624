import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import LoginTheme from 'services/themes/loginTheme';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SupportFooter from 'components/support/SupportFooter';
import IconLoad from 'icons/IconLoad';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const ResetPasswordScreen = ({
  passwordFormData,
  token,
  isSubmitting,
  isSubmitted,
  errors,
  passwordRef,
  onPasswordChange,
  onPasswordSave,
}) => {
  if (!token) {
    return (
      <div>
        Invalid token
      </div>
    );
  }

  return (
    <MuiThemeProvider theme={LoginTheme}>
      <div
        className={classNames(
          styles.c_page__full_height,
          rcStyles.background_color,
        )}
      >
        <div className={styles.o_view}>
          <div className={styles.o_view__main}>
            <div className={rcStyles.logo}>
              <img src="/img/login_logo.svg" alt="Arranger" />
            </div>
            {isSubmitted ? (
              <div className={rcStyles.v_spacing}>
                <div className={styles.long_primer}>
                  {t('Password successfully reset.')}
                </div>
                <div className={styles.u_text__center}>
                  <Button
                    component={Link}
                    to="/login"
                    color="secondary"
                    variant="contained"
                    className={classNames(styles.o_button__white)}
                    data-test-id="returnToLogin"
                    disabled={isSubmitting}
                  >
                    {t('Return to Login')}
                  </Button>
                </div>
              </div>
            ) : (
              <form onSubmit={onPasswordSave}>
                <div className={rcStyles.v_spacing}>
                  <TextField
                    type="password"
                    label={t('Password')}
                    data-test-id="password"
                    value={passwordFormData.password}
                    InputProps={{
                      'inputRef': passwordRef,
                    }}
                    onChange={event => onPasswordChange('password', event.target.value)}
                    error={!!errors.password}
                    helperText={errors.password}
                    fullWidth
                  />
                  <TextField
                    type="password"
                    label={t('Confirm password')}
                    data-test-id="confirmPassword"
                    value={passwordFormData.confirmPassword}
                    onChange={event => onPasswordChange('confirmPassword', event.target.value)}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword}
                    fullWidth
                  />
                  <div className={styles.u_text__center}>
                    <Button
                      color="primary"
                      variant="contained"
                      className={classNames(styles.o_button__white)}
                      onClick={onPasswordSave}
                      data-test-id="setNewPassword"
                      disabled={isSubmitting}
                    >
                      {t('Set New Password')}
                    </Button>
                  </div>
                  {isSubmitting && (
                    <div className={classNames(styles.c_svg_loader_big)}>
                      <IconLoad title={t('Submitting')} />
                    </div>
                  )}
                </div>
              </form>
            )}
          </div>
        </div>
        <SupportFooter />
      </div>
    </MuiThemeProvider>
  );
};

ResetPasswordScreen.propTypes = {
  passwordFormData: PropTypes.shape({
    password: PropTypes.string.isRequired,
    confirmPassword: PropTypes.string.isRequired,
  }).isRequired,
  token: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  passwordRef: PropTypes.objectOf(PropTypes.any).isRequired,
  onPasswordChange: PropTypes.func.isRequired,
  onPasswordSave: PropTypes.func.isRequired,
};

export default ResetPasswordScreen;
