import { arrangementPaths } from 'constants/arrangement';
import { scopes as permissions } from 'constants/scopes';

export const BEREAVEMENTS_PER_PAGE = 12;

export const caseStatuses = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
};

export const emptyBereavement = {
  arrangements: [],
  deceasedPeople: [{
    name: {},
    collectionLocation: null,
    address: null,
    isReadyForCollection: false,
    isInfectious: false,
    transfersIntoCare: [{
      startDateTime: null,
      endDateTime: null,
      fromLocation: null,
      toLocation: null,
      isComplete: false,
      instructions: null,
      isPossessionsChecked: false,
      isIdentificationChecked: false,
      completionNotes: null,
      receivedBy: null,
    }],
  }],
  bereavedPeopleConnections: [],
  advisoryCommittalType: null,
  advisoryPossessions: null,
  clinicians: [],
  coronerDirectoryId: null,
  coronerName: null,
  appointment: null,
  home: null,
  staff: [],
  firstCallType: 'AT_NEED',
  caseStatus: caseStatuses.OPEN,
  notes: [],
  sendEmailsToBereavedConsent: false,
  account: {
    credits: null,
    payments: null,
    historicFuneralAccountCharge: null,
    historicFuneralAccountTransferDateTime: null,
    invoices: [],
    totals: {
      balance: { amount: 0, currency: 'GBP' },
      totalCredit: { amount: 0, currency: 'GBP' },
      totalInvoiced: { amount: 0, currency: 'GBP' },
      totalUnInvoiced: { amount: 0, currency: 'GBP' },
      totalPaid: { amount: 0, currency: 'GBP' },
      totalInvoiceTemplatesInvoiced: { amount: 0, currency: 'GBP' },
      totalInvoiceTemplatesUninvoiced: { amount: 0, currency: 'GBP' },
    },
  },
};

export const emptyBereavedPerson = {
  bereavedPerson: {
    name: {},
    address: null,
    phones: [],
    emails: [],
    note: '',
  },
  relationshipToDeceased: '',
  isPrimaryContact: false,
  isBillPayer: false,
  isNextOfKin: false,
  contactPreferences: [],
};

export const emptyDeceasedPerson = {
  name: {},
  collectionLocation: null,
  address: null,
  isReadyForCollection: false,
  isInfectious: false,
  firstOffices: {},
  mortuary: {},
  transfersIntoCare: [{
    startDateTime: null,
    endDateTime: null,
    fromLocation: null,
    toLocation: null,
    isComplete: false,
    instructions: null,
    isPossessionsChecked: false,
    isIdentificationChecked: false,
    completionNotes: null,
    receivedBy: null,
  }],
  NINumber: null,
  NHSNumber: null,
};

export const relationshipsToDeceased = [
  'Mother',
  'Father',
  'Son',
  'Daughter',
  'Aunt',
  'Uncle',
  'Brother',
  'Sister',
  'Grandparent',
  'Grandson',
  'Granddaughter',
  'Spouse',
  'Partner',
  'Inlaw',
];

export const faiths = [
  'Christianity',
  'Catholic',
  'Islam',
  'Hinduism',
  'Sikhism',
  'Judaism',
  'Buddhism',
  'None',
];

export const nationalities = [
  'British',
  'Caribbean',
  'Chinese',
  'Irish (Republic)',
  'French',
  'German',
  'Greek',
  'Polish',
  'Indian',
  'Italian',
  'Lithuanian',
  'Pakistani',
  'Portuguese',
  'Romanian',
  'Spanish',
];

export const reasonsForNoMCCDIssued = [
  'Awaiting',
  'Post Mortem report issued',
  'Coroner\'s burial / cremation certificate issued',
];

export const placesOfDeath = [
  'Hospice',
  'Hospital',
  'Nursing Home',
  'Home',
  'Other',
];

export const serviceTypeIds = {
  CREMATORIUM: 'CREMATORIUM',
  SERVICE_VENUE: 'SERVICE_VENUE',
  BURIAL: 'BURIAL',
};

export const serviceTypes = [
  { name: 'Crematorium', value: serviceTypeIds.CREMATORIUM },
  { name: 'Service venue', value: serviceTypeIds.SERVICE_VENUE },
  { name: 'Burial', value: serviceTypeIds.BURIAL },
];

export const allSortFilters = {
  newestFirst: { enum: 'TIME_CREATED_DESC', label: 'Newest First' },
  oldestFirst: { enum: 'TIME_CREATED_ASC', label: 'Oldest First' },
  surnameAsc: { enum: 'DECEASED_SURNAME_ASC', label: 'Surname A-Z' },
  outstandingBalanceDesc: { enum: 'OUTSTANDING_BALANCE_DESC', label: 'Highest outstanding balance' },
  uninvoicedAmountDesc: { enum: 'UNINVOICED_AMOUNT_DESC', label: 'Highest uninvoiced value' },
  funeralDate: { enum: 'FUNERAL_DATE_ASC', label: 'Funeral Date' },
};

export const sortAccountsFilters = [
  allSortFilters.newestFirst,
  allSortFilters.oldestFirst,
  allSortFilters.surnameAsc,
  allSortFilters.outstandingBalanceDesc,
  allSortFilters.uninvoicedAmountDesc,
];

export const sortCasesFilters = [
  allSortFilters.newestFirst,
  allSortFilters.oldestFirst,
  allSortFilters.surnameAsc,
];

export const statusesFilters = [
  { id: 'OPEN', name: 'Open' },
  { id: 'CLOSED', name: 'Closed' },
];

export const ownersFilters = [
  { id: 'mine', name: 'My Funerals' },
  { id: 'everyone', name: 'All Funerals' },
];

export const homesFilters = [
  { id: 'all', name: 'All Homes' },
];

export const emptyFirstOffices = {
  eyeCare: {
    hasEyeCareBeenCompleted: false,
    other: null,
  },
  mouthCare: {
    haveDenturesBeenInserted: false,
    haveSuturesBeenClosed: false,
    other: null,
  },
  disinfection: {
    eyesHaveBeenDisinfected: false,
    noseHasBeenDisinfected: false,
    mouthHasBeenDisinfected: false,
    remainsHaveBeenDisinfected: false,
    orificesHaveBeenPacked: false,
  },
};

export const emptyMortuary = {
  bodyHasBeenEmbalmed: false,
  bodyHasBeenDressed: false,
  bodyHasBeenEncoffined: false,
};

export const transferTypes = {
  EXTERNAL_TRANSFER: 'ExternalTransfer',
  INTERNAL_TRANSFER: 'InternalTransfer',
  TRANSFER_INTO_CARE: 'TransferIntoCare',
};

export const noteCategories = {
  DECEASED: 'DECEASED',
  CLIENT: 'CLIENT',
  PACKAGES: 'PACKAGES',
  CARE: 'CARE',
  VENUE: 'VENUE',
  CORTEGE: 'CORTEGE',
  COFFIN: 'COFFIN',
  URN: 'URN',
  OFFICIANT: 'OFFICIANT',
  MUSIC: 'MUSIC',
  FLOWERS: 'FLOWERS',
  OTHER_SERVICES: 'OTHER_SERVICES',
  MEMORIALISATION: 'MEMORIALISATION',
  OBITUARY: 'OBITUARY',
  GENERAL: 'GENERAL',
  ACCOUNTS: 'ACCOUNTS',
  ACCOUNTS_FINANCE_ONLY: 'ACCOUNTS_FINANCE_ONLY',
};

export const noteCategoriesScopeRestrictions = [
  {
    category: [noteCategories.ACCOUNTS_FINANCE_ONLY],
    requiredPermission: permissions.BEREAVEMENT_ACCOUNT_READ,
  },
];

export const ALL_NOTES = 'ALL_NOTES';

export const noteCategoryLabels = {
  [noteCategories.DECEASED]: 'Deceased',
  [noteCategories.CLIENT]: 'Client',
  [noteCategories.PACKAGES]: 'Packages',
  [noteCategories.CARE]: 'Care',
  [noteCategories.VENUE]: 'Venue',
  [noteCategories.CORTEGE]: 'Cortege',
  [noteCategories.COFFIN]: 'Coffin',
  [noteCategories.URN]: 'Urns',
  [noteCategories.OFFICIANT]: 'Officiant',
  [noteCategories.MUSIC]: 'Music',
  [noteCategories.FLOWERS]: 'Flowers',
  [noteCategories.OTHER_SERVICES]: 'Other services',
  [noteCategories.MEMORIALISATION]: 'Memorialisation',
  [noteCategories.OBITUARY]: 'Obituary',
  [noteCategories.GENERAL]: 'General',
  [noteCategories.ACCOUNTS]: 'Accounts',
  [noteCategories.ACCOUNTS_FINANCE_ONLY]: 'Accounts (Finance only)',
};

export const deedActions = {
  AWAITING: 'AWAITING',
  PROVIDED_BY_CLIENT: 'PROVIDED_BY_CLIENT',
  SENT_TO_CEMETERY_OFFICE: 'SENT_TO_CEMETERY_OFFICE',
  COLLECTED_FROM_CEMETERY_OFFICE_BY_US: 'COLLECTED_FROM_CEMETERY_OFFICE_BY_US',
  COLLECTED_FROM_CEMETERY_OFFICE_BY_CLIENT: 'COLLECTED_FROM_CEMETERY_OFFICE_BY_CLIENT',
  RETURNED_TO_CLIENT: 'RETURNED_TO_CLIENT',
  NOT_RECEIVED: 'NOT_RECEIVED',
  NO_LONGER_REQUIRED: 'NO_LONGER_REQUIRED',
};

export const deedActionLabels = {
  [deedActions.AWAITING]: 'Awaiting',
  [deedActions.PROVIDED_BY_CLIENT]: 'Provided by client',
  [deedActions.SENT_TO_CEMETERY_OFFICE]: 'Sent to cemetery office',
  [deedActions.COLLECTED_FROM_CEMETERY_OFFICE_BY_US]: 'Collected from cemetery office by us',
  [deedActions.COLLECTED_FROM_CEMETERY_OFFICE_BY_CLIENT]: 'Collected from cemetery office by client',
  [deedActions.RETURNED_TO_CLIENT]: 'Returned to client',
  [deedActions.NOT_RECEIVED]: 'Not required',
  [deedActions.NO_LONGER_REQUIRED]: 'No longer required',
};

export const emptyDeed = {
  id: null,
  rightOfBurialNumber: null,
  graveNumber: null,
  section: null,
  dateReceived: null,
  cemeteryName: null,
  notes: null,
  documents: [],
  actions: [],
};

export const locationTypes = {
  FUNERAL_HOME: 'FUNERAL_HOME',
  OTHER: 'OTHER',
};

export const locationTypeLabels = {
  [locationTypes.FUNERAL_HOME]: 'Funeral Home',
  [locationTypes.OTHER]: 'Other',
};

export const emptyAshesRecord = {
  id: null,
  cremationCertificateNumber: null,
  storageLocation: null,
  personToReceiveAshes: null,
  actions: [],
};

export const ashesRecordActions = {
  COLLECTED_FROM_CREMATORIUM_BY_FD: 'COLLECTED_FROM_CREMATORIUM_BY_FD',
  RECEIVED_FROM_CLIENT: 'RECEIVED_FROM_CLIENT',
  PLACED_INTO_STORAGE: 'PLACED_INTO_STORAGE',
  TRANSFERRED_TO_CASKET_SCATTER_TUBE_URN: 'TRANSFERRED_TO_CASKET_SCATTER_TUBE_URN',
  RETURNED_TO_CLIENT: 'RETURNED_TO_CLIENT',
  INTERRED: 'INTERRED',
  SCATTERED_BY_FD: 'SCATTERED_BY_FD',
  RETURNED_TO_CREMATORIUM: 'RETURNED_TO_CREMATORIUM',
};

export const ashesRecordActionLabels = {
  [ashesRecordActions.COLLECTED_FROM_CREMATORIUM_BY_FD]: 'Collected from crematorium by FD',
  [ashesRecordActions.RECEIVED_FROM_CLIENT]: 'Received from client',
  [ashesRecordActions.PLACED_INTO_STORAGE]: 'Placed into storage',
  [ashesRecordActions.TRANSFERRED_TO_CASKET_SCATTER_TUBE_URN]: 'Transferred to casket/scatter tube/urn',
  [ashesRecordActions.RETURNED_TO_CLIENT]: 'Returned to client',
  [ashesRecordActions.INTERRED]: 'Interred',
  [ashesRecordActions.SCATTERED_BY_FD]: 'Scattered by FD',
  [ashesRecordActions.RETURNED_TO_CREMATORIUM]: 'Returned to crematorium',
};

export const ashesRecordActionReturnOptions = [
  'Collected in person',
  'Delivered by us in person',
  'Sent via courier',
];

export const ashesRecordExternalEditType = {
  HANDLING_ASHES: 'HANDLING_ASHES',
  CREMATORIUM_REQUESTED: 'CREMATORIUM_REQUESTED',
  CONTAINERS_TYPE_REQUESTED: 'CONTAINERS_TYPE_REQUESTED',
};

export const ashesRecordExternalEditOptions = {
  [ashesRecordExternalEditType.HANDLING_ASHES]: {
    arrangementPath: arrangementPaths.CREMATORIUM,
    dialogTitle: 'Handling of ashes',
    dialogMessage: 'Would you like to save and exit ashes before going to edit handling of ashes in the arrangement?',
  },
  [ashesRecordExternalEditType.CREMATORIUM_REQUESTED]: {
    arrangementPath: arrangementPaths.CREMATORIUM,
    dialogTitle: 'Crematorium requested',
    dialogMessage: 'Would you like to save and exit ashes before going to edit the crematorium requested in the arrangement?',
  },
  [ashesRecordExternalEditType.CONTAINERS_TYPE_REQUESTED]: {
    arrangementPath: arrangementPaths.URNS,
    dialogTitle: 'Containers type requested',
    dialogMessage: 'Would you like to save and exit ashes before going to edit the containers type requested in the arrangement?',
  },
};

export const deceasedTab = {
  DECEASED: 0,
  CARE_WISHES: 1,
  CARE_RECORD: 2,
  POSSESSIONS: 3,
  LEGAL_AND_FORMS: 4,
};

export const deceasedCareRecordTab = {
  BODY: 0,
  ASHES: 1,
};

export const officiantTitles = [
  'Bishop',
  'Canon',
  'Dr',
  'Fr',
  'Mgr',
  'Mr',
  'Mrs',
  'Miss',
  'Ms',
  'Rev',
];
