import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { catalogueServiceCategories, catalogueProductCategories } from 'constants/catalogue';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import PackageItemModal from 'components/arrangement/PackageItemModal';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import CatalogueItemListItem from './CatalogueItemListItem';
import rcStyles from './styles.scss';

const AdminPackageCatalogueItemSelection = ({
  packageItem,
  selectedCatalogueItems,
  disabled,
  isPackageItemModalOpen,
  isServiceCategory,
  isDisbursementCategory,
  onMaxQuantityChange,
  onRemoveCatalogueListItem,
  onSaveCatalogueItemsSelect,
  onOpenPackageItemModal,
  onClosePackageItemModal,
}) => {
  const serviceCategoriesInUse = catalogueServiceCategories
    .filter(category => selectedCatalogueItems
      .map(item => item.category)
      .includes(category.category));
  const productCategoriesInUse = catalogueProductCategories
    .filter(category => selectedCatalogueItems
      .map(item => item.category)
      .includes(category.category));
  const getMaxQuantityValue = (maxQuantities, category) => {
    const maxQuantity = maxQuantities && maxQuantities.find(item => item.category === category.category);
    return (maxQuantity && maxQuantity.value) || 0;
  };

  return (
    <Fragment>
      <div className={styles.o_block_content}>
        <div className={rcStyles.addition_row}>
          <h4 className={styles.long_primer}>{t('Services')}</h4>
          <Button
            data-test-id="addServicesAndFees"
            onClick={() => {
              onOpenPackageItemModal(true, false);
            }}
            color="primary"
            variant="outlined"
            size="medium"
            disabled={disabled}
          >
            {t('Add services & fees')}
          </Button>
        </div>
        {serviceCategoriesInUse.filter(category => category.category !== 'DISBURSEMENTS').map(category => (
          <Fragment>
            <div
              key={category.category}
              className={classNames(
                rcStyles.category_header,
                rcStyles.addition_row,
              )}
            >
              <h4 className={styles.primer}>{category.name}</h4>
              <TextField
                label={t('Limit')}
                value={getMaxQuantityValue(
                  packageItem && packageItem.serviceCategoryMaxQuantities,
                  category,
                ) || 0}
                onChange={event => onMaxQuantityChange(category.category, 'serviceCategoryMaxQuantities', Math.abs(event.target.value))}
                type="number"
                inputProps={{
                  'data-test-id': 'limit',
                  'min': 0,
                  'step': 1,
                }}
              />
            </div>
            {selectedCatalogueItems
              .filter(item => (item.category === category.category))
              .map(catalogueItem => (
                <CatalogueItemListItem
                  key={catalogueItem.id}
                  item={catalogueItem}
                  onDelete={() => onRemoveCatalogueListItem(catalogueItem.id, catalogueItem.category)}
                />
              ))}
          </Fragment>
        ))}
      </div>
      <div className={styles.o_block_content}>
        <div className={rcStyles.addition_row}>
          <h4 className={styles.long_primer}>{t('Products')}</h4>
          <Button
            data-test-id="addProductsAndFees"
            onClick={() => {
              onOpenPackageItemModal(false, false);
            }}
            color="primary"
            variant="outlined"
            size="medium"
            disabled={disabled}
          >
            {t('Add Products & fees')}
          </Button>
        </div>
        {productCategoriesInUse.map(category => (
          <Fragment>
            <div
              key={category.category}
              className={classNames(
                rcStyles.category_header,
                rcStyles.addition_row,
              )}
            >
              <h4 className={styles.primer}>{category.name}</h4>
              <TextField
                label={t('Limit')}
                defaultValue={getMaxQuantityValue(
                  packageItem && packageItem.productCategoryMaxQuantities,
                  category,
                )}
                onChange={event => onMaxQuantityChange(category.category, 'productCategoryMaxQuantities', Math.abs(event.target.value))}
                type="number"
                inputProps={{
                  'data-test-id': 'limit',
                  'min': 0,
                }}
              />
            </div>
            <div>
              {selectedCatalogueItems
                .filter(item => (item.category === category.category))
                .map(catalogueItem => (
                  <CatalogueItemListItem
                    key={catalogueItem.id}
                    item={catalogueItem}
                    onDelete={() => onRemoveCatalogueListItem(catalogueItem.id, catalogueItem.category)}
                  />
                ))}
            </div>
          </Fragment>
        ))}
      </div>
      <div className={styles.o_block_content}>
        <div className={rcStyles.addition_row}>
          <h4 className={styles.long_primer}>{t('Disbursements')}</h4>
          <Button
            data-test-id="addDisbursementsAndFees"
            onClick={() => {
              onOpenPackageItemModal(true, true);
            }}
            color="primary"
            variant="outlined"
            size="medium"
            disabled={disabled}
          >
            {t('Add disbursements')}
          </Button>
        </div>
        {serviceCategoriesInUse.filter(category => category.category === 'DISBURSEMENTS').map(category => (
          <Fragment>
            <div
              key={category.category}
              className={classNames(
                rcStyles.category_header,
                rcStyles.addition_row,
              )}
            >
              <h4 className={styles.primer}>{category.name}</h4>
              <TextField
                label={t('Limit')}
                defaultValue={getMaxQuantityValue(
                  packageItem && packageItem.serviceCategoryMaxQuantities,
                  category,
                )}
                onChange={event => onMaxQuantityChange(category.category, 'serviceCategoryMaxQuantities', Math.abs(event.target.value))}
                type="number"
                inputProps={{
                  'data-test-id': 'limit',
                  'min': 0,
                }}
              />
            </div>
            <div>
              {selectedCatalogueItems
                .filter(item => (item.category === category.category))
                .map(catalogueItem => (
                  <CatalogueItemListItem
                    key={catalogueItem.id}
                    item={catalogueItem}
                    onDelete={() => onRemoveCatalogueListItem(catalogueItem.id, catalogueItem.category)}
                  />
                ))}
            </div>
          </Fragment>
        ))}
      </div>
      <PackageItemModal
        selectedCatalogueItems={selectedCatalogueItems}
        isOpen={isPackageItemModalOpen}
        isDisbursementCategory={isDisbursementCategory}
        isServiceCategory={isServiceCategory}
        onSave={onSaveCatalogueItemsSelect}
        onClose={onClosePackageItemModal}
      />
    </Fragment>
  );
};

AdminPackageCatalogueItemSelection.propTypes = {
  packageItem: PropTypes.objectOf(PropTypes.any),
  selectedCatalogueItems: PropTypes.arrayOf(PropTypes.shape({})),
  disabled: PropTypes.bool,
  isPackageItemModalOpen: PropTypes.bool,
  isServiceCategory: PropTypes.bool,
  isDisbursementCategory: PropTypes.bool,
  onMaxQuantityChange: PropTypes.func.isRequired,
  onRemoveCatalogueListItem: PropTypes.func.isRequired,
  onSaveCatalogueItemsSelect: PropTypes.func.isRequired,
  onOpenPackageItemModal: PropTypes.func.isRequired,
  onClosePackageItemModal: PropTypes.func.isRequired,
};

export default AdminPackageCatalogueItemSelection;
