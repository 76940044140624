import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import { catalogueItemTypes } from 'constants/arrangement';
import { arrangementType } from 'types/bereavement';
import { apolloClientType } from 'types/apollo';
import { getProducts, getServices } from './queries.gql';
import EstimateAddSelectionDialog from './EstimateAddSelectionDialog';

class EstimateAddSelectionDialogContainer extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    bereavementId: PropTypes.string.isRequired,
    bereavementHomeId: PropTypes.string.isRequired,
    arrangement: arrangementType.isRequired,
    onOpenDuplicateSelectionModal: PropTypes.func.isRequired,
    client: apolloClientType.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };
  }

  componentDidMount() {
    this.getItems();
  }

  getItems = () => {
    const {
      client,
      type,
      category,
      bereavementHomeId,
    } = this.props;
    const query = (type === catalogueItemTypes.PRODUCT) ? getProducts : getServices;
    client
      .query({
        query,
        variables: {
          categories: [category],
          organisationalUnitIds: [bereavementHomeId],
        },
      })
      .then((response) => {
        const items = response.data.services && response.data.services.edges.map(({ node }) => node);
        this.setState({ items });
      });
  }

  render() {
    const {
      open,
      category,
      type,
      onCloseModal,
      bereavementId,
      bereavementHomeId,
      arrangement,
      onOpenDuplicateSelectionModal,
    } = this.props;
    const { items } = this.state;
    return (
      <EstimateAddSelectionDialog
        open={open}
        items={items}
        category={category}
        type={type}
        onCloseModal={onCloseModal}
        bereavementId={bereavementId}
        bereavementHomeId={bereavementHomeId}
        arrangement={arrangement}
        onOpenDuplicateSelectionModal={onOpenDuplicateSelectionModal}
      />
    );
  }
}

export default withApollo(
  withRouter(EstimateAddSelectionDialogContainer),
);
