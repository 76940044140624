import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import AdminStaffMembersForm from 'components/admin/AdminStaffMembersForm';
import StaffMemberRoleLookup from 'components/common/lookups/StaffMemberRoleLookup';
import { organisationalUnitTypes } from 'constants/organisationalUnits';
import OrganisationalUnitLookup from 'components/common/lookups/OrganisationalUnitLookup';
import FormHelperText from '@material-ui/core/FormHelperText';
import ScreenLayout from 'components/common/ScreenLayout';
import Button from '@material-ui/core/Button';
import { homeType } from 'types/bereavement';
import { roleType, staffMemberType } from 'types/staffMember';
import classNames from 'classnames';
import IconLoad from 'icons/IconLoad';

import styles from 'scss/main.scss';

const AdminStaffMemberScreen = ({
  staffMember,
  errors,
  formRefs,
  onChange,
  isLoading,
  onCancel,
  onSave,
  toggleIsArrangerUser,
  selectedRoles,
  selectedHomes,
  onUpdateLookupValues,
  isArrangerUser,
}) => (
  <ScreenLayout
    title={t('Manage staff members')}
    dashboardBannerProps={{
      breadcrumbs: [{ path: '/', title: t('Dashboard') }, { path: '/staff-members', title: t('Staff members') }],
      description: t('Assign staff to homes and manage roles.'),
    }}
  >
    <div className={classNames(
      styles.c_page__full_height,
      styles.has_faded_background,
    )}
    >
      {isLoading
        ? (
          <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
            <IconLoad />
          </div>
        )
        : (
          <div
            data-test-id="staffMemberForm"
            className={styles.o_view}
          >
            <div className={styles.o_view__main}>
              <AdminStaffMembersForm
                staffMember={staffMember}
                errors={errors}
                formRefs={formRefs}
                onChange={onChange}
                toggleIsArrangerUser={toggleIsArrangerUser}
                isArrangerUser={isArrangerUser}
                data-test-id="staffMemberForm"
              />

              <div className={styles.c_k_tile}>
                <div className={styles.c_k_tile__header}>
                  <h1 className={styles.is_heading}>{t('Manage Arranger account')}</h1>
                </div>
                <div>
                  <p>
                    {isArrangerUser
                      ? `${t('This staff member has access to Arranger using the email address')}: ${staffMember.emails[0]}. ${t('If you no longer require this staff member to have access you can ‘deactivate’ the account via the button below. The account can be ‘reactivated’ at any time.')}`
                      : t('Activate an Arranger account for this user.')
                    }
                  </p>
                </div>
                <div className={classNames(
                  styles.c_k_tile__action_bar,
                  styles.is_clean,
                )}
                >
                  <Button
                    variant="contained"
                    color={isArrangerUser ? 'secondary' : 'primary'}
                    onClick={toggleIsArrangerUser}
                    checked={isArrangerUser}
                    data-test-id="updateIdentityInterface"
                  >
                    {isArrangerUser ? t('Deactivate account') : t('Activate account')}
                  </Button>
                </div>
              </div>

              <div className={classNames(
                styles.c_k_tile,
              )}
              >
                <div className={styles.c_k_tile__header}>
                  <Tooltip
                    title="Roles can only be selected when the staff member is activated as an Arranger user."
                    placement="top-start"
                    enterTouchDelay={1}
                    leaveTouchDelay={3000}
                    disableFocusListener={isArrangerUser}
                    disableHoverListener={isArrangerUser}
                    disableTouchListener={isArrangerUser}
                  >
                    <h1 className={styles.is_heading}>{t('Role')}</h1>
                  </Tooltip>
                </div>
                <div>
                  <p>{t('Assign a role to this staff member. Roles control access to functionality within Arranger.')}</p>
                  <div className={styles.u_push__top}>
                    <StaffMemberRoleLookup
                      value={selectedRoles || staffMember.roleIds}
                      label="Select Role"
                      onChange={value => onUpdateLookupValues('selectedRoles', value)}
                      isMulti
                      disabled={!isArrangerUser}
                      data-test-id="staffMemberLookup"
                    />
                  </div>
                </div>
              </div>

              <div className={classNames(
                styles.c_k_tile,
              )}
              >
                <div className={styles.c_k_tile__header}>
                  <h1 ref={formRefs.selectedHomes} className={styles.is_heading}>{t('Homes')}</h1>
                </div>
                <div>
                  <p>{t('Select which homes this employee covers.')}</p>
                  <div className={styles.u_push__top}>
                    {errors.selectedHomes
                      && (<FormHelperText error>{errors.selectedHomes}</FormHelperText>)}
                    <OrganisationalUnitLookup
                      value={selectedHomes || staffMember.organisationalUnitIds}
                      label="Select home *"
                      onChange={value => onUpdateLookupValues('selectedHomes', value)}
                      type={organisationalUnitTypes.HOME}
                      isMulti
                      data-test-id="homeLookup"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.c_k_tile}>
                <div className={classNames(
                  styles.c_k_tile__action_bar,
                  styles.is_clean,
                  styles.u_hard__top,
                  styles.u_flush__top,
                )}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={onCancel}
                    data-test-id="cancelSaveStaffMember"
                  >
                    {t('Cancel')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onSave}
                    data-test-id="saveStaffMember"
                  >
                    {t('Save')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div>
  </ScreenLayout>
);

AdminStaffMemberScreen.propTypes = {
  staffMember: staffMemberType,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  toggleIsArrangerUser: PropTypes.func.isRequired,
  selectedRoles: PropTypes.arrayOf(roleType),
  selectedHomes: PropTypes.arrayOf(homeType),
  onUpdateLookupValues: PropTypes.func.isRequired,
  isArrangerUser: PropTypes.bool.isRequired,
};

export default AdminStaffMemberScreen;
