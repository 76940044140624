import React, { Fragment } from 'react';
import classNames from 'classnames';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import Amount from 'components/common/Amount';
import AsyncButton from 'components/common/AsyncButton';
import ExpandableBanner from 'components/common/ExpandableBanner';
import Price from 'components/common/Price';
import { AccountConsumer } from 'contexts/account';
import { getFormattedDate } from 'services/utils';
import { isInvoice, isProforma } from 'services/utils/account';
import { invoiceType } from 'types/account';

import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const withTooltip = (title, children) => (
  <Tooltip
    title={title}
    placement="top-start"
    enterTouchDelay={1}
    leaveTouchDelay={3000}
  >
    {children}
  </Tooltip>
);

const TransactionInvoiceRow = ({
  invoice,
  targetInvoiceId,
  hasConfirmedArrangement,
  onDownloadInvoice,
  isDownloadingInvoice,
  onVoidInvoice,
  onEditInvoice,
}) => (
  <AccountConsumer>
    {({ isAccountOpen, hasWritePermissions }) => (
      <div
        data-test-id={(invoice.dateVoided
          ? 'voidedInvoiceTransaction'
          : `${isProforma(invoice) ? 'proformaInvoice' : 'invoice'}Transaction`)}
        className={classNames(
          invoice.dateVoided ? rcStyles.is_voided : rcStyles.is_active,
        )}
      >
        <ExpandableBanner
          headerClasses={[rcStyles.expanding_banner__header]}
          wrapperClasses={[rcStyles.expanding_banner__wrap]}
          isClosedOnLoad={!(targetInvoiceId === invoice?.id)}
          title={(
            <div className={rcStyles.banner_content}>
              <div className={rcStyles.is_title}>
                {isInvoice(invoice) && (
                  invoice.dateVoided ? t('Voided Invoice') : t('Invoice')
                )}
                {isProforma(invoice) && t('Proforma Invoice')}
              </div>
              <div className={rcStyles.is_date_created}>
                <div className={styles.brevier}>{t('Created')}</div>
                <span data-test-id="invoiceCreatedOn">
                  {getFormattedDate(invoice.createdAt)}
                </span>
              </div>
              {isInvoice(invoice) && invoice.dateVoided ? (
                <div className={rcStyles.is_date_voided}>
                  <div className={styles.brevier}>{t('Voided')}</div>
                  <span data-test-id="invoiceVoidedOn">
                    {getFormattedDate(invoice.dateVoided)}
                  </span>
                </div>
              ) : (
                invoice.editedAt && (
                  <div className={rcStyles.is_date_edited}>
                    <div className={styles.brevier}>{t('Edited')}</div>
                    <span data-test-id="invoiceEditedAt">
                      {getFormattedDate(invoice.editedAt)}
                    </span>
                  </div>
                )
              )}
              <div
                className={rcStyles.is_price}
                data-test-id="invoiceTotalAmountDue"
              >
                <Price
                  price={invoice.totals.total}
                  customClass={classNames(
                    styles.c_price_label__regular,
                    styles.is_black,
                    styles.is_bold,
                    invoice.dateVoided && styles.c_strike_through,
                    invoice.dateVoided && rcStyles.voided_amount_padding,
                  )}
                />
              </div>
            </div>
          )}
        >
          <div className={rcStyles.expanded_content}>
            <div className={rcStyles.invoice_number}>
              {invoice.number && (
                <Fragment>
                  <div className={styles.minion}>{t('Number')}</div>
                  <span data-test-id="invoiceNumber">
                    {invoice.number}
                  </span>
                </Fragment>
              )}
              {invoice.reference && (
                <Fragment>
                  <div className={styles.minion}>{t('Reference')}</div>
                  <span data-test-id="invoiceReference">
                    {invoice.reference}
                  </span>
                </Fragment>
              )}
            </div>
            {isInvoice(invoice) && (
              <div className={rcStyles.invoice_statuses}>
                <Amount
                  label={t('Credit')}
                  labelClasses={[
                    rcStyles.is_credit,
                    rcStyles.amount_label,
                  ]}
                  amountProps={{ 'data-test-id': 'invoiceCreditAmount' }}
                  hasStrikeThrough={!!invoice.dateVoided}
                />

                <Amount
                  label={t('Paid')}
                  labelClasses={[
                    rcStyles.is_paid,
                    rcStyles.amount_label,
                  ]}
                  amount={invoice.totals.totalPaid}
                  amountProps={{ 'data-test-id': 'invoicePaidAmount' }}
                  hasStrikeThrough={!!invoice.dateVoided}
                />
                <Amount
                  label={t('Balance')}
                  labelClasses={[
                    rcStyles.is_balance,
                    rcStyles.amount_label,
                  ]}
                  amount={invoice.totals.balance}
                  amountProps={{ 'data-test-id': 'invoiceBalanceAmount' }}
                  hasStrikeThrough={!!invoice.dateVoided}
                  amountClasses={invoice.dateVoided && [rcStyles.voided_amount_padding]}
                />
              </div>
            )}
            <div className={rcStyles.actions_bar}>
              {(isInvoice(invoice) || isProforma(invoice))
                && !invoice.dateVoided && (
                withTooltip(
                  t(!hasConfirmedArrangement ? 'Confirm an arrangement to edit this invoice.' : ''),
                  (
                    <div>
                      <Button
                        onClick={() => onEditInvoice(invoice.id)}
                        variant="outlined"
                        color="primary"
                        disabled={!(isAccountOpen && hasWritePermissions) || !hasConfirmedArrangement}
                        data-test-id="editInvoice"
                      >
                        {t('Edit')}
                      </Button>
                    </div>
                  ),
                )
              )}
              <div className={rcStyles.download_button}>
                <AsyncButton
                  onClick={onDownloadInvoice}
                  variant="outlined"
                  color="primary"
                  data-test-id="invoiceDownload"
                  data-invoice-id={invoice && invoice.id}
                  disabled={isDownloadingInvoice}
                  nonNativeProps={{
                    isActive: isDownloadingInvoice,
                    text: t('Download'),
                    displayIcon: true,
                  }}
                />
              </div>
              {isInvoice(invoice) && !invoice.dateVoided && (
                <div className={rcStyles.void_button}>
                  <Button
                    onClick={onVoidInvoice}
                    variant="outlined"
                    color="primary"
                    disabled={!(isAccountOpen && hasWritePermissions)}
                    data-test-id="voidInvoice"
                  >
                    {t('Void')}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </ExpandableBanner>
      </div>
    )}
  </AccountConsumer>
);

TransactionInvoiceRow.propTypes = {
  invoice: invoiceType,
  targetInvoiceId: PropTypes.string,
  hasConfirmedArrangement: PropTypes.bool.isRequired,
  onDownloadInvoice: PropTypes.func.isRequired,
  isDownloadingInvoice: PropTypes.bool,
  onVoidInvoice: PropTypes.func.isRequired,
  onEditInvoice: PropTypes.func.isRequired,
};

export default TransactionInvoiceRow;
