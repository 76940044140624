import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import rcStyles from './styles.scss';


const Breadcrumbs = ({
  breadcrumbs,
}) => (
  <div className={rcStyles.breadcrumb}>
    {breadcrumbs.map(breadcrumb => (
      <Fragment key={breadcrumb.title}>
        <Link to={breadcrumb.path}>
          {breadcrumb.title}
        </Link>
      </Fragment>
    ))}
  </div>
);

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
};

export default Breadcrumbs;
