import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createDeedAction, editDeedAction } from 'actions/bereavement';
import { emptyDeed } from 'constants/bereavement';
import { getBereavementCemeteryName } from 'services/utils/bereavement';
import { bereavementType, deedType } from 'types/bereavement';
import DeedModal from 'components/bereavement/DeedModal';
import DeedsSection from './DeedsSection';

export class DeedsSectionContainer extends Component {
  static propTypes = {
    bereavement: bereavementType,
    deceasedPersonId: PropTypes.string.isRequired,
    deeds: PropTypes.arrayOf(deedType),
    createDeed: PropTypes.func.isRequired,
    editDeed: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      deedBeingModified: null,
    };
  }

  handleAddDeed = () => {
    const { bereavement } = this.props;
    const newDeed = {
      ...emptyDeed,
      cemeteryName: getBereavementCemeteryName(bereavement),
    };
    this.setState({ deedBeingModified: newDeed });
  }

  handleEditDeed = (deed) => {
    this.setState({ deedBeingModified: deed });
  }

  handleDeedClose = () => {
    this.setState({ deedBeingModified: null });
  }

  handleDeedSave = (deed) => {
    const {
      bereavement: { id: bereavementId }, deceasedPersonId, createDeed, editDeed,
    } = this.props;
    const isNewDeed = !deed.id;

    if (isNewDeed) {
      createDeed(bereavementId, deceasedPersonId, deed);
    } else {
      editDeed(bereavementId, deceasedPersonId, deed);
    }

    this.setState({ deedBeingModified: null });
  }

  render() {
    const { deeds, disabled } = this.props;
    const { deedBeingModified } = this.state;

    return (
      <Fragment>
        <DeedsSection
          deeds={deeds}
          onAdd={this.handleAddDeed}
          onEdit={this.handleEditDeed}
          disabled={disabled}
        />
        <DeedModal
          deedBeingModified={deedBeingModified}
          isOpen={!!deedBeingModified}
          onClose={this.handleDeedClose}
          onSave={this.handleDeedSave}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  createDeed: createDeedAction,
  editDeed: editDeedAction,
}, dispatch);

export default connect(null, mapDispatchToProps)(DeedsSectionContainer);
