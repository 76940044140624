import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import IconAdd from '@material-ui/icons/Add';
import AdminCatalogueItemVariant from 'components/admin/AdminCatalogueItemVariantList/AdminCatalogueItemVariant';
import Button from '@material-ui/core/Button';
import { variantStatuses } from 'constants/catalogue';
import { variantsType, mediaType } from 'types/media';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import VariantImageModal from './VariantImageModal';

const AdminCatalogueItemVariantList = ({
  variants,
  images,
  selectedImage,
  disabled,
  selectedVariantForImage,
  onChangeVariant,
  onAddVariant,
  errors,
  onDeleteVariant,
  toggleSelectImageModal,
  onSelectVariantImage,
  onSaveVariantImage,
}) => (
  <Fragment>
    {variants
      .filter(variant => variant.status === variantStatuses.ACTIVE)
      .map((variant, index) => (
        <AdminCatalogueItemVariant
          variant={variant}
          key={variant.id}
          index={index}
          disabled={disabled}
          onChange={onChangeVariant}
          onDeleteVariant={onDeleteVariant}
          toggleSelectImageModal={toggleSelectImageModal}
          onSelectVariantImage={onSelectVariantImage}
          onSaveVariantImage={onSaveVariantImage}
          isDeletable={index !== 0}
          errors={errors}
          data-test-id="priceItem"
        />
      ))
    }
    <div className={classNames(
      styles.o_fb__item,
      styles.u_push__top,
    )}
    >
      <Button
        onClick={onAddVariant}
        variant="outlined"
        color="primary"
        data-test-id="addVariant"
      >
        <IconAdd />
        {t('Add variant')}
      </Button>
    </div>
    <VariantImageModal
      variantId={selectedVariantForImage}
      images={images}
      selectedImage={selectedImage}
      onSelect={onSelectVariantImage}
      onSave={onSaveVariantImage}
      onCancel={() => toggleSelectImageModal()}
    />
  </Fragment>
);

AdminCatalogueItemVariantList.propTypes = {
  variants: PropTypes.arrayOf(variantsType.isRequired).isRequired,
  images: PropTypes.arrayOf(mediaType).isRequired,
  selectedImage: mediaType,
  disabled: PropTypes.bool,
  selectedVariantForImage: PropTypes.string,
  errors: PropTypes.objectOf(PropTypes.any),
  onChangeVariant: PropTypes.func.isRequired,
  onAddVariant: PropTypes.func.isRequired,
  onDeleteVariant: PropTypes.func.isRequired,
  toggleSelectImageModal: PropTypes.func.isRequired,
  onSelectVariantImage: PropTypes.func.isRequired,
  onSaveVariantImage: PropTypes.func.isRequired,
};

export default AdminCatalogueItemVariantList;
