import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconLoc from '@material-ui/icons/AddLocation';
import IconPerson from '@material-ui/icons/Person';
import CloseIcon from '@material-ui/icons/Close';
import LookUp from 'components/common/lookups/LookUp';
import { reactRefType } from 'types/common';
import { directoryListingType } from 'types/directoryListing';

import styles from 'scss/main.scss';
import rcStyles from './styles.scss';
import NameDirectoryListingFormSectionModal from './NameDirectoryListingFormSectionModal';

const NameDirectoryListingFormSection = ({
  title,
  disabled,
  name,
  lookUpValue,
  modalFormData,
  isModalOpen,
  isLoading,
  isSaving,
  suggestions,
  lookUpRef,
  onLookUpChange,
  onNameChange,
  onDirectoryListingChange,
  onDirectoryListingClear,
  onModalOpen,
  onModalClose,
  onModalSave,
  onModalChange,
  dialogRef,
  formRefs,
  errors,
}) => {
  const nameLabel = `${title} name`;
  const addressLabel = `${title} address`;

  const loadingIcon = isLoading && (
    <InputAdornment position="end" className={styles.o_icon__primary}>
      <img src="/img/loading_field.gif" alt="Loading" className={styles.image} data-test-id="ellipsisLoader" />
    </InputAdornment>
  );

  const clearIcon = lookUpValue && (
    <InputAdornment position="end" className={styles.o_icon__primary}>
      <IconButton onClick={onDirectoryListingClear} data-test-id="clearDirectoryListing">
        <CloseIcon />
      </IconButton>
    </InputAdornment>
  );

  return (
    <div className={rcStyles.input_group}>

      <div className={rcStyles.directory_lookup}>
        <IconPerson className={rcStyles.form_inline_icon} />
        <TextField
          label={nameLabel}
          placeholder={nameLabel}
          onChange={onNameChange}
          InputLabelProps={{
            FormLabelClasses: {
              root: rcStyles.directory_lookup__label,
              focused: rcStyles.is_focused,
              filled: rcStyles.is_shrunk,
            },
          }}
          value={name || ''}
          fullWidth
          disabled={disabled}
          data-test-id="directoryListingName"
        />
      </div>
      <LookUp
        label={addressLabel}
        onChange={onLookUpChange}
        onSelect={onDirectoryListingChange}
        onSelectNew={onModalOpen}
        lookUpValue={lookUpValue}
        suggestions={suggestions}
        startComponent={(
          <IconLoc className={rcStyles.form_inline_icon} />
        )}
        endComponent={(
          <div className={rcStyles.directory_lookup__actions}>
            {loadingIcon}
            {clearIcon}
          </div>
        )}
        lookUpRef={lookUpRef}
        fullWidth
        disabled={disabled}
        data-test-id="directoryListingLookup"
      />

      <NameDirectoryListingFormSectionModal
        isOpen={isModalOpen}
        formData={modalFormData}
        isSaving={isSaving}
        onCancel={onModalClose}
        onSave={onModalSave}
        onChange={onModalChange}
        data-test-id="directoryListingModal"
        errors={errors}
        dialogRef={dialogRef}
        formRefs={formRefs}
      />
    </div>
  );
};

NameDirectoryListingFormSection.propTypes = {
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  lookUpValue: PropTypes.string.isRequired,
  modalFormData: directoryListingType,
  isModalOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  lookUpRef: PropTypes.objectOf(PropTypes.any).isRequired,
  onLookUpChange: PropTypes.func.isRequired,
  onNameChange: PropTypes.func.isRequired,
  onDirectoryListingChange: PropTypes.func.isRequired,
  onDirectoryListingClear: PropTypes.func.isRequired,
  onModalOpen: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onModalSave: PropTypes.func.isRequired,
  onModalChange: PropTypes.func.isRequired,
  dialogRef: reactRefType.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default NameDirectoryListingFormSection;
