import { statuses } from 'constants/catalogue';
import { mediaVariants } from 'constants/media';
import {
  buildItemObjectForMutation,
  buildPackageObjectForMutation,
  createCatalogueItemTransform,
  filtersForQueryTransform,
  addPackageTransform,
  catalogueOrderMutationTransform,
  arrangementOrderMutationTransform,
} from 'transforms/catalogue';
import { preloadImageVariants } from 'services/utils';
import { enqueueSnackbarAction } from 'actions/snackbar';
import { clearAllAction as clearAllBereavements } from 'actions/bereavement';
import { t } from 'i18next';
import { catalogueItemTypes } from 'constants/arrangement';
import {
  getProducts,
  getServices,
  getProduct,
  getService,
  getPackage,
  getPackages,
} from './catalogue.queries.gql';
import {
  retireServiceMutation,
  publishServiceMutation,
  retireProductMutation,
  retirePackageMutation,
  publishProductMutation,
  publishPackageMutation,
  editServiceMutation,
  editProductMutation,
  editPackageMutation,
  deleteProductMutation,
  deleteServiceMutation,
  deletePackageMutation,
  createServiceMutation,
  createPackageMutation,
  createProductMutation,
  emailCataloguePreviewPDFMutation,
  orderProductsMutation,
  orderServicesMutation,
  orderCataloguePackagesMutation,
  orderArrangementPackagesMutation,
  orderArrangementProductsMutation,
  orderArrangementServicesMutation,
} from './catalogue.mutations.gql';

export const catalogueActions = {
  CLEAR_ALL: 'CATALOGUE_CLEAR_ALL',
  CLEAR_FILTERS: 'CATALOGUE_CLEAR_FILTERS',
  CLEAR_ITEMS: 'CATALOGUE_CLEAR_ITEMS',
  CLEAR_PAGINATION: 'CATALOGUE_CLEAR_PAGINATION',
  UPDATE_FILTERS: 'CATALOGUE_UPDATE_FILTERS',
  UPDATE_PACKAGE: 'CATALOGUE_UPDATE_PACKAGE',
  UPDATE_CATALOGUE_ITEM: 'CATALOGUE_UPDATE_CATALOGUE_ITEM',
  UPDATE_ARRANGEMENT_ITEM: 'CATALOGUE_UPDATE_ARRANGEMENT_ITEM',
  SET_IS_LOADING: 'CATALOGUE_SET_IS_LOADING',
  SET_IS_LOADING_ADMIN_CATALOGUE: 'CATALOGUE_SET_IS_LOADING_ADMIN_CATALOGUE',
  REMOVE_PACKAGE: 'CATALOGUE_REMOVE_PACKAGE',
  REMOVE_CATALOGUE_ITEM: 'CATALOGUE_REMOVE_CATALOGUE_ITEM',
  ADD_PACKAGES: 'CATALOGUE_ADD_PACKAGES',
  ADD_CATALOGUE_ITEMS: 'CATALOGUE_ADD_CATALOGUE_ITEMS',
  SET_PACKAGES_ACTIVE_REQUEST: 'CATALOGUE_SET_PACKAGES_ACTIVE_REQUEST',
  SET_PRODUCTS_ACTIVE_REQUEST: 'CATALOGUE_SET_PRODUCTS_ACTIVE_REQUEST',
  SET_SERVICES_ACTIVE_REQUEST: 'CATALOGUE_SET_SERVICES_ACTIVE_REQUEST',
};


export const clearAllAction = () => (
  { type: catalogueActions.CLEAR_ALL }
);

export const setIsLoadingAction = (itemType, isLoading) => (
  { type: catalogueActions.SET_IS_LOADING, payload: { itemType, isLoading } }
);

export const setPackagesActiveRequest = activeRequest => (
  { type: catalogueActions.SET_PACKAGES_ACTIVE_REQUEST, payload: activeRequest }
);

export const setProductsActiveRequest = activeRequest => (
  { type: catalogueActions.SET_PRODUCTS_ACTIVE_REQUEST, payload: activeRequest }
);

export const setServicesActiveRequest = activeRequest => (
  { type: catalogueActions.SET_SERVICES_ACTIVE_REQUEST, payload: activeRequest }
);

export const setIsLoadingAdminCatalogueAction = isLoading => (
  { type: catalogueActions.SET_IS_LOADING_ADMIN_CATALOGUE, payload: { isLoading } }
);

export const addCatalogueItemsAction = payload => (
  { type: catalogueActions.ADD_CATALOGUE_ITEMS, payload }
);

export const updateFiltersAction = (itemType, key, value) => (
  { type: catalogueActions.UPDATE_FILTERS, payload: { itemType, key, value } }
);

export const clearPaginationAction = itemType => (
  { type: catalogueActions.CLEAR_PAGINATION, payload: { itemType } }
);

export const clearFiltersAction = itemType => (
  { type: catalogueActions.CLEAR_FILTERS, payload: { itemType } }
);

export const clearItemsAction = itemType => (
  { type: catalogueActions.CLEAR_ITEMS, payload: { itemType } }
);

export const removeCatalogueItemAction = (itemType, id) => (
  { type: catalogueActions.REMOVE_CATALOGUE_ITEM, payload: { itemType, id } }
);

export const updateCatalogueItemAction = (itemType, item) => (
  { type: catalogueActions.UPDATE_CATALOGUE_ITEM, payload: { itemType, item } }
);

export const updateArrangementItemAction = (itemType, item) => (
  { type: catalogueActions.UPDATE_ARRANGEMENT_ITEM, payload: { itemType, item } }
);

export const addPackagesAction = payload => (
  { type: catalogueActions.ADD_PACKAGES, payload }
);

export const updatePackageAction = packageItem => (
  { type: catalogueActions.UPDATE_PACKAGE, packageItem }
);

export const removePackageAction = id => (
  { type: catalogueActions.REMOVE_PACKAGE, id }
);

const handleStoreRefreshActions = (itemType, refreshConditions) => (dispatch) => {
  if (refreshConditions) {
    const { clearFilters, clearPagination } = refreshConditions;
    if (clearFilters) {
      dispatch(clearFiltersAction(itemType));
    }
    if (clearPagination) {
      dispatch(clearPaginationAction(itemType));
    }
    dispatch(clearItemsAction(itemType));
  }
};

export const retireCatalogueItemAction = (itemType, id) => (dispatch, getState, client) => {
  client.mutate({
    mutation: itemType === 'services' ? retireServiceMutation : retireProductMutation,
    variables: { input: { id } },
  });

  dispatch(removeCatalogueItemAction(itemType, id));
  dispatch(clearAllBereavements());
  dispatch(clearItemsAction('packages'));
};

export const deleteCatalogueItemAction = (itemType, id) => (dispatch, getState, client) => {
  client.mutate({
    mutation: itemType === 'services' ? deleteServiceMutation : deleteProductMutation,
    variables: { input: { id } },
  });
  dispatch(removeCatalogueItemAction(itemType, id));
  dispatch(clearAllBereavements());
  dispatch(clearItemsAction('packages'));
};

export const publishCatalogueItemAction = (itemType, catalogueItem) => (dispatch, getState, client) => {
  const { id, isNewItem } = catalogueItem;
  client.mutate({
    mutation: itemType === 'services' ? publishServiceMutation : publishProductMutation,
    variables: { input: { id } },
  });
  if (!isNewItem) {
    dispatch(removeCatalogueItemAction(itemType, id));
  } else {
    dispatch(updateCatalogueItemAction(
      itemType,
      {
        ...catalogueItem,
        isNewItem: false,
        status: 'PUBLISHED',
      },
    ));
  }
  dispatch(clearAllBereavements());
};

export const editCatalogueItemAction = (itemType, catalogueItem) => (
  dispatch, getState, client,
) => {
  dispatch(updateCatalogueItemAction(itemType, catalogueItem));
  dispatch(clearAllBereavements());
  dispatch(clearItemsAction('packages'));

  client.mutate({
    mutation: itemType === 'services' ? editServiceMutation : editProductMutation,
    variables: { input: buildItemObjectForMutation(catalogueItem) },
  });
};

export const createCatalogueItemAction = (itemType, catalogueItem) => (
  dispatch, getState, client,
) => {
  const payload = {
    catalogueItems: [catalogueItem],
    itemType,
  };

  dispatch(addCatalogueItemsAction(payload));
  client.mutate({
    mutation: itemType === 'services' ? createServiceMutation : createProductMutation,
    variables: { input: createCatalogueItemTransform(catalogueItem) },
  });
};

export const fetchCatalogueItemAction = (itemType, id) => (
  dispatch, getState, client,
) => {
  dispatch(setIsLoadingAction(itemType, true));

  const isServiceType = itemType === 'services';

  client.query({
    query: itemType === 'services' ? getService : getProduct,
    variables: { id },
  }).then(({ data }) => {
    const payload = {
      catalogueItems: [isServiceType ? data.service : data.product],
      itemType,
    };

    dispatch(addCatalogueItemsAction(payload));
  }).finally(() => {
    dispatch(setIsLoadingAction(itemType, false));
  });
};

export const fetchAdminCatalogueItemsAction = (itemType, refreshConditions, isDebouncing) => (
  dispatch, getState, client,
) => {
  dispatch(setIsLoadingAdminCatalogueAction(true));
  dispatch(setIsLoadingAction(itemType, true));
  handleStoreRefreshActions(itemType, refreshConditions)(dispatch);

  const isServiceType = itemType === 'services';
  const { pagination, filters } = getState().catalogueStore[itemType];
  const isSupplierIdFilterSet = filters.supplierIds.length;
  const {
    products: {
      activeRequest: productsActiveRequest,
    },
    services: {
      activeRequest: servicesActiveRequest,
    },
  } = getState().catalogueStore;
  const activeRequest = isServiceType ? servicesActiveRequest : productsActiveRequest;
  const setActiveRequestAction = isServiceType ? setServicesActiveRequest : setProductsActiveRequest;

  if (activeRequest) {
    client.queryManager.stopQuery(activeRequest);
  }
  dispatch(setActiveRequestAction(client.queryManager.idCounter));

  client.query({
    query: isServiceType ? getServices : getProducts,
    variables: {
      pagination: {
        first: isSupplierIdFilterSet ? 1000 : pagination.first,
        after: pagination.after,
      },
      order: {
        strategy: 'TITLE',
        direction: 'ASCENDING',
      },
      ...filtersForQueryTransform(filters),
    },
  }).then(({ data }) => {
    const itemRoot = isServiceType ? data.services : data.products;
    const catalogueItems = itemRoot.edges.map(({ node }) => node);

    preloadImageVariants(catalogueItems, [
      mediaVariants.FIT_200_X_200,
      mediaVariants.FULL_FIT_300_X_230,
      mediaVariants.FULL_FIT_900_X_900,
    ], isServiceType ? 'services' : 'products');
    const updatedPagination = {
      first: pagination.first,
      after: itemRoot.pageInfo.endCursor,
      hasNextPage: itemRoot.pageInfo.hasNextPage,
    };

    const updatedCatalogueItems = isSupplierIdFilterSet
      ? catalogueItems
        .filter(item => item.supplier && filters.supplierIds
          .find(supplier => supplier.id === item.supplier.id))
      : catalogueItems;
    const payload = {
      catalogueItems: updatedCatalogueItems,
      filters: isDebouncing ? null : filters,
      pagination: updatedPagination,
      itemType,
    };

    dispatch(addCatalogueItemsAction(payload));
  }).finally(() => {
    dispatch(setIsLoadingAction(itemType, false));
    dispatch(setIsLoadingAdminCatalogueAction(false));
    dispatch(setActiveRequestAction(null));
  });
};

const loadMoreArrangementCatalogueItems = (client, getState, dispatch, itemType, homeIds, tags, endCursor = null) => {
  client.query({
    query: itemType === 'services' ? getServices : getProducts,
    variables: {
      pagination: {
        first: 500,
        after: endCursor,
      },
      organisationalUnitIds: homeIds,
      tags,
      status: statuses.PUBLISHED,
    },
  }).then(({ data, errors }) => {
    const { isLoadingAdminCatalogue } = getState().catalogueStore;

    if ((data === null && errors.length > 0) || (isLoadingAdminCatalogue)) {
      return;
    }

    if (!endCursor) {
      dispatch(clearItemsAction(itemType));
    }

    const catalogueItems = data[itemType].edges.map(({ node }) => node);
    preloadImageVariants([itemType], [
      mediaVariants.FIT_200_X_200,
      mediaVariants.FIT_900_X_900,
    ], 'products');

    const payload = {
      catalogueItems,
      itemType,
    };

    dispatch(addCatalogueItemsAction(payload));

    const { endCursor: lastElement, hasNextPage } = data[itemType].pageInfo;
    if (hasNextPage) {
      loadMoreArrangementCatalogueItems(client, getState, dispatch, itemType, homeIds, tags, lastElement);
    }
  }).finally(() => {
    dispatch(setIsLoadingAction(itemType, false));
  });
};

export const fetchArrangementCatalogueItemsAction = (itemType, tags) => (dispatch, getState, client) => {
  dispatch(setIsLoadingAction(itemType, true));
  const homeIds = getState().userStore.user.organisationalUnitIds;

  dispatch(clearPaginationAction(itemType));
  loadMoreArrangementCatalogueItems(client, getState, dispatch, itemType, homeIds, tags);
};

export const publishPackageAction = packageItem => (dispatch, getState, client) => {
  const { id, isNewItem } = packageItem;
  client.mutate({
    mutation: publishPackageMutation,
    variables: { input: { id } },
  });
  if (!isNewItem) {
    dispatch(removePackageAction(id));
  } else {
    dispatch(updatePackageAction({
      ...packageItem,
      isNewItem: false,
      status: 'PUBLISHED',
    }));
  }
  dispatch(clearAllBereavements());
};

export const createPackageAction = packageItem => (
  dispatch, getState, client,
) => {
  const packageWithId = addPackageTransform(packageItem);
  dispatch(addPackagesAction({ packages: [packageWithId] }));
  client.mutate({
    mutation: createPackageMutation,
    variables: { input: buildPackageObjectForMutation(packageWithId) },
  });
};

export const editPackageAction = packageItem => (dispatch, getState, client) => {
  dispatch(updatePackageAction(packageItem));
  dispatch(clearAllBereavements());
  client.mutate({
    mutation: editPackageMutation,
    variables: { input: buildPackageObjectForMutation(packageItem) },
  });
};

export const deletePackageAction = id => (dispatch, getState, client) => {
  client.mutate({
    mutation: deletePackageMutation,
    variables: { input: { id } },
  });
  dispatch(removePackageAction(id));
  dispatch(clearAllBereavements());
};

export const retirePackageAction = id => (dispatch, getState, client) => {
  client.mutate({
    mutation: retirePackageMutation,
    variables: { input: { id } },
  });
  dispatch(removePackageAction(id));
  dispatch(clearAllBereavements());
};

export const fetchPackageAction = id => (
  dispatch, getState, client,
) => {
  dispatch(setIsLoadingAction('packages', true));
  client.query({
    query: getPackage,
    variables: { id },
  }).then(({ data }) => {
    dispatch(addPackagesAction({ packages: [data.package] }));
  }).finally(() => {
    dispatch(setIsLoadingAction('packages', false));
  });
};

export const fetchAdminCataloguePackagesAction = (refreshConditions, isDebouncing) => (
  dispatch, getState, client,
) => {
  dispatch(setIsLoadingAction('packages', true));
  handleStoreRefreshActions('packages', refreshConditions)(dispatch);

  const { packages: { activeRequest } } = getState().catalogueStore;
  if (activeRequest) {
    client.queryManager.stopQuery(activeRequest);
  }
  dispatch(setPackagesActiveRequest(client.queryManager.idCounter));

  const { pagination, filters } = getState().catalogueStore.packages;

  client.query({
    query: getPackages,
    variables: {
      pagination: {
        first: pagination.first,
        after: pagination.after,
      },
      status: filters.status.enum,
      term: filters.term,
      organisationalUnitIds: filters.organisationalUnitIds.map(item => (typeof item === 'string' ? item : item.id)),
    },
  }).then(({ data }) => {
    const updatedPagination = {
      first: pagination.first,
      after: data.packages.pageInfo.endCursor,
      hasNextPage: data.packages.pageInfo.hasNextPage,
    };
    const payload = {
      packages: data.packages.edges.map(({ node }) => node),
      filters: isDebouncing ? null : filters,
      pagination: updatedPagination,
    };
    dispatch(addPackagesAction(payload));
  }).finally(() => {
    dispatch(setIsLoadingAction('packages', false));
    dispatch(setPackagesActiveRequest(null));
  });
};

const loadMoreAdminCataloguePackagesAction = (client, getState, dispatch, homeIds, endCursor = null) => {
  client.query({
    query: getPackages,
    variables: {
      pagination: {
        first: 10,
        after: endCursor,
      },
      organisationalUnitIds: homeIds,
      status: statuses.PUBLISHED,
    },
  }).then(({ data, errors }) => {
    const { isLoadingAdminCatalogue } = getState().catalogueStore;

    if ((data === null && errors.length > 0) || (isLoadingAdminCatalogue)) {
      return;
    }

    if (!endCursor) {
      dispatch(clearItemsAction('packages'));
    }

    const packages = data.packages.edges.map(({ node }) => node);

    const payload = {
      packages,
    };

    dispatch(addPackagesAction(payload));

    const { endCursor: lastElement, hasNextPage } = data.packages.pageInfo;
    if (hasNextPage) {
      loadMoreAdminCataloguePackagesAction(client, getState, dispatch, homeIds, lastElement);
    }
  }).finally(() => {
    dispatch(setIsLoadingAction('packages', false));
  });
};

export const fetchArrangementCataloguePackagesAction = () => (dispatch, getState, client) => {
  dispatch(setIsLoadingAction('packages', true));
  const homeIds = getState().userStore.user.organisationalUnitIds;

  dispatch(clearPaginationAction('packages'));
  loadMoreAdminCataloguePackagesAction(client, getState, dispatch, homeIds);
};

export const downloadCataloguePreviewAction = (
  categories,
  organisationalUnitId,
  callback,
) => (dispatch, getState, client) => {
  client
    .mutate({
      mutation: emailCataloguePreviewPDFMutation,
      variables: {
        categories: categories.map(item => item.category),
        homeId: organisationalUnitId,
      },
    })
    .then(() => {
      dispatch(enqueueSnackbarAction({
        message: t('PDF Preview email was sent'),
        options: {
          variant: 'success',
        },
      }));
    })
    .finally(() => callback());
};

export const orderCatalogueItemsAsyncAction = (itemsType, items, callback) => (dispatch, getState, client) => {
  const updatedOrderItems = items.map((item, index) => {
    const updatedOrderItem = {
      ...item,
      catalogueOrder: items.length - index,
    };
    const itemKey = itemsType === catalogueItemTypes.PRODUCT ? 'products' : 'services';
    dispatch(updateCatalogueItemAction(itemKey, updatedOrderItem));
    dispatch(clearAllBereavements());
    dispatch(clearItemsAction('packages'));
    return updatedOrderItem;
  });
  const mutation = itemsType === catalogueItemTypes.PRODUCT ? orderProductsMutation : orderServicesMutation;
  client.mutate({
    mutation,
    variables: {
      input: {
        catalogueSortAndVisibilities: catalogueOrderMutationTransform(updatedOrderItems),
      },
    },
  }).finally(() => callback());
};

export const orderCataloguePackagesAsyncAction = (items, callback) => (dispatch, getState, client) => {
  const updatedOrderItems = items.map((item, index) => {
    const updatedOrderItem = {
      ...item,
      catalogueOrder: items.length - index,
    };
    dispatch(updatePackageAction(updatedOrderItem));
    dispatch(clearAllBereavements());
    return updatedOrderItem;
  });
  client.mutate({
    mutation: orderCataloguePackagesMutation,
    variables: {
      input: {
        catalogueSortAndVisibilities: catalogueOrderMutationTransform(updatedOrderItems),
      },
    },
  }).finally(() => callback());
};

export const orderArrangementItemsAsyncAction = (itemsType, items, callback) => (dispatch, getState, client) => {
  const updatedOrderItems = items.map((item, index) => {
    const updatedOrderItem = {
      ...item,
      arrangementOrder: items.length - index,
    };
    const itemKey = itemsType === catalogueItemTypes.PRODUCT ? 'products' : 'services';
    dispatch(updateArrangementItemAction(itemKey, updatedOrderItem));
    return updatedOrderItem;
  });
  const mutation = itemsType === catalogueItemTypes.PRODUCT
    ? orderArrangementProductsMutation
    : orderArrangementServicesMutation;
  client.mutate({
    mutation,
    variables: {
      input: {
        arrangementSortAndVisibilities: arrangementOrderMutationTransform(updatedOrderItems),
      },
    },
  }).finally(() => callback());
};

export const orderArrangementPackagesAsyncAction = (items, callback) => (dispatch, getState, client) => {
  const updatedOrderItems = items.map((item, index) => {
    const updatedOrderItem = {
      ...item,
      arrangementOrder: items.length - index,
    };
    dispatch(updateArrangementItemAction('packages', updatedOrderItem));
    return updatedOrderItem;
  });
  const mutation = orderArrangementPackagesMutation;
  client.mutate({
    mutation,
    variables: {
      input: {
        arrangementSortAndVisibilities: arrangementOrderMutationTransform(updatedOrderItems),
      },
    },
  }).finally(() => callback());
};
