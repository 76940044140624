import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import Select from 'components/common/Select';
import { correspondenceLetterCategories, correspondenceLetterCategoryLabels } from 'constants/correspondence';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const TemplateDetails = ({
  template,
  isTemplateValid,
  errors,
  formRefs,
  onChangeTemplateDetails,
  onProceedToUpload,
}) => {
  const templateCategories = Object.keys(correspondenceLetterCategories);

  return (
    <div className={classNames(
      styles.c_page__full_height,
      styles.has_faded_background,
      styles.u_push__top_double,
    )}
    >
      <div className={styles.o_view}>
        <div className={classNames(
          styles.o_view__main,
          styles.o_block_content,
          rcStyles.v_spacing,
        )}
        >
          <div className={classNames(
            styles.long_primer,
            styles.is_black,
            rcStyles.form_item,
          )}
          >
            {t('Template details')}
          </div>
          <div
            className={rcStyles.form_item}
            ref={formRefs.type}
          >
            <Select
              label={t('Template type *')}
              value={template.category}
              onChange={event => onChangeTemplateDetails('category', event.target.value)}
              inputProps={{
                'data-test-id': 'templateType',
              }}
              fullWidth
              errorText={errors && errors.type}
            >
              {templateCategories.map(category => (
                <MenuItem key={category} value={category}>{t(correspondenceLetterCategoryLabels[category])}</MenuItem>
              ))}
            </Select>
          </div>
          <div
            className={rcStyles.form_item}
            ref={formRefs.title}
          >
            <TextField
              label={t('Letter template name *')}
              value={template.title || ''}
              onChange={event => onChangeTemplateDetails('title', event.target.value)}
              InputProps={{
                inputProps: {
                  'data-test-id': 'templateName',
                },
              }}
              error={errors && !!errors.title}
              helperText={errors && errors.title}
              fullWidth
            />
          </div>
          <div className={rcStyles.form_item}>
            <Button
              data-test-id={t('nextBtn')}
              onClick={onProceedToUpload}
              color="primary"
              variant="contained"
              disabled={!isTemplateValid}
            >
              {t('Next')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

TemplateDetails.propTypes = {
  template: PropTypes.objectOf(PropTypes.any).isRequired,
  isTemplateValid: PropTypes.bool.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  onChangeTemplateDetails: PropTypes.func.isRequired,
  onProceedToUpload: PropTypes.func.isRequired,
};

export default TemplateDetails;
