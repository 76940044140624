import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import IconLoad from 'icons/IconLoad';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import SearchIcon from '@material-ui/icons/Search';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {
  catalogueServiceCategories,
  catalogueProductCategories,
  catalogueStatuses,
} from 'constants/catalogue';
import DirectoryListingLookup from 'components/common/lookups/DirectoryListingLookup';
import OrganisationalUnitLookup from 'components/common/lookups/OrganisationalUnitLookup';
import CategoryLookup from 'components/common/lookups/CategoryLookup';
import StatusLookup from 'components/common/lookups/StatusLookup';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import PackageModalListItem from './PackageModalListItem';

import rcStyles from './styles.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const PackageItemModal = ({
  isServiceCategory,
  isOpen,
  onSave,
  isLoading,
  isDisbursementCategory,
  filters,
  onChangeFilters,
  catalogueItems,
  getCatalogueItems,
  hasMoreItems,
  onClose,
  onSelectCatalogueItem,
  selectedCatalogueItemIds,
}) => (
  <Dialog
    open={isOpen}
    onClose={onClose}
    fullScreen
    scroll="paper"
    TransitionComponent={Transition}
    disableBackdropClick
    disableEscapeKeyDown
  >
    <DialogTitle
      disableTypography
      className={styles.dialog_title_bar}
    >
      <IconButton
        className={classNames(
          styles.o_button__secondary,
          styles.is_close,
        )}
        color="primary"
        variant="contained"
        aria-label="Close"
        onClick={onClose}
        data-test-id="feeClose"
      >
        <CloseIcon />
      </IconButton>
      <div className={styles.is_title}>
        {isDisbursementCategory && t('Add disbursements & fees')}
        {isServiceCategory && !isDisbursementCategory && t('Add services & fees')}
        {!isServiceCategory && !isDisbursementCategory && t('Add products & fees')}
      </div>
      <div className={styles.is_actions}>
        <Button
          onClick={onSave}
          variant="contained"
          color="secondary"
          className={styles.o_button__secondary}
          data-test-id="saveClient"
        >
          {t('Save and return')}
        </Button>
      </div>
    </DialogTitle>
    <div className={styles.c_dialog__overflow}>
      <DialogContent>
        <div className={styles.o_block_content}>
          {!isDisbursementCategory && (
            <div className={rcStyles.options}>
              <TextField
                className={rcStyles.colspan2}
                value={filters.term}
                onChange={event => onChangeFilters('term', event.target.value, 'debounce')}
                label={t('Search')}
                type="search"
                InputProps={{
                  'data-test-id': 'term',
                  'startAdornment': (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <div className={rcStyles.rc_layer_order_0}>
                <CategoryLookup
                  value={filters.categories}
                  label={t('Select category')}
                  onChange={value => onChangeFilters('categories', value)}
                  categories={isServiceCategory
                    ? catalogueServiceCategories
                    : catalogueProductCategories}
                  isMulti
                  data-test-id="categoryLookup"
                />
              </div>
              <OrganisationalUnitLookup
                value={filters.organisationalUnitIds}
                label={t('Select home')}
                onChange={value => onChangeFilters(
                  'organisationalUnitIds', value,
                )}
                isMulti
                data-test-id="homeLookup"
                disabled
              />
              <div className={rcStyles.rc_layer_order_1}>
                <DirectoryListingLookup
                  value={filters.supplierIds}
                  label={t('Select supplier')}
                  isMulti
                  isSupplier
                  onChange={value => onChangeFilters('supplierIds', value)}
                  classes={[rcStyles.rc_lookup]}
                  data-test-id="directoryListingLookup"
                />
              </div>
              <StatusLookup
                value={filters.status}
                label={t('Select status')}
                onChange={value => onChangeFilters('status', value && value.value)}
                classes={[rcStyles.rc_lookup]}
                statuses={catalogueStatuses}
                disabled
              />
            </div>
          )}
          <div>
            {(isLoading && catalogueItems && catalogueItems.length === 0)
                && (
                  <div className={classNames(
                    styles.c_page__full_height,
                    styles.has_background,
                  )}
                  >
                    <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
                      <IconLoad />
                    </div>
                  </div>
                )
              }
            <div className={rcStyles.infinite_scroll_wrapper}>
              <InfiniteScroll
                dataLength={catalogueItems.length}
                hasMore={catalogueItems.length > 0 && hasMoreItems}
                next={getCatalogueItems}
                loader={catalogueItems.length > 0 && (
                <div className={styles.u_fullWidth}>
                  <div className={styles.c_svg_loader_infinite_scroll} data-test-id="infiniteScrollLoader">
                    <IconLoad />
                  </div>
                </div>
                )}
              >
                {catalogueItems
                  .filter(item => (
                    isDisbursementCategory ? item.category === 'DISBURSEMENTS' : item
                  ))
                  .map(item => (
                    <div
                      key={item.id}
                      className={classNames(
                        rcStyles.list_item,
                        item.isNewItem ? rcStyles.is_new : '',
                      )}
                    >
                      <PackageModalListItem
                        item={item}
                        isSelected={selectedCatalogueItemIds.includes(item.id)}
                        onSelectCatalogueItem={onSelectCatalogueItem}
                      />
                    </div>
                  ))}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </DialogContent>
    </div>
  </Dialog>
);

PackageItemModal.propTypes = {
  catalogueItems: PropTypes.arrayOf(PropTypes.shape({})),
  selectedCatalogueItemIds: PropTypes.arrayOf(PropTypes.string),
  isServiceCategory: PropTypes.bool,
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  isDisbursementCategory: PropTypes.bool,
  hasMoreItems: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  getCatalogueItems: PropTypes.func.isRequired,
  onChangeFilters: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onSelectCatalogueItem: PropTypes.func.isRequired,
};

export default PackageItemModal;
