import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import EstimatePackageRows from 'components/estimate/EstimatePackageRows';
import EstimateMainSelections from 'components/estimate/EstimateMainSelections';
import EstimateNonPackageSelectionsList from 'components/estimate/EstimateNonPackageSelectionsList';
import { arrangementType } from 'types/bereavement';
import classNames from 'classnames';
import styles from 'scss/main.scss';

const EstimateRows = ({
  bereavementId,
  arrangement,
  onOpenConfirmationWarningModal,
  disabled,
  onSortEnd,
}) => (
  <Fragment>
    {(arrangement.packageSelection) ? (
      <Fragment>
        <EstimatePackageRows
          bereavementId={bereavementId}
          arrangement={arrangement}
          onOpenConfirmationWarningModal={onOpenConfirmationWarningModal}
          disabled={disabled}
          onSortEnd={onSortEnd}
        />
        {!arrangement.packageSelection.package.isFixed && (
          <Fragment>
            <h2 className={classNames(
              styles.long_primer,
              styles.u_push__ends,
            )}
            >
              {t('Additional package options')}
            </h2>
            <EstimateNonPackageSelectionsList
              bereavementId={bereavementId}
              arrangement={arrangement}
              onOpenConfirmationWarningModal={onOpenConfirmationWarningModal}
              disabled={disabled}
              onSortEnd={onSortEnd}
              distance={1}
            />
          </Fragment>
        )}
      </Fragment>
    ) : (
      <EstimateMainSelections
        bereavementId={bereavementId}
        arrangement={arrangement}
        onOpenConfirmationWarningModal={onOpenConfirmationWarningModal}
        disabled={disabled}
        onSortEnd={onSortEnd}
      />
    )}
  </Fragment>
);

EstimateRows.propTypes = {
  bereavementId: PropTypes.string.isRequired,
  arrangement: arrangementType.isRequired,
  onOpenConfirmationWarningModal: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  onSortEnd: PropTypes.func.isRequired,
};

export default EstimateRows;
