import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { components, Creatable } from 'react-select';
import AsyncPaginate from 'react-select-async-paginate';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';

import { catalogueItemTypes } from 'constants/arrangement';
import { getAsyncLookupOptionValue } from 'services/utils';
import styles from 'scss/main.scss';
import { apolloClientType } from 'types/apollo';
import { reactRefType } from 'types/common';

import { getProductTags, getServiceTags } from './queries.gql';


const customSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? styles.colorGreyUltraLight : styles.colorWhite,
    color: 'inherit',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: '4 0',
    color: 'rgba(0, 0, 0, 0.54)',
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: 4,
    color: 'rgba(0, 0, 0, 0.54)',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '6px 0',
  }),
  input: provided => ({
    ...provided,
    margin: 0,
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 0,
    marginRight: 0,
  }),
  control: (provided, state) => {
    const focusedStyle = state.isFocused ? styles.colorOffWhite : 'inherit';
    return {
      'display': 'flex',
      'backgroundColor': state.hasValue ? 'inherit' : focusedStyle,
      '&:hover': {
        cursor: 'pointer',
      },
      '&:before': {
        'left': 0,
        'right': 0,
        'bottom': 0,
        'content': '"\\00a0"',
        'position': 'absolute',
        'transition': 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        'border-bottom': '1px solid',
        'borderBottomWidth': state.isFocused ? '2px' : '1px',
        'borderBottomColor': state.isFocused ? styles.colorHighlight : 'rgba(0, 0, 0, 0.42)',
        'pointer-events': 'none',
      },
    };
  },

  menu: provided => ({
    ...provided,
    marginTop: 0,
    paddingTop: 4,
    paddingBottom: 4,
  }),
};

const CustomClearIndicator = (props) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles('clearIndicator', props)}
    >
      <CloseIcon />
    </div>
  );
};

CustomClearIndicator.propTypes = {
  getStyles: PropTypes.func.isRequired,
  innerProps: PropTypes.shape({
    ref: reactRefType,
  }),
};

const CustomDropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <ArrowDropDownIcon />
  </components.DropdownIndicator>
);

class CatalogueItemTagsLookup extends Component {
  static propTypes = {
    client: apolloClientType.isRequired,
    label: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ])),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    classes: PropTypes.arrayOf(PropTypes.string.isRequired),
    isMulti: PropTypes.bool,
    catalogueItemType: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  getTags = async (term, prevResults, prevMetadata) => {
    const { catalogueItemType } = this.props;
    const query = (catalogueItemType === catalogueItemTypes.SERVICE)
      ? getServiceTags
      : getProductTags;
    const { category } = this.props;
    const { client } = this.props;
    const lastCursor = (prevMetadata && prevMetadata.lastCursor) ? prevMetadata.lastCursor : null;

    const data = await client.query({
      query,
      variables: {
        category,
        order: {
          strategy: 'VALUE',
          direction: 'ASCENDING',
        },
        pagination: {
          first: 15,
          after: lastCursor,
        },
        term: (term !== '') ? term : null,
      },
    });
    const { edges, pageInfo } = catalogueItemType === catalogueItemTypes.SERVICE
      ? data.data.serviceTags
      : data.data.productTags;

    const newResults = edges.map(({ node }) => ({
      label: node.value,
      value: node.value,
    }));

    return {
      options: newResults,
      hasMore: pageInfo.hasNextPage,
      additional: {
        lastCursor: pageInfo.endCursor,
      },
    };
  }

  handleChange = (selected) => {
    const { isMulti, onChange } = this.props;

    if (isMulti) {
      return onChange(selected.map(item => item.value));
    }

    return onChange(selected);
  }

  handleCreate = (inputValue) => {
    const { value: propValue } = this.props;
    const prevOptions = propValue
      ? propValue.map(item => ({
        label: item,
        value: item,
      }))
      : [];
    const options = [...prevOptions, {
      label: inputValue?.toUpperCase(),
      value: inputValue?.toUpperCase(),
    }];

    return this.handleChange(options);
  };

  render() {
    const {
      label,
      disabled,
      isMulti,
      classes,
      category,
    } = this.props;
    const { value: propValue } = this.props;

    const value = propValue
      ? propValue.map(item => ({
        label: item,
        value: item,
      }))
      : null;

    return (
      <div>
        <AsyncPaginate
          id="tagsSelect"
          SelectComponent={Creatable}
          className={classNames(classes)}
          value={value}
          cacheUniq={category}
          allowCreateWhileLoading
          formatCreateLabel={inputValue => `Create ${inputValue?.toUpperCase()}`}
          onChange={this.handleChange}
          onCreateOption={this.handleCreate}
          placeholder={label}
          loadOptions={this.getTags}
          getOptionValue={option => getAsyncLookupOptionValue(option)}
          debounceTimeout={500}
          isClearable
          isMulti={isMulti}
          isDisabled={disabled}
          styles={customSelectStyles}
          components={{
            ClearIndicator: CustomClearIndicator,
            DropdownIndicator: CustomDropdownIndicator,
          }}
        />
      </div>
    );
  }
}

export default withApollo(CatalogueItemTagsLookup);
