import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { debounce } from 'debounce';
import PropTypes from 'prop-types';
import { statusActions } from 'constants/adminCatalogue';
import { matchType } from 'types/reactRouter';
import { productType, serviceType } from 'types/bereavement';
import {
  updateFiltersAction,
  fetchAdminCatalogueItemsAction,
  retireCatalogueItemAction,
  deleteCatalogueItemAction,
  publishCatalogueItemAction,
} from 'actions/catalogue';
import AdminCatalogueItemListScreen from './AdminCatalogueItemListScreen';

class AdminCatalogueItemListScreenContainer extends Component {
  debounceGetCatalogueItems = debounce((refreshConditions) => {
    this.getCatalogueItems(refreshConditions, true);
  }, 500);

  static propTypes = {
    match: matchType,
    catalogueItemType: PropTypes.string.isRequired,
    updateFilters: PropTypes.func.isRequired,
    retireCatalogueItem: PropTypes.func.isRequired,
    publishCatalogueItem: PropTypes.func.isRequired,
    deleteCatalogueItem: PropTypes.func.isRequired,
    fetchCatalogueItems: PropTypes.func.isRequired,
    isServiceCategory: PropTypes.bool,
    products: PropTypes.arrayOf(productType),
    services: PropTypes.arrayOf(serviceType),
    filters: PropTypes.objectOf(PropTypes.any).isRequired,
    pagination: PropTypes.objectOf(PropTypes.any).isRequired,
    isLoading: PropTypes.bool.isRequired,
  }

  componentDidMount() {
    const { products, services, isServiceCategory } = this.props;
    const hasCatalogueItems = isServiceCategory
      ? !!services.length
      : !!products.length;

    if (!hasCatalogueItems.length) {
      this.getCatalogueItems();
    }
  }

  getCatalogueItems = (refreshConditions, isDebouncing) => {
    const { fetchCatalogueItems } = this.props;

    fetchCatalogueItems(refreshConditions, isDebouncing);
  }

  handleChangeFilters = (key, value, shouldDebounce) => {
    const { updateFilters, filters } = this.props;
    const refreshConditions = { clearPagination: true };

    if (value === filters[key]) {
      return;
    }

    updateFilters(key, value);

    if (shouldDebounce) {
      this.debounceGetCatalogueItems(refreshConditions);
      return;
    }

    this.getCatalogueItems(refreshConditions);
  }

  handleStatusChange = (item, statusAction) => {
    const {
      retireCatalogueItem,
      deleteCatalogueItem,
      publishCatalogueItem,
    } = this.props;

    switch (statusAction) {
      case statusActions.PUBLISH:
        return publishCatalogueItem(item);
      case statusActions.RETIRE:
        return retireCatalogueItem(item.id);
      case statusActions.DELETE:
        return deleteCatalogueItem(item.id);
      default:
        return false;
    }
  }

  orderCatalogueItems = catalogueItems => ([
    ...catalogueItems.filter(item => item.isNewItem),
    ...catalogueItems.filter(item => !item.isNewItem),
  ]);

  render() {
    const {
      match,
      catalogueItemType,
      products,
      services,
      pagination,
      filters,
      isLoading,
      isServiceCategory,
    } = this.props;
    const catalogueItems = this.orderCatalogueItems(isServiceCategory ? services : products);

    return (
      <AdminCatalogueItemListScreen
        isServiceCategory={isServiceCategory}
        hasMoreItems={catalogueItems.length > 0 && pagination.hasNextPage}
        catalogueItemType={catalogueItemType}
        catalogueItems={catalogueItems}
        match={match}
        onChangeFilters={this.handleChangeFilters}
        filters={filters}
        isLoading={isLoading}
        getCatalogueItems={this.getCatalogueItems}
        onStatusChange={this.handleStatusChange}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const itemType = ownProps.isServiceCategory ? 'services' : 'products';

  return {
    products: state.catalogueStore.products.items,
    services: state.catalogueStore.services.items,
    filters: state.catalogueStore[itemType].filters,
    pagination: state.catalogueStore[itemType].pagination,
    isLoading: state.catalogueStore.services.isLoading
      || state.catalogueStore.products.isLoading,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const itemType = ownProps.isServiceCategory ? 'services' : 'products';

  return bindActionCreators({
    updateFilters: (key, value) => updateFiltersAction(itemType, key, value),
    fetchCatalogueItems: (refreshConditions, isDebouncing) => (
      fetchAdminCatalogueItemsAction(itemType, refreshConditions, isDebouncing)
    ),
    retireCatalogueItem: id => retireCatalogueItemAction(itemType, id),
    deleteCatalogueItem: id => deleteCatalogueItemAction(itemType, id),
    publishCatalogueItem: item => publishCatalogueItemAction(itemType, item),
  }, dispatch);
};

export default withApollo(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AdminCatalogueItemListScreenContainer),
  ),
);
