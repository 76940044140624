import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconLoad from 'icons/IconLoad';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const ArrangementOtherForm = ({
  categoryInformation,
  disabled,
  isSaving,
  onChange,
  onSave,
}) => (
  <div className={rcStyles.v_spacing}>
    <TextField
      label={t('Note')}
      value={categoryInformation.note || ''}
      onChange={event => onChange('note', event.target.value)}
      disabled={disabled}
      inputProps={{ 'data-test-id': 'categoryInformation' }}
      fullWidth
    />
    {!categoryInformation.isFamilyArranged && (
      <div className={styles.c_confirmed__wrap}>
        <FormControlLabel
          control={(
            <Checkbox
              className={styles.c_confirmed__checkbox}
              checked={categoryInformation.isConfirmed || false}
              onChange={event => onChange('isConfirmed', event.target.checked)}
              inputProps={{ 'data-test-id': 'isConfirmed' }}
              disabled={disabled}
              color="primary"
            />
          )}
          label={t('Order placed/Availability confirmed')}
        />
      </div>
    )}
    <span>
      <Button
        onClick={onSave}
        disabled={disabled}
        color="primary"
        data-test-id="saveOtherInformation"
        variant="contained"
      >
        {t('Save')}
      </Button>
    </span>
    {isSaving && (
      <div className={styles.o_fb__item__whole}>
        <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
          <IconLoad title={t('Saving')} />
        </div>
      </div>
    )}
  </div>
);

ArrangementOtherForm.propTypes = {
  categoryInformation: PropTypes.objectOf(PropTypes.any),
  isSaving: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default ArrangementOtherForm;
