import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { nameType } from 'types/common';
import { validationSchema } from './validation';
import AssignCaseModal from './AssignCaseModal';

class AssignCaseModalContainer extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    saveButtonText: PropTypes.string,
    home: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    owner: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: nameType.isRequired,
    }),
    isOpen: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool,
    isDeceasedFormValid: PropTypes.bool,
    isBereavedFormValid: PropTypes.bool,
    onHomeChange: PropTypes.func.isRequired,
    onOwnerChange: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    setErrors: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any),
    generateRefs: PropTypes.func.isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      isValidationEnabled: false,
    };
  }

  componentDidMount() {
    const { generateRefs } = this.props;
    generateRefs(Object.keys(validationSchema.fields));
  }

  handleHomeChange = (change) => {
    const {
      onHomeChange, validate, setErrors,
    } = this.props;
    const { isValidationEnabled } = this.state;

    const data = {
      homeId: change ? change.id : null,
      ownerId: null,
    };
    validate(data, validationSchema);

    if (!isValidationEnabled) {
      setErrors({});
    }
    onHomeChange(change);
  };

  handleOwnerChange = (change) => {
    const {
      onOwnerChange, validate, home, setErrors,
    } = this.props;
    const { isValidationEnabled } = this.state;

    const data = {
      homeId: home.id,
      ownerId: change ? change.id : null,
    };
    validate(data, validationSchema);

    if (!isValidationEnabled) {
      setErrors({});
    }
    onOwnerChange(change);
  };

  handleSave = () => {
    const {
      validate, home, owner, onSave,
    } = this.props;
    this.setState({ isValidationEnabled: true });

    const homeId = home && home.id;
    const ownerId = owner && owner.id;

    const isValid = validate({ homeId, ownerId }, validationSchema, true);
    onSave(isValid);
  }

  render() {
    const {
      title, saveButtonText, home, owner, isOpen, isSaving, errors,
      formRefs, isDeceasedFormValid, isBereavedFormValid, onClose,
    } = this.props;
    const { isValidationEnabled } = this.state;

    const displayDeceasedError = isDeceasedFormValid === false && isValidationEnabled;
    const displayBereavedError = isBereavedFormValid === false && isValidationEnabled;

    return (
      <AssignCaseModal
        title={title}
        saveButtonText={saveButtonText}
        selectedHome={home}
        selectedOwner={owner}
        isOpen={isOpen}
        isSaving={isSaving}
        displayDeceasedError={displayDeceasedError}
        displayBereavedError={displayBereavedError}
        onHomeChange={this.handleHomeChange}
        onOwnerChange={this.handleOwnerChange}
        onClose={onClose}
        onSave={this.handleSave}
        formRefs={formRefs}
        errors={errors}
      />
    );
  }
}

export default withApollo(withValidation(AssignCaseModalContainer));
