import React, { Fragment } from 'react';
import { t } from 'i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import BereavedPersonCard from 'components/common/cards/BereavedPersonCard';
import NameFormSection from 'components/common/NameFormSection';
import AddressFormSection from 'components/common/AddressFormSection';
import DateTimePicker from 'components/common/DateTimePicker';
import ClientForm from 'components/client/ClientForm';
import MediaUpload from 'components/common/MediaUpload';
import CharityFormSection from 'components/charity/CharityFormSection';
import { buildAddressString } from 'services/utils';
import { bereavedPersonConnectionType } from 'types/bereavement';
import { mediaType } from 'types/media';

import styles from 'scss/main.scss';
import classNames from 'classnames';
import ObituaryAdministratorList from 'components/obituary/ObituaryAdministratorList';
import rcStyles from './styles.scss';

const ArrangementFuneralGuideForm = ({
  funeralDate,
  deceasedDates,
  bereavedPeopleConnections,
  selectedAdministrator,
  formData,
  disabled,
  isObituaryPublished,
  serviceVenueInformation,
  committalInformation,
  image,
  isClientFormOpen,
  onChange,
  onClientEdit,
  onClientClose,
  onClientSave,
  formRefs,
  errors,
}) => (
  <div className={rcStyles.v_spacing}>

    <MediaUpload
      images={image ? [image] : []}
      acceptImages
      showImagePreview
      provisionalUploadMessage={t('')}
      disabled={disabled}
      contentDisposition="inline"
      onUploadStart={uploadImageId => onChange('imageId', uploadImageId)}
      onUploadDone={([uploadedImage]) => onChange('image', uploadedImage)}
      data-test-id="funeralGuideImageUpload"
      className={styles.u_push__bottom}
    />
    <div ref={formRefs.deceasedName}>
      <h3 className={classNames(
        styles.long_primer,
        styles.u_push__bottom,
      )}
      >
        Deceased
      </h3>
      <NameFormSection
        name={formData.deceasedName || {}}
        labelTitle={t('Title')}
        onChangeHandler={name => onChange('deceasedName', name)}
        labelGivenName={t('First Name')}
        labelFamilyName={t('Last Name')}
        disabled={disabled || isObituaryPublished}
        errors={errors && errors.deceasedName}
      />
    </div>

    <p>{deceasedDates}</p>
    <div className={rcStyles.funeral_date_container}>
      <div ref={formRefs.funeralDate}>
        {funeralDate && (
          <Fragment>
            <h3 className={styles.long_primer}>
              {t('Funeral details')}
            </h3>
            <p>{`${t('Funeral Date: ')} ${moment(funeralDate).format('DD MMM YYYY')}`}</p>
          </Fragment>
        )}
        {errors.funeralDate && (
          <FormHelperText error>
            {errors.funeralDate}
          </FormHelperText>
        )}
      </div>
      {serviceVenueInformation && (
        <div
          ref={formRefs.isOnlineObituaryServiceInfo}
        >
          <FormControlLabel
            control={(
              <Checkbox
                checked={formData.isOnlineObituaryServiceInfo || false}
                onChange={event => onChange('isOnlineObituaryServiceInfo', event.target.checked)}
                inputProps={{ 'data-test-id': 'isOnlineObituaryServiceInfo' }}
                disabled={disabled || isObituaryPublished}
                color="primary"
              />
            )}
            label={t('Include service')}
          />
          <div className={styles.o_fb__item}>
            {formData.isOnlineObituaryServiceInfo && (
              <div className={classNames(
                styles.o_block_content,
                styles.u_push__ends,
              )}
              >
                <p className={styles.u_push__bottom_half}>
                  {`${t('Location')}: ${buildAddressString(serviceVenueInformation.address)}`}
                </p>
                <p>{`${t('Time')}: ${moment(serviceVenueInformation.startDateTime).format('ddd DD MMM - HH:mm')}`}</p>
              </div>
            )}
          </div>
        </div>
      )}
      {committalInformation && (
        <div
          ref={formRefs.isOnlineObituaryCommittal}
        >
          <FormControlLabel
            control={(
              <Checkbox
                checked={formData.isOnlineObituaryCommittal || false}
                onChange={event => onChange('isOnlineObituaryCommittal', event.target.checked)}
                inputProps={{ 'data-test-id': 'isOnlineObituaryCommittal' }}
                disabled={disabled || isObituaryPublished}
                color="primary"
              />
            )}
            label={t('Include committal')}
          />
          {formData.isOnlineObituaryCommittal && (
            <div className={classNames(
              styles.o_block_content,
              styles.u_push__ends,
            )}
            >
              <div>
                <p className={classNames(styles.u_push__bottom_half)}>
                  {`${t('Location')}: ${buildAddressString(committalInformation.address)}`}
                </p>
                <p>{`${t('Time')}: ${moment(committalInformation.startDateTime).format('ddd DD MMM - HH:mm')}`}</p>
              </div>
            </div>
          )}
        </div>
      )}
      <div
        ref={formRefs.isOnlineObituaryReception}
      >
        <FormControlLabel
          control={(
            <Checkbox
              checked={formData.isOnlineObituaryReception || false}
              onChange={event => onChange('isOnlineObituaryReception', event.target.checked)}
              inputProps={{ 'data-test-id': 'isOnlineObituaryReception' }}
              disabled={disabled || isObituaryPublished}
              color="primary"
            />
          )}
          label={t('Include reception')}
        />
      </div>
      {formData.isOnlineObituaryReception && (
        <div
          ref={formRefs.onlineReception}
          className={classNames(
            styles.o_block_content,
            styles.has_no_shadow,
            styles.is_secondary,
          )}
        >
          <div className={styles.u_push__bottom}>
            <AddressFormSection
              address={formData.onlineReception ? formData.onlineReception.address : {}}
              onChange={address => onChange('onlineReception', { ...formData.onlineReception, address })}
              data-test-id="onlineReceptionAddress"
              lookupLabel={t('Add location')}
              disabled={disabled || isObituaryPublished}
              errors={errors && errors.onlineReception && errors.onlineReception.address}
            />
          </div>
          <div className={styles.u_push__bottom}>
            <DateTimePicker
              value={formData.onlineReception ? formData.onlineReception.dateTime : null}
              label={t('Date & time')}
              onChange={dateTime => onChange('onlineReception', { ...formData.onlineReception, dateTime })}
              isClearable
              disablePast
              disabled={disabled || isObituaryPublished}
              errors={errors && errors.onlineReception && !!errors.onlineReception.dateTime}
              helperText={errors && errors.onlineReception && errors.onlineReception.dateTime}
              data-test-id="onlineReceptionDateTime"
            />
          </div>
        </div>
      )}
    </div>
    <div>
      <div className={rcStyles.administrator_head}>
        <div className={rcStyles.administrator_head__body}>
          <h3 className={styles.long_primer}>{t('Obituary administrator')}</h3>
          <p className={classNames(
            styles.brevier,
            styles.is_italic,
          )}
          >
            {t('An email and mobile number are required for the best experience')}
          </p>
        </div>
        <div
          ref={formRefs.onlineAdministratorBereavedPersonConnection}
          className={rcStyles.administrator_head__action}
        >
          <ObituaryAdministratorList
            bereavedPeopleConnections={bereavedPeopleConnections}
            selectedAdministrator={selectedAdministrator}
            onAdministratorChange={administrator => onChange('onlineAdministratorBereavedPersonConnection', administrator)}
            disabled={disabled || isObituaryPublished}
          />
        </div>
      </div>
      <BereavedPersonCard
        bereavedPersonConnection={selectedAdministrator}
        disabled={disabled || isObituaryPublished}
        onEdit={onClientEdit}
      />
      {errors.onlineAdministratorBereavedPersonConnectionId && (
        <FormHelperText error>
          {errors.onlineAdministratorBereavedPersonConnectionId}
        </FormHelperText>
      )}
      {errors.onlineAdministratorBereavedPersonConnection && (
        <FormHelperText error>
          {errors.onlineAdministratorBereavedPersonConnection}
        </FormHelperText>
      )}
    </div>
    <div
      ref={formRefs.charityInformation}
      className={rcStyles.charity}
    >

      <h3 className={classNames(
        styles.long_primer,
        styles.u_push__bottom,
      )}
      >
        {t('Charity(s)')}
      </h3>

      <CharityFormSection
        charityInformation={formData.charityInformation}
        disabled={disabled || isObituaryPublished}
        onChange={charities => onChange('charityInformation', charities)}
        errors={errors.charityInformation}
        data-test-id="charityFormSection"
      />
    </div>
    <div className={styles.o_fb__item}>
      <ClientForm
        bereavedPersonConnection={selectedAdministrator}
        isOpen={isClientFormOpen}
        isAdding={false}
        isSaving={false}
        onClose={onClientClose}
        onSave={onClientSave}
      />
    </div>
  </div>
);

ArrangementFuneralGuideForm.propTypes = {
  funeralDate: PropTypes.string,
  deceasedDates: PropTypes.string.isRequired,
  bereavedPeopleConnections: PropTypes.arrayOf(bereavedPersonConnectionType).isRequired,
  selectedAdministrator: PropTypes.objectOf(PropTypes.any).isRequired,
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
  disabled: PropTypes.bool.isRequired,
  isObituaryPublished: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  serviceVenueInformation: PropTypes.objectOf(PropTypes.any),
  committalInformation: PropTypes.objectOf(PropTypes.any),
  image: mediaType,
  isClientFormOpen: PropTypes.bool.isRequired,
  onClientEdit: PropTypes.func.isRequired,
  onClientClose: PropTypes.func.isRequired,
  onClientSave: PropTypes.func.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ArrangementFuneralGuideForm;
