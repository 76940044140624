import PropTypes from 'prop-types';

export const variantsType = PropTypes.shape({
  uri: PropTypes.string,
  name: PropTypes.string,
});

export const mediaType = PropTypes.shape({
  id: PropTypes.string,
  locationId: PropTypes.string,
  tenantId: PropTypes.string,
  uri: PropTypes.string,
  privacy: PropTypes.string,
  variants: PropTypes.arrayOf(variantsType),
});
