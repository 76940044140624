import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';

import classNames from 'classnames';
import rcStyles from './styles.scss';

const DynamicList = ({
  component,
  fields,
  errors,
  disabled,
  secondary,
  addButtonEnabled,
  addButtonProps,
  addButtonInner,
  addButtonComponent = <Button data-test-id="add" />,
  removeButtonEnabled,
  removeButtonProps,
  removeButtonInner,
  keyForIndex,
  onChange,
  onAdd,
  onRemove,
  hideAddButton,
  hideRemoveButton,
  isStacked,
  hasPadding,
  classes,
  'data-test-id': testId,
}) => (
  <div
    data-test-id={testId || 'dynamicList'}
    className={classNames(
      rcStyles.dynamic_list,
      isStacked && rcStyles.is_stacked,
      hasPadding && rcStyles.has_padding,
      classes,
    )}
  >
    {!hideAddButton && (
      <div className={rcStyles.dynamic_list__button}>
        <addButtonComponent.type
          {...addButtonProps}
          onClick={onAdd}
          disabled={!addButtonEnabled}
          data-test-id="dynamicListAddButton"
        >
          {addButtonInner || t('Add')}
        </addButtonComponent.type>
      </div>
    )}
    <ul className={rcStyles.dynamic_list__content}>
      {fields.map((field, index) => (
        <li
          className={rcStyles.dynamic_list__item}
          key={keyForIndex(index)}
        >
          <Fragment>
            <div className={classNames(
              rcStyles.dynamic_list__body,
              secondary && rcStyles.is_secondary,
            )}
            >
              <component.type
                {...component.props}
                fieldData={field}
                index={index}
                key={index}
                errors={typeof errors === 'object' ? errors[index] : null}
                onChange={value => onChange(index, value)}
                onRemove={() => onRemove(index, field)}
                disabled={disabled}
                data-test-id={component.props['data-test-id']}
              />
            </div>
            {!hideRemoveButton && (
              <div className={rcStyles.list_item__action}>
                <Button
                  {...removeButtonProps}
                  onClick={() => onRemove(index, field)}
                  disabled={!removeButtonEnabled}
                  data-test-id="dynamicListRemoveButton"
                >
                  {removeButtonInner || t('Remove')}
                </Button>
              </div>
            )}
          </Fragment>
        </li>
      ))}
      {errors && typeof errors === 'string' && (
        <FormHelperText className={rcStyles.rc_error} error>{errors}</FormHelperText>
      )}
    </ul>
  </div>
);

DynamicList.propTypes = {
  'component': PropTypes.element.isRequired,
  'fields': PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  'errors': PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  'disabled': PropTypes.bool,
  'secondary': PropTypes.bool,
  'onChange': PropTypes.func.isRequired,
  'onAdd': PropTypes.func.isRequired,
  'addButtonEnabled': PropTypes.bool.isRequired,
  'addButtonProps': PropTypes.objectOf(PropTypes.any),
  'addButtonInner': PropTypes.element,
  'isStacked': PropTypes.bool,
  'hasPadding': PropTypes.bool,
  'onRemove': PropTypes.func.isRequired,
  'removeButtonEnabled': PropTypes.bool.isRequired,
  'removeButtonProps': PropTypes.objectOf(PropTypes.any),
  'removeButtonInner': PropTypes.element,
  'keyForIndex': PropTypes.func.isRequired,
  'classes': PropTypes.string,
  'addButtonComponent': PropTypes.node,
  'hideAddButton': PropTypes.bool,
  'hideRemoveButton': PropTypes.bool,
  'data-test-id': PropTypes.string,
};

export default DynamicList;
