import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import classNames from 'classnames';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import Price from 'components/common/Price';
import styles from 'scss/main.scss';
import { serviceVariantType } from 'types/bereavement';

import rcStyles from './styles.scss';

const CatalogueSelectionItem = ({
  title,
  variants,
  selectedVariantId,
  onClickAdd,
  onSelectedVariantChanged,
}) => (
  <div className={rcStyles.action_list}>
    <div className={rcStyles.action_list__main}>
      <div className={rcStyles.is_title}>
        {title}
      </div>
      {variants.length <= 1 ? (
        <Price price={variants[0].prices.sale} />
      ) : (
        <Select
          label={t('Variations')}
          value={selectedVariantId}
          onChange={event => onSelectedVariantChanged(event.target.value)}
          inputProps={{
            'data-test-id': 'selectedVariant',
          }}
          fullWidth
        >
          {variants.map(variant => (
            <MenuItem key={variant.id} value={variant.id}>
              {variant.name}
              <Price price={variant.prices.sale} />
            </MenuItem>
          ))}
        </Select>
      )}
    </div>
    <div className={rcStyles.action_list__button}>
      <Button
        color="primary"
        variant="outlined"
        size="small"
        onClick={onClickAdd}
        className={classNames(styles.o_button__round)}
        data-test-id="add"
      >
        <AddIcon />
      </Button>
    </div>
  </div>
);

CatalogueSelectionItem.propTypes = {
  title: PropTypes.string.isRequired,
  variants: PropTypes.arrayOf(serviceVariantType).isRequired,
  selectedVariantId: PropTypes.string.isRequired,
  onClickAdd: PropTypes.func.isRequired,
  onSelectedVariantChanged: PropTypes.func.isRequired,
};

export default CatalogueSelectionItem;
