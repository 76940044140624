import React, { Component } from 'react';
import { connect } from 'react-redux';

import { RootState } from 'types/ts/state';

import { Props, State } from './index.types';
import AnnouncementsSection from './AnnouncementsSection';

const LOCAL_STORAGE_ANNOUNCEMENTS_LAST_DATETIME_KEY = 'announcementsLastDateTime';
const LOCAL_STORAGE_ANNOUNCEMENTS_EXPANDED_PREFERENCE_KEY = 'announcementsExpandedPreference';

class AnnouncementsSectionContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const lastAnnouncementsDateTime = localStorage.getItem(LOCAL_STORAGE_ANNOUNCEMENTS_LAST_DATETIME_KEY);
    const currentAnnouncementsDateTime = props?.releaseAnnouncement?.dateTime;
    const isNewAnnouncement = currentAnnouncementsDateTime !== lastAnnouncementsDateTime;
    let isExpanded = true;

    if (currentAnnouncementsDateTime && isNewAnnouncement) {
      localStorage.setItem(LOCAL_STORAGE_ANNOUNCEMENTS_LAST_DATETIME_KEY, currentAnnouncementsDateTime);
      localStorage.setItem(LOCAL_STORAGE_ANNOUNCEMENTS_EXPANDED_PREFERENCE_KEY, 'true');
    } else {
      const announcementsExpanded = localStorage.getItem(LOCAL_STORAGE_ANNOUNCEMENTS_EXPANDED_PREFERENCE_KEY) || 'true';
      isExpanded = announcementsExpanded === 'true';
    }

    this.state = {
      isExpanded,
    };
  }

  handleToggleExpanded = () => {
    this.setState((prevState) => {
      localStorage.setItem(LOCAL_STORAGE_ANNOUNCEMENTS_EXPANDED_PREFERENCE_KEY, `${!prevState.isExpanded}`);
      return {
        isExpanded: !prevState.isExpanded,
      };
    });
  }

  render() {
    const { releaseAnnouncement } = this.props;
    const { isExpanded } = this.state;

    if (releaseAnnouncement) {
      return (
        <AnnouncementsSection
          releaseAnnouncement={releaseAnnouncement}
          isExpanded={isExpanded}
          onToggleIsExpanded={this.handleToggleExpanded}
        />
      );
    }
    return null;
  }
}

const mapStateToProps = (state: RootState) => ({
  releaseAnnouncement: state.userStore.user?.releaseAnnouncement,
});

export default connect(mapStateToProps)(AnnouncementsSectionContainer);
