import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import IconMore from '@material-ui/icons/ExpandMore';
import IconLess from '@material-ui/icons/ExpandLess';
import IconApps from '@material-ui/icons/Apps';
import Price from 'components/common/Price';
import { getUriForMediaByVariant } from 'services/utils';
import { mediaVariants } from 'constants/media';
import { productType } from 'types/bereavement';
import { mediaType } from 'types/media';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const CatalogueItemDetails = ({
  item,
  isInPackage,
  isDescriptionExpanded,
  activeImage,
  onToggleDescription,
  getShortDescription,
  onImageChange,
}) => (
  <Fragment>
    <div className={rcStyles.product_lite}>
      <Fragment>
        {isInPackage && (
          <div className={rcStyles.product_lite__header}>
            <IconApps />
            {t('Available with package')}
          </div>
        )}
      </Fragment>
      <div className={rcStyles.product_lite__main_image}>
        {activeImage && (
          <img
            alt={item.title}
            src={getUriForMediaByVariant(activeImage, mediaVariants.FIT_900_X_900)}
          />
        )}
      </div>
      <div className={rcStyles.product_lite__variants}>
        {item.images && item.images.length > 1 && item.images.map(iconImage => (
          <Fragment key={iconImage.id}>
            <div
              className={classNames(
                rcStyles.is_thumbnail,
                iconImage.id === activeImage.id ? rcStyles.rc_active_img : null,
              )}
            >
              <Button onClick={() => onImageChange(iconImage)} data-test-id="selectImage">
                <div
                  style={{ backgroundImage: `url('${iconImage.uri}')` }}
                  className={rcStyles.has_background_image}
                />
              </Button>
            </div>
          </Fragment>
        ))}
      </div>
      <div className={rcStyles.product_lite__pricing}>
        <div className={rcStyles.is_title}>
          <h3 className={classNames(
            styles.pica,
            styles.is_black,
          )}
          >
            {item.title}
          </h3>
          <p className={styles.primer}>{item.subtitle}</p>
        </div>
        <div>
          <Price variants={item.variants} />
        </div>
        <div className={rcStyles.product_lite__description}>
          {item.description && item.description.length > 50 ? (
            <Fragment>
              <div>
                {isDescriptionExpanded ? (
                  item.description
                ) : (
                  getShortDescription(item.description)
                )}
              </div>
              <Button
                variant="text"
                onClick={onToggleDescription}
                data-test-id="toggleDescription"
              >
                {isDescriptionExpanded ? (
                  <div className={rcStyles.toggle_button}>
                    {t('Less')}
                    <IconLess />
                  </div>
                ) : (
                  <div className={rcStyles.toggle_button}>
                    {t('More')}
                    <IconMore />
                  </div>
                )}
              </Button>
            </Fragment>
          ) : (
            <div className={rcStyles.rc_description}>
              {item.description}
            </div>
          )}
        </div>
      </div>
    </div>
  </Fragment>
);

CatalogueItemDetails.propTypes = {
  item: productType.isRequired,
  isInPackage: PropTypes.bool,
  isDescriptionExpanded: PropTypes.bool.isRequired,
  activeImage: mediaType,
  onToggleDescription: PropTypes.func.isRequired,
  getShortDescription: PropTypes.func.isRequired,
  onImageChange: PropTypes.func.isRequired,
};


export default CatalogueItemDetails;
