
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FullPossession"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Possession"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"type"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"description"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"imageId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"image"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FullMedia"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"requiredForViewing"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"familyWishes"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"note"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"actionHistory"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"action"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"timeCreated"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"createdById"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"createdBy"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FullName"},"directives":[]}]}}]}}]}}]}}],"loc":{"start":0,"end":340}};
    doc.loc.source = {"body":"#import \"fragments/name.gql\"\n#import \"fragments/media.gql\"\n\nfragment FullPossession on Possession {\n  id\n  type\n  description\n  imageId\n  image {\n    ...FullMedia\n  }\n  requiredForViewing\n  familyWishes\n  note\n  actionHistory {\n    action\n    timeCreated\n    createdById\n    createdBy {\n      name {\n        ...FullName\n      }\n    }\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("fragments/name.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("fragments/media.gql").definitions));


      module.exports = doc;
    
