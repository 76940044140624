import React from 'react';
import GraphicCircleBg from 'icons/GraphicCircleBg';
import { t } from 'i18next';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const WebSocketMessageError = ({ onLogout }) => (
  <div className={rcStyles.error_page}>
    <div className={rcStyles.embellishments}>
      <GraphicCircleBg className={rcStyles.is_image} />
    </div>
    <div className={rcStyles.feature_image}>
      <img src="/img/core/plantman.png" alt="" className={rcStyles.is_image} />
    </div>
    <div className={rcStyles.error_message}>
      <div className={rcStyles.error_message__title}>
        Sorry, we have experienced an error.
      </div>
      <div className={classNames(
        styles.long_primer,
        styles.u_push__bottom,
      )}
      >
        {t('Please log out and back in again.')}
      </div>
      <div>
        <Button
          color="primary"
          variant="contained"
          data-test-id="logout"
          onClick={onLogout}
          className={styles.u_push__bottom}
        >
          {t('Log Out')}
        </Button>
      </div>
      <div className={styles.brevier}>
        {t('For more information see our ')}
        <Link
          target="_blank"
          href="http://funeral-zone-support.knowledgeowl.com/help"
          data-test-id="returnToDashboard"
        >
          {t('help documentation')}
        </Link>
      </div>
    </div>
  </div>
);

WebSocketMessageError.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

export default WebSocketMessageError;
