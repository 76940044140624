import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import AsyncPaginate from 'react-select-async-paginate';
import { statuses } from 'constants/catalogue';
import { getAsyncLookupOptionValue } from 'services/utils';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';

import { apolloClientType } from 'types/apollo';
import { reactRefType } from 'types/common';
import { components } from 'react-select';
import styles from 'scss/main.scss';
import { getDirectoryListings, getDirectoryListing } from './queries.gql';

const customSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? styles.colorGreyUltraLight : styles.colorWhite,
    color: 'inherit',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: '4 0',
    color: 'rgba(0, 0, 0, 0.54)',
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: 4,
    color: 'rgba(0, 0, 0, 0.54)',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '6px 0',
  }),
  input: provided => ({
    ...provided,
    margin: 0,
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 0,
    marginRight: 0,
  }),
  control: (provided, state) => {
    const focusedStyle = state.isFocused ? styles.colorOffWhite : 'inherit';
    return {
      'display': 'flex',
      'backgroundColor': state.hasValue ? 'inherit' : focusedStyle,
      '&:hover': {
        cursor: 'pointer',
      },
      '&:before': {
        'left': 0,
        'right': 0,
        'bottom': 0,
        'content': '"\\00a0"',
        'position': 'absolute',
        'transition': 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        'border-bottom': '1px solid',
        'borderBottomWidth': state.isFocused ? '2px' : '1px',
        'borderBottomColor': state.isFocused ? styles.colorHighlight : 'rgba(0, 0, 0, 0.42)',
        'pointer-events': 'none',
      },
    };
  },
  menu: provided => ({
    ...provided,
    marginTop: 0,
    paddingTop: 4,
    paddingBottom: 4,
  }),
};

const CustomClearIndicator = (props) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles('clearIndicator', props)}
    >
      <CloseIcon />
    </div>
  );
};

CustomClearIndicator.propTypes = {
  getStyles: PropTypes.func.isRequired,
  innerProps: PropTypes.shape({
    ref: reactRefType,
  }),
};

const CustomDropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <ArrowDropDownIcon />
  </components.DropdownIndicator>
);

class DirectoryListingLookup extends Component {
  static propTypes = {
    client: apolloClientType.isRequired,
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.objectOf(PropTypes.any),
      PropTypes.arrayOf(
        PropTypes.objectOf(PropTypes.any),
      ),
    ]),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isMulti: PropTypes.bool,
    isSupplier: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  getDirectoryListingById = async (id) => {
    const { client, onChange } = this.props;

    const { data } = await client.query({
      query: getDirectoryListing,
      variables: {
        id,
      },
    });
    onChange(data.directoryListing);
  }

  getDirectoryListings = async (term, prevResults, prevMetadata) => {
    const { client, isSupplier } = this.props;
    const lastCursor = (prevMetadata && prevMetadata.lastCursor) ? prevMetadata.lastCursor : null;

    const data = await client.query({
      query: getDirectoryListings,
      variables: {
        pagination: {
          first: 15,
          after: lastCursor,
        },
        term: (term !== '') ? term : null,
        status: statuses.PUBLISHED,
        isSupplier,
      },
    });
    const { edges, pageInfo } = data.data.directoryListings;
    const newResults = edges.map(({ node }) => ({
      label: node.name,
      value: node,
    }));

    return {
      options: newResults,
      hasMore: pageInfo.hasNextPage,
      additional: {
        lastCursor: pageInfo.endCursor,
      },
    };
  }

  handleChange = (selected) => {
    const { isMulti, onChange } = this.props;

    if (isMulti) {
      return onChange(selected.map(item => item.value));
    }

    return onChange(selected);
  }

  render() {
    const {
      label,
      disabled,
      isMulti,
    } = this.props;
    let { value: propValue } = this.props;

    if (typeof propValue === 'string') {
      this.getDirectoryListingById(propValue);
    }

    if (isMulti && propValue && typeof propValue[0] === 'string') {
      this.getDirectoryListingById(propValue);
    }

    if (propValue && typeof propValue === 'object' && !isMulti) {
      propValue = [propValue];
    }

    const value = propValue
      ? propValue.map(item => ({
        label: item.name,
        value: item,
      }))
      : null;

    return (
      <AsyncPaginate
        id="directoryListingSelect"
        value={value}
        onChange={this.handleChange}
        placeholder={label}
        loadOptions={this.getDirectoryListings}
        getOptionValue={option => getAsyncLookupOptionValue(option)}
        debounceTimeout={500}
        isClearable
        isSearchable
        isMulti={isMulti}
        isDisabled={disabled}
        styles={customSelectStyles}
        components={{
          ClearIndicator: CustomClearIndicator,
          DropdownIndicator: CustomDropdownIndicator,
        }}
      />
    );
  }
}

export default withApollo(DirectoryListingLookup);
