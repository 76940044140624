import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const GraphicCircleBg = (props) => {
  const { className } = props;
  const classes = className ? `${className} ${styles.rc_graphic}` : styles.rc_graphic;

  return (
    <svg enableBackground="new 0 0 424.2 307.9" height="307.9" viewBox="0 0 424.2 307.9" width="424.2" className={classes}>
      <clipPath id="a">
        <path d="m329.2 54c-32-30.7-74.6-50.6-121.8-53.8l-3-.2h-20.7c-95.6 5-172.9 78.4-183.4 172l-.3 2.8v38.1c3.4 34.7 15.9 66.6 35.2 93.5h294z" />
      </clipPath>
      <g clipPath="url(#a)" fill="none" stroke="#00b39e" strokeWidth=".907" transform="translate(95)">
        <path d="m200.5-86.2-280.8 278.4" />
        <path d="m209.8-76.9-280.8 278.4" />
        <path d="m219.1-67.6-280.7 278.4" />
        <path d="m228.5-58.3-280.8 278.4" />
        <path d="m237.8-49-280.7 278.4" />
        <path d="m247.2-39.7-280.8 278.4" />
        <path d="m256.5-30.4-280.8 278.4" />
        <path d="m265.8-21.1-280.7 278.4" />
        <path d="m275.2-11.8-280.8 278.3" />
        <path d="m284.5-2.5-280.7 278.3" />
        <path d="m293.9 6.8-280.8 278.3" />
        <path d="m303.2 16.1-280.8 278.3" />
        <path d="m312.6 25.4-280.8 278.3" />
        <path d="m321.9 34.7-280.8 278.3" />
        <path d="m331.2 44-280.7 278.3" />
        <path d="m340.6 53.3-280.8 278.3" />
        <path d="m349.9 62.6-280.7 278.3" />
        <path d="m359.3 71.9-280.8 278.3" />
        <path d="m368.6 81.2-280.8 278.3" />
        <path d="m377.9 90.5-280.7 278.3" />
        <path d="m387.3 99.8-280.8 278.3" />
        <path d="m396.6 109-280.7 278.4" />
        <path d="m406 118.3-280.8 278.4" />
        <path d="m415.3 127.6-280.8 278.4" />
        <path d="m424.6 136.9-280.7 278.4" />
        <path d="m434 146.2-280.8 278.4" />
        <path d="m443.3 155.5-280.7 278.4" />
        <path d="m452.7 164.8-280.8 278.4" />
        <path d="m462 174.1-280.8 278.4" />
        <path d="m471.4 183.4-280.8 278.4" />
      </g>
      <path clipRule="evenodd" d="m250 308.6c18.8-23.8 30-53.9 30-86.6 0-77.3-62.7-140-140-140s-140 62.7-140 140c0 32.7 11.2 62.8 30 86.6z" fill="#eba0c0" fillRule="evenodd" opacity=".085193" />
    </svg>
  );
};

GraphicCircleBg.propTypes = {
  className: PropTypes.string,
};

export default GraphicCircleBg;
