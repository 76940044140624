import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import { defaultMoneyObject } from 'services/utils';
import { invoiceType } from 'types/account';
import { reactRefType } from 'types/common';
import CurrencyField from 'components/common/CurrencyField';
import InvoiceLookup from 'components/common/lookups/InvoiceLookup';

import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const AccountAddCreditModal = ({
  formData,
  isOpen,
  onClose,
  onSave,
  onChange,
  invoices,
  isEditing,
  dialogRef,
  formRefs,
  errors,
}) => (
  <Dialog
    onClose={onClose}
    aria-labelledby="assign-case-title"
    open={isOpen}
    fullWidth
    maxWidth="sm"
    TransitionComponent={Transition}
    disableBackdropClick
    disableEscapeKeyDown
  >
    <div ref={dialogRef} className={styles.c_dialog__body}>
      <DialogTitle disableTypography className={styles.c_dialog__header}>
        <div className={styles.c_dialog__title}>
          {isEditing ? t('Edit Credit') : t('Add Credit')}
        </div>
      </DialogTitle>
      <DialogContent className={styles.c_dialog__content}>
        <div className={rcStyles.v_spacing}>
          <InvoiceLookup
            localInvoices={invoices}
            value={formData.invoice}
            onChange={invoice => onChange('invoice', (invoice && invoice.value) || null)}
            isClearable
            data-test-id="invoiceLookup"
          />
          <div ref={formRefs.amount}>
            <CurrencyField
              label={t('Amount *')}
              min={0}
              value={formData.amount && formData.amount.amount}
              InputProps={{
                inputProps: {
                  'data-test-id': 'amount',
                },
              }}
              onChangeAmount={value => onChange('amount', defaultMoneyObject(value))}
              fullWidth
              error={errors && !!errors.amount}
              helperText={errors && errors.amount && errors.amount.amount}
            />
          </div>
          <div ref={formRefs.reason}>
            <TextField
              label={t('Reason for credit note *')}
              value={formData.reason || ''}
              onChange={event => onChange('reason', event.target.value)}
              inputProps={{
                'data-test-id': 'reason',
              }}
              fullWidth
              error={errors && !!errors.reason}
              helperText={errors && errors.reason}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions className={rcStyles.actions}>
        <Button
          onClick={onClose}
          variant="outlined"
          color="primary"
          data-test-id="cancelCredit"
        >
          {t('Cancel')}
        </Button>
        <Button
          onClick={onSave}
          variant="contained"
          color="primary"
          data-test-id="saveCredit"
        >
          {isEditing ? t('Save') : t('Add Credit')}
        </Button>
      </DialogActions>
    </div>
  </Dialog>
);

AccountAddCreditModal.propTypes = {
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
  isOpen: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  invoices: PropTypes.arrayOf(invoiceType),
  dialogRef: reactRefType.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AccountAddCreditModal;
