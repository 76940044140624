import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  applyTypesToTransactions,
  getSortedTransactionsByDateTimeDesc,
  formatAccount,
} from 'services/utils/account';
import { accountTransactionType } from 'constants/account';
import { accountType, invoiceType } from 'types/account';
import { bereavedPersonConnectionType } from 'types/bereavement';
import { directoryListingType } from 'types/directoryListing';
import TransactionCreditRow from 'components/account/TransactionCreditRow';
import TransactionInvoiceRow from 'components/account/TransactionInvoiceRow';
import TransactionPaymentRow from 'components/account/TransactionPaymentRow';
import TransactionTemplateRow from 'components/account/TransactionTemplateRow';
import TransactionHistoricChargeRow from 'components/account/TransactionHistoricChargeRow';
import TransactionEventRow from 'components/account/TransactionEventRow';

const TransactionRows = ({
  bereavementId,
  account,
  invoices,
  hasConfirmedArrangement,
  hasXeroIntegration,
  toggleAddCreditModal,
  toggleConfirmDeleteModal,
  bereavedPeopleConnections,
  currentInvoiceDownloading,
  isMarkingHasBeenSentToClient,
  onVoidInvoice,
  onDownloadInvoice,
  onSendInvoiceTemplateToXero,
  directoryListings,
  toggleAddPaymentModal,
  setTransactionBeingModified,
  setTransactionBeingDeleted,
  onEditInvoice,
  onMarkHasBeenSentToClient,
  tenantHasGivenConsentToAXeroApp,
  invoiceTemplateSendingToXero,
  targetInvoiceId,
}) => {
  const { totals: { balance: { amount: balanceAmount } } } = account;
  const formattedAccount = formatAccount(account);
  const transactionsWithTypes = applyTypesToTransactions(formattedAccount);
  const dateTimeSortedTransactions = getSortedTransactionsByDateTimeDesc(transactionsWithTypes);

  return (
    dateTimeSortedTransactions.map(transaction => (
      <Fragment key={transaction.dateTime}>
        {hasXeroIntegration
          ? (
            <Fragment>
              {transaction.type === accountTransactionType.INVOICE_TEMPLATE && (
                <TransactionTemplateRow
                  bereavementId={bereavementId}
                  invoiceTemplate={transaction}
                  hasConfirmedArrangement={hasConfirmedArrangement}
                  isMarkingHasBeenSentToClient={isMarkingHasBeenSentToClient}
                  onDownloadInvoice={() => onDownloadInvoice(transaction.id)}
                  isDownloadingInvoice={currentInvoiceDownloading === transaction.id}
                  onEditInvoice={onEditInvoice}
                  onSendInvoiceTemplateToXero={onSendInvoiceTemplateToXero}
                  onMarkHasBeenSentToClient={onMarkHasBeenSentToClient}
                  tenantHasGivenConsentToAXeroApp={tenantHasGivenConsentToAXeroApp}
                  invoiceTemplateSendingToXero={invoiceTemplateSendingToXero}
                  targetInvoiceId={targetInvoiceId}
                />
              )}
            </Fragment>
          )
          : (
            <Fragment>
              {transaction.type === accountTransactionType.CREDIT && (
                <TransactionCreditRow
                  credit={transaction}
                  linkedInvoice={invoices.find(invoice => invoice.id === transaction.invoiceId && !invoice.dateVoided)}
                  toggleAddCreditModal={toggleAddCreditModal}
                  toggleConfirmDeleteModal={toggleConfirmDeleteModal}
                  setTransactionBeingModified={setTransactionBeingModified}
                  setTransactionBeingDeleted={setTransactionBeingDeleted}
                />
              )}

              {transaction.type === accountTransactionType.INVOICE && (
                <TransactionInvoiceRow
                  invoice={transaction}
                  hasConfirmedArrangement={hasConfirmedArrangement}
                  onDownloadInvoice={() => onDownloadInvoice(transaction.id)}
                  isDownloadingInvoice={currentInvoiceDownloading === transaction.id}
                  onVoidInvoice={() => onVoidInvoice(transaction)}
                  onEditInvoice={onEditInvoice}
                  targetInvoiceId={targetInvoiceId}
                />
              )}
              {transaction.type === accountTransactionType.HISTORIC_FUNERAL_ACCOUNT_CHARGE && (
                <TransactionHistoricChargeRow
                  charge={transaction}
                />
              )}
              {transaction.type === accountTransactionType.PAYMENT && (
                <TransactionPaymentRow
                  directoryListings={directoryListings}
                  payment={transaction}
                  linkedInvoice={invoices.find(invoice => invoice.id === transaction.invoiceId && !invoice.dateVoided)}
                  bereavedPeopleConnections={bereavedPeopleConnections}
                  toggleAddPaymentModal={toggleAddPaymentModal}
                  toggleConfirmDeleteModal={toggleConfirmDeleteModal}
                  setTransactionBeingModified={setTransactionBeingModified}
                  setTransactionBeingDeleted={setTransactionBeingDeleted}
                />
              )}
            </Fragment>
          )}
        {((transaction.type === accountTransactionType.ACCOUNT_CLOSE_EVENT)
          || (transaction.type === accountTransactionType.ACCOUNT_REOPEN_EVENT)) && (
          <TransactionEventRow
            event={transaction}
            hasBalanceBeenPaid={balanceAmount === 0}
          />
        )}
      </Fragment>
    ))
  );
};

TransactionRows.propTypes = {
  bereavementId: PropTypes.string.isRequired,
  account: accountType.isRequired,
  invoices: PropTypes.arrayOf(invoiceType).isRequired,
  hasConfirmedArrangement: PropTypes.bool.isRequired,
  hasXeroIntegration: PropTypes.bool.isRequired,
  toggleAddCreditModal: PropTypes.func.isRequired,
  toggleConfirmDeleteModal: PropTypes.func.isRequired,
  bereavedPeopleConnections: PropTypes.arrayOf(bereavedPersonConnectionType).isRequired,
  currentInvoiceDownloading: invoiceType,
  isMarkingHasBeenSentToClient: PropTypes.bool.isRequired,
  onVoidInvoice: PropTypes.func.isRequired,
  onEditInvoice: PropTypes.func.isRequired,
  onDownloadInvoice: PropTypes.func.isRequired,
  directoryListings: PropTypes.arrayOf(directoryListingType),
  toggleAddPaymentModal: PropTypes.func.isRequired,
  setTransactionBeingModified: PropTypes.func,
  setTransactionBeingDeleted: PropTypes.func,
  onMarkHasBeenSentToClient: PropTypes.func.isRequired,
  tenantHasGivenConsentToAXeroApp: PropTypes.bool.isRequired,
  invoiceTemplateSendingToXero: PropTypes.string,
  targetInvoiceId: PropTypes.string,
};

export default TransactionRows;
