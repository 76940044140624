import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import { removePropertyByName, generateHexId } from 'services/utils';
import { determineIsPackageSelection } from 'services/utils/package';
import { isDuplicateCategorySelection } from 'services/utils/arrangement';
import { getFilteredCatalogueItems, getSortedCatalogueItems } from 'services/utils/catalogue';
import { addProductSelectionAction, addServiceSelectionAction, unconfirmArrangementAction } from 'actions/arrangement';
import { catalogueItemTypes, productCategories, serviceCategories } from 'constants/arrangement';
import { arrangementType, productType, serviceType } from 'types/bereavement';
import { apolloClientType } from 'types/apollo';
import { getProductTags, getServiceTags } from './queries.gql';
import { addProduct, addService } from './mutations.gql';
import CatalogueItemList from './CatalogueItemList';

const defaultFirst = 15;
class CatalogueItemListContainer extends Component {
  static propTypes = {
    client: apolloClientType.isRequired,
    bereavementId: PropTypes.string,
    arrangement: arrangementType,
    category: PropTypes.string,
    type: PropTypes.oneOf(Object.keys(catalogueItemTypes)),
    selectedHomeId: PropTypes.string.isRequired,
    isOnline: PropTypes.bool.isRequired,
    products: PropTypes.arrayOf(productType.isRequired).isRequired,
    services: PropTypes.arrayOf(serviceType.isRequired).isRequired,
    disabled: PropTypes.bool,
    addProductSelection: PropTypes.func.isRequired,
    addServiceSelection: PropTypes.func.isRequired,
    unconfirmArrangement: PropTypes.func.isRequired,
    focusNewSelection: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      catalogueItems: [],
      pagination: {
        first: defaultFirst,
        after: null,
        hasMore: true,
      },
      isLoading: false,
      itemIdsInPackage: [],
      tags: [],
      selectedTags: [],
      areTagsLoading: true,
      confirmSelectionItem: null,
      duplicateSelectionItem: null,
      detailDisplayIndex: null,
    };
  }

  componentDidMount() {
    const {
      arrangement,
      isOnline,
      products,
      services,
    } = this.props;
    const allItems = [...products, ...services];
    const { filteredItems, itemIdsInPackage } = this.getFilteredCatalogueItems(allItems);
    if (arrangement && isOnline) {
      this.getCatalogueItemTags(filteredItems);
    }
    this.setState(prevState => ({
      catalogueItems: filteredItems,
      itemIdsInPackage,
      pagination: {
        ...prevState.pagination,
        hasMore: !(filteredItems.length <= defaultFirst),
      },
    }));
  }

  getFilteredCatalogueItems = (allItems) => {
    const {
      selectedHomeId,
      category,
      arrangement,
      type,
    } = this.props;
    const { selectedTags } = this.state;

    const filteredByHome = allItems.filter((item) => {
      if (!item.organisationalUnitIds) {
        return true;
      }
      return item.organisationalUnitIds.length === 0
        || item.organisationalUnitIds.includes(selectedHomeId);
    });

    return getFilteredCatalogueItems(filteredByHome, category, type, selectedTags, arrangement);
  }

  getDisplayCatalogueItems = () => {
    const { catalogueItems, pagination } = this.state;

    const lastIndex = pagination.after
      ? catalogueItems.findIndex(item => item.id === pagination.after)
      : -1;

    return catalogueItems.slice(0, lastIndex + 1 + pagination.first);
  }

  getMoreCatalogueItems = () => {
    const displayItems = this.getDisplayCatalogueItems();
    this.setState(prevState => ({
      pagination: {
        first: defaultFirst,
        after: prevState.catalogueItems.length > 0
          ? prevState.catalogueItems[prevState.catalogueItems.length - 1].id
          : null,
        hasMore: prevState.catalogueItems.length > displayItems.length,
      },
    }));
  }

  getCatalogueItemTags = async (filteredCatalogueItemsBeforePagination) => {
    const { client, category, type } = this.props;

    const data = await client.query({
      query: type === catalogueItemTypes.PRODUCT ? getProductTags : getServiceTags,
      variables: {
        pagination: { first: 1000, after: null },
        category,
        published: true,
      },
    });

    const allTagsOnCatalogueItems = filteredCatalogueItemsBeforePagination.reduce(
      (tags, catalogueItem) => tags.concat(catalogueItem.tags),
      [],
    );
    const propertyToSelect = type === catalogueItemTypes.PRODUCT ? 'productTags' : 'serviceTags';
    const { edges } = data.data[propertyToSelect];
    const tags = edges.map(({ node }) => removePropertyByName(node, '__typename')).filter(
      tag => allTagsOnCatalogueItems.indexOf(tag.value) > -1,
    );
    this.setState({
      tags,
      areTagsLoading: false,
    });
  }

  onTagChange = (selectedTags) => {
    const { products, services } = this.props;
    const allItems = [...products, ...services];
    this.setState({
      selectedTags,
      pagination: {
        first: defaultFirst,
        after: null,
        hasMore: true,
      },
    }, () => {
      const { filteredItems, itemIdsInPackage } = this.getFilteredCatalogueItems(allItems);
      this.setState({
        catalogueItems: filteredItems,
        itemIdsInPackage,
      });
    });
  }

  setDetailDisplay = (detailDisplayIndex = null) => {
    this.setState({ detailDisplayIndex });
  };

  handleAddSelection = (item) => {
    const { arrangement, category, type } = this.props;
    const isDuplicateSelection = isDuplicateCategorySelection(item, arrangement, type, category);

    if (isDuplicateSelection) {
      this.setState({ duplicateSelectionItem: item });
    } else if (arrangement.isConfirmed) {
      this.setState({ confirmSelectionItem: item });
    } else {
      this.addSelectableItem(item);
    }
  }

  handleDuplicateItemConfirm = () => {
    const { arrangement } = this.props;
    const { duplicateSelectionItem } = this.state;

    this.setState({ duplicateSelectionItem: null });

    if (arrangement.isConfirmed) {
      this.setState({ confirmSelectionItem: duplicateSelectionItem });
    } else {
      this.addSelectableItem(duplicateSelectionItem);
    }
  };

  handleDuplicateItemCancel = () => {
    this.setState({ duplicateSelectionItem: null });
  }

  addSelectableItem = async (selectedItem) => {
    const {
      bereavementId,
      arrangement,
      client,
      type,
      addProductSelection,
      addServiceSelection,
      unconfirmArrangement,
      focusNewSelection,
    } = this.props;

    const { confirmSelectionItem } = this.state;
    const item = selectedItem || confirmSelectionItem;
    this.setState({ confirmSelectionItem: null });

    if (arrangement.isConfirmed) {
      unconfirmArrangement(bereavementId, arrangement.id);
    }

    const defaultVariant = item.variants.find(variant => variant.isDefault);
    const selectionId = generateHexId();

    const selectionProperty = type === catalogueItemTypes.PRODUCT ? 'productSelections' : 'serviceSelections';
    const itemIdProperty = type === catalogueItemTypes.PRODUCT ? 'productId' : 'serviceId';
    const selectMethod = type === catalogueItemTypes.PRODUCT
      ? addProductSelection
      : addServiceSelection;

    const isPackageSelection = determineIsPackageSelection(
      arrangement.packageSelection,
      item,
      arrangement[selectionProperty],
      type.toLowerCase(),
    );

    selectMethod(
      bereavementId,
      arrangement.id,
      selectionId,
      item,
      isPackageSelection,
      defaultVariant.id,
    );
    const input = {
      bereavementId,
      arrangementId: arrangement.id,
      selection: {
        id: selectionId,
        [itemIdProperty]: item.id,
        isPackageSelection,
        variantId: defaultVariant.id,
      },
    };
    client.mutate({
      mutation: type === catalogueItemTypes.PRODUCT ? addProduct : addService,
      variables: { input },
    });
    focusNewSelection();
  }

  isSelectionDisabledByPackage = () => {
    const { type, arrangement, category } = this.props;
    const { packageSelection } = arrangement;

    const isPackageFixed = packageSelection
    && packageSelection.package
    && packageSelection.package.isFixed;
    const selectionProperty = type === catalogueItemTypes.PRODUCT ? 'productSelections' : 'serviceSelections';
    const maxQuantitiesProperty = type === catalogueItemTypes.PRODUCT
      ? 'productCategoryMaxQuantities'
      : 'serviceCategoryMaxQuantities';
    const selectableItemMaxQuantity = isPackageFixed
    && packageSelection.package[maxQuantitiesProperty]
    && packageSelection.package[maxQuantitiesProperty]
      .find(maxQuantity => maxQuantity.category === category);

    const selectedItems = arrangement[selectionProperty] && arrangement[selectionProperty]
      .filter(selection => selection[type.toLowerCase()].category === category);

    return selectableItemMaxQuantity
      && selectedItems
      && selectedItems.length >= selectableItemMaxQuantity.value;
  }

  isSelectionDisabledByCategoryInformation = () => {
    const { arrangement, category } = this.props;

    if (!arrangement.categoryInformation) {
      return false;
    }

    if (category === productCategories.FLOWERS && arrangement.categoryInformation.flowers) {
      return arrangement.categoryInformation.flowers.isFamilyArranged;
    }
    if (category === serviceCategories.OFFICIANTS && arrangement.categoryInformation.officiant) {
      return arrangement.categoryInformation.officiant.isFamilyArranged;
    }
    return false;
  }

  orderCatalogueItemsByPackage = (catalogueItems, itemIdsInPackage) => {
    if (!itemIdsInPackage) {
      return catalogueItems;
    }

    return [
      ...catalogueItems.filter(item => itemIdsInPackage.includes(item.id)),
      ...catalogueItems.filter(item => !itemIdsInPackage.includes(item.id)),
    ];
  }

  handleSortChange = (sortOrder) => {
    const { catalogueItems } = this.state;
    this.setState({
      catalogueItems: getSortedCatalogueItems(catalogueItems, sortOrder),
    });
  }

  render() {
    const {
      arrangement,
      disabled,
      focusNewSelection,
    } = this.props;
    const {
      catalogueItems,
      tags,
      isLoading,
      areTagsLoading,
      pagination,
      itemIdsInPackage,
      confirmSelectionItem,
      duplicateSelectionItem,
      detailDisplayIndex,
    } = this.state;
    const isSelectionDisabledByPackage = !!arrangement && this.isSelectionDisabledByPackage();
    const isSelectionDisabledByCategoryInformation = !!arrangement && this.isSelectionDisabledByCategoryInformation();
    const displayItems = this.getDisplayCatalogueItems(catalogueItems);

    return (
      <CatalogueItemList
        catalogueItems={this.orderCatalogueItemsByPackage(displayItems, itemIdsInPackage)}
        tags={tags}
        areTagsLoading={areTagsLoading}
        itemIdsInPackage={itemIdsInPackage}
        hasMoreCatalogueItems={pagination.hasMore}
        hasArrangement={!!arrangement}
        isArrangementConfirmed={arrangement && arrangement.isConfirmed}
        isLoading={isLoading}
        duplicateSelectionItem={duplicateSelectionItem}
        confirmSelectionItem={confirmSelectionItem}
        detailDisplayIndex={detailDisplayIndex}
        disabled={disabled || isSelectionDisabledByPackage || isSelectionDisabledByCategoryInformation}
        focusNewSelection={focusNewSelection}
        onTagChange={this.onTagChange}
        getMoreCatalogueItems={this.getMoreCatalogueItems}
        setDetailDisplay={this.setDetailDisplay}
        onAddSelection={this.handleAddSelection}
        onDuplicateItemConfirm={this.handleDuplicateItemConfirm}
        onDuplicateItemCancel={this.handleDuplicateItemCancel}
        onSelectionConfirm={this.addSelectableItem}
        onSelectionCancel={() => this.setState({ confirmSelectionItem: null })}
        onSortChange={this.handleSortChange}
      />
    );
  }
}

const mapStateToProps = state => ({
  isOnline: state.userStore.isOnline,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  addProductSelection: addProductSelectionAction,
  addServiceSelection: addServiceSelectionAction,
  unconfirmArrangement: unconfirmArrangementAction,
}, dispatch);

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(CatalogueItemListContainer),
);
