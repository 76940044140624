import mapActionToReducer from 'redux-action-reducer-mapper';
import reduceReducers from 'reduce-reducers';
import { bereavementActions } from 'actions/bereavement';
import { getArrayIndexForId, updateArrayByIndex, generateHexId } from 'services/utils';
import {
  updateAgeAtDeath,
  updateClientsBillPayerAndPrimaryContactStatus,
  assignDefaultStatusesToFirstConnection,
} from 'services/utils/bereavement';
import { BEREAVEMENTS_PER_PAGE } from 'constants/bereavement';
import { arrangementReducer, arrangementsCalculatorReducer } from 'reducers/arrangement';
import { accountReducer, accountCalculatorReducer } from 'reducers/account';

export const initialState = {
  bereavements: [],
  pagination: {
    first: BEREAVEMENTS_PER_PAGE,
    after: null,
    hasNextPage: true,
  },
  filters: {
    searchTerm: '',
    organisationalUnitId: 'all',
  },
  isLoading: false,
  isListLoaded: false,
  activeRequest: null,
  totals: {
    uninvoiced: null,
    withOutstandingBalance: null,
  },
};

export const updateAgeAtDeathForBereavement = (state, bereavementId) => {
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  const bereavement = state.bereavements[bereavementIndex];

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...bereavement,
        deceasedPeople: [updateAgeAtDeath(bereavement.deceasedPeople[0])],
      },
    ),
  };
};

const clearBereavementFilters = state => ({
  ...state,
  filters: initialState.filters,
});

const clearBereavements = state => ({
  ...initialState,
  filters: state.filters,
  isLoading: state.isLoading,
  isListLoaded: false,
  activeRequest: state.activeRequest,
});

const setBereavements = (state, action) => {
  const { bereavements, pagination, filters } = action.payload;

  return {
    ...state,
    bereavements,
    pagination: {
      ...initialState.pagination,
      ...pagination,
    },
    filters: filters || initialState.filters,
  };
};

const setBereavement = (state, action) => {
  const { bereavement: updatedBereavement } = action.payload;
  const bereavements = state.bereavements.map((bereavement) => {
    if (updatedBereavement.id === bereavement.id) {
      return updatedBereavement;
    }
    return bereavement;
  });


  return { ...state, bereavements };
};

const setTotals = (state, action) => {
  const { totals } = action.payload;
  return {
    ...state,
    totals,
  };
};

const addBereavements = (state, action) => {
  const { bereavements, pagination, filters } = action.payload;
  const updatedBereavements = state.bereavements.map((bereavement) => {
    const existingApiBereavement = bereavements.find(existing => existing.id === bereavement.id);
    if (existingApiBereavement) {
      if (existingApiBereavement.arrangements.length < bereavement.arrangements.length) {
        existingApiBereavement.arrangements = bereavement.arrangements;
      }
      return existingApiBereavement;
    }
    return bereavement;
  });
  const newBereavements = bereavements.filter(
    bereavement => !state.bereavements.find(existing => existing.id === bereavement.id),
  );

  return {
    ...state,
    bereavements: [...updatedBereavements, ...newBereavements],
    pagination: {
      ...state.pagination,
      ...pagination,
    },
    filters: filters || state.filters,
  };
};

const removeBereavement = (state, action) => {
  const bereavementId = action.payload;

  return {
    ...state,
    bereavements: state.bereavements.filter(bereavement => bereavementId !== bereavement.id),
  };
};

const updateBereavement = (state, action) => {
  const { bereavementId, key, value } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const bereavement = state.bereavements[bereavementIndex];

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...bereavement,
        [key]: value,
      },
    ),
  };
};

const setDeceasedPerson = (state, action) => {
  const { bereavementId, deceasedPerson } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const bereavement = state.bereavements[bereavementIndex];

  let newState = {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...bereavement,
        deceasedPeople: [deceasedPerson],
      },
    ),
  };

  if (!newState.bereavements[bereavementIndex].deceasedPeople[0].id) {
    newState.bereavements[bereavementIndex].deceasedPeople[0].id = generateHexId();
  }

  newState = updateAgeAtDeathForBereavement(newState, bereavementId);

  return newState;
};

const addClient = (state, action) => {
  const { bereavementId, bereavedPeopleConnection } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const bereavement = state.bereavements[bereavementIndex];
  const bereavedPeopleConnections = updateClientsBillPayerAndPrimaryContactStatus(
    bereavement.bereavedPeopleConnections, bereavedPeopleConnection,
  );

  bereavedPeopleConnections.push(bereavedPeopleConnection);

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...bereavement,
        bereavedPeopleConnections,
      },
    ),
  };
};

const editClient = (state, action) => {
  const { bereavementId, bereavedPeopleConnection } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const bereavement = state.bereavements[bereavementIndex];
  const bereavedPeopleConnections = updateClientsBillPayerAndPrimaryContactStatus(
    bereavement.bereavedPeopleConnections, bereavedPeopleConnection, true,
  );

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...bereavement,
        bereavedPeopleConnections,
      },
    ),
  };
};

const removeClient = (state, action) => {
  const { bereavementId, id } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const bereavement = state.bereavements[bereavementIndex];
  const bereavedPeopleConnections = bereavement.bereavedPeopleConnections
    .filter(bereavedPersonConnection => id !== bereavedPersonConnection.id);

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...bereavement,
        bereavedPeopleConnections: assignDefaultStatusesToFirstConnection(
          bereavedPeopleConnections,
        ),
      },
    ),
  };
};

const updateAppointment = (state, action) => {
  const { bereavementId, appointment } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const bereavement = state.bereavements[bereavementIndex];

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...bereavement,
        appointment,
      },
    ),
  };
};

const addPossession = (state, action) => {
  const { bereavementId, possession } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const bereavement = state.bereavements[bereavementIndex];
  const [deceasedPerson] = bereavement.deceasedPeople;
  const possessions = deceasedPerson.possessions || [];

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...bereavement,
        deceasedPeople: [{
          ...deceasedPerson,
          possessions: [
            ...possessions,
            possession,
          ],
        }],
      },
    ),
  };
};


const updatePossession = (state, action) => {
  const {
    bereavementId, possessionId, newAction, image, note, requiredForViewing, familyWishes,
  } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const bereavement = state.bereavements[bereavementIndex];
  const [deceasedPerson] = bereavement.deceasedPeople;
  const possessions = deceasedPerson.possessions || [];

  if (possessions.length === 0) {
    return state;
  }

  const possessionIndex = getArrayIndexForId(possessions, possessionId);
  const imageId = image && image.id;
  const actionHistory = newAction
    ? [...possessions[possessionIndex].actionHistory, newAction]
    : possessions[possessionIndex].actionHistory;

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...bereavement,
        deceasedPeople: [{
          ...deceasedPerson,
          possessions: updateArrayByIndex(
            possessions,
            possessionIndex,
            {
              ...possessions[possessionIndex],
              actionHistory,
              image,
              imageId,
              note,
              requiredForViewing,
              familyWishes,
            },
          ),
        }],
      },
    ),
  };
};

const addAshesRecord = (state, action) => {
  const { bereavementId, ashesRecord } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const bereavement = state.bereavements[bereavementIndex];
  const [deceasedPerson] = bereavement.deceasedPeople;
  const ashesRecords = deceasedPerson.ashesRecords || [];

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...bereavement,
        deceasedPeople: [{
          ...deceasedPerson,
          ashesRecords: [
            ...ashesRecords,
            ashesRecord,
          ],
        }],
      },
    ),
  };
};

const updateAshesRecord = (state, action) => {
  const { bereavementId, ashesRecord } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const bereavement = state.bereavements[bereavementIndex];
  const [deceasedPerson] = bereavement.deceasedPeople;
  const ashesRecords = deceasedPerson.ashesRecords || [];

  if (ashesRecords.length === 0) {
    return state;
  }

  const ashesRecordIndex = getArrayIndexForId(ashesRecords, ashesRecord.id);

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...bereavement,
        deceasedPeople: [{
          ...deceasedPerson,
          ashesRecords: updateArrayByIndex(
            ashesRecords,
            ashesRecordIndex,
            {
              ...deceasedPerson.ashesRecords[ashesRecordIndex],
              ...ashesRecord,
            },
          ),
        }],
      },
    ),
  };
};

const addDeed = (state, action) => {
  const { bereavementId, deed } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const bereavement = state.bereavements[bereavementIndex];
  const [deceasedPerson] = bereavement.deceasedPeople;
  const deeds = deceasedPerson.deeds || [];

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...bereavement,
        deceasedPeople: [{
          ...deceasedPerson,
          deeds: [
            ...deeds,
            deed,
          ],
        }],
      },
    ),
  };
};

const updateDeed = (state, action) => {
  const { bereavementId, deed } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const bereavement = state.bereavements[bereavementIndex];
  const [deceasedPerson] = bereavement.deceasedPeople;
  const deeds = deceasedPerson.deeds || [];

  if (deeds.length === 0) {
    return state;
  }

  const deedIndex = getArrayIndexForId(deeds, deed.id);

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...bereavement,
        deceasedPeople: [{
          ...deceasedPerson,
          deeds: updateArrayByIndex(
            deeds,
            deedIndex,
            {
              ...deceasedPerson.deeds[deedIndex],
              ...deed,
            },
          ),
        }],
      },
    ),
  };
};

const addArrangement = (state, action) => {
  const { bereavementId, arrangement } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const bereavement = state.bereavements[bereavementIndex];
  const arrangements = bereavement.arrangements || [];

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...bereavement,
        arrangements: [...arrangements, arrangement],
      },
    ),
  };
};

const editArrangement = (state, action) => {
  const { bereavementId, arrangementId, updates } = action.payload;
  const bereavementIndex = getArrayIndexForId(state.bereavements, bereavementId);
  if (bereavementIndex === -1) {
    return state;
  }
  const bereavement = state.bereavements[bereavementIndex];
  const arrangements = bereavement.arrangements || [];

  const arrangementIndex = getArrayIndexForId(arrangements, arrangementId);

  return {
    ...state,
    bereavements: updateArrayByIndex(
      state.bereavements,
      bereavementIndex,
      {
        ...bereavement,
        arrangements: updateArrayByIndex(
          arrangements,
          arrangementIndex,
          {
            ...arrangements[arrangementIndex],
            ...updates,
          },
        ),
      },
    ),
  };
};

const updateFilters = (state, action) => {
  const { payload: { key, value } } = action;
  return {
    ...state,
    filters: {
      ...state.filters,
      [key]: value,
    },
  };
};

const setLoading = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    isLoading: payload,
  };
};

const setListLoaded = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    isListLoaded: payload,
  };
};

const setActiveRequest = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    activeRequest: payload,
  };
};

const actionReducers = mapActionToReducer({
  default: initialState,
  [bereavementActions.CLEAR_ALL]: () => initialState,
  [bereavementActions.CLEAR_BEREAVEMENTS]: clearBereavements,
  [bereavementActions.CLEAR_BEREAVEMENT_FILTERS]: clearBereavementFilters,
  [bereavementActions.SET_BEREAVEMENTS]: setBereavements,
  [bereavementActions.SET_BEREAVEMENT]: setBereavement,
  [bereavementActions.ADD_BEREAVEMENTS]: addBereavements,
  [bereavementActions.REMOVE_BEREAVEMENT]: removeBereavement,
  [bereavementActions.UPDATE_BEREAVEMENT]: updateBereavement,
  [bereavementActions.SET_DECEASED_PERSON]: setDeceasedPerson,
  [bereavementActions.UPDATE_APPOINTMENT]: updateAppointment,
  [bereavementActions.ADD_CLIENT]: addClient,
  [bereavementActions.EDIT_CLIENT]: editClient,
  [bereavementActions.REMOVE_CLIENT]: removeClient,
  [bereavementActions.ADD_POSSESSION]: addPossession,
  [bereavementActions.UPDATE_POSSESSION]: updatePossession,
  [bereavementActions.ADD_ASHES_RECORD]: addAshesRecord,
  [bereavementActions.UPDATE_ASHES_RECORD]: updateAshesRecord,
  [bereavementActions.ADD_DEED]: addDeed,
  [bereavementActions.UPDATE_DEED]: updateDeed,
  [bereavementActions.ADD_ARRANGEMENT]: addArrangement,
  [bereavementActions.EDIT_ARRANGEMENT]: editArrangement,
  [bereavementActions.UPDATE_FILTERS]: updateFilters,
  [bereavementActions.SET_LOADING]: setLoading,
  [bereavementActions.SET_LIST_LOADED]: setListLoaded,
  [bereavementActions.SET_ACTIVE_REQUEST]: setActiveRequest,
  [bereavementActions.SET_TOTALS]: setTotals,
  ...arrangementReducer,
  ...accountReducer,
});

export const bereavementReducer = reduceReducers(
  actionReducers,
  arrangementsCalculatorReducer,
  accountCalculatorReducer,
);
