import PortalCatalogueScreen from 'screens/portal/PortalCatalogueScreen';
import PortalCatalogueCategoryScreen from 'screens/portal/PortalCatalogueCategoryScreen';
import PortalCataloguePackagesScreen from 'screens/portal/PortalCataloguePackagesScreen';
import { Route } from 'types/ts/route';

export const portalRoutes: Route[] = [
  {
    path: '/portal/:homeId',
    component: PortalCatalogueScreen,
    title: 'Portal',
    description: '',
  },
  {
    path: '/portal/:homeId/catalogue/packages',
    component: PortalCataloguePackagesScreen,
    title: 'Packages',
    description: '',
  },
  {
    path: '/portal/:homeId/catalogue/:category',
    component: PortalCatalogueCategoryScreen,
    title: 'Category',
    description: '',
  },
  {
    path: '/portal/:homeId/catalogue/:category/:itemId',
    component: PortalCatalogueCategoryScreen,
    title: 'Category',
    description: '',
  },
];

export const defaultPortalStyles = {
  headerColour: '#fff',
  footerColour: '#fff',
};

export const headlineText = 'Create a loving memory';
export const headlineBgColor = '#14213d';

export enum BackgroundType {
  AUTUMN = 'AUTUMN',
  BEACH = 'BEACH',
  BIRDS = 'BIRDS',
  BLOOM = 'BLOOM',
  BLOSSOMS = 'BLOSSOMS',
  BOAT = 'BOAT',
  BUTTERYFLY = 'BUTTERYFLY',
  CLOUDS = 'CLOUDS',
  DANDELION = 'DANDELION',
  DRAGONFLY = 'DRAGONFLY',
  GRASS = 'GRASS',
  GRASS_SEEDS = 'GRASS_SEEDS',
  HEART_BLOSSOM = 'HEART_BLOSSOM',
  HILLS = 'HILLS',
  LADYBEETLE = 'LADYBEETLE',
  LAVENDAR = 'LAVENDAR',
  LIGHTHOUSE = 'LIGHTHOUSE',
  MOUNTAINS = 'MOUNTAINS',
  RED_LEAVES = 'RED_LEAVES',
  SKY = 'SKY',
  SUNSET = 'SUNSET',
  SUNSET_SEA = 'SUNSET_SEA',
  VALLEY = 'VALLEY',
  WILD_FLOWERS = 'WILD_FLOWERS',
  YELLOW_BUTTERFLY = 'YELLOW_BUTTERFLY',
}

export const backgroundImageRoot = '/img/brochure';
export const backgrounds = [
  { type: BackgroundType.AUTUMN, image: 'autumn' },
  { type: BackgroundType.BEACH, image: 'beach' },
  { type: BackgroundType.BIRDS, image: 'birds' },
  { type: BackgroundType.BLOOM, image: 'bloom' },
  { type: BackgroundType.BLOSSOMS, image: 'blossoms' },
  { type: BackgroundType.BOAT, image: 'boat' },
  { type: BackgroundType.BUTTERYFLY, image: 'butteryfly' },
  { type: BackgroundType.CLOUDS, image: 'clouds' },
  { type: BackgroundType.DANDELION, image: 'dandelion' },
  { type: BackgroundType.DRAGONFLY, image: 'dragonfly' },
  { type: BackgroundType.GRASS, image: 'grass' },
  { type: BackgroundType.GRASS_SEEDS, image: 'grassseeds' },
  { type: BackgroundType.HEART_BLOSSOM, image: 'heartblossom' },
  { type: BackgroundType.HILLS, image: 'hills' },
  { type: BackgroundType.LADYBEETLE, image: 'ladybeetle' },
  { type: BackgroundType.LAVENDAR, image: 'lavendar' },
  { type: BackgroundType.LIGHTHOUSE, image: 'lighthouse' },
  { type: BackgroundType.MOUNTAINS, image: 'mountains' },
  { type: BackgroundType.RED_LEAVES, image: 'redleaves' },
  { type: BackgroundType.SKY, image: 'sky' },
  { type: BackgroundType.SUNSET, image: 'sunset' },
  { type: BackgroundType.SUNSET_SEA, image: 'sunsetsea' },
  { type: BackgroundType.VALLEY, image: 'valley' },
  { type: BackgroundType.WILD_FLOWERS, image: 'wildflowers' },
  { type: BackgroundType.YELLOW_BUTTERFLY, image: 'yellowbutterfly' },
];
