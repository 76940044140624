import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import TextField from '@material-ui/core/TextField';
import { nameType } from 'types/common';

import AutoCompleteTextField from 'components/common/AutoCompleteTextField';
import rcStyles from './styles.scss';

const NameFormSection = ({
  name,
  customStyles,
  disabled,
  labelTitle,
  onChangeHandler,
  labelGivenName,
  labelMiddleName,
  labelFamilyName,
  titles,
  errors,
}) => {
  const styles = customStyles || rcStyles;

  return (
    <div className={styles.name_inputs}>
      <div className={styles.is_title}>
        <AutoCompleteTextField
          placeholder={t(labelTitle)}
          value={name.title || ''}
          suggestions={titles}
          onChange={title => onChangeHandler('title', title)}
          fullWidth
          data-test-id="title"
        />
      </div>
      <TextField
        label={labelGivenName}
        value={name.givenName || ''}
        onChange={event => onChangeHandler('givenName', event.target.value)}
        fullWidth
        inputProps={{
          'data-test-id': 'givenName',
        }}
        error={errors && !!errors.givenName}
        helperText={errors && errors.givenName}
        disabled={disabled}
        className={styles.is_first_name}
      />
      <TextField
        label={labelMiddleName}
        value={name.middleName || ''}
        onChange={event => onChangeHandler('middleName', event.target.value)}
        fullWidth
        inputProps={{
          'data-test-id': 'middleName',
        }}
        error={errors && !!errors.middleName}
        helperText={errors && errors.middleName}
        disabled={disabled}
        className={styles.is_middle_name}
      />
      <TextField
        label={labelFamilyName}
        value={name.familyName || ''}
        onChange={event => onChangeHandler('familyName', event.target.value)}
        fullWidth
        inputProps={{
          'data-test-id': 'familyName',
        }}
        error={errors && !!errors.familyName}
        helperText={errors && errors.familyName}
        disabled={disabled}
        className={styles.is_last_name}
      />
    </div>
  );
};

NameFormSection.propTypes = {
  name: nameType.isRequired,
  customStyles: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
  labelTitle: PropTypes.string.isRequired,
  labelGivenName: PropTypes.string.isRequired,
  labelMiddleName: PropTypes.string.isRequired,
  labelFamilyName: PropTypes.string.isRequired,
  titles: PropTypes.arrayOf(PropTypes.string),
  onChangeHandler: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any),
};

export default NameFormSection;
