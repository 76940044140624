import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { t } from 'i18next';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';

import StepNavigation from 'components/common/StepNavigation';
import styles from 'scss/main.scss';

import UploadPanel from './UploadPanel';
import ResultsPanel from './ResultsPanel';
import rcStyles from './styles.scss';

const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

export const navigationSteps = [
  {
    title: 'Import',
    component: UploadPanel,
  },
  {
    title: 'Import successful',
    component: ResultsPanel,
  },
];

const ImportFromCSVModal = ({
  activeStep,
  disabledSteps,
  importHasErrors,
  isOpen,
  isUploading,
  report,
  onChangeActiveStep,
  onClose,
  onUploadInitialized,
  onUploadDone,
}) => {
  const ImportFromCSVStep = navigationSteps[activeStep].component;

  return (
    <Dialog
      onClose={onClose}
      fullScreen
      open={isOpen}
      TransitionComponent={Transition}
      className={styles.c_dialogue}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle
        disableTypography
        className={styles.dialog_title_bar}
      >
        <IconButton
          className={classNames(
            styles.o_button__secondary,
            styles.is_close,
          )}
          color="primary"
          variant="contained"
          aria-label="Close"
          onClick={onClose}
          data-test-id="closeModal"
        >
          <CloseIcon />
        </IconButton>
        <div
          className={styles.is_title}
          data-test-id="importProductsAndServices"
        >
          {t('Import products and services')}
        </div>
        <div className={styles.is_actions}>
          <Button
            color="secondary"
            variant="contained"
            onClick={onClose}
            disabled={isUploading}
            data-test-id="backToImportExport"
          >
            {t('Back to import/export')}
          </Button>
        </div>
      </DialogTitle>
      <div className={rcStyles.wrap}>
        <StepNavigation
          activeStep={activeStep}
          disabledSteps={disabledSteps}
          navigationSteps={navigationSteps}
          onChangeActiveStep={onChangeActiveStep}
        />
        <ImportFromCSVStep
          isUploading={isUploading}
          importHasErrors={importHasErrors}
          report={report}
          onClose={onClose}
          onUploadInitialized={onUploadInitialized}
          onUploadDone={onUploadDone}
        />
      </div>
    </Dialog>
  );
};

ImportFromCSVModal.propTypes = {
  activeStep: PropTypes.number.isRequired,
  disabledSteps: PropTypes.arrayOf(PropTypes.number),
  importHasErrors: PropTypes.bool.isRequired,
  isUploading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  report: PropTypes.arrayOf().isRequired,
  onChangeActiveStep: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onUploadInitialized: PropTypes.func.isRequired,
  onUploadDone: PropTypes.func.isRequired,
};

export default ImportFromCSVModal;
