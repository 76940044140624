import React from 'react';
import i18next from 'i18next';
import ScreenLayout from 'components/common/ScreenLayout';
import PageTabs from 'components/letterTemplates/PageTabs';
import styles from 'scss/main.scss';
import classNames from 'classnames';
import rcStyles from 'screens/admin/AdminLetterTemplatesScreen/styles.scss';
import IconLoad from 'icons/IconLoad';
import { CorrespondenceCategory, CorrespondenceType } from 'constants/ts/tenant';
import TemplatesList from 'components/letterTemplates/TemplatesList';
import { Props } from './AdminLetterTemplatesInvoiceScreen.types';

const AdminLetterTemplatesInvoiceScreen: React.FC<Props> = ({
  isLoading,
  templateDefinitionsLookup,
  onUpdateTemplateDefinition,
}: Props) => {
  const { systemTemplates } = templateDefinitionsLookup || {};
  const invoiceTemplates = systemTemplates[CorrespondenceCategory.ACCOUNT].filter(
    template => template.type === CorrespondenceType.INVOICE
      || template.type === CorrespondenceType.PROFORMA_INVOICE,
  );

  return (
    <ScreenLayout
      title={i18next.t('Invoice Templates')}
      dashboardBannerProps={{
        breadcrumbs: [
          { path: '/', title: i18next.t('Dashboard') },
          { path: '/templates/invoice', title: i18next.t('Invoice templates') },
        ],
        description: i18next.t('Customise your invoice and proforma invoice.') as string,
      }}
    >
      <PageTabs />
      <div className={styles.o_view}>
        <div className={styles.o_view__main}>
          <div className={classNames(
            styles.o_block_content,
            rcStyles.v_spacing,
          )}
          >
            {isLoading.tenantTemplateDefinitions ? (
              <div className={styles.u_soft__ends_double}>
                <div className={styles.c_svg_loader_big} data-test-id="customTenantTemplateDefinitionsLoader">
                  <IconLoad />
                </div>
              </div>
            ) : (
              <div>
                <TemplatesList
                  items={invoiceTemplates}
                  onUpdateTemplateDefinition={onUpdateTemplateDefinition}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </ScreenLayout>
  );
};

export default AdminLetterTemplatesInvoiceScreen;
