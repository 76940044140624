import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';

import MediaUpload from 'components/common/MediaUpload';
import InvoiceLoad from 'icons/InvoiceLoad';
import IconLoadSml from 'icons/IconLoadSml';
import InvoiceLoadStatic from 'icons/InvoiceLoad_static';
import DocumentWarning from 'icons/DocumentWarning';
import DocumentSuccess from 'icons/DocumentSuccess';

import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const UploadTemplate = ({
  template,
  isUploading,
  hasBeenUploaded,
  isPreviewDownloading,
  onClose,
  onPreview,
  onUploadStart,
  onUploadComplete,
  report,
  hasBeenSetOnTenant,
  templateHasErrors,
}) => (
  <div className={classNames(
    styles.c_page__full_height,
    styles.has_faded_background,
  )}
  >
    <div className={styles.o_view}>
      <div className={classNames(
        styles.o_view__main,
        styles.o_block_content,
        rcStyles.v_spacing,
      )}
      >
        <div className={rcStyles.is_image}>
          {!isUploading && !templateHasErrors && !hasBeenSetOnTenant && (
            <InvoiceLoadStatic />
          )}
          {isUploading && (
            <InvoiceLoad />
          )}
          {!isUploading && templateHasErrors && (
            <DocumentWarning
              classes={[rcStyles.templateReportIcon]}
              viewBox="30 -20 145 164"
            />
          )}
          {!isUploading && hasBeenSetOnTenant && (
            <DocumentSuccess
              classes={[rcStyles.templateReportIcon]}
              viewBox="30 -20 145 164"
            />
          )}
        </div>
        <div className={rcStyles.is_content}>
          {!isUploading && !templateHasErrors && !hasBeenSetOnTenant && (
            <p className={classNames(
              styles.primer,
              styles.u_push__bottom,
            )}
            >
              {t('Upload a document you wish to use as your letter template. This must be a DOCX file.')}
            </p>
          )}
          {hasBeenUploaded && (
            <div>
              {!templateHasErrors && (
                <Fragment>
                  <h3 className={styles.long_primer}>{t(`${template.title} created`)}</h3>

                  <p className={styles.primer}>
                    {t(`Your letter has been added to the templates.
                    The letter will now be accessible for each ${template.category === 'CASE' ? 'funeral' : 'account'}.
                    Please preview your letter to ensure it is satisfactory.`)}
                  </p>
                </Fragment>
              )}
              {templateHasErrors && (
                <div>
                  <p className={classNames(
                    styles.primer,
                    styles.u_push__bottom,
                  )}
                  >
                    {t('Some values are incorrect. Please check your values and upload again. For help')}
                    <a
                      href="http://funeral-zone-support.knowledgeowl.com/help/upload-messages"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t(' view the Knowledge Base.')}
                    </a>
                  </p>

                  {report.map(item => (
                    <span className={rcStyles.report}>{ item }</span>
                  ))}
                </div>
              )}
              {hasBeenSetOnTenant && (
                <div className={rcStyles.actions}>
                  <Button
                    onClick={onClose}
                    variant="outlined"
                    color="primary"
                    data-test-id="backToLetterTemplatesButton"
                  >
                    {t('Back to letter templates')}
                  </Button>
                  <Button
                    onClick={onPreview}
                    variant="contained"
                    color="primary"
                    data-test-id="previewLetterButton"
                  >
                    {isPreviewDownloading ? (
                      <div
                        className={classNames(
                          styles.c_svg_loader_sml,
                          styles.c_svg_loader_sml_btn,
                        )}
                        data-test-id="buttonLoader"
                      >
                        <IconLoadSml />
                      </div>
                    ) : (
                      <>
                        {t('Preview letter')}
                      </>
                    )}
                  </Button>
                </div>
              )}
            </div>
          )}
          {(!hasBeenUploaded || templateHasErrors) && (
            <>
              <div className={rcStyles.is_contents}>
                <MediaUpload
                  showImagePreview={false}
                  contentDisposition="inline"
                  acceptDocuments
                  accept={['.docx', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}
                  onUploadDone={onUploadComplete}
                  onUploadInitialized={onUploadStart}
                  customButton={(
                    <Button
                      variant="contained"
                      color="primary"
                      data-test-id="uploadTemplate"
                    >
                      {isUploading ? (
                        <div
                          className={classNames(
                            styles.c_svg_loader_sml,
                            styles.c_svg_loader_sml_btn,
                          )}
                          data-test-id="buttonLoader"
                        >
                          <IconLoadSml />
                        </div>
                      ) : (
                        <>
                          {t('Upload template')}
                        </>
                      )}
                    </Button>
                  )}
                  data-test-id="letterTemplateUpload"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  </div>
);

UploadTemplate.propTypes = {
  template: PropTypes.objectOf(PropTypes.any).isRequired,
  isUploading: PropTypes.bool.isRequired,
  hasBeenUploaded: PropTypes.bool.isRequired,
  isPreviewDownloading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
  onUploadStart: PropTypes.func.isRequired,
  onUploadComplete: PropTypes.func.isRequired,
  report: PropTypes.arrayOf(PropTypes.string),
  hasBeenSetOnTenant: PropTypes.bool.isRequired,
  templateHasErrors: PropTypes.bool.isRequired,
};

export default UploadTemplate;
