import { v4 as uuidv4 } from 'uuid';
import { staffMemberMutationTransform } from 'transforms/staffMember';
import { getStaffMembers } from './staffMembers.queries.gql';
import {
  createStaffMemberMutation,
  editStaffMemberMutation,
  activateIdentityMutation,
  deactivateIdentityMutation,
  createIdentityUsingEmailMutation,
} from './staffMembers.mutations.gql';

export const staffMembersActions = {
  SET_STAFF_MEMBERS: 'STAFF_MEMBER_SET_STAFF_MEMBERS',
  CLEAR_STAFF_MEMBERS: 'STAFF_MEMBER_CLEAR_STAFF_MEMBERS',
  ADD_STAFF_MEMBERS: 'STAFF_MEMBER_ADD_STAFF_MEMBERS',
  ADD_STAFF_MEMBER: 'STAFF_MEMBER_ADD_STAFF_MEMBER',
  UPDATE_STAFF_MEMBER: 'STAFF_MEMBER_UPDATE_STAFF_MEMBER',
  REMOVE_STAFF_MEMBER: 'STAFF_MEMBER_REMOVE_STAFF_MEMBER',
  UPDATE_FILTERS: 'STAFF_MEMBER_UPDATE_FILTERS',
  SET_LOADING: 'STAFF_MEMBER_SET_LOADING',
};

export const setStaffMembersAction = payload => (
  { type: staffMembersActions.SET_STAFF_MEMBERS, payload }
);

export const clearStaffMembersAction = () => (
  { type: staffMembersActions.CLEAR_STAFF_MEMBERS }
);

export const addStaffMembersAction = payload => (
  { type: staffMembersActions.ADD_STAFF_MEMBERS, payload }
);

export const addStaffMemberAction = payload => (
  { type: staffMembersActions.ADD_STAFF_MEMBER, payload }
);

export const updateStaffMemberAction = payload => (
  { type: staffMembersActions.UPDATE_STAFF_MEMBER, payload }
);

export const removeStaffMemberAction = payload => (
  { type: staffMembersActions.REMOVE_STAFF_MEMBER, payload }
);

export const updateFiltersAction = (key, value) => (
  { type: staffMembersActions.UPDATE_FILTERS, payload: { key, value } }
);

export const setLoadingAction = payload => (
  { type: staffMembersActions.SET_LOADING, payload }
);

export const fetchStaffMembersAction = filters => (dispatch, getState, client) => {
  dispatch(setLoadingAction(true));
  if (filters) {
    dispatch(clearStaffMembersAction());
  }

  const { pagination } = getState().staffMembersStore;
  const { searchTerm, organisationalUnits } = filters || getState().staffMembersStore.filters;
  const organisationalUnitIds = organisationalUnits && organisationalUnits.map(item => item.id);

  const query = client.query({
    query: getStaffMembers,
    variables: {
      term: searchTerm || null,
      organisationalUnitIds,
      pagination: {
        first: pagination.first,
        after: pagination.after,
      },
    },
  }).then(({ data, errors }) => {
    if (data === null && errors.length > 0) {
      return;
    }

    const { staffMembers } = data;
    const staff = staffMembers.edges.map(({ node }) => node);
    const updatedPagination = {
      first: pagination.first,
      after: staffMembers.pageInfo.endCursor,
      hasNextPage: staffMembers.pageInfo.hasNextPage,
    };

    const payload = { staffMembers: staff, pagination: updatedPagination, filters };
    dispatch(addStaffMembersAction(payload));
  }).finally(() => {
    dispatch(setLoadingAction(false));
  });
  return query;
};

export const createStaffMemberAction = staffMember => (dispatch, getState, client) => {
  const mutatingStaffMember = staffMemberMutationTransform(staffMember);
  client.mutate({
    mutation: createStaffMemberMutation,
    variables: { input: mutatingStaffMember },
  });
  dispatch(addStaffMemberAction(staffMember));
};

export const editStaffMemberAction = staffMember => (dispatch, getState, client) => {
  const mutatingStaffMember = staffMemberMutationTransform(staffMember);
  client.mutate({
    mutation: editStaffMemberMutation,
    variables: { input: mutatingStaffMember },
  });
  dispatch(updateStaffMemberAction(staffMember));
};

export const updateIdentityStatusAction = ({
  staffMember, status,
}) => (dispatch, getState, client) => {
  const input = { id: staffMember.identityInterface.id };
  const newStaffMember = {
    ...staffMember,
    identityInterface: {
      ...staffMember.identityInterface,
      isActive: status === 'activate',
    },
  };

  dispatch(updateStaffMemberAction(newStaffMember));

  client.mutate({
    mutation: status === 'activate'
      ? activateIdentityMutation
      : deactivateIdentityMutation,
    variables: { input },
  });
};

export const createStaffMemberIdentityInterfaceAction = staffMemberData => (
  dispatch, getState, client,
) => {
  const input = {
    email: staffMemberData.emails[0],
    type: 'STAFF_MEMBER',
    typeId: staffMemberData.id,
    id: uuidv4(),
  };

  const newStaffMember = {
    ...staffMemberData,
    identityInterface: { isActive: true, id: input.id },
  };

  dispatch(updateStaffMemberAction(newStaffMember));

  client.mutate({
    mutation: createIdentityUsingEmailMutation,
    variables: { input },
  });
};
