import styled from 'styled-components';

const Card = styled.aside`
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.5);
  display: grid;

  & .card_detail {
    display: grid;
    align-content: flex-start;
    padding: 2rem;
    overflow: hidden;
    color: #515551;

    @media (max-width: 767px) {
      padding: 1.5rem;
    }

    &__title {
      font-size: 22px;
      font-weight: bold;
      line-height: 2rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__price {
      font-size: 18px;
      margin-bottom: 1rem;
    }
    &__package_items {
      margin-bottom: 1rem;
    }
    &__package_item {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 0.5rem;
      align-items: center;

      &__check {
        line-height: 0;
      }
    }
    &__description {
      font-size: 14px;
      line-height: 1.5rem;
    }
  }
`;

export default {
  Card,
};
