import React from 'react';
import i18next from 'i18next';

import Amount from 'components/common/Amount';
import { defaultMoneyObject } from 'services/utils';

import rcStyles from './styles.scss';
import { Props } from './index.types';

const AccountAmount: React.FC<Props> = ({
  label, total, hasStrikeThrough, testId,
}: Props) => (
  <Amount
    label={i18next.t(label)}
    labelClasses={[rcStyles.is_label]}
    amount={(total
      && (total.amount || 0) >= 0)
      ? total
      : defaultMoneyObject(0)}
    amountClasses={[rcStyles.is_data]}
    amountProps={{ 'data-test-id': testId }}
    hasStrikeThrough={hasStrikeThrough}
  />
);

export default AccountAmount;
