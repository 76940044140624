import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const IconDragnDrop = (props) => {
  const { className } = props;
  const classes = className ? `${className} ${styles.rc_icon}` : styles.rc_icon;

  return (
    <svg viewBox="0 0 24 24" className={classes}>
      <g transform="translate(4,4)">
        <polygon points="12,13 8,16 4,13 " />
        <rect y="8.9" width="16" height="2" />
        <rect y="4.9" width="16" height="2" />
        <polygon points="8,0 12,3 4,3 " />
      </g>
    </svg>
  );
};

IconDragnDrop.propTypes = {
  className: PropTypes.string,
};

export default IconDragnDrop;
