import {
  object,
  string,
  array,
  boolean,
} from 'yup';

export const validationSchema = object().shape({
  title: string().nullable().required('Title is required'),
  variants: array().of(object().shape({
    name: string().nullable().required('Name is required'),
  })),
  address: object().nullable().shape({
    addressLine1: string().nullable(),
    addressLine2: string().nullable(),
    town: string().nullable(),
    county: string().nullable(),
    postCode: string().nullable(),
    countryCode: string().nullable(),
  }).when(['isAddressRequired'], {
    is: isAddressRequired => isAddressRequired,
    then: object().nullable().shape({
      addressLine1: string().nullable().required('First line of address is required'),
      addressLine2: string().nullable(),
      town: string().nullable().required('Town is required'),
      county: string().nullable(),
      postCode: string().nullable().required('Post Code is required'),
      countryCode: string().nullable().required('Country is required'),
    }).required('Service venue address is required'),
  }),
  isAddressRequired: boolean(),
  category: object().nullable().shape({
    category: string().nullable(),
    name: string().nullable(),
  }).required('Category is required'),
});
