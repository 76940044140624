import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';

import CareRecordAshesSection from 'components/bereavement/CareRecordAshesSection';
import CareRecordBodyForm from 'components/bereavement/CareRecordBodyForm';
import TabContainer from 'components/common/TabContainer';
import { getBereavementAshesRelatedData } from 'services/utils/bereavement';
import { bereavementType, deceasedPersonType } from 'types/bereavement';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const tabClasses = {
  tabs: classNames(
    rcStyles.rc_triangle_up,
  ),
  tab: classNames(
    rcStyles.rc_tabButton,
  ),
  tabBar: classNames(
    rcStyles.rc_tabBar,
  ),
};

const CareRecordSection = ({
  bereavement,
  deceasedPerson,
  activeTab,
  activeCareRecordTab,
  disabled,
  onCareRecordBodyFormSave,
  onChangeTab,
}) => {
  const {
    id: deceasedPersonId,
    firstOffices,
    mortuary,
    transfers,
    viewingNotAdvised,
    reasonForViewingNotAdvised,
    collectionLocation,
    ashesRecords,
  } = deceasedPerson;

  return (
    <div className={styles.u_soft__top_half}>
      <TabContainer
        classes={tabClasses}
        activeIndex={activeCareRecordTab}
        onChange={value => onChangeTab(value)}
        data-test-id="careRecordTabs"
        testIds={{
          tabs: 'careRecordTabs',
          tabHeader: 'careRecordTabHeader',
          tabContents: 'careRecordTabContents',
        }}
        tabs={[{
          label: t('Body'),
          component: (
            <CareRecordBodyForm
              firstOffices={firstOffices}
              mortuary={mortuary}
              transfers={transfers}
              viewingNotAdvised={viewingNotAdvised}
              reasonForViewingNotAdvised={reasonForViewingNotAdvised}
              collectionLocation={collectionLocation}
              disabled={disabled}
              onCareRecordBodyFormSave={onCareRecordBodyFormSave}
              activeTab={activeTab}
              activeCareRecordTab={activeCareRecordTab}
            />
          ),
        },
        {
          label: t('Ashes'),
          component: (
            <CareRecordAshesSection
              bereavement={bereavement}
              deceasedPersonId={deceasedPersonId}
              ashesRecords={ashesRecords}
              relatedData={getBereavementAshesRelatedData(bereavement)}
              disabled={disabled}
            />
          ),
        }]}
      />
    </div>
  );
};

CareRecordSection.propTypes = {
  bereavement: bereavementType.isRequired,
  deceasedPerson: deceasedPersonType.isRequired,
  onCareRecordBodyFormSave: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  activeCareRecordTab: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChangeTab: PropTypes.func.isRequired,
};

export default CareRecordSection;
