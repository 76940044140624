import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import CatalogueSelectionItem from 'components/catalogue/CatalogueSelectionItem';
import { getCategoryDisplayText } from 'services/utils/arrangement';
import styles from 'scss/main.scss';
import { arrangementType, productType, serviceType } from 'types/bereavement';

import rcStyles from './styles.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const EstimateAddSelectionDialog = ({
  open,
  items,
  type,
  category,
  onCloseModal,
  bereavementId,
  arrangement,
  onOpenDuplicateSelectionModal,
}) => (
  <Dialog
    open={open}
    fullScreen
    scroll="paper"
    TransitionComponent={Transition}
    disableBackdropClick
    disableEscapeKeyDown
    className={styles.c_dialogue}
  >
    <div>
      <DialogTitle
        disableTypography
        className={styles.dialog_title_bar}
      >
        <IconButton
          className={classNames(
            styles.o_button__secondary,
            styles.is_close,
          )}
          color="primary"
          variant="contained"
          aria-label="Close"
          onClick={onCloseModal}
          data-test-id="done"
        >
          <CloseIcon />
        </IconButton>
        <div className={styles.is_title}>
          {`${t('Add')} ${getCategoryDisplayText(category)}`}
        </div>
        <div className={styles.is_actions}>
          <Button
            onClick={onCloseModal}
            variant="contained"
            color="secondary"
            data-test-id="done"
            className={styles.o_button__secondary}
          >
            {t('Return to Estimate')}
          </Button>
        </div>
      </DialogTitle>
    </div>
    <div className={styles.c_dialog__overflow}>
      <DialogContent className={styles.c_dialog__content}>
        <div className={styles.o_block_content}>
          {items.length > 0 ? (
            <ul className={rcStyles.rc_selection_items_list}>
              {items.map(item => (
                <li key={item.id}>
                  <CatalogueSelectionItem
                    bereavementId={bereavementId}
                    arrangement={arrangement}
                    type={type}
                    category={category}
                    item={item}
                    onOpenDuplicateSelectionModal={onOpenDuplicateSelectionModal}
                  />
                </li>
              ))}
            </ul>
          ) : (
            <p>{t('No items available.')}</p>
          )}
        </div>
      </DialogContent>
    </div>
  </Dialog>
);

EstimateAddSelectionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([productType, serviceType]),
  ).isRequired,
  category: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  bereavementId: PropTypes.string.isRequired,
  arrangement: arrangementType.isRequired,
  onOpenDuplicateSelectionModal: PropTypes.func.isRequired,
};

export default EstimateAddSelectionDialog;
