import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { withValidation } from '@funeralguide/react-form-validation-hoc';

import { sendUserToFuneralSafeSoftCheckAction } from 'actions/bereavement';
import { Name } from 'types/ts';

import { validationSchema } from './validation';
import { Props, State } from './index.types';
import FuneralSafeEligibilityCheckModal from './FuneralSafeEligibilityCheckModal';

const setURLSearchParameter = (url: URL, key: string, value?: string) => {
  if (value) {
    url.searchParams.set(key, value);
  }
};

class FuneralSafeEligibilityCheckModalContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { bereavedPerson } = props;

    this.state = {
      formData: {
        name: bereavedPerson?.name || {},
        email: bereavedPerson?.emails?.[0] || '',
        phoneNumber: bereavedPerson?.phones?.[0]?.telephone?.number || '',
        postCode: bereavedPerson?.address?.postCode || '',
        hasGDPRBeenConfirmed: false,
      },
      isValidationEnabled: false,
    };
  }

  handleApplicantChange = (key: string, value: string|boolean|Name) => {
    const { validate } = this.props;
    const { formData, isValidationEnabled } = this.state;

    const updatedFormData = {
      ...formData,
      [key]: value,
    };

    if (isValidationEnabled) {
      validate(updatedFormData, validationSchema);
    }

    this.setState({ formData: updatedFormData });
  }


  handleNext = () => {
    const {
      bereavementId,
      arrangementId,
      onClose,
      sendUserToFuneralSafeSoftCheck,
      validate,
    } = this.props;
    const { formData } = this.state;

    this.setState({ isValidationEnabled: true });

    if (!validate(formData, validationSchema)) {
      return;
    }

    if (process.env.FUNERAL_SAFE_SOFTCHECK_URL) {
      const softCheckUrl = new URL(process.env.FUNERAL_SAFE_SOFTCHECK_URL);
      const searchParams = {
        title: formData?.name?.title,
        firstname: formData?.name?.givenName,
        middlename: formData?.name?.middleName,
        lastname: formData?.name?.familyName,
        email: formData?.email,
        mobile: formData?.phoneNumber,
        postcode: formData?.postCode,
      };

      (Object.keys(searchParams) as Array<keyof typeof searchParams>).forEach((key) => {
        const value = searchParams[key] as string|undefined;
        setURLSearchParameter(softCheckUrl, key, value);
      });

      const uri = softCheckUrl.toString();
      const openWindow = window.open(uri, uri);
      if (openWindow) {
        openWindow.focus();
      }

      sendUserToFuneralSafeSoftCheck(bereavementId, arrangementId, formData.hasGDPRBeenConfirmed);
    }

    onClose();
  }

  render() {
    const { isOpen, errors, onClose } = this.props;
    const { formData } = this.state;

    return (
      <FuneralSafeEligibilityCheckModal
        isOpen={isOpen}
        formData={formData}
        errors={errors}
        onApplicantChange={this.handleApplicantChange}
        onClose={onClose}
        onNext={this.handleNext}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  sendUserToFuneralSafeSoftCheck: sendUserToFuneralSafeSoftCheckAction,
}, dispatch);

export default withValidation(
  connect(null, mapDispatchToProps)(FuneralSafeEligibilityCheckModalContainer),
);
