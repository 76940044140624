import {
  string, object, array, date, ref,
} from 'yup';

function compareDateTime(value) {
  const { startDateTime } = this.parent;
  return value.getTime() !== startDateTime.getTime();
}

export const validationSchema = object().shape({
  location: object().nullable().required('Viewing address is required').shape({
    addressLine1: string().nullable().required('First line of address is required'),
    addressLine2: string().nullable(),
    town: string().required('Town is required'),
    county: string().nullable(),
    postCode: string().nullable().required('Post Code is required'),
    countryCode: string().nullable().required('Country is required'),
  }),
  startDateTime: date().nullable().required('Appointment date is required'),
  endDateTime: date().nullable().required('Appointment date is required')
    .test('not-same-date-time', 'End date must be after start date', compareDateTime)
    .min(
      ref('startDateTime'),
      'End date must be after start date',
    ),
  permittedAttendees: array().of(string()),
});
