import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  closeAccountAction,
  reopenAccountAction,
  createCreditAction,
  createPaymentAction,
  voidInvoiceAction,
  editCreditAction,
  editPaymentAction,
  deletePaymentAction,
  deleteCreditAction,
  getDocumentsAction,
  markInvoiceAsHasBeenSentToClientAction,
  downloadInvoiceAction,
  downloadInvoiceTemplateAction,
  sendInvoiceTemplateToXeroAction,
  saveDocumentsAction,
} from 'actions/account';
import { fetchBereavementAction, saveNotesAction } from 'actions/bereavement';
import {
  setTenantCorrespondenceTemplateDefinitionsAction,
} from 'actions/user';
import PageLoader from 'components/common/PageLoader';
import { featureFlags } from 'constants/features';
import { accountTransactionType, accountStatuses } from 'constants/account';
import { scopes as scopePermissions } from 'constants/scopes';
import { AccountProvider } from 'contexts/account';
import { generateHexId, hasPermissions } from 'services/utils';
import { getEmptyInvoice, getDefaultInvoiceNote } from 'services/utils/account';
import { buildTemplateDefinitionsLookup } from 'services/utils/correspondence';
import { apolloClientType } from 'types/apollo';
import { bereavementType } from 'types/bereavement';
import { directoryListingType } from 'types/directoryListing';
import { historyType, locationType, matchType } from 'types/reactRouter';

import { getTenantCorrespondenceTemplateDefinitions } from './queries.gql';
import AccountsScreen from './AccountsScreen';

export class AccountsScreenContainer extends Component {
  static propTypes = {
    client: apolloClientType.isRequired,
    scopes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    hasXeroIntegration: PropTypes.bool.isRequired,
    fetchBereavement: PropTypes.func.isRequired,
    bereavements: PropTypes.arrayOf(bereavementType),
    directoryListings: PropTypes.arrayOf(directoryListingType),
    match: matchType,
    location: locationType,
    tenantId: PropTypes.string,
    history: historyType.isRequired,
    isLoadingDirectoryListings: PropTypes.bool,
    tenantHasGivenConsentToAXeroApp: PropTypes.bool.isRequired,
    closeAccount: PropTypes.func.isRequired,
    reopenAccount: PropTypes.func.isRequired,
    createCredit: PropTypes.func.isRequired,
    createPayment: PropTypes.func.isRequired,
    voidInvoice: PropTypes.func.isRequired,
    editCredit: PropTypes.func.isRequired,
    editPayment: PropTypes.func.isRequired,
    deletePayment: PropTypes.func.isRequired,
    deleteCredit: PropTypes.func.isRequired,
    getDocuments: PropTypes.func.isRequired,
    downloadInvoiceTemplate: PropTypes.func.isRequired,
    downloadInvoice: PropTypes.func.isRequired,
    saveNotes: PropTypes.func.isRequired,
    saveDocuments: PropTypes.func.isRequired,
    markInvoiceAsHasBeenSentToClient: PropTypes.func.isRequired,
    sendInvoiceTemplateToXero: PropTypes.func.isRequired,
    tenantCorrespondenceTemplateDefinitions: PropTypes.arrayOf(PropTypes.any),
    setTenantCorrespondenceTemplateDefinitions: PropTypes.func.isRequired,
  }

  static getDerivedStateFromProps(nextProps) {
    const {
      fetchBereavement,
      bereavements,
      match,
    } = nextProps;

    const bereavement = bereavements.find(bereavementObj => bereavementObj.id === match.params.bereavementId);

    if (!bereavement) {
      fetchBereavement(match.params.bereavementId);
    }

    return { bereavement, isLoading: !bereavement };
  }

  constructor(props) {
    super(props);

    const { tenantCorrespondenceTemplateDefinitions } = props;
    const templateDefinitionsLookup = buildTemplateDefinitionsLookup(tenantCorrespondenceTemplateDefinitions);

    this.state = {
      bereavement: {},
      templateDefinitionsLookup,
      isLoading: true,
      isModalOpen: {
        closeAccount: false,
        generateInvoice: false,
        addPayment: false,
        addCredit: false,
        confirmDelete: false,
      },
      anchorElements: {
        generateInvoice: null,
        newTransactions: null,
      },
      invoiceTemplateSendingToXero: null,
      isMarkingHasBeenSentToClient: false,
      currentInvoiceDownloading: null,
      invoiceToVoid: null,
      transactionBeingModified: {
        transactionType: null,
        transaction: null,
      },
      invoiceBeingEdited: null,
    };
  }

  componentDidMount() {
    const {
      client,
      match,
      tenantId,
      getDocuments,
      setTenantCorrespondenceTemplateDefinitions,
    } = this.props;

    getDocuments(match.params.bereavementId);

    client.query({
      query: getTenantCorrespondenceTemplateDefinitions,
      variables: { tenantId },
    }).then(({ data }) => {
      const { correspondenceTemplateDefinitions: definitions } = data.tenant;
      const templateDefinitionsLookup = buildTemplateDefinitionsLookup(definitions);

      this.setState({ templateDefinitionsLookup });
      setTenantCorrespondenceTemplateDefinitions(definitions);
    });
  }

  setTransactionBeingModified = (transactionType, transaction) => {
    this.setState({
      transactionBeingModified: {
        transactionType,
        transaction,
      },
    });
  }

  toggleAccountStatus = () => {
    const { reopenAccount } = this.props;
    const { bereavement, bereavement: { accountStatus } } = this.state;

    if (accountStatus === accountStatuses.OPEN) {
      this.setState(prevState => ({
        isModalOpen: {
          ...prevState.isModalOpen,
          closeAccount: true,
        },
      }));
    } else if (accountStatus === accountStatuses.CLOSED) {
      reopenAccount(bereavement.id);
    }
  }

  handleConfirmCloseAccount = (reason) => {
    const { bereavement } = this.state;
    const { accountStatus } = bereavement;

    this.handleCancelCloseAccount();

    if (accountStatus === accountStatuses.OPEN) {
      const { closeAccount } = this.props;
      closeAccount(bereavement.id, reason);
    }
  }

  handleCancelCloseAccount = () => {
    this.setState(prevState => ({
      isModalOpen: {
        ...prevState.isModalOpen,
        closeAccount: false,
      },
    }));
  }

  handleSaveCredit = (credit, isEditing) => {
    const { createCredit, editCredit } = this.props;
    const { bereavement } = this.state;

    if (isEditing) {
      editCredit({ bereavementId: bereavement.id, ...credit });
    } else {
      createCredit({ bereavementId: bereavement.id, ...credit });
    }
  }

  handleConfirmDeleteTransaction = () => {
    const { deletePayment, deleteCredit } = this.props;
    const { transactionBeingModified: { transaction }, bereavement } = this.state;

    const deleteAction = transaction.type === accountTransactionType.PAYMENT
      ? deletePayment
      : deleteCredit;

    this.toggleModal('confirmDelete');
    deleteAction({ bereavementId: bereavement.id, ...transaction });
    this.setTransactionBeingModified();
  }

  handleSavePayment = (payment, isEditing) => {
    const { createPayment, editPayment } = this.props;
    const { bereavement } = this.state;

    if (isEditing) {
      editPayment({ bereavementId: bereavement.id, ...payment });
    } else {
      createPayment({ id: generateHexId(), bereavementId: bereavement.id, ...payment });
    }
  }

  handleDownloadInvoice = (invoiceId) => {
    const {
      hasXeroIntegration,
      downloadInvoiceTemplate,
      downloadInvoice,
    } = this.props;
    const { bereavement } = this.state;
    const fetchInvoiceAction = hasXeroIntegration ? downloadInvoiceTemplate : downloadInvoice;

    this.setState({ currentInvoiceDownloading: invoiceId });

    fetchInvoiceAction(
      bereavement.id,
      invoiceId,
      () => this.setState({ currentInvoiceDownloading: null }),
    );
  }

  handleSendInvoiceTemplateToXero = (invoiceTemplateId) => {
    const { sendInvoiceTemplateToXero } = this.props;
    const {
      bereavement: { id, account: { invoiceTemplates } },
    } = this.state;
    const invoiceTemplate = invoiceTemplates.find(invoice => invoice.id === invoiceTemplateId);

    this.setState({ invoiceTemplateSendingToXero: invoiceTemplateId });
    sendInvoiceTemplateToXero(id, invoiceTemplate, () => {
      this.setState({ invoiceTemplateSendingToXero: null });
    });
  }

  handleVoidInvoice = (invoiceToVoid) => {
    this.setState({ invoiceToVoid });
  }

  handleConfirmVoidInvoice = () => {
    const { voidInvoice } = this.props;
    const { bereavement, invoiceToVoid } = this.state;
    voidInvoice(bereavement.id, invoiceToVoid);
    this.setState({ invoiceToVoid: null });
  }

  handleCancelVoidInvoice = () => {
    this.setState({ invoiceToVoid: null });
  }

  handleViewCase = () => {
    const { bereavement } = this.state;
    const { history } = this.props;
    history.push(`/case/${bereavement.id}/arrangement`);
  }

  handleEditInvoice = (invoiceId) => {
    const { hasXeroIntegration } = this.props;
    const {
      bereavement: { account: { invoices, invoiceTemplates } },
    } = this.state;

    const invoiceToEdit = hasXeroIntegration
      ? invoiceTemplates.find(invoice => invoice.id === invoiceId)
      : invoices.find(invoice => invoice.id === invoiceId);

    this.toggleGenerateInvoiceModal(invoiceToEdit.invoiceType, invoiceToEdit);
  }

  toggleMenu = (key, target) => {
    this.setState(prevState => ({
      anchorElements: {
        ...prevState.anchorElements,
        [key]: target,
      },
    }));
  }

  toggleGenerateInvoiceModal = (invoiceType = null, invoiceBeingEdited = null) => {
    const { bereavement } = this.state;
    const firstDeceasedPerson = bereavement.deceasedPeople[0];
    const defaultInvoiceNote = getDefaultInvoiceNote(firstDeceasedPerson.name, bereavement.funeralDate);
    const invoice = invoiceBeingEdited || getEmptyInvoice(invoiceType, defaultInvoiceNote);
    this.setState(prevState => ({
      invoiceBeingEdited: invoice,
      isModalOpen: {
        ...prevState.isModalOpen,
        generateInvoice: !prevState.isModalOpen.generateInvoice,
      },
    }));
  }

  toggleModal = (key) => {
    this.setState(prevState => ({
      isModalOpen: {
        ...prevState.isModalOpen,
        [key]: !prevState.isModalOpen[key],
      },
    }));
  }

  handleMarkInvoiceAsHasBeenSentToClient = (bereavementId, invoiceTemplate, hasBeenSentToClient) => {
    const { markInvoiceAsHasBeenSentToClient } = this.props;
    this.setState({ isMarkingHasBeenSentToClient: true });

    markInvoiceAsHasBeenSentToClient(bereavementId, invoiceTemplate, hasBeenSentToClient);
    setTimeout(() => this.setState({ isMarkingHasBeenSentToClient: false }), 1000);
  }

  render() {
    const {
      anchorElements,
      bereavement,
      currentInvoiceDownloading,
      invoiceBeingEdited,
      invoiceTemplateSendingToXero,
      invoiceToVoid,
      isLoading,
      isMarkingHasBeenSentToClient,
      isModalOpen,
      templateDefinitionsLookup,
      transactionBeingModified,
    } = this.state;
    const {
      directoryListings,
      hasXeroIntegration,
      isLoadingDirectoryListings,
      location,
      saveDocuments,
      saveNotes,
      scopes,
      tenantHasGivenConsentToAXeroApp,
      tenantId,
    } = this.props;
    const { accountStatus } = bereavement || {};
    const isAccountOpen = accountStatus && accountStatus === accountStatuses.OPEN;
    const hasWritePermissions = hasPermissions([scopePermissions.BEREAVEMENT_ACCOUNT_WRITE], scopes);
    const targetInvoiceId = location?.state?.invoiceId;

    return (
      <AccountProvider
        isAccountOpen={isAccountOpen}
        hasWritePermissions={hasWritePermissions}
        hasXeroIntegration={hasXeroIntegration}
      >
        <PageLoader
          isLoading={isLoading || isLoadingDirectoryListings}
          fullScreen
        />
        {!isLoading && (
          <AccountsScreen
            anchorElements={anchorElements}
            bereavement={bereavement}
            currentInvoiceDownloading={currentInvoiceDownloading}
            directoryListings={directoryListings}
            invoiceBeingEdited={invoiceBeingEdited}
            invoiceTemplateSendingToXero={invoiceTemplateSendingToXero}
            invoiceToVoid={invoiceToVoid}
            isLoading={isLoading || isLoadingDirectoryListings}
            isMarkingHasBeenSentToClient={isMarkingHasBeenSentToClient}
            isModalOpen={isModalOpen}
            targetInvoiceId={targetInvoiceId}
            templateDefinitionsLookup={templateDefinitionsLookup}
            tenantHasGivenConsentToAXeroApp={tenantHasGivenConsentToAXeroApp}
            tenantId={tenantId}
            transactionBeingModified={transactionBeingModified}
            onAddCredit={this.handleSaveCredit}
            onAddPayment={this.handleSavePayment}
            onCancelCloseAccount={this.handleCancelCloseAccount}
            onCancelVoidInvoice={this.handleCancelVoidInvoice}
            onConfirmCloseAccount={this.handleConfirmCloseAccount}
            onConfirmDeleteTransaction={this.handleConfirmDeleteTransaction}
            onConfirmVoidInvoice={this.handleConfirmVoidInvoice}
            onDocumentsSave={saveDocuments}
            onDownloadInvoice={this.handleDownloadInvoice}
            onEditInvoice={this.handleEditInvoice}
            onMarkHasBeenSentToClient={this.handleMarkInvoiceAsHasBeenSentToClient}
            onNotesSave={saveNotes}
            onSendInvoiceTemplateToXero={this.handleSendInvoiceTemplateToXero}
            onViewCase={this.handleViewCase}
            onVoidInvoice={this.handleVoidInvoice}
            setTransactionBeingModified={this.setTransactionBeingModified}
            toggleAccountStatus={this.toggleAccountStatus}
            toggleGenerateInvoiceModal={this.toggleGenerateInvoiceModal}
            toggleMenu={this.toggleMenu}
            toggleModal={this.toggleModal}
          />
        )}
      </AccountProvider>
    );
  }
}

const mapStateToProps = state => ({
  scopes: state.userStore.user.policy.scopes,
  hasXeroIntegration: state.userStore.user.tenantFeatureFlags.includes(featureFlags.XERO_INTEGRATION),
  bereavements: state.bereavementStore.bereavements,
  tenantId: state.userStore.user.tenantId,
  tenantHasGivenConsentToAXeroApp: state.userStore.user.tenantHasGivenConsentToAXeroApp,
  tenantCorrespondenceTemplateDefinitions: state.userStore.user.tenantCorrespondenceTemplateDefinitions,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setTenantCorrespondenceTemplateDefinitions: setTenantCorrespondenceTemplateDefinitionsAction,
  saveNotes: saveNotesAction,
  saveDocuments: saveDocumentsAction,
  closeAccount: closeAccountAction,
  reopenAccount: reopenAccountAction,
  fetchBereavement: fetchBereavementAction,
  voidInvoice: voidInvoiceAction,
  createCredit: createCreditAction,
  createPayment: createPaymentAction,
  editCredit: editCreditAction,
  editPayment: editPaymentAction,
  deletePayment: deletePaymentAction,
  deleteCredit: deleteCreditAction,
  getDocuments: getDocumentsAction,
  markInvoiceAsHasBeenSentToClient: markInvoiceAsHasBeenSentToClientAction,
  downloadInvoice: downloadInvoiceAction,
  downloadInvoiceTemplate: downloadInvoiceTemplateAction,
  sendInvoiceTemplateToXero: sendInvoiceTemplateToXeroAction,
}, dispatch);

export default withRouter(
  withApollo(
    connect(mapStateToProps, mapDispatchToProps)(AccountsScreenContainer),
  ),
);
