import React from 'react';
import PropTypes from 'prop-types';
import { SortableContainer } from 'react-sortable-hoc';

import styles from 'scss/main.scss';

import SortableCategoryItem from 'components/catalogue/SortableCategoryItem';

const SortableCategoryList = SortableContainer(({
  categories,
  disabled,
  onClickIncludeInCatalogue,
  onEditCategory,
  isIncludible,
}) => (
  <div
    className={styles.c_grid__3col}
    data-test-id="sortableCatalogueContainer"
  >
    {categories.map((category, index) => (
      <SortableCategoryItem
        category={category}
        key={index}
        index={index}
        sortIndex={index}
        onClickIncludeInCatalogue={onClickIncludeInCatalogue}
        onEditCategory={onEditCategory}
        disabled={disabled}
        isSortable={!disabled}
        isIncludible={isIncludible}
        isEditable={category.isEditable}
      />
    ))}
  </div>
));

SortableCategoryList.propTypes = {
  category: PropTypes.objectOf(PropTypes.any),
  onClickIncludeInCatalogue: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  isIncludible: PropTypes.bool.isRequired,
};

export default SortableCategoryList;
