import React from 'react';
import PropTypes from 'prop-types';
import { SortableContainer } from 'react-sortable-hoc';

import styles from 'scss/main.scss';
import { productType, serviceType } from 'types/bereavement';

import SortableProductServiceItem from 'components/catalogue/SortableProductServiceItem';

const SortableProductServiceList = SortableContainer(({
  visibilityKey,
  labelForIncludeItem,
  items,
  disabled,
  onSetIsVisible,
  isIncludible,
}) => (
  <div
    className={styles.c_grid__3col}
    data-test-id="sortableProductServiceContainer"
  >
    {items.map((item, index) => (
      <SortableProductServiceItem
        visibilityKey={visibilityKey}
        labelForIncludeItem={labelForIncludeItem}
        item={item}
        key={index}
        index={index}
        sortIndex={index}
        disabled={disabled}
        isSortable={!disabled}
        onSetIsVisible={onSetIsVisible}
        isIncludible={isIncludible}
      />
    ))}
  </div>
));

SortableProductServiceList.propTypes = {
  visibilityKey: PropTypes.string.isRequired,
  labelForIncludeItem: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      productType.isRequired,
      serviceType.isRequired,
    ]),
  ).isRequired,
  disabled: PropTypes.bool.isRequired,
  onSetIsVisible: PropTypes.func.isRequired,
  isIncludible: PropTypes.bool,
};

export default SortableProductServiceList;
