import React from 'react';
import classNames from 'classnames';
import i18next from 'i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import FuneralSafeApplicantForm from 'components/estimate/FuneralSafeApplicantForm';
import styles from 'scss/main.scss';

import rcStyles from './styles.scss';
import { Props } from './FuneralSafeEligibilityCheckModal.types';

const Transition = React.forwardRef(props => (
  <Slide direction="down" {...props} />
));

const FuneralSafeEligibilityCheckModal: React.FC<Props> = ({
  formData, isOpen, errors, onClose, onApplicantChange, onNext,
}: Props) => (
  <Dialog
    onClose={onClose}
    fullScreen
    open={isOpen}
    TransitionComponent={Transition as React.ComponentType}
    className={styles.c_dialogue}
    disableBackdropClick
    disableEscapeKeyDown
  >
    <DialogTitle
      disableTypography
      className={classNames(styles.dialog_title_bar, styles.no_margin_bottom)}
    >
      <IconButton
        className={classNames(
          styles.o_button__round__small,
          styles.is_close,
        )}
        color="secondary"
        aria-label="Close"
        onClick={onClose}
        data-test-id="closeModal"
      >
        <CloseIcon />
      </IconButton>
      <div />
      <div className={styles.is_actions}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={onNext}
          data-test-id="next"
        >
          {i18next.t('Next')}
        </Button>
      </div>
    </DialogTitle>
    <div className={rcStyles.wrap}>
      <div className={rcStyles.content}>
        <div className={rcStyles.content__information}>
          <div className={rcStyles.content__information__inner}>
            <div className={rcStyles.logo}>
              <img src="/img/funeral_safe/logo.png" alt="Funeral Safe logo" />
            </div>
            <h1 className={styles.pica}>
              {i18next.t('Eligibility check')}
            </h1>
            <p className={styles.primer}>
              {i18next.t('This information will be shared with Funeral Safe to help you create the eligibility check.')}
            </p>
            <p className={styles.primer}>
              {i18next.t('Please ensure all information is correct. You will have a chance to check these details again before they are submitted.')}
            </p>
          </div>
        </div>
        <div className={rcStyles.content__details}>
          <div className={rcStyles.content__details__inner}>
            <h2 className={styles.long_primer}>
              {i18next.t('Applicant information')}
            </h2>
            <div>
              <FuneralSafeApplicantForm
                formData={formData}
                errors={errors}
                onApplicantChange={onApplicantChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
);

export default FuneralSafeEligibilityCheckModal;
