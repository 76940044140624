import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import MenuItem from '@material-ui/core/MenuItem';

import Card from 'components/common/Card';
import Select from 'components/common/Select';
import { getFormattedDate, buildNameString } from 'services/utils';

import classNames from 'classnames';
import styles from 'scss/main.scss';

const ActionCard = ({
  action,
  createdBy,
  createdDateTime,
  actionOptions,
  actionOptionLabels,
  children,
  onChange,
  disabled,
  'data-test-id': testId,
}) => (
  <Card
    disabled={disabled}
    testId={testId}
  >
    <div
      className={classNames(
        styles.o_fb__item,
        styles.u_push__bottom,
      )}
      data-test-id={`${testId}Type`}
    >
      <Select
        label={t('Action taken')}
        value={action}
        disabled={disabled}
        onChange={event => onChange(event.target.value)}
        inputProps={{
          'data-test-id': 'actionTaken',
        }}
        fullWidth
      >
        {Object.keys(actionOptions).map(key => (
          <MenuItem
            key={key}
            value={key}
            data-test-id={`${testId}Option`}
          >
            {actionOptionLabels[key]}
          </MenuItem>
        ))}
      </Select>
    </div>
    {children}
    <div
      className={classNames(
        styles.o_fb__item,
        styles.brevier,
      )}
      data-test-id={`${testId}CreatedByAndDate`}
    >
      {t('Action created by ')}
      {createdBy && `${buildNameString(createdBy.name)}, `}
      {getFormattedDate(createdDateTime, 'DD MMM - HH:mm')}
    </div>
  </Card>
);

ActionCard.propTypes = {
  'action': PropTypes.string,
  'createdBy': PropTypes.objectOf(PropTypes.any),
  'createdDateTime': PropTypes.string,
  'actionOptions': PropTypes.objectOf(PropTypes.any).isRequired,
  'actionOptionLabels': PropTypes.objectOf(PropTypes.any).isRequired,
  'children': PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  'disabled': PropTypes.bool,
  'onChange': PropTypes.func.isRequired,
  'data-test-id': PropTypes.string.isRequired,
};

export default ActionCard;
