import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';

import SortablePackageList from 'components/catalogue/SortablePackageList';
import ScreenLayout from 'components/common/ScreenLayout';
import IconDragnDrop from 'icons/IconDragnDrop';
import IconLoad from 'icons/IconLoad';
import IconLoadSml from 'icons/IconLoadSml';
import styles from 'scss/main.scss';

import rcStyles from './styles.scss';

const AdminArrangementPackagesOrderScreen = ({
  items,
  isLoading,
  isSaving,
  isSortEnabled,
  onCancel,
  onSave,
  onSetIsVisible,
  onSortItems,
  onToggleIsSortEnabled,
}) => (
  <ScreenLayout
    title={t('Packages')}
    dashboardBannerProps={{
      breadcrumbs: [
        { path: '/', title: t('Dashboard') }, { path: '/catalogue', title: t('Brochure management') },
        { path: '/catalogue/arrangement-settings', title: t('Arrangement settings') },
        { path: '/catalogue/arrangement-settings/packages', title: t('Packages') },
      ],
      description: t('Set the order in which these items should be displayed in the arrangement view. When you\'re done, click save.'),
    }}
  >
    <div className={styles.o_view}>
      <div className={classNames(
        styles.o_view__main,
        styles.o_block_content,
      )}
      >
        {isLoading ? (
          <div className={classNames(
            styles.c_page__full_height,
          )}
          >
            <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
              <IconLoad />
            </div>
          </div>
        ) : (
          <>
            <span>
              <Button
                onClick={onToggleIsSortEnabled}
                variant="outlined"
                color="primary"
                data-test-id="enableCategorySort"
              >
                {isSortEnabled ? t('Done ordering') : t('Set order')}
              </Button>
            </span>
            {isSortEnabled && (
              <div className={classNames(
                styles.o_fb__item__flexOne,
                rcStyles.rc_bg_main_sortable,
              )}
              >
                <div className={
                  classNames(
                    styles.o_fb,
                    styles.o_fb__align_left,
                  )}
                >
                  <div className={classNames(
                    styles.u_push__right,
                    rcStyles.rc_sortable_spacing,
                  )}
                  >
                    <IconDragnDrop />
                  </div>
                  <div className={classNames(
                    styles.u_push__ends,
                    styles.primer,
                    styles.is_grey_dark,
                  )}
                  >
                    {t('Press and hold to drag & re-order a category')}
                  </div>
                </div>
              </div>
            )}
            <div className={styles.u_push__ends}>
              <SortablePackageList
                visibilityKey="arrangementVisibility"
                labelForIncludeItem="Show"
                axis="xy"
                items={items}
                disabled={!isSortEnabled}
                onSortEnd={onSortItems}
                onSetIsVisible={onSetIsVisible}
              />
            </div>
            <div className={classNames(
              styles.o_button_bar,
              styles.has_top_border,
            )}
            >
              <Button
                onClick={onCancel}
                variant="outlined"
                color="primary"
                data-test-id="cancelOrderCategories"
              >
                {t('Cancel')}
              </Button>
              <Button
                onClick={onSave}
                variant="contained"
                color="primary"
                data-test-id="saveOrderCategories"
                disabled={isSortEnabled}
              >
                {isSaving ? (
                  <div
                    className={classNames(
                      styles.c_svg_loader_sml,
                      styles.c_svg_loader_sml_btn,
                    )}
                    data-test-id="buttonLoader"
                  >
                    <IconLoadSml />
                  </div>
                ) : t('Save')}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  </ScreenLayout>
);

AdminArrangementPackagesOrderScreen.propTypes = {
  items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  isSortEnabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onSetIsVisible: PropTypes.func.isRequired,
  onSortItems: PropTypes.func.isRequired,
  onToggleIsSortEnabled: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default AdminArrangementPackagesOrderScreen;
