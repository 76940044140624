export const possessionTypes = [
  { value: 'BRACELET', placeholder: 'Bracelet' },
  { value: 'EARRING', placeholder: 'Earring' },
  { value: 'MONEY', placeholder: 'Money' },
  { value: 'NECKLACE', placeholder: 'Necklace' },
  { value: 'RING', placeholder: 'Ring' },
  { value: 'WATCH', placeholder: 'Watch' },
  { value: 'BAG', placeholder: 'Bag' },
  { value: 'CLOTHING', placeholder: 'Clothing' },
  { value: 'DEVICE', placeholder: 'Device' },
  { value: 'OTHER', placeholder: 'Other' },
];

export const possessionActionTaken = [
  { value: 'IN_STORAGE', placeholder: 'In storage' },
  { value: 'WITH_BODY', placeholder: 'With body' },
  { value: 'RETURNED_TO_FAMILY', placeholder: 'Returned to family' },
  { value: 'DONATED_TO_CHARITY', placeholder: 'Donated to charity' },
  { value: 'DISPOSED_OF', placeholder: 'Disposed of' },
  { value: 'RECYCLED', placeholder: 'Recycled' },
];

export const possessionsFamilyWishes = [
  { value: 'RETURN_TO_FAMILY', placeholder: 'Return to family' },
  { value: 'REMAIN_WITH_BODY', placeholder: 'Remain with body' },
  { value: 'DONATE_TO_CHARITY', placeholder: 'Donate to charity' },
  { value: 'DISPOSE_OF', placeholder: 'Dispose of' },
  { value: 'RECYCLE', placeholder: 'Recycle' },
];
