import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { downloadCataloguePreviewAction } from 'actions/catalogue';
import AdminCataloguePreviewModal from './AdminCataloguePreviewModal';
import { validationSchema } from './validation';

export class AdminCataloguePreviewModalContainer extends Component {
  static propTypes = {
    downloadCataloguePreview: PropTypes.func.isRequired,
    categories: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
    isOpen: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedHome: null,
      isValidationEnabled: false,
    };
  }

  handleChangeHome = (selectedHome) => {
    const { validate } = this.props;
    const { isValidationEnabled } = this.state;

    if (isValidationEnabled) {
      validate({ selectedHome }, validationSchema, true);
    }
    this.setState({ selectedHome });
  };

  handleCancel = () => {
    const { onCancel } = this.props;

    this.setState({
      selectedHome: null,
    });
    onCancel();
  };

  handlePreview = () => {
    const {
      validate,
    } = this.props;
    const { selectedHome } = this.state;
    const isValid = validate({ selectedHome }, validationSchema, true);

    if (isValid) {
      this.setState({
        isValidationEnabled: true,
      });
      const uri = `/portal/${selectedHome.id}`;
      window.open(uri, uri).focus();
      this.handleCancel();
    }
  }

  render() {
    const { isOpen, errors } = this.props;

    return (
      <AdminCataloguePreviewModal
        {...this.state}
        errors={errors}
        isOpen={isOpen}
        onCancel={this.handleCancel}
        onChangeHome={this.handleChangeHome}
        onPreview={this.handlePreview}
      />
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  downloadCataloguePreview: downloadCataloguePreviewAction,
}, dispatch);

export default withValidation(connect(null, mapDispatchToProps)(AdminCataloguePreviewModalContainer));
