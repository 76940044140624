import styled from 'styled-components';

const Card = styled.aside<{ imageUri: string|null }>`
  background-color: #ffffff;
  border: 1px solid #D8D8D8;
  border-radius: 3px;
  cursor: pointer;
  display: grid;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(.25,.8,.25,1);

  &:hover {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }

  &.active {
    border-color: #514441;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }

  & .card_image {
    background-image: ${props => props?.imageUri && `url('${props.imageUri}')`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    box-sizing: border-box;
    height: 100%;
    padding-top: 76.7%;
    width: 100%;
  }

  & .card_detail {
    line-height: 1.2;
    overflow: hidden;
    padding: 0.5rem;

    @media screen and (min-width: 768px) {
      padding: 1rem;
    }

    &__title {
      font-size: 1rem;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__price {
      &__from {
        font-size: 0.85rem;
      }

      &__amount {
        font-size: 0.9rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
`;

export default {
  Card,
};
