export const mockHearseVehicle = {
  isHearse: true,
  noOfPassengers: 1,
  journeys: [],
};

export const mockPassengerVehicle = {
  isHearse: false,
  noOfPassengers: 4,
  journeys: [],
};

export const mockJourney = {
  dateTime: null,
  type: 'TO',
  location: {
    addressLine1: '',
    addressLine2: '',
    town: '',
    county: '',
    postCode: '',
    countryCode: 'GB',
  },
};

export const mockLocation = {
  addressLine1: '',
  addressLine2: '',
  town: '',
  county: '',
  postCode: '',
  countryCode: '',
};
