import PropTypes from 'prop-types';
import { organisationalUnitTypes } from 'constants/organisationalUnits';
import { addressType, phonesType, emailsType } from 'types/common';

export const organisationalUnitTypeType = PropTypes.oneOf(
  Object.values(organisationalUnitTypes),
);

export const organisationalUnitType = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  address: addressType,
  phones: PropTypes.oneOfType([
    phonesType,
    PropTypes.arrayOf(phonesType),
  ]),
  emails: emailsType,
  website: PropTypes.arrayOf(PropTypes.string),
  note: PropTypes.string,
  parentId: PropTypes.string,
  imageId: PropTypes.string,
  parent: PropTypes.string,
});
