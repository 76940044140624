import { t } from 'i18next';
import LoginScreen from 'screens/LoginScreen';
import LogoutScreen from 'screens/LogoutScreen';
import ResetPasswordRequestScreen from 'screens/password/ResetPasswordRequestScreen';
import ResetPasswordScreen from 'screens/password/ResetPasswordScreen';
import AdminDashboardScreen from 'screens/admin/AdminDashboardScreen';
import FirstCallScreen from 'screens/bereavement/FirstCallScreen';
import CaseScreen from 'screens/bereavement/CaseScreen';
import CaseListScreen from 'screens/bereavement/CaseListScreen';
import ImportExportScreen from 'screens/ImportExportScreen';
import AdminDirectoryListingSectionScreen from 'screens/admin/AdminDirectoryListingSectionScreen';
import AdminHomesSectionScreen from 'screens/admin/AdminHomesSectionScreen';
import AdminHomeGroupListScreen from 'screens/admin/AdminHomeGroupListScreen';
import AdminStaffMembersSectionScreen from 'screens/admin/AdminStaffMembersSectionScreen';
import AdminCatalogueSectionScreen from 'screens/admin/AdminCatalogueSectionScreen';
import AdminLetterTemplatesSectionScreen from 'screens/admin/AdminLetterTemplatesSectionScreen';
import TasksListScreen from 'screens/TasksListScreen';
import LiteArrangementSectionScreen from 'screens/lite/LiteArrangementSectionScreen';
import AccountsSectionScreen from 'screens/accounts/AccountsSectionScreen';
import XeroAuthenticationScreen from 'screens/XeroAuthenticationScreen';
import { featureFlags } from './features';
import { scopes as permissions } from './scopes';

const routes = [
  {
    path: '/',
    title: t('Dashboard'),
    component: AdminDashboardScreen,
  },
  {
    path: '/catalogue',
    title: t('Manage brochure'),
    component: AdminCatalogueSectionScreen,
    hasSubRoutes: true,
  },
  {
    path: '/accounts',
    title: t('Manage accounts'),
    component: AccountsSectionScreen,
    hasSubRoutes: true,
    requiredScopes: [permissions.BEREAVEMENT_ACCOUNT_READ],
    requiredFeatureFlags: [featureFlags.ACCOUNTS_SCREEN],
  },
  {
    path: '/homes',
    title: t('Manage homes'),
    component: AdminHomesSectionScreen,
    hasSubRoutes: true,
    requiredScopes: [permissions.VIEW_ORGANISATIONAL_UNITS_TILE],
  },
  {
    path: '/homegroups',
    title: t('Home groups'),
    component: AdminHomeGroupListScreen,
    hasSubRoutes: true,
  },
  {
    path: '/staff-members',
    title: t('Manage Staff'),
    component: AdminStaffMembersSectionScreen,
    hasSubRoutes: true,
    requiredScopes: [permissions.VIEW_STAFF_TILE],
  },
  {
    path: '/directory-listings',
    title: t('Manage Business Directory'),
    component: AdminDirectoryListingSectionScreen,
    hasSubRoutes: true,
    requiredScopes: [permissions.VIEW_DIRECTORY_LISTING_TILE],
    requiredFeatureFlags: [featureFlags.DIRECTORY_LISTINGS_SCREEN],
  },
  {
    path: '/templates',
    title: t('Letter Templates'),
    component: AdminLetterTemplatesSectionScreen,
    hasSubRoutes: true,
  },
  {
    path: '/reset-password',
    title: t('Reset Password'),
    component: ResetPasswordRequestScreen,
    isPublic: true,
  },
  {
    path: '/reset-password/:token',
    title: t('Reset Confirmation'),
    component: ResetPasswordScreen,
    isPublic: true,
  },
  {
    path: '/logout',
    title: t('Logout'),
    component: LogoutScreen,
    isPublic: true,
  },
  {
    path: '/login',
    title: t('Login'),
    component: LoginScreen,
    isPublic: true,
  },
  {
    path: '/first-call',
    title: t('First Call'),
    component: FirstCallScreen,
  },
  {
    path: '/case',
    component: CaseListScreen,
    title: t('Funerals'),
    requiredScopes: [permissions.VIEW_BEREAVEMENTS_TILE],
    requiredFeatureFlags: [featureFlags.CASES_SCREEN],
  },
  {
    path: '/case/:bereavementId',
    title: t('Funeral'),
    component: CaseScreen,
    hasSubRoutes: true,
  },
  {
    path: '/import-export',
    title: t('Import/Export'),
    component: ImportExportScreen,
    requiredScopes: [permissions.REPORTS_READ],
    requiredFeatureFlags: [featureFlags.IMPORT_EXPORT_SCREEN],
  },
  {
    path: '/tasks',
    title: t('Tasks'),
    component: TasksListScreen,
  },
  {
    path: '/lite-arrangement',
    title: t('Arrangements'),
    component: LiteArrangementSectionScreen,
    hasSubRoutes: true,
    requiredScopes: [permissions.VIEW_LITE_ARRANGEMENTS_TILE],
    requiredFeatureFlags: [featureFlags.LITE_ARRANGEMENTS_SCREEN],
  },
  {
    path: '/xero-authentication',
    title: t('Xero Authentication'),
    component: XeroAuthenticationScreen,
    requiredFeatureFlags: [featureFlags.XERO_INTEGRATION],
  },
];

export default routes;
