import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WebsitesFormSection from './WebsitesFormSection';

class WebsitesFormSectionContainer extends Component {
  static propTypes = {
    fieldData: PropTypes.string,
    errors: PropTypes.string,
    onChange: PropTypes.func,
  }

  constructor(props) {
    super(props);

    this.state = {};
  }

  handleOnChange = (value) => {
    const { onChange } = this.props;
    if (!onChange) {
      return;
    }

    onChange(value);
  };

  render() {
    const { fieldData, errors } = this.props;
    return (
      <WebsitesFormSection
        value={fieldData}
        errors={errors}
        handleOnChangeTelephone={this.handleOnChangeTelephone}
        handleOnChange={this.handleOnChange}
      />
    );
  }
}

export default WebsitesFormSectionContainer;
