import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';

const MainContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100vh;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const LogoWrapper = styled.div`
  width: fit-content;
`;

const Logo = styled.img`
  color: #000;
  font-size: 28px;
  font-style: italic;
  max-height: 120px;
  max-width: 300px;
  padding: 1rem;

  @media (max-width: 767px) {
    max-height: 80px;
    max-width: 200px;
  }
`;

const ItemsContainer = styled.div`
  display: grid;
  grid-template-columns: 4rem 1fr 4rem;
  grid-gap: 1rem;
  padding-bottom: 1rem;

  @media (max-width: 767px) {
    gap: 0.5rem;
    grid-template-columns: 4rem 1fr 0.25rem;
  }

  & .menu__wrapper {
    justify-self: center;
  }
  & .items__wrapper {
    line-height: 2rem;
    color: #515551;
  }
  & .category__heading {
    font-size: 28px;
    margin-bottom: 1rem;
  }
`;

const Cards = styled(InfiniteScroll)`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill,minmax(400px,1fr));
  padding: 0.4rem;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;

  & .inner {
    display: block;
    height: 100px;
    width: 100px;
    margin: auto;

    > svg {
      fill: $color-primary;
    }
  }
`;

export default {
  MainContainer,
  LogoWrapper,
  Logo,
  ItemsContainer,
  Cards,
  Loader,
};
