import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { bereavedPersonConnectionType } from 'types/bereavement';
import { directoryListingType } from 'types/directoryListing';
import { payeeTypes } from 'constants/account';
import PayeeListModal from './PayeeListModal';

class PayeeListModalContainer extends Component {
  static propTypes = {
    bereavedPeopleConnections: PropTypes.arrayOf(bereavedPersonConnectionType).isRequired,
    isOpen: PropTypes.bool.isRequired,
    selectedBereaved: bereavedPersonConnectionType,
    selectedDirectoryListing: directoryListingType,
    payeeType: PropTypes.string,
    savePayeeChange: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    createBereavedPerson: PropTypes.func.isRequired,
    editBereavedPerson: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedDirectoryListing: null,
      selectedBereaved: null,
      payeeType: null,
      isPayeeFormModalOpen: false,
      payee: null,
      isEditingPayee: false,
    };
  }

  componentDidMount() {
    const { selectedBereaved, selectedDirectoryListing, payeeType } = this.props;
    this.setState({ selectedBereaved, selectedDirectoryListing, payeeType });
  }

  handleBereavedChange = (bereavedId) => {
    const { bereavedPeopleConnections } = this.props;
    const selectedBereaved = bereavedPeopleConnections.find(bereaved => bereaved.id === bereavedId);

    this.setState({
      selectedBereaved,
      payeeType: payeeTypes.BEREAVED_PERSON_CONNECTION,
      selectedDirectoryListing: null,
    });
  }

  handleDirectoryListingChange = (directoryListing) => {
    this.setState({
      selectedDirectoryListing: directoryListing,
      payeeType: payeeTypes.DIRECTORY_LISTING,
      selectedBereaved: null,
    });
  }

  togglePayeeFormModal = (payee) => {
    this.setState(prevState => ({
      isPayeeFormModalOpen: !prevState.isPayeeFormModalOpen,
      payee,
      isEditingPayee: !!payee,
    }));
  }

  savePayee = (bereavedPersonConnection) => {
    const { createBereavedPerson, editBereavedPerson } = this.props;
    const { isEditingPayee } = this.state;
    if (isEditingPayee) {
      this.setState({
        payeeType: payeeTypes.BEREAVED_PERSON_CONNECTION,
        selectedBereaved: bereavedPersonConnection,
        selectedDirectoryListing: null,
        payee: null,
      });
      editBereavedPerson(bereavedPersonConnection);
    } else {
      this.setState({
        payeeType: payeeTypes.BEREAVED_PERSON_CONNECTION,
        selectedBereaved: bereavedPersonConnection,
        selectedDirectoryListing: null,
      }, this.handleSave);
      createBereavedPerson(bereavedPersonConnection);
    }
  }

  handleClose = () => {
    const { onClose } = this.props;
    const { selectedBereaved, selectedDirectoryListing, payeeType } = this.props;
    this.setState({ selectedBereaved, selectedDirectoryListing, payeeType });
    onClose();
  }

  handleSave = () => {
    const { savePayeeChange, onClose } = this.props;
    const { selectedBereaved, selectedDirectoryListing, payeeType } = this.state;
    const payee = payeeType === payeeTypes.BEREAVED_PERSON_CONNECTION
      ? selectedBereaved
      : selectedDirectoryListing;
    savePayeeChange(payee, payeeType);
    onClose();
  }

  render() {
    const {
      bereavedPeopleConnections,
      isOpen,
      onClose,
    } = this.props;
    const {
      selectedBereaved,
      selectedDirectoryListing,
      payeeType,
      isPayeeFormModalOpen,
      payee,
      isEditingPayee,
    } = this.state;
    return (
      <PayeeListModal
        bereavedPeopleConnections={bereavedPeopleConnections}
        isOpen={isOpen}
        isPayeeFormModalOpen={isPayeeFormModalOpen}
        isEditingPayee={isEditingPayee}
        selectedBereaved={selectedBereaved}
        selectedDirectoryListing={selectedDirectoryListing}
        payeeType={payeeType}
        payee={payee}
        onBereavedChange={this.handleBereavedChange}
        onDirectoryListingChange={this.handleDirectoryListingChange}
        onCancel={onClose}
        onSave={this.handleSave}
        togglePayeeFormModal={this.togglePayeeFormModal}
        savePayee={this.savePayee}
      />
    );
  }
}

export default withApollo(PayeeListModalContainer);
