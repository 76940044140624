import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import { getUriForMediaByVariant } from 'services/utils';
import { mediaVariants } from 'constants/media';
import { mediaType } from 'types/media';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const VariantImageModal = ({
  variantId = null,
  images,
  selectedImage,
  onSelect,
  onSave,
  onCancel,
}) => (
  <Dialog
    onClose={onCancel}
    aria-labelledby="confirmation-dialog"
    fullWidth
    maxWidth="md"
    open={!!variantId}
    TransitionComponent={Transition}
    data-test-id="selectVariantImageDialog"
    disableBackdropClick
    disableEscapeKeyDown
  >
    <DialogTitle disableTypography className={styles.c_dialog__header}>
      <div
        className={classNames(styles.c_dialog__title)}
        data-test-id="dialogSelectVariantImageTitle"
      >
        {t('Select a photo for your variant')}
      </div>
    </DialogTitle>
    <DialogContent
      className={styles.c_dialog__content}
      data-test-id="dialogSelectVariantImageContent"
    >
      <div className={rcStyles.modal_image_list}>
        {(images || []).map(image => (
          <div
            key={image.id}
            className={rcStyles.is_image}
            style={{
              backgroundImage: `url('${getUriForMediaByVariant(image, mediaVariants.FIT_200_X_200)}')`,
            }}
          >
            <div
              className={classNames(
                rcStyles.is_image_selector,
                image.id === selectedImage ? styles.c_media_selected : null,
              )}
              onClick={() => onSelect(image)}
              data-test-id="variantImage"
              role="button"
              tabIndex="-1"
              key={image.id}
            />
          </div>
        ))}
      </div>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={onCancel}
        variant="outlined"
        color="primary"
        data-test-id="cancelSelectVariantImage"
      >
        {t('Cancel')}
      </Button>
      <Button
        onClick={() => onSave(variantId)}
        variant="contained"
        color="primary"
        data-test-id="saveSelectVariantImage"
      >
        {t('Confirm')}
      </Button>
    </DialogActions>
  </Dialog>
);

VariantImageModal.propTypes = {
  variantId: PropTypes.string,
  images: PropTypes.arrayOf(mediaType).isRequired,
  selectedImage: mediaType,
  onSelect: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default VariantImageModal;
