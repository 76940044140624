import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { editTaskAction } from 'actions/task';
import { taskType } from 'types/task';
import { status as taskStatus, taskValidationSchema } from 'constants/task';
import { updateValueForFieldByKey, getServiceData } from 'services/utils/task';
import TaskModal from './TaskModal';

class TaskModalContainer extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    task: taskType,
    editTask: PropTypes.func.isRequired,
    isAllowedToDismissTasks: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      task: null,
      isValidationEnabled: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.task) {
      return {
        task: props.task,
      };
    }
    return null;
  }

  onSave = () => {
    const { editTask, validate } = this.props;
    const { task } = this.state;

    const isValid = validate(task, taskValidationSchema);

    if (isValid || task.status !== taskStatus.COMPLETED) {
      editTask(task);
      this.handleClose();
    } else {
      this.setState({
        isValidationEnabled: true,
        task: {
          ...task,
          status: taskStatus.PENDING,
        },
      });
    }
  };

  handleOnUpdateProgressField = (key, value) => {
    const { validate } = this.props;
    const { task } = this.state;

    const updatedTask = {
      ...task,
      progressJson: updateValueForFieldByKey(task.progressJson, key, value),
    };

    validate(updatedTask, taskValidationSchema);

    this.setState({
      task: updatedTask,
    });
  };

  handleOnStatusChange = (status) => {
    const { task } = this.state;

    const updatedTask = {
      ...task,
      status,
    };

    this.setState({
      task: updatedTask,
    });
  };

  handleOnUpdateAssignee = (assignee) => {
    const { task } = this.state;

    const updatedTask = {
      ...task,
      assignee,
    };

    this.setState({
      task: updatedTask,
    });
  };

  handleOnUpdateNote = (note) => {
    const { task } = this.state;

    const updatedTask = {
      ...task,
      note,
    };

    this.setState({
      task: updatedTask,
    });
  };

  handleClose = () => {
    const { onClose } = this.props;
    this.setState({
      task: null,
      isValidationEnabled: false,
    });
    onClose();
  }

  render() {
    const { isAllowedToDismissTasks, isOpen, errors } = this.props;
    const { task, isValidationEnabled } = this.state;

    return (
      <TaskModal
        onClose={this.handleClose}
        onSave={this.onSave}
        task={task || {}}
        serviceData={getServiceData(task)}
        onUpdateProgressField={this.handleOnUpdateProgressField}
        onStatusChange={this.handleOnStatusChange}
        onUpdateNote={this.handleOnUpdateNote}
        onUpdateAssignee={this.handleOnUpdateAssignee}
        isAllowedToDismissTasks={isAllowedToDismissTasks}
        disabled={(task || {}).status === taskStatus.COMPLETED
          || (task || {}).status === taskStatus.DISMISSED}
        isOpen={isOpen}
        errors={isValidationEnabled ? errors : {}}
      />
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  editTask: editTaskAction,
}, dispatch);

export default connect(null, mapDispatchToProps)(
  withValidation(TaskModalContainer),
);
