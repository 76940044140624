import React, { useRef, useState } from 'react';
import i18next from 'i18next';
import { withApollo } from 'react-apollo';
import TextField from '@material-ui/core/TextField';
import IconFileCopy from '@material-ui/icons/FileCopy';
import CreateIcon from '@material-ui/icons/Create';
import ReactHtmlParser from 'react-html-parser';
import { sanitisePreviewValue } from 'transforms/templateValues';

import styles from 'scss/main.scss';
import { debounceMutation } from 'services/utils';

import rcStyles from './styles.scss';
import { setDefaultForCorrespondenceVariable } from './mutations.gql';
import { Props, PropsWithoutApolloClient } from './index.types';

const TemplateValueDetails: React.FC<Props> = ({ client, templateValue }: Props) => {
  const [defaultValue, setDefaultValue] = useState(templateValue.defaultValue ?? '');
  const [allowDefaultValueEdit, setAllowDefaultValueEdit] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleEditDefaultValue = (value: string) => {
    setDefaultValue(value);

    const input = { variableName: templateValue.name, value };
    debounceMutation(client, setDefaultForCorrespondenceVariable, input);
  };

  const handleCopyValueToClipboard = () => {
    if (inputRef?.current != null) {
      inputRef.current.select();
    }

    document.execCommand('copy');
  };

  return (
    <div className={rcStyles.expanded_content}>
      <p className={rcStyles.is_title}>
        {templateValue.description}
      </p>
      <div className={rcStyles.example_box}>
        <div className={styles.brevier}>
          {i18next.t('Value')}
        </div>
        <div className={rcStyles.is_value}>
          <input
            ref={inputRef}
            className={rcStyles.styled_input}
            readOnly
            value={`{{${templateValue.name}}}`}
            data-test-id="letterValue"
          />
        </div>
      </div>
      <div className={rcStyles.copy_value}>
        <IconFileCopy
          onClick={handleCopyValueToClipboard}
          data-test-id="copyLetterValue"
        />
      </div>
      <div className={rcStyles.example_box}>
        <div className={styles.brevier}>
          {i18next.t('How the value is used')}
        </div>
        <div className={rcStyles.is_value}>
          {`{{${templateValue.name}}}`}
        </div>
      </div>
      <div className={rcStyles.example_box}>
        <div className={styles.brevier}>
          {i18next.t('How the value is shown with no funeral data')}
        </div>
        <div className={rcStyles.is_placeholder_value}>
          {allowDefaultValueEdit && templateValue?.type === 'STRING' ? (
            <TextField
              className={rcStyles.rc_estimate_label}
              margin="none"
              value={defaultValue}
              onChange={event => handleEditDefaultValue(event.currentTarget.value)}
              inputProps={{
                'data-test-id': 'defaultValue',
              }}
              fullWidth
            />
          ) : (
            <span>{defaultValue}</span>
          )}
        </div>
      </div>
      <div className={rcStyles.copy_value}>
        {templateValue?.type === 'STRING' && (
          <CreateIcon
            onClick={() => setAllowDefaultValueEdit(!allowDefaultValueEdit)}
            data-test-id="editDefaultValue"
          />
          )}
      </div>
      <div className={rcStyles.example_box}>
        <div className={styles.brevier}>
          {i18next.t('How the value is shown')}
        </div>
        <div className={rcStyles.is_shown_value}>
          { ReactHtmlParser(sanitisePreviewValue(templateValue.previewValue)) }
        </div>
      </div>
    </div>
  );
};

export default withApollo<PropsWithoutApolloClient>((TemplateValueDetails));
