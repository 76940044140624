import React from 'react';
import i18next from 'i18next';
import classNames from 'classnames';

import AdminCard from 'components/common/cards/AdminCard';
import ScreenLayout from 'components/common/ScreenLayout';
import AnnouncementsSection from 'components/dashboard/AnnouncementsSection';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';
import { Props } from './AdminDashboardScreen.types';

const AdminDashboardScreen: React.FC<Props> = ({ cards }: Props) => (
  <ScreenLayout
    title={i18next.t('Arranger')}
    dashboardBannerProps={{
      breadcrumbs: [{ path: '/', title: i18next.t('Dashboard') }],
      title: i18next.t('Welcome to Arranger') as string,
      description: i18next.t('Start arranging funerals, managing your products, directory listings, and services') as string,
    }}
  >
    <div className={styles.o_view}>

      <div className={styles.o_view__header}>
        <div className={rcStyles.announcements}>
          <div className={rcStyles.announcements__inner}>
            <AnnouncementsSection />
          </div>
        </div>
      </div>

      <div className={classNames(
        styles.o_view__main,
        styles.has_auto_columns,
      )}
      >
        {cards
          .filter(card => !card.disabled)
          .map(card => (
            <AdminCard
              key={card.title}
              link={card.link}
              title={card.title}
              icon={card.icon}
              text={card.text}
              testId={card.testId}
              isBeta={card.isBeta}
            />
          ))}
      </div>
    </div>
  </ScreenLayout>
);

export default AdminDashboardScreen;
