import React, { Fragment } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import { transferTypes } from 'constants/bereavement';
import { textForTransferType } from 'services/utils/bereavement';
import { firstOfficesType, mortuaryType, transferType } from 'types/bereavement';
import ExpandableBanner from 'components/common/ExpandableBanner';
import TransferRow from 'components/bereavement/TransferRow';
import TransferModal from 'components/bereavement/TransferModal';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const CareRecordBodyForm = ({
  activeTab,
  activeCareRecordTab,
  viewingNotAdvised,
  reasonForViewingNotAdvised,
  firstOffices,
  firstOfficesTotalSelectedFields,
  mortuary,
  mortuaryTotalSelectedFields,
  transfers,
  disabled,
  onViewingNotAdvisedChange,
  onFirstOfficesChange,
  onFirstOfficesSectionChange,
  onMortuaryChange,
  onSave,
  anchorElement,
  toggleAddTransferMenu,
  activeTransfer,
  toggleTransferModal,
  onSaveTransfer,
  onRemoveTransfer,
  formRefs,
}) => {
  const { eyeCare, mouthCare, disinfection } = firstOffices;
  return (
    <div className={rcStyles.v_spacing}>
      <div className={rcStyles.viewing_not_advised}>
        <div ref={formRefs.viewingNotAdvised}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={!!viewingNotAdvised}
                onChange={event => onViewingNotAdvisedChange('viewingNotAdvised', event.target.checked)}
                inputProps={{ 'data-test-id': 'viewingNotAdvised' }}
                disabled={disabled}
                color="primary"
              />
            )}
            label={t('Viewing not advised')}
          />
        </div>
        <div
          ref={formRefs.reasonForViewingNotAdvised}
          className={rcStyles.is_reason}
        >
          <TextField
            placeholder={t('Reason')}
            value={reasonForViewingNotAdvised || ''}
            onChange={event => onViewingNotAdvisedChange('reasonForViewingNotAdvised', event.target.value)}
            fullWidth
            inputProps={{
              'data-test-id': 'reasonForViewingNotAdvised',
            }}
            disabled={disabled}
          />
        </div>
      </div>
      <div>
        <div
          data-test-id="careRecordFirstOffices"
          ref={formRefs.firstOffices}
        >
          <ExpandableBanner
            headerClasses={[rcStyles.expanding_banner__header]}
            shouldCollapseFromExternalControl={activeTab.indexOf('/care-record') === -1 || activeCareRecordTab !== 0}
            title={(
              <div className={rcStyles.banner_content}>
                <div>{t('First offices')}</div>
                <div className={rcStyles.is_secondary}>
                  {firstOfficesTotalSelectedFields}
                  &nbsp;
                  {t('selected')}
                </div>
              </div>
            )}
            isClosedOnLoad
          >
            <div className={rcStyles.v_spacing}>
              <div className={rcStyles.fieldset}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={!!eyeCare.hasEyeCareBeenCompleted}
                      onChange={event => onFirstOfficesSectionChange('eyeCare', 'hasEyeCareBeenCompleted', event.target.checked)}
                      inputProps={{ 'data-test-id': 'hasEyeCareBeenCompleted' }}
                      disabled={disabled}
                      color="primary"
                    />
                  )}
                  label={t('Eye Care')}
                />
                <TextField
                  label={t('Other')}
                  value={eyeCare.other || ''}
                  onChange={event => onFirstOfficesSectionChange('eyeCare', 'other', event.target.value)}
                  inputProps={{
                    'data-test-id': 'eyeCareOther',
                  }}
                  disabled={disabled}
                />
              </div>
              <div className={rcStyles.fieldset}>
                <h3 className={styles.long_primer}>
                  {t('Mouth care')}
                </h3>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={!!mouthCare.haveDenturesBeenInserted}
                      onChange={event => onFirstOfficesSectionChange('mouthCare', 'haveDenturesBeenInserted', event.target.checked)}
                      inputProps={{ 'data-test-id': 'haveDenturesBeenInserted' }}
                      disabled={disabled}
                      color="primary"
                    />
                  )}
                  label={t('Dentures inserted')}
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={!!mouthCare.haveSuturesBeenClosed}
                      onChange={event => onFirstOfficesSectionChange('mouthCare', 'haveSuturesBeenClosed', event.target.checked)}
                      inputProps={{ 'data-test-id': 'haveSuturesBeenClosed' }}
                      disabled={disabled}
                      color="primary"
                    />
                  )}
                  label={t('Sutures closed')}
                />
                <TextField
                  label={t('Other')}
                  value={mouthCare.other || ''}
                  onChange={event => onFirstOfficesSectionChange('mouthCare', 'other', event.target.value)}
                  inputProps={{
                    'data-test-id': 'mouthCareOther',
                  }}
                  disabled={disabled}
                />
              </div>
              <div className={rcStyles.fieldset}>
                <h3 className={styles.long_primer}>
                  {t('Disinfection')}
                </h3>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={!!disinfection.eyesHaveBeenDisinfected}
                      onChange={event => onFirstOfficesSectionChange('disinfection', 'eyesHaveBeenDisinfected', event.target.checked)}
                      inputProps={{ 'data-test-id': 'eyesHaveBeenDisinfected' }}
                      disabled={disabled}
                      color="primary"
                    />
                  )}
                  label={t('Eyes')}
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={!!disinfection.noseHasBeenDisinfected}
                      onChange={event => onFirstOfficesSectionChange('disinfection', 'noseHasBeenDisinfected', event.target.checked)}
                      inputProps={{ 'data-test-id': 'noseHasBeenDisinfected' }}
                      disabled={disabled}
                      color="primary"
                    />
                  )}
                  label={t('Nose')}
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={!!disinfection.mouthHasBeenDisinfected}
                      onChange={event => onFirstOfficesSectionChange('disinfection', 'mouthHasBeenDisinfected', event.target.checked)}
                      inputProps={{ 'data-test-id': 'mouthHasBeenDisinfected' }}
                      disabled={disabled}
                      color="primary"
                    />
                  )}
                  label={t('Mouth')}
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={!!disinfection.remainsHaveBeenDisinfected}
                      onChange={event => onFirstOfficesSectionChange('disinfection', 'remainsHaveBeenDisinfected', event.target.checked)}
                      inputProps={{ 'data-test-id': 'remainsHaveBeenDisinfected' }}
                      disabled={disabled}
                      color="primary"
                    />
                  )}
                  label={t('Remains')}
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={!!disinfection.orificesHaveBeenPacked}
                      onChange={event => onFirstOfficesSectionChange('disinfection', 'orificesHaveBeenPacked', event.target.checked)}
                      inputProps={{ 'data-test-id': 'orificesHaveBeenPacked' }}
                      disabled={disabled}
                      color="primary"
                    />
                  )}
                  label={t('Orifices packed')}
                />
                <TextField
                  label={t('Notes')}
                  value={firstOffices.notes || ''}
                  onChange={event => onFirstOfficesChange('notes', event.target.value)}
                  inputProps={{
                    'data-test-id': 'firstOfficesNotes',
                  }}
                  disabled={disabled}
                  multiline
                />
              </div>
            </div>
          </ExpandableBanner>
        </div>
        <div
          data-test-id="careRecordMortuary"
          ref={formRefs.mortuary}
        >
          <ExpandableBanner
            headerClasses={[rcStyles.expanding_banner__header]}
            shouldCollapseFromExternalControl={activeTab.indexOf('/care-record') === -1 || activeCareRecordTab !== 0}
            title={(
              <div className={rcStyles.banner_content}>
                <div>{t('Mortuary')}</div>
                <div className={rcStyles.is_secondary}>
                  {mortuaryTotalSelectedFields}
                  &nbsp;
                  {t('selected')}
                </div>
              </div>
            )}
            isClosedOnLoad
          >
            <div className={rcStyles.fieldset}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={!!mortuary.bodyHasBeenEmbalmed}
                    onChange={event => onMortuaryChange('bodyHasBeenEmbalmed', event.target.checked)}
                    inputProps={{ 'data-test-id': 'bodyHasBeenEmbalmed' }}
                    disabled={disabled}
                    color="primary"
                  />
                )}
                label={t('Embalmed')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={!!mortuary.hasCosmetologyBeenPerformed}
                    onChange={event => onMortuaryChange('hasCosmetologyBeenPerformed', event.target.checked)}
                    inputProps={{ 'data-test-id': 'hasCosmetologyBeenPerformed' }}
                    disabled={disabled}
                    color="primary"
                  />
                )}
                label={t('Cosmetology')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={!!mortuary.bodyHasBeenDressed}
                    onChange={event => onMortuaryChange('bodyHasBeenDressed', event.target.checked)}
                    inputProps={{ 'data-test-id': 'bodyHasBeenDressed' }}
                    disabled={disabled}
                    color="primary"
                  />
                )}
                label={t('Dressed')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={!!mortuary.bodyHasBeenEncoffined}
                    onChange={event => onMortuaryChange('bodyHasBeenEncoffined', event.target.checked)}
                    inputProps={{ 'data-test-id': 'bodyHasBeenEncoffined' }}
                    disabled={disabled}
                    color="primary"
                  />
                )}
                label={t('Encoffined')}
              />
              <TextField
                label={t('Notes')}
                value={mortuary.notes || ''}
                onChange={event => onMortuaryChange('notes', event.target.value)}
                fullWidth
                inputProps={{
                  'data-test-id': 'mortuaryNotes',
                }}
                disabled={disabled}
                multiline
              />
            </div>
          </ExpandableBanner>
        </div>
        <div data-test-id="careRecordTransfers">
          <ExpandableBanner
            headerClasses={[rcStyles.expanding_banner__header]}
            shouldCollapseFromExternalControl={activeTab.indexOf('/care-record') === -1 || activeCareRecordTab !== 0}
            title={(
              <div className={rcStyles.banner_content}>
                <div>{t('Transfers')}</div>
                <div className={rcStyles.is_secondary}>
                  {(transfers && transfers.length) || 0}
                  &nbsp;
                  {t('transfers')}
                </div>
              </div>
            )}
            isClosedOnLoad
          >
            <Fragment>
              {transfers && transfers.map(transfer => (
                <TransferRow
                  key={transfer.id}
                  transfer={transfer}
                  onClick={() => toggleTransferModal(transfer)}
                  onRemove={() => onRemoveTransfer(transfer.id)}
                  data-test-id="transferRow"
                />
              ))}
              <div className={classNames(
                (transfers && transfers.length > 0) && styles.u_soft__top,
                styles.has_no_padding,
                styles.u_hard__bottom,
              )}
              >
                <Fragment>
                  <Button
                    onClick={(event => toggleAddTransferMenu(event.target))}
                    variant="outlined"
                    color="primary"
                    disabled={disabled}
                    aria-owns={anchorElement ? 'add-transfer-menu' : undefined}
                    aria-haspopup="true"
                    data-test-id="addTransfer"
                  >
                    <AddIcon />
                    {t('Add Transfer')}
                  </Button>
                  <Menu
                    id="add-transfer-menu"
                    anchorEl={anchorElement}
                    open={!!anchorElement}
                    onClose={() => toggleAddTransferMenu()}
                  >
                    {Object.keys(transferTypes).map((key) => {
                      const value = transferTypes[key];
                      return (
                        <MenuItem
                          key={key}
                          onClick={(() => {
                            toggleTransferModal(value);
                            toggleAddTransferMenu();
                          })}
                          data-test-id={`${key}MenuItem`}
                        >
                          {t(textForTransferType(value))}
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </Fragment>
              </div>
            </Fragment>
          </ExpandableBanner>
        </div>
      </div>
      <div className={styles.o_button_bar}>
        <Button
          variant="contained"
          color="primary"
          onClick={onSave}
          data-test-id="saveCareRecordBodyForm"
          disabled={disabled}
        >
          {t('Save')}
        </Button>
      </div>
      <TransferModal
        transfer={activeTransfer}
        isOpen={!!activeTransfer}
        onClose={() => toggleTransferModal()}
        onSave={onSaveTransfer}
      />
    </div>
  );
};

CareRecordBodyForm.propTypes = {
  activeTab: PropTypes.string.isRequired,
  activeCareRecordTab: PropTypes.number.isRequired,
  viewingNotAdvised: PropTypes.bool.isRequired,
  reasonForViewingNotAdvised: PropTypes.string,
  firstOffices: firstOfficesType.isRequired,
  firstOfficesTotalSelectedFields: PropTypes.number.isRequired,
  mortuary: mortuaryType.isRequired,
  mortuaryTotalSelectedFields: PropTypes.number.isRequired,
  transfers: PropTypes.arrayOf(transferType),
  disabled: PropTypes.bool,
  onViewingNotAdvisedChange: PropTypes.func.isRequired,
  onFirstOfficesChange: PropTypes.func.isRequired,
  onFirstOfficesSectionChange: PropTypes.func.isRequired,
  onMortuaryChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  anchorElement: PropTypes.objectOf(PropTypes.any),
  toggleAddTransferMenu: PropTypes.func.isRequired,
  activeTransfer: transferType,
  toggleTransferModal: PropTypes.func.isRequired,
  onSaveTransfer: PropTypes.func.isRequired,
  onRemoveTransfer: PropTypes.func.isRequired,
  formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default CareRecordBodyForm;
