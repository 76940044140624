export const consolidateReport = (report) => {
  const reportItems = [];
  const unknownVariables = [];

  report.forEach((item) => {
    if (item.type === 'UNKNOWN_VARIABLE') {
      unknownVariables.push(item.templatePlaceholderValue);
    }

    if (item.type === 'INVALID_TEMPLATE') {
      reportItems.push('Your template could not be loaded. Please contact support for assistance');
    }

    if (item.type === 'MALFORMED_PLACEHOLDER') {
      reportItems.push('There are malformed value placeholders in your template. Please ensure all values you have used are in the'
        + ' following format: {{ VALUE_NAME }}.');
    }
  });

  if (unknownVariables.length > 0) {
    const description = unknownVariables.length === 1 ? 'is not a valid value' : 'are not valid values';
    reportItems.push(`${unknownVariables.join(' ')} ${description}.`);
  }

  return reportItems;
};
