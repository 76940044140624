import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Select from 'components/common/Select';
import DateTimePicker from 'components/common/DateTimePicker';
import { musicMoments } from 'constants/music';
import rcStyles from './styles.scss';

const MusicSelectionForm = ({ fieldData, disabled, onChange }) => (
  <FormGroup>
    <div className={rcStyles.music}>
      <div className={rcStyles.music__moment}>
        <Select
          value={fieldData.moment}
          onChange={event => onChange('moment', event.target.value)}
          fullWidth
          label={t('Moment for music')}
          inputProps={{ 'data-test-id': 'moment' }}
          disabled={disabled}
        >
          {musicMoments.map((moment, index) => (
            <MenuItem key={index} value={moment.value}>{t(moment.name)}</MenuItem>
          ))}
        </Select>
      </div>
      <div className={rcStyles.music__time}>
        <DateTimePicker
          label={t('Time')}
          value={fieldData.time || null}
          placeholder={t('Time')}
          onChange={date => onChange('time', date)}
          isClearable
          disabled={disabled}
          data-test-id="time"
          fullWidth
          inputProps={{
            classes: { input: rcStyles.time_input_margin },
          }}
        />
      </div>
      <div className={rcStyles.music__selection}>
        <TextField
          label={t('Music selection')}
          value={fieldData.title || ''}
          onChange={event => onChange('title', event.target.value)}
          fullWidth
          inputProps={{
            'data-test-id': 'title',
          }}
          disabled={disabled}
        />
      </div>
    </div>
  </FormGroup>
);

MusicSelectionForm.propTypes = {
  fieldData: PropTypes.objectOf(PropTypes.any).isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default MusicSelectionForm;
