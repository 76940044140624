import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { bodySizeType } from 'types/bereavement';
import MenuItem from '@material-ui/core/MenuItem';
import Select from 'components/common/Select';
import MeasurementField from 'components/common/MeasurementField';
import { MeasurementDisplayUnit } from 'constants/measurement';

import classNames from 'classnames';
import styles from 'scss/main.scss';

const BodySizeFormSectionView = ({
  bodySize, unit, disabled, onChange, errors,
}) => (
  <div
    data-test-id="bodySize"
    className={classNames(
      styles.o_fb,
      styles.o_fb_one,
    )}
  >
    <div className={classNames(
      styles.o_fb__item,
      styles.o_fb__item__quarter,
    )}
    >
      <MeasurementField
        label={t('Length')}
        value={bodySize && bodySize.length !== null ? bodySize.length : ''}
        units={unit === 'INCHES' ? MeasurementDisplayUnit.FEET : MeasurementDisplayUnit.CENTIMETERS}
        disabled={disabled}
        error={errors && !!errors.length}
        helperText={errors && errors.length}
        onChange={measurement => onChange('length', measurement)}
        data-test-id="length"
      />
    </div>
    <div className={classNames(
      styles.o_fb__item,
      styles.o_fb__item__quarter,
    )}
    >
      <MeasurementField
        label={t('Width')}
        value={bodySize && bodySize.width !== null ? bodySize.width : ''}
        units={unit === 'INCHES' ? MeasurementDisplayUnit.INCHES : MeasurementDisplayUnit.CENTIMETERS}
        disabled={disabled}
        error={errors && !!errors.width}
        helperText={errors && errors.width}
        onChange={measurement => onChange('width', measurement)}
        data-test-id="width"
      />
    </div>
    <div className={classNames(
      styles.o_fb__item,
      styles.o_fb__item__quarter,
    )}
    >
      <MeasurementField
        label={t('Depth')}
        value={bodySize && bodySize.depth !== null ? bodySize.depth : ''}
        units={unit === 'INCHES' ? MeasurementDisplayUnit.INCHES : MeasurementDisplayUnit.CENTIMETERS}
        disabled={disabled}
        error={errors && !!errors.depth}
        helperText={errors && errors.depth}
        onChange={measurement => onChange('depth', measurement)}
        data-test-id="depth"
      />
    </div>
    <div className={classNames(
      styles.o_fb__item,
      styles.o_fb__item__quarter,
    )}
    >
      <Select
        label={t('Units')}
        value={bodySize ? bodySize.units : null}
        onChange={event => onChange('units', event.target.value)}
        inputProps={{
          'data-test-id': 'units',
        }}
        fullWidth
        disabled={disabled}
        error={errors && !!errors.units}
        errorText={errors && errors.units}
      >
        <MenuItem data-test-id="none">None</MenuItem>
        <MenuItem value="INCHES" data-test-id="inches">Inches</MenuItem>
        <MenuItem value="CENTIMETERS" data-test-id="centimeters">Centimeters</MenuItem>
      </Select>
    </div>
  </div>
);
BodySizeFormSectionView.propTypes = {
  bodySize: bodySizeType,
  unit: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any),
};

export default BodySizeFormSectionView;
