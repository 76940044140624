import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import {
  MenuItem,
  InputLabel,
  FormHelperText,
} from '@material-ui/core';
import Select from 'components/common/Select';
import OrganisationalUnitLookup from 'components/common/lookups/OrganisationalUnitLookup';
import AddressFormSection from 'components/common/AddressFormSection';
import { locationTypes, locationTypeLabels } from 'constants/bereavement';
import { organisationalUnitTypes } from 'constants/organisationalUnits';
import { organisationalUnitType } from 'types/organisationalUnit';
import { addressType } from 'types/common';

import rcStyles from './styles.scss';

const LocationFormSection = ({
  locationType,
  value,
  valueKey,
  selectedHome,
  errors,
  lookupLabel,
  onChangeLocationType,
  onChange,
  onSelectHome,
}) => (
  <div className={rcStyles.form_group}>
    <Select
      label={t('Location type')}
      value={locationType}
      onChange={event => onChangeLocationType(event.target.value)}
      inputProps={{
        'data-test-id': 'location',
      }}
      fullWidth
      shrink
    >
      {Object.keys(locationTypes).map(key => (
        <MenuItem
          key={key}
          value={key}
        >
          {t(locationTypeLabels[key])}
        </MenuItem>
      ))}
    </Select>
    {locationType === locationTypes.FUNERAL_HOME && (
      <div>
        <InputLabel
          classes={{ root: rcStyles.label }}
        >
          {t('Select home *')}
        </InputLabel>
        <OrganisationalUnitLookup
          value={selectedHome}
          onChange={onSelectHome}
          type={organisationalUnitTypes.HOME}
          errorText={errors}
          data-test-id="homeLookup"
        />
        {errors && (
          <FormHelperText className={rcStyles.rc_error} error>{errors}</FormHelperText>
        )}
      </div>
    )}
    {locationType === locationTypes.OTHER && (
      <AddressFormSection
        lookupLabel={lookupLabel || 'Other location *'}
        address={value}
        margin={false}
        onChange={address => onChange(valueKey, address)}
        data-test-id="otherLocationLookup"
        errors={errors}
      />
    )}
  </div>
);

LocationFormSection.propTypes = {
  locationType: PropTypes.string.isRequired,
  value: addressType,
  valueKey: PropTypes.string,
  selectedHome: organisationalUnitType,
  errors: PropTypes.string,
  lookupLabel: PropTypes.string,
  onChangeLocationType: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSelectHome: PropTypes.func.isRequired,
};

LocationFormSection.defaultProps = {
  valueKey: 'address',
};

export default LocationFormSection;
