import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withValidation } from '@funeralguide/react-form-validation-hoc';

import { getEmptyCustomTemplate } from 'services/utils/templates';
import { consolidateReport } from 'transforms/templateReport';
import { apolloClientType } from 'types/apollo';
import { nameType } from 'types/common';

import { getPreviewLetter, validateCorrespondenceMedia } from './queries.gql';
import { setTemplateForCorrespondence } from './mutations.gql';
import TemplateGenerationModal from './TemplateGenerationModal';
import { validationSchema } from './validation';

export class TemplateGenerationModalContainer extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    client: apolloClientType.isRequired,
    tenantId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    formRefs: PropTypes.objectOf(PropTypes.any).isRequired,
    generateRefs: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    userName: nameType,
    onUpdateTemplateDefinition: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const template = getEmptyCustomTemplate();

    this.state = {
      activeStep: 0,
      isPreviewDownloading: false,
      isTemplateDirty: false,
      isTemplateValid: false,
      isUploading: false,
      hasBeenUploaded: false,
      hasBeenSetOnTenant: false,
      template,
      report: [],
      templateHasErrors: false,
    };
  }

  componentDidMount() {
    const { generateRefs } = this.props;
    generateRefs(Object.keys(validationSchema.fields));
  }

  handleChangeActiveStep = (activeStep) => {
    this.setState({ activeStep });
  };

  handleChangeTemplateDetails = (key, value) => {
    const { validate } = this.props;
    const { template, isTemplateDirty } = this.state;

    const updatedTemplate = {
      ...template,
      [key]: value,
    };
    const isDirty = !isTemplateDirty ? key === 'title' : isTemplateDirty;
    const isValid = isDirty && validate(updatedTemplate, validationSchema);

    this.setState({
      template: updatedTemplate,
      isTemplateValid: isValid,
      isTemplateDirty: isDirty,
    });
  };

  handleUploadStart = () => {
    this.setState({ isUploading: true });
  };

  handleUploadComplete = async ([media]) => {
    const { client } = this.props;

    const response = await client.query({
      query: validateCorrespondenceMedia,
      variables: {
        mediaId: media.id,
      },
    });

    const report = response?.data?.validateCorrespondenceMedia;

    if (!report) {
      return;
    }

    this.setState({
      isUploading: false,
      hasBeenUploaded: true,
      templateHasErrors: report.length > 0,
      report: consolidateReport(report),
    });

    if (report.length === 0) {
      this.handleSetTemplateForCorrespondence(media);
    }
  }

  handleSetTemplateForCorrespondence = (media) => {
    const {
      client,
      tenantId,
      userId,
      userName,
      onUpdateTemplateDefinition,
    } = this.props;
    const { template } = this.state;

    const updatedTemplate = {
      ...template,
      id: template.id,
      mediaId: media.id,
      media,
      createdById: userId,
      createdBy: {
        name: { ...userName },
      },
      createdDateTime: moment().toISOString(),
    };

    const input = {
      id: tenantId,
      correspondenceId: template.id,
      category: template.category,
      type: template.type,
      title: template.title,
      mediaId: media.id,
    };

    client.mutate({
      mutation: setTemplateForCorrespondence,
      variables: { input },
    }).then(() => {
      this.setState({
        isUploading: false,
        hasBeenUploaded: true,
        hasBeenSetOnTenant: true,
      });
    });

    onUpdateTemplateDefinition(updatedTemplate);
  }

  handlePreview = async () => {
    const { client, tenantId } = this.props;
    const { template } = this.state;

    await client.query({
      query: getPreviewLetter,
      variables: {
        tenantId,
        id: template.id,
      },
    }).then(({ data }) => {
      const { correspondencePreview = {} } = data.tenant || {};
      const { uri } = correspondencePreview;
      if (uri) {
        window.open(uri, uri).focus();
      }
    });
  }

  render() {
    const {
      errors,
      formRefs,
      isOpen,
      onClose,
    } = this.props;
    const {
      activeStep,
      template,
      isPreviewDownloading,
      isTemplateValid,
      isUploading,
      hasBeenUploaded,
      report,
      hasBeenSetOnTenant,
      templateHasErrors,
    } = this.state;

    let disabledSteps = [];
    if (!isTemplateValid) {
      disabledSteps = [1];
    } else if (hasBeenUploaded) {
      disabledSteps = [0];
    }

    return (
      <TemplateGenerationModal
        activeStep={activeStep}
        errors={errors}
        formRefs={formRefs}
        isOpen={isOpen}
        template={template}
        disabledSteps={disabledSteps}
        isPreviewDownloading={isPreviewDownloading}
        isTemplateValid={isTemplateValid}
        isUploading={isUploading}
        hasBeenUploaded={hasBeenUploaded}
        onChangeActiveStep={this.handleChangeActiveStep}
        onChangeTemplateDetails={this.handleChangeTemplateDetails}
        onClose={onClose}
        onPreview={this.handlePreview}
        onUploadStart={this.handleUploadStart}
        onUploadComplete={this.handleUploadComplete}
        report={report}
        hasBeenSetOnTenant={hasBeenSetOnTenant}
        templateHasErrors={templateHasErrors}
      />
    );
  }
}

const mapStateToProps = state => ({
  tenantId: state.userStore.user.tenantId,
  userId: state.userStore.user.staffMember.id,
  userName: state.userStore.user.name,
});

export default withValidation(
  withApollo(
    connect(mapStateToProps)(TemplateGenerationModalContainer),
  ),
);
