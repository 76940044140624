import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import PersonIcon from '@material-ui/icons/Person';
import CalendarContainer from 'components/calendar';
import { scopes } from 'constants/scopes';
import { featureFlags } from 'constants/features';
import classNames from 'classnames';
import rcStyles from './styles.scss';

const appBarClasses = {
  colorPrimary: rcStyles.rc_navContainer,
};

const buttonClasses = {
  root: rcStyles.rc_nav__button,
};


const AppBar = ({
  anchorElement,
  isMenuOpen,
  isCalendarOpen,
  availableFeatures,
  handleMenuOpen,
  handleMenuClose,
  onToggleCalendar,
  hasPermissions,
  currentBereavementId,
}) => {
  const profileMenu = (
    <Menu
      anchorEl={anchorElement}
      anchorOrigin={{ vertical: 40, horizontal: 'left' }}
      getContentAnchorEl={null}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Link to="/logout">
        <MenuItem>
          {t('Logout')}
        </MenuItem>
      </Link>
    </Menu>
  );

  const tasksRoute = () => {
    const bereavementId = currentBereavementId ? `/?bereavementId=${currentBereavementId}` : '';
    return `/tasks${bereavementId}`;
  };

  return (
    <Fragment>
      <MuiAppBar position="static" classes={appBarClasses} elevation={0}>
        <Toolbar variant="dense" disableGutters>
          <div className={classNames(
            rcStyles.rc_header_tenant,
          )}
          >
            <div className={rcStyles.rc_header_Logo}>
              {process.env.FEATURE_FLAG_SHOW_APPBAR_LOGO && (
                <Link
                  className={rcStyles.rc_logo}
                  to="/"
                  data-test-id="appbarLogo"
                >
                  <img src="/img/logo.svg" alt="Logo" />
                </Link>
              )}
            </div>
            <div className={rcStyles.rc_header_Nav}>
              <Button
                classes={buttonClasses}
                component={Link}
                data-test-id="openAdmin"
                to="/"
              >
                {t('Dashboard')}
              </Button>
              {hasPermissions([scopes.BEREAVEMENT_WRITE])
                && availableFeatures.includes(featureFlags.FIRST_CALL_SCREEN) && (
                  <Button classes={buttonClasses} component={Link} data-test-id="openFirstCall" to="/first-call">
                    {t('First Call')}
                  </Button>
              )}
              {hasPermissions([scopes.BEREAVEMENT_READ])
                && availableFeatures.includes(featureFlags.CASES_SCREEN) && (
                <Button classes={buttonClasses} component={Link} data-test-id="openCase" to="/case">
                  {t('Funerals')}
                </Button>
              )}
              {availableFeatures.includes(featureFlags.TASKS_SCREEN) && (
                <Button classes={buttonClasses} component={Link} data-test-id="openTasks" to={tasksRoute()}>
                  {t('Tasks')}
                </Button>
              )}
              {hasPermissions([scopes.BEREAVEMENT_READ])
                && availableFeatures.includes(featureFlags.CALENDAR) && (
                  <CalendarContainer
                    isCalendarOpen={isCalendarOpen}
                    onToggleCalendar={onToggleCalendar}
                  />
              )}
              <Button className={classNames(rcStyles.rc_nav__button, rcStyles.rc_nav_avatar)} onClick={handleMenuOpen} data-test-id="openUserMenu">
                <PersonIcon />
              </Button>
            </div>
          </div>
        </Toolbar>
      </MuiAppBar>
      {profileMenu}
    </Fragment>
  );
};

AppBar.propTypes = {
  anchorElement: PropTypes.objectOf(PropTypes.any),
  isMenuOpen: PropTypes.bool.isRequired,
  isCalendarOpen: PropTypes.bool.isRequired,
  availableFeatures: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  handleMenuOpen: PropTypes.func.isRequired,
  handleMenuClose: PropTypes.func.isRequired,
  onToggleCalendar: PropTypes.func.isRequired,
  hasPermissions: PropTypes.func.isRequired,
  currentBereavementId: PropTypes.string,
};

export default AppBar;
