export const mediaVariants = {
  FULL_FIT_50_X_50: 'FullFitIn50x50',
  FIT_200_X_200: 'FitIn200x200',
  FULL_FIT_300_X_230: 'FullFitIn300x230',
  FULL_FIT_408_X_408: 'FullFitIn408x408',
  FULL_FIT_640_X_640: 'FullFitIn640x640',
  FULL_FIT_900_X_900: 'FullFitIn900x900',
  FIT_900_X_900: 'FitIn900x900',
};

export const imagesMimeTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
export const documentsMimeTypes = ['text/*', 'application/*'];
