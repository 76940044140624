import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import IconCalendar from '@material-ui/icons/DateRange';
import AppointmentModal from 'components/bereavement/AppointmentModal';
import { appointmentType, homeType } from 'types/bereavement';

import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const BookAppointmentModal = ({
  appointment,
  displayAppointment,
  home,
  isOpen,
  disabled,
  buttonColor,
  onCancel,
  onSave,
  onOpen,
  btnWillExpand,
}) => {
  const appointmentButtonText = displayAppointment
    ? (
      <React.Fragment>
        <span className={rcStyles.has_wrapping_text}>
          {t('Appointment on')}
          <br />
          {moment(displayAppointment.dateTime).format('ddd DD MMM - HH:mm')}
        </span>
      </React.Fragment>
    ) : (
      <React.Fragment>
        {t('Book Appointment')}
      </React.Fragment>
    );

  const applyExpandedBtnStyles = displayAppointment && btnWillExpand;

  return (
    <div data-test-id="bookAppointment">
      <Button
        variant="contained"
        size="large"
        color={buttonColor}
        onClick={onOpen}
        data-test-id="open"
        disabled={disabled}
      >
        <IconCalendar className={styles.u_push__right} />
        {appointmentButtonText}
        <EditIcon
          style={{ display: applyExpandedBtnStyles ? 'block' : 'none' }}
          className={styles.u_soft__left}
        />
      </Button>
      <AppointmentModal
        appointment={appointment}
        home={home}
        isOpen={isOpen}
        onClose={onCancel}
        onSave={onSave}
      />
    </div>
  );
};

BookAppointmentModal.propTypes = {
  appointment: appointmentType.isRequired,
  displayAppointment: appointmentType,
  home: homeType,
  isOpen: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onOpen: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  buttonColor: PropTypes.string.isRequired,
  btnWillExpand: PropTypes.bool,
};

export default BookAppointmentModal;
