import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { apolloClientType } from 'types/apollo';
import {
  generateAccountsCsvUriId,
  generateAccountsPaymentsCsvUriId,
  generatePrimaryContactCsvUriId,
} from 'services/utils/testIds';
import ImportExportScreen from './ImportExportScreen';
import {
  getBereavementsCsv,
  getProductsCsv,
  getServicesCsv,
  getAccountsCsv,
  getAccountsPaymentsCsv,
  getPrimaryContactsCsv,
} from './queries.gql';

class ImportExportScreenContainer extends Component {
  static propTypes = {
    client: apolloClientType.isRequired,
    tenantId: PropTypes.string.isRequired,
    availableFeatures: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      casesStartDate: null,
      casesEndDate: null,
      accountCreatedDate: null,
      accountClosedDate: null,
      accountsPaymentsFromDate: null,
      accountsPaymentsToDate: null,
      primaryContactStartDate: null,
      primaryContactEndDate: null,
      isExportCasesLoading: false,
      isExportAccountLoading: false,
      isExportAccountsPaymentsLoading: false,
      isExportPrimaryContactLoading: false,
      isExportProductsLoading: false,
      isExportServicesLoading: false,
      accountsCsvUriId: null,
      accountsPaymentsCsvUriId: null,
      primaryContactCsvUriId: null,
      isModalOpen: {
        importFromCSV: false,
      },
    };
  }

  handleOnClickExportCases = () => {
    const { client, tenantId } = this.props;
    const { casesStartDate, casesEndDate } = this.state;

    this.setState({
      isExportCasesLoading: true,
    });

    client
      .query({
        query: getBereavementsCsv,
        variables: {
          tenantId,
          start: moment(casesStartDate, 'YYYY-MM-DD').format(),
          end: moment(casesEndDate, 'YYYY-MM-DD').endOf('day').format(),
        },
      })
      .then((response) => {
        const { uri } = response.data.tenant.bereavementsCsv;
        window.location.href = uri;
      })
      .finally(() => {
        this.setState({
          isExportCasesLoading: false,
        });
      });
  }

  handleOnClickExportAccounts = () => {
    const { client, tenantId } = this.props;
    const { accountCreatedDate, accountClosedDate } = this.state;

    this.setState({
      isExportAccountLoading: true,
    });

    client
      .query({
        query: getAccountsCsv,
        variables: {
          tenantId,
          start: moment(accountCreatedDate, 'YYYY-MM-DD').startOf('day').format(),
          end: moment(accountClosedDate, 'YYYY-MM-DD').endOf('day').format(),
        },
      })
      .then((response) => {
        const { uri } = response.data.tenant.accountsCsv;
        window.location.href = uri;
      })
      .finally(() => {
        this.setState({
          isExportAccountLoading: false,
        });
      });
  }

  handleOnClickExportAccountsPayments = () => {
    const { client, tenantId } = this.props;
    const { accountsPaymentsFromDate, accountsPaymentsToDate } = this.state;

    this.setState({
      isExportAccountsPaymentsLoading: true,
    });

    client
      .query({
        query: getAccountsPaymentsCsv,
        variables: {
          tenantId,
          start: moment(accountsPaymentsFromDate, 'YYYY-MM-DD').startOf('day').format(),
          end: moment(accountsPaymentsToDate, 'YYYY-MM-DD').endOf('day').format(),
        },
      })
      .then((response) => {
        const { uri } = response.data.tenant.accountsPaymentsCsv;
        window.location.href = uri;
      })
      .finally(() => {
        this.setState({
          isExportAccountsPaymentsLoading: false,
        });
      });
  }

  handleOnClickExportPrimaryContacts = () => {
    const { client, tenantId } = this.props;
    const { primaryContactStartDate, primaryContactEndDate } = this.state;

    this.setState({
      isExportPrimaryContactLoading: true,
    });

    client
      .query({
        query: getPrimaryContactsCsv,
        variables: {
          tenantId,
          start: moment(primaryContactStartDate, 'YYYY-MM-DD').format(),
          end: moment(primaryContactEndDate, 'YYYY-MM-DD').endOf('day').format(),
        },
      })
      .then((response) => {
        const { uri } = response.data.tenant.primaryContactInformationCsv;
        window.location.href = uri;
      })
      .finally(() => {
        this.setState({
          isExportPrimaryContactLoading: false,
        });
      });
  }

  handleOnClickExportProducts = () => {
    const { client, tenantId } = this.props;

    this.setState({
      isExportProductsLoading: true,
    });

    client
      .query({
        query: getProductsCsv,
        variables: {
          tenantId,
        },
      })
      .then((response) => {
        const { uri } = response.data.tenant.productsCsv;
        window.location.href = uri;
      })
      .finally(() => {
        this.setState({
          isExportProductsLoading: false,
        });
      });
  }

  handleOnClickExportServices = () => {
    const { client, tenantId } = this.props;

    this.setState({
      isExportServicesLoading: true,
    });

    client
      .query({
        query: getServicesCsv,
        variables: {
          tenantId,
        },
      })
      .then((response) => {
        const { uri } = response.data.tenant.servicesCsv;
        window.location.href = uri;
      })
      .finally(() => {
        this.setState({
          isExportServicesLoading: false,
        });
      });
  }

  handleAccountFiltersChange = (key, value) => {
    this.setState({ [key]: value }, () => {
      const { accountCreatedDate, accountClosedDate } = this.state;

      if (accountCreatedDate && accountClosedDate) {
        this.setState({
          accountsCsvUriId: generateAccountsCsvUriId(
            moment(accountCreatedDate, 'YYYY-MM-DD').format(),
            moment(accountClosedDate, 'YYYY-MM-DD').endOf('day').format(),
          ),
        });
      }
    });
  }

  handleAccountsPaymentsFiltersChange = (key, value) => {
    this.setState({ [key]: value }, () => {
      const { accountsPaymentsFromDate, accountsPaymentsToDate } = this.state;

      if (accountsPaymentsFromDate && accountsPaymentsToDate) {
        this.setState({
          accountsPaymentsCsvUriId: generateAccountsPaymentsCsvUriId(
            moment(accountsPaymentsFromDate, 'YYYY-MM-DD').format(),
            moment(accountsPaymentsToDate, 'YYYY-MM-DD').endOf('day').format(),
          ),
        });
      }
    });
  }

  handlePrimaryContactFiltersChange = (key, value) => {
    this.setState({ [key]: value }, () => {
      const { primaryContactStartDate, primaryContactEndDate } = this.state;

      if (primaryContactStartDate && primaryContactStartDate) {
        this.setState({
          primaryContactCsvUriId: generatePrimaryContactCsvUriId(
            moment(primaryContactStartDate, 'YYYY-MM-DD').format(),
            moment(primaryContactEndDate, 'YYYY-MM-DD').endOf('day').format(),
          ),
        });
      }
    });
  }

  handleOnCasesStartDateChange = (date) => {
    this.setState({ casesStartDate: date });
  }

  handleOnCasesEndDateChange = (date) => {
    this.setState({ casesEndDate: date });
  }

  handleToggleModal = (key) => {
    this.setState(prevState => ({
      isModalOpen: {
        ...prevState.isModalOpen,
        [key]: !prevState.isModalOpen[key],
      },
    }));
  }


  render() {
    const { availableFeatures } = this.props;
    const {
      casesStartDate,
      casesEndDate,
      accountCreatedDate,
      accountClosedDate,
      accountsPaymentsFromDate,
      accountsPaymentsToDate,
      primaryContactStartDate,
      primaryContactEndDate,
      isExportCasesLoading,
      isExportAccountLoading,
      isExportAccountsPaymentsLoading,
      isExportPrimaryContactLoading,
      isExportProductsLoading,
      isExportServicesLoading,
      accountsCsvUriId,
      accountsPaymentsCsvUriId,
      primaryContactCsvUriId,
      isModalOpen,
    } = this.state;

    return (
      <ImportExportScreen
        availableFeatures={availableFeatures}
        casesStartDate={casesStartDate}
        casesEndDate={casesEndDate}
        accountCreatedDate={accountCreatedDate}
        accountClosedDate={accountClosedDate}
        accountsPaymentsFromDate={accountsPaymentsFromDate}
        accountsPaymentsToDate={accountsPaymentsToDate}
        primaryContactStartDate={primaryContactStartDate}
        primaryContactEndDate={primaryContactEndDate}
        onAccountFiltersChange={this.handleAccountFiltersChange}
        onAccountsPaymentsFiltersChange={this.handleAccountsPaymentsFiltersChange}
        onPrimaryContactFiltersChange={this.handlePrimaryContactFiltersChange}
        onCasesStartDateChange={this.handleOnCasesStartDateChange}
        onCasesEndDateChange={this.handleOnCasesEndDateChange}
        onClickExportCases={this.handleOnClickExportCases}
        onClickExportProducts={this.handleOnClickExportProducts}
        onClickExportServices={this.handleOnClickExportServices}
        onClickExportAccounts={this.handleOnClickExportAccounts}
        onClickExportAccountsPayments={this.handleOnClickExportAccountsPayments}
        onClickExportPrimaryContacts={this.handleOnClickExportPrimaryContacts}
        isExportCasesLoading={isExportCasesLoading}
        isExportAccountLoading={isExportAccountLoading}
        isExportAccountsPaymentsLoading={isExportAccountsPaymentsLoading}
        isExportPrimaryContactLoading={isExportPrimaryContactLoading}
        isExportProductsLoading={isExportProductsLoading}
        isExportServicesLoading={isExportServicesLoading}
        accountsCsvUriId={accountsCsvUriId}
        accountsPaymentsCsvUriId={accountsPaymentsCsvUriId}
        primaryContactCsvUriId={primaryContactCsvUriId}
        isModalOpen={isModalOpen}
        onToggleModal={this.handleToggleModal}
      />
    );
  }
}

const mapStateToProps = state => ({
  tenantId: state.userStore.user.tenantId,
  availableFeatures: state.userStore.user.tenantFeatureFlags,
});

export default withApollo(withRouter(connect(mapStateToProps)(ImportExportScreenContainer)));
