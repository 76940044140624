import React from 'react';
import classNames from 'classnames';
import InputAdornment from '@material-ui/core/InputAdornment';

import NumberField from 'components/common/NumberField';
import { getMeasurementConfigInput } from 'services/utils/measurement';
import { MeasurementInputConfig } from 'types/ts/measurement';

import { Props } from './MeasurementField.types';
import rcStyles from './styles.scss';

const MeasurementField: React.FC<Props> = ({
  className,
  label,
  measurementValues,
  units,
  disabled,
  onChange,
  'data-test-id': testId,
}: Props) => (
  <div className={className} data-test-id={testId}>
    <div className={classNames(measurementValues.length > 1 && rcStyles.measurement_container)}>
      {measurementValues && measurementValues.map(({ unit, value }) => {
        const inputConfig: MeasurementInputConfig|undefined = getMeasurementConfigInput(units, unit);
        if (!inputConfig) {
          return;
        }

        const { adornment } = inputConfig;
        return (
          <NumberField
            key={unit}
            label={label}
            value={value ?? ''}
            onChange={event => onChange(unit, event.target.value)}
            fullWidth
            disabled={disabled}
            InputProps={{
              endAdornment: adornment && (<InputAdornment position="end">{adornment}</InputAdornment>),
            }}
            data-test-id={`${testId}_${unit.toLowerCase()}`}
          />
        );
      })}
    </div>
  </div>
);

export default MeasurementField;
