import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AutoCompleteTextField from 'components/common/AutoCompleteTextField';
import CoffinSizeFormSection from 'components/arrangement/CoffinSizeFormSection';
import Select from 'components/common/Select';
import IconLoad from 'icons/IconLoad';
import { coffinHandleTypeOptions, coffinShapeOptions } from 'constants/arrangement';
import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const ArrangementCoffinsForm = ({
  categoryInformation,
  disabled,
  isSaving,
  onChange,
  onSave,
  errors,
}) => (
  <div className={rcStyles.v_spacing}>
    <TextField
      label={t('Adornments / Special Instructions')}
      value={categoryInformation.engraving || ''}
      onChange={event => onChange('engraving', event.target.value)}
      disabled={disabled}
      inputProps={{
        'data-test-id': 'coffin-engraving',
      }}
      fullWidth
      className={rcStyles.colspan2}
    />
    <div className={classNames(
      rcStyles.col1,
      rcStyles.colspan2,
    )}
    >
      <CoffinSizeFormSection
        value={categoryInformation.size}
        disabled={disabled}
        onChange={size => onChange('size', size)}
        data-test-id="coffinSizeFormSection"
        errors={errors.size}
      />
    </div>
    <div className={classNames(
      rcStyles.col1,
      rcStyles.colspan2,
    )}
    >
      <Select
        label={t('Coffin Shape')}
        value={categoryInformation.shape}
        onChange={event => onChange('shape', event.target.value)}
        inputProps={{
          'data-test-id': 'shape',
        }}
        fullWidth
        disabled={disabled}
      >
        {coffinShapeOptions.map(shape => (
          <MenuItem
            key={shape.value}
            value={shape.value}
            data-test-id={shape.value}
          >
            {t(shape.name)}
          </MenuItem>
        ))}
      </Select>
    </div>
    <div className={classNames(
      rcStyles.col1,
      rcStyles.colspan2,
    )}
    >
      <AutoCompleteTextField
        placeholder={t('Handle Type')}
        suggestions={coffinHandleTypeOptions}
        value={categoryInformation.handleType || ''}
        onChange={handleType => onChange('handleType', handleType)}
        disabled={disabled}
        data-test-id="handleType"
        fullWidth
      />
    </div>
    <div className={classNames(
      rcStyles.col1,
      rcStyles.colspan2,
    )}
    >
      <TextField
        label={t('Nameplate Instructions')}
        value={categoryInformation.nameplateInstructions || ''}
        onChange={event => onChange('nameplateInstructions', event.target.value)}
        disabled={disabled}
        inputProps={{
          'data-test-id': 'nameplateInstructions',
        }}
        fullWidth
      />
    </div>
    <div className={classNames(
      styles.c_confirmed__wrap,
      rcStyles.col1,
      rcStyles.colspan2,
    )}
    >
      <FormControlLabel
        control={(
          <Checkbox
            className={styles.c_confirmed__checkbox}
            checked={categoryInformation.isConfirmed || false}
            onChange={event => onChange('isConfirmed', event.target.checked)}
            inputProps={{ 'data-test-id': 'isConfirmed' }}
            disabled={disabled}
            color="primary"
          />
        )}
        label={t('Order placed/Availability confirmed')}
      />
    </div>
    <span className={rcStyles.col1}>
      <Button
        onClick={onSave}
        variant="contained"
        color="primary"
        data-test-id="saveCoffinsCategory"
        disabled={disabled}
      >
        {t('Save')}
      </Button>
    </span>
    {isSaving && (
      <div className={styles.o_fb__item__whole}>
        <div className={styles.c_svg_loader_big} data-test-id="primaryLoader">
          <IconLoad title={t('Saving')} />
        </div>
      </div>
    )}
  </div>

);

ArrangementCoffinsForm.propTypes = {
  categoryInformation: PropTypes.objectOf(PropTypes.any).isRequired,
  isSaving: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ArrangementCoffinsForm;
