import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import i18next from 'i18next';
import classNames from 'classnames';

import AccountAmount from 'components/account/AccountAmount';
import HighlightableText from 'components/common/HighlightableText';
import { InvoiceType } from 'types/ts/bereavement';

import rcStyles from './styles.scss';
import { Props } from './index.types';

const AccountInvoicesList: React.FC<Props> = ({
  bereavementId,
  hasXeroIntegration,
  invoices,
  match,
  searchTerm,
}: Props) => (
  <ul className={rcStyles.invoices}>
    {invoices.map((invoice) => {
      const showTotalCredit = !hasXeroIntegration && !!invoice.totals.totalCredit.amount;
      const showTotalPaid = !hasXeroIntegration && !!invoice.totals.totalPaid.amount;
      const showTotal = !hasXeroIntegration && (showTotalCredit || showTotalPaid);

      return (
        <li key={invoice.id} className={rcStyles.invoice_list_item}>
          <Link
            to={{
              pathname: `${match.url}/${bereavementId}`,
              state: { invoiceId: invoice.id },
            }}
          >
            <div className={rcStyles.invoice}>
              <div className={rcStyles.invoiceNumber}>
                <div className={rcStyles.is_secondary_label}>
                  {invoice.invoiceType === InvoiceType.INVOICE && i18next.t('Invoice')}
                  {invoice.invoiceType === InvoiceType.PROFORMA && i18next.t('Proforma Invoice')}
                </div>
                <div className={rcStyles.is_secondary_data}>
                  {searchTerm ? <HighlightableText highlight={searchTerm} text={invoice.number} /> : invoice.number}
                </div>
              </div>
              <div className={rcStyles.invoiceVoided}>
                {invoice.dateVoided && (
                  <>
                    <div className={rcStyles.is_secondary_label}>
                      {i18next.t('Voided')}
                    </div>
                    <div className={rcStyles.is_secondary_data}>
                      {invoice.dateVoided}
                    </div>
                  </>
                )}
              </div>
              <div className={classNames(
                rcStyles.invoice_totals,
                (showTotalCredit && showTotalPaid) && rcStyles.invoice_totals__all,
                (showTotal && (!showTotalCredit || !showTotalPaid)) && rcStyles.invoice_totals__paid_or_credit,
                !showTotal && rcStyles.invoice_totals__balance,
              )}
              >
                {showTotal && <AccountAmount label="Total" total={invoice.totals.total} testId="accountInvoiceTotal" />}
                {showTotalCredit ? <AccountAmount label="Credit" total={invoice.totals.totalCredit} testId="accountInvoiceTotalCredit" /> : <div />}
                {showTotalPaid ? <AccountAmount label="Paid" total={invoice.totals.totalPaid} testId="accountInvoiceTotalPaid" /> : <div />}
                <div className={rcStyles.is_balance}>
                  <AccountAmount label="Balance" hasStrikeThrough={!!invoice.dateVoided} total={invoice.totals.balance} testId="accountInvoiceBalance" />
                </div>
              </div>
            </div>
          </Link>
        </li>
      );
    })}
  </ul>
);

export default withRouter(AccountInvoicesList);
