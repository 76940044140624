import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import AsyncPaginate from 'react-select-async-paginate';
import { roleType } from 'types/staffMember';
import { apolloClientType } from 'types/apollo';
import { getAsyncLookupOptionValue, removeDuplicateLookupItems } from 'services/utils';
import { featureFlags } from 'constants/features';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import { reactRefType } from 'types/common';
import { components } from 'react-select';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';
import { staffRoles } from './queries.gql';


const customSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? styles.colorGreyUltraLight : styles.colorWhite,
    color: 'inherit',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: '4 0',
    color: 'rgba(0, 0, 0, 0.54)',
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: 4,
    color: 'rgba(0, 0, 0, 0.54)',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '6px 0',
  }),
  input: provided => ({
    ...provided,
    margin: 0,
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 0,
    marginRight: 0,
  }),
  control: (provided, state) => {
    const focusedStyle = state.isFocused ? styles.colorOffWhite : 'inherit';
    return {
      'display': 'flex',
      'backgroundColor': state.hasValue ? 'inherit' : focusedStyle,
      '&:hover': {
        cursor: 'pointer',
      },
      '&:before': {
        'left': 0,
        'right': 0,
        'bottom': 0,
        'content': '"\\00a0"',
        'position': 'absolute',
        'transition': 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        'border-bottom': '1px solid',
        'borderBottomWidth': state.isFocused ? '2px' : '1px',
        'borderBottomColor': state.isFocused ? styles.colorHighlight : 'rgba(0, 0, 0, 0.42)',
        'pointer-events': 'none',
      },
    };
  },
  menu: provided => ({
    ...provided,
    marginTop: 0,
    paddingTop: 4,
    paddingBottom: 4,
  }),
};

const CustomClearIndicator = (props) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles('clearIndicator', props)}
    >
      <CloseIcon />
    </div>
  );
};

CustomClearIndicator.propTypes = {
  getStyles: PropTypes.func.isRequired,
  innerProps: PropTypes.shape({
    ref: reactRefType,
  }),
};

const CustomDropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <ArrowDropDownIcon />
  </components.DropdownIndicator>
);

class StaffMemberRoleLookupContainer extends Component {
  static propTypes = {
    client: apolloClientType.isRequired,
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(roleType),
    ]),
    hasRestrictedRoles: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isMulti: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  getStaffRolesByIds = async (ids) => {
    const { client, onChange } = this.props;

    const { data } = await client.query({
      query: staffRoles,
      variables: {
        pagination: {
          first: 9999,
          after: null,
        },
      },
    });
    const activeRoles = data.staffRoles.edges
      .map(edge => edge.node)
      .filter(node => ids.includes(node.id));

    onChange(activeRoles);
  }

  getStaffRoles = async (prevMetadata) => {
    const { client, hasRestrictedRoles } = this.props;

    const lastCursor = (prevMetadata && prevMetadata.lastCursor) ? prevMetadata.lastCursor : null;

    const data = await client.query({
      query: staffRoles,
      variables: {
        pagination: {
          first: 15,
          after: lastCursor,
        },
      },
    });

    const { edges, pageInfo } = data.data.staffRoles;
    let newResults = edges.map(({ node }) => ({
      label: node.name,
      value: node,
    }));

    if (hasRestrictedRoles) {
      newResults = newResults
        .filter(role => role.label === 'Arranger' || role.label === 'Administrator');
    }

    return {
      options: newResults,
      hasMore: pageInfo.hasNextPage,
      additional: {
        lastCursor: pageInfo.endCursor,
      },
    };
  }

  handleChange = (selected) => {
    const { isMulti, onChange } = this.props;
    if (isMulti) {
      const updatedSelected = removeDuplicateLookupItems([...selected]);
      return onChange(updatedSelected.map(item => item.value));
    }
    return selected ? onChange(selected.value) : onChange(selected);
  }

  handleFilters = (option, searchTerm) => {
    const { isMulti } = this.props;
    let { value: propValue } = this.props;

    if (propValue && typeof propValue === 'object' && !isMulti) {
      propValue = [propValue];
    }

    const valueIds = propValue ? propValue.map(item => item.id) : [];
    const isSelected = valueIds.includes(option.value.id);

    if (searchTerm) {
      const isSearchMatch = searchTerm
        && option.label.toLowerCase().includes(searchTerm.trim().toLowerCase());
      return !isSelected && isSearchMatch;
    }
    return !isSelected;
  }

  render() {
    const { label, disabled, isMulti } = this.props;
    let { value: propValue } = this.props;

    if (typeof propValue === 'string') {
      this.getStaffRolesByIds([propValue]);
    }

    if (isMulti && propValue && typeof propValue[0] === 'string') {
      this.getStaffRolesByIds(propValue);
    }

    if (propValue && typeof propValue === 'object' && !isMulti) {
      propValue = [propValue];
    }

    const value = propValue
      ? propValue.map(item => ({
        label: item.name,
        value: item,
      }))
      : null;

    return (
      <AsyncPaginate
        id="organisationalUnitSelect"
        value={value}
        onChange={this.handleChange}
        placeholder={label}
        loadOptions={this.getStaffRoles}
        getOptionValue={option => getAsyncLookupOptionValue(option)}
        filterOption={this.handleFilters}
        closeMenuOnSelect={false}
        debounceTimeout={500}
        isClearable
        isSearchable
        isMulti={isMulti}
        isDisabled={disabled}
        styles={customSelectStyles}
        className={rcStyles.rc_z_order}
        components={{
          ClearIndicator: CustomClearIndicator,
          DropdownIndicator: CustomDropdownIndicator,
        }}
      />
    );
  }
}

const mapStateToProps = state => ({
  hasRestrictedRoles: state.userStore.user.tenantFeatureFlags
    .includes(featureFlags.RESTRICT_ROLES_TO_ARRANGER_AND_ADMINISTRATOR),
});

export default withApollo(connect(mapStateToProps)(StaffMemberRoleLookupContainer));
