import React from 'react';
import { t } from 'i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';

import IconOfflinemain from 'icons/IconOfflinemain';
import styles from 'scss/main.scss';

import rcStyles from './styles.scss';

const GenericAppMessage = ({
  subTitle,
  error,
  showReturnToDashboardButton,
  onReturnToDashboard,
}) => (
  <div
    className={classNames(
      (error && error.name === 'OfflineError'),
      rcStyles.layout,
    )}
  >
    <div className={rcStyles.col1}>
      <img src="/img/core/arrman.png" alt="" />
    </div>
    <div className={rcStyles.col2}>
      {error && error.name === 'OfflineError' && (
        <div className={rcStyles.c_logo_offline}>
          <IconOfflinemain />
        </div>
      )}
      <div className={rcStyles.message_title}>
        {subTitle}
      </div>
      {showReturnToDashboardButton && (
        <div className={rcStyles.constrained_width}>
          <h3 className={styles.long_primer}>
            {t('Here are some links to help you.')}
          </h3>
          <ul className={classNames(
            styles.o_list,
            styles.is_plain,
          )}
          >
            <li className={styles.o_list__item}>
              <Link
                className={rcStyles.test}
                onClick={onReturnToDashboard}
                data-test-id="returnToDashboard"
              >
                {t('Return To Dashboard')}
              </Link>
            </li>
            <li className={styles.o_list__item}>
              <Link
                className={rcStyles.test}
                target="_blank"
                to="http://funeral-zone-support.knowledgeowl.com/help"
                data-test-id="returnToDashboard"
              >
                {t('Help & Documentation')}
              </Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  </div>
);

GenericAppMessage.propTypes = {
  subTitle: PropTypes.string.isRequired,
  error: PropTypes.objectOf(PropTypes.any),
  showReturnToDashboardButton: PropTypes.bool.isRequired,
  onReturnToDashboard: PropTypes.func.isRequired,
};

export default GenericAppMessage;
