import React from 'react';
import ReactDOM from 'react-dom';

import { initialiseNetworkSubscriptions } from 'services/network/listeners';
import { deleteCacheStorage } from 'services/cleanup';

import App from './App';

ReactDOM.render(React.createElement(App), document.getElementById('root'));

deleteCacheStorage();
initialiseNetworkSubscriptions();
