import PropTypes from 'prop-types';
import {
  nameType, emailsType, addressType, phonesType, notesType,
} from 'types/common';
import { organisationalUnitType } from 'types/organisationalUnit';

export const staffMemberType = PropTypes.shape({
  id: PropTypes.string,
  timeCreated: PropTypes.string,
  name: nameType,
  address: addressType,
  phones: phonesType,
  emails: emailsType,
  employmentStatus: PropTypes.string,
  active: PropTypes.bool,
  jobTitle: PropTypes.string,
  notes: notesType,
  organisationalUnitIds: PropTypes.arrayOf(PropTypes.string),
  organisationalUnitsFlattened: PropTypes.arrayOf(organisationalUnitType),
  identityInterface: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    name: nameType,
    image: PropTypes.string,
    email: PropTypes.string,
    policy: PropTypes.shape({
      memberships: PropTypes.arrayOf(PropTypes.string),
      scopes: PropTypes.arrayOf(PropTypes.string),
    }),
    isActive: PropTypes.bool,
  }),
});

export const roleType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  scopes: PropTypes.arrayOf(PropTypes.string),
});
