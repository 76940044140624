import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const StepNavigation = ({
  navigationSteps,
  activeStep,
  onChangeActiveStep,
  disabledSteps,
}) => (
  <div className={rcStyles.stepper}>
    <div className={rcStyles.stepper__inner_line}>
      <div style={{ width: `${(100 / navigationSteps.length) * (navigationSteps.length - 1)}%` }} />
    </div>
    <div className={rcStyles.stepper__inner_box}>
      {navigationSteps.map((step, index) => (
        <div
          className={rcStyles.stepper__box}
          key={step.title}
          style={{
            cursor: !(disabledSteps && disabledSteps.includes(index)) && activeStep !== index ? 'pointer' : '',
            outline: 'none',
            width: `${100 / navigationSteps.length}%`,
          }}
          role="button"
          tabIndex="-1"
          onClick={() => !(disabledSteps && disabledSteps.includes(index)) && onChangeActiveStep(index)}
          data-test-id="stepButton"
        >
          <div className={classNames(
            rcStyles.stepper__roundel,
            activeStep === index ? rcStyles.is_selected : null,
          )}
          />
          <div
            className={classNames(
              rcStyles.stepper__label,
              styles.primer,
              activeStep === index ? styles.is_primary : styles.is_grey,
              activeStep === index ? rcStyles.is_active : null,
            )}
            data-test-id="stepTitle"
          >
            {step.title}
          </div>
        </div>
      ))}
    </div>
  </div>
);

StepNavigation.propTypes = {
  activeStep: PropTypes.number.isRequired,
  onChangeActiveStep: PropTypes.func.isRequired,
  disabledSteps: PropTypes.arrayOf(PropTypes.number),
  navigationSteps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default StepNavigation;
