import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { withValidation } from '@funeralguide/react-form-validation-hoc';
import { apolloClientType } from 'types/apollo';
import { bereavedPersonConnectionType, possessionType } from 'types/bereavement';

import { getPossessionsReleaseForm } from './queries.gql';
import PosessionsReleaseFormModal from './PossessionsReleaseFormModal';
import { possessionsValidation } from './validation';

export class PosessionsReleaseFormModalContainer extends Component {
  static propTypes = {
    bereavementId: PropTypes.string.isRequired,
    deceasedPersonId: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    possessions: PropTypes.arrayOf(possessionType).isRequired,
    bereavedPeopleConnections: PropTypes.arrayOf(bereavedPersonConnectionType).isRequired,
    onClose: PropTypes.func.isRequired,
    client: apolloClientType.isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
    validate: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      selectedPossessions: [],
      selectedClient: props.bereavedPeopleConnections.find(client => client.isPrimaryContact).id,
      isValidationEnabled: false,
      isReleaseFormDownloading: false,
    };
  }

  handleChangeActiveStep = (activeStep) => {
    this.setState({
      activeStep,
      isValidationEnabled: false,
    });
  };

  handleChangePossessions = (possessionId, isChecked) => {
    const { validate } = this.props;
    const { selectedPossessions } = this.state;
    let updatedPossessions = [...selectedPossessions];
    if (isChecked) {
      updatedPossessions.push(possessionId);
    } else {
      updatedPossessions = updatedPossessions.filter(id => possessionId !== id);
    }

    validate({ possessions: updatedPossessions }, possessionsValidation);
    this.setState({ selectedPossessions: updatedPossessions });
  }

  handleChangeClient = (connectionId) => {
    this.setState({ selectedClient: connectionId });
  }

  handleSavePossessions = (step = 1) => {
    const { validate } = this.props;
    const { selectedPossessions } = this.state;

    this.setState({ isValidationEnabled: true });

    const isValid = validate({ possessions: selectedPossessions }, possessionsValidation);
    if (isValid) {
      this.handleChangeActiveStep(step);
    }
  }

  handleGenerateReleaseForm = async () => {
    const { client, bereavementId, deceasedPersonId } = this.props;
    const { selectedPossessions, selectedClient } = this.state;
    this.setState({ isReleaseFormDownloading: true });

    const { data } = await client.query({
      query: getPossessionsReleaseForm,
      variables: {
        bereavementId,
        deceasedPersonId,
        bereavedPersonConnectionId: selectedClient,
        possessionIds: selectedPossessions,
      },
    });

    const { deceasedPeople } = data.bereavement || {};
    const { uri } = deceasedPeople[0].possessionsReleaseFormPdf;
    if (uri) {
      window.open(uri, uri).focus();
    }

    this.setState({ isReleaseFormDownloading: false });
  }

  handleClose = () => {
    const { bereavedPeopleConnections, onClose } = this.props;

    this.setState({
      activeStep: 0,
      selectedPossessions: [],
      selectedClient: bereavedPeopleConnections.find(client => client.isPrimaryContact).id,
      isValidationEnabled: false,
      isReleaseFormDownloading: false,
    });
    onClose();
  }

  render() {
    const {
      isOpen,
      possessions,
      bereavedPeopleConnections,
      errors,
    } = this.props;
    const {
      activeStep,
      selectedPossessions,
      selectedClient,
      isReleaseFormDownloading,
      isValidationEnabled,
    } = this.state;

    return (
      <PosessionsReleaseFormModal
        activeStep={activeStep}
        isOpen={isOpen}
        possessions={possessions}
        bereavedPeopleConnections={bereavedPeopleConnections}
        selectedPossessions={selectedPossessions}
        selectedClient={selectedClient}
        isReleaseFormDownloading={isReleaseFormDownloading}
        onChangeActiveStep={this.handleChangeActiveStep}
        onChangePossessions={this.handleChangePossessions}
        onChangeClient={this.handleChangeClient}
        onSavePossessions={this.handleSavePossessions}
        onClose={this.handleClose}
        onGenerateReleaseForm={this.handleGenerateReleaseForm}
        errors={isValidationEnabled ? errors : {}}
      />
    );
  }
}

export default withValidation(withApollo(PosessionsReleaseFormModalContainer));
