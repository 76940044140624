import React, { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  setTenantCorrespondenceTemplateDefinitionsAction,
  updateTenantCorrespondenceTemplateDefinitionAction,
} from 'actions/user';
import {
  buildTemplateDefinitionsLookup,
  updateTemplateDefinitionsLookup,
  deleteDefinitionFromTemplateDefinitionLookup,
} from 'services/utils/correspondence';
import { TemplateDefinition, TemplateDefinitionsLookup } from 'types/ts/tenant';

import { RootState } from 'types/ts/state';
import {
  getCorrespondenceVariablesCsv,
  getTenantCorrespondenceTemplateDefinitions,
} from './queries.gql';
import {
  editCorrespondenceTemplate,
  deleteCorrespondenceTemplate,
} from './mutations.gql';
import {
  Props, PropsWithoutApolloClient, State, IsLoadingKey, IsModalOpenKey,
} from './index.types';
import AdminLetterTemplatesScreen from './AdminLetterTemplatesScreen';

class AdminLetterTemplatesScreenContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { tenantCorrespondenceTemplateDefinitions } = props;
    const templateDefinitionsLookup: TemplateDefinitionsLookup = buildTemplateDefinitionsLookup(
      tenantCorrespondenceTemplateDefinitions,
    );

    this.state = {
      templateDefinitionsLookup,
      isDownloadingCsv: false,
      isLoading: {
        tenantTemplateDefinitions: false,
      },
      isModalOpen: {
        generateTemplate: false,
        editCustomTemplate: false,
        deleteCustomTemplateConfirmation: false,
      },
      customTemplateBeingModified: null,
      customTemplateBeingDeleted: null,
    };
  }

  componentDidMount() {
    const { client, tenantId, setTenantCorrespondenceTemplateDefinitions } = this.props;

    this.toggleLoading('tenantTemplateDefinitions');

    client.query({
      query: getTenantCorrespondenceTemplateDefinitions,
      variables: { tenantId },
    }).then(({ data }) => {
      const { correspondenceTemplateDefinitions: definitions } = data.tenant;
      const templateDefinitionsLookup: TemplateDefinitionsLookup = buildTemplateDefinitionsLookup(definitions);

      this.setState({ templateDefinitionsLookup });
      setTenantCorrespondenceTemplateDefinitions(definitions);
    }).finally(() => {
      this.toggleLoading('tenantTemplateDefinitions');
    });
  }

  handleDownloadTemplateVariables = async () => {
    const { client } = this.props;

    this.setState({ isDownloadingCsv: true });

    const { data } = await client.query({
      query: getCorrespondenceVariablesCsv,
    });

    const {
      correspondence: {
        correspondenceVariablesCsv: {
          uri,
        },
      },
    } = data;

    this.setState({ isDownloadingCsv: false });

    const openWindow = window.open(uri, uri);
    if (openWindow !== null) {
      openWindow.focus();
    }
  };

  handleUpdateTemplateDefinition = (template: TemplateDefinition) => {
    const { updateTenantCorrespondenceTemplateDefinition } = this.props;
    const { templateDefinitionsLookup } = this.state;

    const updatedTemplateDefinitionsLookup = updateTemplateDefinitionsLookup(
      templateDefinitionsLookup,
      template,
    );

    this.setState({
      templateDefinitionsLookup: updatedTemplateDefinitionsLookup,
      customTemplateBeingModified: null,
    });

    updateTenantCorrespondenceTemplateDefinition(template);
  };

  toggleLoading = (key: IsLoadingKey) => {
    this.setState(prevState => ({
      isLoading: {
        ...prevState.isLoading,
        [key]: !prevState.isLoading[key],
      },
    }));
  }

  toggleModal = (key: IsModalOpenKey) => {
    this.setState(prevState => ({
      isModalOpen: {
        ...prevState.isModalOpen,
        [key]: !prevState.isModalOpen[key],
      },
    }));
  }

  handleSetCustomTemplateBeingModified = (template: TemplateDefinition|null = null) => {
    this.setState({
      customTemplateBeingModified: template,
    });
  }

  handleSetCustomTemplateBeingDeleted = (template: TemplateDefinition|null = null) => {
    this.setState({
      customTemplateBeingDeleted: template,
    });
  }

  handleSaveCustomTemplate = (template: TemplateDefinition) => {
    const {
      tenantId,
      client,
      userId,
      userName,
    } = this.props;

    const input = {
      id: tenantId,
      correspondenceId: template.id,
      category: template.category,
      type: template.type,
      title: template.title,
      mediaId: template.mediaId,
    };

    client.mutate({
      mutation: editCorrespondenceTemplate,
      variables: { input },
    });

    const updatedTemplate: TemplateDefinition = {
      ...template,
      lastEditedById: userId,
      lastEditedBy: {
        name: { ...userName },
      },
      lastEditedDateTime: moment().toISOString(),
    };

    this.handleUpdateTemplateDefinition(updatedTemplate);
  }

  handleDeleteCustomTemplate = () => {
    const { tenantId, client } = this.props;
    const {
      templateDefinitionsLookup,
      customTemplateBeingDeleted: template,
    } = this.state;

    if (!template) {
      return;
    }

    this.toggleModal('deleteCustomTemplateConfirmation');

    const updatedTemplateDefinitionsLookup = deleteDefinitionFromTemplateDefinitionLookup(
      templateDefinitionsLookup,
      template,
    );

    const input = {
      id: tenantId,
      correspondenceId: template?.id,
    };

    client.mutate({
      mutation: deleteCorrespondenceTemplate,
      variables: { input },
    });

    this.setState({
      templateDefinitionsLookup: updatedTemplateDefinitionsLookup,
      customTemplateBeingDeleted: null,
    });
  }

  render() {
    const {
      isDownloadingCsv,
      isLoading,
      isModalOpen,
      templateDefinitionsLookup,
      customTemplateBeingModified,
      customTemplateBeingDeleted,
    } = this.state;
    return (
      <AdminLetterTemplatesScreen
        templateDefinitionsLookup={templateDefinitionsLookup}
        isDownloadingCsv={isDownloadingCsv}
        isLoading={isLoading}
        isModalOpen={isModalOpen}
        customTemplateBeingModified={customTemplateBeingModified}
        customTemplateBeingDeleted={customTemplateBeingDeleted}
        onDeleteCustomTemplate={this.handleDeleteCustomTemplate}
        onDownloadTemplateVariables={this.handleDownloadTemplateVariables}
        onSaveCustomTemplate={this.handleSaveCustomTemplate}
        onUpdateTemplateDefinition={this.handleUpdateTemplateDefinition}
        toggleModal={this.toggleModal}
        setCustomTemplateBeingModified={this.handleSetCustomTemplateBeingModified}
        setCustomTemplateBeingDeleted={this.handleSetCustomTemplateBeingDeleted}
      />
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  tenantId: state.userStore.user.tenantId,
  tenantCorrespondenceTemplateDefinitions: state.userStore.user.tenantCorrespondenceTemplateDefinitions,
  userId: state.userStore.user.staffMember.id,
  userName: state.userStore.user.name,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  setTenantCorrespondenceTemplateDefinitions: setTenantCorrespondenceTemplateDefinitionsAction,
  updateTenantCorrespondenceTemplateDefinition: updateTenantCorrespondenceTemplateDefinitionAction,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(
  withApollo<PropsWithoutApolloClient, State>(AdminLetterTemplatesScreenContainer),
);
