import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Button from '@material-ui/core/Button';
import { accountTransactionType } from 'constants/account';
import { getFormattedDate } from 'services/utils';
import { creditType, invoiceType } from 'types/account';
import ExpandableBanner from 'components/common/ExpandableBanner';
import Price from 'components/common/Price';
import { AccountConsumer } from 'contexts/account';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const TransactionCreditRow = ({
  credit,
  linkedInvoice,
  setTransactionBeingModified,
  toggleAddCreditModal,
  toggleConfirmDeleteModal,
}) => (
  <AccountConsumer>
    {({ isAccountOpen, hasWritePermissions }) => (
      <div data-test-id="creditTransaction">
        <ExpandableBanner
          headerClasses={[rcStyles.expanding_banner__header]}
          wrapperClasses={[rcStyles.expanding_banner__wrap]}
          isClosedOnLoad
          title={(
            <div className={rcStyles.banner_content}>
              <div className={rcStyles.is_title}>
                {t('Credit')}
              </div>
              <div className={rcStyles.is_date_created}>
                <div className={styles.brevier}>
                  {t('Credited')}
                </div>
                <span data-test-id="creditCreditedOn">
                  {getFormattedDate(credit.dateTime)}
                </span>
              </div>
              <div
                data-test-id="creditAmount"
                className={rcStyles.is_price}
              >
                <Price
                  price={credit.amount}
                  customClass={classNames(
                    styles.c_price_label__regular,
                    styles.is_black,
                    styles.is_bold,
                  )}
                />
              </div>
            </div>
          )}
        >
          <div className={rcStyles.expanded_content}>
            {linkedInvoice && (
              <div>
                <div className={styles.brevier}>
                  {t('Invoice number')}
                </div>
                <span data-test-id="creditInvoiceNumber">
                  {linkedInvoice.number}
                </span>
              </div>
            )}
            <div
              data-test-id="creditReason"
              className={styles.brevier}
            >
              {credit.reason}
            </div>
            <div className={rcStyles.actions_bar}>
              <Button
                onClick={() => {
                  setTransactionBeingModified(accountTransactionType.CREDIT, credit);
                  toggleAddCreditModal();
                }}
                variant="outlined"
                color="primary"
                disabled={!(isAccountOpen && hasWritePermissions)}
                data-test-id="editCredit"
              >
                {t('Edit')}
              </Button>
              <Button
                onClick={() => {
                  setTransactionBeingModified(accountTransactionType.CREDIT, credit);
                  toggleConfirmDeleteModal();
                }}
                variant="outlined"
                color="primary"
                disabled={!(isAccountOpen && hasWritePermissions)}
                data-test-id="deleteCredit"
                className={rcStyles.is_delete}
              >
                {t('Delete')}
              </Button>
            </div>
          </div>
        </ExpandableBanner>
      </div>
    )}
  </AccountConsumer>
);

TransactionCreditRow.propTypes = {
  credit: creditType.isRequired,
  linkedInvoice: invoiceType,
  setTransactionBeingModified: PropTypes.func.isRequired,
  toggleAddCreditModal: PropTypes.func.isRequired,
  toggleConfirmDeleteModal: PropTypes.func.isRequired,
};

export default TransactionCreditRow;
