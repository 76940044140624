import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import RemoveIcon from '@material-ui/icons/Remove';
import CloseIcon from '@material-ui/icons/Close';
import DynamicList from 'components/common/DynamicList';
import Document from 'components/common/Document';
import MediaUpload from 'components/common/MediaUpload';

import classNames from 'classnames';
import styles from 'scss/main.scss';
import rcStyles from './styles.scss';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const DocumentsModal = ({
  documentsList,
  onChange,
  onSave,
  onClose,
  onUploadComplete,
  disabled,
  errors,
  isOpen,
}) => (
  <Dialog
    open={isOpen}
    fullScreen
    disabled={disabled}
    TransitionComponent={Transition}
    className={styles.c_dialogue}
    disableBackdropClick
    disableEscapeKeyDown
  >
    <DialogTitle
      disableTypography
      className={styles.dialog_title_bar}
    >
      <IconButton
        className={classNames(
          styles.o_button__secondary,
          styles.is_close,
        )}
        color="primary"
        variant="contained"
        aria-label="Close"
        onClick={onClose}
        data-test-id="close"
      >
        <CloseIcon />
      </IconButton>
      <div className={styles.is_title}>
        {t('Documents')}
      </div>
      <div className={styles.is_actions}>
        <Button
          onClick={onSave}
          variant="contained"
          color="secondary"
          data-test-id="saveAndReturn"
          disabled={disabled}
          className={styles.o_button__secondary}
        >
          {t('Save & return')}
        </Button>
      </div>
    </DialogTitle>

    <div className={styles.c_dialog__overflow}>
      <div className={styles.o_view}>
        <DialogContent className={classNames(
          styles.o_view__main,
          styles.o_block_content,
          rcStyles.v_spacing,
        )}
        >
          <MediaUpload
            disabled={disabled}
            showImagePreview={false}
            onUploadDone={onUploadComplete}
            data-test-id="uploadFile"
          />
          <DynamicList
            isStacked
            component={(
              <Document />
            )}
            addButtonProps={{
              variant: 'contained',
              color: 'primary',
            }}
            removeButtonProps={{
              variant: 'outlined',
              color: 'primary',
              classes: { root: styles.o_button__round },
            }}
            removeButtonInner={
              <RemoveIcon />
            }
            dataList={documentsList}
            onChange={onChange}
            classes={classNames(
              documentsList && documentsList.length && styles.has_shadow,
            )}
            errors={errors}
            hideAddButton
            data-test-id="documentList"
          />
        </DialogContent>
      </div>
    </div>
  </Dialog>
);

DocumentsModal.propTypes = {
  documentsList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onUploadComplete: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  errors: PropTypes.objectOf(PropTypes.any),
  isOpen: PropTypes.bool.isRequired,
};

export default DocumentsModal;
