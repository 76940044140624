import { createMuiTheme } from '@material-ui/core/styles';
import vars from 'scss/abstracts/_variables.scss';

const overrideTheme = createMuiTheme({
  palette: {
    primary: {
      main: vars.colorPrimary,
    },
    contrastThreshold: 3,
    secondary: {
      main: vars.colorButtonSecondary,
      contrastText: vars.colorPrimary,
    },
  },
  typography: {
    fontFamily: vars.typographyPrimary,
  },
  overrides: {
    MuiIconButton: {
      root: {
        padding: '6px',
      },
    },
    MuiTab: {
      label: {
        fontWeight: '700',
        fontSize: '16px',
      },
    },
    MuiDialogTitle: {
      root: {
        marginBottom: vars.unitWhole,
        padding: '0',
      },
    },
    MuiDialogContent: {
      root: {
        overflowX: 'visible',
        overflowY: 'visible',
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'center',
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: vars.colorHighlight,
        },
      },
    },
    MuiInput: {
      underline: {
        '&&&&:hover:before': {
          borderBottom: '1px solid #1189cb',
        },
        '&&&&:focus:before': {
          borderBottom: '1px solid #1189cb',
        },
        '&&&&:after': {
          borderBottomColor: vars.colorHighlight,
        },
      },
      error: {
        '&&&&:after': {
          borderBottomColor: vars.colorError,
        },
      },
    },
    MuiButton: {
      contained: {
        boxShadow: 'none',
        fontWeight: vars.typographyBold,
      },
      outlined: {
        boxShadow: 'none',
        fontWeight: vars.typographyBold,
      },
      text: {
        padding: 0,
        margin: 0,
      },
      containedSecondary: {
        '&:hover': {
          backgroundColor: vars.colorGrey,
        },
      },
    },
    MuiListItem: {
      button: {
        transition: 'none',
      },
    },
    MuiFab: {
      root: {
        padding: 0,
      },
    },
    MuiFormControl: {
      root: {
        marginTop: 0,
      },
    },
    MuiBadge: {
      colorPrimary: {
        backgroundColor: vars.colorBadge,
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
        marginTop: 0,
        display: 'inline-flex',
        alignItems: 'center',
      },
    },
  },
});

export default overrideTheme;
