import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import CatalogueDrawer from 'components/portal/CatalogueDrawer';
import CataloguePackageCard from 'components/portal/CataloguePackageCard';
import NavigationMenuIcon from 'components/portal/NavigationMenuIcon';
import IconLoad from 'icons/IconLoad';
import { getTenantCatalogueCategories } from 'services/utils/catalogue';
import { CatalogueCategory } from 'types/ts/catalogue';

import Styled from './PortalCataloguePackagesScreen.styled';
import { Props } from './PortalCataloguePackagesScreen.types';

const Loader = () => (
  <Styled.Loader>
    <div className="inner">
      <IconLoad />
    </div>
  </Styled.Loader>
);

const PortalCataloguePackagesScreen: React.FC<Props> = ({
  category,
  displayPreferences,
  home,
  logoUri,
  cataloguePackages,
  getCataloguePackages,
}: Props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { id: homeId, name } = home || {};
  const url = `/portal/${homeId}`;
  const categories: CatalogueCategory[] = getTenantCatalogueCategories(displayPreferences)
    .filter((item: CatalogueCategory) => item.visible);
  const items = [...cataloguePackages?.items];

  return (
    <>
      <Styled.MainContainer>
        <Styled.LogoWrapper>
          <Link to={url}>
            <Styled.Logo src={logoUri} alt={name} aria-label={name} />
          </Link>
        </Styled.LogoWrapper>
        <Styled.ItemsContainer>
          <div className="menu__wrapper">
            <NavigationMenuIcon
              onClick={() => setIsDrawerOpen(!isDrawerOpen)}
              data-test-id="navigationMenuIcon"
            />
          </div>
          <div className="items__wrapper">
            <h1 className="category__heading">{category?.name}</h1>
            {(cataloguePackages.isLoading && items?.length === 0) && <Loader />}
            <Styled.Cards
              dataLength={items.length}
              hasMore={cataloguePackages.pagination.hasNextPage}
              next={getCataloguePackages}
              loader={items.length > 0 && <Loader />}
            >
              {items?.map(item => (
                <CataloguePackageCard
                  key={item.id}
                  item={item}
                  data-test-id="cataloguePackageCard"
                />
              ))}
            </Styled.Cards>
          </div>
        </Styled.ItemsContainer>
      </Styled.MainContainer>
      <CatalogueDrawer
        activeCategory={category.name}
        categories={categories}
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(!isDrawerOpen)}
      />
    </>
  );
};

export default PortalCataloguePackagesScreen;
