import { string, object, ref } from 'yup';

export const validationSchema = object().shape({
  password: string().nullable().required('The password is required')
    .min('6', 'Password should contain at least 6 characters')
    .matches(/[a-z]/, 'Password should contain at least one lowercase character')
    .matches(/[A-Z]/, 'Password should contain at least one uppercase character'),
  confirmPassword: string()
    .oneOf([ref('password'), null], 'Passwords must match')
    .required('Password confirmation is required'),
});
