import { categories, committalTypes, serviceCategories } from 'constants/arrangement';
import { navigation } from 'constants/navigation';

export const getAvailableNavigation = (
  availableFeatures, tenantCategories, arrangementCategoryDisplayPreferences, committalType, includeVenues = false,
) => {
  const isBurial = committalType === committalTypes.BURIAL;
  const indexByArrangementCategory = arrangementCategoryDisplayPreferences.reduce((accumulator, preference, index) => {
    accumulator[preference.category] = arrangementCategoryDisplayPreferences.length - index;
    return accumulator;
  }, {});

  const featureCategories = availableFeatures
    .filter(feature => feature.includes('category'))
    .map(feature => feature.replace('category', '').toUpperCase());

  const venuesPaths = navigation.filter((path) => {
    const isServiceVenue = path.category === serviceCategories.SERVICE_VENUES;
    const isCrematorium = path?.category === serviceCategories.CREMATORIA;
    return (path.isVenueScreen && !isServiceVenue) && !(isBurial && isCrematorium);
  });

  const excludedCategories = [
    ...(isBurial ? [categories.URNS] : []),
    serviceCategories.CREMATORIA,
    serviceCategories.CEMETERIES,
    serviceCategories.RECEPTION_VENUES,
  ];

  const navigationPaths = navigation
    .filter((path) => {
      const isIncludedCategory = !excludedCategories.includes(path.category);
      if (path.requiresFeatureFlag) {
        return tenantCategories.includes(path.pathCategory)
          && featureCategories.includes(path.pathCategory)
          && isIncludedCategory;
      }
      return tenantCategories.includes(path.pathCategory) && isIncludedCategory;
    })
    .map(path => ({
      ...path,
      order: indexByArrangementCategory[path.pathCategory] ?? 0,
    }))
    .sort((path1, path2) => path2.order - path1.order);

  if (includeVenues) {
    const serviceVenueIndex = navigationPaths.findIndex(
      path => path.category === serviceCategories.SERVICE_VENUES,
    );
    navigationPaths.splice(serviceVenueIndex + 1, 0, ...venuesPaths);
  }

  return navigationPaths;
};

export const getNextNavigationRoute = (currentRoute, routes) => {
  const currentNavigationIndex = routes.findIndex(route => route.name === currentRoute);
  return routes[currentNavigationIndex + 1];
};
