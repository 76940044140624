import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import { arrangementType } from 'types/bereavement';
import {
  filterSelectionsOnArrangement,
  filterSelectionsOptions,
  getArrangementSelectionsSortedByEstimateOrder,
} from 'services/utils/arrangement';
import EstimateAddons from './EstimateAddons';

class EstimateAddonsContainer extends Component {
  static propTypes = {
    bereavementId: PropTypes.string.isRequired,
    bereavementHomeId: PropTypes.string.isRequired,
    arrangement: arrangementType,
    disabled: PropTypes.bool.isRequired,
    onOpenConfirmationWarningModal: PropTypes.func.isRequired,
    onOpenDuplicateSelectionModal: PropTypes.func.isRequired,
    onSortEnd: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      isProfessionalServicesModalOpen: false,
      isDisbursementsModalOpen: false,
    };
  }

  getProfessionalServices() {
    const { arrangement } = this.props;
    const professionalServiceFilters = [
      filterSelectionsOptions.PROFESSIONAL_SERVICES,
    ];
    return getArrangementSelectionsSortedByEstimateOrder(
      arrangement,
      filterSelectionsOnArrangement(arrangement, professionalServiceFilters),
    );
  }

  getDisbursements() {
    const { arrangement } = this.props;
    const disbursementFilters = [
      filterSelectionsOptions.DISPLAY_AS_DISBURSEMENTS,
    ];
    return getArrangementSelectionsSortedByEstimateOrder(
      arrangement,
      filterSelectionsOnArrangement(arrangement, disbursementFilters),
    );
  }

  openAddProfessionalServiceModal = () => {
    this.setState({
      isProfessionalServicesModalOpen: true,
    });
  }

  handleOnClickAddDisbursement = () => {
    const { arrangement, onOpenConfirmationWarningModal } = this.props;

    if (arrangement.isConfirmed) {
      onOpenConfirmationWarningModal(this.openAddDisbursementModal);
      return;
    }

    this.openAddDisbursementModal();
  }

  openAddDisbursementModal = () => {
    this.setState({
      isDisbursementsModalOpen: true,
    });
  }

  handleOnCloseModal = () => {
    this.setState({
      isProfessionalServicesModalOpen: false,
      isDisbursementsModalOpen: false,
    });
  }

  handleOnClickAddProfessionalService = () => {
    const { arrangement, onOpenConfirmationWarningModal } = this.props;

    if (arrangement.isConfirmed) {
      onOpenConfirmationWarningModal(this.openAddProfessionalServiceModal);
      return;
    }

    this.openAddProfessionalServiceModal();
  }

  handleOnSortEndForProfessionalServices = ({ newIndex, oldIndex }) => {
    const { onSortEnd } = this.props;
    const selections = this.getProfessionalServices();
    onSortEnd(selections[oldIndex], selections[newIndex]);
  }

  handleOnSortEndForDisbursements = ({ newIndex, oldIndex }) => {
    const { onSortEnd } = this.props;
    const selections = this.getDisbursements();
    onSortEnd(selections[oldIndex], selections[newIndex]);
  }

  render() {
    const {
      bereavementId,
      bereavementHomeId,
      arrangement,
      disabled,
      onOpenConfirmationWarningModal,
      onOpenDuplicateSelectionModal,
    } = this.props;
    const {
      isProfessionalServicesModalOpen,
      isDisbursementsModalOpen,
    } = this.state;

    return (
      <EstimateAddons
        bereavementId={bereavementId}
        bereavementHomeId={bereavementHomeId}
        arrangement={arrangement}
        onOpenConfirmationWarningModal={onOpenConfirmationWarningModal}
        onOpenDuplicateSelectionModal={onOpenDuplicateSelectionModal}
        disabled={disabled}
        onClickAddProfessionalService={this.handleOnClickAddProfessionalService}
        onClickAddDisbursement={this.handleOnClickAddDisbursement}
        isProfessionalServicesModalOpen={isProfessionalServicesModalOpen}
        isDisbursementsModalOpen={isDisbursementsModalOpen}
        onCloseModal={this.handleOnCloseModal}
        professionalServiceSelections={this.getProfessionalServices()}
        onSortEndForProfessionalServices={this.handleOnSortEndForProfessionalServices}
        disbursementSelections={this.getDisbursements()}
        onSortEndForDisbursements={this.handleOnSortEndForDisbursements}
      />
    );
  }
}

export default withApollo(
  withRouter(EstimateAddonsContainer),
);
