import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const IconVehicle = (props) => {
  const { className } = props;
  const classes = className ? `${className} ${styles.rc_icon}` : styles.rc_icon;

  return (
    <svg viewBox="-0.1 1.2 24 24" className={classes}>
      <path d="M18.92,6.01 C18.72,5.42 18.16,5 17.5,5 L6.5,5 C5.84,5 5.29,5.42 5.08,6.01 L3,12 L3,20 C3,20.55 3.45,21 4,21 L5,21 C5.55,21 6,20.55 6,20 L6,19 L18,19 L18,20 C18,20.55 18.45,21 19,21 L20,21 C20.55,21 21,20.55 21,20 L21,12 L18.92,6.01 L18.92,6.01 Z M6.5,16 C5.67,16 5,15.33 5,14.5 C5,13.67 5.67,13 6.5,13 C7.33,13 8,13.67 8,14.5 C8,15.33 7.33,16 6.5,16 L6.5,16 Z M17.5,16 C16.67,16 16,15.33 16,14.5 C16,13.67 16.67,13 17.5,13 C18.33,13 19,13.67 19,14.5 C19,15.33 18.33,16 17.5,16 L17.5,16 Z M5,11 L6.5,6.5 L17.5,6.5 L19,11 L5,11 L5,11 Z" />
    </svg>
  );
};

IconVehicle.propTypes = {
  className: PropTypes.string,
};

export default IconVehicle;
