import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import styles from './styles.scss';

const SelectView = (props) => {
  const {
    inputProps, label, children, errorText, shrink,
    value, labelClasses, classes, fullWidth,
  } = props;
  const selectProps = { ...props };
  const selectClasses = { ...classes };
  const rootClasses = selectClasses ? { root: selectClasses.root } : {};
  const selectValue = value || '';
  const labelShrink = shrink || (value !== null && value !== undefined);

  delete selectProps.errorText;
  delete selectProps.labelClasses;
  delete selectProps.shrink;
  if (selectClasses) {
    delete selectClasses.root;
  }

  return (
    <FormControl classes={rootClasses} data-test-id="selectControl" fullWidth={fullWidth}>
      {
        label && (
          <InputLabel
            shrink={labelShrink}
            classes={labelClasses}
            htmlFor={inputProps.id}
            className={selectProps.isDark && styles.rc_selectaz}
          >
            {label}
          </InputLabel>
        )
      }
      <Select
        {...selectProps}
        classes={selectClasses}
        value={selectValue}
        inputProps={{
          'data-test-id': 'select',
          ...inputProps,
        }}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          transformOrigin: { vertical: 'top', horizontal: 'center' },
          classes: {
            paper: styles.options,
          },
        }}
        className={selectProps.isDark && styles.rc_selectaz}
      >
        {
          children
        }
      </Select>
      {
        errorText && (
          <FormHelperText error>{errorText}</FormHelperText>
        )
      }
    </FormControl>
  );
};


SelectView.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.any),
  labelClasses: PropTypes.objectOf(PropTypes.any),
  inputProps: PropTypes.objectOf(PropTypes.any),
  errorText: PropTypes.string,
  shrink: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

SelectView.defaultProps = {
  fullWidth: true,
};

export default SelectView;
